<template>
  <div id="eProtokol">
    <!-- Show only button to guardians -->
    <div v-if="userProfile === 'guardian' && ready">
      <!-- Show only button to guardians with associated institution number -->
      <div v-if="institutionFilter[0] !== ''">
        <!-- <input type="button" value="Sygemeld barn i E-protokol" v-on:click="loadUrlsForIntitutions"> Denne er blevet udkommenteret da vi mistænker at det er den der ikke virker i IOS appen -->
        <form v-on:submit="loadUrlsForIntitutions">
          <input type="submit" value="Sygemeld barn i E-protokol" />
        </form>
      </div>
      <div v-else>
        E-protokol kan ikke se hvilke skole du eller dit barn er tilknyttet
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    axios: Function,
    moment: Function,
    sessionUUID: String,
    placement: String,
    currentWeekNumber: String,
    isMobileApp: Boolean,
    userProfile: String,
    getAulaToken: Function,
    assuranceLevel: Number,
    childFilter: Array,
    institutionFilter: Array,
    group: String,
  },
  data: function () {
    return {
      aulaToken: null,
      ready: false,
    };
  },
  methods: {
    /* Custom Vue Event handler: loadUrlsForIntitutions() */
    loadUrlsForIntitutions() {
      var strUsername = "b9u9uk55k6emm283er";
      var strPassword = "Aand3erAnd33BoorHeer";
      var auth = {
        username: strUsername,
        password: strPassword,
      };
      if (this.institutionFilter.length > 0) {
        var arrUrlCollection = [];
        if (this.institutionFilter[0] === "111111") {
          arrUrlCollection.push({
            key: 111111,
            url:
              "https://demo.eprotokol.dk/iframe_frontend.php?mode=protokol&submode=comp_protokol_parent_interface_report_pupil_absent",
          });
          if (arrUrlCollection.length == 1) {
            window.open(
              arrUrlCollection[0].url,
              "_blank",
              "height=480,width=480"
            );
          }
        } else {
          /* Get url to E-protokol for each inst. number */
          this.institutionFilter.forEach((intInstNumber, index) => {
            this.axios
              .get(
                "https://www.apricore.com/gatekeeper.php/REST/getProtocolAulaData/UrlFromInstNum/" +
                  intInstNumber,
                { auth: auth }
              )
              .then((response) => {
                var strUrl = response.data.data;
                if (strUrl !== null) {
                  arrUrlCollection.push({
                    key: intInstNumber,
                    url:
                      "https://" +
                      strUrl +
                      "/iframe_frontend.php?mode=protokol&submode=comp_protokol_parent_interface_report_pupil_absent",
                  });

                  /* Now all URLs is collected - pick 1st URL */
                  if (arrUrlCollection.length == 1) {
                    window.open(
                      arrUrlCollection[0].url,
                      "_blank",
                      "height=480,width=480"
                    );
                  }
                } else {
                  console.log(index);
                  if (index === this.institutionFilter.length - 1) {
                    alert(
                      "E-protokol er ikke i brug på denne skole (skolekode: " +
                        intInstNumber +
                        ")"
                    );
                  }
                }
              })
              .catch((error) => {
                console.log(error);
              });
          });
        }
      } else {
        this.axios
          .get(
            "https://www.apricore.com/gatekeeper.php/REST/getProtocolAulaData/UrlFromInstNum/" +
              this.institutionFilter[0],
            { auth: auth }
          )
          .then((response) => {
            var strUrl = response.data.data;
            window.open(
              "https://" +
                strUrl +
                "/iframe_frontend.php?mode=protokol&submode=comp_protokol_parent_interface_report_pupil_absent",
              "_blank",
              "height=480,width=480"
            );
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
  mounted() {
    /* START: Mandatory */
    this.aulaToken = this.getAulaToken();
    /* END: Mandatory */
  },
  watch: {
    aulaToken: function () {
      this.ready = false;
      if (this.aulaToken !== undefined) {
        this.ready = true;
      }
    },
  },
};
</script>

<style scoped>
/* START: Mandatory */
b:before {
  content: "";
  display: block;
}
.breakWord {
  word-break: break-all;
}
/* END: Mandatory */
</style>