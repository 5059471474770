<template>
  <div>
    <h1>widget - testing props </h1>
    <template v-if="sessionUUID"><strong>sessionUUID:</strong> {{ sessionUUID }}<br></template>
    <template v-if="placement"><strong>placement:</strong> {{ placement }}<br></template>
    <template v-if="currentWeekNumber"><strong>currentWeekNumber:</strong> {{ currentWeekNumber }}<br></template>
    <template v-if="isMobileApp"><strong>isMobileApp:</strong> {{ isMobileApp }}<br></template>
    <template v-if="userProfile"><strong>userProfile:</strong> {{ userProfile }}<br></template>
    <template v-if="aulaToken"><strong>aulaToken:</strong> <span class="breakWord">{{ aulaToken }}</span><br></template>
    <template v-if="assuranceLevel"><strong>assuranceLevel:</strong> {{ assuranceLevel }}<br></template>
    <template v-if="childFilter"><strong>childFilter:</strong> {{ childFilter }}<br></template>
    <template v-if="institutionFilter"><strong>institutionFilter:</strong> {{ institutionFilter }}<br></template>
    <template v-if="group"><strong>group:</strong> {{ group }}<br></template>
    <template v-if="someDate"><strong>moment date:</strong> {{ someDate }}<br></template>
    <br/><button class="btn btn-primary" @click="openModal()">open modal</button><br/>
    <p>
      Lets load some data with Axios:
        <blockquote v-if="posts && posts.length">
            <p v-for="post of posts" :key="post.id">
                <strong>{{ post.id }} - {{post.title}}</strong>
                <br/> {{post.body}}
            </p>
        </blockquote>
    </p>
    <b-modal
      ref="notes-modal"
      title="test modal"
      hide-footer
    >
      test
    </b-modal>
    <button @click="stepUp()">Stepup</button>
    <div>
      <p class="d-flex justify-content-between align-items-center">
        Notifikationer
        <b-btn @click="clearAllNotifications">Ryd alle</b-btn>
      </p>
      <template v-if="notifications && notifications.length > 0">
        <div v-for="notification in notifications" class="d-flex justify-content-between align-items-center" :key="notification.widgetNotificationId">
          <p class="mb-0">
            {{notification.notificationMessage}}
          </p>
          <b-btn @click="clearNotification(notification)" :disabled="removeNotificationSet.has(notification)" variant="link">
            X
          </b-btn>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    axios: Function,
    moment: Function,
    sessionUUID: String,
    placement: String,
    currentWeekNumber: String,
    isMobileApp: Boolean,
    userProfile: String,
    getAulaToken: Function,
    assuranceLevel: Number,
    childFilter: Array,
    institutionFilter: Array,
    group: String,
    triggerStepUp: Function,
    notifications: Array,
    deleteNotifications: Function,
  },
  data: function () {
    return {
      posts: {},
      aulaToken: null,
      someDate: null,
      removeNotificationSet: new Set(),
    }
  },
  methods: {
    loadStuff() {
      this.axios.get("/static/posts.json")
        .then(response => {
          // JSON responses are automatically parsed.
          this.posts = response.data;
        })
        .catch(e => {
          //some error handling
          console.log(e);
        })
    },
    openModal() {
      this.$refs['notes-modal'].show();
    },
    stepUp() {
      this.triggerStepUp();
    },
    async clearNotification(notification) {
      this.removeNotificationSet.add(notification);
      await this.deleteNotifications([notification.widgetNotificationId]);
      this.removeNotificationSet.delete(notification);
    },
    async clearAllNotifications() {
      this.removeNotificationSet = new Set(this.notifications);
      const notificationIds = this.notifications.map(notification => notification.widgetNotificationId);
      await this.deleteNotifications(notificationIds);
      this.removeNotificationSet.clear();
    },
  },
  mounted() {
    this.aulaToken = this.getAulaToken();
    this.someDate = this.moment().format('MMMM Do YYYY, h:mm:ss a');
  },
  watch: {
    aulaToken: function() {
      if (this.aulaToken != undefined) {
        this.loadStuff();
      }
    },
  },
};
</script>

<style scoped>
b:before {
  content: "";
  display: block;
}
.breakWord {
  word-break: break-all;
}
</style>
