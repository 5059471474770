<script>

import Spinner from './spinner'

const ConsentBox = {
    props: {
        users: Array,
        subHeaderText: String,
        giveConsent: Function,
        translations: Object,
    },
    components: {
        spinner: Spinner,
    },
    data() {
        return {
            isOpen: false,
            isLoading: false,
        }
    },
    methods: {
        toggleOpen() {
            this.isOpen = !this.isOpen
        },
        async onGiveConsent() {
            this.isLoading = true
            await this.giveConsent()
            this.isLoading = false
        },
    },
}

export default ConsentBox

</script>

<template>
    <div class="give-consent-container" v-bind:class='{pointer: !isOpen}'>
        <div v-if='isLoading' class='give-consent-loading-container'>
            <spinner :size='40'></spinner>
        </div>
        <div>
            <div class="consent-header" @click='toggleOpen()'>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" class="header-consent-icon">
                    <path
                        d="M4 0v32h15.333c4.786 0 8.667-3.88 8.667-8.667v0-23.333h-24zM26.667 23.333c0 4.050-3.283 7.333-7.333 7.333v0h-14v-29.333h21.333v22zM10.667 9.333h10.667v1.333h-10.667v-1.333zM10.667 14.667h10.667v1.333h-10.667v-1.333zM10.667 20h8v1.333h-8v-1.333z" />
                </svg>
                <div class='header-consent-text'>
                    <h3 class="header-consent-heading">{{ translations.consentBox.header }}</h3>
                    <h3 class="header-consent-subheading">{{ subHeaderText }} (<span class='number-text'>{{ this.users.length }}</span> {{ translations.consentBox.people }})
                    </h3>
                </div>
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" class="header-consent-exit pointer" v-if='!isOpen'>
                    <path d="M28 15.333h-11.333v-11.333h-1.333v11.333h-11.333v1.333h11.333v11.333h1.333v-11.333h11.333v-1.333z" />
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" class="header-consent-exit pointer" v-if='isOpen'>
                    <path d="M5.333 15.333h21.333v1.333h-21.333v-1.333z" />
                </svg>
            </div>
            <div class="consent-body" v-if="isOpen">
                <p class="consent-text">{{ translations.consentBox.text }}</p>
                <div class="consent-button-container pointer">
                    <div class="consent-button text-primary border border-primary pointer" @click="onGiveConsent()">
                        {{ translations.consentBox.buttonText }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>