import { Validator } from './validator';
import { NewVacationInterval } from '../newVacationInterval';
import moment from 'moment-timezone';
import { SpecificOpeningHoursModel } from '../../../shared/models/specificOpeningHours.model';
import { GeneralOpeningHoursModel } from '../../../shared/models/generalOpeningHours.model';

export class NewVacationIntervalValidator extends Validator {
  private _hasTimes = true;
  private _isValidTimes = true;
  private _isValidIncoming = true;
  private _isWithinOpeningHour = true;

  constructor(
    vacationInterval: NewVacationInterval,
    specificOpeningHours?: SpecificOpeningHoursModel,
    generalOpeningHours?: GeneralOpeningHoursModel
  ) {
    super();
    this._vacationInterval = vacationInterval;

    this._specificOpeningHours = specificOpeningHours;
    this._generalOpeningHours = generalOpeningHours;
  }

  private readonly _vacationInterval: NewVacationInterval;
  private _specificOpeningHours: SpecificOpeningHoursModel;
  private _generalOpeningHours: GeneralOpeningHoursModel;

  get isWithinOpeningHour() {
    return this._isWithinOpeningHour;
  }
  set isWithinOpeningHour(isWithinOpeningHour: boolean) {
    this._isWithinOpeningHour = isWithinOpeningHour;
  }

  get hasTimes() {
    return this._hasTimes;
  }
  set hasTimes(hasTimes: boolean) {
    this._hasTimes = hasTimes;
  }

  get isValidTimes() {
    return this._isValidTimes;
  }
  set isValidTimes(isValidTimes: boolean) {
    this._isValidTimes = isValidTimes;
  }

  get isValidIncoming() {
    return this._isValidIncoming;
  }
  set isValidIncoming(isValidTimes: boolean) {
    this._isValidIncoming = isValidTimes;
  }

  validateIsMissingTime = () => {
    const { entryTime, exitTime, isComing, isDisabled, useTimes } = this._vacationInterval;
    let isValid = true;
    if (!isDisabled && useTimes && isComing === true) {
      isValid = Boolean(entryTime && exitTime);
    }
    this._vacationInterval.validator.hasTimes = isValid;
    return isValid;
  };

  validateIsValidTime = () => {
    const { isComing, isDisabled, useTimes } = this._vacationInterval;
    let isValid = true;
    if (!isDisabled && useTimes && isComing === true) {
      isValid = this.getIsEntryTimeLowerThanExitTime(this._vacationInterval);
    }
    this._vacationInterval.validator.isValidTimes = isValid;
    return isValid;
  };

  validateIsComing = () => {
    const { isComing, isDisabled } = this._vacationInterval;
    let isValid = true;
    if (!isDisabled) {
      isValid = isComing != null;
    }
    this._vacationInterval.validator.isValidIncoming = isValid;
    return isValid;
  };

  validateIsValidOpeningHour = () => {
    const { isDisabled, entryTime, exitTime, useTimes, isComing } = this._vacationInterval;
    const hasTimes = this.getHaveTimes();
    let isValid = false;

    if (isDisabled || !useTimes || !isComing) {
      isValid = true;
    } else if (hasTimes) {
      const lowestStartTime = moment(this._vacationInterval.getStartTime(), 'HH:mm');
      const highestEndTime = moment(this._vacationInterval.getEndTime(), 'HH:mm');
      const momentEntryTime = moment(entryTime, 'HH:mm');
      const momentExitTime = moment(exitTime, 'HH:mm');
      isValid = lowestStartTime.isSameOrBefore(momentEntryTime) && highestEndTime.isSameOrAfter(momentExitTime);
    }

    this._vacationInterval.validator.isWithinOpeningHour = isValid;
    return isValid;
  };

  validate(): boolean {
    return this.validateRules([
      this.validateIsMissingTime,
      this.validateIsValidTime,
      this.validateIsComing,
      this.validateIsValidOpeningHour,
    ]);
  }

  private getHaveTimes() {
    const { entryTime, exitTime } = this._vacationInterval;
    return Boolean(entryTime && exitTime);
  }

  private getIsEntryTimeLowerThanExitTime(vacationInterval: NewVacationInterval) {
    const { entryTime, exitTime } = vacationInterval;
    if (!this.getHaveTimes()) {
      return false;
    }
    const momentEntryTime = moment(entryTime, 'HH:mm');
    const momentExitTime = moment(exitTime, 'HH:mm');
    return momentEntryTime.isBefore(momentExitTime);
  }
}
