<template>
  <div>
    <div v-if="isUserSteppedUp">
      <div v-for="group in groups" :key="group" class="nct-container">
        <div class="nct-header" @click="toggleGroup">
          <div class="nct-icon-container">
            <div class="nct-avatar">
              <span>
                {{ initialsForGroup(group) }}
              </span>
            </div>
          </div>
          <div class="nct-group-name">
            <b>{{ group }}</b>
            <div
              class="nct-badge"
              v-if="numberOfUnreadDocumentsFortGroup(group) > 0"
            >
              <span>
                {{ numberOfUnreadDocumentsFortGroup(group) }}
              </span>
            </div>
          </div>

          <i
            class="aula-icon aula-icon-variables nct-expand-icon icon-Aula_down-arrow"
          ></i>
        </div>
        <div
          class="nct-list aula-documentsCommonList-container"
          :class="{ active: activeGroups.indexOf('group') > -1 }"
        >
          <div class="table">
            <div
              class="table-row body"
              v-for="document in documentsForGroup(group)"
              :key="document.id"
              tabindex="0"
              role="button"
              @click="onOpenDocument(document)"
            >
              <div
                class="table-cell icon-container nct-icon-container not-collapsed"
              >
                <i class="icon-Aula_paper"></i>
              </div>
              <div class="table-cell not-collapsed nct-document-name">
                <b>
                  {{ document.fileName }}
                </b>
                <span v-if="!document.isRead" class="nct-new-document-mark">
                  Ny
                </span>
              </div>
              <div class="table-cell not-collapsed">
                {{ document.institutionName }}
              </div>
              <div class="table-cell not-collapsed">
                Delt {{ document.createdDate | toDkDateString }} af
                {{ document.sharedByName }}
              </div>

              <div class="table-cell collapsed-only">
                <div class="item">
                  <b>
                    {{ document.fileName }}
                  </b>
                  <span v-if="!document.isRead" class="nct-new-document-mark">
                    Ny
                  </span>
                  <br />
                  {{ document.institutionName }}
                  <br />
                  Delt {{ document.createdDate | toDkDateString }} af
                  {{ document.sharedByName }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="nct-container" v-if="institutionDocuments">
        <div class="nct-header" @click="toggleGroup">
          <div class="nct-icon-container">
            <span class="icon-Aula_folder"></span>
          </div>
          <div class="nct-group-name">
            <b>Fælles</b>
          </div>
          <i
            class="aula-icon aula-icon-variables nct-expand-icon icon-Aula_down-arrow"
          ></i>
        </div>
        <div
          class="nct-list aula-documentsCommonList-container"
          :class="{ active: activeGroups.indexOf('Fælles') > -1 }"
        >
          <div class="table">
            <div
              class="table-row body"
              v-for="document in institutionDocuments"
              :key="document.id"
              tabindex="0"
              role="button"
              @click="onOpenDocument(document)"
            >
              <div
                class="table-cell icon-container nct-icon-container not-collapsed"
              >
                <i class="icon-Aula_paper"></i>
              </div>
              <div class="table-cell not-collapsed nct-document-name">
                <b>
                  {{ document.fileName }}
                </b>
              </div>
              <div class="table-cell not-collapsed">
                {{ document.institutionName }}
              </div>
              <div class="table-cell not-collapsed">
                Delt {{ document.createdDate | toDkDateString }}
              </div>
              <div class="table-cell collapsed-only">
                <div class="item">
                  <b>{{ document.fileName }}</b>
                  <br />
                  {{ document.institutionName }}
                  <br />
                  Delt {{ document.createdDate | toDkDateString }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else class="nct-button-container" role="button" @click="stepUp">
      <div>
        <span class="icon-Aula_folder nct-file-icon"></span>
      </div>
      <div>KK filer er låst <span class="icon-Aula_lock"></span></div>
      <button>
        Åbn
        <span class="icon-Aula_forward-arrow pull-right"></span>
      </button>
    </div>
  </div>
</template>

<script>
const uriToAulaDocumentsApi =
  "https://aulawidgetapi.nordiccloud.dk/api/confidential/user";
const uriToAulaInstitutionDocumentsApi =
  "https://aulawidgetapi.nordiccloud.dk/api/confidential/institution";

const uriToDocumentRequest =
  "https://aulawidgetapi.nordiccloud.dk/api/confidential/documents/geturl";

export default {
  props: {
    axios: Function,
    getAulaToken: Function,
    triggerStepUp: Function,
    userProfile: String,
    assuranceLevel: Number,
    childFilter: Array,
    institutionFilter: Array,
    isMobileApp: Boolean,
    notifications: Array,
    deleteNotifications: Function,
  },
  data() {
    return {
      activeGroups: [],
      aulaToken: null,
      documentCollection: null,
      institutionDocumentCollection: [],
      title: "",
      selectedChildName: null,
      isChildSelectorHidden: false,
      loadingData: true,
    };
  },

  computed: {
    groups() {
      if (this.documentCollection) {
        const childrenNames = this.documentCollection.map((c) => c.childName);
        return [...new Set(childrenNames)].sort((a, b) => a.localeCompare(b));
      }
      return null;
    },

    institutionDocuments() {
      return this.institutionDocumentCollection.sort((a, b) =>
        a.fileName.localeCompare(b.fileName)
      );
    },

    isNothingToShow() {
      return (
        !this.loadingData &&
        this.documentCollection !== null &&
        (!Array.isArray(this.documentCollection) ||
          !this.documentCollection.length)
      );
    },

    isUserSteppedUp() {
      return this.assuranceLevel > 2;
    },
  },

  mounted() {
    this.aulaToken = this.getAulaToken();
  },

  watch: {
    aulaToken() {
      if (this.aulaToken) {
        this.loadData();
      }
    },

    assuranceLevel() {
      this.loadData();
    },
  },

  methods: {
    async loadData() {
      if (this.isUserSteppedUp) {
        this.loadingData = true;
        const promises = [
          this.loadConfidentialDocumentsSharedFromApi(),
          this.loadInstitutionDocumentsSharedFromApi(),
        ];
        await Promise.all(promises);
        this.loadingData = false;
      }
    },

    async loadConfidentialDocumentsSharedFromApi() {
      try {
        const documentsResult = await this.axios.get(
          `${uriToAulaDocumentsApi}?userProfile=${this.userProfile}&assuranceLevel=${this.assuranceLevel}&childFilter=${this.childFilter}&institutionFilter=${this.institutionFilter}&isMobileApp=${this.isMobileApp}`,
          { headers: { Authorization: `Bearer ${this.aulaToken}` } }
        );
        documentsResult.data.documents.forEach(
          (d) => (d.createdDate = new Date(d.created))
        );
        this.documentCollection = documentsResult.data.documents;
        this.title = documentsResult.data.title;
        this.synchronizeBadgeNotifications();
      } catch {
        this.documentCollection = [];
      }
    },

    async loadInstitutionDocumentsSharedFromApi() {
      try {
        const documentsResult = await this.axios.get(
          `${uriToAulaInstitutionDocumentsApi}?userProfile=${this.userProfile}&assuranceLevel=${this.assuranceLevel}&childFilter=${this.childFilter}&institutionFilter=${this.institutionFilter}&isMobileApp=${this.isMobileApp}`,
          { headers: { Authorization: `Bearer ${this.aulaToken}` } }
        );
        documentsResult.data.documents.forEach((d) => {
          d.createdDate = new Date(d.created);
        });
        this.institutionDocumentCollection = documentsResult.data.documents;
      } catch {
        this.institutionDocumentCollection = [];
      }
    },

    documentsForGroup(groupName) {
      return this.documentCollection
        .filter((dc) => dc.childName === groupName)
        .sort((a, b) => b.createdDate - a.createdDate);
    },

    initialsForGroup(group) {
      const splits = group.split(" ");
      if (splits.length > 1) {
        return splits[0][0] + splits[splits.length - 1][0];
      }
    },

    numberOfUnreadDocumentsFortGroup(groupName) {
      return this.documentCollection.filter(
        (dc) => dc.childName === groupName && !dc.isRead
      ).length;
    },

    toggleGroup(evnt) {
      try {
        let target = evnt.target;

        while (!target.classList.contains("nct-header")) {
          target = target.parentElement;
        }

        target.classList.toggle("active");

        const listDomElement = target.nextElementSibling;

        if (listDomElement.style.maxHeight) {
          listDomElement.style.maxHeight = null;
        } else {
          listDomElement.style.maxHeight = listDomElement.scrollHeight + "px";
        }
      } catch {
        return;
      }
    },

    async onOpenDocument(document) {
      try {
        if (document.uid) {
          const documentRequestResult = await this.axios.get(
            `${uriToDocumentRequest}?uid=${document.uid}&fdi=${document.fdi}&fii=${document.fii}`,
            { headers: { Authorization: `Bearer ${this.aulaToken}` } }
          );
          window.open(documentRequestResult.data, "_blank");
        } else {
          const documentRequestResult = await this.axios.get(
            `${uriToDocumentRequest}?iid=${document.iid}&fdi=${document.fdi}&fii=${document.fii}`,
            { headers: { Authorization: `Bearer ${this.aulaToken}` } }
          );
          window.open(documentRequestResult.data, "_blank");
        }

        setTimeout(() => {
          this.loadConfidentialDocumentsSharedFromApi();
        }, 4000);
      } catch {
        this.loadConfidentialDocumentsSharedFromApi();
      }
    },

    synchronizeBadgeNotifications() {
      try {
        const documentIdsForNotReadDocuments = this.documentCollection
          .filter((doc) => !doc.isRead)
          .map((doc) => doc.confidentialDocumentId);
        const notificationIdsToDelete = this.notifications
          .filter(
            (not) =>
              !documentIdsForNotReadDocuments.includes(not.widgetNotificationId)
          )
          .map((not) => not.widgetNotificationId);
        this.deleteNotifications(notificationIdsToDelete);
      } catch {
        return;
      }
    },

    stepUp() {
      this.triggerStepUp();
    },
  },

  filters: {
    toDkDateString: function (datetime) {
      if (!datetime) return "";

      return `${datetime.toLocaleDateString("da-dk", {
        year: "numeric",
        month: "short",
        day: "numeric",
      })} kl. ${datetime.toLocaleTimeString("da-dk", {
        hour: "numeric",
        minute: "numeric",
      })}`;
    },
  },
};
</script>

<style scoped>
.aula-documentsCommonList-container .table .table-row.body .table-cell {
  border-width: 0 0 2px 0;
}

.fa {
  line-height: unset;
}

b {
  font-weight: 900;
}

a {
  color: rgb(25, 66, 95);
}

.nct-widget-container {
  background-color: white;
}

.nct-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2px;
  background-color: white;
  cursor: pointer;
  user-select: none;
  padding: 14px;
  padding-left: 7px;
}

.nct-header i {
  font-weight: 400;
  font-size: 6px;
  height: 6px;
  margin-right: 10px;
  transition: transform 0.15s ease-out;
}

.nct-header i.fa {
  font-weight: 300;
  font-size: 24px;
  transition: transform 0.15s ease-out;
}

.nct-header.active i:not(.fa) {
  transform: rotate(-180deg);
}

.nct-list {
  transition: max-height 0.15s ease-out;
  max-height: 0;
  overflow: hidden;
}

.nct-badge {
  border-radius: 100%;
  background-color: rgb(181, 0, 80);
  color: white;
  width: 15px;
  height: 15px;
  text-align: center;
  font-size: 0.6rem;
  padding-top: 1px;
  display: inline-block;
  margin-left: 10px;
}

.nct-avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background-color: #18638f;
  color: white;
  width: 35px;
  height: 35px;
  text-align: center;
  font-size: 1rem;
}

.nct-avatar span {
  margin-top: -1px;
}

.nct-new-document-mark {
  color: #b50050;
  margin-left: 10px;
  font-size: 0.75rem;
}

.nct-button-container {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 270px;
  text-align: center;
  background-color: #e9eef1;
}

.nct-button-container > div {
  padding: 20px;
  font-size: 18px;
  margin-top: -4px;
}

.nct-button-container > div:first-child {
  margin-top: 30px;
}

.nct-button-container > button {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0;
  background-color: #347da7;
}

.nct-button-container > button > span {
  margin-left: 10px;
}

.nct-file-icon {
  font-size: 3.5em;
  opacity: 0.2;
}

.nct-ml-10 {
  margin-left: 10px;
}

.nct-group-name {
  width: 100%;
  padding: 14px;
  padding-left: 18px;
}

.nct-icon-container {
  width: 40px;
  text-align: center;
}

.nct-icon-container i {
  font-size: 1.5em;
}

.nct-document-name,
.nct-document-name + div {
  width: 30%;
}
</style>
