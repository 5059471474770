<template>
  <div class="widget-container">
    <div class="df-datepicker">
      <div class="df-date-button date-back">
        <b-button
          :disabled="disablePreviousDay || disableDateSelection"
          class="btn btn-link navbar-btn navbar-link"
          @click="backDate"
        >
          <i class="fas fa-chevron-left"></i>
        </b-button>
      </div>

      <div class="df-date">
        {{ displayDate }}
      </div>

      <div class="df-date-button date-forward">
        <b-button
          :disabled="disableNextDay || disableDateSelection"
          class="btn btn-link navbar-btn navbar-link"
          @click="forwardDate"
        >
          <i class="fas fa-chevron-right"></i>
        </b-button>
      </div>
    </div>

    <div class="fravaer-skemaer">
      <div class="ist-skema" v-for="skole in fravaersInfo" v-bind:key="skole.skoleId">
        <div class="top-bar" v-if="!skole.error">
          <span>
            <h2 align="center" v-if="skole.data">
              Skemaændringer for {{ skole.data.institutionNavn }}
            </h2>
            <span class="title-card" v-else>
              <h2 class="loading" align="center">Henter skemaændringer for {{ skole.skoleId }}</h2>
            </span>
          </span>
          <span>
            <h6 align="center" class="text-muted" v-if="skole.data && skole.data.cachedDate">
              - Sidst opdateret {{ formatDateToHours(skole.data.cachedDate) }}
            </h6>
          </span>
        </div>
        <div class="fravaer-bottom" v-if="skole.errorMessage">
          <span>
            <h5>{{ skole.skoleId }}:</h5>
          </span>
          <span>Fejl ved henting af dagens skemaændringer. Besked:</span>
          {{ skole.errorMessage }}
        </div>
        <div v-if="skole.data">
          <div v-if="skole.data.fravaerendeLaeerer && skole.data.fravaerendeLaeerer.length > 0">
            <b-card class="title-card">
              <h5 align="center">Fraværende personale</h5>
            </b-card>
            <b-card-group columns>
              <div v-for="info in skole.data.fravaerendeLaeerer" v-bind:key="info.betegnelse">
                <b-card class="kort">
                  <div class="nameContainer">
                    {{ info.personNavn }} ({{ info.betegnelse }})
                    <span
                      v-for="tid in info.tider"
                      v-bind:key="tid.from"
                      style="white-space:nowrap;"
                      >({{ formatDateToHours(tid.from) }} - {{ formatDateToHours(tid.to) }})</span
                    >
                    -
                    <span style="white-space:nowrap;"
                      >({{info.aarsager.join(', ')}})</span
                    >
                  </div>

                  <table class="table">
                    <tbody>
                      <tr
                        v-for="aktivitet in sortAndFilterActivities(info.aktiviteter)"
                        v-bind:key="aktivitet.id"
                        v-bind:class="{
                          changedRow:
                            (aktivitet.personaleDeltagerInitialer &&
                              aktivitet.personaleDeltagerInitialer.indexOf(info.betegnelse) ===
                                -1) ||
                            containsSpecialStatus(aktivitet),
                          akuRow: aktivitet.type === 'AndenKlasseundervisning'
                        }"
                      >
                        <td class="periodTd">
                          {{ formatDateToHours(aktivitet.fraTidspunkt) }} -
                          {{ formatDateToHours(aktivitet.tilTidspunkt) }}
                        </td>

                        <td class="leftBorderTd">
                          <span
                            v-if="
                              aktivitet.gruppeDeltagerNavne &&
                                aktivitet.gruppeDeltagerNavne.length > 0
                            "
                            >{{ aktivitet.gruppeDeltagerNavne.join(", ") }} -</span
                          >
                          {{
                            aktivitet.emne.kortBeskrivelse ||
                              aktivitet.emne.beskrivelse ||
                              aktivitet.bemaerkning
                          }}
                        </td>
                        <td class="leftBorderTd">
                          <div v-if="containsSpecialStatus(aktivitet)">
                            {{ formatStatus(aktivitet.status) }}
                          </div>
                          <div
                            v-else-if="
                              aktivitet.personaleDeltagerInitialer &&
                                aktivitet.personaleDeltagerInitialer.length > 0
                            "
                          >
                            {{ aktivitet.personaleDeltagerInitialer.join(", ") }}
                          </div>
                          <div v-else-if="aktivitet.type === 'AndenKlasseundervisning'">
                            {{ info.betegnelse }}
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </b-card>
              </div>
            </b-card-group>
          </div>
          <div v-else>
            <b-card class="title-card">
              <h5 align="center">Intet fraværende personale i dag</h5>
            </b-card>
          </div>

          <div v-if="skole.data.laerereMedAendringer.length > 0">
            <b-card class="title-card">
              <h5 class="title-card" align="center">Personale med skemaændringer</h5>
            </b-card>

            <b-card-group columns>
              <b-card
                class="kort"
                v-for="info in skole.data.laerereMedAendringer"
                v-bind:key="info.betegnelse"
              >
                <div class="nameContainer">{{ info.personNavn }} ({{ info.name }})</div>

                <table class="table">
                  <tbody>
                    <tr
                      v-for="aktivitet in sortAndFilterActivities(info.aktiviteter)"
                      v-bind:key="aktivitet.id"
                      v-bind:class="{
                        changedRow:
                          aktivitet.personaleDeltagerInitialer ||
                          aktivitet.status === 'AndenKlasseundervisningFri' ||
                          aktivitet.type === 'Timebyt'
                      }"
                    >
                      <td class="periodTd">
                        {{ formatDateToHours(aktivitet.fraTidspunkt) }} -
                        {{ formatDateToHours(aktivitet.tilTidspunkt) }}
                      </td>

                      <td class="leftBorderTd">
                        <span
                          v-if="
                            aktivitet.gruppeDeltagerNavne &&
                              aktivitet.gruppeDeltagerNavne.length > 0
                          "
                          >{{ aktivitet.gruppeDeltagerNavne.join(", ") }} -</span
                        >
                        {{
                          aktivitet.emne.kortBeskrivelse ||
                            aktivitet.bemaerkning ||
                            aktivitet.emne.beskrivelse
                        }}
                      </td>
                      <td class="leftBorderTd">
                        <div v-if="containsSpecialStatus(aktivitet)">
                          {{ formatStatus(aktivitet.status) }}
                        </div>
                        <div
                          v-else-if="
                            aktivitet.status === 'AndenKlasseundervisningFri' ||
                              aktivitet.status === 'AKUFriBrugtTilVikardaekning'
                          "
                        >
                          {{ aktivitet.bemaerkning }}
                          <!-- Ignored-->
                        </div>
                        <div v-else-if="aktivitet.personaleDeltagerInitialer">
                          {{ aktivitet.personaleDeltagerInitialer.join(", ") }}
                        </div>
                        <div v-else>{{ info.name }}</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </b-card>
            </b-card-group>
          </div>
          <div v-else>
            <b-card class="title-card">
              <h5 align="center">Intet personale med skemaændringer</h5>
            </b-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    axios: Function,
    sessionUUID: String,
    placement: String,
    currentWeekNumber: String,
    isMobileApp: Boolean,
    userProfile: "child" | "employee" | "guardian",
    getAulaToken: Function,
    assuranceLevel: Number,
    childFilter: Array,
    institutionFilter: Array,
    group: String,
    moment: Function
  },
  data: function() {
    return {
      env: "",
      aulaToken: null,
      fravaersInfo: [],
      loading: false,
      errorLoading: false,
      cachedData: false,
      cachedTime: this.moment(new Date()),
      collator: new Intl.Collator(undefined, {
        numeric: true,
        sensitivity: "base"
      }),
      specialStatuses: ["Aflyst", "Fri", "Bortfalder", "ArbejderAlene", "Opsyn", "TilKlassen"],
      fravaerDate: new Date()
    };
  },
  methods: {
    async getVikarData(token) {
      for (const id of this.institutionFilter) {
        let indexOf = -1;
        for (let i = 0; i < this.fravaersInfo.length; i++) {
          let skole = this.fravaersInfo[i];
          if (skole.skoleId === id) {
            indexOf = i;
            break;
          }
        }

        if (indexOf !== -1) {
          this.fravaersInfo.splice(indexOf, 1);
        }

        this.fravaersInfo.push({
          skoleId: id,
          loading: true,
          error: false
        });

        this.fetchFravaersData(id, token).then(
          (res) => {
            this.fravaersInfo = this.fravaersInfo.map((info) => {
              if (info.skoleId == id) {
                info.navn = res.institutionNavn;
                info.loading = false;
                info.data = res;
              }
              return info;
            });
          },

          (error) => {
            this.fravaersInfo = this.fravaersInfo.map((info) => {
              if (info.skoleId == id) {
                info.data = null;
                info.loading = false;
                info.error = true;
                info.errorMessage = error.response && error.response.data;

                if (error.request && error.request.status) {
                  if (error.request.status == "500") {
                    info.errorMessage =
                      "Noget gik galt da der skulle hentes informationer på denne institution.";
                  }
                }
              }
              return info;
            });
          }
        );
      }
    },
    fetchFravaersData(skoleKode, token) {
      return new Promise(async (resolve, reject) => {
        try {

          const date = this.moment(this.fravaerDate).toISOString();

          const res = await this.axios.get(
            `https://vikar.tabulex.net/gateway/api/v2/aula/${skoleKode}/fraverInfo?date=${date}`,
            {
              headers: { Authorization: "Bearer " + token }
            }
          );
          resolve(res.data.data);
        } catch (e) {
          reject(e);
        }
      });
    },
    formatDateToHours(date) {
      return this.moment(date)
        .locale("da")
        .format("H:mm");
    },
    backDate() {
      this.fravaerDate = this.addDays(this.fravaerDate, -1);
    },
    forwardDate() {
      this.fravaerDate = this.addDays(this.fravaerDate, 1);
    },
    addDays(date, days) {
      const result = new Date(date);
      result.setDate(result.getDate() + days);
      return result;
    },
    sortAndFilterActivities(activities) {
      if (activities && activities.length > 0) {
        activities.sort((a, b) => {
          if (new Date(a.fraTidspunkt).getTime() === new Date(b.fraTidspunkt).getTime()) {
            if (b.type === "AndenKlasseundervisning") {
              return 1;
            }
            if (a.type === "AndenKlasseundervisning") {
              return -1;
            }
            return 0;
          }

          return new Date(a.fraTidspunkt) - new Date(b.fraTidspunkt);
        });
      }
      return activities.filter((akt) => akt.type !== "Pause");
    },
    containsSpecialStatus(aktivitet) {
      return this.specialStatuses.includes(aktivitet.status);
    },
    formatStatus(status) {
      // Aflyst
      // Fri
      // Bortfalder
      // ArbejderAlene -> Arbejder alene
      // Opsyn
      // TilKlassen -> Til klassen
      if (status === "TilKlassen" || status === "ArbejderAlene") {
        if (status === "TilKlassen") {
          return "Til klassen";
        } else {
          return "Arbejder alene";
        }
      }
      return status;
    }
  },
  computed: {
    displayDate() {
      return this.moment(this.fravaerDate).format("Do MMMM");
    },
    disableNextDay() {
      return this.moment(this.fravaerDate).format("Do MMMM") === "31. juli";
    },
    disablePreviousDay() {
      return this.moment(this.fravaerDate).format("Do MMMM") === "1. august"
        || this.moment(this.fravaerDate).isSame(this.moment(new Date()).subtract(29, 'days'), 'day');
    },
    disableDateSelection() {
      return this.fravaersInfo.some(skole => skole.loading);
    }
  },
  created: function() {
    this.moment.locale('da');
  },
  mounted: function() {
    this.aulaToken = this.getAulaToken();
  },
  watch: {
    fravaerDate: function() {
      this.getVikarData(this.aulaToken);
    },
    aulaToken: function() {
      if (this.aulaToken) {
        this.getVikarData(this.aulaToken);
      }
    },
    institutionFilter: function() {
      if (this.fravaersInfo && this.fravaersInfo.length) {
        this.fravaersInfo = this.fravaersInfo.filter((skole) =>
          this.institutionFilter.find((id) => skole.skoleId === id)
        );
      }

      this.getVikarData(this.aulaToken);
    }
  }
};
</script>

<style scoped>
.fravaer-bottom {
  margin-top: 1px;
  background-color: white;
  padding: 5px;
}
.fravaer-bottom {
  margin-bottom: 5px;
  padding-bottom: 25px;
}
.leftBorderTd {
  min-width: 40px;
  border-left: 1px solid #dee2e6;
}

.loading:after {
  content: " .";
  animation: dots 1s steps(5, end) infinite;
}

h5 {
  font-weight: bold;
}

tbody tr td {
  color: black;
}
tbody tr.changedRow td {
  background-color: #e9eef1;
  color: #222350 !important;
}
tbody tr.changedRow.akuRow td {
  background-color: #d0dfe3;
  color: #222350 !important;
}
tbody tr.changedRow td + td {
  border-left: 1px solid lightgray;
}
tbody tr.changedRow + tr.changedRow td {
  border-top: 1px solid lightgray !important;
}
.table thead th {
  border-bottom: 1px solid #dee2e6;
}
table.table {
  margin-bottom: 0;
}
.nameContainer {
  min-height: 46px;
  width: 100%;
  text-align: center;
  vertical-align: middle;
  line-height: 30px;
  color: #222350;
  font-weight: bold;
}
.nameContainer.period {
  white-space: nowrap;
}
.kort {
  margin-bottom: 15px !important;
}
@keyframes dots {
  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  40% {
    color: #222350;
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  60% {
    text-shadow: 0.25em 0 0 #222350, 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  80%,
  100% {
    text-shadow: 0.25em 0 0 #222350, 0.5em 0 0 #222350;
  }
}

h2,
h6 {
  display: inline;
}

h5 {
  margin-bottom: 0;
}

.card.title-card {
  padding: 0;
}
.card.kort {
  padding: 5px;
}
.top-bar {
  margin-bottom: 5px;
}
.df-datepicker {
  display: grid;
  grid-template-columns: 100px auto 100px;
  width: 100%;
}

.df-date {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.df-date-button button {
  width: 55px;
  height: 30px;
  color: white;
}

.date-forward {
  display: flex;
  direction: rtl;
}
</style>