<template>
  <VacationIntervalsFragmentDayRow class="row">
    <VacationIntervalsFragmentDayCol>
      <p class="font-weight-bold">
        {{ 'CALENDAR_TIMES_FOR_ALL_WEEK_TITLE' | fromTextKey }}
      </p>
      <p class="m-0">
        {{ 'CALENDAR_TIMES_FOR_ALL_WEEK_DESCRIPTION' | fromTextKey }}
      </p>
    </VacationIntervalsFragmentDayCol>
    <VacationIntervalsFragmentDayCol class="d-flex justify-content-end flex-wrap">
      <div class="timepicker-group">
        <label class="timepicker-label" for="allWeekFrom">
          {{ 'RESPONSE_VACATION_COMMING_HOUR' | fromTextKey }}
        </label>
        <VacationIntervalsFragmentTimepicker
          id="allWeekFrom"
          :value="entryTime"
          :start="startTime"
          :end="endTime"
          :disabled="disabled"
          @change="onUpdateEntryTime"
        />
      </div>
      <div class="timepicker-group">
        <label class="timepicker-label" for="allWeekTo">
          {{ 'RESPONSE_VACATION_NOT_COMMING_HOUR' | fromTextKey }}
        </label>
        <VacationIntervalsFragmentTimepicker
          id="allWeekTo"
          :value="exitTime"
          :start="startTime"
          :end="endTime"
          :disabled="disabled"
          @change="onUpdateExitTime"
        />
      </div>
    </VacationIntervalsFragmentDayCol>
    <VacationIntervalsFragmentDayCol />
    <aula-modal
      ref="entryTimesExistsWarning"
      @cancelClicked="onCancelEntryTimesExistWarning()"
      @okClicked="onOkEntryTimesExistWarning()"
    >
      {{ 'PRESENCE_WARNING_FILL_OUT_TIMES_FOR_ENTIRE_WEEK' | fromTextKey }}
    </aula-modal>
    <aula-modal
      ref="exitTimesExistsWarning"
      @cancelClicked="onCancelExitTimesExistWarning()"
      @okClicked="onOkExitTimesExistWarning()"
    >
      {{ 'PRESENCE_WARNING_FILL_OUT_TIMES_FOR_ENTIRE_WEEK' | fromTextKey }}
    </aula-modal>
  </VacationIntervalsFragmentDayRow>
</template>
<script>
import VacationIntervalsFragmentDayRow from './VacationIntervalsFragmentDayRow';
import VacationIntervalsFragmentDayCol from './VacationIntervalsFragmentDayCol';
import VacationIntervalsFragmentTimepicker from './VacationIntervalsFragmentTimepicker';
import { comeGoUtil } from '../../utils/comeGoUtil';

export default {
  name: 'VacationIntervalsFragmentComeAllWeek',
  components: { VacationIntervalsFragmentTimepicker, VacationIntervalsFragmentDayCol, VacationIntervalsFragmentDayRow },
  props: {
    vacationRequestDays: {
      required: true,
      type: Array,
    },
    disabled: {
      required: false,
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      entryTime: '',
      exitTime: '',
      startTime: '',
      endTime: '',
      oldEntryTime: '',
      oldExitTime: '',
      newEntryTime: '',
      newExitTime: '',
    };
  },
  watch: {
    vacationRequestDays: {
      deep: true,
      immediate: true,
      handler() {
        this.entryTime = comeGoUtil.getAlignedEntryTimeFromVacationRequestDays(this.vacationRequestDays);
        this.exitTime = comeGoUtil.getAlignedExitTimeFromVacationRequestDays(this.vacationRequestDays);
        this.startTime = comeGoUtil.getLowestStartTimeFromVacationRequestDays(this.vacationRequestDays);
        this.endTime = comeGoUtil.getHighestEndTimeFromVacationRequestDays(this.vacationRequestDays);
      },
    },
  },
  methods: {
    onUpdateExitTime(value) {
      const displayTime = value.displayTime;
      const oldExitTime = this.exitTime;
      this.exitTime = displayTime;
      if (displayTime === '' || displayTime === oldExitTime) {
        this.exitTime = oldExitTime;
        return;
      }

      this.newExitTime = displayTime;
      if (comeGoUtil.hasSpecifiedExitTimeForVacationDay(this.vacationRequestDays)) {
        this.oldExitTime = oldExitTime;
        this.$refs.exitTimesExistsWarning.show();
      } else {
        this.updateExitTimes();
      }
    },
    onUpdateEntryTime(value) {
      const displayTime = value.displayTime;
      const oldEntryTime = this.entryTime;
      this.entryTime = displayTime;
      if (displayTime === '' || displayTime === oldEntryTime) {
        this.entryTime = oldEntryTime;
        return;
      }

      this.newEntryTime = displayTime;
      if (comeGoUtil.hasSpecifiedEntryTimeForVacationDay(this.vacationRequestDays)) {
        this.oldEntryTime = oldEntryTime;
        this.$refs.entryTimesExistsWarning.show();
      } else {
        this.updateEntryTimes();
      }
    },
    onCancelEntryTimesExistWarning() {
      this.entryTime = this.oldEntryTime;
      this.oldEntryTime = '';
      this.newEntryTime = '';
      this.$refs.entryTimesExistsWarning.hide();
    },
    onOkEntryTimesExistWarning() {
      if (this.newEntryTime !== '') {
        this.updateEntryTimes();
      }

      this.oldEntryTime = '';
      this.newEntryTime = '';
      this.$refs.entryTimesExistsWarning.hide();
    },
    onCancelExitTimesExistWarning() {
      this.exitTime = this.oldExitTime;
      this.oldExitTime = '';
      this.newExitTime = '';
      this.$refs.exitTimesExistsWarning.hide();
    },
    onOkExitTimesExistWarning() {
      this.updateExitTimes();
      this.oldExitTime = '';
      this.newExitTime = '';
      this.$refs.exitTimesExistsWarning.hide();
    },
    updateExitTimes() {
      comeGoUtil.updateExitTimeForVacationRequestDays(this.vacationRequestDays, this.newExitTime);
    },
    updateEntryTimes() {
      comeGoUtil.updateEntryTimeForVacationRequestDays(this.vacationRequestDays, this.newEntryTime);
    },
  },
};
</script>
<style lang="scss" scoped>
.row {
  --row-padding-top: 0;
  --row-padding-bottom: 0;
  --row-padding-start: 0;
  --row-padding-end: 0;
  background-color: var(--background-color, var(--color-grey-base));

  .timepicker-group {
    margin: 0 5px;

    label.timepicker-label[for] {
      margin-top: 0;
      font-weight: normal;
      text-transform: none;
    }
  }
}
</style>
