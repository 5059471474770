import { WidgetDtoModel } from '../../shared/models/widgetDto.model';
import { WidgetTokenModel } from '../../shared/models/widgetToken.model';
import { widgetDtoTypes } from '../../shared/enums/widgetDtoTypes';

export class WidgetTokenDto implements WidgetDtoModel<WidgetTokenModel> {
  data: WidgetTokenModel;
  type: widgetDtoTypes = widgetDtoTypes.TOKEN;

  constructor(data: WidgetTokenModel) {
    this.data = data;
  }
}
