/* creation_of_absence 20230321.1 PulsProd */
<template>
  <b-container class="root">
    <b-row>
      <b-col>
        <!-- Header -->
        <widget-header widget-name="Indberet fravær" />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <!-- Progress -->
        <progress-indicator :view-model="progress" />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <!-- Errors -->
        <error-indicator :view-model="errors" />
      </b-col>
    </b-row>

    <b-row v-if="placement === 'NoticeBoard'">
      <b-col>Denne widget kan ikke placeres på dashboards</b-col>
    </b-row>

    <template v-else-if="progress.empty && !errors.has('schools')">
      <b-row v-if="schools.length === 0">
        <b-col>
          <!-- No schools -->
          Der er ingen skoler som du har adgang til.
        </b-col>
      </b-row>
      <template v-else>
        <b-row>
          <b-col>
            <!-- Alert for successful save -->
            <b-alert
              v-model="successfulSaveCountdown"
              variant="success"
              fade
              dismissible
              dismiss-label="Luk"
            >
              Fraværet blev gemt
            </b-alert>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            v-if="schools.length > 1"
            :cols="placement === 'narrow' ? 12 : 4"
          >
            <!-- School selection -->
            <single-selection-input
              id="kmd-educa-personale-creation-of-absence-school-select"
              label="Skoler"
              :view-model="school"
            >
            </single-selection-input>
          </b-col>
          <b-col
            v-if="school.value && school.value.schoolYears.length > 1"
            :cols="placement === 'narrow' ? 12 : 2"
          >
            <!-- School year selection -->
            <single-selection-input
              id="kmd-educa-personale-creation-of-absence-school-year-select"
              label="Skoleår"
              :view-model="schoolYear"
            >
            </single-selection-input>
          </b-col>
          <b-col>
            <!-- Reason selection -->
            <validated-single-selection-input
              id="kmd-educa-personale-creation-of-absence-reason-input"
              label="Fraværsårsag"
              :view-model="absence.reasonId"
            >
            </validated-single-selection-input>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <!-- Description input -->
            <validated-value-input
              id="kmd-educa-personale-creation-of-absence-description-input"
              label="Beskrivelse"
              :view-model="absence.description"
            >
            </validated-value-input>
          </b-col>
          <b-col>
            <!-- Start date selection -->
            <validated-date-input
              id="kmd-educa-personale-creation-of-absence-startdate-select"
              label="Start dato"
              :view-model="absence.startDate"
            >
            </validated-date-input>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <!-- Use time period selection -->
            <b-form-checkbox
              id="kmd-educa-personale-creation-of-absence-usetimeperiod-input"
              v-model="absence.useTimePeriod"
            >
              Indstil start- og sluttider
            </b-form-checkbox>
          </b-col>
        </b-row>
        <b-row v-if="absence.useTimePeriod">
          <b-col>
            <!-- Start time selection -->
            <validated-time-input
              id="kmd-educa-personale-creation-of-absence-starttime-input"
              label="Starttid"
              :view-model="absence.timePeriod.start"
            />
          </b-col>
          <b-col>
            <!-- End time selection -->
            <validated-time-input
              id="kmd-educa-personale-creation-of-absence-endtime-input"
              label="Sluttid"
              :view-model="absence.timePeriod.end"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <!-- Show in Aula and Web input -->
            <b-form-checkbox
              id="kmd-educa-personale-creation-of-absence-showinaulaandweb-input"
              v-model="absence.showInAulaAndWeb"
            >
              Vis på web og i Aula
              <!-- More information -->
              <span
                v-b-tooltip
                tabindex="0"
                title="Betyder at dine lektioner vil blive vist på kmd.puls.dk web udgaven og i Aula widgeten - Fraværsoverblikket. Beskrivelse, tid fra og til vises ikke de pågældende steder."
                class="fa fa-question-circle"
              >
              </span>
            </b-form-checkbox>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <!-- Save button -->
            <b-button @click="save()">Gem</b-button>
          </b-col>
        </b-row>
      </template>
    </template>
  </b-container>
</template>
<script>
/**
 * MODULE shared\widgetHeader.js
 **/
const _module_shared_widgetHeader = (function shared_widgetHeader() {
  let _module_exports = {};

  // @vue/component
  const WidgetHeader = {
    name: "WidgetHeader",
    props: {
      widgetName: {
        type: String,
        required: true
      }
    },
    render(h) {
      var _this$widgetName;
      return h("div", [h("div", {
        class: "widget-header d-flex align-items-end"
      }, [h("h2", [(_this$widgetName = this.widgetName) === null || _this$widgetName === void 0 ? void 0 : _this$widgetName.toString()]), h("p", {
        class: "ml-auto"
      }, ["Educa Personale"])]), h("div", {
        class: "widget-header-separator"
      }, [])]);
    }
  };
  _module_exports = {
    WidgetHeader
  };
  return _module_exports;
})();

/**
 * MODULE shared\time.js
 **/
const _module_shared_time = (function shared_time() {
  let _module_exports = {};

  /**
   * Represent a time point during a day with minute accuracy.
   * @typedef {Object} MinuteTime
   * @property {Number} hour The hour part of the time point.
   * @property {Number} minute The minute part of the time point.
   */
  
  /**
   * Represent a time duration with minute accuracy.
   * @typedef {Object} MinuteDuration
   * @property {Number} totalMinutes The total amount of minutes within the duration.
   */
  
  function makePoint(hour, minute) {
    if (hour > 23 || hour < 0) {
      throw "Hour must be between 0 and 23";
    }
    if (minute > 59 || minute < 0) {
      throw "Minute must be between 0 and 59";
    }
    hour = Math.floor(hour);
    minute = Math.floor(minute);
    return {
      hour: hour,
      minute: minute,
      addHours(hours) {
        const newHour = hour + hours;
        if (newHour > 23) {
          return makePoint(23, 59);
        }
        if (newHour < 0) {
          return makePoint(0, 0);
        }
        return makePoint(newHour, minute);
      },
      addDuration(duration) {
        const newMinutes = hour * 60 + minute + duration.totalMinutes;
        if (newMinutes < 0) {
          return makePoint(0, 0);
        }
        if (newMinutes >= 24 * 60) {
          return makePoint(23, 59);
        }
        return makePoint(Math.floor(newMinutes / 60), newMinutes % 60);
      },
      addMinutes(minutes) {
        return this.addDuration(makeDuration(minutes));
      },
      compare(other) {
        if (this.isBefore(other)) {
          return -1;
        }
        if (this.isSame(other)) {
          return 0;
        }
        return 1;
      },
      durationTo(other) {
        if (this.isAfter(other)) {
          throw "The other time point must be after or same as this time point";
        }
        if (other.hour === hour) {
          return makeDuration(other.minute - minute);
        }
        return makeDuration((other.hour - hour - 1) * 60 + (60 - minute) + other.minute);
      },
      /**
       * Formats the time point to a string.
       * Supported formats are "input".
       * The "input" format results in a string understood by input elements of type "time" ('hh:mm').
       * @param {string} format A format specification to use. The only supported value is "input".
       * @returns A string representation of the time point, according to specified format.
       * @throws {Error} Throws when format argument is none of the supported values.
       */
      format(format) {
        switch (format) {
          case "input":
            // Format used with time input elements and in json, which both expect a "00:00" format
            return padded(hour) + ":" + padded(minute);
          default:
            throw new Error('Unknown time point format "' + format + '"');
        }
      },
      isAfter(other) {
        return other.hour < hour || other.hour === hour && other.minute < minute;
      },
      isBefore(other) {
        return other.hour > hour || other.hour === hour && other.minute > minute;
      },
      isSame(other) {
        return other.hour === hour && other.minute === minute;
      },
      startOfHour() {
        return makePoint(hour, 0);
      },
      toJSON() {
        return padded(hour) + ":" + padded(minute);
      },
      toString() {
        return hour.toString() + ":" + padded(minute);
      }
    };
  }
  
  /**
   * Returns a minute duration from number of minutes.
   * @param {Number} minutes The number of minutes.
   * @returns {MinuteDuration} A duration that consists of the number of minutes.
   */
  function makeDuration(minutes) {
    return {
      totalMinutes: minutes,
      isSame(other) {
        return minutes === other.totalMinutes;
      },
      toString(format) {
        const hour = Math.floor(minutes / 60);
        const minute = minutes % 60;
        switch (format) {
          case "human":
            {
              const hourLabel = hour == 1 ? "time" : "timer";
              const minuteLabel = minute == 1 ? "minut" : "minutter";
              const hourPart = hour + " " + hourLabel;
              const minutePart = minute + " " + minuteLabel;
              return (hour > 0 || minute == 0 ? hourPart : "") + (minute > 0 ? " " + minutePart : "");
            }
          default:
            return hour + ":" + padded(minute);
        }
      }
    };
  }
  function padded(value) {
    return value < 10 ? "0" + value.toString() : value.toString();
  }
  
  /**
   * Parses a string and return a minute duration.
   * Supported format of strings is "h:mm" (more than one h digit is allowed).
   * Leading or trailing whitespace is not ignored, and needs to be trimmed.
   * @param {string} value The string to be parsed.
   * @returns {?MinuteDuration} A minute time duration if parsing was successful, null otherwise.
   * @throws {string} Throws when parsed hour or minute values are out of bounds.
   */
  function parseDuration(value) {
    const result = value.match(/(\d+):(\d\d)/u);
    if (result && result[0] === value) {
      const [, hoursPart, minutePart] = result;
      const minute = Number.parseInt(minutePart, 10);
      if (minute > 59 || minute < 0) {
        throw "Minute must be between 0 and 59";
      }
      const hours = Number.parseInt(hoursPart, 10);
      if (hours < 0) {
        throw "Hours must be greater than or equal to 0";
      }
      return makeDuration(hours * 60 + minute);
    } else {
      return null;
    }
  }
  
  /**
   * Parses a string and return a time point.
   * Supported formats of strings are "hh:mm" and "h:mm".
   * Leading or trailing whitespace is not ignored, and needs to be trimmed.
   * @param {string} value The string to be parsed.
   * @returns {?MinuteTime} A time point if parsing was successful, null otherwise.
   * @throws {string} Throws when parsed hour or minute values are out of bounds.
   */
  function parsePoint(value) {
    const result = value.match(/(\d?\d):(\d\d)/u);
    if (result && result[0] === value) {
      const [, hour, minute] = result;
      return makePoint(Number.parseInt(hour, 10), Number.parseInt(minute, 10));
    } else {
      return null;
    }
  }
  _module_exports = {
    parsePoint,
    point: makePoint,
    duration: makeDuration,
    parseDuration
  };
  return _module_exports;
})();

/**
 * MODULE shared\singleSelection.js
 **/
const _module_shared_singleSelection = (function shared_singleSelection() {
  let _module_exports = {};

  /**
   * Represents an item available for selection.
   * @typedef {Object} Item
   * @property {string} [text] Description of the item, shown to the user. Required when html is not set.
   * @property {string} [html] Html used to show the item to the user. Required when text is not set.
   * Use with caution as contents are not HTML escaped.
   * @property {Boolean} [disabled=false] Determines whether the item can be selected by the user.
   * @property {*} value Value of the item, used to identify the item and passed to selection change callback. Cannot be undefined.
   */
  
  /**
   * A function called when selected item changes or items are updated.
   * @callback SelectionChangeCallback
   * @param {*} value Value of the selected item.
   * @param {Boolean} isResetOrUpdate Indicates whether the callback was called in response to update of items, or reset of desired value.
   */
  
  /**
   * Represents options that specify behavior of single selection.
   * @typedef {Options} SingleSelectionOptions
   * @property {?SelectionChangeCallback} [change] A function that is called when selected value changes or items are updated.
   * @property {Boolean} [preselectFirst=false] Determines whether value of first non-disabled item is selected,
   * when "desired" selected value is undefined.
   * @property {*} [initial=undefined] Determines the initial "desired" selected value.
   * @property {Item} fallback An item that is selected when no item is selected or available,
   * or an item with selected value is not available.
   */
  
  const ActualValueSource = {
    desiredValue: 0,
    fallbackValue: 1,
    firstValue: 2
  };
  const ActualItemsSource = {
    desiredItems: 0,
    desiredItemsWithFallback: 1
  };
  function sourcesOfActualItemsAndValue(desiredItems, desiredValue, preselectFirst) {
    if (desiredItems.length === 0) {
      return {
        items: ActualItemsSource.desiredItemsWithFallback,
        value: ActualValueSource.fallbackValue
      };
    } else if (desiredValue === undefined || !desiredItems.some(x => !x.disabled && x.value === desiredValue)) {
      if (preselectFirst) {
        const first = desiredItems.find(x => !x.disabled);
        return first !== undefined ? {
          items: ActualItemsSource.desiredItems,
          value: ActualValueSource.firstValue
        } : {
          items: ActualItemsSource.desiredItemsWithFallback,
          value: ActualValueSource.fallbackValue
        };
      } else {
        return {
          items: ActualItemsSource.desiredItemsWithFallback,
          value: ActualValueSource.fallbackValue
        };
      }
    } else {
      return {
        items: ActualItemsSource.desiredItems,
        value: ActualValueSource.desiredValue
      };
    }
  }
  
  /**
   * Represents a single selection of an value from among available values, represented by items.
   * There are two kinds of selected value tracked at the same time: an "actual" one and a "desired" one.
   * This is done to ensure that:
   *  - there is always an items selected, and
   *  - that the selected value is independent of available items, and preserved when they change.
   * The "desired" selected value is what is controlled by the user and code.
   * The "actual" selected value is computed, and updates whenever available items or the "desired" selected value change.
   * It is the same as the "desired" selected value when:
   *  - the "desired" selected value is not undefined,
   *  - there is an available item with the value of the "desired" selected value, and
   *  - that item is selected.
   * Otherwise, the "actual" selected value, if "preselect first" option is set,
   * is equal to the value of first non-disabled item.
   * If such item is not available or the "preselect first" option is not set, the value of the "fallback" item is used.
   * Whenever the "actual" selected value changes, or items are updated,
   * the change callback is called with that value as an argument.
   * @summary Represents a selection of a single value from a list of predefined values.
   * @param {SingleSelectionOptions} options The options
   */
  function SingleSelection(options) {
    if (!options || !options.fallback) {
      throw new Error('Fallback value is not set in "options", or "options" is not an object.');
    }
    this._actualItems = [options.fallback];
    this._actualValue = options.fallback.value;
    this._desiredItems = [];
    this._desiredValue = options.initial;
    this._options = {
      change: options.change || (() => {}),
      preselectFirst: !!options.preselectFirst,
      fallback: options.fallback
    };
  }
  SingleSelection.prototype = {
    /**
     * The "actual" selected value.
     * @member {*}
     */
    get value() {
      return this._actualValue;
    },
    /**
     * The items available for selection.
     * @member {Item[]}
     */
    get items() {
      return this._actualItems;
    },
    /**
     * Changes the "desired" selected value.
     * If the change results in the change of "actual" selected value, the change callback is queued for execution.
     * @param {*} newValue The new value.
     */
    select(newValue) {
      this._desiredValue = newValue;
      this._synchronize(false);
    },
    /**
     * Resets the "desired" selected value.
     * The change callback is always queued for execution, as if update was called.
     * @param {*} newValue The new value.
     */
    reset(newValue) {
      this._desiredValue = newValue;
      this._synchronize(true);
    },
    /**
     * Changes available items.
     * Each item should have a value different from that of other items, also from the "fallback" item.
     * The change callback is always queued for execution.
     * @param {Array.<Item>} items The new available items.
     */
    update(items) {
      this._desiredItems = items.filter(x => x.value !== undefined);
      this._synchronize(true);
    },
    _synchronize(isUpdate) {
      const oldActualValue = this._actualValue;
      const {
        value: newActualValueSource,
        items: newActualItemsSource
      } = sourcesOfActualItemsAndValue(this._desiredItems, this._desiredValue, this._options.preselectFirst);
      const newActualValue = newActualValueSource === ActualValueSource.desiredValue ? this._desiredValue : newActualValueSource === ActualValueSource.fallbackValue ? this._options.fallback.value : newActualValueSource === ActualValueSource.firstValue ? this._desiredItems.find(x => !x.disabled).value : undefined;
      const newActualItems = newActualItemsSource === ActualItemsSource.desiredItems ? this._desiredItems : newActualItemsSource === ActualItemsSource.desiredItemsWithFallback ? [this._options.fallback, ...this._desiredItems] : [];
      this._actualValue = newActualValue;
      this._actualItems = newActualItems;
      if (isUpdate || oldActualValue !== newActualValue) {
        setTimeout(() => this._options.change(newActualValue, isUpdate));
      }
    }
  };
  _module_exports = {
    SingleSelection
  };
  return _module_exports;
})();

/**
 * MODULE shared\result.js
 **/
const _module_shared_result = (function shared_result() {
  let _module_exports = {};

  /**
   * Represents a value that is a result of a computation. Either an error or a computed value.
   * @constructor
   * @param {*} value The value representing the result of a computation.
   * @param {boolean} [isSuccess=true] Indicates the type of value. When truthy, the value represents a computed value, otherwise an error.
   */
  function Result(value, isSuccess) {
    isSuccess = isSuccess === undefined ? true : !!isSuccess;
    this.isSuccess = isSuccess;
    this.value = isSuccess ? value : undefined;
    this.error = isSuccess ? undefined : value;
  }
  
  /**
   * A callback that takes in a value and returns a new one.
   * @callback MapCallback
   * @param {*} value
   * @returns {(*|Result)}
   **/
  
  /**
   * Applies the value to one of two functions, and returns the resulting value.
   * One function is used when the value is a computed value, the other when it is an error.
   * If the function returns a value that is not a Result, it is wrapped in one, as a computed value.
   * Errors thrown by the function are not handled in any way.
   * If a function is not provided and needs to be used, the result itself is returned.
   * @summary Returns result of applying the value to a function, chosen depending on type of the value.
   * @param {?MapCallback} successFn A function called when the value is a computed value.
   * @param {?MapCallback} [errorFn] A function called when the value is an error.
   * @returns {Result} Result of the called function, wrapped in a Result if it was not a Result.
   */
  Result.prototype.then = function (successFn, errorFn) {
    if (this.isSuccess) {
      return successFn ? Result.from(successFn(this.value)) : this;
    } else {
      return errorFn ? Result.from(errorFn(this.error)) : this;
    }
  };
  
  /**
   * When the value is an error, applies it to a function, and returns the resulting value.
   * Otherwise returns the result itself.
   * Errors thrown by the function are not handled in any way.
   * If a function is not provided and needs to be used, the result itself is returned.
   * @summary Returns result of applying the value to a function, if it is an error, otherwise the result itself.
   * @param {?MapCallback} [errorFn] A function called when result is an error.
   * @returns {Result} Result of the called function, wrapped in a Result if it was not one.
   */
  Result.prototype.catch = function (errorFn) {
    return this.then(undefined, errorFn);
  };
  
  /**
   * Wraps a value in a Result, as a computed value.
   * @param {(*|Result)} value The value to be wrapped in a Result.
   * @returns {Result} A Result containing the value, as a computed value.
   */
  Result.success = function (value) {
    return new Result(value, true);
  };
  
  /**
   * Wraps a value in a Result, as an error.
   * @param {(*|Result)} value The value to be wrapped in a Result.
   * @returns {Result} A Result containing the value, as an error.
   */
  Result.error = function (value) {
    return new Result(value, false);
  };
  
  /**
   * Wraps a value in a Result, as a computed value. Result values are not wrapped.
   * @param {(*|Result)} value The value to be wrapped in a Result.
   * @returns {Result} A Result containing the value, as a computed value. The value itself if it is a Result.
   */
  Result.from = function (value) {
    if (value instanceof Result) {
      return value;
    } else {
      return Result.success(value);
    }
  };
  _module_exports = {
    Result
  };
  return _module_exports;
})();

/**
 * MODULE shared\validatedValue.js
 **/
const _module_shared_validatedValue = (function shared_validatedValue(
  _module_shared_result
) {
  let _module_exports = {};

  const {
    Result
  } = _module_shared_result;
  
  /**
   * A callback called when text value changes.
   * @callback ChangeCallback
   * @param {Result} value A Result object, which in successful state contains the parsed and valid value,
   * and in error state contains a validation/parse error.
   * @param {boolean} isReset Indicates whether the change is a result of a reset call (as opposed to set call).
   */
  
  /**
   * A callback used to validate and parse a test (string) value.
   * @callback ValidateCallback
   * @param {string} value A value to be parsed and validated.
   * @returns {Result} A Result object, which in successful state contains the parsed and valid value,
   * and in error state contains a validation/parse error.
   */
  
  /**
   * Represents options used when creating instance of ValidatedValue.
   * @typedef {Object} Options
   * @property {?string} [initialTextValue=""] Initial text value.
   * @property {?ChangeCallback} [change=null] A function that is called with the validated value, when it changes.
   * @property {?ValidateCallback} [validate=null] A function that returns a validated value, parsed from the given text value.
   * If not provided, a substitute callback is used which wraps the text value in a Result of successful type.
   * @property {?boolean} [isAvailable=false] Indicates whether the validated value is initially available.
   */
  
  /**
   * Represents a validated value parsed from a string (a text value).
   * Initially, the validated value can be made inaccessible, i.e. is not available.
   * This availability status can be toggled through the use of instance methods.
   * @summary Represents a validated value parsed from a string (a text value).
   * @param {Options} options The options
   */
  function ValidatedValue(options) {
    this._isAvailable = !!options.isAvailable;
    this._initialTextValue = options.initialTextValue || "";
    this._change = options.change || (() => {});
    this._validate = options.validate || (x => Result.from(x));
    /**
     * The text value.
     * Setting a value does not make the validated value available.
     * @member {string}
     */
    this.textValue = this._initialTextValue;
  }
  ValidatedValue.prototype = {
    /**
     * Returns the validated and parsed value if it is available.
     * @returns {?Result} A Result object, which in successful state contains the validated value,
     * and in error state contains a validation/parse error.
     * Null if the value is not yet available.
     */
    get availableValue() {
      return this._isAvailable ? this.value : null;
    },
    /**
     * Returns the validated and parsed value.
     * @returns {?Result} A Result object, which in successful state contains the validated value,
     * and in error state contains a validation/parse error.
     */
    get value() {
      return this._validate(this.textValue);
    },
    /**
     * Sets new text value and makes the validated value available.
     * @param {string} textValue The text value to set.
     */
    set(textValue) {
      this._isAvailable = true;
      this.textValue = textValue;
      this._change(this.value, false);
    },
    /**
     * Sets new text value and makes the validated value no longer available.
     * @param {?string} [textValue=undefined] The text value to set.
     * If undefined, the text value is set to the initial text value.
     */
    reset(textValue) {
      this._isAvailable = false;
      this.textValue = textValue === undefined ? this._initialTextValue : textValue;
      this._change(this.value, true);
    },
    /**
     * Makes the validated value available.
     * @returns {Result} A Result object, which in successful state contains the validated value,
     * and in error state contains a validation/parse error.
     */
    validate() {
      this._isAvailable = true;
      return this.value;
    }
  };
  _module_exports = {
    ValidatedValue
  };
  return _module_exports;
})(_module_shared_result);

/**
 * MODULE shared\validatedTime.js
 **/
const _module_shared_validatedTime = (function shared_validatedTime(
  _module_shared_result,
  _module_shared_time,
  _module_shared_validatedValue
) {
  let _module_exports = {};

  const time = _module_shared_time;
  const {
    Result
  } = _module_shared_result;
  const {
    ValidatedValue
  } = _module_shared_validatedValue;
  
  /** @typedef {import('./time').MinuteTime} MinuteTime */
  
  /**
   * A callback called when time changes.
   * @callback ChangeCallback
   * @param {Result} value A Result object, which in successful state contains the time,
   * and in error state contains a validation/parse error.
   * @param {boolean} isReset Indicates whether the change is a result of a reset call (as opposed to set call).
   */
  
  /**
   * A callback used to validate time.
   * @callback ValidateCallback
   * @param {MinuteTime} value The time to be validated.
   * @returns {Result} A Result object, which in successful state contains the time,
   * and in error state contains a validation error.
   */
  
  /**
   * Represents options used when creating instance of ValidatedTime.
   * @typedef {Object} Options
   * @property {?MinuteTime} [initialValue=""] Initial value of the time.
   * @property {?ChangeCallback} [change=null] A function that is called with the time, when it changes.
   * @property {?boolean} [isAvailable=false] Indicates whether the time is initially available.
   * @property {?ValidateCallback} [validate=null] A function that returns a valid time, given parsed time.
   */
  
  /**
   * Represents a time value parsed from a string (text value).
   * @param {Options} options The options.
   */
  function ValidatedTime(options) {
    var _options$initialValue, _options$change;
    this.input = new ValidatedValue({
      change: (value, isReset) => this._change(value, isReset),
      initialTextValue: (_options$initialValue = options.initialValue) === null || _options$initialValue === void 0 ? void 0 : _options$initialValue.format("input"),
      isAvailable: options.isAvailable,
      validate: value => validTime(value).then(validTime => {
        var _options$validate, _options$validate2;
        return (_options$validate = (_options$validate2 = options.validate) === null || _options$validate2 === void 0 ? void 0 : _options$validate2.call(options, validTime)) !== null && _options$validate !== void 0 ? _options$validate : validTime;
      })
    });
    this._change = (_options$change = options.change) !== null && _options$change !== void 0 ? _options$change : () => {};
  }
  ValidatedTime.prototype = {
    /**
     * Returns the time, if it is available.
     * @returns {?Result} A Result object, which in successful state contains the time,
     * and in error state contains a validation/parse error.
     * Null if the time is not yet available.
     */
    get availableValue() {
      return this.input.availableValue;
    },
    get textValue() {
      return this.input.textValue;
    },
    set textValue(value) {
      this.input.textValue = value;
    },
    /**
     * Returns the time.
     * @returns {?Result} A Result object, which in successful state contains the time,
     * and in error state contains a validation/parse error.
     */
    get value() {
      return this.input.value;
    },
    /**
     * Sets new time and makes the time available.
     * @param {?MinuteTime} value The time to set.
     * If undefined, the bounds period is left unchanged.
     */
    set(value) {
      this.input.set(value === null || value === void 0 ? void 0 : value.format("input"));
    },
    /**
     * Sets new time and makes the time no longer available.
     * @param {?Date} [value=undefined] The time to set.
     * If undefined, the time is set to the initial time.
     */
    reset(value) {
      var _value$format;
      this.input.reset(value !== undefined ? (_value$format = value === null || value === void 0 ? void 0 : value.format("input")) !== null && _value$format !== void 0 ? _value$format : null : undefined);
    },
    /**
     * Makes the time available.
     * @returns {Result} A Result object, which in successful state contains the time,
     * and in error state contains a validation/parse error.
     */
    validate() {
      return this.input.validate();
    }
  };
  function validTime(value) {
    const trimmed = (value || "").trim();
    if (trimmed.length == 0) {
      return Result.error("Vælg et tidspunkt");
    }
    const result = time.parsePoint(trimmed);
    if (!result) {
      return Result.error("Vælg et tidspunkt");
    }
    return Result.success(result);
  }
  _module_exports = {
    ValidatedTime,
    validTime
  };
  return _module_exports;
})(_module_shared_result,
  _module_shared_time,
  _module_shared_validatedValue);

/**
 * MODULE shared\validatedSingleSelection.js
 **/
const _module_shared_validatedSingleSelection = (function shared_validatedSingleSelection(
  _module_shared_result,
  _module_shared_singleSelection
) {
  let _module_exports = {};

  const {
    Result
  } = _module_shared_result;
  const {
    SingleSelection
  } = _module_shared_singleSelection;
  
  /** @typedef {import('./singleSelection').Item} Item */
  
  /**
   * A function called when selected item changes or items are updated.
   * @callback ChangeCallback
   * @param {Result} value A Result object, which in successful state contains the valid value,
   * and in error state contains a validation error.
   * @param {Boolean} isUpdate Indicates whether the change is a result of an update call (as opposed to set or reset call).
   */
  
  /**
   * A callback used to validate selected value.
   * @callback ValidateCallback
   * @param {*} value The selected value to be validated.
   * @returns {Result} A Result object, which in successful state contains the selected value,
   * and in error state contains a validation error.
   */
  
  /**
   * Represents options used when creating instance of ValidatedSingleSelection.
   * @typedef {Object} Options
   * @property {?ChangeCallback} [change=null] A function that is called when selected value changes, is set/reset, or items are updated.
   * @property {?boolean} [isAvailable=false] Indicates whether the selected value is initially available.
   * @property {?ValidateCallback} [validate=null] A function that returns a valid value, given the selected value.
   * @property {Boolean} [preselectFirst=false] Determines whether value of first non-disabled item is selected,
   * when "desired" selected value is undefined.
   * @property {*} [initial=undefined] Determines the initial "desired" selected value.
   * @property {Item} fallback An item that is selected when no item is selected or available,
   * or an item with the selected value is not available.
   */
  
  /**
   * Represents a single selection of a value from among available values, represented by items.
   * Initially, the validated value can be made inaccessible, i.e. is not available.
   * This availability status can be toggled through the use of instance methods.
   * @summary Represents a validated value selected from among available items.
   * @param {Options} options The options.
   */
  function ValidatedSingleSelection(options) {
    this.input = new SingleSelection({
      initial: options.initial,
      fallback: options.fallback,
      preselectFirst: options.preselectFirst,
      change: (value, isUpdate) => {
        var _options$change;
        if (!isUpdate) {
          this._isAvailable = true;
        }
        (_options$change = options.change) === null || _options$change === void 0 ? void 0 : _options$change.call(options, this._validate(value), isUpdate);
      }
    });
    this._isAvailable = !!options.isAvailable;
    this._isReset = false;
    this._validate = value => {
      var _options$validate, _options$validate2;
      if (value === undefined || options.fallback !== undefined && value === options.fallback.value) {
        return Result.error("Vælg en værdi");
      }
      return (_options$validate = (_options$validate2 = options.validate) === null || _options$validate2 === void 0 ? void 0 : _options$validate2.call(options, value)) !== null && _options$validate !== void 0 ? _options$validate : Result.from(value);
    };
  }
  ValidatedSingleSelection.prototype = {
    /**
     * Returns the validated value if it is available.
     * @returns {?Result} A Result object, which in successful state contains the validated value,
     * and in error state contains a validation error.
     * Null if the value is not yet available.
     */
    get availableValue() {
      return this._isAvailable ? this.value : null;
    },
    /**
     * Returns the validated value.
     * @returns {?Result} A Result object, which in successful state contains the validated value,
     * and in error state contains a validation error.
     */
    get value() {
      return this._validate(this.input.value);
    },
    /**
     * Sets new value and makes the validated value available.
     * @param {string} value The value to set.
     */
    set(value) {
      this._isAvailable = true;
      this.input.select(value);
    },
    /**
     * Sets new value and makes the validated value no longer available.
     * @param {?string} [value=undefined] The value to set.
     * If undefined, the value is set to the initial value.
     */
    reset(value) {
      this._isAvailable = false;
      this.input.reset(value);
    },
    /**
     * Changes available items.
     * Each item should have a value different from that of other items, also from the "fallback" item.
     * The change callback is always queued for execution.
     * @param {Array.<Item>} items The new available items.
     */
    update(items) {
      this.input.update(items);
    },
    /**
     * Makes the validated value available.
     * @returns {Result} A Result object, which in successful state contains the validated value,
     * and in error state contains a validation/parse error.
     */
    validate() {
      this._isAvailable = true;
      return this.value;
    }
  };
  _module_exports = {
    ValidatedSingleSelection
  };
  return _module_exports;
})(_module_shared_result,
  _module_shared_singleSelection);

/**
 * MODULE shared\prelude.js
 **/
const _module_shared_prelude = (function shared_prelude() {
  let _module_exports = {};

  /** @constructor */
  function Canceled() {}
  Canceled.prototype.toString = function () {
    return "Canceled";
  };
  const IgnoreCanceledMixin = {
    errorCaptured(error) {
      if (isCanceled(error)) {
        return false;
      }
      return true;
    }
  };
  function areArraysEqual(first, second, elementsEqual) {
    var _elementsEqual;
    elementsEqual = (_elementsEqual = elementsEqual) !== null && _elementsEqual !== void 0 ? _elementsEqual : strictEqual;
    if (first.length !== second.length) {
      return false;
    }
    for (let i = 0; i < first.length; ++i) {
      if (!elementsEqual(first[i], second[i])) {
        return false;
      }
    }
    return true;
  }
  function areSetsEqual(first, second, elementsEqual) {
    var _elementsEqual2, _first$length, _second$length;
    elementsEqual = (_elementsEqual2 = elementsEqual) !== null && _elementsEqual2 !== void 0 ? _elementsEqual2 : strictEqual;
    if (((_first$length = first.length) !== null && _first$length !== void 0 ? _first$length : first.size) !== ((_second$length = second.length) !== null && _second$length !== void 0 ? _second$length : second.size)) {
      return false;
    }
    const notVisited = [...second];
    for (const element of first) {
      const index = notVisited.findIndex(x => elementsEqual(x, element));
      if (index === -1) {
        return false;
      }
      notVisited.splice(index, 1);
    }
    return notVisited.length === 0;
  }
  function canceled() {
    return new Canceled();
  }
  const escapedHtmlCharacters = /[&"'<>]/gu;
  function escapeHtml(string) {
    var _string;
    string = (_string = string) === null || _string === void 0 ? void 0 : _string.toString();
    return string && string.replace(escapedHtmlCharacters, x => {
      switch (x) {
        case "&":
          return "&amp;";
        case '"':
          return "&quot;";
        case "'":
          return "&#39;";
        case "<":
          return "&lt;";
        case ">":
          return "&gt;";
      }
    });
  }
  const regExpCharacters = /[\\^$.*+?()[\]{}|]/gu;
  const regExpCharactersTest = new RegExp(regExpCharacters.source, "u");
  function escapeRegExp(string) {
    var _string2;
    string = (_string2 = string) === null || _string2 === void 0 ? void 0 : _string2.toString();
    return string && regExpCharactersTest.test(string) ? string.replace(regExpCharacters, "\\$&") : string;
  }
  function isCanceled(value) {
    return value instanceof Canceled;
  }
  function isPromise(value) {
    return value && isFunction(value.then);
  }
  function isFunction(value) {
    return value && typeof value == "function";
  }
  function isString(value) {
    return Object.prototype.toString.call(value) === "[object String]";
  }
  function strictEqual(a, b) {
    return a === b;
  }
  function toPromise(value, thisArg, ...args) {
    return isFunction(value) ? new Promise(resolve => resolve(value.apply(thisArg, args))) : Promise.resolve(value);
  }
  _module_exports = {
    areArraysEqual,
    areSetsEqual,
    canceled,
    escapeHtml,
    escapeRegExp,
    IgnoreCanceledMixin,
    isCanceled,
    isPromise,
    isFunction,
    isString,
    strictEqual,
    toPromise
  };
  return _module_exports;
})();

/**
 * MODULE shared\progressList.js
 **/
const _module_shared_progressList = (function shared_progressList(
  _module_shared_prelude
) {
  let _module_exports = {};

  const {
    isString,
    toPromise
  } = _module_shared_prelude;
  
  /**
   * Represents a list of in-progress activities.
   * @constructor
   */
  function ProgressList() {
    this.items = [];
  }
  
  /**
   * Represents an (in-progress) activity.
   * @typedef {Object} Activity
   * @property {*} id The id of activity.
   * @property {string} message A message describing the activity, preferably using nonfinite verbs, e.g. "Loading...".
   */
  
  ProgressList.prototype = {
    /**
     * Indicates, whether there are any activities.
     * @returns {boolean} True, if there are no activities, false otherwise.
     */
    get empty() {
      return this.items.length === 0;
    },
    /**
     * Returns the most recently added activity.
     * @returns {?Activity} The activity that was added most recently, or null if there are none.
     */
    get top() {
      return this.items.length == 0 ? null : this.items[this.items.length - 1];
    },
    /**
     * Adds a new activity with the given id and message.
     * Replaces any previously added activity with the same id.
     * @param {*} id The id of the new activity.
     * @param {string} message The message of the new activity, preferably using nonfinite verbs, e.g. "Loading...".
     */
    add(id, message) {
      const index = this.items.findIndex(x => x.id === id);
      if (index !== -1) {
        this.items.splice(index, 1);
      }
      this.items.push({
        id,
        message
      });
    },
    /**
     * Removes activity with the given id.
     * If an activity with the id was never added, the function does nothing.
     * @param {*} id The id of the activity to be removed
     */
    remove(id) {
      const index = this.items.findIndex(x => x.id === id);
      if (index !== -1) {
        this.items.splice(index, 1);
      }
    },
    /**
     * Tracks a promise or a function by immediately adding a tracking activity,
     * and then removing it when the promise is settled or the function finishes executing.
     * @summary Tracks a promise or execution of a function with an activity.
     * @param {(string|Activity)} activityOrMessage The tracking activity, or a message of an activity that is going to be created with id "default".
     * @param {(function|Promise)} functionOrPromise The function or promise to track.
     * @returns {Promise} The tracked promise, or, if a function was given,
     * a promise resolved with the value returned by the function,
     * or rejected with the error thrown by it.
     */
    track(activityOrMessage, functionOrPromise) {
      const {
        id,
        message
      } = isString(activityOrMessage) ? {
        id: "default",
        message: activityOrMessage
      } : {
        id: activityOrMessage.id,
        message: activityOrMessage.message
      };
      this.add(id, message);
      const result = toPromise(functionOrPromise);
      result.then(() =>
      // Postpone removal until after all other continuations of the promise finish.
      // This is done in case other continuations add new tracking activities.
      // In such situation, we would like to keep the progress list non-empty.
      // This is to avoid any potential flickering in the UI,
      // if it has any content that is only shown when the progress indicator is empty.
      setTimeout(() => this.remove(id), 0), x => {
        setTimeout(() => this.remove(id), 0);
        return Promise.reject(x);
      });
      return result;
    }
  };
  _module_exports = {
    ProgressList
  };
  return _module_exports;
})(_module_shared_prelude);

/**
 * MODULE shared\progressIndicator.js
 **/
const _module_shared_progressIndicator = (function shared_progressIndicator(
  _module_shared_progressList
) {
  let _module_exports = {};

  const {
    ProgressList
  } = _module_shared_progressList;
  
  // @vue/component
  const ProgressIndicator = {
    name: "ProgressIndicator",
    props: {
      establishBfc: {
        type: Boolean,
        default: true
      },
      small: {
        type: Boolean,
        default: false
      },
      viewModel: {
        type: ProgressList,
        required: true
      }
    },
    computed: {
      message() {
        const top = this.viewModel.top;
        return top ? top.message : null;
      },
      visible() {
        return !this.viewModel.empty;
      }
    },
    render(h) {
      var _this$message;
      return h("div", {
        class: {
          "progress-indicator-root": this.establishBfc
        }
      }, [
      // Status
      this.visible ? h("div", {
        class: "progress-indicator-status"
      }, [
      // Spinner
      h("div", [h("b-spinner", {
        props: {
          small: this.small
        }
      }, [])]),
      // Message
      h("div", {
        class: "ml-1"
      }, [(_this$message = this.message) === null || _this$message === void 0 ? void 0 : _this$message.toString()])]) :
      // For some reason the else branch cannot be `""` or `[]`,
      // as then it causes an error in Vue update logic.
      // The error is caused by an `undefined` VNode appearing within `this.$slots.default`.
      // This can be reproduced by drag&dropping a person to a day/break with no assignments.
      h("div", []),
      // Contents
      h("div", {
        class: "progress-indicator-content",
        attrs: {
          "data-obscured": this.visible
        }
      }, this.$slots.default)]);
    }
  };
  _module_exports = {
    ProgressIndicator
  };
  return _module_exports;
})(_module_shared_progressList);

/**
 * MODULE shared\functions.js
 **/
const _module_shared_functions = (function shared_functions(
  _module_shared_prelude
) {
  let _module_exports = {};

  const {
    canceled,
    isString,
    toPromise
  } = _module_shared_prelude;
  _module_exports = {
    combine(inner, outer) {
      return x => {
        const resultOfInner = inner(x);
        const resultOfOuter = outer(resultOfInner);
        return resultOfOuter;
      };
    },
    debounce(callback, delay) {
      let latestReject;
      let timeoutId;
      return function () {
        const result = new Promise((resolve, reject) => {
          var _latestReject;
          (_latestReject = latestReject) === null || _latestReject === void 0 ? void 0 : _latestReject(canceled());
          latestReject = reject;
          clearTimeout(timeoutId);
          timeoutId = setTimeout(() => {
            toPromise(callback, this, ...arguments).then(x => {
              latestReject = undefined;
              timeoutId = undefined;
              resolve(x);
            }, x => {
              latestReject = undefined;
              timeoutId = undefined;
              reject(x);
            });
          }, delay);
        });
        return result;
      };
    },
    /**
     * Transforms a function so that only its latest invocation yields a usable value.
     * When the transformed function is called, it in turns calls the function to be transformed,
     * with the arguments passed to itself, then returns a promise.
     * That promise is resolved to the result of the call,
     * if the transformed function is not called again before that happens.
     * Otherwise, the promise is resolved to an object representing cancellation.
     * @param {Function} callback The function to transform.
     * @returns {Function} The transformed function.
     */
    last(callback) {
      const version = function () {
        let current = 0;
        return {
          is(expected) {
            return expected === current;
          },
          increment() {
            ++current;
            return current;
          }
        };
      }();
      return function () {
        return new Promise((resolve, reject) => {
          const thisVersion = version.increment();
          toPromise(callback, this, ...arguments).then(x => {
            if (version.is(thisVersion)) {
              resolve(x);
            } else {
              reject(canceled());
            }
          }, x => {
            if (version.is(thisVersion)) {
              reject(x);
            } else {
              reject(canceled());
            }
          });
        });
      };
    },
    /**
     * Transform a function so that it can only be called once.
     * First call to the transformed function invokes the function to be transformed,
     * and returns its result.
     * Subsequent calls to the transformed function do not invoke the function to be transformed,
     * and return undefined.
     * @param {Function} callback The function to transform.
     * @returns {Function} The transformed function.
     */
    once(callback) {
      let wasCalled = false;
      return function () {
        if (wasCalled) {
          return;
        } else {
          wasCalled = true;
        }
        return callback.apply(this, arguments);
      };
    },
    /**
     * Transforms a function so that focus is moved to one of target elements after the function returns.
     * In case the function returns a promise, the focus is moved when it is settled.
     * Resolving of target elements and actual moving of focus is performed in next "tick",
     * to allow for queued DOM changes to happen, as they might affect resolve of the target elements.
     * The focus is moved to each target element, in ascending order within the array,
     * until an element is successfully found and becomes the active element when focused.
     * @param {Function} callback The function to transform.
     * @param {Array<String|Object>|String|Object} targetElements The element(s) to move focus to after the function to transform returns.
     * An element can be a DOM element, a CSS selector, or the value "activeElement",
     * which resolves to the active element before the transformed function is called.
     * @returns {Function} The transformed function.
     */
    withFocusRestore(callback, targetElements) {
      return function () {
        const activeElement = document.activeElement;
        return toPromise(callback.apply(this, arguments)).then(x => {
          setTimeout(() => setFocus(targetElements, activeElement), 0);
          return x;
        }, x => {
          setTimeout(() => setFocus(targetElements, activeElement), 0);
          return Promise.reject(x);
        });
      };
      function setFocus(targetElements, activeElement) {
        targetElements = Array.isArray(targetElements) ? targetElements : [targetElements];
        for (const targetElement of targetElements) {
          const element = targetElement === "activeElement" ? activeElement : isString(targetElement) ? document.querySelector(targetElement) : targetElement;
          if (element) {
            element.focus();
            if (element === document.activeElement) {
              break;
            }
          }
        }
      }
    },
    retry(callback, count, delay) {
      return function () {
        let retriesCount = 0;
        const errors = [];
        const result = new Promise((resolve, reject) => {
          invoke.apply(this, arguments);
          function invoke() {
            toPromise(callback, this, ...arguments).then(resolve, x => {
              errors.push(x);
              if (retriesCount < count) {
                ++retriesCount;
                setTimeout(() => invoke.apply(this, arguments), delay || 0);
              } else {
                reject(errors);
              }
            });
          }
        });
        return result;
      };
    },
    throttle(callback, delay) {
      let isScheduledOrRunning = false;
      const context = {};
      return function () {
        context.arguments = arguments;
        context.this = this;
        if (isScheduledOrRunning) return Promise.resolve(null);
        isScheduledOrRunning = true;
        const result = new Promise((resolve, reject) => {
          setTimeout(() => {
            const {
              this: thisArg,
              arguments: args
            } = context;
            context.this = undefined;
            context.arguments = undefined;
            toPromise(callback, thisArg, ...args).then(x => {
              resolve(x);
              isScheduledOrRunning = false;
            }, x => {
              reject(x);
              isScheduledOrRunning = false;
            });
          }, delay);
        });
        return result;
      };
    }
  };
  return _module_exports;
})(_module_shared_prelude);

/**
 * MODULE shared\validatedValueInput.js
 **/
const _module_shared_validatedValueInput = (function shared_validatedValueInput(
  _module_shared_functions
) {
  let _module_exports = {};

  const functions = _module_shared_functions;
  
  // @vue/component
  const ValidatedValueInput = {
    name: "ValidatedValueInput",
    inheritAttrs: false,
    props: {
      id: {
        type: String,
        required: true
      },
      label: {
        type: String,
        required: true
      },
      type: {
        type: String,
        default: "text"
      },
      viewModel: {
        type: Object,
        required: true
      },
      showSuccessStateOnInput: {
        type: Boolean,
        required: false,
        default: true
      }
    },
    computed: {
      invalidFeedback() {
        const availableValue = this.viewModel.availableValue;
        return availableValue && !availableValue.isSuccess ? availableValue.error : null;
      },
      state() {
        const availableValue = this.viewModel.availableValue;
        return availableValue ? availableValue.isSuccess : null;
      }
    },
    methods: {
      set: functions.debounce(function ($event) {
        this.viewModel.set($event);
      }, 200)
    },
    render(h) {
      return h("b-form-group", {
        props: {
          invalidFeedback: this.invalidFeedback,
          label: this.label,
          labelFor: this.id,
          state: this.state
        }
      }, [h("b-form-input", {
        attrs: this.$attrs,
        props: {
          id: this.id,
          state: !this.showSuccessStateOnInput && this.state === true ? null : this.state,
          type: this.type,
          value: this.viewModel.textValue
        },
        on: {
          input: this.set.bind(this)
        }
      }, [])]);
    }
  };
  _module_exports = {
    ValidatedValueInput
  };
  return _module_exports;
})(_module_shared_functions);

/**
 * MODULE shared\validatedTimeInput.js
 **/
const _module_shared_validatedTimeInput = (function shared_validatedTimeInput(
  _module_shared_validatedValueInput
) {
  let _module_exports = {};

  const {
    ValidatedValueInput
  } = _module_shared_validatedValueInput;
  
  // @vue/component
  const ValidatedTimeInput = {
    name: "ValidatedTimeInput",
    components: {
      "validated-value-input": ValidatedValueInput
    },
    inheritAttrs: false,
    props: {
      id: {
        type: String,
        required: true
      },
      label: {
        type: String,
        required: true
      },
      showSuccessStateOnInput: {
        type: Boolean,
        required: false,
        default: true
      },
      viewModel: {
        type: Object,
        required: true
      }
    },
    render(h) {
      return h("validated-value-input", {
        attrs: this.$attrs,
        props: {
          id: this.id,
          label: this.label,
          showSuccessStateOnInput: this.showSuccessStateOnInput,
          type: "time",
          viewModel: this.viewModel.input
        }
      }, []);
    }
  };
  _module_exports = {
    ValidatedTimeInput
  };
  return _module_exports;
})(_module_shared_validatedValueInput);

/**
 * MODULE shared\validatedDateInput.js
 **/
const _module_shared_validatedDateInput = (function shared_validatedDateInput(
  _module_shared_validatedValueInput
) {
  let _module_exports = {};

  const {
    ValidatedValueInput
  } = _module_shared_validatedValueInput;
  
  // @vue/component
  const ValidatedDateInput = {
    name: "ValidatedDateInput",
    components: {
      "validated-value-input": ValidatedValueInput
    },
    inheritAttrs: false,
    props: {
      id: {
        type: String,
        required: true
      },
      label: {
        type: String,
        required: true
      },
      showSuccessStateOnInput: {
        type: Boolean,
        required: false,
        default: true
      },
      viewModel: {
        type: Object,
        required: true
      }
    },
    render(h) {
      return h("validated-value-input", {
        attrs: this.$attrs,
        props: {
          id: this.id,
          label: this.label,
          showSuccessStateOnInput: this.showSuccessStateOnInput,
          type: "date",
          viewModel: this.viewModel.input
        }
      }, []);
    }
  };
  _module_exports = {
    ValidatedDateInput
  };
  return _module_exports;
})(_module_shared_validatedValueInput);

/**
 * MODULE shared\singleSelectionInput.js
 **/
const _module_shared_singleSelectionInput = (function shared_singleSelectionInput(
  _module_shared_functions,
  _module_shared_singleSelection
) {
  let _module_exports = {};

  const {
    SingleSelection
  } = _module_shared_singleSelection;
  const functions = _module_shared_functions;
  
  // @vue/component
  const SingleSelectionInput = {
    name: "SingleSelectionInput",
    inheritAttrs: false,
    props: {
      id: {
        type: String,
        required: true
      },
      label: {
        type: String,
        required: true
      },
      viewModel: {
        type: SingleSelection,
        required: true
      }
    },
    methods: {
      select: functions.debounce(function ($event) {
        this.viewModel.select($event);
      }, 200)
    },
    render(h) {
      return h("b-form-group", {
        props: {
          label: this.label,
          labelFor: this.id
        }
      }, [h("b-form-select", {
        attrs: this.$attrs,
        props: {
          id: this.id,
          options: this.viewModel.items,
          plain: true,
          value: this.viewModel.value
        },
        on: {
          change: this.select.bind(this)
        }
      }, [])]);
    }
  };
  _module_exports = {
    SingleSelectionInput
  };
  return _module_exports;
})(_module_shared_functions,
  _module_shared_singleSelection);

/**
 * MODULE shared\validatedSingleSelectionInput.js
 **/
const _module_shared_validatedSingleSelectionInput = (function shared_validatedSingleSelectionInput(
  _module_shared_singleSelectionInput
) {
  let _module_exports = {};

  const {
    SingleSelectionInput
  } = _module_shared_singleSelectionInput;
  
  // @vue/component
  const ValidatedSingleSelectionInput = {
    name: "ValidatedSingleSelectionInput",
    components: {
      "single-selection-input": SingleSelectionInput
    },
    inheritAttrs: false,
    props: {
      id: {
        type: String,
        required: true
      },
      label: {
        type: String,
        required: true
      },
      showSuccessStateOnInput: {
        type: Boolean,
        required: false,
        default: true
      },
      viewModel: {
        type: Object,
        required: true
      }
    },
    computed: {
      invalidFeedback() {
        const availableValue = this.viewModel.availableValue;
        return availableValue && !availableValue.isSuccess ? availableValue.error : null;
      },
      state() {
        const availableValue = this.viewModel.availableValue;
        return availableValue ? availableValue.isSuccess : null;
      }
    },
    render(h) {
      return h("b-form-group", {
        props: {
          invalidFeedback: this.invalidFeedback,
          label: this.label,
          labelFor: this.id,
          state: this.state
        }
      }, [h("b-form-select", {
        attrs: this.$attrs,
        props: {
          id: this.id,
          options: this.viewModel.input.items,
          plain: true,
          state: !this.showSuccessStateOnInput && this.state === true ? null : this.state,
          value: this.viewModel.input.value
        },
        on: {
          change: this.viewModel.input.select.bind(this.viewModel.input)
        }
      }, [])]);
    }
  };
  _module_exports = {
    ValidatedSingleSelectionInput
  };
  return _module_exports;
})(_module_shared_singleSelectionInput);

/**
 * MODULE shared\errorList.js
 **/
const _module_shared_errorList = (function shared_errorList(
  _module_shared_prelude,
  _module_shared_result
) {
  let _module_exports = {};

  const {
    isCanceled,
    isPromise,
    toPromise
  } = _module_shared_prelude;
  const {
    Result
  } = _module_shared_result;
  
  /**
   * Represents a list of errors.
   */
  function ErrorList() {
    this.items = [];
  }
  
  /**
   * Represents options for error tracking.
   * @typedef {Object} TrackOptions
   * @property {?*} [id="default"] The id of the error.
   * @property {string} string The message of the error.
   * @property {?(bool|function)} [retry=false] Indicates whether the operation that resulted in the error can be retried.
   * If a promise is being tracked, retry must be a function, which is called when user requests a retry.
   * If a function is being tracked, retry can be boolean value.
   * In that case, when set to true, the function is called again with error tracking using the same options.
   * @property {?boolean} [dismissible=true] Indicates whether the error can be dismissed by the user.
   * A dismissed error is removed from the error list to which it was added.
   */
  
  ErrorList.prototype = {
    /**
     * Indicates whether there any errors present.
     * @returns {boolean} True, if there are no errors present, false otherwise.
     */
    get empty() {
      return this.items.length == 0;
    },
    /**
     * Adds an error.
     * The added error replaces any previously added error with the same id.
     * @param {Object} error The error to add.
     * @param {*} error.id The id of the error.
     * @param {string} error.message The error message.
     * @param {?string} [error.details] The error details.
     * If not null, nor undefined, nor '', it is appended to the message in parentheses.
     * @param {?function} [error.retryCallback=null] The callback to retry operation that failed because of the error.
     * @param {?dismissible} [error.dismissible=true] Indicates whether the error can be dismissed by user.
     */
    add({
      id,
      message,
      details,
      retryCallback,
      dismissible
    }) {
      var _id;
      id = (_id = id) !== null && _id !== void 0 ? _id : "default";
      const index = this.items.findIndex(x => x.id === id);
      if (index === -1) {
        this.items.push({
          id,
          dismissible,
          message: formattedMessage(message, details),
          dismiss: () => {
            this.remove(id);
          },
          retry: retryCallback
        });
      } else {
        const existingItem = this.items[index];
        existingItem.message = formattedMessage(message, details);
        existingItem.dismissible = dismissible;
        existingItem.retry = retryCallback;
      }
      function formattedMessage(message, details) {
        return details === null || details === undefined || details === "" ? message : message + " (" + details + ")";
      }
    },
    /**
     * Removes all errors.
     */
    clear() {
      this.items.splice(0);
    },
    /**
     * Checks whether an error with specific id exists.
     * @param {*} id The id of the error to check.
     * @returns {Boolean} A value indicating whether the error exists.
     */
    has(id) {
      const index = this.items.findIndex(x => x.id === id);
      return index !== -1;
    },
    /**
     * Removes error with the given id.
     * If an error with the id was never added, the function does nothing.
     * @param {*} id The id of the error to remove.
     */
    remove(id) {
      const index = this.items.findIndex(x => x.id === id);
      if (index !== -1) {
        this.items.splice(index, 1);
      }
    },
    /**
     * Calls retry on all errors that support it.
     */
    retryAll() {
      this.items.map(x => x.retry).filter(x => x).forEach(x => x());
    },
    /**
     * Tracks a promise or a function by adding an error when they fail.
     * For a promise, an error is added if it is in, or resolves to, a rejected state.
     * For a function, an error is added if throws, or returns a promise that is, or resolves to, a rejected state.
     * The reason of a resulting rejected promise is used as details for the error.
     * @summary Tracks errors in a promise or during execution of a function.
     * @param {(string|TrackOptions)} messageOrOptions The options or a message for the error.
     * When a string is used, the resulting error has id "default", cannot be retried and is dismissible.
     * @param {(function|Promise)} functionOrPromise The function or promise to track.
     * @returns {Promise} The tracked promise, or, if a function was given,
     * a promise resolved with the value returned by the function,
     * or rejected with the error thrown by it.
     */
    track(messageOrOptions, functionOrPromise) {
      const id = messageOrOptions.id || "default";
      const message = messageOrOptions.message || messageOrOptions;
      const retry = messageOrOptions.retry;
      const dismissible = messageOrOptions.dismissible === undefined ? true : !!messageOrOptions.dismissible;
      const retryCallback = (() => {
        if (retry === undefined || retry === false) {
          return Result.from(null);
        } else if (retry === true) {
          if (isPromise(functionOrPromise)) {
            return Result.error('Cannot retry a promise. Assign a retry function to "options.retry" are use a function instead of a promise.');
          } else {
            return Result.from(() => this.track(messageOrOptions, functionOrPromise));
          }
        } else {
          return Result.from(retry);
        }
      })();
      if (!retryCallback.isSuccess) {
        return Promise.reject(new Error(retryCallback.error));
      }
      this.remove(id);
      const promise = toPromise(functionOrPromise);
      return promise.catch(reason => {
        if (isCanceled(reason)) {
          return;
        }
        this.add({
          id,
          message,
          details: reason,
          retryCallback: retryCallback.value,
          dismissible
        });
        return Promise.reject(reason);
      });
    }
  };
  _module_exports = {
    ErrorList
  };
  return _module_exports;
})(_module_shared_prelude,
  _module_shared_result);

/**
 * MODULE shared\errorIndicator.js
 **/
const _module_shared_errorIndicator = (function shared_errorIndicator(
  _module_shared_errorList
) {
  let _module_exports = {};

  const {
    ErrorList
  } = _module_shared_errorList;
  
  // @vue/component
  const ErrorIndicator = {
    name: "ErrorIndicator",
    props: {
      viewModel: {
        type: ErrorList,
        required: true
      }
    },
    computed: {
      showRetryAll() {
        return this.viewModel.items.reduce((total, item) => total + (item.retry ? 1 : 0), 0) > 1;
      }
    },
    render(h) {
      return h("div", [...this.viewModel.items.map(item => {
        var _item$message;
        return h("b-alert", {
          class: "d-flex flex-wrap",
          props: {
            dismissible: item.dismissible,
            dismissLabel: "Luk",
            show: true,
            variant: "danger"
          },
          on: {
            dismissed() {
              item.dismiss();
            }
          },
          key: item.id
        }, [// Message
        (_item$message = item.message) === null || _item$message === void 0 ? void 0 : _item$message.toString(),
        // Retry link
        ...(item.retry ? [h("b-link", {
          class: "ml-auto",
          on: {
            click() {
              item.retry();
            }
          }
        }, "Prøv igen")] : [])]);
      }), this.showRetryAll ? h("div", {
        // Margin chosen so that the right edges of "Retry all" link and other "Retry" links align
        class: "d-flex flex-row-reverse mx-3"
      }, [h("b-link", {
        on: {
          click: () => {
            this.viewModel.retryAll();
          }
        }
      }, "Prøv alle igen")]) : ""]);
    }
  };
  _module_exports = {
    ErrorIndicator
  };
  return _module_exports;
})(_module_shared_errorList);

/**
 * MODULE shared\intervals.js
 **/
const _module_shared_intervals = (function shared_intervals() {
  let _module_exports = {};

  /**
   * Represents an interval between two points,
   * including the start point, and excluding the end point.
   * @typedef {Object} Interval
   * @property {*} start The start point of the interval.
   * @property {*} end The end point of the interval.
   * @property {Boolean} empty Indicates whether the interval is empty, always false.
   */
  
  /**
   * Represents an empty interval.
   * @typedef {Object} EmptyInterval
   * @property {Boolean} empty Indicates whether the interval is empty, always true.
   */
  
  /**
   * @returns {Interval|EmptyInterval}
   */
  function makeNonEmptyInterval(start, end) {
    if (end.isBefore(start)) {
      throw "End must be after start";
    }
    if (start.isSame(end)) {
      return makeEmptyInterval();
    }
    return {
      start: start,
      end: end,
      empty: false,
      contains(point) {
        return end.isAfter(point) && !start.isAfter(point);
      },
      duration() {
        return start.durationTo(end);
      },
      intersect(other) {
        if (!this.intersectsWith(other)) {
          return makeEmptyInterval();
        }
        var startToOtherStart = start.compare(other.start);
        var endToOtherEnd = end.compare(other.end);
        return makeNonEmptyInterval(startToOtherStart > 0 ? start : other.start, endToOtherEnd < 0 ? end : other.end);
      },
      intersectsWith(other) {
        if (other.empty) {
          return false;
        }
        return start.isBefore(other.end) && end.isAfter(other.start);
      },
      isSame(other) {
        if (other.empty) {
          return false;
        }
        return start.isSame(other.start) && end.isSame(other.end);
      },
      *iterate(nextFn) {
        let current = this.start;
        do {
          yield current;
          current = nextFn(current);
        } while (current.isBefore(this.end));
      },
      map(fnOrStartFn, endFn) {
        const newStart = fnOrStartFn(start);
        const newEnd = (endFn !== null && endFn !== void 0 ? endFn : fnOrStartFn)(end);
        if (newEnd.isBefore(newStart)) {
          return makeEmptyInterval();
        }
        return makeNonEmptyInterval(newStart, newEnd);
      },
      span(other) {
        if (other.empty) {
          return this;
        }
        return makeNonEmptyInterval(other.start.isBefore(start) ? other.start : start, other.end.isBefore(end) ? end : other.end);
      },
      split(endFromStart) {
        let rest = this;
        let result = [];
        do {
          const newEnd = endFromStart(rest.start);
          if (!newEnd.isBefore(rest.end) || !newEnd.isAfter(rest.start)) {
            result.push(rest);
            return makeIntervalSum(result);
          }
          result.push(makeNonEmptyInterval(rest.start, newEnd));
          rest = makeNonEmptyInterval(newEnd, rest.end);
        } while (!rest.empty);
        return makeIntervalSum(result);
      },
      subtract(other) {
        if (other.empty) {
          return makeIntervalSum([this]);
        }
        const startToOtherEnd = start.compare(other.end);
        if (startToOtherEnd >= 0) {
          return makeIntervalSum([this]);
        }
        const endToOtherStart = end.compare(other.start);
        if (endToOtherStart <= 0) {
          return makeIntervalSum([this]);
        }
        const startToOtherStart = start.compare(other.start);
        const endToOtherEnd = end.compare(other.end);
        if (startToOtherStart > 0) {
          if (endToOtherEnd <= 0) {
            return makeEmptyIntervalSum();
          } else {
            return makeIntervalSum([makeNonEmptyInterval(other.end, end)]);
          }
        } else if (startToOtherStart == 0) {
          if (endToOtherEnd > 0) {
            return makeIntervalSum([makeNonEmptyInterval(other.end, end)]);
          } else {
            return makeEmptyIntervalSum();
          }
        } else {
          if (endToOtherEnd > 0) {
            return makeIntervalSum([makeNonEmptyInterval(start, other.start), makeNonEmptyInterval(other.end, end)]);
          } else {
            return makeIntervalSum([makeNonEmptyInterval(start, other.start)]);
          }
        }
      },
      subtractSum(other) {
        if (other.empty) {
          return makeIntervalSum([this]);
        }
        return other.periods.map(x => this.subtract(x)).reduce((a, b) => a.intersect(b));
      },
      toString() {
        return start.toString() + " - " + end.toString();
      }
    };
  }
  
  /**
   * @returns {EmptyInterval}
   */
  function makeEmptyInterval() {
    return {
      empty: true,
      contains() {
        return false;
      },
      intersect() {
        return this;
      },
      intersectsWith() {
        return false;
      },
      isSame(other) {
        return other.empty;
      },
      *iterate() {},
      map() {
        return this;
      },
      span(other) {
        return other;
      },
      split() {
        return this;
      },
      subtract() {
        return this;
      },
      subtractSum() {
        return makeEmptyIntervalSum();
      },
      toString() {
        return "";
      }
    };
  }
  function makeIntervalSum(periods) {
    periods = periods.filter(x => !x.empty);
    if (periods.length === 0) {
      return makeEmptyIntervalSum();
    }
    periods = unionIntersecting(periods);
    periods.sort((a, b) => a.start.compare(b.start));
    return {
      empty: false,
      periods: periods,
      contains(point) {
        return periods.some(period => period.contains(point));
      },
      intersect(other) {
        return makeIntervalSum(periods.map(x => other.periods.map(y => x.intersect(y))).reduce((a, b) => a.concat(b), []));
      },
      intersectsWith(other) {
        return periods.some(x => other.periods.some(y => x.intersectsWith(y)));
      },
      joinAdjacent(predicate) {
        if (periods.length === 0) {
          return makeEmptyIntervalSum();
        }
        const {
          result
        } = periods.reduce((state, current) => {
          if (state.result.length === 0) {
            return {
              result: [current],
              lastJoinable: predicate(current)
            };
          } else {
            const last = state.result[state.result.length - 1];
            const currentJoinable = predicate(current);
            if (last.end.isSame(current.start) && state.lastJoinable && currentJoinable) {
              const joined = makeNonEmptyInterval(last.start, current.end);
              state.result[state.result.length - 1] = joined;
              return {
                result: state.result,
                lastJoinable: true
              };
            } else {
              state.result.push(current);
              return {
                result: state.result,
                lastJoinable: currentJoinable
              };
            }
          }
        }, {
          result: []
        });
        return makeIntervalSum(result);
      },
      split(endFromStart) {
        return makeIntervalSum(periods.map(x => x.split(endFromStart).periods).reduce((a, b) => a.concat(b), []));
      },
      toString() {
        return periods.map(x => x.toString()).join(", ");
      },
      union(other) {
        return other.empty ? this : makeIntervalSum([...periods, ...other.periods]);
      }
    };
    function unionIntersecting(periods) {
      return periods.reduce((a, b) => union(a, b), []);
      function union(periods, period) {
        const [intersecting, nonIntersecting] = partition(periods, x => x.intersectsWith(period));
        const merged = intersecting.reduce((a, b) => a.span(b), period);
        return [merged, ...nonIntersecting];
      }
      function partition(array, predicate) {
        return array.reduce(([trueElements, falseElements], element) => {
          const target = predicate(element) ? trueElements : falseElements;
          target.push(element);
          return [trueElements, falseElements];
        }, [[], []]);
      }
    }
  }
  function makeEmptyIntervalSum() {
    return {
      empty: true,
      periods: [],
      contains() {
        return false;
      },
      intersect() {
        return this;
      },
      intersectsWith() {
        return false;
      },
      joinAdjacent() {
        return this;
      },
      split() {
        return this;
      },
      toString() {
        return "";
      },
      union(other) {
        return other;
      }
    };
  }
  _module_exports = {
    empty: makeEmptyInterval,
    nonEmpty: makeNonEmptyInterval,
    sum: makeIntervalSum
  };
  return _module_exports;
})();

/**
 * MODULE shared\validatedTimePeriod.js
 **/
const _module_shared_validatedTimePeriod = (function shared_validatedTimePeriod(
  _module_shared_intervals,
  _module_shared_result,
  _module_shared_validatedTime
) {
  let _module_exports = {};

  const intervals = _module_shared_intervals;
  const {
    Result
  } = _module_shared_result;
  const {
    ValidatedTime,
    validTime
  } = _module_shared_validatedTime;
  
  /** @typedef {import('./time').MinuteTime} MinuteTime */
  /** @typedef {import('./intervals').Interval} Interval */
  
  /**
   * A callback called when time period changes.
   * @callback ChangeCallback
   * @param {Result} value A Result object, which in successful state contains the time period,
   * and in error state contains a validation/parse error.
   * @param {boolean} isReset Indicates whether the change is a result of a reset call (as opposed to set call).
   */
  
  /**
   * Represents options used when creating instance of ValidatedTimePeriod.
   * @typedef {Object} Options
   * @property {?Interval<MinuteTime>} [initialValue=""] Initial value of the time period.
   * @property {?ChangeCallback} [change=null] A function that is called with the time period, when it changes.
   * @property {?boolean} [isAvailable=false] Indicates whether the time period is initially available.
   */
  
  /**
   * Represents a time period value parsed from a pair of strings (text values),
   * that correspond to the period's start and end time.
   * @summary Represents a time period value parsed from a pair of strings (text values).
   * @param {Options} options The options.
   */
  function ValidatedTimePeriod(options) {
    var _options$initialValue, _options$initialValue2, _options$change;
    this.start = new ValidatedTime({
      initialValue: (_options$initialValue = options.initialValue) === null || _options$initialValue === void 0 ? void 0 : _options$initialValue.start,
      isAvailable: !!options.isAvailable,
      change: (start, isReset) => this._changeOfStart(start, isReset),
      validate: start => this._validStart(start)
    });
    this.end = new ValidatedTime({
      initialValue: (_options$initialValue2 = options.initialValue) === null || _options$initialValue2 === void 0 ? void 0 : _options$initialValue2.end.addMinutes(-1).format("input"),
      isAvailable: !!options.isAvailable,
      change: (end, isReset) => this._changeOfEnd(end, isReset),
      validate: end => this._validEnd(end)
    });
    this._change = (_options$change = options.change) !== null && _options$change !== void 0 ? _options$change : () => {};
    this._isAvailable = !!options.isAvailable;
    this._isSynchronizingStartAndEnd = false;
  }
  ValidatedTimePeriod.prototype = {
    /**
     * Returns the time period, if it is available.
     * @returns {?Result} A Result object, which in successful state contains the time period,
     * and in error state contains a validation/parse error.
     * Null if the time period is not yet available.
     */
    get availableValue() {
      return this._isAvailable ? this.value : null;
    },
    /**
     * Returns the time period.
     * @returns {?Result} A Result object, which in successful state contains the time period,
     * and in error state contains a validation/parse error.
     */
    get value() {
      return this._validPeriod(this.start.value, this.end.value);
    },
    /**
     * Sets new time period and makes it available.
     * @param {Interval<MinuteTime>} value The time period to set.
     */
    set(value) {
      this._isAvailable = true;
      this._isSynchronizingStartAndEnd = true;
      try {
        var _value$end;
        this.start.set(value.start);
        this.end.set((_value$end = value.end) === null || _value$end === void 0 ? void 0 : _value$end.addMinutes(-1));
      } finally {
        this._isSynchronizingStartAndEnd = false;
      }
      this._change(this.value, false);
    },
    /**
     * Sets new time period and makes it no longer available.
     * @param {?Interval<MinuteTime>} [value=undefined] The time period to set.
     * If undefined, the time period is set to the initial time period.
     */
    reset(value) {
      this._isAvailable = false;
      this._isSynchronizingStartAndEnd = true;
      try {
        var _value$start, _value$end$addMinutes, _value$end2;
        this.start.reset(value === undefined ? undefined : (_value$start = value === null || value === void 0 ? void 0 : value.start) !== null && _value$start !== void 0 ? _value$start : null);
        this.end.reset(value === undefined ? undefined : (_value$end$addMinutes = value === null || value === void 0 ? void 0 : (_value$end2 = value.end) === null || _value$end2 === void 0 ? void 0 : _value$end2.addMinutes(-1)) !== null && _value$end$addMinutes !== void 0 ? _value$end$addMinutes : null);
      } finally {
        this._isSynchronizingStartAndEnd = false;
      }
      this._change(this.value, true);
    },
    /**
     * Makes the time period available.
     * @returns {Result} A Result object, which in successful state contains the time period,
     * and in error state contains a validation/parse error.
     */
    validate() {
      this._isAvailable = true;
      const start = this.start.validate();
      const end = this.end.validate();
      return this._validPeriod(start, end);
    },
    _changeOfStart(start, isReset) {
      if (this._isSynchronizingStartAndEnd) {
        return;
      }
      this._change(this._validPeriod(start, this.end.value), isReset);
    },
    _changeOfEnd(end, isReset) {
      if (this._isSynchronizingStartAndEnd) {
        return;
      }
      this._change(this._validPeriod(this.start.value, end), isReset);
    },
    _validPeriod(start, end) {
      return start.then(validStart => end.then(validEnd => intervals.nonEmpty(validStart, validEnd.addMinutes(1))));
    },
    _validStart(validStart) {
      return validTime(this.end.textValue, this._bounds, this._moment).then(validEnd => validStart.compare(validEnd) >= 0 ? Result.error("Starttid skal være inden sluttid") : Result.success(validStart), () => validStart);
    },
    _validEnd(validEnd) {
      return validTime(this.start.textValue, this._bounds, this._moment).then(validStart => validStart.compare(validEnd) >= 0 ? Result.error("Sluttid skal være efter starttid") : Result.success(validEnd), () => validEnd);
    }
  };
  _module_exports = {
    ValidatedTimePeriod
  };
  return _module_exports;
})(_module_shared_intervals,
  _module_shared_result,
  _module_shared_validatedTime);

/**
 * MODULE shared\future.js
 **/
const _module_shared_future = (function shared_future() {
  let _module_exports = {};

  /** @constructor */
  function Future() {
    this.promise = new Promise((resolve, reject) => {
      this._resolve = resolve;
      this._reject = reject;
    });
  }
  Future.prototype.reject = function (value) {
    this._reject(value);
  };
  Future.prototype.resolve = function (value) {
    this._resolve(value);
  };
  _module_exports = {
    Future
  };
  return _module_exports;
})();

/**
 * MODULE shared\aula.js
 **/
const _module_shared_aula = (function shared_aula(
  _module_shared_future
) {
  let _module_exports = {};

  const {
    Future
  } = _module_shared_future;
  const AulaTokenMixin = {
    data() {
      return {
        /** @private */
        aulaToken: undefined,
        /** @private */
        aulaTokenFuture: new Future()
      };
    },
    computed: {
      /** @public */
      token() {
        return this.aulaTokenFuture.promise;
      }
    },
    watch: {
      aulaToken(newValue) {
        if (newValue == undefined) {
          return;
        }
        this.aulaTokenFuture.resolve(newValue);
        this.aulaTokenFuture = new Future();
        this.aulaTokenFuture.resolve(newValue);
      }
    },
    mounted() {
      this.aulaToken = this.getAulaToken();
    }
  };
  _module_exports = {
    AulaTokenMixin
  };
  return _module_exports;
})(_module_shared_future);

/**
 * MODULE shared\date.js
 **/
const _module_shared_date = (function shared_date() {
  let _module_exports = {};

  /**
   * A number identifying a day of week.
   * Days are numbered from 0, starting with monday, up to sunday.
   * @typedef {number} DayOfWeek
   */
  
  /**
   * A date.
   * @typedef {Object} Date
   * @property {number} year Year of the date.
   * @property {number} month Month of the date (1-12).
   * @property {number} day Day of month of the date (1-31).
   * @property {DayOfWeek} dayOfWeek Day of week of the date (0-6).
   */
  
  /**
   * @returns {Date}
   */
  function makePoint(year, month, day, moment) {
    return wrapMoment(moment()().year(year).month(month - 1).date(day).hour(0).minute(0).second(0).millisecond(0));
  }
  
  /**
   * @returns {Date}
   */
  function wrapMoment(value) {
    if (!value.isValid()) {
      const message = invalidDateMessage(value);
      throw new Error("Invalid date".concat(message ? ". " + message : "", "."));
    }
    return {
      _value: value,
      get year() {
        return this._value.year();
      },
      get month() {
        return this._value.month() + 1;
      },
      get day() {
        return this._value.date();
      },
      get dayOfWeek() {
        const day = this._value.day();
        return day == 0 ? 6 : day - 1;
      },
      addDays(count) {
        return wrapMoment(this._value.clone().add(count, "days"));
      },
      compare(other) {
        return this._value.diff(other._value, "days");
      },
      /**
       * Formats the date to a string.
       * Supported formats are "dayofweek" and "input".
       * The "dayofweek" format specifies that long name of the date's day of week should be used.
       * The "input" format results in a string understood by input elements of type "date" ('yyyy-mm-dd').
       * @param {string} format A format specification to use. The only supported value is "dayofweek".
       * @returns A string representation of the date, according to specified format.
       * @throws {Error} Throws when format argument is none of the supported values.
       */
      format(format) {
        switch (format) {
          case "dayofweek":
            return this._value.format("dddd");
          case "input":
            return this._value.format("YYYY-MM-DD");
          default:
            throw new Error("Unknown date point format \"".concat(format, "\""));
        }
      },
      isAfter(other) {
        return this._value.isAfter(other._value);
      },
      isBefore(other) {
        return this._value.isBefore(other._value);
      },
      isSame(other) {
        return this._value.isSame(other._value);
      },
      toJSON() {
        return this._value.format("YYYY-MM-DD");
      },
      toString() {
        return this._value.format("L");
      }
    };
  }
  function invalidDateMessage(value) {
    switch (value.invalidAt()) {
      case 0:
        return "Year value ".concat(value.year(), " is invalid");
      case 1:
        return "Month value ".concat(value.month(), " is invalid");
      case 2:
        return "Day value ".concat(value.date(), " is invalid");
      default:
        return null;
    }
  }
  
  /**
   * @returns {?Date}
   */
  function parsePoint(value, moment) {
    const result = value.match(/(\d{1,5})-(\d{1,2})-(\d{1,2})/u);
    if (result && result[0] === value) {
      const [, year, month, day] = result;
      return makePoint(Number.parseInt(year, 10), Number.parseInt(month, 10), Number.parseInt(day, 10), moment);
    } else {
      return null;
    }
  }
  _module_exports = {
    parsePoint,
    point: makePoint,
    wrapMoment(value) {
      return wrapMoment(value.clone().hour(0).minute(0).second(0).millisecond(0));
    }
  };
  return _module_exports;
})();

/**
 * MODULE shared\validatedDate.js
 **/
const _module_shared_validatedDate = (function shared_validatedDate(
  _module_shared_date,
  _module_shared_result,
  _module_shared_validatedValue
) {
  let _module_exports = {};

  const Date = _module_shared_date;
  const {
    Result
  } = _module_shared_result;
  const {
    ValidatedValue
  } = _module_shared_validatedValue;
  
  /** @typedef {import('./date').Date} Date */
  /** @typedef {import('./intervals').Interval} Interval */
  
  /**
   * A callback called when date changes.
   * @callback ChangeCallback
   * @param {Result} value A Result object, which in successful state contains the date,
   * and in error state contains a validation/parse error.
   * @param {boolean} isReset Indicates whether the change is a result of a reset call (as opposed to set call).
   */
  
  /**
   * A callback used to validate date.
   * @callback ValidateCallback
   * @param {Date} value The date to be validated.
   * @returns {Result} A Result object, which in successful state contains the date,
   * and in error state contains a validation error.
   */
  
  /**
   * Represents options used when creating instance of ValidatedDate.
   * @typedef {Object} Options
   * @property {?Date} [initialValue=""] Initial value of the date.
   * @property {?ChangeCallback} [change=null] A function that is called with the date, when it changes.
   * @property {?Interval<Date>} [bounds=null] Period that specifies bounds of the date, if set.
   * @property {?boolean} [isAvailable=false] Indicates whether the date is initially available.
   * @property {?ValidateCallback} [validate=null] A function that returns a valid date, given parsed date.
   */
  
  /**
   * Represents a date value parsed from a string (text value).
   * @param {Options} options The options.
   * @param {Function} moment Function returning a Moment instance.
   */
  function ValidatedDate(options, moment) {
    var _options$initialValue, _options$change;
    this.input = new ValidatedValue({
      change: (value, isReset) => this._change(value, isReset),
      initialTextValue: (_options$initialValue = options.initialValue) === null || _options$initialValue === void 0 ? void 0 : _options$initialValue.format("input"),
      isAvailable: options.isAvailable,
      validate: value => validDate(value, this._bounds, moment).then(validDate => {
        var _options$validate, _options$validate2;
        return (_options$validate = (_options$validate2 = options.validate) === null || _options$validate2 === void 0 ? void 0 : _options$validate2.call(options, validDate)) !== null && _options$validate !== void 0 ? _options$validate : validDate;
      })
    });
    this._bounds = options.bounds;
    this._change = (_options$change = options.change) !== null && _options$change !== void 0 ? _options$change : () => {};
  }
  ValidatedDate.prototype = {
    /**
     * Returns the date, if it is available.
     * @returns {?Result} A Result object, which in successful state contains the date,
     * and in error state contains a validation/parse error.
     * Null if the date is not yet available.
     */
    get availableValue() {
      return this.input.availableValue;
    },
    get textValue() {
      return this.input.textValue;
    },
    set textValue(value) {
      this.input.textValue = value;
    },
    /**
     * Returns the date.
     * @returns {?Result} A Result object, which in successful state contains the date,
     * and in error state contains a validation/parse error.
     */
    get value() {
      return this.input.value;
    },
    /**
     * Sets new date and bounds period and makes the date available.
     * @param {?Date} value The date to set.
     * @param {?Interval<Date>} [bounds=undefined] The bounds period to set.
     * If undefined, the bounds period is left unchanged.
     */
    set(value, bounds) {
      if (bounds !== undefined) {
        this._bounds = bounds;
      }
      this.input.set(value === null || value === void 0 ? void 0 : value.format("input"));
    },
    /**
     * Sets new date and bounds period and makes the date no longer available.
     * @param {?Date} [value=undefined] The date to set.
     * If undefined, the date is set to the initial date.
     * @param {?Interval<Date>} [bounds=undefined] The bounds period to set.
     * If undefined, the bounds period is left unchanged.
     */
    reset(value, bounds) {
      var _value$format;
      if (bounds !== undefined) {
        this._bounds = bounds;
      }
      this.input.reset(value !== undefined ? (_value$format = value === null || value === void 0 ? void 0 : value.format("input")) !== null && _value$format !== void 0 ? _value$format : null : undefined);
    },
    /**
     * Makes the date available.
     * @returns {Result} A Result object, which in successful state contains the date,
     * and in error state contains a validation/parse error.
     */
    validate() {
      return this.input.validate();
    }
  };
  function validDate(value, bounds, moment) {
    if ((value !== null && value !== void 0 ? value : "") === "") {
      return Result.error("Indtast dato");
    }
    const validValue = Date.parsePoint(value, moment);
    if (!validValue) {
      return Result.error("Ugyldig dato");
    }
    if ((bounds === null || bounds === void 0 ? void 0 : bounds.contains(validValue)) === false) {
      return Result.error("Datoen skal v\xE6re inden for perioden (".concat(bounds.start.toString(), " - ").concat(bounds.end.addDays(-1).toString(), ")"));
    }
    return Result.success(validValue);
  }
  _module_exports = {
    ValidatedDate,
    validDate
  };
  return _module_exports;
})(_module_shared_date,
  _module_shared_result,
  _module_shared_validatedValue);

/**
 * MODULE shared\api.js
 **/
const _module_shared_api = (function shared_api() {
  let _module_exports = {};

  function arrayToQueryString(name, value) {
    return value.map(x => {
      var _x$toJSON, _x$toJSON2;
      return "".concat(name, "=").concat((_x$toJSON = x === null || x === void 0 ? void 0 : (_x$toJSON2 = x.toJSON) === null || _x$toJSON2 === void 0 ? void 0 : _x$toJSON2.call(x)) !== null && _x$toJSON !== void 0 ? _x$toJSON : x);
    }).join("&");
  }
  function responseError(result) {
    var _ref, _result$response$data, _result$response, _result$response$data2, _result$response2, _result$response2$dat, _result$response2$dat2;
    return Promise.reject((_ref = (_result$response$data = result === null || result === void 0 ? void 0 : (_result$response = result.response) === null || _result$response === void 0 ? void 0 : (_result$response$data2 = _result$response.data) === null || _result$response$data2 === void 0 ? void 0 : _result$response$data2.Message) !== null && _result$response$data !== void 0 ? _result$response$data : result === null || result === void 0 ? void 0 : (_result$response2 = result.response) === null || _result$response2 === void 0 ? void 0 : (_result$response2$dat = _result$response2.data) === null || _result$response2$dat === void 0 ? void 0 : (_result$response2$dat2 = _result$response2$dat.Error) === null || _result$response2$dat2 === void 0 ? void 0 : _result$response2$dat2.Message) !== null && _ref !== void 0 ? _ref : result.message);
  }
  _module_exports = {
    arrayToQueryString,
    responseError
  };
  return _module_exports;
})();

/**
 * MODULE creation_of_absence\api.js
 **/
const _module_widget_api = (function widget_api(
  _module_shared_api,
  _module_shared_date,
  _module_shared_intervals
) {
  let _module_exports = {};

  const api = _module_shared_api;
  const date = _module_shared_date;
  const intervals = _module_shared_intervals;
  
  /** @typedef {import('../shared/date').Date} Date */
  /** @typedef {import('../shared/intervals').Interval} Interval */
  /** @typedef {import('../shared/time').MinuteTime} MinuteTime */
  
  /**
   * A code of an absence reason.
   * @typedef {*} AbsenceReasonCode
   */
  
  /**
   * An id of an absence reason.
   * @typedef {*} AbsenceReasonId
   */
  
  /**
   * An absence reason.
   * @typedef {Object} AbsenceReason
   * @property {AbsenceReasonCode} code Code of the absence reason.
   * @property {string} description Description of the absence reason.
   * @property {AbsenceReasonId} id Id of the absence reason.
   */
  
  /**
   * A school.
   * @typedef {Object} School
   * @property {SchoolCode} code Code of the school.
   * @property {string} name Name of the school.
   * @property {SchoolYear[]} schoolYears School years available in the school.
   */
  
  /**
   * A school code.
   * @typedef {*} SchoolCode
   */
  
  /**
   * A school year.
   * @typedef {Object} SchoolYear
   * @property {AbsenceReason[]} absenceReasons Absence reasons available in the school year.
   * @property {string} name Name of the school year.
   * @property {Interval<Date>} period Period of the school year.
   * @property {string} schoolAbsencePolicy The school's absence policy.
   */
  
  /**
   * An absence to be added.
   * @typedef {Object} Absence
   * @property {string} description Description of the absence.
   * @property {AbsenceReasonId} reasonId Id of absence reason of the absence.
   * @property {SchoolCode} schoolCode Code of the school of the absence.
   * @property {bool} showInAulaAndWeb Determines whether the absence is shown in Aula and Web.
   * @property {Date} startDate Start date of the absence.
   * @property {?Interval<MinuteTime>} timePeriod Time period of the absence.
   */
  
  /**
   * @classdesc An absence API.
   * @constructor
   * @param {Function} axios Function returning the Axios instance.
   * @param {Function} token Function returning a promise resolving to an Aula token.
   * @param {Function} moment Function returning the Moment instance.
   */
  function Api(axios, token, moment) {
    this._moment = moment;
    this._axios = () => {
      return token().then(x => axios().create({
        headers: {
          Authorization: x
        }
      }));
    };
  }
  Api.prototype = {
    /**
     * Adds an absence.
     * @param {Absence} absence Absence to be added.
     * @returns {Promise}
     */
    addAbsence(absence) {
      return this._axios().then(axios => {
        var _absence$timePeriod, _absence$timePeriod2;
        return axios.post("https://personale.api.kmd.dk/aula/api/v2/absences/", {
          description: absence.description,
          endTime: (_absence$timePeriod = absence.timePeriod) === null || _absence$timePeriod === void 0 ? void 0 : _absence$timePeriod.end.addMinutes(-1),
          reasonId: absence.reasonId,
          schoolCode: absence.schoolCode,
          showInAulaAndWeb: absence.showInAulaAndWeb,
          startDate: absence.startDate,
          startTime: (_absence$timePeriod2 = absence.timePeriod) === null || _absence$timePeriod2 === void 0 ? void 0 : _absence$timePeriod2.start
        }, {
          responseType: "json"
        });
      }).then(response => response.data, api.responseError);
    },
    /**
     * Gets schools with specified school codes.
     * @param {SchoolCode[]} schoolCodes Codes of the schools.
     * @returns {Promise<School[]>} Schools accessible to the user.
     */
    schools(schoolCodes) {
      return this._axios().then(axios => axios.get("https://personale.api.kmd.dk/aula/api/v2/absences/schools?".concat(api.arrayToQueryString("schoolCodes", schoolCodes)), {
        responseType: "json"
      })).then(response => response.data.map(x => ({
        code: x.code,
        name: x.name,
        schoolYears: x.schoolYears.map(y => ({
          absenceReasons: y.absenceReasons,
          name: y.name,
          period: intervals.nonEmpty(date.parsePoint(y.period.start, this._moment), date.parsePoint(y.period.end, this._moment).addDays(1)),
          schoolAbsencePolicy: x.schoolAbsencePolicy
        }))
      })), api.mapError);
    }
  };
  _module_exports = {
    Api
  };
  return _module_exports;
})(_module_shared_api,
  _module_shared_date,
  _module_shared_intervals);

/**
 * WIDGET CODE
 **/

const {
  AulaTokenMixin
} = _module_shared_aula;
const {
  ErrorIndicator
} = _module_shared_errorIndicator;
const {
  ErrorList
} = _module_shared_errorList;
const {
  ProgressIndicator
} = _module_shared_progressIndicator;
const {
  ProgressList
} = _module_shared_progressList;
const {
  Result
} = _module_shared_result;
const {
  SingleSelection
} = _module_shared_singleSelection;
const {
  SingleSelectionInput
} = _module_shared_singleSelectionInput;
const {
  ValidatedDate
} = _module_shared_validatedDate;
const {
  ValidatedDateInput
} = _module_shared_validatedDateInput;
const {
  ValidatedSingleSelection
} = _module_shared_validatedSingleSelection;
const {
  ValidatedSingleSelectionInput
} = _module_shared_validatedSingleSelectionInput;
const {
  ValidatedTimeInput
} = _module_shared_validatedTimeInput;
const {
  ValidatedTimePeriod
} = _module_shared_validatedTimePeriod;
const {
  ValidatedValue
} = _module_shared_validatedValue;
const {
  ValidatedValueInput
} = _module_shared_validatedValueInput;
const {
  WidgetHeader
} = _module_shared_widgetHeader;
const {
  Api
} = _module_widget_api;
// @vue/component
export default {
  name: "CreationOfAbsence",
  components: {
    "error-indicator": ErrorIndicator,
    "progress-indicator": ProgressIndicator,
    "single-selection-input": SingleSelectionInput,
    "validated-date-input": ValidatedDateInput,
    "validated-single-selection-input": ValidatedSingleSelectionInput,
    "validated-time-input": ValidatedTimeInput,
    "validated-value-input": ValidatedValueInput,
    "widget-header": WidgetHeader
  },
  mixins: [AulaTokenMixin],
  props: {
    axios: {
      type: Function,
      required: true
    },
    placement: {
      type: String,
      required: true
    },
    getAulaToken: {
      type: Function,
      required: true
    },
    institutionFilter: {
      type: Array,
      required: true
    },
    moment: {
      type: Function,
      required: true
    }
  },
  data() {
    const api = new Api(() => this.axios, () => this.token, () => this.moment);
    return {
      absence: {
        timePeriod: new ValidatedTimePeriod({}),
        description: new ValidatedValue({
          validate: x => this.validDescription(x)
        }),
        reasonId: new ValidatedSingleSelection({
          fallback: {
            text: "Vælg en fraværsårsag",
            value: "fallback"
          }
        }),
        showInAulaAndWeb: false,
        startDate: new ValidatedDate({}, () => this.moment),
        useTimePeriod: false
      },
      api,
      errors: new ErrorList(),
      progress: new ProgressList(),
      school: new SingleSelection({
        change: school => this.selectSchool(school),
        fallback: {
          value: null,
          text: "Vælg skole"
        },
        preselectFirst: true
      }),
      schools: [],
      schoolYear: new SingleSelection({
        change: schoolYear => this.selectSchoolYear(schoolYear),
        fallback: {
          value: null,
          text: "Vælg skoleår"
        },
        preselectFirst: true
      }),
      successfulSaveCountdown: 0
    };
  },
  watch: {
    institutionFilter() {
      this.refresh();
    }
  },
  mounted() {
    this.moment.locale("da");
    this.refresh();
  },
  methods: {
    refresh() {
      if (this.placement === "NoticeBoard") {
        return;
      }
      this.errors.track({
        id: "schools",
        message: "Kunne ikke indlæse skoler",
        retry: true,
        dismissible: false
      }, () => this.progress.track({
        message: "Indlæser skoler..."
      }, () => {
        var _this$institutionFilt;
        this.schools = [];
        return this.api.schools((_this$institutionFilt = this.institutionFilter) !== null && _this$institutionFilt !== void 0 ? _this$institutionFilt : []).then(schools => {
          this.schools = schools;
          this.school.update(schools.map(x => ({
            text: x.name,
            value: x
          })));
        });
      }));
    },
    selectSchool(school) {
      this.schoolYear.update(school.schoolYears.map(x => ({
        text: x.name,
        value: x
      })));
    },
    selectSchoolYear(schoolYear) {
      this.absence.reasonId.update(schoolYear.absenceReasons.map(x => ({
        text: x.description,
        value: x.id
      })));
      this.absence.startDate.reset(undefined, schoolYear.period);
    },
    save() {
      const description = this.absence.description.validate();
      const timePeriod = this.absence.useTimePeriod ? this.absence.timePeriod.validate() : Result.success(null);
      const startDate = this.absence.startDate.validate();
      const reasonId = this.absence.reasonId.validate();
      if (!description.isSuccess || !timePeriod.isSuccess || !startDate.isSuccess || !reasonId.isSuccess || !this.school.value || !this.schoolYear.value) {
        return;
      }
      this.errors.track("Kunne ikke gemme fravær", () => this.progress.track("Gemmer...", () => this.api.addAbsence({
        description: description.value,
        reasonId: reasonId.value,
        schoolCode: this.school.value.code,
        showInAulaAndWeb: this.absence.showInAulaAndWeb,
        startDate: startDate.value,
        timePeriod: timePeriod.value
      }))).then(() => {
        this.successfulSaveCountdown = 5;
        this.absence.description.reset();
        this.absence.reasonId.reset();
        this.absence.showInAulaAndWeb = false;
        this.absence.startDate.reset();
        this.absence.timePeriod.reset();
        this.absence.useTimePeriod = false;
      });
    },
    validDescription(value) {
      const trimmed = (value || "").trim();
      if (trimmed.length == 0) {
        return Result.error("Indtast et beskrivelse");
      }
      if (trimmed.length > 50) {
        return Result.error("Indtast et beskrivelse, der er kortere end 50 tegn");
      }
      return Result.success(trimmed);
    }
  }
};

</script>
<style scoped>
/* Overrides for Aula styles that conflict with ours */

.root /deep/ .custom-control > input.custom-control-input[type="checkbox"] {
  /* Reset position from 'relative' to 'absolute', set by 'input[type="checkbox"]' in 'form.scss'.
     This removes the element from document flow when inside of a 'b-checkbox' component,
     and so removes an unnecessary top "margin" within the component. */
  position: absolute;
}

/* Styles for MultipleSelectionInput component, code in 'shared/multipleSelectionInput.js' */

.root /deep/ .btn-input {
  background-color: white;
  color: rgb(34, 35, 80);
}

.root /deep/ .multiple-selection-input-dropdown .dropdown-menu {
  padding-bottom: 0;
  padding-top: 0;
}

.root /deep/ .multiple-selection-input-dropdown .dropdown-toggle {
  /* Use flex to force chevron to stay in the same line as text */
  display: flex;
  align-items: center;
  justify-content: stretch;
  /* Use for left and right padding the value of top/bottom padding */
  padding-left: 14px;
  padding-right: 14px;
}

.root /deep/ .multiple-selection-input-dropdown .select {
  margin: 0;
  padding: 0;
}

.root /deep/ .multiple-selection-input-dropdown .text {
  flex-grow: 1;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* Styles for ProgressIndicator component, code in 'shared/progressIndicator.js' */

.root /deep/ .progress-indicator-root {
  /* Create block formatting context for absolutely positioned status,
     by setting position to relative */
  position: relative;
}

.root /deep/ .progress-indicator-status {
  /* Position spinner and message at the top center of content, above it */
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  z-index: 2000;
}

.root /deep/ .progress-indicator-content[data-obscured] {
  /* Make the content obscured by the status */
  opacity: 0.1;
  /* Expand content vertically to encompass absolutely positioned status */
  min-height: 2em;
}

/* Styles for WidgetHeader component, code in 'shared/widgetHeader.js' */

.root /deep/ .widget-header {
  --margin: 0.5rem 0 0.4rem 0;
}

.root /deep/ .widget-header h2 {
  padding: 0;
  margin: var(--margin);
}

.root /deep/ .widget-header p {
  font-weight: 800;
  color: #aaa;
  margin: var(--margin);
  padding: 0;
}

.root /deep/ .widget-header-separator {
  border-bottom: 2px solid #549ec7;
  margin-bottom: 0.5em;
}

/* Styles for utility CSS classes */

/* no-text-overflow prevents text from overflowing by wrapping it */

.root /deep/ .no-text-overflow,
.root /deep/ .no-text-overflow * {
  text-overflow: ellipsis;
  overflow: hidden;
  /* There is a problem with text that contains words longer than the width of the viewport.
     In such situations, the text might still overflow, making the body to be wider than the viewport.
     Because of that, we let the text break between words, and too long words to break anywhere inside it.
     However, this can only be achieved with 'overflow-wrap' set to 'anywhere',
     which is not supported yet on all browsers.
     When it is not supported, we set the property to 'break-word',
     but then too long words are not broken in some situations, namely when:
      - when any of element's parent element is a child of a flexbox container,
        and its 'overflow' is not set to 'hidden` or its `min-width` is not set to '0';
        this cannot be worked around reliably as we do not control anything above widget's root element;
      - the element is a table cell;
        to work around this we could set the element's 'max-width' to something different than 'auto';
        however any value set would result in suboptimal use of available space,
        when the element could receive more available white space, but does not.
     These same issues apply to setting of 'white-space' property to 'nowrap`.
     Hopefully this should not be an issue very often,
     as very long words are used rarely in any kind of names. */
  overflow-wrap: break-word;
  overflow-wrap: anywhere;
}

.root /deep/ .no-text-overflow.dropdown-item,
.root /deep/ .no-text-overflow.dropdown-header {
  /* Re-enable text wrapping in dropdown items as otherwise the text will overflow,
     if wider than the width of the viewport.
     The drawback is that long enough text will break,
     even if there is enough horizontal space to fit it in one line. */
  white-space: normal;
}

.root /deep/ .width-scale-35 {
  --width-scale: 0.35;
}
</style>
