



























import {computed, PropType} from "vue";
import {AxiosInstance} from "axios";
import {Moment} from "moment";

export default {
  props: {
    widgetID: String,
    axios: {
      type: Function as PropType<AxiosInstance>
    },
    moment: {
      type: Object as PropType<Moment>
    },
    sessionUUID: String,
    placement: String,
    currentWeekNumber: String,
    isMobileApp: Boolean,
    userProfile: String,
    group: String,
    source: String,
    institutionCode: String,
    triggerStepUp: Function,
    assuranceLevel: Number,
    childFilter: {
      type: Array as PropType<string[]>
    },
    institutionFilter: {
      type: Array as PropType<string[]>
    },
    getAulaToken: Function,
    aulaToken: String,
  },
  setup(props: any) {
    const propKeys = computed(() => {
      return Object.keys(props).filter((key) => !['axios', 'moment', 'getAulaToken', 'triggerStepUp'].includes(key));
    });
    const propsList = computed(() => {
      return propKeys.value.filter((key: string) => props[key]).map((key: any) => {
        return {
          key,
          value: props[key],
        };
      })
    });
    const updateAulaToken = () => {
        props.getAulaToken();
    };
    const runStepUp = () => {
        props.triggerStepUp();

    };

    return {
      propKeys,
      propsList,
      updateAulaToken,
      runStepUp
    }
  }
}
