import { render, staticRenderFns } from "./WidgetContent.vue?vue&type=template&id=b6e13652&scoped=true&"
import script from "./WidgetContent.vue?vue&type=script&lang=js&"
export * from "./WidgetContent.vue?vue&type=script&lang=js&"
import style0 from "./WidgetContent.vue?vue&type=style&index=0&id=b6e13652&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b6e13652",
  null
  
)

export default component.exports