<script>

import Search from '../components/search'
import ConsentBox from '../components/consent-box'
import RecipientType from '../components/recipient-type'
import SelectedRecipients from '../components/selected-recipients'
import SelectFromClass from '../components/select-from-class'
import { interpolate } from '../util'


const SelectRecipientsScreen = {
    props: {
        selectRecipientsMode: String,
        selected: Object,
        selectedRecipients: Array,
        teachers: Array,
        recipientSearch: String,
        clearRecipientSearch: Function,
        setRecipientSearch: Function,
        unSelectRecipient: Function,
        removeSelectedRecipients: Function,
        giveConsentUsers: Array,
        filteredRecipients: Array,
        filteredTeachers: Array,
        filteredTutors: Array,
        filteredStudents: Array,
        filteredContactPersons: Array,
        filteredInspectors: Array,
        giveConsent: Function,
        isRemovedSelectedRecipientsNotEmpty: Boolean,
        anySelectedRecipients: null,
        classSearch: String,
        clearClassSearch: Function,
        setClassSearch: Function,
        filteredClasses: Array,
        unSelectAllGroupType: Function,
        selectAllGroupTypeByClass: Function,
        unSelectAllGroupTypeByClass: Function,
        translations: Object,
        undoRemoveSelectedRecipients: Function,
        keyedClasses: Object,
    },
    components: {
        'search': Search,
        'consent-box': ConsentBox,
        'recipient-type': RecipientType,
        'selected-recipients': SelectedRecipients,
        'select-from-class': SelectFromClass,
    },
    methods: {
        interpolate,
    },
}

export default SelectRecipientsScreen

</script>

<template>
    <div class="select-recipients-screen">
        <div class="header d-flex">
            <div class="header-heading">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" class="header-icon left pointer"
                     @click="$emit('close-select-recipients-screen')">
                    <path d="M20.393 5.333l0.94 0.94-8.78 9.727 8.78 9.727-0.94 0.94-9.727-10.667 9.727-10.667z" />
                </svg>
                <h3 class="header-title">{{ translations.selectRecipientsScreen.headerTitle }}</h3>
            </div>
            <div class="done-selecting-button" v-on:click="$emit('close-select-recipients-screen')">
                {{ translations.selectRecipientsScreen.done }}
            </div>
        </div>
        <div class="select-recipients-container main-screen-content scrollable">
            <div class="container-padding">
                <div class="recipient-category-buttons">
                    <a :class="[selectRecipientsMode === 'people' && 'active', 'recipient-category-button pointer']"
                       @click="$emit('select-recipients-by-people')">{{ translations.selectRecipientsScreen.people }}</a>
                    <a :class="[selectRecipientsMode === 'group' && 'active', 'recipient-category-button pointer']"
                       @click="$emit('select-recipients-by-group')">{{ translations.selectRecipientsScreen.groups }}</a>
                </div>
            </div>
            <div v-if="selectRecipientsMode === 'people'" class="select-by-people">
                <div style='padding: 0 15px 15px 15px'>
                    <search
                        :placeholder="translations.selectRecipientsScreen.peopleSearchPlaceholder"
                        :search="recipientSearch"
                        :search-clear='clearRecipientSearch'
                        :search-change='setRecipientSearch'
                    >
                    </search>
                    <selected-recipients
                        :translations='translations'
                        :visible-without-any-selections='false'
                        :selected="selected"
                        :selected-recipients='selectedRecipients'
                        :un-select-recipient='unSelectRecipient'
                        :un-select-all-group-type='unSelectAllGroupType'
                        :un-select-all-group-type-by-class='unSelectAllGroupTypeByClass'
                        @unselect-recipient="recipient => selectRecipient(recipient)"
                        @unselect-all-recipients="removeSelectedRecipients"
                    >
                    </selected-recipients>
                    <consent-box
                        v-if="giveConsentUsers.length > 0"
                        :sub-header-text="anySelectedRecipients ? translations.selectRecipientsScreen.consentBoxSubheaderSelectedRecipients : translations.selectRecipientsScreen.consentBoxSubheaderPeople"
                        :users="giveConsentUsers"
                        :give-consent="() => giveConsent(giveConsentUsers)"
                        :translations='translations'
                    >
                    </consent-box>
                    <h3 class="recipient-amount-description">{{ interpolate(
                        recipientSearch ? translations.selectRecipientsScreen.peopleAmount : translations.selectRecipientsScreen.resultsAmount,
                        { count: filteredRecipients.length },
                    ) }}</h3>
                </div>
                <recipient-type
                    role="teacher"
                    :translations='translations'
                    :header="translations.general.roles.teachers"
                    :recipients="filteredTeachers"
                    :selected-users="selected.users"
                    :keyed-classes='keyedClasses'
                    :give-consent='giveConsent'
                    @add-recipient="recipient => $emit('select-recipient', recipient)"
                    @remove-recipient="recipient => unSelectRecipient(recipient)"
                >
                </recipient-type>
                <recipient-type
                    role="tutor"
                    :translations='translations'
                    :header="translations.general.roles.tutors"
                    :recipients="filteredTutors"
                    :selected-users="selected.users"
                    :keyed-classes='keyedClasses'
                    :give-consent='giveConsent'
                    @add-recipient="recipient => $emit('select-recipient', recipient)"
                    @remove-recipient="recipient => unSelectRecipient(recipient)"
                >
                </recipient-type>
                <recipient-type
                    role="student"
                    :translations='translations'
                    :header="translations.general.roles.students"
                    :recipients="filteredStudents"
                    :selected-users="selected.users"
                    :keyed-classes='keyedClasses'
                    :give-consent='giveConsent'
                    @add-recipient="recipient => $emit('select-recipient', recipient)"
                    @remove-recipient="recipient => unSelectRecipient(recipient)"
                >
                </recipient-type>
                <recipient-type
                    role="contact_person"
                    :translations='translations'
                    :header="translations.general.roles.contacts"
                    :recipients="filteredContactPersons"
                    :selected-users="selected.users"
                    :keyed-classes='keyedClasses'
                    :give-consent='giveConsent'
                    @add-recipient="recipient => $emit('select-recipient', recipient)"
                    @remove-recipient="recipient => unSelectRecipient(recipient)"
                >
                </recipient-type>
                <recipient-type
                    role="inspector"
                    :translations='translations'
                    :header="translations.general.roles.inspectors"
                    :recipients="filteredInspectors"
                    :selected-users="selected.users"
                    :keyed-classes='keyedClasses'
                    :give-consent='giveConsent'
                    @add-recipient="recipient => $emit('select-recipient', recipient)"
                    @remove-recipient="recipient => unSelectRecipient(recipient)"
                >
                </recipient-type>
            </div>
            <div v-else-if="selectRecipientsMode = 'group'" class="select-by-group">
                <div class="container-padding">
                    <selected-recipients
                        :translations='translations'
                        :visible-without-any-selections='false'
                        :selected="selected"
                        :selected-recipients='selectedRecipients'
                        :un-select-recipient='unSelectRecipient'
                        :un-select-all-group-type='unSelectAllGroupType'
                        :un-select-all-group-type-by-class='unSelectAllGroupTypeByClass'
                        @unselect-recipient="recipient => $emit('select-recipient', recipient)"
                        @unselect-all-recipients="removeSelectedRecipients"
                    >
                    </selected-recipients>
                    <div class="group-selection-section">
                        <div class="group-selection-types-container">
                            <div class="pointer group-selection-type-container"
                                 v-bind:class="{checked: selected.teachers === 'all'}"
                                 @click="selected.teachers !== 'all' ? $emit('select-all-group-type', 'teachers') : $emit('un-select-all-group-type','teachers')">
                                <div class="form-check">
                                    <input class="form-check-input select-group-checkbox" type="checkbox" value="" id="all-teachers"
                                           :checked="selected.teachers === 'all'">
                                    <span class="select-group-label">
                                                    {{ translations.general.roles.all.teachers }}
                                                </span>
                                </div>
                            </div>

                            <div class="pointer group-selection-type-container"
                                 v-bind:class="{checked: selected.tutors === 'all'}"
                                 @click="selected.tutors !== 'all' ?  $emit('select-all-group-type', 'tutors') : $emit('un-select-all-group-type','tutors')">
                                <div class="form-check">
                                    <input class="form-check-input select-group-checkbox" type="checkbox" value="" id="all-tutors" :checked="selected.tutors === 'all'">
                                    <span class="select-group-label">
                                                    {{ translations.general.roles.all.tutors }}
                                                </span>
                                </div>
                            </div>

                            <div class="pointer group-selection-type-container"
                                 v-bind:class="{checked: selected.students === 'all'}"
                                 @click="selected.students !== 'all' ?  $emit('select-all-group-type', 'students') : $emit('un-select-all-group-type','students')">
                                <div class="form-check">
                                    <input class="form-check-input select-group-checkbox" type="checkbox" value="" id="all-students"
                                           :checked="selected.students === 'all'">
                                    <span class="select-group-label">
                                                    {{ translations.general.roles.all.students }}
                                                </span>
                                </div>
                            </div>

                            <div class="pointer group-selection-type-container"
                                 v-bind:class="{checked: selected.contactPersons === 'all'}"
                                 @click="selected.contactPersons !== 'all' ?  $emit('select-all-group-type', 'contactPersons') : $emit('un-select-all-group-type','contactPersons')">
                                <div class="form-check">
                                    <input class="form-check-input select-group-checkbox" type="checkbox" :checked="selected.contactPersons === 'all'">
                                    <span class="select-group-label">{{ translations.general.roles.all.contacts }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="group-selection-section">
                        <h6 class="group-selection-heading">{{ translations.selectRecipientsScreen.classes }}</h6>
                        <search
                            :placeholder="translations.selectRecipientsScreen.classSearchPlaceholder"
                            :search="classSearch"
                            :search-clear='clearClassSearch'
                            :search-change='setClassSearch'
                        >
                        </search>
                        <div class="group-selection-classes-list" v-for="currentClass in filteredClasses" :key="currentClass.id">
                            <select-from-class
                                :search='classSearch'
                                :current-class="currentClass"
                                :selected-students="selected.students"
                                :selected-contact-persons="selected.contactPersons"
                                :select-class-role="selectAllGroupTypeByClass"
                                :unselect-class-role="unSelectAllGroupTypeByClass"
                                :translations="translations "
                            >
                            </select-from-class>
                        </div>
                    </div>
                </div>
            </div>
            <div class="undo-remove" v-if="isRemovedSelectedRecipientsNotEmpty" @click="undoRemoveSelectedRecipients">
                <p class="undo-text">{{ translations.selectRecipientsScreen.peopleRemoved }}</p>
                <div class="undo-button pointer">{{ translations.selectRecipientsScreen.undo }}</div>
            </div>
        </div>
    </div>
</template>