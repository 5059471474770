<template>
  <div class="vacation-response-col">
    <slot />
  </div>
</template>
<script lang="js">
export default {
  name: 'VacationIntervalsFragmentDayCol',
}
</script>
<style lang="scss" scoped>
.vacation-response-col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  padding-top: var(--padding-top, 15px);
  padding-bottom: var(--padding-bottom, 15px);
  padding-inline-start: var(--padding-start, 15px);
  padding-inline-end: var(--padding-end, 15px);
}
</style>
