<template>
  <div
    v-click-outside="hideCalendarOverview"
    class="aula-calendar daily-overview"
    tabindex="0"
    :class="{ mobile: !isAside, 'daily-close': isAside }"
    @click="showCalendarOverview"
    @keydown.enter="showCalendarOverview"
    @focus="showCalendarOverview"
  >
    <div class="week-number">
      {{ 'Uge ' + weekNumber }}
    </div>
    <FullCalendar
      v-if="isProfileLoaded"
      id="daily-calendar"
      ref="fullCalendar"
      default-view="resourceTimeGridDay"
      :plugins="calendarPlugins"
      locale="da"
      lang="da"
      :weekends="true"
      :all-day-slot="true"
      all-day-text=" "
      :title-format="{ month: 'long', day: '2-digit', year: 'numeric' }"
      :week-numbers="false"
      week-number-title="Uge"
      :slot-label-format="{ hour: '2-digit', minute: '2-digit' }"
      :slot-event-overlap="false"
      :column-header-format="{ month: 'long', day: '2-digit', year: 'numeric' }"
      min-time="08:00:00"
      max-time="18:00:00"
      content-height="auto"
      :time-grid-event-min-height="25"
      :group-by-date-and-resource="true"
      :resources="resources"
      :resource-render="resourceRender"
      :events="fetchEvents"
      :event-render="eventRender"
      :dates-render="datesRender"
      scheduler-license-key="0851031242-fcs-1572871596"
      :button-icons="{
        prev: ' icon-Aula_back-triangle',
        next: ' icon-Aula_forward-triangle',
      }"
      :header="{ left: 'today title prev,next', right: '' }"
      :button-text="{
        today: 'I dag',
        month: 'Måned',
        agendaWeek: 'Uge',
        agendaDay: 'Dag',
      }"
      :event-limit="allDayEventLimit"
      :event-limit-text="'LOAD_MORE_LOWERCASE' | fromTextKey"
      :event-limit-click="onEventLimitClick"
    />
    <b-btn
      v-if="!isMobile"
      variant="link"
      class="daily-overview-close"
      :aria-label="'ARIA_LABEL_CLOSE_CALENDAR' | fromTextKey"
      @click.stop="hideCalendarOverview()"
    >
      <i class="icon-Aula_close" aria-hidden="true" />
    </b-btn>
    <b-button variant="link" :class="!isMobile ? 'pull-right' : ''" size="sm" @click="goToCalendar">
      {{ 'DAILY_OVERVIEW_SEE_CALENDAR' | fromTextKey }}<i class="icon-Aula_forward-arrow" />
    </b-button>
    <important-date-overview
      v-if="isAside"
      @showCalendarOverview="showCalendarOverview"
      @hideCalendarOverview="hideCalendarOverview"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { mapGetters } from 'vuex';
import { types } from '../../store/types/types';
import moment from 'moment-timezone';
import ImportantDateOverview from './ImportantDateOverview.vue';
import { Cookie } from '../../../shared/assets/plugins/cookie';
import { moduleWidgetPlacements } from '../../../shared/enums/moduleWidgetPlacements';
import { moduleTypes } from '../../../shared/enums/moduleTypes';
import { portalRoles } from '../../../shared/enums/portalRoles';
import { docTypes } from '../../../shared/enums/docTypes';
import { eventTypeEnum } from '../../../shared/enums/eventTypeEnum';
import Vue from 'vue';
import FullCalendar from '@fullcalendar/vue';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import $ from 'jquery';
import { calendarUtil } from '../../../shared/utils/calendarUtil';
import ContactAvatar from '../contacts/ContactAvatar.vue';
import { userTypes } from '../../../shared/enums/userTypes.enum';

export default {
  components: {
    'important-date-overview': ImportantDateOverview,
    FullCalendar,
  },
  props: {
    isAside: {
      type: Boolean,
      default: true,
    },
  },
  data: function () {
    return {
      calendar: null,
      weekNumber: moment().isoWeek(),
      childrenIds: [],
      moment: moment,
      moduleWidgetPlacements: moduleWidgetPlacements,
      moduleTypes: moduleTypes,
      calendarPlugins: [resourceTimeGridPlugin],
      allDayEventLimit: 3,
    };
  },
  computed: {
    ...mapGetters({
      events: types.GET_EVENTS,
      profile: types.GET_CURRENT_PROFILE,
      institutions: types.GET_INSTITUTIONS,
      activeInstitutions: types.GET_ACTIVE_INSTITUTIONS,
      isProfileLoaded: types.IS_PROFILE_LOADED,
      children: types.GET_CHILDREN,
      activeChildren: types.GET_ACTIVE_CHILDREN,
      menuItems: types.GET_MENU_ITEMS,
      isMobile: types.GET_IS_MOBILE,
      activeGroupHome: types.GET_ACTIVE_GROUP_HOME,
    }),
    defaultProfile() {
      return {
        role: this.profile.role,
        id: this.profile.id,
      };
    },
    resources() {
      let resourcesArray = [];
      if ([portalRoles.GUARDIAN, portalRoles.OTP].indexOf(this.profile.role) > -1) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.childrenIds = this.children.map(child => child.id);
        for (const child of this.children) {
          if (
            this.activeChildren.includes(child.id) &&
            resourcesArray.filter(c => c.profileId == child.profileId).length == 0
          ) {
            const resource = {
              id: docTypes.PROFILE.toLowerCase() + '-' + child.id,
              singleId: child.id,
              title: child.name,
              type: docTypes.PROFILE.toLowerCase(),
              eventClassName: child.id,
              shortName: child.shortName,
              profileId: child.profileId,
              profilePicture: child.profilePicture,
            };
            resourcesArray.push(resource);
          }
        }
      } else {
        resourcesArray = [
          {
            id: docTypes.PROFILE.toLowerCase() + '-' + this.profile.id,
            singleId: this.profile.id,
            type: docTypes.PROFILE.toLowerCase(),
            title: this.profile.firstName + ' ' + this.profile.lastName,
            shortName: this.profile.shortName,
            eventClassName: this.profile.id,
            profilePicture: this.profile.profilePicture,
            profileId: this.profile.profileId,
          },
        ];
      }
      return resourcesArray;
    },
  },
  methods: {
    ...mapActions({
      loadEventsByProfileId: types.LOAD_EVENTS_BY_PROFILEID,
      loadEventByGroupHome: types.LOAD_EVENTS_BY_GROUPHOME,
      clearEvents: types.CLEAR_EVENTS,
    }),
    onEventLimitClick(info) {
      this.setCalendarStylesForPopover(info.moreEl);
      return 'popover';
    },
    setCalendarStylesForPopover() {
      const fcDayHeader = this.$refs.fullCalendar.$el.querySelector('.fc-more-cell');
      const fcViewContainer = this.$refs.fullCalendar.$el.querySelector('.fc-view-container');
      const fcBody = this.$refs.fullCalendar.$el.querySelector('.fc-body');

      if (fcDayHeader && fcViewContainer && fcBody) {
        const popoverOffsetTop = fcBody.getBoundingClientRect().top - fcViewContainer.getBoundingClientRect().top;
        document.documentElement.style.setProperty('--fcDayColumnWidth', fcDayHeader.offsetWidth + 'px');
        document.documentElement.style.setProperty('--fcPopoverOffsetTop', popoverOffsetTop + 'px');
      }
    },
    stripForHtmlTags(value) {
      return value.replace(/<\/?[^>]+(>|$)/g, '');
    },
    showCalendarOverview() {
      if (!this.isMobile) {
        $('.daily-overview').removeClass('daily-close');
        $('.daily-overview').addClass('daily-open');
      }
    },
    shouldHideCalendarOverview(event) {
      if (this.isMobile) {
        return false;
      }

      if (event != null) {
        return !(this.isChildOfFullcalendarToolbar(event.target) || this.isChildOfCalendarOverivew(event.target));
      }

      return true;
    },
    isChildOfFullcalendarToolbar(target) {
      // when using today, prev and next in Fullcalendar toolbar, the target is referencing a shadow dom element.
      // A way to check if we are in the calendar toolbar is to see if the top parent has the class .fc-left
      const fullcalendarToolbarClass = 'fc-left';
      return this.isChildOfNodeWithClass(target, fullcalendarToolbarClass);
    },
    isChildOfCalendarOverivew(target) {
      const dailyOverviewContainer = this.$el;
      return dailyOverviewContainer.contains(target);
    },
    isChildOfNodeWithClass(target, className) {
      let node = target;

      while (node != null) {
        if (node.classList != null && node.classList.contains(className)) {
          return true;
        }
        node = node.parentNode;
      }
      return false;
    },
    hideCalendarOverview(event) {
      if (this.shouldHideCalendarOverview(event)) {
        $('.daily-overview').removeClass('daily-open');
        $('.daily-overview').addClass('daily-close');
      }
    },
    calculateResourcesAndEvents() {
      const eventsArray = [];
      for (const event of this.events) {
        event.startEditable = false;
        if (
          event.resourceIds != null &&
          this.resources.filter(resource => event.resourceIds.includes(String(resource.id))).length > 0 &&
          !event.type.includes('sum-')
        ) {
          for (const resourceId of event.resourceIds) {
            eventsArray.push({
              ...event,
              resourceIds: [resourceId],
              parentId: resourceId,
            });
          }
        }
      }
      return eventsArray;
    },
    resourceRender(renderInfo) {
      const resourceObj = renderInfo.resource;
      const extendedProps = { ...resourceObj.extendedProps, id: parseInt(resourceObj.extendedProps.singleId) };
      renderInfo.el.innerHTML = '';
      const AvatarClass = Vue.extend(ContactAvatar);
      const disabled = extendedProps.type !== userTypes.PROFILE;
      const avatarInstance = new AvatarClass({
        parent: this,
        propsData: {
          profile: extendedProps,
          disabled,
        },
      });
      avatarInstance.$mount();
      avatarInstance.$el.classList.add('calendar-resource');
      renderInfo.el.appendChild(avatarInstance.$el);
    },
    fetchEvents(fetchInfo, successCallback) {
      if (this.profile.role === portalRoles.OTP) {
        const groupHomeChildPairs = this.activeChildren.map(activeChildId => ({
          regardingChildId: activeChildId,
          groupHomeId: this.activeGroupHome.id,
        }));

        let instProfileIds = [this.defaultProfile.id];
        if ([portalRoles.OTP, portalRoles.GUARDIAN].indexOf(this.defaultProfile.role) > -1) {
          instProfileIds = instProfileIds.concat(this.activeChildren);
        }
        const params = {
          groupHomeChildPairs,
          start: moment(fetchInfo.start),
          end: moment(fetchInfo.end),
          instProfileIds,
        };
        this.loadEventByGroupHome(params).then(() => {
          successCallback(this.calculateResourcesAndEvents());
        });
      } else {
        let instProfileIds = [];
        const activeInstitutions = this.institutions.filter(institution =>
          this.activeInstitutions.includes(institution.institutionCode)
        );
        for (const institution of activeInstitutions) {
          if (instProfileIds.filter(id => id == institution.institutionProfileId).length == 0) {
            instProfileIds.push(institution.institutionProfileId);
          }
        }
        if (this.profile.role === portalRoles.GUARDIAN) {
          instProfileIds = instProfileIds.concat(this.children.map(child => child.id));
        }
        this.clearEvents();
        this.loadEventsByProfileId({
          instProfileIds: instProfileIds,
          start: moment(fetchInfo.start),
          end: moment(fetchInfo.end),
        }).then(() => {
          successCallback(this.calculateResourcesAndEvents());
        });
      }
    },
    eventRender(info) {
      const event = info.event;
      const extendedProps = event.extendedProps;
      const element = info.el;
      const title = this.stripForHtmlTags(event.title);
      const isOldEvent = event.classNames.includes('old-event');
      const responseClassName = calendarUtil.getEventResponseStatusClassName({
        event: extendedProps,
        resources: this.resources,
        profile: this.profile,
      });
      element.setAttribute('id', event.id);
      if (!isOldEvent && responseClassName) {
        element.classList.add(responseClassName);
      }
      let html;
      if (event.rendering != 'background') {
        if (extendedProps.type.includes('sum-')) {
          event.rendering = 'background';
        }
        const eventInstitution =
          this.institutions.length > 1
            ? this.institutions.find(inst => inst.institutionProfileId == extendedProps.creatorInstProfileId)
            : undefined;
        // get information from event to show on dayly calendar
        const place = extendedProps.primaryResource != null ? 'Sted: ' + extendedProps.primaryResource.name : '';
        let resources = [];
        if (extendedProps.additionalResources != null && extendedProps.additionalResources.length > 0) {
          extendedProps.additionalResources.forEach(function (element) {
            resources.push(element.name);
          });
          resources = resources.join(', ');
        }
        let mainGroup = '';
        if (extendedProps.type == eventTypeEnum.LESSON && extendedProps.invitedGroups != null) {
          if (extendedProps.invitedGroups.find(group => group.mainGroup) != null) {
            mainGroup = ' (' + extendedProps.invitedGroups.find(group => group.mainGroup).name + ')';
          }
        }

        // render event base on user role
        switch (this.profile.role) {
          case 'employee':
            html =
              '<div class="fc-content">' +
              '<div class="text-truncate ' +
              (eventInstitution !== undefined ? 'p-r-35' : '') +
              '">' +
              '<span class="fc-title">' +
              title +
              mainGroup +
              ' <span class="fc-info">' +
              place +
              (resources.length > 0 ? ' - Ressourcer: ' + resources : '') +
              '</span>' +
              '</span>' +
              '</div>';
            html +=
              eventInstitution !== undefined
                ? '<div class="user-icon" title="' +
                  eventInstitution.name +
                  '">' +
                  '<span>' +
                  eventInstitution.shortName +
                  '</span>' +
                  '</div>' +
                  '</div>'
                : '</div>';
            break;
          case 'guardian':
            html =
              '<div class="fc-content">' +
              '<div class="fc-title">' +
              title +
              '</div>' +
              '<div class="fc-info">' +
              place +
              '</div>' +
              '</div>';
            break;
          // user role is 'child'
          default:
            html =
              '<div class="fc-content">' +
              '<div class="fc-title">' +
              title +
              '</div>' +
              '<div class="fc-info">' +
              place +
              '</div>' +
              '</div>';
            break;
        }
        element.innerHTML = html;
      }
    },
    datesRender(info) {
      const view = info.view;
      if (this.weekNumber != moment(view.currentStart).isoWeek()) {
        this.weekNumber = moment(view.currentStart).isoWeek();
        setTimeout(() => {
          this.showCalendarOverview();
        }, 1);
      }
      // Create aria label for prev and next buttons
      const nextStart = moment(view.currentStart).add(1, 'days').format('D. MMMM YYYY');
      const prevStart = moment(view.currentStart).subtract(1, 'days').format('D. MMMM YYYY');
      $('.fc-next-button').attr('aria-label', Vue.filter('fromTextKey')('ARIA_LABEL_CALENDAR_NEXT') + nextStart);
      $('.fc-prev-button').attr('aria-label', Vue.filter('fromTextKey')('ARIA_LABEL_CALENDAR_PREV') + prevStart);
    },
    goToCalendar() {
      const currentDate = moment(this.calendar.getDate()).format('YYYY-MM-DD');
      Cookie.Create('calendarDate', currentDate);
      this.$router.push({ name: 'calendar' });
    },
    init() {
      if (this.isProfileLoaded) {
        Vue.nextTick(() => {
          this.clearEvents();
          this.calendar = this.$refs.fullCalendar.getApi();
          $('.fc-today-button').removeAttr('disabled');
          $('.fc-today-button').click(function () {
            $('.fc-today-button').removeAttr('disabled');
          });
        });
      }
      if (!Cookie.Read('initialLogin')) {
        this.showCalendarOverview();
        Cookie.Create('initialLogin', true);
        setTimeout(() => {
          this.hideCalendarOverview();
        }, 5000);
      }
    },
  },
  watch: {
    activeInstitutions() {
      if (this.calendar != null) {
        this.clearEvents();
        if (this.calendar != null) {
          this.calendar.refetchEvents();
        }
      }
    },
    isProfileLoaded() {
      this.init();
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';
@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/timegrid/main.css';

.aula-calendar {
  background: white;
  @media only screen and (min-width: 992px) and (max-width: 1149px) {
    width: $layout-right-normal;
  }
  @media only screen and (min-width: 1150px) {
    width: $layout-right-wide;
  }
  padding: 4px;
  height: 100%;

  &.daily-open {
    @media only screen and (min-width: 992px) and (max-width: 1149px) {
      right: $layout-right-normal;
    }
    @media only screen and (min-width: 1150px) {
      right: $layout-right-wide;
    }
    height: 100%;
  }
  &.daily-close {
    right: 60px;
    z-index: 10;
  }
  &.mobile {
    width: 100%;
    padding: 0;
    height: 100vh;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none; /* hide scrollbars in IE 10+ */
    &::-webkit-scrollbar {
      width: 0px; /* remove scrollbar space */
      background: transparent; /* optional: just make scrollbar invisible */
    }
  }
}

.week-number {
  position: absolute;
  top: 85px;
  left: 6px;
  z-index: 1000;
}
.daily-overview-close {
  position: absolute;
  top: 15px;
  right: 10px;
  padding: 10px;
}
.mobile {
  .week-number {
    top: 16px;
    left: 4px;
  }
}
</style>
