<script>

import Search from '../components/search'
import RecipientType from '../components/recipient-type'

const MessageRecipientsScreen = {
    props: {
        recipientSearch: String,
        clearRecipientSearch: Function,
        setRecipientSearch: Function,
        selectedMessageStatuses: Object,
        selectedMessageStatusFilters: Array,
        selectedMessageRecipientsLength: Number,
        selected: Object,
        selectedMessageRecipientsFiltered: Object,
        addStatusFilter: Function,
        navigate: Function,
        removeStatusFilter: Function,
        translations: Object,
        keyedClasses: Object,
    },
    methods: {
        mustHaveStatus: function(users, statuses) {
            return users.filter(user => statuses[user.id])
        },
    },
    computed: {
        statusCounts() {

            const results = {}
            Object.values(this.selectedMessageStatuses).forEach(status => {
                results[status] = results[status] ? results[status] + 1 : 1
            })

            return results
        },
    },
    components: {
        'search': Search,
        'recipient-type': RecipientType,
    },
}

export default MessageRecipientsScreen

</script>

<template>
    <div class="message-recipients-screen">
        <div class="header">
            <div class='header-heading'>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" class="header-icon left pointer" @click="navigate('group-messages-screen')">
                    <path d="M20.393 5.333l0.94 0.94-8.78 9.727 8.78 9.727-0.94 0.94-9.727-10.667 9.727-10.667z" />
                </svg>
                <h3 class="header-title">{{ translations.messageRecipientsScreen.headerTitle }}</h3>
            </div>
        </div>
        <div class='main-screen-content scrollable'>
            <div class="message-recipients-container">
                <div style='margin-bottom: 15px'>
                    <search
                        :placeholder="translations.messageRecipientsScreen.searchPlaceholder"
                        :search="recipientSearch"
                        :search-clear='clearRecipientSearch'
                        :search-change='setRecipientSearch'
                    >
                    </search>
                </div>
                <div class="filter-buttons-container">
                    <div
                        v-if="statusCounts['pending']"
                        :class="[selectedMessageStatusFilters.includes('pending') && 'active', 'filter-button pointer']"
                        @click="!selectedMessageStatusFilters.includes('pending') ? addStatusFilter('pending') : removeStatusFilter('pending')">
                        <div class='filter-button-top'>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" class="button-icon fill-grey">
                                <path
                                    d="M24 7.273v-5.94h2.667v-1.333h-21.333v1.333h2.667v5.94c0 0.001 0 0.002 0 0.003 0 2.429 1.181 4.582 3 5.916l0.020 0.014 3.847 2.793-3.847 2.8c-1.838 1.348-3.019 3.499-3.020 5.926v5.94h-2.667v1.333h21.333v-1.333h-2.667v-5.94c0-0.001 0-0.002 0-0.003 0-2.429-1.181-4.582-3-5.916l-0.020-0.014-3.847-2.793 3.847-2.8c1.838-1.348 3.019-3.499 3.020-5.926v-0zM9.333 30.667v-3.447l6.667-1.22 6.667 1.213v3.453h-13.333zM20.193 19.873c1.506 1.103 2.473 2.865 2.473 4.853v1.14l-6.667-1.2-6.667 1.2v-1.14c0-1.988 0.967-3.75 2.457-4.842l0.017-0.012 4.193-3.047zM20.193 12.12l-4.193 3.053-4.193-3.053c-1.504-1.102-2.471-2.861-2.473-4.846v-5.94h13.333v5.94c-0 1.988-0.967 3.75-2.457 4.842l-0.017 0.012z"></path>
                            </svg>
                            <span class='filter-button-amount number-text'>{{ statusCounts['pending'] }}</span>
                        </div>
                        <p>{{ translations.messageRecipientsScreen.pending }}</p>
                    </div>
                    <div
                        v-if="statusCounts['received']"
                        :class="[selectedMessageStatusFilters.includes('received') && 'active', 'filter-button pointer']"
                        @click="!selectedMessageStatusFilters.includes('received') ? addStatusFilter('received') : removeStatusFilter('received')">
                        <div class='filter-button-top'>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" class="button-icon fill-green">
                                <path
                                    d="M16 0c-8.837 0-16 7.163-16 16s7.163 16 16 16c8.837 0 16-7.163 16-16v0c0-8.837-7.163-16-16-16v0zM16 30.667c-8.1 0-14.667-6.567-14.667-14.667s6.566-14.667 14.667-14.667c8.1 0 14.667 6.566 14.667 14.667v0c0 8.1-6.566 14.667-14.667 14.667v0zM15.453 20.72l-5.567-5.567-0.94 0.94 6.793 6.793 7.587-13.133-1.153-0.667-6.72 11.633z" />
                            </svg>
                            <span class='filter-button-amount number-text'>{{ statusCounts['received'] }}</span>
                        </div>
                        <p>{{ translations.messageRecipientsScreen.received }}</p>
                    </div>
                    <div
                        v-if="statusCounts['failed']"
                        :class="[selectedMessageStatusFilters.includes('failed') && 'active', 'filter-button fill-green pointer']"
                        @click="!selectedMessageStatusFilters.includes('failed') ? addStatusFilter('failed') : removeStatusFilter('failed')">
                        <div class='filter-button-top'>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" class="button-icon fill-red">
                                <path
                                    d="M16 0c-8.837 0-16 7.163-16 16s7.163 16 16 16c8.837 0 16-7.163 16-16v0c0-8.837-7.163-16-16-16v0zM16 30.667c-8.1 0-14.667-6.567-14.667-14.667s6.566-14.667 14.667-14.667c8.1 0 14.667 6.566 14.667 14.667v0c0 8.1-6.566 14.667-14.667 14.667v0zM21.527 9.527l-5.527 5.533-5.527-5.533-0.947 0.947 5.533 5.527-5.533 5.527 0.947 0.947 5.527-5.533 5.527 5.533 0.947-0.947-5.533-5.527 5.533-5.527-0.947-0.947z" />
                            </svg>
                            <span class='filter-button-amount number-text'>{{ statusCounts['failed'] }}</span>
                        </div>
                        <p>{{ translations.messageRecipientsScreen.failed }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="selectedMessageRecipientsLength">
            <recipient-type
                role="teacher"
                :translations='translations'
                :header="translations.general.roles.teachers"
                :recipients="mustHaveStatus(selectedMessageRecipientsFiltered.teachers, selectedMessageStatuses)"
                :is-selectable='false'
                :keyed-classes='keyedClasses'
                :is-consent-icon-hidden='true'
                :is-message-status-icon-visible='true'
                :is-missing-phone-icon-visible='false'
                :recipient-statuses='selectedMessageStatuses'
            >
            </recipient-type>
            <recipient-type
                role="tutor"
                :translations='translations'
                :header="translations.general.roles.tutors"
                :recipients="mustHaveStatus(selectedMessageRecipientsFiltered.tutors, selectedMessageStatuses)"
                :is-selectable='false'
                :keyed-classes='keyedClasses'
                :is-consent-icon-hidden='true'
                :is-message-status-icon-visible='true'
                :is-missing-phone-icon-visible='false'
                :recipient-statuses='selectedMessageStatuses'
            >
            </recipient-type>
            <recipient-type
                role="student"
                :translations='translations'
                :header="translations.general.roles.students"
                :recipients="mustHaveStatus(selectedMessageRecipientsFiltered.students, selectedMessageStatuses)"
                :is-selectable='false'
                :keyed-classes='keyedClasses'
                :is-consent-icon-hidden='true'
                :is-message-status-icon-visible='true'
                :is-missing-phone-icon-visible='false'
                :recipient-statuses='selectedMessageStatuses'
            >
            </recipient-type>
            <recipient-type
                role="contact_person"
                :translations='translations'
                :header="translations.general.roles.contacts"
                :recipients="mustHaveStatus(selectedMessageRecipientsFiltered.contactPersons, selectedMessageStatuses)"
                :is-selectable='false'
                :keyed-classes='keyedClasses'
                :is-consent-icon-hidden='true'
                :is-message-status-icon-visible='true'
                :is-missing-phone-icon-visible='false'
                :recipient-statuses='selectedMessageStatuses'
            >
            </recipient-type>
            <recipient-type
                role="inspector"
                :translations='translations'
                :header="translations.general.roles.inspectors"
                :recipients="mustHaveStatus(selectedMessageRecipientsFiltered.inspectors, selectedMessageStatuses)"
                :is-selectable='false'
                :keyed-classes='keyedClasses'
                :is-consent-icon-hidden='true'
                :is-message-status-icon-visible='true'
                :is-missing-phone-icon-visible='false'
                :recipient-statuses='selectedMessageStatuses'
            >
            </recipient-type>
        </div>
        <div v-else class="no-results-message">
            {{ translations.messageRecipientsScreen.noResults }}
        </div>
    </div>
</template>