<template>
  <div class="calendar-event-form">
    <time-slot-event
      v-if="isCreatingMeetingEvent"
      ref="timeSlotEvent"
      :is-co-organizer="isCoOrganizer"
      :can-edit-co-organizers="canEditCoOrganizers"
      :parent="parent"
    />
    <simple-event
      v-if="isCreatingBasicEvent"
      ref="simpleEvent"
      :is-co-organizer="isCoOrganizer"
      :can-edit-co-organizers="canEditCoOrganizers"
      :parent="parent"
    />
    <vacation-request
      v-if="isEditingVacationRequest"
      ref="vacationRequestForm"
      :parent="parent"
      :is-edit-mode="isEditingVacationRequest"
      @reloadCalendar="reloadCalendar"
      @reloadVacationRequest="reloadVacationRequest"
    />
    <vacation-request-details
      v-if="isViewingVacationRequest"
      ref="vacationRequestDetails"
      @showChildVacationRequest="$emit('openChildVacationRequest', $event)"
      @reloadCalendar="reloadCalendar"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { types } from '../../store/types/types';
import { axios, portal } from '../../../shared/assets/plugins/axios/axios';
import SimpleEvent from './SimpleEvent.vue';
import TimeSlotEvent from './TimeSlotEvent.vue';
import VacationRequest from './VacationRequest.vue';
import moment from 'moment-timezone';
import { permissionEnum } from '../../../shared/enums/permissionEnum.ts';
import { portalRoles } from '../../../shared/enums/portalRoles';
import { resourceTypes } from '../../../shared/enums/resourceTypes';
import VacationRequestDetails from './VacationRequestDetails';
import { providerKeyTypes } from '../../../shared/enums/providerKeyTypes';
import { calendarUtil } from '../../../shared/utils/calendarUtil';

const cancelToken = axios.CancelToken;
let cancelRequest = null;

export default {
  provide() {
    return {
      [providerKeyTypes.loadEventLocations]: this.loadLocations,
    };
  },
  inject: {
    getIsCreator: providerKeyTypes.isCurrentEventCreator,
    getIsCoOrganizer: providerKeyTypes.isCurrentEventCoOrganizer,
  },
  props: {
    parent: { type: String, default: 'profile' },
  },
  data() {
    return {
      permissionEnum: permissionEnum,
      portalRoles: portalRoles,
      availableDate: {
        disabledDate(time) {
          return time.getTime() + 86400000 < moment().toDate().getTime();
        },
        firstDayOfWeek: 1,
      },
      savedPrimaryLocation: [],
    };
  },
  computed: {
    ...mapGetters({
      institutions: types.GET_INSTITUTIONS,
      defaultDateFormat: types.GET_DEFAULT_DATE_FORMAT,
      defaultDateValueFormat: types.GET_DEFAULT_DATE_VALUE_FORMAT,
      defaultTimeFormat: types.GET_DEFAULT_TIME_FORMAT,
      defaultTimeValuesFormat: types.GET_DEFAULT_TIME_VALUE_FORMAT,
      getDateTimeSelectedByClickedTimeslot: types.GET_DATETIME_SELECTED_BY_CLICKED_TIMESLOT,
      profile: types.GET_CURRENT_PROFILE,
      hasPermission: types.HAS_PERMISSION,
      hasPermissionGroup: types.HAS_PERMISSION_ON_GROUP,
      hasPermissionOnInstitution: types.HAS_PERMISSION_ON_INSTITUTION,
      group: types.GET_ACTIVE_GROUP,
      delegatedContext: types.GET_DELEGATED_CONTEXT,
      activeEvent: types.GET_CURRENT_EVENT,
    }),
    isCreator() {
      return this.getIsCreator();
    },
    isCoOrganizer() {
      return this.getIsCoOrganizer();
    },
    isEditingVacationRequest() {
      return this.$route.path.indexOf('redigerferieanmodning') !== -1 && this.$route.params.id != null;
    },
    isViewingVacationRequest() {
      return this.$route.name === 'viewVacationRequest';
    },
    isCreatingMeetingEvent() {
      return (
        (this.$route.path.includes('opretsamtale') || this.$route.path.includes('redigersamtale')) &&
        this.profile.role == this.portalRoles.EMPLOYEE &&
        (this.hasPermission(this.permissionEnum.HANDLE_PERFORMANCE_MEETING) ||
          this.hasPermission(this.permissionEnum.HANDLE_PARENTAL_MEETING_SCHOOL) ||
          this.hasPermission(this.permissionEnum.HANDLE_PARENTAL_MEETING_DAYCARE))
      );
    },
    isCreatingBasicEvent() {
      if (this.$route.path.includes('opretbegivenhed') || this.$route.path.includes('redigerbegivenhed')) {
        if (this.parent == 'profile') {
          return this.hasPermission(this.permissionEnum.HANDLE_EVENTS);
        } else if (this.parent == 'group') {
          return (
            this.hasPermissionGroup(this.permissionEnum.INVITE_TO_EVENT, this.group.id) ||
            this.hasPermissionOnInstitution(permissionEnum.USE_GROUPS_AS_DISTRIBUTION_LISTS, this.group.institutionCode)
          );
        }
      }
      return false;
    },
    canHandleCoOrganizers() {
      return this.hasPermission(permissionEnum.HANDLE_EVENT_CO_ORGANIZER);
    },
    hasRightsOfDisposalOfEvent() {
      let hasRightsOfDisposal = false;
      if (this.isEditMode && this.delegatedContext != null) {
        const { coOrganizers, creator } = this.activeEvent;
        if (creator != null) {
          hasRightsOfDisposal = creator.id == this.delegatedContext.institutionProfileId;
        }
        if (!hasRightsOfDisposal && Array.isArray(coOrganizers)) {
          hasRightsOfDisposal = coOrganizers.some(
            ({ instProfile }) => instProfile.id == this.delegatedContext.institutionProfileId
          );
        }
      }
      return hasRightsOfDisposal;
    },
    canEditCoOrganizers() {
      const isCreatingEvent = this.isEditMode === false;
      const canHandleCoOrganizers = this.canHandleCoOrganizers;
      if (isCreatingEvent) {
        return canHandleCoOrganizers;
      }
      return canHandleCoOrganizers && (this.isCreator || this.hasRightsOfDisposalOfEvent || this.isCoOrganizer);
    },
    isEditMode() {
      return this.$route.path.includes('redigerbegivenhed') || this.$route.path.includes('redigersamtale');
    },
  },
  methods: {
    ...mapActions({
      deleteEvent: types.DELETE_EVENT,
      getSameAuthorityInstitutions: types.ACTION_GET_SAME_ADMINISTRATIVE_AUTHORITY_INSTITUTIONS,
    }),
    removeEvent(event, occurrenceDateTime) {
      this.deleteEvent({ eventForm: event, occurrenceDateTime }).then(() => {
        this.$emit('removeEventFromCalendar', [event.id]);
        this.$router.push({ path: '/kalender' });
      });
    },
    reloadVacationRequest() {
      this.$emit('reloadVacationRequest');
    },
    reloadCalendar() {
      this.$emit('reloadCalendar');
    },
    updatedEvent() {
      this.$emit('afterUpdated');
    },
    async loadLocations(query, timeslotIndex) {
      let start = '';
      let end = '';
      let institutionCode = '';
      let allDay = false;
      if (timeslotIndex != null) {
        institutionCode = this.$refs.timeSlotEvent.eventForm.institutionCode;
        const timeSlotIndex = this.$refs.timeSlotEvent.eventForm.timeSlots[timeslotIndex];
        if (timeSlotIndex != null) {
          start = moment(timeSlotIndex.date + ' ' + timeSlotIndex.startTime + ':00').format(
            'YYYY-MM-DDTHH:mm:ss.SSSSZ'
          );
          end = moment(timeSlotIndex.date + ' ' + timeSlotIndex.endTime + ':00').format('YYYY-MM-DDTHH:mm:ss.SSSSZ');
        }
      } else {
        institutionCode = this.$refs.simpleEvent.eventForm.institutionCode;
        start = moment(this.$refs.simpleEvent.eventForm.startDateTime).format('YYYY-MM-DDTHH:mm:ss.SSSSZ');
        end = moment(this.$refs.simpleEvent.eventForm.endDateTime).format('YYYY-MM-DDTHH:mm:ss.SSSSZ');
        allDay = this.$refs.simpleEvent.eventForm.allDay;
      }
      const institutionProfileId = this.institutions.find(
        inst => inst.institutionCode == institutionCode
      ).institutionProfileId;
      let eventId = this.$refs.simpleEvent?.eventForm?.id || this.$refs.timeSlotEvent?.eventForm?.id || null;
      eventId = eventId !== '' ? eventId : null;
      const params = {
        query: query,
        resourceTypes: [resourceTypes.LOCATION, resourceTypes.EXTRA_LOCATION],
        institutionCode: institutionCode,
        institutionProfileId: institutionProfileId,
        start: start,
        end: end,
        allDay: allDay,
        eventId: eventId,
      };

      if (
        this.$route.query.occurrence == null &&
        timeslotIndex == null &&
        this.$refs.simpleEvent.eventForm.repeating != null &&
        this.$refs.simpleEvent.eventForm.repeating.pattern != 'never'
      ) {
        params.occurenceLimit = this.$refs.simpleEvent.eventForm.repeating.occurenceLimit;
        params.weekdayMask = this.$refs.simpleEvent.eventForm.repeating.weekdayMask;
        params.pattern = this.$refs.simpleEvent.eventForm.repeating.pattern;
        params.interval = this.$refs.simpleEvent.eventForm.repeating.interval;
        params.maxDate = this.$refs.simpleEvent.eventForm.repeating.maxDate;
      }
      try {
        if (cancelRequest) {
          cancelRequest();
        }
        const resourceResponse = await portal.get('?method=resources.listAvailableResources', {
          params,
          cancelToken: new cancelToken(function executor(c) {
            cancelRequest = c;
          }),
        });

        cancelRequest = null;
        const options = resourceResponse.data.data.map(location => calendarUtil.mapResource(location));
        return [{ label: this.$options.filters.fromTextKey('CALENDAR_LABEL_EVENT_LOCATIONS'), options }];
      } catch (error) {
        if (!axios.isCancel(error)) {
          cancelRequest = null;
        }
        return [];
      }
    },
    async loadResourceSearchResults(query) {
      const institutionProfileId = this.institutions.find(
        inst => inst.institutionCode == this.$refs.simpleEvent.eventForm.institutionCode
      ).institutionProfileId;

      let start = null;
      let end = null;

      if (this.$refs.simpleEvent.eventForm.allDay === true) {
        start = moment(this.$refs.simpleEvent.eventForm.startDateTime).format('YYYY-MM-DDTHH:mm:ss.SSSSZ');
        end = moment(this.$refs.simpleEvent.eventForm.endDateTime).format('YYYY-MM-DDTHH:mm:ss.SSSSZ');
      } else {
        start = moment(this.$refs.simpleEvent.eventForm.startDateTime).utc().format('YYYY-MM-DDTHH:mm:ss.SSSSZ');
        end = moment(this.$refs.simpleEvent.eventForm.endDateTime).utc().format('YYYY-MM-DDTHH:mm:ss.SSSSZ');
      }
      let eventId = this.$refs.simpleEvent.eventForm.id ?? null;
      eventId = eventId !== '' ? eventId : null;
      const params = {
        query: query,
        excludeResourceTypes: [resourceTypes.LOCATION, resourceTypes.EXTRA_LOCATION],
        institutionCode: this.$refs.simpleEvent.eventForm.institutionCode,
        institutionProfileId: institutionProfileId,
        start: start,
        end: end,
        allDay: this.$refs.simpleEvent.eventForm.allDay,
        eventId: eventId,
      };

      if (
        this.$route.query.occurrence == null &&
        this.$refs.simpleEvent.eventForm.repeating != null &&
        this.$refs.simpleEvent.eventForm.repeating.pattern != 'never'
      ) {
        params.occurenceLimit = this.$refs.simpleEvent.eventForm.repeating.occurenceLimit;
        params.weekdayMask = this.$refs.simpleEvent.eventForm.repeating.weekdayMask;
        params.pattern = this.$refs.simpleEvent.eventForm.repeating.pattern;
        params.interval = this.$refs.simpleEvent.eventForm.repeating.interval;
        params.maxDate = this.$refs.simpleEvent.eventForm.repeating.maxDate;
      }
      try {
        if (cancelRequest) {
          cancelRequest();
        }
        const resourceResponse = await portal.get('?method=resources.listAvailableResources', {
          params,
          cancelToken: new cancelToken(function executor(c) {
            cancelRequest = c;
          }),
        });

        cancelRequest = null;
        const resourceResults =
          resourceResponse != null && resourceResponse.data != null ? resourceResponse.data.data : [];
        const groupedResults = [];
        const resources = resourceResults.reduce((resources, resource) => {
          this.addResource(resources, resource);
          return resources;
        }, []);

        for (const key in resources) {
          groupedResults.push(resources[key]);
        }
        this.$refs.simpleEvent.resourceResults = groupedResults;
      } catch (error) {
        if (!axios.isCancel(error)) {
          cancelRequest = null;
        }
      }
    },
    addResource(resources, resource) {
      const entry = calendarUtil.mapResource(resource);
      if (resources[resource.resourceCategory.id]) {
        const exists = resources[resource.resourceCategory.id].options.find(entry => entry.id === resource.id);
        if (!exists) {
          resources[resource.resourceCategory.id].options.push(entry);
        }
      } else {
        resources[resource.resourceCategory.id] = {
          label: resource.resourceCategory.name,
          options: [entry],
        };
      }
      return resources;
    },
  },
  watch: {
    institutions() {
      if (this.institutions.length > 0) {
        this.getSameAuthorityInstitutions(this.institutions);
      }
    },
  },
  components: {
    VacationRequestDetails,
    TimeSlotEvent,
    SimpleEvent,
    VacationRequest,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';
</style>
