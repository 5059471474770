<template>
  <div class="d-weekplans">
    <div v-if="showErrorText">
      <p class="text--warning">{{errorText}}</p>
    </div>
    <div class="week-navigation" v-if="!showErrorText">
      <button class="small-btn" @click="prevWeek"><i class="fas fa-chevron-left"></i></button>
      <div class="week-navigation__date">{{localeWeekNumView}}</div>
      <button class="small-btn" @click="nextWeek"><i class="fas fa-chevron-right"></i></button>
    </div>
    <div class="isLoading" v-if="isLoading">
      <div class="spinner">
        <div class="bounce1"></div>
        <div class="bounce2"></div>
        <div class="bounce3"></div>
      </div>
    </div>
    <div class="isLoading" v-if="!isLoading && !showErrorText && weekPlansReady.length === 0">
      <p>Der er ingen ugeplan at vise</p>
    </div>
    <div class="d-weekplan" :class="classPlacement" v-for="weekplan in weekPlansReady">
      <div class="box">
        <h2 class="marg-20-bottom">Ugeplan<span v-show="weekplan.name.length > 0"> for {{weekplan.name}}</span>
        </h2>
        <div class="week_note note_content"
             v-if="!isLoading && weekplan.note.length > 0">
          <p class="week_note__headline">
            <b>Note til ugen</b>
          </p>
          <widget-html :html="weekplan.note"></widget-html>
        </div>
        <div v-if="!isLoading && !showErrorText && weekplan.lessonsWithNotesCounter === 0">
          <h5>Ingen noter til lektioner i denne uge</h5>
        </div>
        <ul>
          <li v-for="day in weekplan.days"
              :key="day.date"
              v-if="day.lessons.length !== 0"
              class="day-change">
            <div class="day-change__header">
              <h3>{{dayDate(day.date)}}</h3>
            </div>
            <ul>
              <li v-for="event in day.lessons" :key="event.uuid">
                <div class="do-col week_notes_lesson_note"
                     :style="{ borderColor: event.color }">
                  <div class="do-col-1">
                    <div class="subject-info">
                      <div class="subject-title" :style="{ color: event.color }">{{event.title}}
                      </div>
                      <div class="subject-time"><i class="far fa-clock marg-5-right"></i>
                        {{timeStamp(event.startTime)}} - {{timeStamp(event.endTime)}}
                      </div>
                    </div>
                  </div>
                  <div class="do-col-1 week-plan__day__notes note_content">
                    <div class="do-col__col"
                         v-if="event.learningGoal.length > 0"
                         :class="{'hasNotes': event.learningGoal && event.learningGoal.length > 7}">
                      <h4 :style="{ color: event.color }">Læringsmål</h4>
                      <widget-html :html="event.learningGoal"></widget-html>
                    </div>
                    <div class="do-col__col"
                         v-if="event.description.length > 0">
                      <h4 :style="{ color: event.color }">Indhold</h4>
                      <widget-html :html="event.description"></widget-html>
                    </div>
                    <div class="do-col__col"
                         v-if="event.notesForStudents.length >  0">
                      <h4 :style="{ color: event.color }">Elevforberedelse</h4>
                      <widget-html :html="event.notesForStudents"></widget-html>
                    </div>
                    <div class="do-col__col"
                         v-if="userProfile === 'employee' && event.teacherNotes.length > 0">
                      <h4 :style="{ color: event.color }">Vikarnoter</h4>
                      <widget-html :html="event.teacherNotes"></widget-html>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
    <div class="footer-logo">
      <a href="https://docendo.dk" target="_blank"><img
        src="https://docendo.dk/static/img/aula-assets/docendo-logo-grey.png"/></a>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'WeekPlan',
    props: {
      moment: Function,
      currentWeekNumber: {
        default: '2019-W35'
      },
      placement: {
        type: String,
        default: 'narrow' // narrow, medium, full
      },
      userProfile: {
        type: String,
        default: 'Parent' // narrow, medium, full
      },
      getAulaToken: Function,
      group: String,
      axios: Function,
      sessionUUID: String
    },
    data () {
      return {
        aulaToken: null,
        isLoading: false,
        showErrorText: false,
        weekplans: [],
        errorText: '',
        lessonsWithNotesCounter: 0,
        weekYear: this.currentWeekNumber.substring(0, 4),
        weekNum: this.currentWeekNumber.replace(this.weekYear + '-W', ''),
        localeDate: this.moment().isoWeekday(1)
      }
    },
    computed: {
      classPlacement () {
        return 'aula-' + this.placement
      },
      localeYear () {
        return this.moment(this.localeDate).isoWeekYear()
      },
      localeWeekNum () {
        return this.localeYear + '-W' + this.localeDate.format('W')
      },
      localeWeekNumView () {
        return this.moment(this.localeDate).format('MMM') + ' ' + this.moment(this.localeDate).format('DD') + ' - ' + this.moment(this.localeDate).isoWeekday(7).format('DD') + ', Uge ' + this.localeDate.format('W')
      },
      weekPlansReady () {
        let plans = []
        if (this.weekplans) {
          this.weekplans.forEach(weekplan => {
            let plan = {
              name: weekplan.name ? weekplan.name : '',
              note: weekplan.note ? weekplan.note : '',
              days: weekplan.events ? this.eventsWithNotes(weekplan.events) : [],
              lessonsWithNotesCounter: this.lessonsWithNotesCounter
            }
            plans.push(plan)
          })
        }
        this.lessonsWithNotesCounter = 0
        return plans
      }
    },
    watch: {
      currentWeekNumber: function (val, oldVal) {
        this.setLocalDateFromProp()
      },
      localeDate: function () {
        if (this.aulaToken != undefined) {
          this.getWeekNotes()
        }
      },
      aulaToken: function () {
        if (this.aulaToken != undefined) {
          this.getWeekNotes()
        }
      }
    },
    methods: {
      setLocalDateFromProp () {
        if (!this.currentWeekNumber) {
          return
        }
        this.weekYear = this.currentWeekNumber.substring(0, 4)
        this.weekNum = this.currentWeekNumber.replace(this.weekYear + '-W', '')
        let yearAndWeek = this.moment().set({
          'year': this.weekYear,
          'isoWeek': this.weekNum
        })
        this.localeDate = this.moment(yearAndWeek).isoWeekday(1)
      },
      nextWeek () {
        this.localeDate = this.moment(this.localeDate).add(1, 'weeks')
      },
      prevWeek () {
        this.localeDate = this.moment(this.localeDate).subtract(1, 'weeks')
      },
      eventsWithNotes (events) {
        let allDates = []
        for (let i = 1; i < 8; i++) {
          let date = this.moment(this.localeDate).isoWeekday(i).format('YYYY-MM-DD')
          let arr = this.lessonsFilteredByDay(i, events)
          arr.sort(function compare (a, b) {
            let dateA = new Date(a.startTime)
            let dateB = new Date(b.startTime)
            return dateA - dateB
          })
          allDates.push({date, lessons: arr})
        }
        return allDates
      },
      lessonsFilteredByDay (dayNum, events) {
        this.lessonsWithNotesCounter = 0
        return events.filter(lesson => {
          let hasNotes = false
          if (lesson.learningGoal.length > 0 || lesson.notesForStudents.length > 0 || lesson.description.length > 0) {
            hasNotes = true
            this.lessonsWithNotesCounter++
          }
          let lessonDate = this.moment(lesson.startTime).format('YYYY-MM-DD')
          let day = this.moment(this.localeDate).isoWeekday(dayNum).format('YYYY-MM-DD')
          return this.moment(lessonDate).isSame(day) && hasNotes
        })
      },
      timeStamp (date) {
        this.moment.locale('da')
        return this.moment(date).format('LT')
      },
      dayDate (date) {
        this.moment.locale('da')
        return this.moment(date).format('dddd, DD. MMMM YYYY')
      },
      getWeekNotes () {
        this.isLoading = true
        const self = this
        this.axios.get('https://docendo.dk/aula/v2/weekplan?group=' + this.group + '&currentWeekNumber=' + this.localeWeekNum + '&userProfile=' + this.userProfile,
          {
            headers: {'Authorization': 'Baerer ' + self.aulaToken, AulaSessionUuid: self.sessionUUID},
            responseType: 'json'
          })
          .then(response => {
            this.isLoading = false
            if (response.data.response === 'error') {
              this.showErrorText = true
              this.errorText = response.data.message
              if (response.data.message === 'Signature has expired') {
                this.aulaToken = this.getAulaToken()
                this.showErrorText = false
              }
            } else {
              this.showErrorText = false
              this.weekplans = response.data.data.weekPlans
            }
          })
          .catch(e => {
            //some error handling
            this.isLoading = false
            this.showErrorText = true
            this.errorText = 'Der kan ikke skabes forbindelse til docendo (' + e.message + ')'
          })
      }
    },
    created () {
      this.aulaToken = this.getAulaToken()
      this.setLocalDateFromProp()
    }
  }
</script>

<style scoped>

  .d-weekplan {
    overflow: hidden;
    margin-bottom: 40px;
  }

  .d-weekplan h2 {
    font-size: 22px;
    font-weight: 900;
  }

  .d-weekplan.aula-narrow h2 {
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 400;
  }

  .d-weekplan h3 {
    font-size: 16px;
    text-transform: capitalize;
    margin-bottom: 0;
  }

  .d-weekplan ul {
    list-style-type: none;
    padding: 0;
  }

  .do-col > .do-col-1 {
    width: 100%;
  }

  .subject-info {
    margin-bottom: 15px;
  }

  .subject-info > .subject-title {
    font-weight: bold;
    font-size: 17px;
    word-break: break-all;
  }

  .day-change {
    padding-bottom: 10px;
  }

  .day-change__header {
    padding: 5px 0 5px 0;
    margin-bottom: 5px;
  }

  .week_note {
    font-size: 15px;
    border-left: 3px solid #ffffff;
    background-color: #ffffff;
    border-radius: 3px;
    overflow: hidden;
    padding: 10px 20px;
    margin-bottom: 10px;
  }

  .week_note__headline {
    text-transform: uppercase;
  }

  .week_notes_lesson_note {
    font-size: 15px;
    border-left: 3px solid #ffffff;
    border-radius: 3px;
    overflow: hidden;
    padding: 10px 20px;
    background-color: #ffffff;
    margin-bottom: 10px;
  }

  .week_notes_lesson_note h4 {
    font-weight: bold;
    line-height: 14px;
    margin-bottom: 5px;
    font-size: 12px;
    text-transform: uppercase;
  }

  .week-plan__day__notes > .do-col__col {
    margin-bottom: 20px;
  }

  .week-plan__day__notes > .do-col__col > p {
    padding: 0;
  }

  .aula-narrow .word-break, .aula-narrow .word-break > a {
    word-break: unset;
  }

  @media only screen and (max-width: 992px) {

    .word-break, .word-break > a {
      word-break: unset;
    }
  }

  @media only screen and (min-width: 992px) {

    .word-break, .word-break > a {
      word-break: break-all;
    }
  }

  .word-break > p {
    padding-top: 0;
  }

  .spinner {
    margin: 100px auto 0;
    width: 70px;
    text-align: center;
  }

  .spinner > div {
    width: 18px;
    height: 18px;
    background-color: #468AD7;

    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  }

  .spinner .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }

  .spinner .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }

  @-webkit-keyframes sk-bouncedelay {
    0%, 80%, 100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1.0);
      transform: scale(1.0);
    }
  }

  @keyframes sk-bouncedelay {
    0%, 80%, 100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1.0);
      transform: scale(1.0);
    }
  }

  .text--warning {
    color: #212529;
    font-size: 18px;
    line-height: 1em;
    font-weight: 400;
    font-style: italic;
  }

  .marg-5-right {
    margin-right: 5px;
  }

  .marg-20-bottom {
    margin-bottom: 20px;
  }

  .small-btn {
    color: #222350;
    background-color: #ffffff;
    -webkit-appearance: none;
    border: 1px solid #cccccc;
    border-radius: 4px;
    cursor: pointer;
    outline: none;
    padding: 5px 10px;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    -webkit-transition: -webkit-transform 0.2s;
    transition: -webkit-transform 0.2s;
    -o-transition: transform 0.2s;
    transition: transform 0.2s;
    transition: transform 0.2s, -webkit-transform 0.2s;
  }

  .small-btn:hover {
    border-color: #468ad7 !important
  }

  .small-btn:active {
    -webkit-transform: scale(0.95);
    -ms-transform: scale(0.95);
    transform: scale(0.95);
  }

  .week-navigation {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .week-navigation__date {
    padding: 5px 10px;
    background-color: #ffffff;
    border: 1px solid transparent;
    margin: 0 5px;
    border-radius: 4px;
  }

  .footer-logo {
    display: flex;
    align-items: center;
    margin-bottom: 0;
    justify-content: flex-end;
  }

  .footer-logo img {
    width: 100px;
  }

  .note_content ul, .note_content ol {
    margin-bottom: 1em;
    padding-left: 20px;
  }

  .note_content ul li {
    list-style-type: disc;
  }

  .note_content ol li {
    list-style-type: decimal;
  }

  p {
    padding: 0 !important;
  }

</style>
