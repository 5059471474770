<template>
  <div>
    <h5>Dagens fravær</h5>

    <div class="df-datepicker">
      <div class="df-date-button date-back">
        <b-button
          :disabled="disablePreviousDay || disableDateSelection"
          class="btn btn-link navbar-btn navbar-link"
          @click="backDate"
        >
          <i class="fas fa-chevron-left"></i>
        </b-button>
      </div>

      <div class="df-date">
        {{ displayDate }}
      </div>

      <div class="df-date-button date-forward">
        <b-button
          :disabled="disableNextDay || disableDateSelection"
          class="btn btn-link navbar-btn navbar-link"
          @click="forwardDate"
        >
          <i class="fas fa-chevron-right"></i>
        </b-button>
      </div>
    </div>

    <div class="fravaersWidget" v-for="skole in fravaersInfo" v-bind:key="skole.skoleId">
      <div
        class="fravaer-title"
        v-bind:class="{
          titleloading: skole.loading,
          oneSchool: institutionFilter.length === 1
        }"
      >
        <h5>
          <span v-if="skole.loading" v-bind:class="{ loading: institutionFilter.length === 1 }"
            >Indlæser</span
          >
          <span
            v-if="!skole.navn && institutionFilter.length > 1"
            v-bind:class="{ loading: skole.loading }"
            >{{ skole.skoleId }}</span
          >
          <span
            v-else-if="institutionFilter.length > 1"
            v-bind:class="{ loading: skole.loading }"
            >{{ skole.navn }}</span
          >
        </h5>
      </div>

      <div v-if="!skole.error && skole.data">
        <div class="fravaer-top">
          <h6 class="fat" v-if="skole.data.fravaerendeLaeerer.length !== 0">
            Fraværende personale
          </h6>
          <span v-else>Intet fraværende personale</span>
          <span
            v-for="info in skole.data.fravaerendeLaeerer"
            v-bind:key="info.betegnelse"
            v-bind:class="{ laererTidColor: info.tider.length === info.aarsager.length }"
            class="badge badge-pill laererColor"
            v-b-tooltip.hover.focus:title="info.aarsager.join(', ')"
          >
            {{ info.betegnelse }}
            <span v-if="info.tider.length === info.aarsager.length">
              <span v-for="tid in info.tider" v-bind:key="tid.from + tid.to"
                >({{ formatDateToHours(tid.from) }} - {{ formatDateToHours(tid.to) }})</span
              >
            </span>
          </span>
        </div>
        <div class="fravaer-middle">
          <h6 class="fat" v-if="skole.data.klasserMedAku.length !== 0">Anden klasseundervisning</h6>
          <span v-else>Ingen klasser med anden klasseundervisning</span>
          <span
            v-for="info in skole.data.klasserMedAku"
            v-bind:key="info.betegnelse"
            v-bind:class="{ klasseMedTid: info.tider.length }"
            class="badge badge-pill akuBadge"
            :id="'aku-' + info.betegnelse"
            tabindex="0"
            v-b-tooltip.hover.focus
            :title="info.titler.join('; ')"
          >
            {{ info.betegnelse }}
            <span v-for="tid in info.tider" v-bind:key="tid.from"
              >({{ formatDateToHours(tid.from) }} - {{ formatDateToHours(tid.to) }})</span
            >
          </span>
        </div>
        <div class="fravaer-bottom">
          <h6 class="fat" v-if="skole.data.laerereMedAendringer.length !== 0">
            Personale med skemaændringer
          </h6>
          <span v-else>Intet personale med skemaændringer</span>
          <span
            v-for="info in skole.data.laerereMedAendringer"
            v-bind:key="info.name"
            class="badge badge-pill changesColor"
            >{{ info.name || info }}</span
          >
          <div class="cachedTime" v-if="skole.data && skole.data.cachedDate">
            Opdateret {{ formatDateToHours(skole.data.cachedDate) }} - For genopfrisk, tryk F5
          </div>
        </div>
      </div>
      <div class="fravaer-bottom" v-if="skole.errorMessage">
        <span>Fejl ved henting af dagens fravær. Besked:</span>
        {{ skole.errorMessage }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    axios: Function,
    sessionUUID: String,
    placement: String,
    currentWeekNumber: String,
    isMobileApp: Boolean,
    userProfile: "child" | "employee" | "guardian",
    getAulaToken: Function,
    assuranceLevel: Number,
    childFilter: Array,
    institutionFilter: Array,
    group: String,
    moment: Function
  },
  data: function() {
    return {
      env: "",
      aulaToken: null,
      fravaersInfo: [],
      loading: false,
      errorLoading: false,
      cachedData: false,
      cachedTime: this.moment(new Date()),
      unknownPerson: false,
      timeout: false,
      collator: new Intl.Collator(undefined, {
        numeric: true,
        sensitivity: "base"
      }),
      fravaerDate: new Date()
    };
  },
  methods: {
    async getVikarData(token) {
      for (const id of this.institutionFilter) {
        let indexOf = -1;
        for (let i = 0; i < this.fravaersInfo.length; i++) {
          let skole = this.fravaersInfo[i];
          if (skole.skoleId === id) {
            indexOf = i;
            break;
          }
        }

        if (indexOf !== -1) {
          this.fravaersInfo.splice(indexOf, 1);
        }

        this.fravaersInfo.push({
          skoleId: id,
          loading: true,
          error: false
        });

        this.fetchFravaersData(id, token).then(
          (res) => {
            this.fravaersInfo = this.fravaersInfo.map((info) => {
              if (info.skoleId == id) {
                this.sortLists(res);
                info.navn = res.institutionNavn;
                info.loading = false;
                info.data = res;
              }
              return info;
            });
          },

          (error) => {
            this.fravaersInfo = this.fravaersInfo.map((info) => {
              if (info.skoleId == id) {
                info.data = null;
                info.loading = false;
                info.error = true;
                info.errorMessage = error.response && error.response.data;

                if (error.request && error.request.status) {
                  if (error.request.status == "500") {
                    info.errorMessage =
                      "Noget gik galt da der skulle hentesd informationer på denne institution.";
                  }
                }
              }
              return info;
            });
          }
        );
      }
    },
    fetchFravaersData(skoleKode, token) {
      return new Promise(async (resolve, reject) => {
        try {
          const date = this.moment(this.fravaerDate).toISOString();
          const res = await this.axios.get(
            `https://vikar.tabulex.net/gateway/api/v2/aula/${skoleKode}/fraverInfo?date=${date}`,
            {
              headers: { Authorization: "Bearer " + token }
            }
          );
          resolve(res.data.data);
        } catch (e) {
          reject(e);
        }
      });
    },
    formatDateToHours(date) {
      return this.moment(date)
        .locale("da")
        .format("H:mm");
    },
    backDate() {
      this.fravaerDate = this.addDays(this.fravaerDate, -1);
    },
    forwardDate() {
      this.fravaerDate = this.addDays(this.fravaerDate, 1);
    },
    addDays(date, days) {
      const result = new Date(date);
      result.setDate(result.getDate() + days);
      return result;
    },
    sortLists(data) {
      if (data) {
        if (data.fravaerendeLaeerere) {
          data.fravaerendeLaeerer = data.fravaerendeLaeerere;
        }

        data.fravaerendeLaeerer.sort((a, b) => this.collator.compare(a.betegnelse, b.betegnelse));
        data.laerereMedAendringer.sort((a, b) => this.collator.compare(a.betegnelse, b.betegnelse));
        data.klasserMedAku.sort((a, b) => this.collator.compare(a.betegnelse, b.betegnelse));
      }
    }
  },
  computed: {
    displayDate() {
      return this.moment(this.fravaerDate).format("Do MMMM");
    },
    disableNextDay() {
      return this.moment(this.fravaerDate).format("Do MMMM") === "31. juli";
    },
    disablePreviousDay() {
      return (
        this.moment(this.fravaerDate).format("Do MMMM") === "1. august" ||
        this.moment(this.fravaerDate).isSame(this.moment(new Date()).subtract(29, "days"), "day")
      );
    },
    disableDateSelection() {
      return this.fravaersInfo.some((skole) => skole.loading);
    }
  },
  created: function() {
    this.moment.locale("da");
  },
  mounted: function() {
    // this.moment.locale("da");
    this.aulaToken = this.getAulaToken();
  },
  watch: {
    fravaerDate: function() {
      this.getVikarData(this.aulaToken);
    },
    aulaToken: function() {
      if (this.aulaToken) {
        this.getVikarData(this.aulaToken);
      }
    },
    institutionFilter: function() {
      if (this.fravaersInfo && this.fravaersInfo.length) {
        this.fravaersInfo = this.fravaersInfo.filter((skole) =>
          this.institutionFilter.find((id) => skole.skoleId === id)
        );
      }

      this.getVikarData(this.aulaToken);
    }
  }
};
</script>

<style scoped>
.fravaersWidget {
  overflow: hidden;
}
.fravaer-title {
  margin-top: 16px;
  background-color: white;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  text-align: center;
  min-height: 34px;
  padding-top: 5px;
  margin-bottom: -1px;
}
.fravaer-title h5 {
  margin-bottom: 0 !important;
}
.fravaer-title.titleloading {
  margin-bottom: 0 !important;
}
.fravaer-title.oneSchool.titleloading {
  height: 34px;
}
.fravaer-title.oneSchool {
  height: 11px;
  min-height: 0;
}
.fravaer-top,
.fravaer-middle,
.fravaer-bottom {
  margin-top: 1px;
  background-color: white;
  padding: 5px;
}
.fravaer-bottom {
  margin-bottom: 5px;
  padding-bottom: 25px;
}
.badge {
  margin-left: 1px;
  line-height: 2;
  margin-bottom: 1px;
}
h6 .fat {
  font-weight: bolder !important;
}
.laererColor {
  background-color: #2091a2;
  color: white;
}
.laererColor.laererTidColor {
  background-color: #45b7c1;
  color: white;
}
.school-badge {
  background-color: #549ec7;
  color: white;
}
.school-name {
}
.akuBadge {
  background-color: #222350;
  color: white;
}
.changesColor {
  background-color: #007a8d;
  color: white;
}
.cachedTime {
  position: relative;
  float: right;
  color: grey;
  bottom: -5px;
  font-size: 13px;
}
.bottom-row {
  padding-bottom: 18px;
}

.loading:after {
  content: " .";
  animation: dots 1s steps(5, end) infinite;
}
hr {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}

h5 {
  font-weight: bold;
}

.df-datepicker {
  display: grid;
  grid-template-columns: 100px auto 100px;
  width: 100%;
}

.df-date {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.df-date-button button {
  width: 55px;
  height: 30px;
  color: white;
}

.date-forward {
  display: flex;
  direction: rtl;
}

@keyframes dots {
  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  40% {
    color: #222350;
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  60% {
    text-shadow: 0.25em 0 0 #222350, 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  80%,
  100% {
    text-shadow: 0.25em 0 0 #222350, 0.5em 0 0 #222350;
  }
}
</style>
