<template>
	<div>
		<form ref="muAbsenceRegistrationForm" @submit="addAulaTokenOnSubmit" method="POST" target="_blank" :action="link">
			<input type="hidden" name="sessionUUID" :value="sessionUUID" />
			<input type="hidden" name="placement" :value="placement" />
			<input type="hidden" name="currentWeekNumber" :value="currentWeekNumber" />
			<input type="hidden" name="isMobileApp" :value="isMobileApp" />
			<input type="hidden" name="assuranceLevel" :value="assuranceLevel" />
			<input type="hidden" name="userProfile" :value="userProfile" />
			<input type="hidden" name="childFilter" :value="childFilter" />
			<input type="hidden" name="institutionFilter" :value="institutionFilter" />
			<input type="hidden" name="group" :value="group" />
			<input type="hidden" name="aulaToken" :value="getTempAulaToken" />
			<input type="hidden" name="widgetName" :value="widgetName" />
			<b-button class="absenceRegistrationButton" v-if="showLink" role="link" :title="'https://www.minuddannelse.net'" type="submit">
				<b-row class="button-logo">
					<div class="logo">
						<b-img src="https://www.minuddannelse.net/Content/Graphics/Frontpage/frontpage_logo_elev.svg" alt="MU Elev logo" />
					</div>
					<div>
						Indmeld fravær
					</div>
				</b-row>
			</b-button>
		</form>
	</div>
</template>

<script>
	export default {
		props: {
			'assuranceLevel': Number,
			axios: Function,
			'childFilter': [String, Array],
			'currentWeekNumber': String,
			'getAulaToken': Function,
			'group': String,
			'institutionFilter': [String, Array],
			'isMobileApp': Boolean,
			'placement': String,
			'sessionUUID': String,
			'userProfile': String,
		},
		data: function() {
			return {
				link: 'https://api.minuddannelse.net/aula/redirect',
				aulaToken: null,
				showLink: false,
				tempAulaToken: '',
				widgetName: 'AbsenceRegistration',
			};
		},
		watch: {
			aulaToken: function () {
				if (this.aulaToken !== undefined) {
					this.showLink = true;
				}
			}
		},
		computed: {
			getTempAulaToken() {
				return this.tempAulaToken;
			}
    	},
		methods: {
			addAulaTokenOnSubmit: function (e) {
				e.preventDefault();

				// Adding the aulaToken before submitting
				this.tempAulaToken = this.aulaToken;

				// Timeout ensures form is submittet after DOM is repaintet
				setTimeout(() => {
					// Ref can be an array because its located in a v-for
					if (Array.isArray(this.$refs.muAbsenceRegistrationForm)) {
						this.$refs.muAbsenceRegistrationForm[0].submit();
					} else {
						this.$refs.muAbsenceRegistrationForm.submit();
					}
					// Clear the aulaToken after submitting, so it's not visible in DOM
					// Timeout ensures the form has been submittet before clearing
					setTimeout(() => {
						this.tempAulaToken = '';
					}, 500);
				}, 500);
			},
		},
		mounted: function() {
			this.showLink = false;
			this.aulaToken = this.getAulaToken();
		}
	};
</script>

<style scoped>
	.absenceRegistrationButton {
		background-color: white !important;
		border: none !important;
		border-radius: 8px !important;
		font-size: 20px;
		font-weight: 600;
		width: 100%;
		max-width: 300px;
		color: #4D4D4D !important;
	}

	.absenceRegistrationButton:focus {
		box-shadow: none !important;
	}

	.absenceRegistrationButton .button-logo {
		justify-content: center;
		align-items: center;
	}

	.absenceRegistrationButton .logo {
		margin-right: 8px;
	}
</style>
