import { portalRoles } from '../enums/portalRoles';
import Vue from 'vue';

export const getPortalRoleText = portalRole => {
  switch (portalRole) {
    case portalRoles.EMPLOYEE:
      return Vue.filter('fromTextKey')('SEARCH_RECIPIENT_EMPLOYEE');
    case portalRoles.CHILD:
      return Vue.filter('fromTextKey')('SEARCH_RECIPIENT_CHILDREN');
    case portalRoles.GUARDIAN:
      return Vue.filter('fromTextKey')('SEARCH_RECIPIENT_GUARDIAN');
    default:
      return '';
  }
};
