
























import { PropType } from 'vue';
import { eventResponseStatus } from '../../enums/eventResponseStatus';
import { EventInviteeModel } from '../../models/eventInvitee.model';

export default {
  props: {
    invitees: { type: Array as PropType<Array<EventInviteeModel>>, default: () => [] },
  },
  emits: ['assignSlot'],
  computed: {
    acceptedInvitees() {
      return this.invitees.filter(invitee => invitee.responseType === eventResponseStatus.ACCEPTED);
    },
    waitingInvitees() {
      return this.invitees.filter(invitee => invitee.responseType === eventResponseStatus.WAITING);
    },
  },
  methods: {
    assignSlot(invitee) {
      this.$emit('assignSlot', invitee);
    },
  },
};
