<template>
  <div>
    <h1>Widget-7</h1>
    <template v-if="sessionUUID"><strong>sessionUUID:</strong> {{ sessionUUID }}<br /></template>
    <template v-if="placement"><strong>placement:</strong> {{ placement }}<br /></template>
    <template v-if="currentWeekNumber"><strong>currentWeekNumber:</strong> {{ currentWeekNumber }}<br /></template>
    <template v-if="isMobileApp"><strong>isMobileApp:</strong> {{ isMobileApp }}<br /></template>
    <template v-if="userProfile"><strong>userProfile:</strong> {{ userProfile }}<br /></template>
    <template v-if="assuranceLevel"><strong>assuranceLevel:</strong> {{ assuranceLevel }}<br /></template>
    <template v-if="childFilter"><strong>childFilter:</strong> {{ childFilter }}<br /></template>
    <template v-if="institutionFilter"><strong>institutionFilter:</strong> {{ institutionFilter }}<br /></template>
    <template v-if="group"><strong>group:</strong> {{ group }}<br /></template>
  </div>
</template>

<script>
module.exports = {
  props: {
    axios: Function,
    sessionUUID: String,
    placement: String,
    currentWeekNumber: String,
    isMobileApp: Boolean,
    userProfile: String,
    assuranceLevel: Number,
    childFilter: Array,
    institutionFilter: Array,
    group: Number
  }
};
</script>

<style scoped>
b:before {
  content: "";
  display: block;
}
.breakWord {
  word-break: break-all;
}
</style>
