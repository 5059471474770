import { notificationTypes } from '../enums/notificationTypes';
import { notificationEventTypes } from '../enums/notificationEventTypes';
import { WidgetNotificationModel } from '../models/widgetNotification.model';

interface GetNotificationsOptions {
  widgetId: number;
  notificationType: notificationTypes;
  notificationEventType: notificationEventTypes;
}

class WidgetUtil {
  getNotifications(
    notifications: WidgetNotificationModel[],
    {
      widgetId,
      notificationType = notificationTypes.BADGE,
      notificationEventType = notificationEventTypes.WIDGET_NOTIFICATION,
    }: GetNotificationsOptions
  ) {
    return notifications.filter(
      notification =>
        notification.widgetId === widgetId &&
        notification.notificationType === notificationType &&
        notification.notificationEventType === notificationEventType
    );
  }
}

export const widgetUtil = new WidgetUtil();
