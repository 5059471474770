import { eventTypeEnum } from '../enums/eventTypeEnum';
import { parentTypes } from '../enums/parentTypes';
import { resourceTypes } from '../enums/resourceTypes.js';
import { isProfileOTP } from '../functions/isProfileOtp';
import { isProfileGuardian } from '../functions/isProfileGuardian';
import { eventResponseStatus } from '../enums/eventResponseStatus';
import { CalendarEventModel } from '../models/calendarEvent.model';
import { CalendarResourceModel } from '../models/calendarResource.model';

class CalendarUtil {
  openEditEventDrawer(
    eventToEdit: Record<string, any>,
    parent: string,
    route: Record<string, any>,
    router: Record<string, any>,
    query: Record<string, any> = {}
  ): void {
    const editPage: string = [
      eventTypeEnum.PARENTAL_MEETING,
      eventTypeEnum.PERFORMANCE_MEETING,
      eventTypeEnum.SCHOOL_HOME_MEETING,
    ].includes(eventToEdit.type)
      ? '/redigersamtale'
      : '/redigerbegivenhed';
    if (parent === parentTypes.PROFILE) {
      router.push({
        path: '/kalender/' + eventToEdit.id + editPage,
        query: { parent: parent, ...query },
      });
    } else {
      router.push({
        path: '/gruppe/' + route.params.groupId + '/kalender/' + eventToEdit.id + editPage,
        query: { parent: parent, ...query },
      });
    }
  }

  getPrimaryLocation(extendedProps) {
    let location = '';
    const additionalLocations =
      extendedProps.additionalResources?.filter(
        resource => resource.resourceCategory.resourceType === resourceTypes.LOCATION
      ) || [];

    if (extendedProps.primaryResource != null) {
      location = extendedProps.primaryResource.name;
    } else if (additionalLocations.length > 0) {
      location = additionalLocations[0].name;
    } else if (extendedProps.primaryResourceText != null) {
      location = extendedProps.primaryResourceText;
    } else if (extendedProps.additionalResourceText != null) {
      location = extendedProps.additionalResourceText;
    }
    return location;
  }

  getEventResponseStatusClassName({
    event,
    profile,
    resources,
    eventResourceIds = [event.parentId],
    delegatedContext = null,
  }) {
    const isGuardianOrOtp = isProfileOTP(profile) || isProfileGuardian(profile);
    const profileId = delegatedContext?.id || profile.profileId;
    if (this.#getCanSeeEventIndicator({ profileId, resources, eventResourceIds, isGuardianOrOtp, event })) {
      return this.#getClassNameOfResponseStatus(event);
    }

    return null;
  }

  mapResource({
    id,
    name,
    institutionCode,
    institutionName,
    resourceCategory,
    numberOfOccurrences,
    numberOfAvailableOccurrences,
  }) {
    return {
      label: `${name} (${institutionName})`,
      value: id,
      name: name,
      resourceType: resourceCategory.resourceType,
      resourceCategory: resourceCategory.name,
      id: id,
      institutionCode: institutionCode,
      numberOfOccurrences,
      numberOfAvailableOccurrences,
    };
  }

  #getCanSeeEventIndicator({ profileId, resources, eventResourceIds, isGuardianOrOtp, event }) {
    const isInGroup = event.parentId?.includes('group') === true;
    const eventResourceId = eventResourceIds.find(resourceId => resourceId === event.parentId);
    const resource = eventResourceId ? resources.find(resource => resource.id === eventResourceId) : null;
    const eventBelongsToUser = profileId == resource?.profileId;
    const hasResponseStatus = event.responseStatus != null;
    const isDirectlyRelated = event.directlyRelated;
    const isEventCreator = profileId == event.creatorProfileId;

    const userHasTimeslot = event.timeSlot != null && event.responseStatus !== eventResponseStatus.WAITING;

    if (!hasResponseStatus) {
      return false;
    }
    if ((isGuardianOrOtp && isDirectlyRelated) || isEventCreator || userHasTimeslot) {
      return true;
    }

    return isInGroup || eventBelongsToUser;
  }

  #getClassNameOfResponseStatus(event) {
    let className = null;
    if ([eventResponseStatus.WAITING, eventResponseStatus.TENTATIVE].includes(event.responseStatus)) {
      className = 'tentative';
    } else if (event.responseStatus === eventResponseStatus.ACCEPTED) {
      className = 'accepted';
    }
    return className;
  }

  /**
   * @description checks if given event was declined by given profile user Id when profile Id equals  given resource Id
   * @param  {CalendarEventModel} event - event to be checked
   * @param  {number} profileId - profile id of current user
   * @param  {number} delegatedContextId - delegated context profile id
   * @param  {CalendarResourceModel[]} resources - list of chosen resources
   * @param  {string} resourceId - resourceId to be checked
   * @return  {boolean} - returns true if conditions are met:
   *                                current profile id (can be delegated) equals given profile resourceId and
   *                                resourceId can be found in given chosen resources and
   *                                the event is declined,
   *                 false - otherwise
   */
  isEventDeclinedByUser(
    event: CalendarEventModel,
    profileId: number | string,
    delegatedContextId: number | string,
    resources: CalendarResourceModel[],
    resourceId: string
  ): boolean {
    const userProfileId = delegatedContextId ? delegatedContextId : profileId;
    const resource = resources.find(resource => resource.id === resourceId);
    const eventBelongsToUser = userProfileId == resource?.singleId;
    const isDeclined = event.responseStatus === eventResponseStatus.DECLINED;

    return eventBelongsToUser && isDeclined;
  }

  /**
   * @description checks if the event is a parental meeting
   * @return {boolean}
   * @param type
   */
  getIsParentalMeeting(type: eventTypeEnum): boolean {
    return [eventTypeEnum.SCHOOL_HOME_MEETING, eventTypeEnum.PARENTAL_MEETING].includes(type);
  }
}

export const calendarUtil = new CalendarUtil();
