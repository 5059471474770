<template>
  <div class="vacation-date">
    <div class="vacation-day">
      {{ vacationDay.date | longDate(true) | firstLetterUppercase }}
      <b-btn v-if="isMobile" variant="link" class="view-detail" @click="viewDayResponseStatus">
        <i class="icon icon-Aula_forward-arrow" />
      </b-btn>
    </div>
    <div v-if="isCloseDay" class="close-day">
      {{ getClosedDayString() }}
    </div>
    <div v-else>
      <children-by-response-status
        :response-status="vacationResponseStatus.IS_COMING"
        :children="getChildrenByStatus(vacationResponseStatus.IS_COMING)"
        @openVacationResponseForChild="openVacationRequest"
      />
      <children-by-response-status
        :response-status="vacationResponseStatus.IS_NOT_COMING"
        :children="getChildrenByStatus(vacationResponseStatus.IS_NOT_COMING)"
        @openVacationResponseForChild="openVacationRequest"
      />
      <children-by-response-status
        :response-status="vacationResponseStatus.PENDING_ANSWER"
        :children="getChildrenByStatus(vacationResponseStatus.PENDING_ANSWER)"
        @openVacationResponseForChild="openVacationRequest"
      />
    </div>
    <aula-modal
      ref="dayResponseStatus"
      css-class="day-response-status"
      :header-text="vacationDay.date | longDate(true) | firstLetterUppercase"
      :header-text-from-textkey="false"
      :show-cancel="false"
      @okClicked="$refs.dayResponseStatus.hide()"
    >
      <div class="statistics"></div>
      <children-by-response-status
        :response-status="vacationResponseStatus.IS_COMING"
        :show-in-modal="true"
        :children="getChildrenByStatus(vacationResponseStatus.IS_COMING)"
        @openVacationResponseForChild="openVacationRequest"
      />
      <children-by-response-status
        :response-status="vacationResponseStatus.IS_NOT_COMING"
        :show-in-modal="true"
        :children="getChildrenByStatus(vacationResponseStatus.IS_NOT_COMING)"
        @openVacationResponseForChild="openVacationRequest"
      />
      <children-by-response-status
        :response-status="vacationResponseStatus.PENDING_ANSWER"
        :show-in-modal="true"
        :children="getChildrenByStatus(vacationResponseStatus.PENDING_ANSWER)"
        @openVacationResponseForChild="openVacationRequest"
      />
    </aula-modal>
  </div>
</template>

<script>
import { vacationResponseStatus } from '../../../shared/enums/vacationResponseStatus';
import ChildrenByResponseStatus from './ChildrenByResponseStatus';
import { mapGetters } from 'vuex';
import { types } from '../../store/types/types';
import { presenceOpenHours } from '../../../shared/enums/presenceOpenHours';
import Vue from 'vue';
export default {
  props: {
    vacationDay: { type: Object, default: () => {} },
    openingHour: { type: Object, default: () => {} },
  },
  data: function () {
    return {
      vacationResponseStatus: vacationResponseStatus,
      isChildrenComingShown: false,
      isChildrenNotComingShown: false,
      isChildrenDependShown: false,
      showDayResponseStatus: null,
    };
  },
  computed: {
    ...mapGetters({
      isMobile: types.GET_IS_MOBILE,
    }),
    isCloseDay() {
      return this.openingHour && this.openingHour.type === presenceOpenHours.CLOSE_OPEN_HOURS;
    },
  },
  methods: {
    viewDayResponseStatus() {
      this.$refs.dayResponseStatus.show();
    },
    getChildrenByStatus(vacationResponseStatus) {
      return this.vacationDay.children.filter(c => c.status == vacationResponseStatus);
    },
    openVacationRequest(child) {
      this.$emit('openVacationResponseForChild', child);
    },
    getClosedDayString() {
      return (
        Vue.filter('fromTextKey')('PRESENCE_VACATION_CLOSED_DAYS_NAME_CLOSED_PLACEHOLDER') + ' ' + this.openingHour.name
      );
    },
  },
  components: {
    ChildrenByResponseStatus,
  },
};
</script>

<style scoped lang="scss">
@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';

.vacation-date {
  margin-bottom: 20px;
  padding-bottom: 20px;
  padding-left: 15px;
  &:not(:last-of-type) {
    border-bottom: 1px solid $color-grey-dark;
  }
  .view-detail {
    position: relative;
    float: right;
    right: 20px;
    top: 35px;
    padding: 10px;
    i {
      font-size: 16px;
    }
  }
  .vacation-day {
    font-weight: 700;
    margin-bottom: 10px;
    margin-bottom: 10px;
  }

  .vacation-children {
    margin-top: 10px;
    li {
      list-style: none;
      margin-left: -25px;
    }
  }

  .close-day {
    background: var(--color-grey-dark);
    padding: 10px 20px;
    font-weight: 700;
    width: fit-content;
  }
}
</style>
