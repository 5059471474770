<template>
  <div
    :key="section"
    class="main-section">
    <section v-if="loading">
      <div
        class="infoba-header"
        style="background-color: #888;">
        <div style="display:flex">
          <i class="fal fa-home" />
          <span
            class="header-link"
            @click="goto(headerLink)">INFOBA</span>
        </div>
        <img
          :src="domain + '/Images/logowhite.svg'"
          class="infoba-logo">
      </div>
      <div
        class="infoba-quick-access-item "
        style="text-align:center; padding:20px !important">
        <div class="icon-rotator infoba-rotate" />
      </div>
    </section>
    <section v-else-if="section!='default' ">
      <div
        class="infoba-header"
        style="background-color: #888;">
        <div style="display:flex">
          <i class="fal fa-home" />
          <span
            class="header-link"
            @click="goto(headerLink)">INFOBA</span>
        </div>
        <img
          :src="domain + '/Images/logowhite.svg'"
          class="infoba-logo">
      </div>
      <div class="infoba-quick-access-item">
        <widget-html :html="message" />
      </div>
    </section>
    <section v-show="section=='default' && !loading">
      <div
        v-for="tile in item.Binder.Tile"
        :key="tile.Type">
        <!-- Digital suitcase IN -->
        <!--<div v-show="item.Binder.DigitalSuitcaseTile && tile.Type === 127">

                <div v-if="item.Binder.DigitalSuitcaseTile" class="infoba-header">
                    <div style="display: flex">
                        <div class="icon-briefcase"></div>
                        <div @click="goto(headerLinkF(tile.Type).url)" class="header-link"> {{item.Binder.DigitalSuitcaseTile.Header}}</div>
                    </div>
                    <img :src="domain + '/Images/logowhite.svg'" class="infoba-logo" />
                </div>
                <div class="wrapper"  v-if="item.Binder.DigitalSuitcaseTile && (lockedPercent != 0 || unlockedPercent != 0)">
                    <div class="infoba-quick-access-item" style="display:flex;justify-content:center; padding-left:10px;">
                        <canvas id="canvas" width=120 height=120 style="margin:auto"></canvas>
                    </div>
                </div>

            <div v-else class="infoba-accordeon-header" style=" background-color: #FAFAFA; font-size: 12px; color: #888888;">
                Vi har ikke noget relevant data at vise dig.
            </div>
        </div>-->

        <!--Inspection INL-->
        <div v-if="item.Binder.InspectionTile && tile.Type === 36">
          <div
            class="infoba-header"
            style="background-color: #96be2c;">
            <div style="display:flex">
              <div class="icon-clipboard" />
              <div
                class="header-link"
                @click="goto(headerLinkF(tile.Type).url)">
                {{ item.Binder.InspectionTile.Header }}
              </div>
            </div>
            <img
              :src="domain + '/Images/logowhite.svg'"
              class="infoba-logo">
          </div>
          <div class="wrapper">
            <div v-if="item.Binder.InspectionTile.Inspection !== undefined">
              <div
                v-if="item.Binder.InspectionTile.Inspection.VisitDate"
                class="infoba-accordeon-header"
                style=" background-color: #FAFAFA; font-size: 12px; color: #888888;">
                <div>N&aelig;ste tilsyn</div>
              </div>
              <div
                v-if="item.Binder.InspectionTile.Inspection.VisitDate"
                class="infoba-accordeon-row"
                style="border:none; background-color: #FAFAFA;">
                <div style="display:flex; align-items:center; padding-left:15px;padding-bottom:20px;">
                  <div
                    class="icon-calendar-green"
                    style="padding: 20px 10px 0 20px;" />
                  <div
                    v-if="item.Binder.InspectionTile.Inspection.InspectionId != null"
                    class="infoba-link"
                    style="font-size: 16px; color: #555555 !important;"
                    @click="goto(domain + '/IN/Inspection/Index/'+ item.Binder.Tile.Inspection.InstitutionId)">
                    <b>{{ moment(item.Binder.InspectionTile.Inspection.VisitDate).format('DD.MMMM.') }}</b> {{ moment(item.Binder.InspectionTile.Inspection.VisitDate).format('YYYY') }}
                  </div>
                </div>
              </div>
              <div
                v-if="item.Binder.InspectionTile.Inspection.DevelopmentPointList.length != 0"
                style="display:flex; align-items:center"
                class="infoba-accordeon-header">
                <div style="color: #555555;">
                  <b>{{ item.Binder.InspectionTile.TileSettings.InstitutionSettings.InspectionDevelopmentPointNamePlural }}</b>
                </div>
                <i
                  class="fas fa-chevron-right rotate chevron"
                  :class="{right:open}"
                  style="cursor:pointer; padding-left:48px;"
                  @click="open = !open" />
              </div>

              <div v-if="item.Binder.InspectionTile.Inspection.DevelopmentPointList.length != 0">
                <template v-for="le in item.Binder.InspectionTile.Inspection.DevelopmentPointList">
                  <transition name="slide">
                    <div v-show="open">
                      <div
                        class="infoba-accordeon-row"
                        style="padding-left:24px; padding-right:20px; align-items:center;">
                        <div
                          style="cursor:pointer"
                          @click="goto(domain + '/IN/Inspection/ActionPlan/' + le.ActionPlanRef.ActionPlanId +'?devPointId=' + le.DevelopmentPointId +'&institutionId=' + item.Binder.InspectionTile.Inspection.InstitutionId + '&inspectionId=' + le.InspectionId)">
                          <b>{{ le.Title }}</b>
                        </div>
                        <div
                          class="icon-status-dot"
                          :style="getColor(le.ActionPlanRef.Status)" />
                      </div>
                      <div
                        class="infoba-accordeon-row infoba-gray"
                        style="padding-left:24px; padding-right:20px; align-items:center; ">
                        <div><b>Frist:</b> {{ moment(le.ClosingDate).format('DD.MMM.YYYY') }}</div>
                        <div>{{ item.Binder.InspectionTile.Inspection.InspectionSelectedType.SeverityList[le.SeverityId].Name }}</div>
                      </div>
                    </div>
                  </transition>
                </template>
              </div>
              <div
                v-else
                class="infoba-accordeon-header"
                style=" background-color: #FAFAFA; font-size: 12px; color: #888888;">
                Vi har ikke noget relevant data at vise dig.
              </div>
            </div>
          </div>
        </div>

        <!-- Learningplatform -->
        <div v-if="item.Binder.LearningplatformTile && tile.Type === 40">
          <div
            class="infoba-header"
            style="background-color: #873C79;">
            <div @click="goto(headerLinkF(tile.Type).url)">
              <i class="font-awesome-header fas fa-list" />
              <span class="header-link">L&aelig;ringsplatform</span>
            </div>
            <img
              :src="domain + '/Images/logowhite.svg'"
              class="infoba-logo">
          </div>
          <div class="wrapper">
            <div style="height:auto">
              <div
                v-if="learningplatformHeaders.length == 0"
                class="infoba-accordeon-header"
                style=" background-color: #FAFAFA; font-size: 12px; color: #888888;">
                Vi har ikke noget relevant data at vise dig.
              </div>
              <template v-for="project in learningplatformHeaders">
                <div
                  class="infoba-accordeon-header"
                  style="padding-left: 25px;">
                  <div
                    class="ellipsis"
                    style="display: flex;align-items: center;width: 100%;">
                    <div class="theme-circle">
                      {{ project.Project.ActivityList.length }}
                    </div>
                    <widget-html
                      :html="project.Project.Name"
                      class="ellipsis"
                      style="cursor:pointer; width:80%; font-weight:bold"
                      @click="goto(domain +'/IN/ProjectPlan/ProjectView/'+project.Project.ProjectId)" />
                  </div>
                  <i
                    v-if="project.Project.ActivityList.length > 0"
                    class="fas fa-chevron-right rotate chevron"
                    :class="{right:project.Open}"
                    style="cursor:pointer"
                    @click="project.Open = !project.Open" />
                </div>
                <div>
                  <template v-for="activity in project.Project.ActivityList">
                    <transition name="slide">
                      <div
                        v-show="project.Open"
                        class="infoba-accordeon-row"
                        style="border:none;padding-left:58px;padding-right:45px;">
                        <div
                          style="cursor:pointer;width:100%"
                          class="ellipsis"
                          @click="goto(domain +'/IN/ProjectPlan/Activity/'+activity.ActivityId+'?pid='+project.Project.ProjectId)">
                          <widget-html :html="activity.Name" />
                        </div>
                      </div>
                    </transition>
                  </template>
                </div>
              </template>
            </div>
          </div>
        </div>

        <!-- Projectschema -->
        <div v-if="item.Binder.ProjectschemaTile && tile.Type === 123">
          <div
            class="infoba-header"
            style="background-color: #96BE2C;">
            <div @click="goto(headerLinkF(tile.Type).url)">
              <i class="font-awesome-header fas fa-list" />
              <span class="header-link">{{ tile.Header }}</span>
            </div>
            <img
              :src="domain + '/Images/logowhite.svg'"
              class="infoba-logo">
          </div>
          <div class="wrapper">
            <div style="height:auto">
              <div
                v-if="projectschemaHeaders.length == 0"
                class="infoba-accordeon-header"
                style=" background-color: #FAFAFA; font-size: 12px; color: #888888;">
                Vi har ikke noget relevant data at vise dig.
              </div>
              <template v-for="project in projectschemaHeaders">
                <div
                  :key="project.Schema1.ProjectSchema1Id"
                  class="infoba-accordeon-header"
                  style="padding-top:0; align-items:center">
                  <div style="display: flex; align-items:baseline; cursor: pointer; width:90%">
                    <div
                      class="theme-circle"
                      style="flex-shrink:0; background-color: #96BE2C;">
                      {{ project.Schema2.length }}
                    </div>
                    <div class="ellipsis">
                      <b v-if="project.Schema1.ProjectTitle != ''">
                        <widget-html
                          :html="project.Schema1.ProjectTitle.replace('<p>','').replace('</p>','')"
                          class="ellipsis"
                          @click="goto(domain +'/IN/ProjectSchema/Schema1/'+project.Schema1.ProjectSchema1Id)" />
                      </b>
                      <b
                        v-else
                        @click="goto(domain +'/IN/ProjectSchema/Schema1/'+project.Schema1.ProjectSchema1Id)">Skema for retning</b>
                    </div>
                  </div>
                  <i
                    v-if="project.Schema2.length > 0"
                    class="fas fa-chevron-right rotate chevron"
                    :class="{right:project.Open}"
                    style="cursor:pointer;"
                    @click="project.Open = !project.Open" />
                </div>
                <div
                  :key="project.Schema1.ProjectSchema1Id+'act'"
                  style="background-color: #FFFFFF;">
                  <template v-for="activity in project.Schema2">
                    <transition
                      :key="activity.ProjectSchema2Id"
                      name="slide">
                      <div
                        v-show="project.Open"
                        class=""
                        style="border:none;">
                        <div style="display:flex; align-items:baseline;justify-content:space-between; padding: 10px 0 10px 0;">
                          <div
                            class="ellipsis"
                            style="cursor:pointer; margin-left:33px;"
                            @click="goto(domain +'/IN/ProjectSchema/Schema2/'+activity.ProjectSchema2Id+'?schema1Id='+project.Schema1.ProjectSchema1Id)">
                            <widget-html
                              v-if="activity.ProjectTitle != null"
                              class="ellipsis"
                              :html="activity.ProjectTitle.replace('<p>','').replace('</p>','')" />
                            <p v-else>
                              Titel ikke udfyldt
                            </p>
                          </div>
                          <div
                            class="icon-status-dot"
                            style="background-color: orange; margin-left: 8px; margin-right: 15px; flex-shrink:0" />
                        </div>
                      </div>
                    </transition>
                  </template>
                </div>
              </template>
            </div>
          </div>
        </div>

        <!--Focuschild-->
        <div v-if="item.Binder.FocusChildTile && tile.Type === 56">
          <div
            class="infoba-header"
            style="background-color: #873c79;">
            <div style="display:flex">
              <div class="icon-eye" />
              <div
                class="header-link"
                @click="goto(headerLinkF(tile.Type).url)">
                Fokusbarnskema
              </div>
            </div>
            <img
              :src="domain + '/Images/logowhite.svg'"
              class="infoba-logo">
          </div>
          <div class="wrapper">
            <div
              v-if="focusChildHeaders.length == 0"
              class="infoba-accordeon-header"
              style=" background-color: #FAFAFA; font-size: 12px; color: #888888;">
              Vi har ikke noget relevant data at vise dig.
            </div>
            <div class="infoba-content">
              <template
                v-for="dd in focusChildHeaders"
                class="infoba-accordeon">
                <div class="infoba-accordeon-header">
                  <div style="display:flex;align-items: center;">
                    <div
                      class="theme-circle"
                      style="background-color: #873c79;">
                      {{ dd.ChildList.length }}
                    </div>
                    <b>{{ moment(dd.ScheduledDate).format('DD. MMM\xa0') }}</b> {{ moment(dd.ScheduledDate).format('YYYY') }}
                  </div>
                  <i
                    class="fas fa-chevron-right rotate chevron"
                    :class="{right:dd.Open}"
                    style="cursor:pointer;"
                    @click="dd.Open = !dd.Open" />
                </div>
                <template v-for="focusChildSchema in dd.ChildList">
                  <transition name="slide">
                    <div
                      v-show="dd.Open"
                      class="infoba-accordeon-row">
                      <div style="display:flex; flex-direction:column; flex-grow:1;">
                        <div style="display:flex; flex-direction:column">
                          <div style="display:flex;align-items: center;">
                            <div
                              class="theme-circle-big"
                              :style="{backgroundColor: '#' +focusChildSchema.ThemeColor}">
                              <widget-html :html="focusChildSchema.ThemeName | firstLetter" />
                            </div>
                            <div
                              class="ellipsis"
                              style="width:200px; margin-left: 3px; cursor:pointer"
                              @click="goto(domain +'/IN/FocusChild?rtmip=56&rid=0&tid='+focusChildSchema.ThemeId+'&gid='+focusChildSchema.GoalId+'&cid='+focusChildSchema.FocusChild.ChildId)">
                              <div class="infoba-link ellipsis">
                                <widget-html :html="focusChildSchema.FocusChild.Name" />
                              </div>
                              <div
                                v-show="focusChildSchema.DAPSchema2Name.length>0"
                                style="border-bottom: solid 1px #ccc; padding-bottom:5px; font-size:15px; font-weight:bold">
                                <widget-html
                                  class="ellipsis"
                                  :html="focusChildSchema.DAPSchema2Name" />
                              </div>
                              <widget-html :html="focusChildSchema.GoalName" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </transition>
                </template>
              </template>
            </div>
          </div>
        </div>
        <!--PTCMeeting-->
        <div v-if="item.Binder.PTCMeetingTile && tile.Type === 95">
          <div
            class="infoba-header"
            style="background-color: #96be2c;">
            <div style="display:flex">
              <div class="icon-house-solid" />
              <div
                class="header-link"
                @click="goto(domain + '/IN/PTCMeeting/')">
                {{ tile.Header }}
              </div>
            </div>
            <img
              :src="domain + '/Images/logowhite.svg'"
              class="infoba-logo">
          </div>
          <div
            v-if="tile.PTCMeeting.MeetingList.length == 0"
            class="infoba-accordeon-header"
            style=" background-color: #FAFAFA; font-size: 12px; color: #888888;">
            Vi har ikke noget relevant data at vise dig.
          </div>
          <div
            class="infoba-quick-access-item"
            style="height:auto">
            <div class="infoba-content">
              <div
                class="infoba-accordeon-header"
                style="padding-top:0; align-items:center; border:none; padding-left:10px;">
                <div class="ellipsis">
                  <b>Kommende samtaler</b>
                </div>
                <i
                  class="fas fa-chevron-right rotate chevron"
                  :class="{right:open}"
                  style="cursor:pointer;"
                  @click="open = !open" />
              </div>

              <transition name="slide">
                <div
                  v-show="open"
                  class=""
                  style="border:none; ">
                  <div
                    v-for="ptcMeeting in tile.PTCMeeting.MeetingList"
                    class="infoba-row"
                    style="border:none; flex-direction:column; ">
                    <div>
                      <div
                        v-if="ptcMeeting.MeetingDate != null"
                        class="infoba-gray">
                        {{ moment(ptcMeeting.MeetingDate).format('DD. MMM YYYY') }} kl. {{ moment(ptcMeeting.MeetingDate).format('hh:mm') }}
                      </div>
                      <div
                        :class="ptcMeeting.MeetingTypeId == 0 ? 'infoba-gray':'infoba-link'"
                        style="color:#555 !important; border-bottom:solid 1px #ccc; width:100%"
                        @click="goto(getPTCMeetingLink(ptcMeeting))">
                        <b v-if="ptcMeeting.MeetingTypeId == 0">{{ ptcMeeting.Child.FullName }} (Ukendt)</b>
                        <b v-else="">{{ ptcMeeting.Child.FullName }}  ({{ ptcMeeting.Child.AgeInMonthsText + " &aring;r" }})</b>
                      </div>
                    </div>
                    <div style="display:flex; justify-content:space-between; align-content:center; margin-top:5px; margin-bottom:10px;">
                      <div> {{ ptcMeeting.MeetingType }}</div>
                      <div style="display: flex; align-items: center;">
                        <span
                          v-if="ptcMeeting.MeetingId > 0"
                          :class="getReflectionColor(ptcMeeting)">
                          <i
                            class="fas fa-user"
                            style="cursor: pointer;"
                            @click="goto(domain+'/IN/PTCMeeting/Reflection/'+ptcMeeting.Child.ChildId+'?meetingId=' + (ptcMeeting.MeetingId == null ? 0 : ptcMeeting.MeetingId) + '&meetingTypeId='+ptcMeeting.MeetingTypeId)" />
                        </span>
                        <span
                          v-else
                          :class="getReflectionColor(ptcMeeting)">
                          <i class="fas fa-user" />
                        </span>

                        <div :class="getParentColor(ptcMeeting)" />

                        <div
                          v-if="ptcMeeting.MeetingDateSent != null"
                          :class="getConclusionColor(ptcMeeting)"
                          style=" cursor: pointer;"
                          @click="goto(domain+'/IN/PTCMeeting/Conclusion/'+ptcMeeting.Child.ChildId+'?meetingId='+(ptcMeeting.MeetingId == null ? 0 : ptcMeeting.MeetingId))" />
                        <div
                          v-else
                          :class="getConclusionColor(ptcMeeting)" />

                        <span
                          v-if="ptcMeeting.MeetingId > 0"
                          :class="getHistoryColor(ptcMeeting)">
                          <i
                            class="far fa-clock"
                            style="cursor: pointer;"
                            @click="goto(domain+'/IN/PTCMeeting/History/'+ptcMeeting.Child.ChildId+'?meetingId='+(ptcMeeting.MeetingId == null ? 0 : ptcMeeting.MeetingId))" />
                        </span>
                        <span
                          v-else
                          :class="getHistoryColor(ptcMeeting)">
                          <i class="far fa-clock" />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </transition>
            </div>
          </div>
        </div>
        <!-- RelationCircle -->
        <div v-if="(tile.Type === 24 || tile.Type === 26 || tile.Type === 28 || tile.Type === 30 || tile.Type === 70 || tile.Type === 124)">
          <div>
            <div
              style="background: #873c79;"
              class="infoba-header">
              <div style="display: flex">
                <div
                  v-if="tile.Type!=124"
                  class="icon-spf" />
                <div
                  v-if="tile.Type==124"
                  class="icon-trafficLight" />
                <div
                  class="header-link"
                  @click="goto(headerLinkF(tile.Type).url)">
                  {{ tile.Header }}
                </div>
              </div>
              <img
                :src="domain + '/Images/logowhite.svg'"
                class="infoba-logo">
            </div>
            <div
              v-if="tile.SpfTile !== null"
              class="wrapper">
              <div v-if="tile.Type!=124">
                <div v-if="tile.SpfTile.ReminderList.length">
                  <div
                    class="infoba-accordeon-header"
                    style="padding-left:35px; ">
                    <div
                      class="ellipsis"
                      style="display: flex;align-items: center;">
                      <div><b>P&aring;melse</b></div>
                    </div>
                  </div>
                  <div class="infoba-section">
                    <div
                      v-for="reminder in tile.SpfTile.ReminderList"
                      :key="reminder.ReminderId">
                      <div style="display:flex; justify-content:space-between; margin-left:10px; padding-bottom:15px;">
                        <div>
                          <div>{{ tile.Header }} {{ reminder.Description }} </div>
                          <div
                            style="cursor:pointer; display:inline-block"
                            @click="goto(domain+'/IN/SPF/Evaluate/'+reminder.ChildId+'?spfTypeId='+tile.TypeId)">
                            {{ reminder.ChildName }}
                          </div>
                        </div>
                        <div
                          style="display:flex;align-items:center;cursor:pointer;"
                          @click="dismissReminder(reminder.ReminderId,reminder.TypeId)">
                          <div>Afvis</div>
                          <div
                            class="icon-bell-off"
                            style="margin-right: 9px; background-size: 18px; margin-left:5px;" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <template v-for="header in headers.filter(h => h.TileType === tile.Type)">
                <div
                  :key="tile.Type+'_'+header.Count+'_'+header.Month"
                  class="infoba-accordeon-header"
                  style="padding-left:23px; ">
                  <div
                    class="ellipsis"
                    style="display: flex;align-items: center;">
                    <div
                      class="theme-circle"
                      style="background-color: #873c79;">
                      {{ header.Count }}
                    </div>
                    <div><b>{{ header.Month | firstToUpper | getMonth }}</b>{{ header.Month | getYear }}</div>
                  </div>
                  <i
                    class="fas fa-chevron-right rotate chevron"
                    :class="{right:header.Open}"
                    style="cursor:pointer"
                    @click="header.Open = !header.Open" />
                </div>
                <div
                  v-for="child in header.ChildList"
                  :key="tile.Type+'_'+header.Count+'_'+header.Month+'_'+child.ChildId">
                  <transition
                    :key="child.Name"
                    name="slide">
                    <div
                      v-show="header.Open"
                      class="infoba-accordeon-row">
                      <div style="display:flex;align-items: center; flex-grow:1; justify-content:space-between">
                        <div
                          style="cursor:pointer;margin-left: 53px;"
                          @click="goto(domain+'/IN/SPF/Evaluate/'+child.ChildId+'?spfTypeId='+tile.SpfTile.TypeId)">
                          {{ child.Name }}
                        </div>
                        <span :class="child.Reminder!='none'?'icon-alarm-'+ child.Reminder:''">
                          <i :class="child.Reminder!='none'?'far fa-alarm-clock':''" />
                        </span>
                      </div>
                    </div>
                  </transition>
                </div>
              </template>
            </div>
            <div
              v-else-if="tile.TrvslTile !== null"
              class="wrapper">
              <template v-for="header in headers.filter(h => h.TileType === tile.Type)">
                <div
                  :key="tile.Type+'_'+header.Count+'_'+header.Month"
                  class="infoba-accordeon-header"
                  style="padding-left:23px; ">
                  <div
                    class="ellipsis"
                    style="display: flex;align-items: center;">
                    <div
                      class="theme-circle"
                      style="background-color: #873c79;">
                      {{ header.Count }}
                    </div>
                    <div><b>{{ header.Month | firstToUpper | getMonth }}</b>{{ header.Month | getYear }}</div>
                  </div>
                  <i
                    class="fas fa-chevron-right rotate chevron"
                    :class="{right:header.Open}"
                    style="cursor:pointer"
                    @click="header.Open = !header.Open" />
                </div>
                <div
                  v-for="child in header.ChildList"
                  :key="tile.Type+'_'+header.Count+'_'+header.Month+'_'+child.ChildId">
                  <transition
                    :key="child.Name"
                    name="slide">
                    <div
                      v-show="header.Open"
                      class="infoba-accordeon-row">
                      <div style="display:flex;align-items: center; flex-grow:1; justify-content:space-between">
                        <div
                          style="cursor:pointer;margin-left: 53px;"
                          @click="goto(domainOld+'/IN/childTrivsel.aspx?r=l&cid='+child.ChildId)">
                          {{ child.Name }}
                        </div>
                        <span :class="statusColor(child.NextEvaluationDate)">
                          <i class="fal fa-alarm-clock" />
                        </span>
                      </div>
                    </div>
                  </transition>
                </div>
              </template>
            </div>
            <div
              v-else
              class="infoba-accordeon-header"
              style=" background-color: #FAFAFA; font-size: 12px; color: #888888;">
              Vi har ikke noget relevant data at vise dig.
            </div>
          </div>
        </div>
        <!--LearningPlan-->

        <div v-if="item.Binder.LearningPlanTile && tile.Type === 60">
          <div
            class="infoba-header"
            style="background-color: #425D82;">
            <div style="display:flex">
              <div class="icon-book" />
              <div
                class="header-link"
                @click="goto(headerLinkF(tile.Type).url)">
                {{ tile.Header }}
              </div>
            </div>
            <img
              :src="domain + '/Images/logowhite.svg'"
              class="infoba-logo">
          </div>
          <div
            v-if="tile.LearningPlanV2.ChangesSections.length == 0"
            class="infoba-accordeon-header"
            style=" background-color: #FAFAFA; font-size: 12px; color: #888888;">
            Vi har ikke noget relevant data at vise dig.
          </div>
          <template v-for="change in tile.LearningPlanV2.ChangesSections">
            <div
              class="infoba-accordeon-row"
              style="padding-top:0;  margin-bottom: 7px">
              <div style="padding: 16px;">
                <p style="margin-bottom: 0px; color: #888888; font-size: 12px;">
                  {{ moment(change.UpdatedDate).format('DD.MMM.YYYY') }}
                </p>
                <p
                  class="ellipsis"
                  style="margin-bottom: 0px">
                  <b>{{ change.SectionName }}</b>
                </p>
              </div>
            </div>
          </template>
        </div>
        <!--LearningLibrary-->
        <div v-if="item.Binder.LearningLibraryTile && tile.Type === 200">
          <div
            class="infoba-header"
            style="background-color: #425D82;">
            <div style="display:flex">
              <div class="icon-bookshelf" />
              <div
                class="header-link"
                @click="goto(headerLinkF(tile.Type).url)">
                {{ tile.Header }}
              </div>
            </div>
            <img
              :src="domain + '/Images/logowhite.svg'"
              class="infoba-logo">
          </div>
          <div
            v-if="item.Binder.LearningLibraryTile.ChildrenList.length == 0"
            class="infoba-accordeon-header"
            style=" background-color: #FAFAFA; font-size: 12px; color: #888888;">
            Vi har ikke noget relevant data at vise dig.
          </div>
          <div
            class="wrapper"
            style="margin-top:10px">
            <div style="height:auto">
              <template v-for="topic in item.Binder.LearningLibraryTile.ChildrenList">
                <div
                  class="infoba-accordeon-row"
                  style="cursor:pointer;"
                  :style="{paddingTop:'0px', marginBottom: '7px', borderLeft:'4px solid ' + topic.Color}"
                  @click="goto(domain + '/IN/LearningLibrary/Content/' + topic.LearningLibraryId + '?pid=' + topic.ParentId + '&fvid=' + topic.FVId)">
                  <div style="padding: 16px">
                    <p class="infoba-gray">
                      {{ moment(topic.PublishedDate).format('DD.MMM YYYY') }}
                    </p>
                    <p class="topicheader">
                      {{ topic.HeaderText }}
                    </p>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
        <!--KOMPAS Kompetanceprofil-->
        <div v-if="item.Binder.KOMPASListTile && tile.Type === 80">
          <div
            :style="{backgroundColor:backgroundColor}"
            class="infoba-header">
            <div style="display: flex">
              <div class="icon-kompas" />
              <div
                class="header-link"
                @click="goto(headerLinkF(tile.Type).url)">
                {{ tile.Header }}
              </div>
            </div>
            <img
              :src="domain + '/Images/logowhite.svg'"
              class="infoba-logo">
          </div>
          <div v-if="item.Binder.KOMPASListTile.GroupList.length == 0">
            <div class="infoba-quick-access-item">
              En ny aktivitetsperiode er begyndt og du er endnu ikke tilknyttet en KOMPAS-gruppe.
            </div>
          </div>
          <div
            v-else
            class="wrapper">
            <template v-for="header in item.Binder.KOMPASListTile.GroupList">
              <div
                class="infoba-accordeon-header"
                style="padding-left:25px; ">
                <div
                  class="ellipsis"
                  style="display: flex;align-items: center;">
                  <div><b>{{ header.Texts[0].Header }}</b></div>
                </div>
                <i
                  v-if="header.isLocked == false"
                  class="fas fa-chevron-right rotate chevron"
                  :class="{right:header.Open}"
                  style="cursor:pointer"
                  @click="header.Open = !header.Open" />
                <i
                  v-else
                  class="far fa-lock-alt" />
              </div>
              <div>
                <template v-for="child in header.ChildrenGroupList">
                  <transition
                    :key="child.ChildName"
                    name="slide">
                    <div
                      v-show="header.Open"
                      class="infoba-accordeon-row">
                      <div style="display:flex;align-items: center; flex-grow:1;">
                        <div
                          class="theme-circle"
                          :style="{backgroundColor:header.Texts[0].ThemeColor, marginLeft:'20px'}">
                          {{ child.Fills }}
                        </div>
                        <div
                          style="cursor:pointer;"
                          @click="goto(domain+'/IN/SPF/Evaluate/' + child.ChildId + '?spfTypeId=' + item.Binder.KOMPASListTile.TypeId)">
                          {{ child.ChildName }}
                        </div>
                      </div>
                    </div>
                  </transition>
                </template>
              </div>
            </template>
          </div>
        </div>
        <!--KOMPAS Process-->
        <div v-if="item.Binder.KOMPASProcessTile && tile.Type === 81">
          <div
            :style="{backgroundColor:backgroundColorProcess}"
            class="infoba-header">
            <div style="display: flex">
              <div class="icon-kompas" />
              <div
                class="header-link"
                @click="goto(headerLinkF(tile.Type).url)">
                {{ tile.Header }}
              </div>
            </div>
            <img
              :src="domain + '/Images/logowhite.svg'"
              class="infoba-logo">
          </div>
          <div class="wrapper">
            <template v-for="day in item.Binder.KOMPASProcessTile.NextWorkingDays">
              <div class="infoba-accordeon-header">
                <div
                  class="ellipsis"
                  style="display: flex;align-items: center;">
                  <div
                    class="theme-circle"
                    :style="{backgroundColor:backgroundColorProcess}">
                    {{ filterByDate(item.Binder.KOMPASProcessTile.FocusPeriodActivity, day.date).length }}
                  </div>
                  <div><b>{{ moment(day.date).format('dddd')| firstToUpper }}</b>{{ moment(day.date).format(' DD. MMM YYYY') }}</div>
                </div>
                <i
                  class="fas fa-chevron-right rotate chevron"
                  :class="{right:day.Open}"
                  style="cursor:pointer"
                  @click="day.Open = !day.Open" />
              </div>
              <div>
                <template v-for="activity in filterByDate(item.Binder.KOMPASProcessTile.FocusPeriodActivity, day.date)">
                  <transition
                    :key="activity.Tile"
                    name="slide">
                    <div
                      v-show="day.Open"
                      class="infoba-accordeon-row">
                      <div style="margin-left: 54px;">
                        <div
                          class="infoba-gray"
                          style="cursor:pointer;"
                          @click="goto(domain + '/IN/Institution/GroupChildrenList/' + activity.InstitutionId)">
                          {{ activity.InstitutionName }}
                        </div>
                        <div
                          style="cursor:pointer;"
                          @click="goto(domain + '/IN/KOMPAS/FocusPeriodProcess/' + activity.FocusPeriodId + '?KOMPASGroupPeriodActivityId=' + activity.GroupPeriodActivityId + '&activityId=' + activity.FocusPeriodActivityId)">
                          <b>{{ activity.Title }}</b>
                        </div>
                      </div>
                    </div>
                  </transition>
                </template>
              </div>
            </template>
          </div>
        </div>
        <!--PPlan-->
        <div v-if="item.Binder.PPlanTile && tile.Type === 130">
          <div
            style="background: #425d82;"
            class="infoba-header">
            <div style="display:flex">
              <div class="icon-calendar-account" />
              <div
                class="header-link"
                @click="goto(domain + '/IN/PPlan')">
                INFOBA Vagtplan
              </div>
            </div>
            <img
              :src="domain + '/Images/logowhite.svg'"
              class="infoba-logo">
          </div>
          <div
            v-if="pplanSortedTimeSlots.length>0"
            class="wrapper">
            <template v-for="institution in pplanSortedTimeSlots">
              <div class="institution">
                <widget-html
                  :html="institution.institutionName"
                  class="institution-name infoba-gray" />
                <template v-for="group in institution.groups">
                  <div class="group">
                    <widget-html
                      :html="group.groupName"
                      class="group-name" />
                    <template v-for="timeslot in group.timeslots">
                      <div
                        class="timeslot"
                        @click="goto(domain + '/IN/PPlan')">
                        <div
                          class="timeslot-content"
                          :style="{ borderLeftColor: timeslot.color}">
                          <div class="timeslot-time infoba-gray">
                            {{ timeslot.time }}
                          </div>
                          <widget-html
                            :html="timeslot.name"
                            class="timeslot-name" />
                        </div>
                      </div>
                    </template>
                  </div>
                </template>
              </div>
            </template>
          </div>
          <div
            v-else
            class="infoba-accordeon-header"
            style=" background-color: #FAFAFA; font-size: 12px; color: #888888;">
            Vi har ikke noget relevant data at vise dig.
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
var domain = 'https://www2.infoba.dk';
var domainOld = 'https://www.infoba.dk';
var canvas;
function chart(locked, unlocked) {
  setTimeout(function () {
    canvas = document.getElementById("canvas");
    if (canvas != undefined) {
      var ctx = canvas.getContext("2d");
      if (ctx != undefined)
        donutChart(60, 60, 50, 15, [locked, unlocked], ['#96be2c', '#e43351'], ctx)
    }

  }, 1);
}

module.exports = {
  props: {
    /* normal and secure */
    axios: Function,
    sessionUUID: String,
    placement: String,
    currentWeekNumber: String,
    isMobileApp: Boolean,
    userProfile: String,
    /* only secure */
    moment: Function,
    assuranceLevel: Number,
    getAulaToken: Function,
    childFilter: Array,
    institutionFilter: Array,
    group: String
  },
  data: function () {
    return {
      loading: true,
      domain: domain,
      domainOld: domainOld,
      message: "",
      headerLink: domain,
      section: "default",
      date: this.moment().format('DD-MM-YYYY'),
      aulaToken: null,
      learningplatformHeaders: [],
      projectschemaHeaders: [],
      focusChildHeaders: [],
      pplanSortedTimeSlots: Array,
      headers: [],
      open: false,
      timeSlots: Array,
      item: {
        Binder: {
          DigitalSuitcaseTile: {
            Header: "",
            Type: null
          },
          InspectionTile: {},
          LearningplatformTile: {},
          ProjectschemaTile: {},
          LearningLibraryTile: {},
          FocusChildTile: {},
          PTCMeetingTile: {},
          LearningPlanTile: {},
          KOMPASListTile: {},
          KOMPASProcessTile: {},
          PPlanTile: {}
        }
      }
    }
  },
  filters: {
    firstToUpper(value) {
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
    getYear(value) {
      return value.substring(value.length - 4, value.length);
    },
    getMonth(value) {
      return value.substring(0, value.length - 4);
    },
    firstLetter: function (value) {
      return value.charAt(0);
    }
  },
  methods: {
    getColor: function (status) {
      return "background-color:" + (status == 0 ? this.item.Binder.InspectionTile.Inspection.InspectionSelectedType.ActionTypeList[0].Color : this.item.Binder.InspectionTile.Inspection.InspectionSelectedType.ActionTypeList[status - 1].Color);
    },
    getData: function () {
      var uuid = this.sessionUUID;
      this.axios.defaults.headers.common["Authorization"] = this.aulaToken;
      this.axios.defaults.headers.common["AuthorizationContext"] = "INTilePersonnel";

      if (this.childFilter != null)
        this.axios.defaults.headers.common["childFilter"] = this.childFilter.join();
      if (this.institutionFilter != null)
        this.axios.defaults.headers.common["institutionFilter"] = this.institutionFilter.join();
      if (this.group != null)
        this.axios.defaults.headers.common["groupFilter"] = this.group;
      if (this.userProfile != null)
        this.axios.defaults.headers.common["userProfile"] = this.userProfile;
      if (this.currentWeekNumber != null)
        this.axios.defaults.headers.common["currentWeekNumber"] = this.currentWeekNumber;
      if (this.isMobileApp != null)
        this.axios.defaults.headers.common["isMobileApp"] = this.isMobileApp;

      if (this.selectedid == "" || this.selectedid == undefined)
        this.selectedid = 0;
      this.axios.get(domain + '/api/Aula/INTilePersonnelGet', {
        withCredentials: true,
        credentials: 'same-origin'
      })
        .then(response => {
          this.moment.locale('da')
          this.item = response.data;
          handleStatus(this);

          var noTiles = true;
          var tile = null;

          /*RelationCircle*/
          for (let i = 0; i < this.item.Binder.Tile.length; i++) {
            const tileType = this.item.Binder.Tile[i].Type;
            if (tileType === 24 || tileType === 26 || tileType === 28 || tileType === 30 || tileType === 70 || tileType === 124) {
              this.relationCircleSetData(tileType);
              noTiles = false;
            }
          }

          ///*Digitalsuitcase*/
          //tile = this.getTile(127);
          //if (tile !== null) {
          //    this.item.Binder.DigitalSuitcaseTile = tile.DigitalSuitcaseTile;
          //    this.item.Binder.DigitalSuitcaseTile.Header = tile.Header;
          //    this.item.Binder.DigitalSuitcaseTile.Type = tile.Type;
          //    if (this.item.Binder.DigitalSuitcaseTile != undefined) {
          //        if (this.lockedPercent != 0 || this.unlockedPercent != 0) {
          //            chart(this.lockedPercent, this.unlockedPercent);
          //            noTiles = false;
          //        }
          //    }
          //}

          ///*Inspection*/
          tile = this.getTile(36);
          if (tile !== null) {
            this.item.Binder.InspectionTile = tile;
            noTiles = false;
          }
          /*Learningplatform*/
          tile = this.getTile(40);
          if (tile !== null) {
            this.item.Binder.LearningplatformTile = tile;
            this.groupLearningplatformByProject();
            noTiles = false;
          }

          /*Projectschema*/
          tile = this.getTile(123);
          if (tile !== null) {
            this.item.Binder.ProjectschemaTile = tile;
            this.groupProjectschemaByProject();
            noTiles = false;
          }

          //FocusChild
          tile = this.getTile(56);
          if (tile != null) {

            this.item.Binder.FocusChildTile = tile;
            this.focusChildSetData();
          }

          //PTCMeeting
          tile = this.getTile(95);

          if (tile != null) {
            this.item.Binder.PTCMeetingTile = tile;
          }

          //LearningPlan
          tile = this.getTile(60);
          if (tile != null) {
            this.item.Binder.LearningPlanTile = tile;
            noTiles = false;
          }
          /*Learninglibrary*/
          tile = this.getTile(200);
          if (tile !== null) {
            this.item.Binder.LearningLibraryTile = tile.LearningLibraryTile;
            noTiles = false;
          }
          /*KOMPAS Kompetanceprofil*/
          tile = this.getTile(80);
          if (tile !== null) {
            this.item.Binder.KOMPASListTile = tile.KompasTile;
            noTiles = false;
          }
          /*KOMPAS Process*/
          tile = this.getTile(81);
          if (tile !== null) {
            this.item.Binder.KOMPASProcessTile = tile.KompasTile;
            this.item.Binder.KOMPASProcessTile.NextWorkingDays = this.addOpen(this.item.Binder.KOMPASProcessTile.NextWorkingDays);
            noTiles = false;
          }
          /*PPlan*/
          tile = this.getTile(130);
          if (tile !== null) {
            this.item.Binder.PPlanTile = tile;
            this.pplanSetData();
            noTiles = false;
          }
          if (noTiles) {
            this.section = "nodata";
            this.message = "Vi har ikke noget relevant data at vise dig.";
          }
        })
        .catch(error => {
          handleStatus(this);
        })
        .finally(() => this.loading = false)
    },
    pplanSetData: function () {

      this.timeSlots = this.item.Binder.PPlanTile.TimeSlotList;
      this.pplanSortedTimeSlots = [];

      for (i = 0; i < this.timeSlots.length; i++) {
        if (this.pplanSortedTimeSlots.length > 0 && this.pplanSortedTimeSlots[this.pplanSortedTimeSlots.length - 1].institutionName == this.timeSlots[i].InstitutionName) {
          //if institution already exists
          const groups = this.pplanSortedTimeSlots[this.pplanSortedTimeSlots.length - 1].groups;
          if (groups[groups.length - 1].groupName == this.timeSlots[i].GroupName) {
            //if the group already exists
            groups[groups.length - 1].timeslots.push(addNewTimeslot(this.timeSlots[i]));
          }
          else {
            //if group doesn't already exist
            groups.push(addNewGroup(this.timeSlots[i]));
          }
        }
        else {
          // add the very first timeslot, if it doesn't already exist
          if (!this.pplanSortedTimeSlots) this.pplanSortedTimeSlots = [];
          //if institution doesn't already exist
          this.pplanSortedTimeSlots.push(addNewInstitution(this.timeSlots[i]));
        }
      }
    },
    relationCircleSetData: function (tileType) {
      let tile = null;

      if (tileType === 24 || tileType === 26 || tileType === 28 || tileType === 30 || tileType === 70) {
        tile = this.getTile(tileType);
        if (tile !== null) {
          tile.ChildList = tile.SpfTile.ChildList;
        }
      } else if (tileType === 124) {
        tile = this.getTile(tileType);
        if (tile !== null) {
          tile.ChildList = tile.TrvslTile.ChildList;
        }
      }

      if (tile !== null) {
        this.groupByDates(tile);
      }
    },

    focusChildSetData: function () {
      let dates = [];
      let list = this.item.Binder.FocusChildTile.FocusChildList;
      for (let i = 0; i < list.length; i++) {
        if (dates.indexOf(list[i].ScheduledDate) === -1) {
          dates.push(list[i].ScheduledDate);
        }
      }
      for (let i = 0; i < dates.length; i++) {
        this.focusChildHeaders.push({
          Open: false, ScheduledDate: dates[i],
          ChildList: list.filter
          ((item) => this.moment(item.ScheduledDate).format('DD-MM-YYYY') == this.moment(dates[i]).format('DD-MM-YYYY'))
        });
      }
    },
    filterByDate(list, date) {
      return list.filter((item) => this.moment(item.Date).format('DD-MM-YYYY') === this.moment(date).format('DD-MM-YYYY'));
    },
    dismissReminder: function (reminderId, reminderTypeId) {
      var uuid = this.sessionUUID;
      this.axios.defaults.headers.common["Authorization"] = this.aulaToken;


      this.axios.get(domain + "/api/Aula/DismissReminder?reminderId=" + reminderId + "&reminderTypeId=" + reminderTypeId, {
        withCredentials: true,
        credentials: "same-origin",
      })
        .then((response) => {

          for (var i = 0; i < this.tile.ReminderList.length; i++) {
            if (this.item.Binder.Tile.SpfTile.ReminderList[i].ReminderId == response.data.Binder.Reminder.ReminderId) {
              this.item.Binder.Tile.SpfTile.ReminderList.splice(i, 1);
            }
          }
        })
        .catch((error) => {
          handleStatus(this);
        })
        .finally(() => (this.loading = false));
    },
    groupByDates: function (tile) {
      let months = [];

      if (tile.ChildList !== null) {
        for (let i = 0; i < tile.ChildList.length; i++) {
          if (months.indexOf(moment(tile.ChildList[i].NextEvaluationDate).format('MMMM YYYY')) === -1) {
            months.push(moment(tile.ChildList[i].NextEvaluationDate).format('MMMM YYYY'));
          }
        }
        for (let i = 0; i < months.length; i++) {
          this.headers.push({
            TileType: tile.Type,
            Open: false,
            Month: months[i],
            Count: tile.ChildList.filter((item) => this.moment(item.NextEvaluationDate).format('MMMM YYYY') == months[i]).length,
            ChildList: tile.ChildList.filter((item) => this.moment(item.NextEvaluationDate).format('MMMM YYYY') == months[i])

          });
        }
      }
    },
    groupLearningplatformByProject: function () {
      for (let i = 0; i < this.item.Binder.LearningplatformTile.ProjectPlan.ProjectList.length; i++) {

        this.learningplatformHeaders.push({
          Open: false,
          Project: this.item.Binder.LearningplatformTile.ProjectPlan.ProjectList[i]
        });
      }
    },
    groupProjectschemaByProject: function () {
      for (let i = 0; i < this.item.Binder.ProjectschemaTile.Schema1List.length; i++) {
        let schema1Id = this.item.Binder.ProjectschemaTile.Schema1List[i].ProjectSchema1Id;

        let schema2 = this.item.Binder.ProjectschemaTile.Schema2List.filter(
          function (schm2) {
            return schm2.ProjectSchema1Id == schema1Id;
          }
        );

        this.projectschemaHeaders.push({
          Open: false,
          Schema1: this.item.Binder.ProjectschemaTile.Schema1List[i],
          Schema2: schema2
        });
      }
    },
    goto: function (redirectUrl) {
      let serial = this.item.INFOBAserial;
      let token = this.item.INFOBAtoken;
      let url = "";
      //if logged in
      if (serial === null || token === null) {
        let aulaReferrer = '?referrer=Aula';
        if (redirectUrl.indexOf('?') !== -1) {
          aulaReferrer = '&referrer=Aula';
        }
        url = redirectUrl + aulaReferrer;
      }
      //if not logged in
      else {
        url = domain + "/Login/Index?serial=" + serial + "&token=" + token + "&referrer=Aula&returnUrl=" + encodeURIComponent(redirectUrl);
      }

      console.log(url)
      console.log(redirectUrl)
      var a = document.createElement('a');
      a.href = url;
      a.target = "INFOBA";
      a.click();
    },
    statusColor: function (date) {
      var datediff = this.moment(date).diff(this.moment());
      if (this.moment.duration(datediff, 'days').asDays() > 14) {
        return "icon-alarm-green";
      }
      else if (this.moment.duration(datediff, 'days').asDays() > 7) {
        return "icon-alarm-yellow";
      }
      else {
        return "icon-alarm-red";
      }
    },


    addEllipsis: function (text) {

      return text.replace('<p>', '<p style="white-space: nowrap; overflow: hidden; -ms-text-overflow: ellipsis; -o-text-overflow: ellipsis;text-overflow: ellipsis; margin-bottom:0px">');
    },

    getTile: function (type) {
      let tile = null;
      for (let i = 0; i < this.item.Binder.Tile.length; i++) {
        if (this.item.Binder.Tile[i].Type === type) {
          tile = this.item.Binder.Tile[i];
        }
      }
      return tile;
    },
    headerLinkF: function (type) {
      if (this.getTile(type).Url == undefined || this.getTile(type).Url.length == 0)
        return { url: "", style: "" };
      else
        return { url: this.getTile(type).Url, style: "cursor:pointer;" };
    },
    getPTCMeetingLink(ptcMeeting) {
      var link = "";
      if (ptcMeeting.MeetingTypeId == 0)
        link = "#";
      else if (ptcMeeting.MeetingTypeId != 0)
        link = domain + '/IN/PTCMeeting/Reflection/' + ptcMeeting.Child.ChildId + '?meetingId=' + (ptcMeeting.MeetingId == null ? 0 : ptcMeeting.MeetingId) + '&meetingTypeId=' + ptcMeeting.MeetingTypeId;

      if (ptcMeeting.MeetingDateSent != null)
        link = domain + '/IN/PTCMeeting/Conclusion/' + ptcMeeting.Child.ChildId + '?meetingId=' + (ptcMeeting.MeetingId == null ? 0 : ptcMeeting.MeetingId);
      return link;
    },
    getReflectionColor(meeting) {
      returnClass = "grey50";
      if (meeting.MeetingId != null) {
        if (meeting.MeetingDateSent) {
          returnClass = "green";
        }
        else {
          returnClass = "grey80";
        }
      }
      return returnClass + " icon-small-ptcLinks";
    },
    getParentColor(meeting) {
      returnClass = "icon-house-grey50";
      if (meeting.MeetingFirstSeenDate != null) {
        if (meeting.MeetingDateApproved) {
          returnClass = "icon-house-green";
        }
        else {
          returnClass = "icon-house-grey80";
        }
      }
      return returnClass + " icon-small-ptcLinks";
    },
    getConclusionColor(meeting) {
      returnClass = "icon-text-grey50";
      if (meeting.ConslusionExists != 0) {
        if (meeting.ConclusionDateSent) {
          returnClass = "icon-text-green";
        }
        else {
          returnClass = "icon-text-grey80";
        }
      }
      return returnClass + " icon-small-ptcLinks";
    },
    getHistoryColor(meeting) {
      returnClass = "grey50";
      if (meeting.MeetingId) {
        returnClass = "grey80";
      }
      return returnClass + " icon-small-ptcLinks";
    },
    addOpen(list) {
      return list.map(date => ({ date, Open: false }));
    },

  },
  created: function () {
    this.aulaToken = this.getAulaToken();
  },
  watch: {
    aulaToken: function () {
      if (this.aulaToken !== undefined) {
        var t = this;
        t.getData();
      }
    },
  },

  computed: {
    //lockedPercent: function () {
    //    tile = this.item.Binder.DigitalSuitcaseTile;
    //    if (tile.LockedCount == 0)
    //        return 0;
    //    return (tile.LockedCount / (tile.LockedCount + tile.UnlockedCount)) * 100;
    //},
    //unlockedPercent: function () {
    //    tile = this.item.Binder.DigitalSuitcaseTile;
    //    if (tile.UnlockedCount == 0)
    //        return 0;
    //    return (tile.UnlockedCount / (tile.LockedCount + tile.UnlockedCount)) * 100;
    //},

    backgroundColor: function () {
      if (this.item.Binder.KOMPASListTile.GroupList.length == 0 || this.item.Binder.KOMPASListTile.GroupList[0].Texts.length == 0) {
        return "#888888"
      }
      else {
        return this.item.Binder.KOMPASListTile.GroupList[0].Texts[0].ThemeColor;
      }
    },
    backgroundColorProcess: function () {
      if (this.item.Binder.KOMPASProcessTile == null) {
        return "#888888"
      }
      else {
        return this.item.Binder.KOMPASProcessTile.Color;
      }
    }
  }
}


function donutChart(cx, cy, radius, arcwidth, values, colors, ctx) {
  var tot = 0;
  var accum = 0;
  var PI = Math.PI;
  var PI2 = PI * 2;
  var offset = -PI / 2;
  ctx.lineWidth = arcwidth;
  console.log(values.length)
  for (var i = 0; i < values.length; i++) { tot += values[i]; }
  for (var i = 0; i < values.length; i++) {
    ctx.beginPath();
    ctx.arc(cx, cy, radius,
      offset + PI2 * (accum / tot),
      offset + PI2 * ((accum + values[i]) / tot)
    );
    ctx.strokeStyle = colors[i];
    ctx.stroke();
    accum += values[i];
  }
  var innerRadius = radius - arcwidth - 3;
  ctx.beginPath();
  ctx.arc(cx, cy, innerRadius, 0, PI2);
}

function handleStatus(t) {
  clearTimeout(t.timer)
  if (t.item && t.item.Status == 200) {
    if (t.item.Binder.Tile == undefined) {
      t.section = "error"
      t.message = 'Din bruger har ikke adgang til denne widget i INFOBA. Kontakt <a href="https://web.infoba.dk/support/" class="infoba-link">support</a>.'
    }
    else {
      t.section = "default"
    }
  }
  else if (t.item && t.item.Status == 401) {
    if (t.item.Message == "Invalid role" || t.item.Message == "Module payment missing") {
      t.section = "error"
      t.message = 'Din bruger har ikke adgang til denne widget i INFOBA. Kontakt <a href="https://web.infoba.dk/support/" class="infoba-link">support</a>.'
    }
    else {
      t.section = "login";
      t.message = 'For at se data fra INFOBA, skal dine konti f&oslash;rst sammenk&aeligdes. <div><a href="' + domain + "/IN/Personel/MatchAula/" + t.item.Username + '" class="infoba-link">Start sammenk&aeligdning <i class="material-icons icon-arrowright-circle" style="vertical-align: middle; margin-top: 2px; color:#96be2c"></i></a></div>';
      t.timer = setTimeout(function () { t.getData() }.bind(t), 5000);
      t.headerLink = domain + "/IN/Personel/MatchAula/" + t.item.Username;
    }
  }
  else {
    t.section = "error"
    t.message = 'Undskyld. Det er i &oslash;jeblikket ikke muligt at vise dig den &oslash;nskede information. Vi h&aring;ber, du vil pr&oslash;ve igen senere.'
  }
}

function addNewInstitution(timeSlot) {
  return {
    institutionName: timeSlot.InstitutionName,
    groups: [addNewGroup(timeSlot)]
  }
}

function addNewGroup(timeSlot) {
  return {
    groupName: timeSlot.GroupName,
    timeslots: [addNewTimeslot(timeSlot)]
  }
}

function addNewTimeslot(timeSlot) {
  return {
    name: timeSlot.Name,
    time: `${timeSlot.StartTime.slice(0, -3)} - ${timeSlot.EndTime.slice(0, -3)}`,
    color: timeSlot.Color
  }
}
</script>
<style scoped>

i.font-awesome-header {
  font-size: 20px;
  margin-right: 5px;
  margin-left: 2px;
}

p {
  margin: 0 0 2px 0;
}

.green {
  color: #96be2c;
}

.grey80 {
  color: #555555;
}

.grey50 {
  color: #888888;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  -ms-text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

i.icon-small-ptcLinks {
  font-size: 12px;
  margin-left: 10px;
}

div.icon-small-ptcLinks {
  padding: 15px 0 0 15px;
  background-size: 15px 15px !important;
  display: inline-block;
  margin-left: 10px;
}

span.icon-small-ptcLinks i {
  font-size: 12px;
  margin-left: 10px;
}

div.icon-calendar-account {
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QAAAAAAAD5Q7t/AAAACXBIWXMAAA7EAAAOxAGVKw4bAAAA+0lEQVRIx92UMW4CMRRE528gHXVQBBwgOUnEDRBFRCTukAPlNohLLBRElGmgeTQWclZe/IMDQoy0hXc9f/6M11+6WxBQuq+6dKO/BIAxsIo7IoPGvhp4i2taQ6CWNChsujazUZtANnMPzOxY97pncAl0chb/glTEbgfAFFgDG+D9LDttlwZ4AnbR37kH+h6+18FQ0mO07kp6/k8HHWAZOVgADx5+8h6kDhnoSZqE5ZeZ/aQEmnxXREBX0ouk7/C8hndlEQEV8AlsE2NoG75VbXyPwEdu2AGzUwK5iIYO4yPHHqfFM/jNUbGSNCicqvWpiOZBpKT4vIB/gzgAmQ1GdMzg1uAAAAAldEVYdGRhdGU6Y3JlYXRlADIwMjAtMTItMDdUMDc6MTY6MDUrMDA6MDADbSVIAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDIwLTEyLTA3VDA3OjE2OjA1KzAwOjAwcjCd9AAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAAASUVORK5CYII=') no-repeat center center;
  background-size: 20px;
  padding: 35px 0 0 35px;
  display: inline-block;
}

div.icon-kompas {
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QAAAAAAAD5Q7t/AAAACXBIWXMAAABgAAAAYADwa0LPAAABwklEQVRIx9WVzU4UQRSFTzFASJQ34AmcWcET4A+bWTCiYjIL1xDG8Se+A+HnMUzc4nMYTBBMIEhg4ROw4sfkc8HpeG2K7p4lN+l0973n3Ft16laVdN8tVQWBSUnPJS1KmpU049BvSd8l7UjaSSldjVwZeAGcUG+/gKVREreA7ZDgB/AOaAMP/LTt2w+4LWCsSYEi+QWwWkXyYNaAS3M2m8hSJJ8fYdaPQ5HenQsaNF9pkDQBXWDd/wNzj90ctwivg+ZVsowDfWDP+G6Q68C+VzniFweHFTN8AxyFhd0DUsB8sP9zLkFBfJSJTQGnmRbtl3Ad+w9zBc4dnM7EVh0bAG/9fQKMl3DTjp0Xvtq+dZJP/k36t/u3U0p/6vi1EnlBy3YKTGVy3JIozmDX72eBkMLoJelM0ntJ7ZTSRWacC35/y81g2dX3gZZ93dAt/bLmJX4L+Gn8yxxg0gcXwJp96y5SeeoaOwwbbeIu0JJBl8CTEdbvKXBl7mIdeCsUGRRyVcgyDMk3moxmDNgMHXPgHdoBHvrpAB+D5gAbNDmuQ6Ge9ayz4ypZ6q7MCUk93VyZc/r/ytzVzZX5NaV03Xjk987+AsOc0OROwvslAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIxLTAzLTE1VDEwOjAyOjIzKzAwOjAwryq+ZQAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMS0wMy0xNVQxMDowMjoyMyswMDowMN53BtkAAAAASUVORK5CYII=') no-repeat center center;
  padding: 35px 0 0 35px;
  display: inline-block;
}

div.icon-eye {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QAAAAAAAD5Q7t/AAAACXBIWXMAAA7EAAAOxAGVKw4bAAABWUlEQVRIx+2UvUpDQRCFz6pJkUQw8RH8KUXBPhrwmYRgoWDkWoraSLDJC0hiLUb8KSX4DGoVtTRg+CzuCuEym9zYpMmB5cKeM2fn7syONMWk4UYJgDlJG5LWJJX8dlfSi6Rn59zPv04GVoFz4IswPoEzYGUc4wxwBPRIjx5QAzKjzEtA2zC4AspA3q8toGnoboFiyDwPPBlBu0MSqhr6ByBniS+tzD2XBY6BN+AViICs51pGXD1pvhO427LnI4OLPLcdiK0MHnAXEBU8/2Zw756bD8S2JWkmbXMZe33/HfqW/g44CPCb/tswuEZCk0xoP1mHuvGbzYEiR77AySJfG3EXVhflgEdDXA3eG+wZ+nuzTX1AkfixJNHy3VLwqxLI/AZYGF7NeFTUGG9UfAOHxIMxHYBl4JR4oIXwAZwASyGfNON6VtK64nG96Le7kjqSOs65/iiPKSaLX/pA8qZ4SFURAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIwLTEwLTA2VDExOjAxOjEzKzAwOjAwaHN9ngAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMC0xMC0wNlQxMTowMToxMyswMDowMBkuxSIAAAAZdEVYdFNvZnR3YXJlAHd3dy5pbmtzY2FwZS5vcmeb7jwaAAAAAElFTkSuQmCC ) no-repeat center center;
  padding: 35px 0 0 35px;
  display: inline-block;
}

div.icon-book {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QAAAAAAAD5Q7t/AAAACXBIWXMAAA7EAAAOxAGVKw4bAAABEklEQVRIx+2UvUoDURCFv7uKQlJZiVgo2KX0p7IRO8G0Fj6JjX3Al7DxBQRtbQ2xVCsbCyHYBRVi9Vm44mXJrqvXgEhOOWfOnLnnwsAEgBrUzriGz6gnquMY3lTPzVHVmKlt9Vi9UQe5ZqBe5/VdNUSaefXKCBHXVrPYoGs9dCPNXZGMONUeQPgo1I0lhFCqKXIhhJDVHfxTTAz+j4HAQTn7DYzQvKr7o+b9hsGzulO2cKpBX12rSiTVYOWryAGmEz72Ud0GWkATGAIPwGXSJ6tb6qk6rPPa4uF6AXrAPdAHpoAlYANYLuz1lPfe5ro5YAFYBRbh8/ihHql7aqMi13X1TL3w/d7PVvRuqocJ8f8hvAFd7TPJdUrGsgAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAyMC0xMC0wN1QxMToxOTo0NCswMDowMJkFQ9EAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMjAtMTAtMDdUMTE6MTk6NDQrMDA6MDDoWPttAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAABJRU5ErkJggg==) no-repeat center center;
  padding: 35px 0 0 35px;
  background-size: 20px;
  display: inline-block;
}

div.icon-calendar-green {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QAAAAAAAD5Q7t/AAAACXBIWXMAAA7EAAAOxAGVKw4bAAABXElEQVRIx92VMUtCYRSGn3PV/AGWhmlrkHdz18yx3amhFhEdG1zvJDg0JlkE7f2AltBoNir0H+QQgotLKHlPQwQWV7PrFaJ3PN/5znPOy+H74N+q1jC11jB10Txj2Y3Kt272gDqw4bJYV1XyxWz72nECgVO3xQEUYojWp1qkEPPAlfhUwDK0dIDfKVjc7chvC8HHys4FcLzcNPdRqYL6VaRcyrQvPbPo/MaMoFyARoGwqJ6dNBPrngHGPuLAykQooLYv6hnghdVHVB4mQvfh/taTZwArc/sWDI7SIlIQkYKK7uRyV2PPAPVWMvA68m1jaw9be6qaqLeSgXnuztwiy8IIp8zyeDA8MjBCKp9dGYwHw36tYR737jpVy8J2NcFa2jwEKkDI4TgEVCIp88C9RfbXd8UxRdl0bVEp27EA6yfI3ACBrkJsnp9shp6nWqQqeYHuIsVVNL/IxH9P77i1cnmY9ATIAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIwLTEwLTA5VDE0OjA1OjQxKzAwOjAwLSIWgAAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMC0xMC0wOVQxNDowNTo0MSswMDowMFx/rjwAAAAZdEVYdFNvZnR3YXJlAHd3dy5pbmtzY2FwZS5vcmeb7jwaAAAAAElFTkSuQmCC) no-repeat center center;
  background-size: 20px;
  padding: 35px 0 0 35px;
  display: inline-block;
}

div.icon-clipboard {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QAAAAAAAD5Q7t/AAAACXBIWXMAAA7EAAAOxAGVKw4bAAAA8ElEQVRIx+2UQWoCQRBFf0UleAfJIbIMeoDgDdxk5xWydJZhzDEEQW+RK+gN2p0XyEJ5bnqCTGba6bEFCfnQ0FR1/1dU0S3dg4Ae8Ans/cqBbkrAnN/K2xiNgR31egGGgbwDXs89rQRwkgaBGkb+zlfgjDOzpzoAKVpqZj++DykMQ0oB2NwSsJb0LCm7BWAtaWJmB5VmWSuaa1U8NCArJ2MA24rYMmQeA5gBHWDRtPIqQOg/6ZvZEXiThKTHoudAJmkWPbWKYjIf7zapvM0MAD7O8hfNY1pU6B349vvotvy9v2iXwNOFANMrIc57/Ku5Tvsu1izwqqlOAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIwLTEwLTEzVDEyOjAxOjA0KzAwOjAwIUs5FQAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMC0xMC0xM1QxMjowMTowNCswMDowMFAWgakAAAAZdEVYdFNvZnR3YXJlAHd3dy5pbmtzY2FwZS5vcmeb7jwaAAAAAElFTkSuQmCC) no-repeat center center;
  background-size: 20px;
  padding: 35px 0 0 35px;
  display: inline-block;
}

div.icon-trafficLight {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QAAAAAAAD5Q7t/AAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAhklEQVRIx+2RwQ2DMBAE16nDPKB0P1AqgAIgtaCkieHjSImF4QQ8b14n3Wl3dSs5VwBeHDPtaYQDAyxBQghVnceG6OcbrVgNkqKkJs9/QTJvy1uelVfEn5umctNbDDpgOWGwAJ3lpQJaIBUCAxCz+FjsEtB6yV6yl+wl31VywWzQnww3Tp0VnZpacqu5CHUAAAAldEVYdGRhdGU6Y3JlYXRlADIwMjAtMTAtMDlUMTI6MTA6MTcrMDA6MDCBUOaHAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDIwLTEwLTA5VDEyOjEwOjE3KzAwOjAw8A1eOwAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAAASUVORK5CYII=) no-repeat center center;
  padding: 35px 0 0 35px;
  display: inline-block;
}

div.icon-spf {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAACp0lEQVRIS9WVS2xNURSGvz8GiBh4jbxiIATxmiDShDAxlSAeYUAIqo1OdODRYsCkQpUQBkQJElMTDYmImlBSQgzEa+Q1EMFAlvw3+9bp6Tm3nVrJzbnn7L3Wv/Za//q3GIJFxHBgDrAgbX8C9Er6PZi7am2IiLVAYwo8Mrf3J2Cgk5JulMUpBYiIS8Dm5PgeeAm8Su8zgJnA5PR+WdKWIpBCgIj4DIwDvgHNks4XOUfEduAYMAb4Iml8ft8AgIi4AmwErkrys88iotsvkhbnvncCG4BOSZuya/0AImK9AwNdklbms4mISABFid0BVhhI0rWqb9/GiJgL3AXGAtOBP66zpNvVzUUAEbEq9WcY8Br4CiyX9KySTMZ5N3AaaJXUEhGngD3ATaBJ0ocsQERMAtqANUC7pIaIaAEOAfWSOvIAF4CtgGt4LwWvB0alJE4Aewv+/0iJtQPLAPfwoqRteYDHie/zqseLiPlmEbCuhOfXzSJJPV5PZX7q+ZC0MA9Qq4HOzEf30+YTupR+9rN8n7I9MAUXAbMlvUgZeaCaAPO9yDwfbZIqAxgRs4DnwKMqlbMA1aZaHh4AO5NMjE6RjwL7C/5/t1wAZ4GlgGWj0vR8iZzlOaBFUmtEeEL3AWfSt085Fk3wd2AXcFxSc4ZFO6rTnz2BlfJ+Yk0dYP2Zlq1zyRy4L2+SLtnfrKqTZCH8Nwephi7BEddRkuW5ZgOzixHR6/4BByS5nBUrGvmHgLWmQ5LnoM/KpCIiPKAe1G5JS7I+ZWr6FpgCfAQaJN0qolBErAZMjonAO0lT8/tq3QdmgdlhM5B1xneCzXeB9cqBbY2SDDTABrvRrI6HAU/liJz3L8DTf1BSV1Hwwh6UlMLBLRv+2SwNPZIMUtNqnmAw56Gs//8AfwEguxIoVgPDyAAAAABJRU5ErkJggg==) no-repeat center center;
  padding: 24px 0 0 24px;
  display: inline-block;
}

span.icon-alarm-red {
  padding: 9px;
  color: #e43351;
}

span.icon-alarm-yellow {
  padding: 9px;
  color: #f8b100;
}

span.icon-alarm-green {
  padding: 9px;
  color: #96be2c;
}

span.icon-bell-off {
  padding: 9px;
  color: #e43351;
  margin-left: 5px;
}

div.icon-projectskema {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QAAAAAAAD5Q7t/AAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAy0lEQVRIx92VTQrCMBBG2+LOO+gBPImeyqVrUYz0IC5c9SAi6EGeCwcM0TSZSbrxg0LJhPem07+m+bsAG+BJOlurIAd+N0sy4AAL4Cbnu+oC2WeT5ArMEo3AJNEKQklYm6mfAmkid29nEYzkXGVEkfRAuuFJ4UaBA1o5DsAVmNcS+PAln0/IpYbAh5+AAViJZF8q8OHOWx94vxNtiaAHOm/mX7WSm+zDj2p4QlAOF0Hsh9NK3QXrbnTmPwRr4BHSI1eog2c2oB/LlHkBdEn46XZrjvUAAAAldEVYdGRhdGU6Y3JlYXRlADIwMjAtMTAtMjdUMDg6NDA6MzgrMDA6MDAXhNxtAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDIwLTEwLTI3VDA4OjQwOjM4KzAwOjAwZtlk0QAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAAASUVORK5CYII=) no-repeat center center;
  padding: 35px 0 0 35px;
  background-size: 20px;
  display: inline-block;
}

div.icon-rotator {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QAAAAAAAD5Q7t/AAAACXBIWXMAAA7EAAAOxAGVKw4bAAAD5UlEQVRo3u2ZT4gbdRTHv++XJUzwT6HuoUrB0nhQKR6kerG9eCgL1l3FdT1ZpLB4kL1Edmf29rtlJpsuSPEgQvdiD6ZQdS1ioTf/gFUQ24KSS3qQIloKVXGSyWa+Hjq7JNNkMzOZcT3M9zZv5v3m98n7vfd+8wuQK1euXLlyJZekPaDW+mHDMI6TPCIijwPYB2ALwB2SvwH4rtPp/KC1/vt/B6K1njIMYx7AaQAvAiiMcekC+ALARrvd/lxr7e85iOM48yQdAIeT+JO8JiIrlmVdnmQeU0kd19fX93ued47k3CQTEJEnRaQ0yRhAwohUq9UnROQyRkdhC8BVki0R+V1EPJLTAJ4C8CwAI3jOE5E3TNP89D8HCSC+AnBgyO2fAKwXi8VLlUrlzjB/rfWDhmHMAlgSEScNiNggwXL6HvdH4raIVFzXPZ9G4iZRrBzxPO9cGEJEbiilZpeXl1t7AbAtFfVBx3HmAQwktojccF33hawgHMc5mCqI1noqKLH9uq2UmtVa/5kFRLVanSHZtG37ndRAgmYXXlKVrCJRrVZnROQTACUAZ6PARF1ap/svSF5zXfd8RhAnAojtEi0BzNJEILZt78O9bceORORMFtUpiMRnfRA7rwTw3m6RiRKRYxjcO20Vi8VLGUH0RyKs7cgMhRkLQvJIyHR1VLPLEGIszFgQpdShkOnmHkHsCjMWxPf9h0KmWylCnIgJEYZZ6jcAAGzbXgTwAZJtJD2l1GsrKyup5I5t22cAVPpMH1mW9eZuPjsRsSzrQwBLABjzvW2Sc2lBBHqs/4Lk2KY7sLQsy3o/Jkyb5Kurq6tfpggBAIdC1zdjgcSEyQSiXq9PA3guZL4eGyQiTFaRQK/XexmDfaurlPomEUgfzNtDYDyl1OtZQDQajQIGkxwArpim+VdikAAmXACySOwdtVqtU+EGTHIjiu/YPtK3zNyslhMA1Gq1Msl6yNwsl8sXo/hH7hmO4xw0TfPXLCCCjem3AJ4emJzInGmam6mCZKVarVb2fX9zCMQF0zQXoo6T+FxrUjUajUKr1Trl+34dwP7Q7SbJxTjjpRYRx3FeIWmJyFkAm6MqTb1en+52uyeVUu8O2VkDwK1CoXAs7tdnKiABxMcAioGpLSI/kvxZRP4gWRSRAyQPAziK0WfDzUKhMJPkEzqNs98wRCKJyAWSi5Zl3U3iP3GO+L7/D4BfROSZhEM0RWQ5anUa+UNMCgIAWmtVKpVOknwLwEsYH50ugCskN8rl8sWFhYXepHNIvfyura09QPJor9d7XkQeBfAIyQKAu7i3i73e6XS+TvuPnly5cuXKlWsS/QvhKq1sLYByvgAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAyMC0xMC0wNlQxMTozNjo1NSswMDowMPTXOyYAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMjAtMTAtMDZUMTE6MzY6NTUrMDA6MDCFioOaAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAABJRU5ErkJggg==) no-repeat center center;
  padding: 25px 25px 25px 25px;
  display: inline-block;
}

div.icon-briefcase {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QAAAAAAAD5Q7t/AAAACXBIWXMAAA7EAAAOxAGVKw4bAAAArElEQVRIx+2TMQ6DMAxF/RHHQD1MpQ5wEa7ByCGrLuk9fpcEVa4hKcQdKt7oON/2jyNyUgOSEz+ZPcXrFUlKpXFN42GpWYDkQDJoD3THuXjU6NM53hKDiHSVGg8ALrs8zaF13N+gLe3KigNA7u7vtqgERPYWeCQ71lZSs5F7t5JvrMd1mVp3lKyw4pZluftFW/St72tv4IKe4Cki3cEfHbYmGGORI+Kjtyt/xgvhYilSEQrPJAAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAyMC0xMC0wNlQxMjo1Njo1NyswMDowMDY3jIIAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMjAtMTAtMDZUMTI6NTY6NTcrMDA6MDBHajQ+AAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAABJRU5ErkJggg==) no-repeat center center;
  background-size: 20px;
  padding: 35px 0 0 35px;
  display: inline-block;
}

div.icon-briefcase-account {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QAAAAAAAD5Q7t/AAAACXBIWXMAAA7EAAAOxAGVKw4bAAAA7klEQVRIx+2UQW7CQAxFvyv2VbMrQr0Gm14AddFb9EDhIizoBaqygGMwSKw5wetmgoIRE6cJm6pPGimy7Hz7exLpzwO8AQeuScBiDIHEbfZjCAAQjXse7mXtlcAtr33HXXG/G2t7LWk2UuPJzF68QKeffTAzu7AoQC1pms+ytyJlvlyuAd+lgiY3OsHOjY+kbaQwKjD3E/jYUIsAamCaz7Ir+TxtW6D34go0t2gSyD1JWknaSDrm2LOkV0nvkh6HWLQGqkJNBXyWLOoSeAo0Vv16B42PkekjOzjI/YsGLD41D+3v4COLDCXld/0T4wfrMqeKIP60yQAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAyMC0xMC0wN1QwNzowMjoyMCswMDowMK+hdNkAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMjAtMTAtMDdUMDc6MDI6MjArMDA6MDDe/MxlAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAABJRU5ErkJggg==) no-repeat center center;
  background-size: 20px;
  padding: 35px 0 0 35px;
  display: inline-block;
}

div.icon-house-solid {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QAAAAAAAD5Q7t/AAAACXBIWXMAAABgAAAAYADwa0LPAAAApUlEQVRIx+3TMQ4BQRSAYSOikWjVGhdwA50rcAilK2jdgSvo3GAvoFFvK9Eo+FQTBZvdHSua/Q/wvfeSmU6nrakQsMEW3abxPvZe7dBvCh/g4L0jht/iI2SKyzBKxcc4Ke+MSV18irwCHssxrYrPcKmBx66Yl+FL3BLw2A2LInyF+xd47IF1dEPBMCkPI4Tw5jX7Iz/US92u6pU/v6Ad8P8Bbf/vCQE4pIQLr5KUAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIxLTAxLTA0VDA3OjE2OjQzKzAwOjAwHCZzVQAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMS0wMS0wNFQwNzoxNjo0MyswMDowMG17y+kAAAAASUVORK5CYII=) no-repeat center center;
  padding: 35px 0 0 35px;
  display: inline-block;
}

div.icon-house-grey50 {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QAAAAAAAD5Q7t/AAAACXBIWXMAAABgAAAAYADwa0LPAAABKElEQVRIx+2TL0/DQBiHf+9KSNol2GoMX2C2ihrIggA5HF8A2z+iqWj6NvcFcCgUBrNASLakArkvgEHPYtqQXA+zkVFYei0hM33k3Xu/5973ckBPA4ZuoVKKLMti13XPHMd5zvNc6ZwjnaIoivZN07wFMFnJ7sqyvIrj+OPPAiHEUEp5D+C0tjUviuI8juP3zoIkSWzDMKYARltKFlLKcRiGy9YCIcShlPIJwFFDk29VVZ0EQfCqLUjTdEREUwB20whXLJVSY9/3F/WNwS/hx0Q0bxEOADYR5cxcf6fvgizLLonoEcBBi/A1QwAPzDzZXPz6B8x8DeAGwF6H8M28C9d1y9ls9gJseQNm1vpEdTzP+5E36BLUBu1x1G+n2+W/d9ALdi/o2T2fN2paFoIVlu0AAAAldEVYdGRhdGU6Y3JlYXRlADIwMjAtMTItMzBUMTA6MTE6NTcrMDA6MDBw0WWjAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDIwLTEyLTMwVDEwOjExOjU3KzAwOjAwAYzdHwAAAABJRU5ErkJggg==) no-repeat center center;
}

div.icon-house-grey80 {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QAAAAAAAD5Q7t/AAAACXBIWXMAAABgAAAAYADwa0LPAAABEElEQVRIx+2TL0/DQBjGf1caErJkdhqDad2+AYpljtwruksNX4DwEar4CjgUaZozM8tQJAjkZA0GvW9ASMNhRtJsjGs3yEx/8u6553n/5KDDg2qj1VrfAidRFN1kWfb5ZwEicgzcAxMApdSDc+7KWvvhexv4BGma9oDptzmAc84Ac2NMf68OkiQZVFU1A4ZbJIswDMd5ni9bB4jIKfAInHmKfAuC4KIoitfGASIyBGbAwDeCFUtgbK1drF9s7EBEzoGnFuastM9a69GvAVprA8wB7/J+oKeUmorIpH54VKv8Wil1B4Q7mNf9LuM4fi/L8gW278Dt4m6t3fDz/oN9aTyO9eqadvnvHXQBhw/oODxfkNJCAjccYSwAAAAldEVYdGRhdGU6Y3JlYXRlADIwMjAtMTItMzBUMTA6MTI6MjcrMDA6MDCRI9e5AAAAJXRFWHRkYXRlOm1vZGlmeQAyMDIwLTEyLTMwVDEwOjEyOjI3KzAwOjAw4H5vBQAAAABJRU5ErkJggg==) no-repeat center center;
}

div.icon-house-green {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QAAAAAAAD5Q7t/AAAACXBIWXMAAABgAAAAYADwa0LPAAABOklEQVRIx2NgGAUEADOxCv//Z2AUt9Pu8EoQ9zVVeLXr4EGG/8ToYyRG0apVWmxvRBjnMzAwRjEwMDD8Z2BYKvrmX1JY2LVfFFvQvVOPm5vt72qG/4yeaFr3/fnxPTDP684nsi2YvUdH/DcTw1YGBgZjHErOsv5j8E51ufKSZAsmH9RTZP77dwcDA6MaAU/e//eP2SPH5eItoi2Ytk/X+D/D/62MDAzihIKQgYGB4T8Dw0tGBkbvLKfLZ9HlmNAFpu/VcmJg+L+PWMOhrhRnYPh/cNpeXU90ORQLpu/Xjf7PyLSdgYGBj1jDkQA3A+P/DdP26UZhtWDqfp38////L2JgYGAjw3AYYGNg+L9k2n7dciTfYYJp+3SIykToIMvpCoZ5TOQYRApgIdd1xPqS5j4YtWDgLRgFAw8AH5BYVmv+Qa4AAAAldEVYdGRhdGU6Y3JlYXRlADIwMjAtMTItMzBUMTA6MTM6MjUrMDA6MDDpfq2uAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDIwLTEyLTMwVDEwOjEzOjI1KzAwOjAwmCMVEgAAAABJRU5ErkJggg==) no-repeat center center;
}

div.icon-text-grey50 {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QAAAAAAAD5Q7t/AAAACXBIWXMAAABgAAAAYADwa0LPAAAAOUlEQVRIx2NgGAXDHjCiC3R0dPynxMCKigoUM5kG2oeDH4zGwcCD0TgYeEB2HKCHNS4wGgejgHIAAKZ4EA03ayhEAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIwLTEyLTMwVDEwOjI5OjI1KzAwOjAwZ75bZgAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMC0xMi0zMFQxMDoyOToyNSswMDowMBbj49oAAAAASUVORK5CYII=) no-repeat center center;
}

div.icon-text-grey80 {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QAAAAAAAD5Q7t/AAAACXBIWXMAAABgAAAAYADwa0LPAAAAOUlEQVRIx2NgGAXDHjCiC4SGhv6nxMDVq1ejmMk00D4c/GA0DgYejMbBwAOy4wA9rHGB0TgYBZQDABvQEA0asr0QAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIwLTEyLTMwVDEwOjI5OjA2KzAwOjAwFHNGhgAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMC0xMi0zMFQxMDoyOTowNiswMDowMGUu/joAAAAASUVORK5CYII=) no-repeat center center;
}

i {
  font-size: 14px;
}

i.chevron {
  margin-right: 10px;
  height: 14px;
}

div.icon-bookshelf {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QAAAAAAAD5Q7t/AAAACXBIWXMAAABgAAAAYADwa0LPAAAA00lEQVRIx+2VQQ4BQRBFf7HiAOIGs5gjuIHIiMtYz5VYWNlzAYkjiD02eDZNyoQYPW3nr6p/urrq/+p0S398gNXZBPBIMKuVc0fr246AHCiBDZBFycKhuga2Lp4mVyBp5uLiFwoGLr4A/dQKVpJ2Ln+YtICZXSUtHDVOalFYF446At3UBTrAwdFvhx0zA5nZSdKyjk1RBQL8dR0B7WQWBa4HnP31TarAzPaS1o56OYcmFknS3MWTpBYFPguPXgnkDZuNw9PbXu0w+lD3ZzSdwR/NcQOfhhkFqmnKWgAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAyMS0wNC0wOFQwODoxODoxOCswMDowMPbQkj8AAAAldEVYdGRhdGU6bW9kaWZ5ADIwMjEtMDQtMDhUMDg6MTg6MTgrMDA6MDCHjSqDAAAAAElFTkSuQmCC) no-repeat center center;
  padding: 35px 0 0 35px;
  background-size: 20px;
  display: inline-block;
}

.child-circle {
  background-color: #ccc;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  -moz-border-radius: 20px;
  -webkit-border-radius: 20px;
  margin-right: 10px;
  display: inline-block;
  position: relative
}

.header-link {
  cursor: pointer;
  margin-left: 5px;
  line-height: 35px;
}

.infoba-header span {
  position: absolute;
  left: 34px;
  line-height: 35px;
  color: #fff;
  cursor: pointer;
  top: 5px
}

.icon-color-grey {
  color: #ccc !important
}

.icon-color-green {
  background-color: #96be2c
}

.icon-status-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.infoba-accordeon-header {
  padding-left: 24px;
  background-color: #FFFFFF;
  line-height: 46px;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 46px;
}

.infoba-accordeon-row {
  background-color: #FFFFFF;
  padding-left: 5px;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  justify-content: space-between;
  padding-top: 5px;
  padding-bottom: 5px;
}

.infoba-row {
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
  background-color: #fff;
  padding-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
}

.infoba-gray {
  color: #888
}

.infoba-header {
  color: #fff;
  padding: 4px;
  padding-left: 8px;
  padding-right: 10px;
  display: flex;
  justify-content: space-between;
  align-content: center;
  background: #873C79;
  position: relative;
}

.infoba-header:first-child {
  margin-top: 0
}



.infoba-header i {
  /*position: absolute;*/
  /*  top: 2px;*/
  line-height: 35px;
}

.infoba-link {
  color: #888 !important;
  cursor: pointer
}

.infoba-logo {
  height: 35px
}

.infoba-quick-access-item {
  padding: 20px 13px 20px 12px;
  cursor: auto;
  height: auto
}

.infoba-quick-access-item ul {
  list-style: disc;
  margin: 0
}

.infoba-quick-access-item li {
  margin-left: 16px
}

.infoba-rotate {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite
}

.infoba-section {
  padding-left: 24px;
  background-color: #FFFFFF;
  font-size: 12px;
  line-height: 14px;
  padding-bottom: 10px;
}

.main-section {
  border: 2px solid white;
  background-color: #FAFAFA;
}

.multiselect {
  height: 40px;
  width: 100%;
}

.pictureCircle {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  margin: -12px 0 0 -12px
}

.rotate {
  -moz-transition: all .3s linear;
  -webkit-transition: all .3s linear;
  transition: all .3s linear
}

.rotate.right {
  -moz-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg)
}

.rotate.up {
  -moz-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg)
}

@-webkit-keyframes rotating {
  from {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg)
  }

  to {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg)
  }
}

@keyframes rotating {
  from {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg)
  }

  to {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg)
  }
}

.slide-enter-active {
  -moz-transition-duration: .3s;
  -webkit-transition-duration: .3s;
  -o-transition-duration: .3s;
  transition-duration: .3s;
  -moz-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in
}

.slide-leave-active {
  -moz-transition-duration: .3s;
  -webkit-transition-duration: .3s;
  -o-transition-duration: .3s;
  transition-duration: .3s;
  -moz-transition-timing-function: cubic-bezier(0,1,.5,1);
  -webkit-transition-timing-function: cubic-bezier(0,1,.5,1);
  -o-transition-timing-function: cubic-bezier(0,1,.5,1);
  transition-timing-function: cubic-bezier(0,1,.5,1)
}

.slide-enter-to, .slide-leave {
  max-height: 100px;
  overflow: hidden
}

.slide-enter, .slide-leave-to {
  overflow: hidden;
  max-height: 0
}

.tab-container {
  display: flex;
  height: 50px;
  border-bottom: 2px solid #873C79;
}

.tab-item {
  color: #888888;
  flex-basis: 50%;
  text-align: center;
  vertical-align: middle;
  line-height: 48px;
  cursor: pointer;
}

.tab-item.active {
  font-weight: bold;
  color: #873C79;
  border-bottom: 5px solid #873C79;
}

.theme-circle {
  width: 18px;
  height: 18px;
  color: #fff;
  font-size: 12px;
  display: inline-block;
  margin-right: 5px;
  margin-top: -1px;
  text-align: center;
  line-height: 18px;
  border-radius: 10px;
  background-color: #873C79;
  margin-left: 10px;
}

.theme-circle-big {
  width: 40px;
  height: 40px;
  display: inline-block;
  margin-right: 10px;
  text-align: center;
  line-height: 40px;
  border-radius: 40px;
  margin-left: 27px;
  margin-bottom: 10px;
  font-size: 25px;
  color: #fff;
}

.whiteCircle {
  background-color: #fff;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  -moz-border-radius: 15px;
  -webkit-border-radius: 15px;
  position: absolute;
  display: inline-block;
  top: 50%;
  left: 50%;
  margin: -15px 0 0 -15px
}

.wrapper {
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.institution {
  margin-top: 30px;
}

.institution-name {
  font-size: 12px;
}

.group-name {
  font-weight: bolder;
}

.timeslot {
  background-color: #fff;
  padding: 10px;
  margin: 10px 0;
  cursor: pointer;
}

.timeslot-content {
  padding: 2px 0px 2px 10px;
  border-left-width: 4px;
  border-left-style: solid;
}

.timeslot-time {
  font-size: 12px;
}
</style>
