<style scoped></style>

<template>
  <div style="display: inline-block; width: 100%">
    <div style="font-size: 18px">
      KALENDA INFOSKÆRM
      <b-form-select
        v-if="showLoginList"
        v-model="selectedLogin"
        @change="setUserLoginDetails()"
      >
        <option
          v-for="login in loginList"
          :key="login.value.userId"
          :value="login.value"
        >
          {{ login.text }}
        </option>
      </b-form-select>
    </div>

    <div v-if="loading" style="text-align: center; margin-top: 12px">
      <b-spinner label="Spinning"></b-spinner>
    </div>

    <b-alert v-model="userNotLinked" variant="danger"
    >Din AULA-bruger er ikke knyttet sammen med nogen Kalenda-bruger - kontakt
      din leder eller ring til Greenbyte på 70 27 70 47.</b-alert
    >

    <div v-if="infoScreenLocationCode && !userNotLinked">
      <b-link
        v-bind:href="
          baseUrl +
          '/Display?location=' +
          infoScreenLocationCode +
          '&scheduleType=classic'
        "
        target="blank"
      ><i class="fas fa-external-link-alt"></i> Aktivitetsoversigt</b-link
      ><br />
      <b-link
        v-bind:href="
          baseUrl +
          '/Display?location=' +
          infoScreenLocationCode +
          '&scheduleType=working-hours'
        "
        target="blank"
      ><i class="fas fa-external-link-alt"></i> Ugens skema</b-link
      >
    </div>
  </div>
</template>

<script>
module.exports = {
  props: {
    axios: Function,
    getAulaToken: Function,
  },
  data: function () {
    return {
      baseUrl: window.location.href.includes("widget-test2")
        ? "https://login.kalendatest.dk/Kalenda"
        : "https://login.kalenda.dk/Kalenda",
      infoScreenLocationCode: "", // ?location=... query parameter for the info screen
      userNotLinked: false, // if false, the message that the user is not linked will be displayed
      loading: false, // true initially while getting the token and loading info screen location
      aulaToken: null,
      selectedOetidType: "",
      loginList: [],
      selectedLogin: "",
      userId: "",
      showLoginList: true,
    };
  },
  methods: {
    load() {
      var config = {
        method: "post",
        url: `${this.baseUrl}/widgetapi/getInfoscreenLocationCode`,
        headers: {
          "Content-Type": "text/plain",
        },
        data: JSON.stringify({
          aulaToken: this.aulaToken,
          userId: this.userId,
        }),
      };

      var self = this;
      self.loading = true;
      this.axios(config)
        .then(function (response) {
          if (response.data.status == "user_not_linked") {
            self.userNotLinked = true;
          }
          if (response.data.status == "success") {
            self.infoScreenLocationCode = response.data.infoScreenLocationCode;
            self.userNotLinked = false;
          }
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(() => {
          self.loading = false;
        });
    },
    getLoginList(token) {
      var config = {
        method: "post",
        url: `${this.baseUrl}/widgetapi/loginList`,
        headers: {
          "Content-Type": "text/plain",
        },
        data: JSON.stringify({
          aulaToken: token,
        }),
      };
      var self = this;
      this.axios(config)
        .then(function (response) {
          let tmpLoginList =
            response.data.loginList && response.data.loginList.length
              ? response.data.loginList
              : [];
          tmpLoginList.forEach((value) => {
            self.loginList.push({
              text: value.customerName,
              value: { userId: value.userId, url: value.url },
            });
          });
          if (self.loginList.length >= 1) {
            self.selectedLogin = self.loginList[0].value;
          }
          self.showLoginList = self.loginList.length > 1;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    setUserLoginDetails() {
      if (this.selectedLogin.url != null) {
        this.baseUrl = this.selectedLogin.url;
      }
      this.userId = this.selectedLogin.userId;
      this.load();
    },
  },
  mounted() {
    this.aulaToken = this.getAulaToken();
  },
  watch: {
    aulaToken: function () {
      if (this.aulaToken != undefined) {
        this.getLoginList(this.aulaToken);
        this.load();
      }
    },
  },
};
</script>
