<template>
  <div class="widget-W0013V0002">
    <div>
      <div
        v-bind:class="{'links-title-employee': userProfile == 'employee', 'links-title-other': userProfile != 'employee'}">
        NYTTIGE LINKS
      </div>
      <ul v-if="posts && posts.length">
        <li v-tooltip v-for="(post, index) in posts" :class="{ 'first': index === 0 }">
          <span v-if="post.institutionsNavn != null" class="institution-title">{{ post.institutionsNavn }}</span>
          <a v-if="post.linkText != null"
             v-bind:class="{'links-a-employee': userProfile == 'employee', 'links-a-other': userProfile != 'employee'}"
             v-bind:href="post.linkUrl" target="_blank">&bull;&ensp;{{ post.linkText }}</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  directives: {
    tooltip: {
      bind: function (el) {
        el.addEventListener("mouseover", function (evt) {
          let targetEl = evt.target;
          if (targetEl.offsetWidth < targetEl.scrollWidth) {
            if (targetEl.firstElementChild.tagName.toUpperCase() == "SPAN") {
              targetEl.setAttribute("title", evt.target.textContent);
            } else {
              targetEl.setAttribute("title", evt.target.textContent.substring(3));
            }
          } else {
            targetEl.hasAttribute("title") && targetEl.removeAttribute("title");
          }
        });
      }
    }
  },
  props: {
    axios: Function,
    sessionUUID: String,
    userProfile: String,
    placement: String,
    institutionFilter: Array
  },
  data: function () {
    return {
      posts: []
    };
  },
  created: function () {
    this.axios.get("https://api.ibkservices.dk/?institutionsnummer=" + this.institutionFilter + "&userprofile=" + this.userProfile)

      .then(response => {
        this.posts = response.data;
      })
      .catch(e => {
        console.log(e);
      });
  }
};
</script>

<style lang="scss">
.widget-W0013V0002 {
  * {
    background-color: #fff;
  }

  ul {
    margin: 0;
    padding: 12px;
  }

  li {
    list-style: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media only screen and (max-width: 1149px) {
    .links-a-employee {
      color: #222350 !important;
      font-size: 15px !important;
      line-height: 1.5 !important;
    }

    .links-a-employee:hover {
      color: #007a8d !important;
      text-decoration: none !important;
    }

    .links-a-employee:focus {
      box-shadow: none !important;
      outline-color: #fff !important;
    }

    .links-a-other {
      color: #222350 !important;
      font-size: 15px !important;
      line-height: 1.5 !important;
    }

    .links-a-other:hover {
      color: #18638f !important;
      text-decoration: none !important;
    }

    .links-a-other:focus {
      box-shadow: none !important;
      outline-color: #fff !important;
    }

    .institution-title {
      margin-top: 8px;
      text-transform: uppercase;
      font-size: 15px;
      display: inline-block;
      white-space: normal;
    }
  }

  @media only screen and (min-width: 1150px) {
    .links-a-employee {
      color: #222350 !important;
      font-size: 16px !important;
      line-height: 1.5 !important;
    }

    .links-a-employee:hover {
      color: #007a8d !important;
      text-decoration: none !important;
    }

    .links-a-employee:focus {
      box-shadow: none !important;
      outline-color: #fff !important;
    }

    .links-a-other {
      color: #222350 !important;
      font-size: 16px !important;
      line-height: 1.5 !important;
    }

    .links-a-other:hover {
      color: #18638f !important;
      text-decoration: none !important;
    }

    .links-a-other:focus {
      box-shadow: none !important;
      outline-color: #fff !important;
    }

    .institution-title {
      margin-top: 8px;
      text-transform: uppercase;
      font-size: 16px;
      display: inline-block;
      white-space: normal;
    }
  }

  .first .institution-title {
    margin-top: 2px !important;
  }

  .links-title-employee {
    font-family: Lato, sans-serif;
    background-color: #007a8d;
    font-size: 18px;
    padding: 12px !important;
    margin-bottom: 0 !important;
    color: #fff;
    display: block;
    text-transform: uppercase;
  }

  .links-title-other {
    font-family: Lato, sans-serif;
    background-color: #18638f;
    font-size: 18px;
    padding: 12px !important;
    margin-bottom: 0 !important;
    color: #fff;
    display: block;
    text-transform: uppercase;
  }
}
</style>
