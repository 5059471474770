<script>

const ConfirmBackModal = {
    props: {
        onClose: Function,
        onConfirm: Function,
        translations: Object,
    },
}

export default ConfirmBackModal

</script>

<template>
    <div class="modal-container confirm-back-modal">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" class="modal-top-right-icon pointer" @click="onClose">
            <path d="M26 6.96l-0.96-0.96-9.040 9.040-9.040-9.040-0.96 0.96 9.040 9.040-9.040 9.040 0.96 0.96 9.040-9.040 9.040 9.040 0.96-0.96-9.040-9.040 9.040-9.040z" />
        </svg>
        <h6 class="modal-heading text-color-primary">{{ translations.confirmBackModal.header }}</h6>
        <p class="modal-text text-color-lighter">{{ translations.confirmBackModal.text }}</p>
        <div class='modal-buttons'>
            <div class="modal-button normal" @click="onClose">{{ translations.confirmBackModal.cancel }}</div>
            <div class="modal-button danger" @click="onConfirm">{{ translations.confirmBackModal.confirm }}</div>
        </div>
    </div>
</template>