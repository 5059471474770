<template>
  <div :key="section" class="main-section">
    <section v-if="loading">
      <div class="infoba-header">
        <div style="display:flex">
          <i class="fal fa-home"></i>
          <span @click="goto(headerLink)" class="header-link">INFOBA</span>
        </div>
        <img :src="domain + '/Images/logowhite.svg'" class="infoba-logo" />
      </div>
      <div class="infoba-quick-access-item " style="text-align:center; padding:20px !important">
        <div class="material-icons icon-rotator infoba-rotate"></div>
      </div>
    </section>
    <section v-else-if="section!='default' ">
      <div class="infoba-header">
        <div style="display:flex">
          <i class="fal fa-home"></i>
          <span @click="goto(headerLink)" class="header-link">INFOBA</span>
        </div>
        <img :src="domain + '/Images/logowhite.svg'" class="infoba-logo" />
      </div>
      <div class="infoba-quick-access-item">
        <widget-html :html="message"></widget-html>
      </div>
    </section>
    <section v-else>
      <div style="background: #873c79;" class="infoba-header">
        <div style="display: flex">
          <div v-if="tileType!=124" class="icon-spf"></div>
          <div v-if="tileType==124" class="icon-trafficLight"></div>
          <div @click="goto(headerLinkC.url)" class="header-link">{{ item.Binder.Tile.Header }}</div>
        </div>
        <img :src="domain + '/Images/logowhite.svg'" class="infoba-logo" />
      </div>
      <div class="wrapper">
        <div v-if="tileType!=124">
          <div v-if="tile.ReminderList.length">
            <div class="infoba-accordeon-header" style="padding-left:35px; ">
              <div class="ellipsis" style="display: flex;align-items: center;">

                <div><b>P&aring;mindelse</b></div>
              </div>
            </div>
            <div class="infoba-section">

              <div v-for="reminder in item.Binder.Tile.SpfTile.ReminderList" v-bind:key="reminder.ReminderId">
                <div style="display:flex; justify-content:space-between; margin-left:10px; padding-bottom:15px;">
                  <div>
                    <div>{{ item.Binder.Tile.Header }} {{ reminder.Description }}</div>
                    <div style="cursor:pointer; display:inline-block"
                         @click="goto(domain+'/IN/SPF/Evaluate/'+reminder.ChildId+'?spfTypeId='+tile.TypeId)">
                      {{ reminder.ChildName }}
                    </div>
                  </div>
                  <div style="display:flex;align-items:center;cursor:pointer;"
                       @click="dismissReminder(reminder.ReminderId,reminder.TypeId)">
                    <div>Afvis</div>
                    <i class="fas fa-bell-slash icon-bell-off"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <template v-for="header in headers">

          <div class="infoba-accordeon-header" style="padding-left:25px; ">
            <div class="ellipsis" style="display: flex;align-items: center;">
              <div class="theme-circle" style="background-color: #873c79;"> {{ header.Count }}</div>
              <div><b>{{ header.Month | firstToUpper | getMonth }}</b>{{ header.Month | getYear }}</div>
            </div>
            <i class="fas fa-chevron-right rotate chevron" v-bind:class="{right:header.Open}"
               @click="header.Open = !header.Open" style="cursor:pointer"></i>
          </div>
          <div>
            <template v-for="child in header.ChildList">
              <transition name="slide" v-bind:key="child.Name">
                <div v-show="header.Open" class="infoba-accordeon-row">
                  <div style="display:flex;align-items: center; flex-grow:1; justify-content:space-between">
                    <div v-if="tileType!=124" style="cursor:pointer;margin-left: 53px;"
                         @click="goto(domain+'/IN/SPF/Evaluate/'+child.ChildId+'?spfTypeId='+tile.TypeId)">
                      {{ child.Name }}
                    </div>
                    <div v-else style="cursor:pointer;margin-left: 53px;"
                         @click="goto(domainOld+'/IN/childTrivsel.aspx?r=l&cid='+child.ChildId)"> {{ child.Name }}
                    </div>

                    <span v-if="tileType!=124" :class="child.Reminder!='none'?'icon-alarm-'+ child.Reminder:''">
                                            <i :class="child.Reminder!='none'?'far fa-alarm-clock':''"></i>
                                        </span>
                    <span v-if="tileType==124" v-bind:class="statusColor(child.NextEvaluationDate)">
                                            <i class="fal fa-alarm-clock"></i>
                                        </span>
                  </div>
                </div>
              </transition>
            </template>
          </div>
        </template>
      </div>
    </section>
  </div>
</template>

<script>

var domain = "https://www2.infoba.dk";
var domainOld = "https://www.infoba.dk";

export default {
  props: {
    /* normal and secure */
    axios: Function,
    sessionUUID: String,
    placement: String,
    currentWeekNumber: String,
    isMobileApp: Boolean,
    userProfile: String,
    /* only secure */
    getAulaToken: Function,
    assuranceLevel: Number,
    moment: Function,
    childFilter: Array,
    institutionFilter: Array,
    group: String
  },
  data: function () {
    return {
      loading: true,
      section: "default",
      message: "",
      timer: "",
      headerLink: domain,
      date: this.moment().format("DD-MM-YYYY"),
      aulaToken: null,
      fields: {},
      firstMonth: "",
      domain: domain,
      domainOld: domainOld,
      headers: [],
      tile: {},
      tileType: 0,
      item: {
        Binder: { Tile: { SpfTile: { ChildList: [] }, TrvslTile: { ChildList: [] } } }
      }
    };
  },
  filters: {
    firstToUpper(value) {
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
    getYear(value) {
      return value.substring(value.length - 4, value.length);
    },
    getMonth(value) {
      return value.substring(0, value.length - 4);
    }
  },
  methods: {
    statusColor: function (date) {
      var datediff = this.moment(date).diff(this.moment());
      if (this.moment.duration(datediff, "days").asDays() > 14) {
        return "icon-alarm-green";
      } else if (this.moment.duration(datediff, "days").asDays() > 7) {
        return "icon-alarm-yellow";
      } else {
        return "icon-alarm-red";
      }
    },
    getData: function (match) {
      var uuid = this.sessionUUID;
      this.axios.defaults.headers.common["Authorization"] = this.aulaToken;
      this.axios.defaults.headers.common["AuthorizationContext"] = "INTile";

      if (this.childFilter != null)
        this.axios.defaults.headers.common["childFilter"] = this.childFilter.join();
      if (this.institutionFilter != null)
        this.axios.defaults.headers.common["institutionFilter"] = this.institutionFilter.join();
      if (this.group != null)
        this.axios.defaults.headers.common["groupFilter"] = this.group;
      if (this.userProfile != null)
        this.axios.defaults.headers.common["userProfile"] = this.userProfile;
      if (this.currentWeekNumber != null)
        this.axios.defaults.headers.common["currentWeekNumber"] = this.currentWeekNumber;
      if (this.isMobileApp != null)
        this.axios.defaults.headers.common["isMobileApp"] = this.isMobileApp;
      //Tras - 24
      //TrasMo - 26
      //MIO - 28
      //AlleMed - 30
      //Relationsanalyse -70
      //Trivsel - 124
      //Holbaek129 - 90
      this.tileType = 30;
      this.axios.get(domain + "/api/Aula/INTileGet/" + this.tileType, {
        withCredentials: true,
        credentials: "same-origin"
      })
        .then((response) => {
          this.item = response.data;
          handleStatus(this, true);

          if (this.tileType == 124)
            this.tile = this.item.Binder.Tile.TrvslTile;
          else
            this.tile = this.item.Binder.Tile.SpfTile;
          this.groupByDates(this.tileType);

          if (this.tile.length == 0) {
            this.section = "nodata";
            this.message = "Vi har ikke noget relevant data at vise dig.";
          }
        })
        .catch((error) => {
          handleStatus(this);
        })
        .finally(() => (this.loading = false));
    },
    goto: function (redirectUrl) {
      let serial = this.item.INFOBAserial;
      let token = this.item.INFOBAtoken;
      let url = "";
      //if logged in
      if (serial === null || token === null) {
        let aulaReferrer = "?referrer=Aula";
        if (redirectUrl.indexOf("?") !== -1) {
          aulaReferrer = "&referrer=Aula";
        }
        url = redirectUrl + aulaReferrer;
      }
      //if not logged in
      else {
        url = domain + "/Login/Index?serial=" + serial + "&token=" + token + "&referrer=Aula&returnUrl=" + encodeURIComponent(redirectUrl);
      }
      var a = document.createElement("a");
      a.href = url;
      a.target = "INFOBA";
      a.click();
    },
    dismissReminder: function (reminderId, reminderTypeId) {
      var uuid = this.sessionUUID;
      this.axios.defaults.headers.common["Authorization"] = this.aulaToken;


      this.axios.get(domain + "/api/Aula/DismissReminder?reminderId=" + reminderId + "&reminderTypeId=" + reminderTypeId, {
        withCredentials: true,
        credentials: "same-origin"
      })
        .then((response) => {

          for (var i = 0; i < this.tile.ReminderList.length; i++) {
            if (this.item.Binder.Tile.SpfTile.ReminderList[i].ReminderId == response.data.Binder.Reminder.ReminderId) {
              this.item.Binder.Tile.SpfTile.ReminderList.splice(i, 1);
            }
          }
        })
        .catch((error) => {
          handleStatus(this);
        })
        .finally(() => (this.loading = false));
    },
    groupByDates: function () {
      let months = [];
      this.headers = [];

      for (let i = 0; i < this.tile.ChildList.length; i++) {
        if (months.indexOf(this.moment(this.tile.ChildList[i].NextEvaluationDate).format("MMMM YYYY")) === -1) {
          months.push(this.moment(this.tile.ChildList[i].NextEvaluationDate).format("MMMM YYYY"));
        }
      }
      for (let i = 0; i < months.length; i++) {
        this.headers.push({
          Open: false,
          Month: months[i],
          Count: this.tile.ChildList.filter((item) => this.moment(item.NextEvaluationDate).format("MMMM YYYY") == months[i]).length,
          ChildList: this.tile.ChildList.filter((item) => this.moment(item.NextEvaluationDate).format("MMMM YYYY") == months[i])
        });
      }

    }
  },
  computed: {
    headerLinkC: function () {
      if (this.item.Binder.Tile.Url == undefined || this.item.Binder.Tile.Url.length == 0)
        return { url: "", style: "" };
      else
        return { url: this.item.Binder.Tile.Url };
    }
  },
  created: function () {
    this.aulaToken = this.getAulaToken();
    this.moment.locale("da");
  },
  watch: {
    aulaToken: function () {
      if (this.aulaToken !== undefined) {
        var t = this;
        t.getData(false);
      }
    }
  }
};

function handleStatus(t) {
  clearTimeout(t.timer);
  if (t.item && t.item.Status == 200) {
    if (t.item.Binder.Tile == undefined) {
      t.section = "error";
      t.message = "Din bruger har ikke adgang til denne widget i INFOBA. Kontakt <a href=\"https://web.infoba.dk/support/\" class=\"infoba-link\">support</a>.";
    } else {
      t.section = "default";
    }
  } else if (t.item && t.item.Status == 401) {
    if (t.item.Message == "Invalid role" || t.item.Message == "Module payment missing") {
      t.section = "error";
      t.message = "Din bruger har ikke adgang til denne widget i INFOBA. Kontakt <a href=\"https://web.infoba.dk/support/\" class=\"infoba-link\">support</a>.";
    } else {
      t.section = "login";
      t.message = "For at se data fra INFOBA, skal dine konti f&oslash;rst sammenk&aeligdes. <div><a href=\"" + domain + "/IN/Personel/MatchAula/" + t.item.Username + "\" class=\"infoba-link\">Start sammenk&aeligdning <i class=\"material-icons icon-arrowright-circle\" style=\"vertical-align: middle; margin-top: 2px; color:#96be2c\"></i></a></div>";
      //t.timer = setTimeout(function () { t.getData() }.bind(t), 5000);
      t.headerLink = domain + "/IN/Personel/MatchAula/" + t.item.Username;
    }
  } else {
    t.section = "error";
    t.message = "Undskyld. Det er i &oslash;jeblikket ikke muligt at vise dig den &oslash;nskede information. Vi h&aring;ber, du vil pr&oslash;ve igen senere.";
  }
}

</script>
<style scoped>
div.icon-rotator {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QAAAAAAAD5Q7t/AAAACXBIWXMAAA7EAAAOxAGVKw4bAAAD5UlEQVRo3u2ZT4gbdRTHv++XJUzwT6HuoUrB0nhQKR6kerG9eCgL1l3FdT1ZpLB4kL1Edmf29rtlJpsuSPEgQvdiD6ZQdS1ioTf/gFUQ24KSS3qQIloKVXGSyWa+Hjq7JNNkMzOZcT3M9zZv5v3m98n7vfd+8wuQK1euXLlyJZekPaDW+mHDMI6TPCIijwPYB2ALwB2SvwH4rtPp/KC1/vt/B6K1njIMYx7AaQAvAiiMcekC+ALARrvd/lxr7e85iOM48yQdAIeT+JO8JiIrlmVdnmQeU0kd19fX93ued47k3CQTEJEnRaQ0yRhAwohUq9UnROQyRkdhC8BVki0R+V1EPJLTAJ4C8CwAI3jOE5E3TNP89D8HCSC+AnBgyO2fAKwXi8VLlUrlzjB/rfWDhmHMAlgSEScNiNggwXL6HvdH4raIVFzXPZ9G4iZRrBzxPO9cGEJEbiilZpeXl1t7AbAtFfVBx3HmAQwktojccF33hawgHMc5mCqI1noqKLH9uq2UmtVa/5kFRLVanSHZtG37ndRAgmYXXlKVrCJRrVZnROQTACUAZ6PARF1ap/svSF5zXfd8RhAnAojtEi0BzNJEILZt78O9bceORORMFtUpiMRnfRA7rwTw3m6RiRKRYxjcO20Vi8VLGUH0RyKs7cgMhRkLQvJIyHR1VLPLEGIszFgQpdShkOnmHkHsCjMWxPf9h0KmWylCnIgJEYZZ6jcAAGzbXgTwAZJtJD2l1GsrKyup5I5t22cAVPpMH1mW9eZuPjsRsSzrQwBLABjzvW2Sc2lBBHqs/4Lk2KY7sLQsy3o/Jkyb5Kurq6tfpggBAIdC1zdjgcSEyQSiXq9PA3guZL4eGyQiTFaRQK/XexmDfaurlPomEUgfzNtDYDyl1OtZQDQajQIGkxwArpim+VdikAAmXACySOwdtVqtU+EGTHIjiu/YPtK3zNyslhMA1Gq1Msl6yNwsl8sXo/hH7hmO4xw0TfPXLCCCjem3AJ4emJzInGmam6mCZKVarVb2fX9zCMQF0zQXoo6T+FxrUjUajUKr1Trl+34dwP7Q7SbJxTjjpRYRx3FeIWmJyFkAm6MqTb1en+52uyeVUu8O2VkDwK1CoXAs7tdnKiABxMcAioGpLSI/kvxZRP4gWRSRAyQPAziK0WfDzUKhMJPkEzqNs98wRCKJyAWSi5Zl3U3iP3GO+L7/D4BfROSZhEM0RWQ5anUa+UNMCgIAWmtVKpVOknwLwEsYH50ugCskN8rl8sWFhYXepHNIvfyura09QPJor9d7XkQeBfAIyQKAu7i3i73e6XS+TvuPnly5cuXKlWsS/QvhKq1sLYByvgAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAyMC0xMC0wNlQxMTozNjo1NSswMDowMPTXOyYAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMjAtMTAtMDZUMTE6MzY6NTUrMDA6MDCFioOaAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAABJRU5ErkJggg==) no-repeat center center;
  padding: 25px;
  display: inline-block;
}


div.icon-spf {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAACp0lEQVRIS9WVS2xNURSGvz8GiBh4jbxiIATxmiDShDAxlSAeYUAIqo1OdODRYsCkQpUQBkQJElMTDYmImlBSQgzEa+Q1EMFAlvw3+9bp6Tm3nVrJzbnn7L3Wv/Za//q3GIJFxHBgDrAgbX8C9Er6PZi7am2IiLVAYwo8Mrf3J2Cgk5JulMUpBYiIS8Dm5PgeeAm8Su8zgJnA5PR+WdKWIpBCgIj4DIwDvgHNks4XOUfEduAYMAb4Iml8ft8AgIi4AmwErkrys88iotsvkhbnvncCG4BOSZuya/0AImK9AwNdklbms4mISABFid0BVhhI0rWqb9/GiJgL3AXGAtOBP66zpNvVzUUAEbEq9WcY8Br4CiyX9KySTMZ5N3AaaJXUEhGngD3ATaBJ0ocsQERMAtqANUC7pIaIaAEOAfWSOvIAF4CtgGt4LwWvB0alJE4Aewv+/0iJtQPLAPfwoqRteYDHie/zqseLiPlmEbCuhOfXzSJJPV5PZX7q+ZC0MA9Qq4HOzEf30+YTupR+9rN8n7I9MAUXAbMlvUgZeaCaAPO9yDwfbZIqAxgRs4DnwKMqlbMA1aZaHh4AO5NMjE6RjwL7C/5/t1wAZ4GlgGWj0vR8iZzlOaBFUmtEeEL3AWfSt085Fk3wd2AXcFxSc4ZFO6rTnz2BlfJ+Yk0dYP2Zlq1zyRy4L2+SLtnfrKqTZCH8Nwephi7BEddRkuW5ZgOzixHR6/4BByS5nBUrGvmHgLWmQ5LnoM/KpCIiPKAe1G5JS7I+ZWr6FpgCfAQaJN0qolBErAZMjonAO0lT8/tq3QdmgdlhM5B1xneCzXeB9cqBbY2SDDTABrvRrI6HAU/liJz3L8DTf1BSV1Hwwh6UlMLBLRv+2SwNPZIMUtNqnmAw56Gs//8AfwEguxIoVgPDyAAAAABJRU5ErkJggg==) no-repeat center center;
  padding: 35px 0 0 35px;
  display: inline-block;
}

div.icon-trafficLight {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QAAAAAAAD5Q7t/AAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAhklEQVRIx+2RwQ2DMBAE16nDPKB0P1AqgAIgtaCkieHjSImF4QQ8b14n3Wl3dSs5VwBeHDPtaYQDAyxBQghVnceG6OcbrVgNkqKkJs9/QTJvy1uelVfEn5umctNbDDpgOWGwAJ3lpQJaIBUCAxCz+FjsEtB6yV6yl+wl31VywWzQnww3Tp0VnZpacqu5CHUAAAAldEVYdGRhdGU6Y3JlYXRlADIwMjAtMTAtMDlUMTI6MTA6MTcrMDA6MDCBUOaHAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDIwLTEwLTA5VDEyOjEwOjE3KzAwOjAw8A1eOwAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAAASUVORK5CYII=) no-repeat center center;
  padding: 35px 0 0 35px;
  display: inline-block;
}

i {
  font-size: 14px;
}

i.chevron {
  margin-right: 10px;
  height: 14px;
}

span.icon-alarm-red {
  padding: 9px;
  color: #e43351;
}

span.icon-alarm-yellow {
  padding: 9px;
  color: #f8b100;
}

span.icon-alarm-green {
  padding: 9px;
  color: #96be2c;
}

i.icon-bell-off {
  padding: 9px;
  color: #e43351;
  margin-left: 5px;
}

.header-link {
  cursor: pointer;
  margin-left: 5px;
  line-height: 35px;
}

.main-section {
  border: 2px solid white;
  background-color: #FAFAFA;
}


.wrapper {
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.icon-color-red {
  background-color: #e43351
}

.icon-color-green {
  background-color: #96be2c
}

.icon-color-yellow {
  background-color: #f8b100
}


.quick-access-item {
  position: relative;
}

.theme-circle {
  width: 18px;
  height: 18px;
  color: #fff;
  font-size: 12px;
  display: inline-block;
  margin-right: 5px;
  margin-top: -1px;
  text-align: center;
  line-height: 18px;
  border-radius: 10px;
  background-color: #873C79;
  margin-left: 10px;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  -ms-text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}


.infoba-header {
  color: #fff;
  padding: 4px;
  padding-left: 8px;
  padding-right: 10px;
  display: flex;
  justify-content: space-between;
  align-content: center;
  background: #888;
  position: relative;
  margin-top: 20px
}

.infoba-header:first-child {
  margin-top: 0
}

.infoba-header i {
  /*position: absolute;*/
  top: 2px;
  line-height: 35px;
  font-size: 20px !important
}

.infoba-header span {
  position: absolute;
  left: 34px;
  line-height: 35px;
  color: #fff;
  cursor: pointer;
  top: 5px
}


.icon-color-green {
  background-color: #96be2c
}

.icon-color-grey {
  background-color: #ccc
}

.icon-status-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.quick-access-item {
  position: relative
}

.infoba-quick-access-item {
  padding: 20px 35px 20px 35px;
  cursor: auto;
  height: auto
}

.infoba-gray {
  color: #888
}

.infoba-link {
  color: #888 !important;
  cursor: pointer
}

.slide-enter-active {
  -moz-transition-duration: .3s;
  -webkit-transition-duration: .3s;
  -o-transition-duration: .3s;
  transition-duration: .3s;
  -moz-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in
}

.slide-leave-active {
  -moz-transition-duration: .3s;
  -webkit-transition-duration: .3s;
  -o-transition-duration: .3s;
  transition-duration: .3s;
  -moz-transition-timing-function: cubic-bezier(0, 1, .5, 1);
  -webkit-transition-timing-function: cubic-bezier(0, 1, .5, 1);
  -o-transition-timing-function: cubic-bezier(0, 1, .5, 1);
  transition-timing-function: cubic-bezier(0, 1, .5, 1)
}

.slide-enter-to, .slide-leave {
  max-height: 100px;
  overflow: hidden
}

.slide-enter, .slide-leave-to {
  overflow: hidden;
  max-height: 0
}

.rotate {
  -moz-transition: all .3s linear;
  -webkit-transition: all .3s linear;
  transition: all .3s linear
}

.rotate.up {
  -moz-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg)
}

.rotate.right {
  -moz-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg)
}

.infoba-rotate {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite
}

@-webkit-keyframes rotating {
  from {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg)
  }

  to {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg)
  }
}

@keyframes rotating {
  from {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg)
  }

  to {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg)
  }
}


.infoba-accordeon {
  padding-left: 5px
}

.infoba-accordeon-header {
  padding-left: 24px;
  background-color: #FFFFFF;
  line-height: 46px;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 46px;
}

.infoba-accordeon-row {
  background-color: #FFFFFF;
  padding-left: 5px;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  justify-content: space-between;
  padding-top: 5px;
  padding-bottom: 5px;
}

.infoba-section {
  padding-left: 24px;
  background-color: #FFFFFF;
  font-size: 12px;
  line-height: 14px;
  padding-bottom: 10px;
}

.infoba-accordeon-row:last-child {
  padding-bottom: 20px;
}

.infoba-row {
  padding-left: 5px;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ccc
}


.infoba-logo {
  height: 35px
}
</style>
