<template>
  <div class="widget-W0119V0001">
    <div :class="placement" class="widget">
      <h2 class="title">Meebook</h2>
      <div v-if="loading" class="widget-loader">
        <i class="fa fa-spinner"></i>
        Loading...
      </div>
      <div v-if="hasErrors">
        <ul class="errors">
          <li v-for="error in errors" v-bind:key="error" class="errors__error">
            {{ error }}
          </li>
        </ul>
      </div>
      <ul class="links">
        <li>Gå til:</li>
        <li v-for="(item, index) in data.links">
          <a @click="submitLink(widgetIdentifier + 'link' + index)">
            {{ item.label }}

            <form v-bind:action="getApiBaseUrl() + 'relatednotificationslinkauth/'"
                  method="post"
                  target="_blank"
                  v-bind:ref="widgetIdentifier + 'link' + index">
              <input type="hidden" name="type" v-bind:value="typeIdentifier">
              <input type="hidden" name="sessionUUID" v-bind:value="sessionUUID" />
              <input type="hidden" name="userProfile" v-bind:value="userProfile" />
              <input type="hidden" name="returnUrl"
                     v-bind:value="encodeURIComponent(getBaseUrl() + item.path + '?aulaJWT=' + aulaToken)" />
              <input type="hidden" name="currentWeekNumber" v-bind:value="getThisYearWeek()" />
              <input v-for="(inst) in institutionFilter" type="hidden" name="institutionFilter[]" v-bind:value="inst" />
              <input v-for="(child) in childFilter" type="hidden" name="childFilter[]" v-bind:value="child" />
              <input type="hidden" :id="`aulaJWT${widgetIdentifier + 'link' + index}`" name="aulaJWT" value="" />
            </form>
          </a>
        </li>
      </ul>
      <div class="empty-state" v-if="(data.notifications.items.length === 0)">
        <h3 class="empty-state__title">Ingen notifikationer</h3>
        <p class="empty-state__body">Når der sker noget nyt i Meebook,
          vil det blive vist her</p>
      </div>
      <ul v-else>
        <li
          v-for="item in data.notifications.items"
          :key="item.id"
          v-if="item.data.isVisibleForRelated"
          class="notification"
        >
          <div class="notification__header">
            <h2 class="notification__title">{{ renderTitle(item) }}</h2>
            <time class="notification__date">{{ renderDate(item.date) }}</time>
          </div>
          <p class="notification__subtitle">
            <span v-if="item.type === 'messagebook_comment_new'" class="notification__bubble"></span>
            {{ renderMessage(item) }}
          </p>
          <a
            class="notification__link"
            @click="submitLink(widgetIdentifier + 'notification' + item.id)"
          >
            <form v-bind:action="getApiBaseUrl() + 'relatednotificationslinkauth/'"
                  method="post"
                  target="_blank"
                  v-bind:ref="widgetIdentifier + 'notification' + item.id">
              <input type="hidden" name="type" v-bind:value="typeIdentifier">
              <input type="hidden" name="sessionUUID" v-bind:value="sessionUUID" />
              <input type="hidden" name="userProfile" v-bind:value="userProfile" />
              <input type="hidden" name="returnUrl"
                     v-bind:value="encodeURIComponent(getBaseUrl() + item.link + '&aulaJWT=' + aulaToken)" />
              <input type="hidden" name="currentWeekNumber" v-bind:value="getThisYearWeek()" />
              <input type="hidden" :id="`aulaJWT${widgetIdentifier + 'notification' + item.id}`" name="aulaJWT"
                     value="" />
              <input v-for="(inst) in institutionFilter" type="hidden" name="institutionFilter[]" v-bind:value="inst" />
              <input v-for="(child) in childFilter" type="hidden" name="childFilter[]" v-bind:value="child" />
            </form>
            Se på Meebook
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    axios: Function,
    moment: Function,
    placement: String,
    getAulaToken: Function,
    sessionUUID: String,
    meebookApiBaseUrl: String,
    meebookApiTestData: String,
    childFilter: [String, Array],
    institutionFilter: [String, Array],
    userProfile: String,
    currentWeekNumber: String
  },
  data() {
    return {
      aulaToken: null,
      aulaTokenExpired: false,
      client: null,
      data: {
        notifications: { items: [], pagination: [] },
        links: []
      },
      errors: [],
      loading: false,
      widgetIdentifier: "NotificationWidget-",
      typeIdentifier: "linkauth"
    };
  },
  mounted() {
    this.client = this.axios.create({
      baseURL: this.getApiBaseUrl(),
      timeout: 30000
    });
    this.aulaToken = this.getAulaToken();
  },
  watch: {
    aulaToken: function () {
      if (this.aulaToken !== undefined && !this.aulaTokenExpired) {
        this.fetchNotifications();
      } else if (this.aulaTokenExpired) {
        this.aulaTokenExpired = false;
        this.errors = [];
        this.fetchNotifications();
      }
    }
  },
  computed: {
    hasErrors() {
      return this.errors && this.errors.length;
    },
    headers() {
      return {
        "Content-Type": "application/x-www-form-urlencoded",
        Accept: "application/json",
        sessionUUID: this.sessionUUID,
        "X-Version": "1.0",
        Authorization: `Bearer ${this.aulaToken}`
      };
    }
  },
  methods: {
    submitLink(index) {
      let aulaJwtInputElement = this.$el.querySelector("#aulaJWT" + index);
      // insert jwt token value into form
      aulaJwtInputElement.value = this.aulaToken;
      // send form
      this.$refs[index][0].submit();
      // reset aula jwt token input value to null
      aulaJwtInputElement.value = null;
    },
    getBaseUrl() {
      // production url (defined here because there wil not be an API_BASE_URL on aula live)
      return "https://app.meebook.com";
    },
    getApiBaseUrl() {
      // production url (defined here because there wil not be an API_BASE_URL on aula live)
      let url = this.getBaseUrl() + "/aulaapi/";
      // if dev server is set, use that
      if (this.meebookApiBaseUrl !== undefined) {
        // could be localhost (or other local vhost) or development server
        url = this.meebookApiBaseUrl;
      }
      return url;
    },
    getTestData() {
      // should only be used when building html. Not "real" data
      this.loading = false;
      return JSON.parse(this.meebookApiTestData);
    },
    fetchNotifications() {
      this.loading = true;

      if (this.meebookApiTestData !== undefined) {
        this.data = this.getTestData();
        return;
      }

      this.client
        .get("relatednotifications", {
          headers: this.headers,
          params: {
            currentWeekNumber: this.getThisYearWeek(),
            userProfile: this.userProfile,
            childFilter: this.childFilter,
            institutionFilter: this.institutionFilter
          }
        }).then((response) => {
        this.loading = false;
        this.data = response.data || [];
      })
        .catch((error) => {
          if (error.status === 401) {
            this.aulaTokenExpired = true;
            this.loading = true;
            this.aulaToken = this.getAulaToken();
            this.errors.push("Din Aula-token fornyes.. Øjeblik");
          } else {
            this.loading = false;
            if (error.response.data.exceptionMessage) {
              this.errors.push(error.response.data.exceptionMessage);
            } else {
              this.errors.push("Notifikationer kunne ikke hentes");
            }
          }
        });
    },
    getThisYearWeek() {
      this.moment.locale("da");
      return this.moment().format(this.moment.HTML5_FMT.WEEK);
    },
    renderTitle(item) {
      switch (item.type) {
        case "messagebook_comment_new":
          return (
            this.possesiveFirstname(item.data.studentName) + " meddelelsesbog"
          );
        case "messagebook_published":
          return (
            this.possesiveFirstname(item.data.studentName) + " meddelelsesbog"
          );
        case "annualplan_published":
          return this.possesiveFirstname(item.data.studentName) + " årsplan";
        case "upv_published":
          return this.possesiveFirstname(item.data.studentName) + " UPV";
        default:
          return "Unknown notification";
      }
    },
    renderMessage(item) {
      switch (item.type) {
        case "messagebook_comment_new":
          return (
            this.firstname(item.data.senderName) + ": " + item.data.excerpt
          );
        case "messagebook_published":
          return `Ny meddelelsesbog for ${item.data.yearSpan}`;
        case "annualplan_published":
          return `Ny årsplan for ${item.data.categories.join(", ")}`;
        case "upv_published":
          return `Ny UPV for ${item.data.yearSpan}`;
      }
    },
    renderDate(dateStr) {
      const today = new Date();
      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      const date = new Date(dateStr);
      today.setHours(0, 0, 0, 0);
      date.setHours(0, 0, 0, 0);
      yesterday.setHours(0, 0, 0, 0);
      if (date.getTime() === today.getTime()) {
        return "I dag";
      }
      if (date.getTime() === yesterday.getTime()) {
        return "I går";
      }
      return date.toLocaleDateString("da", {
        day: "numeric",
        month: "short"
      });
    },
    firstname(name) {
      return name.split(" ")[0];
    },
    possesiveFirstname(name) {
      const firstname = this.firstname(name);
      return ["x", "s", "z"].includes(firstname.slice(-1))
        ? firstname + "’"
        : firstname + "s";
    }
  }
};
</script>

<style lang="scss">
.widget-W0119V0001 {
  .widget-loader {
    font-family: "Lato", "Avenir", Helvetica, Arial, sans-serif;
    font-size: 16px;
    margin: 20px 0
  }

  .widget-loader > .fa {
    animation-name: spin;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-fill-mode: both;
    margin-right: 10px
  }

  @keyframes spin {
    0% {
      transform: rotate(0)
    }
    100% {
      transform: rotate(360deg)
    }
  }

  .errors {
    margin-top: 20px
  }

  .errors__error {
    display: block;
    padding: 15px 20px;
    background-color: #fff;
    color: #b50050
  }

  * {
    box-sizing: border-box
  }

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none
  }

  li {
    margin: 0;
    width: 100%
  }

  .widget-title {
    display: block;
    text-align: left;
    font-family: "Lato", "Avenir", Helvetica, Arial, sans-serif;
    font-size: 17px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 0;
    margin-top: 30px
  }

  .widget {
    text-align: left
  }

  .widget__item__name {
    display: block;
    font-size: 14px;
    font-weight: 700;
    text-transform: capitalize;
    color: #5d6c79;
    margin-top: 30px;
    margin-bottom: 10px
  }

  .widgetlist {
    text-align: left
  }

  .widgetlist__item {
    position: relative;
    padding: 15px 20px;
    background-color: #fff;
    margin-bottom: 5px;
    cursor: pointer;
    outline: 0
  }

  .widgetlist__item:focus {
    background-color: rgba(255, 255, 255, .4)
  }

  .widgetlist__item__title {
    font-size: 16px;
    font-weight: 700
  }

  .widgetlist__item__subtitle {
    font-size: 11px;
    font-weight: 700;
    color: #6a7681;
    text-transform: capitalize
  }

  .widgetlist__item__subtitle::first-letter {
    text-transform: uppercase
  }

  .widgetlist__showall {
    display: block;
    padding: 0;
    margin-top: 5px;
    font-size: 14px;
    font-weight: 700;
    text-decoration: underline;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0);
    border: 0;
    outline: none
  }

  .widgetlist__showall:focus {
    opacity: .5
  }

  .title {
    background: #233f58;
    color: #fff;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    padding: 10px;
    margin: 0
  }

  .notification {
    position: relative;
    background: #fff;
    padding: 10px;
    transition: transform .15s ease-out
  }

  .notification + .notification {
    margin-top: 8px
  }

  .notification__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 4px
  }

  .notification__title {
    font-size: 14px;
    font-weight: 700;
    color: #2c3e50;
    line-height: 17px;
    margin: 0
  }

  .notification__subtitle {
    font-size: 12px;
    line-height: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0 0 4px;
    color: #2c3e50
  }

  .notification__bubble {
    content: "";
    width: 14px;
    height: 14px;
    display: inline-block;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxNCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNNi42OTkwMiAxLjc1MDM1QzcuNzA4OTggMS43NDAxMSA4LjcwODUzIDEuOTU1MDMgOS42MjUwMiAyLjM3OTQ3QzEwLjQ2NDIgMi43NTUyNiAxMS4xOTM2IDMuMzM4OTIgMTEuNzQ0MyA0LjA3NTIyQzEyLjI1MTYgNC43NDg5OSAxMi41MjUzIDUuNTY5ODMgMTIuNTIzOSA2LjQxMzIyQzEyLjUyNTEgNy4yNTYzMiAxMi4yNTE0IDguMDc2ODIgMTEuNzQ0MyA4Ljc1MDM1QzExLjE5MzYgOS40ODY2NiAxMC40NjQyIDEwLjA3MDMgOS42MjUwMiAxMC40NDYxQzguNzA4NDYgMTAuODcwMiA3LjcwODkxIDExLjA4NDkgNi42OTkwMiAxMS4wNzQzQzYuMDA2MTMgMTEuMDc2OSA1LjMxNjExIDEwLjk4NTEgNC42NDgwMiAxMC44MDEzQzQuMjQ3MTQgMTEuMTI5NSAzLjgwNjA5IDExLjQwNTMgMy4zMzU1MiAxMS42MjIxQzIuOTQxMDQgMTEuODA4NiAyLjUyODkxIDExLjk1NTEgMi4xMDUyNyAxMi4wNTk2QzEuODA4MzggMTIuMTMzNiAxLjUwNzA1IDEyLjE4ODYgMS4yMDMxNSAxMi4yMjQxTDAuODc1MDIxIDEyLjI1MDNDMS4zOTA4NyAxMS43NTczIDEuODI0NzggMTEuMTg1MiAyLjE2MDQgMTAuNTU1NUMyLjMyMzUyIDEwLjI2OTUgMi40MjU3IDkuOTUyNzcgMi40NjA1MiA5LjYyNTM1QzEuOTc5NDkgOS4yMjAwNyAxLjU4NDQgOC43MjI2NCAxLjI5ODUyIDguMTYyMzVDMS4wMjA4MyA3LjYyNTc3IDAuODc1NjIxIDcuMDMwNTIgMC44NzUwMjEgNi40MjYzNUMwLjg3MjIzNSA1LjU3ODcgMS4xNDU5NSA0Ljc1MzI3IDEuNjU0NjUgNC4wNzUyMkMyLjIwNTQ0IDMuMzM5MjQgMi45MzQ4NCAyLjc1NTg5IDMuNzczOSAyLjM4MDM1QzQuNjkwMDUgMS45NTU3MyA1LjY4OTI5IDEuNzQwNTIgNi42OTkwMiAxLjc1MDM1WiIgZmlsbD0iI0RCRTNFOSIvPjwvc3ZnPg==");
    vertical-align: bottom
  }

  .notification__date {
    color: rgba(44, 62, 80, .5);
    font-size: 12px;
    line-height: 14px
  }

  .notification__link {
    font-size: 12px;
    line-height: 14px;
    font-weight: 700;
    color: #f5836d;
    text-decoration: none;
    display: block
  }

  .notification__link:hover {
    text-decoration: underline
  }

  .notification__link::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0
  }

  .notification:hover {
    transform: translate3d(0, 0, 0) scale(1.015)
  }

  .links {
    display: flex;
    justify-content: flex-start;
    padding: 14px 0;
    gap: 10px
  }

  .links > li {
    font-size: 12.6px;
    display: block;
    width: auto;
    color: rgba(0, 0, 0, .5)
  }

  .links a {
    color: #f5836d;
    font-weight: bold;
    text-decoration: underline
  }

  .links a:hover {
    text-decoration: unset;
    color: #e26b54
  }

  .empty-state {
    padding: 20px 10px;
    text-align: center
  }

  .empty-state__title {
    font-size: 14px;
    line-height: 19px;
    font-weight: 700;
    color: rgba(44, 62, 80, .8);
    margin: 0
  }

  .empty-state__body {
    font-size: 14px;
    line-height: 19px;
    font-weight: 400;
    color: rgba(44, 62, 80, .8);
    margin: 0
  }
}
</style>
