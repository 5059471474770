<template>
  <aula-modal
    ref="planningAssistantModal"
    header-text="PLANNING_ASSISTANT_HEADER"
    :disable-close="true"
    css-class="aula-calendar planning-assistant-modal"
    @okClicked="$refs.planningAssistantModal.hide()"
    @cancelClicked="cancel()"
    @hidden="destroyInteract()"
  >
    <div class="planning-assistant-timepickers">
      <AulaTimepicker
        v-model="startTime"
        format="HH:mm"
        hour-label="Time"
        minute-label="Minut"
        :placeholder="'MESSAGE_PLACEHOLDER_SELECT_TIME' | fromTextKey"
        :aria-label="'ARIA_LABEL_CALENDAR_START_TIME' | fromTextKey"
        :disabled="!canEditTime"
        hide-clear-button
        :minute-interval="minuteInterval"
        advanced-keyboard
        @change="updateStartTime"
      />
      <AulaTimepicker
        v-model="endTime"
        format="HH:mm"
        hour-label="Time"
        minute-label="Minut"
        :placeholder="'MESSAGE_PLACEHOLDER_SELECT_TIME' | fromTextKey"
        :aria-label="'ARIA_LABEL_CALENDAR_END_TIME' | fromTextKey"
        :disabled="!canEditTime"
        class="margin-left"
        hide-clear-button
        :minute-interval="minuteInterval"
        advanced-keyboard
        @change="updateEndTime"
      />
    </div>
    <div class="resizable-container">
      <div class="resizable" />
    </div>
    <div class="planning-assistant-calendar">
      <el-date-picker
        ref="calendarDatepicker"
        v-model="startDate"
        class="datetime-input"
        :append-to-body="false"
        :clearable="false"
        popper-class="planning-assistant-datepicker"
        :disabled="!canEditTime"
        type="date"
        value-format="yyyy-MM-dd"
        format="dd/MM/yyyy"
        aria-hidden="true"
        prefix-icon="icon-Aula_down-arrow"
        :picker-options="{ firstDayOfWeek: 1 }"
        @focus="datePickerModifier()"
        @input="changeCalendarDate"
      />
      <FullCalendar
        id="planning-assistant"
        ref="fullCalendar"
        :default-view="defaultView"
        :plugins="calendarPlugins"
        :default-date="eventForm.startDateTime"
        :header="defaultHeader"
        :custom-buttons="customButtons"
        locale="da"
        lang="da"
        :weekends="true"
        :title-format="{ month: 'long', day: '2-digit', year: 'numeric' }"
        :week-numbers="false"
        :resources-initially-expanded="false"
        week-number-title="Uge"
        :slot-label-format="{ hour: '2-digit', minute: '2-digit' }"
        :slot-event-overlap="false"
        :column-header-format="{ month: 'long', day: '2-digit', year: 'numeric' }"
        content-height="auto"
        :resource-area-width="300"
        :slot-width="100"
        :dates-render="datesRender"
        :resources="allResources"
        :resource-render="resourceRender"
        :event-sources="allResources.length > 0 ? eventSources : []"
        resource-group-field="category"
        :resource-group-text="resourceGroupText"
        resource-label-text="Ressourcer"
        scheduler-license-key="0851031242-fcs-1572871596"
        :button-icons="{
          prev: ' icon-Aula_back-triangle',
          next: ' icon-Aula_forward-triangle',
        }"
        :button-text="{ today: 'I dag' }"
        @eventClick="eventItemClick"
      />
    </div>
    <b-input-group v-if="parent !== parentTypes.GROUP" class="search-module mt-2 mb-2" style="max-width: 300px">
      <aula-search-recipients
        :existing-participants="eventForm.invitees"
        :from-module="moduleTypes.EVENT"
        :portal-roles="[portalRoles.CHILD, portalRoles.EMPLOYEE]"
        :hide-portal-roles-when-expanding-group="[portalRoles.GUARDIAN]"
        :show-checkbox="true"
        :add-existing-participants="false"
        :select-all="false"
        :reset-input="resetInput"
        :invite-members-when-selecting-group="true"
        :close-dropdown-after-select="false"
        :invite-members-when-selecting-group-portal-role="true"
        :invite-group-when-adding-members="true"
        :institution-code="eventForm.institutionCode"
        :disabled="!canInviteInvitees"
        placeholder-textkey="PLANNING_ASSISTANT_ADD_RECIPIENT"
        @emitSelected="addInvitee"
      />
      <b-input-group-addon>
        <i class="icon-Aula_search" style="font-size: 1.5em" />
      </b-input-group-addon>
    </b-input-group>

    <aula-modal ref="tooManyInviteesModal" :show-cancel="false" @okClicked="$refs.tooManyInviteesModal.hide()">
      {{ 'PLANNING_ASSISTANT_TOO_MANY_INVITEES' | fromTextKey }}
    </aula-modal>
  </aula-modal>
</template>

<script>
import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';
import { types } from '../../store/types/types';
import moment from 'moment-timezone';
import { docTypes } from '../../../shared/enums/docTypes';
import { portalRoles } from '../../../shared/enums/portalRoles';
import { moduleTypes } from '../../../shared/enums/moduleTypes';
import { timepickerSettings } from '../../../shared/enums/timepickerSettings';
import { parentTypes } from '../../../shared/enums/parentTypes.ts';
import FullCalendar from '@fullcalendar/vue';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import AulaSearchRecipients from '../../../shared/components/AulaSearchRecipients';
import AulaTimepicker from '../../../shared/components/Timepicker';
import interact from 'interactjs';
import cloneDeep from 'lodash/cloneDeep';
import { planningAssitantCategory } from '../../../shared/enums/planningAssitantCategory';
import $ from 'jquery';
import * as dateUtil from '../../../shared/utils/dateUtil';
import { dateFormatEnum } from '../../../shared/enums/dateFormatEnum';

export default {
  props: {
    parent: { type: String, default: parentTypes.PROFILE },
    eventForm: { type: Object, default: null },
    locations: { type: Array, default: () => [] },
    canEditTime: { type: Boolean, default: false },
    showInvitees: { type: Boolean, default: true },
    canInviteInvitees: { type: Boolean, default: true },
  },
  data: function () {
    return {
      calendar: null,
      eventFormBackup: {},
      observer: null,
      calendarPlugins: [resourceTimelinePlugin],
      allResources: [],
      defaultView: 'resourceTimeline',
      resizableHtml: '',
      startTime: moment().format('HH:mm'),
      endTime: moment().format('HH:mm'),
      startDate: moment().format('YYYY-MM-DD'),
      datePickerDate: moment().format('YYYY-MM-DD'),
      minuteInterval: 15,
      fifteenMinuteWidth: 0,
      columnWidth: 0,
      startLeft: 0,
      startRight: 0,
      draggedDistance: 0,
      resetInput: false,
      moment: moment,
      portalRoles: portalRoles,
      moduleTypes: moduleTypes,
      timepickerSettings: timepickerSettings,
      parentTypes: parentTypes,
    };
  },
  computed: {
    ...mapGetters({
      profile: types.GET_CURRENT_PROFILE,
      resources: types.GET_RESOURCES,
      events: types.GET_EVENTS,
      calendarActiveInstProfileId: types.GET_CALENDAR_ACTIVE_INST_PROFILE_ID,
    }),
    defaultHeader() {
      return {
        left: 'title openCalendar prev,next',
        right: 'today',
      };
    },
    customButtons() {
      if (!this.canEditTime) {
        return;
      }
      const self = this;
      return {
        openCalendar: {
          icon: ' icon-Aula_down-arrow',
          click() {
            self.openDatePicker();
          },
        },
      };
    },
    eventSources() {
      const self = this;
      const eventSources = [
        {
          events(fetchInfo, successCallback) {
            const instProfileIds = self.allResources
              .filter(profile => profile.type == docTypes.PROFILE.toLowerCase())
              .map(profile => profile.singleId);
            const resourceIds = self.allResources
              .filter(resource => resource.type == docTypes.RESOURCE.toLowerCase())
              .map(resource => resource.singleId);

            const params = {
              instProfileIds: instProfileIds,
              resourceIds: resourceIds,
              start: moment(fetchInfo.start),
              end: moment(fetchInfo.end),
              calendarActiveInstProfileId: self.calendarActiveInstProfileId,
              showVacationRegistrationEvents: false,
            };
            self.clearEvents();
            self.loadEventsByProfileIdsAndResourceIds(params).then(() => {
              successCallback(self.calculateEvents());
            });
          },
        },
      ];
      return eventSources;
    },
    startMinute() {
      return this.startTime.split(':')[1];
    },
    endMinute() {
      return this.endTime.split(':')[1];
    },
    startHour() {
      return this.startTime.split(':')[0];
    },
    endHour() {
      return this.endTime.split(':')[0];
    },
    startMoment() {
      return moment(this.startDate).hour(this.startHour).minute(this.startMinute);
    },
    endMoment() {
      return moment(this.startDate).hour(this.endHour).minute(this.endMinute);
    },
  },
  methods: {
    ...mapActions({
      listResources: types.LIST_RESOURCES,
      loadEventsByProfileIdsAndResourceIds: types.LOAD_EVENTS_BY_PROFILEID,
      clearEvents: types.CLEAR_EVENTS,
    }),
    cancel() {
      this.$emit('resetEventForm', this.eventFormBackup);
      this.$refs.planningAssistantModal.hide();
    },
    getInviteeResources() {
      let allResources = [];
      let counter = 1;

      allResources = this.pushResource(
        allResources,
        this.profile.id,
        Vue.filter('displayProfileNameWithMetadata')(this.profile),
        docTypes.PROFILE.toLowerCase(),
        planningAssitantCategory.INVITEES
      );
      if (this.eventForm.creator) {
        allResources = this.pushResource(
          allResources,
          this.eventForm.creator.id,
          Vue.filter('displayProfileNameWithMetadata')(this.eventForm.creator),
          docTypes.PROFILE.toLowerCase(),
          planningAssitantCategory.INVITEES,
          'creator'
        );
      }
      for (const invitee of this.eventForm.invitees) {
        if (
          invitee.portalRole !== portalRoles.GUARDIAN &&
          invitee.role !== portalRoles.GUARDIAN &&
          invitee.institutionCode === this.eventForm.institutionCode &&
          counter < 40
        ) {
          const name = Vue.filter('displayProfileNameWithMetadata')(invitee);
          allResources = this.pushResource(
            allResources,
            invitee.id,
            name,
            docTypes.PROFILE.toLowerCase(),
            planningAssitantCategory.INVITEES,
            'invitee'
          );
          counter++;
        } else if (counter >= 40) {
          this.$refs.tooManyInviteesModal.show();
        }
      }
      if (Array.isArray(this.eventForm.coOrganizers)) {
        for (const organizer of this.eventForm.coOrganizers) {
          if (organizer.institutionCode === this.eventForm.institutionCode && counter < 40) {
            const name = Vue.filter('displayProfileNameWithMetadata')(organizer);
            allResources = this.pushResource(
              allResources,
              organizer.id,
              name,
              docTypes.PROFILE.toLowerCase(),
              planningAssitantCategory.INVITEES,
              'coOrganizer'
            );
            counter++;
          } else if (counter >= 40) {
            this.$refs.tooManyInviteesModal.show();
          }
        }
      }

      return allResources;
    },
    setAllResources() {
      let allResources = [];

      if (this.showInvitees) {
        allResources.push(...this.getInviteeResources());
      }

      for (const resource of this.locations) {
        if (resource.id) {
          allResources = this.pushResource(
            allResources,
            resource.id,
            resource.label,
            docTypes.RESOURCE.toLowerCase(),
            planningAssitantCategory.LOCATION
          );
        }
      }
      for (const resource of this.eventForm.resources) {
        allResources = this.pushResource(
          allResources,
          resource.id,
          resource.label,
          docTypes.RESOURCE.toLowerCase(),
          planningAssitantCategory.RESOURCES
        );
      }
      for (const resource of this.resources) {
        allResources = this.pushResource(
          allResources,
          resource.id,
          `${resource.displayName} (${resource.institutionName})`,
          docTypes.RESOURCE.toLowerCase(),
          resource.resourceCategory.name
        );
      }
      this.allResources = allResources;
    },
    eventItemClick(eventClickInfo) {
      eventClickInfo.disableEventRouting = true;
      this.$eventHub.$emit('planningAssistantEventClicked', eventClickInfo);
    },
    changeCalendarDate() {
      if (this.calendar != null) {
        this.calendar.gotoDate(moment(this.startDate).toDate());
      }
    },
    openDatePicker() {
      if ($('.planning-assistant-datepicker').is(':visible')) {
        this.$refs.calendarDatepicker.blur();
      } else {
        this.$refs.calendarDatepicker.handleFocus();
      }
    },
    resourceGroupText(groupValue) {
      if (groupValue === planningAssitantCategory.LOCATION) {
        return 'Valgte lokaler';
      } else if (groupValue === planningAssitantCategory.RESOURCES) {
        return 'Valgte ressourcer';
      } else if (groupValue === planningAssitantCategory.INVITEES) {
        return 'Deltagere';
      }
      return groupValue;
    },
    resourceRender(renderInfo) {
      const self = this;
      const resource = renderInfo.resource;
      const element = renderInfo.el;

      if (
        resource.extendedProps.category != planningAssitantCategory.RESOURCES &&
        resource.extendedProps.category != planningAssitantCategory.LOCATION &&
        resource.extendedProps.category != planningAssitantCategory.INVITEES
      ) {
        const resourceEvents = this.calendar.getResourceById(resource.id).getEvents();
        if (
          resourceEvents.filter(
            evt =>
              moment(evt.start).isBetween(this.startMoment, this.endMoment) ||
              moment(evt.end).isBetween(this.startMoment, this.endMoment) ||
              (moment(evt.start).isSameOrBefore(this.startMoment) && moment(evt.end).isSameOrAfter(this.endMoment))
          ).length == 0
        ) {
          const e = document.createElement('span');
          e.innerHTML =
            " <button class='btn btn-link add-resource-btn'><i class='icon icon-Aula_plus in-circle'></i></button>";
          element.querySelector('.fc-cell-content').appendChild(e);
          $('#planning-assistant .add-resource-btn').off();
          $('#planning-assistant .add-resource-btn').click(function () {
            const resourceId = $(this).closest('tr').data('resource-id');
            const resource = self.allResources.find(res => res.id == resourceId);
            self.addResource(resource);
          });
        }
      } else if (
        resource.extendedProps.category == planningAssitantCategory.INVITEES &&
        resource.extendedProps.inviteeType === 'invitee'
      ) {
        const e = document.createElement('span');
        e.innerHTML = " <button class='btn btn-link remove-invitee-btn'><i class='icon-Aula_close'></i></button>";
        element.querySelector('.fc-cell-content').appendChild(e);
        $('#planning-assistant .remove-invitee-btn').off();
        $('#planning-assistant .remove-invitee-btn').click(function () {
          const resourceId = $(this).closest('tr').data('resource-id');
          const resource = self.allResources.find(res => res.id == resourceId);
          self.removeInvitee(resource);
        });
      } else if (
        resource.extendedProps.category == planningAssitantCategory.RESOURCES ||
        resource.extendedProps.category == planningAssitantCategory.LOCATION
      ) {
        const e = document.createElement('span');
        e.innerHTML = " <button class='btn btn-link remove-resource-btn'><i class='icon-Aula_close'></i></button>";
        element.querySelector('.fc-cell-content').appendChild(e);
        $('#planning-assistant .remove-resource-btn').off();
        $('#planning-assistant .remove-resource-btn').click(function () {
          const resourceId = $(this).closest('tr').data('resource-id');
          const resource = self.allResources.find(res => res.id == resourceId);
          self.removeResource(resource);
        });
      }
    },
    datesRender(info) {
      this.startDate = moment(info.view.currentStart).format('YYYY-MM-DD');
      this.$emit('updateStartDate', moment(info.view.currentStart).format('YYYY-MM-DD'));
    },
    pushResource(allResources, id, title, type, category, inviteeType = '') {
      const resources = allResources;
      if (resources.find(res => res.id == type + '-' + id) == null) {
        resources.push({
          id: type + '-' + id,
          singleId: id,
          type: type,
          title: title,
          category: category,
          inviteeType: inviteeType,
        });
      }
      return resources;
    },
    calculateEvents() {
      return this.events.filter(evt => !evt.type.includes('sum-'));
    },
    removeInvitee(invitee) {
      const invitees = this.eventForm.invitees.filter(inv => inv.id != invitee.singleId);
      this.$emit('updateInvitees', invitees);
      this.calendar.refetchEvents();
      this.setAllResources();
    },
    removeResource(resource) {
      if (
        resource.category === planningAssitantCategory.LOCATION ||
        resource.category === planningAssitantCategory.LOKALER
      ) {
        let allResources = this.locations;
        allResources = allResources.filter(res => res.id != resource.singleId);
        this.$emit('updateLocations', allResources);
      } else if (resource.category === planningAssitantCategory.RESOURCES) {
        let allResources = this.eventForm.resources;
        allResources = allResources.filter(res => res.id != resource.singleId);
        this.$emit('updateResources', allResources);
      }
      this.$nextTick(() => {
        this.setAllResources();
      });
    },
    addResource(resource) {
      const emittedResource = {
        id: resource.singleId,
        name: resource.title,
        label: resource.title,
        value: resource.singleId,
      };
      if (
        resource.category === planningAssitantCategory.LOCATION ||
        resource.category === planningAssitantCategory.LOKALER
      ) {
        const allResources = this.locations;
        allResources.push(emittedResource);
        this.$emit('updateLocations', allResources);
      } else {
        const allResources = this.eventForm.resources;
        allResources.push(emittedResource);
        this.$emit('updateResources', allResources);
      }
      this.setAllResources();
      setTimeout(() => {
        this.expandCategories();
        this.applyTabAndKeyEventToFcExpanders();
      }, 50);
    },
    addInvitee(invitees) {
      let addedInvitees = this.eventForm.invitees;
      addedInvitees = addedInvitees.concat(invitees);
      this.$emit('updateInvitees', addedInvitees);
      this.resetInput = !this.resetInput;
      this.calendar.refetchEvents();
      this.setAllResources();
      setTimeout(() => {
        this.expandCategories();
      }, 50);
    },
    expandCategories() {
      $('.fc-resourceTimeline-view .fc-body .fc-resource-area .fc-rows tr').each(function () {
        if (
          $(this).find('.fc-cell-text').text().includes('Deltagere') ||
          $(this).find('.fc-cell-text').text().includes('Valgte lokaler') ||
          $(this).find('.fc-cell-text').text().includes('Valgte ressourcer')
        ) {
          $(this).find('.fc-icon-plus-square').click();
        }
      });
    },
    updateStartTime() {
      if (this.startMoment.isSameOrAfter(this.endMoment)) {
        this.endTime = this.startMoment.add(30, 'minutes').format('HH:mm');
      }
      this.emitStartAndEndTime();
    },
    updateEndTime() {
      if (this.endMoment.isSameOrBefore(this.startMoment)) {
        this.startTime = this.endMoment.subtract(30, 'minutes').format('HH:mm');
      }
      this.emitStartAndEndTime();
    },
    emitStartAndEndTime() {
      this.$emit('updateStartTime', this.startTime);
      this.$emit('updateEndTime', this.endTime);
      this.calendar.scrollToTime(this.startTime);
    },
    show() {
      if (this.$refs.planningAssistantModal) {
        this.$refs.planningAssistantModal.show();
      }
      this.init();
      this.eventFormBackup = cloneDeep(this.eventForm);
    },
    updateStartAndEndToNearestHalfHour() {
      this.startTime = this.eventForm.allDay
        ? dateUtil.format(dateUtil.startOf(this.eventForm.startDateTime, 'day'), dateFormatEnum.SHORT_TIME)
        : dateUtil.format(this.eventForm.startDateTime, dateFormatEnum.SHORT_TIME);
      this.endTime = this.eventForm.allDay
        ? dateUtil.format(dateUtil.endOf(this.eventForm.endDateTime, 'day'), dateFormatEnum.SHORT_TIME)
        : dateUtil.format(this.eventForm.endDateTime, dateFormatEnum.SHORT_TIME);
      if (this.startMinute % this.minuteInterval != 0 && !this.eventForm.allDay) {
        const startRemainder = this.minuteInterval - (this.startMinute % this.minuteInterval);
        this.startTime = moment()
          .hour(this.startHour)
          .minute(this.startMinute)
          .add(startRemainder, 'minutes')
          .format('HH:mm');
        this.$emit('updateStartTime', this.startTime);
      }
      if (this.endMinute % this.minuteInterval != 0 && !this.eventForm.allDay) {
        const endRemainder = this.minuteInterval - (this.endMinute % this.minuteInterval);
        this.endTime = moment().hour(this.endHour).minute(this.endMinute).add(endRemainder, 'minutes').format('HH:mm');
        this.$emit('updateEndTime', this.endTime);
      }
    },
    windowResized() {
      this.columnWidth = $('#planning-assistant .fc-widget-header[data-date]').width();
      this.fifteenMinuteWidth = (this.columnWidth / 60) * this.minuteInterval;
    },
    modifyResizable() {
      const self = this;
      $('#planning-assistant .fc-widget-header[data-date]').each(function (index) {
        const date = $(this).data('date');
        if (moment(date).hour() == self.startHour) {
          // index is the column that matches start hour
          // We operate in 15 minute intervals
          self.fifteenMinuteWidth = Math.round((self.columnWidth / 60) * self.minuteInterval);
          const newPos =
            index * self.columnWidth + (self.startMinute / self.minuteInterval) * self.fifteenMinuteWidth + 8; // border width;
          $('.resizable').css('left', newPos + 'px');
          const difference = moment()
            .hour(self.endHour)
            .minute(self.endMinute)
            .diff(moment().hour(self.startHour).minute(self.startMinute), 'minutes');
          const differenceFifteenMinutes = difference / self.minuteInterval;
          $('.resizable').css('width', differenceFifteenMinutes * self.fifteenMinuteWidth + 'px');
          $('.resizable').css('transform', '');
          $('.resizable').attr('data-x', '0');
          $('.resizable').height($('#planning-assistant .fc-body .fc-time-area .fc-scroller-canvas').height() - 6);
          // $('.resizable-container').css('left', $('.fc-resourceTimeline-view .fc-resource-area').width() + 16);
          // $('.resizable-container').width($('.fc-resourceTimeline-view .fc-time-area').width());
        }
      });
    },
    saveResizableHtml() {
      this.resizableHtml = document.getElementsByClassName('resizable-container')[0].outerHTML;
      $('.resizable-container').remove();
    },
    positionResizableInCalendar() {
      $('#planning-assistant .fc-body .fc-time-area .fc-scroller-canvas').append(this.resizableHtml);
    },
    initInteraction() {
      if (!this.canEditTime) {
        return;
      }
      const self = this;
      interact('.resizable')
        .draggable({
          startAxis: 'x',
          lockAxis: 'start',
          modifiers: [
            interact.modifiers.snap({
              targets: [interact.createSnapGrid({ x: this.fifteenMinuteWidth, y: 100 })],
              range: Infinity,
            }),
            interact.modifiers.restrict({
              restriction: 'parent',
            }),
          ],
          listeners: {
            start() {
              self.draggedDistance = 0;
            },
            end() {
              const minutes = (Math.abs(self.draggedDistance) / self.fifteenMinuteWidth) * self.minuteInterval;
              if (self.draggedDistance > 0) {
                self.startTime = moment()
                  .hour(self.startHour)
                  .minute(self.startMinute)
                  .add(minutes, 'minutes')
                  .format('HH:mm');
                self.endTime = moment()
                  .hour(self.endHour)
                  .minute(self.endMinute)
                  .add(minutes, 'minutes')
                  .format('HH:mm');
              } else if (self.draggedDistance < 0) {
                self.startTime = moment()
                  .hour(self.startHour)
                  .minute(self.startMinute)
                  .subtract(minutes, 'minutes')
                  .format('HH:mm');
                self.endTime = moment()
                  .hour(self.endHour)
                  .minute(self.endMinute)
                  .subtract(minutes, 'minutes')
                  .format('HH:mm');
              }
              self.$emit('updateStartTime', self.startTime);
              self.$emit('updateEndTime', self.endTime);
            },
            move(event) {
              if (event.delta.x < 0) {
                self.draggedDistance += -50;
              } else if (event.delta.x > 0) {
                self.draggedDistance += 50;
              }
              event.target.style.webkitTransform = event.target.style.transform =
                'translate(' + self.draggedDistance + 'px)';
            },
          },
          inertia: true,
        })
        .resizable({
          // resize from all edges and corners
          edges: { left: true, right: true, bottom: false, top: false },

          modifiers: [
            interact.modifiers.snapSize({
              targets: [
                { width: this.fifteenMinuteWidth },
                interact.createSnapGrid({
                  width: this.fifteenMinuteWidth,
                  height: 100,
                }),
              ],
            }),
            // keep the edges inside the parent
            interact.modifiers.restrictEdges({
              outer: 'parent',
            }),

            // minimum size
            interact.modifiers.restrictSize({
              min: { width: this.fifteenMinuteWidth, height: 50 },
            }),
          ],
        })
        .on('resizemove', event => {
          const target = event.target;
          let x = parseFloat(target.getAttribute('data-x')) || 0;

          // update the element's style
          target.style.width = event.rect.width + 'px';

          // translate when resizing from top or left edges
          x += event.deltaRect.left;
          target.style.webkitTransform = target.style.transform = 'translate(' + x + 'px)';

          target.setAttribute('data-x', x);
        })
        .on('resizestart', event => {
          this.startLeft = event.rect.left;
          this.startRight = event.rect.right;
        })
        .on('resizeend', event => {
          let distance = 0;
          if (this.startLeft != event.rect.left) {
            distance = Math.abs(this.startLeft - event.rect.left);
            let minutes = this.minuteInterval * (distance / this.fifteenMinuteWidth);
            minutes = (Math.round(minutes / this.minuteInterval) * this.minuteInterval) % 600;
            if (this.startLeft > event.rect.left) {
              this.startTime = moment()
                .hour(this.startHour)
                .minute(this.startMinute)
                .subtract(minutes, 'minutes')
                .format('HH:mm');
            } else if (this.startLeft < event.rect.left) {
              this.startTime = moment()
                .hour(this.startHour)
                .minute(this.startMinute)
                .add(minutes, 'minutes')
                .format('HH:mm');
            }
          } else if (this.startRight != event.rect.right) {
            distance = Math.abs(this.startRight - event.rect.right);
            let minutes = this.minuteInterval * (distance / this.fifteenMinuteWidth);
            minutes = (Math.round(minutes / this.minuteInterval) * this.minuteInterval) % 600;
            if (this.startRight > event.rect.right) {
              this.endTime = moment()
                .hour(this.endHour)
                .minute(this.endMinute)
                .subtract(minutes, 'minutes')
                .format('HH:mm');
            } else if (this.startRight < event.rect.right) {
              this.endTime = moment().hour(this.endHour).minute(this.endMinute).add(minutes, 'minutes').format('HH:mm');
            }
          }

          this.$emit('updateStartTime', this.startTime);
          this.$emit('updateEndTime', this.endTime);
        });
    },
    init() {
      this.updateStartAndEndToNearestHalfHour();
      window.addEventListener('resize', this.windowResized);
      this.listResources({
        query: '*',
        institutionCodes: [this.eventForm.institutionCode],
      }).then(() => {
        Vue.nextTick(() => {
          this.setAllResources();
          this.calendar = this.$refs.fullCalendar.getApi();
          this.columnWidth = $('#planning-assistant .fc-widget-header[data-date]').width();
          this.fifteenMinuteWidth = (this.columnWidth / 60) * 15;
          this.saveResizableHtml();
          this.initInteraction();
          this.listenToMutation();
          setTimeout(() => {
            this.calendar.scrollToTime(this.startTime);
            this.applyTabAndKeyEventToFcExpanders();
          }, 50);
        });
      });
    },
    applyTabAndKeyEventToFcExpanders() {
      $('.fc-expander').attr('tabindex', 0);
      $('.fc-expander').off('keyup');
      $('.fc-expander').on('keyup', function (e) {
        if (e.key === 'Enter') {
          $(this).click();
        }
      });
    },
    listenToMutation() {
      const self = this;
      // Select the node that will be observed for mutations
      const targetNode = document.getElementById('planning-assistant');
      // Options for the observer (which mutations to observe)
      const config = { attributes: true, childList: true, subtree: true };

      // Callback function to execute when mutations are observed
      const callback = function (mutationsList) {
        if (
          $('#planning-assistant .fc-body .fc-time-area .fc-scroller-canvas').find('.resizable-container').length == 0
        ) {
          self.positionResizableInCalendar();
          Vue.nextTick(self.modifyResizable());
        }
        for (const mutation of mutationsList) {
          if (
            mutation.target.className.includes('fc-icon-minus-square') ||
            mutation.target.className.includes('fc-icon-plus-square')
          ) {
            Vue.nextTick(self.modifyResizable());
          }
        }
      };

      // Create an observer instance linked to the callback function
      this.observer = new MutationObserver(callback);

      // Start observing the target node for configured mutations
      this.observer.observe(targetNode, config);
    },
    destroyInteract() {
      window.removeEventListener('resize', this.windowResized);
      interact('.resizable').unset();
      this.observer.disconnect();
    },
  },
  components: {
    AulaSearchRecipients,
    FullCalendar,
    AulaTimepicker,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';
@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/timeline/main.css';
@import '~@fullcalendar/resource-timeline/main.css';

.planning-assistant-calendar {
  /deep/ .fc-resourceTimeline-view {
    .fc-resource-area .fc-scroller {
      overflow: hidden !important;
    }
  }
}

.resizable-container {
  position: absolute;
  display: inline-block;
  top: 0px;
  z-index: 1000;
  width: 4800px;

  .resizable {
    position: absolute;
    z-index: 1000;
    background: $color-alert;
    opacity: 0.2;
    border: 2px solid $color-alert;
    color: white;
    font-size: 20px;
    font-family: sans-serif;
    border-radius: 8px;
    touch-action: none;

    min-height: 50px;

    /* This makes things *much* easier */
    box-sizing: border-box;
  }
}
</style>
