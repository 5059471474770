
<template>
	<div>
		<form ref="muSSOForm" @submit="addAulaTokenOnSubmit" method="POST" target="_blank" :action="link">
			<input type="hidden" name="sessionUUID" :value="sessionUUID" />
			<input type="hidden" name="placement" :value="placement" />
			<input type="hidden" name="currentWeekNumber" :value="currentWeekNumber" />
			<input type="hidden" name="isMobileApp" :value="isMobileApp" />
			<input type="hidden" name="assuranceLevel" :value="assuranceLevel" />
			<input type="hidden" name="userProfile" :value="userProfile" />
			<input type="hidden" name="childFilter" :value="childFilter" />
			<input type="hidden" name="institutionFilter" :value="institutionFilter" />
			<input type="hidden" name="group" :value="group" />
			<input type="hidden" name="aulaToken" :value="getTempAulaToken" />
			<b-button class="muSSOButton" v-if="showLink" role="link" :title="'https://www.minuddannelse.net'" type="submit">
				<b-img src="https://www.minuddannelse.net/Content/Graphics/Frontpage/frontpage_logo.svg" alt="MinUddannelse logo" fluid />
			</b-button>
		</form>
	</div>
</template>

<script>
	export default {
		props: {
			'assuranceLevel': Number,
			axios: Function,
			'childFilter': [String, Array],
			'currentWeekNumber': String,
			'getAulaToken': Function,
			'group': String,
			'institutionFilter': [String, Array],
			'isMobileApp': Boolean,
			'placement': String,
			'sessionUUID': String,
			'userProfile': String,
		},
		data: function() {
			return {
				link: new URL('https://api.minuddannelse.net/aula/redirect?redirectUrl=https://www.minuddannelse.net/'),
				aulaToken: null,
				showLink: false,
				tempAulaToken: '',
			};
		},
		watch: {
			aulaToken: function () {
				if (this.aulaToken !== undefined) {
					this.showLink = true;
				}
			}
		},
		computed: {
			getTempAulaToken() {
				return this.tempAulaToken;
			}
    	},
		methods: {
			addAulaTokenOnSubmit: function (e) {
				e.preventDefault();

				// Adding the aulaToken before submitting
				this.tempAulaToken = this.aulaToken;

				// Timeout ensures form is submittet after DOM is repaintet
				setTimeout(() => {
					// Ref can be an array because its located in a v-for
					if (Array.isArray(this.$refs.muSSOForm)) {
						this.$refs.muSSOForm[0].submit();
					} else {
						this.$refs.muSSOForm.submit();
					}

					// Clear the aulaToken after submitting, so it's not visible in DOM
					// Timeout ensures the form has been submittet before clearing
					setTimeout(() => {
						this.tempAulaToken = '';
					}, 500);
				}, 500);
			},
		},
		mounted: function() {
			this.showLink = false;
			this.link.searchParams.append('userProfile', this.userProfile.toLowerCase());

			this.aulaToken = this.getAulaToken();
		}
	};
</script>

<style scoped>
	.muSSOButton {
		background-color: white !important;
		padding-left: 25px;
		border: none;
		border-radius: 0;
		width: 100%;
		max-width: 300px;
	}
</style>
