<template>
  <div class="momo-courses">
    <div class="momo-heading">
      <div class="momo-heading-text">Forløb</div>
    </div>
    <div v-if="model.state === states.failed">
      <div class="momo-message">{{ model.error }}</div>
    </div>
    <div>
      <div
        v-if="isDisplayingCourses() && model.usersWithCourses.length > 0"
        v-for="userWithCourses of model.usersWithCourses"
        :key="userWithCourses.userName"
        class="momo-user-courses"
      >
        <div v-if="model.usersWithCourses.length > 1" class="momo-child-name">
          {{ formatPersonName(userWithCourses.userName) }}
        </div>
        <div
          v-for="course of userWithCourses.courses"
          :key="course.id"
          class="momo-course"
        >
          <img
            :src="course.image || urls.defaultCourseImage"
            @error="onCourseImageLoadError(course)"
            class="momo-course-icon"
          />
          <div class="momo-course-text-container">
            <div class="momo-course-text">{{ course.title }}</div>
            <div
              v-if="course.subjects.length > 0"
              class="momo-course-additional-text"
            >
              {{ formatNames(course.subjects) }}
            </div>
            <div class="momo-course-additional-text">
              {{ formatNames(course.teamNames) }}
            </div>
          </div>
          <a
            :href="momoCoursePageUrl(course.id)"
            target="_blank"
            :id="getCourseLinkId(course.id, userWithCourses.userId)"
            @click="setMomoCoursePageUrl(course, userWithCourses.userId)"
            class="momo-icon-button"
          >
            <img height="25" :src="urls.goToCourseIcon" alt=">" />
          </a>
        </div>
        <div
          v-if="
            userWithCourses.courses.length === 0 &&
            userProfile !== userProfileTypes.guardian
          "
        >
          <div class="momo-message">Du har ingen aktuelle forløb i MoMo.</div>
        </div>
        <div
          v-if="
            userWithCourses.courses.length === 0 &&
            userProfile === userProfileTypes.guardian
          "
          class="momo-no-courses-for-user"
        >
          {{ formatPersonName(userWithCourses.userName) }} har ingen forløb
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    axios: Function,
    moment: Function,
    sessionUUID: String,
    placement: String,
    isMobileApp: Boolean,
    userProfile: String,
    getAulaToken: Function,
    assuranceLevel: Number,
    childFilter: Array,
    institutionFilter: Array,
    group: String,
  },

  data: function () {
    const moMoBackendRootUrl = "https://systematic-momo.dk";
    const moMoFrontendRootUrl = "https://systematic-momo.dk";
    const apiVersion = "v1";

    return {
      widgetVersion: "1.3",
      aulaToken: null,
      urls: {
        moMoBackendCourses:
          moMoBackendRootUrl + "/api/aula/courses/" + apiVersion,
        defaultCourseImage:
          moMoFrontendRootUrl + "/assets/images/aula/default-course-image.svg",
        goToCourseIcon:
          moMoFrontendRootUrl + "/assets/images/aula/chevron-right.svg",
        moMoCoursePageFragment: "#/course/",
        moMoCoursePageRootUrl: moMoFrontendRootUrl + "#/course/",
        moMoCoursePageRootUrlWithQueryParameter: moMoFrontendRootUrl + "?",
      },
      states: {
        loading: 0,
        displaying: 1,
        failed: 2,
      },
      userProfileTypes: {
        child: "child",
        employee: "employee",
        guardian: "guardian",
      },
      model: {
        usersWithCourses: [],
        error: "",
        state: 0,
        hoveringOn: {
          goToCourseIcon: null,
        },
      },
    };
  },

  computed: {},

  methods: {
    /** STATE MANAGEMENT FUNCTIONS BEGIN **/
    load() {
      this.model.usersWithCourses = [];
      this.model.error = "";
      if (![2, 3].includes(this.assuranceLevel)) {
        this.model.state = this.states.failed;
        this.model.error = "Du skal være logget ind for at se forløb.";
      } else {
        this.model.state = this.states.loading;
        this.fetchCourses();
      }
    },

    isDisplayingCourses() {
      return this.model.state === this.states.displaying;
    },
    /** STATE MANAGEMENT FUNCTIONS END **/

    /** BACKEND COMMUNICATION FUNCTIONS BEGIN **/
    fetchCourses() {
      this.axios
        .get(
          this.urls.moMoBackendCourses +
            "?" +
            this.createChildrenParameter(this.childFilter, this.userProfile) +
            "&" +
            this.createTraceParameters(),
          this.createHeaders()
        )
        .then((response) => {
          this.model.usersWithCourses = response.data;
          this.model.state = this.states.displaying;
        })
        .catch(() => {
          this.model.error = "Noget gik galt. Prøv igen senere.";
          this.model.state = this.states.failed;
        });
    },

    createQueryParameter(key, items) {
      if (items) {
        const trimmed = items.map((s) => s.trim()).filter((s) => !!s);
        if (trimmed.length > 0) {
          return key + "=" + trimmed.join("&" + key + "=");
        }
      }
      return "";
    },

    createChildrenParameter(children, userProfile) {
      return userProfile === this.userProfileTypes.guardian
        ? this.createQueryParameter("children", children)
        : "";
    },

    createTraceParameters() {
      return (
        "widgetVersion=" +
        this.widgetVersion +
        "&userProfile=" +
        this.userProfile +
        "&sessionId=" +
        this.sessionUUID +
        "&" +
        this.createQueryParameter("institutions", this.institutionFilter)
      );
    },

    createHeaders() {
      return {
        headers: {
          "Aula-Authorization": "Bearer " + this.aulaToken,
        },
      };
    },

    /** BACKEND COMMUNICATION FUNCTIONS END **/

    /** FORMATING FUNCTIONS BEGIN **/
    formatPersonName(name) {
      return name ? name.split(" ")[0] : "Ukendt person";
    },

    formatNames(names) {
      return names.join(", ");
    },
    /** FORMATING FUNCTIONS END **/

    /** VARIOUS OTHER FUNCTIONS BEGIN **/
    arraysDiffer(a1, a2) {
      // assuming both are arrays of strings
      if ((a1 === null && a2 !== null) || (a2 === null && a1 !== null)) {
        return true;
      }
      if (a1 === null && a2 === null) {
        return false;
      }
      if (a1.length !== a2.length) {
        return true;
      }
      for (let i = 0; i < a1.length; i++) {
        if (a1[i] !== a2[i]) {
          return true;
        }
      }
      return false;
    },

    momoCoursePageUrl(courseId) {
      return this.urls.moMoCoursePageRootUrl + courseId;
    },

    getCourseLinkId(courseId, userId) {
      return "momo_course_link_" + courseId + "_" + userId;
    },

    setMomoCoursePageUrl(course, userId) {
      const queryParameters =
        "aulaToken=" +
        this.aulaToken +
        "&momoUserId=" +
        userId +
        "&momoInstitutionId=" +
        course.institutionId +
        "&userProfile=" +
        this.userProfile;

      const href =
        this.urls.moMoCoursePageRootUrlWithQueryParameter +
        queryParameters +
        this.urls.moMoCoursePageFragment +
        course.id;
      document.getElementById(
        this.getCourseLinkId(course.id, userId)
      ).href = href;
    },

    onCourseImageLoadError(course) {
      course.image = null;
    },
    /** VARIOUS OTHER FUNCTIONS END **/
  },

  watch: {
    childFilter: function (newValue, oldValue) {
      if (
        this.arraysDiffer(newValue, oldValue) &&
        this.aulaToken !== undefined
      ) {
        this.load();
      }
    },
    institutionFilter: function (newValue, oldValue) {
      if (
        this.arraysDiffer(newValue, oldValue) &&
        this.aulaToken !== undefined
      ) {
        this.load();
      }
    },
    assuranceLevel(newValue, oldValue) {
      if (newValue !== oldValue && this.aulaToken !== undefined) {
        this.load();
      }
    },
    aulaToken: function () {
      if (this.aulaToken !== undefined) {
        this.load();
      }
    },
  },

  created: function () {
    this.moment.locale("da");
    this.aulaToken = this.getAulaToken();
  },

  updated: function () {},
};
</script>

<style scoped>
.momo-courses {
  margin: 2px;
  background-color: white;
  border-radius: 4px;
  font-family: lato, sans-serif;
}

.momo-heading {
  padding: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: grey solid 1px;
}

.momo-heading-text {
  font-weight: bold;
  font-size: 22px;
}

.momo-user-courses {
  padding-bottom: 12px;
  margin-bottom: 4px;
}

.momo-user-reminders-no-more-to-see {
  padding-bottom: 12px;
}

.momo-child-name {
  padding: 8px;
  padding-left: 16px;
  font-size: 18px;
  font-weight: bold;
  background-color: #f6f7f8;
}

.momo-user-reminders-for-date:not(:last-child) {
  border-bottom: 1px solid RGBA(0, 0, 0, 0.08);
}

.momo-course {
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 16px 0;
}

.momo-course-icon {
  min-height: 40px;
  min-width: 40px;
  max-height: 40px;
  max-width: 40px;
  background-color: #f2f2f2;
  border-radius: 4px;
}

.momo-course-text-container {
  padding-left: 8px;
  flex-grow: 2;
  max-width: 75%;
}

.momo-course-text,
.momo-course-additional-text {
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden;
}

.momo-course-text {
  font-size: 15px;
  color: RGBA(0, 0, 0, 0.78);
  min-height: 18px;
  margin-bottom: 2px;
  margin-right: 2px;
}

.momo-course-additional-text {
  font-size: 12px;
  color: RGBA(0, 0, 0, 0.54);
  min-height: 18px;
  display: flex;
  align-items: center;
}

.momo-icon-button {
  height: 20px;
  width: 20px;
  cursor: pointer;
  opacity: 0.54;
}

.momo-icon-button:hover {
  opacity: 0.87;
}

.momo-no-courses-for-user {
  padding: 8px;
}

.momo-message {
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 8px;
  padding-right: 8px;
}
</style>