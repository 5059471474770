<script>

import { interpolate } from '../util'

const SelectedRecipients = {
    props: {
        visibleWithoutAnySelections: Boolean,
        selected: Object,
        selectedRecipients: Array,
        unSelectRecipient: Function,
        unSelectAllGroupType: Function,
        unSelectAllGroupTypeByClass: Function,
        translations: Object,
    },
    data() {
        return {
            preview: this.createPreview(),
        }
    },
    watch: {
        selected: {
            deep: true,
            handler: function() {
                this.preview = this.createPreview()
            },
        },
    },
    methods: {
        interpolate,
        createPreview() {

            const roleTranslations = this.translations.general.roles

            return this.createTypePreviews('teachers', roleTranslations.teachers, roleTranslations.all.teachers)
                .concat(this.createTypePreviews('tutors', roleTranslations.tutors, roleTranslations.all.tutors))
                .concat(this.createTypePreviews('students', roleTranslations.students, roleTranslations.all.students))
                .concat(this.createTypePreviews('contactPersons', roleTranslations.contacts, roleTranslations.all.contacts))
                .concat(this.createIndividualPreviews())
        },
        createTypePreviews(selectedKey, classLabel, allLabel) {

            if (this.selected[selectedKey] === 'all') {
                return [{
                    label: allLabel,
                    onRemove: () => this.unSelectAllGroupType(selectedKey),
                }]
            }

            // Students or student contacts containing classes
            if (this.selected[selectedKey] && typeof this.selected[selectedKey] === 'object') {
                return Object.values(this.selected[selectedKey])
                    .filter(value => !!value)
                    .map(_class => ({
                        label: `${_class.name}: ${classLabel}`,
                        onRemove: () => this.unSelectAllGroupTypeByClass(selectedKey, _class),
                    }))
            }

            return []
        },
        createIndividualPreviews() {
            const users = Object.values(this.selected.users)
            return users.map(user => ({
                label: user.name,
                onRemove: () => this.unSelectRecipient(user),
            }))
        },
    },
}

export default SelectedRecipients

</script>

<template>
    <div>
        <div style="position: relative;" v-if="preview.length || visibleWithoutAnySelections">
            <div class="selected-recipients-container scrollable">
                <div class="selected-recipients-list">
                    <div class="selected-recipient-bubble" v-for="recipientPreview in preview">
                        <span class="selected-recipient-text" v-if="recipientPreview.label">{{ recipientPreview.label }}</span>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" class="selected-recipient-icon pointer" v-if='recipientPreview.onRemove'
                             @click='recipientPreview.onRemove'>
                            <path
                                d="M26 6.96l-0.96-0.96-9.040 9.04040-9.0-9.040-0.96 0.96 9.040 9.040-9.040 9.040 0.96 0.96 9.040-9.040 9.040 9.040 0.96-0.96-9.040-9.040 9.040-9.040z" />
                        </svg>
                    </div>
                </div>
            </div>
            <span class="selected-recipients-total number-text" v-if="selectedRecipients.length">
                    {{ interpolate(translations.selectedRecipients.peopleSelected, { count: selectedRecipients.length }) }}
                </span>
            <span class="clear-selected-recipients-button pointer" @click="$emit('unselect-all-recipients')" v-if="selectedRecipients.length">
                    {{ interpolate(translations.selectedRecipients.clearSelected) }}
                </span>
        </div>
    </div>
</template>