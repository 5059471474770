<script>

const SelectFromClass = {
    props: {
        search: String,
        currentClass: Object,
        selectedStudents: null,
        selectedContactPersons: null,
        selectClassRole: Function,
        unselectClassRole: Function,
        translations: Object,
    },
    data() {
        return {
            isManuallyToggled: false,
            isManuallyOpen: false,
        }
    },
    computed: {
        isOpen() {
            return this.isManuallyToggled
                ? this.isManuallyOpen
                : this.isDefaultOpen
        },
        isDefaultOpen() {
            return this.search.length > 0
        },
        isStudentsSelected() {
            return (
                this.selectedStudents === 'all' ||
                (this.selectedStudents && this.selectedStudents[this.currentClass.id])
            )
        },
        isContactsSelected() {
            return (
                this.selectedContactPersons === 'all' ||
                (this.selectedContactPersons && this.selectedContactPersons[this.currentClass.id])
            )
        },
        isAllSelected() {
            return this.isStudentsSelected && this.isContactsSelected
        },
    },
    methods: {
        toggleOpen() {
            this.isManuallyToggled = true
            this.isManuallyOpen = !this.isManuallyOpen
        },
        toggleHeaderCheckbox() {

            if (this.isAllSelected) {
                this.toggleContactsCheckbox()
                this.toggleStudentsCheckbox()
                return
            }

            if (!this.isStudentsSelected) {
                this.toggleStudentsCheckbox()
            }

            if (!this.isContactsSelected) {
                this.toggleContactsCheckbox()
            }
        },
        toggleStudentsCheckbox() {
            const handler = this.isStudentsSelected
                ? this.unselectClassRole
                : this.selectClassRole

            handler('students', this.currentClass)
        },
        toggleContactsCheckbox() {

            const handler = this.isContactsSelected
                ? this.unselectClassRole
                : this.selectClassRole

            handler('contactPersons', this.currentClass)
        },
    },
}

export default SelectFromClass

</script>

<template>

    <div class="class-container pointer" v-bind:class='{open: isOpen}'>
        <div class="class-header" @click="toggleOpen()">
            <div class="form-check">
                <input class="form-check-input class-checkbox" type="checkbox" :checked="isAllSelected" v-on:click.stop='toggleHeaderCheckbox'>
                <span class="class-name">{{ currentClass.name }}</span>
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" class="class-dropdown-icon" v-if="!isOpen">
                <path d="M5.333 12.273l0.94-0.94 9.727 8.78 9.727-8.78 0.94 0.94-10.667 9.727-10.667-9.727z" />
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" class="class-dropdown-icon" v-else>
                <path d="M16 10.667l-10.667 9.727 0.94 0.94 9.727-8.78 9.727 8.78 0.94-0.94-10.667-9.727z" />
            </svg>
        </div>
        <div class="class-body" v-if="isOpen">
            <div class="class-dropdown">
                <div
                    class="dropdown-menu-item"
                    @click="toggleContactsCheckbox()"
                >
                    <input type="checkbox" :checked="isContactsSelected">
                    <span class="dropdown-label">{{ translations.general.roles.contacts }}</span>
                </div>
                <div
                    class="dropdown-menu-item"
                    @click="toggleStudentsCheckbox()"
                >
                    <input type="checkbox" :checked="isStudentsSelected">
                    <span class="dropdown-label">{{ translations.general.roles.students }}</span>
                </div>
            </div>
        </div>
    </div>
</template>