<script>

import { interpolate } from '../util'

const MessageForm = {
    data() {
        return {
            isHoveringLengthDescriptionTooltip: false,
            inputHeight: 44,
        }
    },
    props: {
        message: String,
        group_id: null,
        onMessageChange: Function,
        messageLengthDescription: String,
        onSubmit: Function,
        newMessageMessagesAmount: Number,
        charactersInCurrentMessage: Number,
        isValidGsmMessage: Boolean,
        translations: Object,
    },
    watch: {
        message: function() {
            if (this.message === '') {
                this.inputHeight = 44
            }
        },
    },
    methods: {
        interpolate,
        handleChange(event) {

            const keyCode = event.key
            const value = event.target.value

            const shadow = document.getElementById('subit-message-input-shadow')
            const input = document.getElementById('subit-message-input')
            shadow.innerHTML = value + (keyCode === 'Enter' ? '\n' : '')
            shadow.style.width = `${input.offsetWidth}px`
            const paddingHeight = shadow.clientHeight
            this.inputHeight = Math.max(Math.min(150, paddingHeight), 44)

            this.onMessageChange(value)
        },
        openLengthDescriptionTooltip() {
            this.isHoveringLengthDescriptionTooltip = true
        },
        closeLengthDescriptionTooltip() {
            this.isHoveringLengthDescriptionTooltip = false
        },
    },
}

export default MessageForm

</script>

<template>
    <form class="message-form">
        <div class='message-form-input-component'>
            <div style='position: relative;'>
                <pre class='message-input-shadow message-input' id='subit-message-input-shadow'></pre>
                <textarea
                    :style="{height: String(inputHeight + 2) + 'px'}"
                    id="subit-message-input"
                    class="message-input scrollable"
                    @keyup="handleChange"
                    :placeholder='translations.messageForm.messageFormPlaceholder'
                    :value='message'
                ></textarea>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" class="message-icon pointer" @mousedown='e => e.preventDefault()' @click="this.onSubmit">
                    <path
                        d="M32 2.667l-32 9 7.333 6.893v10.773l6-5.12 5.333 5.12zM26.527 5.82l-18.447 11.613-5.413-5.1zM8.667 26.527v-6.707l3.693 3.473zM9.087 18.38l19.873-12.52-10.813 21.047z" />
                </svg>
            </div>
        </div>
        <div
            class="message-length-description number-text"
            @mouseenter="openLengthDescriptionTooltip"
            @mouseleave="closeLengthDescriptionTooltip">
            {{ messageLengthDescription || '-' }}
            <div
                v-if="isHoveringLengthDescriptionTooltip && charactersInCurrentMessage"
                class='tooltip-container message-length-description-tooltip'>
                {{ interpolate(translations.messageForm.lengthDescription, { characterCount: message.length, messageCount: newMessageMessagesAmount }) }}
                <span v-if='!isValidGsmMessage' class='tooltip-section'>
                        {{ translations.messageForm.utfDescription }}
                    </span>
            </div>
        </div>
    </form>
</template>