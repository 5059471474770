<template>
  <div>
    <h1>widget - secure notice board data</h1>
    <template v-if="sessionUUID"><strong>sessionUUID:</strong> {{ sessionUUID }}<br></template>
    <template v-if="placement"><strong>placement:</strong> {{ placement }}<br></template>
    <template v-if="currentWeekNumber"><strong>currentWeekNumber:</strong> {{ currentWeekNumber }}<br></template>
    <template v-if="group"><strong>group:</strong> {{ group }}<br></template>
    <template v-if="someDate"><strong>moment date:</strong> {{ someDate }}<br></template>
    <template v-if="placement"><strong>placement:</strong> {{ placement }}<br></template>
    <template v-if="institutionCode"><strong>institutionCode:</strong> {{ institutionCode }}<br></template>
    <template v-if="source"><strong>source:</strong> {{ source }}<br></template>
    <template v-if="aulaToken"><strong>aulaToken:</strong> <span class="breakWord">{{ aulaToken }}</span><br></template>
  </div>
</template>

<script>
module.exports = {
  props: {
    axios: Function,
    moment: Function,
    widgetID: String,
    placement: String,
    group: String,
    currentWeekNumber: String,
    institutionCode: String,
    getAulaToken: Function,
    sessionUUID: String,
    source: String,
    someDate: String,
  },
  data: function () {
    return {
      posts: {},
      aulaToken: null,
      properties: {}
    }
  },
  methods: {
    loadStuff() {
      this.axios.get("/static/posts.json")
        .then(response => {
          // JSON responses are automatically parsed.
          this.posts = response.data;
        })
        .catch(e => {
          //some error handling
          console.log(e);
        })
    },
  },
  mounted() {
   this.aulaToken = this.getAulaToken();
  },
  watch: {
     aulaToken: function() {
      if (this.aulaToken != undefined) {
        this.loadStuff();
      }
    },
  }
};
</script>

<style scoped>
b:before {
  content: "";
  display: block;
}
.breakWord {
  word-break: break-all;
}
</style>
