<script>

import { interpolate } from '../util'

const Recipient = {
    props: {
        role: String,
        recipient: Object,
        keyedClasses: Object,
        isSelected: Boolean,
        isSelectable: Boolean,
        giveConsent: Function,
        isMissingPhoneIconVisible: { type: Boolean, default: true },
        isConsentIconVisible: { type: Boolean, default: true },
        isMessageStatusIconVisible: { type: Boolean, default: false },
        recipientStatus: String,
        translations: Object,
    },
    data() {
        return {
            isOpen: false,
            isAddConsentMenuOpen: false,
            isHoveringConsentTooltip: false,
            isHoveringRecipientMissingPhoneTooltip: false,
            isStatusTooltipOpen: false,
        }
    },
    methods: {
        interpolate,
        onGiveConsent() {
            this.giveConsent([this.recipient])
            this.closeAddConsentMenu()
        },
        onToggleSelection() {
            if (this.isSelected) {
                this.$emit('remove-recipient', this.recipient)
            } else if (this.isSelectable) {
                this.$emit('add-recipient', this.recipient)
            }
        },
        toggleAddConsentMenu() {
            this.isAddConsentMenuOpen = !this.isAddConsentMenuOpen
        },
        closeAddConsentMenu() {
            this.isAddConsentMenuOpen = false
        },
        openConsentTooltip() {
            this.isHoveringConsentTooltip = true
        },
        closeConsentTooltip() {
            this.isHoveringConsentTooltip = false
        },
        toggleOpen() {
            this.isOpen = !this.isOpen
        },
        openRecipientMissingPhoneTooltip() {
            this.isHoveringRecipientMissingPhoneTooltip = true
        },
        closeRecipientMissingPhoneTooltip() {
            this.isHoveringRecipientMissingPhoneTooltip = false
        },
        openStatusTooltip() {
            this.isStatusTooltipOpen = true
        },
        closeStatusTooltip() {
            this.isStatusTooltipOpen = false
        },
    },
    computed: {
        initials() {
            return this.recipient.roles.find(role => role.type === this.role)?.initials || 'N/A'
        },
        otherRolesText() {
            const otherRoles = this.recipient.roles.filter(role => role.type !== this.role)
            const translations = this.translations.general.roles

            return otherRoles.length > 0
                ? otherRoles.map(role => translations[role.type]).join(', ')
                : null
        },
        mainClassName() {

            if (this.role !== 'student') {
                return null
            }

            const studentRole = this.recipient.roles.find(role => role.type === 'student')
            if (!studentRole || !studentRole.classes || studentRole.classes.length < 1) {
                return
            }

            return this.keyedClasses && this.keyedClasses[studentRole.classes[0]]?.name
        },
        consentTooltipText() {

            if (this.recipient.is_consented) {
                return this.translations.recipient.consentGiven
            }

            return this.recipient.is_changeable_consent
                ? this.translations.recipient.consentCanBeGiven
                : this.translations.recipient.consentDenied
        },
    },
}

export default Recipient

</script>

<template>
    <div style="position: relative;">
        <div @click="onToggleSelection" :class="[isSelectable && 'pointer']">
            <div :class="['recipient-list-item']">
                <div class="form-check" v-if="isSelectable">
                    <input class="form-check-input select-group-checkbox" type="checkbox" id="all-tutors" :checked="isSelected">
                </div>
                <div class="recipient-initials" v-if="role === 'teacher' || role === 'tutor'">
                    <span>{{ initials || 'N/A' }}</span>
                </div>
                <p class="recipient-name">{{ recipient.name }}</p>
                <div class='recipient-right-icons'>
                    <div v-if='isMissingPhoneIconVisible' class='recipient-icon-container'>
                        <svg
                            v-if='!recipient.has_phone_number'
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 32 32" class='recipient-icon fill-red'
                            @mouseenter="openRecipientMissingPhoneTooltip"
                            @mouseleave="closeRecipientMissingPhoneTooltip">
                            <path
                                d="M22 0h-12c-1.841 0-3.333 1.492-3.333 3.333v0 25.333c0 1.841 1.492 3.333 3.333 3.333v0h12c1.841 0 3.333-1.492 3.333-3.333v0-25.333c0-1.841-1.492-3.333-3.333-3.333v0zM10 1.333h12c1.105 0 2 0.895 2 2v0 22h-16v-22c0-1.105 0.895-2 2-2v0zM22 30.667h-12c-1.105 0-2-0.895-2-2v0-2h16v2c0 1.105-0.895 2-2 2v0zM17.333 28.667c0 0.736-0.597 1.333-1.333 1.333s-1.333-0.597-1.333-1.333c0-0.736 0.597-1.333 1.333-1.333s1.333 0.597 1.333 1.333z" />
                        </svg>
                        <div class="tooltip-container tooltip-left recipient-no-phone-tooltip " v-if='!recipient.has_phone_number && isHoveringRecipientMissingPhoneTooltip'>
                            {{ translations.recipient.userDoesNotHavePhoneNumber }}
                        </div>
                    </div>
                    <div v-if='isConsentIconVisible' class='recipient-icon-container'>
                        <svg v-if="recipient.is_consented" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"
                             class="recipient-icon fill-blue"
                             @mouseenter="openConsentTooltip" @mouseleave="closeConsentTooltip">
                            <path
                                d="M4 0v32h15.333c4.786 0 8.667-3.88 8.667-8.667v0-23.333h-24zM26.667 23.333c0 4.050-3.283 7.333-7.333 7.333v0h-14v-29.333h21.333v22zM10.667 9.333h10.667v1.333h-10.667v-1.333zM10.667 14.667h10.667v1.333h-10.667v-1.333zM10.667 20h8v1.333h-8v-1.333z" />
                        </svg>
                        <svg v-else-if='!recipient.is_changeable_consent' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"
                             class="recipient-icon icon-consent-denied fill-red"
                             @mouseenter="openConsentTooltip" @mouseleave="closeConsentTooltip">
                            <path
                                d="M10.667 14.679v1.333h10.667v-1.333h-10.667zM18.667 20.012v1.333h-8v-1.333h8zM10.667 9.346v1.333h10.667v-1.333h-10.667zM19.333 30.667l-14 0.012v-29.333h21.333v15.988c0.529 0 0.863 0 1.333 0v-17.321h-24v32l15.333-0.012v-1.333zM26.171 21.333l-0.179 5.254-0.008 0.242v0.004h-1.308l-0.183-5.5zM26.212 28.504c0 0.1-0.012 0.196-0.042 0.283 0 0 0 0 0 0-0.038 0.117-0.104 0.221-0.2 0.313-0.050 0.050-0.108 0.092-0.171 0.125-0.133 0.075-0.292 0.108-0.471 0.108-0.121 0-0.229-0.017-0.329-0.050-0.117-0.038-0.217-0.1-0.304-0.183-0.121-0.117-0.196-0.254-0.225-0.413-0.012-0.058-0.017-0.117-0.017-0.179 0-0.242 0.079-0.438 0.242-0.596s0.375-0.238 0.633-0.238h0.012c0.258 0.004 0.471 0.079 0.629 0.233 0.133 0.129 0.212 0.288 0.233 0.475 0.008 0.038 0.008 0.079 0.008 0.121zM25.333 20c1.425 0 2.762 0.554 3.771 1.563s1.563 2.346 1.563 3.771-0.554 2.762-1.563 3.771-2.346 1.563-3.771 1.563-2.762-0.554-3.771-1.563c-1.008-1.008-1.563-2.346-1.563-3.771s0.554-2.762 1.563-3.771c1.008-1.008 2.346-1.563 3.771-1.563zM25.333 18.667c-3.683 0-6.667 2.983-6.667 6.667s2.983 6.667 6.667 6.667 6.667-2.983 6.667-6.667-2.983-6.667-6.667-6.667v0z" />
                        </svg>
                        <svg v-else-if='recipient.is_changeable_consent' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"
                             class="recipient-icon icon-consent-denied fill-orange pointer"
                             @mouseenter="openConsentTooltip" @mouseleave="closeConsentTooltip" v-on:click.stop='toggleAddConsentMenu'>
                            <path
                                d="M10.667 14.679v1.333h10.667v-1.333h-10.667zM18.667 20.012v1.333h-8v-1.333h8zM10.667 9.346v1.333h10.667v-1.333h-10.667zM19.333 30.667l-14 0.012v-29.333h21.333v15.988c0.529 0 0.863 0 1.333 0v-17.321h-24v32l15.333-0.012v-1.333zM26.171 21.333l-0.179 5.254-0.008 0.242v0.004h-1.308l-0.183-5.5zM26.212 28.504c0 0.1-0.012 0.196-0.042 0.283 0 0 0 0 0 0-0.038 0.117-0.104 0.221-0.2 0.313-0.050 0.050-0.108 0.092-0.171 0.125-0.133 0.075-0.292 0.108-0.471 0.108-0.121 0-0.229-0.017-0.329-0.050-0.117-0.038-0.217-0.1-0.304-0.183-0.121-0.117-0.196-0.254-0.225-0.413-0.012-0.058-0.017-0.117-0.017-0.179 0-0.242 0.079-0.438 0.242-0.596s0.375-0.238 0.633-0.238h0.012c0.258 0.004 0.471 0.079 0.629 0.233 0.133 0.129 0.212 0.288 0.233 0.475 0.008 0.038 0.008 0.079 0.008 0.121zM25.333 20c1.425 0 2.762 0.554 3.771 1.563s1.563 2.346 1.563 3.771-0.554 2.762-1.563 3.771-2.346 1.563-3.771 1.563-2.762-0.554-3.771-1.563c-1.008-1.008-1.563-2.346-1.563-3.771s0.554-2.762 1.563-3.771c1.008-1.008 2.346-1.563 3.771-1.563zM25.333 18.667c-3.683 0-6.667 2.983-6.667 6.667s2.983 6.667 6.667 6.667 6.667-2.983 6.667-6.667-2.983-6.667-6.667-6.667v0z" />
                        </svg>
                        <div class="tooltip-container tooltip-left recipient-consent-tooltip" v-if="isHoveringConsentTooltip">
                            {{ consentTooltipText }}
                        </div>
                    </div>
                    <div v-if='recipientStatus && isMessageStatusIconVisible' class='recipient-icon-container'>
                        <svg v-if="recipientStatus === 'received'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" class="recipient-icon fill-green"
                             @mouseenter="openStatusTooltip" @mouseleave="closeStatusTooltip">
                            <path
                                d="M16 0c-8.837 0-16 7.163-16 16s7.163 16 16 16c8.837 0 16-7.163 16-16v0c0-8.837-7.163-16-16-16v0zM16 30.667c-8.1 0-14.667-6.567-14.667-14.667s6.566-14.667 14.667-14.667c8.1 0 14.667 6.566 14.667 14.667v0c0 8.1-6.566 14.667-14.667 14.667v0zM15.453 20.72l-5.567-5.567-0.94 0.94 6.793 6.793 7.587-13.133-1.153-0.667-6.72 11.633z" />
                        </svg>
                        <svg v-else-if="recipientStatus === 'pending'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" class="recipient-icon fill-grey"
                             @mouseenter="openStatusTooltip" @mouseleave="closeStatusTooltip">
                            <path
                                d="M24 7.273v-5.94h2.667v-1.333h-21.333v1.333h2.667v5.94c0 0.001 0 0.002 0 0.003 0 2.429 1.181 4.582 3 5.916l0.020 0.014 3.847 2.793-3.847 2.8c-1.838 1.348-3.019 3.499-3.020 5.926v5.94h-2.667v1.333h21.333v-1.333h-2.667v-5.94c0-0.001 0-0.002 0-0.003 0-2.429-1.181-4.582-3-5.916l-0.020-0.014-3.847-2.793 3.847-2.8c1.838-1.348 3.019-3.499 3.020-5.926v-0zM9.333 30.667v-3.447l6.667-1.22 6.667 1.213v3.453h-13.333zM20.193 19.873c1.506 1.103 2.473 2.865 2.473 4.853v1.14l-6.667-1.2-6.667 1.2v-1.14c0-1.988 0.967-3.75 2.457-4.842l0.017-0.012 4.193-3.047zM20.193 12.12l-4.193 3.053-4.193-3.053c-1.504-1.102-2.471-2.861-2.473-4.846v-5.94h13.333v5.94c-0 1.988-0.967 3.75-2.457 4.842l-0.017 0.012z"></path>
                        </svg>
                        <svg v-else-if="recipientStatus === 'failed'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" class="recipient-icon fill-red"
                             @mouseenter="openStatusTooltip" @mouseleave="closeStatusTooltip">
                            <path
                                d="M16 0c-8.837 0-16 7.163-16 16s7.163 16 16 16c8.837 0 16-7.163 16-16v0c0-8.837-7.163-16-16-16v0zM16 30.667c-8.1 0-14.667-6.567-14.667-14.667s6.566-14.667 14.667-14.667c8.1 0 14.667 6.566 14.667 14.667v0c0 8.1-6.566 14.667-14.667 14.667v0zM21.527 9.527l-5.527 5.533-5.527-5.533-0.947 0.947 5.533 5.527-5.533 5.527 0.947 0.947 5.527-5.533 5.527 5.533 0.947-0.947-5.533-5.527 5.533-5.527-0.947-0.947z" />
                        </svg>
                        <div class="tooltip-container tooltip-left recipient-status-tooltip" v-if="recipientStatus === 'received' && isStatusTooltipOpen">
                            {{ interpolate(translations.recipient.messageReceivedBy, { name: recipient.name }) }}
                        </div>
                        <div class="tooltip-container tooltip-left recipient-status-tooltip" v-if="recipientStatus === 'pending' && isStatusTooltipOpen">
                            {{ interpolate(translations.recipient.messagePendingFor, { name: recipient.name }) }}
                        </div>
                        <div class="tooltip-container tooltip-left recipient-status-tooltip" v-if="recipientStatus === 'failed' && isStatusTooltipOpen">
                            {{ interpolate(translations.recipient.messageFailedFor, { name: recipient.name }) }}
                        </div>
                    </div>
                    <div class="recipient-initials" v-if="!!mainClassName" style='margin-left: 10px;'>
                        <span>{{ mainClassName }}</span>
                    </div>
                    <div class='recipient-icon-container' v-on:click.stop='toggleOpen'>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" class='recipient-icon pointer'>
                            <path d="M3.333 5.333h25.333v1.333h-25.333v-1.333zM3.333 14.667h18.667v1.333h-18.667v-1.333zM3.333 24h25.333v1.333h-25.333v-1.333z" />
                        </svg>
                    </div>
                </div>
            </div>
            <div class='recipient-details-container' v-if='isOpen'>
                <div class='recipient-details-item'>
                    <span class='contact-details-item-key'>{{ translations.recipient.otherRoles }}</span>
                    <span class='recipient-details-item-value'>{{ otherRolesText || '-' }}</span>
                </div>
                <div class='recipient-details-item'>
                    <span class='contact-details-item-key'>{{ translations.recipient.uniId }}</span>
                    <span class='recipient-details-item-value'>{{ this.recipient.provider_id || '-' }}</span>
                </div>
            </div>
            <div class="give-consent-card" v-if='isAddConsentMenuOpen'>
                <div class="consent-button text-primary border border-primary pointer" v-on:click.stop="onGiveConsent">
                    {{ translations.recipient.giveConsentForUser }}
                </div>
                <div class="recipient-border-bottom"></div>
            </div>
        </div>
        <div class="recipient-border-bottom"></div>
    </div>
</template>