<template>
    <div v-if="isFetchingPost">
        <p>Loading</p>
    </div>
    <div v-else>
        <h1>{{ currentPost.Produktnavn }}</h1>
        <p><em>Nyt fra {{ currentPost.Author }}</em></p>
        <p v-if="currentPost.Manchet">{{ currentPost.Manchet }}</p>
        <img :src="billedeUrl" alt="" />
        <p><widget-html :html="beskrivelse"></widget-html></p>
        <p><a :href="currentPost.Url" target="_blank">Læs mere</a></p>
        <p><em>{{ articleUrl }}</em></p>
    </div>
</template>
<script>
// Constants for filter methods
const FilterMethods = {
    BY_TARGET_GROUP: 0,
    BY_TARGET_GROUP_SUB: 1,
};
// Constants for selection methods
const SelectionMethods = {
    LATEST: 0,
    OLDEST: 1,
    RANDOM: 2,
};
// Institution IDs for different regions
const institutions = {
    thisted_skoler: ['280215','280220','280811','281197','281396','765001','765004','765005','785002','785006','785007','785009','785013','785015','785016','785301','787002','787004','787005','787006','787008','787012','787013','787016','787019','787021','787022','787024','787030','787033','787035','787036','787214','787303','787000'],
    thisted_dagtilbud: ['G21469','G21673','G21674','G21675','G21676','G21677','G21678','G21704','G21705','G21706','G21707','G21708','G21731','G21775','G22746','G22747','G22748','G22749','G22750','G22751','G22752','G22753','G22754','G22755','G22756','G22757','G22759','G22760','G22761','G22762','G22763','G22764','G22765','G22768'],
    aarhus_skoler: ['280114','280144','280146','280307','280313','280397','280410','280458','281191','281290','281401','281403','281404','281520','281602','281648','281689','751002','751003','751004','751006','751007','751008','751013','751014','751015','751016','751017','751018','751019','751020','751021','751022','751023','751024','751025','751026','751027','751032','751033','751034','751035','751036','751038','751039','751040','751041','751042','751043','751044','751045','751046','751050','751051','751052','751053','751054','751055','751056','751058','751059','751060','751061','751062','751063','751064','751065','751066','751080','751081','751089','751090','751094','751095','751098','751107','751111','751114','751204','751312','751327','751903','791413'],  
    hjoerring_skoler: ['819002', '819003', '819004', '839006', '839004', '819001', '821006', '829005', '829002', '821003', '821010', '821002', '821001', '821009', '821019', '280628'],
};
// Lookup table for region-specific settings
const regionSettings = {
    thisted_skoler: {
        feedUrl: 'https://www.rum.thisted.dk/feed/json/node/course',
        filterMethod: FilterMethods.BY_TARGET_GROUP,
        filterKeyword: 'Grundskole',
        selectionMethod: SelectionMethods.LATEST,
    },
    thisted_dagtilbud: {
        feedUrl: 'https://www.rum.thisted.dk/feed/json/node/course',
        filterMethod: FilterMethods.BY_TARGET_GROUP,
        filterKeyword: 'Dagtilbud',
        selectionMethod: SelectionMethods.LATEST,
    },
    aarhus_skoler: {
        feedUrl: 'https://www.ulfiaarhus.dk/feed/json/node/news',
        filterMethod: undefined,
        filterKeyword: undefined,
        selectionMethod: SelectionMethods.LATEST,
    },
    hjoerring_skoler: {
        feedUrl: 'https://www.ungegarantien.dk/feed/json/node/education',
        filterMethod: undefined,
        filterKeyword: undefined,
        selectionMethod: SelectionMethods.RANDOM,
    },
};
// Error post to display when there's an error
const errorPost = {
    Produktnavn: 'Der opstod en fejl',
    Author: 'Udvikleren',
    Manchet: 'Noget gik galt',
    BilledeUrl: '',
    BilledeUrlTeaser: '',
    Beskrivelse: 'Ind til videre virker denne widget kun for institutioner i Aarhus, Hjørring og Thisted kommune.\nHvis din kommune er med i OS2udoglær, kan dette widget udvikles til at vise seneste nyt fra jeres portal.',
    Beskrivelse_cut: 'Ind til videre virker denne widget kun for institutioner i Aarhus, Hjørring og Thisted kommune.\nHvis din kommune er med i OS2udoglær, kan dette widget udvikles til at vise seneste nyt fra jeres portal.',
    Url: 'https://os2.eu/produkt/os2udoglaer',
    BaseUrl: 'https://www.',
};

export default {
    props: {
        axios: Function,
        placement: String,
        institutionFilter: Array,
    },
    data() {
        return {
            isFetchingPost: true, // Loading state
            currentPost: {}, // Object to store the fetched post
        };
    },
    computed: {
        beskrivelse() {
            return this.placement.includes('narrow') ? this.currentPost.Beskrivelse_cut : this.currentPost.Beskrivelse;
        },
        billedeUrl() {
            return this.placement.includes('narrow') ? this.currentPost.BilledeUrlTeaser : this.currentPost.BilledeUrl;
        },
        articleUrl() {
            return this.currentPost.BaseUrl?.replace('https://', '').replace('www.', '');
        },
    },
    methods: {
        // Loads a fresh post based on institution id
        async loadPost(institutionId) {
            try {
                const institution = Object.keys(institutions).find((key) => institutions[key].includes(institutionId));

                if (!institution) throw new Error('Invalid institution number');

                const { feedUrl, filterMethod, filterKeyword, selectionMethod } = regionSettings[institution];

                let posts = await this.fetchPosts(feedUrl);
                posts = this.filterPosts(posts, filterMethod, filterKeyword);

                if (posts.length < 1) throw new Error('No posts to show');

                this.currentPost = this.selectPost(posts, selectionMethod);
            } catch (error) {
                console.error(error);
                this.currentPost = errorPost;
            } finally {
                this.isFetchingPost = false;
            }
        },
        // Fetches posts from the given feedUrl
        async fetchPosts(feed) {
            try {
                const response = await this.axios.get(feed);
                return response.data;
            } catch (error) {
                console.error(error);
                throw new Error('Failed to fetch posts');
            }
        },
        // Returns filtered posts based on regional settings filtering method and keyword
        filterPosts(posts, filterMethod, filterKeyword) {
            switch (filterMethod) {
                case FilterMethods.BY_TARGET_GROUP:
                    return posts.filter((post) => post?.targetGroup.includes(filterKeyword));
                case FilterMethods.BY_TARGET_GROUP_SUB:
                    return posts.filter((post) => post?.targetGroupSub.includes(filterKeyword));
                default:
                    return posts;
            }
        },
        // Returns single post based on regional settings selection method
        selectPost(posts, selectionMethod) {
            switch (selectionMethod) {
                case SelectionMethods.RANDOM:
                    return posts[Math.floor(Math.random() * posts.length)];
                case SelectionMethods.OLDEST:
                    return posts[0];
                case SelectionMethods.LAST:
                default:
                    return posts.at(-1);
            }
        },
    },

    async created() {
        if (this.institutionFilter?.length > 0) {
            const institutionId = this.institutionFilter[0];
            await this.loadPost(institutionId);
        } else {
            console.error('No institution number given');
            this.currentPost = errorPost;
            this.isFetchingPost = false;
        }
    },
};
</script>