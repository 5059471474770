<script>

const BASE_URL = 'https://api.subit.dk'
const X_CLIENT = 'Aula sms-widget-user-consent'
const DEFAULT_LANGUAGE = 'da'
const LOCAL_STORAGE_SUBIT_TOKEN = 'subit_token'
const LOCAL_STORAGE_SUBIT_TOKEN_EXPIRES = 'subit_token_expires'
const LOCAL_STORAGE_SUBIT_LANGUAGE = 'subit_language'

export default {
// export default {

  props: {
    getAulaToken: Function,
    axios: Function,
  },

  data() {
    return {
      aulaToken: null,
      collapsed: true,
      isLoading: true,
      isFirstLoad: true,
      user: null,
      language: localStorage.getItem(LOCAL_STORAGE_SUBIT_LANGUAGE) || DEFAULT_LANGUAGE,
      phoneExtension: null,
      phoneNumber: null,

      showWithdrawConsentConfirmation: false,
      takenPhoneNumbers: [],
      errorOccurred: false,
    }
  },

  computed: {

    isPhoneValid() {

      const formatValid = this.isPhoneFormatValid
      const phoneTaken = this.isPhoneTaken

      return formatValid && !phoneTaken
    },

    isPhoneFormatValid() {

      if (!this.phoneNumber) {
        return true
      }

      switch (this.phoneExtension) {
        case 45:
          return !!this.phoneNumber.toString().match(/^[2-9]\d{7}$/g)
        case 46:
          return !!this.phoneNumber.toString().match(/^[1-9]\d{7,12}$/g)
        case 298:
          return !!this.phoneNumber.toString().match(/^[0-9]\d{5}$/g)
        default:
          return true
      }
    },

    isPhoneTaken() {
      return this.takenPhoneNumbers.includes(
        this.phoneNumberHash,
      )
    },

    phoneNumberHash() {
      return `${this.phoneExtension}:${this.phoneNumber}`
    },

    translations: function() {
      return TRANSLATIONS[this.language]
    },

    isConsented() {
      return this.user.communication.is_consented
    },

    originalPhoneNumber() {
      return (this.user.phone ? this.user.phone.number : null)
    },

    originalPhoneExtension() {
      return (this.user.phone ? this.user.phone.extension : null)
    },

    canSavePhone() {

      const phoneNumberChanged = this.originalPhoneNumber != this.phoneNumber
      const phoneExtensionChanged = this.originalPhoneExtension != this.phoneExtension

      return phoneNumberChanged || phoneExtensionChanged
    },

    checkboxBackgroundClasses() {
      return {
        'switch-active': this.isConsented,
        'switch-inactive': !this.isConsented,
      }
    },

    checkboxForegroundStyle() {
      return { transform: this.isConsented ? 'translateX(14px)' : 'translateX(0)' }
    },

  },

  mounted() {
    this.aulaToken = this.getAulaToken()
  },

  watch: {
    aulaToken: function() {
      if (this.aulaToken && Object.keys(this.aulaToken).length > 0) {
        if (!this.collapsed) {
          this.performFirstLoad()
        }
      }
    },
  },

  methods: {

    async initRender() {

    },

    async performFirstLoad() {

      this.isLoading = true

      this.axios.defaults.baseURL = BASE_URL
      this.axios.defaults.headers.common['x-client'] = X_CLIENT

      if (this.shouldReloadToken()) {
        const authResponse = await this.axios.post(`/v2/auth/aula`, { 'jwt_token': this.aulaToken })
        localStorage.setItem(LOCAL_STORAGE_SUBIT_TOKEN, authResponse.data.data.access_token)
        localStorage.setItem(LOCAL_STORAGE_SUBIT_TOKEN_EXPIRES, authResponse.data.data.expires_at)
      }

      this.axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem(LOCAL_STORAGE_SUBIT_TOKEN)

      await this.loadUser()

      this.isLoading = false
      this.isFirstLoad = false
    },

    shouldReloadToken() {
      try {
        const token = localStorage.getItem(LOCAL_STORAGE_SUBIT_TOKEN)
        const expire = localStorage.getItem(LOCAL_STORAGE_SUBIT_TOKEN_EXPIRES)
        return !token || new Date(expire).getTime() < new Date().getTime()
      } catch (e) {
        return true
      }
    },

    async loadUser() {

      const { data } = await this.axios.get('/v2/me')

      this.user = data.data
      this.language = TRANSLATIONS[this.user.language] ? this.user.language : DEFAULT_LANGUAGE
      this.phoneExtension = this.user.phone ? this.user.phone.extension : null
      this.phoneNumber = this.user.phone ? this.user.phone.number : null

      localStorage.setItem(LOCAL_STORAGE_SUBIT_LANGUAGE, this.language)
    },

    async saveUser(attributes) {

      this.isLoading = true

      try {

        const { data } = await this.axios.patch('v2/me', attributes)

        this.user = data.data
        this.errorOccurred = false
        this.isLoading = false
        return true

      } catch (error) {

        let handled = false
        if (error.response && error.response.data) {

          const { errors } = error.response.data
          const errorPaths = errors && errors.body && Array.isArray(errors.body)
            ? errors.body.map(error => error.path)
            : []

          if (errorPaths.includes('phone')) {
            handled = true
            this.takenPhoneNumbers.push(this.phoneNumberHash)
          }
        }

        this.errorOccurred = !handled
        this.isLoading = false
        return false
      }
    },

    onHeaderClicked() {
      this.collapsed = !this.collapsed
      if (!this.collapsed && this.isFirstLoad) {
        this.performFirstLoad()
      }
    },

    onConsentChanged() {

      if (this.isConsented) {
        this.showWithdrawConsentConfirmation = true
        return
      }

      this.saveUser({
        communication: { is_consented: true },
      })
    },

    onPhoneExtensionChanged(event) {
      this.phoneExtension = parseInt(event.target.value)
    },

    onPhoneNumberChanged(event) {
      this.phoneNumber = event.target.value.length > 0 ? event.target.value : null
    },

    onSavePhone() {
      this.saveUser({
        phone: {
          number: this.phoneNumber,
          extension: this.phoneExtension,
        },
      })
    },

    onWithdrawConsentCancel() {
      this.showWithdrawConsentConfirmation = false
      this.errorOccurred = false
    },

    async onWithdrawConsentConfirm() {
      const success = await this.saveUser({
        communication: { is_consented: false },
      })

      if (success) {
        this.showWithdrawConsentConfirmation = false
      }
    },
  },
}

const TRANSLATIONS = {
  'da': {
    title: 'SMS samtykke',
    descriptionHeader: 'Samtykke til at modtage sms-beskeder',
    descriptionText: 'Med dit samtykke kan skoler sende sms-beskeder med generel information omkring skolen, ændringer i undervisningen, eller kontakte dig i nødstilfælde.',
    phoneNumberHeader: 'Telefon',
    phoneNumberTaken: 'Nummeret er allerede registreret til en anden bruger',
    save: 'Gem',
    errorOccurred: 'Der skete en fejl',
    withdrawConsent: {
      message: 'Er du sikker på, at du ønsker at fjerne dit samtykke til at modtage sms-beskeder? Uden dit samtykke går du måske glip af vigtig information.',
      cancel: 'Tilbage',
      confirm: 'Fjern samtykke',
    },
  },
  'en': {
    title: 'SMS consent',
    descriptionHeader: 'Consent to receiving text messages',
    descriptionText: 'With your consent, schools can send you text messages with general information about the school or schedule changes or in cases of emergency.',
    phoneNumberHeader: 'Phone number',
    phoneNumberTaken: 'That phone number is registered to another user',
    save: 'Save',
    errorOccurred: 'An error occurred',
    withdrawConsent: {
      message: 'Are you sure you want to withdraw your consent? Without your consent you may miss important announcements and information.',
      cancel: 'Cancel',
      confirm: 'Withdraw consent',
    },
  },
}

</script>
<template>
  <div class="container">
    <div class="header" @click='onHeaderClicked' v-bind:class='{collapsed: collapsed}'>
      <svg class='header-icon' viewBox='0 0 32 32'>
        <path fill='#fff' xmlns="http://www.w3.org/2000/svg"
              d="M0.667 0v32l10.24-9.333h13.093c4.050 0 7.333-3.283 7.333-7.333v0-15.333h-30.667zM30 15.333c0 3.314-2.686 6-6 6v0h-13.587l-8.413 7.88v-27.88h28v14zM10 11.333c0 1.105-0.895 2-2 2s-2-0.895-2-2c0-1.105 0.895-2 2-2s2 0.895 2 2zM18 11.333c0 1.105-0.895 2-2 2s-2-0.895-2-2c0-1.105 0.895-2 2-2s2 0.895 2 2zM26 11.333c0 1.105-0.895 2-2 2s-2-0.895-2-2c0-1.105 0.895-2 2-2s2 0.895 2 2z" />
      </svg>
      <div style="display: flex; margin-left: 10px">
        <p class="title">{{ translations.title }}</p>
      </div>
      <div class='header-expand-icon'>
        <svg v-if='collapsed' xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#ffffff" class="bi bi-chevron-down" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
        </svg>
        <svg v-if='!collapsed' xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#ffffff" class="bi bi-chevron-up" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z" />
        </svg>
      </div>
    </div>
    <div class='body' v-if='!collapsed'>
      <div class="loading-container" v-if="isLoading">
        <img class='loading-spinner' src='https://subit-asset.s3.eu-north-1.amazonaws.com/loading-spinner.gif' alt='loading'>
      </div>
      <div class='main-content' v-if='!isLoading'>
        <div v-if='showWithdrawConsentConfirmation'>
          <div class='withdraw-consent-container'>
            <p class='withdraw-consent-message'>{{ translations.withdrawConsent.message }}</p>
            <div class='withdraw-consent-button-container'>
              <button
                class='withdraw-consent-button withdraw-consent-button-cancel'
                @click='onWithdrawConsentCancel'>
                {{ translations.withdrawConsent.cancel }}
              </button>
              <button
                class='withdraw-consent-button withdraw-consent-button-confirm'
                @click='onWithdrawConsentConfirm'
              >
                {{ translations.withdrawConsent.confirm }}
              </button>
            </div>
          </div>
        </div>
        <div v-if='!showWithdrawConsentConfirmation'>
          <div class='subtitle-container'>
            <p class='subtitle'>{{ translations.descriptionHeader }}</p>
            <div>
    						<span
                  class="toggle-wrapper"
                  role="checkbox"
                  :aria-checked="isConsented.toString()"
                  tabindex="0"
                  @click="onConsentChanged"
                  @keydown.space.prevent="onConsentChanged"
                >
      						<span class="toggle-background" :class="checkboxBackgroundClasses" />
      						<span class="toggle-indicator" :style="checkboxForegroundStyle" /></span>
            </div>
          </div>
          <div>
            <p class='text'>{{ translations.descriptionText }}</p>
          </div>
          <div v-if='isConsented'>
            <p class='subtitle subtitle-phone'><span class='required'>*</span> {{ translations.phoneNumberHeader }}</p>
            <div class='phone-form-container'>
              <div class='phone-input-container'>
                <select
                  class='phone-extension-input'
                  name='phone-extension'
                  @change='onPhoneExtensionChanged'
                >
                  <option :selected='phoneExtension === 45' value='45'>+45</option>
                  <option :selected='phoneExtension === 46' value='46'>+46</option>
                  <option :selected='phoneExtension === 298' value='298'>+298</option>
                </select>
                <input
                  type='tel'
                  name='phone-number'
                  class='phone-number-input'
                  :value='phoneNumber'
                  @input='onPhoneNumberChanged'
                />
              </div>
              <button
                :disabled='!canSavePhone || !isPhoneValid'
                class='phone-save-button'
                @click='onSavePhone'
              >
                {{ translations.save }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div v-if='!isLoading'>
        <p v-if='isPhoneTaken' class='error-message'>{{ translations.phoneNumberTaken }}</p>
        <p v-if='errorOccurred' class='error-message'>{{ translations.errorOccurred }}</p>
      </div>
      <div class="widget-footer">
        <div class="footer-item">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" class="footer-item-icon">
            <title>mail</title>
            <path
              d="M0 4v18c0 3.314 2.686 6 6 6v0h20c3.314 0 6-2.686 6-6v0-18h-32zM30.667 22c0 2.577-2.089 4.667-4.667 4.667v0h-20c-2.577 0-4.667-2.089-4.667-4.667v0-16.667h29.333v16.667zM28.32 9.113l-0.893-0.987-11.427 10.307-11.36-10.293-0.893 0.987 12.253 11.107 12.32-11.12z" />
          </svg>
          <p class="footer-item-text">support@subit.dk</p>
        </div>
        <div class="footer-item">
          <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 32 32" class="footer-item-icon">
            <title>smart-phone</title>
            <path
              d="M22 0h-12c-1.841 0-3.333 1.492-3.333 3.333v0 25.333c0 1.841 1.492 3.333 3.333 3.333v0h12c1.841 0 3.333-1.492 3.333-3.333v0-25.333c0-1.841-1.492-3.333-3.333-3.333v0zM10 1.333h12c1.105 0 2 0.895 2 2v0 22h-16v-22c0-1.105 0.895-2 2-2v0zM22 30.667h-12c-1.105 0-2-0.895-2-2v0-2h16v2c0 1.105-0.895 2-2 2v0zM17.333 28.667c0 0.736-0.597 1.333-1.333 1.333s-1.333-0.597-1.333-1.333c0-0.736 0.597-1.333 1.333-1.333s1.333 0.597 1.333 1.333z" />
          </svg>
          <p class="footer-item-text">70 60 51 02</p>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>

* {
  margin: 0;
  padding: 0;
  font-weight: normal;
  font-family: Helvetica, Arial, sans-serif;
}

input {
  min-height: initial;
  box-shadow: none;
  box-sizing: border-box;
}

.container {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: left;
}

.header {
  display: flex;
  flex-direction: row;
  background-color: #4d88ff;
  color: white;
  text-align: left;
  padding: 17px 15px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  cursor: pointer;
  align-items: center;
}

.header.collapsed {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.header-icon {
  height: 14px;
  width: 14px;
}

.header-expand-icon {
  margin-left: auto;
  color: #fff;
}

.title {
  font-size: 15px;
  color: #fff;
}

.loading-container {
  height: 170px;
  position: relative;
}

.loading-spinner {
  height: 40px;
  width: 40px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.main-content {
  padding: 15px 15px 18px 15px;
}

.subtitle-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  align-items: center;
}

.subtitle {
  font-size: 14px;
  width: 220px;
  color: #333;
  line-height: 20px;
}

.subtitle-phone {
  padding: 15px 0 5px 0;
}

.text {
  font-size: 12px;
  color: #7d7e7f;
  line-height: 18px;
  width: 220px;
}

.required {
  color: red;
}

.phone-form-container {
  display: flex;
}

.phone-save-button {
  background: #4d88ff;
  color: #fff;
  border: none;
  outline: none;
  border-radius: 5px;
  padding: 5px 15px;
  margin-left: 10px;
  font-size: 12px;
  cursor: pointer;
}

.phone-save-button:disabled {
  background: #898989;
  cursor: not-allowed;
}

.phone-input-container {
  border: 1px solid #cccfd4;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  height: 30px;
}

.error-message {
  font-size: 12px;
  padding: 10px 15px;
  color: #fff;
  background: #ff9999;
}

.phone-extension-input, .phone-number-input {
  border: 0;
  outline: 0;
  background: none;
  font-size: 12px;
  color: #797979;
}

.phone-extension-input {
  border-right: 1px solid #cccfd4;
  padding: 5px 10px;
  appearance: none;
  color: #000;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  width: 60px;
}

.phone-number-input {
  padding: 5px 10px;
  height: 100%;
  border-left: none;
}

.withdraw-consent-container {

}

.withdraw-consent-message {
  font-family: gt, sans-serif;
  font-size: 12px;
  color: #444;
  line-height: 18px;
}

.withdraw-consent-button-container {
  display: flex;
  margin-top: 20px;
  justify-content: flex-end;
}

.withdraw-consent-button {
  border-radius: 15px;
  padding: 5px 15px;
  font-size: 12px;
  outline: none;
  cursor: pointer;
  margin-left: 15px;
}

.withdraw-consent-button-cancel {
  background: #fff;
  border: 1px solid #DADFE3;
  color: #000;
}

.withdraw-consent-button-confirm {
  border: 1px solid #FD5061;
  background: #FD5061;
  color: #fff;
}

.widget-footer {
  height: 35px;
  background: #E1E4EC;
  display: flex;
  align-items: center;
  padding: 10px 5px;
  box-sizing: border-box;
  border-top: 1px solid #cacdd4;
}

.widget-footer .footer-item {
  margin-left: 14px;
  height: 100%;
  display: flex;
  align-items: center;
}

.widget-footer .footer-item .footer-item-icon {
  fill: #6F7785;
  height: 14px;
  margin-right: 8px;
}

.widget-footer .footer-item .footer-item-text {
  color: #6F7785;
  font-size: 12px;
}

/* Used dynamically */
/* Switch active color */
.switch-active {
  background-color: #4d88ff;
}

/* Used dynamically */
/* Switch inactive color */
.switch-inactive {
  background-color: #c2c2c2;
}

.toggle-wrapper {
  display: inline-block;
  position: relative;
  cursor: pointer;
  width: 32px;
  height: 18px;
  border-radius: 9999px;
}

.toggle-wrapper:focus {
  outline: 0;
}

.toggle-background {
  display: inline-block;
  border-radius: 9999px;
  height: 100%;
  width: 100%;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color .4s ease;
}

.toggle-indicator {
  position: absolute;
  height: 14px;
  width: 14px;
  left: 2px;
  bottom: 2px;
  background-color: #ffffff;
  border-radius: 9999px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform .4s ease;
}

</style>
