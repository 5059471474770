<template>
  <div>
    <h1>widget - testing axios</h1>
    <p> Lets load some data with Axios:
      <i class="fa fa-spinner fa-spin" v-if="loading"></i>
      <div v-if="posts && posts.length">
        <p v-for="post of posts">
          <strong>{{ post.id }} - {{post.title}}</strong>
          <br /> {{post.body}}
        </p>
      </div>
    <div>
      <p class="d-flex justify-content-between align-items-center">
        Notifikationer
        <b-btn @click="clearAllNotifications">Ryd alle</b-btn>
      </p>
      <template v-if="notifications && notifications.length > 0">
        <div v-for="notification in notifications" class="d-flex justify-content-between align-items-center" :key="notification.widgetNotificationId">
          <p class="mb-0">
            {{notification.notificationMessage}}
          </p>
          <b-btn @click="clearNotification(notification)" :disabled="removeNotificationSet.has(notification)" variant="link">
            X
          </b-btn>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    axios: Function,
    sessionUUID: String,
    placement: String,
    currentWeekNumber: String,
    isMobileApp: Boolean,
    userProfile: String,
    getAulaToken: Function,
    assuranceLevel: Number,
    childFilter: Array,
    institutionFilter: Array,
    group: Number,
    notifications: Array,
    deleteNotifications: Function,
  },
  data: function() {
    return {
      loading: true,
      posts: [],
      token: "nope",
      removeNotificationSet: new Set(),
    };
  },
  methods: {
    async clearNotification(notification) {
      this.removeNotificationSet.add(notification);
      await this.deleteNotifications([notification.widgetNotificationId]);
      this.removeNotificationSet.delete(notification);
    },
    async clearAllNotifications() {
      this.removeNotificationSet = new Set(this.notifications);
      const notificationIds = this.notifications.map(notification => notification.widgetNotificationId);
      await this.deleteNotifications(notificationIds);
      this.removeNotificationSet.clear();
    },
  },
  created: function() {
    this.axios
      .get("/static/posts.json")
      .then(response => {
        this.posts = response.data;
        this.loading = false;
      })
      .catch(e => {
        console.log(e);
      });
  }
};
</script>

<style scoped>
b:before {
  content: "";
  display: block;
}
</style>
