











export default {
  name: 'AulaSwitch',
  props: {
    value: {
      required: true,
      type: Boolean,
    },
  },
  emits: ['input', 'change'],
  setup(props, { emit }) {
    const onClick = (event: MouseEvent) => {
      emit('input', !props.value);
      emit('change', event);
    };
    return {
      onClick,
    };
  },
};
