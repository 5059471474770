import { Validator } from './validator';
import { NewSimpleEvent } from '../newSimpleEvent';

export class NewSimpleEventValidator extends Validator {
  private _isValidMaximumParticipants = true;
  private _isMaximumParticipantsHigherThanActualParticipants = true;

  constructor(simpleEventModel: NewSimpleEvent) {
    super();
    this._simpleEventModel = simpleEventModel;
  }

  private readonly _simpleEventModel: NewSimpleEvent;

  get isValidMaximumParticipants() {
    return this._isValidMaximumParticipants;
  }
  set isValidMaximumParticipants(isValidMaximumParticipants: boolean) {
    this._isValidMaximumParticipants = isValidMaximumParticipants;
  }

  get isMaximumParticipantsHigherThanActualParticipants() {
    return this._isMaximumParticipantsHigherThanActualParticipants;
  }
  set isMaximumParticipantsHigherThanActualParticipants(isMaximumParticipantsBelowActualNumberOfParticipants: boolean) {
    this._isMaximumParticipantsHigherThanActualParticipants = isMaximumParticipantsBelowActualNumberOfParticipants;
  }

  validateMaximumParticipants = () => {
    const { maximumNumberOfParticipants, maxParticipantsEnabled } = this._simpleEventModel;
    let isValid = true;
    if (maxParticipantsEnabled) {
      isValid = maximumNumberOfParticipants >= 1 && maximumNumberOfParticipants <= 2000;
    }
    this._simpleEventModel.validator.isValidMaximumParticipants = isValid;
    return isValid;
  };

  validateMaximumParticipantsBelowActualNumberOfParticipants = () => {
    const { maxParticipantsEnabled } = this._simpleEventModel;
    let isValid = true;

    if (maxParticipantsEnabled) {
      isValid = this.getIsMaximumParticipantsHigherOrEqualToActualParticipants();
    }

    this._simpleEventModel.validator.isMaximumParticipantsHigherThanActualParticipants = isValid;
    return isValid;
  };

  validate() {
    return this.validateRules([
      this.validateMaximumParticipants,
      this.validateMaximumParticipantsBelowActualNumberOfParticipants,
    ]);
  }

  private getIsMaximumParticipantsHigherOrEqualToActualParticipants() {
    const { maximumNumberOfParticipants, actualNumberOfParticipants } = this._simpleEventModel;
    let isHigher = false;

    if (actualNumberOfParticipants == null || actualNumberOfParticipants === 0) {
      isHigher = true;
    } else if (maximumNumberOfParticipants != null) {
      isHigher = maximumNumberOfParticipants >= actualNumberOfParticipants;
    }

    return isHigher;
  }
}
