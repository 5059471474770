<template>
  <div>
    <h1>Log ind i Vikarlæggeren</h1>

    <div v-if="loginFailed" style="padding-top: 1rem;">
      <b-alert
        :show="loginFailed"
        dismissible
        @dismissed="loginFailed = false"
        variant="danger"
      >
        <p>Kunne ikke logge ind i vikarlæggeren, kontakt Substy's support.</p>
      </b-alert>
    </div>

    <div style="padding-top: 1rem;">
      <b-button @click="doLogin">
        <span v-if="!isLoading">Log ind</span>
        <i v-else class="fa fa-spinner fa-spin"></i>
      </b-button>

    </div>

  </div>
</template>

<script>
module.exports = {
  props: {
    axios: Function,
    getAulaToken: Function,
    institutionFilter: Array
  },
  data: function () {
    return {
      isLoading: false,
      aulaToken: null,
      loginFailed: false
    }
  },
  mounted() {
    this.aulaToken = this.getAulaToken()
  },
  methods: {
    IsInPreview () {
      if (this.institutionFilter.indexOf('101021') > 0) return true
      if (this.institutionFilter.indexOf('185003') > 0) return true
    },
    doLogin () {
      var self = this
      this.isLoading = true
      this.loginFailed = false
      var sharedAPIUrl = 'https://production-shared-api.azurewebsites.net/v1/userLogin/AulaLogin'
      var localAPIUrl = 'http://localhost:54058/v1/userLogin/AulaLogin'


      // Use preview url if user is on preview schoo
      if (this.IsInPreview()) sharedAPIUrl = 'https://preview-shared-api.azurewebsites.net/v1/userLogin/AulaLogin'

      if (this.aulaToken != undefined) {
        this.axios({
          method: 'post',
          url: sharedAPIUrl,
          headers: { 'Content-Type': 'application/json'},
          data: '"'+this.aulaToken+'"'
        }).then(function (response) {
          console.log(response.data)

          if (response.data.accessToken) {
            // We have token, redirect to login page
            var tokens = ''
            tokens += '?accesstoken=' + response.data.accessToken
            tokens += '&refreshtoken=' + response.data.refreshToken
            tokens += '&expires=' + response.data.expires

            if (self.IsInPreview()) {
              window.open("https://preview-skole.substy.dk/login" + tokens)
            } else{
              window.open("https://skole.substy.dk/login" + tokens)
            }

          }
          self.isLoading = false
        }).catch(function (err) {
          console.error(err)
          self.isLoading = false
          self.loginFailed = true
        })

      }
    }
  }
}
</script>

<style scoped>
</style>
