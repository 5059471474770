<template>
  <div class="widget-W0021V0001">
    <div class="subit-widget-container">
      <div class="absence-header">
        <div style="display: flex">
          <div class="calendar-left" v-on:click="changeDate(-1)">
            <i class="calendar-arrow-left"></i>
          </div>
          <div class="absence-header-content cut-text">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 486 494" fill="none">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M283.314 493.388H201.759C185.411 493.388 172.109 480.534 172.109 464.737V402.928C172.109 395.722 165.832 391.256 160.021 391.256C157.785 391.256 155.646 391.869 153.657 393.068L88.7046 432.395C83.9772 435.253 78.5366 436.767 72.9817 436.767C62.6904 436.767 53.2883 431.74 47.8302 423.327L4.53476 356.541C0.326702 350.059 -1.01142 342.377 0.766876 334.917C2.53637 327.456 7.21981 321.11 13.9192 317.053L92.1556 269.687C95.7474 267.518 97.8162 263.92 97.8162 259.828C97.8162 255.728 95.7474 252.138 92.1644 249.969L13.9192 202.604C7.21981 198.538 2.53637 192.2 0.766876 184.74C-1.01142 177.279 0.326702 169.598 4.53476 163.116L47.8302 96.3382C53.2883 87.9166 62.6904 82.8891 72.9817 82.8891C78.5454 82.8891 83.9772 84.4033 88.7046 87.2616L153.665 126.588C155.646 127.787 157.785 128.4 160.021 128.4C165.832 128.4 172.109 123.934 172.109 116.729V28.6505C172.109 12.8536 185.411 0 201.759 0H283.314C299.662 0 312.964 12.8536 312.964 28.6505V116.244C312.964 120.378 314.954 123.075 316.617 124.614C320.561 128.247 326.75 128.987 331.513 126.035L396.218 85.9005C401.007 82.9232 406.545 81.3494 412.214 81.3494C422.505 81.3494 431.916 86.3769 437.366 94.7985L480.538 161.389C489.218 174.778 485.001 192.489 471.153 200.885L390.065 249.969C386.473 252.138 384.413 255.728 384.413 259.828C384.413 263.92 386.473 267.518 390.065 269.696L471.153 318.779C485.001 327.167 489.218 344.878 480.538 358.268L437.418 424.773C431.96 433.194 422.558 438.213 412.267 438.213C406.545 438.213 400.972 436.623 396.148 433.603L331.566 393.111C329.55 391.843 327.358 391.197 325.06 391.197C319.25 391.197 312.964 395.663 312.964 402.877V464.737C312.964 480.534 299.662 493.388 283.314 493.388Z"
                    fill="white" />
            </svg>
            <span>Dagens fravær</span>
            <span>{{ formatDate(selectedDate, "D. MMM") }}</span>
          </div>

          <div class="calendar-right" v-on:click="changeDate(1)">
            <i class="calendar-arrow-right"></i>
          </div>
        </div>
        <div class="absence-header-status-content">
          <div class="absence-header-status-indicator">
            <div class="absence-header-status-circle absence-item__teacher-initials--covered"></div>
            <span>Dækket</span></div>
          <div class="absence-header-status-indicator">
            <div class="absence-header-status-circle absence-item__teacher-initials--partly-covered"></div>
            Delvis dækket
          </div>
          <div class="absence-header-status-indicator">
            <div class="absence-header-status-circle "></div>
            Ikke dækket
          </div>
        </div>
      </div>

      <div class="absence-content">
        <div class="loading" v-if="loading">
          <i class="fa fa-spinner"></i>
        </div>
        <div v-if="!loading">
          <div v-for="absence in showConfirmedAbsences" :key="absence.id">
            <div class="absence-item-main">
              <div class="absence-item__teacher-initials" v-bind:class="[ getAbsenceCoverage(absence) ]"
                   v-bind:title="getTeacher(absence.teacher_id).name.full">
                <span class="absence-item__teacher-initial-text">{{ getInitials(absence.teacher_id) }}</span>
              </div>
              <div class="absence-item__absence-period" v-if="isPartially(absence)">
                <span>Delvis fravær</span>
              </div>
              <div class="absence-item__absence-period" v-if="isAllDay(absence)">
                <span>Hele dagen</span>
              </div>
              <div class="absence-item__absence-period" v-else-if="!isAllDay(absence) && !isPartially(absence)">
                <div class="absence-item__absence-period-start">{{ formatDate(absence.starts_at, "HH:mm") }}</div>
                <div class="absence-item__absence-period-spacing">-</div>
                <div class="absence-item__absence-period-end">
                  {{ absence.ends_at && formatDate(absence.ends_at, "HH:mm") }}
                </div>
              </div>
              <div class="absence-item__lessons cut-text" v-if="absence.reason_id">
                <div>{{ getAbsenceReason(absence.reason_id) }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="footer-item">
          <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 32 32" class="footer-item-icon">
            <title>mail</title>
            <path
              d="M0 4v18c0 3.314 2.686 6 6 6v0h20c3.314 0 6-2.686 6-6v0-18h-32zM30.667 22c0 2.577-2.089 4.667-4.667 4.667v0h-20c-2.577 0-4.667-2.089-4.667-4.667v0-16.667h29.333v16.667zM28.32 9.113l-0.893-0.987-11.427 10.307-11.36-10.293-0.893 0.987 12.253 11.107 12.32-11.12z" />
          </svg>
          <p class="footer-item-text">support@subit.dk</p>
        </div>
        <div class="footer-item">
          <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 32 32" class="footer-item-icon">
            <title>smart-phone</title>
            <path
              d="M22 0h-12c-1.841 0-3.333 1.492-3.333 3.333v0 25.333c0 1.841 1.492 3.333 3.333 3.333v0h12c1.841 0 3.333-1.492 3.333-3.333v0-25.333c0-1.841-1.492-3.333-3.333-3.333v0zM10 1.333h12c1.105 0 2 0.895 2 2v0 22h-16v-22c0-1.105 0.895-2 2-2v0zM22 30.667h-12c-1.105 0-2-0.895-2-2v0-2h16v2c0 1.105-0.895 2-2 2v0zM17.333 28.667c0 0.736-0.597 1.333-1.333 1.333s-1.333-0.597-1.333-1.333c0-0.736 0.597-1.333 1.333-1.333s1.333 0.597 1.333 1.333z" />
          </svg>
          <p class="footer-item-text">70 60 51 02</p>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
export default {
  props: {
    getAulaToken: Function,
    axios: Function,
    moment: Function
  },
  data() {
    return {
      loading: true,
      selectedDate: this.moment(),
      today: null,
      absences: [],
      teachers: [],
      aulaToken: null,
      absenceReasons: new Map()
    };
  },
  computed: {
    showConfirmedAbsences: function () {
      return this.absences.filter(absence => absence.is_confirmed !== false);
    }
  },
  mounted() {
    this.today = this.moment();
    this.aulaToken = this.getAulaToken();
  },
  watch: {
    aulaToken: function () {
      if (this.aulaToken != undefined && Object.keys(this.aulaToken).length > 0) {
        this.initRender();
      }
    }
  },
  methods: {
    async initRender() {
      this.axios.defaults.baseURL = "https://api.subit.dk";
      this.axios.defaults.headers.common["x-client"] = "Aula teacher-absences";

      let reloadToken = false;
      try {
        const token = localStorage.getItem("subit_token");
        const expire = localStorage.getItem("subit_token_expires");
        if (!token || new Date(expire).getTime() < new Date().getTime()) {
          reloadToken = true;
        }
      } catch (e) {
        reloadToken = true;
      }

      if (reloadToken) {
        let authResponse = await this.axios.post(`/v2/auth/aula`, {
          "jwt_token": this.aulaToken
        });

        localStorage.setItem("subit_token", authResponse.data.data.access_token);
        localStorage.setItem("subit_token_expires", authResponse.data.data.expires_at);
      }

      this.axios.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("subit_token");

      try {
        this.teachers = await this.fetchTeachers();
        if (this.teachers) {
          await this.fetchAbsenceReasons();
          this.absences = await this.fetchAbsences();
        }
      } catch (e) {
        console.log(e);
      }

      this.loading = false;
    },
    getTeacher: function (teacherId) {
      return this.teachers.find(v => v.id === teacherId);
    },
    getInitials: function (teacherId) {
      const teacher = this.getTeacher(teacherId);
      if (!teacher) return "";
      if (teacher.schools.length > 0) {
        const activeSchools = teacher.schools.filter(s => !s.is_hidden);
        return activeSchools[0]?.initials || teacher.schools[0].initials;
      }
      return teacher.name?.initials || "";
    },
    getAbsenceCoverage: function (absence) {
      if (!absence.counters) {
        return "absence-item__teacher-initials--covered";
      }

      const counters = absence.counters.find(d => d.date === this.formatDate(this.selectedDate));
      if (counters) {
        const uncovered = counters.uncovered_count - counters.cancelled_count;
        if (uncovered > 0 && uncovered == counters.total_count) {
          return "absence-item__teacher-initials--not-covered";
        }

        if (uncovered > 0) {
          return "absence-item__teacher-initials--partly-covered";
        }

        return "absence-item__teacher-initials--covered";
      }
    },
    getAbsenceReason: function (reasonId) {
      return this.absenceReasons.get(reasonId) || "Fraværende";
    },
    formatDate(d, format = "YYYY-MM-DD") {
      return this.moment(d).format(format);
    },
    fetchTeachers: async function () {
      const { data } = await this.axios.get("/v2/teachers");
      if (data) {
        return data.data;
      }
      return [];
    },
    fetchAbsenceReasons: async function () {
      const { data } = await this.axios.get(`/v2/teachers/absences/reasons`);
      if (data) {
        data.data.forEach(v => {
          this.absenceReasons.set(v.id, v.value);
        });
      }
    },
    fetchAbsences: async function () {
      this.loading = true;
      const { data } = await this.axios.get(`/v2/teachers/absences?period=` + this.formatDate(this.selectedDate));

      this.loading = false;

      if (data) {
        const filteredExemptedAbsences = data.data.filter(absence => {
          const exemptedCounter = absence.counters.find(d => d.date === this.formatDate(this.selectedDate));
          return exemptedCounter && exemptedCounter.exempted === false;
        });
        return filteredExemptedAbsences.sort((a, b) => {
          const a_initials = this.getInitials(a.teacher_id);
          const b_initials = this.getInitials(b.teacher_id);
          if (a_initials > b_initials) {
            return 1;
          }
          if (a_initials < b_initials) {
            return -1;
          }
          return 0;
        });
      }
      return [];
    },

    changeDate: async function (diff) {
      this.selectedDate = this.moment(this.selectedDate).add(diff, "days");
      this.absences = await this.fetchAbsences();
    },
    isPartially: function (absence) {
      return absence.inverted_exemptions === true && absence.exempt_periods && absence.exempt_periods.length > 0;
    },
    minutesOfDay: function (m) {
      return m.minutes() + m.hours() * 60;
    },
    isAllDay: function (absence) {
      const start = this.selectedDate.clone().set({ hour: 8, minute: 1 });
      if (!this.moment(absence.starts_at).isBefore(start)) {
        return false;
      }

      if (!absence.ends_at && !absence.inverted_exemptions && (!absence.exempt_periods || absence.exempt_periods.length == 0)) {
        return true;
      }

      const isBefore = this.selectedDate.isBefore(absence.ends_at, "date");
      if (isBefore) {
        const end = this.selectedDate.clone().set({ hour: 17, minute: 0 });
        return !absence.ends_at || this.minutesOfDay(end) < this.minutesOfDay(this.moment(absence.ends_at));
      }

      const isSame = this.selectedDate.isSame(absence.ends_at, "date");
      if (isSame) {
        const end = this.selectedDate.clone().set({ hour: 17, minute: 0 });
        return this.moment(absence.ends_at).isAfter(end, "hour");
      }

      return false;
    }
  }
};
</script>
<style lang="scss">
.widget-W0021V0001 {

  * {
    padding: 0;
    margin: 0;
    font-weight: normal;
  }

  .subit-widget-container {
    background-color: #f5f6fa;
    height: 100%;
    min-height: 300px;

    display: flex;
    flex-direction: column;
  }

  .absence-header {
    display: flex;
    flex-direction: column;
    height: 78px;
    background-color: #4d88ff;
    color: white;
    line-height: 55px;
    text-align: left;
  }

  .absence-header-content {
    margin-left: 35px;
    margin-right: 35px;
    text-align: center;
  }

  .absence-header-status-content {
    margin-left: 35px;
    margin-right: 35px;
    display: flex;
    flex-direction: row;
    height: 10px;
    overflow: hidden;
  }

  .absence-header-status-indicator {
    font-size: 10px;
    display: flex;
    align-items: center;
    margin-right: 10px;
  }

  .absence-header-status-circle {
    background-color: #EB5757;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    flex: 1;
    margin-right: 5px;
  }

  .absence-header-content span {
    padding-left: 10px;
    vertical-align: middle;
    font-weight: bold;
    font-size: 16px;
  }

  .absence-header-content svg {
    width: 12px;
  }

  .cut-text {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .absence-item-main {
    height: 55px;
    background-color: #FBFBFF;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .absence-item__teacher-initials {
    background-color: #EB5757;
    width: 34px;
    height: 34px;
    border-radius: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 17px;
    font-size: 12px;
    color: white;
    margin-left: 9px;
    margin-right: 5px;
  }

  .absence-item__teacher-initials--covered {
    background-color: #37BD6F;
    color: #ffffff;
  }

  .absence-item__teacher-initials--partly-covered {
    background-color: #F2C94C;
    color: #ffffff;
  }

  .absence-item__absence-period {
    display: flex;
    line-height: 17px;
    font-size: 12px;
    margin-right: 5px;
  }

  .absence-item__lessons {
    margin-right: 20px;
    background: lightgray;
    padding: 5px;
    font-size: 12px;
    border-radius: 4px;
    margin-left: auto;
  }

  .absence-item__absence-period-spacing {
    margin: 0 5px 0 5px;
  }

  .calendar-left, .calendar-right {
    width: 35px;
    overflow: hidden;
    position: absolute;
    height: 55px;
    box-sizing: border-box;
    transition: all ease-in-out .3s;
    z-index: 61;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .calendar-right {
    right: 0;
  }

  .calendar-left {
    left: 0;
  }

  .absence-content {
    width: 100%;
    height: 100%;
    max-height: 220px;
    overflow-y: auto;

    flex-grow: 1;
  }

  .calendar-arrow-left {
    border: solid #4f4f4f;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
  }

  .calendar-arrow-right {
    border: solid #4f4f4f;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }

  .loading {
    margin: 10px auto 0;
    text-align: center;
  }

  .footer {
    background: #F5F6FA;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 7px 0;
  }

  .footer .footer-item {
    margin-left: 14px;
    display: flex;
    align-items: center;
    padding: 0;
  }

  .footer .footer-item .footer-item-icon {
    fill: #6F7785;
    height: 14px;
    margin-right: 8px;
  }

  .footer .footer-item .footer-item-text {
    color: #6F7785;
    font-size: 12px;
  }

}
</style>
