<template>
  <div class="widget-W0120V0001">
    <div class="mb-container">
      <div v-if="isLoading" class="spinner-wrapper">
        <i class="fa fa-spinner fa-spin"></i>
        MinUddannelse Meddelelsesbog: Henter link...
      </div>

      <span v-else-if="error === 'error' || (!result.harAdgang && userProfile.toLowerCase() === 'employee')"
            class="error-message">
			MinUddannelse Meddelelsesbog: Noget gik galt med at hente link. Prøv evt. at logge ud og ind igen.
		</span>

      <form v-else-if="result.harAdgang" ref="mBForm" @submit="addAulaTokenOnSubmit" method="POST" target="_blank"
            :action="link">
        <input type="hidden" name="sessionUUID" :value="sessionUUID" />
        <input type="hidden" name="placement" :value="placement" />
        <input type="hidden" name="currentWeekNumber" :value="currentWeekNumber" />
        <input type="hidden" name="isMobileApp" :value="isMobileApp" />
        <input type="hidden" name="assuranceLevel" :value="assuranceLevel" />
        <input type="hidden" name="userProfile" :value="userProfile" />
        <input type="hidden" name="childFilter" :value="childFilter" />
        <input type="hidden" name="institutionFilter" :value="institutionFilter" />
        <input type="hidden" name="group" :value="group" />
        <input type="hidden" name="aulaToken" :value="getTempAulaToken" />
        <input type="hidden" name="widgetName" :value="widgetName" />
        <b-button class="mb-button" role="link" :title="'https://www.minuddannelse.net'" type="submit">
          <b-row class="button-logo">
            <div class="logo">
              <b-img src="https://www.minuddannelse.net/Content/Graphics/Frontpage/logo_mu.svg"
                     alt="MinUddannelse logo" />
            </div>
            <div>
              Meddelelsesbog
            </div>
          </b-row>
        </b-button>
      </form>

      <span v-else-if="!result.harAdgang" class="text-muted">
			<span v-if="userProfile.toLowerCase() === 'child'">
				MinUddannelse Meddelelsesbog: Dine lærere har ikke skrevet noget i din meddelelsesbog endnu.
			</span>
			<span v-else-if="userProfile.toLowerCase() === 'guardian' || userProfile.toLowerCase() === 'otp'">
				MinUddannelse Meddelelsesbog: Skolens personale har ikke skrevet noget i dit barns/dine børns meddelelsesbog endnu.
			</span>
		</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    "assuranceLevel": Number,
    axios: Function,
    "childFilter": [String, Array],
    "currentWeekNumber": String,
    "getAulaToken": Function,
    "group": String,
    "institutionFilter": [String, Array],
    "isMobileApp": Boolean,
    "placement": String,
    "sessionUUID": String,
    "userProfile": String
  },
  data: function () {
    return {
      aulaToken: null,
      error: "",
      isLoading: false,
      link: "https://api.minuddannelse.net/aula/redirect",
      result: {
        harAdgang: false,
        personType: ""
      },
      tempAulaToken: "",
      widgetName: "MessageBook"
    };
  },
  watch: {
    aulaToken: function () {
      if (this.aulaToken !== undefined) {
        this.validateMbLicens();
      }
    }
  },
  computed: {
    getTempAulaToken() {
      return this.tempAulaToken;
    }
  },
  methods: {
    addAulaTokenOnSubmit: function (e) {
      e.preventDefault();

      // Adding the aulaToken before submitting
      this.tempAulaToken = this.aulaToken;

      // Timeout ensures form is submittet after DOM is repaintet
      setTimeout(() => {
        // Ref can be an array because its located in a v-for
        if (Array.isArray(this.$refs.mBForm)) {
          this.$refs.mBForm[0].submit();
        } else {
          this.$refs.mBForm.submit();
        }
        // Clear the aulaToken after submitting, so it's not visible in DOM
        // Timeout ensures the form has been submittet before clearing
        setTimeout(() => {
          this.tempAulaToken = "";
        }, 500);
      }, 500);
    },
    validateMbLicens: function () {
      const params = { widgetName: this.widgetName };
      const propsToDismiss = [
        "axios",
        "getaulatoken"
      ];

      Object.keys(this.$props).forEach(prop => {
        if (!propsToDismiss.includes(prop.toLowerCase())) {
          params[prop] = this.$props[prop] ? this.$props[prop].toString() : this.$props[prop];
        }
      });

      const config = {
        method: "get",
        headers: {
          Authorization: `Bearer ${this.aulaToken}`,
          Accept: "application/json"
        },
        params,
        url: "https://api.minuddannelse.net/aula/meddelelsesbog"
      };
      this.axios(config)
        .then((result) => {
          this.error = "";
          this.result = result.data;
          this.isLoading = false;
        })
        .catch(() => {
          this.error = "error";
          this.isLoading = false;

          return;
        });
    }
  },
  created: function () {
    this.isLoading = true;
    this.aulaToken = this.getAulaToken();
  }
};
</script>

<style lang="scss">
.widget-W0120V0001 {
  .mb-container .spinner-wrapper {
    min-height: 42px;
    padding: 10px 0;
  }

  .mb-container .mb-button {
    background-color: white !important;
    border: none !important;
    border-radius: 8px !important;
    font-size: 20px;
    font-weight: 600;
    width: 100%;
    max-width: 300px;
    color: #4D4D4D !important;
  }

  .mb-container .mb-utton:focus {
    box-shadow: none !important;
  }

  .mb-container .mb-button .button-logo {
    justify-content: center;
    align-items: center;
  }

  .mb-container .mb-button .logo {
    margin-right: 8px;
  }

  .error-message {
    color: #b50050;
  }
}
</style>
