/* substitute_notes 20231017.6 PulsProd */
<template>
  <b-container
    id="widget-content"
    :class="['root', { mobile: isMobile }]"
  >
    <b-row>
      <b-col>
        <div class="widget-header">
          <h2>Vikarnoter</h2>
          <h4>Educa Personale</h4>
        </div>
      </b-col>
    </b-row>

    <b-row v-if="placement === 'NoticeBoard'">
      <b-col>Denne widget kan ikke placeres på dashboards</b-col>
    </b-row>

    <template v-else>
      <b-row v-if="showAlert">
        <b-col>
          <b-alert
            :variant="alertType"
            :show="alertCountdown"
            fade
            dismiss-label="Luk"
            @dismissed="dismissAlert"
          >
            {{ alertMessage }}
          </b-alert>
        </b-col>
      </b-row>

      <b-row>
        <b-col :cols="numberOfColsInPickers">
          <div class="input-header">Dato</div>
          <b-form-input
            id="type-date"
            v-model="selectedDate"
            type="date"
            class="form-control"
            @change="handleDateChange"
          />
        </b-col>

        <!-- Institution picker -->
        <b-col
          v-if="hasMultipleSchools"
          :cols="numberOfColsInPickers"
        >
          <div class="input-header">Institution</div>
          <b-form-select
            v-model="selectedInstitution"
            :options="schools"
            class
            @change="handleInstitutionChanged"
          />
        </b-col>

        <b-col
          v-if="hasMultiplePersonnelTypes"
          :cols="numberOfColsInPickers"
        >
          <div class="input-header">Ansættelse</div>

          <select
            v-model="selectedPersonnelType"
            name="PersonnelType"
            @change="loadActivities()"
          >
            <option
              disabled
              value="0"
            >
              Vælg ansættelse
            </option>
            <option
              v-for="personnelType in personnelTypes"
              :key="personnelType.id"
              :value="personnelType.id"
            >
              {{ personnelType.description }}
            </option>
          </select>
        </b-col>
      </b-row>

      <div class="splitter"></div>

      <b-row>
        <b-col
          ref="activityTableColRef"
          :cols="isNarrow ? 12 : 6"
        >
          <!-- ====== ACTIVITY LIST START ====== -->
          <div
            v-if="loading"
            class="loader"
          >
            <i class="fa fa-spinner fa-spin"></i>
          </div>
          <div v-if="activities.length > 0">
            <div class="d-flex flex-row justify-content-end mb-2">
              <!-- Time axis -->
              <span class="mr-2">Tidsakse:</span>
              <b-input
                id="kmd-substitute-notes-timeaxis-input"
                v-model="breakDurationInput.value"
                :min="breakDurationInput.min"
                :max="breakDurationInput.max"
                class="activity-table-timeaxis-input"
                type="range"
              ></b-input>
              <b-popover
                target="kmd-substitute-notes-timeaxis-input"
                triggers="hover"
                placement="top"
              >
                {{ breakDurationInput.item().toString("human") }}
              </b-popover>
            </div>
            <div class="activity-table-container">
              <div
                ref="activityTableContainerRef"
                class="position-relative"
              >
                <table
                  :class="[
                    'activity-table table b-table table-striped',
                    { busy: loading },
                  ]"
                >
                  <tbody>
                  <tr
                    v-for="(period, periodIndex) in periods"
                    :key="periodIndex"
                  >
                    <th :class="['period-column', { narrow: isNarrow }]">
                      <p>{{ period.number }}</p>
                      <p>{{ period.description }}</p>
                    </th>

                    <td :class="'activity-column'">
                      <template v-if="periodIndex === 0">
                        <div
                          v-for="activity in activities"
                          :key="activity.id"
                        >
                          <div
                            :class="[
                                'activity',
                                'clickable',
                                {
                                  narrow: isNarrow,
                                  selected:
                                    selectedActivity &&
                                    activity.id === selectedActivity.id,
                                },
                              ]"
                            :style="{
                                height: activity.height(activityVerticalLayout),
                                top: activity.top(activityVerticalLayout),
                                left: activity.left(activityHorizontalLayout),
                                width: activity.width(activityHorizontalLayout),
                              }"
                            :title="activity.tooltip"
                            @click="selectActivity(activity.id)"
                          >
                            <div class="activity-content">
                              <div
                                :class="[
                                    'activity-description',
                                    {
                                      'without-period':
                                        !activity.showPeriod(activityTextLines),
                                    },
                                  ]"
                              >
                                  <span>{{
                                      activity.description(activityTextLines)
                                    }}</span>
                              </div>
                              <div
                                v-show="
                                    activity.showPeriod(activityTextLines)
                                  "
                                class="activity-period"
                              >
                                <span>{{ activity.periodDescription }}</span>
                              </div>
                              <div
                                v-show="activity.showNote()"
                                class="activity-note"
                              >
                                <i
                                  :title="activity.notes.tooltip()"
                                  class="fas fa-sticky-note"
                                ></i>
                              </div>
                              <div
                                v-show="
                                    activity.showAbsentee(activityTextLines)
                                  "
                                :class="[
                                    'activity-absentee',
                                    {
                                      'with-note': activity.showNote(),
                                      'with-substitution':
                                        activity.showSubstitution(
                                          activityTextLines
                                        ),
                                    },
                                  ]"
                              >
                                <span>{{ activity.absentee }}</span>
                              </div>
                              <div
                                v-show="
                                    activity.showSubstitution(activityTextLines)
                                  "
                                :class="[
                                    'activity-substitution',
                                    {
                                      'with-note':
                                        activity.showNote() &&
                                        !activity.showAbsentee(
                                          activityTextLines
                                        ),
                                      'with-absentee':
                                        activity.showAbsentee(
                                          activityTextLines
                                        ),
                                    },
                                  ]"
                              >
                                <span>{{ activity.substitution }}</span>
                              </div>
                              <div
                                v-show="
                                    activity.showClasses(activityTextLines)
                                  "
                                :class="[
                                    'activity-classes',
                                    {
                                      'with-rooms':
                                        activity.showRooms(activityTextLines),
                                    },
                                  ]"
                              >
                                <span>{{ activity.classes }}</span>
                              </div>
                              <div
                                v-show="activity.showRooms(activityTextLines)"
                                :class="[
                                    'activity-rooms',
                                    {
                                      'with-classes':
                                        activity.showClasses(activityTextLines),
                                    },
                                  ]"
                              >
                                <span>{{ activity.rooms }}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <span
            v-else-if="!loading && selectedDate && selectedPersonnelType > 0"
            class="text-muted"
          >Der er ikke registret noget aktivitet i den valgte periode.</span
          >
          <span
            v-else-if="!loading && selectedDate && !selectedPersonnelType"
            class="text-muted"
          >Der er ingen aktive ansættelser i den valgte period.</span
          >

          <!-- ====== ACTIVITY LIST END ====== -->
        </b-col>

        <b-col
          v-if="!isNarrow"
          :cols="isNarrow ? 12 : 6"
        >
          <!-- ====== NOTES START ====== -->

          <div id="notes">
            <div
              v-if="notesLoading"
              class="loader"
            >
              <i class="fa fa-spinner fa-spin"></i>
            </div>

            <div
              v-if="!loading && !notesLoading && hasSelectedActivity"
              id="notes-content"
              :class="{ narrow: isNarrow }"
            >
              <div id="notes-header">Vikarnote</div>
              <div class="notes-description">
                {{ selectedActivity.panelDescription }}
              </div>
              <div
                v-for="line in selectedActivity.panelInformation"
                :key="line"
                class="text-center"
              >
                <span>{{ line }}</span>
              </div>
              <div class="note-header">
                Vikarnote
                <span
                  v-b-tooltip.hover
                  class="info fa fa-question-circle"
                  title='Vil blive vist for vikaren på Aula i noten "note til vikar" yderligt vises noten på widgten "skolens fraværsoverblik"'
                ></span>
              </div>
              <div class="note-container">
                <div class="note-textarea">
                  <b-form-textarea
                    id="substituteNote"
                    v-model="lessonNote"
                    placeholder="Indtast note"
                    rows="3"
                  >
                    {{ lessonNote }}
                  </b-form-textarea>
                </div>
              </div>
              <div class="note-header">
                Intern note
                <span
                  v-b-tooltip.hover
                  class="info fa fa-question-circle"
                  title="Vil kun blive vist ved intern brug."
                ></span>
              </div>
              <div class="note-container">
                <div class="note-textarea">
                  <b-form-textarea
                    id="internalNote"
                    v-model="internalNote"
                    placeholder="Indtast note"
                    rows="3"
                  >
                    {{ internalNote }}
                  </b-form-textarea>
                </div>
              </div>

              <div class="note-header">
                Note til lærer
                <span
                  v-b-tooltip.hover
                  class="info fa fa-question-circle"
                  title="Denne note bliver tilgængelig for læreren for den time der vikardækkes."
                ></span>
              </div>
              <div class="note-container">
                <div class="note-textarea">
                  <b-form-textarea
                    id="substituteNote"
                    v-model="substituteNote"
                    placeholder="Indtast note til læreren"
                    rows="3"
                  >
                    {{ substituteNote }}
                  </b-form-textarea>
                </div>
              </div>

              <div class="text-right">
                <b-button
                  :class="{ fullwidth: isNarrow }"
                  type="submit"
                  variant="primary"
                  class="save-button"
                  @click="saveNotes"
                >
                  Gem
                </b-button>
              </div>
            </div>
          </div>

          <!-- ====== NOTES END ====== -->
        </b-col>
      </b-row>

      <b-modal
        ref="notes-modal"
        :title="
          selectedActivity
            ? 'Vikarnote: ' + selectedActivity.panelDescription
            : ''
        "
        hide-footer
        no-close-on-backdrop
      >
        <div id="notes">
          <div
            v-if="loading || notesLoading"
            class="loader"
          >
            <i class="fa fa-spinner fa-spin"></i>
          </div>

          <div
            v-if="!loading && !notesLoading && hasSelectedActivity"
            id="notes-content"
            :class="{ narrow: isNarrow }"
          >
            <div id="notes-header">Vikarnote</div>
            <div class="notes-description">
              {{ selectedActivity.panelDescription }}
            </div>
            <div
              v-for="line in selectedActivity.panelInformation"
              :key="line"
              class="text-center"
            >
              <span>{{ line }}</span>
            </div>
            <div class="note-header">
              Vikarnote
              <span
                v-b-tooltip.hover
                class="info fa fa-question-circle"
                title='Vil blive vist for vikaren på Aula i noten "note til vikar" yderligt vises noten på widgten "skolens fraværsoverblik"'
              ></span>
            </div>
            <div class="note-container">
              <div class="note-textarea">
                <b-form-textarea
                  id="substituteNote"
                  v-model="lessonNote"
                  placeholder="Indtast note"
                  rows="3"
                >
                  {{ lessonNote }}
                </b-form-textarea>
              </div>
            </div>
            <div class="note-header">
              Intern note
              <span
                v-b-tooltip.hover
                class="info fa fa-question-circle"
                title="Vil kun blive vist ved intern brug."
              ></span>
            </div>
            <div class="note-container">
              <div class="note-textarea">
                <b-form-textarea
                  id="internalNote"
                  v-model="internalNote"
                  placeholder="Indtast note"
                  rows="3"
                >
                  {{ internalNote }}
                </b-form-textarea>
              </div>
            </div>
            <div class="note-header">
              Note til lærer
              <span
                v-b-tooltip.hover
                class="info fa fa-question-circle"
                title="Denne note bliver tilgængelig for læreren for den time der vikardækkes."
              ></span>
            </div>
            <div class="note-container">
              <div class="note-textarea">
                <b-form-textarea
                  id="substituteNote"
                  v-model="substituteNote"
                  placeholder="Indtast note til læreren"
                  rows="3"
                >
                  {{ substituteNote }}
                </b-form-textarea>
              </div>
            </div>

            <b-button
              :class="{ fullwidth: isNarrow }"
              type="submit"
              variant="primary"
              class="save-button"
              @click="saveNotes"
            >
              Gem
            </b-button>
          </div>
        </div>
      </b-modal>
    </template>
  </b-container>
</template>
<script>
/**
 * MODULE substitute_notes\store.js
 **/
const _module_widget_store = (function widget_store() {
  let _module_exports = {};

  /** @typedef {import('../shared/date').Date} Date */

  /**
   * Id of an activity.
   * @typedef {*} ActivityId
   */

  /**
   * Identifies a date. Uses JSON format.
   * @typedef {String} DateId
   */

  /**
   * Id of a personnel type.
   * @typedef {*} PersonnelTypeId
   */

  /**
   * Code of a school.
   * @typedef {*} SchoolCode
   */

  /**
   * Notes of an activity.
   * @typedef {Object} Notes
   * @property {String} internal The internal note of the activity.
   * @property {String} lesson The lesson note of the activity.
   * @property {String} substitute The substiute note of the activity.
   */

  /**
   * A substitution of an activity.
   * @typedef {Object} Substitution
   * @property {String} description The description of the substitution.
   * @property {Boolean} isPersonal Indicates if the substitution is personal or an alternate action.
   */

  /**
   * An activity.
   * @typedef {Object} Activity
   * @property {String} [abbreviationOfSubject] The abbreviation of subject of the activity, if applicable.
   * @property {String} [absentee] The absentee of the activity, if applicable.
   * @property {String} [endLesson] The end lesson of the activity, if applicable.
   * @property {String[]} classes The classes of the activity.
   * @property {String} description The description.
   * @property {String} end The end time of the activity, in HH:MM format.
   * @property {ActivityId} id The id of the activity.
   * @property {Notes} notes The notes of the activity.
   * @property {String[]} rooms The rooms of the activity.
   * @property {String} start The start time of the activity, in HH:MM format.
   * @property {String} [startLesson] The start lesson of the activity, if applicable.
   * @property {Substitution} [substitution] The substitution of the activity, if substituted.
   */

  /**
   * A school.
   * @typedef {Object} School
   * @property {SchoolCode} code The code of the school.
   * @property {String} name The name of the school.
   */

  /**
   * A personnel type.
   * @typedef {Object} PersonnelType
   * @property {String} description The description of the personnel type.
   * @property {PersonnelType} id The id of the personnel type.
   */

  /**
   * @class
   * @classdesc Stores all data used in schools absence overview widget.
   */
  function Store(vueSet) {
    /**
     * @member {Object.<SchoolCode, Object.<DateId, Object.<PersonnelTypeId, Activity[]>>>}
     */
    this.activities = {};
    /**
     * @member {Object.<SchoolCode, PersonnelTypeId>}
     */
    this.idsOfPreselectedPersonnelTypes = {};
    /**
     * @member {Object.<SchoolCode, PersonnelType[]>}
     */
    this.personnelTypes = {};
    /**
     * @member {School[]}
     */
    this.schools = [];
    this._vueSet = vueSet;
  }
  Store.prototype = {
    /**
     * Removes all data from the store.
     */
    clear() {
      this.activities = {};
      this.personnelTypes = {};
      this.schools = [];
    },
    /**
     * Sets activities of a school and personnel type, on a date.
     * @param {SchoolCode} schoolCode The code of school of the activities.
     * @param {PersonnelTypeId} personnelType Id of the personnel type of the activities.
     * @param {Date} date The date of the activities.
     * @param {Activity[]} activities The activities.
     */
    setActivities(schoolCode, personnelType, date, activities) {
      var _this$activities$scho, _activitiesOfSchool$d;
      const activitiesOfSchool = (_this$activities$scho = this.activities[schoolCode]) !== null && _this$activities$scho !== void 0 ? _this$activities$scho : this._vueSet(this.activities, schoolCode, {});
      const activitiesOfDate = (_activitiesOfSchool$d = activitiesOfSchool[date.toJSON()]) !== null && _activitiesOfSchool$d !== void 0 ? _activitiesOfSchool$d : this._vueSet(activitiesOfSchool, date.toJSON(), {});
      this._vueSet(activitiesOfDate, personnelType, activities);
    },
    /**
     * Sets personnel type of a school
     * @param {SchoolCode} schoolCode The code of the school.
     * @param {PersonnelType[]} personnelTypes The personnel types.
     */
    setPersonnelTypes(schoolCode, personnelTypes, idOfPreselectedPersonnelType) {
      this._vueSet(this.idsOfPreselectedPersonnelTypes, schoolCode, idOfPreselectedPersonnelType);
      this._vueSet(this.personnelTypes, schoolCode, personnelTypes);
    },
    /**
     * Updates schools.
     * @param {School[]} schools The schools.
     */
    updateSchools(schools) {
      schools.forEach(school => {
        const index = this.schools.findIndex(x => x.code === school.code);
        if (index === -1) {
          this.schools.push(school);
        } else {
          this.schools.splice(index, 1, school);
        }
      });
      this.schools.sort((a, b) => a.name.localeCompare(b.name));
    },
    /**
     * Updates notes for an activity in a school.
     * @param {ActivityId} activityId The id of the activity.
     * @param {SchoolCode} schoolCode The code of the school of the activity
     * @param {String} internalNote New internal note for the activity.
     * @param {String} lessonNote New lesson note for the activity.
     * @param {String} substituteNote New substitute note for the activity.
     * @returns {Promise}
     */
    updateNotes(activityId, schoolCode, internalNote, lessonNote, substituteNote) {
      const activitiesOfSchool = this.activities[schoolCode];
      if (!activitiesOfSchool) {
        return;
      }
      for (const date of Object.keys(activitiesOfSchool)) {
        const activitiesOfDate = activitiesOfSchool[date];
        for (const personnelType of Object.keys(activitiesOfDate)) {
          const activitiesOfPersonnelType = activitiesOfDate[personnelType];
          const activity = activitiesOfPersonnelType.find(x => x.id === activityId);
          if (activity) {
            activity.notes.internal = internalNote;
            activity.notes.lesson = lessonNote;
            activity.notes.substitute = substituteNote;
            return;
          }
        }
      }
    }
  };
  _module_exports = {
    Store
  };
  return _module_exports;
})();

/**
 * MODULE substitute_notes\notes.js
 **/
const _module_widget_notes = (function widget_notes() {
  let _module_exports = {};

  function makeNote(dto) {
    const internal = dto.internal || "";
    const lesson = dto.lesson || "";
    const substitute = dto.substitute || "";
    return {
      internal: internal,
      lesson: lesson,
      substitute: substitute,
      empty() {
        return !this.internal && !this.lesson && !this.substitute;
      },
      tooltip() {
        return [["Vikarnote", this.lesson], ["Intern note", this.internal], ["Note til lærer", this.substitute]].map(x => x[0] + ": " + x[1]).join("\n");
      },
      update(newInternal, newLesson, newSubstitute) {
        this.internal = newInternal || "";
        this.lesson = newLesson || "";
        this.substitute = newSubstitute || "";
      }
    };
  }
  _module_exports = {
    makeNote
  };
  return _module_exports;
})();

/**
 * MODULE substitute_notes\activities.js
 **/
const _module_widget_activities = (function widget_activities(
  _module_widget_notes
) {
  let _module_exports = {};

  const {
    makeNote
  } = _module_widget_notes;
  function makeActivity(dto) {
    const periodDescription = period(dto.period, dto.startLesson, dto.endLesson);
    const classesDescription = sortedAndJoined(dto.classes);
    const roomsDescription = sortedAndJoined(dto.rooms);
    const panelDescription = makePanelDescription(dto.abbreviationOfSubject, dto.description, classesDescription);
    const substitutionDescription = dto.substitution ? dto.substitution.isPersonal ? "Vikar: " + dto.substitution.description : dto.substitution.description : "";
    return {
      absentee: dto.absentee ? "Vikar for: " + dto.absentee : null,
      activityId: dto.id,
      classes: classesDescription,
      description(textLines) {
        return description(dto.abbreviationOfSubject, classesDescription, dto.description, this.substitution, this.showClasses(textLines), this.showSubstitution(textLines));
      },
      id: dto.id + (dto.absentee ? " " + dto.absentee : ""),
      panelDescription,
      panelInformation: makePanelInformation(dto.absentee, periodDescription, roomsDescription, substitutionDescription),
      period: dto.period,
      periodDescription: periodDescription,
      notes: makeNote(dto.notes),
      rooms: roomsDescription,
      substitution: substitutionDescription,
      tooltip: tooltip(dto.abbreviationOfSubject, dto.absentee, classesDescription, dto.description, periodDescription, roomsDescription, dto.substitution ? dto.substitution.description : null),
      left(horizontalLayout) {
        return horizontalLayout[this.id].left;
      },
      lines(activityTextLines) {
        return activityTextLines[this.id];
      },
      height(verticalLayout) {
        return verticalLayout[this.id].height;
      },
      showAbsentee(textLines) {
        return dto.absentee && textLines[this.id] >= 4;
      },
      showClasses(textLines) {
        return classesDescription && textLines[this.id] >= 4;
      },
      showNote() {
        return !this.notes.empty();
      },
      showRooms(textLines) {
        return roomsDescription && textLines[this.id] >= 4;
      },
      showPeriod(textLines) {
        return textLines[this.id] >= 2;
      },
      showSubstitution(textLines) {
        return dto.substitution && textLines[this.id] >= 4;
      },
      top(verticalLayout) {
        return verticalLayout[this.id].top;
      },
      width(horizontalLayout) {
        return horizontalLayout[this.id].width;
      }
    };
  }
  function description(abbreviationOfSubject, classes, description, substitution, showClasses, showSubstitution) {
    return description + (abbreviationOfSubject ? " (" + abbreviationOfSubject + ")" : "") + (!showClasses && classes ? " " + classes : "") + (!showSubstitution && substitution ? " (" + substitution + ")" : "");
  }
  function period(period, startLesson, endLesson) {
    return period.toString() + lessons(startLesson, endLesson);
    function lessons(start, end) {
      if (!start || !end) {
        return "";
      }
      if (start == end) {
        return " (lek. " + start + ")";
      }
      return " (lek. " + start + " - " + end + ")";
    }
  }
  function makePanelDescription(abbreviationOfSubject, description, classes) {
    return description + (abbreviationOfSubject ? " (" + abbreviationOfSubject + ")" : "") + (classes ? " - " + classes : "");
  }
  function makePanelInformation(absentee, period, rooms, substitution) {
    const lines = [line("", period), line("Lokaler", rooms), line("", substitution), line("Vikar for", absentee)];
    return lines.filter(x => !x.empty).map(x => x.toString());
    function line(title, value) {
      return {
        empty: !value,
        toString() {
          return (title ? title + ": " : "") + value;
        }
      };
    }
  }
  function sortedAndJoined(array) {
    if (!array) {
      return "";
    }
    const copy = array.slice();
    copy.sort();
    return copy.join(", ");
  }
  function tooltip(abbreviationOfSubject, absentee, classes, description, period, rooms, substitution) {
    const lines = [line("Beskrivelse", description), line("Fag", abbreviationOfSubject), line("Klasser", classes), line("Lærer", absentee), line("Lokaler", rooms), line("Periode", period), line("Vikar", substitution)];
    return lines.filter(x => !x.empty).map(x => x.toString()).join("\n");
    function line(title, value) {
      return {
        empty: !value,
        toString() {
          return title + ": " + value;
        }
      };
    }
  }
  _module_exports = {
    makeActivity
  };
  return _module_exports;
})(_module_widget_notes);

/**
 * MODULE substitute_notes\actions.js
 **/
const _module_widget_actions = (function widget_actions() {
  let _module_exports = {};

  /** @typedef {import('./api').Api} Api */
  /** @typedef {import('./store').Store} Store */

  /**
   * Actions for the schools absence overview widget.
   * @param {Api} api The api.
   * @param {Store} store The store.
   */
  function Actions(api, store) {
    /** @member {Api} */
    this._api = api;
    /** @member {Store} */
    this._store = store;
  }
  Actions.prototype = {
    /**
     * Loads activities of a school and personnel type, on a date.
     * Skips loading if activities for the given arguments are already loaded.
     * @param {SchoolCode} schoolCode The code of school of the activities.
     * @param {PersonnelTypeId} personnelType Id of the personnel type of the activities.
     * @param {Date} date The date of the activities.
     * @returns {Promise}
     */
    loadActivities(schoolCode, personnelType, date) {
      var _this$_store$activiti, _this$_store$activiti2;
      if ((_this$_store$activiti = this._store.activities[schoolCode]) !== null && _this$_store$activiti !== void 0 && (_this$_store$activiti2 = _this$_store$activiti[date.toJSON()]) !== null && _this$_store$activiti2 !== void 0 && _this$_store$activiti2[personnelType]) {
        return Promise.resolve();
      }
      return this._api.activities(schoolCode, personnelType, date).then(activities => {
        this._store.setActivities(schoolCode, personnelType, date, activities);
      });
    },
    /**
     * Loads personnel types of a school, if not already loaded.
     * @param {SchoolCode} schoolCode The code of the school.
     * @returns {Promise}
     */
    loadPersonnelTypes(schoolCode) {
      if (this._store.personnelTypes[schoolCode]) {
        return Promise.resolve();
      }
      return this._api.personnelTypes(schoolCode).then(result => this._store.setPersonnelTypes(schoolCode, result.personnelTypes, result.idOfPreselectedPersonnelType));
    },
    /**
     * Loads school with given school codes, excluding those already loaded.
     * @param {SchoolCode} schoolCodes Codes of the schools.
     * @returns {Promise}
     */
    loadSchools(schoolCodes) {
      const existingSchoolCodes = this._store.schools.map(x => x.code);
      const missingSchoolCodes = schoolCodes.filter(schoolCode => !existingSchoolCodes.includes(schoolCode));
      if (missingSchoolCodes.length === 0) {
        return Promise.resolve();
      }
      return this._api.schools(missingSchoolCodes).then(schools => this._store.updateSchools(schools));
    },
    /**
     * Sets notes for an activity in a school.
     * @param {ActivityId} activityId The id of the activity.
     * @param {SchoolCode} schoolCode The code of the school of the activity
     * @param {String} internalNote New internal note for the activity.
     * @param {String} lessonNote New lesson note for the activity.
     * @param {String} substituteNote New substitute note for the activity.
     * @returns {Promise}
     */
    setNotes(activityId, schoolCode, internalNote, lessonNote, substituteNote) {
      return this._api.setNotes(activityId, schoolCode, internalNote, lessonNote, substituteNote).then(() => this._store.updateNotes(activityId, schoolCode, internalNote, lessonNote, substituteNote));
    }
  };
  _module_exports = {
    Actions
  };
  return _module_exports;
})();

/**
 * MODULE shared\time.js
 **/
const _module_shared_time = (function shared_time() {
  let _module_exports = {};

  /**
   * Represent a time point during a day with minute accuracy.
   * @typedef {Object} MinuteTime
   * @property {Number} hour The hour part of the time point.
   * @property {Number} minute The minute part of the time point.
   */

  /**
   * Represent a time duration with minute accuracy.
   * @typedef {Object} MinuteDuration
   * @property {Number} totalMinutes The total amount of minutes within the duration.
   */

  function makePoint(hour, minute) {
    if (hour > 23 || hour < 0) {
      throw "Hour must be between 0 and 23";
    }
    if (minute > 59 || minute < 0) {
      throw "Minute must be between 0 and 59";
    }
    hour = Math.floor(hour);
    minute = Math.floor(minute);
    return {
      hour: hour,
      minute: minute,
      addHours(hours) {
        const newHour = hour + hours;
        if (newHour > 23) {
          return makePoint(23, 59);
        }
        if (newHour < 0) {
          return makePoint(0, 0);
        }
        return makePoint(newHour, minute);
      },
      addDuration(duration) {
        const newMinutes = hour * 60 + minute + duration.totalMinutes;
        if (newMinutes < 0) {
          return makePoint(0, 0);
        }
        if (newMinutes >= 24 * 60) {
          return makePoint(23, 59);
        }
        return makePoint(Math.floor(newMinutes / 60), newMinutes % 60);
      },
      compare(other) {
        if (this.isBefore(other)) {
          return -1;
        }
        if (this.isSame(other)) {
          return 0;
        }
        return 1;
      },
      durationTo(other) {
        if (this.isAfter(other)) {
          throw "The other time point must be after or same as this time point";
        }
        if (other.hour === hour) {
          return makeDuration(other.minute - minute);
        }
        return makeDuration((other.hour - hour - 1) * 60 + (60 - minute) + other.minute);
      },
      /**
       * Formats the time point to a string.
       * Supported formats are "input".
       * The "input" format results in a string understood by input elements of type "time" ('hh:mm').
       * @param {string} format A format specification to use. The only supported value is "input".
       * @returns A string representation of the time point, according to specified format.
       * @throws {Error} Throws when format argument is none of the supported values.
       */
      format(format) {
        switch (format) {
          case "input":
            // Format used with time input elements and in json, which both expect a "00:00" format
            return padded(hour) + ":" + padded(minute);
          default:
            throw new Error('Unknown time point format "' + format + '"');
        }
      },
      isAfter(other) {
        return other.hour < hour || other.hour === hour && other.minute < minute;
      },
      isBefore(other) {
        return other.hour > hour || other.hour === hour && other.minute > minute;
      },
      isSame(other) {
        return other.hour === hour && other.minute === minute;
      },
      startOfHour() {
        return makePoint(hour, 0);
      },
      toJSON() {
        return padded(hour) + ":" + padded(minute);
      },
      toString() {
        return hour.toString() + ":" + padded(minute);
      }
    };
  }

  /**
   * Returns a minute duration from number of minutes.
   * @param {Number} minutes The number of minutes.
   * @returns {MinuteDuration} A duration that consists of the number of minutes.
   */
  function makeDuration(minutes) {
    return {
      totalMinutes: minutes,
      isSame(other) {
        return minutes === other.totalMinutes;
      },
      toString(format) {
        const hour = Math.floor(minutes / 60);
        const minute = minutes % 60;
        switch (format) {
          case "human":
          {
            const hourLabel = hour == 1 ? "time" : "timer";
            const minuteLabel = minute == 1 ? "minut" : "minutter";
            const hourPart = hour + " " + hourLabel;
            const minutePart = minute + " " + minuteLabel;
            return (hour > 0 || minute == 0 ? hourPart : "") + (minute > 0 ? " " + minutePart : "");
          }
          default:
            return hour + ":" + padded(minute);
        }
      }
    };
  }
  function padded(value) {
    return value < 10 ? "0" + value.toString() : value.toString();
  }

  /**
   * Parses a string and return a minute duration.
   * Supported format of strings is "h:mm" (more than one h digit is allowed).
   * Leading or trailing whitespace is not ignored, and needs to be trimmed.
   * @param {string} value The string to be parsed.
   * @returns {?MinuteDuration} A minute time duration if parsing was successful, null otherwise.
   * @throws {string} Throws when parsed hour or minute values are out of bounds.
   */
  function parseDuration(value) {
    const result = value.match(/(\d+):(\d\d)/u);
    if (result && result[0] === value) {
      const [, hoursPart, minutePart] = result;
      const minute = Number.parseInt(minutePart, 10);
      if (minute > 59 || minute < 0) {
        throw "Minute must be between 0 and 59";
      }
      const hours = Number.parseInt(hoursPart, 10);
      if (hours < 0) {
        throw "Hours must be greater than or equal to 0";
      }
      return makeDuration(hours * 60 + minute);
    } else {
      return null;
    }
  }

  /**
   * Parses a string and return a time point.
   * Supported formats of strings are "hh:mm" and "h:mm".
   * Leading or trailing whitespace is not ignored, and needs to be trimmed.
   * @param {string} value The string to be parsed.
   * @returns {?MinuteTime} A time point if parsing was successful, null otherwise.
   * @throws {string} Throws when parsed hour or minute values are out of bounds.
   */
  function parsePoint(value) {
    const result = value.match(/(\d?\d):(\d\d)/u);
    if (result && result[0] === value) {
      const [, hour, minute] = result;
      return makePoint(Number.parseInt(hour, 10), Number.parseInt(minute, 10));
    } else {
      return null;
    }
  }
  _module_exports = {
    parsePoint,
    point: makePoint,
    duration: makeDuration,
    parseDuration
  };
  return _module_exports;
})();

/**
 * MODULE shared\settingsStores.js
 **/
const _module_shared_settingsStores = (function shared_settingsStores() {
  let _module_exports = {};

  _module_exports = {
    browserSession(widgetName, scope, sessionUUID, expireAfterInSecs) {
      const key = "kmd-" + widgetName + "-widget-" + scope + "-settings";
      return {
        read() {
          try {
            const serializedSettings = window.sessionStorage.getItem(key);
            if (serializedSettings === null) return null;
            const {
              value,
              writtenAt,
              sessionUUID: storedSessionUUID
            } = JSON.parse(serializedSettings);
            if (storedSessionUUID !== sessionUUID) {
              return null;
            }
            if (expireAfterInSecs === 0 || expireAfterInSecs && Date.now() - new Date(writtenAt) > expireAfterInSecs) {
              return null;
            }
            return value || {};
          } catch (_) {
            return null;
          }
        },
        write(value) {
          try {
            window.sessionStorage.setItem(key, JSON.stringify({
              value,
              writtenAt: new Date().toJSON(),
              sessionUUID
            }));
          } catch (_) {
            return;
          }
        }
      };
    },
    database(aulaToken, axios, widgetName, isNoticeBoard, institutionCode) {
      return {
        read() {
          const params = {
            widgetName
          };
          if (isNoticeBoard && institutionCode) {
            params.institutionCode = institutionCode;
          }
          return axios.get("https://personale.api.kmd.dk/aula/api/v2/settings", {
            headers: {
              Authorization: aulaToken
            },
            params: params
          }).then(x => x.data, () => null);
        },
        write(value) {
          const params = {
            widgetName
          };
          if (isNoticeBoard && institutionCode) {
            params.institutionCode = institutionCode;
          }
          return axios.post("https://personale.api.kmd.dk/aula/api/v2/settings", value, {
            headers: {
              Authorization: aulaToken
            },
            params: params
          });
        }
      };
    }
  };
  return _module_exports;
})();

/**
 * MODULE shared\rangeInput.js
 **/
const _module_shared_rangeInput = (function shared_rangeInput() {
  let _module_exports = {};

  _module_exports = {
    selectingItem({
                    items,
                    initialItem,
                    itemCompare
                  }) {
      if (!items.length) {
        throw "Items array cannot be empty";
      }
      return {
        min: 0,
        max: items.length - 1,
        value: initialValue(),
        item() {
          return items[Math.max(0, Math.min(this.value, items.length - 1))];
        }
      };
      function initialValue() {
        const compare = itemCompare || ((a, b) => a === b);
        const value = items.findIndex(x => compare(x, initialItem));
        return Math.max(0, value);
      }
    }
  };
  return _module_exports;
})();

/**
 * MODULE shared\prelude.js
 **/
const _module_shared_prelude = (function shared_prelude() {
  let _module_exports = {};

  /** @constructor */
  function Canceled() {}
  Canceled.prototype.toString = function () {
    return "Canceled";
  };
  const IgnoreCanceledMixin = {
    errorCaptured(error) {
      if (isCanceled(error)) {
        return false;
      }
      return true;
    }
  };
  function areArraysEqual(first, second, elementsEqual) {
    var _elementsEqual;
    elementsEqual = (_elementsEqual = elementsEqual) !== null && _elementsEqual !== void 0 ? _elementsEqual : strictEqual;
    if (first.length !== second.length) {
      return false;
    }
    for (let i = 0; i < first.length; ++i) {
      if (!elementsEqual(first[i], second[i])) {
        return false;
      }
    }
    return true;
  }
  function areSetsEqual(first, second, elementsEqual) {
    var _elementsEqual2, _first$length, _second$length;
    elementsEqual = (_elementsEqual2 = elementsEqual) !== null && _elementsEqual2 !== void 0 ? _elementsEqual2 : strictEqual;
    if (((_first$length = first.length) !== null && _first$length !== void 0 ? _first$length : first.size) !== ((_second$length = second.length) !== null && _second$length !== void 0 ? _second$length : second.size)) {
      return false;
    }
    const notVisited = [...second];
    for (const element of first) {
      const index = notVisited.findIndex(x => elementsEqual(x, element));
      if (index === -1) {
        return false;
      }
      notVisited.splice(index, 1);
    }
    return notVisited.length === 0;
  }
  function canceled() {
    return new Canceled();
  }
  const escapedHtmlCharacters = /[&"'<>]/gu;
  function escapeHtml(string) {
    var _string;
    string = (_string = string) === null || _string === void 0 ? void 0 : _string.toString();
    return string && string.replace(escapedHtmlCharacters, x => {
      switch (x) {
        case "&":
          return "&amp;";
        case '"':
          return "&quot;";
        case "'":
          return "&#39;";
        case "<":
          return "&lt;";
        case ">":
          return "&gt;";
      }
    });
  }
  const regExpCharacters = /[\\^$.*+?()[\]{}|]/gu;
  const regExpCharactersTest = new RegExp(regExpCharacters.source, "u");
  function escapeRegExp(string) {
    var _string2;
    string = (_string2 = string) === null || _string2 === void 0 ? void 0 : _string2.toString();
    return string && regExpCharactersTest.test(string) ? string.replace(regExpCharacters, "\\$&") : string;
  }
  function isCanceled(value) {
    return value instanceof Canceled;
  }
  function isPromise(value) {
    return value && isFunction(value.then);
  }
  function isFunction(value) {
    return value && typeof value == "function";
  }
  function isString(value) {
    return Object.prototype.toString.call(value) === "[object String]";
  }
  function strictEqual(a, b) {
    return a === b;
  }
  function toPromise(value, thisArg, ...args) {
    return isFunction(value) ? new Promise(resolve => resolve(value.apply(thisArg, args))) : Promise.resolve(value);
  }
  _module_exports = {
    areArraysEqual,
    areSetsEqual,
    canceled,
    escapeHtml,
    escapeRegExp,
    IgnoreCanceledMixin,
    isCanceled,
    isPromise,
    isFunction,
    isString,
    strictEqual,
    toPromise
  };
  return _module_exports;
})();

/**
 * MODULE shared\functions.js
 **/
const _module_shared_functions = (function shared_functions(
  _module_shared_prelude
) {
  let _module_exports = {};

  const {
    canceled,
    isString,
    toPromise
  } = _module_shared_prelude;
  _module_exports = {
    combine(inner, outer) {
      return x => {
        const resultOfInner = inner(x);
        const resultOfOuter = outer(resultOfInner);
        return resultOfOuter;
      };
    },
    debounce(callback, delay) {
      let latestReject;
      let timeoutId;
      return function () {
        const result = new Promise((resolve, reject) => {
          var _latestReject;
          (_latestReject = latestReject) === null || _latestReject === void 0 ? void 0 : _latestReject(canceled());
          latestReject = reject;
          clearTimeout(timeoutId);
          timeoutId = setTimeout(() => {
            toPromise(callback, this, ...arguments).then(x => {
              latestReject = undefined;
              timeoutId = undefined;
              resolve(x);
            }, x => {
              latestReject = undefined;
              timeoutId = undefined;
              reject(x);
            });
          }, delay);
        });
        return result;
      };
    },
    /**
     * Transforms a function so that only its latest invocation yields a usable value.
     * When the transformed function is called, it in turns calls the function to be transformed,
     * with the arguments passed to itself, then returns a promise.
     * That promise is resolved to the result of the call,
     * if the transformed function is not called again before that happens.
     * Otherwise, the promise is resolved to an object representing cancellation.
     * @param {Function} callback The function to transform.
     * @returns {Function} The transformed function.
     */
    last(callback) {
      const version = function () {
        let current = 0;
        return {
          is(expected) {
            return expected === current;
          },
          increment() {
            ++current;
            return current;
          }
        };
      }();
      return function () {
        return new Promise((resolve, reject) => {
          const thisVersion = version.increment();
          toPromise(callback, this, ...arguments).then(x => {
            if (version.is(thisVersion)) {
              resolve(x);
            } else {
              reject(canceled());
            }
          }, x => {
            if (version.is(thisVersion)) {
              reject(x);
            } else {
              reject(canceled());
            }
          });
        });
      };
    },
    /**
     * Transform a function so that it can only be called once.
     * First call to the transformed function invokes the function to be transformed,
     * and returns its result.
     * Subsequent calls to the transformed function do not invoke the function to be transformed,
     * and return undefined.
     * @param {Function} callback The function to transform.
     * @returns {Function} The transformed function.
     */
    once(callback) {
      let wasCalled = false;
      return function () {
        if (wasCalled) {
          return;
        } else {
          wasCalled = true;
        }
        return callback.apply(this, arguments);
      };
    },
    /**
     * Transforms a function so that focus is moved to one of target elements after the function returns.
     * In case the function returns a promise, the focus is moved when it is settled.
     * Resolving of target elements and actual moving of focus is performed in next "tick",
     * to allow for queued DOM changes to happen, as they might affect resolve of the target elements.
     * The focus is moved to each target element, in ascending order within the array,
     * until an element is successfully found and becomes the active element when focused.
     * @param {Function} callback The function to transform.
     * @param {Array<String|Object>|String|Object} targetElements The element(s) to move focus to after the function to transform returns.
     * An element can be a DOM element, a CSS selector, or the value "activeElement",
     * which resolves to the active element before the transformed function is called.
     * @returns {Function} The transformed function.
     */
    withFocusRestore(callback, targetElements) {
      return function () {
        const activeElement = document.activeElement;
        return toPromise(callback.apply(this, arguments)).then(x => {
          setTimeout(() => setFocus(targetElements, activeElement), 0);
          return x;
        }, x => {
          setTimeout(() => setFocus(targetElements, activeElement), 0);
          return Promise.reject(x);
        });
      };
      function setFocus(targetElements, activeElement) {
        targetElements = Array.isArray(targetElements) ? targetElements : [targetElements];
        for (const targetElement of targetElements) {
          const element = targetElement === "activeElement" ? activeElement : isString(targetElement) ? document.querySelector(targetElement) : targetElement;
          if (element) {
            element.focus();
            if (element === document.activeElement) {
              break;
            }
          }
        }
      }
    },
    retry(callback, count, delay) {
      return function () {
        let retriesCount = 0;
        const errors = [];
        const result = new Promise((resolve, reject) => {
          invoke.apply(this, arguments);
          function invoke() {
            toPromise(callback, this, ...arguments).then(resolve, x => {
              errors.push(x);
              if (retriesCount < count) {
                ++retriesCount;
                setTimeout(() => invoke.apply(this, arguments), delay || 0);
              } else {
                reject(errors);
              }
            });
          }
        });
        return result;
      };
    },
    throttle(callback, delay) {
      let isScheduledOrRunning = false;
      const context = {};
      return function () {
        context.arguments = arguments;
        context.this = this;
        if (isScheduledOrRunning) return Promise.resolve(null);
        isScheduledOrRunning = true;
        const result = new Promise((resolve, reject) => {
          setTimeout(() => {
            const {
              this: thisArg,
              arguments: args
            } = context;
            context.this = undefined;
            context.arguments = undefined;
            toPromise(callback, thisArg, ...args).then(x => {
              resolve(x);
              isScheduledOrRunning = false;
            }, x => {
              reject(x);
              isScheduledOrRunning = false;
            });
          }, delay);
        });
        return result;
      };
    }
  };
  return _module_exports;
})(_module_shared_prelude);

/**
 * MODULE shared\settings.js
 **/
const _module_shared_settings = (function shared_settings(
  _module_shared_functions,
  _module_shared_prelude,
  _module_shared_settingsStores
) {
  let _module_exports = {};

  const functions = _module_shared_functions;
  const {
    isFunction
  } = _module_shared_prelude;
  const stores = _module_shared_settingsStores;
  _module_exports = function (axios, aulaToken, sessionUUID, isNoticeBoard, institutionCode, widgetName) {
    const browserSessionStore = stores.browserSession(widgetName, "session", sessionUUID);
    const databaseStore = stores.database(aulaToken, axios, widgetName, isNoticeBoard, institutionCode);
    const databaseCacheStore = stores.browserSession(widgetName, "database", sessionUUID, 8 * 60 * 60 * 1000);
    return new Promise(resolve => {
      const browserSessionSettings = browserSessionStore.read() || {};
      const databaseSettings = databaseCacheStore.read();
      if (databaseSettings !== null) {
        resolve({
          browserSessionSettings,
          databaseSettings
        });
        return;
      }
      databaseStore.read().then(x => {
        return x || {};
      }, () => {
        return {};
      }).then(x => {
        databaseCacheStore.write(x);
        resolve({
          browserSessionSettings,
          databaseSettings: x
        });
      });
    }).then(function ({
                        browserSessionSettings,
                        databaseSettings
                      }) {
      const browserSessionStoreWrite = functions.throttle(() => browserSessionStore.write(browserSessionSettings), 1000);
      const databaseStoreWrite = functions.combine(x => functions.retry(x, 5, 5000), x => functions.throttle(x, 10000))(() => databaseStore.write(databaseSettings));
      const databaseCacheStoreWrite = functions.throttle(() => databaseCacheStore.write(databaseSettings), 1000);
      function get(key, fallbackValue, validateValue, settings) {
        const value = settings[key];
        if (value === undefined || (validateValue || (() => true))(value) === false) {
          return isFunction(fallbackValue) ? fallbackValue() : fallbackValue;
        }
        return value;
      }
      function set(args, settings, storeWrites) {
        if (args.length === 2) {
          const [key, value] = args;
          settings[key] = value;
        } else if (args.length === 1) {
          const [keysAndValues] = args;
          keysAndValues.forEach(({
                                   key,
                                   value
                                 }) => settings[key] = value);
        } else {
          return;
        }
        storeWrites.forEach(x => x());
      }
      return {
        getLocal(key, fallbackValue, validateValue) {
          return get(key, fallbackValue, validateValue, browserSessionSettings);
        },
        getPersistent(key, fallbackValue, validateValue) {
          return get(key, fallbackValue, validateValue, databaseSettings);
        },
        setLocal(...args) {
          set(args, browserSessionSettings, [browserSessionStoreWrite]);
        },
        setPersistent(...args) {
          set(args, databaseSettings, [databaseCacheStoreWrite, databaseStoreWrite]);
        }
      };
    });
  };
  return _module_exports;
})(_module_shared_functions,
  _module_shared_prelude,
  _module_shared_settingsStores);

/**
 * MODULE shared\intervals.js
 **/
const _module_shared_intervals = (function shared_intervals() {
  let _module_exports = {};

  /**
   * Represents an interval between two points,
   * including the start point, and excluding the end point.
   * @typedef {Object} Interval
   * @property {*} start The start point of the interval.
   * @property {*} end The end point of the interval.
   * @property {Boolean} empty Indicates whether the interval is empty, always false.
   */

  /**
   * Represents an empty interval.
   * @typedef {Object} EmptyInterval
   * @property {Boolean} empty Indicates whether the interval is empty, always true.
   */

  /**
   * @returns {Interval|EmptyInterval}
   */
  function makeNonEmptyInterval(start, end) {
    if (end.isBefore(start)) {
      throw "End must be after start";
    }
    if (start.isSame(end)) {
      return makeEmptyInterval();
    }
    return {
      start: start,
      end: end,
      empty: false,
      contains(point) {
        return end.isAfter(point) && !start.isAfter(point);
      },
      duration() {
        return start.durationTo(end);
      },
      intersect(other) {
        if (!this.intersectsWith(other)) {
          return makeEmptyInterval();
        }
        var startToOtherStart = start.compare(other.start);
        var endToOtherEnd = end.compare(other.end);
        return makeNonEmptyInterval(startToOtherStart > 0 ? start : other.start, endToOtherEnd < 0 ? end : other.end);
      },
      intersectsWith(other) {
        if (other.empty) {
          return false;
        }
        return start.isBefore(other.end) && end.isAfter(other.start);
      },
      isSame(other) {
        if (other.empty) {
          return false;
        }
        return start.isSame(other.start) && end.isSame(other.end);
      },
      *iterate(nextFn) {
        let current = this.start;
        do {
          yield current;
          current = nextFn(current);
        } while (current.isBefore(this.end));
      },
      map(fnOrStartFn, endFn) {
        const newStart = fnOrStartFn(start);
        const newEnd = (endFn !== null && endFn !== void 0 ? endFn : fnOrStartFn)(end);
        if (newEnd.isBefore(newStart)) {
          return makeEmptyInterval();
        }
        return makeNonEmptyInterval(newStart, newEnd);
      },
      span(other) {
        if (other.empty) {
          return this;
        }
        return makeNonEmptyInterval(other.start.isBefore(start) ? other.start : start, other.end.isBefore(end) ? end : other.end);
      },
      split(endFromStart) {
        let rest = this;
        let result = [];
        do {
          const newEnd = endFromStart(rest.start);
          if (!newEnd.isBefore(rest.end) || !newEnd.isAfter(rest.start)) {
            result.push(rest);
            return makeIntervalSum(result);
          }
          result.push(makeNonEmptyInterval(rest.start, newEnd));
          rest = makeNonEmptyInterval(newEnd, rest.end);
        } while (!rest.empty);
        return makeIntervalSum(result);
      },
      subtract(other) {
        if (other.empty) {
          return makeIntervalSum([this]);
        }
        const startToOtherEnd = start.compare(other.end);
        if (startToOtherEnd >= 0) {
          return makeIntervalSum([this]);
        }
        const endToOtherStart = end.compare(other.start);
        if (endToOtherStart <= 0) {
          return makeIntervalSum([this]);
        }
        const startToOtherStart = start.compare(other.start);
        const endToOtherEnd = end.compare(other.end);
        if (startToOtherStart > 0) {
          if (endToOtherEnd <= 0) {
            return makeEmptyIntervalSum();
          } else {
            return makeIntervalSum([makeNonEmptyInterval(other.end, end)]);
          }
        } else if (startToOtherStart == 0) {
          if (endToOtherEnd > 0) {
            return makeIntervalSum([makeNonEmptyInterval(other.end, end)]);
          } else {
            return makeEmptyIntervalSum();
          }
        } else {
          if (endToOtherEnd > 0) {
            return makeIntervalSum([makeNonEmptyInterval(start, other.start), makeNonEmptyInterval(other.end, end)]);
          } else {
            return makeIntervalSum([makeNonEmptyInterval(start, other.start)]);
          }
        }
      },
      subtractSum(other) {
        if (other.empty) {
          return makeIntervalSum([this]);
        }
        return other.periods.map(x => this.subtract(x)).reduce((a, b) => a.intersect(b));
      },
      toString() {
        return start.toString() + " - " + end.toString();
      }
    };
  }

  /**
   * @returns {EmptyInterval}
   */
  function makeEmptyInterval() {
    return {
      empty: true,
      contains() {
        return false;
      },
      intersect() {
        return this;
      },
      intersectsWith() {
        return false;
      },
      isSame(other) {
        return other.empty;
      },
      *iterate() {},
      map() {
        return this;
      },
      span(other) {
        return other;
      },
      split() {
        return this;
      },
      subtract() {
        return this;
      },
      subtractSum() {
        return makeEmptyIntervalSum();
      },
      toString() {
        return "";
      }
    };
  }
  function makeIntervalSum(periods) {
    periods = periods.filter(x => !x.empty);
    if (periods.length === 0) {
      return makeEmptyIntervalSum();
    }
    periods = unionIntersecting(periods);
    periods.sort((a, b) => a.start.compare(b.start));
    return {
      empty: false,
      periods: periods,
      contains(point) {
        return periods.some(period => period.contains(point));
      },
      intersect(other) {
        return makeIntervalSum(periods.map(x => other.periods.map(y => x.intersect(y))).reduce((a, b) => a.concat(b), []));
      },
      intersectsWith(other) {
        return periods.some(x => other.periods.some(y => x.intersectsWith(y)));
      },
      joinAdjacent(predicate) {
        if (periods.length === 0) {
          return makeEmptyIntervalSum();
        }
        const {
          result
        } = periods.reduce((state, current) => {
          if (state.result.length === 0) {
            return {
              result: [current],
              lastJoinable: predicate(current)
            };
          } else {
            const last = state.result[state.result.length - 1];
            const currentJoinable = predicate(current);
            if (last.end.isSame(current.start) && state.lastJoinable && currentJoinable) {
              const joined = makeNonEmptyInterval(last.start, current.end);
              state.result[state.result.length - 1] = joined;
              return {
                result: state.result,
                lastJoinable: true
              };
            } else {
              state.result.push(current);
              return {
                result: state.result,
                lastJoinable: currentJoinable
              };
            }
          }
        }, {
          result: []
        });
        return makeIntervalSum(result);
      },
      split(endFromStart) {
        return makeIntervalSum(periods.map(x => x.split(endFromStart).periods).reduce((a, b) => a.concat(b), []));
      },
      toString() {
        return periods.map(x => x.toString()).join(", ");
      },
      union(other) {
        return other.empty ? this : makeIntervalSum([...periods, ...other.periods]);
      }
    };
    function unionIntersecting(periods) {
      return periods.reduce((a, b) => union(a, b), []);
      function union(periods, period) {
        const [intersecting, nonIntersecting] = partition(periods, x => x.intersectsWith(period));
        const merged = intersecting.reduce((a, b) => a.span(b), period);
        return [merged, ...nonIntersecting];
      }
      function partition(array, predicate) {
        return array.reduce(([trueElements, falseElements], element) => {
          const target = predicate(element) ? trueElements : falseElements;
          target.push(element);
          return [trueElements, falseElements];
        }, [[], []]);
      }
    }
  }
  function makeEmptyIntervalSum() {
    return {
      empty: true,
      periods: [],
      contains() {
        return false;
      },
      intersect() {
        return this;
      },
      intersectsWith() {
        return false;
      },
      joinAdjacent() {
        return this;
      },
      split() {
        return this;
      },
      toString() {
        return "";
      },
      union(other) {
        return other;
      }
    };
  }
  _module_exports = {
    empty: makeEmptyInterval,
    nonEmpty: makeNonEmptyInterval,
    sum: makeIntervalSum
  };
  return _module_exports;
})();

/**
 * MODULE shared\timelineLayout.js
 **/
const _module_shared_timelineLayout = (function shared_timelineLayout(
  _module_shared_intervals
) {
  let _module_exports = {};

  const intervals = _module_shared_intervals;
  function makeVerticalTimelineLayout(periods) {
    periods = periods.filter(x => !x.empty).map((x, i) => ({
      period: x,
      index: i
    }));
    if (periods.length === 0) {
      throw "The periods contain no non-empty intervals";
    }
    periods.sort(({
                    period: a
                  }, {
                    period: b
                  }) => a.start.compare(b.start));
    function toCoordinate(point) {
      const {
        period,
        index
      } = periods.find(x => x.period.contains(point)) || periods[periods.length - 1];
      if (!period.contains(point) && !period.end.isSame(point)) {
        throw "The point + " + point.toString() + " is outside of all periods";
      }
      const pointPositionWithinPeriod = period.start.durationTo(point).totalMinutes / period.start.durationTo(period.end).totalMinutes;
      return (index + pointPositionWithinPeriod) / periods.length;
    }
    function toCoordinates(interval) {
      if (interval.empty) {
        throw "Empty intervals are not supported";
      }
      const start = toCoordinate(interval.start);
      const end = toCoordinate(interval.end);
      return {
        start,
        duration: end - start
      };
    }
    return {
      cssHeight(interval, totalHeight) {
        const {
          duration
        } = toCoordinates(interval);
        return duration * totalHeight + "px";
      },
      toCssPercents(interval) {
        function toCssPercent(value) {
          return (value * 100).toFixed(5) + "%";
        }
        const {
          start,
          duration
        } = toCoordinates(interval);
        return {
          top: toCssPercent(start),
          height: toCssPercent(duration)
        };
      }
    };
  }
  function makeHorizontalTimelineLayout(items) {
    const sortedItems = [...items];
    sortedItems.sort((a, b) => comparePeriods(a.period, b.period));
    const container = makeContainer(sortedItems);
    return container.layout();
    function makeColumn(initialItem) {
      let coverage = intervals.sum([initialItem.period]);
      let items = [initialItem];
      return {
        dump() {
          return {
            coverage: coverage.toString(),
            items: items.map(x => ({
              period: x.period.toString(),
              id: x.id
            }))
          };
        },
        layout(layout, columnIndex, sizes) {
          return items.reduce((layout, item) => {
            layout[item.id] = sizes(columnIndex, 1);
            return layout;
          }, layout);
        },
        tryAdd(item) {
          const itemPeriod = intervals.sum([item.period]);
          if (coverage.intersectsWith(itemPeriod)) {
            return false;
          }
          coverage = coverage.union(itemPeriod);
          items.push(item);
          return true;
        }
      };
    }
    function makeContainer(items) {
      const columns = items.reduce((columns, item) => {
        if (!columns.some(x => x.tryAdd(item))) {
          columns.push(makeColumn(item));
        }
        return columns;
      }, []);
      return {
        dump() {
          return columns.map(x => x.dump());
        },
        layout() {
          const columnCount = columns.length;
          return {
            cssPercents(offsetWithinContainer, containerWidth) {
              return layout(cssPercents(offsetWithinContainer, containerWidth));
            },
            cssPixels(offsetOutsideContainer, containerWidth) {
              return layout(cssPixels(offsetOutsideContainer, containerWidth));
            }
          };
          function cssPercents(offsetWithinContainer, containerWidth) {
            const offsetPercentage = offsetWithinContainer / containerWidth * 100;
            const scale = (containerWidth - offsetWithinContainer) / containerWidth / columnCount * 100;
            return function (left, width) {
              return {
                left: offsetPercentage + left * scale + "%",
                width: width * scale + "%"
              };
            };
          }
          function cssPixels(offset, containerWidth) {
            const scale = containerWidth / columnCount;
            return function (left, width) {
              return {
                left: offset + left * scale + "px",
                width: width * scale + "px"
              };
            };
          }
          function layout(sizes) {
            return columns.reduce((layout, column, index) => column.layout(layout, index, sizes), {});
          }
        }
      };
    }
    function comparePeriods(a, b) {
      const start = a.start.compare(b.start);
      return start != 0 ? start : -a.end.compare(b.end);
    }
  }
  function makePeriods(activities, intervals, {
    breakDuration
  }) {
    if (!activities.length) {
      return [];
    }
    const span = activities.reduce((a, b) => a.span(b));
    const breaks = makeBreaks(span);
    const periods = breaks.periods.map(x => ({
      description: periodDescription(x),
      isBreak: true,
      number: "",
      period: x
    }));
    periods.sort(({
                    period: a
                  }, {
                    period: b
                  }) => a.start.compare(b.start));
    return periods;
    function makeBreaks(span) {
      const periods = [];
      const adjustedSpan = intervals.nonEmpty(span.start.startOfHour(), span.end);
      let period = intervals.nonEmpty(adjustedSpan.start, adjustedSpan.start.addDuration(breakDuration));
      while (period.intersectsWith(adjustedSpan)) {
        periods.push(period);
        period = period.map(x => x.addDuration(breakDuration));
      }
      return intervals.sum(periods);
    }
    function periodDescription(x) {
      if (x.start.minute === 0 && x.end.minute === 0 && x.end.hour - x.start.hour === 1) {
        return x.start.toString();
      }
      return x.toString();
    }
  }
  function defaultBreakDurations(time) {
    return {
      durations: [time.duration(180), time.duration(120), time.duration(60), time.duration(30)],
      initial: time.duration(60)
    };
  }
  function makeTextLines({
                           breakDuration,
                           minutesPerLineAtOneHourBreak
                         }) {
    const linesPerMinute = 60 / breakDuration.totalMinutes / minutesPerLineAtOneHourBreak;
    return textLines;
    function textLines(interval) {
      const duration = interval.duration();
      const lines = duration.totalMinutes * linesPerMinute;
      return Math.round(lines);
    }
  }
  _module_exports = {
    defaultBreakDurations,
    makeTextLines,
    makeHorizontal: makeHorizontalTimelineLayout,
    makeVertical: makeVerticalTimelineLayout,
    makePeriods
  };
  return _module_exports;
})(_module_shared_intervals);

/**
 * MODULE shared\future.js
 **/
const _module_shared_future = (function shared_future() {
  let _module_exports = {};

  /** @constructor */
  function Future() {
    this.promise = new Promise((resolve, reject) => {
      this._resolve = resolve;
      this._reject = reject;
    });
  }
  Future.prototype.reject = function (value) {
    this._reject(value);
  };
  Future.prototype.resolve = function (value) {
    this._resolve(value);
  };
  _module_exports = {
    Future
  };
  return _module_exports;
})();

/**
 * MODULE shared\aula.js
 **/
const _module_shared_aula = (function shared_aula(
  _module_shared_future
) {
  let _module_exports = {};

  const {
    Future
  } = _module_shared_future;
  const AulaTokenMixin = {
    data() {
      return {
        /** @private */
        aulaToken: undefined,
        /** @private */
        aulaTokenFuture: new Future()
      };
    },
    computed: {
      /** @public */
      token() {
        return this.aulaTokenFuture.promise;
      }
    },
    watch: {
      aulaToken(newValue) {
        if (newValue == undefined) {
          return;
        }
        this.aulaTokenFuture.resolve(newValue);
        this.aulaTokenFuture = new Future();
        this.aulaTokenFuture.resolve(newValue);
      }
    },
    mounted() {
      this.aulaToken = this.getAulaToken();
    }
  };
  _module_exports = {
    AulaTokenMixin
  };
  return _module_exports;
})(_module_shared_future);

/**
 * MODULE shared\date.js
 **/
const _module_shared_date = (function shared_date() {
  let _module_exports = {};

  /**
   * A number identifying a day of week.
   * Days are numbered from 0, starting with monday, up to sunday.
   * @typedef {number} DayOfWeek
   */

  /**
   * A date.
   * @typedef {Object} Date
   * @property {number} year Year of the date.
   * @property {number} month Month of the date (1-12).
   * @property {number} day Day of month of the date (1-31).
   * @property {DayOfWeek} dayOfWeek Day of week of the date (0-6).
   */

  /**
   * @returns {Date}
   */
  function makePoint(year, month, day, moment) {
    return wrapMoment(moment()().year(year).month(month - 1).date(day).hour(0).minute(0).second(0).millisecond(0));
  }

  /**
   * @returns {Date}
   */
  function wrapMoment(value) {
    if (!value.isValid()) {
      const message = invalidDateMessage(value);
      throw new Error("Invalid date".concat(message ? ". " + message : "", "."));
    }
    return {
      _value: value,
      get year() {
        return this._value.year();
      },
      get month() {
        return this._value.month() + 1;
      },
      get day() {
        return this._value.date();
      },
      get dayOfWeek() {
        const day = this._value.day();
        return day == 0 ? 6 : day - 1;
      },
      addDays(count) {
        return wrapMoment(this._value.clone().add(count, "days"));
      },
      compare(other) {
        return this._value.diff(other._value, "days");
      },
      /**
       * Formats the date to a string.
       * Supported formats are "dayofweek" and "input".
       * The "dayofweek" format specifies that long name of the date's day of week should be used.
       * The "input" format results in a string understood by input elements of type "date" ('yyyy-mm-dd').
       * @param {string} format A format specification to use. The only supported value is "dayofweek".
       * @returns A string representation of the date, according to specified format.
       * @throws {Error} Throws when format argument is none of the supported values.
       */
      format(format) {
        switch (format) {
          case "dayofweek":
            return this._value.format("dddd");
          case "input":
            return this._value.format("YYYY-MM-DD");
          default:
            throw new Error("Unknown date point format \"".concat(format, "\""));
        }
      },
      isAfter(other) {
        return this._value.isAfter(other._value);
      },
      isBefore(other) {
        return this._value.isBefore(other._value);
      },
      isSame(other) {
        return this._value.isSame(other._value);
      },
      toJSON() {
        return this._value.format("YYYY-MM-DD");
      },
      toString() {
        return this._value.format("L");
      }
    };
  }
  function invalidDateMessage(value) {
    switch (value.invalidAt()) {
      case 0:
        return "Year value ".concat(value.year(), " is invalid");
      case 1:
        return "Month value ".concat(value.month(), " is invalid");
      case 2:
        return "Day value ".concat(value.date(), " is invalid");
      default:
        return null;
    }
  }

  /**
   * @returns {?Date}
   */
  function parsePoint(value, moment) {
    const result = value.match(/(\d{1,5})-(\d{1,2})-(\d{1,2})/u);
    if (result && result[0] === value) {
      const [, year, month, day] = result;
      return makePoint(Number.parseInt(year, 10), Number.parseInt(month, 10), Number.parseInt(day, 10), moment);
    } else {
      return null;
    }
  }
  _module_exports = {
    parsePoint,
    point: makePoint,
    wrapMoment(value) {
      return wrapMoment(value.clone().hour(0).minute(0).second(0).millisecond(0));
    }
  };
  return _module_exports;
})();

/**
 * MODULE shared\api.js
 **/
const _module_shared_api = (function shared_api() {
  let _module_exports = {};

  function arrayToQueryString(name, value) {
    return value.map(x => {
      var _x$toJSON, _x$toJSON2;
      return "".concat(name, "=").concat((_x$toJSON = x === null || x === void 0 ? void 0 : (_x$toJSON2 = x.toJSON) === null || _x$toJSON2 === void 0 ? void 0 : _x$toJSON2.call(x)) !== null && _x$toJSON !== void 0 ? _x$toJSON : x);
    }).join("&");
  }
  function responseError(result) {
    var _ref, _result$response$data, _result$response, _result$response$data2, _result$response2, _result$response2$dat, _result$response2$dat2;
    return Promise.reject((_ref = (_result$response$data = result === null || result === void 0 ? void 0 : (_result$response = result.response) === null || _result$response === void 0 ? void 0 : (_result$response$data2 = _result$response.data) === null || _result$response$data2 === void 0 ? void 0 : _result$response$data2.Message) !== null && _result$response$data !== void 0 ? _result$response$data : result === null || result === void 0 ? void 0 : (_result$response2 = result.response) === null || _result$response2 === void 0 ? void 0 : (_result$response2$dat = _result$response2.data) === null || _result$response2$dat === void 0 ? void 0 : (_result$response2$dat2 = _result$response2$dat.Error) === null || _result$response2$dat2 === void 0 ? void 0 : _result$response2$dat2.Message) !== null && _ref !== void 0 ? _ref : result.message);
  }
  _module_exports = {
    arrayToQueryString,
    responseError
  };
  return _module_exports;
})();

/**
 * MODULE substitute_notes\api.js
 **/
const _module_widget_api = (function widget_api(
  _module_shared_api,
  _module_shared_intervals,
  _module_shared_time
) {
  let _module_exports = {};

  const intervals = _module_shared_intervals;
  const time = _module_shared_time;
  const api = _module_shared_api;

  /** @typedef {import('../shared/date').Date} Date */
  /** @typedef {import('./store').Activity} Activity */
  /** @typedef {import('./store').ActivityId} ActivityId */
  /** @typedef {import('./store').PersonnelType} PersonnelType */
  /** @typedef {import('./store').PersonnelTypeId} PersonnelTypeId */
  /** @typedef {import('./store').School} School */
  /** @typedef {import('./store').SchoolCode} SchoolCode */

  /**
   * A list of personnel types.
   * @typedef {Object} PersonnelTypeList
   * @property {?PersonnelTypeId} idOfPreselectedPersonnelType The id of personnel type to preselect.
   * @property {PersonnelType[]} personnelTypes The personnel types.
   */

  /**
   * @classdesc A substitute notes API.
   * @constructor
   * @param {Function} axios Function returning the Axios instance.
   * @param {Function} token Function returning a promise resolving to an Aula token.
   * @param {Function} moment Function returning the Moment instance.
   */
  function Api(axios, token, moment) {
    this._moment = moment;
    this._axios = () => {
      return token().then(x => axios().create({
        headers: {
          Authorization: x
        }
      }));
    };
    this.apiUrl = "https://personale.api.kmd.dk/aula/api/v1/SubstituteNotes";
    this.apiUrlV2 = "https://personale.api.kmd.dk/aula/api/v2/SubstituteNotes";
  }
  Api.prototype = {
    /**
     * Gets activities of a school and personnel type, on a date.
     * @param {SchoolCode} schoolCode The code of school of the activities.
     * @param {PersonnelTypeId} personnelType Id of the personnel type of the activities.
     * @param {Date} date The date of the activities.
     * @returns {Promise<Activity>} The activities.
     */
    activities(schoolCode, personnelType, date) {
      return this._axios().then(axios => axios.get(this.apiUrl + "/activities", {
        headers: {
          Accept: "application/json"
        },
        params: {
          date: date.toJSON(),
          personnelType,
          schoolCode
        }
      })).then(response => response.data.map(x => ({
        abbreviationOfSubject: x.abbreviationOfSubject,
        absentee: x.absentee,
        endLesson: x.endLesson,
        classes: x.classes,
        description: x.description,
        id: x.id,
        notes: x.notes,
        period: intervals.nonEmpty(time.parsePoint(x.start), time.parsePoint(x.end)),
        rooms: x.rooms,
        startLesson: x.startLesson,
        substitution: x.substitution
      })), api.responseError);
    },
    /**
     * Gets personnel types of a school.
     * @param {SchoolCode} schoolCode The code of the school.
     * @returns {Promise<PersonnelTypeList>} The personnel types.
     */
    personnelTypes(schoolCode) {
      return this._axios().then(axios => axios.get(this.apiUrlV2 + "/personnelTypes", {
        headers: {
          Accept: "application/json"
        },
        params: {
          schoolCode
        }
      })).then(response => response.data, api.responseError);
    },
    /**
     * Gets school with given school codes.
     * @param {SchoolCode} schoolCodes Codes of the schools.
     * @returns {Promise<School[]>} The schools
     */
    schools(schoolCodes) {
      return this._axios().then(axios => axios.get(this.apiUrl + "/schools?".concat(api.arrayToQueryString("schoolCodes", schoolCodes !== null && schoolCodes !== void 0 ? schoolCodes : [])), {
        headers: {
          Accept: "application/json"
        }
      })).then(response => response.data, api.responseError);
    },
    /**
     * Sets notes for an activity in a school.
     * @param {ActivityId} activityId Id of the activity.
     * @param {SchoolCode} schoolCode Code of the school of the activity
     * @param {String} internalNote New internal note for the activity.
     * @param {String} lessonNote New lesson note for the activity.
     * @param {String} substituteNote New substitute note for the activity.
     * @returns {Promise}
     */
    setNotes(activityId, schoolCode, internalNote, lessonNote, substituteNote) {
      const dto = {
        activity: activityId,
        notes: [{
          type: 0,
          contents: internalNote
        }, {
          type: 1,
          contents: lessonNote
        }, {
          type: 2,
          contents: substituteNote
        }],
        schoolCode
      };
      return this._axios().then(axios => axios.post(this.apiUrl, dto)).then(response => response.data, api.responseError);
    }
  };
  _module_exports = {
    Api
  };
  return _module_exports;
})(_module_shared_api,
  _module_shared_intervals,
  _module_shared_time);

/**
 * WIDGET CODE
 **/

const {
  AulaTokenMixin
} = _module_shared_aula;
const date = _module_shared_date;
const time = _module_shared_time;
const intervals = _module_shared_intervals;
const settings = _module_shared_settings;
const timelineLayout = _module_shared_timelineLayout;
const rangeInput = _module_shared_rangeInput;
const {
  Actions
} = _module_widget_actions;
const {
  makeActivity
} = _module_widget_activities;
const {
  Api
} = _module_widget_api;
const {
  Store
} = _module_widget_store;
function breakDurationInput() {
  const {
    durations,
    initial
  } = timelineLayout.defaultBreakDurations(time);
  return rangeInput.selectingItem({
    items: durations,
    initialItem: initial,
    itemCompare(a, b) {
      return a.isSame(b);
    }
  });
}
// @vue/component
export default {
  name: "SubstituteNotes",
  mixins: [AulaTokenMixin],
  props: {
    axios: {
      type: Function,
      required: true
    },
    currentWeekNumber: {
      type: String,
      default: null
    },
    moment: {
      type: Function,
      required: true
    },
    placement: {
      type: String,
      required: true
    },
    getAulaToken: {
      type: Function,
      required: true
    },
    institutionFilter: {
      type: Array,
      required: true
    },
    sessionUUID: {
      type: String,
      required: true
    }
  },
  data() {
    const api = new Api(() => this.axios, () => this.token, () => this.moment);
    const store = new Store(this.$set.bind(this));
    const actions = new Actions(api, store);
    return {
      actions,
      activityTableColClientWidth: 200,
      breakDurationInput: breakDurationInput(),
      loading: false,
      notesLoading: false,
      personnelTypes: [],
      activities: [],
      schools: [],
      selectedDate: null,
      selectedInstitution: null,
      selectedPersonnelType: 0,
      settings: null,
      internalNote: null,
      lessonNote: null,
      substituteNote: null,
      selectedActivity: null,
      showAlert: false,
      store,
      alertMessage: "",
      alertType: "success",
      alertCountdown: 5,
      windowWidth: window.innerWidth,
      apiUrl: "https://personale.api.kmd.dk/aula/api/v1/SubstituteNotes",
      apiUrlV2: "https://personale.api.kmd.dk/aula/api/v2/SubstituteNotes"
    };
  },
  computed: {
    activityHorizontalLayout() {
      if (!this.activities || !this.activityTableColClientWidth) {
        return {};
      }
      // We're using element referenced by activityTableColRef instead of activityTableContainerRef,
      // to use as width of the container, since we haven't figured out how to access the second.
      // The width of the first must be adjusted, as it's wider, due to padding and scroll bars.
      const containerWidth = this.activityTableColClientWidth - 50;
      const periodColumnWidth = this.isNarrow ? 50 : 120;
      const layout = timelineLayout.makeHorizontal(this.activities).cssPercents(periodColumnWidth, containerWidth);
      return layout;
    },
    activityTextLines() {
      if (!this.activities) {
        return {};
      }
      const lines = timelineLayout.makeTextLines({
        breakDuration: this.breakDurationInput.item(),
        minutesPerLineAtOneHourBreak: 15
      });
      return this.activities.reduce((result, activity) => {
        result[activity.id] = lines(activity.period);
        return result;
      }, {});
    },
    activityVerticalLayout() {
      if (!this.activities || !this.periods) {
        return {};
      }
      const layout = timelineLayout.makeVertical(this.periods.map(x => x.period));
      return this.activities.reduce((result, activity) => {
        result[activity.id] = layout.toCssPercents(activity.period);
        return result;
      }, {});
    },
    numberOfColsInPickers: function () {
      if (this.isNarrow) {
        return 12;
      }
      if (this.hasMultipleSchools && this.hasMultiplePersonnelTypes) {
        return 4;
      }
      return 6;
    },
    hasMultipleSchools: function () {
      return this.schools.length > 1;
    },
    hasMultiplePersonnelTypes: function () {
      return this.personnelTypes.length > 1;
    },
    inputSelected: function () {
      return this.selectedDate != null && this.selectedDate.length > 0 && this.selectedPersonnelType > 0;
    },
    isNarrow: function () {
      return this.placement.toLowerCase() == "narrow" || this.isMobile;
    },
    isMobile: function () {
      return this.windowWidth < 576;
    },
    periods: function () {
      if (!this.activities) {
        return [];
      }
      return timelineLayout.makePeriods(this.activities.map(x => x.period), intervals, {
        breakDuration: this.breakDurationInput.item()
      });
    },
    hasSelectedActivity: function () {
      return this.selectedActivity != null && this.selectedActivity.id.length > 0;
    }
  },
  watch: {
    currentWeekNumber: function (newWeek, oldWeek) {
      if (this.placement !== "NoticeBoard" && newWeek && newWeek.toLowerCase() !== oldWeek.toLowerCase()) {
        this.week = this.extractWeekNumber(newWeek);
        this.selectedDate = this.extractSelectedDate();
        this.loadActivities();
      }
    },
    institutionFilter: function () {
      if (this.placement === "NoticeBoard") {
        return;
      }
      if (!this.institutionFilter.includes(this.selectedInstitution)) {
        this.selectedInstitution = this.institutionFilter[0];
      }
      this.loadSchools();
    },
    activities: function () {
      this.$nextTick(function () {
        this.resizeEventHandler();
      });
    }
  },
  created() {
    this.moment.locale("da");
    this.week = this.extractWeekNumber(this.currentWeekNumber);
    this.selectedDate = this.extractSelectedDate();
    this.selectedInstitution = this.institutionFilter[0];
  },
  mounted() {
    window.addEventListener("resize", this.resizeEventHandler);
    if (this.placement !== "NoticeBoard") {
      this.loadSettings();
    }
  },
  beforeDestroy: function () {
    window.removeEventListener("resize", this.resizeEventHandler);
  },
  methods: {
    dismissAlert() {
      this.showAlert = false;
    },
    extractWeekNumber: function (week) {
      const regex = /^(\d){4}-W(\d){1,2}$/u;
      if (!regex.test(week)) {
        return this.moment().week();
      }
      const [yearExtracted, weekExtracted] = week.split("-W");
      const weekNumber = parseInt(weekExtracted, 10);
      this.year = parseInt(yearExtracted, 10);
      return weekNumber;
    },
    extractSelectedDate: function () {
      const selectedWeekOfYear = this.moment().year(this.year).week(this.week);
      const today = this.moment();
      const selectedDate = today.isSame(selectedWeekOfYear, "week") ? today : selectedWeekOfYear.weekday(0);
      return selectedDate.format("YYYY-MM-DD");
    },
    handleDateChange: function (value) {
      if (!value) {
        return;
      }
      this.loadActivities();
    },
    handleInstitutionChanged: function (value) {
      this.settings.setPersistent("selectedInstitution", value);
      this.loadPersonnelTypes();
    },
    showAlertMessage(alertMessage, isError) {
      this.showAlert = false;
      this.alertMessage = alertMessage;
      this.alertType = isError ? "danger" : "success";
      this.showAlert = true;
    },
    selectActivity(selectedActivityId) {
      this.selectedActivity = this.activities.find(x => x.id === selectedActivityId);
      this.internalNote = this.selectedActivity.notes.internal;
      this.lessonNote = this.selectedActivity.notes.lesson;
      this.substituteNote = this.selectedActivity.notes.substitute;
      if (this.isNarrow) {
        this.$refs["notes-modal"].show();
      }
    },
    loadSchools() {
      var _this$institutionFilt, _this$institutionFilt2, _this$institutionFilt3;
      const defaultSchools = (_this$institutionFilt = (_this$institutionFilt2 = this.institutionFilter) === null || _this$institutionFilt2 === void 0 ? void 0 : _this$institutionFilt2.map(x => ({
        text: x,
        value: x
      }))) !== null && _this$institutionFilt !== void 0 ? _this$institutionFilt : [];
      defaultSchools.version = 1;
      if (!this.schools.version) {
        this.schools = defaultSchools;
      }
      const expectedVersion = this.schools.version;
      this.actions.loadSchools((_this$institutionFilt3 = this.institutionFilter) !== null && _this$institutionFilt3 !== void 0 ? _this$institutionFilt3 : []).then(() => {
        if (this.schools.version !== expectedVersion) {
          return;
        }
        this.schools = this.store.schools.map(x => ({
          text: x.name,
          value: x.code
        }));
        this.schools.version = expectedVersion + 1;
        if (this.schools.length > 0 && !this.schools.some(x => x.code === this.selectedInstitution)) {
          this.selectedInstitution = this.schools[0].value;
          this.handleInstitutionChanged();
        }
      }).catch(err => {
        this.showError(err);
        if (this.schools.version !== expectedVersion) {
          return;
        }
        this.schools = defaultSchools;
      });
    },
    loadSettings() {
      this.token.then(aulaToken => settings(this.axios, aulaToken, this.sessionUUID, this.placement === "NoticeBoard", this.institutionCode, "substitute-notes").then(settings => {
        this.settings = settings;
        this.breakDurationInput.value = settings.getPersistent("breakDurationInputValue", this.breakDurationInput.value);
        this.$watch("breakDurationInput.value", x => {
          settings.setPersistent("breakDurationInputValue", x);
        });
        this.selectedInstitution = this.settings.getPersistent("selectedInstitution", this.selectedInstitution, x => this.institutionFilter.includes(x));
        this.loadSchools();
      }));
    },
    loadPersonnelTypes() {
      this.loading = true;
      this.selectedActivity = null;
      const schoolCode = this.selectedInstitution;
      this.actions.loadPersonnelTypes(schoolCode).then(() => {
        const personnelTypes = this.store.personnelTypes[schoolCode];
        const idOfPreselectedPersonnelType = this.store.idsOfPreselectedPersonnelTypes[schoolCode];
        this.selectedPersonnelType = idOfPreselectedPersonnelType;
        this.personnelTypes = personnelTypes;
        this.loading = false;
        this.loadActivities();
      }).catch(err => {
        this.loading = false;
        this.showError(err);
      });
    },
    loadActivities() {
      if (this.selectedPersonnelType === null || this.selectedPersonnelType < 1 || this.selectedDate === null || this.selectedDate === "") {
        this.activities = [];
        this.selectedActivity = null;
        return;
      }
      this.loading = true;
      this.selectedActivity = null;
      const schoolCode = this.selectedInstitution;
      const personnelType = this.selectedPersonnelType;
      const selectedDate = date.parsePoint(this.selectedDate, () => this.moment);
      this.actions.loadActivities(schoolCode, personnelType, selectedDate).then(() => {
        var _this$store$activitie, _this$store$activitie2, _this$store$activitie3;
        const activities = (_this$store$activitie = (_this$store$activitie2 = this.store.activities[schoolCode]) === null || _this$store$activitie2 === void 0 ? void 0 : (_this$store$activitie3 = _this$store$activitie2[selectedDate.toJSON()]) === null || _this$store$activitie3 === void 0 ? void 0 : _this$store$activitie3[personnelType]) !== null && _this$store$activitie !== void 0 ? _this$store$activitie : [];
        this.activities = activities.map(x => makeActivity(x));
        this.loading = false;
      }).catch(err => {
        this.loading = false;
        this.showError(err);
      });
    },
    showError(error) {
      var message = "Der skete en fejl";
      if (error.response === undefined) {
        message = error.message || "Ukendt fejl";
      } else {
        message = error.response.data.message;
      }
      this.showAlertMessage(message, true);
    },
    // ====== NOTES METHODS START ======
    saveNotes() {
      if (this.selectedActivity === null) {
        return;
      }
      this.notesLoading = true;
      this.actions.setNotes(this.selectedActivity.activityId, this.selectedInstitution, this.internalNote, this.lessonNote, this.substituteNote).then(() => {
        this.activities.filter(x => x.activityId === this.selectedActivity.activityId).forEach(x => {
          x.notes.update(this.internalNote, this.lessonNote, this.substituteNote);
        });
        this.notesLoading = false;
        this.showAlertMessage("Noterne blev gemt", false);
      }, () => {
        this.notesLoading = false;
        this.showAlertMessage("Kunne ikke gemme noterne", true);
      });
      if (this.isNarrow) {
        // Close modal
        this.$refs["notes-modal"].hide();
      }
    },
    resizeEventHandler: function () {
      if (this.$refs.activityTableColRef && this.$refs.activityTableColRef.clientWidth !== 0) {
        this.activityTableColClientWidth = this.$refs.activityTableColRef.clientWidth;
      }
      this.windowWidth = window.innerWidth;
    }
    // ====== NOTES METHODS END ======
  }
};

</script>

<style scoped>
/* ====== COMMON STYLES ====== */

.loader {
  font-size: 3em;
  padding-top: 2em;
  text-align: center;
  width: 100%;
  color: #549ec7;
}

.splitter {
  display: block;
  height: 20px;
}

.type-date {
  margin-right: 10px;
}

.root /deep/ select {
  min-height: 50px;
}

.root /deep/ select,
.root /deep/ input[type="date"] {
  border: 1px solid #d9e3e9;
}

.root /deep/ select:focus,
.root /deep/ input[type="date"]:focus {
  border: 1px solid #549ec7;
  box-shadow: none;
}

.info {
  color: #18add6;
  padding-left: 5px;
}

.root /deep/ textarea {
  border: 1px solid #d9e3e9;
}

.root /deep/ textarea:focus {
  border: 1px solid #549ec7;
  box-shadow: none;
}

.root /deep/ textarea::-webkit-input-placeholder {
  /* Edge */
  color: #c7c7c7 !important;
}

.root /deep/ textarea:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #c7c7c7 !important;
}

.root /deep/ textarea::placeholder {
  color: #c7c7c7 !important;
}

.root /deep/ button,
.root /deep/ .button {
  background-color: transparent !important;
  color: #549ec7 !important;
  border: 1px solid #549ec7 !important;
  box-shadow: none !important;
  transition: all ease 0.2s;
}

.root /deep/ button:hover,
.root /deep/ .button:hover {
  background-color: #549ec7 !important;
  color: #ffffff !important;
  box-shadow: none !important;
}

.root /deep/ button:active,
.root /deep/ .button:active {
  background-color: #549ec7 !important;
  color: #ffffff !important;
  box-shadow: none !important;
}

button.fullwidth {
  width: 100%;
  margin-bottom: 10px;
  margin-right: 0;
}

.root /deep/ button.save-button,
.root /deep/ .button.save-button {
  margin-top: 10px;
}

.root /deep/ .button.close,
.root /deep/ button.close {
  background-color: transparent !important;
  color: white !important;
  border: none !important;
  box-shadow: none !important;
  transition: all ease 0.2s;
  padding: 0;
  margin-right: 0;
  margin-top: 0;
}

.root /deep/ button.close:hover,
.root /deep/ .button.close:hover {
  background-color: transparent !important;
  box-shadow: none !important;
}

.root /deep/ button.close:active,
.root /deep/ .button.close:active {
  background-color: transparent !important;
  box-shadow: none !important;
}

.root /deep/ .modal-header {
  background-color: #549ec7;
  color: white;
}

/* ====== APP STYLES ====== */

#widget-content.mobile {
  margin-bottom: 80px;
}

.input-header {
  font-weight: bold;
}

.widget-header {
  margin-bottom: 1em;
}

.widget-header h2 {
  margin-bottom: 0;
}

.widget-header h4 {
  font-size: 1em;
  border-bottom: 2px solid #549ec7;
  color: #aaa;
  padding-bottom: 0.5em;
}

/* ====== STYLES FOR ACTIVITY LIST ====== */

.activity {
  background-color: #2a7ab0;
  position: absolute;
  color: #ffffff;
  border-radius: 2px;
  border: 1px solid #ffffff;
}

.activity.clickable *:hover {
  cursor: pointer !important;
}

.activity.selected {
  border: 2px solid #000000;
}

.activity > .activity-content {
  --note-width: 32px;
  display: grid;
  grid: [top] 50% [middle] 50% [bottom]
    / [left] 50% [middle] 50% [right];
  height: 100%;
  line-height: initial;
  min-width: 0;
  min-height: 0;
  padding: 5px;
  width: 100%;
}

.activity > .activity-content > * {
  min-width: 0;
  min-height: 0;
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.activity > .activity-content > .activity-absentee {
  align-self: start;
  justify-self: right;
  grid-area: top / left / bottom / right;
}

.activity > .activity-content > .activity-absentee.with-note {
  padding-right: var(--note-width);
}

.activity > .activity-content > .activity-absentee.with-substitution {
  grid-column-start: middle;
}

.activity > .activity-content > .activity-classes {
  align-self: end;
  justify-self: left;
  grid-area: top / left / bottom / right;
}

.activity > .activity-content > .activity-classes.with-rooms {
  grid-column-end: middle;
}

.activity > .activity-content > .activity-description {
  align-self: end;
  justify-self: center;
  grid-area: top / left / middle / right;
}

.activity > .activity-content > .activity-description.without-period {
  align-self: center;
  grid-row-end: bottom;
}

.activity > .activity-content > .activity-note {
  align-self: start;
  justify-self: right;
  grid-area: top / left / bottom / right;
}

.activity > .activity-content > .activity-period {
  align-self: start;
  justify-self: center;
  grid-area: middle / left / bottom / right;
}

.activity > .activity-content > .activity-rooms {
  align-self: end;
  justify-self: right;
  grid-area: top / left / bottom / right;
}

.activity > .activity-content > .activity-rooms.with-classes {
  grid-column-start: middle;
}

.activity > .activity-content > .activity-substitution {
  align-self: start;
  justify-self: left;
  grid-area: top / left / bottom / right;
}

.activity > .activity-content > .activity-substitution.with-note {
  padding-right: var(--note-width);
}

.activity > .activity-content > .activity-substitution.with-absentee {
  grid-column-end: middle;
}

.activity-table {
  table-layout: fixed;
  width: 100%;
}

.activity-table :hover {
  cursor: default;
}

.activity-table.busy {
  opacity: 0.1;
}

.activity-table .activity-column {
  border-top: none;
  height: 110px;
  padding: 10px;
  line-height: 8px;
  position: initial;
  text-align: center;
  vertical-align: middle;
  width: auto;
}

.activity-table .activity-column p {
  line-height: 16px;
}

.activity-table .period-column {
  border-top: none;
  white-space: initial;
  text-align: center;
}

.activity-table tbody tr:hover {
  color: initial;
  background-color: initial;
}

.activity-table tbody tr:hover td {
  border-right-color: #f6f7f8 !important;
}

.activity-table tbody tr:nth-of-type(odd):hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.activity-table tr {
  border-top: 1px solid #dee2e6;
}

.activity-table-container {
  max-height: 540px;
  overflow: hidden auto;
}

.activity-table-timeaxis-input {
  max-width: 150px;
}

/* ------ vertical narrow layout ------ */

@media (max-width: 325px) {
  .activity-table .period-column.narrow {
    display: none;
  }
}

@media (min-width: 325px) {
  .activity-table .period-column.narrow {
    width: 50px;
    margin: 0px;
    padding: 5px;
  }

  .activity-table .period-column.narrow p {
    padding: 0px;
    text-align: center;
  }
}

/* ------ vertical non-narrow layout ------ */

@media (max-width: 768px) {
  .activity-table .period-column p {
    text-align: center;
  }
}

@media (min-width: 768px) {
  .activity-table .period-column {
    width: 120px;
  }
}

/* ====== NOTES STYLES ====== */

.note-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}

#notes-content.narrow {
  margin-top: 20px;
}

.note-textarea {
  width: 100%;
}

#notes {
  grid-column: 2;
  grid-row: 2;
}

.note-header {
  font-weight: bold;
}

#notes-header {
  text-align: center;
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 10px;
}

.notes-description {
  text-align: center;
  font-size: 1.2em;
  color: #549ec7;
}
</style>
