import { ChildVacationRegistrationModel } from '../../shared/models/childVacationRegistration.model';

export class ChildVacationRegistration implements ChildVacationRegistrationModel {
  id: number;
  institutionCode: string;
  metadata: string;
  name: string;

  constructor(model: ChildVacationRegistrationModel) {
    this.id = model.id;
    this.institutionCode = model.institutionCode;
    this.metadata = model.metadata;
    this.name = model.name;
  }
}
