<template>
  <b-form>
    <div class="mb-2"><br />{{ 'CALENDAR_TOOLBAR_REQUEST_VACATION_BODY' | fromTextKey }}</div>
    <b-row class="mt-3">
      <b-col cols="12" lg="6">
        <label>{{ 'CALENDAR_CREATE_EVENT_TITLE' | fromTextKey }}</label>
        <b-form-input
          v-model="vacationRequest.title"
          :disabled="isEditMode"
          :class="{ 'red-border': showAlertNoTitle }"
          @input="showAlertNoTitle = false"
        />
        <b-alert variant="danger" :show="showAlertNoTitle">
          {{ 'CALENDAR_TOOLBAR_REQUEST_VACATION_WARNING_TITLE' | fromTextKey }}
        </b-alert>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" lg="6" class="mt-2">
        <label>{{ 'ALERT_FROM' | fromTextKey }}</label
        ><br />
        <el-date-picker
          ref="requestVacationFrom"
          v-model="vacationRequest.startDateTime"
          class="datetime-input"
          :append-to-body="true"
          :clearable="false"
          :disabled="isEditMode"
          type="date"
          :format="defaultDateFormat"
          :value-format="defaultDateValueFormat"
          :placeholder="'MESSAGE_PLACEHOLDER_SELECT_DATE' | fromTextKey"
          :picker-options="pickerOptions"
          :class="{ 'red-border': showAlertNoStartAndEndDate }"
          :editable="false"
          @input="validateVacationRequest()"
          @focus="datePickerModifier()"
        />
      </b-col>
      <b-col cols="12" lg="6" class="mt-2">
        <label>{{ 'ALERT_TO' | fromTextKey }}</label
        ><br />
        <el-date-picker
          ref="requestVacationTo"
          v-model="vacationRequest.endDateTime"
          class="datetime-input"
          :append-to-body="true"
          :clearable="false"
          :disabled="isEditMode"
          type="date"
          :format="defaultDateFormat"
          :value-format="defaultDateValueFormat"
          :placeholder="'MESSAGE_PLACEHOLDER_SELECT_DATE' | fromTextKey"
          :picker-options="endDatePickerOptions"
          :class="{ 'red-border': showAlertNoStartAndEndDate }"
          :editable="false"
          @input="validateVacationRequest()"
          @focus="datePickerModifier()"
        />
      </b-col>
    </b-row>
    <b-alert variant="danger" :show="showAlertNoStartAndEndDate">
      {{ 'CALENDAR_TOOLBAR_REQUEST_VACATION_WARNING' | fromTextKey }}
    </b-alert>
    <b-row class="mt-3">
      <b-col cols="12" lg="6">
        <label>{{ 'CALENDAR_LABEL_EVENT_REQUEST_DEADLINE' | fromTextKey }}</label
        ><br />
        <el-date-picker
          v-model="vacationRequest.responseDeadline"
          :format="defaultDateFormat"
          :value-format="defaultDateValueFormat"
          :disabled="isEditMode && moment(vacationRequest.startDateTime).isSameOrBefore(moment(), 'days')"
          type="date"
          :aria-label="'ARIA_LABEL_CALENDAR_START_DATE' | fromTextKey"
          :placeholder="'MESSAGE_PLACEHOLDER_SELECT_DATE' | fromTextKey"
          :picker-options="availableDeadlineDate"
          @focus="datePickerModifier()"
        />
      </b-col>

      <b-col cols="12" lg="6" class="align-self-stretch d-flex align-items-end">
        <div class="pl-3" :class="{ 'mt-3': isMobile }">
          <b-form-checkbox v-model="vacationRequest.isPresenceTimesRequired" :disabled="isEditMode" class="pl-3">
            {{ 'CALENDAR_VACATION_REQUEST_PRESENCE_TIME_REQUIRED' | fromTextKey }}
          </b-form-checkbox>
        </div>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col cols="12">
        <label>{{ 'CALENDAR_VACATION_REQUEST_NOTE' | fromTextKey }}</label>
        <b-textarea
          id="comment"
          v-model="vacationRequest.noteToGuardians"
          :disabled="isEditMode"
          maxlength="400"
          max-rows="1"
          rows="5"
          class="absence-input"
          :placeholder="'ARIA_LABEL_VACATION_REQUEST_NOTES' | fromTextKey"
          :aria-label="'ARIA_LABEL_VACATION_REQUEST_NOTES' | fromTextKey"
        />
      </b-col>
    </b-row>
    <hr class="mt-5" />
    <b-row>
      <b-col v-if="activeInstWithVacationRequestPermission.length > 1" cols="12" lg="6" :class="{ 'mt-3': isMobile }">
        <label>{{ 'CALENDAR_CREATE_EVENT_CHOOSE_INSTITUTION' | fromTextKey }}</label>
        <aula-select
          v-model="vacationRequest.institutionCode"
          :aria-label="'ARIA_LABEL_VACATION_REQUEST_CHOOSE_INSTITUTION' | fromTextKey"
          :placeholder="'ARIA_LABEL_VACATION_REQUEST_CHOOSE_INSTITUTION' | fromTextKey"
          :disabled="isEditMode"
          @change="populateDepartmentsAndGroups"
        >
          <aula-option
            v-for="(institution, index) in activeInstWithVacationRequestPermission"
            :id="institution.institutionCode"
            :key="'institution' + index"
            :label="institution.name"
            :value="institution.institutionCode"
          />
        </aula-select>
      </b-col>
      <b-col v-if="departments.length > 1" cols="12" lg="6" :class="{ 'mt-3': isMobile }">
        <label>{{ 'CALENDAR_VACATION_REQUEST_CHOOSE_DEPARTMENTS' | fromTextKey }}</label>
        <aula-select
          v-model="vacationRequest.departmentIds"
          :disabled="isEditMode"
          multiple
          :aria-label="'ARIA_LABEL_VACATION_REQUEST_CHOOSE_DEPARTMENTS' | fromTextKey"
          :placeholder="'ARIA_LABEL_VACATION_REQUEST_CHOOSE_DEPARTMENTS' | fromTextKey"
          :class="{ 'red-border': showAlertNoDepartment }"
          @change="changeDepartment"
        >
          <aula-option
            v-for="department in departments"
            :id="department.id"
            :key="'department' + department.id"
            :label="department.name"
            :value="department.id"
          />
        </aula-select>
        <b-alert variant="danger" :show="showAlertNoDepartment">
          {{ 'CALENDAR_TOOLBAR_REQUEST_VACATION_WARNING_DEPARTMENT' | fromTextKey }}
        </b-alert>
      </b-col>
      <b-col cols="12" lg="6" :class="{ 'mt-3': isMobile }">
        <label>{{ 'CALENDAR_VACATION_REQUEST_CHOOSE_GROUPS' | fromTextKey }}<span class="mandatory">*</span></label>
        <aula-select
          v-model="vacationRequest.groupIds"
          multiple
          :disabled="isEditMode"
          :placeholder="'ARIA_LABEL_VACATION_REQUEST_CHOOSE_GROUPS' | fromTextKey"
          :aria-label="'ARIA_LABEL_VACATION_REQUEST_CHOOSE_GROUPS' | fromTextKey"
          :class="{ 'red-border': showAlertNoGroup }"
          @change="changeGroup"
        >
          <aula-option
            v-for="(group, index) in groups"
            :id="group.id"
            :key="'groups' + index"
            :label="group.name"
            :value="group.id"
          />
        </aula-select>
        <b-alert variant="danger" :show="showAlertNoGroup">
          {{ 'CALENDAR_TOOLBAR_REQUEST_VACATION_WARNING_GROUP' | fromTextKey }}
        </b-alert>
      </b-col>
    </b-row>
    <aula-form-footer
      delete-text="CALENDAR_VACATION_REQUEST_DELETE"
      :is-loading="isLoading"
      :show-delete="isEditMode && canHandleVacationRequests"
      :show-delete-mobile="isEditMode && canHandleVacationRequests"
      :show-delete-icon="isEditMode && canHandleVacationRequests"
      :submit-text="isEditMode ? 'BUTTON_SAVE' : 'BUTTON_CREATE'"
      @cancelClicked="closeVacationRequestForm"
      @submitClicked="saveVacationRequest"
      @deleteClicked="$refs.deleteModal.show()"
    />
    <aula-modal
      ref="cancelModal"
      ok-text="BUTTON_YES"
      @cancelClicked="$refs.cancelModal.hide()"
      @okClicked="
        closeForm();
        $refs.cancelModal.hide();
      "
    >
      {{ 'CALENDAR_CANCEL_INFO' | fromTextKey }} <br />
      {{ 'CALENDAR_CANCEL_INFO_2' | fromTextKey }}
    </aula-modal>
    <aula-modal
      ref="deleteModal"
      ok-text="BUTTON_YES"
      @cancelClicked="$refs.deleteModal.hide()"
      @okClicked="deleteVacationRequest"
    >
      {{ 'CALENDAR_DELETE_VACATION_REGISTRATION_TEXT' | fromTextKey }}
    </aula-modal>
  </b-form>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { types } from '../../store/types/types';
import Vue from 'vue';
import { permissionEnum } from '../../../shared/enums/permissionEnum.ts';
import { parentTypes } from '../../../shared/enums/parentTypes.ts';
import { portalRoles } from '../../../shared/enums/portalRoles';
import isEqualWith from 'lodash/isEqualWith';
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment-timezone';
import { eventTypeEnum } from '../../../shared/enums/eventTypeEnum';

export default {
  props: {
    parent: { type: String, default: parentTypes.PROFILE },
    isEditMode: { type: Boolean, default: false },
    selectedVacationRequestId: { type: Number, default: null },
  },
  data() {
    return {
      Vue: Vue,
      portalRoles: portalRoles,
      permissionEnum: permissionEnum,
      isLoading: false,
      vacationRequest: {
        institutionCode: null,
        departmentIds: [],
        groupIds: [],
        title: '',
        startDateTime: null,
        endDateTime: null,
        responseDeadline: null,
        isPresenceTimesRequired: false,
        noteToGuardians: null,
      },
      departments: [],
      groups: [],
      departmentGroups: [],
      emptyVacationRequest: {},
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() + 86400000 < moment().add(1, 'days').toDate().getTime();
        },
        firstDayOfWeek: 1,
      },
      endDatePickerOptions: {
        disabledDate(time) {
          return time.getTime() + 86400000 < moment().add(1, 'days').toDate().getTime();
        },
        firstDayOfWeek: 1,
      },
      showAlertNoStartAndEndDate: false,
      showAlertNoTitle: false,
      showAlertNoDepartment: false,
      showAlertNoGroup: false,
      availableDeadlineDate: {},
      previousDepartmentIds: [],
      previousGroupIds: [],
    };
  },
  computed: {
    ...mapGetters({
      getPresenceFilters: types.GET_PRESENCE_FILTERS,
      activeEvent: types.GET_CURRENT_EVENT,
      activeInstitutions: types.GET_ACTIVE_INSTITUTIONS,
      hasPermissionInstitution: types.HAS_PERMISSION_ON_INSTITUTION,
      defaultDateFormat: types.GET_DEFAULT_DATE_FORMAT,
      defaultDateValueFormat: types.GET_DEFAULT_DATE_VALUE_FORMAT,
      hasPermission: types.HAS_PERMISSION,
      hasPermissionOnInstitution: types.HAS_PERMISSION_ON_INSTITUTION,
      institutions: types.GET_INSTITUTIONS,
      sameAuthorityInstitutions: types.GET_SAME_AUTHORITY_INSTITUTIONS,
      isMobile: types.GET_IS_MOBILE,
      profile: types.GET_CURRENT_PROFILE,
    }),
    activeInstWithVacationRequestPermission() {
      return this.institutions.filter(
        inst =>
          this.activePresenceInstitutions.includes(inst.institutionCode) &&
          this.hasPermissionInstitution(permissionEnum.HANDLE_VACATION_REQUESTS, inst.institutionCode)
      );
    },
    activePresenceInstitutions() {
      return this.getPresenceFilters
        .filter(
          p => p.departments != null && p.departments.length > 0 && this.activeInstitutions.includes(p.institutionCode)
        )
        .map(p => p.institutionCode);
    },
    vacationRequestCreator() {
      let institutionProfileId = null;
      if (this.vacationRequest.institutionCode) {
        institutionProfileId = this.institutions.find(
          i => i.institutionCode == this.vacationRequest.institutionCode
        ).institutionProfileId;
      } else if (this.activeInstWithVacationRequestPermission.length > 0) {
        institutionProfileId = this.institutions.find(
          i => i.institutionCode == this.activeInstWithVacationRequestPermission[0].institutionCode
        ).institutionProfileId;
      }
      return institutionProfileId;
    },
    canHandleVacationRequests() {
      return (
        this.activeEvent &&
        this.hasPermissionOnInstitution(permissionEnum.HANDLE_VACATION_REQUESTS, this.activeEvent.institutionCode)
      );
    },
  },
  methods: {
    ...mapActions({
      loadPresenceFilters: types.LOAD_PRESENCE_FILTER,
      loadEventById: types.GET_EVENT_BY_ID,
      deleteEvent: types.DELETE_EVENT,
      requestVacation: types.CREATE_VACATION_REQUEST,
      updateVacationRequest: types.UPDATE_REQUEST_VACATION,
      loadEventTypes: types.LOAD_EVENT_TYPES,
      loadAssignedDelegatedAccesses: types.LOAD_ASSIGNED_DELEGATED_ACCESSES,
      loadMyDelegatedAccesses: types.LOAD_MY_DELEGATED_ACCESSES,
      updateDelegatedAccess: types.UPDATE_DELEGATED_ACCESS,
      loadNotifications: types.LOAD_NOTIFICATIONS,
    }),
    resetForm() {
      this.vacationRequest.institutionCode = null;
      this.vacationRequest.departmentIds = [];
      this.vacationRequest.groupIds = [];
      this.vacationRequest.title = '';
      this.vacationRequest.startDateTime = null;
      this.vacationRequest.endDateTime = null;
      this.vacationRequest.responseDeadline = null;
      this.vacationRequest.isPresenceTimesRequired = false;
      this.vacationRequest.noteToGuardians = null;
      this.showAlertNoTitle = false;
      this.showAlertNoStartAndEndDate = false;
    },
    validateVacationRequest() {
      const today = moment().format('YYYY-MM-DD');
      this.showAlertNoStartAndEndDate = false;
      let startDate = this.vacationRequest.startDateTime;
      if (startDate === null) {
        startDate = today;
      }
      this.endDatePickerOptions = {
        disabledDate(time) {
          return moment(time).isBefore(moment(startDate));
        },
        firstDayOfWeek: 1,
      };
      this.availableDeadlineDate = {
        disabledDate(time) {
          return moment(time).isBefore(today) || moment(time).isSameOrAfter(startDate);
        },
        firstDayOfWeek: 1,
      };
      if (
        this.vacationRequest.responseDeadline != null &&
        this.vacationRequest.responseDeadline != '' &&
        moment(this.vacationRequest.responseDeadline).isAfter(moment(startDate).subtract(1, 'days'))
      ) {
        this.vacationRequest.responseDeadline = moment(startDate).subtract(1, 'days').toDate();
      }
    },
    validateForm() {
      let isSave = true;
      if (!this.vacationRequest.title || this.vacationRequest.title.trim() == '') {
        this.showAlertNoTitle = true;
        this.isLoading = false;
        isSave = false;
      }
      if (
        !this.vacationRequest.startDateTime ||
        !this.vacationRequest.endDateTime ||
        moment(this.vacationRequest.endDateTime).isBefore(moment(this.vacationRequest.startDateTime))
      ) {
        this.showAlertNoStartAndEndDate = true;
        isSave = false;
        this.isLoading = false;
      }
      if (
        (moment(this.vacationRequest.endDateTime).day() == 6 || moment(this.vacationRequest.endDateTime).day() == 0) &&
        (moment(this.vacationRequest.startDateTime).day() == 6 ||
          moment(this.vacationRequest.startDateTime).day() == 0) &&
        moment(this.vacationRequest.endDateTime).diff(moment(this.vacationRequest.startDateTime), 'days') < 3
      ) {
        this.showAlertNoStartAndEndDate = true;
        this.isLoading = false;
        isSave = false;
      }
      if (this.vacationRequest.departmentIds.length === 0) {
        this.showAlertNoDepartment = true;
        this.isLoading = false;
        isSave = false;
      }

      if (this.vacationRequest.groupIds.length === 0) {
        this.showAlertNoGroup = true;
        this.isLoading = false;
        isSave = false;
      }
      return isSave;
    },
    saveVacationRequest() {
      if (this.validateForm()) {
        this.isLoading = true;
        if (this.isEditMode) {
          const params = {
            id: this.activeEvent.id,
            responseDeadline: this.vacationRequest.responseDeadline,
          };
          this.updateVacationRequest(params).then(() => {
            this.isLoading = false;
            this.closeForm();
          });
        } else {
          const departments = [];
          for (const departmentId of this.vacationRequest.departmentIds) {
            const department = {
              groupId: departmentId,
              filteringGroups: this.vacationRequest.groupIds.filter(
                groupId => this.departmentGroups.find(g => g.id == groupId && g.departmentId == departmentId) != null
              ),
            };
            departments.push(department);
          }
          this.requestVacation({
            creatorInstProfileId: this.vacationRequestCreator,
            title: this.vacationRequest.title,
            departments: departments,
            noteToGuardians: this.vacationRequest.noteToGuardians,
            isPresenceTimesRequired: this.vacationRequest.isPresenceTimesRequired,
            startDateTime: moment(this.vacationRequest.startDateTime).format('YYYY-MM-DD'),
            endDateTime: moment(this.vacationRequest.endDateTime).format('YYYY-MM-DD'),
            responseDeadline: moment(this.vacationRequest.responseDeadline).isValid()
              ? moment(this.vacationRequest.responseDeadline).format('YYYY-MM-DD')
              : null,
          }).then(() => {
            this.isLoading = false;
            this.$emit('reloadVacationRequest');
            this.closeForm();
          });
        }
      }
    },
    deleteVacationRequest() {
      this.isLoading = true;
      this.deleteEvent({
        eventForm: { id: this.activeEvent.id },
        type: eventTypeEnum.VACATION_REGISTRATION,
      }).then(() => {
        this.isLoading = false;
        this.$refs.deleteModal.hide();
        this.$emit('reloadCalendar');
        this.closeForm();
      });
    },
    closeForm() {
      this.resetForm();
      if (this.parent === parentTypes.PROFILE) {
        this.$router.push({ path: '/kalender' });
      } else if (this.parent === parentTypes.PRESENCE) {
        this.$emit('onClosed');
      } else {
        this.$router.push({ name: 'group-calendar' });
      }
    },
    closeVacationRequestForm() {
      if (isEqualWith(this.emptyVacationRequest, this.vacationRequest)) {
        this.closeForm();
      } else {
        this.$refs.cancelModal.show();
      }
    },
    validateAddOrHideInOwnCalendar() {
      Vue.nextTick(() => {
        this.$forceUpdate();
      });
    },
    changeDepartment() {
      this.showAlertNoDepartment = false;
      const newDepartmentId = this.vacationRequest.departmentIds.find(d => !this.previousDepartmentIds.includes(d));
      if (newDepartmentId != null) {
        const newGroupIds = this.departmentGroups.filter(d => d.departmentId == newDepartmentId).map(g => g.id);
        this.vacationRequest.groupIds = [...new Set([...this.vacationRequest.groupIds, ...newGroupIds])];
      }
      const groups = this.departmentGroups.filter(d => this.vacationRequest.departmentIds.includes(d.departmentId));
      const uniqueGroups = [];
      groups.forEach(function (group) {
        if (uniqueGroups.find(g => g.id == group.id) == null) {
          uniqueGroups.push(group);
        }
      });
      this.groups = uniqueGroups;
      this.vacationRequest.groupIds = this.vacationRequest.groupIds.filter(
        groupId => this.groups.find(ag => ag.id == groupId) != null
      );
      this.previousDepartmentIds = cloneDeep(this.vacationRequest.departmentIds);
      this.previousGroupIds = cloneDeep(this.vacationRequest.groupIds);
    },
    changeGroup() {
      this.showAlertNoGroup = this.vacationRequest.groupIds.length === 0;
      const newGroupId = this.vacationRequest.groupIds.find(d => !this.previousGroupIds.includes(d));
      if (newGroupId != null) {
        const departments = this.departmentGroups.filter(d => d.id == newGroupId);
        for (const department of departments) {
          if (!this.vacationRequest.departmentIds.includes(department.departmentId)) {
            this.vacationRequest.departmentIds.push(department.departmentId);
          }
        }
      }

      const removedGroupId = this.previousGroupIds.find(d => !this.vacationRequest.groupIds.includes(d));
      if (removedGroupId) {
        const currentDepartments = [];
        if (this.departments.length == 1) {
          currentDepartments.push(this.departments[0].id);
        } else {
          for (const departmentId of this.vacationRequest.departmentIds) {
            const groups = this.departmentGroups.filter(d => d.departmentId == departmentId).map(g => g.id);
            if (this.vacationRequest.groupIds.find(g => groups.includes(g)) != null) {
              currentDepartments.push(departmentId);
            }
          }
        }
        this.vacationRequest.departmentIds = currentDepartments;
        this.groups = this.departmentGroups.filter(d => this.vacationRequest.departmentIds.includes(d.departmentId));
      }
      this.previousDepartmentIds = cloneDeep(this.vacationRequest.departmentIds);
      this.previousGroupIds = cloneDeep(this.vacationRequest.groupIds);
    },
    populateDepartmentsAndGroups() {
      this.departments = [];
      this.groups = [];
      this.departmentGroups = [];

      for (const presenceFilter of this.getPresenceFilters) {
        if (this.vacationRequest.institutionCode === presenceFilter.institutionCode) {
          let departmentList;
          if (this.isEditMode) {
            departmentList = this.activeEvent.vacationRegistration.departments;
          } else {
            departmentList = presenceFilter.departments;
          }

          for (const department of departmentList) {
            if (this.departments.find(dept => dept.id === department.mainGroup.id) == null) {
              this.departments.push({
                id: department.mainGroup.id,
                name: department.mainGroup.name + ' (' + presenceFilter.institutionName + ')',
              });

              if (department.filteringGroups == null) {
                continue;
              }

              for (const group of department.filteringGroups) {
                this.departmentGroups.push({
                  id: group.id,
                  name: group.name + ' (' + presenceFilter.institutionName + ')',
                  departmentId: department.mainGroup.id,
                });
                if (this.groups.find(g => g.id == group.id) == null) {
                  this.groups.push({
                    id: group.id,
                    name: group.name + ' (' + presenceFilter.institutionName + ')',
                    departmentId: department.mainGroup.id,
                  });
                }
              }
            }
          }
        }
      }
      if (!this.isEditMode) {
        this.setDefaultDepartmentsAndGroups();
      }
      this.previousDepartmentIds = cloneDeep(this.vacationRequest.departmentIds);
      this.previousGroupIds = cloneDeep(this.vacationRequest.groupIds);
    },
    setDefaultDepartmentsAndGroups() {
      this.vacationRequest.groupIds = this.groups.map(d => d.id);
      this.vacationRequest.departmentIds = this.departments.map(d => d.id);
    },
    populateVacationRequest() {
      this.vacationRequest.institutionCode = this.isEditMode ? this.activeEvent.institutionCode : null;
      this.vacationRequest.title = this.activeEvent.title;
      this.vacationRequest.startDateTime = this.activeEvent.startDateTime;
      this.vacationRequest.endDateTime = this.activeEvent.endDateTime;
      this.vacationRequest.responseRequired = this.activeEvent.responseRequired;
      this.vacationRequest.responseDeadline = this.activeEvent.responseDeadline;
      this.vacationRequest.isPresenceTimesRequired = this.activeEvent.vacationRegistration.isPresenceTimesRequired;
      this.vacationRequest.noteToGuardians =
        this.activeEvent.description != null ? this.activeEvent.description.html : '';
      this.vacationRequest.departmentIds = this.activeEvent.vacationRegistration.departments.map(d => d.mainGroup.id);
      const startDateTime = moment(this.vacationRequest.startDateTime).format('YYYY-MM-DD');
      this.availableDeadlineDate = {
        disabledDate(time) {
          const today = moment().format('YYYY-MM-DD');
          return moment(time).isBefore(today) || moment(time).isSameOrAfter(startDateTime);
        },
        firstDayOfWeek: 1,
      };
      for (const department of this.activeEvent.vacationRegistration.departments) {
        if (department.filteringGroups) {
          const groups = department.filteringGroups.map(g => g.id);
          this.vacationRequest.groupIds = [...new Set([...this.vacationRequest.groupIds, ...groups])];
        }
      }
      this.previousGroupIds = this.vacationRequest.groupIds;
    },
    resetVacationRequestData() {
      if (!this.isEditMode) {
        const today = moment().format('YYYY-MM-DD');
        if (this.vacationRequest.startDateTime === null) {
          this.endDatePickerOptions = {
            disabledDate(time) {
              return moment(time).isBefore(moment(today));
            },
            firstDayOfWeek: 1,
          };
        }
        this.vacationRequest.institutionCode =
          this.activeInstWithVacationRequestPermission.length > 0
            ? this.activeInstWithVacationRequestPermission[0].institutionCode
            : null;
        this.availableDeadlineDate = {
          disabledDate(time) {
            return moment(time).isBefore(today);
          },
          firstDayOfWeek: 1,
        };
      }
      this.populateDepartmentsAndGroups();
      this.emptyVacationRequest = cloneDeep(this.vacationRequest);
    },
    initData() {
      if (this.hasPermission(permissionEnum.HANDLE_VACATION_REQUESTS) && this.activeInstitutions) {
        if (this.isEditMode) {
          const vacationRequestId = this.$route.params.id || this.selectedVacationRequestId;
          if (this.activeEvent.id != vacationRequestId) {
            this.loadEventById({ eventId: vacationRequestId }).then(() => {
              this.populateVacationRequest();
            });
          } else {
            this.populateVacationRequest();
          }
        }
        this.resetVacationRequestData();
      }
    },
  },
  mounted() {
    this.resetForm();
    this.initData();
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';
@import '../../../shared/assets/scss/components/presence/presence-employee';

.maxDatePicker {
  margin-left: 10px;
  width: 160px !important;
}

#cannot-send-invitation-modal {
  position: absolute;
  left: 35%;
  top: 30%;
  z-index: 9999;
}

.disabledAddon {
  background: #f5f7fa;
}

#cancel-modal .modal-content {
  height: 210px;
}
</style>
