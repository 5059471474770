








import IconContainer from './IconContainer.vue';
import Icon from './Icon.vue';
import { iconClassEnum } from '../enums/iconClassEnum';

export default {
  components: { Icon, IconContainer },
  computed: {
    iconClassEnum() {
      return iconClassEnum;
    },
  },
};
