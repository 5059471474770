<template>
  <div class='widget-W0014V0001'>
    <div data-widgetversion="1.4.5" >
      <div class="p-2 d-flex align-items-center easyiq-wp-widgetheader">
        <div>Vigtig information <span class="pl-1" v-if="weekPlanInfo != null && weekPlanInfo.group != null">{{weekPlanInfo.group.Name}}</span></div>
        <div class="pl-2">
          <div class="easyiq-wp-notificationbrand" v-if="hasNotifications">
            <b-button variant="outlined" ref="button" id="btnNotification">
              <b-img id="imgNotification" :src="baseSchoolPortalUrl + '/Images/24/notification.png'" @click="readNotifications()">
              </b-img>
            </b-button>
            <b-badge variant="danger" class="easyiq-wp-notificationbadge" v-if="notViewedNotifications > 0">
              {{notViewedNotifications}}
            </b-badge>

            <b-popover target="btnNotification" placement="bottomleft" triggers="focus" v-if="hasNotifications">
              <div class="container" :style="getNotificationContentStyle()">
                <div class="row">
                  <div class="col no-gutter">
                    <div :style="getNotificationTopHeaderStyle()">
                      <strong>Notifikationer</strong>
                    </div>
                  </div>
                </div>
                <div class="row" v-if="hasNewNotifications" :style="getNotificationHeaderStyle()">
                  <div class="col">
                    Nye
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <div v-for="note in getNewNotifications()">
                      <div v-if="note.notificationtypeid == 1" :style="getNotificationStyle(note)">
                        <div class="container text-left">
                          <div class="row">
                            <div class="col">
                              <b-link target="_blank" :href="baseSchoolPortalUrl +'aula/authuser/' + ssoLinkUrlParams(baseSchoolPortalUrl + note.url)">
                                <p><strong>{{note.sender.fullname}}</strong> har oprettet et <strong>overblik</strong> til <strong>{{note.target.fullname}}</strong>.</p>
                              </b-link><br />
                            </div>
                          </div>
                          <div class="row">
                            <div class="col">
                              <p style="margin:0;display:inline;"><i class="far fa-history"></i> {{getNotificationAgeText(note)}}</p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div v-if="note.notificationtypeid == 6" :style="getNotificationStyle(note)">
                        <div class="container text-left">
                          <div class="row">
                            <div class="col">
                              <b-link target="_blank" :href="baseSchoolPortalUrl +'aula/authuser/' + ssoLinkUrlParams(baseSchoolPortalUrl + note.url)">
                                <p style="margin:0;"><strong>{{note.sender.fullname}}</strong> har kommenteret <strong>{{note.target.fullname}}</strong>'s <strong>elevplan</strong>.</p>
                              </b-link>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col">
                              <p style="margin:0;display:inline;"><i class="far fa-history"></i> {{getNotificationAgeText(note)}}</p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div v-else-if="note.notificationtypeid == 2" :style="getNotificationStyle(note)">
                        <div class="container text-left">
                          <div class="row">
                            <div class="col">
                              <strong>{{note.sender.fullname}}</strong> har oprettet en <strong>ugeplan</strong> for uge {{note.data[1]}} til <strong>{{note.target.fullname}}</strong>.
                            </div>
                          </div>
                          <div class="row">
                            <div class="col text-left">
                              <p style="margin:0;display:inline;"><i class="far fa-history"></i> {{getNotificationAgeText(note)}}</p>
                            </div>
                            <div class="col text-right">
                              <div role="link" @click="selectedWeekNr = note.data[0] + '-W' + note.data[1]">Gå til uge {{note.data[1]}}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row" v-if="hasNewNotifications && hasOldNotifications" :style="getNotificationHeaderStyle()">
                  <div class="col">
                    Tidligere
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <div v-for="note in getOldNotifications()">
                      <div v-if="note.notificationtypeid == 1" :style="getNotificationStyle(note)">
                        <div class="container text-left">
                          <div class="row">
                            <div class="col">
                              <b-link target="_blank" :href="baseSchoolPortalUrl +'aula/authuser/' + ssoLinkUrlParams(baseSchoolPortalUrl + note.url)">
                                <strong>{{note.sender.fullname}}</strong> har oprettet et <strong>overblik</strong> til {{note.target.fullname}}.
                              </b-link>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div v-if="note.notificationtypeid == 2" :style="getNotificationStyle(note)">
                        <div class="container text-left">
                          <div class="row">
                            <div class="col">
                              <strong>{{note.sender.fullname}}</strong> har oprettet en <strong>ugeplan</strong> for uge {{note.data[1]}} til <strong>{{note.target.fullname}}</strong>.

                            </div>
                          </div>
                          <div class="row">
                            <div class="col text-left">
                              <p style="margin:0;display:inline;"><i class="far fa-history"></i> {{getNotificationAgeText(note)}}</p>
                            </div>
                            <div class="col text-right">
                              <div role="link" @click="selectedWeekNr = note.data[0] + '-W' + note.data[1]">Gå til uge {{note.data[1]}}</div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div v-if="note.notificationtypeid == 3" :style="getNotificationStyle(note)">
                        <div class="container text-left">
                          <div class="row">
                            <div class="col">
                              <b-link target="_blank" :href="baseSchoolPortalUrl +'aula/authuser/' + ssoLinkUrlParams(baseSchoolPortalUrl + note.url)">
                                <p style="margin:0;"><strong>{{note.sender.fullname}}</strong> har kommenteret en <strong>elevplan</strong>.</p>
                              </b-link>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col">
                              <p style="margin:0;display:inline;"><i class="far fa-history"></i> {{getNotificationAgeText(note)}}</p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div v-if="note.notificationtypeid == 6" :style="getNotificationStyle(note)">
                        <div class="container text-left">
                          <div class="row">
                            <div class="col">
                              <b-link target="_blank" :href="baseSchoolPortalUrl +'aula/authuser/' + ssoLinkUrlParams(baseSchoolPortalUrl + note.url)">
                                <p style="margin:0;"><strong>{{note.sender.fullname}}</strong> har kommenteret <strong>{{note.target.fullname}}</strong>'s <strong>elevplan</strong>.</p>
                              </b-link>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col">
                              <p style="margin:0;display:inline;"><i class="far fa-history"></i> {{getNotificationAgeText(note)}}</p>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </b-popover>
          </div>
        </div>
        <div class="ml-auto easyiq-wp-classesDropdown" >
          <b-dropdown text="Klasser" right v-if="weekPlanInfo.groups != null && userProfile.toLowerCase() == 'employee'"
                      toggle-class="p-1 m-0 "
                      size="sm" variant="outline">
            <b-dropdown-item v-for="group in weekPlanInfo.groups"
                             :key="group.Name"
                             :value="group.GroupId"
                             :active="group.GroupId == group"
                             @click="selectedGroup = group.GroupId">{{group.Name}}</b-dropdown-item>
          </b-dropdown>
          <b-dropdown text="Barn"
                      toggle-class="p-1 m-0 easyiq-wp-classesDropdown"
                      size="sm" variant="outline"
                      v-if="userProfile.toLowerCase() == 'parent' || userProfile.toLowerCase() == 'guardian'" right>
            <b-dropdown-item v-for="child in childFilter"
                             :key="child"
                             :value="child"
                             @click="selectedChild = child">{{child}}</b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
      <div class="easyiq-wp-widgetsubheader">
        <div v-if="placement != 'narrow'">
          <template v-for="l in weekPlanInfo.links" v-if="(weekPlanInfo.links != null)">
            <b-link class="p-2 widget-toplink" v-if="l.ssoenable" :href="baseSchoolPortalUrl + 'aula/authuser/' + ssoLinkUrlParams(l.address)" target="_blank">{{l.name}}</b-link>
            <b-link class="p-2 widget-toplink" v-else :href="l.address" target="_blank">{{l.name}}</b-link>
          </template>
        </div>
        <div v-else>
          <b-dropdown text="Links" v-if="(weekPlanInfo.links != null)"
                      toggle-class="p-1 m-0 easyiq-wp-classesDropdown"
                      size="sm" variant="outline">
            <template v-for="l in weekPlanInfo.links" v-if="(weekPlanInfo.links != null)">
              <b-dropdown-item class="p-2 widget-toplink" v-if="l.ssoenable" :href="baseSchoolPortalUrl + 'aula/authuser/' + ssoLinkUrlParams(l.address)" target="_blank">{{l.name}}</b-dropdown-item>
              <b-dropdown-item class="p-2 widget-toplink" v-else :href="l.address" target="_blank">{{l.name}}</b-dropdown-item>
            </template>
          </b-dropdown>
        </div>
      </div>
    </div>
    <div v-if="weekPlanInfo.ErrorCode != null">
      <b-alert show variant="warning">{{weekPlanInfo.ErrorDescription}}</b-alert>
    </div>
    <div v-else>
      <div class="lds-css" v-if="fetchingData" style="align-content:center;height:5em;width:5em;">
        <div class="lds-ring"><div></div><div></div><div></div><div></div><div></div></div>
      </div>

      <div v-if="!fetchingData">
        <div class="easyiq-wp-weekinfocontainer">
          <div class="easyiq-wp-weekinfocontaineruser">
            <span v-if="userProfile.toLowerCase() == 'guardian'">{{displaySelectedChild}}</span>
          </div>
          <div class="easyiq-wp-weekinfocontainerlinks">
            <div class="easyiq-wp-todayweekcontainer">
              <div @click="selectedWeekNr = weekPlanInfo.weekinfo.todaysweeknr" role="link">I dag</div>
            </div>
            <div>
              <div @click="selectedWeekNr = weekPlanInfo.weekinfo.prevweeknr" role="link"><i class="fal fa-chevron-circle-left easyiq-wp-arrowcirle"></i></div>
            </div>
            <div class="easyiq-wp-currentweekcontainer"><span v-if="weekPlanInfo.weekinfo != null">{{weekPlanInfo.weekinfo.currentweeknr}}</span></div>
            <div>
              <div @click="selectedWeekNr = weekPlanInfo.weekinfo.nextweeknr" role="link"><i class="fal fa-chevron-circle-right easyiq-wp-arrowcirle"></i></div>
            </div>
          </div>
        </div>

        <div class="easyiq-wp-events">
          <div class="easyiq-wp-eventContainer">
            <table class="easyiq-wp-weekcontainer" data-wptype="8">
              <template v-for="i in 5">
                <tr>
                  <td class="sm easyiq-wp-dayevent-header">
                    <p>{{dateToFormat(addDay(weekPlanInfo.fromDate,(i-1)))}}</p>
                  </td>
                </tr>
                <tr v-if="(weekPlanInfo.Events != null && weekPlanInfo.Events.length > 0)">
                  <td class="easyiq-wp-weekday">
                    <template v-for="dayEvent in eventsByDateAndType(i,8)">
                      <div class="easyiq-wp-dayinformationevent"
                           data-itemeventype="8"
                           :style="{backgroundColor:'#fff',borderRadius:'4px',border:'1px solid ' + shadeBlendConvert(-0.15,dayEvent.color)}"
                           :key="dayEvent.itemid">
                        <div :style="{textAlign:'right',padding:'0.4em',fontWeight:'bold'}">{{dayEvent.activities}}</div>
                        <p>
                        <div v-if="useHtmlWidgets"><widget-html class="p-1" style="white-space:pre-wrap" :html="dayEvent.description"></widget-html></div>
                        <div v-else style="white-space:pre-wrap;overflow-wrap: break-word;" class="p-1">{{dayEvent.description}}</div>
                        </p>
                        <div :style="{height:'10px',borderRadius:'0 0 3px 3px',backgroundColor:dayEvent.color, }">
                        </div>
                      </div>
                    </template>
                  </td>
                </tr>
              </template>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-info" style="min-height:20px;">
    </div>
  </div>
</template>
<script>
export default  {
  props: {
    /* normal and secure */
    axios: Function,
    sessionUUID: String,
    placement: String,
    currentWeekNumber: String,
    isMobileApp: Boolean,
    /* only secure */
    getAulaToken: Function,
    assuranceLevel: Number,
    childFilter: Array,
    group: String,
    userProfile: String,
    institutionFilter: Array
  },
  data() {
    return {
      aulaToken: null,
      user: {},
      errors: [],
      weekPlanInfo: {},
      monthNames: ["Januar", "Februar", "Marts", "April", "Maj", "Juni", "Juli", "August", "September", "Oktober", "November", "December"],
      shortMonthNames: ["Jan", "Feb", "Mar", "Apr", "Maj", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dec"],
      dayNames: ["Søndag","Mandag", "Tirsdag", "Onsdag", "Torsdag", "Fredag", "Lørdag"],
      baseApiAddress: 'https://api.easyiqcloud.dk/api/aula/',
      selectedGroup: "",
      fetchingData: false,
      selectedWeekNr: "",
      selectedChild: "",
      notificationListIsVisible: false,
      planSort: function ( a, b ) {
        var comparison = 0;
        return ( a.title > b.title ) ? 1 : -1;
      },
      countEvents: function ( typeId ) {
        var countResult = this.weekPlanInfo.Events.filter( ci => ci.itemType == typeId );
        return countResult.length;
      },
      ssoLinkUrlParams: function ( link ) {
        var baseParam = "?token=" + this.aulaToken;
        baseParam += "&userProfile=" + this.userProfile;
        baseParam += "&institutionFilter=" + this.institutionFilter.join( "," );
        baseParam += "&ssoLink=" + link;
        baseParam += `&selectedChild=${this.displaySelectedChild}`;
        return baseParam;
      },
      eventsByType: function ( typeId ) {
        switch ( typeId ) {
          default: {
            var eventTypeEvents = new Array();
            return this.weekPlanInfo.Events.filter( ci => ci.itemType == typeId );
            break;
          }
        }
      },
      eventsByDateAndType: function ( dayOfWeek, typeId ) {
        switch ( typeId ) {
          default: {
            var eventTypeEvents = new Array();
            return this.weekPlanInfo.Events.filter( ci => ci.itemType == typeId && new Date( ci.start ).getDay() == dayOfWeek );
            break;
          }
        }
      },
      dateToFormat:function( date ) {
        var year = date.getFullYear();
        var month = date.getMonth() + 1;
        var day = date.getDate();

        return this.dayNames[date.getDay()] + " " + day + " " + this.shortMonthNames[date.getMonth()];
      },
      addDay: function ( date, days ) {
        var dat = new Date( date );
        dat.setDate( dat.getDate() + days );
        return dat;
      },
      dateOnlyCompare: function ( a,b ) {
        var today = new Date( a.getFullYear(), a.getMonth(), a.getDate() );
        var bDateOnly = new Date( b.getFullYear(), b.getMonth(), b.getDate() );

        if ( today > bDateOnly ) { return 1; }
        else if ( today < bDateOnly ) { return -1; }
        else return 0;
      },
      getNotificationStyle: function ( n ) {
        return {
          "background-color": ( n.viewed ? "#FFF" : "aliceblue"),
          "font-size": "0.8em",
          "margin": "1px 0",
          "padding": "2px"
        };
      },
      getNotificationAgeText: function ( n ) {
        var hour = 60;
        var day = hour * 24;
        var week = day * 7;
        var ageInMinutes = Math.round( ( ( new Date() - new Date( n.timestamp ) ).valueOf() / 60000 ) );
        if ( ageInMinutes < 3 ) {
          return "Lige nu";
        }
        else if ( ageInMinutes >= hour && ageInMinutes < day )
        {
          var hours = Math.round(ageInMinutes / hour);
          return hours + ( ( hours > 1 ) ? ' timer' : ' time');
        }
        else if ( ageInMinutes >= day && ageInMinutes < week )
        {
          var days = Math.round(ageInMinutes / day);
          return days + ( ( days > 1 ) ? ' dage' : ' dag' );
        }
        else if ( ageInMinutes >= week) {
          var weeks = Math.round( ageInMinutes / week );
          return weeks + ( ( weeks > 1 ) ? ' uger' : ' uge' );
        }
        else {
          return ageInMinutes + " minutter";
        }
      },
      getNotificationHeaderStyle: function () {
        return {
          "background-color": "transparent",
          "font-size": "1em",
          "padding": "5px 10px",
          "color": "#12798c"
        };
      },
      getNotificationTopHeaderStyle: function () {
        return {
          "background-color": "#FFF",
          "font-size": "1em",
          "padding": "5px 10px",
          "color": "#12798c"
        };
      },
      getNotificationContentStyle: function () {
        return {
          "background-color": "#EEE",
          "padding":"2px"
        };
      },
      getNewNotifications: function () {
        if ( this.hasNotifications ) {
          var now = new Date();
          var nList = this.weekPlanInfo.notifications.notificationlist.filter( n => ( now - new Date( n.timestamp ) ).valueOf() < ( 3600 * 1000 ) );
          return nList;
        }
        return null;
      },
      getOldNotifications: function () {
        if ( this.hasNotifications ) {
          var now = new Date();
          var nList = this.weekPlanInfo.notifications.notificationlist.filter( n => ( now - new Date( n.timestamp ) ).valueOf() > ( 3600 * 1000 ) );
          return nList;
        }
        return null;
      },
      shadeBlendConvert: function ( p, from, to ) {
        if ( typeof ( p ) != "number" || p < -1 || p > 1 || typeof ( from ) != "string" || ( from[0] != 'r' && from[0] != '#' ) || ( to && typeof ( to ) != "string" ) ) return null; //ErrorCheck
        if ( !this.sbcRip ) {
          this.sbcRip = function ( d ) {
            var l = d.length;
            var RGB = {};
            if ( l > 9 ) {
              d = d.split( "," );
              if ( d.length < 3 || d.length > 4 ) return null;//ErrorCheck
              RGB[0] = i( d[0].split( "(" )[1] ), RGB[1] = i( d[1] ), RGB[2] = i( d[2] ), RGB[3] = d[3] ? parseFloat( d[3] ) : -1;
            } else {
              if ( l == 8 || l == 6 || l < 4 ) return null; //ErrorCheck
              if ( l < 6 ) d = "#" + d[1] + d[1] + d[2] + d[2] + d[3] + d[3] + ( l > 4 ? d[4] + "" + d[4] : "" ); //3 or 4 digit
              d = i( d.slice( 1 ), 16 ), RGB[0] = d >> 16 & 255, RGB[1] = d >> 8 & 255, RGB[2] = d & 255, RGB[3] = -1;
              if ( l == 9 || l == 5 ) RGB[3] = r( ( RGB[2] / 255 ) * 10000 ) / 10000, RGB[2] = RGB[1], RGB[1] = RGB[0], RGB[0] = d >> 24 & 255;
            }
            return RGB;
          }
        }
        var i = parseInt, r = Math.round, h = from.length > 9, h = typeof ( to ) == "string" ? to.length > 9 ? true : to == "c" ? !h : false : h, b = p < 0, p = b ? p * -1 : p, to = to && to != "c" ? to : b ? "#000000" : "#FFFFFF", f = this.sbcRip( from ), t = this.sbcRip( to );
        if ( !f || !t ) return null; //ErrorCheck
        if ( h ) return "rgb" + ( f[3] > -1 || t[3] > -1 ? "a(" : "(" ) + r( ( t[0] - f[0] ) * p + f[0] ) + "," + r( ( t[1] - f[1] ) * p + f[1] ) + "," + r( ( t[2] - f[2] ) * p + f[2] ) + ( f[3] < 0 && t[3] < 0 ? ")" : "," + ( f[3] > -1 && t[3] > -1 ? r( ( ( t[3] - f[3] ) * p + f[3] ) * 10000 ) / 10000 : t[3] < 0 ? f[3] : t[3] ) + ")" );
        else return "#" + ( 0x100000000 + r( ( t[0] - f[0] ) * p + f[0] ) * 0x1000000 + r( ( t[1] - f[1] ) * p + f[1] ) * 0x10000 + r( ( t[2] - f[2] ) * p + f[2] ) * 0x100 + ( f[3] > -1 && t[3] > -1 ? r( ( ( t[3] - f[3] ) * p + f[3] ) * 255 ) : t[3] > -1 ? r( t[3] * 255 ) : f[3] > -1 ? r( f[3] * 255 ) : 255 ) ).toString( 16 ).slice( 1, f[3] > -1 || t[3] > -1 ? undefined : -2 );
      },
      initWidget: function () {
        this.errors.push( { message: "henter data" } );
        this.fetchingData = true;
        this.axios.post(
          this.baseApiAddress + 'weekinfo',
          this.aulaFilter,
          {
            headers:
              {
                'Authorization': 'bearer ' + this.aulaToken,
                'x-aula-userprofile': this.userProfile,
                'x-aula-institutionfilter': this.institutionFilter,
                'Content-Type': 'application/json'
              }
          } )
          .then( response => {
            this.fetchingData = false;
            this.weekPlanInfo = response.data;
          } );
      },
      readNotifications: function () {
        this.axios.post(
          this.baseApiAddress + 'notifications/updateviewed',
          this.aulaFilter,
          {
            headers:
              {
                'Authorization': 'bearer ' + this.aulaToken,
                'x-aula-userprofile': this.userProfile,
                'x-aula-institutionfilter': this.institutionFilter,
                'Content-Type': 'application/json'
              }
          })
          .then( response => {
              if ( this.weekPlanInfo != null && this.weekPlanInfo.notifications != null ) {
                if ( this.weekPlanInfo.notifications.notificationlist != null && this.weekPlanInfo.notifications.notificationlist.length > 0 ) {
                  for ( var i = 0; i < this.weekPlanInfo.notifications.notificationlist.length; i++ ) {
                    if ( this.weekPlanInfo.notifications.notificationlist[i] ) {
                      this.weekPlanInfo.notifications.notificationlist[i].read = true;
                    }
                  }
                  this.$emit( 'updateUnread', this.notViewedNotifications );
                }
              }
            }
          );
      },
      listNotifications: function () {
        if ( this.weekPlanInfo != null ) {
          this.axios.post(
            this.baseApiAddress + 'notifications/list',
            this.aulaFilter,
            {
              headers:
                {
                  'Authorization': 'bearer ' + this.aulaToken,
                  'x-aula-userprofile': this.userProfile,
                  'x-aula-institutionfilter': this.institutionFilter,
                  'Content-Type': 'application/json'
                }
            } )
            .then( response => {
              this.weekPlanInfo.notifications = response.data;
              this.$emit( 'updateUnread', this.notViewedNotifications );
            } );
        }
      }
    }
  },
  computed: {
    aulaFilter: function () {
      return {
        sessionId: this.sessionUUID,
        group: this.selectedGroup != "" ? this.selectedGroup : this.group,
        currentWeekNr: this.selectedWeekNr != "" ? this.selectedWeekNr : this.currentWeekNumber,
        userProfile: this.userProfile,
        institutionFilter: this.institutionFilter,
        childFilter: this.selectedChild != "" ? [this.selectedChild.trim()] : this.childFilter
      }
    },
    selectedGroupName: function () {
      if ( this.group ) {
        if ( this.weekPlanInfo.groups != null ) {
          return this.weekPlanInfo.groups.filter( g => g.GroupId == this.group ).Name;
        }
      }
      return "";
    },
    displaySelectedChild: function () {
      return this.selectedChild != "" ? this.selectedChild.trim() : this.childFilter[0];
    },
    isWidgetTest: function () {
      if ( window.origin ) {
        if ( ( window.origin ).includes( 'widget-test' ) ) {
          return true;
        }
      }
      else if ( window.location.origin ) {
        if ( ( window.location.origin ).includes( 'widget-test' ) ) {
          return true;
        }
      }
      else {
        return false;
      }
    },
    baseSchoolPortalUrl: function () {
      try {
        return this.isWidgetTest ? "Https://skoleportal-udv.easyiqcloud.dk/" : "Https://skoleportal.easyiqcloud.dk/";
      }
      catch(e){
        return "Https://skoleportal.easyiqcloud.dk/";
      }

    },
    notViewedNotifications: function () {
      if ( !this.fetchingData ) {
        if ( this.weekPlanInfo != null && this.weekPlanInfo.notifications != null ) {
          if ( this.weekPlanInfo.notifications.notificationlist != null && this.weekPlanInfo.notifications.notificationlist.length > 0 ) {
            return this.weekPlanInfo.notifications.notificationlist.filter( n => n.viewed == false ).length;
          }

        }
      }
      return 0;
    },
    hasNotifications: function () {
      if ( !this.fetchingData ) {
        if ( this.weekPlanInfo != null && this.weekPlanInfo.notifications != null ) {
          if ( this.weekPlanInfo.notifications.notificationlist != null && this.weekPlanInfo.notifications.notificationlist.length > 0 )
            return true;
        }
      }
      return false;
    },
    hasNewNotifications: function () {
      if ( this.hasNotifications ) {
        var newNotifications = this.getNewNotifications();
        return ( newNotifications != null && newNotifications.length > 0 );
      }
    },
    hasOldNotifications: function () {
      if ( this.hasNotifications ) {
        var oldNotifications = this.getOldNotifications();
        return ( oldNotifications != null && oldNotifications.length > 0 );
      }
    },
    useHtmlWidgets: function () {
      if (this.weekPlanInfo != null && this.weekPlanInfo.weekplanconfiguration != null) {
        return this.weekPlanInfo.weekplanconfiguration.usehtmlwidget;
      }
      return false;
    }
  },
  watch: {
    aulaToken: function () {
      if (this.aulaToken !== undefined) {
        this.initWidget();
      }
    },
    aulaFilter() {
      this.aulaToken = this.getAulaToken();
    }
  }
  , created() {
    try {
      if ( this.isWidgetTest ) {
        this.baseApiAddress = "https://test-api.easyiqcloud.dk/api/aula/";
      }
    }
    catch(e){

    }

    this.aulaToken = this.getAulaToken();
  }
}
</script>
<style lang="scss">
.widget-W0014V0001 {
.easyiq-wp-widgetheader
{
  background-color:#17a2b8;
  color:#000;
  font-size:1em;
}
.easyiq-wp-widgetsubheader{
  background-color:#45b7c1;
  color:#FFF;
  font-size:1em;
}

.easyiq-wp-classesDropdown .dropdown-toggle{
  background-color:transparent;
}

.easyiq-wp-classesDropdown{
  background-color:transparent;
}
/*bootstrap overrides*/

.easyiq-wp-classesDropdown /deep/ .dropdown-menu
{
  height: auto;
  max-height: 200px;
  overflow-x: hidden;
  font-size:0.9em;
}

/*bootstrap overrides end*/
.easyiq-spMain {
  font-size:0.8em;
}

.easyiq-wp-notificationitem {
  font-size: 0.9em;
  margin: 5px 0;
}

.easyiq-wp-notificationitem [data-notification-read='false'] {
  background-color: aliceblue !important;
}

.easyiq-wp-notificationitem [data-notification-read='true'] {
}

.easyiq-wp-notificationbadge {
  position: absolute;
  top: 0.2em;
  right: -0.4em;
  height: 1.4em;
  font-size: 0.6em;
  width: 1.4em;
}
.easyiq-wp-notificationbrand {
  position: relative;
}
.easyiq-wp-notificationbrand button{
  background:transparent;
  padding:0;
  margin:0;
}
.easyiq-wp-notificationbrand popover-body {
  padding: 0 !important;
}

.easyiq-wp-info {
  font-weight: normal;
  text-align: left;
  border-radius: 4px;
  background-color: rgba(212, 239, 255,1);
  border: 1px solid rgba(212, 239, 255,0.7);
  padding: 5px 35px 0 5px;
  min-height: 80px;
  margin:10px 0;
}
.easyiq-wp-eventContainer-title
{
  z-index:10;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 1.2em;
  font-weight: bold;
  padding: 0.2em;
}
.easyiq-wp-weekcontainer[data-wptype="8"] {
  background-color: #92b1c6;
  border: 1px solid #81a5bd;
}

.easyiq-wp-weekcontainer[data-wptype="9"] {
  background-color: #d4efff;
  border: 1px solid #bbe6ff;
}

.easyiq-wp-weekcontainer[data-wptype="5"] {
  background-color: #8dbfe0;
  border: 1px solid #79b4db;
}

.easyiq-wp-weekcontainer[data-wptype="2"] {
  background-color: #a5dbff;
  border: 1px solid #8bd1ff;
}

.easyiq-wp-weekcontainer[data-wptype="6"] {
  background-color: rgba(255, 255, 255, 0.5);
  border: 1px solid rgba(242, 242, 242, 0.5);
}

.easyiq-wp-eventContainer {
  position:relative;
  width: 100%;
}

.easyiq-wp-eventContainer > h2 {
  text-align: center;
  margin: 0;
}
.easyiq-wp-dayinformationevent {
  font-weight: normal;
  text-align: left;
  border-radius: 4px;
  margin: 10px;
}
.easyiq-wp-dayinformationevent /deep/ p {
  padding: 0.4em;
}
.easyiq-wp-weekcontainer tr > .easyiq-wp-weekday {
  font-size: 0.9em;
  font-weight: bold;
  margin: 0;
  height: 100%;
  text-align: center;
  align-content: space-around;
  align-items: flex-start;
  list-style: none;
  position: relative;
  color: #000;
}

.easyiq-wp-weekcontainer tr > .wp-weekday img {
  opacity: 0.4;
}

.easyiq-wp-weekcontainer tr > .wp-weekday img:hover {
  opacity: 1;
}

.easyiq-wp-weekcontainer tr > .wp-weekday .wp-dayEvents {
  width: 100%;
  height: 100%;
}

.easyiq-wp-weekcontainer {
  width: 100%;
  margin: 0;
  color: black;
  border: 1px solid rgba(182, 182, 182, 0.88);
  position: relative;
  table-layout: fixed;
  height: 60px;
  color: #FFF;
}
.easyiq-wp-dayinformationevent p {
  padding: 0 5px 0 5px;
  margin: 5px 0;
}
.easyiq-wp-weekcontainer tr {
  font-size: 0.9em;
  font-weight: bold;
  margin: 0;
  height: 100%;
  text-align: center;
  align-content: space-around;
  align-items: flex-start;
  list-style: none;
  border-left: 1px solid rgba(182, 182, 182, 0.88);
  position: relative;
}
.easyiq-wp-weekcontainer td
{
  vertical-align:top;
}

.easyiq-wp-weekcontainer[data-showdays="1"] {
  color:#000;
  height: 20px;
}

.easyiq-wp-weekday video, .easyiq-wp-info video {
  width: 100%;
}
.easyiq-wp-dayevent-header
{
  background-color:rgb(98, 130, 152);
  border:1px solid rgba(128, 128, 128,0.6);
}
.easyiq-wp-dayevent {
}

.easyiq-plandetail-skillmethod {
  font-size: 0.8em;
}

.easyiq-plandetail-skillmethod:before {
  content: "\2022";
}
.easyiq-wp-day-plancontainer {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  width: 100%;
  height: 35px;
}
.easyiq-wp-day-plancontainer img {
  opacity: 0.4;
}
.easyiq-wp-day-plancontainer img:hover {
  opacity: 1;
}
.easyiq-wp-day-plancontainer img {
  margin: 0 5px;
  width: 20px;
}
.easyiq-wp-day-plancontainer p {
  font-size: 1.0em;
  margin: 0;
  padding: 5px;
  -moz-text-overflow: ellipsis;
  -webkit-text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.easyiq-wp-day-plancontainer .easyiq-wp-day-plan-start {
  position: absolute;
  left: 0;
  width: 10px;
  height: 100%;
  background-color: rgba(43, 43, 43, 0.6);
}

.easyiq-wp-day-plancontainer .easyiq-wp-day-plan-title {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  -webkit-align-items: center;
  align-items: center;

}
.widget-toplink a {
  color: #FFF;
  text-decoration: underline;
  text-align:right;
}

.easyiq-wp-day-plan-title a{
  color:#FFF;
  text-decoration:underline;
}

.easyiq-wp-day-plancontainer .easyiq-wp-day-plan-courses {
  font-style: italic;
  font-size: 0.8em;
}
.easyiq-wp-day-plancontainer .easyiq-wp-day-plan-end {
  position: absolute;
  right: 0;
  width: 10px;
  height: 100%;
  background-color: rgba(43, 43, 43, 0.6);
}
.easyiq-wp-day-plancontainer-hideStart .easyiq-wp-day-plan-start {
  display: none !important;
}
.easyiq-wp-day-plancontainer-hideEnd .easyiq-wp-day-plan-end {
  display: none !important;
}

.easyiq-wp-dayassignmentevent {
  width: 85%;
  border: 1px solid rgba(124, 124, 124, 0.51);
  border-radius: 4px;
  margin: 10px;
}

.easyiq-wp-dayassignmentevent p {
  padding: 0 5px 0 5px;
  margin: 5px 0;
}

.easyiq-wp-dayassignmentevent .easyiq-wp-dayassignmentevent-description {
  font-weight: normal;
  text-align: left;
  padding: 5px;
}

.easyiq-wp-dayassignmentevent .easyiq-wp-dayassignmentevent-course {
  font-weight: normal;
  font-size: 0.8em;
  text-align: left;
  font-style: italic;
}

.easyiq-wp-dayassignmentevent .easyiq-wp-dayassignmentevent-title {
  text-align: left;
  font-weight: bold;
}

.easyiq-wp-dayassignmentevent div[data-icontitle] {
  width: 100%;
}
.easyiq-wp-weekcontainer-datecolumn {
  border-left: 1px solid rgba(255, 255, 255, 1.00);
  text-align: center;
  font-size: 0.95em;
  text-transform: capitalize;
  color: rgba(0,0,0,0.7);
  height:1.5em;
}
.easyiq-wp-weekinfocontainerlinks {
  justify-content: flex-end;
  -webkit-justify-content: flex-end;
  display: flex;
  margin-left:auto;
}
.easyiq-wp-weekinfocontainer {
  display: flex;
  background-color: #D9DFE3;
  color: #000;
  padding:0.4em;
}

.easyiq-wp-weekinfocontainerlinks div {
  margin: 0 2px;
}

.easyiq-wp-currentweekcontainer {
  display: flex;
  align-items: center;
  -webkit-align-items: center;
}

.easyiq-wp-todayweekcontainer {
  display: flex;
  align-items: center;
  -webkit-align-items: center;
  margin-right: 10px;
}
.easyiq-wp-weekinfocontaineruser {
  font-size: 0.8em;
  justify-content: center;
  align-self: center;
  color: #12798c;
}

* div[role='link']
{
  cursor:pointer;
  color: #000;
}
* div[role='link']:hover
{
  text-decoration:underline;
}

.easyiq-wp-arrowcirle{
  transform:scale(1.3);
  margin:2px;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0)
  }

  100% {
    transform: rotate(360deg)
  }
}

.lds-css {
  width: 240px;
  height: 240px;
  margin: auto;
  transform: scale(0.7);
}

.lds-ring {
  height: 100%;
  width: 100%;
}

.lds-ring > div {
  position: absolute;
  top: 10%;
  left: 10%;
  width: 80%;
  height: 80%;
  border-radius: 50%;
  border: 10px solid #fff;
  border-color: #fff transparent transparent transparent;
  animation: lds-ring 1.5s cubic-bezier(0.5,0,0.5,1) infinite;
}

.lds-ring > div:nth-child(1) {
  border-color: rgba(0, 122, 141, 0.25);
  animation: none;
}

.lds-ring > div:nth-child(3) {
  animation-delay: .19s;
}

.lds-ring > div:nth-child(4) {
  animation-delay: .38s;
}

.lds-ring > div:nth-child(5) {
  animation-delay: .57s;
}
}
</style>
