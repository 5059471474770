<template>
  <div class="shortcuts">
    <a v-for="icon in icons" :key="icon.icon" :href="icon.url" target="_blank" :title="icon.name">

      <svg v-if="icon.icon == 'teams'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
        <circle cx="17" cy="6" r="4.7" fill="#7b83eb"/>
        <path d="M16.7 7h-4.3v.1a4.7 4.7 0 0 0 5.6 3.5V8.3A1.3 1.3 0 0 0 16.7 7z" opacity=".1"/>
        <path d="M15.7 8h-3a4.7 4.7 0 0 0 4.3 2.7V9.3A1.3 1.3 0 0 0 15.7 8z" opacity=".2"/>
        <circle data-name="Head" cx="27.5" cy="7.5" r="3.5" fill="#5059c9"/>
        <path d="M30.5 12h-7.9a.6.6 0 0 0-.6.6v8.1a5.1 5.1 0 0 0 4 5.1 5 5 0 0 0 6-4.9v-7.4a1.5 1.5 0 0 0-1.5-1.5z" fill="#5059c9"/>
        <path d="M25 13.5V23a8 8 0 0 1-15 4 7.2 7.2 0 0 1-.4-1 8.4 8.4 0 0 1-.3-1 8.2 8.2 0 0 1-.3-2v-9.5a1.5 1.5 0 0 1 1.5-1.5h13a1.5 1.5 0 0 1 1.5 1.5z" fill="#7b83eb"/>
        <path d="M15.7 8h-3a4.7 4.7 0 0 0 4.3 2.7V9.3A1.3 1.3 0 0 0 15.7 8z" opacity=".2"/>
        <path d="M18 12v12.7a1.3 1.3 0 0 1-1 1.3 1 1 0 0 1-.3 0H9.6a8.4 8.4 0 0 1-.3-1 8.2 8.2 0 0 1-.3-2v-9.5a1.5 1.5 0 0 1 1.5-1.5z" opacity=".1"/>
        <path d="M17 12v13.7a1 1 0 0 1 0 .3 1.3 1.3 0 0 1-1.3 1H10a7.2 7.2 0 0 1-.5-1 8.4 8.4 0 0 1-.3-1 8.2 8.2 0 0 1-.3-2v-9.5a1.5 1.5 0 0 1 1.5-1.5z" opacity=".2"/>
        <path d="M17 12v11.7a1.3 1.3 0 0 1-1.3 1.3H9.3a8.2 8.2 0 0 1-.3-2v-9.5a1.5 1.5 0 0 1 1.5-1.5z" opacity=".2"/>
        <path d="M10.5 12A1.5 1.5 0 0 0 9 13.5V23a8.2 8.2 0 0 0 .3 2h5.4a1.3 1.3 0 0 0 1.3-1.3V12z" opacity=".2"/>
        <path data-name="Back Plate" d="M1.3 8h13.4A1.3 1.3 0 0 1 16 9.3v13.4a1.3 1.3 0 0 1-1.3 1.3H1.3A1.3 1.3 0 0 1 0 22.7V9.3A1.3 1.3 0 0 1 1.3 8z" fill="#4b53bc"/>
        <path d="M12 13H9v8H7v-8H4v-2h8z" fill="#fff"/>
        <path fill="none" d="M0 0h32v32H0z"/>
      </svg>

      <svg v-else-if="icon.icon == 'onedrive'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
        <path d="M12.2 11.2l6.7 4 4-1.7a6.5 6.5 0 0 1 2.6-.5h.4a10 10 0 0 0-18-3H8a8 8 0 0 1 4.2 1.2z" fill="#0364b8"/>
        <path d="M12.2 11.2A8 8 0 0 0 8 10h-.1a8 8 0 0 0-6.5 12.6l6-2.5L10 19l5.9-2.5 3-1.3z" fill="#0078d4"/>
        <path d="M26 13h-.5a6.5 6.5 0 0 0-2.6.5l-4 1.7 1.2.7 3.8 2.3 1.6 1 5.7 3.4A6.5 6.5 0 0 0 26 13z" fill="#1490df"/>
        <path d="M25.5 19.2l-1.6-1-3.8-2.3-1.2-.7-3 1.3-6 2.5-2.5 1-6 2.6A8 8 0 0 0 8 26h17.5a6.5 6.5 0 0 0 5.7-3.4z" fill="#28a8ea"/>
      </svg>

      <svg v-else-if="icon.icon == 'word'" xmlns="http://www.w3.org/2000/svg" viewBox="0 -30 474 474">
        <path d="M454.2 0h-316a19.7 19.7 0 0 0-19.7 19.7v83.9l177.8 51.8L474 103.7v-84C474 8.8 465.2 0 454.2 0z" fill="#41a5ee"/>
        <path fill="#2b7cd3" d="M474 103.7H118.5v103.7l177.7 51.8L474 207.4z"/>
        <path fill="#185abd" d="M474 207.4H118.5v103.7l177.7 51.8L474 311.1z"/>
        <path d="M474 311.1H118.5V395c0 10.9 8.8 19.8 19.8 19.8h316c10.9 0 19.7-8.8 19.7-19.8v-83.9z" fill="#103f91"/>
        <path d="M246.9 74.1H118.5v281.4h128.4a20 20 0 0 0 19.8-19.8V93.8a19.8 19.8 0 0 0-19.8-19.7z" opacity=".1"/>
        <path opacity=".2" enable-background="new" d="M232.1 88.9H118.5v281.4h113.6a20 20 0 0 0 19.8-19.8V108.6a19.8 19.8 0 0 0-19.8-19.7z"/>
        <path opacity=".2" enable-background="new" d="M232.1 88.9H118.5v251.8h113.6a20 20 0 0 0 19.8-19.8V108.6a19.8 19.8 0 0 0-19.8-19.7z"/>
        <path opacity=".2" enable-background="new" d="M217.2 88.9h-98.7v251.8h98.7a20 20 0 0 0 19.8-19.8V108.6c0-10.9-8.9-19.7-19.8-19.7z"/>
        <path id="Back_Plate" fill="#185abd" d="M19.7 88.9h197.5c10.9 0 19.8 8.8 19.8 19.7v197.5c0 10.9-8.8 19.8-19.8 19.8H19.7A19.8 19.8 0 0 1 0 306.1V108.6c0-10.9 8.8-19.7 19.7-19.7z"/>
        <path id="Letter" d="M177 281.4h-26.7l-31.1-102.2-32.5 102.2H60L30.4 133.3H57L77.8 237l31.1-100.7h22.2L160.7 237l20.7-103.7h25.2L177 281.4z" fill="#fff"/>
      </svg>

      <svg v-else-if="icon.icon == 'excel'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
        <path d="M20 2H9.3A1.3 1.3 0 0 0 8 3.3V9l12 7 6 2.5 6-2.5V9z" fill="#21a366"/>
        <path fill="#107c41" d="M8 9h12v7H8z"/>
        <path d="M30.7 2H20v7h12V3.3A1.3 1.3 0 0 0 30.7 2z" fill="#33c481"/>
        <path d="M20 16H8v12.7A1.3 1.3 0 0 0 9.3 30h21.4a1.3 1.3 0 0 0 1.3-1.3V23z" fill="#185c37"/>
        <path fill="#107c41" d="M20 16h12v7H20z"/>
        <path d="M16.7 7H8v19h8.7a1.3 1.3 0 0 0 1.3-1.3V8.3A1.3 1.3 0 0 0 16.7 7z" opacity=".1"/>
        <path d="M15.7 8H8v19h7.7a1.3 1.3 0 0 0 1.3-1.3V9.3A1.3 1.3 0 0 0 15.7 8z" opacity=".2"/>
        <path d="M15.7 8H8v17h7.7a1.3 1.3 0 0 0 1.3-1.3V9.3A1.3 1.3 0 0 0 15.7 8z" opacity=".2"/>
        <path d="M14.7 8H8v17h6.7a1.3 1.3 0 0 0 1.3-1.3V9.3A1.3 1.3 0 0 0 14.7 8z" opacity=".2"/>
        <path data-name="Back Plate" d="M1.3 8h13.4A1.3 1.3 0 0 1 16 9.3v13.4a1.3 1.3 0 0 1-1.3 1.3H1.3A1.3 1.3 0 0 1 0 22.7V9.3A1.3 1.3 0 0 1 1.3 8z" fill="#107c41"/>
        <path d="M3.5 21l3.3-5-3-5h2.4l1.6 3.2.3.7.4-.7 1.7-3.2h2.2l-3 5 3 5h-2.3l-1.8-3.5A3 3 0 0 1 8 17a2.2 2.2 0 0 1-.2.5l-2 3.5z" fill="#fff"/>
        <path fill="none" d="M0 0h32v32H0z"/>
      </svg>

      <svg v-else-if="icon.icon == 'powerpoint'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
        <path d="M18 2A14 14 0 0 0 4 16l17.7 3.7z" fill="#ed6c47"/>
        <path d="M18 2a14 14 0 0 1 14 14l-7 4.8-7-4.8z" fill="#ff8f6b"/>
        <path d="M18 30a14 14 0 0 0 14-14H4a14 14 0 0 0 14 14z" fill="#d35230"/>
        <path d="M16.7 7H7.3a14 14 0 0 0 1 19h8.4a1.3 1.3 0 0 0 1.3-1.3V8.3A1.3 1.3 0 0 0 16.7 7z" opacity=".1"/>
        <path d="M15.7 8H6.5a14 14 0 0 0 2.9 19h6.3a1.3 1.3 0 0 0 1.3-1.3V9.3A1.3 1.3 0 0 0 15.7 8z" opacity=".2"/>
        <path d="M15.7 8H6.5a13.9 13.9 0 0 0 .8 17h8.4a1.3 1.3 0 0 0 1.3-1.3V9.3A1.3 1.3 0 0 0 15.7 8z" opacity=".2"/>
        <path d="M14.7 8H6.5a13.9 13.9 0 0 0 .8 17h7.4a1.3 1.3 0 0 0 1.3-1.3V9.3A1.3 1.3 0 0 0 14.7 8z" opacity=".2"/>
        <path data-name="Back Plate" d="M1.3 8h13.4A1.3 1.3 0 0 1 16 9.3v13.4a1.3 1.3 0 0 1-1.3 1.3H1.3A1.3 1.3 0 0 1 0 22.7V9.3A1.3 1.3 0 0 1 1.3 8z" fill="#c43e1c"/>
        <path d="M8 11a4.2 4.2 0 0 1 2.8.8 2.9 2.9 0 0 1 1 2.3 2.7 2.7 0 0 1-.6 1.6A3.2 3.2 0 0 1 10 17a4.6 4.6 0 0 1-2 .4h-2V21H4V11zm-2 4.7h1.6a2.2 2.2 0 0 0 1.5-.5 1.1 1.1 0 0 0 .5-1q0-1.6-1.9-1.6H6z" fill="#f9f7f7"/>
        <path fill="none" d="M0 0h32v32H0z"/>
      </svg>

      <svg v-else-if="icon.icon == 'onenote'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
        <path d="M9.3 2h21.4A1.3 1.3 0 0 1 32 3.3v25.4a1.3 1.3 0 0 1-1.3 1.3H9.3A1.3 1.3 0 0 1 8 28.7V3.3A1.3 1.3 0 0 1 9.3 2z" fill="#ca64ea"/>
        <path d="M32 3.3A1.3 1.3 0 0 0 30.7 2H9.3A1.3 1.3 0 0 0 8 3.3v25.4A1.3 1.3 0 0 0 9.3 30H25l7-21z" fill="#ca64ea"/>
        <path fill="#ae4bd5" d="M32 9h-7v7l3.5 3.2L32 16V9z"/>
        <path fill="#9332bf" d="M32 16h-7v7l3.5 3.4L32 23v-7z"/>
        <path d="M25 23h7v5.7a1.3 1.3 0 0 1-1.3 1.3H25v-7z" fill="#7719aa"/>
        <path d="M16.7 7H8v19h8.7a1.3 1.3 0 0 0 1.3-1.3V8.3A1.3 1.3 0 0 0 16.7 7z" opacity=".1"/>
        <path d="M15.7 8H8v19h7.7a1.3 1.3 0 0 0 1.3-1.3V9.3A1.3 1.3 0 0 0 15.7 8z" opacity=".2"/>
        <path d="M15.7 8H8v17h7.7a1.3 1.3 0 0 0 1.3-1.3V9.3A1.3 1.3 0 0 0 15.7 8z" opacity=".3"/>
        <path d="M14.7 8H8v17h6.7a1.3 1.3 0 0 0 1.3-1.3V9.3A1.3 1.3 0 0 0 14.7 8z" opacity=".2"/>
        <path data-name="Back Plate" d="M1.3 8h13.4A1.3 1.3 0 0 1 16 9.3v13.4a1.3 1.3 0 0 1-1.3 1.3H1.3A1.3 1.3 0 0 1 0 22.7V9.3A1.3 1.3 0 0 1 1.3 8z" fill="#7719aa"/>
        <path d="M4 11h2.1l3.7 6.7.4.7a9.5 9.5 0 0 1 0-1.3V11H12v10h-2l-3.8-6.6a5.1 5.1 0 0 1-.4-.7 13.6 13.6 0 0 1 0 1.4V21H4z" fill="#fff"/>
        <path fill="none" d="M0 0h32v32H0z"/>
      </svg>

      <svg v-else-if="icon.icon == 'outlook'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
        <path d="M28.596 2H11.404A1.404 1.404 0 0010 3.404V5l9.69 3L30 5V3.404A1.404 1.404 0 0028.596 2z" fill="#0364b8" />
        <path d="M31.65 17.405A11.341 11.341 0 0032 16a.666.666 0 00-.333-.576l-.013-.008-.004-.002-10.838-6.175a1.499 1.499 0 00-.145-.082 1.5 1.5 0 00-1.334 0 1.49 1.49 0 00-.145.082L8.35 15.415l-.004.002-.012.007A.666.666 0 008 16a11.344 11.344 0 00.35 1.405l11.492 8.405z" fill="#0a2767" />
        <path fill="#28a8ea" d="M24 5h-7l-2.021 3L17 11l7 6h6v-6l-6-6z" />
        <path fill="#0078d4" d="M10 5h7v6h-7z" />
        <path fill="#50d9ff" d="M24 5h6v6h-6z" />
        <path fill="#0364b8" d="M24 17l-7-6h-7v6l7 6 10.832 1.768L24 17z" />
        <path fill="none" d="M10.031 5H30" />
        <path fill="#0078d4" d="M17 11h7v6h-7z" />
        <path fill="#064a8c" d="M10 17h7v6h-7z" />
        <path fill="#0078d4" d="M24 17h6v6h-6z" />
        <path d="M20.19 25.218l-11.793-8.6.495-.87s10.745 6.12 10.909 6.212a.528.528 0 00.42-.012l10.933-6.23.496.869z" fill="#0a2767" opacity=".5" />
        <path d="M31.667 16.577l-.014.008-.003.002-10.838 6.174a1.497 1.497 0 01-1.46.091l3.774 5.061 8.254 1.797v.004A1.498 1.498 0 0032 28.5V16a.666.666 0 01-.333.577z" fill="#1490df" />
        <path d="M32 28.5v-.738l-9.983-5.688-1.205.687a1.497 1.497 0 01-1.46.091l3.774 5.061 8.254 1.797v.004A1.498 1.498 0 0032 28.5z" opacity=".05" />
        <path d="M31.95 28.883L21.007 22.65l-.195.11a1.497 1.497 0 01-1.46.092l3.774 5.061 8.254 1.797v.004a1.501 1.501 0 00.57-.83z" opacity=".1" />
        <path d="M8.35 16.59v-.01h-.01l-.03-.02A.65.65 0 018 16v12.5A1.498 1.498 0 009.5 30h21a1.503 1.503 0 00.37-.05.637.637 0 00.18-.06.142.142 0 00.06-.02 1.048 1.048 0 00.23-.13c.02-.01.03-.01.04-.03z" fill="#28a8ea" />
        <path d="M18 24.667V8.333A1.337 1.337 0 0016.667 7H10.03v7.456l-1.68.958-.005.002-.012.007A.666.666 0 008 16v.005V16v10h8.667A1.337 1.337 0 0018 24.667z" opacity=".1" />
        <path d="M17 25.667V9.333A1.337 1.337 0 0015.667 8H10.03v6.456l-1.68.958-.005.002-.012.007A.666.666 0 008 16v.005V16v11h7.667A1.337 1.337 0 0017 25.667z" opacity=".2" />
        <path d="M17 23.667V9.333A1.337 1.337 0 0015.667 8H10.03v6.456l-1.68.958-.005.002-.012.007A.666.666 0 008 16v.005V16v9h7.667A1.337 1.337 0 0017 23.667z" opacity=".2" />
        <path d="M16 23.667V9.333A1.337 1.337 0 0014.667 8H10.03v6.456l-1.68.958-.005.002-.012.007A.666.666 0 008 16v.005V16v9h6.667A1.337 1.337 0 0016 23.667z" opacity=".2" />
        <path data-name="Back Plate" d="M1.333 8h13.334A1.333 1.333 0 0116 9.333v13.334A1.333 1.333 0 0114.667 24H1.333A1.333 1.333 0 010 22.667V9.333A1.333 1.333 0 011.333 8z" fill="#0078d4" />
        <path d="M3.867 13.468a4.181 4.181 0 011.642-1.814A4.965 4.965 0 018.119 11a4.617 4.617 0 012.413.62 4.14 4.14 0 011.598 1.733 5.597 5.597 0 01.56 2.55 5.901 5.901 0 01-.577 2.666 4.239 4.239 0 01-1.645 1.794A4.8 4.8 0 017.963 21a4.729 4.729 0 01-2.468-.627 4.204 4.204 0 01-1.618-1.736 5.459 5.459 0 01-.567-2.519 6.055 6.055 0 01.557-2.65zm1.75 4.258a2.716 2.716 0 00.923 1.194 2.411 2.411 0 001.443.435 2.533 2.533 0 001.541-.449 2.603 2.603 0 00.897-1.197 4.626 4.626 0 00.286-1.665 5.063 5.063 0 00-.27-1.686 2.669 2.669 0 00-.866-1.24 2.387 2.387 0 00-1.527-.473 2.493 2.493 0 00-1.477.439 2.741 2.741 0 00-.944 1.203 4.776 4.776 0 00-.007 3.44z" fill="#fff" />
        <path fill="none" d="M0 0h32v32H0z" />
      </svg>

      <span v-else class="default-icon" :style="{ 'background-color': stringToColor(icon.name, 90, 75) }">
                <span class="initial" :style="{ 'background-color': stringToColor(icon.name, 100, 50) }">
                    {{ getInitials(icon.name) }}
                </span>
            </span>

    </a>
  </div>
</template>

<script>
  // eslint-disable-next-line
  module.exports = {
    data: function () {
      return {
        icons: [{
          name: "Microsoft Teams",
          url: "https://teams.microsoft.com",
          icon: "teams"
        },
          {
            name: "Microsoft OneDrive",
            url: "https://www.office.com/launch/onedrive",
            icon: "onedrive"
          },
          {
            name: "Microsoft Word",
            url: "https://www.office.com/launch/word",
            icon: "word"
          },
          {
            name: "Microsoft Excel",
            url: "https://www.office.com/launch/excel",
            icon: "excel"
          },
          {
            name: "Microsoft PowerPoint",
            url: "https://www.office.com/launch/powerpoint",
            icon: "powerpoint"
          },
          {
            name: "Microsoft OneNote",
            url: "https://www.office.com/launch/onenote",
            icon: "onenote"
          },
          {
            name: "Microsoft Outlook",
            url: "https://outlook.office365.com",
            icon: "outlook"
          }
        ]
      }
    },
    methods: {
      stringToColor: function (str, saturation, lightness) {
        var hash = 0;
        for (var i = 0; i < str.length; i++) {
          hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }

        var h = hash % 360;
        return "hsl(" + h + ", " + saturation + "%, " + lightness + "%)";
      },

      getInitials: function (name) {
        if (name) {
          var array = name.split(" ");

          switch (array.length) {
            case 1:
              return array[0].charAt(0).toUpperCase();
            default:
              return array[0].charAt(0).toUpperCase() + array[array.length - 1].charAt(0).toUpperCase();
          }
        }
        return "";
      }
    }
  };
</script>

<style scoped>
  .shortcuts {
    background-color: #fff;
    display: flex;
    flex-wrap: wrap;
    padding: .5em;
  }

  .shortcuts>a {
    display: block;
    padding: .5em;
    margin: .5em;
    background: #fff;
    width: 3.45em;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .shortcuts>a:hover,
  .shortcuts>a:focus {
    background: #f6f7f8;
  }

  .shortcuts>a>svg {
    width:100%;
  }

  .default-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: .25em;
    width: 80%;
    height: 90%;
    background: #666;
    color: #fff;
    font-weight: bold;
    text-overflow: clip;
    text-align: center;
    position:relative;
  }

  .initial {
    position:absolute;
    top:17%;
    left:-20%;
    padding:.25em;
    background:#333;
    box-shadow:rgba(0, 0, 0, 0.25) 1px 1px 2px 1px;
    width: 65%;
    line-height:1.2;
    font-size:1.1em;
    border-radius:.1em;
  }

</style>
