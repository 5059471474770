<script>

const Search = {
    props: {
        search: String,
        searchClear: Function,
        searchChange: Function,
        placeholder: String,
    },
    data() {
        return {
            localSearchStr: this.search,
            debounceTimeout: null,
        }
    },
    watch: {
        search: function(val) {
            this.localSearchStr = val
        },
    },
    methods: {
        onInput() {

            if (this.debounceTimeout) {
                clearTimeout(this.debounceTimeout)
            }

            this.debounceTimeout = setTimeout(() => {
                this.searchChange(this.localSearchStr)
            }, 500)
        },
    },
}

export default Search

</script>

<template>
    <form class="search-person-form">
        <input type="text" class="search-person-input" :placeholder='placeholder' v-model="localSearchStr" @keyup="onInput">
        <div v-if="!localSearchStr.length">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" class="search-person-icon">
                <path
                    d="M32 31.060l-12.233-12.24c1.777-1.992 2.863-4.634 2.863-7.53 0-6.259-5.074-11.333-11.333-11.333s-11.333 5.074-11.333 11.333c0 6.259 5.074 11.333 11.333 11.333 2.896 0 5.538-1.086 7.541-2.873l-0.011 0.010 12.233 12.24zM1.293 11.333c0-5.523 4.477-10 10-10s10 4.477 10 10c0 5.509-4.454 9.977-9.958 10h-0.002c-0 0-0 0-0 0-5.531 0-10.017-4.472-10.040-9.998v-0.002z" />
            </svg>
        </div>
        <div v-else>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" class="search-person-icon pointer" @click="searchClear">
                <path d="M26 6.96l-0.96-0.96-9.040 9.040-9.040-9.040-0.96 0.96 9.040 9.040-9.040 9.040 0.96 0.96 9.040-9.040 9.040 9.040 0.96-0.96-9.040-9.040 9.040-9.040z" />
            </svg>
        </div>
    </form>
</template>