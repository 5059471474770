<template>
  <div
    :key="section"
    class="main-section">
    <section v-if="loading">
      <div class="infoba-header">
        <div style="display:flex">
          <i class="fal fa-home" />
          <span
            class="header-link"
            @click="goto(headerLink)">INFOBA</span>
        </div>
        <img
          :src="domain + '/Images/logowhite.svg'"
          class="infoba-logo">
      </div>
      <div
        class="infoba-quick-access-item "
        style="text-align:center; padding:20px !important">
        <div class="icon-rotator infoba-rotate" />
      </div>
    </section>
    <section v-else-if="section!='default' ">
      <div class="infoba-header">
        <div style="display:flex">
          <i class="fal fa-home" />
          <span
            class="header-link"
            @click="goto(headerLink)">INFOBA</span>
        </div>
        <img
          :src="domain + '/Images/logowhite.svg'"
          class="infoba-logo">
      </div>
      <div class="infoba-quick-access-item">
        <widget-html :html="message" />
      </div>
    </section>
    <section v-else>
      <div
        style="background:#eb7404;"
        class="infoba-header">
        <div style="display:flex">
          <div class="icon-logbook" />
          <div
            class="header-link"
            @click="goto(headerLinkC.url)">
            L&aelig;selog
          </div>
        </div>
        <img
          :src="domain + '/Images/logowhite.svg'"
          class="infoba-logo">
      </div>
      <div class="wrapper">
        <template
          v-for="ll in item.Binder.Laeselog"
          class="infoba-accordeon">
          <div class="infoba-accordeon-header">
            <div>{{ ll.Name }} {{ ll.Subject }}</div>
            <i
              class="fas fa-chevron-right rotate chevron"
              :class="{right:ll.Open}"
              style="cursor:pointer"
              @click="ll.Open = !ll.Open" />
          </div>
          <template v-for="c in ll.ChildList">
            <transition name="slide">
              <div
                v-show="ll.Open"
                class="infoba-accordeon-row"
                style="padding-bottom:20px;">
                <div
                  style="margin-left:20px;"
                  class="infoba-link"
                  @click="goto(domainOld + '/laeselog/laeselogCreate.aspx?studentId=' + c.Id)">
                  {{ c.Name }}
                </div>
                <div
                  style="margin-right:10px;"
                  class="infoba-gray">
                  {{ c.UpdatedDate }}
                </div>
              </div>
            </transition>
          </template>
        </template>
      </div>
    </section>
  </div>
</template>
<script>
var domain = 'https://www2.infoba.dk';
var domainOld = 'https://www.infoba.dk';

export default {
  props: {
    /* normal and secure */
    axios: Function,
    sessionUUID: String,
    placement: String,
    currentWeekNumber: String,
    isMobileApp: Boolean,
    userProfile: String,
    /* only secure */
    getAulaToken: Function,
    assuranceLevel: Number,
    childFilter: Array,
    institutionFilter: Array,
    group: String
  },
  data: function () {
    return {
      loading: true,
      section: "default",
      message: "",
      timer: '',
      headerLink: domain,
      who: 'Aula widget developers',
      aulaToken: null,
      domain: domain,
      domainOld: domainOld,
      fields: {},
      item: { Binder: {} },
    }
  },
  methods: {
    getData: function () {
      var uuid = this.sessionUUID;
      this.axios.defaults.headers.common["Authorization"] = this.aulaToken;
      this.axios.defaults.headers.common["AuthorizationContext"] = "LaeselogChildList";
      if (this.childFilter != null)
        this.axios.defaults.headers.common["childFilter"] = this.childFilter.join();
      if (this.institutionFilter != null)
        this.axios.defaults.headers.common["institutionFilter"] = this.institutionFilter.join();
      if (this.group != null)
        this.axios.defaults.headers.common["groupFilter"] = this.group;
      if (this.userProfile != null)
        this.axios.defaults.headers.common["userProfile"] = this.userProfile;
      if (this.currentWeekNumber != null)
        this.axios.defaults.headers.common["currentWeekNumber"] = this.currentWeekNumber;
      if (this.isMobileApp != null)
        this.axios.defaults.headers.common["isMobileApp"] = this.isMobileApp;

      this.axios.get(domain + "/api/Aula/LaeselogChildListGet", {
        withCredentials: true,
        credentials: "same-origin",
      })
        .then((response) => {
          this.item = response.data;
          handleStatus(this);
          if (this.item.Binder.Laeselog.length == 0) {
            this.section = "nodata";
            this.message = "Vi har ikke noget relevant data at vise dig.";
          }
        })
        .catch((error) => {
          handleStatus(this);
        })
        .finally(() => (this.loading = false));
    },
    goto: function (redirectUrl) {
      let serial = this.item.INFOBAserial;
      let token = this.item.INFOBAtoken;
      let url = "";
      //if logged in
      if (serial === null || token === null) {
        let aulaReferrer = '?referrer=Aula';
        if (redirectUrl.indexOf('?') !== -1) {
          aulaReferrer = '&referrer=Aula';
        }
        url = redirectUrl + aulaReferrer;
      }
      //if not logged in
      else {
        url = domain + "/Login/Index?serial=" + serial + "&token=" + token + "&referrer=Aula&returnUrl=" + encodeURIComponent(redirectUrl);
      }
      var a = document.createElement('a');
      a.href = url;
      a.target = "INFOBA";
      a.click();
    }
  },
  computed: {
    headerLinkC: function () {

      return {
        url: domainOld + "/laeselog" };
    }
  },
  created: function () {
    this.aulaToken = this.getAulaToken();
  },
  watch: {
    aulaToken: function () {
      if (this.aulaToken !== undefined) {
        var t = this;
        t.getData();
      }
    },
  },
}
function handleStatus(t) {
  clearTimeout(t.timer)
  if (t.item && t.item.Status == 200) {
    if (t.item.Binder.Laeselog == undefined) {
      t.section = "error"
      t.message = 'Din bruger har ikke adgang til denne widget i INFOBA. Kontakt <a href="https://web.infoba.dk/support/" class="infoba-link">support</a>.'
    }
    else {
      t.section = "default"
    }
  }
  else if (t.item && t.item.Status == 401) {
    if (t.item.Message == "Invalid role" || t.item.Message == "Module payment missing") {
      t.section = "error"
      t.message = 'Din bruger har ikke adgang til denne widget i INFOBA. Kontakt <a href="https://web.infoba.dk/support/" class="infoba-link">support</a>.'
    }
    else {
      t.section = "login";
      t.message = 'For at se data fra INFOBA, skal dine konti f&oslash;rst sammenk&aeligdes. <div><a href="' + domain + "/IN/Personel/MatchAula/" + t.item.Username + '" class="infoba-link">Start sammenk&aeligdning <i class="material-icons icon-arrowright-circle" style="vertical-align: middle; margin-top: 2px; color:#96be2c"></i></a></div>';
      t.timer = setTimeout(function () { t.getData() }.bind(t), 5000);
      t.headerLink = domain + "/IN/Personel/MatchAula/" + t.item.Username;
    }
  }
  else {
    t.section = "error"
    t.message = 'Undskyld. Det er i &oslash;jeblikket ikke muligt at vise dig den &oslash;nskede information. Vi h&aring;ber, du vil pr&oslash;ve igen senere.'
  }
}

</script>
<style scoped>
.main-section {
  border: 2px solid white;
  background-color: #FAFAFA;
}

.wrapper {
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
}

div.icon-logbook {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QAAAAAAAD5Q7t/AAAACXBIWXMAAA7EAAAOxAGVKw4bAAABe0lEQVRIx+3Uz4uNYRjG8c8zM2ayoEkSNSXETilnFmgklkxR5B9Tlpay8RfYkVKHImU1QzQYP8NmmMVlcZ5zOg5zvF6JhWvz/rq67+d7X+/z8F9/W2X4IcledDCHTfiCFXRLKcsj3n04PORdH/IuGTEvJrmb8eomOdPQey/J4oAgSVrQr+IOlvEZm7ELRyqVUkrpE/yKbiQ5lWRio859YyuCwarGaFC8lDLx85I/LHAuyZUkD5OsJllP8iLJ/e8W1Iag+p/gAZ7jAyaxv5Rydphgqg0BTuIWDmA7ZvEWrzecWVNV//UkH8d8H9y3Jejgch3RCt7XWrtHjW0bHMKJej2GbXiltze+zayP1LRyDXkNM/XVm0owiblSysxwyG0zuJRkPsn0SJZToxn88Y3WKoMkC7iIo9ij95u+wzN0fysD3MRCU9p+gzU8wtVa4CleYoveCdnBBZzGRPVcw208xifswE4cxHkcL6XM9pG3NhzNfB1PE+90E9+/r6/j8a/EXDtBvgAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAyMC0xMC0wN1QxMzo1MTo0MCswMDowMK4Y4f8AAAAldEVYdGRhdGU6bW9kaWZ5ADIwMjAtMTAtMDdUMTM6NTE6NDArMDA6MDDfRVlDAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAABJRU5ErkJggg==) no-repeat center center;
  background-size: 20px;
  padding: 35px 0 0 35px;
  display: inline-block;
}

.header-link {
  cursor: pointer;
  margin-left: 5px;
  line-height: 35px;
}

.infoba-header {
  color: #fff;
  padding: 4px;
  padding-left: 8px;
  padding-right: 10px;
  display: flex;
  justify-content: space-between;
  align-content: center;
  background: #888;
  position: relative;
}

.infoba-header:first-child {
  margin-top: 0
}

.infoba-header i {
  position: absolute;
  top: 2px;
  line-height: 35px;
  font-size: 24px !important
}

.infoba-header span {
  position: absolute;
  left: 34px;
  line-height: 35px;
  color: #fff;
  cursor: pointer;
  top: 5px
}

.quick-access-item {
  position: relative
}

.infoba-quick-access-item {
  background-color: #fff;
  padding: 20px 35px 20px 35px;
  cursor: auto;
  height: auto
}

.infoba-gray {
  color: #888
}

.infoba-link {
  color: #888 !important;
  cursor: pointer
}

.slide-enter-active {
  -moz-transition-duration: .3s;
  -webkit-transition-duration: .3s;
  -o-transition-duration: .3s;
  transition-duration: .3s;
  -moz-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in
}

.slide-leave-active {
  -moz-transition-duration: .3s;
  -webkit-transition-duration: .3s;
  -o-transition-duration: .3s;
  transition-duration: .3s;
  -moz-transition-timing-function: cubic-bezier(0,1,.5,1);
  -webkit-transition-timing-function: cubic-bezier(0,1,.5,1);
  -o-transition-timing-function: cubic-bezier(0,1,.5,1);
  transition-timing-function: cubic-bezier(0,1,.5,1)
}

.slide-enter-to, .slide-leave {
  max-height: 100px;
  overflow: hidden
}

.slide-enter, .slide-leave-to {
  overflow: hidden;
  max-height: 0
}

.rotate {
  -moz-transition: all .3s linear;
  -webkit-transition: all .3s linear;
  transition: all .3s linear
}

.rotate.right {
  -moz-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg)
}

.rotate.up {
  -moz-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg)
}

.infoba-rotate {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite
}

@-webkit-keyframes rotating {
  from {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg)
  }

  to {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg)
  }
}

@keyframes rotating {
  from {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg)
  }

  to {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg)
  }
}

.infoba-quick-access-item ul {
  list-style: disc;
  margin: 0
}

.infoba-quick-access-item li {
  margin-left: 16px
}

infoba-accordeon
.infoba-accordeon {
  padding-left: 5px
}

.infoba-accordeon-header {
  padding-left: 24px;
  background-color: #FFFFFF;
  line-height: 46px;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 46px;
}

.infoba-accordeon-row {
  background-color: #FFFFFF;
  padding-left: 5px;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  justify-content: space-between;
  padding-top: 5px;
  padding-bottom: 5px;
}

.infoba-logo {
  height: 35px
}

i {
  font-size: 14px;
}

i.chevron {
  margin-right: 10px;
  height: 14px;
}
</style>
