// CALENDAR
export const CALENDAR_TOOLBAR_RESOURCE_TYPES_ENUM_location = 'Location';
export const CALENDAR_TOOLBAR_RESOURCE_TYPES_ENUM_other = 'Other';

export const CALENDAR_TOOLBAR_NEW_EVENT = 'Opret begivenhed';
export const CALENDAR_TOOLBAR_NEW_EVENT_MOBILE = 'BEGIVENHED';
export const CALENDAR_TOOLBAR_NEW_MEETING_EVENT_MOBILE = 'SAMTALE';
export const CALENDAR_TOOLBAR_TYPE_EVENT = 'Type begivenhed';
export const CALENDAR_TOOLBAR_SHARED_CALENDAR = 'Åbn kalender';
export const CALENDAR_TOOLBAR_REQUEST_VACATION = 'Anmod om ferieregistrering';
export const CALENDAR_TOOLBAR_REQUEST_VACATION_HEADER = 'Ferieanmodning';
export const CALENDAR_TOOLBAR_REQUEST_VACATION_BODY =
  'Vær opmærksom på, at ferieanmodningen KUN bliver sendt til de forældre, der har komme/gå modulet.';
export const CALENDAR_TOOLBAR_REQUEST_VACATION_WARNING = 'Du skal angive en gyldig start og slut dato';
export const CALENDAR_TOOLBAR_REQUEST_VACATION_WARNING_TITLE = 'Du skal angive en titel';
export const CALENDAR_TOOLBAR_REQUEST_VACATION_WARNING_DEPARTMENT = 'Du skal vælge en afdeling';
export const CALENDAR_TOOLBAR_REQUEST_VACATION_WARNING_GROUP = 'Du skal vælge mindst en gruppe';
export const CALENDAR_TOOLBAR_SHARED_CALENDAR_OPEN = 'Åbn kalender';
export const CALENDAR_TOOLBAR_SHARED_CALENDAR_SEARCH = 'Du kan fremsøge en anden brugers kalender eller ressourcer';
export const CALENDAR_TOOLBAR_CONTROLLABLE_CALENDARS = 'Kalendere med råderet';
export const CALENDAR_TOOLBAR_MY_VACATION_REQUESTS = 'Ferieanmodninger';
export const CALENDAR_TOOLBAR_CONTROLLABLE_CALENDARS_MOBILE = 'Kalendere jeg har råderet over';
export const CALENDAR_TOOLBAR_SEE_SCHOOL_CALENDAR = 'Se institutionens kalender';
export const CALENDAR_TOOLBAR_SCHOOL_CALENDAR = 'Kalender for';
export const CALENDAR_VACATION_REQUEST_OTHERS = 'andre';
export const CALENDAR_VACATION_REQUEST_SEND_MESSAGE = 'Besked';
export const CALENDAR_VACATION_REQUEST_CREATOR = 'Opretter:';
export const CALENDAR_VACATION_REQUEST_INSTITUTION = 'Institution:';
export const CALENDAR_VACATION_REQUEST_DEPARTMENTS = 'Afdeling:';
export const CALENDAR_VACATION_REQUEST_GROUPS = 'Gruppe:';
export const CALENDAR_VACATION_REQUEST_PERIOD_1 = 'Ferieperiode:';
export const CALENDAR_VACATION_REQUEST_PERIOD_2 = 'd.';
export const CALENDAR_VACATION_REQUEST_PERIOD_3 = 'til d.';
export const CALENDAR_VACATION_REQUEST_MISSING_ANSWER = 'Mangler svar:';
export const CALENDAR_VACATION_REQUEST_NOTE = 'Tekst til forældre:';
export const CALENDAR_VACATION_REQUEST_DELETE = 'Slet ferieanmodning';
export const CALENDAR_VACATION_REQUEST_ON_VACATION_1 = 'Ferie/fri:';
export const CALENDAR_VACATION_REQUEST_ON_VACATION_2 = 'kommer';
export const CALENDAR_VACATION_REQUEST_ALL_COMING = 'Alle børn kommer';
export const CALENDAR_VACATION_REQUEST_COMING = 'Kommer:';
export const CALENDAR_VACATION_REQUEST_PENDDING = 'Mangler svar:';
export const CALENDAR_VACATION_REQUEST_WEEK = 'Uge';
export const CALENDAR_VACATION_REQUEST_RESPONSE_IS_COMING = 'Kommer';
export const CALENDAR_VACATION_REQUEST_RESPONSE_IS_NOT_COMING = 'Kommer ikke';
export const CALENDAR_VACATION_REQUEST_RESPONSE_PENDING_ANSWER = 'Mangler svar';
export const CALENDAR_VACATION_REQUEST_RESPONSE_CHILDREN = 'børn';
export const CALENDAR_TITLE_CREATE_EVENT = 'OPRET BEGIVENHED';
export const CALENDAR_TITLE_EDIT_EVENT = 'REDIGER BEGIVENHED';
export const CALENDAR_TITLE_DELETE_EVENT = 'SLET BEGIVENHED';
export const CALENDAR_DELETE_EVENT_TEXT = 'Er du sikker på, at du vil slette begivenheden?';
export const CALENDAR_DELETE_OTHERS_EVENT_WARNING_1 = 'Du er ved at slette en begivenhed, du ikke selv har oprettet.';
export const CALENDAR_DELETE_OTHERS_EVENT_WARNING_2 = 'Er du sikker på du vil fortsætte?';
export const CALENDAR_EDIT_OTHERS_EVENT_WARNING_1 = 'Du er ved at ændre en begivenhed, du ikke selv har oprettet.';
export const CALENDAR_EDIT_OTHERS_EVENT_WARNING_2 = 'Er du sikker på du vil fortsætte?';
export const CALENDAR_EDIT_REPEATING_SERIES_WARNING_1 =
  'Hvis du har lavet ændringer til enkelte begivenheder i serien, vil ændringerne blive overskrevet med de nye ændringer til serien.';
export const CALENDAR_PARTIALLY_AVAILABLE_RESOURCES_WARNING =
  'Bemærk, at det valgte lokale/ressource ikke er tilgængeligt på ét eller flere tidspunkter.';
export const CALENDAR_SAVE_EVENT_CONFIRMATION = 'Vil du fortsætte?';
export const CALENDAR_EXCESS_TIMESLOTS_WARNING =
  'Bemærk, at du har tilføjet mere tid end nødvendigt for at alle kan deltage.';
export const CALENDAR_MISSING_TIMESLOTS_WARNING =
  'Vær opmærksom på, at du ikke har tilføjet nok tid til det antal personer, der er inviteret til samtalen.';
export const CALENDAR_DELETE_VACATION_REGISTRATION_TEXT = 'Er du sikker på, at du vil slette ferieanmodningen?';
export const CALENDAR_DELETE_SERIES_EVENT_TEXT = 'Du er ved at slette en serie af begivenheder.';
export const CALENDAR_DELETE_SERIES_EVENT_TEXT_2 = 'Er du sikker på, at du vil slette alle begivenhederne i serien?';
export const CALENDAR_DELETE_OCCURRENCE_EVENT_TEXT =
  'Du er ved at slette en enkelt begivenhed i en serie af begivenheder.';
export const CALENDAR_DELETE_OCCURRENCE_EVENT_TEXT_2 = 'Er du sikker på, at du vil slette begivenheden?';
export const CALENDAR_UPDATE_BOOKED_TIMESLOT = 'Er du sikker på, at du vil flytte din tid til d.';
export const CALENDAR_UPDATE_BOOKED_TIMESLOT_2 = 'kl.';
export const CALENDAR_CLEAR_BOOKED_TIMESLOT =
  'Vær opmærksom på, at du ikke kan vælge en ny tid, hvis du vælger ikke at deltage.';
export const CALENDAR_CLEAR_BOOKED_TIMESLOT_2 = 'Er du sikker på, at du ikke kan deltage?';
export const CALENDAR_REMOVE_RESOURCES_WHEN_CHANGING_DATE_TIME =
  'Hvis du ændrer tid og dato, forsvinder dine bookinger af lokaler og ressourcer for denne begivenhed. Er du sikker på, at du vil fortsætte?';
export const CALENDAR_BOTH_PRIVATE_AND_ADDED_TO_INST_CALENDAR =
  'Begivenheden kan ikke både være privat og blive tilføjet til institutionens kalender';
export const CALENDAR_DELETE_HOLIDAY_TEXT = 'Du er ved at slette den oprettede ferie/fri';
export const CALENDAR_DELETE_HOLIDAY_TEXT_2 = 'Er du sikker på, at du vil slette den?';
export const CALENDAR_TIMESLOT_ALREADY_BOOKED = 'Dette mødetidspunkt er allerede booket. Vælg venligst et andet.';
export const CALENDAR_TITLE_RIGHT_OF_DISPOSAL = 'Giv råderet';
export const CALENDAR_TITLE_PRINT = 'Print';
export const CALENDAR_TOOLBAR_PRINT_MAIN_DAY = 'Print (06:00-18:00)';
export const CALENDAR_TOOLBAR_PRINT_FULL_DAY = 'Print (Hele dagen)';
export const CALENDAR_ACTIVE_DELEGATED_ACCESS_START = 'OBS: Du bruger kalenderen på vegne af';
export const CALENDAR_ACTIVE_DELEGATED_ACCESS_END =
  'Alt hvad du foretager dig bliver gjort i personens navn. Klik her for at komme tilbage til din egen kalender';

export const CALENDAR_TIMES_FOR_ALL_WEEK_TITLE = 'Tider for hele ugen';
export const CALENDAR_TIMES_MOBILE_FOR_ALL_WEEK_TITLE = 'Hele ugen';
export const CALENDAR_TIMES_FOR_ALL_WEEK_DESCRIPTION =
  'Her kan du angive tider for hele ugen. Du kan efterfølgende overskrive tider for enkelte dage ved at udfylde de pågældende dage manuelt.';
export const CALENDAR_TOOLBAR_FILTER = 'Filtrer';
export const CALENDAR_TOOLBAR_EVENT_TYPES = 'Begivenheder';
export const CALENDAR_TOOLBAR_EVENT_FILTER_TYPES = 'Du kan filtrere efter type';
export const CALENDAR_TOOLBAR_RESOURCE_TYPES = 'Ressourcer';
export const CALENDAR_TOOLBAR_EVENT_TYPES_ENUM_event = 'Møde/Begivenhed';
export const CALENDAR_TOOLBAR_EVENT_TYPES_ENUM_vacation_registration = 'Ferieanmodning';
export const CALENDAR_TOOLBAR_EVENT_TYPES_ENUM_lesson = 'Skema';
export const CALENDAR_TOOLBAR_EVENT_TYPES_ENUM_holiday = 'Ferie/fri';
export const CALENDAR_TOOLBAR_EVENT_TYPES_ENUM_presence_holiday = 'Ferie/fri';
export const CALENDAR_TOOLBAR_EVENT_TYPES_ENUM_birthday = 'Fødselsdag';
export const CALENDAR_TOOLBAR_EVENT_TYPES_ENUM_other = 'Andet';
export const CALENDAR_TOOLBAR_EVENT_TYPES_ENUM_school_home_meeting = 'Skole-hjem-samtale';
export const CALENDAR_TOOLBAR_EVENT_TYPES_ENUM_parental_meeting = 'Forældresamtale';
export const CALENDAR_TOOLBAR_EVENT_TYPES_ENUM_performance_meeting = 'MUS-samtale';
export const CALENDAR_TOOLBAR_EVENT_TYPES_ENUM_excursion = 'Ekskursion';
export const CALENDAR_TOOLBAR_EVENT_TYPES_ENUM_DIRECTLY_RELATED = 'Mine begivenheder';
export const CALENDAR_TOOLBAR_OTHER_FUNCTIONS = 'Andre funktioner';
export const CALENDAR_RESPOND_ON_BEHALF_CONFIRM = 'Er du sikker på, at du vil tilmelde personen til dette møde.';
export const CALENDAR_RESPOND_ON_BEHALF_CONFIRM_2 = 'Denne handling kan ikke fortrydes';
export const CALENDAR_RESPOND_ON_BEHALF_CONFIRM_BLOCKED =
  'Du er ved at blokere en tid, så deltagerne ikke kan vælge den.';
export const CALENDAR_RESPOND_ON_BEHALF_CONFIRM_2_BLOCKED = 'Er du sikker på, at du vil fortsætte?';
export const CALENDAR_TIMESLOT_FREE_SLOT = 'Ledig plads';
export const CALENDAR_TIMESLOT_ASSIGN_SLOT = 'Tilmeld person';
export const LESSON_NOTE_TO_CLASS_TITLE = 'ADVARSEL';
export const LESSON_NOTE_TO_CLASS_TEXT = 'Bemærk at denne tekst kan blive overskrevet af Skemaplanlægningen';

export const CALENDAR_TITLE_WITH_CHILDREN = 'Samtalen skal ligeledes fremgå i børns kalender';
export const CALENDAR_TITLE_WITH_CHILDREN_HELP_TITLE = 'Opret i børns kalender';
export const CALENDAR_TITLE_WITH_CHILDREN_HELP =
  'Når forældre har valgt tid og dato, vil samtalen ligeledes fremgå i børns kalender.';
export const CALENDAR_MEETING_UPLOAD_HELP = 'Alle, der er inviteret til samtalen, kan se vedhæftninger.';
export const CALENDAR_ALLOW_MULTIPLE_PARTICIPANTS_HELP_TEXT_TITLE = 'Flere til samme tid';
export const CALENDAR_ALLOW_MULTIPLE_PARTICIPANTS_HELP_TEXT_1 =
  'Hvis du vælger denne mulighed, betyder det, at flere kan booke den samme tid.';
export const CALENDAR_ALLOW_MULTIPLE_PARTICIPANTS_HELP_TEXT_2 =
  'Det kan fx være i forbindelse med det, der bl.a. kaldes cafésamtaler eller rullende samtaler, hvor to eller flere forældrepar kan rotere mellem medarbejdere inden for det valgte tidsinterval.';

export const CALENDAR_TOOLBAR_RESOURCE_TYPES_ENUM_rooms = 'Rum';
export const CALENDAR_TOOLBAR_RESOURCE_TYPES_ENUM_it = 'IT';
export const CALENDAR_TOOLBAR_REQUEST_VACATION_SUCCESS = 'Ferieanmodningen blev oprettet.';
export const CALENDAR_TOOLBAR_REQUEST_VACATION_UPDATE_SUCCESS = 'Ferieanmodningen blev besvaret.';
export const CALENDAR_TOOLBAR_UPDATE_REQUEST_VACATION_SUCCESS = 'Ferieanmodningen blev opdateret';

export const HOLIDAY = 'Ferie/fri';
export const PRESENCE_HOLIDAY = 'Ferie/fri';
export const EVENT = 'Begivenheder';
export const BIRTHDAY = 'Fødselsdag';
export const LESSON = 'Skema';
export const VACATION_REGISTRATION = 'Ferieanmodning';
export const EXCURSION = 'Ekskursion';
export const PARENTAL_MEETING = 'Forældresamtale';
export const SCHOOL_HOME_MEETING = 'Skole-hjem-samtale';
export const PERFORMANCE_MEETING = 'MUS-samtale';
export const OTHER = 'Andet';

export const CALENDAR_PROFILE_ROLE_EMPLOYEE = 'employee';
export const CALENDAR_PROFILE_ROLE_GUARDIAN = 'guardian';
export const CALENDAR_PROFILE_ROLE_CHILD = 'child';

// event form
export const CALENDAR_TITLE_CONFIRM_CANCEL = 'ADVARSEL';
export const CALENDAR_CANCEL_INFO = 'Ændringerne vil ikke blive gemt.';
export const CALENDAR_CANCEL_INFO_2 = 'Er du sikker på, du vil forlade siden?';
export const CALENDAR_TITLE_ATTENDEE_CONFLICT_TIME = 'Advarsels konflikt';
export const CALENDAR_TITLE_NEVER_REPEAT = 'Aldrig';
export const CALENDAR_PAGE_TITLE = 'Kalender';
export const CALENDAR_TITLE_DAILY_EVENT = 'Dagligt';
export const CALENDAR_TITLE_WEEKLY_EVENT = 'Ugentligt';
export const CALENDAR_TITLE_MONTHLY_EVENT = 'Månedligt';
export const CALENDAR_TITLE_YEARLY_EVENT = 'Årligt';

export const CALENDAR_LABEL_EVENT_TITLE = 'EMNE';
export const CALENDAR_LABEL_EVENT_PLACEHOLDER_TITLE = 'Skriv emne';
export const CALENDAR_ALERT_TITLE = 'Du mangler at angive emne';
export const CALENDAR_ALERT_INVALID_MAXIMUM_PARTICIPANTS = 'Angiv antal mellem 1 og 2000';
export const CALENDAR_ALERT_NUMBER_OF_TIMESLOT_PARTICIPANTS = 'Angiv antal mellem 2 og 10';
export const CALENDAR_ALERT_TOO_FEW_MAXIMUM_PARTICIPANTS =
  'Der er allerede tilmeldt {participants} personer. Du kan derfor ikke angive et antal, som er lavere end {participants}';
export const CALENDAR_ALERT_TIME = 'Du mangler at vælge en tid';
export const CALENDAR_ALERT_CHOOSE_INSTITUTION = 'Vælg venligst en institution, som du ville oprette begivenhed til';
export const CALENDAR_TITLE_CHOOSE_INSTITUTION = 'Vælg en institution';
export const CALENDAR_TITLE_SELECTED_INSTITUTION = 'Institution';
export const CALENDAR_LABEL_EVENT_TYPE = 'TYPE';
export const CALENDAR_LABEL_EVENT_PERSONS = 'DELTAGERE';
export const CALENDAR_LABEL_EVENT_PLACEHOLDER_PERSONS = 'Vælg personer';
export const CALENDAR_LABEL_EVENT_REQUEST_FOR_ANSWER = 'Send notifikation';
export const CALENDAR_LABEL_EVENT_REQUEST_FOR_ANSWER_HELP_TITLE = 'Send notifikation';
export const CALENDAR_LABEL_EVENT_REQUEST_FOR_ANSWER_HELP_1 =
  'Vælger du denne mulighed, får de inviterede at vide de er indkaldt. Du får også mulighed for at angive en deadline for svar.';
export const CALENDAR_LABEL_EVENT_REQUEST_FOR_ANSWER_HELP_2 =
  'Vælger du ikke denne mulighed, får de inviterede ikke at vide de er indkaldt, men begivenheden vil stadig optræde i deres kalendere. Du kan ikke angive en deadline for svar.';
export const CALENDAR_VACATION_REQUEST_CHOOSE_DEPARTMENTS = 'Vælg afdeling';
export const CALENDAR_VACATION_REQUEST_CHOOSE_GROUPS = 'Vælg grupper';
export const CALENDAR_VACATION_REQUEST_PRESENCE_TIME_REQUIRED = 'Tilføj angivelse af tider, hvis barnet kommer';
export const CALENDAR_VACATION_REQUEST_SETTINGS = 'Indstillinger';
export const CALENDAR_VACATION_REQUEST_RESPONSE_FILTERING_DEPARTMENT = 'Afdeling:';
export const CALENDAR_VACATION_REQUEST_RESPONSE_FILTER = 'Filtrer';
export const CALENDAR_VACATION_REQUEST_RESPONSE_FILTERING_GROUP = 'Gruppe:';
export const RESPONSE_VACATION_REQUEST_INTRODUCTION =
  'Vælg nedenfor hvilke dage, dit barn kommer og ikke kommer i ferieperioden';
export const RESPONSE_VACATION_COMING = 'Kommer';
export const RESPONSE_VACATION_COMMING_HOUR = 'Fra kl.';
export const RESPONSE_VACATION_NOT_COMMING_HOUR = 'Til kl.';
export const RESPONSE_VACATION_NOT_COMING = 'Kommer ikke';
export const RESPONSE_VACATION_COMMING_TITLE = 'Kommer hele ugen';
export const RESPONSE_VACATION_NOT_COMMING_TITLE = 'Kommer ikke hele ugen';
export const RESPONSE_VACATION_COMING_TIME_INVALID = 'Du mangler at angive tid';
export const RESPONSE_VACATION_TIMES_OUTSIDE_OPENING_HOURS = 'Du har angivet tider, der ligger uden for åbningstiden';
export const RESPONSE_VACATION_NOTE_LABEL = 'Ferienote til institutionen';
export const RESPONSE_VACATION_NOTE_PLACEHOLDER = 'Skriv note her...';
export const CALENDAR_LABEL_EVENT_REQUEST_DEADLINE = 'Deadline for svar';
export const CALENDAR_LABEL_EVENT_AVAILABLE_SEATS = 'Ledige pladser';
export const CALENDAR_EVENT_PARTICIPANTS = '{available} ud af {max}';
export const CALENDAR_LABEL_EVENT_INVALID_DEADLINE = 'Deadline kan ikke være efter start dato';
export const CALENDAR_LABEL_EVENT_ADD_TO_INSTITUTION_CALENDAR = 'Tilføj til min egen og kalender for';
export const CALENDAR_LABEL_EVENT_ADD_ONLY_TO_INSTITUTION_CALENDAR = 'Tilføj KUN til kalender for';
export const CALENDAR_LABEL_EVENT_START = 'STARTER';
export const CALENDAR_LABEL_EVENT_START_LOWER = 'Start:';
export const CALENDAR_LABEL_EVENT_SETTINGS_FOR_SIMPLE_EVENT = 'Indstillinger for begivenheden';
export const CALENDAR_LABEL_EVENT_SETTINGS_FOR_TIME_SLOT_EVENT = 'Indstillinger for samtalen';
export const CALENDAR_LABEL_EVENT_PLACEHOLDER_START = 'dd/mm/yyyy';
export const CALENDAR_ALERT_START_DATE = 'Indtast startdatoen';
export const CALENDAR_ALERT_START_DATETIME_INVALID = 'Indtast venligst gyldig startdato tid';
export const CALENDAR_ALERT_START_TIME = 'Indtast starttidspunkt';
export const CALENDAR_ALERT_END_DATETIME = 'Indtast venligst en gyldig slutdato tid';
export const CALENDAR_ALERT_EDIT_END_TIME_IN_THE_PAST =
  'Du er ved at ændre en begivenhed hvor sluttidspunktet allerede er overskredet.';
export const CALENDAR_ALERT_CREATE_END_TIME_IN_THE_PAST =
  'Du er ved at oprette en begivenhed hvor sluttidspunktet allerede er overskredet.';
export const CALENDAR_ALERT_EDIT_REPEATING_IN_THE_PAST =
  'Du er ved at ændre en begivenhed hvor den første gentagelses sluttidspunkt allerede er overskredet.';
export const CALENDAR_ALERT_CREATE_REPEATING_IN_THE_PAST =
  'Du er ved at oprette en begivenhed hvor den første gentagelses sluttidspunkt allerede er overskredet.';
export const CALENDAR_ALERT_OCCURENCE_DATE_BEFORE_FIRST_OCCURRENCE =
  'Du kan ikke ændre en begivenhed i en serie til et tidspunkt før den første begivenhed i serien.';
export const CALENDAR_ALERT_OCCURENCE_DATE_AFTER_LAST_OCCURRENCE =
  'Du kan ikke ændre en begivenhed i en serie til et tidspunkt efter den sidste begivenhed i serien.';
export const CALENDAR_LABEL_EVENT_END = 'SLUTTER';
export const CALENDAR_LABEL_EVENT_PLACEHOLDER_END = 'dd/mm/yyyy';
export const CALENDAR_LABEL_EVENT_DEFINE_PARTICIPANT_SEATS = 'Angiv antal pladser til rådighed';
export const CALENDAR_LABEL_EVENT_PARTICIPANT_NUMBER_REQUIRED = 'Anmod om angivelse af deltagerantal';
export const CALENDAR_LABEL_EVENT_WRITE_PARTICIPANT_SEATS = 'Skriv antal';
export const CALENDAR_LABEL_EVENT_WRITE_NUMBER_OF_PARTICIPANTS = 'Vælg antal';
export const CALENDAR_LABEL_EVENT_ALL_DAY = 'Hele dagen';
export const CALENDAR_LABEL_EVENT_ALLDAY = 'HELDAG';
export const CALENDAR_LABEL_EVENT_PRIVATE = 'Privat';
export const CALENDAR_LABEL_EVENT_REPEAT = 'GENTAG';
export const CALENDAR_ALERT_EVENT_REPEAT = 'Felt skal udfyldes';
export const CALENDAR_ALERT_EVENT_REPEAT_OCCURENCE_MAX = 'Max 2000 gentagelser';
export const CALENDAR_ALERT_TOO_MANY_RECIPIENTS =
  'Begivenheden kan ikke oprettes, da der er valgt mere end 2000 deltagere.';
export const CALENDAR_ALERT_UNAVAILABLE_RESOURCES_1 = 'Ressourcen/Ressourcerne';
export const CALENDAR_ALERT_UNAVAILABLE_RESOURCES_2 =
  'er blevet booket af en anden bruger i det valgte tidsrum og er derfor blevet fjernet.';
export const CALENDAR_ALERT_UNAVAILABLE_RESOURCES_3 = 'Ønsker du at gemme begivenheden alligevel?';
export const CALENDAR_ALERT_REPEATING_EVENT_CANNOT_MOVE_TO_EXISTING_REPETITION =
  'Det er ikke muligt at flytte en gentagelse til en dag hvor der er en anden gentagelse i serien.';
export const CALENDAR_INVITED_GROUPS =
  'Følgende grupper er inviteret til begivenheden og nye medlemmer af gruppen inviteres automatisk:';
export const CALENDAR_LABEL_ORGANIZERS = 'Medarrangører';

export const CALENDAR_SEND_REMINDER_WARNING_TITLE = 'Rykker';
export const CALENDAR_SEND_VACATION_REQUEST_REMINDER_WARNING =
  'Du er ved at sende en rykker til forældrene til {numberOfInvitees} børn, der mangler at svare.';
export const CALENDAR_SEND_EVENT_REMINDER_WARNING =
  'Du er ved at sende en rykker til {numberOfInvitees} inviterede, der mangler at svare.';

export const CALENDAR_LABEL_EVENT_REPEAT_EACH = 'Gentag hver';
export const CALENDAR_LABEL_EVENT_FOR_EACH = 'for hver';
export const CALENDAR_LABEL_EVENT_REPEAT_END_AFTER = 'Slut efter';
export const CALENDAR_LABEL_EVENT_REPEAT_REPETITION = 'gentagelser';
export const CALENDAR_LABEL_EVENT_REPEAT_THE = 'Den';
export const CALENDAR_LABEL_EVENT_REPEAT_END_ON_DATE = 'Slut: ';
export const CALENDAR_LABEL_EVENT_LOCATIONS = 'Lokaler';
export const CALENDAR_LABEL_EVENT_LOCATIONS_HELP_TEXT =
  'Her kan du tilføje et eller flere lokaler, der er tilknyttet institutionen.';
export const CALENDAR_LABEL_EVENT_PLACEHOLDER_PLACE = 'Book et eller flere lokaler';
export const MESSAGE_EVENT_NOT_CREATED = 'Begivenheden blev ikke oprettet ';
export const MESSAGE_EVENT_RESPONSE_accepted = 'Du har accepteret begivenheden';
export const MESSAGE_EVENT_RESPONSE_declined = 'Du har afvist begivenheden';

export const CALENDAR_LABEL_EVENT_PRIMARY_LOCATION = 'Primær lokale';
export const CALENDAR_LABEL_EVENT_MEETING_PLACE = 'Mødested (fritekst)';
export const CALENDAR_LABEL_EVENT_MEETING_PLACE_HELP_TEXT =
  'Her kan du tilføje et mødested, der ikke er oprettet som et lokale på institutionen. Det vil blive vist som et lokale kalenderen. Fx "skolegården".';
export const CALENDAR_LABEL_EVENT_MEETING_PLACE_PLACEHOLDER = 'Tilføj mødested i fritekst';
export const CALENDAR_LABEL_EVENT_EXTRA_ROOM_HELP =
  'Her kan du tilføje ekstra lokaler, hvis der er behov for det, fx til gruppearbejde eller lignende';
export const CALENDAR_LABEL_EVENT_RESOURCES = 'RESSOURCER';
export const CALENDAR_LABEL_EVENT_RESOURCES_PLACEHOLDER = 'Tilføj ressourcer';
export const CALENDAR_AVAILABLE = 'Ledig';
export const CALENDAR_AVAILABLE_PARTIALLY = 'Ledig {available} ud af {occurrences} gange';
export const CALENDAR_AVAILABLE_PARTIALLY_DESCRIPTION_1 = '{resource} er ledig';
export const CALENDAR_AVAILABLE_PARTIALLY_DESCRIPTION_2 = '{available} ud af {occurrences} gange';
export const CALENDAR_LABEL_EVENT_RESOURCES_HELP =
  'Her kan du tilføje de ressourcer, der er tilknyttet institutionen, som fx læringsmaterialer og teknisk udstyr';
export const CALENDAR_LABEL_EVENT_DESCRIPTION = 'TEKST';
export const CALENDAR_CHANGE_TIME = 'Skift tidspunkt';
export const CALENDAR_NO_MORE_SEATS = 'Der er ingen ledige pladser';
export const CALENDAR_NOT_ENOUGH_REMAINING_SLOTS =
  'Der er kun {numberOfRemainingSlots} ledige pladser tilbage. Du kan derfor ikke angive et antal, som er større end {numberOfRemainingSlots}.';
export const CALENDAR_MISSING_NUMBER_OF_PARTICIPANTS =
  'Du skal angive mindst en deltager for at deltage i begivenheden.';
export const CALENDAR_PARTICIPATION_NO_LONGER_POSSIBLE =
  'Tilmelding er desværre ikke længere mulig, da der ikke er flere ledige pladser';
export const CALENDAR_TOOLBAR_NEW_MEETING_EVENT = 'Opret samtale';
export const CALENDAR_TITLE_CREATE_MEETING_EVENT = 'OPRET SAMTALE';
export const CALENDAR_TITLE_EDIT_MEETING_EVENT = 'REDIGER SAMTALE';
export const CALENDAR_TITLE_EDIT_LESSON = 'REDIGER SKEMABRIK';

export const CALENDAR_LABEL_PARTICIPANT_SEATS_ADULT = 'Angiv antal voksne';
export const CALENDAR_LABEL_PARTICIPANT_SEATS_CHILD = 'Angiv antal børn';

export const CALENDAR_ALLOW_MULTIPLE_PARTICIPANTS = 'Flere kan vælge samme tid';
export const CALENDAR_MEETING_SUGGESTION_LABEL = 'Tidspunkter';
export const CALENDAR_TITLE_MEETING_DURATION = 'Samtalens varighed';
export const CALENDAR_TITLE_PAUSE_AFTER = 'Antal samtaler før pause';
export const CALENDAR_TITLE_PAUSE_DURATION = 'Pausens varighed';
export const CALENDAR_LABEL_MEETING_DATE = 'Dato';
export const CALENDAR_LABEL_MEETING_FROM_TIME = 'Fra kl.';
export const CALENDAR_LABEL_MEETING_TO_TIME = 'Til kl.';
export const CALENDAR_LABEL_MEETING_LOCATION = 'Lokale';
export const CALENDAR_LABEL_MEETING_COORGANIZER = 'Co-organizer';
export const CALENDAR_LABEL_MEETING_EVENT_ADD_TIME = 'Tilføj tidspunkt';
export const CALENDAR_LABEL_SERIES_EVENT_NEVER_END = 'Begivenhed slutter aldrig';
export const CALENDAR_LABEL_EVENT_ORIGINAL = ' (Original tid)';
export const CALENDAR_LABEL_EVENT_NEW = ' (Ny tid)';

export const CALENDAR_MEETING_SUGGESTED_TIMESLOTS =
  'Hvis du ønsker at alle får tid til en samtale, skal du tilføje tid til';
export const CALENDAR_MEETING_ADDED_TIMESLOTS = 'Lige nu har du tilføjet nok tid til';
export const CALENDAR_MEETING = 'samtale';
export const CALENDAR_MEETINGS = 'samtaler';

export const CALENDAR_ALERT_SERIES_MONTHLY_END_DATE_OF_MONTH =
  'Begivenhederne vil blive sat på den sidste dag i måneden';
export const CALENDAR_ALERT_SERIES_DATE_INVALID = 'Datoen er ugyldig';
export const CALENDAR_ALERT_ATTENDEES = 'Du skal vælge mindst én person';
export const CALENDAR_ALERT_INVITEES_ARE_CORGANIZERS = 'Du kan ikke vælge en medarrangør, der også er deltager';
export const CALENDAR_ALERT_INVALID_MEETING_DURATION = 'Du mangler at angive varighed af den enkelte samtale';
export const CALENDAR_ALERT_INVALID_TIMESLOT = 'Du har indtastet ugyldig tid eller varighed er mindre end mødevarighed';
export const CALENDAR_ALERT_CONFLICT_TIMESLOT = 'Tidspunktet er allerede booket';
export const CALENDAR_ALERT_CONFLICTINT_INVITEES = 'Følgende personer har andre begivenheder på samme tidspunkt:';
export const CALENDAR_ALERT_CONFLICTINT_CONFIRM = 'Vil du gemme begivenheden alligevel?';
export const CALENDAR_ALERT_INVALID_MEETING_LOCATION = 'Du mangler at angive et lokale';
export const CALENDAR_ALERT_EDIT_SINGLE_OCCURRENCE =
  'Du er ved at redigere en enkelt begivenhed i en serie af begivenheder';
export const CALENDAR_ALERT_EDIT_REPEATING_SERIES = 'Du er ved at redigere en serie af begivenheder';
export const CALENDAR_ALERT_MISSING_TIMESLOT = 'Du skal tilføje nok tid til mindst en samtale.';
export const CALENDAR_ALERT_NOT_ENOUGH_SLOTS_FOR_EVERYONE = 'Vær opmærksom på, at der ikke er nok tider til alle.';

export const CALENDAR_WARNING_MISSING_TIMESLOT =
  'Hvis du ønsker, at alle skal kunne deltage, mangler du at tilføje tid til {missingSlots}, hvilket er {missingTime}';
export const CALENDAR_WARNING_EXCESS_TIMESLOT =
  'Bemærk, at du har tilføjet tid til {excessSlots} mere end nødvendigt, hvilket er {excessTime} for meget.';

export const CALENDAR_WARNING_REMOVE_ORGANIZER_FROM_SIMPLE_EVENT =
  'Du er ved at fjerne en medarrangør fra begivenheden. Det betyder, at vedkommende ikke længere vil kunne se eller redigere i begivenheden.';
export const CALENDAR_WARNING_REMOVE_ORGANIZER_FROM_MEETING_EVENT =
  'Du er ved at fjerne en medarrangør fra samtalen. Det betyder, at vedkommende ikke længere vil kunne se eller redigere i samtalen.';
export const CALENDAR_WARNING_REMOVE_ORGANIZER_FROM_EVENT_CONFIRM = 'Er du sikker på, at du vil fortsætte?';

export const CALENDAR_WEEK_IN_MONTH_ENUM_first = 'Første';
export const CALENDAR_WEEK_IN_MONTH_ENUM_second = 'Anden';
export const CALENDAR_WEEK_IN_MONTH_ENUM_third = 'Tredje';
export const CALENDAR_WEEK_IN_MONTH_ENUM_fourth = 'Fjerde';
export const CALENDAR_WEEK_IN_MONTH_ENUM_last = 'Sidste';
// view Event- need translate some works to Danish
export const CALENDAR_UPDATE_EVENT_ANSWER_BUTTON = 'Opdater svar';
export const CALENDAR_EDIT_EVENT_BUTTON = 'Rediger';
export const CALENDAR_ACCEPT_EVENT_BUTTON = 'DELTAG';
export const CALENDAR_REJECT_EVENT_BUTTON = 'AFVIS';
export const CALENDAR_MAYBE_EVENT_BUTTON = 'MÅSKE';
export const CALENDAR_EDIT_REPEATING_OCCURRENCE = 'Denne begivenhed';
export const CALENDAR_EDIT_REPEATING_SERIES = 'Hele serien';
export const CALENDAR_LABEL_EVENT_ATTENDEE = 'Gæster';
export const CALENDAR_LABEL_EVENT_CLASS = 'Klasse';
export const CALENDAR_LABEL_EVENT_TEACHER = 'Lærer';
export const CALENDAR_LABEL_EVENT_PEDAGOGUE = 'Pædagog';
export const CALENDAR_LABEL_EVENT_HELP_TEACHER = 'Hjælpelærer';
export const CALENDAR_LABEL_EVENT_SUBSTITUTE = 'Vikar';
export const CALENDAR_LABEL_EVENT_MESSAGE_CLASS = 'NOTE TIL KLASSE';
export const CALENDAR_LABEL_EVENT_MESSAGE_TEACHER = 'NOTE TIL LÆRER';
export const CALENDAR_LABEL_EVENT_MESSAGE_SUBSTITUTE = 'NOTE TIL VIKAR';
export const CALENDAR_LABEL_EVENT_LOCATION = 'Sted';
export const CALENDAR_LABEL_EVENT_CLOCK = 'Kl.';
export const CALENDAR_LABEL_ALL_DAY_EVENT_TIME = '00:00 - 23:59';
export const CALENDAR_LABEL_ALL_DAY_END_TIME = '23:59';
export const CALENDAR_LABEL_SERIES_EVENT = 'Begivenheden er en del af en serie';
export const CALENDAR_LABEL_EVENT_ENDS = 'og slutter';
export const CALENDAR_LABEL_EVENT_STARTS = 'Serien startede';
export const CALENDAR_LABEL_EVENT_NEVER_ENDS = 'og slutter aldrig';
export const CALENDAR_LABEL_RESOURCE_TYPES = 'Ressourcer';
export const CALENDAR_LABEL_EVENT_CREATOR = 'Oprettet af';
export const CALENDAR_LABEL_MOVED_FROM = 'Flyttet fra';
export const CALENDAR_LABEL_EVENT_ORGANIZERS = 'Medarrangører';
export const CALENDAR_LABEL_EVENT_CONCERNING = 'Vedrører';
export const CALENDAR_LABEL_EVENT_SUNDAY = 'Søndag';
export const CALENDAR_LABEL_EVENT_MONDAY = 'Mandag';
export const CALENDAR_LABEL_EVENT_TUESDAY = 'Tirsdag';
export const CALENDAR_LABEL_EVENT_WEDNESDAY = 'Onsdag';
export const CALENDAR_LABEL_EVENT_THURSDAY = 'Torsdag';
export const CALENDAR_LABEL_EVENT_FRIDAY = 'Fredag';
export const CALENDAR_LABEL_EVENT_SATURDAY = 'Lørdag';
export const CALENDAR_BUTTON_CANCEL = 'Annuller';
export const CALENDAR_BUTTON_SAVE = 'GEM';
export const CALENDAR_BUTTON_CREATE = 'Opret';
export const CALENDAR_BUTTON_CONFIRM = 'Opdater tidspunkt';
export const CALENDAR_BUTTON_UNDO = 'Fortryd ændring';
export const CALENDAR_POPOVER_HEADER = 'DETALJER';
export const CALENDAR_LABEL_EVENT_INVITED = 'DELTAGERE';
export const CALENDAR_LABEL_EVENT_INVITEES = 'Inviterede';
export const CALENDAR_LABEL_EVENT_ANSWERS = 'svar';
export const CALENDAR_LABEL_EVENT_INVITED_ACCEPTED = 'Deltagere';
export const CALENDAR_LABEL_EVENT_INVITED_DECLINED = 'Deltager ikke';
export const CALENDAR_LABEL_EVENT_INVITED_TENTATIVE = 'Deltager måske';
export const CALENDAR_LABEL_EVENT_INVITED_WAITING = 'Mangler svar';
export const CALENDAR_MENU_MOBILE_HEADER = 'KALENDERMENU';
export const CALENDAR_TEACHER_SUBSTITUTE = 'Vikar';
export const CALENDAR_BREAK_TEXT = 'Pause';
export const CALENDAR_RESPONSE_ACCEPTED = 'Deltager';
export const CALENDAR_RESPONSE_DECLINED = 'Deltager ikke';
export const CALENDAR_RESPONSE_TENTATIVE = 'Måske';
export const CALENDAR_RESPONSE_WAITING = 'Afventer';

export const CALENDAR_SEND_EVENT_REMINDER = 'Ryk for svar';
export const CALENDAR_VACATION_REQUEST_REMINDER_HELP_TEXT_1 =
  'Når du vælger at sende en rykker, bliver en besked sendt til de forældre, der ikke har svaret.';
export const CALENDAR_VACATION_REQUEST_REMINDER_HELP_TEXT_2 = 'Ferieanmodningen bliver vedhæftet til beskeden.';
export const CALENDAR_VACATION_REQUEST_REMINDER_HELP_TEXT_3 =
  'Modtagerne kan ikke besvare beskeden, men kan godt besvare den vedhæftede ferieanmodning.';
export const CALENDAR_EVENT_REMINDER_HELP_TEXT_1 =
  'Når du vælger at sende en rykker, bliver en besked sendt til de inviterede, der ikke har svaret.';
export const CALENDAR_EVENT_REMINDER_HELP_TEXT_2 = 'Invitation bliver vedhæftet beskeden.';
export const CALENDAR_EVENT_REMINDER_HELP_TEXT_3 =
  'Modtagerne kan ikke besvare beskeden, men kan godt besvare den vedhæftede invitation.';
export const CALENDAR_EVENT_REMINDER_MESSAGE = 'Du bedes at svare på den vedhæftede invitation.';
export const CALENDAR_VACATION_REQUEST_REMINDER_MESSAGE = 'Du bedes svare på den vedhæftede ferieanmodning.';
export const CALENDAR_EVENT_REMINDER_AUTO_GENERATED = 'Denne besked kan ikke besvares.';

export const CALENDAR_HOUR_UNIT = 't.';
export const CALENDAR_MINUTE_UNIT = 'min.';
export const CALENDAR_APPROXIMATE_TEXT = 'cirka';

export const CALENDAR_AVAILABLE_TIMESLOT = 'ledig tid';
export const CALENDAR_AVAILABLE_TIMESLOTS = 'ledige tider';
export const CALENDAR_REGISTERED_TIMESLOT = 'tilmeldt';
export const CALENDAR_REGISTERED_TIMESLOTS = 'tilmeldte';

export const CALENDAR_EVENT_REMINDER_ALERT = 'Seneste rykker blev sendt {dateTime}';
export const CALENDAR_WARNING_DRAG_DROP_DEADLINE =
  'Du kan ikke opdatere begivenheden, da start tidspunktet er tidligere end deadline for svar.';
export const CALENDAR_WARNING_EDIT_TIMESLOT = 'Du skal åbne samtalen for at redigere i den';
export const CALENDAR_WARNING_EDIT_HOLIDAY = 'Du skal åbne ferie/fri begivenheden for at redigere i den';
export const CALENDAR_WARNING_EDIT_PRESENCE_REGISTRATION = 'Du kan ikke flytte en ferieanmodning';
export const CALENDAR_NOTIFY_MESSAGE_1 = 'Er du sikker på, at du vil flytte begivenheden "';
export const CALENDAR_NOTIFY_MESSAGE_2 = '" til';
export const CALENDAR_NOTIFY_MESSAGE_3 = 'kl.';
export const CALENDAR_NOTIFY_MESSAGE_CANNOT_SEND_INVITATION =
  'Du kan som Værge ikke kommunikere med andre Værger, da en administrator har blokeret din kommunikationskanal.';
export const CALENDAR_NOTIFY_MESSAGE_NO_PROFILE_GET_INVITATION_1 =
  'Du er blokeret fra at kommunikere med brugere af profiltypen ';
export const CALENDAR_NOTIFY_MESSAGE_NO_PROFILE_GET_INVITATION_2 =
  '. Derfor vil brugere med denne profiltype ikke modtage din invitation.';
export const CALENDAR_NOTIFY_MESSAGE_NO_NOTIFICATIONS = 'Du har ingen invitationer';
export const CALENDAR_NOTIFY_MESSAGE_CHANGE_EVENT_TYPE =
  'Er du sikker på, at du vil skifte type? Hvis du skifter, vil dine ændringer blive slettet.';
// Event Message box
export const CALENDAR_CONFIRM_BOX_MESSAGE =
  'Er du sikker på du vil flytte [eventTitle] til [eventWeekday] kl. [eventTime]?';
export const CALENDAR_CONFIRM_BOX_OVERLAP_1 = 'Bemærk:';
export const CALENDAR_CONFIRM_BOX_OVERLAP_2 = 'har en anden aftale på det valgte tidspunkt';
export const CALENDAR_CONFIRM_SWITCH_INSTITUTION_1 = 'Er du sikker på, at du vil bytte institution?';
export const CALENDAR_CONFIRM_SWITCH_INSTITUTION_2 = 'Formularen nulstiller alle data, du har indtastet';

export const DAILY_OVERVIEW_SEE_CALENDAR = 'Se kalender';

// CALENDAR ADD MANAGEMENT USER MODAL
export const CALENDAR_HEADER_CALENDAR_MANAGEMENT = 'RÅDERET OVER KALENDER';
export const CALENDAR_HANDLE_CALENDAR = 'Brugere, der må håndtere min kalender';
export const CALENDAR_ADD_USER = 'Tilføj bruger:';

export const CALENDAR_WARNING_CONLICT_EVENT_TITLE = 'ADVARSEL';
export const CALENDAR_WARNING_CONLICT_EVENT_MESSAGE = 'Du har konfliktbegivenhed med nogle mennesker.';

// calendar overview
export const CALENDAR_IMPORTANT_DATE = 'Vigtige datoer';
export const CALENDAR_IMPORTANT_DATE_MORE = 'Se kalender';
export const CALENDAR_IMPORTANT_DATE_SEE_MORE = 'Se flere datoer';
export const CALENDAR_NO_IMPORTANT_DATES = 'Der er ingen vigtige datoer at vise.';
export const CALENDAR_GO_TO_DAY = 'Gå til dag';

export const CALENDAR_REGISTRATION_TIMESLOT_EVENT = 'TILMELDING';
export const CALENDAR_TIMESLOT_BLOCKED = 'Blokeret';
export const CALENDAR_TIMESLOT_HEADER_BLOCKED = 'Bloker';
export const CALENDAR_TIMESLOT_HEADER_WAITING = 'Mangler tider';
export const CALENDAR_TIMESLOT_HEADER_ACCEPTED = 'Har booket tid';
export const CALENDAR_TIMESLOT_OPTION_BLOCKED = 'Blokér tiden';
export const CALENDAR_TIMESLOT_CANCEL_EVENT = 'Deltager ikke';
export const CALENDAR_CHOOSE_TIMESLOT_EVENT = 'GEM';
export const CALENDAR_ORGANIZER = 'Arrangør';
export const CALENDAR_PARTICIPANT = 'Deltagere';
export const CALENDAR_CONCERNING_CHILD = 'om';
export const CALENDAR_NAVBAR_TAB_CALENDAR = 'KALENDER';
export const CALENDAR_NAVBAR_TAB_NOTIFICATIONS = 'INVITATIONER';

export const CALENDAR_RESOURCES_ALREADY_BOOKED = 'De valgte ressourcer er allerede booket';
export const CALENDAR_RESOURCES_NO_PERMISSION = 'Du har ikke tilladelse til at booke de valgte ressourcer';
export const CALENDAR_CREATE_EVENT_ERROR = 'Kunne ikke oprette begivenhed';
export const CALENDAR_BLOCK_COMMUNICATION_TITLE = 'ADVARSEL';
export const CALENDAR_BLOCK_COMMUNICATION_BODY =
  'Du er blevet blokeret fra at kommunikere i Aula. Kontakt institutionens administrator for mere information';
export const CALENDAR_RECIPIENT_BLOCK_COMMUNICATION_BODY =
  'Kalenderindkaldelsen kan ikke sendes, da kommunikation til modtageren er blokeret af en administrator. Kontakt institutionens administrator for mere information';
export const CALENDAR_RECIPIENT_NOT_ABLE_TO_ADD_OR_REMOVE_RECIPIENTS_TITLE = 'BEMÆRK';
export const CALENDAR_RECIPIENT_NOT_ABLE_TO_ADD_OR_REMOVE_RECIPIENTS_BODY =
  'Du kan ikke fjerne eller tilføje flere deltagere end dem, der allerede er i gruppen, da du opretter begivenheden gennem gruppens egen side.';
export const CALENDAR_RESPONSE_PARTICIPATE_TITLE = 'Deltager';
export const CALENDAR_RESPONSE_AWAITING_TITLE = 'Afventer svar';
export const CALENDAR_RESPONSE_NOT_PARTICIPATE_TITLE = 'Deltager ikke';
export const CALENDAR_CONFLICT_EVENT_CONFIRMED_TITLE = 'Vil du stadig oprette begivenheden?';
export const CALENDAR_REPEAT_TYPE_HEADER = 'Vælg en af mulighederne herunder';
export const CALENDAR_FILTER_TITLE = 'Kalendermenu';
export const CALENDAR_CONFIRMED_DELETE_EVENT = 'Er du sikker på du vil slette begivenheden';
export const CALENDAR_EVENT_PRIVATE_TITLE = 'Nej';
export const CALENDAR_EVENT_NOT_PRIVATE_TITLE = 'Ja';
export const CALENDAR_TAB_TITLE = 'Kalender';
export const MY_CALENDAR_TAB_TITLE = 'FØDSELSDAGE';
export const CALENDAR_ACCESS_ON_BEHALF_OF =
  'OBS: Du bruger kalenderen på vegne af {0}. Klik her for at komme tilbage til din egen kalender';
export const EVENT_COMMUNICATION_EVENT_TYPE_TITLE = 'Samtale';
export const REGARDING_CHILDREND_LABEL = 'Vedrører';
export const TIMESLOT_IS_ALREADY_REGISTERED = 'Den valgte tid er allerede optaget.';
export const CHOOSE_NEW_SLOT_MESSAGE = 'Vælg derfor en ny tid.';
export const CALENDAR_ALERT_ONLY_ONE_GROUP = 'Du kan kun vælge én gruppe ad gangen';
export const CALENDAR_CANT_SAVE_VACATION_WARNING =
  'Du skal vælge nogle datoer for ferie/fri, eller vælge at dit barn kommer i hele perioden';
export const CALENDAR_VACATION_CHILD_NOT_COMING = 'Mit barn kommer, da vi ikke har planlagt ferie/fri i den periode';
export const RESPONSE_VACATION_REQUEST_FOR = 'Registrer ferie for ';

// PLANNING ASSISTANT
export const PLANNING_ASSISTANT_HEADER = 'Planlægningsassistent';
export const PLANNING_ASSISTANT_BUTTON = 'Åbn planlægningsassistenten';
export const PLANNING_ASSISTANT_ADD_RECIPIENT = 'Tilføj deltager';
export const PLANNING_ASSISTANT_TOO_MANY_INVITEES = 'Du kan kun se kalenderen for 40 inviterede';
// ARIA LABELS
export const ARIA_LABEL_VACATION_REQUEST_CHOOSE_INSTITUTION = 'Vælg institution';
export const ARIA_LABEL_VACATION_REQUEST_CHOOSE_DEPARTMENTS = 'Vælg afdelinger';
export const ARIA_LABEL_VACATION_REQUEST_CHOOSE_GROUPS = 'Vælg grupper';
export const ARIA_LABEL_VACATION_REQUEST_NOTES = 'Skriv her';
export const ARIA_LABEL_VACATION_REQUEST_SEE_LESS_NOTE = 'Vis mindre note';
export const ARIA_LABEL_VACATION_REQUEST_SEE_FULL_NOTE = 'Vis mindre note';
export const ARIA_LABEL_VACATION_REQUEST_SHOW_LIST_CHILDREN = 'Vis liste over børn';
export const ARIA_LABEL_VACATION_REQUEST_HIDE_CHILDREN = 'Skjul børn';
export const ARIA_LABEL_REMOVE_DELEGATE_ACCESS = 'Fjern råderet';
export const ARIA_LABEL_CHOOSE_MEETING = 'vælge et mødetidspunkt';
