<template>
<div class='widget-W0102V0001'>
  <div v-if="userProfile==='employee'">
    <h2><a v-bind:href="envUrl" target="_blank">{{ envTitle }}</a></h2>
    <div v-if="showFeed">
      <h3>Nyheder</h3>
      <ul>
        <li v-for="entry in feedEntries" :key="entry.url">
          <a v-bind:href="entry.url" target="_blank" v-bind:title="entry.title">
            <img v-if="entry.type==='News article'"
                 alt="Nyhed"
                 src="data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMjQgMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+DQo8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTQgMThWNUgxNlYxOEMxNiAxOC4zNTA2IDE2LjA2MDIgMTguNjg3MiAxNi4xNzA3IDE5SDVDNC40NDc3MiAxOSA0IDE4LjU1MjMgNCAxOFpNMTkgMjFINUMzLjM0MzE1IDIxIDIgMTkuNjU2OSAyIDE4VjNIMTZIMTdIMThWOEgyMlY5VjEwVjE4QzIyIDE5LjY1NjkgMjAuNjU2OSAyMSAxOSAyMVpNMTggMTBIMjBWMThDMjAgMTguNTUyMyAxOS41NTIzIDE5IDE5IDE5QzE4LjQ0NzcgMTkgMTggMTguNTUyMyAxOCAxOFYxMFpNMTAgN1YxMUg2VjdIMTBaTTE0IDEwVjhIMTFWMTBIMTRaTTE0IDEyVjE0SDZWMTJIMTRaTTE0IDE3VjE1SDZWMTdIMTRaIiAvPg0KPC9zdmc+DQo="
            />
            <img v-else-if="entry.type==='Event'"
                 alt="Begivenhed"
                 src="data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMjQgMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+DQo8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTggNkg2VjVINFY4SDIwVjVIMThWNkgxNlY1SDhWNlpNMjAgMTBINFYyMEgyMFYxMFpNMTYgM0g4VjJINlYzSDRDMi44OTU0MyAzIDIgMy44OTU0MyAyIDVWMjBDMiAyMS4xMDQ2IDIuODk1NDMgMjIgNCAyMkgyMEMyMS4xMDQ2IDIyIDIyIDIxLjEwNDYgMjIgMjBWNUMyMiAzLjg5NTQzIDIxLjEwNDYgMyAyMCAzSDE4VjJIMTZWM1pNNyAxNFYxMkg5VjE0SDdaTTExIDE0SDEzVjEySDExVjE0Wk0xNSAxNFYxMkgxN1YxNEgxNVpNNyAxNlYxOEg5VjE2SDdaTTEzIDE4SDExVjE2SDEzVjE4WiIvPg0KPC9zdmc+DQo="
            />
            <img v-else
                 alt="Opslag"
                 src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNMiAyMGEyIDIgMCAwMDIgMmgxMy40MTRMMjIgMTcuNDE0VjRhMiAyIDAgMDAtMi0ySDRhMiAyIDAgMDAtMiAydjE2ek00IDRoMTZ2MTFoLTNhMiAyIDAgMDAtMiAydjNINFY0em0xMyAxM2gyLjU4NkwxNyAxOS41ODZWMTd6TTcgMTV2Mmg2di0ySDd6bTAtMnYtMmgxMHYySDd6bTAtNnYyaDEwVjdIN3oiIGNsaXAtcnVsZT0iZXZlbm9kZCI+PC9wYXRoPjwvc3ZnPg=="
            />
            <span>{{ entry.title }}</span>
          </a>
        </li>
      </ul>
    </div>
    <div v-if="showDocuments">
      <h3>Viden</h3>
      <ul>
        <li v-for="doc in documents" :key="doc.url">
          <a v-bind:href="doc.url" target="_blank" v-bind:title="doc.title">
            <img v-if="doc.type==='Folder'"
                 alt="Folder"
                 src="data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMjQgMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+DQo8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTIxIDVDMjIuMTA0NiA1IDIzIDUuODk1NDMgMjMgN1YxOUMyMyAyMC4xMDQ2IDIyLjEwNDYgMjEgMjEgMjFIM0MxLjg5NTQzIDIxIDEgMjAuMTA0NiAxIDE5VjVDMSAzLjg5NTQzIDEuODk1NDMgMyAzIDNIOUMxMC4xMiAzIDEwLjgzMjkgMy40NzU0NSAxMS41NDkgNC4zNzg4NUMxMS41Njg4IDQuNDAzODUgMTEuNjE3MSA0LjQ2NjA4IDExLjY3MDYgNC41MzVMMTEuNjcwNyA0LjUzNTE1QzExLjc0NjEgNC42MzIzNiAxMS44MzE4IDQuNzQyODMgMTEuODYyNSA0Ljc4MDgyQzExLjg5MzUgNC44MTkyNSAxMS45MTk2IDQuODUxMDMgMTEuOTQxMiA0Ljg3NzNMMTEuOTQxMiA0Ljg3NzMyQzEyLjAyOTIgNC45ODQyOCAxMi4wNDE5IDQuOTk5NzggMTIuMDAxOCA1SDIxWk0yMSA5VjdMMTEuOTk0NiA2Ljk5OTk5QzExLjI3NjUgNi45OTYxNCAxMC44MDg3IDYuNjU5OTEgMTAuMzA1OCA2LjAzNjU0QzEwLjI2MDMgNS45ODAwNiAxMC4xNDk2IDUuODM3MzcgMTAuMDY5MiA1LjczMzc2TDEwLjA2OTIgNS43MzM3NUwxMC4wNjkxIDUuNzMzNTdDMTAuMDI3MSA1LjY3OTQ0IDkuOTkzMzYgNS42MzYgOS45ODE2IDUuNjIxMTZDOS42MDcwMiA1LjE0ODU3IDkuMzg0MjQgNSA5IDVIM1Y5SDIxWk0yMSAxMUgzVjE5SDIxVjExWiIgLz4NCjwvc3ZnPg0K"
            />
            <img v-else
                 alt="Fil"
                 src="data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMjQgMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik01IDFIMTUuNDE0MkwyMSA2LjU4NTc5VjIxQzIxIDIyLjEwNDYgMjAuMTA0NiAyMyAxOSAyM0g1QzMuODk1NDMgMjMgMyAyMi4xMDQ2IDMgMjFWM0MzIDEuODk1NDMgMy44OTU0MyAxIDUgMVpNMTMgM0g1VjIxSDE5VjlIMTVDMTMuODk1NCA5IDEzIDguMTA0NTcgMTMgN1YzWk0xNSAzLjQxNDIxVjdIMTguNTg1OEwxNSAzLjQxNDIxWk04IDE3VjE1SDE0VjE3SDhaTTggMTFWMTNIMTZWMTFIOFoiLz48L3N2Zz4NCg=="
            />
            <span>{{ doc.title }}</span>
          </a>
        </li>
      </ul>
    </div>
    <div v-if="showLinks">
      <h3>Favoritter</h3>
      <ul>
        <li v-for="link in links" :key="link.url">
          <a v-bind:href="link.url" target="_blank" v-bind:title="link.title">
            <img
              alt="Link"
              src="data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMjQgMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMS43MDQyIDEzLjcwNzFMMTguOTk3MSA2LjQxNDIxVjExSDIwLjk5NzFWM0gxMi45OTcxVjVIMTcuNTgyOUwxMC4yOSAxMi4yOTI5TDExLjcwNDIgMTMuNzA3MVpNMTkgMTlWMTRIMTdWMTlINVY3SDEwVjVINUMzLjg5NTQzIDUgMyA1Ljg5NTQzIDMgN1YxOUMzIDIwLjEwNDYgMy44OTU0MyAyMSA1IDIxSDE3QzE4LjEwNDYgMjEgMTkgMjAuMTA0NiAxOSAxOVoiIC8+PC9zdmc+DQo="
            />
            <span>{{ link.title }}</span>
          </a>
        </li>
      </ul>
    </div>
    <div v-if="showError" class="error">
      <div>Der opstod en fejl under indlæsning af nyheder og dokumenter.</div>
      <center><button v-on:click="loadData()">Prøv igen</button></center>
    </div>
    <center v-if="showLoading"><div class="fa fa-spinner"></div></center>
  </div>
</div>
</template>

<script>
export default {
  props: {
    axios: Function,
    getAulaToken: Function,
    placement: String,
    userProfile: String
  },
  data() {
    return {
      aulaToken: null,
      coliboServer: "",
      documents: [],
      links: [],
      feedEntries: [],
      envTitle: "",
      envUrl: "",
      showDocuments: false,
      showError: false,
      showLinks: false,
      showFeed: false,
      showLoading: true
    };
  },
  mounted() {
    this.aulaToken = this.getAulaToken && this.getAulaToken();
    this.coliboServer = "aula-integration.colibo.com";
  },
  watch: {
    aulaToken: function () {
      if (this.aulaToken != undefined) {
        this.loadData();
      }
    }
  },
  methods: {
    loadData() {
      this.showLoading = true;
      this.showError = false;
      this.axios
        .get(`https://${this.coliboServer}/api/Aula`, {
          headers: {
            Authorization: `bearer ${this.aulaToken}`,
          },
        })
        .then(response => {
          this.documents = response.data.documents;
          this.links = response.data.favorites;
          this.feedEntries = response.data.feedEntries;
          this.showDocuments = this.documents && this.documents.length;
          this.showLinks = this.links && this.links.length;
          this.showFeed = this.feedEntries && this.feedEntries.length;
          this.envTitle = response.data.coliboInstanceName || "Colibo";
          this.envUrl = response.data.coliboInstanceUrl;
        })
        .catch(() => {
          this.showError = true;
        })
        .finally(() => {
          this.showLoading = false;
        });
    },
  },
};
</script>

<style lang="scss">
.widget-W0102V0001 {
h2 {
  margin: 0;
  font-size: 1.125rem;
}
h3 {
  margin: 2rem 0 0;
  font-size: 1rem;
}
ul {
  list-style-type: none;
  padding: 0;
  margin-top: 0.5rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
  max-height: 13.5rem;
  overflow-y: auto;
  overflow-x: hidden;
}
a {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
a:not([href]) {
  cursor: default;
}
li {
  display: block;
}
li > a {
  display: flex;
  align-items: center;
  padding: 0.5rem;
  width: 100%;
  text-align: left;
  box-sizing: border-box;
  font-size: 0.75rem;
}
li > a[href]:hover {
  background: rgba(0, 0, 0, 0.05);
}
li > a > span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
h2 > a:hover {
  text-decoration: underline;
  margin: 0;
}
img {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.5rem;
}
button {
  margin-top: 0.5rem;
}
.error {
  font-size: 12px;
  font-style: italic;
}
}
</style>
