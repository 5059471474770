<template>
  <div class="cicero-frame">
    <div class="cicero-contents" :style="setBackgroundImage(backgroundImageUrl)">
      <div class="cicero-tab-container">
        <div @click="selectTab(loansTab)" class="cicero-tab"
             :class="{'cicero-selected-tab': selectedTab === loansTab}">
          udlån {{this.error === '' ? '(' + loans.length + ')' : ''}}
        </div>
        <div @click="selectTab(reservationsTab)" class="cicero-tab"
             :class="{'cicero-selected-tab': selectedTab === reservationsTab}">
          reservationer {{this.error === '' ? '(' + reservations.length + ')' : ''}}
        </div>
      </div>
      <div class="cicero-tab-triangle" :style="setTriangleIndentation()"></div>
      <div v-if="showMessage()" class="cicero-error-container">
        <div class="cicero-error-image" :style="setBackgroundImage(errorStateImageurl)"></div>
        <div class="cicero-error-text">{{getMessage()}}</div>
        <div class="cicero-long-term-loans-error-button" @click="displayLongtermLoans()" v-if="this.shouldDisplayLongtermLoansButton() && selectedTab === loansTab">
          <div class="cicero-long-term-error-button-text">
            Vis langtidsudlån {{'(' + this.longtermLoans.length + ')'}}
          </div>
        </div>
      </div>
      <div v-if="!showMessage()">
        <div v-if="loading" class="cicero-slides">
          <div v-for="n in 4" :key="n" class="cicero-slide">
            <div class="cicero-loading-placeholder"></div>
          </div>
        </div>
        <div v-if="!loading" class="cicero-slides">
          <div class="cicero-prev cicero-arrow" @click="moveCarousel(-1)" v-if="!atHeadOfList">
            <i class="fa fa-angle-left fa-2x"></i>
          </div>
          <div class="cicero-carousel">
            <div ref="carouselItems" class="cicero-carousel-items" :style="slideAnimation()">
              <div v-if="selectedTab === loansTab && loansToShow.length > 0" v-for="loan of loansToShow" :key="loan.id"
                   class="cicero-slide" @click="onSlideClicked(loan.id)">
                <div v-if="multipleChildren()" class="cicero-patron-name">{{formatName(loan.patronDisplayName)}}</div>
                <div v-if="!multipleChildren()" class="cicero-one-child-filler"></div>
                <div class="cicero-loans-layer-container">
                  <div class="cicero-loan">
                    <div v-if="loan.coverImageUrl" class="cicero-real-cover-image">
                      <img :src="loan.coverImageUrl"
                           alt="Forsidebillede"
                           @error="onCoverImageLoadError(loan)">
                    </div>
                    <div v-if="!loan.coverImageUrl"
                         :style="setBackgroundImage(defaultCoverImageUrl)"
                         class="cicero-default-cover-image">
                      <div :style="overlayBackgroundColour(loan.title)"
                           class="cicero-overlay">
                        <div v-if="loan.author" class="cicero-text cicero-author">{{loan.author}}</div>
                        <div class="cicero-text cicero-title">
                          {{truncateTitle(loan.title)}}
                        </div>
                      </div>
                    </div>
                    <div v-if="isLongtermLoan(loan)" class="cicero-longterm-loan-info">Langtidsudlån</div>
                    <div v-if="loan.numberOfLoans < 2" class="cicero-info-icon" :style="loanInfoIconExtraStyle(loan.dueDate)">i</div>
                    <div v-if="loan.numberOfLoans > 1" class="cicero-number-of-loans-icon">{{loan.numberOfLoans}}</div>
                    <div class="cicero-hover-container"></div>
                    <div class="cicero-detailed-view" :style="ciceroDetailedViewExtraStyle(loansTab, loan.id)">
                      <div class="cicero-detailed-view-header">
                        <div v-if="loan.author" class="cicero-text cicero-author">{{loan.author}}</div>
                        <div class="cicero-text cicero-title">
                          {{truncateTitle(loan.title)}}
                        </div>
                      </div>
                      <div class="cicero-detailed-view-footer">
                        <div class="cicero-text cicero-handin-text">Skal afleveres</div>
                        <div class="cicero-text cicero-date">
                          {{formatDate(loan.dueDate)}}
                        </div>
                        <div class="cicero-text cicero-return-alert"
                             :style="alertTextColour(loan.dueDate)">
                          {{formatLoanReturnAlertText(loan.dueDate)}}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="loan.numberOfLoans > 1" class="cicero-loans-layer-1"></div>
                  <div v-if="loan.numberOfLoans > 1" class="cicero-loans-layer-2"></div>
                </div>
              </div>
              <div class="cicero-long-term-loans-slide-button" @click="displayLongtermLoans()" v-if="this.shouldDisplayLongtermLoansButton() && selectedTab === loansTab">
                <div class="cicero-long-term-loans-slide-button-text">
                  Vis langtidsudlån {{'(' + this.longtermLoans.length + ')'}}
                </div>
              </div>
              <div v-if="selectedTab === reservationsTab && reservations.length > 0" v-for="reservation of reservations"
                   :key="reservation.id" class="cicero-slide"
                   @click="onSlideClicked(reservation.id)">
                <div v-if="multipleChildren()" class="cicero-patron-name">
                  {{formatName(reservation.patronDisplayName)}}
                </div>
                <div v-if="!multipleChildren()" class="cicero-one-child-filler"></div>
                <div class="cicero-reservation">
                  <img v-if="reservation.coverImageUrl" :src="reservation.coverImageUrl"
                       alt="Forsidebillede"
                       @error="onCoverImageLoadError(reservation)" class="cicero-real-cover-image">
                  <div v-if="!reservation.coverImageUrl"
                       :style="setBackgroundImage(defaultCoverImageUrl)"
                       class="cicero-default-cover-image">
                    <div :style="overlayBackgroundColour(reservation.title)"
                         class="cicero-overlay">
                      <div v-if="reservation.author" class="cicero-text cicero-author">{{reservation.author}}</div>
                      <div class="cicero-text cicero-title">
                        {{truncateTitle(reservation.title)}}
                      </div>
                    </div>
                  </div>
                  <div class="cicero-info-icon" :style="reservationInfoIconExtraStyle(reservation.state)">i</div>
                  <div class="cicero-hover-container"></div>
                  <div class="cicero-detailed-view" :style="ciceroDetailedViewExtraStyle(reservationsTab, reservation.id)">
                    <div class="cicero-detailed-view-header">
                      <div v-if="reservation.author" class="cicero-text cicero-author">{{reservation.author}}</div>
                      <div class="cicero-text cicero-title">
                        {{truncateTitle(reservation.title)}}
                      </div>
                    </div>
                    <div class="cicero-detailed-view-footer">
                      <div v-if="reservation.state === 'reserved'" class="cicero-text cicero-number-in-queue">
                        {{'Du er nr. ' + reservation.numberInQueue + ' i køen'}}
                      </div>
                      <div v-if="reservation.state === 'readyForPickup'">
                        <div class="cicero-text cicero-details-header">
                          {{'Kan hentes indtil ' + formatDate(reservation.pickupDeadline)}}
                        </div>
                        <div class="cicero-text cicero-ready-for-pickup">
                          Klar til afhentning
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="cicero-next cicero-arrow" @click="moveCarousel(1)" v-if="!atEndOfList">
            <i class="fa fa-angle-right fa-2x"></i>
          </div>
        </div>
      </div>
      <div class="cicero-footer">
        <a :href="ciceroSurfBranchLandingPage()" target="_blank">
          <img :src="logoUrl" height="13" alt="Cicero portal">
        </a>
        <a :href="ciceroSurfBranchLandingPage()" target="_blank">Besøg biblioteket ></a>
      </div>
    </div>
    <div class="cicero-wedge-container">
      <div class="cicero-wedge"></div>
    </div>
  </div>
</template>

<script>
  module.exports = {
    props: {
      axios: Function,
      moment: Function,
      sessionUUID: String,
      placement: String,
      isMobileApp: Boolean,
      userProfile: String,
      getAulaToken: Function,
      assuranceLevel: Number,
      childFilter: Array,
      institutionFilter: Array,
      group: String
    },

    data: function () {
      const surfBackendRootUrl = 'https://surf.cicero-suite.com';
      const surfFrontendRootUrl = 'https://surf.cicero-suite.com';

      return {
        aulaToken: null,
        widgetVersion: '1.6',
        currentOffset: 0,
        carouselWidth: 0,
        slidesWidth: 0,
        widthOfLongtermLoansSlideButton: 58,
        remaingSlidesWidth: 0,
        carouselInitialised: false,
        slideLengthFactor: 0.5,
        loading: true,
        branchIds: [],
        loans: [],
        longtermLoans: [],
        loansToShow: [],
        displayingLongtermLoans: false,
        reservations: [],
        surfFrontendRootUrl: surfFrontendRootUrl,
        surfBackendLibraryStatusUrl: surfBackendRootUrl + '/portal-api/rest/aula/library/status/v3?',
        logoUrl: surfFrontendRootUrl + '/assets/cui/logo/cicero-portal-black.svg',
        defaultCoverImageUrl: surfFrontendRootUrl + '/assets/defaultCoverImage.png',
        backgroundImageUrl: surfFrontendRootUrl + '/assets/aulaCiceroPillBackground.svg',
        errorStateImageurl: surfFrontendRootUrl + '/assets/aulaCiceroEmptyState.svg',
        coverImageHeight: 160,
        error: '',
        currentlySelectedLoanId: 0,
        currentlySelectedReservationId: 0,
        dueSoonColour: '#f5a623',
        dueColour: '#F6485C',
        dueIconColour: '#d0021b',
        loansTab: 0,
        reservationsTab: 1,
        selectedTab: 0,
      }
    },

    computed: {
      atEndOfList() {
        return this.carouselWidth - this.currentOffset >= this.slidesWidth ||
          this.slidesWidth <= this.carouselWidth;
      },
      atHeadOfList() {
        return this.currentOffset === 0;
      },
    },

    methods: {
      setBackgroundImage(url) {
        return {
          backgroundImage: 'url("' + url + '")'
        }
      },

      setTriangleIndentation() {
        return {
          position: 'relative',
          left: this.selectedTab === this.loansTab ? '40px' : '180px',
        }
      },

      slideAnimation() {
        return {transform: 'translateX' + '(' + this.currentOffset + 'px' + ')'};
      },

      moveCarousel(direction) {
        if (direction === 1 && !this.atEndOfList) {
          this.slideRight();
        } else if (direction === -1 && !this.atHeadOfList) {
          this.slideLeft();
        }
      },

      slideRight() {
        if (this.remaingSlidesWidth < this.slideLength()) {
          this.slideRemainingWidth();
        } else {
          this.remaingSlidesWidth -= this.slideLength();
          this.currentOffset -= this.slideLength();
        }
      },

      slideLength() {
        return this.carouselWidth * this.slideLengthFactor;
      },

      slideRemainingWidth() {
        this.currentOffset -= this.remaingSlidesWidth;
        this.remaingSlidesWidth = 0;
      },

      slideLeft() {
        if (this.remaingSlidesWidth + this.slideLength() >= this.calculateRemainingSlidesWidth(0)) {
          this.resetCarousel();
        } else {
          this.currentOffset += this.slideLength();
          this.remaingSlidesWidth += this.slideLength();
        }
      },

      resetCarousel() {
        this.currentOffset = 0;
        this.remaingSlidesWidth = this.calculateRemainingSlidesWidth(0);
      },

      overlayBackgroundColour(title) {
        // Algorithm and constants taken from Surf's MaterialCoverComponent
        const overlayColours = [
          'rgba(64, 93, 100, 0.8)',
          'rgba(25, 53, 64, 0.8)',
          'rgba(141, 86, 1, 0.6)',
          'rgba(154, 62, 9, 0.65)',
          'rgba(123, 15, 0, 0.65)',
          'rgba(60, 62, 64, 0.5)',
          'rgba(27, 53, 46, 0.7)',
          'rgba(78, 74, 21, 0.7)',
          'rgba(66, 44, 16, 0.7)',
          'rgba(80, 20, 92, 0.5)',
          'rgba(41, 119, 112, 0.6)',
          'rgba(30, 86, 198, 0.5)',
          'rgba(172, 32, 36, 0.7)',
          'rgba(229, 172, 57, 0.75)',
          'rgba(102, 102, 103, 0.6)'
        ];
        const split = title ? title.split(' ') : [];
        const sum = split.reduce((acc, char) => acc + char.charCodeAt(0), 0);
        return {
          backgroundColor: overlayColours[sum % overlayColours.length],
        };
      },

      daysToDueDate(dueDateString) {
        const dueDate = this.moment(dueDateString);
        return dueDate.isValid()
          ? Math.ceil(this.moment.duration(dueDate.diff(this.moment())).asDays())
          : null;
      },

      selectAlertColour(dueDateString, noAlertColour) {
        const daysToDueDate = this.daysToDueDate(dueDateString);
        if (daysToDueDate === null || daysToDueDate > 3) {
          return noAlertColour;
        } else if (daysToDueDate <= 0) {
          return this.dueColour;
        } else if (daysToDueDate <= 3) {
          return this.dueSoonColour;
        }
      },

      selectLoanIconColour(dueDateString, noAlertColour) {
        const daysToDueDate = this.daysToDueDate(dueDateString);
        if (daysToDueDate === null || daysToDueDate > 3) {
          return noAlertColour;
        } else if (daysToDueDate <= 0) {
          return this.dueIconColour;
        } else if (daysToDueDate <= 3) {
          return this.dueSoonColour;
        }
      },

      loanInfoIconExtraStyle(dueDateString) {
        return {
          background: this.selectLoanIconColour(dueDateString, '#555555'),
        };
      },

      isLongtermLoan(loan) {
        return !!this.longtermLoans.find(l => l.id === loan.id);
      },

      reservationInfoIconExtraStyle(state) {
        return {
          background: state === 'readyForPickup' ? '#43A047' : '#555555',
        };
      },

      ciceroDetailedViewExtraStyle(selectedTab, itemId) {
        const active = (selectedTab === this.loansTab && itemId === this.currentlySelectedLoanId) || (selectedTab === this.reservationsTab && itemId === this.currentlySelectedReservationId);
        return {
          top: active ? '0px' : '100%',
          transition: active ? 'top 0.25s ease-out' : 'top 0.25s ease-in'
        };
      },

      alertTextColour(dueDateString) {
        return {
          color: this.selectAlertColour(dueDateString, '#FFFFFF'),
        };
      },

      fetchStatus() {
        this.loans = [];
        this.longtermLoans = [];
        this.reservations = [];
        this.branchIds = [];
        this.loading = true;
        this.error = '';
        this.selectedTab = this.loansTab;
        this.axios.get(
          this.surfBackendLibraryStatusUrl +
          this.createQueryParameter('institutions', this.institutionFilter) +
          '&' + this.createQueryParameter('children', this.childFilter) +
          '&coverImageHeight=' + this.coverImageHeight +
          '&widgetVersion=' + this.widgetVersion +
          '&userProfile=' + this.userProfile +
          '&sessionUUID=' + this.sessionUUID,
          {
            headers: {
              'Authorization': 'Bearer ' + this.aulaToken
            }
          }
        )
          .then(response => {
            this.loans = response.data.loans;
            this.longtermLoans = response.data.longtermLoans;
            this.loansToShow = this.loans;
            this.sortLoans(this.loansToShow);
            this.reservations = response.data.reservations;
            this.sortReservations();
            this.branchIds = response.data.branchIds ? response.data.branchIds : [];
            this.loading = false;
            this.currentlySelectedLoanId = 0;
            this.currentlySelectedReservationId = 0;
            this.resetCarousel();
          }).catch(e => {
          if ([400, 403, 404, 500].includes(e.response.status)) {
            this.error = 'Noget gik galt. Prøv igen senere.';
          }
          if (401 === e.response.status) {
            this.error = e.response.data === null || e.response.data === '' ? 'Noget gik galt. Prøv igen senere.' : e.response.data;
          }
          this.loading = false;
          this.currentlySelectedLoanId = 0;
          this.currentlySelectedReservationId = 0;
          this.resetCarousel();
        })
      },

      sortLoans(loans) {
        const moment = this.moment;
        loans.sort(function (l1, l2) {
          const d1 = moment(l1.dueDate);
          const d2 = moment(l2.dueDate);
          if (!(d1.isValid() && d2.isValid())) {
            return 0;
          }
          return d1.diff(d2);
        });
      },

      sortReservations() {
        const moment = this.moment;
        this.reservations.sort(function (r1, r2) {
          const sensibleStates = ['readyForPickup', 'reserved'];
          if (sensibleStates.includes(r1.state) && !sensibleStates.includes(r2.state)) {
            return -1;
          }
          if (r1.state === sensibleStates[0] && r2.state === sensibleStates[1]) {
            return -1;
          }
          if (r1.state === sensibleStates[1] && r2.state === sensibleStates[0]) {
            return 1;
          }
          if (r1.state === 'readyForPickup' && r2.state === 'readyForPickup') {
            const d1 = moment(r1.pickupDeadline);
            const d2 = moment(r2.pickupDeadline);
            if (!(d1.isValid() && d2.isValid())) {
              return 0;
            }
            return d1.diff(d2);
          }
          if (r1.state === 'reserved' && r2.state === 'reserved') {
            return r1.numberInQueue - r2.numberInQueue;
          }
          return 0;
        });
      },

      displayLongtermLoans() {
        this.sortLoans(this.longtermLoans);
        this.longtermLoans.map(l => this.loansToShow.push(l));
        this.getWidthsForSliding();
        this.displayingLongtermLoans = true;
      },

      showMessage() {
        return !this.loading && this.getMessage() !== '';
      },

      getMessage() {
        if (this.error !== '') {
          return this.error;
        } else if (this.selectedTab === this.loansTab && this.loans.length === 0) {
          return 'Du har ingen udlån i øjeblikket';
        } else if (this.selectedTab === this.reservationsTab && this.reservations.length === 0) {
          return 'Du har ingen reservationer i øjeblikket';
        } else {
          return '';
        }
      },

      selectTab(tab) {
        this.selectedTab = tab;
        this.getWidthsForSliding();
        this.currentlySelectedLoanId = 0;
        this.currentlySelectedReservationId = 0;
        this.currentOffset = 0;
      },

      createQueryParameter(key, items) {
        if (items) {
          const trimmed = items.map(s => s.trim()).filter(s => !!s);
          if (trimmed.length > 0) {
            return key + '=' + trimmed.join('&' + key + '=');
          }
        }
        return '';
      },

      truncateTitle(title) {
        if (title) {
          return title.length > 50 ? title.substr(0, 50) + '...' : title;
        } else {
          return '';
        }
      },

      getWidthsForSliding() {
        if (this.$refs.carouselItems && !this.carouselInitialised) {
          this.carouselInitialised = true;
          this.calculateCarouselProperties(0);
        } else if (this.$refs.carouselItems && this.slidesWidth !== this.sumOfSlidesWidths() &&
          this.carouselInitialised) {
          this.calculateCarouselProperties(this.currentOffset);
        }
      },

      calculateCarouselProperties(offset) {
        this.carouselWidth = this.$refs.carouselItems.clientWidth;
        this.slidesWidth = this.calculateSumOfSlidesWidths();
        this.slidesWidth += this.shouldDisplayLongtermLoansButton() ? this.widthOfLongtermLoansSlideButton : 0;
        this.remaingSlidesWidth = this.calculateRemainingSlidesWidth(offset);
      },

      shouldDisplayLongtermLoansButton() {
        return this.longtermLoans.length > 0 && !this.displayingLongtermLoans;
      },

      sumOfSlidesWidths() {
        return this.shouldDisplayLongtermLoansButton() ? this.calculateSumOfSlidesWidths() + this.widthOfLongtermLoansSlideButton : this.calculateSumOfSlidesWidths();
      },

      calculateRemainingSlidesWidth(currentOffset) {
        return currentOffset + this.slidesWidth - this.carouselWidth;
      },

      calculateSumOfSlidesWidths() {
        let width = 0;
        this.$refs.carouselItems.childNodes.forEach(childNode => {
          if (childNode.classList && childNode.classList.contains('cicero-slide')) {
            width += childNode.clientWidth + 4; // We need to add the margin of the cicero-slide element otherwise will the slide calculations be wrong
          }
        });
        return width;
      },

      onCoverImageLoadError(item) {
        item.coverImageUrl = null;
      },

      onSlideClicked(itemId) {
        if (this.selectedTab === this.loansTab) {
          this.currentlySelectedLoanId = (itemId === this.currentlySelectedLoanId) ? 0 : itemId;
        }
        if (this.selectedTab === this.reservationsTab) {
          this.currentlySelectedReservationId = (itemId === this.currentlySelectedReservationId) ? 0 : itemId;
        }
      },

      multipleChildren() {
        return this.childFilter.length > 1;
      },

      formatDate(date) {
        return date ? this.moment(date).format('LL') : 'ukendt dato';
      },

      formatLoanReturnAlertText(dueDateString) {
        const daysToDueDate = this.daysToDueDate(dueDateString);
        if (daysToDueDate === null) {
          return '';
        } else if (daysToDueDate < 1) {
          return 'med det samme';
        } else if (daysToDueDate === 1) {
          return 'om 1 dag';
        } else {
          return 'om ' + daysToDueDate + ' dage';
        }
      },

      formatName(name) {
        return name ? name.split(' ')[0] : '';
      },

      validateConstraintsAndFetchStatus() {
        if (![2, 3].includes(this.assuranceLevel)) {
          this.error = 'Du skal være logget ind for at se udlån og reservationer.';
        } else {
          this.fetchStatus();
        }
      },

      ciceroSurfBranchLandingPage() {
        return this.surfFrontendRootUrl + (this.branchIds.length > 0 ? '/institution/' + this.branchIds[0] : '');
      },

      arraysDiffer(a1, a2) { // assuming both are arrays of strings
        if ((a1 === null && a2 !== null) || (a2 === null && a1 !== null)) {
          return true;
        }
        if (a1 === null && a2 === null) {
          return false;
        }
        if (a1.length !== a2.length) {
          return true
        }
        for (let i = 0; i < a1.length; i++) {
          if (a1[i] !== a2[i]) {
            return true;
          }
        }
        return false;
      }
    },

    watch:
      {
        childFilter: function (newValue, oldValue) {
          if (this.arraysDiffer(newValue, oldValue) && this.aulaToken !== undefined) {
            this.validateConstraintsAndFetchStatus();
          }
        },
        institutionFilter: function (newValue, oldValue) {
          if (this.arraysDiffer(newValue, oldValue) && this.aulaToken !== undefined) {
            this.validateConstraintsAndFetchStatus();
          }
        },
        assuranceLevel(newValue, oldValue) {
          if (newValue !== oldValue && this.aulaToken !== undefined) {
            this.validateConstraintsAndFetchStatus();
          }
        },
        aulaToken: function () {
          if (this.aulaToken !== undefined) {
            this.validateConstraintsAndFetchStatus();
          }
        }
      },

    created: function () {
      this.moment.locale('da');
      this.aulaToken = this.getAulaToken();
    },

    updated: function () {
      this.getWidthsForSliding();
    }
  }
</script>

<style scoped>
  .cicero-frame {
    overflow: hidden;
    position: relative;
  }

  .cicero-contents {
    background-color: white;
    background-repeat: repeat-x;
    background-position: top -50px right 0;
    font-family: Lato, sans-serif;
    color: #222350;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  .cicero-tab-container {
    padding-top: 16px;
    margin-top: 0;
    display: flex;
    flex-direction: row;
    font-size: 14px;
    font-weight: bold;
    font-family: Lato, sans-serif;
    text-transform: uppercase;
    border-bottom: #222350 2px solid;
  }

  .cicero-tab {
    color: #45B7C1;
    padding-right: 16px;
    padding-left: 16px;
    padding-bottom: 10px;
    cursor: pointer;
  }

  .cicero-selected-tab {
    color: #222350;
    max-height: 15px;
  }

  .cicero-tab-triangle {
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid #222350;
    border-bottom: 0;
  }

  .cicero-footer {
    height: calc(264px - 56px - 160px + 2px);
    padding-left: 16px;
    padding-top: 16px;
    display: flex;
    flex-direction: column;
    font-family: Lato, sans-serif;
    font-weight: bold;
    font-size: 12px;
  }

  .cicero-footer img {
    height: 13px;
    margin-bottom: 6px;
  }

  .cicero-wedge-container {
    height: calc(288px - 264px);
    background-color: transparent;
    overflow: hidden;
  }

  .cicero-wedge {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 10px;
    height: 100%;
    width: calc(100% + 2px);
    background-color: white;
    transform: skewY(-3.5deg);
    transform-origin: bottom left;
  }

  .cicero-hover-container {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    width: 100%;
    height: 160px;
    background-color: black;
    opacity: 0;
    cursor: pointer;
    transition: opacity 1s ease;
  }

  .cicero-hover-container:hover {
    opacity: 0.16;
  }

  .cicero-detailed-view {
    position: absolute;
    display: flex;
    flex-direction: column;
    left: 0;
    z-index: 3;
    width: 100%;
    height: 160px;
    background-color: black;
    opacity: 0.87;
    cursor: pointer;
  }

  .cicero-detailed-view-header {
    box-sizing: content-box;
    height: 50%;
    padding: 12px;
    overflow: hidden;
  }

  .cicero-detailed-view-footer {
    padding-left: 12px;
  }

  .cicero-info-icon, .cicero-number-of-loans-icon {
    position: absolute;
    right: 2px;
    z-index: 1;
    height: 24px;
    width: 24px;
    margin: 4px;
    border-radius: 50%;
    text-align: center;
    color: white;
    font-size: 20px;
    line-height: 1.2;
    font-weight: bold;
    bottom: 4px;
  }

  .cicero-number-of-loans-icon {
    font-size: 12px;
    line-height: 2;
    font-family: Lato, sans-serif;
    font-weight: 500;
    background: #555555;
    bottom: 4px;
  }

  .cicero-real-cover-image {
    height: 160px;
    max-width: 100%;
    max-height: 100%;
  }

  .cicero-loan, .cicero-reservation {
    max-width: 100%;
    transform: rotate(0deg); /* To make this element the containing block of its children so that they can be absolutely positioned. See https://developer.mozilla.org/en-US/docs/Web/CSS/Containing_block */
  }

  .cicero-loans-layer-container {
    display: flex;
    align-items: center;
    flex-direction: row;
  }

  .cicero-loans-layer-1 {
    background-color: #767676;
    width: 4px;
    height: 152px;
  }

  .cicero-loans-layer-2 {
    background-color: #A5A5A5;
    width: 4px;
    height: 146px;
  }

  .cicero-real-cover-image img {
    height: 100%;
  }

  .cicero-error-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 148px;
  }

  .cicero-error-text {
    margin-top: 18px;
    padding-top: 8px;
    text-align: center;
    font-family: Lato, sans-serif;
    color: #222350;
    font-size: 16px;
  }

  .cicero-error-image {
    margin-top: 16px;
    height: 64px;
    width: 120px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
  }

  .cicero-default-cover-image {
    width: 120px;
    height: 160px;
    background-size: cover;
    background-position: center center;
  }

  .cicero-overlay {
    height: 100%;
    width: 100%;
    padding: 12px;
    overflow: hidden;
    box-sizing: border-box;
  }

  .cicero-text {
    color: white;
    word-wrap: break-word;
    text-align: left;
    line-height: 1;
    hyphens: auto;
  }

  .cicero-author {
    font-family: Lato sans-serif;
    font-weight: bold;
    padding-top: 5px;
    font-size: 12px;
  }

  .cicero-title {
    font-family: Lato, sans-serif;;
    font-weight: bold;
    padding-top: 5px;
    font-size: 14px;
  }

  .cicero-patron-name {
    font-family: Lato, sans-serif;;
    font-size: 12px;
    font-weight: 700;
    margin-left: 4px;
    margin-bottom: 2px;
  }

  .cicero-one-child-filler {
    height: 8px;
    width: 100%;
  }

  .cicero-details-header {
    font-size: 12px;
    opacity: 0.7;
  }

  .cicero-handin-text {
    font-size: 12px;
    font-family: Lato, sans-serif;
    font-weight: bold;
    opacity: 0.7;
  }

  .cicero-number-in-queue {
    margin-top: 16px;
    font-size: 12px;
    opacity: 0.7;
  }

  .cicero-date {
    font-size: 12px;
    font-family: Lato, sans-serif;
    font-weight: bold;
    opacity: 0.7;
  }

  .cicero-return-alert {
    margin-top: 2px;
    font-size: 12px;
    font-family: Lato, sans-serif;
    font-weight: bold;
  }

  .cicero-ready-for-pickup {
    color: #5BB05F;
    font-size: 12px;
    font-family: Lato, sans-serif;
    font-weight: 700;
  }

  .cicero-slides {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    min-width: 1px;
    margin: 4px 4px;
  }

  .cicero-next {
    right: 0;
    background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
    flex-direction: row-reverse;
  }

  .cicero-prev {
    left: 0;
    background: -webkit-linear-gradient(left, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
  }

  .cicero-arrow {
    position: absolute;
    height: 180px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 40px;
    top: 0;
    bottom: 0;
    z-index: 10;
    cursor: pointer;

    margin: 0;
    border: 0;
    outline: 0;
  }

  .cicero-slide {
    flex-shrink: 0;
    margin: 0 2px 0 2px;
  }

  .cicero-carousel {
    overflow: hidden;
  }

  .cicero-carousel-items {
    display: flex;
    transition: transform 0.5s ease-out;
    margin: 0 4px;
  }

  .cicero-long-term-loans-slide-button {
    background-color: #e6e6e6;
    height: 160px;
    display: flex;
    align-self: flex-end;
    margin-left: 2px;
    width: 58px;
    justify-content: center;
    background: -webkit-linear-gradient(left, rgba(230, 230, 230, 1), rgba(230, 230, 230, 0));
    cursor: pointer;
  }

  .cicero-long-term-loans-error-button {
    background-color: white;
    height: 30px;
    margin-top: 8px;
    border-radius: 66px 66px 66px 66px;
    box-shadow: 0 2px 3px #A5A5A5;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .cicero-long-term-error-button-text {
    font-size: 14px;
    color: #222350;
    font-family: Lato, sans-serif;
    font-weight: bold;
    padding: 0 10px;
  }

  .cicero-long-term-loans-slide-button-text {
    display: flex;
    align-self: center;
    justify-content: center;
    min-width: 160px;
    font-family: Lato, sans-serif;
    font-size: 14px;
    font-weight: bold;
    color: #222350;
    transform: rotate(-90deg);
  }

  .cicero-longterm-loan-info {
    position: absolute;
    left: 0;
    display: block;
    background-color: #222350;
    z-index: 1;
    height: 24px;
    width: 84%;
    margin-bottom: 4px;
    padding-left: 4px;
    color: white;
    font-size: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 2;
    font-family: Lato, sans-serif;
    font-weight: 700;
    bottom: 4px;
  }

  .cicero-loading-placeholder {
    width: 120px;
    height: 160px;
    background: #f6f7f8;
    background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
    background-repeat: no-repeat;
    background-size: 800px 300px;
    display: inline-block;
    position: relative;

    -webkit-animation-duration: 1s;
    -webkit-animation-fill-mode: forwards;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-name: placeholderShimmer;
    -webkit-animation-timing-function: linear;
  }

  @-webkit-keyframes placeholderShimmer {
    0% {
      background-position: -468px 0;
    }

    100% {
      background-position: 468px 0;
    }
  }
</style>