<template>
  <div class="aula-widget">
    <div v-show="loaded" class="widgetBox">
      <div class="id">
        {{ id }}
      </div>
      <div class="header">
        <AulaBadge v-if="badgeCount" class="mr-1">{{ badgeCount }}</AulaBadge>
        <div class="widget-name text-truncate">{{ widgetTitle }}</div>
        <div v-if="supplier" class="widget-supplier">{{ 'WIDGET_SUPPLIED_BY' | fromTextKey }} {{ supplier }}</div>
      </div>
      <div class="content">
        <div>
          <template v-if="isMobile">
            <form
              :id="id + 'SSOForm'"
              name="SSOForm"
              :class="'widgetSSOmobile' + id"
              :action="SSOaction"
              method="get"
              enctype="application/x-www-form-urlencoded"
            >
              <input type="hidden" name="sessionUUID" :value="sessionUUID" />
              <input type="hidden" name="isMobileApp" :value="isMobile" />
              <input :id="id + 'aulaToken'" type="hidden" name="aulaToken" />
              <input type="hidden" name="assuranceLevel" :value="assuranceLevel" />
              <input type="hidden" name="userProfile" :value="userProfile" />
              <input type="hidden" name="childFilter" :value="childFilter" />
              <input type="hidden" name="institutionFilter" :value="institutionFilter" />
              <input type="hidden" name="group" :value="group" />
              <input type="hidden" name="currentWeekNumber" :value="currentWeekNumber" />
              <span class="addons" />
            </form>
            <button type="button" class="quick-access-item" :title="widgetTitle" @click="submitForm()">
              <span v-if="icon" class="widget-icon"><img :src="icon" /></span>
              {{ widgetTitle }}
            </button>
          </template>
          <template v-else>
            <form
              :id="id + 'SSOForm'"
              name="SSOForm"
              :action="SSOaction"
              target="_blank"
              method="post"
              enctype="application/x-www-form-urlencoded"
            >
              <input type="hidden" name="sessionUUID" :value="sessionUUID" />
              <input type="hidden" name="isMobileApp" :value="isMobile" />
              <input :id="id + 'aulaToken'" type="hidden" name="aulaToken" />
              <input type="hidden" name="assuranceLevel" :value="assuranceLevel" />
              <input type="hidden" name="userProfile" :value="userProfile" />
              <input type="hidden" name="childFilter" :value="childFilter" />
              <input type="hidden" name="institutionFilter" :value="institutionFilter" />
              <input type="hidden" name="group" :value="group" />
              <input type="hidden" name="currentWeekNumber" :value="currentWeekNumber" />
            </form>
            <button type="button" class="quick-access-item" :title="widgetTitle" @click="submitForm()">
              <span v-if="icon" class="widget-icon"><img :src="icon" /></span>
              {{ widgetTitle }}
            </button>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import axios from 'axios';
import moment from 'moment-timezone';
import { types } from '../../store/types/types';
import { mapGetters } from 'vuex';
import { portal } from '../../../shared/assets/plugins/axios/axios';
import $ from 'jquery';
import AulaBadge from "../../../shared/components/AulaBadge";

export default {
  components: {AulaBadge},
  props: {
    placement: { type: String, default: '' },
    widgetTitle: { type: String, default: '' },
    widgetID: { default: '' },
    widgetVersion: { default: '' },
    widgetType: { type: String, default: '' },
    icon: { type: String, default: '' },
    SSOaction: { type: String, default: '' },
    cleanUp: { type: Boolean, default: false },
    supplier: { type: String, default: '' },
    isMobile: { type: Boolean, default: false },
    badgeCount: { type: Number, default: 0 },
  },
  data: function () {
    return {
      id: null,
      sessionUUID: null,
      userProfile: null,
      assuranceLevel: 2,
      childFilter: [],
      institutionFilter: [],
      group: null,
      currentWeekNumber: null,
      aulaToken: null,
      loaded: false,
    };
  },
  computed: {
    ...mapGetters({
      profile: types.GET_CURRENT_PROFILE,
      isSteppedUp: types.GET_GLOBAL_STEPPED_UP,
      currentUniLogin: types.GET_CURRENT_UNILOGIN,
      activeInstitutions: types.GET_ACTIVE_INSTITUTIONS,
      activeChildrenUniLogin: types.GET_ACTIVE_CHILDREN_UNILOGIN,
      activeWeek: types.GET_CURRENT_WEEK,
      getGroup: types.GET_ACTIVE_GROUP,
    }),
    axios() {
      const copy = axios.create();
      // TODO: Remove create method when all widget suppliers no longer requires this method.
      copy.create = axios.create;
      return copy;
    },
  },
  watch: {
    activeInstitutions() {
      this.setParams();
    },
    activeChildrenUniLogin() {
      this.setParams();
    },
    widgetID() {
      this.setParams();
    },
    activeWeek() {
      this.setParams();
    },
    isSteppedUp() {
      this.setParams();
    },
    getGroup() {
      this.setParams();
    },
  },
  mounted() {
    this.id = 'W' + this.widgetID + 'V' + this.widgetVersion;
    const widgetItemID = this.widgetID;
    if (
      sessionStorage.getItem('tokenTime' + widgetItemID) &&
      moment().isBefore(moment(sessionStorage.getItem('tokenTime' + widgetItemID), 'DD-MM-YYYY HH:mm:ss'))
    ) {
      Vue.nextTick(() => {
        this.aulaToken = sessionStorage.getItem('token' + widgetItemID);
        this.setParams();
      });
    } else {
      portal.get('/?method=aulaToken.getAulaToken&widgetId=' + widgetItemID).then(response => {
        sessionStorage.setItem('token' + widgetItemID, response.data.data);
        sessionStorage.setItem('tokenTime' + widgetItemID, moment().add(4, 'm').format('DD-MM-YYYY HH:mm:ss'));
        Vue.nextTick(() => {
          this.aulaToken = response.data.data;
          this.setParams();
        });
      });
    }
  },
  methods: {
    submitForm() {
      // Set aulaToken dynamically to avoid security breach
      document.getElementById(this.id + 'aulaToken').value = this.aulaToken;
      document.getElementById(this.id + 'SSOForm').submit();
      document.getElementById(this.id + 'aulaToken').value = '';
    },
    setParams() {
      this.userProfile = this.profile.role;
      this.sessionUUID = this.currentUniLogin;
      this.assuranceLevel = this.isSteppedUp ? 3 : 2;
      this.currentWeekNumber = this.activeWeek;
      this.childFilter = this.activeChildrenUniLogin.filter((v, i, a) => a.indexOf(v) === i);
      this.institutionFilter = this.activeInstitutions.filter((v, i, a) => a.indexOf(v) === i);
      this.group = this.getGroup.uniGroupId;
      if (this.SSOaction.split('?').length > 0) {
        $('.widgetSSOmobile' + this.id + ' .addons').html('');
        const queryString = this.SSOaction.split('?')[1];
        if (queryString && queryString.length > 0) {
          const queryStringItem = queryString.split('&');
          for (let i = 0; i < queryStringItem.length; i++) {
            if (queryStringItem[i].split('=').length > 0) {
              $('.widgetSSOmobile' + this.id + ' .addons').append(
                '<input type="hidden" name="' +
                  queryStringItem[i].split('=')[0] +
                  '" value="' +
                  queryStringItem[i].split('=')[1] +
                  '" />'
              );
            }
          }
        }
      }
      this.loaded = true;
    },
  }
};
</script>

<style scoped lang="scss">
@import '../../../shared/assets/scss/core/variables.scss';
.aula-widget {
  .quick-access-item {
    margin-top: 0 !important;
    height: 70px;
    line-height: 70px;
    padding: 0 25px;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    font-size: 15px;
    background: #ffffff;
    form {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .widget-icon img {
    margin-left: -15px;
    max-width: 50px;
    max-height: 50px;
    margin-right: 5px;
  }
  .widgetBox {
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      white-space: nowrap;
      padding-bottom: 0.25rem;
      color: var(--color-primary-light);
      .widget-name {
        font-size: 1rem;
        font-weight: bold;
        flex-grow: 1;
        color: var(--color-primary-darker);
      }
      .widget-supplier {
        font-size: 0.7rem;
        color: var(--color-primary-darker);
      }
    }
    .id {
      display: none;
    }
    .content {
      border: solid 1px $color-primary-light-guardian;
      .theme-employee & {
        border-color: $color-primary-light-employee;
      }
      padding: 5px;
    }
  }
  button {
    border: 0;
    text-align: left;
  }
}
</style>
