export function convertWeekdayMask(weekdayMask) {
  const dayNames = ['søndag', 'mandag', 'tirsdag', 'onsdag', 'torsdag', 'fredag', 'lørdag'];
  let counter = 0;
  const weekdayNames = [];
  for (const mask of weekdayMask) {
    if (mask) {
      weekdayNames.push(dayNames[counter]);
    }
    counter++;
  }
  return weekdayNames.join(', ');
}
