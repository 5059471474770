<template>
  <div class="c-viborg-link-list" :class="`c-viborg-link-list--${placement}`">
    <div
      v-if="heading"
      class="c-viborg-link-list__main-heading"
      v-text="heading"
    ></div>
    <div v-if="isLoading" key="loading" class="c-viborg-link-list__link">
      Indlæser...
    </div>
    <template v-else-if="items.length">
      <a
        v-for="(item, index) in items"
        :key="index"
        class="c-viborg-link-list__link"
        :href="item.url"
        target="_blank"
      >
        <span
          v-if="item.title"
          class="c-viborg-link-list__heading"
          v-text="item.title"
        ></span>

        <p
          v-if="item.teaser"
          class="c-viborg-link-list__teaser"
          v-text="item.teaser"
        ></p>

        <div class="c-viborg-link-list__link-arrow">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 34 34"
            svg-inline=""
            aria-hidden="true"
            role="presentation"
            focusable="false"
            tabindex="-1"
          >
            <path
              d="M17.007.007c9.4 0 17 7.6 17 17s-7.6 17-17 17-17-7.6-17-17 7.6-17 17-17z"
              fill="#afe0ce"
              class="circle"
            ></path>
            <path
              d="M20.088 22.371c-.3 0-.5-.1-.7-.3-.4-.4-.4-1 0-1.4l3.4-3.4-3.4-3.4c-.4-.4-.4-1 0-1.4s1-.4 1.4 0l4.2 4.1c.2.2.3.4.3.7 0 .3-.1.5-.3.7l-4.1 4.1c-.3.2-.5.3-.8.3z"
              class="st1"
            ></path>
            <path
              d="M22.988 18.271h-13c-.6 0-1-.4-1-1s.4-1 1-1h13c.6 0 1 .4 1 1s-.4 1-1 1z"
              class="st1"
            ></path>
          </svg>

          Læs mere
        </div>
      </a>
    </template>
    <p v-else>Der er ingen links tilgængelige</p>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: {
    axios: Function,
    sessionUUID: String,
    placement: String,
    currentWeekNumber: String,
    isMobileApp: Boolean,
    getAulaToken: Function,
    assuranceLevel: Number,
    institutionCode: String,
    userProfile: String,
    childFilter: Array,
    institutionFilter: Array,
    group: String,
    triggerStepUp: Function,
    notifications: Array,
    deleteNotifications: Function,
    setIframeHeight: Function,
    getProps: Function,
  },

  data: function () {
    return {
      heading: null,
      isLoading: true,
      items: [],
    };
  },

  created: async function () {
    const response = await axios(
      "https://vivi.dk/api/AulaWidget/GetAulaLinks"
    ).catch(() => {});
    const { data } = response || {};
    const { heading, items = [] } = data || {};

    this.isLoading = false;
    this.heading = heading;
    this.items = (items.length ? items : []).filter((item) => item?.url);
  },
};
</script>

<style scoped>
.c-viborg-link-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  row-gap: 10px;
}
.c-viborg-link-list__main-heading {
  grid-column: 1/-1;
  text-transform: uppercase;
  font-size: 18px;
  margin-bottom: 10px;
}
.c-viborg-link-list__link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  font-size: 15px;
  color: white;
  background-color: #004536;
  padding: 18px 25px;
  width: 100%;
}
.c-viborg-link-list__link:hover {
  color: white;
}

.c-viborg-link-list__heading {
  text-transform: uppercase;
}
.c-viborg-link-list__teaser {
  font-weight: lighter;
}

.c-viborg-link-list__link-arrow {
  display: flex;
  column-gap: 16px;
  align-items: center;
  margin-top: auto;
  transition: translate 0.2s cubic-bezier(0.47, 0, 0.745, 0.715);
}
.c-viborg-link-list__heading + .c-viborg-link-list__link-arrow {
  margin-top: 10px;
}
.c-viborg-link-list__link-arrow svg {
  width: 32px;
  height: 32px;
}

.c-viborg-link-list__link:hover[href] .c-viborg-link-list__link-arrow,
.c-viborg-link-list__link:focus[href] .c-viborg-link-list__link-arrow {
  translate: 16px;
  cursor: pointer;
}

@media screen and (min-width: 1200px) {
  .c-viborg-link-list.c-viborg-link-list--full {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 10px;
  }
}
</style>
