import { CalendarEventModel } from '../../../shared/models/calendarEvent.model';
import { ResourceModel } from '../../../shared/models/resource.model';
import { InstitutionProfileModel } from '../../../shared/models/institutionProfile.model';
import { NotificationModel } from '../../../shared/models/notification.model';
import { EventRenderer } from './eventRenderer';
import Vue from 'vue';
import { htmlUtil } from '../../../shared/utils/htmlUtil';

export class MonthEventRenderer extends EventRenderer {
  constructor(
    { event, el }: { event: CalendarEventModel; el: HTMLElement },
    resources: ResourceModel[],
    notifications: NotificationModel[],
    profile: InstitutionProfileModel,
    delegatedContext: InstitutionProfileModel,
    noOfResources: number
  ) {
    super(
      {
        event,
        el,
      },
      resources,
      notifications,
      profile,
      delegatedContext,
      noOfResources
    );
  }

  override render(): string {
    const titleHtml = htmlUtil.createSimpleDiv(
      null,
      'month-view-title-event',
      Vue.filter('fromTextKey')(this.titleHtmlStripped.toUpperCase())
    );
    const eventCounter = htmlUtil.createSimpleDiv(null, 'month-view-count-event', this.extendedProps.count.toString());

    return htmlUtil.createDiv(null, 'month-view-event-group', [titleHtml, eventCounter]);
  }
}
