<template>
  <div
    :key="section"
    class="main-section">
    <section v-if="loading">
      <div class="infoba-header">
        <div style="display:flex">
          <i class="fal fa-home" />
          <span
            class="header-link"
            @click="goto(headerLink)">INFOBA</span>
        </div>
        <img
          :src="domain + '/Images/logowhite.svg'"
          class="infoba-logo">
      </div>
      <div
        class="infoba-quick-access-item "
        style="text-align:center; padding:20px !important">
        <div class="icon-rotator infoba-rotate" />
      </div>
    </section>
    <section v-else-if="section!='default' ">
      <div class="infoba-header">
        <div style="display:flex">
          <i class="fal fa-home" />
          <span
            class="header-link"
            @click="goto(headerLink)">INFOBA</span>
        </div>
        <img
          :src="domain + '/Images/logowhite.svg'"
          class="infoba-logo">
      </div>
      <div class="infoba-quick-access-item">
        <widget-html :html="message" />
      </div>
    </section>
    <section v-else>
      <div
        style="background: #425d82;"
        class="infoba-header">
        <div style="display:flex">
          <div class="icon-calendar-account" />
          <div
            class="header-link"
            @click="goto(domain + '/IN/PPlan')">
            INFOBA Vagtplan
          </div>
        </div>
        <img
          :src="domain + '/Images/logowhite.svg'"
          class="infoba-logo">
      </div>
      <div class="wrapper">
        <template v-for="institution in sortedTimeSlots">
          <div class="institution">
            <widget-html
              :html="institution.institutionName"
              class="institution-name infoba-gray" />
            <template v-for="group in institution.groups">
              <div class="group">
                <widget-html
                  :html="group.groupName"
                  class="group-name" />
                <template v-for="timeslot in group.timeslots">
                  <div
                    class="timeslot"
                    @click="goto(domain + '/IN/PPlan')">
                    <div
                      class="timeslot-content"
                      :style="{ borderLeftColor: timeslot.color}">
                      <div class="timeslot-time infoba-gray">
                        {{ timeslot.time }}
                      </div>
                      <widget-html
                        :html="timeslot.name"
                        class="timeslot-name" />
                    </div>
                  </div>
                </template>
              </div>
            </template>
          </div>
        </template>
      </div>
    </section>
  </div>
</template>
<script>
var domain ='https://www2.infoba.dk';
var domainOld = 'https://www.infoba.dk';

export default {
  props: {
    /* normal and secure */
    axios: Function,
    sessionUUID: String,
    placement: String,
    currentWeekNumber: String,
    isMobileApp: Boolean,
    userProfile: String,
    /* only secure */
    getAulaToken: Function,
    assuranceLevel: Number,
    moment: Function,
    childFilter: Array,
    institutionFilter: Array,
    group: String,
    userProfile: String,
    institutionCode: String
  },
  data: function () {
    return {
      loading: true,
      section: "default",
      message: "",
      timer: '',
      headerLink: domain,
      who: 'Aula widget developers',
      date: this.moment().format('DD-MM-YYYY'),
      aulaToken: null,
      domain: domain,
      domainOld: domainOld,
      fields: {},
      item: { Binder: { Tile:{ }} },
      timeSlots: Array,
      sortedTimeSlots: Array
    }
  },
  computed: {

  },
  methods: {
    getData: function () {
      var uuid = this.sessionUUID;
      this.axios.defaults.headers.common["Authorization"] = this.aulaToken;
      this.axios.defaults.headers.common["AuthorizationContext"] = "PPlan";

      if (this.childFilter != null)
        this.axios.defaults.headers.common["childFilter"] = this.childFilter.join();
      if (this.institutionFilter != null)
        this.axios.defaults.headers.common["institutionFilter"] = this.institutionFilter.join();
      if (this.group != null)
        this.axios.defaults.headers.common["groupFilter"] = this.group;
      if (this.userProfile != null)
        this.axios.defaults.headers.common["userProfile"] = this.userProfile;
      if (this.institutionCode != null)
        this.axios.defaults.headers.common["institutionCode"] = this.institutionCode;
      if (this.userProfile != null)
        this.axios.defaults.headers.common["userProfile"] = this.userProfile;
      if (this.currentWeekNumber != null)
        this.axios.defaults.headers.common["currentWeekNumber"] = this.currentWeekNumber;
      if (this.isMobileApp != null)
        this.axios.defaults.headers.common["isMobileApp"] = this.isMobileApp;
      this.tileType = 130
      this.axios.get(domain + "/api/Aula/INTileGet/" + this.tileType, {
        withCredentials: true,
        credentials: "same-origin",
      })
        .then((response) => {
          this.moment.locale('da');
          this.item = response.data;
          handleStatus(this);
          if (this.item.Binder.Tile.TimeSlotList.length == 0) {
            this.section = "nodata";
            this.message = "Vi har ikke noget relevant data at vise dig.";
          }
          this.timeSlots = this.item.Binder.Tile.TimeSlotList;

          this.sortedTimeSlots = [];

          for (let i = 0; i < this.timeSlots.length; i++) {
            if (this.sortedTimeSlots.length > 0 && this.sortedTimeSlots[this.sortedTimeSlots.length - 1].institutionName == this.timeSlots[i].InstitutionName) {
              //if institution already exists
              const groups = this.sortedTimeSlots[this.sortedTimeSlots.length - 1].groups;
              if (groups[groups.length - 1].groupName == this.timeSlots[i].GroupName) {
                //if the group already exists
                groups[groups.length - 1].timeslots.push(addNewTimeslot(this.timeSlots[i]));
              }
              else {
                //if group doesn't already exist
                groups.push(addNewGroup(this.timeSlots[i]));
              }
            }
            else {
              // add the very first timeslot, if it doesn't already exist
              if (!this.sortedTimeSlots) this.sortedTimeSlots = [];
              //if institution doesn't already exist
              this.sortedTimeSlots.push(addNewInstitution(this.timeSlots[i]));
            }
          }

        })
        .catch((error) => {
          handleStatus(this);
        })
        .finally(() => (this.loading = false));
    },
    goto: function (redirectUrl) {
      let serial = this.item.INFOBAserial;
      let token = this.item.INFOBAtoken;
      let url = "";
      //if logged in
      if (serial === null || token === null) {
        let aulaReferrer = '?referrer=Aula';
        if (redirectUrl.indexOf('?') !== -1) {
          aulaReferrer = '&referrer=Aula';
        }
        url = redirectUrl + aulaReferrer;
      }
      //if not logged in
      else {
        url = domain + "/Login/Index?serial=" + serial + "&token=" + token + "&referrer=Aula&returnUrl=" + encodeURIComponent(redirectUrl);
      }
      var a = document.createElement('a');
      a.href = url;
      a.target = "INFOBA";
      a.click();
    },
  },
  created: function () {
    this.aulaToken = this.getAulaToken();
  },
  watch: {
    aulaToken: function () {
      if (this.aulaToken !== undefined) {
        var t = this;
        t.getData();
      }
    },
  },
}

function addNewInstitution(timeSlot) {
  return {
    institutionName: timeSlot.InstitutionName,
    groups: [addNewGroup(timeSlot)]
  }
}

function addNewGroup(timeSlot) {
  return {
    groupName: timeSlot.GroupName,
    timeslots: [addNewTimeslot(timeSlot)]
  }
}

function addNewTimeslot(timeSlot) {
  return {
    name: timeSlot.Name,
    time: `${timeSlot.StartTime.slice(0, -3)} - ${timeSlot.EndTime.slice(0, -3)}`,
    color: timeSlot.Color
  }
}

function handleStatus(t) {
  clearTimeout(t.timer)
  if (t.item && t.item.Status == 200) {
    if (t.item.Binder.Tile == undefined) {
      t.section = "error"
      t.message = 'Din bruger har ikke adgang til denne widget i INFOBA. Kontakt <a href="https://web.infoba.dk/support/" class="infoba-link">support</a>.'
    }
    else {
      t.section = "default"
    }
  }
  else if (t.item && t.item.Status == 401) {
    if (t.item.Message == "Invalid role" || t.item.Message == "Module payment missing") {
      t.section = "error"
      t.message = 'Din bruger har ikke adgang til denne widget i INFOBA. Kontakt <a href="https://web.infoba.dk/support/" class="infoba-link">support</a>.'
    }
    else {
      t.section = "login";
      t.message = 'For at se data fra INFOBA, skal dine konti f&oslash;rst sammenk&aeligdes. <div><a href="' + domain + "/IN/Personel/MatchAula/" + t.item.Username + '" class="infoba-link">Start sammenk&aeligdning <i class="material-icons icon-arrowright-circle" style="vertical-align: middle; margin-top: 2px; color:#96be2c"></i></a></div>';
      t.timer = setTimeout(function () { t.getData() }.bind(t), 5000);
      t.headerLink = domain + "/IN/Personel/MatchAula/" + t.item.Username;
    }
  }
  else {
    t.section = "error"
    t.message = 'Undskyld. Det er i &oslash;jeblikket ikke muligt at vise dig den &oslash;nskede information. Vi h&aring;ber, du vil pr&oslash;ve igen senere.'
  }
}

</script>
<style scoped>
.main-section {
  border: 2px solid white;
  background-color: #FAFAFA;
}

div.icon-calendar-account {
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QAAAAAAAD5Q7t/AAAACXBIWXMAAA7EAAAOxAGVKw4bAAAA+0lEQVRIx92UMW4CMRRE528gHXVQBBwgOUnEDRBFRCTukAPlNohLLBRElGmgeTQWclZe/IMDQoy0hXc9f/6M11+6WxBQuq+6dKO/BIAxsIo7IoPGvhp4i2taQ6CWNChsujazUZtANnMPzOxY97pncAl0chb/glTEbgfAFFgDG+D9LDttlwZ4AnbR37kH+h6+18FQ0mO07kp6/k8HHWAZOVgADx5+8h6kDhnoSZqE5ZeZ/aQEmnxXREBX0ouk7/C8hndlEQEV8AlsE2NoG75VbXyPwEdu2AGzUwK5iIYO4yPHHqfFM/jNUbGSNCicqvWpiOZBpKT4vIB/gzgAmQ1GdMzg1uAAAAAldEVYdGRhdGU6Y3JlYXRlADIwMjAtMTItMDdUMDc6MTY6MDUrMDA6MDADbSVIAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDIwLTEyLTA3VDA3OjE2OjA1KzAwOjAwcjCd9AAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAAASUVORK5CYII=') no-repeat center center;
  background-size: 20px;
  padding: 35px 0 0 35px;
  display: inline-block;
}
div.icon-rotator {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QAAAAAAAD5Q7t/AAAACXBIWXMAAA7EAAAOxAGVKw4bAAAD5UlEQVRo3u2ZT4gbdRTHv++XJUzwT6HuoUrB0nhQKR6kerG9eCgL1l3FdT1ZpLB4kL1Edmf29rtlJpsuSPEgQvdiD6ZQdS1ioTf/gFUQ24KSS3qQIloKVXGSyWa+Hjq7JNNkMzOZcT3M9zZv5v3m98n7vfd+8wuQK1euXLlyJZekPaDW+mHDMI6TPCIijwPYB2ALwB2SvwH4rtPp/KC1/vt/B6K1njIMYx7AaQAvAiiMcekC+ALARrvd/lxr7e85iOM48yQdAIeT+JO8JiIrlmVdnmQeU0kd19fX93ued47k3CQTEJEnRaQ0yRhAwohUq9UnROQyRkdhC8BVki0R+V1EPJLTAJ4C8CwAI3jOE5E3TNP89D8HCSC+AnBgyO2fAKwXi8VLlUrlzjB/rfWDhmHMAlgSEScNiNggwXL6HvdH4raIVFzXPZ9G4iZRrBzxPO9cGEJEbiilZpeXl1t7AbAtFfVBx3HmAQwktojccF33hawgHMc5mCqI1noqKLH9uq2UmtVa/5kFRLVanSHZtG37ndRAgmYXXlKVrCJRrVZnROQTACUAZ6PARF1ap/svSF5zXfd8RhAnAojtEi0BzNJEILZt78O9bceORORMFtUpiMRnfRA7rwTw3m6RiRKRYxjcO20Vi8VLGUH0RyKs7cgMhRkLQvJIyHR1VLPLEGIszFgQpdShkOnmHkHsCjMWxPf9h0KmWylCnIgJEYZZ6jcAAGzbXgTwAZJtJD2l1GsrKyup5I5t22cAVPpMH1mW9eZuPjsRsSzrQwBLABjzvW2Sc2lBBHqs/4Lk2KY7sLQsy3o/Jkyb5Kurq6tfpggBAIdC1zdjgcSEyQSiXq9PA3guZL4eGyQiTFaRQK/XexmDfaurlPomEUgfzNtDYDyl1OtZQDQajQIGkxwArpim+VdikAAmXACySOwdtVqtU+EGTHIjiu/YPtK3zNyslhMA1Gq1Msl6yNwsl8sXo/hH7hmO4xw0TfPXLCCCjem3AJ4emJzInGmam6mCZKVarVb2fX9zCMQF0zQXoo6T+FxrUjUajUKr1Trl+34dwP7Q7SbJxTjjpRYRx3FeIWmJyFkAm6MqTb1en+52uyeVUu8O2VkDwK1CoXAs7tdnKiABxMcAioGpLSI/kvxZRP4gWRSRAyQPAziK0WfDzUKhMJPkEzqNs98wRCKJyAWSi5Zl3U3iP3GO+L7/D4BfROSZhEM0RWQ5anUa+UNMCgIAWmtVKpVOknwLwEsYH50ugCskN8rl8sWFhYXepHNIvfyura09QPJor9d7XkQeBfAIyQKAu7i3i73e6XS+TvuPnly5cuXKlWsS/QvhKq1sLYByvgAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAyMC0xMC0wNlQxMTozNjo1NSswMDowMPTXOyYAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMjAtMTAtMDZUMTE6MzY6NTUrMDA6MDCFioOaAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAABJRU5ErkJggg==) no-repeat center center;
  padding: 25px 25px 25px 25px;
  display: inline-block;
}
.header-link {
  cursor: pointer;
  margin-left: 5px;
  line-height: 35px;
}

.infoba-header {
  color: #fff;
  padding: 4px;
  padding-left: 8px;
  padding-right: 10px;
  display: flex;
  justify-content: space-between;
  align-content: center;
  background: #888;
  position: relative;
}

.infoba-header:first-child {
  margin-top: 0
}

.infoba-header i {
  position: absolute;
  top: 2px;
  line-height: 35px;
  font-size: 24px !important
}

.infoba-header span {
  position: absolute;
  left: 34px;
  line-height: 35px;
  color: #fff;
  cursor: pointer;
  top: 5px
}

.infoba-header span:hover {
  color: #fff;
  text-decoration: none
}

.quick-access-item {
  position: relative
}

.infoba-quick-access-item {
  background-color: #fff;
  padding: 20px 35px 20px 35px;
  cursor: auto;
  height: auto
}

.infoba-gray {
  color: #888
}

.infoba-link {
  color: #888 !important;
  cursor: pointer
}

.slide-enter-active {
  -moz-transition-duration: .3s;
  -webkit-transition-duration: .3s;
  -o-transition-duration: .3s;
  transition-duration: .3s;
  -moz-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in
}

.slide-leave-active {
  -moz-transition-duration: .3s;
  -webkit-transition-duration: .3s;
  -o-transition-duration: .3s;
  transition-duration: .3s;
  -moz-transition-timing-function: cubic-bezier(0,1,.5,1);
  -webkit-transition-timing-function: cubic-bezier(0,1,.5,1);
  -o-transition-timing-function: cubic-bezier(0,1,.5,1);
  transition-timing-function: cubic-bezier(0,1,.5,1)
}

.slide-enter-to, .slide-leave {
  max-height: 100px;
  overflow: hidden
}

.slide-enter, .slide-leave-to {
  overflow: hidden;
  max-height: 0
}

.rotate {
  -moz-transition: all .3s linear;
  -webkit-transition: all .3s linear;
  transition: all .3s linear
}


.infoba-rotate {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite
}

@-webkit-keyframes rotating {
  from {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg)
  }

  to {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg)
  }
}

@keyframes rotating {
  from {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg)
  }

  to {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg)
  }
}

.infoba-quick-access-item ul {
  list-style: disc;
  margin: 0
}

.infoba-quick-access-item li {
  margin-left: 16px
}

.infoba-logo {
  height: 35px
}

.wrapper {
  padding: 0 20px;
}

.institution {
  margin-top: 30px;
}

.institution-name {
  font-size: 12px;
}

.group-name {
  font-weight: bolder;
}

.timeslot {
  background-color: white;
  padding: 10px;
  margin: 10px 0;
  cursor: pointer;
}

.timeslot-content {
  padding: 2px 0px 2px 10px;
  border-left-width: 4px;
  border-left-style: solid;
}

.timeslot-time {
  font-size: 12px;
}
</style>
