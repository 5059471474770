<template>
  <div v-if="invitees.length > 0" class="d-flex text-left flex-column mb-2">
    <div class="d-flex">
      <div v-button class="response-collapse-button pointer" @click="onSubGroupClicked">
        <slot></slot>
        <i
          :class="{
            open: isSubGroupOpen,
          }"
          class="icon icon-Aula_down-arrow mx-1"
        />
      </div>
      <CalendarEventSendReminder v-if="showSendReminder" class="ml-auto" />
    </div>
    <transition name="toggle">
      <div v-if="isSubGroupOpen" class="invitee-response">
        <div
          v-for="(invitee, index) in invitees"
          :key="index"
          :class="showParticipantInformation ? 'information' : 'name'"
          class="participant"
        >
          <template v-if="!showParticipantInformation">{{ invitee.name }}</template>
          <template v-else>
            <div class="participant-name">{{ invitee.name }}</div>
            <div class="number-of-participants">
              {{ invitee.numberOfAdultParticipants }}
              {{ (invitee.numberOfAdultParticipants === 1 ? 'ADULT' : 'ADULTS') | fromTextKey }}
            </div>
            <div class="number-of-participants">
              {{ invitee.numberOfChildParticipants }}
              {{ (invitee.numberOfChildParticipants === 1 ? 'SMALL_CHILD' : 'SMALL_CHILDREN') | fromTextKey }}
            </div>
          </template>
        </div>
        <div v-if="showParticipantInformation" class="participant information font-weight-bold">
          <div class="participant-name text-capitalize">{{ 'TOTAL' | fromTextKey }}</div>
          <div class="number-of-participants">
            {{ totalAdults }}
            {{ (totalAdults === 1 ? 'ADULT' : 'ADULTS') | fromTextKey }}
          </div>
          <div class="number-of-participants">
            {{ totalChildren }}
            {{ (totalChildren === 1 ? 'SMALL_CHILD' : 'SMALL_CHILDREN') | fromTextKey }}
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import button from '../../../shared/directives/button';
import CalendarEventSendReminder from '../../../shared/PageFragments/CalendarEvent/CalendarEventSendReminder.vue';
export default {
  name: 'CalendarEventResponseTypeInvitee',
  components: { CalendarEventSendReminder },
  directives: {
    button,
  },
  props: {
    invitees: {
      type: Array,
      required: true,
    },
    showParticipantInformation: { type: Boolean, default: false },
    showSendReminder: { type: Boolean, default: false },
  },
  data: function () {
    return {
      isSubGroupOpen: false,
    };
  },
  computed: {
    totalAdults() {
      return this.invitees.reduce((sum, invitee) => sum + invitee.numberOfAdultParticipants, 0);
    },
    totalChildren() {
      return this.invitees.reduce((sum, invitee) => sum + invitee.numberOfChildParticipants, 0);
    },
  },
  methods: {
    onSubGroupClicked() {
      this.isSubGroupOpen = !this.isSubGroupOpen;
    },
  },
};
</script>

<style scoped lang="scss">
.response-collapse-button {
  display: flex;
  align-items: center;
  width: fit-content;
}
.invitee-response {
  margin-left: 20px;
}
i.icon-Aula_down-arrow {
  font-size: 6px;
  display: inline-block;
  transform: rotate(0);
  transition: transform 200ms linear;

  &.open {
    transform: rotate(180deg);
  }
}
.number-of-participants {
  width: 100px;
  text-align: center;
}
.participant {
  display: flex;
  margin-right: 20px;
  &.information {
    border-bottom: 1px solid var(--color-grey-dark);
    padding: 10px 0;
    .participant-name {
      flex: 1;
    }
  }
  &.name {
    padding: 3px 0;
  }
}
</style>
