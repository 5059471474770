<style scoped>
.button-bar {
  display: flex;
  margin-bottom: 10px;
  margin-top: 5px;
  border-radius: 4px;
  border: 1px solid #222350;
}

/* normal button in the button bar */
.button-bar div {
  flex: 1;
  text-align: center;
  border-right: 1px solid #222350;
  color: #222350;
  font-size: 14px;
  padding: 4px;
}

.button-bar div:last-child {
  border-right: 0;
}

.button-bar div:hover {
  cursor: pointer;
}

.button-bar div.active {
  color: white;
  background-color: #222350;
}
.el-date-editor--timerange.el-input__inner {
  width: 300px;
}

.history-item {
  border-bottom: 1px solid #222350;
  padding-bottom: 4px;
  margin-bottom: 4px;
}

.link-button {
  color: #222350;
  cursor: pointer;
  padding-top: 4px;
  padding-bottom: 4px;
  text-decoration: underline;
}
</style>

<template>
  <div style="display: inline-block; width: 100%">
    <b-alert v-model="userNotLinked" variant="danger"
    >Din AULA-bruger er ikke knyttet sammen med nogen Kalenda-bruger - kontakt
      din leder eller ring til Greenbyte på 70 27 70 47.</b-alert
    >

    <div style="font-size: 18px">
      KALENDA
      <b-form-select
        v-if="showLoginList"
        v-model="selectedLogin"
        @change="setUserLoginDetails()"
      >
        <option
          v-for="login in loginList"
          :key="login.value.userId"
          :value="login.value"
        >
          {{ login.text }}
        </option>
      </b-form-select>
    </div>

    <div class="button-bar">
      <div
        v-bind:class="{ active: state == states.absence }"
        v-on:click="switchTab(states.absence)"
      >
        Fravær
      </div>
      <div
        v-bind:class="{ active: state == states.flex }"
        v-on:click="switchTab(states.flex)"
      >
        Flex
      </div>
      <div
        v-bind:class="{ active: state == states.oetid }"
        v-on:click="switchTab(states.oetid)"
      >
        Ø-tid
      </div>
      <div
        v-bind:class="{ active: state == states.vacation }"
        v-on:click="switchTab(states.vacation)"
      >
        Ferie
      </div>
      <div
        v-bind:class="{ active: state == states.history }"
        v-on:click="
          switchTab(states.history);
          showHistory();
        "
      >
        Historik
      </div>
    </div>

    <div v-if="state == states.absence">
      <b-form-group label="Dato">
        <el-date-picker
          v-bind:clearable="false"
          v-model="absenceDate"
        ></el-date-picker>
      </b-form-group>

      <b-form-group label="Årsag">
        <b-form-select
          v-model="selectedAbsenceCause"
          :options="absenceCauses"
        ></b-form-select>
      </b-form-group>

      <b-form-group label="Beskrivelse">
        <b-form-input type="text" v-model="absenceDescription"></b-form-input>
      </b-form-group>
      <b-form-group
        label="Timer"
        description="0 timer betyder hele dagen"
        v-if="absenceManualHours == 'false'"
      >
        <b-form-input type="number" v-model="absenceHours"></b-form-input>
      </b-form-group>

      <b-form-group label="Fra - til" v-if="absenceManualHours == 'true'">
        <el-time-picker
          is-range
          v-model="absenceTimePicker"
          start-placeholder="Fra"
          end-placeholder="Til"
          :picker-options="{
            step: '00:15',
          }"
        ></el-time-picker>
      </b-form-group>

      <b-form-checkbox
        v-model="absenceManualHours"
        value="true"
        unchecked-value="false"
      >Vis fra og til</b-form-checkbox
      >

      <b-form-checkbox
        v-model="absencePeriodeCheckbox"
        value="true"
        unchecked-value="false"
      >Fravær over periode</b-form-checkbox
      >

      <b-form-group label="Slutdato" v-if="absencePeriodeCheckbox == 'true'">
        <el-date-picker
          v-bind:clearable="false"
          v-model="absenceEndDate"
        ></el-date-picker>
      </b-form-group>
    </div>

    <div v-if="state == states.flex">
      <label>
        Flexsaldo:
        <b>{{ flexSaldo }}</b>
      </label>

      <b-form-group label="Dato">
        <el-date-picker
          v-bind:clearable="false"
          id="flexDate"
          v-model="flexDate"
        ></el-date-picker>
      </b-form-group>

      <b-form-group>
        <b-form-radio value="less" v-model="flexType"
        >Afspadsering</b-form-radio
        >
        <b-form-radio value="more" v-model="flexType">Merarbejde</b-form-radio>
      </b-form-group>

      <b-form-group label="Timer" v-if="flexManualHours == 'false'">
        <b-form-input
          v-model="flexHours"
          type="number"
          placeholder="Timer"
        ></b-form-input>
      </b-form-group>

      <b-form-group label="Fra - til" v-if="flexManualHours == 'true'">
        <el-time-picker
          is-range
          v-model="flexTimePicker"
          start-placeholder="Fra"
          end-placeholder="Til"
          :picker-options="{
            step: '00:15',
          }"
        ></el-time-picker>
      </b-form-group>

      <b-form-checkbox
        v-model="flexManualHours"
        value="true"
        unchecked-value="false"
      >Vis fra og til</b-form-checkbox
      >

      <b-form-group label="Evt. beskrivelse">
        <b-form-input
          v-model="flexDescription"
          placeholder="Evt. beskrivelse"
        ></b-form-input>
      </b-form-group>
    </div>

    <div v-if="state == states.oetid">
      <b-form-group label="Dato">
        <el-date-picker
          v-bind:clearable="false"
          id="oetidDate"
          v-model="oetidDate"
        ></el-date-picker>
      </b-form-group>

      <b-form-group label="Timer" v-if="oetidManualHours == 'false'">
        <b-form-input
          v-model="oetidHours"
          type="number"
          placeholder="Timer"
        ></b-form-input>
      </b-form-group>

      <b-form-group label="Fra - til" v-if="oetidManualHours == 'true'">
        <el-time-picker
          is-range
          v-model="oetidTimePicker"
          start-placeholder="Fra"
          end-placeholder="Til"
          :picker-options="{
            step: '00:15',
          }"
        ></el-time-picker>
      </b-form-group>

      <b-form-checkbox
        v-model="oetidManualHours"
        value="true"
        unchecked-value="false"
      >Vis fra og til</b-form-checkbox
      >

      <b-form-group label="Ø-tid type">
        <b-form-select
          v-model="selectedOetidType"
          :options="oetidTypes"
        ></b-form-select>
      </b-form-group>

      <b-form-group label="Evt. beskrivelse">
        <b-form-input
          v-model="oetidDescription"
          placeholder="Evt. beskrivelse"
        ></b-form-input>
      </b-form-group>
    </div>

    <div v-if="state == states.vacation">
      <b-form-group label="Fra">
        <el-date-picker
          v-bind:clearable="false"
          v-model="vacationStartDate"
        ></el-date-picker>
      </b-form-group>

      <b-form-group label="Til">
        <el-date-picker
          v-bind:clearable="false"
          v-model="vacationEndDate"
        ></el-date-picker>
      </b-form-group>

      <b-form-group>
        <b-form-radio value="1" v-model="vacationType"
        >Almindelig ferie</b-form-radio
        >
        <b-form-radio value="2" v-model="vacationType"
        >Feriefridage</b-form-radio
        >
        <b-form-radio value="3" v-model="vacationType"
        >Selvbetalt ferie</b-form-radio
        >
      </b-form-group>

      <b-form-group label="Evt. beskrivelse">
        <b-form-input
          v-model="vacationDescription"
          placeholder="Evt. beskrivelse"
        ></b-form-input>
      </b-form-group>
    </div>

    <!-- after creating something the user is presented with a receipt containing the summary of what he just made !-->
    <div v-if="state == states.receipt" style="text-align: center">
      <b style="color: #45b7c1; font-size: 18px">{{ receiptTitle }}</b>
      <div>{{ receiptText }}</div>
      <b-button class="mt-2" @click="closeReceipt()" size="sm">Luk</b-button>
    </div>

    <!-- history section -->
    <div
      v-if="state == states.history"
      style="height: 450px; display: flex; flex-direction: column"
    >
      <!-- body -->
      <div style="flex: 1; overflow-y: scroll">
        <div v-if="!loadingHistory && historyData.length == 0">
          Intet at vise
        </div>
        <div v-if="loadingHistory">
          <b-spinner label="Spinning"></b-spinner>
        </div>
        <div>
          <div v-for="item in historyData" :key="item.key">
            <div class="history-item" v-if="item.type == 'vacation'">
              <div style="font-weight: bold">Ferie</div>
              <div>
                fra {{ msToDateStr(item.obj.startDate) }} til
                {{ msToDateStr(item.obj.endDate) }}
              </div>
            </div>
            <div class="history-item" v-if="item.type == 'absence'">
              <div><b>Fravær,</b> {{ msToDateStr(item.obj.date) }}</div>
              <div>{{ item.obj.string }}</div>
            </div>
            <div class="history-item" v-if="item.type == 'flex'">
              <div><b>Flex,</b> {{ msToDateStr(item.obj.date) }}</div>
              <div>{{ item.obj.string }}</div>
            </div>
            <div class="history-item" v-if="item.type == 'oetid'">
              <div><b>Ø-tid,</b> {{ msToDateStr(item.obj.date) }}</div>
              <div>{{ item.obj.string }}</div>
            </div>
          </div>
        </div>
      </div>

      <!-- footer -->
      <div>
        <div v-if="historyFromDateStr && historyToDateStr">
          Data fra {{ historyFromDateStr }} og frem
        </div>
      </div>
    </div>

    <!-- footer for creating absence/oetid/vacation/flex -->
    <div
      class="mt-2"
      v-if="
        state != states.receipt && state != states.history && !userNotLinked
      "
    >
      <b-alert v-model="showError" variant="danger" dismissible>{{
          error
        }}</b-alert>
      <div style="text-align: right">
        <b-button @click="submit()" variant="primary" size="sm">Anmod</b-button>
      </div>
    </div>
  </div>
</template>

<script>
module.exports = {
  props: {
    moment: Function,
    axios: Function,
    getAulaToken: Function,
    assuranceLevel: Number,
    sessionUUID: String,
    isMobileApp: Boolean,
    userProfile: String,
  },
  data: function () {
    return {
      baseUrl: window.location.href.includes("widget-test2")
        ? "https://login.kalendatest.dk/Kalenda"
        : "https://login.kalenda.dk/Kalenda",
      apiUrl: "/widgetapi",
      aulaToken: undefined,
      tokenExpireTime: undefined, //time when the token expires in milliseconds in the future
      states: {
        login: 0,
        absence: 1,
        flex: 2,
        vacation: 3,
        receipt: 4,
        oetid: 5,
        history: 6,
      },
      state: 1,
      stateBeforeReceipt: 2, // when closing the receipt, we want to get back to the same tab as it was before
      stateBeforeHistory: 2, // when closing the history, we want to get back to the same tab as it was before
      receiptTitle: "",
      receiptText: "",
      error: "",
      showError: false,
      userNotLinked: false,
      flexSaldo: 0,
      /* FRAVAER */
      selectedAbsenceCause: "",
      absenceCauses: [], //loaded from the API
      absenceDate: new Date().toString(),
      absenceEndDate: new Date().toString(),
      absencePeriodeCheckbox: "false",
      absenceHours: "0",
      absenceDescription: "",
      absenceTimePicker: [
        new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate(),
          8,
          0
        ),
        new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate(),
          10,
          0
        ),
      ],
      absenceManualHours: "false",
      /* FLEKS */
      flexDate: new Date().toString(),
      flexType: "less",
      flexHours: "0",
      flexTimePicker: [
        new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate(),
          8,
          0
        ),
        new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate(),
          10,
          0
        ),
      ],
      flexManualHours: "false", // user manually types absence hours. "true" will show the time pickers
      flexDescription: "",
      /* Ø-tid */
      oetidDate: new Date().toString(),
      oetidHours: "0",
      oetidTypes: [],
      selectedOetidType: "",
      loginList: [],
      selectedLogin: "",
      userId: "",
      showLoginList: true,
      oetidTimePicker: [
        new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate(),
          8,
          0
        ),
        new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate(),
          10,
          0
        ),
      ],
      oetidManualHours: "false", // user manually types absence hours. "true" will show the time pickers
      oetidDescription: "",
      /* FERIE */
      vacationStartDate: new Date().toString(),
      vacationEndDate: new Date().toString(),
      vacationType: "1",
      vacationDescription: "",
      loadingHistory: false, // shows loading indicator in history
      historyData: [], // for more details about this object, see the showHistory to see how the objects in this array look
      // these 2 fields contain the from and to dates between which history items are loaded
      historyFromDateStr: "",
      historyToDateStr: "",
    };
  },
  created() {
    var self = this;
    // check if the user exists (defined in the 'methods' property of the component)
    this.getLoginList();

    //load absence causes
    this.loadAbsenceCauses();

    this.checkIfUserExists();
    //load oetid types
    this.getOetidTypes();
  },
  mounted() {},
  watch: {},
  methods: {
    switchTab(newState) {
      this.state = newState;
      this.showError = false;
      this.error = false;
    },
    //wrapper method to get the aula token asynchronously. If the token is valid and set, it returns it, otherwise it refreshes the token.
    getToken(callback) {
      if (
        this.aulaToken != undefined &&
        this.tokenExpireTime != undefined &&
        this.tokenExpireTime > new Date().getTime() //token expire time still in the future, this means the token is not expired yet
      ) {
        return callback(this.aulaToken);
      }
      this.aulaToken = undefined;
      this.aulaToken = this.getAulaToken();
      var self = this;
      function checkAulaToken() {
        if (self.aulaToken == undefined) {
          setTimeout(checkAulaToken, 100);
          return;
        }
        self.tokenExpireTime = new Date().getTime() + 4 * 60 * 1000; //time when the token expires, 4 minutes in the future (token is valid for 5 minutes, 4 minutes to manage to refresh it)
        callback(self.aulaToken);
      }
      setTimeout(checkAulaToken, 100);
    },
    loadAbsenceCauses() {
      this.getToken((token) => {
        var config = {
          method: "post",
          url: `${this.baseUrl + this.apiUrl}/ApiGetAbsenceCauses`,
          headers: {
            "Content-Type": "text/plain",
          },
          data: JSON.stringify({
            aulaToken: token,
          }),
        };
        var self = this;
        this.axios(config)
          .then(function (response) {
            self.absenceCauses = response.data.length > 0 ? response.data : [];
          })
          .catch(function (error) {
            console.log(error);
          });
      });
    },
    getOetidTypes() {
      this.getToken((token) => {
        var config = {
          method: "post",
          url: `${this.baseUrl + this.apiUrl}/getAllOetidTypes`,
          headers: {
            "Content-Type": "text/plain",
          },
          data: JSON.stringify({
            aulaToken: token,
            userId: this.userId,
          }),
        };
        var self = this;
        this.axios(config)
          .then(function (response) {
            let tmpoetid =
              response.data.oetidTypes && response.data.oetidTypes.length
                ? response.data.oetidTypes
                : [];
            self.oetidTypes = [];
            tmpoetid.forEach((value) => {
              self.oetidTypes.push({ text: value.name, value: value.id });
            });
          })
          .catch(function (error) {
            console.log(error);
          });
      });
    },
    getLoginList() {
      this.getToken((token) => {
        var config = {
          method: "post",
          url: `${this.baseUrl + this.apiUrl}/loginList`,
          headers: {
            "Content-Type": "text/plain",
          },
          data: JSON.stringify({
            aulaToken: token,
          }),
        };
        var self = this;
        this.axios(config)
          .then(function (response) {
            let tmpLoginList =
              response.data.loginList && response.data.loginList.length
                ? response.data.loginList
                : [];
            tmpLoginList.forEach((value) => {
              self.loginList.push({
                text: value.customerName,
                value: { userId: value.userId, url: value.url },
              });
            });
            if (self.loginList.length >= 1) {
              self.selectedLogin = self.loginList[0].value;
            }
            self.showLoginList = self.loginList.length > 1;
          })
          .catch(function (error) {
            console.log(error);
          });
      });
    },
    setUserLoginDetails() {
      if (this.selectedLogin.url != null) {
        this.baseUrl = this.selectedLogin.url;
      }
      this.userId = this.selectedLogin.userId;

      //this loads the flex balance for some reason
      this.checkIfUserExists();
      //load oetid types
      this.getOetidTypes();
      if (this.state == this.states.history) {
        this.showHistory();
      }
    },
    checkIfUserExists() {
      this.getToken((token) => {
        var config = {
          method: "post",
          url: `${this.baseUrl + this.apiUrl}/checkUserLinked`,
          headers: {
            "Content-Type": "text/plain",
          },
          data: JSON.stringify({
            aulaToken: token,
            userId: this.userId,
          }),
        };

        var self = this;
        this.axios(config)
          .then(function (response) {
            if (response.data.status == "user_not_linked") {
              self.userNotLinked = true;
            } else {
              self.userNotLinked = false;
              self.flexSaldo = response.data.flexSaldo;
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      });
    },
    submit() {
      switch (this.state) {
        case this.states.absence:
          this.submitAbsence();
          break;
        case this.states.flex:
          this.submitFlex();
          break;
        case this.states.oetid:
          this.submitOetid();
          break;
        case this.states.vacation:
          this.submitVacation();
          break;
      }
    },
    submitAbsence() {
      this.getToken((token) => {
        var data = {
          aulaToken: token,
          userId: this.userId,
          description: this.absenceDescription,
          cause: parseInt(this.selectedAbsenceCause),
          date: new Date(this.absenceDate).getTime(),
        };
        if (this.absencePeriodeCheckbox === "true") {
          data["endDate"] = new Date(this.absenceEndDate).getTime();
        }

        if (this.absenceManualHours === "false") {
          data["hours"] = parseFloat(this.absenceHours.replace(/,/g, "."));
        } else {
          data["from"] = this.absenceTimePicker[0].getTime();
          data["to"] = this.absenceTimePicker[1].getTime();
        }

        var config = {
          method: "post",
          url: `${this.baseUrl}${this.apiUrl}/absence`,
          headers: {
            "Content-Type": "text/plain",
          },
          data: JSON.stringify(data),
        };

        var self = this;
        this.axios(config)
          .then(function (response) {
            if (response.data.status == "success") {
              self.showReceipt();
            } else {
              self.showError = true;
              self.error = response.data.errorDescription;
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      });
    },
    submitFlex() {
      this.getToken((token) => {
        var data = {
          aulaToken: token,
          userId: this.userId,
          date: new Date(this.flexDate).getTime(),
          description: this.flexDescription,
        };
        if (this.flexManualHours === "false") {
          data["hours"] =
            this.flexType === "less"
              ? -Math.abs(parseFloat(this.flexHours.replace(/,/g, ".")))
              : Math.abs(parseFloat(this.flexHours.replace(/,/g, ".")));
          if (data["hours"] == 0) {
            this.showError = true;
            this.error = "Tilføj venligst flextimer";
            return;
          }
        } else {
          data["from"] = this.flexTimePicker[0].getTime();
          data["to"] = this.flexTimePicker[1].getTime();
          data["hours"] = (
            (this.flexTimePicker[1].getTime() -
              this.flexTimePicker[0].getTime()) /
            (60 * 60 * 1000)
          ).toFixed(2);
          if (this.flexType === "less") data["hours"] = -data["hours"];
        }

        var config = {
          method: "post",
          url: `${this.baseUrl}${this.apiUrl}/flextime`,
          headers: {
            "Content-Type": "text/plain",
          },
          data: JSON.stringify(data),
        };

        var self = this;
        this.axios(config)
          .then(function (response) {
            if (response.data.status == "success") {
              self.showReceipt();
              self.checkIfUserExists(); //to refresh the flex saldo
            } else {
              self.showError = true;
              self.error = response.data.errorDescription;
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      });
    },
    submitOetid() {
      this.getToken((token) => {
        var data = {
          aulaToken: token,
          userId: this.userId,
          date: new Date(this.oetidDate).getTime(),
          description: this.oetidDescription,
          oetidType: parseInt(this.selectedOetidType),
        };
        if (this.oetidManualHours === "false") {
          data["hours"] = Math.abs(
            parseFloat(this.oetidHours.replace(/,/g, "."))
          );
          if (data["hours"] == 0) {
            this.showError = true;
            this.error = "Tilføj venligst ø-tidtimer";
            return;
          }
        } else {
          data["from"] = this.oetidTimePicker[0].getTime();
          data["to"] = this.oetidTimePicker[1].getTime();
          data["hours"] = (
            (this.oetidTimePicker[1].getTime() -
              this.oetidTimePicker[0].getTime()) /
            (60 * 60 * 1000)
          ).toFixed(2);
        }

        var config = {
          method: "post",
          url: `${this.baseUrl}${this.apiUrl}/oetid`,
          headers: {
            "Content-Type": "text/plain",
          },
          data: JSON.stringify(data),
        };

        var self = this;
        this.axios(config)
          .then(function (response) {
            if (response.data.status == "success") {
              self.showReceipt();
            } else {
              self.showError = true;
              self.error = response.data.errorDescription;
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      });
    },
    submitVacation() {
      this.getToken((token) => {
        var data = {
          aulaToken: token,
          userId: this.userId,
          vacationType: parseInt(this.vacationType),
          startDate: new Date(this.vacationStartDate).getTime(),
          endDate: new Date(this.vacationEndDate).getTime(),
          description: this.vacationDescription,
          absenceStatus: 1, //should be 2 if he is a leader
        };

        var config = {
          method: "post",
          url: `${this.baseUrl}${this.apiUrl}/vacation`,
          headers: {
            "Content-Type": "text/plain",
          },
          data: JSON.stringify(data),
        };

        var self = this;
        this.axios(config)
          .then(function (response) {
            if (response.data.status == "success") {
              self.showReceipt();
            } else {
              self.showError = true;
              self.error = response.data.errorDescription;
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      });
    },
    //generates and shows the receipt if the request was successfull
    showReceipt() {
      switch (this.state) {
        case this.states.absence:
          this.receiptTitle = "Fraværsanmodning sendt";
          if (this.absencePeriodeCheckbox === "false") {
            this.receiptText = `Dato: ${this.moment(this.absenceDate).format(
              "DD-MM-YYYY"
            )}. `;
          } else {
            this.receiptText = `Fra ${this.moment(this.absenceDate).format(
              "DD-MM-YYYY"
            )} til ${this.moment(this.absenceEndDate).format("DD-MM-YYYY")}. `;
          }
          this.receiptText += `Årsag: ${
            this.absenceCauses.filter(
              (c) => c.value == this.selectedAbsenceCause
            )[0].text
          }. `;

          if (this.absenceManualHours === "false") {
            if (this.absenceHours != 0)
              this.receiptText += ` ${this.absenceHours} timer. `;
            if (this.absenceHours == 0) this.receiptText += `Hele dagen. `;
          } else {
            var fromTimeAbsence = this.moment(this.absenceTimePicker[0]).format(
              "HH:mm"
            );
            var toTimeAbsence = this.moment(this.absenceTimePicker[1]).format(
              "HH:mm"
            );
            this.receiptText += `Fra ${fromTimeAbsence} til ${toTimeAbsence}.`;
          }

          break;
        case this.states.flex:
          this.receiptTitle = "Flexanmodning sendt";
          this.receiptText = `Dato: ${this.moment(this.flexDate).format(
            "DD-MM-YYYY"
          )}. `;
          this.receiptText += `${
            this.flexType == "less" ? "Afspadsering" : "Merarbejde"
          } `;
          if (this.flexManualHours === "false") {
            this.receiptText += ` ${this.flexHours} timer. `;
          } else {
            var fromTimeFlex = this.moment(this.flexTimePicker[0]).format(
              "HH:mm"
            );
            var toTimeFlex = this.moment(this.flexTimePicker[1]).format(
              "HH:mm"
            );
            this.receiptText += ` fra ${fromTimeFlex} til ${toTimeFlex}. `;
          }
          if (this.flexDescription != "")
            this.receiptText += ` ${this.flexDescription} `;
          break;
        case this.states.oetid:
          this.receiptTitle = "Ø-tidanmodning sendt";
          this.receiptText = `Dato: ${this.moment(this.oetidDate).format(
            "DD-MM-YYYY"
          )}. `;
          if (this.oetidManualHours === "false") {
            this.receiptText += ` ${this.oetidHours} timer. `;
          } else {
            var fromTimeOetid = this.moment(this.oetidTimePicker[0]).format(
              "HH:mm"
            );
            var toTimeOetid = this.moment(this.oetidTimePicker[1]).format(
              "HH:mm"
            );
            this.receiptText += ` Fra ${fromTimeOetid} til ${toTimeOetid}. `;
          }
          if (this.oetidDescription != "")
            this.receiptText += ` ${this.oetidDescription} `;
          break;
        case this.states.vacation:
          this.receiptTitle = "Ferieanmodning sendt";
          this.receiptText = `Fra ${this.moment(this.vacationStartDate).format(
            "DD-MM-YYYY"
          )} til ${this.moment(this.vacationEndDate).format("DD-MM-YYYY")}. `;
          switch (this.vacationType) {
            case "1":
              this.receiptText += `Ferietype: Almindelig ferie. `;
              break;
            case "2":
              this.receiptText += `Ferietype: Feriefridage. `;
              break;
            case "3":
              this.receiptText += `Ferietype: Selvbetalt ferie. `;
              break;
          }
          if (this.vacationDescription != "")
            this.receiptText += ` ${this.vacationDescription} `;
          break;
      }
      this.stateBeforeReceipt = this.state;
      this.state = this.states.receipt;
    },
    closeReceipt() {
      this.state = this.stateBeforeReceipt;
      this.showError = false;
      this.error = false;
    },
    showHistory() {
      this.stateBeforeHistory = this.state;
      this.state = this.states.history;
      this.loadingHistory = true;
      var self = this;
      this.getToken((token) => {
        var config = {
          method: "post",
          url: `${this.baseUrl}${this.apiUrl}/getHistory`,
          headers: {
            "Content-Type": "text/plain",
          },
          data: JSON.stringify({
            aulaToken: token,
            userId: this.userId,
          }),
        };

        this.axios(config)
          .then(function (response) {
            // populate the history data with a list of objects, containg the date field by which they will be sorted, the type, for ex "flex", and the object with the absence itself (flex for example)
            let key = 0;
            self.historyData = [];
            self.historyFromDateStr = self.msToDateStr(
              response.data.fromDateMs
            );
            self.historyToDateStr = self.msToDateStr(response.data.toDateMs);
            response.data.flexDTOs.forEach((flex) => {
              self.historyData.push({
                key: key++,
                date: flex.date,
                type: "flex",
                obj: flex,
              });
            });
            response.data.oetidDTOs.forEach((oetid) => {
              self.historyData.push({
                key: key++,
                date: oetid.date,
                type: "oetid",
                obj: oetid,
              });
            });
            response.data.absenceDTOs.forEach((absence) => {
              self.historyData.push({
                key: key++,
                date: absence.date,
                type: "absence",
                obj: absence,
              });
            });
            response.data.vacationViewDays.forEach((vacation) => {
              self.historyData.push({
                key: key++,
                date: vacation.endDate,
                type: "vacation",
                obj: vacation,
              });
            });
            self.historyData.sort((a, b) => b.date - a.date); // sort all absences descending by date
          })
          .catch(function (error) {
            console.log(error);
          })
          .finally(() => {
            this.loadingHistory = false;
          });
      });
    },
    closeHistory() {
      this.state = this.stateBeforeHistory;
    },
    HHmmssToDate(hhmmss) {
      var pieces = hhmmss.split(":");
      var hour, minute, second;
      hour = parseInt(pieces[0], 10);
      minute = parseInt(pieces[1], 10);
      second = parseInt(pieces[2], 10);
      var date = new Date();
      date.setHours(hour, minute, second);
      return date;
    },
    msToDateStr(ms) {
      return this.moment.unix(ms / 1000).format("DD-MM-YYYY");
    },
  },
};
</script>
