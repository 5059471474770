




























































import { PropType } from 'vue';
import { TimeslotModel } from '../../models/timeslot.model';
import * as dateUtil from '../../utils/dateUtil.js';
import { dateFormatEnum } from '../../enums/dateFormatEnum';
import { EventInviteeModel } from '../../models/eventInvitee.model';
import AulaButton from '../../components/AulaButton.vue';

export default {
  components: { AulaButton },
  props: {
    timeslot: { type: Object as PropType<TimeslotModel>, required: true },
    invitees: { type: Array as PropType<Array<EventInviteeModel>>, required: true },
    concerningInstitutionProfileId: { type: Number, default: null },
    numberOfSlots: { type: Number, default: 1 },
    selected: { type: Boolean, default: false },
  },
  emits: ['selectSlot', 'clearSlot'],
  computed: {
    startTime() {
      return dateUtil.format(this.timeslot.startTime, dateFormatEnum.SHORT_TIME);
    },
    endTime() {
      return dateUtil.format(this.timeslot.endTime, dateFormatEnum.SHORT_TIME);
    },
    answers() {
      return this.timeslot.answers;
    },
    acceptedInvitees() {
      const answeredInviteeIds = this.answers.map(answer => answer.concerningProfile?.id);
      return this.invitees.filter(invitee => answeredInviteeIds.includes(invitee.instProfile.id));
    },
    blockedAnswers() {
      return this.answers.filter(answer => answer.instProfile == null);
    },
    isAccepted() {
      return this.answers.some(answer => answer.concerningProfile?.id === this.concerningInstitutionProfileId);
    },
    numberOfSlotsRemaining() {
      return this.numberOfSlots - this.acceptedInvitees.length - this.blockedAnswers.length;
    },
  },
  methods: {
    selectSlot() {
      this.$emit('selectSlot');
    },
    clearSlot(institutionProfileId) {
      this.$emit('clearSlot', institutionProfileId);
    },
  },
};
