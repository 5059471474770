import { SimpleEventModel } from '../../shared/models/simpleEvent.model';
import { NewSimpleEventValidator } from './validators/newSimpleEventValidator';

export class NewSimpleEvent implements SimpleEventModel {
  doRequestNumberOfParticipants = false;
  maximumNumberOfParticipants = null;
  actualNumberOfParticipants = null;

  constructor(model: SimpleEventModel) {
    this._validator = new NewSimpleEventValidator(this);
    this._maxParticipantsEnabled = model.maxParticipantsEnabled;
    this.doRequestNumberOfParticipants = model.doRequestNumberOfParticipants;
    this.setMaximumNumberOfParticipants(model.maximumNumberOfParticipants);
  }

  private _validator: NewSimpleEventValidator;
  private _maxParticipantsEnabled: boolean = false;

  get validator() {
    return this._validator;
  }

  get maxParticipantsEnabled() {
    return this._maxParticipantsEnabled;
  }

  isValid() {
    return this._validator.validate();
  }

  setMaxParticipantsEnabled(maxParticipantsEnabled: boolean) {
    this._maxParticipantsEnabled = maxParticipantsEnabled;
  }

  setMaximumNumberOfParticipants(maximumNumberOfParticipants: number | string | null) {
    let value = maximumNumberOfParticipants;
    if (typeof maximumNumberOfParticipants === 'string') {
      value = parseInt(maximumNumberOfParticipants) || null;
    }
    this.maximumNumberOfParticipants = value;
    this._validator.isValidMaximumParticipants = true;
    this._validator.isMaximumParticipantsHigherThanActualParticipants = true;
  }

  setActualNumberOfParticipants(actualNumberOfParticipants: number | null) {
    this.actualNumberOfParticipants = actualNumberOfParticipants;
  }

  setDoRequestNumberOfParticipants(doRequestNumberOfParticipants: boolean) {
    this.doRequestNumberOfParticipants = doRequestNumberOfParticipants;
  }

  getMaximumNumberOfParticipants() {
    let maximumNumberOfParticipants: null | number = null;
    if (this._maxParticipantsEnabled && this.maximumNumberOfParticipants) {
      maximumNumberOfParticipants = this.maximumNumberOfParticipants;
    }
    return maximumNumberOfParticipants;
  }

  getDoRequestNumberOfParticipants() {
    return this.doRequestNumberOfParticipants;
  }
}
