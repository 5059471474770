<template>
  <div class="shortcuts">
    <span class="nctHeader">{{ title }}</span>
    <div class="nctContent">
      <!-- One -->
      <ul class="nctLinks">
        <li v-for="(item, index) in collection(null)" :key="index">
          <span v-if="!item.isFolder">
            <a v-if="item.useAulaToken" :href="cleanLink(item.uri)" :title="item.description" @click.prevent.stop="openLinkWithAulatoken(item)" class="nctLink">{{ item.displayName }}</a>
            <a v-else :href="item.uri" :title="item.description" target="_blank" class="nctLink">{{ item.displayName }}</a>
          </span>
          <span v-else :class="{ nctActive : activeCollectionId === item.linkId}">
            <i class="fal fa-angle-down"></i>
            <span class="nctFolder" @click="toggleFolder(item.linkId)">{{ item.displayName }}</span>

            <!-- Two -->
            <transition v-on:enter="onOpenFolder" v-on:leave="onCloseFolder">
              <ul class="nctFolderLinkCollection" v-if="activeCollectionId === item.linkId">
                <li v-for="(itemLevelOne, index) in collection(item.linkId)" :key="index">
                  <span v-if="!itemLevelOne.isFolder">
                    <a v-if="itemLevelOne.useAulaToken" :href="cleanLink(itemLevelOne.uri)"
                      :title="itemLevelOne.description" @click.prevent.stop="openLinkWithAulatoken(itemLevelOne)" class="nctLink">{{ item.displayName }}</a>
                    <a
                      v-else
                      :href="itemLevelOne.uri"
                      :title="itemLevelOne.description"
                      target="_blank"
                      class="nctLink"
                    >{{ itemLevelOne.displayName }}</a>
                  </span>

                  <span v-else :class="{ nctActive : activeCollectionIdLevelOne === itemLevelOne.linkId}">
                    <i class="fal fa-angle-down"></i>
                    <span
                      class="nctFolder"
                      @click="toggleFolderLevelOne(itemLevelOne.linkId)"
                    >{{ itemLevelOne.displayName }}</span>

                    <!-- Two -->
                    <transition v-on:enter="onOpenFolder" v-on:leave="onCloseFolder">
                      <ul
                        class="nctFolderLinkCollection"
                        v-if="activeCollectionIdLevelOne === itemLevelOne.linkId"
                      >
                        <li
                          v-for="(itemLevelTwo, indexTwo) in collection(itemLevelOne.linkId)"
                          :key="indexTwo"
                        >
                          <span v-if="!itemLevelTwo.isFolder">
                            <a v-if="itemLevelTwo.useAulaToken" :href="cleanLink(itemLevelTwo.uri)"
                              :title="itemLevelTwo.description" @click.prevent.stop="openLinkWithAulatoken(itemLevelTwo)" class="nctLink">{{ item.displayName }}</a>
                            <a
                              v-else
                              :href="itemLevelTwo.uri"
                              :title="itemLevelTwo.description"
                              target="_blank"
                              class="nctLink"
                            >{{ itemLevelTwo.displayName }}</a>
                          </span>
                        </li>
                      </ul>
                    </transition>
                  </span>
                </li>
              </ul>
            </transition>
          </span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    axios: Function,
    getAulaToken: Function,
    userProfile: String
  },

  data() {
    return {
      urlToCheckApi: "https://aulawidgetfunction.nordiccloud.dk/api/LinkCollection?code=9AKluwwbadOKCvtuZYXm2y/q6mODObQ7VJwTF4DWGGaHnU5CMPGePw==",
      aulaToken: null,
      title: "",
      linkCollection: [],
      activeCollectionId: null,
      activeCollectionIdLevelOne: null,
    };
  },
  
  mounted() {
    this.aulaToken = this.getAulaToken();
  },

  watch: {
    aulaToken() {
      if (this.aulaToken) {
        this.loadLinkCollectionFromApi();
      }
    }
  },

  methods: {
    async loadLinkCollectionFromApi() {
      const linkCollectionResult = await this.axios.get(`${this.urlToCheckApi}`, { headers: { Authorization: `Bearer ${this.aulaToken}`}});

      this.title = linkCollectionResult.data.title;
      this.linkCollection = linkCollectionResult.data.links;
      this.linkCollection.forEach(l => l.useAulaToken = l.uri.includes("##aulatoken##"));
    },

    collection(parentId) {
      const arr = [];
      return arr.concat(this.folders(parentId), this.links(parentId));
    },

    folders(parentId) {
      return this.linkCollection
        .filter((l) => l.isFolder && l.parentId === parentId)
        .sort((a, b) => a.sort - b.sort);
    },

    links(parentId) {
      return this.linkCollection
        .filter((l) => !l.isFolder && l.parentId === parentId)
        .sort((a, b) => a.sort - b.sort);
    },

    linkReference(link) {
      return "linkCollection" + link.linkId;
    },

    toggleFolder(linkId) {
      if (this.activeCollectionId === linkId) {
        this.activeCollectionId = null;
      } else {
        this.activeCollectionId = linkId;
      }
      this.activeCollectionIdLevelOne = null;
    },

    toggleFolderLevelOne(linkId) {
      if (this.activeCollectionIdLevelOne === linkId) {
        this.activeCollectionIdLevelOne = null;
      }
      else {
        this.activeCollectionIdLevelOne = linkId;
      }
    },

    onOpenFolder(elm) {
      elm.style.visibility = "hidden";
      elm.style.maxHeight = "auto";
      const scrollHeight = elm.scrollHeight;

      if (elm.parentNode && elm.parentNode.parentNode && elm.parentNode.parentNode.parentNode && elm.parentNode.parentNode.parentNode.classList.contains("nctFolderLinkCollection")) {
        const parentNode = elm.parentNode.parentNode.parentNode;
        const parentHeight = elm.parentNode.parentNode.parentNode.scrollHeight;

        parentNode.style.maxHeight = scrollHeight + parentHeight + "px";
      }

      elm.style.maxHeight = "0px";
      elm.style.visibility = "visible";

      elm.style.maxHeight = scrollHeight + "px";
    },

    onCloseFolder(elm) {
      elm.style.maxHeight = "0px";
    },

    openLinkWithAulatoken(link) {
      if (link.useAulaToken) {
        window.open(link.uri.replace("##aulatoken##", this.aulaToken), "_blank");
      }
    },

    cleanLink(uri) {
      const regexRemoveQuery = /([^?]+).*/gm
      const split = regexRemoveQuery.exec(uri);
      if (split.length > 1) {
        return split[1];
      }
      return "";
    }
  },
};
</script>


<style scoped>
.shortcuts {
  padding-left: 5px;
  padding-right: 5px;
}

.nctHeader {
  font-size: 18px;
}

.nctContent {
  text-align: left;
}

.nctFolder {
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.fal {
  transition: transform, opacity 0.25s ease-out;
  opacity: 0.4;
  margin-right: 7px;
}

.nctActive > .fal {
  transform: rotate(-90deg);
  opacity: 1;
  /*transform: scaleY(-1);*/
}

.nctFolderLinkCollection {
  max-height: 0px;
  transition: max-height 0.15s ease-out;
  overflow: hidden;
  margin-bottom: 10px;
  list-style: none;
  margin: 0px;
  margin-left: 18px;
  margin-bottom: 3px;
  padding-left: 0px;
}

li {
  line-height: 1.7;
}

.nctLinks {
  margin: 0px;
  margin-bottom: 3px;
  padding-left: 0px;
  list-style: none;
}

.nctLink {
  margin-left: 18px;
  cursor: pointer;
}

.nctContent {
  margin-top: 10px;
  padding: 18px;
  padding-bottom: 25px;
  padding-top: 25px;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  font-size: 15px;
  background: #fff;
}

</style>