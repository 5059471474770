<template>
<div class='widget-W0062V0001'>
  <div class="momo-reminders">
    <datalist id="subjects">
      <option v-for="subject in model.subjects" :key="subject.id" :id="subject.id">
        {{subject.name}}
      </option>
    </datalist>
    <div class="momo-top">
      <div class="momo-heading">
        <span class="momo-heading-text">{{TEXT[LANG].HEADING}}</span>
        <div>
          <img v-if="canEditFavouriteTeams()"
               :src="urls.editFavouriteTeamsIcon"
               @click="openEditFavouriteTeamsPane()"
               :alt="TEXT[LANG].FAVOURITE_TEAMS"
               class="momo-icon-button">
          <span class="momo-icon-spacer"></span>
          <img v-if="canAddTeamReminder()"
               :src="urls.addTeamReminderIcon"
               @click="openCreateTeamReminderPane()"
               :alt="TEXT[LANG].ADD_TEAM_REMINDER"
               class="momo-icon-button">
        </div>
      </div>
      <div v-if="model.state === states.editingFavouriteTeams" class="momo-pane">
        <div class="momo-favourite-teams-heading">{{TEXT[LANG].FILTER_BY_TEAM}}</div>
        <div class="momo-team-checkboxes">
          <div v-for="team of model.teams" :key="team.id" class="momo-team-checkbox">
            <label :for="team.id" class="momo-team-checkbox-text">{{team.displayName}}</label>
            <input type="checkbox" :id="team.id" :value="team.id" v-model="model.favouriteTeamsBeingEdited">
          </div>
        </div>
        <div class="momo-button-group">
          <button @click="abortEditFavouriteTeamsPane()">{{TEXT[LANG].CANCEL}}</button>
          <button @click="submitEditFavouriteTeamsPane()">{{TEXT[LANG].APPLY_FILTER}}</button>
        </div>
      </div>
      <div v-if="model.state === states.addingTeamReminder" class="momo-pane">
        <img :src="urls.teamReminderIcon" class="momo-reminder-icon"
             :class="{'momo-team-reminder-icon-in-momo': !!systematic, 'momo-team-reminder-icon-in-aula': !systematic}">
        <input type="text" v-model="model.newTeamReminder.reminderText" minlength="1" maxlength="120"
               :placeholder="TEXT[LANG].REMEMBER + '...'" autofocus>
        <datalist id="teams">
          <option v-for="team of model.favouriteTeams" :key="team.id" :id="team.id">
            {{team.displayName}}
          </option>
          <option v-for="team of model.unfavouriteTeams" :key="team.id" :id="team.id">
            {{team.displayName}}
          </option>
        </datalist>
        <input list="teams" v-model="model.newTeamReminder.teamDisplayName"
               :placeholder="TEXT[LANG].TEAMS + '...'"/>
        <input list="subjects" v-model="model.newTeamReminder.subjectName" :placeholder="TEXT[LANG].SUBJECT"/>
        <input type="date" v-model="model.newTeamReminder.dueDate" :placeholder="TEXT[LANG].DATE_TEMPLATE">
        <div class="momo-button-group">
          <button @click="abortCreateTeamReminderPane()">{{TEXT[LANG].CANCEL}}</button>
          <button @click="submitCreateTeamReminderPane()" :disabled="!isValidCreateTeamReminderInput()">
            {{TEXT[LANG].SAVE}}
          </button>
        </div>
      </div>
    </div>
    <div v-if="model.state === states.failed" class="">
      <div class="momo-message">{{model.error}}</div>
    </div>
    <div class="momo-containing-block">
      <transition name="fade">
        <div v-if="model.statusBanner.doDisplay" class="momo-status-banner"
             :class="{'momo-status-banner-success': model.statusBanner.success, 'momo-status-banner-failure': !model.statusBanner.success}">
          <div class="momo-status-banner-text">{{model.statusBanner.text}}</div>
          <img height="26" :src="model.statusBanner.icon" alt="!">
        </div>
      </transition>
      <div v-if="model.favouriteTeams.length > 0 && model.state === states.displaying"
           class="momo-favourite-teams-display">
        <span class="momo-reminder-additional-text">{{formatTeamDisplayNames(model.favouriteTeams)}}</span>
        <button @click="resetFavouriteTeams()">{{TEXT[LANG].RESET}}</button>
      </div>
      <div v-if="isDisplayingReminders()"
           v-for="userReminders of model.reminders"
           :key="userReminders.userName"
           class="momo-user-reminders"
           :class="{'momo-user-reminders-no-more-to-see': !thereAreMoreRemindersToDisplay()}">
        <div v-if="model.reminders.length > 1" class="momo-child-name">
          {{formatPersonName(userReminders.userName)}}
        </div>
        <div v-if="userReminders.displayedRemindersGroupedByDueDate.length > 0"
             v-for="userRemindersOnDueDate of userReminders.displayedRemindersGroupedByDueDate"
             :key="userRemindersOnDueDate.dueDate.dayOfYear()"
             class="momo-user-reminders-for-date">
          <div v-if="userRemindersOnDueDate.remindersForDueDate.length > 0" class="momo-date-heading">
            {{formatDate(userRemindersOnDueDate.dueDate)}}
          </div>
          <div v-for="userReminderForDueDate of userRemindersOnDueDate.remindersForDueDate"
               :key="userReminderForDueDate.id" class="momo_user-reminder-for-due-date">
            <div v-if="userReminderForDueDate.type === reminderTypes.team">
              <div v-if="!isEditingTeamReminder(userReminderForDueDate)" class="momo-reminder">
                <img :src="urls.teamReminderIcon" alt=""
                     class="momo-reminder-icon"
                     :class="{'momo-team-reminder-icon-in-momo': !!systematic, 'momo-team-reminder-icon-in-aula': !systematic}">
                <div class="momo-reminder-text-container">
                  <div class="momo-reminder-text">{{userReminderForDueDate.reminderText}}</div>
                  <div class="momo-reminder-additional-text">
                    {{formatTeamAndSubject(userReminderForDueDate)}}
                  </div>
                </div>
                <img v-if="canEditTeamReminder(userReminderForDueDate)"
                     :src="urls.editTeamReminderIcon"
                     :alt="TEXT[LANG].EDIT"
                     @click="openEditTeamReminderPane(userReminderForDueDate)"
                     class="momo-icon-button">
              </div>
              <div v-if="isEditingTeamReminder(userReminderForDueDate)" class="momo-pane">
                <img :src="urls.teamReminderIcon" alt=""
                     class="momo-reminder-icon"
                     :class="{'momo-team-reminder-icon-in-momo': !!systematic, 'momo-team-reminder-icon-in-aula': !systematic}">
                <input type="text" v-model="model.teamReminderBeingEdited.edited.reminderText"
                       minlength="1" maxlength="120"
                       :placeholder="TEXT[LANG].REMEMBER + '...'"
                       autofocus>
                <input list="subjects" v-model="model.teamReminderBeingEdited.edited.subjectName"
                       :placeholder="TEXT[LANG].SUBJECT"/>
                <input type="date" v-model="model.teamReminderBeingEdited.edited.dueDate">
                <div class="momo-reminder-additional-text">{{TEXT[LANG].LAST_EDIT_BY + ' ' +
                model.teamReminderBeingEdited.original.lastEditBy}}
                </div>
                <div class="momo-button-group">
                  <div class="momo-delete-button-container">
                    <button @click="deleteTeamReminder()" class="momo-delete-button">
                      {{TEXT[LANG].DELETE}}
                    </button>
                  </div>
                  <button @click="abortEditTeamReminderPane()">{{TEXT[LANG].CANCEL}}</button>
                  <button @click="submitEditTeamReminderPane()"
                          :disabled="!isValidEditTeamReminderInput()">
                    {{TEXT[LANG].SAVE}}
                  </button>
                </div>
              </div>
            </div>
            <div
              v-if="userReminderForDueDate.type === reminderTypes.course || userReminderForDueDate.type === reminderTypes.assignment"
              class="momo-reminder">
              <div class="momo-containing-block">
                <img :src="userReminderForDueDate.courseImage || urls.defaultCourseImage" alt=""
                     @error="onCourseImageLoadError(userReminderForDueDate)" class="momo-reminder-icon">
                <img v-if="userReminderForDueDate.type === reminderTypes.assignment"
                     :src="urls.assignmentIcon" alt=""
                     class="momo-assignment-icon"
                     :class="{'momo-assignment-icon-in-momo': !!systematic, 'momo-assignment-icon-in-aula': !systematic}">
              </div>
              <div class="momo-reminder-text-container">
                <div class="momo-reminder-text">{{getReminderText(userReminderForDueDate)}}</div>
                <div class="momo-reminder-additional-text">
                  {{formatTeamsAndSubjects(userReminderForDueDate)}}
                </div>
              </div>
              <a
                :href="momoCoursePageUrl(userReminderForDueDate)"
                :target="courseLinkTarget()"
                :id="getCourseLinkId(userReminderForDueDate, userReminders.userId)"
                @click="setMomoCoursePageUrl(userReminderForDueDate, userReminders.userId)"
                class="momo-icon-button">
                <img height="25" :src="urls.goToCourseIcon" alt=">">
              </a>
            </div>
          </div>
        </div>
        <div v-if="userReminders.displayedRemindersGroupedByDueDate.length === 0"
             class="momo-no-reminders-for-user">
          {{formatNoticeOfNoReminders(userReminders.userName)}}
        </div>
      </div>
      <div v-if="isDisplayingReminders() && thereAreMoreRemindersToDisplay()" @click="displayMoreReminders()"
           class="momo-view-more-button">
        {{TEXT[LANG].SEE_MORE}}
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  props: {
    axios: Function,
    moment: Function,
    sessionUUID: String,
    placement: String,
    isMobileApp: Boolean,
    userProfile: String,
    getAulaToken: Function,
    assuranceLevel: Number,
    childFilter: Array,
    institutionFilter: Array,
    group: String,
    language: String,
    systematic: Object,
  },

  data: function () {
    const moMoBackendRootUrl = this.systematic && this.systematic.moMoFrontendUrl ? this.systematic.moMoFrontendUrl : 'https://systematic-momo.dk';
    const moMoFrontendRootUrl = this.systematic && this.systematic.moMoFrontendUrl ? this.systematic.moMoFrontendUrl : 'https://systematic-momo.dk';
    const apiVersion = 'v1';

    return {
      widgetVersion: '1.10',
      aulaToken: null,
      urls: {
        moMoBackendReminders: moMoBackendRootUrl + '/api/aula/reminders/' + apiVersion,
        moMoBackendTeamReminders: moMoBackendRootUrl + '/api/aula/reminders/team/' + apiVersion,
        moMoBackendTeams: moMoBackendRootUrl + '/api/aula/teams/' + apiVersion + '?',
        moMoBackendFavouriteTeams: moMoBackendRootUrl + '/api/aula/teams/favourites/' + apiVersion + '?',
        moMoBackendSubjects: moMoBackendRootUrl + '/api/aula/subjects/' + apiVersion + '?',
        teamReminderIcon: moMoFrontendRootUrl + '/assets/images/aula/team-reminder.svg',
        editTeamReminderIcon: moMoFrontendRootUrl + '/assets/images/aula/pen.svg',
        assignmentIcon: moMoFrontendRootUrl + '/assets/images/aula/assignment.svg',
        defaultCourseImage: moMoFrontendRootUrl + '/assets/images/aula/default-course-image.svg',
        addTeamReminderIcon: moMoFrontendRootUrl + '/assets/images/aula/plus.svg',
        editFavouriteTeamsIcon: moMoFrontendRootUrl + '/assets/images/aula/funnel.svg',
        goToCourseIcon: moMoFrontendRootUrl + '/assets/images/aula/chevron-right.svg',
        successIcon: moMoFrontendRootUrl + '/assets/images/aula/check-mark-in-circle.svg',
        failureIcon: moMoFrontendRootUrl + '/assets/images/aula/exclamation-mark-in-circle.svg',
        moMoCoursePageRootUrl: moMoFrontendRootUrl + '#/course/',
        moMoCoursePageRootUrlWithQueryParameter: moMoFrontendRootUrl + '?',
        moMoCoursePageFragment: '#/course/',
        moMoAssignmentTabPath: '/assignments',
      },
      states: {
        loading: 0,
        displaying: 1,
        addingTeamReminder: 2,
        editingTeamReminder: 3,
        editingFavouriteTeams: 4,
        failed: 5
      },
      userProfileTypes: {
        child: 'child',
        employee: 'employee',
        guardian: 'guardian'
      },
      reminderTypes: {
        team: 0,
        course: 1,
        assignment: 2
      },
      model: {
        remindersFetchedFromBackend: [],
        reminders: [],
        teams: [],
        hasTeamsFromMultipleInstitutions: false,
        favouriteTeams: [],
        unfavouriteTeams: [],
        subjects: [],
        error: '',
        state: 0,
        newTeamReminder: null,
        favouriteTeamsBeingEdited: null,
        teamReminderBeingEdited: null,
        statusBanner: {
          doDisplay: false,
          text: null,
          icon: null,
          success: true
        },
      },
      dateInputFieldDateFormat: 'YYYY-MM-DD',
      backendInputShortDateFormat: 'YYYY-MM-DD',
      TEXT: {
        DA: {
          HEADING: 'Huskeliste',
          FAVOURITE_TEAMS: 'Yndlingshold',
          ADD_TEAM_REMINDER: 'Tilføj',
          FILTER_BY_TEAM: 'Filtrer efter hold',
          REMEMBER: 'Husk',
          TEAMS: 'Hold',
          SUBJECT: 'Fag (valgfrit)',
          CANCEL: 'Annuller',
          RESET: 'Nulstil',
          EDIT: 'Ret',
          LAST_EDIT_BY: 'Sidst opdateret af',
          DELETE: 'Slet',
          SEE_MORE: 'Se flere',
          APPLY_FILTER: 'Filtrer',
          SAVE: 'Gem',
          UNKNOWN_DATE: 'Ukendt dato',
          YESTERDAY: 'I går',
          TODAY: 'I dag',
          TOMORROW: 'I morgen',
          WRONG_AULA_ASSURANCE_LEVEL: 'Du skal være logget ind for at se påmindelser.',
          GENERIC_ERROR_MESSAGE: 'Noget gik galt. Prøv igen senere.',
          UNKNOWN_PERSON: 'Ukendt person',
          YOU_HAVE_NO_REMINDERS: 'Du har ingen påmindelser',
          HAS_NO_REMINDERS: 'har ingen påmindelser',
          REMINDER_ADDED_FOR: 'Påmindelse tilføjet for',
          AN_ERROR_OCCURRED: 'Der er desværre opstået en fejl',
        },
        EN: {
          HEADING: 'Reminders',
          FAVOURITE_TEAMS: 'Favourite teams',
          ADD_TEAM_REMINDER: 'Add',
          FILTER_BY_TEAM: 'Filter by team',
          REMEMBER: 'Remember',
          TEAMS: 'Teams',
          SUBJECT: 'Subject (optional)',
          CANCEL: 'Cancel',
          RESET: 'Reset',
          EDIT: 'Edit',
          LAST_EDIT_BY: 'Last update by',
          DELETE: 'Delete',
          SEE_MORE: 'See more',
          APPLY_FILTER: 'Filter',
          SAVE: 'Save',
          UNKNOWN_DATE: 'Unknown date',
          YESTERDAY: 'Yesterday',
          TODAY: 'Today',
          TOMORROW: 'Tomorrow',
          WRONG_AULA_ASSURANCE_LEVEL: 'You need to be logged in to see reminders.',
          GENERIC_ERROR_MESSAGE: 'Something went wrong. Try again later.',
          UNKNOWN_PERSON: 'Unknown person',
          YOU_HAVE_NO_REMINDERS: 'You have no reminders',
          HAS_NO_REMINDERS: 'has no reminders',
          REMINDER_ADDED_FOR: 'Reminder added for',
          AN_ERROR_OCCURRED: 'An error occurred',
        }
      },
    }
  },

  computed: {
    LANG: function () {
      if (this.language && this.language.toUpperCase() === 'EN') {
        return this.language.toUpperCase();
      } else {
        return 'DA';
      }
    }
  },

  methods: {
    /** STATE MANAGEMENT FUNCTIONS BEGIN **/
    load() {
      this.model.remindersFetchedFromBackend = [];
      this.model.reminders = [];
      this.model.teams = [];
      this.model.hasTeamsFromMultipleInstitutions = false;
      this.model.favouriteTeams = [];
      this.model.unfavouriteTeams = [];
      this.model.error = '';
      if (![2, 3].includes(this.assuranceLevel)) {
        this.model.state = this.states.failed;
        this.model.error = this.TEXT[this.LANG].WRONG_AULA_ASSURANCE_LEVEL;
      } else {
        this.model.state = this.states.loading;
        this.fetchRemindersAndFavouriteTeams();
      }
    },

    openCreateTeamReminderPane() {
      this.fetchTeams();
      this.fetchSubjects();
      if (this.model.state === this.states.displaying) {
        this.model.newTeamReminder = {
          reminderText: '',
          teamName: '',
          subjectName: '',
          dueDate: this.moment().add(1, 'days').format(this.dateInputFieldDateFormat)
        };
        this.model.state = this.states.addingTeamReminder;
      }
    },

    isDisplayingReminders() {
      return [this.states.displaying, this.states.addingTeamReminder, this.states.editingTeamReminder, this.states.editingFavouriteTeams].includes(this.model.state);
    },

    isEditingTeamReminder(teamReminder) {
      return this.model.state === this.states.editingTeamReminder && this.model.teamReminderBeingEdited.original.id === teamReminder.id;
    },

    openEditTeamReminderPane(teamReminder) {
      if (this.model.state === this.states.displaying) {
        this.fetchSubjects();
        this.model.teamReminderBeingEdited = {
          original: teamReminder,
          edited: {
            reminderText: teamReminder.reminderText,
            dueDate: teamReminder.dueDate.format(this.dateInputFieldDateFormat),
            subjectName: teamReminder.subjectName
          }
        };
        this.model.state = this.states.editingTeamReminder;
      }
    },

    canAddTeamReminder() {
      return this.model.state === this.states.displaying &&
        [this.userProfileTypes.child, this.userProfileTypes.employee].includes(this.userProfile);
    },

    canEditTeamReminder(reminder) {
      return this.canAddTeamReminder() && (this.userProfile === this.userProfileTypes.employee || reminder.lastEditBy === this.model.reminders[0].userName);
    },

    canEditFavouriteTeams() {
      return this.model.state === this.states.displaying && this.userProfile === this.userProfileTypes.employee;
    },

    abortCreateTeamReminderPane() {
      this.model.editingTeamReminder = null;
      this.model.state = this.states.displaying;
    },

    submitCreateTeamReminderPane() {
      this.createTeamReminder();
    },

    abortEditTeamReminderPane() {
      this.model.editingTeamReminder = null;
      this.model.state = this.states.displaying;
    },

    submitEditTeamReminderPane() {
      this.updateTeamReminder();
    },

    openEditFavouriteTeamsPane() {
      this.model.favouriteTeamsBeingEdited = this.model.favouriteTeams.map(team => team.id);
      this.fetchTeams();
      if (this.model.state === this.states.displaying) {
        this.model.state = this.states.editingFavouriteTeams;
      }
    },

    abortEditFavouriteTeamsPane() {
      this.model.favouriteTeamsBeingEdited = null;
      this.model.state = this.states.displaying;
    },

    submitEditFavouriteTeamsPane() {
      this.updateFavouriteTeams();
    },
    /** STATE MANAGEMENT FUNCTIONS END **/

    /** BACKEND COMMUNICATION FUNCTIONS BEGIN **/
    fetchRemindersAndFavouriteTeams() {
      this.axios.get(
        this.urls.moMoBackendReminders + '?' +
        this.createChildrenParameter(this.childFilter, this.userProfile) +
        '&from=' + this.moment().format(this.backendInputShortDateFormat) +
        '&dueNoLaterThan=' + this.moment().add(6, 'months').format(this.backendInputShortDateFormat) + '&' +
        this.createTraceParameters(),
        this.createHeaders()
      ).then(response => {
        this.model.remindersFetchedFromBackend = response.data;
        this.model.favouriteTeams = [];
        if (this.userProfile === this.userProfileTypes.employee) {
          this.axios.get(
            this.urls.moMoBackendFavouriteTeams +
            this.createTraceParameters(),
            this.createHeaders(),
          )
            .then(response => {
              this.model.hasTeamsFromMultipleInstitutions = this.hasTeamsFromMultipleInstitutions(response.data);
              this.model.favouriteTeams = this.sortTeams(this.enrichWithDisplayNames(response.data));
              this.processRemindersFetchedFromBackend();
            }).catch(() => {
          });
        } else {
          this.processRemindersFetchedFromBackend();
        }
      }).catch(() => {
        this.model.error = this.TEXT[this.LANG].GENERIC_ERROR_MESSAGE;
        this.model.state = this.states.failed;
      });
    },

    fetchTeams() {
      if (this.model.teams.length === 0) {
        this.model.unfavouriteTeams = [];
        this.axios.get(
          this.urls.moMoBackendTeams + '&' +
          this.createTraceParameters(),
          this.createHeaders(),
        )
          .then(response => {
            this.model.hasTeamsFromMultipleInstitutions = this.hasTeamsFromMultipleInstitutions(response.data);
            this.model.teams = this.sortTeams(this.enrichWithDisplayNames(response.data));
            this.updateUnfavouriteTeams();
          }).catch(() => {
        })
      }
    },

    fetchSubjects() {
      if (this.model.subjects.length === 0) {
        this.axios.get(
          this.urls.moMoBackendSubjects +
          this.createTraceParameters(),
          this.createHeaders(),
        )
          .then(response => {
            this.model.subjects = response.data;
          }).catch(() => {
        })
      }
    },

    createTeamReminder() {
      const team = this.model.teams.find(team => team.displayName === this.model.newTeamReminder.teamDisplayName);
      if (team) {
        const payload = {
          teamId: team.id,
          teamName: team.displayName,
          dueDate: this.dateInputFieldDateFormatToBackendInputLongDateFormat(this.model.newTeamReminder.dueDate),
          reminderText: this.model.newTeamReminder.reminderText
        };
        if (this.model.newTeamReminder.subjectName) {
          payload.subjectName = this.model.newTeamReminder.subjectName;
        }
        this.axios.post(
          this.urls.moMoBackendTeamReminders + '?' +
          this.createTraceParameters(),
          payload,
          this.createHeaders()
        )
          .then(response => {
            if ([200, 201].includes(response.status)) {
              this.teamReminderCreated(response.data);
              this.displayTeamReminderAddedBanner(true, this.moment(response.data.dueDate));
            } else {
              this.displayTeamReminderAddedBanner(false);
            }
            this.processRemindersFetchedFromBackend();
          }).catch(() => {
          this.displayTeamReminderAddedBanner(false);
          this.model.state = this.states.displaying;
        })
      }
    },

    updateTeamReminder() {
      const payload = {
        dueDate: this.dateInputFieldDateFormatToBackendInputLongDateFormat(this.model.teamReminderBeingEdited.edited.dueDate),
        teamName: this.model.teamReminderBeingEdited.edited.displayName,
        reminderText: this.model.teamReminderBeingEdited.edited.reminderText
      };
      if (this.model.teamReminderBeingEdited.edited.subjectName) {
        payload.subjectName = this.model.teamReminderBeingEdited.edited.subjectName;
      }
      this.axios.put(
        this.urls.moMoBackendTeamReminders + '/' + this.model.teamReminderBeingEdited.original.id + '?' +
        this.createTraceParameters(),
        payload,
        this.createHeaders()
      )
        .then(response => {
          if (response.status === 200) {
            this.teamReminderEdited(response.data);
          }
          this.processRemindersFetchedFromBackend();
        }).catch(() => {
        this.model.state = this.states.displaying;
      });
    },

    deleteTeamReminder() {
      const reminderId = this.model.teamReminderBeingEdited.original.id;
      this.axios.delete(
        this.urls.moMoBackendTeamReminders + '/' + reminderId + '?' +
        this.createTraceParameters(),
        this.createHeaders()
      )
        .then(response => {
          if (response.status === 204) {
            this.teamReminderDeleted(reminderId);
          }
          this.processRemindersFetchedFromBackend();
        }).catch(() => {
        this.model.state = this.states.displaying;
      })
    },

    updateFavouriteTeams() {
      this.axios.put(
        this.urls.moMoBackendFavouriteTeams + this.createTraceParameters(),
        this.model.favouriteTeamsBeingEdited,
        this.createHeaders()
      )
        .then(response => {
          this.model.favouriteTeamsBeingEdited = [];
          this.model.favouriteTeams = this.sortTeams(this.enrichWithDisplayNames(response.data));
          this.updateUnfavouriteTeams();
          this.processRemindersFetchedFromBackend();
        }).catch(() => {
        this.model.state = this.states.displaying;
      })
    },

    createQueryParameter(key, items) {
      if (items) {
        const trimmed = items.map(s => s.trim()).filter(s => !!s);
        if (trimmed.length > 0) {
          return key + '=' + trimmed.join('&' + key + '=');
        }
      }
      return '';
    },

    createChildrenParameter(children, userProfile) {
      return userProfile === this.userProfileTypes.guardian ? (this.createQueryParameter('children', children)) : ''
    },

    createTraceParameters() {
      return 'widgetVersion=' + this.widgetVersion +
        '&userProfile=' + this.userProfile +
        '&sessionId=' + this.sessionUUID + '&' +
        this.createQueryParameter('institutions', this.institutionFilter)
    },

    createHeaders() {
      return {
        headers: {
          'Aula-Authorization': 'Bearer ' + this.aulaToken,
          'zone': Intl.DateTimeFormat().resolvedOptions().timeZone
        }
      }
    },

    dateInputFieldDateFormatToBackendInputLongDateFormat(date) {
      return this.moment(date).toISOString()
    },
    /** BACKEND COMMUNICATION FUNCTIONS END **/

    /** FORMATING FUNCTIONS BEGIN **/
    formatDate(date) {
      const yesterday = this.moment().subtract(1, 'day');
      const today = this.moment();
      const tomorrow = this.moment().add(1, 'day');
      if (!date.isValid()) {
        return this.TEXT[this.LANG].UNKNOWN_DATE;
      } else {
        if (date.isSame(yesterday, 'day')) {
          return this.TEXT[this.LANG].YESTERDAY;
        } else if (date.isSame(today, 'day')) {
          return this.TEXT[this.LANG].TODAY;
        } else if (date.isSame(tomorrow, 'day')) {
          return this.TEXT[this.LANG].TOMORROW;
        } else {
          date.locale(this.LANG.toLowerCase());
          const formatted = date.format(this.LANG === 'EN' ? 'dddd MMMM Do' : 'dddd Do MMMM');
          return formatted.charAt(0).toUpperCase() + formatted.slice(1);
        }
      }
    },

    formatPersonName(name) {
      return name ? name.split(' ')[0] : this.TEXT[this.LANG].UNKNOWN_PERSON;
    },

    formatNoticeOfNoReminders(name) {
      return this.model.reminders.length > 1 ? this.formatPersonName(name) + ' ' + this.TEXT[this.LANG].HAS_NO_REMINDERS : this.TEXT[this.LANG].YOU_HAVE_NO_REMINDERS;
    },

    join(names) {
      return names.join(', ');
    },

    formatTeamDisplayNames(teams) {
      return this.join(teams.map(team => team.name));
    },

    formatTeamAndSubject(teamReminder) {
      return teamReminder.teamName + (teamReminder.subjectName ? ', ' + teamReminder.subjectName : '');
    },

    formatTeamsAndSubjects(momoReminder) {
      return this.join(momoReminder.teamNames) + (momoReminder.courseSubjects.length > 0 ? ', ' + this.join(momoReminder.courseSubjects) : '');
    },
    /** FORMATING FUNCTIONS END **/

    /** TEAMS PROCESSING FUNCTIONS BEGIN **/
    enrichWithDisplayNames(data) {
      data.forEach(team => {
        team.displayName = this.model.hasTeamsFromMultipleInstitutions ? (team.name + ' (' + team.institutionName + ')') : team.name;
      });
      return data;
    },

    updateUnfavouriteTeams() {
      const favouriteTeamIds = this.model.favouriteTeams.map(team => team.id);
      this.model.unfavouriteTeams = this.sortTeams(this.model.teams.filter(team => !favouriteTeamIds.includes(team.id)));
    },

    sortTeams(teams) {
      return teams.sort((t1, t2) => {
        const instComp = t1.institutionName.localeCompare(t2.institutionName);
        return instComp !== 0 ? instComp : t1.name.localeCompare(t2.name);
      });
    },

    resetFavouriteTeams() {
      this.model.favouriteTeamsBeingEdited = [];
      this.updateFavouriteTeams();
    },
    /** TEAMS PROCESSING FUNCTIONS END **/

    /** REMINDERS PROCESSING FUNCTIONS BEGIN **/
    processRemindersFetchedFromBackend() {
      this.model.reminders = this.model.remindersFetchedFromBackend.map(userDataFetchedFromBackend => {
        let reminders = [];
        this.setTypeAndDueDate(userDataFetchedFromBackend.teamReminders, this.reminderTypes.team, reminders);
        this.setTypeAndDueDate(userDataFetchedFromBackend.courseReminders, this.reminderTypes.course, reminders);
        this.setTypeAndDueDate(userDataFetchedFromBackend.assignmentReminders, this.reminderTypes.assignment, reminders);
        reminders = this.filterAccordingToFavourites(reminders);
        reminders.sort((r1, r2) => {
          if (!r1.dueDate.isValid() && !r2.dueDate.isValid()) {
            return 0;
          } else if (!r1.dueDate.isValid()) {
            return 1;
          } else if (!r2.dueDate.isValid()) {
            return -1;
          }
          return r1.dueDate.diff(r2.dueDate);
        });
        let remindersGroupedByDueDate = [];
        if (reminders.length > 0) {
          let dueDate = reminders[0].dueDate;
          let remindersForDueDate = [];
          reminders.forEach(reminder => {
            if (reminder.dueDate.isSame(dueDate, 'day')) {
              remindersForDueDate.push(reminder);
            } else {
              if (remindersForDueDate.length > 0) {
                remindersGroupedByDueDate.push({
                  dueDate: dueDate,
                  remindersForDueDate: remindersForDueDate
                });
              }
              dueDate = reminder.dueDate;
              remindersForDueDate = [reminder];
            }
          });
          if (remindersForDueDate.length > 0) {
            remindersGroupedByDueDate.push({
              dueDate: dueDate, remindersForDueDate: remindersForDueDate
            });
          }
        }
        return {
          userName: userDataFetchedFromBackend.userName,
          userId: userDataFetchedFromBackend.userId,
          undisplayedRemindersGroupedByDueDate: remindersGroupedByDueDate,
          displayedRemindersGroupedByDueDate: [],
        };
      });
      this.displayMoreReminders();
      this.model.state = this.states.displaying;
    },

    setTypeAndDueDate(reminders, reminderType, result) {
      if (reminders) {
        reminders.forEach(reminder => {
          reminder.type = reminderType;
          reminder.dueDate = this.moment(reminder.dueDate);
          result.push(reminder);
        });
      }
    },

    filterAccordingToFavourites(reminders) {
      if (this.model.favouriteTeams.length > 0) {
        const favouriteTeamsIds = this.model.favouriteTeams.map(team => team.id);
        return reminders.filter(reminder => {
          switch (reminder.type) {
            case this.reminderTypes.team:
              return favouriteTeamsIds.includes(+reminder.teamId);
            case this.reminderTypes.assignment:
            case this.reminderTypes.course:
              return reminder.teamIds.some(teamId => favouriteTeamsIds.includes(+teamId));
            default:
              return false;
          }
        });
      } else {
        return reminders;
      }
    },

    displayMoreReminders() {
      this.model.reminders.forEach(remindersForUser => {
        let daysAdded = 0;
        while (remindersForUser.undisplayedRemindersGroupedByDueDate.length > 0 && daysAdded < 3) {
          const remindersForANewDay = remindersForUser.undisplayedRemindersGroupedByDueDate[0];
          remindersForUser.undisplayedRemindersGroupedByDueDate = remindersForUser.undisplayedRemindersGroupedByDueDate.slice(1);
          remindersForUser.displayedRemindersGroupedByDueDate.push(remindersForANewDay);
          daysAdded++;
        }
      })
    },

    thereAreMoreRemindersToDisplay() {
      return this.model.reminders.find(remindersForUser => remindersForUser.undisplayedRemindersGroupedByDueDate.length > 0);
    },

    teamReminderCreated(addedTeamReminder) {
      // Because guardians cannot edit or delete reminders, there is only ever one element in this.model.remindersFetchedFromBackend when we want to delete a team reminder.
      this.model.remindersFetchedFromBackend[0].teamReminders.push(addedTeamReminder);
    },

    teamReminderEdited(editedTeamReminder) {
      // Because guardians cannot edit or delete reminders, there is only ever one element in this.model.remindersFetchedFromBackend when we want to delete a team reminder.
      const teamReminderToUpdate = this.model.remindersFetchedFromBackend[0].teamReminders.find(teamReminder => teamReminder.id === editedTeamReminder.id);
      if (teamReminderToUpdate) {
        teamReminderToUpdate.dueDate = editedTeamReminder.dueDate;
        teamReminderToUpdate.lastEditBy = editedTeamReminder.lastEditBy;
        teamReminderToUpdate.reminderText = editedTeamReminder.reminderText;
        teamReminderToUpdate.subjectName = editedTeamReminder.subjectName;
      }
    },

    teamReminderDeleted(teamReminderId) {
      // Because guardians cannot edit or delete reminders, there is only ever one element in this.model.reminders when we want to delete a team reminder.
      this.model.remindersFetchedFromBackend[0].teamReminders = this.model.remindersFetchedFromBackend[0].teamReminders.filter(tr => tr.id !== teamReminderId);
    },
    /** REMINDERS PROCESSING FUNCTIONS END **/

    /** STATUS BANNER FUNCTIONS BEGIN **/
    mustDisplayStatusBanner() {
      return this.model.statusBanner.doDisplay;
    },

    displayTeamReminderAddedBanner(succeeded, dueDate) {
      this.model.statusBanner.text = succeeded ? this.TEXT[this.LANG].REMINDER_ADDED_FOR + ' ' + dueDate.format('D/M-YY') : this.TEXT[this.LANG].AN_ERROR_OCCURRED;
      this.model.statusBanner.icon = succeeded ? this.urls.successIcon : this.urls.failureIcon;
      this.model.statusBanner.success = succeeded;
      this.model.statusBanner.doDisplay = true;
      setTimeout(() => {
        this.model.statusBanner.doDisplay = false;
      }, 3000);
    },
    /** STATUS BANNER FUNCTIONS END **/

    /** VARIOUS OTHER FUNCTIONS BEGIN **/
    arraysDiffer(a1, a2) { // assuming both are arrays of strings
      if ((a1 === null && a2 !== null) || (a2 === null && a1 !== null)) {
        return true;
      }
      if (a1 === null && a2 === null) {
        return false;
      }
      if (a1.length !== a2.length) {
        return true
      }
      for (let i = 0; i < a1.length; i++) {
        if (a1[i] !== a2[i]) {
          return true;
        }
      }
      return false;
    },

    isValidCreateTeamReminderInput() {
      return this.isValidTeamReminderInput(this.model.newTeamReminder, true);
    },

    isValidEditTeamReminderInput() {
      return this.isValidTeamReminderInput(this.model.teamReminderBeingEdited.edited, false) &&
        (
          this.textChanged(this.model.teamReminderBeingEdited.edited.reminderText, this.model.teamReminderBeingEdited.original.reminderText) ||
          !this.moment(this.model.teamReminderBeingEdited.edited.dueDate, this.dateInputFieldDateFormat).isSame(this.model.teamReminderBeingEdited.original.dueDate) ||
          this.textChanged(this.model.teamReminderBeingEdited.edited.subjectName, this.model.teamReminderBeingEdited.original.subjectName)
        );
    },

    textChanged(edited, original) {
      return (!edited ? '' : edited).localeCompare(!original ? '' : original) !== 0;
    },

    isValidTeamReminderInput(reminder, checkTeam) {
      const today = this.moment();
      const dueDate = this.moment(reminder.dueDate, this.dateInputFieldDateFormat);
      return dueDate.isValid() && dueDate.isSameOrAfter(today, 'day') &&
        reminder.reminderText && reminder.reminderText.length >= 1 &&
        (!checkTeam || this.model.teams.find(team => team.displayName.localeCompare(reminder.teamDisplayName) === 0)) &&
        (!reminder.subjectName || this.model.subjects.find(subject => subject.name.localeCompare(reminder.subjectName) === 0));
    },

    momoCoursePageUrl(userReminderForDueDate) {
      return this.urls.moMoCoursePageRootUrl + userReminderForDueDate.courseId + this.courseTab(userReminderForDueDate);
    },

    getCourseLinkId(userReminderForDueDate, userId) {
      if (userReminderForDueDate.type === this.reminderTypes.assignment) {
        return 'momo_reminder_link_' +
          userReminderForDueDate.type + '_' +
          userReminderForDueDate.courseId + '_' +
          userReminderForDueDate.assignmentId + '_' +
          userId;
      }

      return 'momo_reminder_link_' +
        userReminderForDueDate.type + '_' +
        userReminderForDueDate.courseId + '_' +
        userReminderForDueDate.id + '_' +
        userId;
    },

    setMomoCoursePageUrl(userReminderForDueDate, userId) {
      if (!this.systematic) {
        const queryParameters = 'aulaToken=' + this.aulaToken +
          '&momoUserId=' + userId +
          '&momoInstitutionId=' + userReminderForDueDate.institutionId +
          '&userProfile=' + this.userProfile;

        const href = this.urls.moMoCoursePageRootUrlWithQueryParameter + queryParameters + this.urls.moMoCoursePageFragment + userReminderForDueDate.courseId + this.courseTab(userReminderForDueDate);
        document.getElementById(this.getCourseLinkId(userReminderForDueDate, userId)).href = href;
      }
    },

    courseTab(userReminderForDueDate) {
      return this.userProfile === this.userProfileTypes.employee && userReminderForDueDate.type === this.reminderTypes.assignment ? this.urls.moMoAssignmentTabPath : '';
    },

    onCourseImageLoadError(reminder) {
      reminder.courseImage = null;
    },

    getReminderText(reminder) {
      if (reminder.type === this.reminderTypes.course) {
        return reminder.reminderText;
      } else if (reminder.type === this.reminderTypes.assignment) {
        return reminder.assignmentText;
      } else {
        return '';
      }
    },

    hasTeamsFromMultipleInstitutions(teams) {
      return teams.length > 1 && teams.some(team => team.institutionName !== teams[0].institutionName);
    },

    courseLinkTarget() {
      return this.systematic ? '_parent' : '_blank';
    }
    /** VARIOUS OTHER FUNCTIONS END **/
  },

  watch:
    {
      childFilter: function (newValue, oldValue) {
        if (this.arraysDiffer(newValue, oldValue) && this.aulaToken !== undefined) {
          this.load();
        }
      },
      institutionFilter: function (newValue, oldValue) {
        if (this.arraysDiffer(newValue, oldValue) && this.aulaToken !== undefined) {
          this.load();
        }
      },
      assuranceLevel(newValue, oldValue) {
        if (newValue !== oldValue && this.aulaToken !== undefined) {
          this.load();
        }
      },
      aulaToken: function () {
        if (this.aulaToken !== undefined) {
          this.load();
        }
      }
    },

  created: function () {
    this.moment.locale('da');
    this.aulaToken = this.getAulaToken();
  },

  updated: function () {
  }
}
</script>

<style lang="scss">
.widget-W0062V0001 {
/* Override display:none with display:block */
input::-webkit-calendar-picker-indicator {
  display: block;
}
.momo-reminders {
  margin: 2px;
  background-color: white;
  border-radius: 4px;
  font-family: lato, sans-serif;
}

.momo-heading {
  padding: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: grey solid 1px;
}

.momo-heading-text {
  font-weight: bold;
  font-size: 22px;
}

.momo-status-banner {
  position: absolute;
  top: 0;
  height: 32px;
  width: 100%;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  opacity: 0.8;
}

.fade-leave-active {
  transition: opacity 3s;
}

.fade-leave-to {
  opacity: 0;
}

.momo-status-banner-success {
  background-color: #4BB543;
}

.momo-status-banner-failure {
  background-color: #C11E25;
}

.momo-status-banner-text {
  font-size: 16px;
  color: white;
}

.momo-favourite-teams-display {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 16px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 0px;
}

.momo-user-reminders {
  margin-bottom: 4px;
}

.momo-user-reminders-no-more-to-see {
  padding-bottom: 12px;
}

.momo-child-name {
  padding: 8px;
  padding-left: 16px;
  font-size: 18px;
  font-weight: bold;
  background-color: #F6F7F8;
}

.momo-user-reminders-for-date:not(:last-child) {
  border-bottom: 1px solid RGBA(0, 0, 0, .08);
}

.momo-date-heading {
  padding-left: 16px;
  font-size: 15px;
  font-weight: bold;
  opacity: 0.54;
  min-height: 48px;
  line-height: 48px;
}

.momo-reminder {
  padding-left: 8px;
  padding-right: 16px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 16px;
}

.momo-containing-block {
  transform: rotate(0deg); /* This makes this element the containing block of its children so that they can be absolutely positioned. See https://developer.mozilla.org/en-US/docs/Web/CSS/Containing_block */
}

.momo-reminder-icon {
  min-height: 40px;
  min-width: 40px;
  max-height: 40px;
  max-width: 40px;
  background-color: #F2F2F2;
  border-radius: 4px;
}

.momo-team-reminder-icon-in-aula {
  min-height: 40px;
  min-width: 40px;
  max-height: 40px;
  max-width: 40px;
  padding: 8px;
}

.momo-team-reminder-icon-in-momo {
  min-height: 24px;
  min-width: 24px;
  max-height: 24px;
  max-width: 24px;
  padding: 8px;
}

.momo-assignment-icon {
  padding: 5px;
  border-radius: 50%;
  background-color: #1e88e5;
  position: absolute;
  right: -4px;
  bottom: -4px;
  z-index: 1;
}

.momo-assignment-icon-in-aula {
  width: 23px;
  height: 23px;
}

.momo-assignment-icon-in-momo {
  width: 13px;
  height: 13px;
}

.momo-reminder-text-container {
  padding-left: 8px;
  flex-grow: 2;
}

.momo-reminder-text {
  font-size: 15px;
  opacity: 0.78;
  min-height: 18px;
  margin-bottom: 2px;
  margin-right: 2px;
  overflow-wrap: break-word;
}

.momo-reminder-additional-text {
  font-size: 12px;
  opacity: 0.54;
  min-height: 18px;
  display: flex;
  align-items: center;
}

.momo-icon-button {
  height: 20px;
  width: 20px;
  cursor: pointer;
  opacity: 0.54;
}

.momo-icon-spacer {
  margin-right: 16px;
}

.momo-icon-button:hover {
  opacity: 0.87;
}

.momo-no-reminders-for-user {
  padding: 8px;
}

.momo-pane {
  padding-top: 4px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 8px;
  display: flex;
  flex-direction: column;
  background-color: #F6F7F8;
}

.momo-button-group {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.momo-favourite-teams-heading {
  font-size: 15px;
  font-weight: bold;
  opacity: 0.54;
  min-height: 36px;
  line-height: 36px;
}

.momo-team-checkboxes {
  max-height: 200px;
  overflow-y: scroll;
  margin-bottom: 6px;
}

.momo-team-checkbox {
  padding: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border-bottom: #F6F7F8 solid 1px;
}

.momo-team-checkbox-text {
  font-size: 15px;
  opacity: 0.78;
}

input {
  border-radius: 4px;
  background-color: white;
  font-family: lato, sans-serif;
  font-size: 15px;
  opacity: 0.78;
  padding: 6px;
  margin-bottom: 6px;
  border-style: none;
}

button {
  border-radius: 4px;
  border-style: none;
  font-family: Lato, sans-serif;
  font-weight: bold;
  font-size: 12px;
  background-color: transparent;
  color: #222350;
  padding: 0 4px;
  outline: none;
  height: 36px;
  width: 60px;
  white-space: normal;
  cursor: pointer;
}

button:hover {
  background-color: RGBA(22, 23, 50, 0.08);
}

button:disabled {
  color: RGBA(22, 23, 50, 0.38);
}

.momo-delete-button-container {
  flex: 1 1 auto;
}

.momo-delete-button {
  color: #C11E25;
}

.momo-delete-button:hover {
  background-color: RGBA(193, 30, 25, 0.08);
}

.momo-message {
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 8px;
  padding-right: 8px;
}

.momo-view-more-button {
  text-align: center;
  font-family: lato, sans-serif;
  font-size: 12px;
  text-transform: uppercase;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 4px;
  cursor: pointer;
  background-color: #F6F7F8;
}

.momo-view-more-button:hover {
  background-color: RGBA(22, 23, 50, 0.08);
}

option {
  background-color: red;
}
}
</style>
