<script>

import ConsentBox from '../components/consent-box'
import Search from '../components/search'
import RecipientType from '../components/recipient-type'

const GroupMembersScreen = {
    props: {
        members: Array,
        classes: Object,
        users: Object,
        selectedGroupMembersFiltered: Object,
        selectedGroupMembersWhereConsentable: Array,
        selected: Object,
        recipientSearch: String,
        clearRecipientSearch: Function,
        setRecipientSearch: Function,
        giveConsent: Function,
        translations: Object,
    },
    components: {
        'consent-box': ConsentBox,
        'search': Search,
        'recipient-type': RecipientType,
    },
    computed: {
        previews() {

            return this.members
                .filter(member => !member.user_id)
                .map(member => {

                    if (member.class_id && member.relation) {
                        const className = this.classes[member.class_id]?.name
                        const relation = this.translations.general.roles[member.relation]
                        return `${className}: ${relation}`
                    }

                    if (member.relation) {
                        return this.translations.general.roles.all[member.relation]
                    }

                    return this.users[member.user_id]?.name
                })
        },
    },
}

export default GroupMembersScreen

</script>

<template>
    <div class="group-members main-screen-content scrollable">
        <div class='group-members-padding-container'>
            <p v-if='previews.length > 0' class="group-members-section-header">{{ translations.groupMembersScreen.groups }}</p>
            <div v-if='previews.length > 0' class="group-members-groups-container">
                <div v-for="group in previews" :key="group">
                    <div class="group-members-group-bubble">
                        {{ group }}
                    </div>
                </div>
            </div>
            <p class='group-members-section-header'>{{ translations.groupMembersScreen.members }}</p>
            <consent-box
                v-if="selectedGroupMembersWhereConsentable.length > 0"
                :sub-header-text="translations.groupMembersScreen.consentBoxSubheader"
                :users="selectedGroupMembersWhereConsentable"
                :give-consent="() => giveConsent(selectedGroupMembersWhereConsentable)"
                :translations='translations'
            >
            </consent-box>
            <search
                :placeholder="translations.groupMembersScreen.searchPlaceholder"
                :search="recipientSearch"
                :search-clear='clearRecipientSearch'
                :search-change='setRecipientSearch'
            >

            </search>
        </div>
        <recipient-type
            role="teacher"
            :translations='translations'
            :header="translations.general.roles.teachers"
            :recipients="selectedGroupMembersFiltered.teachers"
            :selected-users="selected.users"
            :keyed-classes='this.classes'
            :give-consent='giveConsent'
            :is-selectable='false'
        >
        </recipient-type>
        <recipient-type
            role="tutor"
            :translations='translations'
            :header="translations.general.roles.tutors"
            :recipients="selectedGroupMembersFiltered.tutors"
            :selected-users="selected.users"
            :keyed-classes='this.classes'
            :give-consent='giveConsent'
            :is-selectable='false'
        >
        </recipient-type>
        <recipient-type
            role="student"
            :translations='translations'
            :header="translations.general.roles.students"
            :recipients="selectedGroupMembersFiltered.students"
            :selected-users="selected.users"
            :keyed-classes='this.classes'
            :give-consent='giveConsent'
            :is-selectable='false'
        >
        </recipient-type>
        <recipient-type
            role="contact_person"
            :translations='translations'
            :header="translations.general.roles.contacts"
            :recipients="selectedGroupMembersFiltered.contactPersons"
            :selected-users="selected.users"
            :keyed-classes='this.classes'
            :give-consent='giveConsent'
            :is-selectable='false'
        >
        </recipient-type>
        <recipient-type
            role="inspector"
            :translations='translations'
            :header="translations.general.roles.inspectors"
            :recipients="selectedGroupMembersFiltered.inspectors"
            :selected-users="selected.users"
            :keyed-classes='this.classes'
            :give-consent='giveConsent'
            :is-selectable='false'
        >
        </recipient-type>
    </div>
</template>