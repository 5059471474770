import { WidgetDtoModel } from '../../shared/models/widgetDto.model';
import { WidgetPropsModel } from '../../shared/models/widgetProps.model';
import { widgetDtoTypes } from '../../shared/enums/widgetDtoTypes';

export class WidgetPropsDto implements WidgetDtoModel<WidgetPropsModel> {
  data: WidgetPropsModel;
  type: widgetDtoTypes = widgetDtoTypes.PROPS;

  constructor(data: WidgetPropsModel) {
    this.data = data;
  }
}
