<template>
  <div v-if="birthdayEvents.length > 0" class="shortcuts margin-bottom-40">
    <div class="birthday-title margin-bottom-10 margin-top">
      {{ 'POSTS_ASIDE_BIRTHDAY' | fromTextKey }}
    </div>
    <div v-if="todayBirthdays.length > 0" class="margin-bottom-20">
      {{ 'POSTS_ASIDE_BIRTHDAY_TODAY' | fromTextKey }}
    </div>
    <template v-for="(birthday, index) in todayBirthdays">
      <div v-if="isExpandedBirthday || index < 5" :key="index" class="quick-access-item">
        <div class="text">{{ birthday.title }}{{ 'POSTS_ASIDE_BIRTHDAY_CONGRATULATION' | fromTextKey }}</div>
      </div>
    </template>
    <div v-if="isExpandedBirthday || todayBirthdays.length < 5" class="margin-bottom-20 margin-top">
      {{ 'POSTS_ASIDE_BIRTHDAY_UPCOMMING' | fromTextKey }}
    </div>
    <template v-for="(birthday, index) in futureBirthdays">
      <div v-if="isExpandedBirthday || todayBirthdays.length + index < 5" :key="index" class="quick-access-item">
        <div class="text">
          {{ 'POSTS_ASIDE_BIRTHDAY_ON' | fromTextKey }}
          {{ birthday.upComingDays }}
          {{
            birthday.upComingDays > 1 ? 'POSTS_ASIDE_BIRTHDAY_DAYS_HAS' : 'POSTS_ASIDE_BIRTHDAY_DAY_HAS' | fromTextKey
          }}
          {{ birthday.title }}
          {{ 'POSTS_ASIDE_BIRTHDAY' | fromTextKey }}
        </div>
      </div>
    </template>
    <div v-if="birthdayEvents.length > 5" class="pull-right margin-top">
      <b-btn variant="link" @click="expandBirthday">
        {{ birthdayTitle | fromTextKey }}
        <i
          class="small"
          :class="{
            'icon-Aula_down-arrow': !isExpandedBirthday,
            'icon-Aula_up-arrow': isExpandedBirthday,
          }"
        />
      </b-btn>
    </div>
  </div>
  <div v-else>{{ 'POSTS_ASIDE_NO_BIRTHDAY_EVENTS' | fromTextKey }}</div>
</template>

<script>
import { mapGetters } from 'vuex';
import { mapActions } from 'vuex';
import { types } from '../../store/types/types';
import moment from 'moment-timezone';

export default {
  data: function () {
    return {
      birthdayTitle: 'POSTS_ASIDE_BIRTHDAY_BUTTON_TITLE_SEEMORE',
      isExpandedBirthday: false,
      moment: moment,
    };
  },
  computed: {
    ...mapGetters({
      profile: types.GET_CURRENT_PROFILE,
      isProfileLoaded: types.IS_PROFILE_LOADED,
      birthdayEvents: types.GET_BIRTHDAY_EVENTS,
      activeInstitutions: types.GET_ACTIVE_INSTITUTIONS,
    }),
    todayBirthdays() {
      return this.birthdayEvents.filter(event => moment(event.start).isSame(moment(), 'day'));
    },
    futureBirthdays() {
      const result = this.birthdayEvents
        .filter(event => moment(event.start).isAfter(moment(), 'day'))
        .sort((event1, event2) => moment(event1.start).diff(event2.start));
      for (const birthday of result) {
        birthday.upComingDays = moment(birthday.start, 'YYYY-MM-DD').diff(moment().startOf('day'), 'days', false);
      }
      return result;
    },
  },
  methods: {
    ...mapActions({
      loadBirthdayEvents: types.LOAD_BIRTHDAY_EVENTS,
    }),
    expandBirthday: function () {
      this.isExpandedBirthday = !this.isExpandedBirthday;
      this.birthdayTitle = this.isExpandedBirthday
        ? 'POSTS_ASIDE_BIRTHDAY_BUTTON_TITLE_SEEFEWER'
        : 'POSTS_ASIDE_BIRTHDAY_BUTTON_TITLE_SEEMORE';
    },
    initBirthdayEvents() {
      if (this.activeInstitutions.length > 0) {
        this.loadBirthdayEvents({
          start: moment(),
          end: moment().add(6, 'days'),
          source: 'overview',
          instCodes: this.activeInstitutions,
        });
      }
    },
  },
  watch: {
    profile() {
      this.initBirthdayEvents();
    },
    activeChildren() {
      this.initBirthdayEvents();
    },
    activeInstitutions() {
      this.initBirthdayEvents();
    },
  },
  mounted() {
    this.initBirthdayEvents();
  },
};
</script>

<style scoped lang="scss">
@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';

.birthday-title {
  display: none;
  @include breakpoint-sm-up() {
    display: block;
    text-transform: uppercase;
  }
}
.quick-access-item {
  margin-top: 10px;
  height: auto !important;
  min-height: 70px;
  line-height: 1.3 !important;
  cursor: pointer;
  width: 100%;
  font-size: 15px;
  background: $color-white;
  display: flex;
  align-content: center;
  white-space: normal !important;
  .text {
    margin: auto 25px;
    @include breakpoint-lg() {
      margin: auto 0;
    }
  }
  &:hover {
    background: $color-grey-light;
  }
}
</style>
