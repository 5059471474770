











































import AulaButton from '../../components/AulaButton.vue';
import Editor from '../../../shared/components/Editor/index.vue';
import Icon from '../../components/Icon.vue';
import { iconClassEnum } from '../../enums/iconClassEnum';
import { mapActions, mapGetters } from 'vuex';
import { types } from '../../../src/store/types/types.js';
import { eventTypeEnum } from '../../enums/eventTypeEnum';
import { portalRoles } from '../../enums/portalRoles';
import { eventResponseStatus } from '../../enums/eventResponseStatus';
import { isBefore } from '../../utils/dateUtil.js';

export default {
  components: { Icon, AulaButton, Editor },
  data() {
    return {
      reminderMessage: '',
      isValid: true,
      isValidReminderMessage: true,
      portalRoles: portalRoles,
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      currentEvent: types.GET_CURRENT_EVENT,
    }),
    canSendReminder() {
      if (this.currentEvent.responseDeadline) {
        return !this.currentEvent.isDeadlineExceeded;
      }

      return isBefore(new Date(), this.currentEvent.endDateTime);
    },
    iconClassEnum() {
      return iconClassEnum;
    },
    isVacationRequest() {
      return this.currentEvent.type === eventTypeEnum.VACATION_REGISTRATION;
    },
    isMeeting() {
      return (
        this.currentEvent.type === eventTypeEnum.SCHOOL_HOME_MEETING ||
        this.currentEvent.type === eventTypeEnum.PARENTAL_MEETING ||
        this.currentEvent.type === eventTypeEnum.PERFORMANCE_MEETING
      );
    },
    warningText() {
      if (this.isVacationRequest) {
        return this.$options.filters.fromTextKey('CALENDAR_SEND_VACATION_REQUEST_REMINDER_WARNING', {
          numberOfInvitees: this.currentEvent.vacationRegistration.childrenPendingAnswers.length,
        });
      }

      let waitingInvitees = this.currentEvent.invitees.filter(
        invitee => invitee.responseType === eventResponseStatus.WAITING
      );

      if (this.isMeeting) {
        waitingInvitees = waitingInvitees.filter(invitee => invitee.instProfile.role !== portalRoles.CHILD);
      }

      const waitingOtps = this.currentEvent.invitedGroupHomeChildren.filter(
        invitee => invitee.responseType === eventResponseStatus.WAITING
      );

      const inviteeCount = waitingInvitees.length + waitingOtps.length;

      return this.$options.filters.fromTextKey('CALENDAR_SEND_EVENT_REMINDER_WARNING', {
        numberOfInvitees: inviteeCount,
      });
    },
    reminderMessageText() {
      if (this.isVacationRequest) {
        return this.$options.filters.fromTextKey('CALENDAR_VACATION_REQUEST_REMINDER_MESSAGE');
      }
      return this.$options.filters.fromTextKey('CALENDAR_EVENT_REMINDER_MESSAGE');
    },
    autoGeneratedText() {
      return this.$options.filters.fromTextKey('CALENDAR_EVENT_REMINDER_AUTO_GENERATED');
    },
  },
  mounted() {
    this.initReminderMessage();
  },
  methods: {
    ...mapActions({
      sendReminder: types.SEND_EVENT_REMINDER,
      loadEventById: types.GET_EVENT_BY_ID,
    }),
    initReminderMessage() {
      this.reminderMessage = `<div>${this.reminderMessageText}</div>\r\n<div><em>(${this.autoGeneratedText})</em></div>`;
    },
    validateReminderMessage() {
      this.isValidReminderMessage = this.reminderMessage.length > 0;
    },
    validateReminder() {
      if (!this.isValidReminderMessage) {
        this.isValid = false;
      }
    },
    resetReminderStates() {
      this.isValidReminderMessage = true;
      this.isValid = true;
    },
    async onSendReminderClicked() {
      this.validateReminder();
      if (!this.isValid) {
        return;
      }
      this.isLoading = true;
      try {
        await this.sendReminder({
          eventId: this.currentEvent.id,
          message: this.reminderMessage,
        });
      } catch {
        this.isValid = false;
      }
      this.isLoading = false;
      this.$refs.sendReminderWarningModal.hide();
      await this.loadEventById({ eventId: this.currentEvent.id });
      this.resetReminderStates();
    },
    openSendReminderModal() {
      this.$refs.sendReminderWarningModal.show();
      this.initReminderMessage();
      this.resetReminderStates();
    },
  },
};
