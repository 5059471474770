<script>

import GroupMessage from '../components/group-message'
import MessageForm from '../components/message-form'
import Spinner from '../components/spinner'

const GroupMessagesScreen = {
    props: {
        sortedSelectedGroupMessages: Array,
        selectedGroup: Object,
        newMessage: String,
        newMessageLengthDescription: String,
        newMessageMessagesAmount: Number,
        charactersInCurrentMessage: Number,
        isValidGsmMessage: Boolean,
        isNewMessageLoading: Boolean,
        handleNewMessageChange: Function,
        attemptSendMessageToExistingGroup: Function,
        handleOpenMessageRecipientsScreen: Function,
        translations: Object,
    },
    components: {
        'group-message': GroupMessage,
        'message-form': MessageForm,
        'spinner': Spinner,
    },
}

export default GroupMessagesScreen

</script>

<template>
    <div class="group-messages-screen main-screen-content">
        <div id="group-messages-list" class='group-messages-list scrollable'>
            <div v-for="message in sortedSelectedGroupMessages" :key="message.id">
                <group-message
                    :translations="translations"
                    :message="message"
                    @handle-open-single-message="handleOpenMessageRecipientsScreen(message)"
                >
                </group-message>
            </div>
        </div>
        <div class="group-messages-form-box">
            <message-form
                style="margin-top:0;"
                :message="newMessage"
                :on-message-change='handleNewMessageChange'
                :group_id="selectedGroup.id"
                :on-submit="attemptSendMessageToExistingGroup"
                :message-length-description="newMessageLengthDescription"
                :new-message-messages-amount="newMessageMessagesAmount"
                :characters-in-current-message="charactersInCurrentMessage"
                :is-valid-gsm-message='isValidGsmMessage'
                :translations="translations"
            ></message-form>
        </div>
        <div v-if="isNewMessageLoading">
            <spinner></spinner>
            <div>
            </div>
        </div>
    </div>
</template>