<template>
<div class='widget-W0029V0001'>
  <div class="note-list" ref="noteListRef">
    <!-- Header -->
    <b-container fluid>
      <b-row class="note-list-header align-items-baseline">
        <b-col class="d-flex">
          <div class="d-flex align-items-center">
            <span class="note-list-header-small" v-if="placement === 'narrow'">Ugenoter</span>
            <h1 class="note-list-header-large" v-else>Ugenoter</h1>
          </div>
          <div v-if="week && year" :class="['d-flex', placement === 'narrow' ? 'align-items-center' : 'align-items-baseline']">
            <b-button
              class="note-list-header-week-picker d-flex justify-content-center"
              title="Gå til forrige uge"
              @click="handlePreviousWeek"
            >
              <i :class="['fa fa-caret-left', placement === 'narrow' ? 'fa-lg' : 'fa-2x']"></i>
            </b-button>
            <span class="note-list-header-week-label" v-if="placement === 'narrow'">
							uge {{ week }} <span v-if="year !== yearLocale">({{year}})</span>
						</span>
            <h1 v-else>
              uge {{ week }} <span v-if="year !== yearLocale">({{year}})</span>
            </h1>
            <b-button
              class="note-list-header-week-picker d-flex justify-content-center"
              title="Gå til næste uge"
              @click="handleNextWeek"
            >
              <i :class="['fa fa-caret-right', placement === 'narrow' ? 'fa-lg' : 'fa-2x']"></i>
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-container>

    <!-- Loading -->
    <div v-if="isLoading" class="spinner-wrapper">
      <i class="fa fa-spinner fa-spin"></i>
      henter ugenoter...
    </div>

    <!-- Message/error shown to user -->
    <div v-else-if="error || !persons.length" class="message-container">
			<span v-if="error === 'licenseError'">
				<p>Du har ikke adgang til widgets fra MinUddannelse.</p>
				Kontakt <a :href="'mailto:support@minuddannelse.dk?subject=Ingen adgang til Aula-widgets fra MinUddannelse (reference-id: ' + sessionUUID + ')'">support@minuddannelse.dk</a> for adgang.
				<p class="text-muted info-text">
					Ved henvendelse kan følgende reference-id hjælpe med at spore fejlen: {{sessionUUID}}
				</p>
			</span>

      <span v-else-if="error === 'error'" class="error-message">
				Noget gik galt med at hente ugenoter. Prøv evt. at logge ud og ind igen.
			</span>

      <span v-else class="text-muted">
				Der er ingen ugenoter i denne uge.
			</span>
    </div>

    <div v-else>
      <!-- Note List -->
      <b-container v-if="persons && persons.length"  class="notes-list-container" fluid>
        <b-row v-for="person in persons" :key="person.navn + '-' + person.id">
          <b-col :class="['note-person-wrapper', {'narrow': placement === 'narrow'}]">
            <b-row v-if="persons.length > 1 && (userProfile.toLowerCase() === 'guardian' || userProfile.toLowerCase() === 'parent' || userProfile.toLowerCase() === 'otp')">
              <b-col class="note-person">
                <strong v-if="placement === 'narrow'">{{person.navn}}</strong>
                <h2 class="note-person-header" v-else>{{person.navn}}</h2>
              </b-col>
            </b-row>
            <b-row v-for="institution in person.institutioner" :key="institution.kode">
              <b-col class="note-institution-container" :style="htmlWrapperStyleObject">
                <b-row v-if="person.institutioner.length > 1">
                  <b-col class="note-institution">
                    {{institution.navn}}
                  </b-col>
                </b-row>
                <b-row v-for="(note, index) in sortedNotes(institution.ugebreve)" :key="index">
                  <b-col class="note-container">
                    <b-row>
                      <b-col>
                        <div class="note-class-name">
                          {{note.tilknytningNavn}}
                        </div>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col :class="['note-text', {'narrowView': placement === 'narrow'}]">
                        <div class="note-item">
                          <widget-html :html="note.indhold"></widget-html>
                        </div>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </div>

    <!-- Logo -->
    <div v-if="!error" class="mu-provider" :class="{'narrow': placement === 'narrow'}">
      <form ref="muNoteListForm" @submit="addAulaTokenOnSubmit" method="POST" target="_blank" :action="link">
        <span class="mu-provider-text">Fra alle os på</span>
        <input type="hidden" name="assuranceLevel" :value="assuranceLevel" />
        <input type="hidden" name="aulaToken" :value="getTempAulaToken" />
        <input type="hidden" name="childFilter" :value="childFilter" />
        <input type="hidden" name="currentWeekNumber" :value="currentWeekNumber" />
        <input type="hidden" name="group" :value="group" />
        <input type="hidden" name="institutionFilter" :value="institutionFilter" />
        <input type="hidden" name="isMobileApp" :value="isMobileApp" />
        <input type="hidden" name="placement" :value="placement" />
        <input type="hidden" name="sessionUUID" :value="sessionUUID" />
        <input type="hidden" name="userProfile" :value="userProfile" />

        <b-button role="link" class="mu-provider-btn" :title="'https://www.minuddannelse.net'" type="submit">
          <b-img
            src="https://www.minuddannelse.net/Content/Graphics/Frontpage/frontpage_logo.svg"
            alt="MinUddannelse"
            fluid
            class="mu-provider-img"
          />
        </b-button>
      </form>
    </div>
  </div>
</div>
</template>

<script>

export default {
  name: 'NoteList',
  props: {
    assuranceLevel: Number,
    axios: Function,
    childFilter: [String, Array],
    currentWeekNumber: String,
    getAulaToken: Function,
    group: String,
    institutionFilter: [String, Array],
    isMobileApp: Boolean,
    moment: Function,
    placement: String,
    sessionUUID: String,
    userProfile: String,
  },
  data: function() {
    return {
      aulaToken: null,
      error: '',
      isLoading: false,
      link: new URL('https://api.minuddannelse.net/aula/redirect?redirectUrl=https://www.minuddannelse.net/'),
      persons: [],
      week: null,
      year: null,
      yearLocale: parseInt(this.moment().format('YYYY')),
      htmlWrapperStyleObject: {
        maxWidth: '',
      },
      tempAulaToken: '',
    };
  },
  watch: {
    aulaToken: function () {
      if (this.aulaToken !== undefined) {
        const { week, year } = this.extractWeekAndYear(this.currentWeekNumber);
        this.getNotes(week, year);
      }
    },
  },
  computed: {
    getTempAulaToken() {
      return this.tempAulaToken;
    }
  },
  methods: {
    sortedNotes: function (notes) {
      return notes.slice().sort((a, b) => a.sortOrder - b.sortOrder);
    },
    extractWeekAndYear: function(week) {
      const regex = (/^(\d){4}-W(\d){1,2}$/);

      // If there's something wrong with the data
      if (!regex.test(week)) {
        return {
          week: this.moment().week(),
          year: this.moment().year(),
        }
      }

      const [yearExtracted, weekExtracted] = week.split('-W');

      return {
        week: parseInt(weekExtracted, 10),
        year: parseInt(yearExtracted, 10),
      };
    },
    getNotes: function(week, year) {
      this.week = week;
      this.year = year;

      const propsToPass = {};
      const propToDismiss = [
        'axios',
        'getaulatoken',
        'moment',
        'currentWeekNumber',
      ];

      Object.keys(this.$props).forEach(prop => {
        if (!propToDismiss.includes(prop.toLowerCase())) {
          propsToPass[prop] = this.$props[prop] ? this.$props[prop].toString() : this.$props[prop];
        }
      });

      // Set `currentWeekNumber` to selected week
      propsToPass.currentWeekNumber = `${year}-W${week}`;

      const config = {
        method: 'get',
        headers: {
          Authorization: `Bearer ${this.aulaToken}`,
          Accept: 'application/json',
        },
        params: propsToPass,
        url: 'https://api.minuddannelse.net/aula/ugebrev',
      };

      // Get notes
      this.axios(config)
        .then((result) => {
          this.persons = result.data.personer;

          this.error = '';

          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          const licenseError = err.response.data.responseStatus.errorCode.toLowerCase() === 'licenserequired';

          this.error = licenseError ? 'licenseError' : 'error';

          return;
        });
    },
    resizeEventHandler: function() {
      this.noteListWidth = this.$refs.noteListRef.clientWidth;
      this.htmlWrapperStyleObject.maxWidth = this.placement === 'narrow' ? '' : (this.noteListWidth - 30) + 'px';
    },
    handlePreviousWeek: function() {
      this.isLoading = true;
      const newWeek = this.week - 1;
      const weeksInPreviousYear = this.moment({year: this.year - 1}).isoWeeksInYear();
      const week = newWeek < 1 ? weeksInPreviousYear : newWeek;
      const year = newWeek < 1 ? this.year -1 : this.year;
      this.getNotes(week, year);
    },
    handleNextWeek: function() {
      this.isLoading = true;
      const newWeek = this.week + 1;
      const weeksInYear = this.moment({year: this.year}).isoWeeksInYear();
      const week = newWeek <= weeksInYear ? newWeek : 1;
      const year = newWeek <= weeksInYear ? this.year : this.year + 1;
      this.getNotes(week, year);
    },
    addAulaTokenOnSubmit: function (e) {
      e.preventDefault();

      // Adding the aulaToken before submitting
      this.tempAulaToken = this.aulaToken;

      // Timeout ensures form is submittet after DOM is repaintet
      setTimeout(() => {
        // Ref can be an array because its located in a v-for
        if (Array.isArray(this.$refs.muNoteListForm)) {
          this.$refs.muNoteListForm[0].submit();
        } else {
          this.$refs.muNoteListForm.submit();
        }

        // Clear the aulaToken after submitting, so it's not visible in DOM
        // Timeout ensures the form has been submittet before clearing
        setTimeout(() => {
          this.tempAulaToken = '';
        }, 500);
      }, 500);
    },
  },
  created: function() {
    this.isLoading = true;
    this.aulaToken = this.getAulaToken();
    this.moment.locale('da');
    this.link.searchParams.append('userProfile', this.userProfile.toLowerCase());
  },
  mounted: function() {
    window.addEventListener("resize", this.resizeEventHandler);
    this.resizeEventHandler();
  },
  beforeDestroy: function() {
    window.removeEventListener("resize", this.resizeEventHandler);
  },
};
</script>
<style lang="scss">
.widget-W0029V0001 {
/* Class implemented by NC due to difference between style in preview and production.
Hopefully this will be fixed in the future */
.note-list h1 {
  font-size: 36px;
  font-weight: 900;
  margin: 0;
}

/* Class implemented by NC due to difference between style in preview and production.
Hopefully this will be fixed in the future */
.note-list .container-fluid {
  padding: 0 !important;
}

.note-list .spinner-wrapper {
  min-height: 42px;
  padding: 10px 0;
}

.note-list .message-container {
  margin: 0;
  padding: 10px 0;
}

.note-list .error-message {
  color: #b50050;
}

.note-list .info-text {
  font-size: 12px;
}

.note-list .note-list-header .note-list-header-small,
.note-list .note-list-header .note-list-header-week-label {
  font-size: 18px !important;
  text-transform: uppercase;
}

.note-list .note-list-header .note-list-header-large {
  font-size: 36px !important;
}

.note-list .note-list-header .note-list-header-week-picker {
  background-color: transparent;
  color: #222350;
  width: 30px;
  padding: 0;
  height: 30px;
  border-radius: 50%;
  position: relative;
  align-items: center;
}

.note-list .note-list-header .note-list-header-week-picker .fa-caret-left {
  margin-right: 3px;
}

.note-list .note-list-header .note-list-header-week-picker .fa-caret-right {
  margin-left: 3px;
}

.note-list .note-list-header .note-list-header-week-picker:active {
  background-color: #828a9180 !important;
}

.note-list .note-list-header .note-list-header-week-picker:focus {
  box-shadow: none !important;
}

.note-list .notes-list-container {
  margin-top: 10px;
}

.note-list .note-institution {
  margin-bottom: 8px;
}

.note-list .note-person .note-person-header {
  font-size: 22px;
  font-weight: 900;
}

.note-list .notes-list-container .note-person-wrapper,
.note-list .notes-list-container .note-person-wrapper.narrow,
.note-list .notes-list-container .note-person-wrapper.narrow .note-person {
  margin-bottom: 8px;
}

.note-list .note-institution-container {
  margin-bottom: 4px;
}

.note-list .note-institution {
  font-weight: 600;
  font-size: 15px;
}

.note-list .note-container {
  margin-bottom: 16px;
}

.note-list .note-class-name {
  background-color: #45B7C1;
  color: #ffffff;
  font-size: 13px;
  height: 24px;
  line-height: 24px;
  padding-left: 8px;
}

.note-list .note-item {
  padding: 12px 0 12px 8px;
  font-size: 15px;
  color: #222350;
  white-space: pre-wrap;
  overflow-x: auto;
  background-color: #ffffff;
}

.note-list .note-text img {
  max-width: 100%;
}

/* Content in <widget-html /> is injected therefore /deep/ selector is necessary in production*/
.note-list .note-text /deep/ table tr > td {
  padding: 7px;
  vertical-align: top;
  border: 1px solid #eaebef;
}

.note-list .note-text /deep/ a {
  color: #007a8d;
}

.note-list .mu-provider.narrow {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 4px;
}

.note-list .mu-provider-text {
  font-size: 12px;
  color: #868686;
}

.note-list .mu-provider-btn {
  background-color: transparent;
  padding: 0 0 0 8px;
  border: none;
  border-radius: 0;
}

.note-list .mu-provider-btn:active {
  background-color: transparent;
  border-color: transparent;
}

.note-list .mu-provider-btn:focus {
  box-shadow: none;
}

.note-list .mu-provider-img {
  height: 30px;
}
}
</style>
