<template>
<div class='widget-W0116V0001'>
  <div @click="showTitle(-1)">
    <!-- 23/6-2023 -->
    <div id="ist-div" v-bind:class="{ 'widget-scroll': placement === 'narrow' && !isMobile() }">
      <div v-bind:class="{ 'ist-sticky': placement === 'narrow' || isMobile() }">
        <div class="ist-top">
          <div style="display: flex;flex-direction: row">
            <div style="display: flex;flex-direction: column">
              <div><h5>Arbejdstid</h5></div>
              <div class="ist-dtp">Dagtilbud Personale</div>
            </div>
            <div style="width: 100%" v-if="aulaToken && institutions.length === 1">
              <form :id="'ist-mine-timer' + institutions[0].institution_g_number" :action="mineTimerUrl"
                    method="get" target="Dagtilbud_Personale">
                <div class="mine-timer"
                     v-bind:class="{linkbtn: placement === 'narrow'}"
                     type="submit" title="Rediger arbejdstider"
                     @click="goToMineTimer(institutions[0].institution_g_number)">
                  <i class="fas fa-edit fa-lg"></i>
                  <input type="text" name="token" hidden :value="'Bearer ' + aulaToken "></input>
                  <input type="text" name="institution_g_number" hidden
                         :value="institution_g_number"></input>
                </div>
              </form>
            </div>
          </div>
          <div style="margin-top: -20px" v-if="errorMessage !== ''">
            <br/>{{ errorMessage }}
            <i v-if="fullErrorMessage !== '' && !showFullErrorMessage"
               @click="showFullErrorMessage = !showFullErrorMessage"
               class="fas fa-chevron-down errormessage-chevron"
            ></i>
            <i
              @click="showFullErrorMessage = !showFullErrorMessage"
              class="fas fa-chevron-up errormessage-chevron"
              v-if="fullErrorMessage !== '' && showFullErrorMessage"
            ></i>
            <div v-if="showFullErrorMessage" class="ist-errormessage">{{ fullErrorMessage }}</div>
          </div>
        </div>
        <div class="weekpicker" v-if="errorMessage === '' && institutions.length > 0">
          <div class="ist-date-button date-back">
            <b-button
              :disabled="disablePreviousDay"
              class="btn btn-link navbar-btn navbar-link"
              @click="backWeek"
            >
              <i class="fas fa-chevron-left"></i>
            </b-button>
          </div>
          <div class="ist-date">
            Uge {{ currentWeek }}
            <b-button class="btn btn-link navbar-btn navbar-link ist-weekoptions-arrow"
                      @click="showWeekOptions = !showWeekOptions"
            >
              <i class="fas fa-chevron-down"></i>
            </b-button>
            <div class="ist-weekoptions" v-if="showWeekOptions">
              <div :ist-week="week.value" v-for="week in weekOptions" v-bind:key="week.value"
                   @click="selectWeek(week.value)">{{week.label}}
              </div>
            </div>
          </div>
          <div class="ist-date-button date-forward">
            <b-button
              :disabled="disableNextDay"
              class="btn btn-link navbar-btn navbar-link"
              @click="forwardWeek"
            >
              <i class="fas fa-chevron-right"></i>
            </b-button>
          </div>
        </div>
      </div>
      <div v-for="(inst, instIndex) in institutions" v-bind:key="instIndex">
        <div v-bind:class="{ 'ist-header': placement !== 'narrow' }">
          <div>{{ inst.institution_name }}</div>
          <div v-if="aulaToken && institutions.length > 1" style="display:flow-root">
            <form :id="'ist-mine-timer' + inst.institution_g_number" :action="mineTimerUrl"
                  method="get" target="Dagtilbud_Personale">
              <div class="mine-timer" v-bind:class="{linkbtnmultiple: placement !== 'narrow'}"
                   type="submit" title="Rediger arbejdstider"
                   @click="goToMineTimer(inst.institution_g_number)">
                <i class="fas fa-edit fa-lg"></i>
                <input type="text" name="token" hidden :value="'Bearer ' + aulaToken "></input>
                <input type="text" name="institution_g_number" hidden
                       :value="inst.institution_g_number"></input>
              </div>
            </form>
          </div>
        </div>
        <div v-bind:class="{ 'weekplan-medium': placement !== 'narrow' && !isMobile(), 'weekplan-narrow': placement === 'narrow' || isMobile() }"
             class="ist-day">
          <div
            class="list-table"
            v-for="(dayItem, index) in getDisplayDates()"
            :value="dayItem"
            :key="index"
          >
            <table>
              <tbody>
              <div>
                <tr>
                  <td
                    class="widget-header"
                    v-bind:class="{ today: moment().isSame(dayItem, 'day') }"
                  >
                                 <span :ist-date="moment(dayItem).format('YYYYMMDD')" v-if="!medium">{{
                                     formatDateLong(dayItem)
                                   }}</span>
                    <span :ist-date="moment(dayItem).format('YYYYMMDD')" v-else>{{
                        formatDateShort(dayItem)
                      }}</span>
                  </td>
                </tr>
                <tr>
                  <td class="hours">
                    <span>Arbejdstimer {{ getTotaleHoursPerDay(dayItem, inst) }}</span>
                  </td>
                </tr>
                <tr>
                  <td class="absence">
                    <span v-for="absence in getAbsenceOnDay(dayItem, inst)">{{ absence.code + " "}}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div
                      v-for="(item, index) in getWorkHoursOnDay(dayItem, inst)"
                      :key="'workhours' + index"
                      class="day-group"
                    >
                      <div class="day-view-time-event">
                        <div v-if="!item.duration_override">
                          {{ item.start_time }}
                        </div>
                        <div class="content-bottom" v-if="!item.duration_override">
                          {{ item.end_time }}
                        </div>
                        <div v-if="item.duration_override && item.target !== 2"
                             class="duration-clock">
                          <i class="far fa-clock"></i>
                        </div>
                        <div v-if="item.duration_override && item.target === 2"
                             class="duration-clock">
                          <i class="far fa-hourglass-half"></i>
                        </div>
                      </div>
                      <div class="day-view-title-event">
                        <div v-if="!item.duration_override && (item.overlapping_duties.length > 0 || item.events_outside_workhours.length > 0)"
                             class="alert-icon">
                          <i class="fas fa-exclamation-circle"
                             @click="showTitle(item.id)"></i>
                        </div>
                        <div
                          class="title-div"
                          v-bind:class="{ 'title-div-position': placement !== 'narrow'}"
                          v-if="showTitleId === item.id"
                        >
                          <div @click="showTitleId = -1" class="close-icon"><i
                            class="fas fa-times"></i></div>
                          <div v-for="(event_outside, eo_index) in item.events_outside_workhours"
                               :value="event_outside"
                               :key="eo_index"
                               class="new-item">
                            {{event_outside.name}}
                          </div>
                          <div v-for="(overlapping_item, od_index) in item.overlapping_duties"
                               :value="overlapping_item"
                               :key="overlapping_item.id"
                               style="padding-bottom:5px;"
                          >
                            {{overlapping_item.name }}
                            {{ overlapping_item.overlap_warning }}
                          </div>
                        </div>
                        <div v-if="item.opening_duty && !item.duration_override"
                             class="new-item">
                          <strong>Åbne vagt</strong><br/>
                        </div>
                        <div v-for="pool in item.pools" class="new-item">
                          {{ pool.name }}
                          <div v-if="pool.spend"> Forbrug: {{pool.spend}}</div>
                          <div v-if="pool.event_name">{{pool.event_name}} <br>{{pool.time}}
                          </div>
                        </div>
                        <div v-for="event in item.events" class="new-item">
                          {{ event.name }} <br>
                          {{ event.time }}
                        </div>
                        <div v-if="item.duration_override && item.start_time === '' && item.target !== 2"
                             class="new-item">
                          {{ item.duration + " timer uden start/slut tid" }}
                        </div>
                        <div v-if="item.duration_override && item.start_time !== ''"
                             class="new-item">
                          {{ "Varighed: " + item.duration + "\nOprindelig: " + item.start_time
                        + " - " + item.end_time }}
                        </div>
                        <div v-for="duration_duty in item.durations">
                          {{duration_duty.name}}
                        </div>
                        <div v-for="cr in item.change_requests" class="new-item">
                          <i class="far fa-calendar"></i>
                          <span v-if="cr.status !== 1 && cr.status !== 2">{{ cr.start_time }}-{{ cr.end_time }}<br/></span>
                          <span v-if="cr.status === 0">
                                          <b>Afventer</b>
                                          </span>
                          <span v-if="cr.status === 1" style="color: #45b7c1">
                                          <b>Godkendt</b>
                                          </span>
                          <span v-if="cr.status === 3" style="color: #222350"
                          ><b>Nulstillet</b></span
                          >
                          <span v-if="cr.status === 2" style="color: #b50050"
                          ><b>Afvist</b>
                                          </span>
                          <i
                            v-if="cr.note !== ''"
                            class="far fa-comment"
                            aria-hidden="true"
                            title="Se note i Dagtilbud Personale"
                          ></i>
                        </div>
                        <div v-if="item.closing_duty && !item.duration_override">
                          <strong>Lukke vagt</strong><br/>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </div>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="title-div-updated" v-if="showUpdatedTitle">
        Data hentes hvert 5. minut
      </div>
      <div @click="showUpdated()" class="hours" v-bind:class="{ 'mobile-bottom': isMobile() }"
           style="cursor:pointer;">{{updated}}&nbsp;<i class="fa fa-spinner fa-spin" v-if="loading"></i>
      </div>
    </div>
  </div>
</div>
</template>
<script>

export default {
  props: {
    axios: Function,
    sessionUUID: String,
    placement: String,
    currentWeekNumber: String,
    isMobileApp: Boolean,
    userProfile: "child" | "employee" | "guardian",
    getAulaToken: Function,
    assuranceLevel: Number,
    childFilter: Array,
    institutionFilter: Array,
    group: String,
    moment: Function
  },
  data: function () {
    return {
      aulaToken: undefined,
      localToken: undefined,
      loading: false,
      medium: true,
      displayDate: new Date(),
      displayDates: [],
      currentWeek: this.moment().week(),
      showTitleId: -1,
      showUpdatedTitle: false,
      institutions: [],
      errorMessage: "",
      fullErrorMessage: "",
      showFullErrorMessage: false,
      mineTimerUrl: "",
      institution_g_number: "",
      updated: "",
      timer: null,
      weekOptions: [],
      showWeekOptions: false,
      twoMonthsAgo: this.moment().subtract(2, "month").startOf("month").startOf("week"),
      aYearAhead: this.moment().add(1, "year").startOf("week"),
      firstTime: true
    };
  },
  methods: {
    async initData() {
      if (!this.aulaToken) {
        return;
      }
      this.errorMessage = "";
      this.fullErrorMessage = "";
      this.loading = true;

      const url = "https://api-v3.ist.com/dtp_prod/api/aula_work_hours_widget/get_employee_work_hours.json?date=" +
        this.moment(this.displayDate).format("YYYY-MM-DD") +
        "&institutionids=" +
        this.institutionFilter.toString().replace(" ", "");
      this.mineTimerUrl = "https://dagtilbudpersonale.ist.com/api/personale/login_via_aula.json";

      this.axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.aulaToken
          }
        })
        .then((res) => {
          this.institutions = res.data.data;
          this.institution_g_number = this.institutions[0].institution_g_number;
          this.dates = this.institutions[0].dates_and_total_hours_per_day;
          this.setWeekDates();
          if (this.firstTime) {
            this.gotoToday();
            this.firstTime = false;
          }

          this.updated = "Opdateret: " + this.moment(new Date()).format('DD/MM-YY HH:mm:ss')
        })
        .catch((e) => {
          try {
            const jsonObj = JSON.parse(e.response.data.errors[0].message);
            this.errorMessage = jsonObj.short_message;
            this.fullErrorMessage = jsonObj.full_message;
            clearInterval(this.timer);
          } catch (error) {
            this.errorMessage = "Der er sket en uventet fejl, forsøg venligst at opdatere siden senere.";
            // clearInterval(this.timer);
          }
        }).finally(() => {
        this.loading = false;
      });
    },
    goToMineTimer(g_number) {
      this.$el.querySelector('#ist-mine-timer' + g_number).submit();
    },
    setWeekDates() {
      this.displayDates = [];
      const startDate = this.moment(this.displayDate).startOf("week");
      this.currentWeek = this.moment(this.displayDate).week();
      for (let index = 0; index < 7; index++) {
        const d = this.addDays(startDate, index);
        const saturdayOrSunday = this.moment(d).day() == 6 || this.moment(d).day() == 0;
        if (saturdayOrSunday) {
          for (let i_index = 0; i_index < this.institutions.length; i_index++) {
            if (this.getWorkHoursOnDay(d, this.institutions[i_index]).length > 0) {
              this.displayDates.push(d);
              i_index = this.institutions.length;
            }
          }
        } else {
          this.displayDates.push(d);
        }
      }
    },
    getWeeks() {
      const weeks = [];
      let loop = this.moment().subtract(2, "month").startOf("month").startOf("week");
      while (loop <= this.aYearAhead) {
        const week = loop.week();
        const year = loop.year();
        weeks.push({
          value: year + "-W" + week,
          label: week + ": " + loop.startOf("week").format("DD/MM-YY") +
            " - " + loop.endOf("week").format("DD/MM-YY")
        })
        loop = loop.add(1, "week");
      }
      this.weekOptions = weeks;
      this.scrollToWeek();
    },
    selectWeek(yearAndWeek) {
      this.setDisplayDate(yearAndWeek);
      this.scrollToWeek();
      this.showWeekOptions = false;
      this.getData();
    },
    formatDateLong(date) {
      return this.moment(date).format("dddd [DEN] DD. MMMM YYYY");
    },
    formatDateShort(date) {
      return this.moment(date).format("dddd DD. MMMM");
    },

    getDisplayDates() {
      return this.displayDates;
    },

    getWorkHoursOnDay(displayDate, inst) {
      return inst.duties.filter(
        (d) => d.start_date === this.moment(displayDate).format("YYYY-MM-DD")
      );
    },
    getTitle(item) {
      let text = "Arbejdstid overlapper:";
      item.overlapping_duties.forEach((od) => {
        text += "  " + od.time;
      });
      return text;
    },

    getAbsenceOnDay(date, inst) {
      const obj = inst.absences.filter(
        (o) => o.date === this.moment(date).format("YYYY-MM-DD")
      );
      return obj;
    },

    getTotaleHoursPerDay(date, inst) {
      const obj = inst.dates_and_total_hours_per_day.find(
        (o) => o.date === this.moment(date).format("YYYY-MM-DD")
      );
      if (!obj) {
        return "0:00";
      } else if (obj.totale_hours_on_day === obj.totale_hours_incl_change_request) {
        return obj.totale_hours_on_day;
      } else {
        return obj.totale_hours_on_day + " (" + obj.totale_hours_incl_change_request + ")";
      }
    },
    showTitle(id) {
      if (this.showTitleId === id) {
        this.showTitleId = -1;
      } else {
        setTimeout(() => {
          this.showTitleId = id;
        }, 200);
      }
    },
    showUpdated() {
      this.showUpdatedTitle = !this.showUpdatedTitle;
      if (this.showUpdatedTitle) {
        setTimeout(() => {
          this.showUpdatedTitle = false;
        }, 1000);
      }
    },
    gotoToday() {
      setTimeout(() => {
        const date = this.moment().format("YYYYMMDD");
        const el = this.$el.querySelector("[ist-date='" + date + "']");
        if (el) {
          el.scrollIntoView({
            behavior: "smooth"
          });
        }
      }, 500);
    },
    scrollToWeek() {
      const yearAndWeek = this.moment(this.displayDate).year() + "-W" + this.moment(this.displayDate).week();
      setTimeout(() => {
        const el = this.$el.querySelector("[ist-week='" + yearAndWeek + "']");
        if (el) {
          el.style.fontWeight = "bold";
          el.scrollIntoView();
        }
      }, 10);
    },
    setDisplayDate(yearAndWeek) {
      const weekYearArr = yearAndWeek.split("-W");
      this.currentWeek = weekYearArr[1];
      this.displayDate = this.moment()
        .year(weekYearArr[0])
        .week(this.currentWeek)
        .day(1).toDate();
    },
    backWeek() {
      this.displayDate = this.addDays(this.displayDate, -7);
      this.getData();
    },
    forwardWeek() {
      this.displayDate = this.addDays(this.displayDate, 7);
      this.getData();
    },
    getData() {
      this.showWeekOptions = false;
      if (this.dates.find((d) => d.date === this.moment(this.displayDate).format("YYYY-MM-DD"))) {
        this.setWeekDates();
      } else {
        this.initData();
      }
    },
    addDays(date, days) {
      const result = new Date(date);
      result.setDate(result.getDate() + days);
      return result;
    },
    isMobile() {
      return document.body.clientWidth < 500 || this.isMobileApp;
    }

  },
  computed: {
    disableNextDay() {
      return this.displayDate > this.aYearAhead;
    },
    disablePreviousDay() {
      return this.displayDate < this.twoMonthsAgo;
    }
  },
  created: function () {
    this.moment.locale("da");
  },
  mounted: function () {
    this.getWeeks();
    this.medium = this.placement !== "narrow";
    this.aulaToken = this.getAulaToken();
    this.timer = setInterval(() => {
      this.aulaToken = this.getAulaToken();
    }, 60000 * 5);

  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  watch: {
    showWeekOptions: function () {
      if (this.showWeekOptions) {
        this.scrollToWeek()
      }
    },
    currentWeekNumber: function () {
      this.setDisplayDate(this.currentWeekNumber);
      this.initData();
    },
    aulaToken: function () {
      if (this.aulaToken && this.aulaToken !== this.localToken) {
        this.localToken = this.aulaToken;
        this.initData();
      }
    },
    placement: function () {
      this.medium = this.placement === "medium";
    },
    institutionFilter: function () {
      if (this.fravaersInfo && this.fravaersInfo.length) {
        this.fravaersInfo = this.fravaersInfo.filter((skole) =>
          this.institutionFilter.find((id) => skole.skoleId === id)
        );
      }
    }
  }
};
</script>

<style lang="scss">
.widget-W0116V0001 {
.widget-scroll {
  overflow: scroll;
  overflow-x: clip;
  max-height: 550px;
}

.list-table {
  margin: 10px 2px 2px 2px;
  min-width: 175px;
  overflow: hidden;
}

table {
  width: -webkit-fill-available;
}

.hours {
  padding-left: 10px;
  font-size: 13px;
}

.day-group {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: stretch;
  align-content: flex-start;
  background-color: #fff;
  margin-top: 5px;
  overflow: hidden;
  min-width: 175px;
}

.day-group .day-view-time-event {
  background: #d9e3e9;
  padding: 10px;
  border-top: 1px solid #f6f7f8;
  font-size: 13px;
  min-width: 50px;
  max-width: 50px;
  text-align: right;
  display: grid;
}

.widget-header {
  text-transform: uppercase;
  padding: 10px;
  font-size: 13px;
  color: #19425f;
  font-weight: 400;
  background: #d9e3e9;
}

.today {
  background: #19425f;
  color: #fff;
}

.day-view-title-event {
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 10px;
  font-size: 13px;
  margin: 0 auto 0 0;
}

.duration-clock {
  display: flex;
  justify-content: center;
}

.weekplan-medium {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.weekplan-narrow {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.title-div {
  background-color: rgb(217, 227, 233);
  border: solid 1px;
  padding: 5px;
  max-width: 180px;
  white-space: pre-line;
  min-width: fit-content;
}

.title-div-position {
  position: absolute;
  margin-top: 7px;
  margin-left: 120px;
}

.title-div-updated {
  position: absolute;
  background-color: rgb(217, 227, 233);
  border: solid 1px;
  padding: 5px;
  max-width: 200px;
}

.new-item {
  padding-bottom: 10px;
}

.mine-timer {
  float: right;
  text-align: right;
  display: flex;
}

.alert-icon {
  text-align: end;
  margin: -10px -7px -5px 0px;
  cursor: pointer;
}

.close-icon {
  text-align: end;
  width: 100%;
  margin: -2px 0px -20px 0px;
  cursor: pointer;
}

body, pre.reset-pre, table td, table th, div {
  font-family: Lato, sans-serif;
}

.weekpicker {
  display: grid;
  grid-template-columns: 100px auto 100px;
  width: 100%;
}

.ist-date {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ist-date-button button {
  width: 55px;
  height: 30px;
  color: white;
}

.date-forward {
  display: flex;
  direction: rtl;
}

.errormessage-chevron {
  margin-left: 10px;
}

.ist-errormessage {
  white-space: pre-line;
  margin-top: 10px;
}

.ist-header {
  display: flex;
  align-items: baseline;
  font-size: 13px;
  padding-top: 10px;
}

.ist-header div {
  -ms-flex: 1;
  /* IE 10 */
  flex: 1;
}

.ist-sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background: #eaeef1;
  padding-bottom: 5px;
  padding-top: 5px;
}

.ist-day {
  display: flex;
}

.ist-day div {
  -ms-flex: 1;
  /* IE 10 */
  flex: 1;
}

.nowrap {
  white-space: nowrap;
}

.nowrap h5 {
  margin-top: 7px;
}

.ist-mine-time-multiple {
  margin: 14px 0px 0px 5px !important;
}

.ist-weekoptions {
  border: solid;
  width: 210px;
  text-align: right;
  padding-right: 10px;
  max-height: 150px;
  position: absolute;
  overflow-x: hidden;
  overflow-y: scroll;
  background-color: white;
  top: 110px;
  margin-left: 155px;
  scroll-margin: 25px;
}

.ist-weekoptions div {
  cursor: pointer;
  margin-right: -10px;
  padding: 5px 10px 0px 5px;
}

.ist-weekoptions div:hover {
  background-color: #d9e3e9;
}

.ist-weekoptions-arrow {
  color: white;
  width: 20px;
  margin-left: 5px;
}

.ist-dtp {
  font-size: 13px;
  margin-top: -10px;
  margin-bottom: 10px;
  white-space: nowrap;
}

.mobile-bottom {
  margin-bottom: 50px;
}

.content-bottom {
  align-self: end;
}

.theme-employee .btn-link {
  color: #fff;
}

.absence {
  color: #B50050;
  font-weight: 900;
  padding-left: 10px;
  font-size: 13px;
}

linkbtnmultiple {
  padding-bottom: 10px;
}

linkbtn {
  padding-top: 10px;
}
}
</style>
