export default {
  /**
   *
   * @el {HTMLElement} element
   */
  // When the bound element is inserted into the DOM...
  inserted: function (el) {
    // Focus the element
    el.focus();
  },
};
