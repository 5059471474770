<script>

import Spinner from '../components/spinner'
import SelectedRecipients from '../components/selected-recipients'
import MessageForm from '../components/message-form'
import MessageValidationErrorsModal from '../components/message-validation-errors-modal'
import ConfirmSendMessageModal from '../components/confirm-send-message-modal'
import ConfirmBackModal from '../components/confirm-back-modal'

const NewGroupMessageScreen = {
    props: {
        isNewMessageConfirmModalOpen: Boolean,
        isNewMessageErrorModalOpen: Boolean,
        newMessageMessagesAmount: Number,
        selectedRecipients: Array,
        unSelectRecipient: Function,
        unSelectAllGroupType: Function,
        unSelectAllGroupTypeByClass: Function,
        selected: null,
        anySelectedRecipients: Boolean,
        newMessage: String,
        onMessageChange: Function,
        attemptSendMessage: Function,
        charactersInCurrentMessage: Number,
        isValidGsmMessage: Boolean,
        isNewMessageLoading: Boolean,
        newMessageLengthDescription: String,
        newMessageErrors: Array,
        selectedRecipientsReceivable: Array,
        selectedRecipientsWithoutPhone: Array,
        selectedRecipientsWithoutConsent: Array,
        selectedRecipientsNotReceivable: Array,
        closeNewMessageConfirmModal: Function,
        handleSendMessage: Function,
        handleMessageGroupNameChange: Function,
        newMessageGroupName: String,
        translations: Object,
        isConfirmBackModalOpen: Boolean,
        closeConfirmBackModal: Function,
        confirmGoBack: Function,
        isRemovedSelectedRecipientsNotEmpty: Boolean,
        undoRemoveSelectedRecipients: Function,
    },
    components: {
        'selected-recipients': SelectedRecipients,
        'message-form': MessageForm,
        'message-validation-errors-modal': MessageValidationErrorsModal,
        'confirm-send-message-modal': ConfirmSendMessageModal,
        'spinner': Spinner,
        'confirm-back-modal': ConfirmBackModal,
    },
}

export default NewGroupMessageScreen

</script>

<template>
    <div class="sending-container">
        <div class="modal-background" v-if="isNewMessageConfirmModalOpen">
            <confirm-send-message-modal
                @close-modal="closeNewMessageConfirmModal"
                @send-message="closeNewMessageConfirmModal(); handleSendMessage()"
                :messages-amount="newMessageMessagesAmount"
                :recipients-receivable='selectedRecipientsReceivable'
                :recipients-not-receivable='selectedRecipientsNotReceivable'
                :recipients-without-consent='selectedRecipientsWithoutConsent'
                :recipients-without-phone='selectedRecipientsWithoutPhone'
                :translations='translations'
            >
            </confirm-send-message-modal>
        </div>
        <div class="modal-background" v-if="isConfirmBackModalOpen">
            <confirm-back-modal
                :on-close="closeConfirmBackModal"
                :on-confirm="confirmGoBack"
                :translations='translations'
            >
            </confirm-back-modal>
        </div>
        <div class="modal-background" v-if="isNewMessageErrorModalOpen">
            <message-validation-errors-modal
                :errors='newMessageErrors'
                :translations="translations"
                :on-close="() => $emit('close-new-message-error-modal')"
            >
            </message-validation-errors-modal>
        </div>
        <div class="header">
            <div class="header-heading">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" class="header-icon left pointer"
                     @click="$emit('handle-close')">
                    <path d="M20.393 5.333l0.94 0.94-8.78 9.727 8.78 9.727-0.94 0.94-9.727-10.667 9.727-10.667z" />
                </svg>
                <h3 class="header-title">{{ translations.newMessageScreen.headerTitle }}</h3>
            </div>
        </div>
        <div class="not-selecting main-screen-content scrollable">
            <div class="new-message-container">
                <div class='new-message-container-grow'>
                    <div class="new-message-heading">
                        <h3 class='new-message-heading-text'>{{ translations.newMessageScreen.groupNameHeading }}</h3>
                    </div>
                    <input class='new-message-group-name-input'
                           type='text'
                           :value='newMessageGroupName'
                           @input="e => this.handleMessageGroupNameChange(e.target.value)"
                    />
                    <div class="new-message-heading pointer" @click="$emit('open-select-recipients-screen')">
                        <h3 class='new-message-heading-text'>{{ translations.newMessageScreen.selectRecipientsHeading }}</h3>
                        <div class='new-message-heading-icon-container'>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" class="new-message-heading-icon">
                                <path d="M28 15.333h-11.333v-11.333h-1.333v11.333h-11.333v1.333h11.333v11.333h1.333v-11.333h11.333v-1.333z" />
                            </svg>
                        </div>
                    </div>
                    <selected-recipients
                        v-if='anySelectedRecipients'
                        :translations='translations'
                        :visible-without-any-selections='true'
                        :selected="selected"
                        :selected-recipients='selectedRecipients'
                        :un-select-recipient='unSelectRecipient'
                        :un-select-all-group-type='unSelectAllGroupType'
                        :un-select-all-group-type-by-class='unSelectAllGroupTypeByClass'
                        @unselect-recipient="$emit('unselect-recipient', recipient)"
                        @unselect-all-recipients="$emit('unselect-all-recipients')"
                    >
                    </selected-recipients>
                </div>
                <div v-if="isNewMessageLoading" class='new-message-loading-backdrop'>
                    <spinner></spinner>
                </div>
                <div>
                    <message-form
                        :message="newMessage"
                        :on-message-change='onMessageChange'
                        :on-submit="attemptSendMessage"
                        :message-length-description="newMessageLengthDescription"
                        :new-message-messages-amount="newMessageMessagesAmount"
                        :characters-in-current-message="charactersInCurrentMessage"
                        :is-valid-gsm-message='isValidGsmMessage'
                        :translations="translations"
                    ></message-form>
                </div>
            </div>
        </div>
        <div class="undo-remove" v-if="isRemovedSelectedRecipientsNotEmpty" @click="undoRemoveSelectedRecipients">
            <p class="undo-text">{{ translations.selectRecipientsScreen.peopleRemoved }}</p>
            <div class="undo-button pointer">{{ translations.selectRecipientsScreen.undo }}</div>
        </div>
    </div>
</template>