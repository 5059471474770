<template>
  <div class="minFormDivCSS">
    <form
      ref="kalendaForm"
      action="https://secure.kalenda.dk/auth/realms/Kalenda/protocol/openid-connect/auth/"
      method="post"
      target="_blank"
      v-if="tokenLoaded"
    >
      <input type="hidden" name="response_type" value="code" />
      <input type="hidden" name="response_mode" value="fragment" />
      <input
        type="hidden"
        name="redirect_uri"
        value="https://login.kalenda.dk/Kalenda/"
      />
      <input type="hidden" name="scope" value="openid" />
      <input type="hidden" name="client_id" value="Kalenda" />
      <input type="hidden" name="assuranceLevel" :value="assuranceLevel" />
      <input type="hidden" name="sessionUUID" :value="sessionUUID" />
      <input type="hidden" name="isMobileApp" :value="isMobileApp" />
      <input type="hidden" name="userProfile" :value="userProfile" />
    </form>
    <img
      @click="submit"
      src="https://img.kalenda.dk/aula/Kalenda_cmyk_uden_payoff.svg"
      class="center"
      alt="Kalenda - Tid til det vigtige. Skemalægningsprogram"
    />
  </div>
</template>

<script>
  module.exports = {
    props: {
      getAulaToken: Function,
      axios: Function,
      userProfile: String,
      isMobileApp: Boolean,
      sessionUUID: String,
      assuranceLevel: Number,
      moment: Function,
    },
    data: function () {
      return {
        aulaToken: null,
        tokenLoaded: false,
      };
    },
    mounted() {
      this.aulaToken = this.getAulaToken();
    },
    watch: {
      aulaToken: function () {
        if (this.aulaToken != undefined) {
          this.tokenLoaded = true;
          localStorage.setItem(
            "token_expires",
            this.moment().add(5, "m").format("YYYY-MM-DD HH:mm:ss")
          );
        }
      },
    },
    methods: {
      submit: function () {
        const expire = this.moment(
          localStorage.getItem("token_expires"),
          "YYYY-MM-DD HH:mm:ss"
        );

        if (this.moment().isAfter(expire)) {
          this.tokenLoaded = false;
          this.aulaToken = undefined;
          this.aulaToken = this.getAulaToken();
          setTimeout(this.submit, 200);
        } else {
          var elem = document.createElement("input");
          elem.setAttribute("value", this.aulaToken);
          elem.setAttribute("name", "aulaToken");
          elem.setAttribute("type", "hidden");
          var submitForm = this.$refs.kalendaForm;
          submitForm.appendChild(elem);
          submitForm.submit();
          submitForm.removeChild(elem);
        }
      },
    },
  };
</script>
<style scoped>
  .minFormDivCSS {
    margin-top: 10px;
    height: 70px;
    line-height: 70px;
    padding: 0 25px;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    font-size: 15px;
    background: #fff;
  }
  .center {
    display: block;
    padding-top: 2%;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }
</style>
