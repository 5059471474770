<template>
  <div
    :key="section"
    class="main-section">
    <section v-if="loading">
      <div
        class="infoba-header"
        style="background-color: #888;">
        <div style="display:flex">
          <i class="fal fa-home" />
          <span
            class="header-link"
            @click="goto(headerLink)">INFOBA</span>
        </div>
        <img
          :src="domain + '/Images/logowhite.svg'"
          class="infoba-logo">
      </div>
      <div
        class="infoba-quick-access-item "
        style="text-align:center; padding:20px !important">
        <div class="material-icons icon-rotator infoba-rotate" />
      </div>
    </section>
    <section v-else-if="section!='default' ">
      <div
        class="infoba-header"
        style="background-color: #888;">
        <div style="display:flex">
          <i class="fal fa-home" />
          <span
            class="header-link"
            @click="goto(headerLink)">INFOBA</span>
        </div>
        <img
          :src="domain + '/Images/logowhite.svg'"
          class="infoba-logo">
      </div>
      <div class="infoba-quick-access-item">
        <widget-html :html="message" />
      </div>
    </section>
    <section v-else>
      <div
        class="infoba-header"
        style="background-color: #425D82;">
        <div style="display:flex">
          <div class="icon-bookshelf" />
          <div
            class="header-link"
            @click="goto(headerLinkC.url)">
            {{ item.Binder.Tile.Header }}
          </div>
        </div>
        <img
          :src="domain + '/Images/logowhite.svg'"
          class="infoba-logo">
      </div>
      <div class="wrapper">
        <div style="height:auto">
          <template v-for="topic in item.Binder.Tile.LearningLibraryTile.ChildrenList">
            <div
              class="infoba-accordeon-row"
              style="cursor:pointer;"
              :style="{paddingTop:'0px', marginBottom: '7px', borderLeft:'4px solid ' + topic.Color}"
              @click="goto(domain + '/IN/LearningLibrary/Content/' + topic.LearningLibraryId + '?pid=' + topic.ParentId + '&fvid=' + topic.FVId)">
              <div style="padding: 16px">
                <p class="infoba-gray">
                  {{ moment(topic.PublishedDate).format('DD.MMM YYYY') }}
                </p>
                <p class="topicheader">
                  {{ topic.HeaderText }}
                </p>
              </div>
            </div>
          </template>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
var domain = 'https://www2.infoba.dk';
var domainOld = 'https://www.infoba.dk';

export default {
  props: {
    /* normal and secure */
    axios: Function,
    sessionUUID: String,
    placement: String,
    currentWeekNumber: String,
    isMobileApp: Boolean,
    userProfile: String,
    /* only secure */
    moment: Function,
    assuranceLevel: Number,
    getAulaToken: Function,
    childFilter: Array,
    institutionFilter: Array,
    group: String,
  },
  data: function () {
    return {
      loading: true,
      section: "default",
      message: "",
      timer: "",
      headerLink: domain,
      who: 'Aula widget developers',
      date: this.moment().format('DD-MM-YYYY'),
      aulaToken: null,
      domain: domain,
      domainOld: domainOld,
      fields: {},
      item: { Binder: { Tile: {} } },
      headers: []
    }
  },
  methods: {
    getData: function () {
      var uuid = this.sessionUUID;
      this.axios.defaults.headers.common["Authorization"] = this.aulaToken;
      this.axios.defaults.headers.common["AuthorizationContext"] = "INTile";

      if (this.childFilter != null)
        this.axios.defaults.headers.common["childFilter"] = this.childFilter.join();
      if (this.institutionFilter != null)
        this.axios.defaults.headers.common["institutionFilter"] = this.institutionFilter.join();
      if (this.group != null)
        this.axios.defaults.headers.common["groupFilter"] = this.group;
      if (this.userProfile != null)
        this.axios.defaults.headers.common["userProfile"] = this.userProfile;
      if (this.currentWeekNumber != null)
        this.axios.defaults.headers.common["currentWeekNumber"] = this.currentWeekNumber;
      if (this.isMobileApp != null)
        this.axios.defaults.headers.common["isMobileApp"] = this.isMobileApp;

      this.axios.get(domain + "/api/Aula/INTileGet/200", {
        withCredentials: true,
        credentials: "same-origin",
      })
        .then((response) => {
          this.moment.locale('da');
          this.item = response.data;
          handleStatus(this);
          if (this.item.Binder.Tile.LearningLibraryTile == null) {
            if (this.item.Binder.Tile.LearningLibraryTile.ChildrenList.length == 0) {
              this.section = "nodata";
              this.message = "Vi har ikke noget relevant data at vise dig.";
            }
          }
        })
        .catch((error) => {
          handleStatus(this);
        })
        .finally(() => (this.loading = false));
    },
    goto: function (redirectUrl) {
      let serial = this.item.INFOBAserial;
      let token = this.item.INFOBAtoken;
      let url = "";
      //if logged in
      if (serial === null || token === null) {
        let aulaReferrer = '?referrer=Aula';
        if (redirectUrl.indexOf('?') !== -1) {
          aulaReferrer = '&referrer=Aula';
        }
        url = redirectUrl + aulaReferrer;
      }
      //if not logged in
      else {
        url = domain + "/Login/Index?serial=" + serial + "&token=" + token + "&referrer=Aula&returnUrl=" + encodeURIComponent(redirectUrl);
      }
      var a = document.createElement('a');
      a.href = url;
      a.target = "INFOBA";
      a.click();
    }
  },
  computed: {
    headerLinkC: function () {
      if (this.item.Binder.Tile.Url == undefined || this.item.Binder.Tile.Url.length == 0)
        return { url: "", style: "" };
      else
        return { url: this.item.Binder.Tile.Url, style: "cursor:pointer;" };
    },
  },
  filters: {
    firstLetter: function (value) {
      return value.charAt(0);
    }
  },
  created: function () {
    this.aulaToken = this.getAulaToken();
    this.moment.locale('da');
  },
  watch: {
    aulaToken: function () {
      if (this.aulaToken !== undefined) {
        var t = this;
        t.getData();
      }
    },
  }
}

function handleStatus(t) {
  clearTimeout(t.timer)
  if (t.item && t.item.Status == 200) {
    if (t.item.Binder.Tile == undefined) {
      t.section = "error"
      t.message = 'Din bruger har ikke adgang til denne widget i INFOBA. Kontakt <a href="https://web.infoba.dk/support/" class="infoba-link">support</a>.'
    }
    else {
      t.section = "default"
    }
  }
  else if (t.item && t.item.Status == 401) {
    if (t.item.Message == "Invalid role" || t.item.Message == "Module payment missing") {
      t.section = "error"
      t.message = 'Din bruger har ikke adgang til denne widget i INFOBA. Kontakt <a href="https://web.infoba.dk/support/" class="infoba-link">support</a>.'
    }
    else {
      t.section = "login";
      t.message = 'For at se data fra INFOBA, skal dine konti f&oslash;rst sammenk&aeligdes. <div><a href="' + domain + "/IN/Personel/MatchAula/" + t.item.Username + '" class="infoba-link">Start sammenk&aeligdning <i class="material-icons icon-arrowright-circle" style="vertical-align: middle; margin-top: 2px; color:#96be2c"></i></a></div>';
      t.timer = setTimeout(function () { t.getData() }.bind(t), 5000);
      t.headerLink = domain + "/IN/Personel/MatchAula/" + t.item.Username;
    }
  }
  else {
    t.section = "error"
    t.message = 'Undskyld. Det er i &oslash;jeblikket ikke muligt at vise dig den &oslash;nskede information. Vi h&aring;ber, du vil pr&oslash;ve igen senere.'
  }
}
</script>
<style scoped>
.main-section {
  border: 2px solid white;
  background-color: #FAFAFA;
}

.wrapper {
  margin: 20px 20px 10px 20px;
}

div.icon-rotator {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QAAAAAAAD5Q7t/AAAACXBIWXMAAA7EAAAOxAGVKw4bAAAD5UlEQVRo3u2ZT4gbdRTHv++XJUzwT6HuoUrB0nhQKR6kerG9eCgL1l3FdT1ZpLB4kL1Edmf29rtlJpsuSPEgQvdiD6ZQdS1ioTf/gFUQ24KSS3qQIloKVXGSyWa+Hjq7JNNkMzOZcT3M9zZv5v3m98n7vfd+8wuQK1euXLlyJZekPaDW+mHDMI6TPCIijwPYB2ALwB2SvwH4rtPp/KC1/vt/B6K1njIMYx7AaQAvAiiMcekC+ALARrvd/lxr7e85iOM48yQdAIeT+JO8JiIrlmVdnmQeU0kd19fX93ued47k3CQTEJEnRaQ0yRhAwohUq9UnROQyRkdhC8BVki0R+V1EPJLTAJ4C8CwAI3jOE5E3TNP89D8HCSC+AnBgyO2fAKwXi8VLlUrlzjB/rfWDhmHMAlgSEScNiNggwXL6HvdH4raIVFzXPZ9G4iZRrBzxPO9cGEJEbiilZpeXl1t7AbAtFfVBx3HmAQwktojccF33hawgHMc5mCqI1noqKLH9uq2UmtVa/5kFRLVanSHZtG37ndRAgmYXXlKVrCJRrVZnROQTACUAZ6PARF1ap/svSF5zXfd8RhAnAojtEi0BzNJEILZt78O9bceORORMFtUpiMRnfRA7rwTw3m6RiRKRYxjcO20Vi8VLGUH0RyKs7cgMhRkLQvJIyHR1VLPLEGIszFgQpdShkOnmHkHsCjMWxPf9h0KmWylCnIgJEYZZ6jcAAGzbXgTwAZJtJD2l1GsrKyup5I5t22cAVPpMH1mW9eZuPjsRsSzrQwBLABjzvW2Sc2lBBHqs/4Lk2KY7sLQsy3o/Jkyb5Kurq6tfpggBAIdC1zdjgcSEyQSiXq9PA3guZL4eGyQiTFaRQK/XexmDfaurlPomEUgfzNtDYDyl1OtZQDQajQIGkxwArpim+VdikAAmXACySOwdtVqtU+EGTHIjiu/YPtK3zNyslhMA1Gq1Msl6yNwsl8sXo/hH7hmO4xw0TfPXLCCCjem3AJ4emJzInGmam6mCZKVarVb2fX9zCMQF0zQXoo6T+FxrUjUajUKr1Trl+34dwP7Q7SbJxTjjpRYRx3FeIWmJyFkAm6MqTb1en+52uyeVUu8O2VkDwK1CoXAs7tdnKiABxMcAioGpLSI/kvxZRP4gWRSRAyQPAziK0WfDzUKhMJPkEzqNs98wRCKJyAWSi5Zl3U3iP3GO+L7/D4BfROSZhEM0RWQ5anUa+UNMCgIAWmtVKpVOknwLwEsYH50ugCskN8rl8sWFhYXepHNIvfyura09QPJor9d7XkQeBfAIyQKAu7i3i73e6XS+TvuPnly5cuXKlWsS/QvhKq1sLYByvgAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAyMC0xMC0wNlQxMTozNjo1NSswMDowMPTXOyYAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMjAtMTAtMDZUMTE6MzY6NTUrMDA6MDCFioOaAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAABJRU5ErkJggg==) no-repeat center center;
  padding: 25px 25px 25px 25px;
  display: inline-block;
}

div.icon-bookshelf {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QAAAAAAAD5Q7t/AAAACXBIWXMAAABgAAAAYADwa0LPAAAA00lEQVRIx+2VQQ4BQRBFf7HiAOIGs5gjuIHIiMtYz5VYWNlzAYkjiD02eDZNyoQYPW3nr6p/urrq/+p0S398gNXZBPBIMKuVc0fr246AHCiBDZBFycKhuga2Lp4mVyBp5uLiFwoGLr4A/dQKVpJ2Ln+YtICZXSUtHDVOalFYF446At3UBTrAwdFvhx0zA5nZSdKyjk1RBQL8dR0B7WQWBa4HnP31TarAzPaS1o56OYcmFknS3MWTpBYFPguPXgnkDZuNw9PbXu0w+lD3ZzSdwR/NcQOfhhkFqmnKWgAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAyMS0wNC0wOFQwODoxODoxOCswMDowMPbQkj8AAAAldEVYdGRhdGU6bW9kaWZ5ADIwMjEtMDQtMDhUMDg6MTg6MTgrMDA6MDCHjSqDAAAAAElFTkSuQmCC) no-repeat center center;
  padding: 35px 0 0 35px;
  background-size: 20px;
  display: inline-block;
}

.icon-status-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.infoba-header {
  color: #fff;
  padding: 4px;
  padding-left: 8px;
  padding-right: 10px;
  display: flex;
  justify-content: space-between;
  align-content: center;
  background: #873C79;
  position: relative;
}

.theme-circle {
  width: 20px;
  color: #fff;
  display: inline-block;
  margin-right: 5px;
  height: 20px;
  margin-top: 12px;
  text-align: center;
  line-height: 20px;
  border-radius: 10px;
  background-color: #873C79;
  margin-left: 10px;
}

.header-link {
  cursor: pointer;
  margin-left: 5px;
  line-height: 35px;
  font-weight: bold;
}

.infoba-header:first-child {
  margin-top: 0
}

.infoba-header i {
  position: absolute;
  top: 2px;
  line-height: 35px;
  font-size: 24px !important
}

.infoba-header span {
  position: absolute;
  left: 34px;
  line-height: 35px;
  color: #fff;
  cursor: pointer;
  top: 5px
}

.quick-access-item {
  position: relative
}

.infoba-quick-access-item {
  padding: 20px 35px 20px 35px;
  cursor: auto;
  height: auto
}

.infoba-accordeon-header {
  background-color: #FFFFFF;
  line-height: 46px;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  height: 46px;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  -ms-text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis
}

.infoba-accordeon-row {
  background-color: #FFFFFF;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  justify-content: space-between;
  padding-top: 5px;
}

.rotate {
  -moz-transition: all .3s linear;
  -webkit-transition: all .3s linear;
  transition: all .3s linear
}

.rotate.up {
  -moz-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg)
}

.rotate.right {
  -moz-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg)
}

.infoba-rotate {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite
}

@-webkit-keyframes rotating {
  from {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg)
  }

  to {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg)
  }
}

@keyframes rotating {
  from {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg)
  }

  to {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg)
  }
}

.slide-enter-active {
  -moz-transition-duration: .3s;
  -webkit-transition-duration: .3s;
  -o-transition-duration: .3s;
  transition-duration: .3s;
  -moz-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in
}

.slide-leave-active {
  -moz-transition-duration: .3s;
  -webkit-transition-duration: .3s;
  -o-transition-duration: .3s;
  transition-duration: .3s;
  -moz-transition-timing-function: cubic-bezier(0,1,.5,1);
  -webkit-transition-timing-function: cubic-bezier(0,1,.5,1);
  -o-transition-timing-function: cubic-bezier(0,1,.5,1);
  transition-timing-function: cubic-bezier(0,1,.5,1)
}

.slide-enter-to, .slide-leave {
  max-height: 100px;
  overflow: hidden
}

.slide-enter, .slide-leave-to {
  overflow: hidden;
  max-height: 0
}

.infoba-logo {
  height: 35px
}

.infoba-gray {
  color: #888
}

.infoba-accordeon-row p {
  margin-bottom: 0;
}

.topicheader {
  font-weight: bold;
}
</style>
