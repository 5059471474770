<template>
  <div class="widget">
    <h2 class="widget-title">Ugens Meebook forløb</h2>
    <div v-if="loading" class="widget-loader">
      <i class="fa fa-spinner"></i>
      Loading...
    </div>
    <div v-if="hasErrors">
      <ul class="errors">
        <li v-for="error in errors" v-bind:key="error" class="errors__error">{{ error }}</li>
      </ul>
    </div>
    <div v-if="!loading">
      <ul v-if="items && items.length" class="widget">
        <li v-for="item in items" v-bind:key="item.id" class="widget__item">
          <span class="widget__item__name">{{ item.name }}</span>

          <div v-if="item.books && item.books.length > 0">
            <ul class="widgetlist">
              <li
                v-for="book in item.books.slice(0, maxShown)"
                v-bind:key="book.id"
                class="widgetlist__item"
                v-on:click="submitLink(widgetIdentifier + 'book' + book.id + item.unilogin)"
                v-on:keypress="(e) => activateItem(e, 'book' + book.id + item.unilogin)"
                :style="getBookIcon()"
                tabindex="0"
                role="button"
                :title="`Se ${book.title} (${book.category}) i Meebook`"
              >
                <form
                  v-bind:action="getApiBaseUrl() + 'relatedweeklybooklinkauth'"
                  method="post"
                  target="_blank"
                  v-bind:ref="widgetIdentifier + 'book' + book.id + item.unilogin"
                >
                  <input type="hidden" name="bookId" v-bind:value="book.id"/>
                  <input type="hidden" name="childFilter[]" v-bind:value="[item.unilogin]"/>
                  <input type="hidden" name="sessionUUID" v-bind:value="sessionUUID"/>
                  <input type="hidden" :id="`aulaJWT${widgetIdentifier + 'book' + book.id + item.unilogin}`"
                         name="aulaJWT" value=""/>
                  <input type="hidden" name="userProfile" v-bind:value="userProfile"/>
                  <input type="hidden" name="currentWeekNumber" v-bind:value="getThisYearWeek()"/>
                </form>

                <div class="widgetlist__item__title">
                  <span>{{ book.title }}</span>
                </div>
                <div class="widgetlist__item__subtitle">
                  <span>{{ book.category }}</span>
                </div>
              </li>
            </ul>
            <button
              v-if="item.books.length > 3"
              class="widgetlist__showall"
              v-on:click="toggleShowAll"
            >
              {{ showAll ? 'Se færre' : 'Se alle' }}
            </button>
          </div>
          <div v-else>
            <p>Ingen forløb i denne uge</p>
          </div>
        </li>
      </ul>
      <div v-else-if="!hasErrors">
        <p>Ingen forløb i denne uge</p>
      </div>
    </div>
  </div>
</template>

<script>
module.exports = {
  props: {
    axios: Function,
    moment: Function,
    getAulaToken: Function,
    sessionUUID: String,
    placement: String,
    currentWeekNumber: String,
    isMobileApp: Boolean,
    assuranceLevel: Number,
    userProfile: String,
    childFilter: [String, Array],
    institutionFilter: [String, Array],
    group: String,
    meebookImgBaseUrl: {
      type: String,
      default: 'https://app.meebook.com/images/aula/',
    },
    widgetIdentifier: {
      type: String,
      default: 'relatedWeeklyBooks-',
    },
    meebookApiBaseUrl: String,
    meebookApiTestData: String,
  },
  computed: {
    maxShown() {
      return this.showAll ? Infinity : 3;
    },
    hasErrors() {
      return this.errors && this.errors.length;
    },
  },
  methods: {
    getThisYearWeek() {
      this.moment.locale('da');
      return this.moment().format(this.moment.HTML5_FMT.WEEK);
    },
    getClient() {
      return this.axios.create({
        baseURL: this.getApiBaseUrl(),
        timeout: 30000,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + this.aulaToken,
          'sessionUUID': this.sessionUUID,
          'X-Version': '1.0',
        }
      });
    },
    getApiBaseUrl() {
// production url (defined here because there wil not be an API_BASE_URL on aula live)
      let url = 'https://app.meebook.com/aulaapi/';
// if dev server is set, use that
      if (this.meebookApiBaseUrl !== undefined) {
// could be localhost (or other local vhost) or development server
        url = this.meebookApiBaseUrl;
      }

      return url;
    },
    activateItem(e, index) {
      if (e.code === 'Enter' || e.code === 'Space') {
        this.submitLink(index);
      }
    },
    submitLink(index) {
      let aulaJwtInputElement = document.getElementById('aulaJWT' + index);
// insert jwt token value into form
      aulaJwtInputElement.value = this.aulaToken;
// send form
      this.$refs[index][0].submit();
// reset aula jwt token input value to null
      aulaJwtInputElement.value = null;
    },
    getTestData() {
// shold only be used when building html. Not "real" data
      this.loading = false;
      return JSON.parse(this.meebookApiTestData);
    },
    getBookIcon() {
      return `background-image: url(${this.meebookImgBaseUrl}meebook-2x.png)`;
    },
    toggleShowAll() {
      this.showAll = !this.showAll;
    },
    loadContent() {
// if we are just testing layout/styles we can use static test data
      if (this.meebookApiTestData !== undefined) {
        this.items = this.getTestData();
        return;
      }

      if (!this.childFilter || this.childFilter.length === 0) {
        this.errors.push('Vælg en eller flere elever i Aula top-menu for at se data');
        this.loading = false;
      }

// call aula api for dynamic data
      let http = this.getClient();
      http.get(
        'relatedweeklybook/all',
        {
          params: {
            currentWeekNumber: this.getThisYearWeek(),
            userProfile: this.userProfile,
            childFilter: this.childFilter,
            institutionFilter: this.institutionFilter,
          }
        }
      ).then((response) => {
        this.loading = false;
        this.items = response.data || [];
      }).catch((error) => {
        if (error.response.status === 401) {
          // Aula Token expired
          this.aulaTokenExpired = true;
          this.loading = true;
          this.aulaToken = this.getAulaToken();
          this.errors.push('Din Aula-token fornyes.. Øjeblik');
        } else {
          this.loading = false;
          if (error.response.data.exceptionMessage) {
            this.errors.push(error.response.data.exceptionMessage);
          } else {
            this.errors.push('Ugens forløb kunne ikke hentes.');
          }
        }
      });
    }
  },
  data: () => ({
    loading: true,
    items: '',
    errors: [],
    showAll: false,
    aulaToken: null,
    aulaTokenExpired: null
  }),
  mounted() {
    this.aulaToken = this.getAulaToken();
  },
  watch: {
    aulaToken: function () {
      if (this.aulaToken !== undefined && !this.aulaTokenExpired) {
        this.loadContent();
      } else if (this.aulaTokenExpired) {
        this.aulaTokenExpired = false;
        this.errors = [];
        this.loadContent();
      }
    },
    childFilter: function () {
      if (this.aulaToken && !this.aulaTokenExpired) {
        this.loadContent();
      }
    }
  }
}
</script>

<style scoped>
.widget-loader {
  font-family: "Lato", "Avenir", Helvetica, Arial, sans-serif;
  font-size: 16px;
  margin: 20px 0
}

.widget-loader > .fa {
  animation-name: spin;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-fill-mode: both;
  margin-right: 10px
}

@keyframes spin {
  0% {
    transform: rotate(0)
  }
  100% {
    transform: rotate(360deg)
  }
}

.errors {
  margin-top: 20px
}

.errors__error {
  display: block;
  padding: 15px 20px;
  background-color: #fff;
  color: #b50050
}

* {
  box-sizing: border-box
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none
}

li {
  margin: 0;
  width: 100%
}

.widget-title {
  display: block;
  text-align: left;
  font-family: "Lato", "Avenir", Helvetica, Arial, sans-serif;
  font-size: 17px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 0;
  margin-top: 30px
}

.widget {
  text-align: left
}

.widget__item__name {
  display: block;
  font-size: 14px;
  font-weight: 700;
  text-transform: capitalize;
  color: #5d6c79;
  margin-top: 30px;
  margin-bottom: 10px
}

.widgetlist {
  text-align: left
}

.widgetlist__item {
  position: relative;
  padding: 15px 20px;
  background-color: #fff;
  margin-bottom: 5px;
  cursor: pointer;
  outline: 0
}

.widgetlist__item:focus {
  background-color: rgba(255, 255, 255, .4)
}

.widgetlist__item__title {
  font-size: 16px;
  font-weight: 700
}

.widgetlist__item__subtitle {
  font-size: 11px;
  font-weight: 700;
  color: #6a7681;
  text-transform: capitalize
}

.widgetlist__item__subtitle::first-letter {
  text-transform: uppercase
}

.widgetlist__showall {
  display: block;
  padding: 0;
  margin-top: 5px;
  font-size: 14px;
  font-weight: 700;
  text-decoration: underline;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
  border: 0;
  outline: none
}

.widgetlist__showall:focus {
  opacity: .5
}

.widgetlist__item {
  padding: 15px 20px 15px 70px;
  background-repeat: no-repeat;
  background-position: -5px center;
  background-size: 70px
}
</style>
