// POSTS
export const NO_POSTS = 'Der er desværre ingen opslag';
export const NO_POSTS_EMPLOYEES = 'Der er ingen opslag fra medarbejdere';
export const NO_POSTS_MARKED = 'Der er ingen opslag, der er markerede som vigtige';
export const NO_POSTS_UNREAD = 'Der er ingen ulæste opslag';
export const NO_POSTS_PARENTS = 'Der er ingen opslag fra forældre';
export const NO_POSTS_STUDENTS = 'Der er ingen opslag fra elever';
export const NO_POSTS_OWNPOST = 'Der er ingen opslag oprettet af dig';
export const POSTS_PAGETITLE = 'Overblik';
export const POSTS_FILTER_BUTTON = 'Filtrer';
export const POSTS_FILTER_ALL = 'Alle';
export const POSTS_FILTER_MARKED = 'Vigtige';
export const POSTS_FILTER_OWN = 'Egne opslag';
export const POSTS_FILTER_UNREAD = 'Ulæste';
export const POSTS_FILTER_EMPLOYEES = 'Pædagogisk Personale';
export const POSTS_FILTER_OTHER_EMPLOYEES = 'Andre medarbejdere';
export const POSTS_FILTER_PARENTS = 'Forældre';
export const POSTS_FILTER_OTHER_PARENTS = 'Andre forældre';
export const POSTS_FILTER_STUDENTS = 'Elever';
export const POSTS_FILTER_OTHER_STUDENTS = 'Andre elever';
export const POSTS_CREATE = 'Opret';
export const POSTS_NEW = 'NY';
export const POSTS_NEW_DRAWER_TITLE = 'Nyt opslag';
export const POSTS_EDIT_DRAWER_TITLE = 'Rediger opslag';
export const POSTS_SEND_BTN = 'Gem';
export const POSTS_EDITED = '(redigeret {editedDate})';
export const POSTS_CREATE_NEW_POST = 'Opret ny post';
export const POSTS_CREATE_HIGH_PRIORITY = 'Marker som vigtig';
export const POSTS_CREATE_ENABLE_COMMENTS = 'Tillad kommentarer';
export const POSTS_CREATE_LABEL_HEADING = 'OPRET OPSLAG';
export const POSTS_EDIT_LABEL_HEADING = 'REDIGER OPSLAG';
export const POSTS_CREATE_DATE_FROM = 'Vigtig fra';
export const POSTS_CREATE_DATE_TO = 'Vigtig til';
export const POSTS_CREATE_LABEL_TITLE = 'EMNE';
export const POSTS_CREATE_LABEL_CONTENTS = 'TEKST';
export const POSTS_CREATE_LABEL_MEETING = 'Samtale';
export const POSTS_CREATE_LABEL_TO = 'Til';
export const POSTS_CREATE_TITLE_INPUT_PLACEHOLDER = 'Skriv emne';
export const POSTS_CREATE_LABEL_INSTITUTION = 'Fra';
export const POST_CREATE_CONTENT_WARNING_POST =
  'Du har den blokerede kommunikationsrolle og har derfor ikke tilladelse til kommunikation i Aula. Kontakt din administrator, hvis du mener, dette er en fejl.';
export const POST_CREATE_TITLE_WARNING = 'ADVARSEL';
export const POST_CREATE_TITLE_WARNING_CONTENT =
  'Kommunikationskanalen er blokeret. Modtagerne vil ikke se denne post.';
export const POST_NOT_FOUND =
  'Vi kunne desværre ikke finde dit opslag. Det er muligvis blevet slettet eller ikke længere delt med dig.';
export const POST_MANDATORY_CONTENT = 'Du mangler at angive en tekst';
export const POST_CONTENT_TOO_LONG = 'Teksten må ikke være mere end 6600 tegn lang';
export const POST_MANDATORY_TITLE = 'Du mangler at angive et emne';
export const POST_MANDATORY_RECEIVERS = 'Du mangler at angive en modtager';
export const POST_ADMIN_CAN_SEE_POST = 'Alle gruppeadministratorer i gruppen vil også kunne se opslaget';
export const POST_DISABLE_COMMENTS_WARNING =
  'Vær opmærksom på, at hvis du slår kommentarer fra, vil kommentarer der allerede er oprettet, blive skjult.';
export const POST_NO_PERMISSION = 'Ingen tilladelse til at skrive opslag';

export const POSTS_MENU_LABEL_HOME = 'Opslag';
export const POSTS_START_DATE_WARNING = 'Indtast startdatoen';
export const POSTS_START_DATE_INVALID_WARNING = 'Startdato kan ikke være i fortiden';
export const POSTS_END_DATE_INVALID_WARNING = 'Slutdato er ugyldig';
export const POSTS_MENU_LABEL_CALENDARPOST = 'Begivenhed';
export const POSTS_MENU_LABEL_PARENT_MEETING_POST = 'Samtale';
export const POSTS_MENU_LABEL_NEW_POST = 'Opslag';
export const POSTS_MENU_LABEL_NEW_MESSAGE = 'Besked';
export const POSTS_MENU_LABEL_ALBUM = 'Album';
export const POSTS_MENU_LABEL_EDIT_POST = 'Rediger opslag';
export const POSTS_MENU_LABEL_DELETE_POST = 'Slet';
export const POSTS_MENU_LABEL_SECURE_FILE_SHARING = 'Dokument';
export const POSTS_MENU_LABEL_SECURE_FILE_NOTE = 'Note';
export const POSTS_MENU_LABEL_CREATE_POST = 'Opret opslag';
export const POSTS_MENU_LABEL_CREATE_CALENDARPOST = 'Opret begivenhed';
export const POSTS_MENU_LABEL_CREATE_PARENT_MEETING_POST = 'Opret samtale';
export const POSTS_MENU_LABEL_CREATE_MESSAGE = 'Opret besked';
export const POSTS_MENU_LABEL_CREATE_ALBUM = 'Opret album';

export const POSTS_TAB_OVERVIEW = 'OVERBLIK';
export const POSTS_TAB_MYDAY = 'MIN DAG';
export const POSTS_TAB_BIRTHDAY = 'FØDSELSDAGE';
export const POSTS_TAB_PLANNING = 'VIGTIGE DATOER';
export const POSTS_TAB_WIDGETS = 'WIDGETS';

export const POSTS_ASIDE_BIRTHDAY = 'fødselsdag';
export const POSTS_ASIDE_BIRTHDAY_TODAY = 'I dag';
export const POSTS_ASIDE_BIRTHDAY_UPCOMMING = 'Kommende';
export const POSTS_ASIDE_BIRTHDAY_CONGRATULATION = ', tillykke!';
export const POSTS_ASIDE_BIRTHDAY_BUTTON_TITLE_SEEMORE = 'Se flere';
export const POSTS_ASIDE_BIRTHDAY_BUTTON_TITLE_SEEFEWER = 'Se færre';
export const POSTS_BUTTON_SEEMORE = 'Se mere';
export const POSTS_BUTTON_SEELESS = 'Se mindre';
export const POSTS_ASIDE_BIRTHDAY_DAYS_HAS = ' dage har';
export const POSTS_ASIDE_BIRTHDAY_DAY_HAS = ' dag har';
export const POSTS_ASIDE_BIRTHDAY_ON = 'Om ';
export const POSTS_ASIDE_NO_BIRTHDAY_EVENTS = 'Der er ingen fødselsdage inden for den næste uge';

export const POST_CONFIRM_DELETE = 'ADVARSEL';
export const POST_DELETE_POST_INFO_2 = 'Er du sikker på, at du vil slette opslaget?';
export const POST_DELETE_POST_INFO =
  'Bemærk, at når du sletter et opslag, sletter du det også for alle andre brugere og grupper, som opslaget er delt med.';
export const POST_BUTTON_CANCEL = 'Annuller';
export const POST_BUTTON_OK = 'OK';
export const POST_BUTTON_FORTRYD = 'Fortryd';

export const POST_DETAIL_TITLE = 'Detaljer';

export const POSTS_SEARCH_RESULT_NOT_FOUND_ALL = 'Der er desværre ingen opslag';
export const POSTS_SEARCH_RESULT_NOT_FOUND_PARENT = 'Der er ingen opslag fra forældre';
export const POSTS_SEARCH_RESULT_NOT_FOUND_STAFF = 'Der er ingen opslag fra medarbejdere';
export const POSTS_SEARCH_RESULT_NOT_FOUND_STUDENT = 'Der er ingen opslag fra elever';
export const POSTS_SEARCH_RESULT_NOT_FOUND_IS_IMPORTANT = 'Der er ingen opslag, der er markerede som vigtige';
export const CONFIRM_MESSAGE_REMOVED_STYLE_QUESTION = 'Er du sikker på, at du vil fortsætte?';
export const ARIA_LABEL_POST_OPTIONS = 'Indstillinger for indlæg';
export const ARIA_LABEL_POST_CHOOSE_INSTITUTION = 'Vælg institution';
export const ARIA_LABEL_POSTS_CREATE_TITLE = 'Skriv emne';
export const ARIA_LABEL_POSTS_CREATE_DATE_FROM = 'Vælg vigtigt fra';
export const ARIA_LABEL_POSTS_CREATE_DATE_TO = 'Vælg vigtigt til';
export const ARIA_LABEL_POSTS_PUBLISH_DATE = 'Synligt fra dato';
export const ARIA_LABEL_POSTS_PUBLISH_TIME = 'Vælg synligt fra tid';
export const ARIA_LABEL_POSTS_EXPIRE_DATE = 'Synligt til dato';
export const ARIA_LABEL_POSTS_EXPIRE_TIME = 'Vælg synligt til tid';
export const POST_PUBLISH_DATE = 'Synlig fra: {date} kan ikke redigeres';
export const POSTS_PUBLISH_LABEL = 'Synlig fra';
export const POSTS_EXPIRE_LABEL = 'Synlig til';
export const POSTS_EXPIRE_BEFORE_PUBLISH =
  'Synlig til kan ikke være i fortiden og skal være mindst et kvarter efter synlig fra';

export const HOUR = 'Time';
export const MINUTE = 'Minut';
