<template>
  <div>
    <h1>widget - notice board </h1>
    <template v-if="placement"><strong>placement:</strong> {{ placement }}<br></template>
    <template v-if="currentWeekNumber"><strong>currentWeekNumber:</strong> {{ currentWeekNumber }}<br></template>
    <template v-if="institutionCode"><strong>institutionCode:</strong> {{ institutionCode }}<br></template>
    <template v-if="aulaToken"><strong>aulaToken:</strong> <span class="breakWord">{{ aulaToken }}</span><br></template>
    <br/><button class="btn btn-primary" @click="openModal()">open modal</button><br/>
    <p> Lets load some data with Axios:
        <blockquote v-if="posts && posts.length">
            <p v-for="post of posts">
                <strong>{{ post.id }} - {{post.title}}</strong>
                <br/> {{post.body}}
            </p>
        </blockquote>
    </p>
    <b-modal
      ref="notes-modal"
      title="test modal"
      hide-footer
    >
      test
    </b-modal>
  </div>
</template>

<script>
module.exports = {
  props: {
    axios: Function,
    moment: Function,
    placement: String,
    currentWeekNumber: String,
    institutionCode: String
  },
  data: function () {
    return {
      posts: {},
      someDate: null,
    }
  },
  methods: {
    loadStuff() {
      this.axios.get("/static/posts.json")
        .then(response => {
          // JSON responses are automatically parsed.
          this.posts = response.data;
        })
        .catch(e => {
          //some error handling
          console.log(e);
        })
    },
    openModal() {
      this.$refs['notes-modal'].show();
    },
  },
  mounted() {
    this.loadStuff();
  }
};
</script>

<style scoped>
b:before {
  content: "";
  display: block;
}
.breakWord {
  word-break: break-all;
}
</style>
