<template>
    <div>
        <a v-bind:class="{ loaded: isLoaded }" target="_blank" v-bind:href="subitUrl">
            <div class="box">
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 31 31" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M18.1068 31H12.8945C11.8497 31 10.9996 30.1924 10.9996 29.1999V25.3163C10.9996 24.8636 10.5984 24.583 10.2271 24.583C10.0842 24.583 9.94744 24.6215 9.82028 24.6968L5.66917 27.1678C5.36703 27.3473 5.01932 27.4425 4.6643 27.4425C4.00658 27.4425 3.40569 27.1266 3.05685 26.598L0.289819 22.4018C0.0208797 21.9945 -0.0646407 21.5119 0.0490115 21.0431C0.162101 20.5744 0.461422 20.1757 0.889587 19.9207L5.88972 16.9447C6.11927 16.8084 6.25149 16.5823 6.25149 16.3252C6.25149 16.0676 6.11927 15.8421 5.89028 15.7058L0.889587 12.7298C0.461422 12.4743 0.162101 12.0761 0.0490115 11.6074C-0.0646407 11.1386 0.0208797 10.656 0.289819 10.2487L3.05685 6.05302C3.40569 5.52388 4.00658 5.208 4.6643 5.208C5.01989 5.208 5.36703 5.30314 5.66917 5.48272L9.82084 7.95364C9.94744 8.029 10.0842 8.06748 10.2271 8.06748C10.5984 8.06748 10.9996 7.78688 10.9996 7.33417V1.80014C10.9996 0.807603 11.8497 0 12.8945 0H18.1068C19.1516 0 20.0017 0.807603 20.0017 1.80014V7.30371C20.0017 7.56347 20.1289 7.7329 20.2352 7.82964C20.4873 8.05786 20.8828 8.10436 21.1872 7.9189L25.3225 5.39721C25.6286 5.21014 25.9825 5.11126 26.3449 5.11126C27.0026 5.11126 27.604 5.42714 27.9523 5.95628L30.7115 10.1402C31.2662 10.9815 30.9967 12.0943 30.1117 12.6218L24.9293 15.7058C24.6997 15.8421 24.5681 16.0676 24.5681 16.3252C24.5681 16.5823 24.6997 16.8084 24.9293 16.9452L30.1117 20.0292C30.9967 20.5562 31.2662 21.669 30.7115 22.5103L27.9557 26.6889C27.6068 27.218 27.0059 27.5333 26.3482 27.5333C25.9825 27.5333 25.6264 27.4334 25.318 27.2437L21.1906 24.6995C21.0617 24.6199 20.9216 24.5793 20.7748 24.5793C20.4034 24.5793 20.0017 24.8599 20.0017 25.3131V29.1999C20.0017 30.1924 19.1516 31 18.1068 31Z"
                          fill="#4D88FF" />
                </svg>
                <div class="separator"></div>
                <div class="sign-in-icon"></div>
                <div class="text">
                    Åbn Subit infoskærm
                </div>
            </div>
        </a>
        <div class="footer">
            <div class="footer-item">
                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 32 32" class="footer-item-icon">
                    <title>mail</title>
                    <path
                        d="M0 4v18c0 3.314 2.686 6 6 6v0h20c3.314 0 6-2.686 6-6v0-18h-32zM30.667 22c0 2.577-2.089 4.667-4.667 4.667v0h-20c-2.577 0-4.667-2.089-4.667-4.667v0-16.667h29.333v16.667zM28.32 9.113l-0.893-0.987-11.427 10.307-11.36-10.293-0.893 0.987 12.253 11.107 12.32-11.12z" />
                </svg>
                <p class="footer-item-text">support@subit.dk</p>
            </div>
            <div class="footer-item">
                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 32 32" class="footer-item-icon">
                    <title>smart-phone</title>
                    <path
                        d="M22 0h-12c-1.841 0-3.333 1.492-3.333 3.333v0 25.333c0 1.841 1.492 3.333 3.333 3.333v0h12c1.841 0 3.333-1.492 3.333-3.333v0-25.333c0-1.841-1.492-3.333-3.333-3.333v0zM10 1.333h12c1.105 0 2 0.895 2 2v0 22h-16v-22c0-1.105 0.895-2 2-2v0zM22 30.667h-12c-1.105 0-2-0.895-2-2v0-2h16v2c0 1.105-0.895 2-2 2v0zM17.333 28.667c0 0.736-0.597 1.333-1.333 1.333s-1.333-0.597-1.333-1.333c0-0.736 0.597-1.333 1.333-1.333s1.333 0.597 1.333 1.333z" />
                </svg>
                <p class="footer-item-text">70 60 51 02</p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        getAulaToken: Function,
        axios: Function,
    },
    data() {
        return {
            isLoaded: false,
            subitUrl: '',
            aulaToken: null,
        }
    },
    mounted() {
        this.aulaToken = this.getAulaToken()
    },
    watch: {
        aulaToken: function() {
            if (this.aulaToken != undefined && Object.keys(this.aulaToken).length > 0) {
                this.initRender()
            }
        },
    },
    methods: {
        async initRender() {
            this.axios.defaults.baseURL = 'https://api.subit.dk'
            this.axios.defaults.headers.common['x-client'] = 'Aula info-screen-button'

            let reloadToken = false
            try {
                const token = localStorage.getItem('subit_token')
                const expire = localStorage.getItem('subit_token_expires')
                if (!token || new Date(expire).getTime() < new Date().getTime()) {
                    reloadToken = true
                }
            } catch {
                reloadToken = true
            }

            if (reloadToken) {
                let authResponse = await this.axios.post(`/v2/auth/aula`, {
                    'jwt_token': this.aulaToken,
                })

                localStorage.setItem('subit_token', authResponse.data.data.access_token)
                localStorage.setItem('subit_token_expires', authResponse.data.data.expires_at)
            }

            this.axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('subit_token')
            this.subitUrl = 'https://info.subit.dk/?token=' + localStorage.getItem('subit_token')
            this.isLoaded = true
        },
    },
}
</script>
<style scoped>

* {
    padding: 0;
    margin: 0;
    font-weight: normal;
    font-family: Helvetica, Arial, sans-serif;
}

.box {
    height: 64px;
    padding: 0 15px;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background: #fff;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-family: sans-serif;
}

.separator {
    width: 1px;
    background: #E6E7ED;
    height: 100%;
    margin-left: 15px;
}

.text {
    color: #4d88ff;
    display: flex;
    margin-left: 10px;
    font-family: "Gt Walsheim Pro", sans-serif;
}

.sign-in-icon {
    background: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20version%3D%221.1%22%20id%3D%22Layer_1%22%20x%3D%220px%22%20y%3D%220px%22%20viewBox%3D%220%200%2048%2048%22%20style%3D%22enable-background%3Anew%200%200%2048%2048%3B%22%20xml%3Aspace%3D%22preserve%22%3E%0A%3Cstyle%20type%3D%22text%2Fcss%22%3E%0A%09.st0%7Bfill%3Anone%3Bstroke%3A%23000000%3Bstroke-width%3A2%3Bstroke-miterlimit%3A10%3B%7D%0A%3C%2Fstyle%3E%0A%3Ctitle%3EUntitled-13%3C%2Ftitle%3E%0A%3Cg%20id%3D%22Projector_Screen%22%3E%0A%09%3Cline%20class%3D%22st0%22%20x1%3D%224%22%20y1%3D%2235%22%20x2%3D%224%22%20y2%3D%227%22%2F%3E%0A%09%3Cline%20class%3D%22st0%22%20x1%3D%2244%22%20y1%3D%227%22%20x2%3D%2244%22%20y2%3D%2235%22%2F%3E%0A%09%3Crect%20x%3D%221%22%20y%3D%221%22%20class%3D%22st0%22%20width%3D%2246%22%20height%3D%226%22%2F%3E%0A%09%3Cline%20class%3D%22st0%22%20x1%3D%220%22%20y1%3D%2235%22%20x2%3D%2248%22%20y2%3D%2235%22%2F%3E%0A%09%3Cline%20class%3D%22st0%22%20x1%3D%2224%22%20y1%3D%2248%22%20x2%3D%2224%22%20y2%3D%2235%22%2F%3E%0A%3C%2Fg%3E%0A%3C%2Fsvg%3E');
    background-repeat: no-repeat;
    background-position: center;
    width: 18px;
    height: 18px;
    display: flex;
    margin-left: 10px;
}

.loaded {
    opacity: 1.0;
    filter: alpha(opacity=100);
}

.footer {
    background: #F5F6FA;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 7px 0;
}

.footer .footer-item {
    margin-left: 14px;
    display: flex;
    align-items: center;
    padding: 0;
}

.footer .footer-item .footer-item-icon {
    fill: #6F7785;
    height: 14px;
    margin-right: 8px;
}

.footer .footer-item .footer-item-text {
    color: #6F7785;
    font-size: 12px;
}

</style>