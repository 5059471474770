












import { PropType } from 'vue';
import { PostModel } from '../../../shared/models/post.model';
import Post from './Post.vue';

export default {
  components: { Post },
  props: {
    posts: { type: Array as PropType<PostModel[]>, required: true },
  },
  emits: ['edit', 'delete', 'report'],
  methods: {
    emitEdit(postId) {
      this.$emit('edit', postId);
    },
    emitDelete(postId) {
      this.$emit('delete', postId);
    },
    emitReport(postId) {
      this.$emit('report', postId);
    },
  },
};
