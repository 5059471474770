<template>
  <div
    :key="section"
    class="main-section">
    <section v-if="loading">
      <div
        class="infoba-header"
        style="background-color: #888;">
        <div style="display:flex">
          <i class="fal fa-home" />
          <span
            class="header-link"
            @click="goto(headerLink)">INFOBA</span>
        </div>
        <img
          :src="domain + '/Images/logowhite.svg'"
          class="infoba-logo">
      </div>
      <div
        class="infoba-quick-access-item "
        style="text-align:center; padding:20px !important">
        <div class="icon-rotator infoba-rotate" />
      </div>
    </section>
    <section v-else-if="section!='default' ">
      <div
        class="infoba-header"
        style="background-color: #888;">
        <div style="display:flex">
          <i class="fal fa-home" />
          <span
            class="header-link"
            @click="goto(headerLink)">INFOBA</span>
        </div>
        <img
          :src="domain + '/Images/logowhite.svg'"
          class="infoba-logo">
      </div>
      <div class="infoba-quick-access-item">
        <widget-html :html="message" />
      </div>
    </section>
    <section v-show="section=='default' && !loading">
      <div class="">
        <div class="wrapper" />
        <div class="infoba-header">
          <div style="display: flex">
            <div class="icon-briefcase-account" />
            <div
              class="header-link"
              @click="goto(domain +'/School/DigitalSchoolSuitcase/SuitcaseDistributionInstitutionsList')">
              Digital skolekuffert
            </div>
          </div>
          <img
            :src="domain + '/Images/logowhite.svg'"
            class="infoba-logo">
        </div>

        <div class="tab-container">
          <div
            class="tab-item"
            :class="{active:isClass}"
            @click="selectedId = 0;getData(true)">
            Klasse
          </div>
          <div
            class="tab-item"
            :class="{active:!isClass}"
            @click="selectedId = 0;getData(false)">
            B&oslash;rnehave
          </div>
        </div>
        <div
          v-show="isClass"
          class="infoba-quick-access-item">
          <div
            v-show="selectedId>0"
            style="font-weight:bold;margin-top:5px; margin-bottom:5px;">
            B&oslash;rn til klasse
          </div>
          <div
            v-show="selectedId<=0"
            style="font-weight:bold;margin-top:5px; margin-bottom:5px;">
            V&aelig;lg klasse
          </div>
          <select
            id="classes"
            v-model="selectedId"
            class="multiselect"
            @change="getData(true)">
            <option
              v-for="option in item.Binder.DigitalSuitcase.DigitalSchoolSuitCaseClassesSelectList"
              :value="option.Value">
              {{ option.Text }}
            </option>
          </select>
        </div>
        <div
          v-show="!isClass"
          class="infoba-quick-access-item">
          <div
            v-show="selectedId>0"
            style="font-weight:bold;margin-top:5px; margin-bottom:5px;">
            B&oslash;rn fra b&oslash;rnehave
          </div>
          <div
            v-show="selectedId<=0"
            style="font-weight:bold;margin-top:5px; margin-bottom:5px;">
            V&aelig;lg b&oslash;rnehave
          </div>
          <select
            id="institutions"
            v-model="selectedId"
            class="multiselect"
            @change="getData(false)">
            <option
              v-for="option in item.Binder.DigitalSuitcase.DigitalSchoolSuitCaseInstitutionsSelectList"
              :value="option.Value">
              {{ option.Text }}
            </option>
          </select>
        </div>

        <div
          class="infoba-quick-access-item"
          style="padding-top: 0;">
          <template v-for="h in headers">
            <div
              class="infoba-accordeon-header"
              style="cursor:pointer"
              @click="h.Open = !h.Open">
              <div class="theme-circle">
                {{ h.Count }}
              </div>
              <div
                v-show="!isClass"
                style="width:70%; ">
                <p class="ellipsis">
                  <b>{{ h.ClassName }}</b>
                </p>
              </div>
              <div
                v-show="isClass"
                style="width:70%;">
                <p class="ellipsis">
                  <b>{{ h.InstitutionName }}</b>
                </p>
              </div>
              <i
                class="fas fa-chevron-right rotate chevron"
                :class="{right:h.Open}" />
            </div>
            <template v-for="schoolSuitcase in h.ChildList">
              <transition name="slide">
                <div
                  v-show="h.Open"
                  class="infoba-accordeon-row"
                  style="padding:0px;">
                  <div style=" display: flex; flex-grow: 1;">
                    <div
                      class="child-circle"
                      style="margin: 16px 10px 10px 10px;">
                      <div
                        class="whiteCircle"
                        style="vertical-align: middle; text-align: center;">
                        <img
                          class="pictureCircle"
                          :src="domain+'/images/icons/childFace.png'">
                      </div>
                    </div>
                    <div style="width:70%">
                      <div style="margin-top:22px;">
                        <div
                          v-if="schoolSuitcase.LockedBySystem && schoolSuitcase.Consent && moment(schoolSuitcase.SuitcaseDate, 'DD-MM-YYYY').add(1, 'year').diff(schoolSuitcase.CreatedDate, 'days')>0"
                          class="infoba-link"
                          @click="goto(domain +'/School/DigitalSchoolSuitcase/DownloadFile/'+schoolSuitcase.DocumentId+'?childName='+schoolSuitcase.ChildName+'&viewName=SuitcaseDistributionInstitutions&dataId='+selectedId)">
                          <p
                            class="ellipsis"
                            style="padding-bottom: 0px;">
                            <b>{{ schoolSuitcase.ChildName }}</b>
                          </p>
                        </div>
                        <div v-else>
                          <p
                            class="ellipsis"
                            style="padding-bottom: 0px;">
                            <b>{{ schoolSuitcase.ChildName }}</b>
                          </p>
                        </div>
                        <p style="color:#ccc; padding-top: 0px;">
                          {{ schoolSuitcase.ChildAge }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </transition>
            </template>
          </template>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
var domain = 'https://www2.infoba.dk' ;
export default {
  props: {
    /* normal and secure */
    axios: Function,
    sessionUUID: String,
    placement: String,
    currentWeekNumber: String,
    isMobileApp: Boolean,
    userProfile: String,
    /* only secure */
    getAulaToken: Function,
    moment: Function,
    assuranceLevel: Number,
    childFilter: Array,
    institutionFilter: Array,
    group: String
  },
  data: function () {
    return {
      loading: true,
      domain: domain,
      message: "",
      timer: "",
      headerLink: domain,
      section: "default",
      date: this.moment().format('DD-MM-YYYY'),
      aulaToken: null,
      isClass: true,
      selectedId: 0,
      headers: [],
      item: {
        Binder: {
          DigitalSuitcase: {}
        }
      }
    }
  },
  methods: {
    getData: function (isClass) {
      if (isClass) {
        this.isClass = true;
      } else {
        this.isClass = false;
      }

      var uuid = this.sessionUUID;
      this.axios.defaults.headers.common["Authorization"] = this.aulaToken;
      this.axios.defaults.headers.common["AuthorizationContext"] = "DigitalSchoolSuitcase";

      if (this.childFilter != null)
        this.axios.defaults.headers.common["childFilter"] = this.childFilter.join();
      if (this.institutionFilter != null)
        this.axios.defaults.headers.common["institutionFilter"] = this.institutionFilter.join();
      if (this.group != null)
        this.axios.defaults.headers.common["groupFilter"] = this.group;
      if (this.userProfile != null)
        this.axios.defaults.headers.common["userProfile"] = this.userProfile;
      if (this.currentWeekNumber != null)
        this.axios.defaults.headers.common["currentWeekNumber"] = this.currentWeekNumber;
      if (this.isMobileApp != null)
        this.axios.defaults.headers.common["isMobileApp"] = this.isMobileApp;

      if (this.selectedid == "" || this.selectedid == undefined)
        this.selectedid = 0;
      this.axios.get(domain + '/api/Aula/DigitalSchoolSuitcase?isClass=' + (this.isClass ? "true" : "false") + '&id=' + this.selectedId, {
        withCredentials: true,
        credentials: 'same-origin'
      })
        .then(response => {
          this.moment.locale('da')
          this.item = response.data;
          handleStatus(this);
          if (this.item.Binder.DigitalSuitcase != undefined) {
            if (!isClass) {
              this.groupByClassId();
            }
            else {
              this.groupByInstitutionId();
            }
          }
          else {
            this.item.Binder.DigitalSuitcase = { DigitalSchoolSuitCaseInstitutionsSelectList: [] };
          }
        })
        .catch(error => {
          handleStatus(this);
        }).finally(() => this.loading = false)
    },
    goto: function (redirectUrl) {
      let serial = this.item.INFOBAserial;
      let token = this.item.INFOBAtoken;
      let url = "";
      //if logged in
      if (serial === null || token === null) {
        let aulaReferrer = '?referrer=Aula';
        if (redirectUrl.indexOf('?') !== -1) {
          aulaReferrer = '&referrer=Aula';
        }
        url = redirectUrl + aulaReferrer;
      }
      //if not logged in
      else {
        url = domain + "/Login/Index?serial=" + serial + "&token=" + token + "&referrer=Aula&returnUrl=" + encodeURIComponent(redirectUrl);
      }
      var a = document.createElement('a');
      a.href = url;
      a.target = "INFOBA";
      a.click();
    },
    groupByClassId: function () {
      let classIds = [];
      this.headers = [];

      for (let i = 0; i < this.item.Binder.DigitalSuitcase.DigitalSchoolSuitCaseList.length; i++) {
        if (classIds.indexOf(this.item.Binder.DigitalSuitcase.DigitalSchoolSuitCaseList[i].ClassId) === -1) {
          classIds.push(this.item.Binder.DigitalSuitcase.DigitalSchoolSuitCaseList[i].ClassId);
        }
      }
      for (let i = 0; i < classIds.length; i++) {
        this.headers.push({
          Open: false,
          ClassId: classIds[i],
          ClassName: this.item.Binder.DigitalSuitcase.DigitalSchoolSuitCaseList.filter((item) => item.ClassId == classIds[i])[0].ClassName,
          Count: this.item.Binder.DigitalSuitcase.DigitalSchoolSuitCaseList.filter((item) => item.ClassId == classIds[i]).length,
          ChildList: this.item.Binder.DigitalSuitcase.DigitalSchoolSuitCaseList.filter
          ((item) => item.ClassId == classIds[i])
        });
      }
    },
    groupByInstitutionId: function () {
      let institutionIds = [];
      this.headers = [];

      for (let i = 0; i < this.item.Binder.DigitalSuitcase.DigitalSchoolSuitCaseList.length; i++) {
        if (institutionIds.indexOf(this.item.Binder.DigitalSuitcase.DigitalSchoolSuitCaseList[i].ParentInstitutionId) === -1) {
          institutionIds.push(this.item.Binder.DigitalSuitcase.DigitalSchoolSuitCaseList[i].ParentInstitutionId);
        }
      }
      for (let i = 0; i < institutionIds.length; i++) {
        this.headers.push({
          Open: false,
          InstitutionId: institutionIds[i],
          InstitutionName: this.item.Binder.DigitalSuitcase.DigitalSchoolSuitCaseList.filter((item) => item.ParentInstitutionId == institutionIds[i])[0].InstitutionName,
          Count: this.item.Binder.DigitalSuitcase.DigitalSchoolSuitCaseList.filter((item) => item.ParentInstitutionId == institutionIds[i]).length,
          ChildList: this.item.Binder.DigitalSuitcase.DigitalSchoolSuitCaseList.filter
          ((item) => item.ParentInstitutionId == institutionIds[i])
        });
      }
    }
  },
  created: function () {
    this.aulaToken = this.getAulaToken();
  },
  watch: {
    aulaToken: function () {
      if (this.aulaToken !== undefined) {
        var t = this;
        t.getData(true);
      }
    },
  }
}

function handleStatus(t) {
  clearTimeout(t.timer)
  if (t.item && t.item.Status == 200) {
    if (t.item.Binder.DigitalSuitcase == undefined) {
      t.section = "error"
      t.message = 'Din bruger har ikke adgang til denne widget i INFOBA. Kontakt <a href="https://web.infoba.dk/support/" class="infoba-link">support</a>.'
    }
    else {
      t.section = "default"
    }
  }
  else if (t.item && t.item.Status == 401) {
    if (t.item.Message == "Invalid role" || t.item.Message == "Module payment missing") {
      t.section = "error"
      t.message = 'Din bruger har ikke adgang til denne widget i INFOBA. Kontakt <a href="https://web.infoba.dk/support/" class="infoba-link">support</a>.'
    }
    else {
      t.section = "login";
      t.message = 'For at se data fra INFOBA, skal dine konti f&oslash;rst sammenk&aeligdes. <div><a href="' + domain + "/IN/Personel/MatchAula/" + t.item.Username + '" class="infoba-link">Start sammenk&aeligdning <i class="material-icons icon-arrowright-circle" style="vertical-align: middle; margin-top: 2px; color:#96be2c"></i></a></div>';
      t.timer = setTimeout(function () { t.getData(true) }.bind(t), 5000);
      t.headerLink = domain + "/IN/Personel/MatchAula/" + t.item.Username;
    }
  }
  else {
    t.section = "error"
    t.message = 'Undskyld. Det er i &oslash;jeblikket ikke muligt at vise dig den &oslash;nskede information. Vi h&aring;ber, du vil pr&oslash;ve igen senere.'
  }
}
</script>
<style scoped>
.main-section {
  border: 2px solid white;
  background-color: #FAFAFA;
}

p {
  margin: 0 0 2px 0;
}

div.icon-rotator {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QAAAAAAAD5Q7t/AAAACXBIWXMAAA7EAAAOxAGVKw4bAAAD5UlEQVRo3u2ZT4gbdRTHv++XJUzwT6HuoUrB0nhQKR6kerG9eCgL1l3FdT1ZpLB4kL1Edmf29rtlJpsuSPEgQvdiD6ZQdS1ioTf/gFUQ24KSS3qQIloKVXGSyWa+Hjq7JNNkMzOZcT3M9zZv5v3m98n7vfd+8wuQK1euXLlyJZekPaDW+mHDMI6TPCIijwPYB2ALwB2SvwH4rtPp/KC1/vt/B6K1njIMYx7AaQAvAiiMcekC+ALARrvd/lxr7e85iOM48yQdAIeT+JO8JiIrlmVdnmQeU0kd19fX93ued47k3CQTEJEnRaQ0yRhAwohUq9UnROQyRkdhC8BVki0R+V1EPJLTAJ4C8CwAI3jOE5E3TNP89D8HCSC+AnBgyO2fAKwXi8VLlUrlzjB/rfWDhmHMAlgSEScNiNggwXL6HvdH4raIVFzXPZ9G4iZRrBzxPO9cGEJEbiilZpeXl1t7AbAtFfVBx3HmAQwktojccF33hawgHMc5mCqI1noqKLH9uq2UmtVa/5kFRLVanSHZtG37ndRAgmYXXlKVrCJRrVZnROQTACUAZ6PARF1ap/svSF5zXfd8RhAnAojtEi0BzNJEILZt78O9bceORORMFtUpiMRnfRA7rwTw3m6RiRKRYxjcO20Vi8VLGUH0RyKs7cgMhRkLQvJIyHR1VLPLEGIszFgQpdShkOnmHkHsCjMWxPf9h0KmWylCnIgJEYZZ6jcAAGzbXgTwAZJtJD2l1GsrKyup5I5t22cAVPpMH1mW9eZuPjsRsSzrQwBLABjzvW2Sc2lBBHqs/4Lk2KY7sLQsy3o/Jkyb5Kurq6tfpggBAIdC1zdjgcSEyQSiXq9PA3guZL4eGyQiTFaRQK/XexmDfaurlPomEUgfzNtDYDyl1OtZQDQajQIGkxwArpim+VdikAAmXACySOwdtVqtU+EGTHIjiu/YPtK3zNyslhMA1Gq1Msl6yNwsl8sXo/hH7hmO4xw0TfPXLCCCjem3AJ4emJzInGmam6mCZKVarVb2fX9zCMQF0zQXoo6T+FxrUjUajUKr1Trl+34dwP7Q7SbJxTjjpRYRx3FeIWmJyFkAm6MqTb1en+52uyeVUu8O2VkDwK1CoXAs7tdnKiABxMcAioGpLSI/kvxZRP4gWRSRAyQPAziK0WfDzUKhMJPkEzqNs98wRCKJyAWSi5Zl3U3iP3GO+L7/D4BfROSZhEM0RWQ5anUa+UNMCgIAWmtVKpVOknwLwEsYH50ugCskN8rl8sWFhYXepHNIvfyura09QPJor9d7XkQeBfAIyQKAu7i3i73e6XS+TvuPnly5cuXKlWsS/QvhKq1sLYByvgAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAyMC0xMC0wNlQxMTozNjo1NSswMDowMPTXOyYAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMjAtMTAtMDZUMTE6MzY6NTUrMDA6MDCFioOaAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAABJRU5ErkJggg==) no-repeat center center;
  padding: 25px 25px 25px 25px;
  display: inline-block;
}

div.icon-briefcase-account {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QAAAAAAAD5Q7t/AAAACXBIWXMAAA7EAAAOxAGVKw4bAAAA7klEQVRIx+2UQW7CQAxFvyv2VbMrQr0Gm14AddFb9EDhIizoBaqygGMwSKw5wetmgoIRE6cJm6pPGimy7Hz7exLpzwO8AQeuScBiDIHEbfZjCAAQjXse7mXtlcAtr33HXXG/G2t7LWk2UuPJzF68QKeffTAzu7AoQC1pms+ytyJlvlyuAd+lgiY3OsHOjY+kbaQwKjD3E/jYUIsAamCaz7Ir+TxtW6D34go0t2gSyD1JWknaSDrm2LOkV0nvkh6HWLQGqkJNBXyWLOoSeAo0Vv16B42PkekjOzjI/YsGLD41D+3v4COLDCXld/0T4wfrMqeKIP60yQAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAyMC0xMC0wN1QwNzowMjoyMCswMDowMK+hdNkAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMjAtMTAtMDdUMDc6MDI6MjArMDA6MDDe/MxlAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAABJRU5ErkJggg==) no-repeat center center;
  background-size: 20px;
  padding: 35px 0 0 35px;
  display: inline-block;
}

i {
  font-size: 14px;
}

i.chevron {
  margin-right: 10px;
  height: 14px;
}
.tab-container {
  display: flex;
  height: 50px;
  border-bottom: 2px solid #873C79;
}

.tab-item {
  color: #888888;
  flex-basis: 50%;
  text-align: center;
  vertical-align: middle;
  line-height: 48px;
  cursor: pointer;
}

.tab-item.active {
  font-weight: bold;
  color: #873C79;
  border-bottom: 5px solid #873C79;
}

.multiselect {
  height: 40px;
  width: 100%;
}

.header-link {
  cursor: pointer;
  margin-left: 5px;
  line-height: 35px;
}

.child-circle {
  background-color: #ccc;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  -moz-border-radius: 20px;
  -webkit-border-radius: 20px;
  margin-right: 10px;
  display: inline-block;
  position: relative
}

.whiteCircle {
  background-color: #fff;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  -moz-border-radius: 15px;
  -webkit-border-radius: 15px;
  position: absolute;
  display: inline-block;
  top: 50%;
  left: 50%;
  margin: -15px 0 0 -15px
}

.pictureCircle {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  margin: -12px 0 0 -12px
}


.theme-circle {
  width: 18px;
  height: 18px;
  color: #fff;
  font-size: 12px;
  display: inline-block;
  margin-right: 5px;
  text-align: center;
  line-height: 18px;
  border-radius: 10px;
  background-color: #873C79;
  margin-left: 10px;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  -ms-text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis
}

.infoba-header {
  color: #fff;
  padding: 4px;
  padding-left: 8px;
  padding-right: 10px;
  display: flex;
  justify-content: space-between;
  align-content: center;
  background: #873C79;
  position: relative;
}

.infoba-header:first-child {
  margin-top: 0
}

.infoba-header i {
  position: absolute;
  top: 2px;
  line-height: 35px;
  font-size: 24px !important
}

.infoba-header span {
  position: absolute;
  left: 34px;
  line-height: 35px;
  color: #fff;
  cursor: pointer;
  top: 5px
}

.quick-access-item {
  position: relative
}

.infoba-quick-access-item {
  padding: 20px 13px 20px 12px;
  cursor: auto;
  height: auto
}

.infoba-link {
  color: #888 !important;
  cursor: pointer
}

.rotate {
  -moz-transition: all .3s linear;
  -webkit-transition: all .3s linear;
  transition: all .3s linear
}

.rotate.right {
  -moz-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg)
}

.rotate.up {
  -moz-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg)
}

.slide-enter-active {
  -moz-transition-duration: .3s;
  -webkit-transition-duration: .3s;
  -o-transition-duration: .3s;
  transition-duration: .3s;
  -moz-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in
}

.slide-leave-active {
  -moz-transition-duration: .3s;
  -webkit-transition-duration: .3s;
  -o-transition-duration: .3s;
  transition-duration: .3s;
  -moz-transition-timing-function: cubic-bezier(0,1,.5,1);
  -webkit-transition-timing-function: cubic-bezier(0,1,.5,1);
  -o-transition-timing-function: cubic-bezier(0,1,.5,1);
  transition-timing-function: cubic-bezier(0,1,.5,1)
}

.slide-enter-to, .slide-leave {
  max-height: 100px;
  overflow: hidden
}

.slide-enter, .slide-leave-to {
  overflow: hidden;
  max-height: 0
}

.infoba-rotate {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite
}

@-webkit-keyframes rotating {
  from {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg)
  }

  to {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg)
  }
}

@keyframes rotating {
  from {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg)
  }

  to {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg)
  }
}

.infoba-quick-access-item ul {
  list-style: disc;
  margin: 0
}

.infoba-quick-access-item li {
  margin-left: 16px
}

.infoba-accordeon-header {

  background-color: #FFFFFF;
  line-height: 46px;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 46px;
}

.infoba-accordeon-row {
  background-color: #FFFFFF;
  padding-left: 5px;
  font-size: 12px;
  line-height: 12px;
  display: flex;
  justify-content: space-between;
}

.infoba-logo {
  height: 35px
}
</style>
