<template>
  <div :key="section">
    <section v-if="loading">
      <div class="infoba-header">
        <div style="display:flex">
          <i class="fal fa-home" />
          <span
            class="header-link"
            @click="goto(headerLink)">INFOBA</span>
        </div>
        <img
          :src="domain + '/Images/logowhite.svg'"
          class="infoba-logo">
      </div>
      <div
        class="infoba-quick-access-item "
        style="text-align:center; padding:20px !important">
        <div class="material-icons icon-rotator infoba-rotate" />
      </div>
    </section>
    <section v-else-if="section!='default' ">
      <div class="infoba-header">
        <div style="display:flex">
          <i class="fal fa-home-alt icon-house" />
          <span
            class="header-link"
            @click="goto(headerLink)">INFOBA</span>
        </div>
        <img
          :src="domain + '/Images/logowhite.svg'"
          class="infoba-logo">
      </div>
      <div class="infoba-quick-access-item">
        <widget-html :html="message" />
      </div>
    </section>
    <section v-else>
      <div
        v-show="this.item.Binder.Tile.PTCMeeting.MeetingList.length != 0"
        class="component">
        <div
          class="infoba-header"
          style="background-color: #96be2c;">
          <div style="display:flex">
            <div class="icon-house-solid" />
            <div
              class="header-link"
              @click="goto(domain + '/IN/PTCMeeting/')">
              {{ item.Binder.Tile.Header }}
            </div>
          </div>
          <img
            :src="domain + '/Images/logowhite.svg'"
            class="infoba-logo">
        </div>
        <div
          class="infoba-quick-access-item"
          style="height:auto">
          <div class="infoba-content">
            <div
              class="infoba-accordeon-header"
              style="padding-top:0; align-items:center; border:none; padding-left:0px;">
              <div class="ellipsis">
                <b>Kommende samtaler</b>
              </div>
              <i
                class="fas fa-chevron-right rotate chevron"
                :class="{right:open}"
                style="cursor:pointer;"
                @click="open = !open" />
            </div>

            <transition name="slide">
              <div
                v-show="open"
                class=""
                style="border:none; padding-right: 10px;">
                <div
                  v-for="ptcMeeting in this.item.Binder.Tile.PTCMeeting.MeetingList"
                  class="infoba-row"
                  style="border:none; flex-direction:column; margin-top:20px;padding-left:0px;">
                  <div>
                    <div
                      v-if="ptcMeeting.MeetingDate != null"
                      class="infoba-gray">
                      {{ moment(ptcMeeting.MeetingDate).format('DD. MMM YYYY') }} kl. {{ moment(ptcMeeting.MeetingDate).format('HH:mm') }}
                    </div>
                    <div
                      :class="ptcMeeting.MeetingTypeId == 0 ? 'infoba-gray':'infoba-link'"
                      style="color:#555 !important; border-bottom:solid 1px #ccc; width:100%"
                      @click="goto(getPTCMeetingLink(ptcMeeting))">
                      <b v-if="ptcMeeting.MeetingTypeId == 0">{{ ptcMeeting.Child.FullName }} (Ukendt)</b>
                      <b v-else="">{{ ptcMeeting.Child.FullName }}  ({{ ptcMeeting.Child.AgeInMonthsText + " &aring;r" }})</b>
                    </div>
                  </div>
                  <div style="display:flex; justify-content:space-between; align-content:center; margin-top:5px; margin-bottom:10px;">
                    <div> {{ ptcMeeting.MeetingType }}</div>
                    <div style="display: flex; align-items: center;">
                      <span
                        v-if="ptcMeeting.MeetingId > 0"
                        :class="getReflectionColor(ptcMeeting)">
                        <i
                          class="fas fa-user"
                          style="cursor: pointer;"
                          @click="goto(domain+'/IN/PTCMeeting/Reflection/'+ptcMeeting.Child.ChildId+'?meetingId=' + (ptcMeeting.MeetingId == null ? 0 : ptcMeeting.MeetingId) + '&meetingTypeId='+ptcMeeting.MeetingTypeId)" />
                      </span>
                      <span
                        v-else
                        :class="getReflectionColor(ptcMeeting)">
                        <i class="fas fa-user" />
                      </span>
                      <div :class="getParentColor(ptcMeeting)" />

                      <div
                        v-if="ptcMeeting.MeetingDateSent != null"
                        :class="getConclusionColor(ptcMeeting)"
                        style=" cursor: pointer;"
                        @click="goto(domain+'/IN/PTCMeeting/Conclusion/'+ptcMeeting.Child.ChildId+'?meetingId='+(ptcMeeting.MeetingId == null ? 0 : ptcMeeting.MeetingId))" />
                      <div
                        v-else
                        :class="getConclusionColor(ptcMeeting)" />
                      <span
                        v-if="ptcMeeting.MeetingId > 0"
                        :class="getHistoryColor(ptcMeeting)">
                        <i
                          class="far fa-clock"
                          style="cursor: pointer;"
                          @click="goto(domain+'/IN/PTCMeeting/History/'+ptcMeeting.Child.ChildId+'?meetingId='+(ptcMeeting.MeetingId == null ? 0 : ptcMeeting.MeetingId))" />
                      </span>
                      <span
                        v-else
                        :class="getHistoryColor(ptcMeeting)">
                        <i class="far fa-clock" />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </transition>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
var domain = 'https://www2.infoba.dk';
var domainOld = 'https://www.infoba.dk';

export default {
  props: {
    /* normal and secure */
    axios: Function,
    sessionUUID: String,
    placement: String,
    currentWeekNumber: String,
    isMobileApp: Boolean,
    userProfile: String,
    /* only secure */
    moment: Function,
    assuranceLevel: Number,
    getAulaToken: Function,
    childFilter: Array,
    institutionFilter: Array,
    group: String,
  },
  data: function () {
    return {
      loading: true,
      open: false,
      section: "default",
      message: "",
      timer: "",
      headerLink: domain,
      who: "Aula widget developers",
      date: this.moment().format("DD-MM-YYYY"),
      aulaToken: null,
      domain: domain,
      domainOld: domainOld,
      fields: {},
      item: {
        Binder: { Tile: {} }
      },
    };
  },
  methods: {
    getReflectionColor(meeting) {
      let returnClass = "grey50";
      if (meeting.MeetingId != null) {
        if (meeting.MeetingDateSent) {
          returnClass = "green";
        }
        else {
          returnClass = "grey80";
        }
      }
      return returnClass + " icon-small-ptcLinks";
    },
    getParentColor(meeting) {
      let returnClass = "icon-house-grey50";
      if (meeting.MeetingFirstSeenDate != null) {
        if (meeting.MeetingDateApproved) {
          returnClass = "icon-house-green";
        }
        else {
          returnClass = "icon-house-grey80";
        }
      }
      return returnClass + " icon-small-ptcLinks";
    },
    getConclusionColor(meeting) {
      let returnClass = "icon-text-grey50";
      if (meeting.ConslusionExists != 0) {
        if (meeting.ConclusionDateSent) {
          returnClass = "icon-text-green";
        }
        else {
          returnClass = "icon-text-grey80";
        }
      }
      return returnClass + " icon-small-ptcLinks";
    },
    getHistoryColor(meeting) {
      let returnClass = "grey50";
      if (meeting.MeetingId) {
        returnClass = "grey80";
      }
      return returnClass + " icon-small-ptcLinks";
    },
    getData: function (match) {
      var uuid = this.sessionUUID;
      this.axios.defaults.headers.common["Authorization"] = this.aulaToken;
      this.axios.defaults.headers.common["AuthorizationContext"] = "INTile";

      if (this.childFilter != null)
        this.axios.defaults.headers.common["childFilter"] = this.childFilter.join();
      if (this.institutionFilter != null)
        this.axios.defaults.headers.common["institutionFilter"] = this.institutionFilter.join();
      if (this.group != null)
        this.axios.defaults.headers.common["groupFilter"] = this.group;
      if (this.userProfile != null)
        this.axios.defaults.headers.common["userProfile"] = this.userProfile;
      if (this.currentWeekNumber != null)
        this.axios.defaults.headers.common["currentWeekNumber"] = this.currentWeekNumber;
      if (this.isMobileApp != null)
        this.axios.defaults.headers.common["isMobileApp"] = this.isMobileApp;

      this.axios.get(domain + "/api/Aula/INTileGet/95", {
        withCredentials: true,
        credentials: "same-origin",
      })
        .then((response) => {
          this.moment.locale('da');
          this.item = response.data;
          handleStatus(this);

        })
        .catch((error) => {
          handleStatus(this);
        })
        .finally(() => (this.loading = false));
    },
    goto: function (redirectUrl) {
      let serial = this.item.INFOBAserial;
      let token = this.item.INFOBAtoken;
      let url = "";
      //if logged in
      if (serial === null || token === null) {
        let aulaReferrer = '?referrer=Aula';
        if (redirectUrl.indexOf('?') !== -1) {
          aulaReferrer = '&referrer=Aula';
        }
        url = redirectUrl + aulaReferrer;
      }
      //if not logged in
      else {
        url = domain + "/Login/Index?serial=" + serial + "&token=" + token + "&referrer=Aula&returnUrl=" + encodeURIComponent(redirectUrl);
      }
      var a = document.createElement('a');
      a.href = url;
      a.target = "INFOBA";
      a.click();
    },
    getPTCMeetingLink(ptcMeeting) {
      var link = "";
      if (ptcMeeting.MeetingTypeId == 0)
        link = "#";
      else if (ptcMeeting.MeetingTypeId != 0)
        link = domain + '/IN/PTCMeeting/Reflection/' + ptcMeeting.Child.ChildId + '?meetingId=' + (ptcMeeting.MeetingId == null ? 0 : ptcMeeting.MeetingId) + '&meetingTypeId=' + ptcMeeting.MeetingTypeId;

      if (ptcMeeting.MeetingDateSent != null)
        link = domain + '/IN/PTCMeeting/Conclusion/' + ptcMeeting.Child.ChildId + '?meetingId=' + (ptcMeeting.MeetingId == null ? 0 : ptcMeeting.MeetingId);
      return link;
    },
  },
  created: function () {
    this.aulaToken = this.getAulaToken();
  },
  watch: {
    aulaToken: function () {
      if (this.aulaToken !== undefined) {
        var t = this;
        t.getData();
      }
    },
  },
};
function handleStatus(t) {
  clearTimeout(t.timer)
  if (t.item && t.item.Status == 200) {
    if (t.item.Binder.Tile == undefined) {
      t.section = "error"
      t.message = 'Din bruger har ikke adgang til denne widget i INFOBA. Kontakt <a href="https://web.infoba.dk/support/" class="infoba-link">support</a>.'
    }
    else {
      t.section = "default"
    }
  }
  else if (t.item && t.item.Status == 401) {
    if (t.item.Message == "Invalid role" || t.item.Message == "Module payment missing") {
      t.section = "error"
      t.message = 'Din bruger har ikke adgang til denne widget i INFOBA. Kontakt <a href="https://web.infoba.dk/support/" class="infoba-link">support</a>.'
    }
    else {
      t.section = "login";
      t.message = 'For at se data fra INFOBA, skal dine konti f&oslash;rst sammenk&aeligdes. <div><a href="' + domain + "/IN/Personel/MatchAula/" + t.item.Username + '" class="infoba-link">Start sammenk&aeligdning <i class="material-icons icon-arrowright-circle" style="vertical-align: middle; margin-top: 2px; color:#96be2c"></i></a></div>';
      t.timer = setTimeout(function () { t.getData() }.bind(t), 5000);
      t.headerLink = domain + "/IN/Personel/MatchAula/" + t.item.Username;
    }
  }
  else {
    t.section = "error"
    t.message = 'Undskyld. Det er i &oslash;jeblikket ikke muligt at vise dig den &oslash;nskede information. Vi h&aring;ber, du vil pr&oslash;ve igen senere.'
  }
}
</script>
<style scoped>
.component {
  margin-top: 20px;
}

.component:first-child {
  margin-top: 0px;
}

.green {
  color: #96be2c;
}
.grey80{
  color:#555555;
}
.grey50 {
  color: #888888;
}
div.icon-rotator {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QAAAAAAAD5Q7t/AAAACXBIWXMAAA7EAAAOxAGVKw4bAAAD5UlEQVRo3u2ZT4gbdRTHv++XJUzwT6HuoUrB0nhQKR6kerG9eCgL1l3FdT1ZpLB4kL1Edmf29rtlJpsuSPEgQvdiD6ZQdS1ioTf/gFUQ24KSS3qQIloKVXGSyWa+Hjq7JNNkMzOZcT3M9zZv5v3m98n7vfd+8wuQK1euXLlyJZekPaDW+mHDMI6TPCIijwPYB2ALwB2SvwH4rtPp/KC1/vt/B6K1njIMYx7AaQAvAiiMcekC+ALARrvd/lxr7e85iOM48yQdAIeT+JO8JiIrlmVdnmQeU0kd19fX93ued47k3CQTEJEnRaQ0yRhAwohUq9UnROQyRkdhC8BVki0R+V1EPJLTAJ4C8CwAI3jOE5E3TNP89D8HCSC+AnBgyO2fAKwXi8VLlUrlzjB/rfWDhmHMAlgSEScNiNggwXL6HvdH4raIVFzXPZ9G4iZRrBzxPO9cGEJEbiilZpeXl1t7AbAtFfVBx3HmAQwktojccF33hawgHMc5mCqI1noqKLH9uq2UmtVa/5kFRLVanSHZtG37ndRAgmYXXlKVrCJRrVZnROQTACUAZ6PARF1ap/svSF5zXfd8RhAnAojtEi0BzNJEILZt78O9bceORORMFtUpiMRnfRA7rwTw3m6RiRKRYxjcO20Vi8VLGUH0RyKs7cgMhRkLQvJIyHR1VLPLEGIszFgQpdShkOnmHkHsCjMWxPf9h0KmWylCnIgJEYZZ6jcAAGzbXgTwAZJtJD2l1GsrKyup5I5t22cAVPpMH1mW9eZuPjsRsSzrQwBLABjzvW2Sc2lBBHqs/4Lk2KY7sLQsy3o/Jkyb5Kurq6tfpggBAIdC1zdjgcSEyQSiXq9PA3guZL4eGyQiTFaRQK/XexmDfaurlPomEUgfzNtDYDyl1OtZQDQajQIGkxwArpim+VdikAAmXACySOwdtVqtU+EGTHIjiu/YPtK3zNyslhMA1Gq1Msl6yNwsl8sXo/hH7hmO4xw0TfPXLCCCjem3AJ4emJzInGmam6mCZKVarVb2fX9zCMQF0zQXoo6T+FxrUjUajUKr1Trl+34dwP7Q7SbJxTjjpRYRx3FeIWmJyFkAm6MqTb1en+52uyeVUu8O2VkDwK1CoXAs7tdnKiABxMcAioGpLSI/kvxZRP4gWRSRAyQPAziK0WfDzUKhMJPkEzqNs98wRCKJyAWSi5Zl3U3iP3GO+L7/D4BfROSZhEM0RWQ5anUa+UNMCgIAWmtVKpVOknwLwEsYH50ugCskN8rl8sWFhYXepHNIvfyura09QPJor9d7XkQeBfAIyQKAu7i3i73e6XS+TvuPnly5cuXKlWsS/QvhKq1sLYByvgAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAyMC0xMC0wNlQxMTozNjo1NSswMDowMPTXOyYAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMjAtMTAtMDZUMTE6MzY6NTUrMDA6MDCFioOaAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAABJRU5ErkJggg==) no-repeat center center;
  padding: 25px 25px 25px 25px;
  display: inline-block;
}

div.icon-house-solid {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QAAAAAAAD5Q7t/AAAACXBIWXMAAABgAAAAYADwa0LPAAAApUlEQVRIx+3TMQ4BQRSAYSOikWjVGhdwA50rcAilK2jdgSvo3GAvoFFvK9Eo+FQTBZvdHSua/Q/wvfeSmU6nrakQsMEW3abxPvZe7dBvCh/g4L0jht/iI2SKyzBKxcc4Ke+MSV18irwCHssxrYrPcKmBx66Yl+FL3BLw2A2LInyF+xd47IF1dEPBMCkPI4Tw5jX7Iz/US92u6pU/v6Ad8P8Bbf/vCQE4pIQLr5KUAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIxLTAxLTA0VDA3OjE2OjQzKzAwOjAwHCZzVQAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMS0wMS0wNFQwNzoxNjo0MyswMDowMG17y+kAAAAASUVORK5CYII=) no-repeat center center;
  padding: 35px 0 0 35px;
  display: inline-block;
}

div.icon-house-grey50 {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QAAAAAAAD5Q7t/AAAACXBIWXMAAABgAAAAYADwa0LPAAABKElEQVRIx+2TL0/DQBiHf+9KSNol2GoMX2C2ihrIggA5HF8A2z+iqWj6NvcFcCgUBrNASLakArkvgEHPYtqQXA+zkVFYei0hM33k3Xu/5973ckBPA4ZuoVKKLMti13XPHMd5zvNc6ZwjnaIoivZN07wFMFnJ7sqyvIrj+OPPAiHEUEp5D+C0tjUviuI8juP3zoIkSWzDMKYARltKFlLKcRiGy9YCIcShlPIJwFFDk29VVZ0EQfCqLUjTdEREUwB20whXLJVSY9/3F/WNwS/hx0Q0bxEOADYR5cxcf6fvgizLLonoEcBBi/A1QwAPzDzZXPz6B8x8DeAGwF6H8M28C9d1y9ls9gJseQNm1vpEdTzP+5E36BLUBu1x1G+n2+W/d9ALdi/o2T2fN2paFoIVlu0AAAAldEVYdGRhdGU6Y3JlYXRlADIwMjAtMTItMzBUMTA6MTE6NTcrMDA6MDBw0WWjAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDIwLTEyLTMwVDEwOjExOjU3KzAwOjAwAYzdHwAAAABJRU5ErkJggg==) no-repeat center center;
}

div.icon-house-grey80 {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QAAAAAAAD5Q7t/AAAACXBIWXMAAABgAAAAYADwa0LPAAABEElEQVRIx+2TL0/DQBjGf1caErJkdhqDad2+AYpljtwruksNX4DwEar4CjgUaZozM8tQJAjkZA0GvW9ASMNhRtJsjGs3yEx/8u6553n/5KDDg2qj1VrfAidRFN1kWfb5ZwEicgzcAxMApdSDc+7KWvvhexv4BGma9oDptzmAc84Ac2NMf68OkiQZVFU1A4ZbJIswDMd5ni9bB4jIKfAInHmKfAuC4KIoitfGASIyBGbAwDeCFUtgbK1drF9s7EBEzoGnFuastM9a69GvAVprA8wB7/J+oKeUmorIpH54VKv8Wil1B4Q7mNf9LuM4fi/L8gW278Dt4m6t3fDz/oN9aTyO9eqadvnvHXQBhw/oODxfkNJCAjccYSwAAAAldEVYdGRhdGU6Y3JlYXRlADIwMjAtMTItMzBUMTA6MTI6MjcrMDA6MDCRI9e5AAAAJXRFWHRkYXRlOm1vZGlmeQAyMDIwLTEyLTMwVDEwOjEyOjI3KzAwOjAw4H5vBQAAAABJRU5ErkJggg==) no-repeat center center;
}

div.icon-house-green {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QAAAAAAAD5Q7t/AAAACXBIWXMAAABgAAAAYADwa0LPAAABOklEQVRIx2NgGAUEADOxCv//Z2AUt9Pu8EoQ9zVVeLXr4EGG/8ToYyRG0apVWmxvRBjnMzAwRjEwMDD8Z2BYKvrmX1JY2LVfFFvQvVOPm5vt72qG/4yeaFr3/fnxPTDP684nsi2YvUdH/DcTw1YGBgZjHErOsv5j8E51ufKSZAsmH9RTZP77dwcDA6MaAU/e//eP2SPH5eItoi2Ytk/X+D/D/62MDAzihIKQgYGB4T8Dw0tGBkbvLKfLZ9HlmNAFpu/VcmJg+L+PWMOhrhRnYPh/cNpeXU90ORQLpu/Xjf7PyLSdgYGBj1jDkQA3A+P/DdP26UZhtWDqfp38////L2JgYGAjw3AYYGNg+L9k2n7dciTfYYJp+3SIykToIMvpCoZ5TOQYRApgIdd1xPqS5j4YtWDgLRgFAw8AH5BYVmv+Qa4AAAAldEVYdGRhdGU6Y3JlYXRlADIwMjAtMTItMzBUMTA6MTM6MjUrMDA6MDDpfq2uAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDIwLTEyLTMwVDEwOjEzOjI1KzAwOjAwmCMVEgAAAABJRU5ErkJggg==) no-repeat center center;
}

div.icon-text-grey50 {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QAAAAAAAD5Q7t/AAAACXBIWXMAAABgAAAAYADwa0LPAAAAOUlEQVRIx2NgGAXDHjCiC3R0dPynxMCKigoUM5kG2oeDH4zGwcCD0TgYeEB2HKCHNS4wGgejgHIAAKZ4EA03ayhEAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIwLTEyLTMwVDEwOjI5OjI1KzAwOjAwZ75bZgAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMC0xMi0zMFQxMDoyOToyNSswMDowMBbj49oAAAAASUVORK5CYII=) no-repeat center center;
}

div.icon-text-grey80 {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QAAAAAAAD5Q7t/AAAACXBIWXMAAABgAAAAYADwa0LPAAAAOUlEQVRIx2NgGAXDHjCiC4SGhv6nxMDVq1ejmMk00D4c/GA0DgYejMbBwAOy4wA9rHGB0TgYBZQDABvQEA0asr0QAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIwLTEyLTMwVDEwOjI5OjA2KzAwOjAwFHNGhgAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMC0xMi0zMFQxMDoyOTowNiswMDowMGUu/joAAAAASUVORK5CYII=) no-repeat center center;
}

i.icon-small-ptcLinks {
  font-size: 12px;

  margin-left: 10px;
}
div.icon-small-ptcLinks {
  padding: 15px 0 0 15px;
  background-size: 15px 15px;
  display: inline-block;
  margin-left: 10px;
}

span.icon-small-ptcLinks i {
  font-size: 12px;
  margin-left: 10px;
}

i.chevron {
  margin-right: 10px;
  height: 14px;
}
.header-link {
  cursor: pointer;
  margin-left: 5px;
  line-height: 35px;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  -ms-text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

.infoba-header {
  color: #fff;
  padding: 4px;
  padding-left: 8px;
  padding-right: 10px;
  display: flex;
  justify-content: space-between;
  align-content: center;
  background: #888;
  position: relative;
  margin-top: 20px
}

.infoba-header:first-child {
  margin-top: 0
}

.infoba-header i {
  position: absolute;
  top: 2px;
  line-height: 35px;
  font-size: 24px !important
}

.infoba-header span {
  position: absolute;
  left: 34px;
  line-height: 35px;
  color: #fff;
  cursor: pointer;
  top: 5px
}

.quick-access-item {
  position: relative
}

.infoba-quick-access-item {
  background-color: #fff;
  padding: 20px 10px 20px 35px;
  cursor: auto;
  height: auto
}

.infoba-gray {
  color: #888
}

.infoba-link {
  color: #888 !important;
  cursor: pointer
}

.slide-enter-active {
  -moz-transition-duration: .3s;
  -webkit-transition-duration: .3s;
  -o-transition-duration: .3s;
  transition-duration: .3s;
  -moz-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in
}

.slide-leave-active {
  -moz-transition-duration: .3s;
  -webkit-transition-duration: .3s;
  -o-transition-duration: .3s;
  transition-duration: .3s;
  -moz-transition-timing-function: cubic-bezier(0,1,.5,1);
  -webkit-transition-timing-function: cubic-bezier(0,1,.5,1);
  -o-transition-timing-function: cubic-bezier(0,1,.5,1);
  transition-timing-function: cubic-bezier(0,1,.5,1)
}

.slide-enter-to, .slide-leave {
  max-height: 100px;
  overflow: hidden
}

.slide-enter, .slide-leave-to {
  overflow: hidden;
  max-height: 0
}

.rotate {
  -moz-transition: all .3s linear;
  -webkit-transition: all .3s linear;
  transition: all .3s linear
}

.rotate.up {
  -moz-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg)
}

.rotate.right {
  -moz-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg)
}

.infoba-rotate {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite
}

@-webkit-keyframes rotating {
  from {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg)
  }

  to {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg)
  }
}

@keyframes rotating {
  from {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg)
  }

  to {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg)
  }
}

.infoba-accordeon {
  padding-left: 5px
}

.infoba-accordeon-header {
  line-height: 20px;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  border-bottom: solid 1px
}

.infoba-accordeon-row {
  padding-left: 5px;
  font-size: 12px;
  line-height: 30px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ccc
}

.infoba-row {
  padding-left: 5px;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ccc
}

.infoba-logo {
  height: 35px
}
</style>
