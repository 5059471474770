<template>
  <AulaButtons class="my-1 justify-content-around">
    <template v-if="showRepeatingEventOptions">
      <CalendarEventFooterDropdown class="text-center" :dropup="dropUp">
        <template #button-content>
          {{ 'CALENDAR_ACCEPT_EVENT_BUTTON' | fromTextKey }}
          <i v-if="respondedType === eventResponseStatus.ACCEPTED" class="icon icon-Aula_check" />
        </template>
        <b-dropdown-item-btn
          v-if="showRepeatingOccurrenceOption"
          @click="onRespondToCurrentEvent(eventResponseStatus.ACCEPTED)"
        >
          {{ 'CALENDAR_EDIT_REPEATING_OCCURRENCE' | fromTextKey }}
        </b-dropdown-item-btn>
        <b-dropdown-item-btn
          v-if="showRepeatingSeriesOption"
          @click="onRespondToRepeatingSeries(eventResponseStatus.ACCEPTED)"
        >
          {{ 'CALENDAR_EDIT_REPEATING_SERIES' | fromTextKey }}
        </b-dropdown-item-btn>
      </CalendarEventFooterDropdown>
      <CalendarEventFooterDropdown class="text-center" :dropup="dropUp">
        <template #button-content>
          {{ 'CALENDAR_REJECT_EVENT_BUTTON' | fromTextKey }}
          <i v-if="respondedType === eventResponseStatus.DECLINED" class="icon icon-Aula_check" />
        </template>
        <b-dropdown-item-btn
          v-if="showRepeatingOccurrenceOption"
          @click="onRespondToCurrentEvent(eventResponseStatus.DECLINED)"
        >
          {{ 'CALENDAR_EDIT_REPEATING_OCCURRENCE' | fromTextKey }}
        </b-dropdown-item-btn>
        <b-dropdown-item-btn
          v-if="showRepeatingSeriesOption"
          @click="onRespondToRepeatingSeries(eventResponseStatus.DECLINED)"
        >
          {{ 'CALENDAR_EDIT_REPEATING_SERIES' | fromTextKey }}
        </b-dropdown-item-btn>
      </CalendarEventFooterDropdown>
      <CalendarEventFooterDropdown v-if="showTentativeResponse" class="text-center" :right="isMobile" :dropup="dropUp">
        <template #button-content>
          {{ 'CALENDAR_MAYBE_EVENT_BUTTON' | fromTextKey }}
          <i v-if="respondedType === eventResponseStatus.TENTATIVE" class="icon icon-Aula_check" />
        </template>
        <b-dropdown-item-btn
          v-if="showRepeatingOccurrenceOption"
          @click="onRespondToCurrentEvent(eventResponseStatus.TENTATIVE)"
        >
          {{ 'CALENDAR_EDIT_REPEATING_OCCURRENCE' | fromTextKey }}
        </b-dropdown-item-btn>
        <b-dropdown-item-btn
          v-if="showRepeatingSeriesOption"
          @click="onRespondToRepeatingSeries(eventResponseStatus.TENTATIVE)"
        >
          {{ 'CALENDAR_EDIT_REPEATING_SERIES' | fromTextKey }}
        </b-dropdown-item-btn>
      </CalendarEventFooterDropdown>
    </template>
    <template v-else>
      <AulaButton
        variant="link"
        class="text-center"
        :disabled="disabledAccept"
        @click="onRespondToCurrentEvent(eventResponseStatus.ACCEPTED)"
      >
        <span class="button-text">
          <template v-if="showUpdateAnswer">{{ 'CALENDAR_UPDATE_EVENT_ANSWER_BUTTON' | fromTextKey }}</template>
          <template v-else>
            {{ 'CALENDAR_ACCEPT_EVENT_BUTTON' | fromTextKey }}
            <i v-if="respondedType === eventResponseStatus.ACCEPTED" class="icon icon-Aula_check" />
          </template>
        </span>
      </AulaButton>
      <AulaButton variant="link" class="text-center" @click="onRespondToCurrentEvent(eventResponseStatus.DECLINED)">
        <span class="button-text">
          {{ 'CALENDAR_REJECT_EVENT_BUTTON' | fromTextKey }}
          <i v-if="respondedType === eventResponseStatus.DECLINED" class="icon icon-Aula_check" />
        </span>
      </AulaButton>
      <AulaButton
        v-if="showTentativeResponse"
        variant="link"
        class="text-center"
        @click="onRespondToCurrentEvent(eventResponseStatus.TENTATIVE)"
      >
        <span class="button-text">
          {{ 'CALENDAR_MAYBE_EVENT_BUTTON' | fromTextKey }}
          <i v-if="respondedType === eventResponseStatus.TENTATIVE" class="icon icon-Aula_check" />
        </span>
      </AulaButton>
    </template>
  </AulaButtons>
</template>
<script>
import { eventResponseStatus } from '../../enums/eventResponseStatus';
import CalendarEventFooterDropdown from './CalendarEventFooterDropdown';
import AulaButtons from '../../components/AulaButtons';
import AulaButton from '../../components/AulaButton';
import { types } from '../../../src/store/types/types';
import { mapGetters } from 'vuex';

export default {
  components: { AulaButton, AulaButtons, CalendarEventFooterDropdown },
  props: {
    respondedType: { type: String, default: null },
    showTentativeResponse: { type: Boolean, default: false },
    showRepeatingOccurrenceOption: { type: Boolean, default: true },
    showRepeatingSeriesOption: { type: Boolean, default: true },
    showUpdateAnswer: { type: Boolean, default: false },
    disabledAccept: { type: Boolean, default: false },
    showRepeatingEventOptions: { type: Boolean, default: false },
    dropUp: { type: Boolean, default: false },
  },
  data() {
    return {
      eventResponseStatus,
    };
  },
  computed: {
    ...mapGetters({
      isMobile: types.GET_IS_MOBILE,
    }),
  },
  methods: {
    onRespondToCurrentEvent(responseType) {
      this.$emit('onRespondToCurrentEvent', responseType);
    },
    onRespondToRepeatingSeries(responseType) {
      this.$emit('onRespondToRepeatingSeries', responseType);
    },
  },
};
</script>
