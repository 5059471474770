<template>
  <div class="momo-links momo-containing-block">
    <div class="momo-links-heading">
      <div class="momo-links-heading-text">{{ displayHeaderText() }}</div>
      <img v-if="isDisplaying() && canEdit()"
           :src="urls.editIcon"
           @click="openListingEditableCategoriesView()"
           class="momo-links-edit-button" :alt="TEXT[LANG].EDIT_CATEGORIES">
      <img v-if="isEditing()"
           :src="urls.closeIcon"
           @click="closeCurrentView()"
           class="momo-links-edit-button" :alt="TEXT[LANG].CLOSE">
    </div>
    <transition name="fade">
      <div class="banner-container-centered">
        <div v-if="model.statusBanner.doDisplay" class="momo-links-status-banner"
             v-bind:class="{'momo-links-status-banner-success': model.statusBanner.success, 'momo-links-status-banner-failure': !model.statusBanner.success}">
          <div class="momo-links-status-banner-text">{{ model.statusBanner.text }}</div>
          <img height="26" :src="model.statusBanner.icon" :alt="model.statusBanner.text">
        </div>
      </div>
    </transition>
    <div v-if="model.state === states.failed">
      <div class="momo-links-error-message">{{ model.error }}</div>
    </div>
    <div v-if="model.state === states.displaying && !hasCategoriesForDisplay()" class="momo-links-no-categories">
      <div>{{ TEXT[LANG].NO_LINKS }}</div>
      <div class="momo-links-employee-text" v-if="userProfile === userProfileTypes.employee">
        {{ TEXT[LANG].PENCIL }}
      </div>
    </div>
    <div v-if="model.state === states.displaying && hasCategoriesForDisplay()">
      <div v-for="(category, index) of model.categoriesForDisplay.filter(category => category.links.length > 0)"
           :key="category.id"
           :class="{'momo-links-category-container-top-border': index !== 0}">
        <div class="momo-links-category-title">{{ category.name }}</div>
        <div class="momo-links-links-grid">
          <div v-for="link of category.links" class="momo-links-link" :key="link.id">
            <a v-if="systematic || !link.sso" :href=link.url target="_blank"
               class="momo-links-icon-and-text">
              <img :src="displayImageOrPlaceholderImage(link.imageUrl)"
                   @error="displayPlaceholderImageOnError"
                   class="momo-links-icon" :alt="link.name"/>
              <div class="momo-tooltip">
                <span class="momo-tooltiptext momo-link-tooltiptext">{{ link.name }}</span>
                <div class="momo-links-icon-text">{{ link.name }}</div>
              </div>
            </a>
            <form v-if="!systematic && link.sso" :action=link.url target="_blank" method="post">
              <input hidden name="userProfile" :value="userProfile">
              <input hidden name="institutionFilter" :value="institutionFilter">
              <input hidden name="aulaToken"
                     :id="getSsoAulaTokenParameterElementId(model.state, link.id)">
              <button type="submit" class="momo-links-sso-button momo-links-icon-and-text"
                      @click="setSsoAulaTokenParameterValue(model.state, link.id)">
                <img :src="displayImageOrPlaceholderImage(link.imageUrl)"
                     @error="displayPlaceholderImageOnError"
                     class="momo-links-icon" :alt="link.name"/>
                <div class="momo-tooltip">
                  <span class="momo-tooltiptext momo-link-tooltiptext">{{ link.name }}</span>
                  <div class="momo-links-icon-text">{{ link.name }}</div>
                </div>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div v-if="model.state === states.listingEditableCategories">
      <div class="momo-links-create-category-button-container">
        <button class="momo-links-create-category-button"
                @click="openCreateCategoryView()">{{ TEXT[LANG].NEW_CATEGORY }}
        </button>
      </div>
      <div v-for="category of model.categoriesForEdit" :key="category.id"
           @click="openEditCategoryView(category)" class="momo-category-for-edit-block">
        <div class="momo-category-for-edit-container">
          <div>
            <p class="momo-category-for-edit-heading">{{ category.name }}</p>
            <p class="momo-category-for-edit-text">{{ formatAudience(category) }}</p>
            <p class="momo-category-for-edit-text">{{ formatOrganisationalUnits(category) }}</p>
          </div>
          <img :src="urls.editIcon" @click="openEditCategoryView(category)" class="momo-links-edit-button"
               :alt="TEXT[LANG].EDIT_CATEGORY">
        </div>
      </div>
    </div>
    <div v-if="model.state === states.creatingCategory || model.state === states.editingCategory"
         class="momo-links-category-form">
      <div class="momo-links-wrapper-with-padding">
        <input class="momo-links-input" type="text" v-model="model.categoryForm.name" minlength="1"
               :placeholder="TEXT[LANG].NAME_ELLIPSIS">
      </div>
      <p class="momo-links-target-group-header">{{ TEXT[LANG].RECIPIENTS }}</p>
      <div class="momo-links-form-button-container">
        <button class="momo-audience-button"
                @click="model.categoryForm.audienceEmployees = !model.categoryForm.audienceEmployees"
                :class="{'active':model.categoryForm.audienceEmployees}">{{ TEXT[LANG].EMPLOYEES }}
        </button>
        <button class="momo-audience-button"
                @click="model.categoryForm.audienceStudents = !model.categoryForm.audienceStudents"
                :class="{'active':model.categoryForm.audienceStudents}">{{ TEXT[LANG].STUDENTS }}
        </button>
        <button class="momo-audience-button"
                @click="model.categoryForm.audienceParents = !model.categoryForm.audienceParents"
                :class="{'active':model.categoryForm.audienceParents}">{{ TEXT[LANG].PARENTS }}
        </button>
      </div>
      <div class="momo-links-wrapper-with-padding">
        <el-select v-model="model.categoryForm.associatedOrganisationalUnits" multiple value-key="key"
                   v-on:remove-tag="associatedOrganisationalUnitRemoved" :placeholder="TEXT[LANG].TARGET_GROUP"
                   :popper-append-to-body="false" popper-class="momo-links-select-target-groups-dropdown">
          <el-option-group
            v-if="administrativeUnitItemsForSelector(model.organisationalUnits.administrativeUnits.items, model.categoryForm.category).length > 0"
            :label="model.organisationalUnits.administrativeUnits.label">
            <el-option
              v-for="item in administrativeUnitItemsForSelector(model.organisationalUnits.administrativeUnits.items, model.categoryForm.category)"
              :key="item.key"
              :label="item.name"
              :value="item"
              :disabled="item.disabled">
            </el-option>
          </el-option-group>
          <el-option-group
            v-for="institution in institutionsWithItemsForSelector(model.organisationalUnits.institutions, model.categoryForm.category)"
            :key="institution.key"
            :label="institution.name">
            <el-option
              v-for="item in institutionOrTeamItemsForSelector(institution.items, model.categoryForm.category)"
              :key="item.key"
              :label="item.name"
              :value="item"
              :disabled="item.disabled">
            </el-option>
          </el-option-group>
        </el-select>
      </div>
      <div class="momo-category-form-submit-button-container">
        <button @click="deleteCategory()" v-if="canDeleteCategory()"
                class="momo-category-form-delete-button">
          {{ TEXT[LANG].DELETE }}
        </button>
        <button @click="submitCategoryForm()" :disabled="!categoryFormHasValidInput()"
                class="momo-links-form-save-button">
          {{ model.state === states.creatingCategory ? TEXT[LANG].CREATE : TEXT[LANG].UPDATE }}
        </button>
      </div>
      <div v-if="model.state === states.editingCategory" class="momo-links-category-links-containing-block">
        <p class="momo-links-target-group-header">{{ TEXT[LANG].LINKS }}</p>
        <div class="momo-links-links-grid">
          <div v-for="link of model.categoryForm.category.links" class="momo-links-link momo-containing-block"
               :key="link.id">
            <img class="momo-links-link-icon momo-links-link-delete-icon"
                 :src="urls.removeLinkIcon"
                 @click="removeLinkFromCategory(model.categoryForm.category, link)" :alt="TEXT[LANG].DELETE_LINK"/>
            <img class="momo-links-link-icon momo-links-link-edit-icon"
                 :src="urls.editLinkIcon" @click="openEditLinkView(model.categoryForm.category, link)"
                 :alt="TEXT[LANG].EDIT_LINK"/>
            <a v-if="systematic || !link.sso" :href=link.url target="_blank"
               class="momo-links-icon-and-text">
              <img :src="displayImageOrPlaceholderImage(link.imageUrl)"
                   @error="displayPlaceholderImageOnError"
                   class="momo-links-icon" :alt="link.name"/>
              <div class="momo-tooltip">
                <div class="momo-links-icon-text">{{ link.name }}</div>
              </div>
            </a>
            <form v-if="!systematic && link.sso" :action=link.url target="_blank" method="post">
              <input hidden name="userProfile" :value="userProfile">
              <input hidden name="institutionFilter" :value="institutionFilter">
              <input hidden name="aulaToken"
                     :id="getSsoAulaTokenParameterElementId(model.state, link.id)">
              <button type="submit" class="momo-links-sso-button momo-links-icon-and-text"
                      @click="setSsoAulaTokenParameterValue(model.state, link.id)">
                <img :src="displayImageOrPlaceholderImage(link.imageUrl)"
                     @error="displayPlaceholderImageOnError"
                     class="momo-links-icon" :alt="link.name"/>
                <div class="momo-tooltip">
                  <div class="momo-links-icon-text">{{ link.name }}</div>
                </div>
              </button>
            </form>
          </div>
          <div class="momo-links-link">
            <img :src="urls.addLinkIcon" class="momo-links-icon"
                 @click="openCreateLinkView(model.categoryForm.category)" :alt="TEXT[LANG].CREATE_LINK"/>
            <div class="momo-links-icon-text">{{ TEXT[LANG].CREATE_LINK }}</div>
          </div>
        </div>
      </div>
      <div class="momo-links-category-create-spacer" v-if="model.state === states.creatingCategory"></div>
    </div>
    <div v-if="model.state === states.creatingLink || model.state === states.editingLink"
         class="momo-links-link-form">
      <input class="momo-links-input" type="text" v-model="model.linkForm.url"
             minlength="1"
             :placeholder="TEXT[LANG].LINK_ELLIPSIS" v-on:change="getLinkMetadata()">
      <input class="momo-links-input" type="text" v-model="model.linkForm.name"
             minlength="1"
             :placeholder="TEXT[LANG].NAME_ELLIPSIS">
      <input class="momo-links-input" type="text" v-model="model.linkForm.imageUrl"
             minlength="1"
             :placeholder="TEXT[LANG].INSERT_PICTURE_MESSAGE">
      <div class="momo-links-sso-link-checkbox">
        <div class="momo-tooltip">
                  <span class="momo-tooltiptext momo-sso-tooltiptext">
                    {{ TEXT[LANG].SSO_CHECK_MESSAGE }}
                  </span>
          <input type="checkbox" v-model="model.linkForm.sso">
        </div>
        <p class="momo-links-sso-link-text">{{ TEXT[LANG].SSO_LINK }}</p>
      </div>
      <div class="momo-links-example-container">
        <div class="momo-links-example">
          <p class="momo-links-example-text">{{ TEXT[LANG].EXAMPLE }}</p>
          <img class="momo-links-example-image"
               :src="displayImageOrPlaceholderImageOnWhitebackground(model.linkForm.imageUrl)"
               @error="displayPlaceholderImageOnWhitebackground" :alt="TEXT[LANG].EXAMPLE_LINK_ICON">
          <div class="momo-tooltip">
            <span class="momo-tooltiptext momo-link-tooltiptext">{{ model.linkForm.name }}</span>
            <div class="momo-links-example-text">{{ model.linkForm.name }}</div>
          </div>
        </div>
      </div>
      <div class="momo-links-form-submit-button-container">
        <button class="momo-links-form-cancel-button" @click="cancelCreatingLink()">{{ TEXT[LANG].CANCEL }}
        </button>
        <button class="momo-links-form-save-button" @click="submitLinkForm()"
                :disabled="!linkFormHasValidInput()">
          {{ TEXT[LANG].SAVE }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    axios: Function,
    moment: Function,
    sessionUUID: String,
    placement: String,
    isMobileApp: Boolean,
    userProfile: String,
    getAulaToken: Function,
    assuranceLevel: Number,
    childFilter: Array,
    institutionFilter: Array,
    group: String,
    language: String,
    systematic: Object,
  },

  data: function () {
    const moMoBackendRootUrl = this.systematic && this.systematic.moMoFrontendUrl ? this.systematic.moMoFrontendUrl : 'https://systematic-momo.dk';
    const moMoFrontendRootUrl = this.systematic && this.systematic.moMoFrontendUrl ? this.systematic.moMoFrontendUrl : 'https://systematic-momo.dk';
    const apiVersion = 'v3';
    const linksApiRootUrl = `${moMoBackendRootUrl}/api/aula/links/${apiVersion}`;

    return {
      widgetVersion: '1.12',
      aulaToken: null,
      urls: {
        categoriesForDisplayUrl: `${linksApiRootUrl}/categories`,
        editableContentUrl: `${linksApiRootUrl}/editable-content`,
        categoryUrl: `${linksApiRootUrl}/category`,
        linkMetadataUrl: `${linksApiRootUrl}/link-metadata`,
        moMoBackendRootUrl: moMoBackendRootUrl,
        editIcon: moMoFrontendRootUrl + '/assets/images/aula/pen-no-opacity.svg',
        closeIcon: moMoFrontendRootUrl + '/assets/images/aula/cross.svg',
        successIcon: moMoFrontendRootUrl + '/assets/images/aula/check-mark-in-circle.svg',
        failureIcon: moMoFrontendRootUrl + '/assets/images/aula/exclamation-mark-in-circle.svg',
        addLinkIcon: moMoFrontendRootUrl + '/assets/images/aula/plus-in-square.svg',
        removeLinkIcon: moMoFrontendRootUrl + '/assets/images/aula/cross-in-red-circle.svg',
        editLinkIcon: moMoFrontendRootUrl + '/assets/images/aula/pencil-in-blue-circle.svg',
        searchFieldIcon: moMoFrontendRootUrl + '/assets/images/aula/search.svg',
        placeHolderImageIcon: moMoFrontendRootUrl + '/assets/images/aula/placeholder-image-on-grey-background.svg',
        placeHolderImageOnWhiteBackgroundIcon: moMoFrontendRootUrl + '/assets/images/aula/placeholder-image-on-white-background.svg'
      },
      states: {
        loading: 0,
        displaying: 1,
        failed: 2,
        listingEditableCategories: 3,
        creatingCategory: 4,
        editingCategory: 5,
        creatingLink: 6,
        editingLink: 7
      },
      userProfileTypes: {
        child: 'child',
        employee: 'employee',
        guardian: 'guardian'
      },
      organisationalUnitTypes: {
        administrativeUnit: 0,
        institution: 1,
        team: 2
      },
      audienceTypes: {
        EMPLOYEES: 'EMPLOYEES',
        STUDENTS: 'STUDENTS',
        PARENTS: 'PARENTS'
      },
      model: {
        categoryToAddLinkTo: null,
        categoryForm: null,
        linkForm: null,
        categoriesForDisplay: [],
        categoriesForEdit: [],
        error: '',
        state: 0,
        stackedState: null,
        organisationalUnits: {administrativeUnits: {label: '', items: []}, institutions: []},
        statusBanner: {
          doDisplay: false,
          text: '',
          icon: null,
          success: true
        },
      },
      TEXT: {
        DA: {
          CREATE_CATEGORY: 'Opret kategori',
          EDIT_CATEGORY: 'Rediger kategori',
          LINKS: 'Links',
          CATEGORIES: 'Kategorier',
          CREATE_LINK: 'Opret link',
          EDIT_LINK: 'Rediger link',
          GENERAL_ERROR_BANNER_TEXT: 'Der er desværre opstået en fejl',
          LOGGED_IN_REQUIRED: 'Du skal være logget ind for at se links.',
          SOMETHING_WENT_WRONG: 'Noget gik galt. Prøv igen senere.',
          ADDED: 'er tilføjet',
          UPDATED: 'er opdateret',
          REMOVED: 'fjernet',
          ADDED_TO: 'tilføjet til',
          NEW_CATEGORY: 'Opret ny kategori',
          SAVE: 'Gem',
          CANCEL: 'Annuller',
          EXAMPLE: 'Eksempel',
          SSO_LINK: 'Single sign-on link',
          SSO_CHECK_MESSAGE: 'Skal som udgangspunkt ikke markeres. Marker kun, hvis linket understøtter single sign-on.',
          INSERT_PICTURE_MESSAGE: 'Indsæt evt. webadresse på billede...',
          LINK_ELLIPSIS: 'Link...',
          NAME_ELLIPSIS: 'Titel...',
          SEARCH_LINK_ELLIPSIS: 'Søg efter link...',
          CREATE_NEW_LINK: 'Opret nyt link',
          CREATE: 'Opret',
          UPDATE: 'Opdater',
          DELETE: 'Slet',
          TARGET_GROUP: 'Målgruppe',
          PARENTS: 'Forældre',
          STUDENTS: 'Elever',
          EMPLOYEES: 'Medarbejdere',
          RECIPIENTS: 'Modtagere',
          PENCIL: 'Tryk på blyanten oppe i højre hjørne for at tilføje links eller kategorier',
          NO_LINKS: 'Ingen links..',
          ADMINISTRATIVE_UNITS: 'Kommuner',
          ALL_OF: 'Hele',
          YOU_DO_NOT_HAVE_THE_RIGHT_TO_REMOVE: 'Du har ikke ret til at fjerne',
          DELETE_LINK: 'Fjern link',
          EDIT_CATEGORIES: 'Rediger kategorier',
          CLOSE: 'Afslut redigering',
          EXAMPLE_LINK_ICON: 'Link ikon'
        },
        EN: {
          CREATE_CATEGORY: 'Create category',
          EDIT_CATEGORY: 'Edit category',
          LINKS: 'Links',
          CATEGORIES: 'Categories',
          CREATE_LINK: 'Create link',
          EDIT_LINK: 'Edit link',
          GENERAL_ERROR_BANNER_TEXT: 'Unfortunately, an error has occurred',
          LOGGED_IN_REQUIRED: 'You need to be logged in to see links.',
          SOMETHING_WENT_WRONG: 'Something went wrong. Try again later.',
          ADDED: 'has been added',
          UPDATED: 'has been updated',
          REMOVED: 'removed',
          ADDED_TO: 'added to',
          NEW_CATEGORY: 'Create new category',
          SAVE: 'Save',
          CANCEL: 'Cancel',
          EXAMPLE: 'Example',
          SSO_LINK: 'Single sign-on link',
          SSO_CHECK_MESSAGE: 'Should not be checked by default. Check only if the link supports single sign-on.',
          INSERT_PICTURE_MESSAGE: 'Optionally insert a web address to a picture...',
          LINK_ELLIPSIS: 'Link...',
          NAME_ELLIPSIS: 'Title...',
          SEARCH_LINK_ELLIPSIS: 'Search for link...',
          CREATE_NEW_LINK: 'Create new link',
          CREATE: 'Create',
          UPDATE: 'Update',
          DELETE: 'Delete',
          TARGET_GROUP: 'Target audience',
          PARENTS: 'Parents',
          STUDENTS: 'Students',
          EMPLOYEES: 'Employees',
          RECIPIENTS: 'Recipients',
          PENCIL: 'Press the pencil in the upper right corner to add links or categories',
          NO_LINKS: 'No links...',
          ADMINISTRATIVE_UNITS: 'Municipalities',
          ALL_OF: 'All of',
          YOU_DO_NOT_HAVE_THE_RIGHT_TO_REMOVE: 'You do not have the right to remove',
          DELETE_LINK: 'Remove link',
          EDIT_CATEGORIES: 'Edit categories',
          CLOSE: 'Close edit mode',
          EXAMPLE_LINK_ICON: 'Link icon'
        }
      }
    }
  },

  computed:
    {
      LANG: function () {
        if (this.language && this.language.toUpperCase() === 'EN') {
          return this.language.toUpperCase();
        } else {
          return 'DA';
        }
      }
    },

  methods: {
    /** STATE MANAGEMENT FUNCTIONS BEGIN **/
    load() {
      this.model.categoriesForDisplay = [];
      this.model.categoriesForEdit = [];
      this.resetTargetOrganisationalUnits();
      this.model.error = '';
      if (![2, 3].includes(this.assuranceLevel)) {
        this.model.state = this.states.failed;
        this.model.error = this.TEXT[this.LANG].LOGGED_IN_REQUIRED;
      } else {
        this.model.state = this.states.loading;
        this.fetchCategoriesForDisplay(this.states.displaying);
        if (this.userProfile === this.userProfileTypes.employee) {
          this.fetchEditableContent(this.states.displaying);
        }
      }
    },

    openListingEditableCategoriesView() {
      this.model.state = this.states.listingEditableCategories;
    },

    openEditCategoryView(category) {
      this.model.categoryForm = {
        category: category,
        name: category.name,
        audienceEmployees: category.audience.includes(this.audienceTypes.EMPLOYEES),
        audienceStudents: category.audience.includes(this.audienceTypes.STUDENTS),
        audienceParents: category.audience.includes(this.audienceTypes.PARENTS),
        associatedOrganisationalUnits: this.getAssociatedOrganisationalUnits(category.administrationIds, category.institutionIds, category.teamIds)
      };
      this.model.state = this.states.editingCategory;
    },

    resetCategoryForm() {
      const emptyCategory = {
        id: null,
        name: '',
        audience: [],
        administrationIds: [],
        institutionIds: [],
        teamIds: [],
        links: []
      };
      this.model.categoryForm = {
        category: emptyCategory,
        name: '',
        audienceEmployees: false,
        audienceStudents: false,
        audienceParents: false,
        associatedOrganisationalUnits: []
      };
    },

    openCreateCategoryView() {
      this.resetCategoryForm();
      this.model.state = this.states.creatingCategory;
    },

    closeCurrentView() {
      this.resetCategoryForm();
      if (this.model.state === this.states.listingEditableCategories) {
        this.load();
      } else {
        this.openListingEditableCategoriesView();
      }
    },

    openCreateLinkView(category) {
      this.model.categoryToAddLinkTo = category;
      this.model.linkForm = {
        url: '',
        name: '',
        imageUrl: '',
        sso: false
      };
      this.model.stackedState = this.model.state;
      this.model.state = this.states.creatingLink;
    },

    openEditLinkView(category, link) {
      this.model.categoryToAddLinkTo = category;
      this.model.linkForm = {
        id: link.id,
        url: link.url,
        name: link.name,
        imageUrl: link.imageUrl,
        sso: link.sso
      };
      this.model.stackedState = this.model.state;
      this.model.state = this.states.editingLink;
    },

    cancelCreatingLink() {
      this.model.linkForm = null;
      this.model.state = this.model.stackedState;
      this.model.stackedState = null;
    },

    /** STATE MANAGEMENT FUNCTIONS END **/

    /** BACKEND COMMUNICATION FUNCTIONS BEGIN **/
    fetchCategoriesForDisplay(nextState) {
      this.axios.get(
        this.urls.categoriesForDisplayUrl + '?' +
        this.createTraceParameters(),
        this.createHeaders()
      ).then(response => {
        this.model.categoriesForDisplay = response.data;
        this.model.state = nextState;
      }).catch(() => {
        this.model.error = this.TEXT[this.LANG].SOMETHING_WENT_WRONG;
        this.model.state = this.states.failed;
      })
    },

    fetchEditableContent(nextState) {
      this.axios.get(
        this.urls.editableContentUrl + '?' +
        this.createTraceParameters(),
        this.createHeaders()
      ).then(response => {
        this.setCategoriesForEdit(response.data.categories);
        this.setOrganisationalUnits(response.data.targetOrganisationalUnits, response.data.nonTargetOrganisationalUnits);
        this.model.state = nextState;
      }).catch(() => {
        this.model.error = this.TEXT[this.LANG].SOMETHING_WENT_WRONG;
        this.model.state = this.states.failed;
      })
    },

    createQueryParameter(key, items) {
      if (items) {
        const trimmed = items.map(s => s.trim()).filter(s => !!s);
        if (trimmed.length > 0) {
          return key + '=' + trimmed.join('&' + key + '=');
        }
      }
      return '';
    },

    createTraceParameters() {
      return 'widgetVersion=' + this.widgetVersion +
        '&userProfile=' + this.userProfile +
        '&sessionId=' + this.sessionUUID + '&' +
        this.createQueryParameter('institutions', this.institutionFilter)
    },

    createHeaders() {
      return {
        headers: {
          'Aula-Authorization': 'Bearer ' + this.aulaToken
        }
      }
    },

    submitCategoryForm() {
      if (this.model.state === this.states.creatingCategory) {
        this.axios.post(
          this.urls.categoryUrl + '?' +
          this.createTraceParameters(),
          this.createCategoryPayload(),
          this.createHeaders()
        ).then(response => {
          this.model.categoriesForEdit.push(response.data);
          this.model.categoriesForEdit = this.sortByName(this.model.categoriesForEdit);
          this.openEditCategoryView(response.data);
          this.displayBanner(true, `${response.data.name} ${this.TEXT[this.LANG].ADDED}`);
        }).catch(() => {
          this.displayBanner(false, this.TEXT[this.LANG].GENERAL_ERROR_BANNER_TEXT);
        })
      } else if (this.model.state === this.states.editingCategory) {
        this.axios.put(
          this.urls.categoryUrl + '/' + this.model.categoryForm.category.id + '?' +
          this.createTraceParameters(),
          this.createCategoryPayload(),
          this.createHeaders()
        ).then(response => {
          this.model.categoriesForEdit = this.model.categoriesForEdit.filter(category => category.id !== response.data.id);
          this.model.categoriesForEdit.push(response.data);
          this.model.categoriesForEdit = this.sortByName(this.model.categoriesForEdit);
          this.model.state = this.states.listingEditableCategories;
          this.resetCategoryForm();
          this.displayBanner(true, `${response.data.name} ${this.TEXT[this.LANG].UPDATED}`);
        }).catch(() => {
          this.displayBanner(false, this.TEXT[this.LANG].GENERAL_ERROR_BANNER_TEXT);
        })
      }
    },

    deleteCategory() {
      if (this.model.state === this.states.editingCategory) {
        const categoryId = this.model.categoryForm.category.id;
        const categoryName = this.model.categoryForm.name;
        this.axios.delete(
          this.urls.categoryUrl + '/' + categoryId + '?' + this.createTraceParameters(),
          this.createHeaders())
          .then(() => {
            this.model.categoriesForDisplay = this.model.categoriesForDisplay.filter(category => category.id !== categoryId);
            this.model.categoriesForEdit = this.model.categoriesForEdit.filter(category => category.id !== categoryId);
            this.model.state = this.states.listingEditableCategories;
            this.resetCategoryForm();
            this.displayBanner(true, `${categoryName} ${this.TEXT[this.LANG].REMOVED}`);
          }).catch(() => {
          this.displayBanner(false, this.TEXT[this.LANG].GENERAL_ERROR_BANNER_TEXT);
        })
      }
    },

    submitLinkForm() {
      if (this.model.state === this.states.creatingLink) {
        this.axios.post(
          this.urls.categoryUrl + '/' + this.model.categoryToAddLinkTo.id + '/link?' +
          this.createTraceParameters(),
          this.createPostPutBodyForALink(),
          this.createHeaders()
        ).then(response => {
          const categoryForEdit = this.model.categoriesForEdit.find(c => c.id === this.model.categoryToAddLinkTo.id);
          const categoryForDisplay = this.model.categoriesForDisplay.find(c => c.id === this.model.categoryToAddLinkTo.id);
          categoryForEdit.links.push(response.data);
          categoryForEdit.links = this.sortByName(categoryForEdit.links);
          if (categoryForDisplay) {
            categoryForDisplay.links.push(response.data);
            categoryForDisplay.links = this.sortByName(categoryForDisplay.links);
          }
          this.model.state = this.model.stackedState;
          this.model.stackedState = null;
          this.displayBanner(true, `${response.data.name} ${this.TEXT[this.LANG].ADDED_TO} ${this.model.categoryToAddLinkTo.name}`);
        }).catch(error => {
          this.displayBanner(false, this.TEXT[this.LANG].GENERAL_ERROR_BANNER_TEXT);
        })
      } else if (this.model.state === this.states.editingLink) {
        this.axios.put(
          this.urls.categoryUrl + '/' + this.model.categoryToAddLinkTo.id + '/link/' + this.model.linkForm.id + '?' +
          this.createTraceParameters(),
          this.createPostPutBodyForALink(),
          this.createHeaders()
        ).then(response => {
          const categoryForEdit = this.model.categoriesForEdit.find(c => c.id === this.model.categoryToAddLinkTo.id);
          const categoryForDisplay = this.model.categoriesForDisplay.find(c => c.id === this.model.categoryToAddLinkTo.id);
          this.updateLink(categoryForEdit, this.model.linkForm.id, response.data);
          if (categoryForDisplay) {
            this.updateLink(categoryForDisplay, this.model.linkForm.id, response.data);
          }
          this.model.state = this.model.stackedState;
          this.model.stackedState = null;
          this.displayBanner(true, `${response.data.name} ${this.TEXT[this.LANG].UPDATED}`);
        }).catch(error => {
          this.displayBanner(false, this.TEXT[this.LANG].GENERAL_ERROR_BANNER_TEXT);
        })
      }
    },

    updateLink(category, linkId, updatedLink) {
      const existingLink = category.links.find(l => l.id === linkId);
      existingLink.name = updatedLink.name;
      existingLink.url = updatedLink.url;
      existingLink.imageUrl = updatedLink.imageUrl;
      existingLink.sso = updatedLink.sso;
      category.links = this.sortByName(category.links);
    },

    removeLinkFromCategory(category, link) {
      this.axios.delete(
        this.urls.categoryUrl + '/' + category.id + '/link/' + link.id + '?' +
        this.createTraceParameters(),
        this.createHeaders()
      ).then(() => {
        const editCategoryToRemoveLinkFrom = this.model.categoriesForEdit.find(c => c.id === category.id);
        const displayCategoryToRemoveLinkFrom = this.model.categoriesForDisplay.find(c => c.id === category.id);
        editCategoryToRemoveLinkFrom.links = editCategoryToRemoveLinkFrom.links.filter(l => l.id !== link.id);
        if (displayCategoryToRemoveLinkFrom) {
          displayCategoryToRemoveLinkFrom.links = displayCategoryToRemoveLinkFrom.links.filter(l => l.id !== link.id);
        }
        this.displayBanner(true, `${link.name} ${this.TEXT[this.LANG].REMOVED}`);
      }).catch(() => {
        this.displayBanner(false, this.TEXT[this.LANG].GENERAL_ERROR_BANNER_TEXT);
      })
    },

    createPostPutBodyForALink() {
      return {
        name: this.model.linkForm.name,
        url: this.addHypertextProtocolPrefix(this.model.linkForm.url),
        imageUrl: this.model.linkForm.imageUrl,
        sso: this.model.linkForm.sso
      };
    },

    addHypertextProtocolPrefix(url) {
      if (!(url.startsWith('http://') || url.startsWith('https://')) && (url.startsWith('www.') || url.search('\\.') !== -1)) {
        url = 'https://' + url;
      }
      return url;
    },

    createCategoryPayload() {
      let audience = [];
      if (this.model.categoryForm.audienceEmployees) {
        audience.push(this.audienceTypes.EMPLOYEES);
      }
      if (this.model.categoryForm.audienceStudents) {
        audience.push(this.audienceTypes.STUDENTS);
      }
      if (this.model.categoryForm.audienceParents) {
        audience.push(this.audienceTypes.PARENTS);
      }
      return {
        name: this.model.categoryForm.name,
        audience: audience,
        administrationIds: this.getAssociatedAdministrationIds(),
        institutionIds: this.getAssociatedInstitutionIds(),
        teamIds: this.getAssociatedTeamIds(),
      };
    },

    getAssociatedAdministrationIds() {
      return this.model.categoryForm.associatedOrganisationalUnits.filter(u => u.type === this.organisationalUnitTypes.administrativeUnit).map(u => u.id)
    },

    getAssociatedInstitutionIds() {
      return this.model.categoryForm.associatedOrganisationalUnits.filter(u => u.type === this.organisationalUnitTypes.institution).map(u => u.id);
    },

    getAssociatedTeamIds() {
      return this.model.categoryForm.associatedOrganisationalUnits.filter(u => u.type === this.organisationalUnitTypes.team).map(u => u.id);
    },


    getLinkMetadata() {
      this.axios.get(
        this.urls.linkMetadataUrl + '?' +
        this.createTraceParameters() +
        '&link=' + encodeURIComponent(this.addHypertextProtocolPrefix(this.model.linkForm.url)),
        this.createHeaders()
      ).then((response) => {
        if (response.status === 200) {
          this.model.linkForm.name = this.model.linkForm.name && this.model.linkForm.name.length > 0 ? this.model.linkForm.name : response.data.title;

          if (response.data.image.optimizedUrl && response.data.image.url && !(this.model.linkForm.imageUrl && this.model.linkForm.imageUrl.length > 0)) {
            if (response.data.image.optimizedUrl === response.data.image.url) {
              this.model.linkForm.imageUrl = response.data.image.optimizedUrl;
            } else {
              this.model.linkForm.imageUrl = `${this.urls.moMoBackendRootUrl}${response.data.image.optimizedUrl}`;
            }
          }
        }
      })
    },
    /** BACKEND COMMUNICATION FUNCTIONS END **/

    /** PROCESSING FUNCTIONS BEGIN **/
    setCategoriesForEdit(categories) {
      this.model.categoriesForEdit = categories;
    },

    resetTargetOrganisationalUnits() {
      const empty = {
        targetAdministrativeUnits: [],
        targetInstitutions: [],
        targetTeams: []
      };
      this.setOrganisationalUnits(empty, empty);
    },

    setOrganisationalUnits(targetOrganisationalUnitsFromBackend, nonTargetOrganisationalUnitsFromBackend) {
      this.model.organisationalUnits.administrativeUnits = this.targetAdministrativeUnits(targetOrganisationalUnitsFromBackend.targetAdministrativeUnits, nonTargetOrganisationalUnitsFromBackend.targetAdministrativeUnits);
      this.model.organisationalUnits.institutions = this.targetInstitutions(
        targetOrganisationalUnitsFromBackend.targetInstitutions,
        nonTargetOrganisationalUnitsFromBackend.targetInstitutions,
        targetOrganisationalUnitsFromBackend.targetTeams,
        nonTargetOrganisationalUnitsFromBackend.targetTeams);
    },

    targetAdministrativeUnits(targetAdministrativeUnitsFromBackend, nonTargetAdministrativeUnitsFromBackend) {
      return {
        label: this.TEXT[this.LANG].ADMINISTRATIVE_UNITS,
        items: [
          ...targetAdministrativeUnitsFromBackend.map(administrativeUnit => this.makeAdministrativeUnitItem(administrativeUnit, false)),
          ...nonTargetAdministrativeUnitsFromBackend.map(administrativeUnit => this.makeAdministrativeUnitItem(administrativeUnit, true))
        ]
      };
    },


    makeAdministrativeUnitItem(administrativeUnit, disabled) {
      return {
        id: administrativeUnit.id,
        key: this.uniqueKey(this.organisationalUnitTypes.administrativeUnit, administrativeUnit.id),
        name: administrativeUnit.name,
        type: this.organisationalUnitTypes.administrativeUnit,
        disabled
      }
    },

    targetInstitutions(targetInstitutionsFromBackend, nonTargetInstitutionsFromBackend, targetTeamsFromBackend, nonTargetTeamsFromBackend) {
      let institutions = this.removeDuplicates([
        ...targetInstitutionsFromBackend.map(institution => this.makeInstitutionGroupFromInstitution(institution)),
        ...nonTargetInstitutionsFromBackend.map(institution => this.makeInstitutionGroupFromInstitution(institution)),
        ...targetTeamsFromBackend.map(team => this.makeInstitutionGroupFromTeam(team)),
        ...nonTargetTeamsFromBackend.map(team => this.makeInstitutionGroupFromTeam(team))
      ]);

      institutions.forEach(institution => {
        this.addInstitutionItem(institution, targetInstitutionsFromBackend, false);
        this.addInstitutionItem(institution, nonTargetInstitutionsFromBackend, true);
        this.addTeamItems(institution, targetTeamsFromBackend, false);
        this.addTeamItems(institution, nonTargetTeamsFromBackend, true);
      });
      return institutions;
    },

    makeInstitutionGroupFromInstitution(institution) {
      return {
        id: institution.id,
        key: this.uniqueKey(this.organisationalUnitTypes.institution, institution.id),
        name: institution.name,
        items: []
      };
    },

    makeInstitutionGroupFromTeam(team) {
      return {
        id: team.institutionId,
        key: this.uniqueKey(this.organisationalUnitTypes.institution, team.institutionId),
        name: team.institutionName,
        items: []
      };
    },

    addInstitutionItem(institution, institutionsFromBackend, disabled) {
      if (institutionsFromBackend.find(institutionFromBackend => institutionFromBackend.id === institution.id)) {
        institution.items.push(
          {
            id: institution.id,
            key: this.uniqueKey(this.organisationalUnitTypes.institution, institution.id),
            name: `${this.TEXT[this.LANG].ALL_OF} ${institution.name}`,
            type: this.organisationalUnitTypes.institution,
            disabled
          }
        )
      }
    },

    addTeamItems(institution, teamsFromBackend, disabled) {
      teamsFromBackend.forEach(teamFromBackend => {
        if (teamFromBackend.institutionId === institution.id) {
          institution.items.push(
            {
              id: teamFromBackend.id,
              key: this.uniqueKey(this.organisationalUnitTypes.team, teamFromBackend.id),
              name: teamFromBackend.name,
              type: this.organisationalUnitTypes.team,
              disabled
            }
          )
        }
      })
    },

    uniqueKey(organisationalUnitType, id) {
      return `${organisationalUnitType}:${id}`;
    },

    removeDuplicates(institutions) {
      let noDuplicates = [];
      institutions.forEach(institution => {
        if (!noDuplicates.find(i => i.id === institution.id)) {
          noDuplicates.push(institution);
        }
      });
      return noDuplicates;
    },

    getAssociatedOrganisationalUnits(administrationIds, institutionIds, teamIds) {
      return [
        ...this.model.organisationalUnits.administrativeUnits.items.filter(item => administrationIds.includes(item.id)),
        ...this.model.organisationalUnits.institutions.flatMap(institution => institution.items).filter(item =>
          item.type === this.organisationalUnitTypes.institution && institutionIds.includes(item.id)
          ||
          item.type === this.organisationalUnitTypes.team && teamIds.includes(item.id))
      ];
    },

    getSsoAulaTokenParameterElementId(state, linkId) {
      return `moMoSsoAulaTokenParameterElement-${state}-${linkId}`;
    },

    setSsoAulaTokenParameterValue(state, linkId) {
      document.getElementById(this.getSsoAulaTokenParameterElementId(state, linkId)).value = this.aulaToken;
    },

    sortByName(items) {
      return items.sort((l1, l2) => l1.name.localeCompare(l2.name));
    },

    associatedOrganisationalUnitRemoved(removedOrganisationalUnit) {
      const associatedAdministrationIds = this.getAssociatedAdministrationIds();
      const associatedInstitutionIds = this.getAssociatedInstitutionIds();
      const associatedTeamIds = this.getAssociatedTeamIds();

      if (removedOrganisationalUnit.disabled) {
        this.displayBanner(false, `${this.TEXT[this.LANG].YOU_DO_NOT_HAVE_THE_RIGHT_TO_REMOVE} ${removedOrganisationalUnit.name}`);
        switch (removedOrganisationalUnit.type) {
          case this.organisationalUnitTypes.administrativeUnit:
            associatedAdministrationIds.push(removedOrganisationalUnit.id);
            break;
          case this.organisationalUnitTypes.institution:
            associatedInstitutionIds.push(removedOrganisationalUnit.id);
            break;
          case this.organisationalUnitTypes.team:
            associatedTeamIds.push(removedOrganisationalUnit.id);
            break;
        }
      }
      this.model.categoryForm.associatedOrganisationalUnits = this.getAssociatedOrganisationalUnits(associatedAdministrationIds, associatedInstitutionIds, associatedTeamIds);
    },
    /** PROCESSING FUNCTIONS END **/

    /** DISPLAY FUNCTIONS BEGIN **/
    administrativeUnitItemsForSelector(administrativeUnitsItems, category) {
      return administrativeUnitsItems.filter(item => !item.disabled || category.administrationIds.includes(item.id));
    },

    institutionsWithItemsForSelector(institutions, category) {
      return institutions.filter(institution => this.institutionOrTeamItemsForSelector(institution.items, category).length > 0)
    },

    institutionOrTeamItemsForSelector(institutionOrTeamItems, category) {
      return institutionOrTeamItems.filter(item =>
        !item.disabled
        ||
        item.type === this.organisationalUnitTypes.institution && category.institutionIds.includes(item.id)
        ||
        item.type === this.organisationalUnitTypes.team && category.teamIds.includes(item.id)
      )
    },

    displayHeaderText() {
      switch (this.model.state) {
        case this.states.displaying:
        case this.states.failed:
        case this.states.loading:
          return this.TEXT[this.LANG].LINKS;
        case this.states.listingEditableCategories:
          return this.TEXT[this.LANG].CATEGORIES;
        case this.states.creatingCategory:
          return this.TEXT[this.LANG].CREATE_CATEGORY;
        case this.states.editingCategory:
          return this.TEXT[this.LANG].EDIT_CATEGORY;
        case this.states.creatingLink:
          return this.TEXT[this.LANG].CREATE_LINK;
        case this.states.editingLink:
          return this.TEXT[this.LANG].EDIT_LINK;

      }
    },

    categoryFormHasValidInput() {
      return this.model.categoryForm.name !== '' &&
        this.model.categoryForm.associatedOrganisationalUnits.length > 0 &&
        (this.model.categoryForm.audienceEmployees || this.model.categoryForm.audienceStudents || this.model.categoryForm.audienceParents);
    },

    linkFormHasValidInput() {
      return this.model.linkForm.url !== '' && this.model.linkForm.name !== '';
    },

    canEdit() {
      return this.hasTargetAdministrativeUnits() || this.hasTargetInstitutionsOrTeams();
    },

    canDeleteCategory() {
      return this.model.state === this.states.editingCategory
        &&
        this.model.categoryForm.category.administrationIds.every(administrationId =>
          this.model.organisationalUnits.administrativeUnits.items.find(item => item.id === administrationId && !item.disabled))
        &&
        this.model.categoryForm.category.institutionIds.every(institutionId =>
          this.model.organisationalUnits.institutions.flatMap(i => i.items).find(item => item.type === this.organisationalUnitTypes.institution && item.id === institutionId && !item.disabled))
        &&
        this.model.categoryForm.category.teamIds.every(teamId =>
          this.model.organisationalUnits.institutions.flatMap(i => i.items).find(item => item.type === this.organisationalUnitTypes.team && item.id === teamId && !item.disabled))
    },

    isEditing() {
      return [this.states.listingEditableCategories, this.states.creatingCategory, this.states.editingCategory, this.states.creatingLink, this.states.editingLink].includes(this.model.state);
    },

    isDisplaying() {
      return [this.states.displaying, this.states.failed, this.states.loading].includes(this.model.state);
    },

    displayImageOrPlaceholderImage(imageUrl) {
      return imageUrl || this.urls.placeHolderImageIcon;
    },

    displayImageOrPlaceholderImageOnWhitebackground(imageUrl) {
      return imageUrl || this.urls.placeHolderImageOnWhiteBackgroundIcon;
    },

    displayPlaceholderImageOnWhitebackground(event) {
      event.target.src = this.urls.placeHolderImageOnWhiteBackgroundIcon;
    },

    displayPlaceholderImageOnError(event) {
      event.target.src = this.urls.placeHolderImageIcon;
    },

    hasCategoriesForDisplay() {
      return this.model.categoriesForDisplay.filter(category => category.links.length > 0).length > 0;
    },

    displayBanner(succeeded, text) {
      this.model.statusBanner.text = text;
      this.changeBannerState(succeeded);
    },

    changeBannerState(succeeded) {
      this.model.statusBanner.icon = succeeded ? this.urls.successIcon : this.urls.failureIcon;
      this.model.statusBanner.success = succeeded;
      this.model.statusBanner.doDisplay = true;
      setTimeout(() => {
        this.model.statusBanner.doDisplay = false;
      }, 3000);
    },

    hasTargetAdministrativeUnits() {
      return this.model.organisationalUnits.administrativeUnits.items.length > 0;
    },

    hasTargetInstitutionsOrTeams() {
      return this.model.organisationalUnits.institutions.length > 0;
    },

    formatAudience(category) {
      return category.audience.map(a => this.TEXT[this.LANG][a]).join(', ');
    },

    formatOrganisationalUnits(category) {
      return this.getAssociatedOrganisationalUnits(category.administrationIds, category.institutionIds, category.teamIds).map(aou => aou.name).join(', ');
    },
    /** DISPLAY FUNCTIONS END **/

    /** VARIOUS OTHER FUNCTIONS BEGIN **/
    arraysDiffer(a1, a2) { // assuming both are arrays of strings
      if ((a1 === null && a2 !== null) || (a2 === null && a1 !== null)) {
        return true;
      }
      if (a1 === null && a2 === null) {
        return false;
      }
      if (a1.length !== a2.length) {
        return true
      }
      for (let i = 0; i < a1.length; i++) {
        if (a1[i] !== a2[i]) {
          return true;
        }
      }
      return false;
    },
    /** VARIOUS OTHER FUNCTIONS END **/
  },

  watch: {
    childFilter: function (newValue, oldValue) {
      if (this.arraysDiffer(newValue, oldValue) && this.aulaToken !== undefined) {
        this.load();
      }
    },
    institutionFilter: function (newValue, oldValue) {
      if (this.arraysDiffer(newValue, oldValue) && this.aulaToken !== undefined) {
        this.load();
      }
    },
    assuranceLevel(newValue, oldValue) {
      if (newValue !== oldValue && this.aulaToken !== undefined) {
        this.load();
      }
    },
    aulaToken: function () {
      if (this.aulaToken !== undefined) {
        this.load();
      }
    }
  },

  created: function () {
    this.moment.locale('da');
    this.aulaToken = this.getAulaToken();
  },

  updated: function () {
  }
}
</script>

<style scoped>
.el-select {
  margin-bottom: 12px;
}

.el-select-group__wrap {
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: black;
}

.el-select-dropdown.is-multiple .el-select-dropdown__item.selected {
  color: #007A8D;
}

.el-select-dropdown.is-multiple .el-select-dropdown__item.selected::after {
  font-size: 22px;
}

/* Applies to MoMo */
::v-deep .el-select-dropdown.is-multiple .el-select-dropdown__item.selected {
  display: flex;
}

/* Applies to MoMo */
::v-deep .el-select-dropdown.is-multiple .el-select-dropdown__item.selected span {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  width: calc(100% - 30px) !important;
}

/* Applies to Aula - .momo-links-select-target-groups-dropdown */
.momo-links-select-target-groups-dropdown {
  width: calc(100% - 32px) /* 100% minus standard padding left and right. 100% - 2*16px */
}

/* Applies to MoMo - .momo-links-select-target-groups-dropdown */
::v-deep .momo-links-select-target-groups-dropdown {
  width: calc(100% - 32px) /* 100% minus standard padding left and right. 100% - 2*16px */
}

/* Applies to Aula - .el-select-dropdown__item */
.el-select-dropdown__item {
  padding-left: 20px !important;
}

/* Applies to MoMo - .el-tag */
::v-deep .el-tag {
  display: flex;
  align-items: baseline;
  flex-flow: row;
  max-width: 95%;
}

/* Applies to Aula - .el-tag */
.el-select__tags .el-tag {
  display: flex;
  align-items: baseline;
  flex-flow: row;
  max-width: 95%;
}

/* Applies to Aula - .el-select__tags span */
.el-select__tags span {
  display: flex;
  flex-flow: row wrap;
}

/* Applies to MoMo. - .el-select__tags-text */
::v-deep .el-select__tags-text {
  overflow: hidden;
  text-overflow: ellipsis;
  flex-grow: 1;
  flex-basis: 60%;
}

/* Applies to Aula - .el-select__tags-text */
.el-select__tags .el-tag .el-select__tags-text {
  overflow: hidden;
  text-overflow: ellipsis;
  flex-grow: 1;
  flex-basis: 60%;
  display: block;
}

.momo-links {
  padding-bottom: 8px;
  background-color: white;
  border-radius: 4px;
  font-family: Lato, sans-serif;
}

.momo-links-heading {
  padding: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: grey solid 1px;
}

.momo-links-heading-text {
  font-weight: bold;
  font-size: 22px;
  line-height: 1.5;
}

.fade-leave-active {
  transition: opacity 3s;
}

.fade-leave-to {
  opacity: 0;
}

.momo-links-status-banner-success {
  background-color: #4BB543;
}

.momo-links-status-banner-failure {
  background-color: #C11E25;
}

.banner-container-centered {
  display: flex;
  justify-content: center;
}

.momo-links-status-banner {
  position: absolute;
  top: 65px;
  padding: 6px 8px;
  width: 95%;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  opacity: 0.8;
  z-index: 10;
}

.momo-links-status-banner-text {
  font-size: 16px;
  color: white;
}

.momo-links-edit-button {
  opacity: 0.54;
  height: 20px;
  width: 20px;
  cursor: pointer;
}

.momo-links-edit-button:hover {
  opacity: 0.87;
}

.momo-links-example-image,
.momo-links-icon {
  border: 1px solid #E9EEF1;
  border-radius: 4px;
  cursor: pointer;
  height: 48px;
  width: 48px;
}

.momo-links-icon-text {
  max-width: 60px;
  margin-top: 4px;
  text-align: center;
  font-family: Lato, sans-serif;
  font-weight: bold;
  font-size: 12px;
  color: black;
  opacity: 0.87;
  line-height: 1.2em;
  max-height: 2.4em;
  overflow: hidden;
  text-overflow: ellipsis;
}

.momo-links-sso-button {
  background: none !important;
  border: none;
  padding: 0 !important;
  cursor: pointer;
}

.momo-links-icon-and-text {
  outline: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.momo-links-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 8px;
  width: 70px;
  text-align: center;
  font-family: Lato, sans-serif;
  font-weight: bold;
  font-size: 12px;
  color: black;
  line-height: 1.2;
}

.momo-containing-block {
  transform: rotate(0deg);
  /* This makes this element the containing block of its children so that they can be absolutely positioned. See https://developer.mozilla.org/en-US/docs/Web/CSS/Containing_block */
  /* In edit mode we need .momo-links-link to be a containing block so that we can position the editing and deletion icons properly.
  But this ruins the stacking order of the link tool tip. So we only enable the .momo-containing-block class in edit mode.
  Yes, it's complicated.*/
}

.momo-links-links-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(68px, 1fr));
  margin: 0 7px;
}

.momo-links-link-icon {
  position: absolute;
  left: 52px;
  width: 15px;
  cursor: pointer;
}

.momo-links-link-delete-icon {
  top: 4px;
}

.momo-links-link-edit-icon {
  top: 30px;
}

.momo-links-category-container-top-border {
  border-top: solid 1px #E9EEF1;
}

.momo-links-category-title {
  padding-top: 10px;
  padding-bottom: 4px;
  padding-left: 16px;
  font-family: Lato, sans-serif;
  font-weight: bold;
  color: black;
  opacity: 0.87;
  font-size: 15px;
  display: flex;
}

.momo-links-no-categories {
  height: 105px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: Lato, sans-serif;
  font-weight: bold;
  font-size: 14px;
  color: #12BAC3;
  opacity: 0.87;
}

.momo-links-add-link-container {
  background-color: #F6F7F8;
}

.momo-links-link-form,
.momo-links-category-form {
  background-color: #F6F7F8;
  padding: 16px 0;
  display: flex;
  flex-direction: column;
  min-height: 250px;
  font-family: Lato, sans-serif;
}

.momo-links-category-create-spacer {
  height: 216px;
}

.momo-links-category-links-containing-block {
  min-height: 216px;
}

.momo-links-wrapper-with-padding {
  padding-left: 16px;
  padding-right: 16px;
  display: grid;
}

.momo-links-example-container {
  display: flex;
  align-self: flex-start;
}

.momo-links-example {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.momo-links-sso-link-checkbox {
  display: flex;
  align-items: center;
}

.momo-links-sso-link-text {
  margin: 0 8px;
  width: fit-content;
}

.momo-links-example-text {
  width: 80px;
  margin-top: 8px;
  margin-bottom: 8px;
}

.momo-links-sso-link-text,
.momo-links-example-text {
  font-family: Lato, sans-serif;
  font-size: 12px;
  font-weight: bold;
  color: black;
  opacity: 0.87;
  line-height: 1.2em;
  max-height: 2.4em;
  overflow: hidden;
}

.momo-links-target-group-header {
  font-weight: bold;
  padding-left: 16px;
  padding-right: 16px;
}

.momo-links-input {
  border-radius: 4px;
  background-color: white;
  font-family: Lato, sans-serif;
  font-size: 15px;
  color: RGBA(0, 0, 0, .87);
  padding: 6px;
  margin-bottom: 10px;
  border-style: none;
  height: 36px;
  box-sizing: border-box;
}

.momo-links-form-button-container {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  padding-right: 16px;
  padding-left: 16px;
}

.momo-links-form-button-container button.active {
  background-color: #12BAC3;
  color: #fff;
}

.momo-links-form-submit-button-container,
.momo-category-form-submit-button-container {
  display: flex;
  align-items: flex-end;
  height: 100%;
  padding-left: 16px;
  padding-right: 16px;
}

.momo-category-form-submit-button-container {
  justify-content: space-between;
}

.momo-links-form-submit-button-container {
  justify-content: flex-end;
}

.momo-links-create-category-button-container {
  display: flex;
  justify-content: center;
}

.momo-links-create-category-button:hover,
.momo-links-create-category-button {
  margin-left: 16px;
  margin-right: 16px;
  margin-top: 16px;
  margin-bottom: 10px;
  background-color: #12BAC3;
  width: 100%;
  color: white;
  font-size: 13px;
}

.momo-category-for-edit-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  margin-left: 16px;
  margin-right: 16px;
}

.momo-category-for-edit-block:not(:last-child) {
  border-bottom: #e9eef1 solid 1px;
}

.momo-category-for-edit-heading {
  font-size: 18px;
  font-weight: bold;
}

.momo-category-for-edit-text {
  font-size: 12px;
  line-height: 1em;
}

.momo-links-form-cancel-button,
.momo-links-form-save-button,
.momo-category-form-delete-button {
  background-color: transparent;
  width: 90px;
  font-size: 12px;
  color: #222350;
}

.momo-category-form-delete-button {
  color: #C11E25;
}

.momo-category-form-delete-button:hover {
  background-color: RGBA(193, 30, 25, 0.08);
}

.momo-audience-button {
  background-color: white;
  color: #222350;
  width: 86px;
  font-size: 12px;
}

.momo-links-form-cancel-button,
.momo-links-form-save-button,
.momo-category-form-delete-button,
.momo-links-create-category-button,
.momo-audience-button {
  border-radius: 4px;
  border-style: none;
  font-family: Lato, sans-serif;
  font-weight: bold;
  padding: 0 4px;
  outline: none;
  height: 36px;
  white-space: normal;
  cursor: pointer;
}

button:hover {
  background-color: RGBA(22, 23, 50, 0.08);
}

button:disabled {
  color: RGBA(22, 23, 50, 0.38);
}

form > button {
  padding: 0;
}

a {
  text-decoration: none;
  color: black;
}

.momo-links-employee-text {
  text-align: center;
  width: 57%;
  font-size: 12px;
  color: black;
  opacity: 0.87;
}

.momo-links-error-message {
  padding: 40px 8px;
  text-align: center;
  font-family: Lato, sans-serif;
}

.momo-tooltip {
  position: relative;
  display: inline-block;
}

.momo-tooltiptext {
  visibility: hidden;
  background-color: black;
  color: white;
  padding: 6px 6px;
  border-radius: 6px;
  font-family: Lato, sans-serif;
  font-size: 12px;
  font-weight: bold;
  position: absolute;
  z-index: 1;
}

.momo-sso-tooltiptext {
  width: 230px;
  top: 25px;
  left: 0;
}

.momo-link-tooltiptext {
  overflow-wrap: break-word;
  top: 5px;
  left: 0;
}

.momo-tooltip:hover .momo-tooltiptext {
  visibility: visible;
}
</style>
