const previousValueMap = new Map();
export default {
  bind(el) {
    el.addEventListener('input', event => {
      const input = event.target;
      const value = input.value;
      const numValue = parseInt(input.value);
      if (value === '' || numValue == value) {
        previousValueMap.set(el, value);
      } else {
        // Firefox input event is emit slightly faster than Chrome,
        // which results in Firefox not overriding the value.
        // To avoid this problem, we wrap the override in a Promise
        Promise.resolve().then(() => (input.value = previousValueMap.get(el) || ''));
      }
    });
  },
  unbind(el) {
    previousValueMap.delete(el);
  },
};
