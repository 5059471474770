
















































import * as dateUtil from '../../utils/dateUtil.js';
import { dateFormatEnum } from '../../enums/dateFormatEnum';
import { PropType } from 'vue';
import { TimeslotModel } from '../../models/timeslot.model';
import CalendarTimeslotResponseSelect from './CalendarTimeslotResponseSelect.vue';
import AulaButton from '../../components/AulaButton.vue';
import { EventInviteeModel } from '../../models/eventInvitee.model';

export default {
  components: { AulaButton, CalendarTimeslotResponseSelect },
  props: {
    timeslot: { type: Object as PropType<TimeslotModel>, required: true },
    invitees: { type: Array as PropType<Array<EventInviteeModel>>, required: true },
    numberOfSlots: { type: Number, default: 1 },
    canHandleSlot: { type: Boolean, default: false },
  },
  emits: ['assignSlot', 'clearSlot'],
  computed: {
    startTime() {
      return dateUtil.format(this.timeslot.startTime, dateFormatEnum.SHORT_TIME);
    },
    endTime() {
      return dateUtil.format(this.timeslot.endTime, dateFormatEnum.SHORT_TIME);
    },
    answers() {
      return this.timeslot.answers;
    },
    acceptedInvitees() {
      const answeredInviteeIds = this.answers.map(answer => answer.concerningProfile?.id);
      return this.invitees.filter(invitee => answeredInviteeIds.includes(invitee.instProfile.id));
    },
    canRemoveAnswer() {
      if (!this.canHandleSlot) {
        return false;
      }
      return this.answers.some(answer => answer.canRemoveBlockingOrResponseForTimeSlot);
    },
    blockedAnswers() {
      return this.answers.filter(answer => answer.instProfile == null);
    },
    numberOfSlotsOpen() {
      return this.numberOfSlots - this.acceptedInvitees.length - this.blockedAnswers.length;
    },
  },
  methods: {
    assignSlot(invitee) {
      this.$emit('assignSlot', invitee);
    },
    clearSlot(institutionProfileId) {
      this.$emit('clearSlot', institutionProfileId);
    },
  },
};
