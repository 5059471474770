import { VacationRegistrationModel } from '../../shared/models/vacationRegistration.model';

export class VacationRegistration implements VacationRegistrationModel {
  endDate: string;
  isEditable: boolean;
  isMissingAnswer: boolean;
  isPresenceTimesRequired: boolean;
  noteToGuardian: string;
  responseDeadline: string | null;
  responseId: number;
  startDate: string;
  title: string;
  vacationRegistrationId: number;

  constructor(model: VacationRegistrationModel) {
    this.endDate = model.endDate;
    this.isEditable = model.isEditable;
    this.isMissingAnswer = model.isMissingAnswer;
    this.isPresenceTimesRequired = model.isPresenceTimesRequired;
    this.noteToGuardian = model.noteToGuardian;
    this.responseDeadline = model.responseDeadline;
    this.responseId = model.responseId;
    this.startDate = model.startDate;
    this.title = model.title;
    this.vacationRegistrationId = model.vacationRegistrationId;
  }
}
