<template>
  <div class="vacation-status">
    <div class="status" :class="{ header: showInModal }">
      {{ ('CALENDAR_VACATION_REQUEST_RESPONSE_' + responseStatus.toUpperCase()) | fromTextKey }}
      <template v-if="!showInModal">
        : {{ children.length }}
        {{ children.length == 1 ? 'SMALL_CHILD' : 'SMALL_CHILDREN' | fromTextKey }}
      </template>
      <b-btn
        v-if="!isMobile && children.length > 0"
        variant="link"
        class="show-more"
        :aria-label="
          isChildrenShown
            ? 'ARIA_LABEL_VACATION_REQUEST_SHOW_LIST_CHILDREN'
            : 'ARIA_LABEL_VACATION_REQUEST_HIDE_CHILDREN' | fromTextKey
        "
        @click="isChildrenShown = !isChildrenShown"
      >
        <i v-if="isChildrenShown" class="icon icon-Aula_up-arrow" />
        <i v-else class="icon icon-Aula_down-arrow" />
      </b-btn>
    </div>
    <ul v-if="(!isMobile && isChildrenShown) || showInModal" class="vacation-children">
      <li v-for="(child, index) of children" :key="index">
        <a role="button" :class="{ disabled: !canEditVacationRequest }" @click.prevent="openVacationResponse(child)">
          {{ child.child | displayProfileNameWithMetadata }}
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { types } from '../../store/types/types';
import { permissionEnum } from '../../../shared/enums/permissionEnum';

export default {
  props: {
    responseStatus: { type: String, default: '' },
    showInModal: { type: Boolean, default: false },
    children: { type: Array, default: () => [] },
  },
  data: function () {
    return {
      isChildrenShown: false,
    };
  },
  computed: {
    ...mapGetters({
      isMobile: types.GET_IS_MOBILE,
      hasPermissionInstitution: types.HAS_PERMISSION_ON_INSTITUTION,
      activeEvent: types.GET_CURRENT_EVENT,
    }),
    canEditVacationRequest() {
      return (
        this.activeEvent &&
        this.hasPermissionInstitution(permissionEnum.EDIT_PRESENCE_TEMPLATES, this.activeEvent.institutionCode)
      );
    },
  },
  methods: {
    openVacationResponse(child) {
      this.$emit('openVacationResponseForChild', child);
    },
  },
};
</script>

<style scoped lang="scss">
@import '../../../shared/assets/scss/core/variables.scss';

.vacation-status {
  margin-top: 10px;
  .status {
    font-weight: 700;
    &.header {
      background-color: $color-grey-dark;
      text-transform: uppercase;
      width: 100%;
      padding: 10px 20px;
    }
    .show-more {
      padding: 0px 5px;
      i {
        font-size: 6px;
      }
    }
  }
  .vacation-children {
    margin-top: 10px;
    li {
      list-style: none;
      margin-left: -25px;

      a.disabled {
        pointer-events: none;
        color: $color-grey;
      }
    }
  }
}
</style>
