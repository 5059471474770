import { store } from '../../src/store/store';
import { portalRoles } from '../../shared/enums/portalRoles';

export class LoginProfile {
  public get isEmployee() {
    return store.getters['global/GET_CURRENT_PROFILE'].role === portalRoles.EMPLOYEE;
  }
}

export const loginProfile = new LoginProfile();
