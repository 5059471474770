<template>
  <div class="foodplan-container default-theme" :class="{'admin-theme' : userProfile == 'employee'}">
    <div class="foodplan-header" v-if="childInfo.CanLogin">
      <form :action="aioUrl" method="POST" target="_blank">
        <input type="hidden" id="aulatoken" name="aulatoken" :value="aulaToken" />
        <input type="hidden" id="userProfile" name="userProfile" :value="userProfile" />
        <button class="foodplan-login-button" type="submit" aria-label="Gå til Ishøj Pay oversigt"><span class="mr-8">Gå til Ishøj Pay</span><i aria-hidden="true" class="fa-solid fa-arrow-right"></i></button>
      </form>
    </div>
    <div class="week-select-buttons">
      <button @click="selectWeek(currentWeek.date)" class="week-select-button" :aria-label="'Vis bestillinger for uge ' + currentWeek.number" :class="{active : currentWeek.number == selectedWeek.number}" :disabled="loading">
        <div>Uge {{currentWeek.number}}</div>
        <div class="week-select-date">{{currentWeek.fromDate}} - {{currentWeek.toDate}}</div>
      </button>
      <button @click="selectWeek(nextWeek.date)" class="week-select-button" :aria-label="'Vis bestillinger for uge ' + nextWeek.number" :class="{active : nextWeek.number == selectedWeek.number}" :disabled="loading">
        <div>Uge {{nextWeek.number}}</div>
        <div class="week-select-date">{{nextWeek.fromDate}} - {{nextWeek.toDate}}</div>
      </button>
    </div>

    <div class="error-container" v-if="errors.length">
      <div v-for="error of errors" class="foodplan-error">
        <span>{{error}}</span>
      </div>
    </div>

    <div v-if="childInfo && !loading">
      <div v-for="(child, i) of childInfo.Wallets">
        <button v-if="userProfile != 'child'" :aria-label="(child.expand ? 'Skjul' : 'Vis') + ' bestillinger for ' + child.Name" class="foodplan-child-name" @click="toggleChild(child)"><i aria-hidden="true" class="fa-solid fa-user mr-8"></i>{{child.Name}}</button>
        <hr />
        <div class="foodplan-child-info" :class="{expand : child.expand}">
          <form :action="aioUrl" method="POST" target="_blank" v-if="childInfo.CanLogin">
            <input type="hidden" id="aulatoken" name="aulatoken" :value="aulaToken" />
            <input type="hidden" id="userProfile" name="userProfile" :value="userProfile" />
            <input type="hidden" id="returnUrl" name="returnUrl" :value="'/FoodPlannerV3/Index?walletID=' + child.WalletID + '&date=' + selectedWeek.date.format('YYYY-MM-DD')" />
            <button class="foodplan-order-button"><i aria-hidden="true" class="fa-solid fa-cart-arrow-down mr-8" :aria-label="'Bestil og afbestil mad for ' + child.Name"></i>Bestil / Afbestil</button>
          </form>

          <div v-for="(key) in Object.keys(child.Dates)">

            <span class="date">{{moment(key).format('dddd')}}:</span>
            <div class="product product-order-none" v-if="child.Dates[key].length == 0"><span class="product-wrapper"><span class="product-name">Ingen bestillinger</span></span></div>

            <button class="product" :class="{expand : activeChild == i && activeDate == key && activeProduct == k }" v-for="(product, k) of child.Dates[key]" @click="toggleProduct(i, key, k)">
              <span class="product-wrapper"><span class="product-name">{{product}}</span></span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="loading-container" v-if="loading">
      <i aria-hidden="true" class="fa-solid fa-spinner loading-spinner"></i>
    </div>
  </div>
</template>
<style scoped>
button {
  color: #fff;
  background-color: rgb(69, 147, 194);
  border: 1px solid rgb(69, 147, 194);
  border-radius: 0;
  font-weight: 600;
  transition: none;
}

button:hover {
  color: rgb(255, 255, 255);
  background: rgb(40, 113, 159);
  border-color: rgb(40, 113, 159);
}

button:active {
  background: #18638F;
  border-color: #18638F;
}

button.active {
  background: #153854;
  border-color: #153854;
  pointer-events: none;
}

button[disabled="disabled"] {
  background-color: transparent !important;
  color: rgba(16, 16, 16, 0.3) !important;
  border-color: rgba(118, 118, 118, 0.3) !important;
  pointer-events: none !important;
}

.admin-theme button {
  background-color: #45B7C1;
  border: 1px solid #45B7C1;
}

.admin-theme button:hover {
  color: rgb(255, 255, 255);
  background: #2091A2;
  border-color: #2091A2;
}

.admin-theme button:active {
  background: #007A8D;
  border-color: #007A8D;
}

.admin-theme button.active {
  background: #222350;
  border-color: #222350;
  pointer-events: none;
}

.error-container {
  margin: 10px;
}

.foodplan-error {
  color: rgb(255, 255, 255);
  background: rgb(181, 0, 80);
  padding: 5px;
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 4px;
}

.foodplan-container {
  background: #ffffff;
  padding: 10px;
  color: #222350;
}

.foodplan-child-name {
  cursor: pointer;
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: transparent !important;
  border: none !important;
  padding: 0 !important;
  text-align: left !important;
  font-weight: 500 !important;
  color: #222350 !important;
  font-weight: 600 !important;
  margin-top: 20px;
  width: 100%;
}

.foodplan-child-name:hover {
  text-decoration: underline;
}

.foodplan-child-info {
  display: none;
}

.foodplan-child-info.expand {
  display: block;
}

.date {
  font-weight: 600;
  text-transform: capitalize;
  min-width: 70px;
  display: inline-block;
  float: left;
}

.week-select-buttons {
  display: flex;
}

.week-select-button {
  display: inline-block;
  margin: 0;
  padding: 10px 0px;
  flex: 1;
  transition: none;
}

.week-select-button:nth-child(2n+1) {
  border-right: none;
}

.week-select-date {
  font-size: 11px;
}

.product {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  display: grid;
  background-color: transparent !important;
  border: none !important;
  padding: 0 !important;
  text-align: left !important;
  font-weight: 500 !important;
  color: #222350 !important;
}

.product:hover .product-name {
  text-decoration: underline;
}

.product span {
  text-overflow: ellipsis;
  overflow: hidden;
}

.product-name {
  flex: 1;
}

.product:last-child {
  margin-bottom: 10px;
}

.product:nth-child(n+1) {
  margin-left: 70px;
}

.product.expand {
  white-space: normal;
}

.product-wrapper {
  display: flex;
}

.product-order-none {
  text-decoration: none !important;
  cursor: default;
  pointer-events: none;
}

.product-order-none .product-name {
  opacity: 0.8;
  font-style: italic;
}

.foodplan-order-button,
.foodplan-login-button {
  padding: 10px 15px;
  margin-bottom: 10px;
  width: 100%;
}

.foodplan-container .fa-solid {
  font-family: 'Font Awesome 5 Pro';
  font-style: initial;
}

.foodplan-container .loading-container {
  padding: 40px;
  text-align: center;
}

.foodplan-container .loading-spinner {
  font-size: 30px;
  animation: loading-spinner 2s ease infinite;
  display: inline-block;
}

.mr-8 {
  margin-right: 8px;
}

@keyframes loading-spinner {
  0% {
    transform: rotateZ(0deg);
  }

  50% {
    transform: rotateZ(180deg);
  }

  100% {
    transform: rotateZ(360deg);
  }
}

@-moz-keyframes loading-spinner {
  0% {
    transform: rotateZ(0deg);
  }

  50% {
    transform: rotateZ(180deg);
  }

  100% {
    transform: rotateZ(360deg);
  }
}

@-webkit-keyframes loading-spinner {
  0% {
    transform: rotateZ(0deg);
  }

  50% {
    transform: rotateZ(180deg);
  }

  100% {
    transform: rotateZ(360deg);
  }
}

@media screen and (min-width:992px) and (max-width:1200px) {
  .date {
    min-width: 65px !important;
  }

  .product:nth-child(n+1) {
    margin-left: 65px !important;
  }

  .foodplan-login-button {
    font-size: 14px;
  }

  .foodplan-child-name,
  .foodplan-container,
  .week-select-button {
    font-size: 14px;
  }
}
</style>
<script>

export default {
  props: {
    axios: Function,
    getAulaToken: Function,
    userProfile: String,
    childFilter: Array,
    moment: Function
  },
  data: () => {
    return {
      errors: [],
      loading: true,
      aulaToken: null,
      childInfo: {},
      activeChild: null,
      activeDate: null,
      activeProduct: null,
      apiUrl: "https://api.mywallet.dk/V2/Wallet/AulaSchoolInfoV2",
      aioUrl: "https://ishøjpay.dk/aula/login?return",
      selectedWeek: {
        date: null,
        number: null,
      },
      currentWeek: {
        date: null,
        number: -1,
        fromDate: "",
        toDate: ""
      }, nextWeek: {
        date: null,
        number: -1,
        fromDate: "",
        toDate: ""
      }
    }
  },
  methods: {
    getChildren(date) {
      this.loading = true;

      this.axios
        .post(this.apiUrl,
          {
            aulaToken: this.aulaToken,
            childs: this.childFilter,
            date: date.format()
          }
        )
        .then((response) => {
          // JSON responses are automatically parsed.
          response.data.Wallets.forEach((c) => {
            c.expand = true;
          });

          this.errors = [];
          this.selectedWeek.date = this.moment(date).startOf("isoWeek");
          this.selectedWeek.number = this.moment(date).isoWeek()
          this.childInfo = response.data;
          this.resetSelectedProduct();
          this.loading = false;
        })
        .catch((e) => {
          this.errors.push(e);
          this.loading = false;
        });
    },
    resetSelectedProduct() {
      this.activeChild = null;
      this.activeDate = null;
      this.activeProduct = null;
    },
    toggleProduct(child, date, product) {
      if (child == this.activeChild && date == this.activeDate && product == this.activeProduct) {
        this.resetSelectedProduct();
      } else {
        this.activeChild = child;
        this.activeDate = date;
        this.activeProduct = product;
      }
    },
    toggleChild(child) {
      child.expand = !child.expand;
    },
    selectWeek(week) {
      this.getChildren(week);
    }

  },
  mounted() {
    this.moment.locale('da');

    var currentWeek = this.moment();
    var nextWeek = this.moment(currentWeek).add(1, 'weeks');

    var switchDay = 5;
    var switchHour = 16;
    var switchDate = this.moment().startOf("isoWeek").add(switchDay - 1, "days").add(switchHour, "hours");

    if (currentWeek.isSameOrAfter(switchDate)) {
      currentWeek = this.moment().add(1, 'weeks').startOf("week");
      nextWeek = this.moment(currentWeek).add(1, 'weeks');
    }

    this.currentWeek.date = this.moment(currentWeek).startOf("isoWeek");
    this.currentWeek.number = currentWeek.isoWeek();
    this.currentWeek.fromDate = currentWeek.startOf("isoWeek").format("D. MMM");
    this.currentWeek.toDate = currentWeek.endOf("isoWeek").format("D. MMM");

    this.nextWeek.date = this.moment(nextWeek).startOf("isoWeek");
    this.nextWeek.number = nextWeek.isoWeek();
    this.nextWeek.fromDate = nextWeek.startOf("isoWeek").format("D. MMM");
    this.nextWeek.toDate = nextWeek.endOf("isoWeek").format("D. MMM");

    this.selectedWeek.date = this.currentWeek.date;
    this.selectedWeek.number = this.currentWeek.date.isoWeek();

    this.aulaToken = this.getAulaToken();
  },
  watch: {
    aulaToken: function () {
      if (this.aulaToken != undefined) {
        this.getChildren(this.currentWeek.date);
      }
    },
  },
};
</script>
