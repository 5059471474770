import { EventRenderer } from './eventRenderer';
import { CalendarEventModel } from '../../../shared/models/calendarEvent.model';
import { ResourceModel } from '../../../shared/models/resource.model';
import { InstitutionProfileModel } from '../../../shared/models/institutionProfile.model';
import { NotificationModel } from '../../../shared/models/notification.model';
import moment from 'moment-timezone';
import { htmlUtil } from '../../../shared/utils/htmlUtil';

export class FiveDaysEventRenderer extends EventRenderer {
  constructor(
    { event, el }: { event: CalendarEventModel; el: HTMLElement },
    resources: ResourceModel[],
    notifications: NotificationModel[],
    profile: InstitutionProfileModel,
    delegatedContext: InstitutionProfileModel,
    noOfResources: number
  ) {
    super(
      {
        event,
        el,
      },
      resources,
      notifications,
      profile,
      delegatedContext,
      noOfResources
    );
  }

  override render(): string {
    const titleHtml = this.noOfResource > 2 ? this.getMultipleResourcesTitle() : this.getResourcesTitle();

    return htmlUtil.createSimpleDiv(null, 'week-view-event-group', titleHtml);
  }

  private getMultipleResourcesTitle() {
    const title = this.allDay ? this.detailedTitle : this.shortTitle;
    return htmlUtil.createCustomDiv(this.eventTitle, 'week-view-title-event', [title], this.titleCustomProps);
  }

  private getResourcesTitle() {
    const title = htmlUtil.createSpan('title', this.allDay ? this.detailedTitle : this.detailedBoldedTitle);
    return htmlUtil.createCustomDiv(this.eventTitle, 'week-view-title-event', [title], this.titleCustomProps);
  }

  get titleCustomProps() {
    const divTitleCustomProps = new Map<string, string>();
    divTitleCustomProps.set('data-date', moment(this.eventStart).format('YYYY-MM-DD'));
    return divTitleCustomProps;
  }
}
