<template>
<div class='widget-W0030V0001'>
  <div class="assignment-list">
    <!-- Header -->
    <b-container fluid>
      <b-row class="assignment-list-header-container align-items-baseline">
        <b-col class="d-flex">
          <div class="d-flex align-items-center">
            <span class="assignment-list-header-small" v-if="placement === 'narrow'">Opgaver</span>
            <h1 class="assignment-list-header-large" v-else>Opgaver</h1>
          </div>
          <div v-if="week && year" :class="['d-flex', placement === 'narrow' ? 'align-items-center' : 'align-items-baseline']">
            <b-button
              class="assignment-list-header-week-picker d-flex justify-content-center"
              title="Gå til forrige uge"
              @click="handlePreviousWeek"
            >
              <i :class="['fa fa-caret-left', placement === 'narrow' ? 'fa-lg' : 'fa-2x']"></i>
            </b-button>
            <span class="assignment-list-header-week-label" v-if="placement === 'narrow'">
							uge {{ week }} <span v-if="year !== yearLocale">({{year}})</span>
						</span>
            <h1 v-else>
              uge {{ week }} <span v-if="year !== yearLocale">({{year}})</span>
            </h1>
            <b-button
              class="assignment-list-header-week-picker d-flex justify-content-center"
              title="Gå til næste uge"
              @click="handleNextWeek"
            >
              <i :class="['fa fa-caret-right', placement === 'narrow' ? 'fa-lg' : 'fa-2x']"></i>
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-container>

    <!-- Loading -->
    <div v-if="isLoading">
      <i class="fa fa-spinner fa-spin"></i>
      henter opgaver...
    </div>

    <!-- license error shown to user -->
    <div v-else-if="error">
			<span v-if="error === 'licenseError'">
				Du har ikke adgang til widgets fra MinUddannelse.
				<br />Kontakt
				<a :href="'mailto:support@minuddannelse.dk?subject=Ingen adgang til Aula-widgets fra MinUddannelse (reference-id: ' + sessionUUID + ')'">
					support@minuddannelse.dk
				</a>
				for adgang.
				<p class="text-muted info-text">
					Ved henvendelse kan følgende reference-id hjælpe med at spore fejlen: {{sessionUUID}}
				</p>
			</span>

      <!-- Message shown to user -->
      <span v-else class="error-message">
				Noget gik galt med at hente opgaver. Prøv evt. at logge ud og ind igen.
			</span>
    </div>

    <!-- Assignment list -->
    <div v-else>
      <div :class="['assignment-list-narrow', {'d-block d-lg-none': placement !== 'narrow'}]">
        <b-card no-body>
          <b-tabs pills card>
            <b-tab v-for="day in days" :key="day.name" :active="isPresentDay(day)">
              <div slot="title" class="assignment-tab">
                <div>
                  <small>{{ day.name.slice(0, 1).toUpperCase() }}</small>
                </div>
                <small>{{ day.date.split('.').shift() }}</small>
                <div class="assignment-indicator">{{ day.assignments.map((assignment) => '•').slice(0, 3).join('') }}</div>
                <span v-if="!day.assignments.length">&nbsp;</span>
              </div>
              <div class="assignment-list-container">
                <div v-if="!filterAssignmentsByPerson(day.assignments).length" class="text-muted default no-assignments">Der er ingen opgaver for denne dag</div>
                <div v-else>
                  <div v-for="(person, index) in filterAssignmentsByPerson(day.assignments)" :key="index">
                    <strong v-if="userProfile.toLowerCase() === 'parent' || userProfile.toLowerCase() === 'guardian' || userProfile.toLowerCase() === 'otp'">
                      <span class="assignment-assignee">{{ person.assignee }}</span>
                    </strong>
                    <ul class="assignments">
                      <li v-for="assignment in person.assignments" :key="assignment.id" class="assignment-list" >
                        <div class="assignment">
                          <div class="assignment-link-btn-wrapper">
                            <form
                              :ref="aulaTokenInputIdAssignmentMobileForm + assignment.id"
                              @submit="addAulaTokenOnSubmit($event, aulaTokenInputIdAssignmentMobileForm + assignment.id)"
                              method="POST"
                              target="_blank"
                              :action="assignment.url"
                            >
                              <input type="hidden" name="assuranceLevel" :value="assuranceLevel" />
                              <input type="hidden" name="aulaToken" :value="getTempAulaToken" />
                              <input type="hidden" name="childFilter" :value="childFilter" />
                              <input
                                type="hidden"
                                name="currentWeekNumber"
                                :value="currentWeekNumber"
                              />
                              <input type="hidden" name="group" :value="group" />
                              <input
                                type="hidden"
                                name="institutionFilter"
                                :value="institutionFilter"
                              />
                              <input type="hidden" name="isMobileApp" :value="isMobileApp" />
                              <input type="hidden" name="placement" :value="placement" />
                              <input type="hidden" name="sessionUUID" :value="sessionUUID" />
                              <input type="hidden" name="userProfile" :value="userProfile" />
                              <b-button
                                variant="link"
                                role="link"
                                :class="['assignment-link-btn', {'complete': assignment.isCompleted}]"
                                :title="assignment.name"
                                type="submit"
                              >{{ assignment.name }}</b-button>
                            </form>
                          </div>
                          <div>
                            <small class="assignment-timestamp-tag" v-if="assignment.placementTimestamp">
                              <i class="far fa-clock"></i>
                              <span class="assignment-timestamp">{{ assignment.placementTimestamp }}</span>
                            </small>
                            <ul class="groups">
                              <li
                                class="group-name"
                                v-for="(group, index) in assignment.groups"
                                :key="group.id"
                              >
                                <small>
                                  {{ group.name }}
                                  <span v-if="assignment.groups.length - 1 !== index">,</span>
                                </small>
                              </li>
                            </ul>
                            <small class="course-name" v-if="assignment.courseName">{{ assignment.courseName }}</small>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </b-tab>
          </b-tabs>
        </b-card>
      </div>
      <div v-if="placement !== 'narrow'" class="assignment-list-medium d-none d-lg-block">
        <div class="days">
          <div class="day-container" v-for="(day) in days" :key="day.name">
            <div :class="['day-title', isPresentDay(day) ? 'today' : '']">{{ day.name }} {{ day.date }}</div>
            <div class="assignment-list-container">
              <div v-if="filterAssignmentsByPerson(day.assignments).length">
                <div v-for="(person, index) in filterAssignmentsByPerson(day.assignments)" :key="index">
                  <strong v-if="userProfile.toLowerCase() === 'parent' || userProfile.toLowerCase() === 'guardian' || userProfile.toLowerCase() === 'otp'">
                    <span class="assignment-assignee">{{ person.assignee }}</span>
                  </strong>
                  <ul class="assignments">
                    <li v-for="assignment in person.assignments" :key="assignment.id" class="assignment-list">
                      <div class="assignment">
                        <div class="assignment-link-btn-wrapper">
                          <form
                            :ref="aulaTokenInputIdAssignmentForm + assignment.id"
                            @submit="addAulaTokenOnSubmit($event, aulaTokenInputIdAssignmentForm + assignment.id)"
                            method="POST" target="_blank"
                            :action="assignment.url"
                          >
                            <input type="hidden" name="assuranceLevel" :value="assuranceLevel" />
                            <input type="hidden" name="aulaToken" :value="getTempAulaToken" />
                            <input type="hidden" name="childFilter" :value="childFilter" />
                            <input
                              type="hidden"
                              name="currentWeekNumber"
                              :value="currentWeekNumber"
                            />
                            <input type="hidden" name="group" :value="group" />
                            <input
                              type="hidden"
                              name="institutionFilter"
                              :value="institutionFilter"
                            />
                            <input type="hidden" name="isMobileApp" :value="isMobileApp" />
                            <input type="hidden" name="placement" :value="placement" />
                            <input type="hidden" name="sessionUUID" :value="sessionUUID" />
                            <input type="hidden" name="userProfile" :value="userProfile" />
                            <b-button
                              variant="link"
                              role="link"
                              :class="['assignment-link-btn', {'complete': assignment.isCompleted}]"
                              :title="assignment.name"
                              type="submit"
                            >{{ assignment.name }}</b-button>
                          </form>
                        </div>
                        <div>
                          <small class="assignment-timestamp-tag" v-if="assignment.placementTimestamp">
                            <i class="far fa-clock"></i>
                            <span class="assignment-timestamp">{{ assignment.placementTimestamp }}</span>
                          </small>
                          <ul class="groups">
                            <li
                              class="group-name"
                              v-for="(group, index) in assignment.groups"
                              :key="group.id"
                            >
                              <small>
                                {{ group.name }}
                                <span v-if="assignment.groups.length - 1 !== index">,</span>
                              </small>
                            </li>
                          </ul>
                          <small class="course-name" v-if="assignment.courseName">{{ assignment.courseName }}</small>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div :class="{'mu-provider-narrow': placement === 'narrow'}">
        <form :ref="aulaTokenInputIdLogoForm" @submit="addAulaTokenOnSubmit($event, aulaTokenInputIdLogoForm)" method="POST" target="_blank" :action="link">
          <span class="mu-provider-text">Fra alle os på</span>
          <input type="hidden" name="assuranceLevel" :value="assuranceLevel" />
          <input type="hidden" name="aulaToken" :value="getTempAulaToken" />
          <input type="hidden" name="childFilter" :value="childFilter" />
          <input type="hidden" name="currentWeekNumber" :value="currentWeekNumber" />
          <input type="hidden" name="group" :value="group" />
          <input type="hidden" name="institutionFilter" :value="institutionFilter" />
          <input type="hidden" name="isMobileApp" :value="isMobileApp" />
          <input type="hidden" name="placement" :value="placement" />
          <input type="hidden" name="sessionUUID" :value="sessionUUID" />
          <input type="hidden" name="userProfile" :value="userProfile" />
          <b-button
            role="link"
            class="mu-provider-btn"
            :title="'https://www.minuddannelse.net'"
            type="submit"
          >
            <b-img
              src="https://www.minuddannelse.net/Content/Graphics/Frontpage/frontpage_logo.svg"
              alt="MinUddannelse"
              fluid
              class="mu-provider-img"
            />
          </b-button>
        </form>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'AssignmentList',
  props: {
    assuranceLevel: Number,
    axios: Function,
    childFilter: [String, Array],
    currentWeekNumber: String,
    getAulaToken: Function,
    group: String,
    institutionFilter: [String, Array],
    isMobileApp: Boolean,
    moment: Function,
    placement: String,
    sessionUUID: String,
    userProfile: String,
  },
  data: function() {
    return {
      aulaToken: null,
      days: [],
      error: '',
      isLoading: false,
      link: new URL('https://api.minuddannelse.net/aula/redirect?redirectUrl=https://www.minuddannelse.net/'),
      week: null,
      year: null,
      yearLocale: parseInt(this.moment().format('YYYY')),
      aulaTokenInputIdLogoForm: 'logo-form',
      aulaTokenInputIdAssignmentForm: 'assignment-form',
      aulaTokenInputIdAssignmentMobileForm: 'assignment-narrow-form',
      tempAulaToken: '',
    };
  },
  watch: {
    aulaToken: function() {
      if (this.aulaToken !== undefined) {
        const { week, year } = this.extractWeekAndYear(this.currentWeekNumber);
        this.setDays(week, year);
        this.fetchAssignments(week, year);
      }
    },
  },
  computed: {
    getTempAulaToken() {
      return this.tempAulaToken;
    },
  },
  methods: {
    filterAssignmentsByPerson: function(assignments) {
      const persons = assignments.reduce((acc, { unilogin, assignee, ...curr }) => {
        if (!acc.find(person => person.unilogin === unilogin)) {
          acc = [...acc, { unilogin, assignee, assignments: [curr] }];

          return acc;
        }

        acc = acc.map(person => {
          if (person.unilogin !== unilogin) {
            return person;
          }

          return {
            ...person,
            assignments: [...person.assignments, curr],
          };
        });

        return acc;
      }, []);

      return persons;
    },
    isPresentDay(day) {
      const today = this.moment().locale('da');

      return this.moment(day.isoDate).format('YYYY-MM-DD') === this.moment(today).format('YYYY-MM-DD');
    },
    fetchAssignments(week, year) {
      this.week = week;
      this.year = year;

      const config = {
        method: 'get',
        headers: {
          Authorization: `Bearer ${this.aulaToken}`,
          Accept: 'application/json',
        },
        params: {
          placement: this.placement,
          sessionUUID: this.sessionUUID,
          userProfile: this.userProfile,
          currentWeekNumber: `${year}-W${week}`,
          childFilter: this.childFilter,
          isMobileApp: this.isMobileApp,
          institutionFilter: this.institutionFilter,
        },
        url: 'https://api.minuddannelse.net/aula/opgaveliste',
      };

      this.axios(config)
        .then(({ data }) => {
          this.days = data.opgaver.reduce((result, current) => {
            const day = result.find(({ name }) => name === current.ugedag.toLowerCase());
            const dayIndex = result.indexOf(day);
            result[dayIndex].assignments.push({
              name: current.title,
              id: current.id,
              assignmentType: current.opgaveType,
              placementTimestamp: current.placeringTidspunkt
                ? this.moment(current.placeringTidspunkt).format('HH:mm')
                : undefined,
              isCompleted: current.erFaerdig,
              dueDate: current.afleveringsdato,
              url: current.url,
              unilogin: current.unilogin,
              assignee: current.kuvertnavn,
              groups: current.hold
                ? current.hold.map(group => ({
                  id: group.id,
                  name: group.navn,
                }))
                : [],
              courseName: current.forloeb ? current.forloeb.navn : undefined,
            });

            return result;
          }, this.days);

          this.error = '';
          this.isLoading = false;
        })
        .catch(err => {
          this.isLoading = false;
          const licenseError = err.response.data.responseStatus.errorCode.toLowerCase() === 'licenserequired';

          this.error = licenseError ? 'licenseError' : 'error';

          return;
        });
    },
    extractWeekAndYear: function(currentWeekNumber) {
      const regex = (/^(\d){4}-W(\d){1,2}$/);

      // make date from Aula's currentWeekNumber if available,
      // otherwise make it from moment's current week
      if (!regex.test(currentWeekNumber)) {
        return {
          week: this.moment().week(),
          year: this.moment().year(),
        };
      }

      const [yearExtracted, weekExtracted] = currentWeekNumber.split('-W');

      return {
        week: parseInt(weekExtracted, 10),
        year: parseInt(yearExtracted, 10),
      };
    },
    makeDate(dayName, week, year) {
      return this.moment()
        .startOf('week')
        .isoWeekday(1)
        .year(year)
        .week(week)
        .day(dayName)
        .format('D. MMMM');
    },
    setDays(week, year) {
      const workingDays = this.moment.weekdays(true);
      this.days = workingDays
        .map(name => ({
          assignments: [],
          name,
          isoDate: this.moment()
            .locale('da')
            .isoWeek(week)
            .year(year)
            .isoWeekday(name)
            .format(),
          date: this.makeDate(name, week, year),
        }))
        .filter(day => day.name !== 'lørdag' && day.name !== 'søndag');
    },
    handlePreviousWeek: function() {
      this.isLoading = true;
      const newWeek = this.week - 1;
      const weeksInPreviousYear = this.moment({year: this.year - 1}).isoWeeksInYear();
      const week = newWeek < 1 ? weeksInPreviousYear : newWeek;
      const year = newWeek < 1 ? this.year -1 : this.year;
      this.setDays(week, year);
      this.fetchAssignments(week, year);
    },
    handleNextWeek: function() {
      this.isLoading = true;
      const newWeek = this.week + 1;
      const weeksInYear = this.moment({year: this.year}).isoWeeksInYear();
      const week = newWeek <= weeksInYear ? newWeek : 1;
      const year = newWeek <= weeksInYear ? this.year : this.year + 1;
      this.setDays(week, year);
      this.fetchAssignments(week, year);
    },
    addAulaTokenOnSubmit: function (e, ref) {
      e.preventDefault();

      // Adding the aulaToken before submitting
      this.tempAulaToken = this.aulaToken;

      // Timeout ensures form is submittet after DOM is repaintet
      setTimeout(() => {
        // Ref can be an array because its located in a v-for
        if (Array.isArray(this.$refs[ref])) {
          this.$refs[ref][0].submit();
        } else {
          this.$refs[ref].submit();
        }

        // Clear the aulaToken after submitting, so it's not visible in DOM
        // Timeout ensures the form has been submittet before clearing
        setTimeout(() => {
          this.tempAulaToken = '';
        }, 500);
      }, 500);
    },
  },
  created: function() {
    this.isLoading = true;
    this.moment.locale('da');
    this.aulaToken = this.getAulaToken();
    this.link.searchParams.append('userProfile', this.userProfile.toLowerCase());
  },
};
</script>

<style lang="scss">
.widget-W0030V0001 {
/* Class implemented by NC due to difference between style in preview and production.
Hopefully this will be fixed in the future */
.assignment-list h1 {
  font-size: 36px;
  font-weight: 900;
  margin: 0;
}

/* Class implemented by NC due to difference between style in preview and production.
Hopefully this will be fixed in the future */
.assignment-list .container-fluid {
  padding: 0 !important;
}

.assignment-list .assignment-list-header-container .assignment-list-header-small,
.assignment-list .assignment-list-header-container .assignment-list-header-week-label {
  font-size: 18px !important;
  text-transform: uppercase;
}

.assignment-list .assignment-list-header-container .assignment-list-header-large {
  font-size: 36px !important;
}

.assignment-list .assignment-list-header-container .assignment-list-header-week-picker {
  background-color: transparent;
  color: #222350;
  width: 30px;
  padding: 0;
  height: 30px;
  border-radius: 50%;
  position: relative;
  align-items: center;
}

.assignment-list .assignment-list-header-container .assignment-list-header-week-picker .fa-caret-left {
  margin-right: 3px
}

.assignment-list .assignment-list-header-container .assignment-list-header-week-picker .fa-caret-right {
  margin-left: 3px;
}

.assignment-list .assignment-list-header-container .assignment-list-header-week-picker:active {
  background-color: #828a9180 !important;
}

.assignment-list .assignment-list-header-container .assignment-list-header-week-picker:focus {
  box-shadow: none !important;
}

.assignment-list .assignment-list-container .assignment-assignee {
  margin-top: 16px;
}

.assignment-list .assignments {
  list-style: none;
  padding-left: 0;
}

.assignment-list .assignment-tab {
  text-align: center;
  line-height: 16px;
}

.assignment-list .groups {
  list-style: none;
  padding-left: 0;
  line-height: 1 !important;
}
.assignment-list .group-name {
  display: inline-block;
  margin-right: 4px;
}
.assignment-list .assignment-timestamp-tag {
  border-radius: 4px;
  margin-right: 4px;
}
.assignment-list .assignment-timestamp-tag .assignment-timestamp {
  margin-right: 2px;
}
.assignment-list .assignment-link-btn {
  padding: 0;
  color: #007a8d;
  background: transparent;
}
.assignment-list .assignment-link-btn.complete {
  text-decoration: line-through;
  opacity: 0.5;
}
.assignment-list .assignment-link-btn:focus {
  box-shadow: none !important;
}
.assignment-list-medium .assignment,
.assignment-list-narrow .assignment {
  margin-bottom: 16px;
}
.assignment-list .assignment-indicator {
  margin-top: -4px;
}
.assignment-list-medium {
  padding-top: 20px;
}
.assignment-list-medium .days {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
}
.assignment-list-medium .day-title {
  text-transform: uppercase;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  background: #45b7c1;
  text-align: center;
  padding: 17px 0;
  margin-bottom: 8px;
}
.assignment-list-medium .today {
  background: #222350;
}
.assignment-list-medium .assignment-list-container {
  padding: 0 16px 16px 16px;
}
.assignment-list-medium .day-container {
  margin-right: 2px;
  min-width: 0;
}
.assignment-list-medium .day-container .assignment-link-btn-wrapper {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.assignment-list-medium .day-container:last-child {
  margin-right: 0;
}
.assignment-list-narrow {
  margin-top: 10px;
}
.assignment-list-narrow .nav.nav-pills.card-header-pills {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
}
.assignment-list-narrow .no-assignments {
  padding: 16px 0;
}
.assignment-list-narrow .card-body {
  padding: 0 16px;
}
.assignment-list .mu-provider-narrow {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 6px;
}
.assignment-list .mu-provider-text {
  font-size: 12px;
  color: #868686;
}
.assignment-list .mu-provider-btn {
  background-color: transparent;
  padding: 0 0 0 8px;
  border: none;
  border-radius: 0;
}
.assignment-list .mu-provider-btn:active {
  background-color: transparent;
  border-color: transparent;
}
.assignment-list .mu-provider-btn:focus {
  box-shadow: none;
}
.assignment-list .mu-provider-img {
  height: 30px;
}
.error-message {
  color: #b50050;
}
}
</style>
