<template>
  <div>
    <h1>Widget-9</h1>
    <template v-if="sessionUUID"><strong>sessionUUID:</strong> {{ sessionUUID }}<br /></template>
    <template v-if="placement"><strong>placement:</strong> {{ placement }}<br /></template>
    <template v-if="currentWeekNumber"><b>currentWeekNumber:</b> {{ currentWeekNumber }}<br /></template>
    <template v-if="isMobileApp"><strong>isMobileApp:</strong> {{ isMobileApp }}<br /></template>
    <template v-if="userProfile"><strong>userProfile:</strong> {{ userProfile }}<br /></template>
    <template v-if="aulaToken"><strong>aulaToken:</strong> <span class="breakWord">{{ aulaToken }}</span><br /></template>
    <template v-if="assuranceLevel"><strong>assuranceLevel:</strong> {{ assuranceLevel }}<br /></template>
    <template v-if="childFilter"><strong>childFilter:</strong> {{ childFilter }}<br /></template>
    <template v-if="institutionFilter"><strong>institutionFilter:</strong> {{ institutionFilter }}<br /></template>
    <template v-if="group"><strong>group:</strong> {{ group }}<br /></template>
    <div>
      <p class="d-flex justify-content-between align-items-center">
        Notifikationer
        <b-btn @click="clearAllNotifications">Ryd alle</b-btn>
      </p>
      <template v-if="notifications && notifications.length > 0">
        <div v-for="notification in notifications" class="d-flex justify-content-between align-items-center" :key="notification.widgetNotificationId">
          <p class="mb-0">
            {{notification.notificationMessage}}
          </p>
          <b-btn @click="clearNotification(notification)" :disabled="removeNotificationSet.has(notification)" variant="link">
            X
          </b-btn>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    axios: Function,
    sessionUUID: String,
    placement: String,
    currentWeekNumber: String,
    isMobileApp: Boolean,
    userProfile: String,
    getAulaToken: Function,
    assuranceLevel: Number,
    childFilter: Array,
    institutionFilter: Array,
    group: Number,
    notifications: Array,
    deleteNotifications: Function,
  },
  data: function () {
    return {
      aulaToken: null,
      removeNotificationSet: new Set(),
    }
  },
  mounted() {
    this.getAulaToken();
  },
  methods: {
    async clearNotification(notification) {
      this.removeNotificationSet.add(notification);
      await this.deleteNotifications([notification.widgetNotificationId]);
      this.removeNotificationSet.delete(notification);
    },
    async clearAllNotifications() {
      this.removeNotificationSet = new Set(this.notifications);
      const notificationIds = this.notifications.map(notification => notification.widgetNotificationId);
      await this.deleteNotifications(notificationIds);
      this.removeNotificationSet.clear();
    },
  }
};
</script>

<style scoped>
b:before {
  content: "";
  display: block;
}
.breakWord {
  word-break: break-all;
}
</style>
