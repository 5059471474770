import moment from 'moment-timezone';
import Vue from 'vue';
import { CalendarEventModel } from '../../../shared/models/calendarEvent.model';
import { InstitutionProfileModel } from '../../../shared/models/institutionProfile.model';
import { ResourceModel } from '../../../shared/models/resource.model';
import { notificationTypes } from '../../../shared/enums/notificationTypes';
import { notificationEventTypes } from '../../../shared/enums/notificationEventTypes';
import { eventTypeEnum } from '../../../shared/enums/eventTypeEnum';
import { teacherTypes } from '../../../shared/enums/teacherTypes';
import { lessonStatus } from '../../../shared/enums/lessonStatus';
import { NotificationModel } from '../../../shared/models/notification.model';
import { EventExtendedPropsModel } from '../../../shared/models/eventExtendedProps.model';
import { calendarUtil } from '../../../shared/utils/calendarUtil';
import { htmlUtil } from '../../../shared/utils/htmlUtil';

export abstract class EventRenderer {
  protected readonly profile: InstitutionProfileModel;
  protected readonly resources: ResourceModel[];
  protected readonly allDay: boolean;
  protected readonly eventStart: string;
  protected readonly eventEnd: string;
  protected readonly eventTitle: string;
  protected readonly element: HTMLElement;
  protected readonly noOfResource: number;
  protected readonly hasBadge: boolean;
  protected readonly extendedProps: EventExtendedPropsModel;

  private readonly isOldEvent: boolean;
  private readonly isNewEvent: boolean;

  protected constructor(
    { event, el }: { event: CalendarEventModel; el: HTMLElement },
    resources: ResourceModel[],
    notifications: NotificationModel[],
    profile: InstitutionProfileModel,
    delegatedContext: InstitutionProfileModel,
    noOfResources: number
  ) {
    this.profile = profile;
    this.resources = resources;
    this.extendedProps = event.extendedProps;
    this.allDay = event.allDay;
    this.eventStart = event.start;
    this.eventEnd = event.end;
    this.eventTitle = event.title;
    this.element = el;
    this.noOfResource = noOfResources;

    this.isOldEvent = event.classNames.includes('old-event');
    this.isNewEvent = event.classNames.includes('new-event');

    this.hasBadge = this.getHasBadge(
      event.id,
      notifications,
      event.classNames.indexOf('event-waiting-response') > -1,
      delegatedContext
    );

    this.setElementStyling(event.id, this.element, delegatedContext);
  }

  protected abstract render(): string;

  private get badgeHtml(): string {
    return this.hasBadge ? '<div class="event-badge"></div>' : '';
  }

  private doesEventBelongToCurrentProfile(delegatedContext: InstitutionProfileModel) {
    const resource = this.extendedProps.parentId
      ? this.resources.find(resource => String(resource.id) == this.extendedProps.parentId)
      : null;
    const currentProfile = delegatedContext.id ? delegatedContext : this.profile;
    return resource?.singleId == String(currentProfile.id);
  }

  private getHasBadge(
    eventId: string | number,
    notifications: NotificationModel[],
    awaitingResponse: boolean,
    delegatedContext: InstitutionProfileModel
  ): boolean {
    if (!delegatedContext) {
      return false;
    }

    const resource = this.extendedProps.parentId
      ? this.resources.find(resource => String(resource.id) == this.extendedProps.parentId)
      : null;
    const isCurrentProfile = resource?.singleId == String(this.profile.id);

    const hasSomeNotifications = notifications.some(
      notification =>
        notification.eventId == eventId &&
        notification.notificationType === notificationTypes.BADGE &&
        [
          notificationEventTypes.INVITED_TO_EVENT_NO_RESPONSE_REQUIRED,
          notificationEventTypes.EVENT_CHANGED_NO_RESPONSE_REQUIRED,
          notificationEventTypes.INVITED_TO_EVENT_RESPONSE_REQUIRED,
          notificationEventTypes.EVENT_CHANGED_RESPONSE_REQUIRED,
          notificationEventTypes.EVENT_SUBSTITUTE_ADDED,
          notificationEventTypes.EVENT_LESSON_NOTE_CHANGED,
          notificationEventTypes.SOMEONE_ELSE_RESPONDED_TO_EVENT,
        ].includes(notification.notificationEventType)
    );

    const lessonBadge =
      this.hasLesson &&
      this.extendedProps.lesson.lessonStatus == lessonStatus.SUBSTITUTE &&
      this.extendedProps.description != null &&
      this.extendedProps.description.html != null &&
      this.extendedProps.description.html != '';

    return isCurrentProfile && (hasSomeNotifications || awaitingResponse || lessonBadge);
  }

  private stripForHtmlTags(value: string): string {
    return value.replace(/<\/?[^>]+(>|$)/g, '');
  }

  private get eventDataString() {
    return this.eventData.join(', ');
  }

  private get eventData(): string[] {
    const data = [];

    data.push(this.stripForHtmlTags(calendarUtil.getPrimaryLocation(this.extendedProps)));

    if (this.extendedProps.type == eventTypeEnum.LESSON && this.extendedProps.invitedGroups != null) {
      const mainGroups = this.extendedProps.invitedGroups.map(group => group.name);
      data.push(mainGroups.join(', '));
    }

    if (this.hasLesson) {
      if (this.extendedProps.lesson.participants != null) {
        let hasSubstitute = false;
        for (const participant of this.extendedProps.lesson.participants) {
          if (participant.participantRole == teacherTypes.SUBSTITUTE) {
            hasSubstitute = true;
            data.push(participant.teacherInitials);
          }
        }
        if (!hasSubstitute) {
          for (const participant of this.extendedProps.lesson.participants) {
            if (
              participant.participantRole == teacherTypes.PRIMARY ||
              participant.participantRole == teacherTypes.HELP ||
              participant.participantRole == teacherTypes.PEDAGOGUE
            ) {
              data.push(participant.teacherInitials);
            }
          }
        }
      }
    }
    return data;
  }

  private setElementStyling(eventId: string, element: HTMLElement, delegatedContext: InstitutionProfileModel): void {
    if (!this.doesEventBelongToCurrentProfile(delegatedContext)) {
      return;
    }

    const responseClassName = calendarUtil.getEventResponseStatusClassName({
      event: this.extendedProps,
      resources: this.resources,
      delegatedContext: delegatedContext,
      eventResourceIds: this.extendedProps.eventResourceIds,
      profile: this.profile,
    });

    element.setAttribute('tabindex', '0');
    element.setAttribute('id', eventId);
    if (responseClassName && !this.isOldEvent) {
      element.classList.add(responseClassName);
    }
  }

  private get updatedEventSpan() {
    return htmlUtil.createSpan('', this.updatedEventText);
  }

  private get hasSubstituteSpan() {
    return htmlUtil.createSpan('', this.hasSubstituteText);
  }

  private get titleSpan(): string {
    return htmlUtil.createSpan('', this.titleHtmlStripped);
  }

  private get boldedTitleSpan(): string {
    return htmlUtil.createSpan('', htmlUtil.boldedText(this.titleHtmlStripped));
  }

  private get eventDataHtml(): string {
    const detailsClasses = this.isOneLineTitle ? 'day-view-details pl-1' : 'day-view-details';
    return htmlUtil.createSpan(detailsClasses, this.eventDataString);
  }

  private get eventIcons() {
    const icons = [];
    if (this.extendedProps.private) {
      icons.push(htmlUtil.createSpan('icon icon-Aula_lock', ''));
    }
    if (this.extendedProps.lesson?.hasRelevantNote) {
      icons.push(htmlUtil.createSpan('icon icon-Aula_paper', ''));
    }
    return icons;
  }

  private getOneLineIconsDetailedTitle(title: string): string {
    const classes = this.hasBadge ? 'has-badge' : '';
    const titleAndIcons = htmlUtil.createDiv(null, classes, [title, ...this.eventIcons]);

    return htmlUtil.createDiv(null, 'title-container one-line', [
      this.badgeHtml,
      titleAndIcons,
      this.updatedEventSpan,
      this.hasSubstituteSpan,
      this.eventDataHtml,
    ]);
  }

  private getTwoLinesIconDetailedTitle(title: string): string {
    const detailsHtml = htmlUtil.createDiv(null, null, [
      this.badgeHtml,
      title,
      ...this.eventIcons,
      this.updatedEventSpan,
      this.hasSubstituteSpan,
    ]);

    const detailsAndEventData = htmlUtil.createDiv(null, null, [detailsHtml, this.eventDataHtml]);
    return htmlUtil.createSimpleDiv(null, 'title-container two-lines', detailsAndEventData);
  }

  private getDetailedTitleNoIcons(title: string): string {
    const htmlChildren = [
      this.badgeHtml,
      title,
      this.updatedEventSpan,
      this.hasSubstituteSpan,
      this.isOneLineTitle ? '' : '<br>',
      this.eventDataHtml,
    ];
    return htmlChildren.join('');
  }

  protected get shortTitle(): string {
    const title = htmlUtil.boldedText(this.eventTitle.substring(0, 3).toUpperCase());
    return this.getOneLineIconsDetailedTitle(title);
  }

  protected get titleHtmlStripped(): string {
    return this.stripForHtmlTags(this.eventTitle);
  }

  protected get titleHasIcons(): boolean {
    return this.extendedProps.private || (this.hasLesson && this.extendedProps.lesson.hasRelevantNote);
  }

  protected getDetailedTitle(boldedTitle: boolean): string {
    if (this.isBirthdayEvent) {
      return Vue.filter('fromTextKey')('CALENDAR_TOOLBAR_EVENT_TYPES_ENUM_birthday') + ': ' + this.titleHtmlStripped;
    }

    const title = boldedTitle ? this.boldedTitleSpan : this.titleSpan;
    if (!this.titleHasIcons) {
      this.getDetailedTitleNoIcons(title);
    }

    return this.isOneLineTitle ? this.getOneLineIconsDetailedTitle(title) : this.getTwoLinesIconDetailedTitle(title);
  }

  protected get updatedEventText(): string {
    if (this.isOldEvent) {
      return Vue.filter('fromTextKey')('CALENDAR_LABEL_EVENT_ORIGINAL');
    }
    if (this.isNewEvent) {
      return Vue.filter('fromTextKey')('CALENDAR_LABEL_EVENT_NEW');
    }
    return '';
  }

  protected get isBirthdayEvent() {
    return this.extendedProps.type === eventTypeEnum.BIRTHDAY;
  }

  protected get hasLesson(): boolean {
    return this.extendedProps.type == eventTypeEnum.LESSON && !!this.extendedProps.lesson;
  }

  protected get hasSubstituteText(): string {
    if (!this.hasLesson) {
      return '';
    }

    if (this.extendedProps.lesson.lessonStatus == lessonStatus.SUBSTITUTE) {
      const substituteHtml = htmlUtil.createSpan(
        'day-view-substitute',
        Vue.filter('fromTextKey')('CALENDAR_TEACHER_SUBSTITUTE')
      );
      return ', ' + substituteHtml;
    }
    return '';
  }

  protected get leftColumnText(): string {
    const endTime = this.eventEnd != null ? moment(this.eventEnd).format('HH:mm') : '';
    return this.allDay
      ? Vue.filter('fromTextKey')('CALENDAR_LABEL_EVENT_ALLDAY')
      : moment(this.eventStart).format('HH:mm') + '<br>' + endTime;
  }

  protected get detailedTitle(): string {
    return this.getDetailedTitle(false);
  }

  protected get detailedBoldedTitle(): string {
    return this.getDetailedTitle(true);
  }

  protected get isOneLineTitle(): boolean {
    return true;
  }
}
