










export default {
  props: {
    show: { type: Boolean, default: false },
    center: { type: Boolean, default: false },
  },
};
