export default {
  /**
   *  It will attach sr-only span with text value to the element's label
   * @element {HTMLElement} element
   */
  bind: function (element, binding) {
    const labelChild = element.getElementsByTagName('label')[0];
    const srOnlySpan = document.createElement('span');
    srOnlySpan.classList.add('sr-only');
    srOnlySpan.innerText = binding.value;

    if (binding.arg || binding.arg === 'pre') {
      labelChild.insertBefore(srOnlySpan, labelChild.firstChild);
    } else {
      labelChild.append(srOnlySpan);
    }
  },
  update: function (element, binding) {
    const labelChild = element.getElementsByTagName('label')[0];
    const srOnlySpans = labelChild.querySelectorAll('.sr-only');

    srOnlySpans.forEach(span => {
      if (
        span.innerText.replace(/\s/g, '') === binding.oldValue.replace(/\s/g, '') &&
        binding.value !== binding.oldValue
      ) {
        span.innerText = binding.value;
      }
    });
  },
};
