/* playground_duty 20231205.2 PulsProd */
<template>
  <b-container
    fluid
    class="root"
  >
    <b-row>
      <b-col>
        <!-- Header -->
        <widget-header widget-name="Gårdvagt" />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <!-- Progress -->
        <progress-indicator :view-model="progress" />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <!-- Errors -->
        <error-indicator :view-model="errors" />
      </b-col>
    </b-row>
    <template
      v-if="
        progress.empty &&
        !errors.has('schools') &&
        schools &&
        breaks &&
        plans &&
        tabs
      "
    >
      <b-row v-if="schools.hasNone">
        <b-col>
          <!-- No schools -->
          Der er ingen skoler med licens til gårdvagt, eller som du har adgang
          til.
        </b-col>
      </b-row>
      <b-row v-else-if="schoolYears.hasNone">
        <b-col>
          <!-- No school years -->
          Der er ingen skoleår.
        </b-col>
      </b-row>
      <b-row v-else>
        <b-col>
          <!-- Tabs -->
          <!-- Add top margin to the contents, so that they do not touch the tabs above -->
          <b-tabs
            :value="tabs.activeTab"
            content-class="mt-3"
            @input="tabs.activateTab($event)"
          >
            <!-- Planning tab -->
            <b-tab
              class="plans-tab"
              title="Planlægning"
            >
              <b-container fluid>
                <b-row>
                  <b-col>
                    <!-- Toolbar -->
                    <widget-toolbar :placement="placement">
                      <!-- School selection -->
                      <single-selection-input
                        v-if="schools.hasMultiple"
                        id="kmd-educa-personale-playground-duty-plans-school-select"
                        label="Skoler"
                        :view-model="schools.school"
                      >
                      </single-selection-input>

                      <!-- School year selection -->
                      <single-selection-input
                        v-if="schoolYears.hasMultiple"
                        id="kmd-educa-personale-playground-duty-plans-schoolyear-select"
                        label="Skoleår"
                        class="width-scale-35"
                        :view-model="schoolYears.schoolYear"
                      >
                      </single-selection-input>

                      <!-- Area selection -->
                      <multiple-selection-input
                        id="kmd-educa-personale-playground-duty-plans-areas-select"
                        dropdown
                        label="Områder"
                        :view-model="plans.areas"
                      >
                      </multiple-selection-input>

                      <!-- Week selection -->
                      <single-selection-input
                        v-show="plans.gridLayout"
                        id="kmd-educa-personale-playground-duty-plans-week-select"
                        label="Uge"
                        :view-model="plans.week"
                      >
                      </single-selection-input>

                      <!-- Date selection -->
                      <validated-date-input
                        v-show="!plans.gridLayout"
                        id="kmd-educa-personale-plaground-duty-plans-date-select"
                        label="Dato"
                        :show-success-state-on-input="false"
                        :view-model="plans.date"
                      >
                      </validated-date-input>

                      <!-- Refresh -->
                      <b-button @click="refresh()">Opdater</b-button>
                    </widget-toolbar>
                  </b-col>
                </b-row>

                <progress-indicator :view-model="plans.progress">
                  <b-row>
                    <b-col>
                      <!-- Errors -->
                      <error-indicator :view-model="plans.errors" />
                    </b-col>
                  </b-row>

                  <b-row v-if="plans.items.length === 0">
                    <!-- No selected areas -->
                    <b-col>Der er ingen valgte områder</b-col>
                  </b-row>

                  <b-row v-else-if="plans.days.length === 0">
                    <!-- No work days -->
                    <b-col>Ingen arbejdsdage</b-col>
                  </b-row>

                  <template v-else>
                    <!-- Plan -->
                    <b-row
                      v-for="plan in plans.items"
                      :key="plan.key"
                      class="plan"
                    >
                      <b-col>
                        <!-- Plan header -->
                        <div class="plan-header">
                          <!-- Use h3 for continuity with widget header, where h2 is used -->
                          <h3 class="no-text-overflow">
                            {{ plan.name }}
                          </h3>

                          <!-- Copy week -->
                          <b-link
                            v-if="plan.isEditable"
                            size="sm"
                            variant="secondary"
                            @click="plans.showCopyWeek(plan.id)"
                          >
                            Kopier uge
                          </b-link>

                          <!-- Enable "add new assignments" -->
                          <b-form-checkbox
                            v-if="plan.dragDrop.isToggleAvailable"
                            aria-hidden="true"
                            :checked="plans.dragDrop.isEnabled"
                            class="ml-auto"
                            switch
                            @change="plans.toggleDragDropEnabled($event)"
                          >
                            Påsæt personale
                          </b-form-checkbox>
                        </div>

                        <div
                          v-if="
                            plan.assignments.every(
                              (x) => x.assignmentsByBreaks.length === 0
                            )
                          "
                        >
                          <!-- No breaks -->
                          Der er ingen pauser i området
                        </div>

                        <template v-else>
                          <!-- Errors -->
                          <error-indicator :view-model="plan.errors">
                          </error-indicator>

                          <!-- New assignments bar -->
                          <div
                            v-if="plan.dragDrop.isAvailable"
                            class="personnel-with-types-bar-container"
                            aria-hidden="true"
                          >
                            <personnel-with-types-bar
                              :area-settings="plan.settings"
                              :departments="plans.dragDrop.departments"
                              :personnel-with-types="
                                plans.dragDrop.availablePersonnelWithTypes
                              "
                              :teams="plans.dragDrop.teams"
                              @person-with-type-drag-start="
                                plans.dragDrop.dragStart($event)
                              "
                              @person-with-type-drag-end="
                                plans.dragDrop.dragEnd($event)
                              "
                            >
                            </personnel-with-types-bar>
                          </div>

                          <!-- Assignments by breaks and days -->
                          <div
                            :class="[
                              'plan-contents',
                              { grid: plans.gridLayout },
                            ]"
                          >
                            <template
                              v-for="{
                                day,
                                assignmentsByBreaks,
                              } in plan.assignments"
                            >
                              <!-- Day header -->
                              <div
                                :key="day.key"
                                :style="day.style"
                                :class="[
                                  'plan-day',
                                  { grid: plans.gridLayout },
                                ]"
                              >
                                <h4>{{ day.name }}</h4>
                                <span>{{ day.date }}</span>
                              </div>

                              <template
                                v-for="{
                                  break: break_,
                                  assignments,
                                } in assignmentsByBreaks"
                              >
                                <!-- Break header -->
                                <div
                                  :key="break_.key(day.index)"
                                  :style="break_.style"
                                  :class="[
                                    'plan-break',
                                    {
                                      grid: plans.gridLayout,
                                      'sr-only':
                                        plans.gridLayout && day.index > 0,
                                    },
                                  ]"
                                >
                                  <h5 class="no-text-overflow">
                                    {{ break_.name }}
                                  </h5>
                                  <span>{{ break_.period }}</span>

                                  <!-- Assignments details -->
                                  <assignments-details-button
                                    :assignments="assignments"
                                    :is-person-with-type-dragged="
                                      plan.dragDrop.isPersonWithTypeDragged
                                    "
                                    placement="break"
                                    :plan-drag-drop-available="
                                      plan.dragDrop.isAvailable
                                    "
                                    :plan-grid-layout="plans.gridLayout"
                                  >
                                  </assignments-details-button>
                                </div>

                                <!-- Assignments of break on day -->
                                <div
                                  :key="assignments.key"
                                  :style="assignments.style"
                                  :class="[
                                    'plan-day-break',
                                    {
                                      grid: plans.gridLayout,
                                      empty:
                                        assignments.primary.empty &&
                                        assignments.backup.empty,
                                    },
                                  ]"
                                >
                                  <!-- Overlay for when dragged person is planned absent -->
                                  <person-planned-absent-overlay
                                    :view-model="
                                      assignments.dragDrop.personPlannedAbsentOverlay()
                                    "
                                  >
                                    <!-- Progress indicator-->
                                    <progress-indicator
                                      :establish-bfc="false"
                                      :view-model="assignments.progress"
                                    >
                                      <div
                                        :class="[
                                          'plan-day-break-contents',
                                          {
                                            empty:
                                              assignments.primary.empty &&
                                              assignments.backup.empty,
                                          },
                                        ]"
                                      >
                                        <template
                                          v-if="
                                            assignments.primary.empty &&
                                            assignments.backup.empty
                                          "
                                        >
                                          <span
                                            :class="{
                                              'sr-only': plans.gridLayout,
                                              invisible:
                                                plan.dragDrop
                                                  .isPersonWithTypeDragged,
                                            }"
                                          >
                                            <!-- No personnel -->
                                            Intet personale
                                          </span>

                                          <!-- Placeholder for new primary assignments -->
                                          <assignment-placeholder
                                            placement="assignments-empty-primary"
                                            :view-model="
                                              assignments.dragDrop.noAssignmentsPlaceholder(
                                                true
                                              )
                                            "
                                          >
                                          </assignment-placeholder>

                                          <!-- Placeholder for new backup assignments -->
                                          <assignment-placeholder
                                            placement="assignments-empty-backup"
                                            :view-model="
                                              assignments.dragDrop.noAssignmentsPlaceholder(
                                                false
                                              )
                                            "
                                          >
                                          </assignment-placeholder>

                                          <!-- Assignments details -->
                                          <assignments-details-button
                                            :assignments="assignments"
                                            :is-person-with-type-dragged="
                                              plan.dragDrop
                                                .isPersonWithTypeDragged
                                            "
                                            placement="assignments-empty"
                                            :plan-drag-drop-available="
                                              plan.dragDrop.isAvailable
                                            "
                                            :plan-grid-layout="plans.gridLayout"
                                          >
                                          </assignments-details-button>
                                        </template>

                                        <!-- Assignments grouped by type (primary/backup) -->
                                        <template v-else>
                                          <div
                                            v-for="group in [
                                              assignments.primary,
                                              assignments.backup,
                                            ].filter((x) => x.available)"
                                            :key="group.key"
                                            class="assignment-group"
                                          >
                                            <!-- Items in the group are ordered explicitly using CSS 'order' property,
                                                to place placeholders between assignment pills. -->

                                            <!-- Compact header (invisible to assistive technologies) -->
                                            <div
                                              v-b-tooltip.focus.hover
                                              aria-hidden="true"
                                              class="compact-header"
                                              style="--order: -2"
                                              tabindex="0"
                                              :title="group.label"
                                            >
                                              <!-- Icon -->
                                              <span
                                                :class="[
                                                  'fa-fw',
                                                  group.icon,
                                                  'compact-header-icon',
                                                ]"
                                              ></span>

                                              <span>:</span>

                                              <!-- No personnel -->
                                              <span
                                                v-if="group.empty"
                                                class="ml-1"
                                              >
                                                Intet personale
                                              </span>
                                            </div>

                                            <!-- Long header (for assistive technologies) -->
                                            <h6 class="sr-only">
                                              <span>{{ group.label }}</span>
                                              <!-- No personnel -->
                                              <span v-if="group.empty"
                                              >Intet personale</span
                                              >
                                            </h6>

                                            <!-- Assignment -->
                                            <assignment-pill
                                              v-for="assignment in group.items"
                                              :key="assignment.personId"
                                              v-b-tooltip.focus.hover
                                              class="assignment"
                                              :style="assignment.style"
                                              tabindex="0"
                                              :view-model="assignment"
                                            >
                                            </assignment-pill>

                                            <!-- Placeholder for new primary/backup assignment (when there are none) -->
                                            <assignment-placeholder
                                              placement="assignments-group"
                                              :view-model="
                                                group.dragDrop.groupPlaceholder()
                                              "
                                            >
                                            </assignment-placeholder>

                                            <!-- Placeholder for new backup assignments (when there are some) -->
                                            <assignment-placeholder
                                              v-for="placeholder in group.dragDrop.inlinePlaceholders()"
                                              :key="placeholder.key"
                                              placement="assignments-inline"
                                              :view-model="placeholder"
                                            >
                                            </assignment-placeholder>

                                            <!-- Assignments details -->
                                            <assignments-details-button
                                              :assignments="assignments"
                                              :is-person-with-type-dragged="
                                                plan.dragDrop
                                                  .isPersonWithTypeDragged
                                              "
                                              :placement="
                                                group.detailsButtonPlacement
                                              "
                                              :plan-drag-drop-available="
                                                plan.dragDrop.isAvailable
                                              "
                                              :plan-grid-layout="
                                                plans.gridLayout
                                              "
                                              :style="
                                                '--order: ' +
                                                group.items.length +
                                                ';'
                                              "
                                            >
                                            </assignments-details-button>
                                          </div>
                                        </template>
                                      </div>
                                    </progress-indicator>
                                  </person-planned-absent-overlay>
                                </div>
                              </template>
                            </template>
                          </div>
                        </template>
                      </b-col>
                    </b-row>
                  </template>
                </progress-indicator>

                <!-- Assignments details modal -->
                <!-- Change default body background with body-bg-variant, as otherwise inputs are invisible against it -->
                <b-modal
                  id="kmd-educa-personale-playground-duty-assignments-details-modal"
                  ref="assignmentsDetailsModal"
                  v-set-focus.shown
                  :busy="!plans.details.progress.empty"
                  cancel-title="Annuller"
                  class="assignments-details-modal"
                  body-bg-variant="light"
                  dialog-class="modal-xl-wider"
                  no-close-on-backdrop
                  :ok-only="!plans.details.areaIsEditable"
                  :ok-title="plans.details.areaIsEditable ? 'Gem' : 'Luk'"
                  :size="plans.details.areaIsEditable ? 'xl' : 'lg'"
                  static
                  title-tag="div"
                >
                  <!-- Modal title template -->
                  <template #modal-title>
                    <!-- h5 is used by modal by default -->
                    <h5>
                      <template v-if="plans.details.areaIsEditable">
                        Rediger vagter
                      </template>
                      <template v-else>Vagter</template>
                    </h5>
                    <span class="no-text-overflow">{{
                        plans.details.breakAndDate
                      }}</span>
                  </template>

                  <!-- Modal contents template -->
                  <b-container fluid>
                    <b-row v-if="plans.details.areaIsEditable">
                      <b-col
                        v-for="group in plans.details.assignablePersonnel"
                        :key="group.key"
                        cols="auto"
                      >
                        <!-- (Re-)assign person as primary/backup -->
                        <!-- Add bottom margin so the elements do not touch each other when stacked, or header elements below -->
                        <b-dropdown
                          :id="group.id"
                          v-set-focus.shown
                          boundary="viewport"
                          class="mb-3"
                          data-set-focus
                          :text="group.label"
                          @hidden="group.search.change('')"
                        >
                          <!-- Incremental search -->
                          <b-dropdown-form>
                            <b-form-group
                              label="Søg"
                              :label-for="group.searchInputId"
                              label-sr-only
                            >
                              <b-form-input
                                :id="group.searchInputId"
                                data-set-focus
                                placeholder="Søg"
                                size="sm"
                                type="text"
                                :value="group.search.value"
                                @update="group.search.change($event)"
                              >
                              </b-form-input>
                            </b-form-group>
                          </b-dropdown-form>

                          <!-- Personnel groups (unassigned, already assigned, absent...) -->
                          <template v-for="personGroup in group.groups">
                            <div
                              v-if="personGroup.items.length > 0"
                              :key="personGroup.key"
                              role="group"
                              :aria-labelledby="personGroup.id"
                            >
                              <!-- Group header -->
                              <b-dropdown-header
                                :id="personGroup.id"
                                class="no-text-overflow"
                              >
                                {{ personGroup.label }}
                              </b-dropdown-header>

                              <!-- Personnel -->
                              <b-dropdown-item-button
                                v-for="item in personGroup.items"
                                v-show="group.search.matches(item.label)"
                                :key="item.key"
                                :aria-describedby="personGroup.id"
                                class="no-text-overflow"
                                :disabled="item.disabled"
                                @click="item.call()"
                              >
                                {{ item.label }}
                              </b-dropdown-item-button>
                            </div>
                          </template>
                        </b-dropdown>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col
                        v-if="plans.details.areaIsEditable"
                        sm
                      >
                        <!-- Planned assignments -->
                        <!-- Use h6, as modal title uses h5 -->
                        <h6>Planlagte vagter</h6>
                        <b-table
                          :fields="plans.details.plannedAssignments.fields"
                          :items="plans.details.plannedAssignments.items"
                          borderless
                          empty-text="Der er ikke personale påsat"
                          primary-key="key"
                          show-empty
                          stacked="lg"
                          striped
                          :tbody-transition-props="{ name: 'flip-list' }"
                        >
                          <!-- Assignment cell template -->
                          <template #cell(assignment)="{ item }">
                            <div class="d-flex flex-wrap">
                              {{ item.assignment }}
                              <substituted-in-stm-icon
                                v-if="item.isSubstituted"
                                class="ml-1"
                              >
                              </substituted-in-stm-icon>
                            </div>
                          </template>

                          <!-- Actions cell template -->
                          <template #cell(actions)="{ item }">
                            <table-row-toolbar :actions="item.actions()">
                            </table-row-toolbar>
                          </template>
                        </b-table>
                      </b-col>
                      <b-col sm>
                        <!-- Actual assignments -->
                        <!-- Use h6, as modal title uses h5 -->
                        <h6>Faktiske vagter</h6>
                        <b-table
                          :fields="plans.details.actualAssignments.fields"
                          :items="plans.details.actualAssignments.items"
                          borderless
                          empty-text="Der er ikke personale påsat"
                          primary-key="key"
                          show-empty
                          stacked="sm"
                          striped
                          :tbody-transition-props="{ name: 'flip-list' }"
                        >
                          <!-- Actual assignment cell template -->
                          <template #cell(actualAssignment)="{ item }">
                            <div class="d-flex flex-wrap">
                              {{ item.actualAssignment }}
                              <substituted-in-stm-icon
                                v-if="item.isSubstituted"
                                class="ml-1"
                              >
                              </substituted-in-stm-icon>
                            </div>
                          </template>
                        </b-table>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <!-- Progress -->
                        <progress-indicator
                          :view-model="plans.details.progress"
                        >
                        </progress-indicator>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <!-- Errors -->
                        <error-indicator :view-model="plans.details.errors">
                        </error-indicator>
                      </b-col>
                    </b-row>
                  </b-container>
                </b-modal>

                <!-- Copy week modal -->
                <!-- Change default body background with body-bg-variant, as otherwise inputs are invisible against it -->
                <b-modal
                  id="kmd-educa-personale-playground-duty-assignments-copy-week-modal"
                  ref="copyWeekModal"
                  v-set-focus.shown
                  :busy="!plans.copyWeek.progress.empty"
                  body-bg-variant="light"
                  cancel-title="Annuller"
                  ok-title="Kopiere"
                  no-close-on-backdrop
                  static
                  title="Kopier vagter"
                >
                  <b-container fluid>
                    <b-row>
                      <b-col>
                        <!-- Source week -->
                        <single-selection-input
                          id="kmd-educa-personale-playground-duty-assignments-copy-week-source-week-input"
                          label="Fra uge"
                          :view-model="plans.copyWeek.sourceWeek"
                        >
                        </single-selection-input>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <!-- Target weeks -->
                        <multiple-selection-input
                          id="kmd-educa-personale-playground-duty-assignments-copy-week-target-weeks-input"
                          data-set-focus
                          label="Til uge(r)"
                          :view-model="plans.copyWeek.targetWeeks"
                        >
                        </multiple-selection-input>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <!-- Progress -->
                        <progress-indicator
                          :view-model="plans.copyWeek.progress"
                        >
                        </progress-indicator>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <!-- Errors -->
                        <error-indicator :view-model="plans.copyWeek.errors">
                        </error-indicator>
                      </b-col>
                    </b-row>
                  </b-container>
                </b-modal>
              </b-container>
            </b-tab>

            <!-- Counts tab -->
            <b-tab title="Optælling">
              <counts
                ref="counts"
                :actions="actions"
                :moment="moment"
                :placement="placement"
                :schools="schools"
                :school-code="schools.school.value"
                :school-year-id="schoolYears.schoolYear.value"
                :school-years="schoolYears"
                :settings="settings"
                :store="store"
              >
              </counts>
            </b-tab>

            <!-- Breaks tab -->
            <b-tab title="Pauser">
              <b-container>
                <b-row>
                  <b-col>
                    <!-- Toolbar -->
                    <widget-toolbar :placement="placement">
                      <!-- School selection -->
                      <single-selection-input
                        v-if="schools.hasMultiple"
                        id="kmd-educa-personale-playground-duty-breaks-school-select"
                        label="Skoler"
                        :view-model="schools.school"
                      >
                      </single-selection-input>

                      <!-- School year selection -->
                      <single-selection-input
                        v-if="schoolYears.hasMultiple"
                        id="kmd-educa-personale-playground-duty-breaks-schoolyear-select"
                        class="width-scale-35"
                        label="Skoleår"
                        :view-model="schoolYears.schoolYear"
                      >
                      </single-selection-input>

                      <!-- Area selection -->
                      <single-selection-input
                        id="kmd-educa-personale-playground-duty-breaks-area-select"
                        label="Område"
                        :view-model="breaks.area"
                      >
                      </single-selection-input>

                      <!-- Refresh -->
                      <b-button @click="refresh()">Opdater</b-button>

                      <!-- Add break -->
                      <b-button
                        v-if="!isReadOnly"
                        id="kmd-educa-personale-playground-duty-breaks-add-button"
                        :disabled="!breaks.area.value"
                        @click="breaks.addNew()"
                      >
                        Tilføj pause
                      </b-button>
                    </widget-toolbar>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col>
                    <!-- Errors -->
                    <error-indicator :view-model="breaks.errors" />
                  </b-col>
                </b-row>

                <b-row>
                  <b-col>
                    <!-- Breaks table -->
                    <b-table
                      borderless
                      show-empty
                      :empty-text="breaks.noBreaksPrompt.value"
                      :busy="!breaks.progress.empty"
                      :stacked="placement === 'narrow' ? true : 'sm'"
                      :items="breaks.table.items"
                      :fields="breaks.table.fields"
                      primary-key="id"
                      sort-by="start"
                      :sort-desc="false"
                    >
                      <!-- Actions cell template -->
                      <template #cell(actions)="{ item }">
                        <table-row-toolbar :actions="item.actions()">
                        </table-row-toolbar>
                      </template>

                      <!-- Busy template -->
                      <template #table-busy>
                        <!-- Progress -->
                        <progress-indicator :view-model="breaks.progress" />
                      </template>
                    </b-table>
                  </b-col>
                </b-row>

                <!-- Edit break -->
                <!-- Change default body background with body-bg-variant, as otherwise inputs are invisible against it -->
                <b-modal
                  id="kmd-educa-personale-playground-duty-break-edition-modal"
                  ref="breakEditionModal"
                  v-set-focus.shown
                  :busy="!breaks.edition.progress.empty"
                  :title="breaks.edition.title"
                  cancel-title="Annuller"
                  centered
                  ok-title="Gem"
                  no-close-on-backdrop
                  static
                  body-bg-variant="light"
                >
                  <!-- Contents -->
                  <b-container fluid>
                    <b-row>
                      <b-col>
                        <!-- Name input -->
                        <validated-value-input
                          id="kmd-educa-personale-playground-duty-break-edition-name-input"
                          data-set-focus
                          label="Navn"
                          :view-model="breaks.edition.name"
                        />
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <!-- Start input -->
                        <validated-time-input
                          id="kmd-educa-personale-playground-duty-break-edition-start-input"
                          label="Starttid"
                          :view-model="breaks.edition.period.start"
                        />
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <!-- End input -->
                        <validated-time-input
                          id="kmd-educa-personale-playground-duty-break-edition-end-input"
                          label="Sluttid"
                          :view-model="breaks.edition.period.end"
                        />
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col>
                        <!-- Progress -->
                        <progress-indicator
                          :view-model="breaks.edition.progress"
                        />
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col>
                        <!-- Errors -->
                        <error-indicator :view-model="breaks.edition.errors" />
                      </b-col>
                    </b-row>
                  </b-container>
                </b-modal>
              </b-container>
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>
    </template>

    <!-- Confirmation modal -->
    <confirmation-modal
      id="kmd-educa-personale-playground-duty-confirmation-modal"
    >
    </confirmation-modal>
  </b-container>
</template>
<script>
/**
 * MODULE playground_duty\substitutedInStmIcon.js
 **/
const _module_widget_substitutedInStmIcon = (function widget_substitutedInStmIcon() {
  let _module_exports = {};

  // @vue/component
  // test
  const SubstitutedInStmIcon = {
    name: "SubstitutedInStmIcon",
    render(h) {
      const substitutedInStm = "Vikardækket i vikarmodulet";
      const cannotBeDeleted = "Kan ikke slettes";
      const cannotBeChangedToBackup = "Kan ikke ændres til bagvagt";
      return h("div", {}, [
        // Icon
        h("span", {
          class: ["fa", "fa-retweet"],
          attrs: {
            "aria-hidden": true
          },
          directives: [{
            name: "b-tooltip",
            modifiers: {
              hover: true,
              html: true
            }
          }],
          domProps: {
            title: "<div class=\"text-left\">".concat(substitutedInStm, "<ul><li>").concat(cannotBeDeleted, "</li><li>").concat(cannotBeChangedToBackup, "</li></ul></div>")
          }
        }, []),
        // Label for assistive technologies
        h("div", {
          class: "sr-only"
        }, [substitutedInStm, h("ul", {}, [h("li", {}, cannotBeDeleted), h("li", {}, cannotBeChangedToBackup)])])]);
    }
  };
  _module_exports = {
    SubstitutedInStmIcon
  };
  return _module_exports;
})();

/**
 * MODULE playground_duty\personnel.js
 **/
const _module_widget_personnel = (function widget_personnel() {
  let _module_exports = {};

  function fullNameAndEmployment(person, personnelType) {
    var _person$initials, _person$firstName, _person$lastName, _personnelType$descri;
    return "".concat((_person$initials = person === null || person === void 0 ? void 0 : person.initials) !== null && _person$initials !== void 0 ? _person$initials : "???", " ").concat((_person$firstName = person === null || person === void 0 ? void 0 : person.firstName) !== null && _person$firstName !== void 0 ? _person$firstName : "???", " ").concat((_person$lastName = person === null || person === void 0 ? void 0 : person.lastName) !== null && _person$lastName !== void 0 ? _person$lastName : "???", " (").concat((_personnelType$descri = personnelType === null || personnelType === void 0 ? void 0 : personnelType.description) !== null && _personnelType$descri !== void 0 ? _personnelType$descri : "???", ")");
  }
  _module_exports = {
    fullNameAndEmployment
  };
  return _module_exports;
})();

/**
 * MODULE playground_duty\personWithTypePill.js
 **/
const _module_widget_personWithTypePill = (function widget_personWithTypePill() {
  let _module_exports = {};

  /**
   * A person with personnel type.
   * @typedef {Object} PersonWithType
   * @property {String?} personFirstName
   * @property {Number?} personHasMultipleEmployments
   * @property {String?} personInitials
   * @property {String?} personLastName
   * @property {Number?} personIsCurrentUser
   * @property {String?} personnelTypeAbbreviation
   * @property {String?} personnelTypeDescription
   */

    // @vue/component
  const PersonWithTypePill = {
      name: "PersonWithTypePill",
      props: {
        /** @type {PersonWithType} */
        viewModel: {
          type: Object,
          required: true
        }
      },
      computed: {
        isCurrentUser() {
          return !!this.viewModel.personIsCurrentUser;
        },
        shortDescription() {
          var _this$viewModel$perso, _this$viewModel$perso2;
          const personnelTypePart = this.viewModel.personHasMultipleEmployments ? " (".concat((_this$viewModel$perso = this.viewModel.personnelTypeAbbreviation) !== null && _this$viewModel$perso !== void 0 ? _this$viewModel$perso : "???", ")") : "";
          return "".concat((_this$viewModel$perso2 = this.viewModel.personInitials) !== null && _this$viewModel$perso2 !== void 0 ? _this$viewModel$perso2 : "???").concat(personnelTypePart);
        },
        longDescription() {
          var _this$viewModel$perso3, _this$viewModel$perso4, _this$viewModel$perso5;
          return "".concat((_this$viewModel$perso3 = this.viewModel.personFirstName) !== null && _this$viewModel$perso3 !== void 0 ? _this$viewModel$perso3 : "???", " ").concat((_this$viewModel$perso4 = this.viewModel.personLastName) !== null && _this$viewModel$perso4 !== void 0 ? _this$viewModel$perso4 : "???", " (").concat((_this$viewModel$perso5 = this.viewModel.personnelTypeDescription) !== null && _this$viewModel$perso5 !== void 0 ? _this$viewModel$perso5 : "???", ")");
        },
        srDescription() {
          var _this$viewModel$perso6;
          return "".concat((_this$viewModel$perso6 = this.viewModel.personInitials) !== null && _this$viewModel$perso6 !== void 0 ? _this$viewModel$perso6 : "???", " ").concat(this.longDescription);
        }
      },
      render(h) {
        var _this$shortDescriptio, _this$srDescription;
        // Styles in './personWithTypePill.css'
        return h("div", {
          class: ["person-with-type-pill", {
            "current-user": this.isCurrentUser
          }],
          attrs: {
            title: this.longDescription
          }
        }, [
          // Short description
          h("span", {
            attrs: {
              "aria-hidden": true
            }
          }, [(_this$shortDescriptio = this.shortDescription) === null || _this$shortDescriptio === void 0 ? void 0 : _this$shortDescriptio.toString()]),
          // Description for assistive technologies
          h("span", {
            class: "sr-only"
          }, [(_this$srDescription = this.srDescription) === null || _this$srDescription === void 0 ? void 0 : _this$srDescription.toString()])]);
      }
    };
  _module_exports = {
    PersonWithTypePill
  };
  return _module_exports;
})();

/**
 * MODULE playground_duty\personPlannedAbsentOverlay.js
 **/
const _module_widget_personPlannedAbsentOverlay = (function widget_personPlannedAbsentOverlay() {
  let _module_exports = {};

  // @vue/component
  const PersonPlannedAbsentOverlay = {
    name: "PersonPlannedAbsentOverlay",
    props: {
      viewModel: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        dragInsideCount: 0,
        hover: false
      };
    },
    methods: {
      dragDrop() {
        this.dragInsideCount = 0;
        this.hover = false;
      },
      dragEnter() {
        ++this.dragInsideCount;
        this.hover = true;
      },
      dragLeave() {
        if (this.hover) {
          --this.dragInsideCount;
          if (this.dragInsideCount <= 0) {
            this.dragInsideCount = 0;
            this.hover = false;
          }
        }
      }
    },
    render(h) {
      // Styles in './personPlannedAbsentOverlay.css'
      if (!this.viewModel.available) {
        return this.$scopedSlots.default();
      }
      return h("div", {
        class: "person-planned-absent-overlay",
        on: {
          dragenter: this.dragEnter.bind(this),
          dragleave: this.dragLeave.bind(this),
          drop: this.dragDrop.bind(this)
        }
      }, [h("div", {
        class: "overlay",
        attrs: {
          "aria-hidden": true,
          "data-contents-hidden": this.hover,
          "data-visible": this.viewModel.visible
        }
      }, [h("div", {
        class: ["planned-absences", "no-text-overflow"]
      }, [this.viewModel.plannedAbsences])]), this.$scopedSlots.default()]);
    }
  };
  _module_exports = {
    PersonPlannedAbsentOverlay
  };
  return _module_exports;
})();

/**
 * MODULE playground_duty\assignmentsDetailsButton.js
 **/
const _module_widget_assignmentsDetailsButton = (function widget_assignmentsDetailsButton() {
  let _module_exports = {};

  // @vue/component
  const AssignmentsDetailsButton = {
    name: "AssignmentsDetailsButton",
    props: {
      assignments: {
        type: Object,
        required: true
      },
      isPersonWithTypeDragged: {
        type: Boolean,
        required: true
      },
      placement: {
        type: String,
        required: true
      },
      planDragDropAvailable: {
        type: Boolean,
        required: true
      },
      planGridLayout: {
        type: Boolean,
        required: true
      }
    },
    computed: {
      icon() {
        return this.assignments.areEditable ? "fa-edit" : "fa-list";
      },
      label() {
        return this.assignments.areEditable ? "Rediger vagter" : "Vis faktiske vagter";
      },
      invisible() {
        return this.placement !== "break" && this.planDragDropAvailable && this.isPersonWithTypeDragged;
      },
      enabled() {
        return this.placement === this.expectedPlacement;
      },
      expectedPlacement() {
        const anyPrimaryAssignments = !this.assignments.primary.empty;
        const anyBackupAssignments = !this.assignments.backup.empty;
        const areAssignmentsEditable = this.assignments.areEditable;
        if (!this.planGridLayout) {
          return anyPrimaryAssignments || anyBackupAssignments || areAssignmentsEditable ? "break" : null;
        }
        // In grid layout, show the button at the bottom-right corner of a grid cell,
        // inline with visible assignments, to conserve vertical space.
        if (!anyPrimaryAssignments && !anyBackupAssignments) {
          return areAssignmentsEditable ? "assignments-empty" : null;
        }
        if (this.planDragDropAvailable) {
          return "assignments-backup";
        }
        if (anyBackupAssignments) {
          return "assignments-backup";
        }
        if (anyPrimaryAssignments) {
          return "assignments-primary";
        }
        return null;
      }
    },
    render(h) {
      var _this$label;
      // Styles in 'assignmentsDetailsButton.css'
      if (!this.enabled) {
        return null;
      }
      return h("b-button", {
        class: ["assignments-details-button", {
          invisible: this.invisible
        }],
        attrs: {
          "data-placement": this.placement
        },
        props: {
          size: "sm",
          title: this.label,
          variant: "outline-secondary"
        },
        on: {
          click: () => this.assignments.showDetails()
        }
      }, [
        // Icon
        h("span", {
          class: ["fal", this.icon]
        }, []),
        // Label
        h("span", {
          class: "sr-only"
        }, [(_this$label = this.label) === null || _this$label === void 0 ? void 0 : _this$label.toString()])]);
    }
  };
  _module_exports = {
    AssignmentsDetailsButton
  };
  return _module_exports;
})();

/**
 * MODULE playground_duty\assignments.js
 **/
const _module_widget_assignments = (function widget_assignments() {
  let _module_exports = {};

  /** @typedef {import('../shared/dateMinuteTime').DateMinuteTime} DateMinuteTime */
  /** @typedef {import('../shared/intervals').IntervalSum} IntervalSum */
  /** @typedef {import('./store').AssignmentsOfBreakAndDate AssignmentsOfBreakAndDate */
  /** @typedef {import('./store').Person Person */
  /** @typedef {import('./store').PersonId PersonId */
  /** @typedef {import('./store').PersonnelType PersonnelType */

  /** @constructor */
  function PrimaryAssignmentStatus(status, backupAssignment) {
    this._status = status;
    this._backupAssignment = backupAssignment;
  }
  PrimaryAssignmentStatus.replaced = function (backupAssignment) {
    return new PrimaryAssignmentStatus("replaced", backupAssignment);
  };
  PrimaryAssignmentStatus.substituted = function () {
    return new PrimaryAssignmentStatus("substituted");
  };
  PrimaryAssignmentStatus.unavailable = function () {
    return new PrimaryAssignmentStatus("unavailable");
  };
  PrimaryAssignmentStatus.valid = function () {
    return new PrimaryAssignmentStatus("valid");
  };

  /**
   * A backup assignment
   * @typedef {Object} BackupAssignment
   * @property {PersonId} personId The id of the assignment's person.
   * @property {PersonnelType} personnelType The personnel type of the assignment.
   * @property {Number} priority The priority of the assignment.
   */

  /**
   * Callback for replaced state of primary assignment.
   * @callback ReplacedStateFunction
   * @param {BackupAssignment} backupAssignment The backup assignment that will replace the current one.
   */

  /**
   * Callbacks for statuses of primary assignments.
   * @typedef {Object} PrimaryAssignmentStatusCallbacks
   * @property {ReplacedStateFunction} [replaced] Callback for replaced state.
   * This state is used when the assignment's person is absent
   * and the assignment is replaced with a backup assignment.
   * @property {Function} [substituted] Callback for substituted state.
   * This state is used when the assignment's person is substituted with substitute lesson or alternate action.
   * @property {Function} [unavailable] Callback for unavailable state.
   * This state is used when the assignment's person is absent.
   * @property {Function} [valid] Callback for valid state.
   * This state is used when the assignment stands.
   */

  /**
   * Returns return value of a callback selected by the status,
   * or undefined if the callback is missing.
   * @param {PrimaryAssignmentStatusCallbacks} callbacks The callbacks, one for each possible status.
   */
  PrimaryAssignmentStatus.prototype.match = function (callbacks) {
    var _callbacks$this$_stat;
    return (_callbacks$this$_stat = callbacks[this._status]) === null || _callbacks$this$_stat === void 0 ? void 0 : _callbacks$this$_stat.call(callbacks, this._backupAssignment);
  };

  /** @constructor */
  function BackupAssignmentStatus(status) {
    this._status = status;
  }
  BackupAssignmentStatus.substituted = function () {
    return new BackupAssignmentStatus("substituted");
  };
  BackupAssignmentStatus.unavailable = function () {
    return new BackupAssignmentStatus("unavailable");
  };
  BackupAssignmentStatus.unused = function () {
    return new BackupAssignmentStatus("unused");
  };
  BackupAssignmentStatus.used = function () {
    return new BackupAssignmentStatus("used");
  };

  /**
   * Callbacks for statuses of backup assignments.
   * @typedef {Object} BackupAssignmentStatusCallbacks
   * @property {Function} [substituted] Callback for substituted state.
   * This state is used when the assignment's person is substituted with substitute lesson or alternate action.
   * @property {Function} [unavailable] Callback for unavailable state.
   * This state is used when the assignment's person is absent.
   * @property {Function} [unused] Callback for unused state.
   * This state is used when the assignment is not used to replace any primary assignment.
   * @property {Function} [used] Callback for used state.
   * This state is used when the assignment is used to replace unavailable primary assignment.
   */

  /**
   * Returns return value of a callback selected by the status,
   * or undefined if the callback is missing.
   * @param {BackupAssignmentStatusCallbacks} callbacks The callbacks, one for each possible status.
   */
  BackupAssignmentStatus.prototype.match = function (callbacks) {
    var _callbacks$this$_stat2;
    return (_callbacks$this$_stat2 = callbacks[this._status]) === null || _callbacks$this$_stat2 === void 0 ? void 0 : _callbacks$this$_stat2.call(callbacks);
  };

  /**
   * Statuses of assignments of break on a date.
   * @typedef {Object} AssignmentStatuses
   * @param {Map<PersonId, PrimaryAssignmentStatus>} primary Statuses of primary assignments.
   * @param {Map<PersonId, BackupAssignmentStatus>} backup Statuses of backup assignments.
   */

  /**
   * Determines statues of assignments of a break on a date.
   * @param {AssignmentsOfBreakAndDate} assignments The assignments of a break on a date.
   * @param {IntervalSum<DateMinuteTime>} breakPeriod The period of the assignments' break.
   * @param {Person[]} personnel The personnel in school of the break.
   * @param {Object.<PersonId, Object.<PersonnelType?, IntervalSum<DateMinuteTime>>>} personnelAbsences The absences of the personnel during the period of the break.
   * @returns {AssignmentStatuses} The statuses of assignments, grouped by their primary/backup type.
   */
  function assignmentStatuses(assignments, breakPeriod, personnel, personnelAbsences, substitutedPersonnel) {
    const primaryAssignments = assignments.primary.map(assignment => ({
      isPersonAbsent: isPersonAbsentDuringAssignment(assignment),
      isPersonSubstituted: substitutedPersonnel.includes(assignment.personId),
      person: personnel.find(person => person.id === assignment.personId),
      personId: assignment.personId
    })).sort((a, b) => {
      var _a$person, _b$person, _a$person2, _b$person2, _a$person3, _b$person3;
      return ((_a$person = a.person) === null || _a$person === void 0 ? void 0 : _a$person.initials.localeCompare((_b$person = b.person) === null || _b$person === void 0 ? void 0 : _b$person.initials)) || ((_a$person2 = a.person) === null || _a$person2 === void 0 ? void 0 : _a$person2.firstName.localeCompare((_b$person2 = b.person) === null || _b$person2 === void 0 ? void 0 : _b$person2.firstName)) || ((_a$person3 = a.person) === null || _a$person3 === void 0 ? void 0 : _a$person3.lastName.localeCompare((_b$person3 = b.person) === null || _b$person3 === void 0 ? void 0 : _b$person3.lastName)) || a.personId - b.personId;
    });
    const backupAssignments = assignments.backup.map((assignment, index) => ({
      isPersonAbsent: isPersonAbsentDuringAssignment(assignment),
      isPersonSubstituted: substitutedPersonnel.includes(assignment.personId),
      personId: assignment.personId,
      personnelType: assignment.personnelType,
      priority: index + 1
    }));
    const {
      primaryStatuses,
      usableBackupAssignments
    } = primaryAssignments.reduce(({
                                     primaryStatuses,
                                     usableBackupAssignments
                                   }, assignment) => {
      if (assignment.isPersonSubstituted) {
        return {
          primaryStatuses: primaryStatuses.set(assignment.personId, PrimaryAssignmentStatus.substituted()),
          usableBackupAssignments
        };
      } else if (!assignment.isPersonAbsent) {
        return {
          primaryStatuses: primaryStatuses.set(assignment.personId, PrimaryAssignmentStatus.valid()),
          usableBackupAssignments
        };
      } else {
        return {
          primaryStatuses: primaryStatuses.set(assignment.personId, usableBackupAssignments.length === 0 ? PrimaryAssignmentStatus.unavailable() : PrimaryAssignmentStatus.replaced({
            personId: usableBackupAssignments[0].personId,
            personnelType: usableBackupAssignments[0].personnelType,
            priority: usableBackupAssignments[0].priority
          })),
          usableBackupAssignments: usableBackupAssignments.slice(1)
        };
      }
    }, {
      primaryStatuses: new Map(),
      usableBackupAssignments: backupAssignments.filter(assignment => !assignment.isPersonAbsent)
    });
    const backupStatuses = backupAssignments.reduce((backupStatuses, assignment) => backupStatuses.set(assignment.personId, assignment.isPersonSubstituted ? BackupAssignmentStatus.substituted() : assignment.isPersonAbsent ? BackupAssignmentStatus.unavailable() : usableBackupAssignments.some(x => x.personId === assignment.personId) ? BackupAssignmentStatus.unused() : BackupAssignmentStatus.used()), new Map());
    return {
      primary: primaryStatuses,
      backup: backupStatuses
    };
    function isPersonAbsentDuringAssignment(assignment) {
      var _personAbsences$inter, _personAbsences, _personAbsences$assig, _personAbsences$assig2;
      const personAbsences = personnelAbsences[assignment.personId];
      return ((_personAbsences$inter = personAbsences === null || personAbsences === void 0 ? void 0 : (_personAbsences = personAbsences[null]) === null || _personAbsences === void 0 ? void 0 : _personAbsences.intersectsWith(breakPeriod)) !== null && _personAbsences$inter !== void 0 ? _personAbsences$inter : false) || ((_personAbsences$assig = personAbsences === null || personAbsences === void 0 ? void 0 : (_personAbsences$assig2 = personAbsences[assignment.personnelType]) === null || _personAbsences$assig2 === void 0 ? void 0 : _personAbsences$assig2.intersectsWith(breakPeriod)) !== null && _personAbsences$assig !== void 0 ? _personAbsences$assig : false);
    }
  }
  function canDeleteAssignment(isAssignedPersonSubstituted) {
    return !isAssignedPersonSubstituted;
  }
  function canPersonHaveAssignment(isAssignmentBackup, isAssignedPersonSubstituted) {
    return isAssignmentBackup ? canPersonHaveBackupAssignment(isAssignedPersonSubstituted) : canPersonHavePrimaryAssignment();
  }
  function canPersonHaveBackupAssignment(isAssignedPersonSubstituted) {
    return !isAssignedPersonSubstituted;
  }
  function canPersonHavePrimaryAssignment() {
    return true;
  }
  _module_exports = {
    assignmentStatuses,
    canDeleteAssignment,
    canPersonHaveAssignment,
    canPersonHavePrimaryAssignment,
    canPersonHaveBackupAssignment
  };
  return _module_exports;
})();

/**
 * MODULE playground_duty\editableAssignments.js
 **/
const _module_widget_editableAssignments = (function widget_editableAssignments(
  _module_widget_assignments
) {
  let _module_exports = {};

  const {
    canDeleteAssignment,
    canPersonHaveAssignment
  } = _module_widget_assignments;
  function EditableAssignments(change) {
    this.primary = [];
    this.backup = [];
    this._change = change !== null && change !== void 0 ? change : () => {};
    this._originalBackupPersonnel = [];
  }
  EditableAssignments.prototype.assignPersonAsBackup = function (personWithType, priority) {
    const primaryIndex = this.primary.findIndex(assignment => assignment.personId === personWithType.id);
    if (primaryIndex !== -1) {
      this.primary.splice(primaryIndex, 1);
    }
    const backupIndex = this.backup.findIndex(assignment => assignment.personId === personWithType.id);
    if (priority !== null && backupIndex === priority && this.backup[backupIndex].personnelType === personWithType.personnelType) {
      return;
    }
    this.backup.splice(priority !== null ? Math.max(0, priority) : this.backup.length, 0, {
      personId: personWithType.id,
      personnelType: personWithType.personnelType
    });
    if (backupIndex !== -1) {
      this.backup.splice(priority !== null && priority <= backupIndex ? backupIndex + 1 : backupIndex, 1);
    }
    this._change("assignment");
  };
  EditableAssignments.prototype.assignPersonAsPrimary = function (personWithType) {
    const backupIndex = this.backup.findIndex(assignment => assignment.personId === personWithType.id);
    if (backupIndex !== -1) {
      this.backup.splice(backupIndex, 1);
    }
    const primaryIndex = this.primary.findIndex(assignment => assignment.personId === personWithType.id);
    this.primary.splice(primaryIndex === -1 ? this.primary.length : primaryIndex, primaryIndex === -1 ? 0 : 1, {
      personId: personWithType.id,
      personnelType: personWithType.personnelType
    });
    this._change("assignment");
  };
  EditableAssignments.prototype.canDelete = function (isPersonSubstituted) {
    return canDeleteAssignment(isPersonSubstituted);
  };
  EditableAssignments.prototype.canToggleBackup = function (personId, isPersonSubstituted) {
    // Even though primary assignments with substituted personnel cannot be toggled backup,
    // allow this for assignments that were originally backup.
    // This way users can undo toggle to primary without reopening the details,
    // losing other edits in the process.
    return this._originalBackupPersonnel.includes(personId) || canPersonHaveAssignment(this.primary.some(assignment => assignment.personId === personId), isPersonSubstituted);
  };
  EditableAssignments.prototype.canMoveDown = function (personId) {
    const index = this.backup.findIndex(assignment => assignment.personId === personId);
    return index !== -1 && index !== this.backup.length - 1;
  };
  EditableAssignments.prototype.canMoveUp = function (personId) {
    const index = this.backup.findIndex(assignment => assignment.personId === personId);
    return index !== -1 && index !== 0;
  };
  EditableAssignments.prototype.delete = function (personId) {
    const primaryIndex = this.primary.findIndex(assignment => assignment.personId === personId);
    if (primaryIndex !== -1) {
      this.primary.splice(primaryIndex, 1);
      this._change("delete");
    } else {
      const backupIndex = this.backup.findIndex(assignment => assignment.personId === personId);
      if (backupIndex !== -1) {
        this.backup.splice(backupIndex, 1);
        this._change("delete");
      }
    }
  };
  EditableAssignments.prototype.moveDown = function (personId) {
    const index = this.backup.findIndex(assignment => assignment.personId === personId);
    switch (index) {
      case -1:
      case this.backup.length - 1:
        break;
      default:
      {
        const current = this.backup[index];
        const next = this.backup[index + 1];
        this.backup.splice(index, 2, next, current);
        this._change("move");
      }
    }
  };
  EditableAssignments.prototype.moveUp = function (personId) {
    const index = this.backup.findIndex(assignment => assignment.personId === personId);
    switch (index) {
      case -1:
      case 0:
        break;
      default:
      {
        const current = this.backup[index];
        const previous = this.backup[index - 1];
        this.backup.splice(index - 1, 2, current, previous);
        this._change("move");
      }
    }
  };
  EditableAssignments.prototype.toggleBackup = function (personId) {
    const index = this.primary.findIndex(assignment => assignment.personId === personId);
    if (index !== -1) {
      const [personWithType] = this.primary.splice(index, 1);
      this.backup.unshift(personWithType);
      this._change("toggleBackup");
    } else {
      const index = this.backup.findIndex(assignment => assignment.personId === personId);
      if (index !== -1) {
        const [assignment] = this.backup.splice(index, 1);
        this.primary.push(assignment);
        this._change("toggleBackup");
      }
    }
  };
  EditableAssignments.prototype.update = function (primary, backup) {
    this.primary.splice(0, this.primary.length, ...primary);
    this.backup.splice(0, this.backup.length, ...backup);
    this._originalBackupPersonnel.splice(0, this._originalBackupPersonnel.length, ...this.backup.map(x => x.personId));
    this._change("update");
  };
  _module_exports = {
    EditableAssignments
  };
  return _module_exports;
})(_module_widget_assignments);

/**
 * MODULE playground_duty\assignmentPlaceholder.js
 **/
const _module_widget_assignmentPlaceholder = (function widget_assignmentPlaceholder() {
  let _module_exports = {};

  /** @vue/component */
  const AssignmentPlaceholder = {
    name: "AssignmentPlaceholder",
    props: {
      viewModel: {
        type: Object,
        required: true
      },
      placement: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        hover: false
      };
    },
    computed: {
      containerStyle() {
        return this.viewModel.order ? "--order: ".concat(this.viewModel.order, ";") : "";
      },
      label() {
        switch (this.placement) {
          case "assignments-empty-primary":
            return "Forvagt";
          case "assignments-empty-backup":
            return "Bagvagt";
          default:
            return null;
        }
      }
    },
    methods: {
      dragDrop() {
        this.hover = false;
        if (this.viewModel.visible) {
          this.viewModel.addAssignment();
        }
      },
      dragEnter() {
        this.hover = true;
      },
      dragOver($event) {
        if (this.viewModel.visible) {
          $event.preventDefault();
        }
      },
      dragLeave() {
        this.hover = false;
      }
    },
    render(h) {
      var _this$label;
      // Styles in './assignmentPlaceholder.css'
      if (!this.viewModel.available) {
        return null;
      }
      return h("div", {
        class: "assignment-placeholder-container",
        style: this.containerStyle,
        attrs: {
          "data-placement": this.placement
        }
      }, [h("div", {
        class: "assignment-placeholder no-text-overflow",
        attrs: {
          "aria-hidden": true,
          "data-hover": this.hover,
          "data-placement": this.placement,
          "data-visible": this.viewModel.visible
        },
        on: {
          dragenter: this.dragEnter.bind(this),
          dragleave: this.dragLeave.bind(this),
          dragover: this.dragOver.bind(this),
          drop: this.dragDrop.bind(this)
        }
      }, [(_this$label = this.label) === null || _this$label === void 0 ? void 0 : _this$label.toString()])]);
    }
  };
  _module_exports = {
    AssignmentPlaceholder
  };
  return _module_exports;
})();

/**
 * MODULE playground_duty\assignmentPill.js
 **/
const _module_widget_assignmentPill = (function widget_assignmentPill() {
  let _module_exports = {};

  /** @typedef {import('./assignments').PrimaryAssignmentStatus PrimaryAssignmentStatus */
  /** @typedef {import('./assignments').BackupAssignmentStatus BackupAssignmentStatus */

  /**
   * An assignment.
   * @typedef {Object} Assignment
   * @property {String?} personFirstName
   * @property {Number?} personHasMultipleEmployments
   * @property {String?} personInitials
   * @property {String?} personLastName
   * @property {Number?} personIsCurrentUser
   * @property {String?} personnelTypeAbbreviation
   * @property {String?} personnelTypeDescription
   * @property {(PrimaryAssignmentStatus|BackupAssignmentStatus)?} [status]
   */

    // @vue/component
  const AssignmentPill = {
      name: "AssignmentPill",
      props: {
        /** @type {Assignment} */
        viewModel: {
          type: Object,
          required: true
        }
      },
      computed: {
        isCurrentUser() {
          return !!this.viewModel.personIsCurrentUser;
        },
        shortDescription() {
          var _this$viewModel$perso;
          const personnelTypePart = this.viewModel.personHasMultipleEmployments ? " (".concat(this.viewModel.personnelTypeAbbreviation, ")") : "";
          return "".concat((_this$viewModel$perso = this.viewModel.personInitials) !== null && _this$viewModel$perso !== void 0 ? _this$viewModel$perso : "???").concat(personnelTypePart);
        },
        longDescription() {
          var _this$viewModel$statu, _this$viewModel$perso2, _this$viewModel$perso3, _this$viewModel$perso4;
          const statusDescription = (_this$viewModel$statu = this.viewModel.status) === null || _this$viewModel$statu === void 0 ? void 0 : _this$viewModel$statu.match({
            unavailable: () => "fraværende ",
            replaced: () => "fraværende, dækket af bagvagt",
            substituted: () => "fraværende, vikardækket i vikarmodulet",
            used: () => "vikar for en fraværende forvagt"
          });
          const statusDescriptionPart = statusDescription ? ",\n".concat(statusDescription) : "";
          return "".concat((_this$viewModel$perso2 = this.viewModel.personFirstName) !== null && _this$viewModel$perso2 !== void 0 ? _this$viewModel$perso2 : "???", " ").concat((_this$viewModel$perso3 = this.viewModel.personLastName) !== null && _this$viewModel$perso3 !== void 0 ? _this$viewModel$perso3 : "???", " (").concat((_this$viewModel$perso4 = this.viewModel.personnelTypeDescription) !== null && _this$viewModel$perso4 !== void 0 ? _this$viewModel$perso4 : "???", ")").concat(statusDescriptionPart);
        },
        srDescription() {
          return "".concat(this.viewModel.personInitials, " ").concat(this.longDescription);
        },
        status() {
          var _this$viewModel$statu2, _this$viewModel$statu3;
          return (_this$viewModel$statu2 = (_this$viewModel$statu3 = this.viewModel.status) === null || _this$viewModel$statu3 === void 0 ? void 0 : _this$viewModel$statu3.match({
            replaced: () => "replaced",
            substituted: () => "replaced",
            unavailable: () => "unavailable",
            used: () => "used"
          })) !== null && _this$viewModel$statu2 !== void 0 ? _this$viewModel$statu2 : "normal";
        },
        statusIcon() {
          var _this$viewModel$statu4;
          const kind = this.isCurrentUser ? "fa" : "fal";
          return (_this$viewModel$statu4 = this.viewModel.status) === null || _this$viewModel$statu4 === void 0 ? void 0 : _this$viewModel$statu4.match({
            replaced: () => "".concat(kind, " fa-times-circle"),
            substituted: () => "".concat(kind, " fa-retweet"),
            unavailable: () => "".concat(kind, " fa-times-circle"),
            used: () => "".concat(kind, " fa-arrow-circle-up")
          });
        }
      },
      render(h) {
        var _this$shortDescriptio, _this$srDescription;
        // Styles in './assignmentPill.css'
        return h("div", {
          class: ["assignment-pill", {
            "current-user": this.isCurrentUser
          }],
          attrs: {
            "data-status": this.status
          },
          domProps: {
            title: this.longDescription
          }
        }, [
          // Status icon
          this.statusIcon ? h("span", {
            class: [this.statusIcon, "mr-1"],
            attrs: {
              "aria-hidden": true
            }
          }, []) : "",
          // Short description
          h("span", {
            attrs: {
              "aria-hidden": true
            }
          }, [(_this$shortDescriptio = this.shortDescription) === null || _this$shortDescriptio === void 0 ? void 0 : _this$shortDescriptio.toString()]),
          // Description for assistive technologies
          h("span", {
            class: "sr-only"
          }, [(_this$srDescription = this.srDescription) === null || _this$srDescription === void 0 ? void 0 : _this$srDescription.toString()])]);
      }
    };
  _module_exports = {
    AssignmentPill
  };
  return _module_exports;
})();

/**
 * MODULE shared\widgetToolbar.js
 **/
const _module_shared_widgetToolbar = (function shared_widgetToolbar() {
  let _module_exports = {};

  /**
   * A component that places its children in a toolbar layout,
   * with special consideration for placement of widget (e.g. narrow).
   * See styles for details.
   */
    // @vue/component
  const WidgetToolbar = {
      name: "WidgetToolbar",
      props: {
        placement: {
          type: String,
          required: true
        }
      },
      computed: {
        narrow() {
          return this.placement === "narrow";
        }
      },
      render(h) {
        return h("div", {
          class: ["widget-toolbar", {
            narrow: this.narrow
          }]
        }, this.$slots.default);
      }
    };
  _module_exports = {
    WidgetToolbar
  };
  return _module_exports;
})();

/**
 * MODULE shared\widgetHeader.js
 **/
const _module_shared_widgetHeader = (function shared_widgetHeader() {
  let _module_exports = {};

  // @vue/component
  const WidgetHeader = {
    name: "WidgetHeader",
    props: {
      widgetName: {
        type: String,
        required: true
      }
    },
    render(h) {
      var _this$widgetName;
      return h("div", [h("div", {
        class: "widget-header d-flex align-items-end"
      }, [h("h2", [(_this$widgetName = this.widgetName) === null || _this$widgetName === void 0 ? void 0 : _this$widgetName.toString()]), h("p", {
        class: "ml-auto"
      }, ["Educa Personale"])]), h("div", {
        class: "widget-header-separator"
      }, [])]);
    }
  };
  _module_exports = {
    WidgetHeader
  };
  return _module_exports;
})();

/**
 * MODULE shared\viewportWidth.js
 **/
const _module_shared_viewportWidth = (function shared_viewportWidth() {
  let _module_exports = {};

  const widthBreakpoints = {
    _values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
      xxl: 1400
    },
    _checkBreakpoint(breakpoint) {
      if (!(breakpoint in this._values)) {
        throw new Error("Unknown breakpoint \"".concat(breakpoint, "\""));
      }
    },
    min(breakpoint) {
      this._checkBreakpoint(breakpoint);
      return this._values[breakpoint];
    },
    max(breakpoint) {
      // The maximum value is reduced by 0.02px to work around the limitations of
      // `min-` and `max-` prefixes and viewports with fractional widths.
      // See https://www.w3.org/TR/mediaqueries-4/#mq-min-max
      // Uses 0.02px rather than 0.01px to work around a current rounding bug in Safari.
      // See https://bugs.webkit.org/show_bug.cgi?id=178261
      this._checkBreakpoint(breakpoint);
      const value = this._values[breakpoint];
      return value && value > 0 ? value - 0.02 : null;
    },
    next(breakpoint) {
      this._checkBreakpoint(breakpoint);
      switch (breakpoint) {
        case "xs":
          return "sm";
        case "sm":
          return "md";
        case "md":
          return "lg";
        case "lg":
          return "xl";
        case "xl":
          return "xxl";
        default:
          return null;
      }
    }
  };
  function ViewportWidth() {
    const minQueries = ["sm", "md", "lg", "xl", "xxl"].reduce((result, breakpoint) => {
      result[breakpoint] = new ViewportWidthQuery({
        min: breakpoint
      });
      return result;
    }, {});
    const maxQueries = ["sm", "md", "lg", "xl", "xxl"].reduce((result, breakpoint) => {
      result[breakpoint] = new ViewportWidthQuery({
        max: breakpoint
      });
      return result;
    }, {});
    this._minQueries = minQueries;
    this._maxQueries = maxQueries;
  }
  ViewportWidth.prototype.isAfter = function (breakpoint) {
    return breakpoint === "xs" || this._minQueries[breakpoint].matches;
  };
  ViewportWidth.prototype.isAfterBeforeNext = function (breakpoint) {
    switch (breakpoint) {
      case "xs":
        return this.isBefore("sm");
      case "xxl":
        return this.isAfter("xxl");
      default:
        return this.isAfter(breakpoint) && this.isBefore(widthBreakpoints.next(breakpoint));
    }
  };
  ViewportWidth.prototype.isBefore = function (breakpoint) {
    return breakpoint !== "xs" && this._maxQueries[breakpoint].matches;
  };
  ViewportWidth.prototype.isBetween = function (startBreakpoint, endBreakpoint) {
    return this.isAfter(startBreakpoint) && this.isBefore(endBreakpoint);
  };
  ViewportWidth.prototype.destroy = function () {
    this._minQueries.forEach(query => query.destroy());
    this._maxQueries.forEach(query => query.destroy());
  };
  function ViewportWidthQuery(options) {
    const queryString = widthMediaQueryString(options);
    const query = window.matchMedia(queryString);
    const queryChangeListener = () => this._updateMatches();
    this.matches = null;
    this._query = query;
    this._listener = queryChangeListener;
    query.addEventListener("change", queryChangeListener);
    this._updateMatches();
  }
  ViewportWidthQuery.prototype._updateMatches = function () {
    var _this$_query;
    this.matches = (_this$_query = this._query) === null || _this$_query === void 0 ? void 0 : _this$_query.matches;
  };
  ViewportWidthQuery.prototype.destroy = function () {
    this._query.removeEventListener("change", this._listener);
    this._query = null;
    this.matches = null;
  };
  function widthMediaQueryString(options) {
    const min = options.min ? widthBreakpoints.min(options.min) : null;
    const max = options.max ? widthBreakpoints.max(options.max) : null;
    if (min && max) {
      return "(min-width: ".concat(min, "px) and (max-width: ").concat(max, "px)");
    } else if (min) {
      return "(min-width: ".concat(min, "px)");
    } else if (max) {
      return "(max-width: ".concat(max, "px)");
    } else {
      return "";
    }
  }
  _module_exports = {
    ViewportWidth
  };
  return _module_exports;
})();

/**
 * MODULE shared\time.js
 **/
const _module_shared_time = (function shared_time() {
  let _module_exports = {};

  /**
   * Represent a time point during a day with minute accuracy.
   * @typedef {Object} MinuteTime
   * @property {Number} hour The hour part of the time point.
   * @property {Number} minute The minute part of the time point.
   */

  /**
   * Represent a time duration with minute accuracy.
   * @typedef {Object} MinuteDuration
   * @property {Number} totalMinutes The total amount of minutes within the duration.
   */

  function makePoint(hour, minute) {
    if (hour > 23 || hour < 0) {
      throw "Hour must be between 0 and 23";
    }
    if (minute > 59 || minute < 0) {
      throw "Minute must be between 0 and 59";
    }
    hour = Math.floor(hour);
    minute = Math.floor(minute);
    return {
      hour: hour,
      minute: minute,
      addHours(hours) {
        const newHour = hour + hours;
        if (newHour > 23) {
          return makePoint(23, 59);
        }
        if (newHour < 0) {
          return makePoint(0, 0);
        }
        return makePoint(newHour, minute);
      },
      addDuration(duration) {
        const newMinutes = hour * 60 + minute + duration.totalMinutes;
        if (newMinutes < 0) {
          return makePoint(0, 0);
        }
        if (newMinutes >= 24 * 60) {
          return makePoint(23, 59);
        }
        return makePoint(Math.floor(newMinutes / 60), newMinutes % 60);
      },
      compare(other) {
        if (this.isBefore(other)) {
          return -1;
        }
        if (this.isSame(other)) {
          return 0;
        }
        return 1;
      },
      durationTo(other) {
        if (this.isAfter(other)) {
          throw "The other time point must be after or same as this time point";
        }
        if (other.hour === hour) {
          return makeDuration(other.minute - minute);
        }
        return makeDuration((other.hour - hour - 1) * 60 + (60 - minute) + other.minute);
      },
      /**
       * Formats the time point to a string.
       * Supported formats are "input".
       * The "input" format results in a string understood by input elements of type "time" ('hh:mm').
       * @param {string} format A format specification to use. The only supported value is "input".
       * @returns A string representation of the time point, according to specified format.
       * @throws {Error} Throws when format argument is none of the supported values.
       */
      format(format) {
        switch (format) {
          case "input":
            // Format used with time input elements and in json, which both expect a "00:00" format
            return padded(hour) + ":" + padded(minute);
          default:
            throw new Error('Unknown time point format "' + format + '"');
        }
      },
      isAfter(other) {
        return other.hour < hour || other.hour === hour && other.minute < minute;
      },
      isBefore(other) {
        return other.hour > hour || other.hour === hour && other.minute > minute;
      },
      isSame(other) {
        return other.hour === hour && other.minute === minute;
      },
      startOfHour() {
        return makePoint(hour, 0);
      },
      toJSON() {
        return padded(hour) + ":" + padded(minute);
      },
      toString() {
        return hour.toString() + ":" + padded(minute);
      }
    };
  }

  /**
   * Returns a minute duration from number of minutes.
   * @param {Number} minutes The number of minutes.
   * @returns {MinuteDuration} A duration that consists of the number of minutes.
   */
  function makeDuration(minutes) {
    return {
      totalMinutes: minutes,
      isSame(other) {
        return minutes === other.totalMinutes;
      },
      toString(format) {
        const hour = Math.floor(minutes / 60);
        const minute = minutes % 60;
        switch (format) {
          case "human":
          {
            const hourLabel = hour == 1 ? "time" : "timer";
            const minuteLabel = minute == 1 ? "minut" : "minutter";
            const hourPart = hour + " " + hourLabel;
            const minutePart = minute + " " + minuteLabel;
            return (hour > 0 || minute == 0 ? hourPart : "") + (minute > 0 ? " " + minutePart : "");
          }
          default:
            return hour + ":" + padded(minute);
        }
      }
    };
  }
  function padded(value) {
    return value < 10 ? "0" + value.toString() : value.toString();
  }

  /**
   * Parses a string and return a minute duration.
   * Supported format of strings is "h:mm" (more than one h digit is allowed).
   * Leading or trailing whitespace is not ignored, and needs to be trimmed.
   * @param {string} value The string to be parsed.
   * @returns {?MinuteDuration} A minute time duration if parsing was successful, null otherwise.
   * @throws {string} Throws when parsed hour or minute values are out of bounds.
   */
  function parseDuration(value) {
    const result = value.match(/(\d+):(\d\d)/u);
    if (result && result[0] === value) {
      const [, hoursPart, minutePart] = result;
      const minute = Number.parseInt(minutePart, 10);
      if (minute > 59 || minute < 0) {
        throw "Minute must be between 0 and 59";
      }
      const hours = Number.parseInt(hoursPart, 10);
      if (hours < 0) {
        throw "Hours must be greater than or equal to 0";
      }
      return makeDuration(hours * 60 + minute);
    } else {
      return null;
    }
  }

  /**
   * Parses a string and return a time point.
   * Supported formats of strings are "hh:mm" and "h:mm".
   * Leading or trailing whitespace is not ignored, and needs to be trimmed.
   * @param {string} value The string to be parsed.
   * @returns {?MinuteTime} A time point if parsing was successful, null otherwise.
   * @throws {string} Throws when parsed hour or minute values are out of bounds.
   */
  function parsePoint(value) {
    const result = value.match(/(\d?\d):(\d\d)/u);
    if (result && result[0] === value) {
      const [, hour, minute] = result;
      return makePoint(Number.parseInt(hour, 10), Number.parseInt(minute, 10));
    } else {
      return null;
    }
  }
  _module_exports = {
    parsePoint,
    point: makePoint,
    duration: makeDuration,
    parseDuration
  };
  return _module_exports;
})();

/**
 * MODULE shared\tableRowToolbar.js
 **/
const _module_shared_tableRowToolbar = (function shared_tableRowToolbar() {
  let _module_exports = {};

  /**
   * @typedef {Object} Action An action that can be invoked from UI.
   * @property {String} label Label of the action, displayed in tooltip and for screen readers.
   * @property {String} icon CSS class specifying icon for the action.
   * @property {Boolean} [enabled=true] Determines whether the action is displayed.
   * @property {Function} call The function that is called when the action is invoked.
   */

  /**
   * A component that, for an item displayed in a row of a table,
   * lays out actions affecting that item.
   * The component is usually placed in a separate column of the table.
   * The actions are displayed as icon links.
   */
    // @vue/component
  const TableRowToolbar = {
      name: "TableRowToolbar",
      props: {
        /** @type {Action[]} */
        actions: {
          type: Array,
          required: true
        }
      },
      render(h) {
        return h("div", {
          class: "table-row-toolbar"
        }, this.actions.map((action, index) => {
          var _action$label, _action$label2;
          return h("b-link", {
            props: {
              disabled: action.enabled === false
            },
            on: {
              click: () => action.call()
            },
            key: index
          }, [
            // Icon
            h("span", {
              class: ["fa", "fa-lg", "fa-fw", action.icon],
              domProps: {
                title: (_action$label = action.label) === null || _action$label === void 0 ? void 0 : _action$label.toString()
              }
            }),
            // Label
            h("span", {
              class: "sr-only"
            }, [(_action$label2 = action.label) === null || _action$label2 === void 0 ? void 0 : _action$label2.toString()])]);
        }));
      }
    };
  _module_exports = {
    TableRowToolbar
  };
  return _module_exports;
})();

/**
 * MODULE shared\singleSelection.js
 **/
const _module_shared_singleSelection = (function shared_singleSelection() {
  let _module_exports = {};

  /**
   * Represents an item available for selection.
   * @typedef {Object} Item
   * @property {string} [text] Description of the item, shown to the user. Required when html is not set.
   * @property {string} [html] Html used to show the item to the user. Required when text is not set.
   * Use with caution as contents are not HTML escaped.
   * @property {Boolean} [disabled=false] Determines whether the item can be selected by the user.
   * @property {*} value Value of the item, used to identify the item and passed to selection change callback. Cannot be undefined.
   */

  /**
   * A function called when selected item changes or items are updated.
   * @callback SelectionChangeCallback
   * @param {*} value Value of the selected item.
   * @param {Boolean} isResetOrUpdate Indicates whether the callback was called in response to update of items, or reset of desired value.
   */

  /**
   * Represents options that specify behavior of single selection.
   * @typedef {Options} SingleSelectionOptions
   * @property {?SelectionChangeCallback} [change] A function that is called when selected value changes or items are updated.
   * @property {Boolean} [preselectFirst=false] Determines whether value of first non-disabled item is selected,
   * when "desired" selected value is undefined.
   * @property {*} [initial=undefined] Determines the initial "desired" selected value.
   * @property {Item} fallback An item that is selected when no item is selected or available,
   * or an item with selected value is not available.
   */

  const ActualValueSource = {
    desiredValue: 0,
    fallbackValue: 1,
    firstValue: 2
  };
  const ActualItemsSource = {
    desiredItems: 0,
    desiredItemsWithFallback: 1
  };
  function sourcesOfActualItemsAndValue(desiredItems, desiredValue, preselectFirst) {
    if (desiredItems.length === 0) {
      return {
        items: ActualItemsSource.desiredItemsWithFallback,
        value: ActualValueSource.fallbackValue
      };
    } else if (desiredValue === undefined || !desiredItems.some(x => !x.disabled && x.value === desiredValue)) {
      if (preselectFirst) {
        const first = desiredItems.find(x => !x.disabled);
        return first !== undefined ? {
          items: ActualItemsSource.desiredItems,
          value: ActualValueSource.firstValue
        } : {
          items: ActualItemsSource.desiredItemsWithFallback,
          value: ActualValueSource.fallbackValue
        };
      } else {
        return {
          items: ActualItemsSource.desiredItemsWithFallback,
          value: ActualValueSource.fallbackValue
        };
      }
    } else {
      return {
        items: ActualItemsSource.desiredItems,
        value: ActualValueSource.desiredValue
      };
    }
  }

  /**
   * @classdesc Represents a single selection of an value from among available values, represented by items.
   * There are two kinds of selected value tracked at the same time: an "actual" one and a "desired" one.
   * This is done to ensure that:
   *  - there is always an items selected, and
   *  - that the selected value is independent of available items, and preserved when they change.
   * The "desired" selected value is what is controlled by the user and code.
   * The "actual" selected value is computed, and updates whenever available items or the "desired" selected value change.
   * It is the same as the "desired" selected value when:
   *  - the "desired" selected value is not undefined,
   *  - there is an available item with the value of the "desired" selected value, and
   *  - that item is selected.
   * Otherwise, the "actual" selected value, if "preselect first" option is set,
   * is equal to the value of first non-disabled item.
   * If such item is not available or the "preselect first" option is not set, the value of the "fallback" item is used.
   * Whenever the "actual" selected value changes, or items are updated,
   * the change callback is called with that value as an argument.
   * @summary Represents a selection of a single value from a list of predefined values.
   * @constructor
   * @param {SingleSelectionOptions} options The options
   */
  function SingleSelection(options) {
    if (!options || !options.fallback) {
      throw new Error('Fallback value is not set in "options", or "options" is not an object.');
    }
    this._actualItems = [options.fallback];
    this._actualValue = options.fallback.value;
    this._desiredItems = [];
    this._desiredValue = options.initial;
    this._options = {
      change: options.change || (() => {}),
      preselectFirst: !!options.preselectFirst,
      fallback: options.fallback
    };
  }
  SingleSelection.prototype = {
    /**
     * The "actual" selected value.
     * @member {*}
     */
    get value() {
      return this._actualValue;
    },
    /**
     * The items available for selection.
     * @member {Item[]}
     */
    get items() {
      return this._actualItems;
    },
    /**
     * Changes the "desired" selected value.
     * If the change results in the change of "actual" selected value, the change callback is queued for execution.
     * @param {*} newValue The new value.
     */
    select(newValue) {
      this._desiredValue = newValue;
      this._synchronize(false);
    },
    /**
     * Resets the "desired" selected value.
     * The change callback is always queued for execution, as if update was called.
     * @param {*} newValue The new value.
     */
    reset(newValue) {
      this._desiredValue = newValue;
      this._synchronize(true);
    },
    /**
     * Changes available items.
     * Each item should have a value different from that of other items, also from the "fallback" item.
     * The change callback is always queued for execution.
     * @param {Array.<Item>} items The new available items.
     */
    update(items) {
      this._desiredItems = items.filter(x => x.value !== undefined);
      this._synchronize(true);
    },
    _synchronize(isUpdate) {
      const oldActualValue = this._actualValue;
      const {
        value: newActualValueSource,
        items: newActualItemsSource
      } = sourcesOfActualItemsAndValue(this._desiredItems, this._desiredValue, this._options.preselectFirst);
      const newActualValue = newActualValueSource === ActualValueSource.desiredValue ? this._desiredValue : newActualValueSource === ActualValueSource.fallbackValue ? this._options.fallback.value : newActualValueSource === ActualValueSource.firstValue ? this._desiredItems.find(x => !x.disabled).value : undefined;
      const newActualItems = newActualItemsSource === ActualItemsSource.desiredItems ? this._desiredItems : newActualItemsSource === ActualItemsSource.desiredItemsWithFallback ? [this._options.fallback, ...this._desiredItems] : [];
      this._actualValue = newActualValue;
      this._actualItems = newActualItems;
      if (isUpdate || oldActualValue !== newActualValue) {
        setTimeout(() => this._options.change(newActualValue, isUpdate));
      }
    }
  };
  _module_exports = {
    SingleSelection
  };
  return _module_exports;
})();

/**
 * MODULE shared\settingsStores.js
 **/
const _module_shared_settingsStores = (function shared_settingsStores() {
  let _module_exports = {};

  _module_exports = {
    browserSession(widgetName, scope, sessionUUID, expireAfterInSecs) {
      const key = "kmd-" + widgetName + "-widget-" + scope + "-settings";
      return {
        read() {
          try {
            const serializedSettings = window.sessionStorage.getItem(key);
            if (serializedSettings === null) return null;
            const {
              value,
              writtenAt,
              sessionUUID: storedSessionUUID
            } = JSON.parse(serializedSettings);
            if (storedSessionUUID !== sessionUUID) {
              return null;
            }
            if (expireAfterInSecs === 0 || expireAfterInSecs && Date.now() - new Date(writtenAt) > expireAfterInSecs) {
              return null;
            }
            return value || {};
          } catch (_) {
            return null;
          }
        },
        write(value) {
          try {
            window.sessionStorage.setItem(key, JSON.stringify({
              value,
              writtenAt: new Date().toJSON(),
              sessionUUID
            }));
          } catch (_) {
            return;
          }
        }
      };
    },
    database(aulaToken, axios, widgetName, isNoticeBoard, institutionCode) {
      return {
        read() {
          const params = {
            widgetName
          };
          if (isNoticeBoard && institutionCode) {
            params.institutionCode = institutionCode;
          }
          return axios.get("https://personale.api.kmd.dk/aula/api/v2/settings", {
            headers: {
              Authorization: aulaToken
            },
            params: params
          }).then(x => x.data, () => null);
        },
        write(value) {
          const params = {
            widgetName
          };
          if (isNoticeBoard && institutionCode) {
            params.institutionCode = institutionCode;
          }
          return axios.post("https://personale.api.kmd.dk/aula/api/v2/settings", value, {
            headers: {
              Authorization: aulaToken
            },
            params: params
          });
        }
      };
    }
  };
  return _module_exports;
})();

/**
 * MODULE shared\setFocus.js
 **/
const _module_shared_setFocus = (function shared_setFocus() {
  let _module_exports = {};

  const elementsEventsNames = new WeakMap();
  const SetFocus = {
    bind(el, binding, vnode) {
      var _elementsEventsNames$;
      const existingEventNames = (_elementsEventsNames$ = elementsEventsNames.get(el)) !== null && _elementsEventsNames$ !== void 0 ? _elementsEventsNames$ : [];
      const eventNames = Object.keys(binding.modifiers);
      const missingEventNames = eventNames.filter(eventName => !existingEventNames.includes(eventName));
      const component = vnode.componentInstance;
      missingEventNames.forEach(eventName => component.$on(eventName, setFocus));
      elementsEventsNames.set(el, [...existingEventNames, ...missingEventNames]);
    },
    unbind(el, _, vnode) {
      var _elementsEventsNames$2;
      const component = vnode.componentInstance;
      const existingEventNames = (_elementsEventsNames$2 = elementsEventsNames.get(el)) !== null && _elementsEventsNames$2 !== void 0 ? _elementsEventsNames$2 : [];
      elementsEventsNames.delete(el);
      existingEventNames.forEach(eventName => component.$off(eventName, setFocus));
    }
  };
  function setFocus() {
    setTimeout(() => {
      var _this$$el$querySelect;
      return (_this$$el$querySelect = this.$el.querySelector("[data-set-focus]")) === null || _this$$el$querySelect === void 0 ? void 0 : _this$$el$querySelect.focus();
    }, 0);
  }
  _module_exports = {
    SetFocus
  };
  return _module_exports;
})();

/**
 * MODULE shared\result.js
 **/
const _module_shared_result = (function shared_result() {
  let _module_exports = {};

  /**
   * @classdesc Represents a value that is a result of a computation. Either an error or a computed value.
   * @constructor
   * @param {*} value The value representing the result of a computation.
   * @param {boolean} [isSuccess=true] Indicates the type of value. When truthy, the value represents a computed value, otherwise an error.
   */
  function Result(value, isSuccess) {
    isSuccess = isSuccess === undefined ? true : !!isSuccess;
    this.isSuccess = isSuccess;
    this.value = isSuccess ? value : undefined;
    this.error = isSuccess ? undefined : value;
  }

  /**
   * A callback that takes in a value and returns a new one.
   * @callback MapCallback
   * @param {*} value
   * @returns {(*|Result)}
   **/

  /**
   * Applies the value to one of two functions, and returns the resulting value.
   * One function is used when the value is a computed value, the other when it is an error.
   * If the function returns a value that is not a Result, it is wrapped in one, as a computed value.
   * Errors thrown by the function are not handled in any way.
   * If a function is not provided and needs to be used, the result itself is returned.
   * @summary Returns result of applying the value to a function, chosen depending on type of the value.
   * @param {?MapCallback} successFn A function called when the value is a computed value.
   * @param {?MapCallback} [errorFn] A function called when the value is an error.
   * @returns {Result} Result of the called function, wrapped in a Result if it was not a Result.
   */
  Result.prototype.then = function (successFn, errorFn) {
    if (this.isSuccess) {
      return successFn ? Result.from(successFn(this.value)) : this;
    } else {
      return errorFn ? Result.from(errorFn(this.error)) : this;
    }
  };

  /**
   * When the value is an error, applies it to a function, and returns the resulting value.
   * Otherwise returns the result itself.
   * Errors thrown by the function are not handled in any way.
   * If a function is not provided and needs to be used, the result itself is returned.
   * @summary Returns result of applying the value to a function, if it is an error, otherwise the result itself.
   * @param {?MapCallback} [errorFn] A function called when result is an error.
   * @returns {Result} Result of the called function, wrapped in a Result if it was not one.
   */
  Result.prototype.catch = function (errorFn) {
    return this.then(undefined, errorFn);
  };

  /**
   * Wraps a value in a Result, as a computed value.
   * @param {(*|Result)} value The value to be wrapped in a Result.
   * @returns {Result} A Result containing the value, as a computed value.
   */
  Result.success = function (value) {
    return new Result(value, true);
  };

  /**
   * Wraps a value in a Result, as an error.
   * @param {(*|Result)} value The value to be wrapped in a Result.
   * @returns {Result} A Result containing the value, as an error.
   */
  Result.error = function (value) {
    return new Result(value, false);
  };

  /**
   * Wraps a value in a Result, as a computed value. Result values are not wrapped.
   * @param {(*|Result)} value The value to be wrapped in a Result.
   * @returns {Result} A Result containing the value, as a computed value. The value itself if it is a Result.
   */
  Result.from = function (value) {
    if (value instanceof Result) {
      return value;
    } else {
      return Result.success(value);
    }
  };
  _module_exports = {
    Result
  };
  return _module_exports;
})();

/**
 * MODULE shared\validatedValue.js
 **/
const _module_shared_validatedValue = (function shared_validatedValue(
  _module_shared_result
) {
  let _module_exports = {};

  const {
    Result
  } = _module_shared_result;

  /**
   * A callback called when text value changes.
   * @callback ChangeCallback
   * @param {Result} value A Result object, which in successful state contains the parsed and valid value,
   * and in error state contains a validation/parse error.
   * @param {boolean} isReset Indicates whether the change is a result of a reset call (as opposed to set call).
   */

  /**
   * A callback used to validate and parse a test (string) value.
   * @callback ValidateCallback
   * @param {string} value A value to be parsed and validated.
   * @returns {Result} A Result object, which in successful state contains the parsed and valid value,
   * and in error state contains a validation/parse error.
   */

  /**
   * Represents options used when creating instance of ValidatedValue.
   * @typedef {Object} Options
   * @property {?string} [initialTextValue=""] Initial text value.
   * @property {?ChangeCallback} [change=null] A function that is called with the validated value, when it changes.
   * @property {?ValidateCallback} [validate=null] A function that returns a validated value, parsed from the given text value.
   * If not provided, a substitute callback is used which wraps the text value in a Result of successful type.
   * @property {?boolean} [isAvailable=false] Indicates whether the validated value is initially available.
   */

  /**
   * @classdesc Represents a validated value parsed from a string (a text value).
   * Initially, the validated value can be made inaccessible, i.e. is not available.
   * This availability status can be toggled through the use of instance methods.
   * @constructor
   * @summary Represents a validated value parsed from a string (a text value).
   * @param {Options} options The options
   */
  function ValidatedValue(options) {
    this._isAvailable = !!options.isAvailable;
    this._initialTextValue = options.initialTextValue || "";
    this._change = options.change || (() => {});
    this._validate = options.validate || (x => Result.from(x));
    /**
     * The text value.
     * Setting a value does not make the validated value available.
     * @member {string}
     */
    this.textValue = this._initialTextValue;
  }
  ValidatedValue.prototype = {
    /**
     * Returns the validated and parsed value if it is available.
     * @returns {?Result} A Result object, which in successful state contains the validated value,
     * and in error state contains a validation/parse error.
     * Null if the value is not yet available.
     */
    get availableValue() {
      return this._isAvailable ? this.value : null;
    },
    /**
     * Returns the validated and parsed value.
     * @returns {?Result} A Result object, which in successful state contains the validated value,
     * and in error state contains a validation/parse error.
     */
    get value() {
      return this._validate(this.textValue);
    },
    /**
     * Sets new text value and makes the validated value available.
     * @param {string} textValue The text value to set.
     */
    set(textValue) {
      this._isAvailable = true;
      this.textValue = textValue;
      this._change(this.value, false);
    },
    /**
     * Sets new text value and makes the validated value no longer available.
     * @param {?string} [textValue=undefined] The text value to set.
     * If undefined, the text value is set to the initial text value.
     */
    reset(textValue) {
      this._isAvailable = false;
      this.textValue = textValue === undefined ? this._initialTextValue : textValue;
      this._change(this.value, true);
    },
    /**
     * Makes the validated value available.
     * @returns {Result} A Result object, which in successful state contains the validated value,
     * and in error state contains a validation/parse error.
     */
    validate() {
      this._isAvailable = true;
      return this.value;
    }
  };
  _module_exports = {
    ValidatedValue
  };
  return _module_exports;
})(_module_shared_result);

/**
 * MODULE shared\validatedTime.js
 **/
const _module_shared_validatedTime = (function shared_validatedTime(
  _module_shared_result,
  _module_shared_time,
  _module_shared_validatedValue
) {
  let _module_exports = {};

  const time = _module_shared_time;
  const {
    Result
  } = _module_shared_result;
  const {
    ValidatedValue
  } = _module_shared_validatedValue;

  /** @typedef {import('./time').MinuteTime} MinuteTime */

  /**
   * A callback called when time changes.
   * @callback ChangeCallback
   * @param {Result} value A Result object, which in successful state contains the time,
   * and in error state contains a validation/parse error.
   * @param {boolean} isReset Indicates whether the change is a result of a reset call (as opposed to set call).
   */

  /**
   * A callback used to validate time.
   * @callback ValidateCallback
   * @param {MinuteTime} value The time to be validated.
   * @returns {Result} A Result object, which in successful state contains the time,
   * and in error state contains a validation error.
   */

  /**
   * Represents options used when creating instance of ValidatedTime.
   * @typedef {Object} Options
   * @property {?MinuteTime} [initialValue=""] Initial value of the time.
   * @property {?ChangeCallback} [change=null] A function that is called with the time, when it changes.
   * @property {?boolean} [isAvailable=false] Indicates whether the time is initially available.
   * @property {?ValidateCallback} [validate=null] A function that returns a valid time, given parsed time.
   */

  /**
   * @classdesc Represents a time value parsed from a string (text value).
   * @param {Options} options The options.
   * @constructor
   */
  function ValidatedTime(options) {
    var _options$initialValue, _options$change;
    this.input = new ValidatedValue({
      change: (value, isReset) => this._change(value, isReset),
      initialTextValue: (_options$initialValue = options.initialValue) === null || _options$initialValue === void 0 ? void 0 : _options$initialValue.format("input"),
      isAvailable: options.isAvailable,
      validate: value => validTime(value).then(validTime => {
        var _options$validate, _options$validate2;
        return (_options$validate = (_options$validate2 = options.validate) === null || _options$validate2 === void 0 ? void 0 : _options$validate2.call(options, validTime)) !== null && _options$validate !== void 0 ? _options$validate : validTime;
      })
    });
    this._change = (_options$change = options.change) !== null && _options$change !== void 0 ? _options$change : () => {};
  }
  ValidatedTime.prototype = {
    /**
     * Returns the time, if it is available.
     * @returns {?Result} A Result object, which in successful state contains the time,
     * and in error state contains a validation/parse error.
     * Null if the time is not yet available.
     */
    get availableValue() {
      return this.input.availableValue;
    },
    get textValue() {
      return this.input.textValue;
    },
    set textValue(value) {
      this.input.textValue = value;
    },
    /**
     * Returns the time.
     * @returns {?Result} A Result object, which in successful state contains the time,
     * and in error state contains a validation/parse error.
     */
    get value() {
      return this.input.value;
    },
    /**
     * Sets new time and makes the time available.
     * @param {?MinuteTime} value The time to set.
     * If undefined, the bounds period is left unchanged.
     */
    set(value) {
      this.input.set(value === null || value === void 0 ? void 0 : value.format("input"));
    },
    /**
     * Sets new time and makes the time no longer available.
     * @param {?Date} [value=undefined] The time to set.
     * If undefined, the time is set to the initial time.
     */
    reset(value) {
      var _value$format;
      this.input.reset(value !== undefined ? (_value$format = value === null || value === void 0 ? void 0 : value.format("input")) !== null && _value$format !== void 0 ? _value$format : null : undefined);
    },
    /**
     * Makes the time available.
     * @returns {Result} A Result object, which in successful state contains the time,
     * and in error state contains a validation/parse error.
     */
    validate() {
      return this.input.validate();
    }
  };
  function validTime(value) {
    const trimmed = (value || "").trim();
    if (trimmed.length == 0) {
      return Result.error("Vælg et tidspunkt");
    }
    const result = time.parsePoint(trimmed);
    if (!result) {
      return Result.error("Vælg et tidspunkt");
    }
    return Result.success(result);
  }
  _module_exports = {
    ValidatedTime,
    validTime
  };
  return _module_exports;
})(_module_shared_result,
  _module_shared_time,
  _module_shared_validatedValue);

/**
 * MODULE shared\prelude.js
 **/
const _module_shared_prelude = (function shared_prelude() {
  let _module_exports = {};

  /** @constructor */
  function Canceled() {}
  Canceled.prototype.toString = function () {
    return "Canceled";
  };
  const IgnoreCanceledMixin = {
    errorCaptured(error) {
      if (isCanceled(error)) {
        return false;
      }
      return true;
    }
  };
  function areArraysEqual(first, second, elementsEqual) {
    var _elementsEqual;
    elementsEqual = (_elementsEqual = elementsEqual) !== null && _elementsEqual !== void 0 ? _elementsEqual : strictEqual;
    if (first.length !== second.length) {
      return false;
    }
    for (let i = 0; i < first.length; ++i) {
      if (!elementsEqual(first[i], second[i])) {
        return false;
      }
    }
    return true;
  }
  function areSetsEqual(first, second, elementsEqual) {
    var _elementsEqual2, _first$length, _second$length;
    elementsEqual = (_elementsEqual2 = elementsEqual) !== null && _elementsEqual2 !== void 0 ? _elementsEqual2 : strictEqual;
    if (((_first$length = first.length) !== null && _first$length !== void 0 ? _first$length : first.size) !== ((_second$length = second.length) !== null && _second$length !== void 0 ? _second$length : second.size)) {
      return false;
    }
    const notVisited = [...second];
    for (const element of first) {
      const index = notVisited.findIndex(x => elementsEqual(x, element));
      if (index === -1) {
        return false;
      }
      notVisited.splice(index, 1);
    }
    return notVisited.length === 0;
  }
  function canceled() {
    return new Canceled();
  }
  const escapedHtmlCharacters = /[&"'<>]/gu;
  function escapeHtml(string) {
    var _string;
    string = (_string = string) === null || _string === void 0 ? void 0 : _string.toString();
    return string && string.replace(escapedHtmlCharacters, x => {
      switch (x) {
        case "&":
          return "&amp;";
        case '"':
          return "&quot;";
        case "'":
          return "&#39;";
        case "<":
          return "&lt;";
        case ">":
          return "&gt;";
      }
    });
  }
  const regExpCharacters = /[\\^$.*+?()[\]{}|]/gu;
  const regExpCharactersTest = new RegExp(regExpCharacters.source, "u");
  function escapeRegExp(string) {
    var _string2;
    string = (_string2 = string) === null || _string2 === void 0 ? void 0 : _string2.toString();
    return string && regExpCharactersTest.test(string) ? string.replace(regExpCharacters, "\\$&") : string;
  }
  function isCanceled(value) {
    return value instanceof Canceled;
  }
  function isPromise(value) {
    return value && isFunction(value.then);
  }
  function isFunction(value) {
    return value && typeof value == "function";
  }
  function isString(value) {
    return Object.prototype.toString.call(value) === "[object String]";
  }
  function strictEqual(a, b) {
    return a === b;
  }
  function toPromise(value, thisArg, ...args) {
    return isFunction(value) ? new Promise(resolve => resolve(value.apply(thisArg, args))) : Promise.resolve(value);
  }
  _module_exports = {
    areArraysEqual,
    areSetsEqual,
    canceled,
    escapeHtml,
    escapeRegExp,
    IgnoreCanceledMixin,
    isCanceled,
    isPromise,
    isFunction,
    isString,
    strictEqual,
    toPromise
  };
  return _module_exports;
})();

/**
 * MODULE shared\progressList.js
 **/
const _module_shared_progressList = (function shared_progressList(
  _module_shared_prelude
) {
  let _module_exports = {};

  const {
    isString,
    toPromise
  } = _module_shared_prelude;

  /**
   * An activity.
   * @typedef {Object} Activity
   * @property {*} id The id of the activity.
   * @property {string} message A message describing the activity, preferably using nonfinite verbs, e.g. "Loading...".
   */

  /**
   * @classdesc Represents a list of in-progress activities.
   * @constructor
   */
  function ProgressList() {
    /** @member {Activity[]} */
    this.items = [];
  }

  /**
   * Represents an (in-progress) activity.
   * @typedef {Object} Activity
   * @property {*} id The id of activity.
   * @property {string} message A message describing the activity, preferably using nonfinite verbs, e.g. "Loading...".
   */

  ProgressList.prototype = {
    /**
     * Indicates, whether there are any activities.
     * @returns {boolean} True, if there are no activities, false otherwise.
     */
    get empty() {
      return this.items.length === 0;
    },
    /**
     * Returns the most recently added activity.
     * @returns {?Activity} The activity that was added most recently, or null if there are none.
     */
    get top() {
      return this.items.length == 0 ? null : this.items[this.items.length - 1];
    },
    /**
     * Adds a new activity with the given id and message.
     * Replaces any previously added activity with the same id.
     * @param {*} id The id of the new activity.
     * @param {string} message The message of the new activity, preferably using nonfinite verbs, e.g. "Loading...".
     */
    add(id, message) {
      const index = this.items.findIndex(x => x.id === id);
      if (index !== -1) {
        this.items.splice(index, 1);
      }
      this.items.push({
        id,
        message
      });
    },
    /**
     * Removes activity with the given id.
     * If an activity with the id was never added, the function does nothing.
     * @param {*} id The id of the activity to be removed
     */
    remove(id) {
      const index = this.items.findIndex(x => x.id === id);
      if (index !== -1) {
        this.items.splice(index, 1);
      }
    },
    /**
     * Tracks a promise or a function by immediately adding a tracking activity,
     * and then removing it when the promise is settled or the function finishes executing.
     * @summary Tracks a promise or execution of a function with an activity.
     * @param {(string|Activity)} activityOrMessage The tracking activity, or a message of an activity that is going to be created with id "default".
     * @param {(function|Promise)} functionOrPromise The function or promise to track.
     * @returns {Promise} The tracked promise, or, if a function was given,
     * a promise resolved with the value returned by the function,
     * or rejected with the error thrown by it.
     */
    track(activityOrMessage, functionOrPromise) {
      const {
        id,
        message
      } = isString(activityOrMessage) ? {
        id: "default",
        message: activityOrMessage
      } : {
        id: activityOrMessage.id,
        message: activityOrMessage.message
      };
      this.add(id, message);
      const result = toPromise(functionOrPromise);
      result.then(() =>
        // Postpone removal until after all other continuations of the promise finish.
        // This is done in case other continuations add new tracking activities.
        // In such situation, we would like to keep the progress list non-empty.
        // This is to avoid any potential flickering in the UI,
        // if it has any content that is only shown when the progress indicator is empty.
        setTimeout(() => this.remove(id), 0), x => {
        setTimeout(() => this.remove(id), 0);
        return Promise.reject(x);
      });
      return result;
    }
  };
  _module_exports = {
    ProgressList
  };
  return _module_exports;
})(_module_shared_prelude);

/**
 * MODULE shared\progressIndicator.js
 **/
const _module_shared_progressIndicator = (function shared_progressIndicator(
  _module_shared_progressList
) {
  let _module_exports = {};

  const {
    ProgressList
  } = _module_shared_progressList;

  // @vue/component
  const ProgressIndicator = {
    name: "ProgressIndicator",
    props: {
      establishBfc: {
        type: Boolean,
        default: true
      },
      small: {
        type: Boolean,
        default: false
      },
      viewModel: {
        type: ProgressList,
        required: true
      }
    },
    computed: {
      message() {
        const top = this.viewModel.top;
        return top ? top.message : null;
      },
      visible() {
        return !this.viewModel.empty;
      }
    },
    render(h) {
      var _this$message;
      return h("div", {
        class: {
          "progress-indicator-root": this.establishBfc
        }
      }, [
        // Status
        this.visible ? h("div", {
            class: "progress-indicator-status"
          }, [
            // Spinner
            h("div", [h("b-spinner", {
              props: {
                small: this.small
              }
            }, [])]),
            // Message
            h("div", {
              class: "ml-1"
            }, [(_this$message = this.message) === null || _this$message === void 0 ? void 0 : _this$message.toString()])]) :
          // For some reason the else branch cannot be `""` or `[]`,
          // as then it causes an error in Vue update logic.
          // The error is caused by an `undefined` VNode appearing within `this.$slots.default`.
          // This can be reproduced by drag&dropping a person to a day/break with no assignments.
          h("div", []),
        // Contents
        h("div", {
          class: "progress-indicator-content",
          attrs: {
            "data-obscured": this.visible
          }
        }, this.$slots.default)]);
    }
  };
  _module_exports = {
    ProgressIndicator
  };
  return _module_exports;
})(_module_shared_progressList);

/**
 * MODULE playground_duty\countsTable.js
 **/
const _module_widget_countsTable = (function widget_countsTable(
  _module_shared_progressIndicator
) {
  let _module_exports = {};

  const {
    ProgressIndicator
  } = _module_shared_progressIndicator;

  // @vue/component
  const CountsTable = {
    name: "CountsTable",
    components: {
      "progress-indicator": ProgressIndicator
    },
    props: {
      placement: {
        type: String,
        required: true
      },
      progress: {
        type: Object,
        required: true
      },
      store: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        caption: this._caption([], null, null, null),
        emptyText: this._emptyText([], null),
        fields: [{
          key: "name",
          class: "no-text-overflow",
          label: "Navn",
          sortable: true
        }, {
          key: "initials",
          class: "no-text-overflow",
          label: "Initialer",
          sortable: true
        }, {
          key: "area",
          class: "no-text-overflow",
          label: "Område",
          sortable: true
        }, {
          key: "primaryAssignmentCount",
          label: "Forvagter",
          sortable: true
        }, {
          key: "primaryAssignmentDuration",
          label: "Planlagt tid forvagt",
          formatter(x) {
            return x.toString("human");
          },
          sortable: true
        }, {
          key: "backupAssignmentCount",
          label: "Bagvagter",
          sortable: true
        }, {
          key: "backupAssignmentDuration",
          label: "Planlagt tid bagvagt",
          formatter(x) {
            return x.toString("human");
          },
          sortable: true
        }],
        items: this._items(null, null)
      };
    },
    methods: {
      update(areaIds, counts, period, schoolCode, schoolYearId) {
        this.caption = this._caption(areaIds, counts, period, schoolCode, schoolYearId);
        this.emptyText = this._emptyText(areaIds, counts);
        this.items = this._items(counts, schoolYearId);
      },
      _caption(areaIds, counts, period, schoolCode, schoolYearId) {
        var _this$store$schools$f, _this$store$schools$f2, _period$start$toStrin, _period$end$addDays$t;
        if (!counts || !period || !schoolYearId || !schoolCode) {
          return "";
        }
        const schoolPart = this.store.schools.length > 1 ? " i skolen ".concat((_this$store$schools$f = (_this$store$schools$f2 = this.store.schools.find(x => (x === null || x === void 0 ? void 0 : x.code) === schoolCode)) === null || _this$store$schools$f2 === void 0 ? void 0 : _this$store$schools$f2.name) !== null && _this$store$schools$f !== void 0 ? _this$store$schools$f : "???", ",") : "";
        const areasDescription = areaIds.length === 0 ? "(ingen)" : areaIds.map(x => {
          var _this$store$areas$sch, _this$store$areas$sch2, _this$store$areas$sch3;
          return (_this$store$areas$sch = (_this$store$areas$sch2 = this.store.areas[schoolYearId]) === null || _this$store$areas$sch2 === void 0 ? void 0 : (_this$store$areas$sch3 = _this$store$areas$sch2.find(y => y.id === x)) === null || _this$store$areas$sch3 === void 0 ? void 0 : _this$store$areas$sch3.name) !== null && _this$store$areas$sch !== void 0 ? _this$store$areas$sch : "???";
        }).sort().join(", ");
        const periodDescription = "".concat((_period$start$toStrin = period === null || period === void 0 ? void 0 : period.start.toString()) !== null && _period$start$toStrin !== void 0 ? _period$start$toStrin : "???", " - ").concat((_period$end$addDays$t = period === null || period === void 0 ? void 0 : period.end.addDays(-1).toString()) !== null && _period$end$addDays$t !== void 0 ? _period$end$addDays$t : "???");
        return "Opt\xE6lling af vagter p\xE5".concat(schoolPart, " for omr\xE5derne ").concat(areasDescription, " i perioden ").concat(periodDescription);
      },
      _emptyText(areaIds, counts) {
        return counts === null ? "Tryk på vis for at indlæse optællingen" : areaIds.length === 0 ? "Intet område valgt" : "Der er ingen tilgængelige vagter";
      },
      _items(counts, schoolYearId) {
        return counts === null || counts === void 0 ? void 0 : counts.map(a => {
          var _this$store$personnel, _this$store$areas$sch4, _person$firstName, _person$lastName, _person$initials, _area$name;
          const person = (_this$store$personnel = this.store.personnel[schoolYearId]) === null || _this$store$personnel === void 0 ? void 0 : _this$store$personnel.find(x => x.id === a.personId);
          const area = (_this$store$areas$sch4 = this.store.areas[schoolYearId]) === null || _this$store$areas$sch4 === void 0 ? void 0 : _this$store$areas$sch4.find(x => x.id === a.areaId);
          return {
            id: "".concat(a.personId, "_").concat(a.areaId),
            name: "".concat((_person$firstName = person === null || person === void 0 ? void 0 : person.firstName) !== null && _person$firstName !== void 0 ? _person$firstName : "???", " ").concat((_person$lastName = person === null || person === void 0 ? void 0 : person.lastName) !== null && _person$lastName !== void 0 ? _person$lastName : "???"),
            initials: (_person$initials = person === null || person === void 0 ? void 0 : person.initials) !== null && _person$initials !== void 0 ? _person$initials : "???",
            area: (_area$name = area === null || area === void 0 ? void 0 : area.name) !== null && _area$name !== void 0 ? _area$name : "???",
            primaryAssignmentCount: a.primaryAssignmentCount,
            primaryAssignmentDuration: a.primaryAssignmentDuration,
            backupAssignmentCount: a.backupAssignmentCount,
            backupAssignmentDuration: a.backupAssignmentDuration
          };
        });
      }
    },
    render(h) {
      var _this$items;
      return h("b-table", {
        props: {
          borderless: true,
          busy: !this.progress.empty,
          caption: this.caption,
          emptyText: this.emptyText,
          fields: this.fields,
          primaryKey: "id",
          items: (_this$items = this.items) !== null && _this$items !== void 0 ? _this$items : [],
          showEmpty: true,
          sortBy: "name",
          sortDesc: false,
          stacked: this.placement === "narrow" ? true : "md"
        },
        scopedSlots: {
          tableBusy: h("progress-indicator", {
            props: {
              viewModel: this.progress
            }
          }, [])
        }
      }, []);
    }
  };
  _module_exports = {
    CountsTable
  };
  return _module_exports;
})(_module_shared_progressIndicator);

/**
 * MODULE shared\functions.js
 **/
const _module_shared_functions = (function shared_functions(
  _module_shared_prelude
) {
  let _module_exports = {};

  const {
    canceled,
    isString,
    toPromise
  } = _module_shared_prelude;
  _module_exports = {
    combine(inner, outer) {
      return x => {
        const resultOfInner = inner(x);
        const resultOfOuter = outer(resultOfInner);
        return resultOfOuter;
      };
    },
    debounce(callback, delay) {
      let latestReject;
      let timeoutId;
      return function () {
        const result = new Promise((resolve, reject) => {
          var _latestReject;
          (_latestReject = latestReject) === null || _latestReject === void 0 ? void 0 : _latestReject(canceled());
          latestReject = reject;
          clearTimeout(timeoutId);
          timeoutId = setTimeout(() => {
            toPromise(callback, this, ...arguments).then(x => {
              latestReject = undefined;
              timeoutId = undefined;
              resolve(x);
            }, x => {
              latestReject = undefined;
              timeoutId = undefined;
              reject(x);
            });
          }, delay);
        });
        return result;
      };
    },
    /**
     * Transforms a function so that only its latest invocation yields a usable value.
     * When the transformed function is called, it in turns calls the function to be transformed,
     * with the arguments passed to itself, then returns a promise.
     * That promise is resolved to the result of the call,
     * if the transformed function is not called again before that happens.
     * Otherwise, the promise is resolved to an object representing cancellation.
     * @param {Function} callback The function to transform.
     * @returns {Function} The transformed function.
     */
    last(callback) {
      const version = function () {
        let current = 0;
        return {
          is(expected) {
            return expected === current;
          },
          increment() {
            ++current;
            return current;
          }
        };
      }();
      return function () {
        return new Promise((resolve, reject) => {
          const thisVersion = version.increment();
          toPromise(callback, this, ...arguments).then(x => {
            if (version.is(thisVersion)) {
              resolve(x);
            } else {
              reject(canceled());
            }
          }, x => {
            if (version.is(thisVersion)) {
              reject(x);
            } else {
              reject(canceled());
            }
          });
        });
      };
    },
    /**
     * Transform a function so that it can only be called once.
     * First call to the transformed function invokes the function to be transformed,
     * and returns its result.
     * Subsequent calls to the transformed function do not invoke the function to be transformed,
     * and return undefined.
     * @param {Function} callback The function to transform.
     * @returns {Function} The transformed function.
     */
    once(callback) {
      let wasCalled = false;
      return function () {
        if (wasCalled) {
          return;
        } else {
          wasCalled = true;
        }
        return callback.apply(this, arguments);
      };
    },
    /**
     * Transforms a function so that focus is moved to one of target elements after the function returns.
     * In case the function returns a promise, the focus is moved when it is settled.
     * Resolving of target elements and actual moving of focus is performed in next "tick",
     * to allow for queued DOM changes to happen, as they might affect resolve of the target elements.
     * The focus is moved to each target element, in ascending order within the array,
     * until an element is successfully found and becomes the active element when focused.
     * @param {Function} callback The function to transform.
     * @param {Array<String|Object>|String|Object} targetElements The element(s) to move focus to after the function to transform returns.
     * An element can be a DOM element, a CSS selector, or the value "activeElement",
     * which resolves to the active element before the transformed function is called.
     * @returns {Function} The transformed function.
     */
    withFocusRestore(callback, targetElements) {
      return function () {
        const activeElement = document.activeElement;
        return toPromise(callback.apply(this, arguments)).then(x => {
          setTimeout(() => setFocus(targetElements, activeElement), 0);
          return x;
        }, x => {
          setTimeout(() => setFocus(targetElements, activeElement), 0);
          return Promise.reject(x);
        });
      };
      function setFocus(targetElements, activeElement) {
        targetElements = Array.isArray(targetElements) ? targetElements : [targetElements];
        for (const targetElement of targetElements) {
          const element = targetElement === "activeElement" ? activeElement : isString(targetElement) ? document.querySelector(targetElement) : targetElement;
          if (element) {
            element.focus();
            if (element === document.activeElement) {
              break;
            }
          }
        }
      }
    },
    retry(callback, count, delay) {
      return function () {
        let retriesCount = 0;
        const errors = [];
        const result = new Promise((resolve, reject) => {
          invoke.apply(this, arguments);
          function invoke() {
            toPromise(callback, this, ...arguments).then(resolve, x => {
              errors.push(x);
              if (retriesCount < count) {
                ++retriesCount;
                setTimeout(() => invoke.apply(this, arguments), delay || 0);
              } else {
                reject(errors);
              }
            });
          }
        });
        return result;
      };
    },
    throttle(callback, delay) {
      let isScheduledOrRunning = false;
      const context = {};
      return function () {
        context.arguments = arguments;
        context.this = this;
        if (isScheduledOrRunning) return Promise.resolve(null);
        isScheduledOrRunning = true;
        const result = new Promise((resolve, reject) => {
          setTimeout(() => {
            const {
              this: thisArg,
              arguments: args
            } = context;
            context.this = undefined;
            context.arguments = undefined;
            toPromise(callback, thisArg, ...args).then(x => {
              resolve(x);
              isScheduledOrRunning = false;
            }, x => {
              reject(x);
              isScheduledOrRunning = false;
            });
          }, delay);
        });
        return result;
      };
    }
  };
  return _module_exports;
})(_module_shared_prelude);

/**
 * MODULE playground_duty\incrementalSearch.js
 **/
const _module_widget_incrementalSearch = (function widget_incrementalSearch(
  _module_shared_functions,
  _module_shared_prelude
) {
  let _module_exports = {};

  const functions = _module_shared_functions;
  const {
    escapeRegExp
  } = _module_shared_prelude;

  /** @constructor */
  function IncrementalSearch() {
    this.value = "";
    this._parts = [];
  }
  IncrementalSearch.prototype.change = function (value) {
    this.value = value;
    this._updateParts();
  };
  IncrementalSearch.prototype.matches = function (text) {
    for (const part of this._parts) {
      const index = text.search(part.regExp);
      if (index === -1) {
        return false;
      } else {
        text = text.slice(index + part.length);
      }
    }
    return true;
  };
  IncrementalSearch.prototype._updateParts = functions.debounce(function () {
    this._parts = this.value.split(" ").filter(part => part.length > 0).map(part => ({
      length: part.length,
      regExp: new RegExp(escapeRegExp(part), "iu")
    }));
  }, 200);
  _module_exports = {
    IncrementalSearch
  };
  return _module_exports;
})(_module_shared_functions,
  _module_shared_prelude);

/**
 * MODULE playground_duty\breakTable.js
 **/
const _module_widget_breakTable = (function widget_breakTable(
  _module_shared_functions
) {
  let _module_exports = {};

  const functions = _module_shared_functions;

  /** @constructor */
  function BreakTable(deleteBreak, editBreak, store) {
    this._areaId = undefined;
    this._deleteBreak = deleteBreak;
    this._editBreak = editBreak;
    this._store = store;
  }
  BreakTable.prototype = {
    get fields() {
      return [{
        key: "name",
        class: "no-text-overflow",
        label: "Navn",
        sortable: true
      }, {
        key: "start",
        label: "Starttid",
        sortable: true
      }, {
        key: "end",
        label: "Sluttid",
        sortable: true
      }, {
        key: "actions",
        label: ""
      }];
    },
    get items() {
      var _this$_store$breaks$t;
      return ((_this$_store$breaks$t = this._store.breaks[this._areaId]) !== null && _this$_store$breaks$t !== void 0 ? _this$_store$breaks$t : []).map(break_ => breakTableRow(break_, this._deleteBreak, this._editBreak));
    },
    selectArea(areaId) {
      this._areaId = areaId;
    }
  };

  // Using a function returning instance instead of a constructor function,
  // as BTable shows blank cells when getters below are moved to a prototype.
  function breakTableRow(break_, deleteBreak, editBreak) {
    return {
      _break: break_,
      get id() {
        return this._break.id;
      },
      get name() {
        return this._break.name;
      },
      get start() {
        return this._break.start.toString();
      },
      get end() {
        return this._break.end.toString();
      },
      actions() {
        return [{
          icon: "fa-edit",
          label: "Rediger",
          call: functions.withFocusRestore(() => editBreak(this._break), "activeElement")
        }, {
          icon: "fa-trash",
          label: "Slet",
          call: functions.withFocusRestore(() => deleteBreak(this._break.id, this._break.name), ["activeElement", "#kmd-educa-personale-playground-duty-breaks-add-button"])
        }];
      }
    };
  }
  _module_exports = {
    BreakTable
  };
  return _module_exports;
})(_module_shared_functions);

/**
 * MODULE shared\validatedValueInput.js
 **/
const _module_shared_validatedValueInput = (function shared_validatedValueInput(
  _module_shared_functions
) {
  let _module_exports = {};

  const functions = _module_shared_functions;

  // @vue/component
  const ValidatedValueInput = {
    name: "ValidatedValueInput",
    inheritAttrs: false,
    props: {
      id: {
        type: String,
        required: true
      },
      label: {
        type: String,
        required: true
      },
      type: {
        type: String,
        default: "text"
      },
      viewModel: {
        type: Object,
        required: true
      },
      showSuccessStateOnInput: {
        type: Boolean,
        required: false,
        default: true
      }
    },
    computed: {
      invalidFeedback() {
        const availableValue = this.viewModel.availableValue;
        return availableValue && !availableValue.isSuccess ? availableValue.error : null;
      },
      state() {
        const availableValue = this.viewModel.availableValue;
        return availableValue ? availableValue.isSuccess : null;
      }
    },
    methods: {
      set: functions.debounce(function ($event) {
        this.viewModel.set($event);
      }, 200)
    },
    render(h) {
      return h("b-form-group", {
        props: {
          invalidFeedback: this.invalidFeedback,
          label: this.label,
          labelFor: this.id,
          state: this.state
        }
      }, [h("b-form-input", {
        attrs: this.$attrs,
        props: {
          id: this.id,
          state: !this.showSuccessStateOnInput && this.state === true ? null : this.state,
          type: this.type,
          value: this.viewModel.textValue
        },
        on: {
          input: this.set.bind(this)
        }
      }, [])]);
    }
  };
  _module_exports = {
    ValidatedValueInput
  };
  return _module_exports;
})(_module_shared_functions);

/**
 * MODULE shared\validatedTimeInput.js
 **/
const _module_shared_validatedTimeInput = (function shared_validatedTimeInput(
  _module_shared_validatedValueInput
) {
  let _module_exports = {};

  const {
    ValidatedValueInput
  } = _module_shared_validatedValueInput;

  // @vue/component
  const ValidatedTimeInput = {
    name: "ValidatedTimeInput",
    components: {
      "validated-value-input": ValidatedValueInput
    },
    inheritAttrs: false,
    props: {
      id: {
        type: String,
        required: true
      },
      label: {
        type: String,
        required: true
      },
      showSuccessStateOnInput: {
        type: Boolean,
        required: false,
        default: true
      },
      viewModel: {
        type: Object,
        required: true
      }
    },
    render(h) {
      return h("validated-value-input", {
        attrs: this.$attrs,
        props: {
          id: this.id,
          label: this.label,
          showSuccessStateOnInput: this.showSuccessStateOnInput,
          type: "time",
          viewModel: this.viewModel.input
        }
      }, []);
    }
  };
  _module_exports = {
    ValidatedTimeInput
  };
  return _module_exports;
})(_module_shared_validatedValueInput);

/**
 * MODULE shared\validatedDateInput.js
 **/
const _module_shared_validatedDateInput = (function shared_validatedDateInput(
  _module_shared_validatedValueInput
) {
  let _module_exports = {};

  const {
    ValidatedValueInput
  } = _module_shared_validatedValueInput;

  // @vue/component
  const ValidatedDateInput = {
    name: "ValidatedDateInput",
    components: {
      "validated-value-input": ValidatedValueInput
    },
    inheritAttrs: false,
    props: {
      id: {
        type: String,
        required: true
      },
      label: {
        type: String,
        required: true
      },
      showSuccessStateOnInput: {
        type: Boolean,
        required: false,
        default: true
      },
      viewModel: {
        type: Object,
        required: true
      }
    },
    render(h) {
      return h("validated-value-input", {
        attrs: this.$attrs,
        props: {
          id: this.id,
          label: this.label,
          showSuccessStateOnInput: this.showSuccessStateOnInput,
          type: "date",
          viewModel: this.viewModel.input
        }
      }, []);
    }
  };
  _module_exports = {
    ValidatedDateInput
  };
  return _module_exports;
})(_module_shared_validatedValueInput);

/**
 * MODULE shared\singleSelectionInput.js
 **/
const _module_shared_singleSelectionInput = (function shared_singleSelectionInput(
  _module_shared_functions,
  _module_shared_singleSelection
) {
  let _module_exports = {};

  const {
    SingleSelection
  } = _module_shared_singleSelection;
  const functions = _module_shared_functions;

  // @vue/component
  const SingleSelectionInput = {
    name: "SingleSelectionInput",
    inheritAttrs: false,
    props: {
      id: {
        type: String,
        required: true
      },
      label: {
        type: String,
        required: true
      },
      viewModel: {
        type: SingleSelection,
        required: true
      }
    },
    methods: {
      select: functions.debounce(function ($event) {
        this.viewModel.select($event);
      }, 200)
    },
    render(h) {
      return h("b-form-group", {
        props: {
          label: this.label,
          labelFor: this.id
        }
      }, [h("b-form-select", {
        attrs: this.$attrs,
        props: {
          id: this.id,
          options: this.viewModel.items,
          plain: true,
          value: this.viewModel.value
        },
        on: {
          change: this.select.bind(this)
        }
      }, [])]);
    }
  };
  _module_exports = {
    SingleSelectionInput
  };
  return _module_exports;
})(_module_shared_functions,
  _module_shared_singleSelection);

/**
 * MODULE shared\settings.js
 **/
const _module_shared_settings = (function shared_settings(
  _module_shared_functions,
  _module_shared_prelude,
  _module_shared_settingsStores
) {
  let _module_exports = {};

  const functions = _module_shared_functions;
  const {
    isFunction
  } = _module_shared_prelude;
  const stores = _module_shared_settingsStores;
  _module_exports = function (axios, aulaToken, sessionUUID, isNoticeBoard, institutionCode, widgetName) {
    const browserSessionStore = stores.browserSession(widgetName, "session", sessionUUID);
    const databaseStore = stores.database(aulaToken, axios, widgetName, isNoticeBoard, institutionCode);
    const databaseCacheStore = stores.browserSession(widgetName, "database", sessionUUID, 8 * 60 * 60 * 1000);
    return new Promise(resolve => {
      const browserSessionSettings = browserSessionStore.read() || {};
      const databaseSettings = databaseCacheStore.read();
      if (databaseSettings !== null) {
        resolve({
          browserSessionSettings,
          databaseSettings
        });
        return;
      }
      databaseStore.read().then(x => {
        return x || {};
      }, () => {
        return {};
      }).then(x => {
        databaseCacheStore.write(x);
        resolve({
          browserSessionSettings,
          databaseSettings: x
        });
      });
    }).then(function ({
                        browserSessionSettings,
                        databaseSettings
                      }) {
      const browserSessionStoreWrite = functions.throttle(() => browserSessionStore.write(browserSessionSettings), 1000);
      const databaseStoreWrite = functions.combine(x => functions.retry(x, 5, 5000), x => functions.throttle(x, 10000))(() => databaseStore.write(databaseSettings));
      const databaseCacheStoreWrite = functions.throttle(() => databaseCacheStore.write(databaseSettings), 1000);
      function get(key, fallbackValue, validateValue, settings) {
        const value = settings[key];
        if (value === undefined || (validateValue || (() => true))(value) === false) {
          return isFunction(fallbackValue) ? fallbackValue() : fallbackValue;
        }
        return value;
      }
      function set(args, settings, storeWrites) {
        if (args.length === 2) {
          const [key, value] = args;
          settings[key] = value;
        } else if (args.length === 1) {
          const [keysAndValues] = args;
          keysAndValues.forEach(({
                                   key,
                                   value
                                 }) => settings[key] = value);
        } else {
          return;
        }
        storeWrites.forEach(x => x());
      }
      return {
        getLocal(key, fallbackValue, validateValue) {
          return get(key, fallbackValue, validateValue, browserSessionSettings);
        },
        getPersistent(key, fallbackValue, validateValue) {
          return get(key, fallbackValue, validateValue, databaseSettings);
        },
        setLocal(...args) {
          set(args, browserSessionSettings, [browserSessionStoreWrite]);
        },
        setPersistent(...args) {
          set(args, databaseSettings, [databaseCacheStoreWrite, databaseStoreWrite]);
        }
      };
    });
  };
  return _module_exports;
})(_module_shared_functions,
  _module_shared_prelude,
  _module_shared_settingsStores);

/**
 * MODULE shared\confirmationModal.js
 **/
const _module_shared_confirmationModal = (function shared_confirmationModal(
  _module_shared_functions
) {
  let _module_exports = {};

  const {
    withFocusRestore
  } = _module_shared_functions;
  const eventBus = function () {
    return {
      _listeners: new Map(),
      emit(...args) {
        for (const listener of this._listeners.values()) {
          listener(...args);
        }
      },
      addListener(id, listener) {
        this._listeners.set(id, listener);
      },
      removeListener(id) {
        this._listeners.delete(id);
      }
    };
  }();

  /**
   * Represents options for confirmation modal.
   * @typedef {Object} ConfirmationModalOptions
   * @property {string} message The message shown as contents in the modal.
   * @property {string} title The title of the modal.
   * @property {string} [cancelTitle="Nej"] The label of the Cancel button in the modal.
   * @property {string} [okTitle="Ja"] The label of the Ok button in the modal.
   * @property {string} [okVariant="primary"] The variant of the Ok button, see Bootstrap documentation of button variants for possible values.
   */

  /**
   * A mixin that extends Vue instance with the ability to show confirmation modals.
   */
  const ConfirmationModalMixin = {
    data() {
      return {
        confirmationModal: {
          show: this.showConfirmationModal
        }
      };
    },
    methods: {
      /**
       * Shows a confirmation modal to the user.
       * @param {ConfirmationModalOptions} options The options for confirmation modal.
       * @returns {Promise<boolean>} A promise which is resolved when user closes the modal.
       * The resolved value indicates whether user closed the modal with the "Ok" button.
       */
      showConfirmationModal: withFocusRestore(function (options) {
        return new Promise(resolve => {
          eventBus.emit({
            options,
            resolve
          });
        });
      }, ["activeElement"])
    }
  };

  // @vue/component
  const ConfirmationModal = {
    name: "ConfirmationModal",
    props: {
      id: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        title: "",
        okTitle: "",
        okVariant: "",
        cancelTitle: "",
        message: "",
        isVisible: false
      };
    },
    created() {
      eventBus.addListener(this.id, ({
                                       options,
                                       resolve
                                     }) => {
        this.show(resolve, options);
      });
    },
    destroyed() {
      eventBus.removeListener(this.id);
    },
    methods: {
      show(resolve, options) {
        this.okTitle = options.okTitle || "Ja";
        this.cancelTitle = options.cancelTitle || "Nej";
        this.title = options.title;
        this.message = options.message;
        this.okVariant = options.okVariant || "primary";
        this.$refs.modal.$once("hide", e => {
          resolve(e.trigger === "ok");
        });
        this.$refs.modal.show();
      },
      shown() {
        // Move focus to first button within the modal
        setTimeout(() => {
          var _this$$el$querySelect;
          return (_this$$el$querySelect = this.$el.querySelector("footer button")) === null || _this$$el$querySelect === void 0 ? void 0 : _this$$el$querySelect.focus();
        }, 0);
      }
    },
    render(h) {
      var _this$message;
      return h("b-modal", {
        attrs: {
          id: this.id
        },
        props: {
          cancelTitle: this.cancelTitle,
          centered: true,
          okTitle: this.okTitle,
          okVariant: this.okVariant,
          title: this.title
        },
        on: {
          shown: this.shown.bind(this)
        },
        ref: "modal"
      }, [(_this$message = this.message) === null || _this$message === void 0 ? void 0 : _this$message.toString()]);
    }
  };
  _module_exports = {
    ConfirmationModal,
    ConfirmationModalMixin
  };
  return _module_exports;
})(_module_shared_functions);

/**
 * MODULE shared\errorList.js
 **/
const _module_shared_errorList = (function shared_errorList(
  _module_shared_prelude,
  _module_shared_result
) {
  let _module_exports = {};

  const {
    isCanceled,
    isPromise,
    toPromise
  } = _module_shared_prelude;
  const {
    Result
  } = _module_shared_result;

  /**
   * Determines whether an action is to be displayed.
   * @callback ActionAvailableCallback
   * @param {*} result The error from a tracked function or promise.
   * @returns {boolean} True, to display an action, false otherwise.
   */

  /**
   * Represents an action template.
   * @typedef {Object} ActionTemplate
   * @property {string} label The label of the action.
   * @property {ActionAvailableCallback} [available] An optional callback that indicates whether the action should be displayed.
   * @property {function} call Calls the action.
   * @property {boolean} isRetry Indicates whether the action retries the operation that resulted in the error.
   */

  /**
   * Represents an action.
   * @typedef {Object} Action
   * @property {string} label The label of the action.
   * @property {function} call Calls the action.
   * @property {boolean} isRetry Indicates whether the action retries the operation that resulted in the error.
   */

  /**
   * Represents an error
   * @typedef {Object} Error
   * @property {Action[]} actions Callable actions shown with the error.
   * @property {*} id The id of the error.
   * @property {boolean} dismissible
   * Indicates whether the error can be dismissed by user.
   * When dismissed, the error is removed from the error list to which it was added.
   * @property {string} message The error message, with details.
   * @property {function} dismiss The callback to dismiss the error.
   */

  /**
   * @classdesc Represents a list of errors.
   * @constructor
   */
  function ErrorList() {
    /** @member {Error[]} */
    this.items = [];
  }

  /**
   * Represents options for error tracking.
   * @typedef {Object} TrackOptions
   * @property {?*} [id="default"] The id of the error.
   * @property {string} string The message of the error.
   * @property {?boolean} [dismissible=true]
   * Indicates whether the error can be dismissed by the user.
   * When dismissed, the error is removed from the error list to which it was added.
   * @property {ActionTemplate[]} actions
   * Callable actions shown with the error.
   * Supports only one retry action.
   */

  ErrorList.prototype = {
    /**
     * Indicates whether there any errors present.
     * @returns {boolean} True, if there are no errors present, false otherwise.
     */
    get empty() {
      return this.items.length == 0;
    },
    /**
     * Adds an error.
     * The added error replaces any previously added error with the same id.
     * @param {Object} error The error to add.
     * @param {*} error.id The id of the error.
     * @param {string} error.message The error message.
     * @param {?string} [error.details] The error details.
     * If not null, nor undefined, nor '', it is appended to the message in parentheses.
     * @param {?boolean} [error.dismissible=true] Indicates whether the error can be dismissed by user.
     * @param {Action[]} [error.actions]
     * An array of callable actions to be displayed with the error.
     * Supports only one retry action.
     */
    add(error) {
      var _error$id, _error$actions;
      const id = (_error$id = error.id) !== null && _error$id !== void 0 ? _error$id : "default";
      const dismissible = !!error.dismissible;
      const message = formattedMessage(error.message, error.details);
      const retryCallback = error.retryCallback;
      const actions = (_error$actions = error.actions) !== null && _error$actions !== void 0 ? _error$actions : [];
      const index = this.items.findIndex(x => x.id === id);
      if (index === -1) {
        this.items.push({
          id,
          dismissible,
          message,
          dismiss: () => {
            this.remove(id);
          },
          actions
        });
      } else {
        const existingItem = this.items[index];
        existingItem.message = message;
        existingItem.dismissible = dismissible;
        existingItem.retry = retryCallback;
        existingItem.actions = actions;
      }
      function formattedMessage(message, details) {
        return details === null || details === undefined || details === "" ? message : message + " (" + details + ")";
      }
    },
    /**
     * Removes all errors.
     */
    clear() {
      this.items.splice(0);
    },
    /**
     * Checks whether an error with specific id exists.
     * @param {*} id The id of the error to check.
     * @returns {Boolean} A value indicating whether the error exists.
     */
    has(id) {
      const index = this.items.findIndex(x => x.id === id);
      return index !== -1;
    },
    /**
     * Removes error with the given id.
     * If an error with the id was never added, the function does nothing.
     * @param {*} id The id of the error to remove.
     */
    remove(id) {
      const index = this.items.findIndex(x => x.id === id);
      if (index !== -1) {
        this.items.splice(index, 1);
      }
    },
    /**
     * Calls retry on all errors that support it.
     */
    retryAll() {
      this.items.slice().forEach(x => {
        var _x$actions$find;
        return (_x$actions$find = x.actions.find(x => x.isRetry)) === null || _x$actions$find === void 0 ? void 0 : _x$actions$find.call();
      });
    },
    /**
     * Tracks a promise or a function by adding an error when they fail.
     * For a promise, an error is added if it is in, or resolves to, a rejected state.
     * For a function, an error is added if throws, or returns a promise that is, or resolves to, a rejected state.
     * The reason of a resulting rejected promise is used as details for the error.
     * @summary Tracks errors in a promise or during execution of a function.
     * @param {(string|TrackOptions)} messageOrOptions The options or a message for the error.
     * When a string is used, the resulting error has id "default", cannot be retried and is dismissible.
     * @param {(function|Promise)} functionOrPromise The function or promise to track.
     * @returns {Promise} The tracked promise, or, if a function was given,
     * a promise resolved with the value returned by the function,
     * or rejected with the error thrown by it.
     */
    track(messageOrOptions, functionOrPromise) {
      var _messageOrOptions$act, _messageOrOptions$act2, _messageOrOptions$act3;
      const id = messageOrOptions.id || "default";
      const message = messageOrOptions.message || messageOrOptions;
      const dismissible = messageOrOptions.dismissible === undefined ? true : !!messageOrOptions.dismissible;
      const retryActionTemplate = (_messageOrOptions$act = messageOrOptions.actions) === null || _messageOrOptions$act === void 0 ? void 0 : _messageOrOptions$act.find(x => x.isRetry);
      const retryCallback = (() => {
        if (!retryActionTemplate) {
          return Result.from(null);
        } else if (!retryActionTemplate.call) {
          if (isPromise(functionOrPromise)) {
            return Result.error("Cannot retry a promise. Pass a retry callback to retry action or use a function instead of a promise.");
          } else {
            return Result.from(() => this.track(messageOrOptions, functionOrPromise));
          }
        } else {
          return Result.from(retryActionTemplate.call);
        }
      })();
      if (!retryCallback.isSuccess) {
        return Promise.reject(new Error(retryCallback.error));
      }
      this.remove(id);
      // Retry action goes at the end of action list, so it aligns with "Retry all"
      const actionTemplates = [...((_messageOrOptions$act2 = (_messageOrOptions$act3 = messageOrOptions.actions) === null || _messageOrOptions$act3 === void 0 ? void 0 : _messageOrOptions$act3.filter(x => !x.isRetry)) !== null && _messageOrOptions$act2 !== void 0 ? _messageOrOptions$act2 : []), ...(retryCallback.value ? [{
        label: retryActionTemplate.label,
        call: retryCallback.value,
        available: retryActionTemplate.available,
        isRetry: true
      }] : [])];
      const promise = toPromise(functionOrPromise);
      return promise.catch(reason => {
        var _reason$message;
        if (isCanceled(reason)) {
          return;
        }
        const actions = actionTemplates.filter(x => {
          var _x$available, _x$available2;
          return (_x$available = (_x$available2 = x.available) === null || _x$available2 === void 0 ? void 0 : _x$available2.call(x, reason)) !== null && _x$available !== void 0 ? _x$available : true;
        });
        const details = (_reason$message = reason === null || reason === void 0 ? void 0 : reason.message) !== null && _reason$message !== void 0 ? _reason$message : reason;
        this.add({
          id,
          message,
          details,
          dismissible,
          actions
        });
        return Promise.reject(reason);
      });
    }
  };

  /**
   * Creates an action template.
   * @param {string} label The label of the action.
   * @param {function?} callback The callback that performs the action.
   * @param {ActionAvailableCallback?} [available]
   * A callback, when given, that determines if the action is shown.
   * When not given, the action is always shown.
   * @returns {ActionTemplate} The action template.
   */
  function actionTemplate(label, callback, available) {
    return {
      label: label,
      available: available,
      call: callback
    };
  }

  /**
   * Crates an action template for retry action.
   * @param {function?} [callback]
   * The callback that performs the retry.
   * Can be omitted when tracking a function execution; in that case the function is called again.
   * It is required when tracking a promise.
   * @param {ActionAvailableCallback?} [available]
   * A callback, that when given, determines if the action is shown.
   * When omitted, the action is always shown.
   * @returns {ActionTemplate} The template for retry action.
   */
  function retryActionTemplate(callback, available) {
    return {
      label: "Prøv igen",
      available: available,
      call: callback,
      isRetry: true
    };
  }
  _module_exports = {
    ErrorList,
    actionTemplate,
    retryActionTemplate
  };
  return _module_exports;
})(_module_shared_prelude,
  _module_shared_result);

/**
 * MODULE playground_duty\plansDragDrop.js
 **/
const _module_widget_plansDragDrop = (function widget_plansDragDrop(
  _module_shared_errorList,
  _module_widget_assignments,
  _module_widget_editableAssignments
) {
  let _module_exports = {};

  const {
    EditableAssignments
  } = _module_widget_editableAssignments;
  const {
    canPersonHaveAssignment
  } = _module_widget_assignments;
  const {
    actionTemplate,
    retryActionTemplate
  } = _module_shared_errorList;

  /** @typedef {import('./personnelWithTypesBar').PersonWithType} PersonWithType */

  /** @constructor */
  function PlansDragDrop(actions, isEnabled, isMobileApp, state, store) {
    this.isEnabled = isEnabled;
    this._actions = actions;
    this._draggedPersonWithType = null;
    this._isMobileApp = isMobileApp;
    this._schoolCode = null;
    this._schoolYearId = null;
    this._state = state;
    this._store = store;
    this._week = null;
  }
  PlansDragDrop.prototype = {
    /** @returns {PersonWithType[]} */
    get availablePersonnelWithTypes() {
      var _this$_store$personne;
      const school = this._store.schools.find(x => x.code === this._schoolCode);
      const personnel = (_this$_store$personne = this._store.personnel[this._schoolYearId]) !== null && _this$_store$personne !== void 0 ? _this$_store$personne : [];
      const personnelTypes = this._store.personnelTypes;
      return personnel.map(person => person.employments.map(employment => {
        const personnelType = personnelTypes[employment.personnelType];
        return {
          id: person.id,
          personnelType: employment.personnelType,
          personInitials: person.initials,
          personFirstName: person.firstName,
          personHasMultipleEmployments: person.employments.length > 1,
          personLastName: person.lastName,
          personIsCurrentUser: person.id === (school === null || school === void 0 ? void 0 : school.personId),
          personnelTypeAbbreviation: personnelType === null || personnelType === void 0 ? void 0 : personnelType.abbreviation,
          personnelTypeDescription: personnelType === null || personnelType === void 0 ? void 0 : personnelType.description
        };
      })).reduce((a, b) => a.concat(b), []).sort((a, b) => {
        var _a$personInitials, _a$personFirstName, _a$personLastName;
        return ((_a$personInitials = a.personInitials) === null || _a$personInitials === void 0 ? void 0 : _a$personInitials.localeCompare(b.personInitials)) || ((_a$personFirstName = a.personFirstName) === null || _a$personFirstName === void 0 ? void 0 : _a$personFirstName.localeCompare(b.personFirstName)) || ((_a$personLastName = a.personLastName) === null || _a$personLastName === void 0 ? void 0 : _a$personLastName.localeCompare(b.personLastName));
      });
    },
    get departments() {
      var _this$_store$departme;
      return (_this$_store$departme = this._store.departments[this._schoolYearId]) !== null && _this$_store$departme !== void 0 ? _this$_store$departme : [];
    },
    get teams() {
      var _this$_store$teams$th;
      return (_this$_store$teams$th = this._store.teams[this._schoolYearId]) !== null && _this$_store$teams$th !== void 0 ? _this$_store$teams$th : [];
    },
    dragEnd() {
      this._draggedPersonWithType = null;
    },
    dragStart({
                event,
                personWithType
              }) {
      this._draggedPersonWithType = {
        id: personWithType.id,
        personnelType: personWithType.personnelType
      };
      event.dataTransfer.dropEffect = "link";
    },
    ofArea(areaId, areaIsEditable) {
      return new PlanDragDrop(this._addAssignment.bind(this, areaId), areaIsEditable, () => this._draggedPersonWithType, () => this.isEnabled, this._isMobileApp);
    },
    selectSchoolYear(schoolYearId, schoolCode) {
      this._schoolYearId = schoolYearId;
      this._schoolCode = schoolCode;
    },
    selectWeek(week) {
      this._week = week;
    },
    _addAssignment(areaId, breakId, dayIndex, assignment) {
      var _this$_store$personne2, _this$_store$breaks$a, _person$initials, _person$firstName, _person$lastName, _break_$name;
      const person = (_this$_store$personne2 = this._store.personnel[this._schoolYearId]) === null || _this$_store$personne2 === void 0 ? void 0 : _this$_store$personne2.find(person => person.id === assignment.personId);
      const break_ = (_this$_store$breaks$a = this._store.breaks[areaId]) === null || _this$_store$breaks$a === void 0 ? void 0 : _this$_store$breaks$a.find(break_ => break_.id === breakId);
      const date = this._week.toDate(dayIndex);
      const errors = this._state.areaErrorList(areaId);
      const progress = this._state.dayBreakProgressList(areaId, breakId, this._week, dayIndex);
      const errorId = "assign-person-".concat(areaId, "-").concat(breakId, "-").concat(dayIndex);
      return errors.track({
        id: errorId,
        message: "Kunne ikke s\xE6tte ".concat((_person$initials = person === null || person === void 0 ? void 0 : person.initials) !== null && _person$initials !== void 0 ? _person$initials : "???", " ").concat((_person$firstName = person === null || person === void 0 ? void 0 : person.firstName) !== null && _person$firstName !== void 0 ? _person$firstName : "???", " ").concat((_person$lastName = person === null || person === void 0 ? void 0 : person.lastName) !== null && _person$lastName !== void 0 ? _person$lastName : "???", " pausen ").concat((_break_$name = break_ === null || break_ === void 0 ? void 0 : break_.name) !== null && _break_$name !== void 0 ? _break_$name : "???", " den ").concat(date),
        actions: [actionTemplate("Genindlæs", () => {
          errors.track({
            id: errorId,
            message: "Kunne ikke indlæse vagter",
            actions: [retryActionTemplate()]
          }, () => progress.track("Indlæser vagter...", () => this._actions.reloadAssignments(areaId, this._week)));
        }, error => error.status == 409), retryActionTemplate(null, error => error.status != 409)]
      }, () => progress.track("Påsætter person", () => {
        var _this$_store$assignme, _this$_store$assignme2, _this$_store$assignme3;
        const assignments = (_this$_store$assignme = (_this$_store$assignme2 = this._store.assignments[areaId]) === null || _this$_store$assignme2 === void 0 ? void 0 : (_this$_store$assignme3 = _this$_store$assignme2[this._week]) === null || _this$_store$assignme3 === void 0 ? void 0 : _this$_store$assignme3.find(x => x.breakId === breakId && x.dayIndex === dayIndex)) !== null && _this$_store$assignme !== void 0 ? _this$_store$assignme : {
          primary: [],
          backup: [],
          timestamp: null
        };
        const editableAssignments = new EditableAssignments();
        editableAssignments.update(assignments.primary, assignments.backup);
        if (assignment.priority !== null) {
          editableAssignments.assignPersonAsBackup({
            id: assignment.personId,
            personnelType: assignment.personnelType
          }, assignment.priority);
        } else {
          editableAssignments.assignPersonAsPrimary({
            id: assignment.personId,
            personnelType: assignment.personnelType
          });
        }
        return this._actions.saveAssignments({
          breakId,
          date: this._week.toDate(dayIndex),
          primary: editableAssignments.primary,
          backup: editableAssignments.backup,
          timestamp: assignments.timestamp
        });
      }));
    }
  };

  /** @constructor */
  function PlanDragDrop(addAssignment, areaIsEditable, draggedPersonWithType, isEnabled, isMobileApp) {
    this._addAssignment = addAssignment;
    this._areaIsEditable = areaIsEditable;
    this._draggedPersonWithType = draggedPersonWithType;
    this._isEnabled = isEnabled;
    this._isMobileApp = isMobileApp;
  }
  PlanDragDrop.prototype = {
    get isAvailable() {
      return this._isEnabled() && this.isToggleAvailable;
    },
    get isPersonWithTypeDragged() {
      return this.isAvailable && !!this._draggedPersonWithType();
    },
    get isToggleAvailable() {
      return !this._isMobileApp() && this._areaIsEditable;
    },
    ofBreakAndDay(assignments, breakId, breakPeriod, dayIndex, personnelPlannedAbsences, substitutedPersonnel) {
      return new DayAndBreakDragDrop(assignments, breakPeriod, this._draggedPersonWithType, personnelPlannedAbsences, substitutedPersonnel, () => this.isAvailable, this._addAssignment.bind(null, breakId, dayIndex));
    }
  };

  /** @constructor */
  function DayAndBreakDragDrop(assignments, breakPeriod, draggedPersonWithType, personnelPlannedAbsences, substitutedPersonnel, isAvailable, addAssignment) {
    this.__addAssignment = addAssignment;
    this._assignments = assignments;
    this._breakPeriod = breakPeriod;
    this._draggedPersonWithType = draggedPersonWithType;
    this._personnelPlannedAbsences = personnelPlannedAbsences;
    this._substitutedPersonnel = substitutedPersonnel;
    this._isAvailable = isAvailable;
    this._isSaving = false;
  }
  DayAndBreakDragDrop.prototype = {
    noAssignmentsPlaceholder(isPrimary) {
      const self = this;
      return {
        get available() {
          return self._isAvailable();
        },
        get visible() {
          return !self._isSaving && !!self._draggedPersonWithType();
        },
        addAssignment() {
          return self._addAssignment(isPrimary ? null : self._assignments.backup.length);
        }
      };
    },
    ofGroup(isPrimary) {
      return new AssignmentsDragDrop(isPrimary ? this._assignments.primary : this._assignments.backup, isPrimary, this._draggedPersonWithType, this._substitutedPersonnel, this._isAvailable, () => this._isSaving, this._addAssignment.bind(this));
    },
    personPlannedAbsentOverlay() {
      const self = this;
      return {
        get available() {
          return self._isAvailable();
        },
        get plannedAbsences() {
          var _self$_personnelPlann, _plannedAbsencesOfPer, _plannedAbsencesOfPer2;
          if (!self._isAvailable()) {
            return null;
          }
          const personWithType = self._draggedPersonWithType();
          const breakPeriod = self._breakPeriod;
          if (!personWithType || !breakPeriod) {
            return null;
          }
          const plannedAbsencesOfPerson = (_self$_personnelPlann = self._personnelPlannedAbsences[personWithType.id]) !== null && _self$_personnelPlann !== void 0 ? _self$_personnelPlann : [];
          const plannedAbsences = ((_plannedAbsencesOfPer = plannedAbsencesOfPerson[null]) !== null && _plannedAbsencesOfPer !== void 0 ? _plannedAbsencesOfPer : []).concat((_plannedAbsencesOfPer2 = plannedAbsencesOfPerson[personWithType.personnelType]) !== null && _plannedAbsencesOfPer2 !== void 0 ? _plannedAbsencesOfPer2 : []).filter(x => x.period.intersectsWith(breakPeriod)).map(x => x.description);
          return plannedAbsences.length > 0 ? plannedAbsences.join(", ") : null;
        },
        get visible() {
          return !self._isSaving && !!self._draggedPersonWithType() && this.plannedAbsences !== null;
        }
      };
    },
    _addAssignment(priority) {
      const draggedPersonWithType = this._draggedPersonWithType();
      if (draggedPersonWithType) {
        this._isSaving = true;
        return this.__addAssignment({
          personId: draggedPersonWithType.id,
          personnelType: draggedPersonWithType.personnelType,
          priority
        }).then(() => {
          this._isSaving = false;
        }, () => {
          this._isSaving = false;
        });
      } else {
        return Promise.resolve();
      }
    }
  };

  /** @constructor */
  function AssignmentsDragDrop(assignments, areAssignmentsPrimary, draggedPersonWithType, substitutedPersonnel, isAvailable, isSaving, addAssignment) {
    this._addAssignment = addAssignment;
    this._assignments = assignments;
    this._areAssignmentsPrimary = areAssignmentsPrimary;
    this._draggedPersonWithType = draggedPersonWithType;
    this._substitutedPersonnel = substitutedPersonnel;
    this._isAvailable = isAvailable;
    this._isSaving = isSaving;
  }
  AssignmentsDragDrop.prototype = {
    get isAvailable() {
      var _this$_draggedPersonW;
      return this._isAvailable() && canPersonHaveAssignment(!this._areAssignmentsPrimary, this._substitutedPersonnel.includes((_this$_draggedPersonW = this._draggedPersonWithType()) === null || _this$_draggedPersonW === void 0 ? void 0 : _this$_draggedPersonW.id));
    },
    groupPlaceholder() {
      const self = this;
      return {
        get available() {
          return self.isAvailable && (self._areAssignmentsPrimary || self._assignments.length === 0);
        },
        get visible() {
          if (self._isSaving()) {
            return false;
          }
          const draggedPersonWithType = self._draggedPersonWithType();
          return !!draggedPersonWithType && !self._assignments.some(assignment => isPersonWithTypeAssigned(assignment, draggedPersonWithType));
        },
        addAssignment() {
          return self._addAssignment(self._areAssignmentsPrimary ? null : self._assignments.length);
        }
      };
    },
    inlinePlaceholders() {
      if (!this.isAvailable || this._areAssignmentsPrimary || this._assignments.length === 0) {
        return [];
      }
      return [this._inlinePlaceholder(this._assignments, -1), ...this._assignments.map((_, index) => this._inlinePlaceholder(this._assignments, index))];
    },
    _inlinePlaceholder(assignments, index) {
      const self = this;
      return {
        get available() {
          return true;
        },
        get visible() {
          if (self._isSaving()) {
            return false;
          }
          const draggedPersonWithType = self._draggedPersonWithType();
          const result = draggedPersonWithType && !isPersonWithTypeAssigned(assignments[index], draggedPersonWithType) && !isPersonWithTypeAssigned(assignments[index + 1], draggedPersonWithType);
          return result !== null && result !== void 0 ? result : false;
        },
        key: "placeholder-".concat(index + 1),
        order: index,
        addAssignment() {
          return self._addAssignment(self._areAssignmentsPrimary ? null : index + 1);
        }
      };
    }
  };
  function isPersonWithTypeAssigned(assignment, personWithType) {
    return assignment && assignment.personId === personWithType.id && assignment.personnelType === personWithType.personnelType;
  }
  _module_exports = {
    PlansDragDrop
  };
  return _module_exports;
})(_module_shared_errorList,
  _module_widget_assignments,
  _module_widget_editableAssignments);

/**
 * MODULE shared\errorIndicator.js
 **/
const _module_shared_errorIndicator = (function shared_errorIndicator(
  _module_shared_errorList
) {
  let _module_exports = {};

  const {
    ErrorList
  } = _module_shared_errorList;

  // @vue/component
  const ErrorIndicator = {
    name: "ErrorIndicator",
    props: {
      viewModel: {
        type: ErrorList,
        required: true
      }
    },
    computed: {
      showRetryAll() {
        return this.viewModel.items.reduce((total, item) => total + (item.actions.some(x => x.isRetry) ? 1 : 0), 0) > 1;
      }
    },
    render(h) {
      return h("div", [...this.viewModel.items.map(item => {
        var _item$message;
        return h("b-alert", {
          class: "d-flex flex-wrap",
          props: {
            dismissible: item.dismissible,
            dismissLabel: "Luk",
            show: true,
            variant: "danger"
          },
          on: {
            dismissed() {
              item.dismiss();
            }
          },
          key: item.id
        }, [// Message
          (_item$message = item.message) === null || _item$message === void 0 ? void 0 : _item$message.toString(),
          // Actions
          ...item.actions.map((x, i) => h("b-link", {
            class: i == 0 ? "ml-auto" : "ml-3",
            on: {
              click() {
                x.call();
              }
            }
          }, x.label))]);
      }), this.showRetryAll ? h("div", {
        // Margin chosen so that the right edges of "Retry all" link and other "Retry" links align
        class: "d-flex flex-row-reverse mx-3"
      }, [h("b-link", {
        on: {
          click: () => {
            this.viewModel.retryAll();
          }
        }
      }, "Prøv alle igen")]) : ""]);
    }
  };
  _module_exports = {
    ErrorIndicator
  };
  return _module_exports;
})(_module_shared_errorList);

/**
 * MODULE shared\multipleSelection.js
 **/
const _module_shared_multipleSelection = (function shared_multipleSelection() {
  let _module_exports = {};

  /**
   * Represents an item available for selection.
   * @typedef {Object} Item
   * @property {string} [text] Description of the item, shown to the user. Required when html is not set.
   * @property {string} [html] Html used to show the item to the user. Required when text is not set.
   * Use with caution as contents are not HTML escaped.
   * @property {Boolean} [disabled=false] Determines whether the item can be selected by the user.
   * @property {*} value Value of the item, used to identify the item and passed to selection change callback. Cannot be undefined.
   */

  /**
   * A function called when selected items change or items are updated.
   * @callback SelectionChangedCallback
   * @param {Array} values The selected values.
   * @param {Boolean} isUpdate Indicates whether the callback was called in response to update of items.
   */

  /**
   * Represents options that specify behavior of multiple selection.
   * @typedef {Object} MultipleSelectionOptions
   * @property {?SelectionChangedCallback} [change] A function that is called when selected value changes.
   * @property {?Array} [initial] Determines the initial "desired" selected values.
   * @property {Boolean} [preselectAll=false] Determines whether values of all non-disabled items should be selected,
   * when the set of "desired" selected values is undefined, or all its values have no corresponding item.
   */

  /**
   * @classdesc Represents a selection of multiple values from among available values, represented by items.
   * There are two kinds of selected values tracked at the same time: "actual" ones and "desired" ones.
   * This is done to ensure that the selected values are independent of available items, and preserved when they change.
   * The set of "desired" selected values is what is controlled by the user and code.
   * The set of "actual" selected values is computed, and updates whenever available items or the "desired" selected values change.
   * A value from "desired" selected value is present in the set of "actual" selected values when:
   *  - there is an available item with the value of the "desired" selected value, and
   *  - that item is selected.
   * Until the set of "desired" selected changes,
   * and when all "desired" selected values do not have a corresponding available item,
   * the set of "actual" selected values consists of values of all items,
   * if the "preselect all" options is set, or is empty otherwise.
   * Whenever the "actual" selected values change, or items are updated,
   * the change callback is called with the values as an argument.
   * @summary Represents a selection of multiple values from a list of predefined values.
   * @constructor
   * @param {MultipleSelectionOptions} options The options.
   */
  function MultipleSelection(options) {
    var _options$preselectAll, _options$change;
    /**
     * The items available for selection.
     * @member {Array.<Item>}
     */
    this.items = [];
    this._actualValues = [];
    this._desiredValues = options.initial;
    this._options = {
      preselectAll: (_options$preselectAll = options.preselectAll) !== null && _options$preselectAll !== void 0 ? _options$preselectAll : false,
      change: (_options$change = options.change) !== null && _options$change !== void 0 ? _options$change : () => {}
    };
  }
  MultipleSelection.prototype = {
    /**
     * The "actual" selected value.
     * @member {Array}
     */
    get values() {
      return this._actualValues;
    },
    /**
     * Changes the "desired" selected values.
     * If the change results in the change of "actual" selected values, the change callback is queued for execution.
     * @param {Array} newValues The new values.
     */
    select(newValues) {
      this._desiredValues = [...newValues];
      this._synchronize({
        forceChange: false
      });
    },
    /**
     * Changes available items.
     * Each item should have a value different from that of other items.
     * The change callback is always queued for execution.
     * @param {Array.<Item>} items The new available items.
     */
    update(items) {
      this.items = items;
      this._synchronize({
        forceChange: true
      });
    },
    _synchronize({
                   forceChange
                 }) {
      var _this$_desiredValues;
      const desiredValues = (_this$_desiredValues = this._desiredValues) === null || _this$_desiredValues === void 0 ? void 0 : _this$_desiredValues.filter(x => this.items.some(y => x === y.value && !y.disabled));
      const newActualValues = (desiredValues === null || desiredValues === void 0 ? void 0 : desiredValues.length) > 0 ? desiredValues : this._options.preselectAll ? this.items.map(x => x.value) : [];
      const added = newActualValues.filter(x => !this._actualValues.includes(x));
      const removed = this._actualValues.filter(x => !newActualValues.includes(x));
      this._actualValues.splice(0, this._actualValues.length, ...newActualValues);
      if (forceChange || added.length > 0 || removed.length > 0) {
        this._options.change(newActualValues, forceChange);
      }
    }
  };
  _module_exports = {
    MultipleSelection
  };
  return _module_exports;
})();

/**
 * MODULE playground_duty\personnelWithTypesBar.js
 **/
const _module_widget_personnelWithTypesBar = (function widget_personnelWithTypesBar(
  _module_shared_multipleSelection,
  _module_shared_prelude,
  _module_widget_incrementalSearch,
  _module_widget_personWithTypePill
) {
  let _module_exports = {};

  const {
    escapeHtml
  } = _module_shared_prelude;
  const {
    MultipleSelection
  } = _module_shared_multipleSelection;
  const {
    IncrementalSearch
  } = _module_widget_incrementalSearch;
  const {
    PersonWithTypePill
  } = _module_widget_personWithTypePill;

  /**
   * @typedef {import('./settings').AreaSettings} AreaSettings
   * @typedef {import('./store').Department} Department
   * @typedef {import('./store').Team} Team
   */

  /**
   * A person with a personnel type.
   * @typedef {import('./personWithTypePill').PersonWithType} PersonWithType
   * @property {PersonId} id Id of the person.
   * @property {PersonnelType} personnelType The personnel type.
   */

    // @vue/component
  const PersonnelWithTypesBar = {
      name: "PersonnelWithTypesBar",
      components: {
        "person-with-type-pill": PersonWithTypePill
      },
      props: {
        /** @type {AreaSettings} */
        areaSettings: {
          type: Object,
          required: true
        },
        /** @type {Department[]} */
        departments: {
          type: Array,
          required: true
        },
        /** @type {PersonWithType[]} */
        personnelWithTypes: {
          type: Array,
          required: true
        },
        /** @type {Team[]} */
        teams: {
          type: Array,
          required: true
        }
      },
      data() {
        return {
          departmentSelection: new MultipleSelection({
            initial: this.areaSettings.planAssignmentSelectedDepartments,
            change: this.onDepartmentSelectionChange.bind(this)
          }),
          search: new IncrementalSearch(),
          teamSelection: new MultipleSelection({
            initial: this.areaSettings.planAssignmentSelectedTeams,
            change: this.onTeamSelectionChange.bind(this)
          })
        };
      },
      computed: {
        filterIcon() {
          return this.departmentSelection.values.length === 0 && this.teamSelection.values.length === 0 ? "far fa-filter" : "fa fa-filter";
        },
        filteredPersonnelWithTypes() {
          const selectedTeams = this.teamSelection.values.map(teamId => this.teams.find(team => team.id === teamId));
          const selectedDepartments = this.departmentSelection.values.map(departmentId => this.departments.find(department => department.id === departmentId));
          if (selectedTeams.length === 0 && selectedDepartments.length === 0) {
            return this.personnelWithTypes;
          }
          return this.personnelWithTypes.filter(personWithType => selectedTeams.some(team => team.members.includes(personWithType.id)) || selectedDepartments.some(department => department.members.includes(personWithType.id)));
        },
        searchedPersonnelWithTypes() {
          return this.filteredPersonnelWithTypes.filter(personWithType => this.search.matches(personWithType.personInitials));
        }
      },
      watch: {
        departments: {
          immediate: true,
          handler: function (newValue) {
            const departments = [...newValue].sort((a, b) => a.name.localeCompare(b.name));
            this.departmentSelection.update([...childDepartments(departments, null, "")]);
            function* childDepartments(departments, parentId, indent) {
              for (const department of departments) {
                if (department.parentId != parentId) {
                  continue;
                }
                yield {
                  html: "".concat(indent).concat(escapeHtml(department.name)),
                  value: department.id
                };
                yield* childDepartments(departments, department.id, indent + "&nbsp;&nbsp;");
              }
            }
          }
        },
        teams: {
          immediate: true,
          handler: function (newValue) {
            this.teamSelection.update(newValue.map(team => ({
              text: team.name,
              value: team.id
            })).sort((a, b) => a.text.localeCompare(b.text)));
          }
        }
      },
      methods: {
        personWithTypeKey(personWithType) {
          return "".concat(personWithType.id, "-").concat(personWithType.personnelType);
        },
        personWithTypeDragStart(event, personWithType) {
          this.$emit("person-with-type-drag-start", {
            event,
            personWithType: {
              id: personWithType.id,
              personnelType: personWithType.personnelType
            }
          });
        },
        personWithTypeDragEnd(event) {
          this.$emit("person-with-type-drag-end", event);
        },
        onDepartmentSelectionChange(values, isUpdate) {
          if (!isUpdate) {
            // eslint-disable-next-line vue/no-mutating-props
            this.areaSettings.planAssignmentSelectedDepartments = values;
          }
        },
        onTeamSelectionChange(values, isUpdate) {
          if (!isUpdate) {
            // eslint-disable-next-line vue/no-mutating-props
            this.areaSettings.planAssignmentSelectedTeams = values;
          }
        },
        onDropdownSelectKeydown($event) {
          // Prevent dropdown-specific keyboard navigation from overriding the native one of the select element,
          // with the exception of ESC and TAB keys.
          // This makes it possible to change selection with keyboard using arrow keys etc.
          switch ($event.code) {
            case "Escape":
            case "Tab":
              break;
            default:
              $event.stopPropagation();
          }
        }
      },
      render(h) {
        // Styles in './personnelWithTypesBar.css'
        const filterDropDown = h("b-dropdown", {
          class: "filter-dropdown",
          props: {
            boundary: "window",
            size: "sm",
            variant: "outline-secondary"
          }
        }, [
          // Department selection
          ...filter("Afdelinger", this.departmentSelection, this),
          // Team selection
          ...filter("Teams", this.teamSelection, this),
          // Dropdown toggle content
          h("template", {
            slot: "button-content"
          }, [h("span", {
            class: this.filterIcon
          }, [])])]);
        return h("div", {
          class: "personnel-with-types-bar"
        }, [
          // Incremental search
          h("b-form-input", {
            class: "search-input",
            props: {
              placeholder: "Søg",
              value: this.search.value
            },
            on: {
              update: x => this.search.change(x)
            }
          }, []),
          // Filtering by department/team
          filterDropDown,
          // Personnel with types
          ...this.searchedPersonnelWithTypes.map(personWithType => personWithTypePill(personWithType, this))]);
        function filter(name, viewModel, self) {
          return [
            // Selection header
            h("b-dropdown-header", {
              class: "d-flex"
            }, [h("span", [name]),
              // Clear selection
              h("b-link", {
                class: "ml-auto",
                on: {
                  click: () => viewModel.select([])
                }
              }, "Ryd")]),
            // Selection
            h("b-dropdown-form", [h("b-form-select", {
              props: {
                multiple: true,
                options: viewModel.items,
                plain: true,
                selectSize: 5,
                value: viewModel.values
              },
              on: {
                change: x => viewModel.select(x)
              },
              nativeOn: {
                keydown: self.onDropdownSelectKeydown.bind(self)
              }
            }, [])])];
        }
        function personWithTypePill(personWithType, self) {
          return h("person-with-type-pill", {
            attrs: {
              draggable: true,
              tabIndex: "0"
            },
            props: {
              viewModel: personWithType
            },
            nativeOn: {
              dragstart: x => self.personWithTypeDragStart(x, personWithType),
              dragend: x => self.personWithTypeDragEnd(x)
            },
            directives: [{
              name: "b-tooltip",
              modifiers: {
                blur: true,
                click: true,
                window: true
              }
            }],
            key: self.personWithTypeKey(personWithType)
          }, []);
        }
      }
    };
  _module_exports = {
    PersonnelWithTypesBar
  };
  return _module_exports;
})(_module_shared_multipleSelection,
  _module_shared_prelude,
  _module_widget_incrementalSearch,
  _module_widget_personWithTypePill);

/**
 * MODULE shared\multipleSelectionInput.js
 **/
const _module_shared_multipleSelectionInput = (function shared_multipleSelectionInput(
  _module_shared_functions,
  _module_shared_multipleSelection,
  _module_shared_setFocus
) {
  let _module_exports = {};

  const {
    MultipleSelection
  } = _module_shared_multipleSelection;
  const {
    SetFocus
  } = _module_shared_setFocus;
  const functions = _module_shared_functions;

  // @vue/component
  const MultipleSelectionInput = {
    name: "MultipleSelectionInput",
    directives: {
      setFocus: SetFocus
    },
    inheritAttrs: false,
    props: {
      id: {
        type: String,
        required: true
      },
      dropdown: {
        type: Boolean,
        default: false
      },
      label: {
        type: String,
        required: true
      },
      viewModel: {
        type: MultipleSelection,
        required: true
      }
    },
    computed: {
      text() {
        return this.viewModel.values.map(value => this.viewModel.items.find(item => item.value === value)).map(item => item.text).join(", ");
      }
    },
    methods: {
      onSelectKeydown($event) {
        // Prevent dropdown-specific keyboard navigation from overriding the native one of the select element,
        // with the exception of ESC and TAB keys.
        // This makes it possible to change selection with keyboard using arrow keys etc.
        switch ($event.code) {
          case "Escape":
          case "Tab":
            break;
          default:
            $event.stopPropagation();
        }
      },
      select: functions.debounce(function ($event) {
        this.viewModel.select($event);
      }, 200)
    },
    render(h) {
      var _this$text;
      const formSelect = h("b-form-select", {
        class: "select",
        attrs: Object.assign({
          "data-set-focus": true
        }, this.$attrs),
        props: {
          id: this.id,
          options: this.viewModel.items,
          multiple: true,
          plain: true,
          selectSize: 8,
          value: this.viewModel.values
        },
        on: {
          change: this.select.bind(this)
        },
        nativeOn: {
          keydown: this.onSelectKeydown.bind(this)
        }
      }, []);
      return h("b-form-group", {
        class: "multiple-selection-input-dropdown",
        props: {
          label: this.label,
          labelFor: this.id
        }
      }, [this.dropdown ? h("b-dropdown", {
        props: {
          text: this.text,
          variant: "input"
        },
        directives: [{
          name: "setFocus",
          modifiers: {
            shown: true
          }
        }]
      }, [
        // Selection
        formSelect,
        // Dropdown button content
        h("template", {
          slot: "button-content"
        }, [h("span", {
          class: "text"
        }, [(_this$text = this.text) === null || _this$text === void 0 ? void 0 : _this$text.toString()])])]) : formSelect]);
    }
  };
  _module_exports = {
    MultipleSelectionInput
  };
  return _module_exports;
})(_module_shared_functions,
  _module_shared_multipleSelection,
  _module_shared_setFocus);

/**
 * MODULE shared\intervals.js
 **/
const _module_shared_intervals = (function shared_intervals() {
  let _module_exports = {};

  /**
   * Represents an interval between two points,
   * including the start point, and excluding the end point.
   * @typedef {Object} Interval
   * @property {*} start The start point of the interval.
   * @property {*} end The end point of the interval.
   * @property {Boolean} empty Indicates whether the interval is empty, always false.
   */

  /**
   * Represents an empty interval.
   * @typedef {Object} EmptyInterval
   * @property {Boolean} empty Indicates whether the interval is empty, always true.
   */

  /**
   * @returns {Interval|EmptyInterval}
   */
  function makeNonEmptyInterval(start, end) {
    if (end.isBefore(start)) {
      throw "End must be after start";
    }
    if (start.isSame(end)) {
      return makeEmptyInterval();
    }
    return {
      start: start,
      end: end,
      empty: false,
      contains(point) {
        return end.isAfter(point) && !start.isAfter(point);
      },
      duration() {
        return start.durationTo(end);
      },
      intersect(other) {
        if (!this.intersectsWith(other)) {
          return makeEmptyInterval();
        }
        var startToOtherStart = start.compare(other.start);
        var endToOtherEnd = end.compare(other.end);
        return makeNonEmptyInterval(startToOtherStart > 0 ? start : other.start, endToOtherEnd < 0 ? end : other.end);
      },
      intersectsWith(other) {
        if (other.empty) {
          return false;
        }
        return start.isBefore(other.end) && end.isAfter(other.start);
      },
      isSame(other) {
        if (other.empty) {
          return false;
        }
        return start.isSame(other.start) && end.isSame(other.end);
      },
      *iterate(nextFn) {
        let current = this.start;
        do {
          yield current;
          current = nextFn(current);
        } while (current.isBefore(this.end));
      },
      map(fnOrStartFn, endFn) {
        const newStart = fnOrStartFn(start);
        const newEnd = (endFn !== null && endFn !== void 0 ? endFn : fnOrStartFn)(end);
        if (newEnd.isBefore(newStart)) {
          return makeEmptyInterval();
        }
        return makeNonEmptyInterval(newStart, newEnd);
      },
      span(other) {
        if (other.empty) {
          return this;
        }
        return makeNonEmptyInterval(other.start.isBefore(start) ? other.start : start, other.end.isBefore(end) ? end : other.end);
      },
      split(endFromStart) {
        let rest = this;
        let result = [];
        do {
          const newEnd = endFromStart(rest.start);
          if (!newEnd.isBefore(rest.end) || !newEnd.isAfter(rest.start)) {
            result.push(rest);
            return makeIntervalSum(result);
          }
          result.push(makeNonEmptyInterval(rest.start, newEnd));
          rest = makeNonEmptyInterval(newEnd, rest.end);
        } while (!rest.empty);
        return makeIntervalSum(result);
      },
      subtract(other) {
        if (other.empty) {
          return makeIntervalSum([this]);
        }
        const startToOtherEnd = start.compare(other.end);
        if (startToOtherEnd >= 0) {
          return makeIntervalSum([this]);
        }
        const endToOtherStart = end.compare(other.start);
        if (endToOtherStart <= 0) {
          return makeIntervalSum([this]);
        }
        const startToOtherStart = start.compare(other.start);
        const endToOtherEnd = end.compare(other.end);
        if (startToOtherStart > 0) {
          if (endToOtherEnd <= 0) {
            return makeEmptyIntervalSum();
          } else {
            return makeIntervalSum([makeNonEmptyInterval(other.end, end)]);
          }
        } else if (startToOtherStart == 0) {
          if (endToOtherEnd > 0) {
            return makeIntervalSum([makeNonEmptyInterval(other.end, end)]);
          } else {
            return makeEmptyIntervalSum();
          }
        } else {
          if (endToOtherEnd > 0) {
            return makeIntervalSum([makeNonEmptyInterval(start, other.start), makeNonEmptyInterval(other.end, end)]);
          } else {
            return makeIntervalSum([makeNonEmptyInterval(start, other.start)]);
          }
        }
      },
      subtractSum(other) {
        if (other.empty) {
          return makeIntervalSum([this]);
        }
        return other.periods.map(x => this.subtract(x)).reduce((a, b) => a.intersect(b));
      },
      toString() {
        return start.toString() + " - " + end.toString();
      }
    };
  }

  /**
   * @returns {EmptyInterval}
   */
  function makeEmptyInterval() {
    return {
      empty: true,
      contains() {
        return false;
      },
      intersect() {
        return this;
      },
      intersectsWith() {
        return false;
      },
      isSame(other) {
        return other.empty;
      },
      *iterate() {},
      map() {
        return this;
      },
      span(other) {
        return other;
      },
      split() {
        return this;
      },
      subtract() {
        return this;
      },
      subtractSum() {
        return makeEmptyIntervalSum();
      },
      toString() {
        return "";
      }
    };
  }
  function makeIntervalSum(periods) {
    periods = periods.filter(x => !x.empty);
    if (periods.length === 0) {
      return makeEmptyIntervalSum();
    }
    periods = unionIntersecting(periods);
    periods.sort((a, b) => a.start.compare(b.start));
    return {
      empty: false,
      periods: periods,
      contains(point) {
        return periods.some(period => period.contains(point));
      },
      intersect(other) {
        return makeIntervalSum(periods.map(x => other.periods.map(y => x.intersect(y))).reduce((a, b) => a.concat(b), []));
      },
      intersectsWith(other) {
        return periods.some(x => other.periods.some(y => x.intersectsWith(y)));
      },
      joinAdjacent(predicate) {
        if (periods.length === 0) {
          return makeEmptyIntervalSum();
        }
        const {
          result
        } = periods.reduce((state, current) => {
          if (state.result.length === 0) {
            return {
              result: [current],
              lastJoinable: predicate(current)
            };
          } else {
            const last = state.result[state.result.length - 1];
            const currentJoinable = predicate(current);
            if (last.end.isSame(current.start) && state.lastJoinable && currentJoinable) {
              const joined = makeNonEmptyInterval(last.start, current.end);
              state.result[state.result.length - 1] = joined;
              return {
                result: state.result,
                lastJoinable: true
              };
            } else {
              state.result.push(current);
              return {
                result: state.result,
                lastJoinable: currentJoinable
              };
            }
          }
        }, {
          result: []
        });
        return makeIntervalSum(result);
      },
      split(endFromStart) {
        return makeIntervalSum(periods.map(x => x.split(endFromStart).periods).reduce((a, b) => a.concat(b), []));
      },
      toString() {
        return periods.map(x => x.toString()).join(", ");
      },
      union(other) {
        return other.empty ? this : makeIntervalSum([...periods, ...other.periods]);
      }
    };
    function unionIntersecting(periods) {
      return periods.reduce((a, b) => union(a, b), []);
      function union(periods, period) {
        const [intersecting, nonIntersecting] = partition(periods, x => x.intersectsWith(period));
        const merged = intersecting.reduce((a, b) => a.span(b), period);
        return [merged, ...nonIntersecting];
      }
      function partition(array, predicate) {
        return array.reduce(([trueElements, falseElements], element) => {
          const target = predicate(element) ? trueElements : falseElements;
          target.push(element);
          return [trueElements, falseElements];
        }, [[], []]);
      }
    }
  }
  function makeEmptyIntervalSum() {
    return {
      empty: true,
      periods: [],
      contains() {
        return false;
      },
      intersect() {
        return this;
      },
      intersectsWith() {
        return false;
      },
      joinAdjacent() {
        return this;
      },
      split() {
        return this;
      },
      toString() {
        return "";
      },
      union(other) {
        return other;
      }
    };
  }
  _module_exports = {
    empty: makeEmptyInterval,
    nonEmpty: makeNonEmptyInterval,
    sum: makeIntervalSum
  };
  return _module_exports;
})();

/**
 * MODULE shared\validatedTimePeriod.js
 **/
const _module_shared_validatedTimePeriod = (function shared_validatedTimePeriod(
  _module_shared_intervals,
  _module_shared_result,
  _module_shared_validatedTime
) {
  let _module_exports = {};

  const intervals = _module_shared_intervals;
  const {
    Result
  } = _module_shared_result;
  const {
    ValidatedTime,
    validTime
  } = _module_shared_validatedTime;

  /** @typedef {import('./time').MinuteTime} MinuteTime */
  /** @typedef {import('./intervals').Interval} Interval */

  /**
   * A callback called when time period changes.
   * @callback ChangeCallback
   * @param {Result} value A Result object, which in successful state contains the time period,
   * and in error state contains a validation/parse error.
   * @param {boolean} isReset Indicates whether the change is a result of a reset call (as opposed to set call).
   */

  /**
   * Represents options used when creating instance of ValidatedTimePeriod.
   * @typedef {Object} Options
   * @property {?Interval<MinuteTime>} [initialValue=""] Initial value of the time period.
   * @property {?ChangeCallback} [change=null] A function that is called with the time period, when it changes.
   * @property {?boolean} [isAvailable=false] Indicates whether the time period is initially available.
   */

  /**
   * @classdesc Represents a time period value parsed from a pair of strings (text values),
   * that correspond to the period's start and end time.
   * @constructor
   * @summary Represents a time period value parsed from a pair of strings (text values).
   * @param {Options} options The options.
   */
  function ValidatedTimePeriod(options) {
    var _options$initialValue, _options$initialValue2, _options$change;
    this.start = new ValidatedTime({
      initialValue: (_options$initialValue = options.initialValue) === null || _options$initialValue === void 0 ? void 0 : _options$initialValue.start,
      isAvailable: !!options.isAvailable,
      change: (start, isReset) => this._changeOfStart(start, isReset),
      validate: start => this._validStart(start)
    });
    this.end = new ValidatedTime({
      initialValue: (_options$initialValue2 = options.initialValue) === null || _options$initialValue2 === void 0 ? void 0 : _options$initialValue2.end,
      isAvailable: !!options.isAvailable,
      change: (end, isReset) => this._changeOfEnd(end, isReset),
      validate: end => this._validEnd(end)
    });
    this._change = (_options$change = options.change) !== null && _options$change !== void 0 ? _options$change : () => {};
    this._isAvailable = !!options.isAvailable;
    this._isSynchronizingStartAndEnd = false;
  }
  ValidatedTimePeriod.prototype = {
    /**
     * Returns the time period, if it is available.
     * @returns {?Result} A Result object, which in successful state contains the time period,
     * and in error state contains a validation/parse error.
     * Null if the time period is not yet available.
     */
    get availableValue() {
      return this._isAvailable ? this.value : null;
    },
    /**
     * Returns the time period.
     * @returns {?Result} A Result object, which in successful state contains the time period,
     * and in error state contains a validation/parse error.
     */
    get value() {
      return this._validPeriod(this.start.value, this.end.value);
    },
    /**
     * Sets new time period and makes it available.
     * @param {Interval<MinuteTime>} value The time period to set.
     */
    set(value) {
      this._isAvailable = true;
      this._isSynchronizingStartAndEnd = true;
      try {
        this.start.set(value.start);
        this.end.set(value.end);
      } finally {
        this._isSynchronizingStartAndEnd = false;
      }
      this._change(this.value, false);
    },
    /**
     * Sets new time period and makes it no longer available.
     * @param {?Interval<MinuteTime>} [value=undefined] The time period to set.
     * If undefined, the time period is set to the initial time period.
     */
    reset(value) {
      this._isAvailable = false;
      this._isSynchronizingStartAndEnd = true;
      try {
        var _value$start, _value$end;
        this.start.reset(value === undefined ? undefined : (_value$start = value === null || value === void 0 ? void 0 : value.start) !== null && _value$start !== void 0 ? _value$start : null);
        this.end.reset(value === undefined ? undefined : (_value$end = value === null || value === void 0 ? void 0 : value.end) !== null && _value$end !== void 0 ? _value$end : null);
      } finally {
        this._isSynchronizingStartAndEnd = false;
      }
      this._change(this.value, true);
    },
    /**
     * Makes the time period available.
     * @returns {Result} A Result object, which in successful state contains the time period,
     * and in error state contains a validation/parse error.
     */
    validate() {
      this._isAvailable = true;
      const start = this.start.validate();
      const end = this.end.validate();
      return this._validPeriod(start, end);
    },
    _changeOfStart(start, isReset) {
      if (this._isSynchronizingStartAndEnd) {
        return;
      }
      this._change(this._validPeriod(start, this.end.value), isReset);
    },
    _changeOfEnd(end, isReset) {
      if (this._isSynchronizingStartAndEnd) {
        return;
      }
      this._change(this._validPeriod(this.start.value, end), isReset);
    },
    _validPeriod(start, end) {
      return start.then(validStart => end.then(validEnd => intervals.nonEmpty(validStart, validEnd)));
    },
    _validStart(validStart) {
      return validTime(this.end.textValue, this._bounds, this._moment).then(validEnd => validStart.compare(validEnd) >= 0 ? Result.error("Starttid skal være inden sluttid") : Result.success(validStart), () => validStart);
    },
    _validEnd(validEnd) {
      return validTime(this.start.textValue, this._bounds, this._moment).then(validStart => validStart.compare(validEnd) >= 0 ? Result.error("Sluttid skal være efter starttid") : Result.success(validEnd), () => validEnd);
    }
  };
  _module_exports = {
    ValidatedTimePeriod
  };
  return _module_exports;
})(_module_shared_intervals,
  _module_shared_result,
  _module_shared_validatedTime);

/**
 * MODULE playground_duty\breakEdition.js
 **/
const _module_widget_breakEdition = (function widget_breakEdition(
  _module_shared_errorList,
  _module_shared_intervals,
  _module_shared_prelude,
  _module_shared_progressList,
  _module_shared_result,
  _module_shared_validatedTimePeriod,
  _module_shared_validatedValue
) {
  let _module_exports = {};

  const {
    ErrorList
  } = _module_shared_errorList;
  const {
    canceled
  } = _module_shared_prelude;
  const {
    ProgressList
  } = _module_shared_progressList;
  const {
    Result
  } = _module_shared_result;
  const {
    ValidatedTimePeriod
  } = _module_shared_validatedTimePeriod;
  const {
    ValidatedValue
  } = _module_shared_validatedValue;
  const intervals = _module_shared_intervals;

  /** @constructor */
  function BreakEdition(actions, modalRef) {
    this.name = new ValidatedValue({
      validate: validName
    });
    this.period = new ValidatedTimePeriod({});
    this.title = "";
    this.errors = new ErrorList();
    this.progress = new ProgressList();
    this._actions = actions;
    this._modalRef = modalRef;
  }
  BreakEdition.prototype = {
    show(options) {
      this.title = options.isEdit ? "Rediger pause" : "Tilføj pause";
      this.name.reset(options.break ? options.break.name : null);
      this.period.reset(options.break ? intervals.nonEmpty(options.break.start, options.break.end) : null);
      this.errors.clear();
      const modalRef = this._modalRef();
      const self = this;
      const result = new Promise((resolve, reject) => {
        modalRef.$on("ok", onOk);
        modalRef.$on("hide", onHide);
        function onHide(e) {
          if (e.trigger !== "ok") {
            modalRef.$off("ok", onOk);
            modalRef.$off("hide", onHide);
            if (e.trigger !== "saved") {
              reject(canceled());
            }
          }
        }
        function onOk(e) {
          e.preventDefault(); // Prevent hiding of the modal
          self._save(options).then(() => {
            modalRef.hide("saved");
            resolve();
          });
        }
      });
      modalRef.show();
      return result;
    },
    _save(options) {
      const name = this.name.validate();
      const period = this.period.validate();
      if (!name.isSuccess || !period.isSuccess) {
        return Promise.reject();
      }
      return this.errors.track("Kunne ikke gemme pausen", () => this.progress.track("Gemmer...", () => options.isEdit ? this._actions.updateBreak(options.break.id, name.value, period.value.start, period.value.end, options.break.timestamp) : this._actions.addBreak(options.areaId, name.value, period.value.start, period.value.end)));
    }
  };
  function validName(value) {
    const trimmed = (value || "").trim();
    if (trimmed.length == 0) {
      return Result.error("Indtast et navn");
    }
    if (trimmed.length > 100) {
      return Result.error("Indtast et navn, der er kortere end 100 tegn");
    }
    return Result.success(trimmed);
  }
  _module_exports = {
    BreakEdition
  };
  return _module_exports;
})(_module_shared_errorList,
  _module_shared_intervals,
  _module_shared_prelude,
  _module_shared_progressList,
  _module_shared_result,
  _module_shared_validatedTimePeriod,
  _module_shared_validatedValue);

/**
 * MODULE playground_duty\breaks.js
 **/
const _module_widget_breaks = (function widget_breaks(
  _module_shared_errorList,
  _module_shared_functions,
  _module_shared_progressList,
  _module_shared_singleSelection,
  _module_widget_breakEdition,
  _module_widget_breakTable
) {
  let _module_exports = {};

  const {
    ErrorList,
    retryActionTemplate
  } = _module_shared_errorList;
  const functions = _module_shared_functions;
  const {
    ProgressList
  } = _module_shared_progressList;
  const {
    SingleSelection
  } = _module_shared_singleSelection;
  const {
    BreakTable
  } = _module_widget_breakTable;
  const {
    BreakEdition
  } = _module_widget_breakEdition;

  /** @constructor */
  function Breaks(actions, confirmationModal, editBreakModalRef, settings, store) {
    this.area = new SingleSelection({
      change: (x, isUpdate) => this._selectArea(x, isUpdate),
      initial: settings.breaksAreaId,
      fallback: {
        value: null,
        text: "Vælg område"
      },
      preselectFirst: true
    });
    this.edition = new BreakEdition(actions, editBreakModalRef);
    this.errors = new ErrorList();
    this.noBreaksPrompt = new NoBreaksPrompt(store);
    this.progress = new ProgressList();
    this.table = new BreakTable(this._delete.bind(this), this._edit.bind(this), store);
    this._actions = actions;
    this._confirmationModal = confirmationModal;
    this._settings = settings;
    this._store = store;
  }
  Breaks.prototype = {
    addNew: functions.withFocusRestore(function () {
      return this.edition.show({
        isEdit: false,
        areaId: this.area.value
      });
    }, "activeElement"),
    selectSchoolYear(schoolYearId) {
      this.noBreaksPrompt.selectSchoolYear(schoolYearId);
      this.errors.track({
        message: "Kunne ikke indlæse områder",
        id: "areas",
        actions: [retryActionTemplate()]
      }, () => this.progress.track({
        id: "areas",
        message: "Indlæser områder..."
      }, () => this._actions.loadAreas(schoolYearId).then(() => this.area.update(this._areas(schoolYearId)), x => {
        this.area.update(this._areas(schoolYearId));
        return Promise.reject(x);
      })));
    },
    _areas(schoolYearId) {
      var _this$_store$areas$sc;
      return ((_this$_store$areas$sc = this._store.areas[schoolYearId]) !== null && _this$_store$areas$sc !== void 0 ? _this$_store$areas$sc : []).filter(x => x.isEditable).map(x => ({
        value: x.id,
        text: x.name
      }));
    },
    _delete(id, name) {
      return this._confirmationModal().show({
        message: "Er du sikker på, at du vil slette?",
        title: "Slet pause",
        okVariant: "danger"
      }).then(x => {
        if (!x) {
          return;
        }
        this.errors.track({
          message: 'Kunne ikke slette pausen "' + name + '"',
          id: "delete-" + id,
          actions: [retryActionTemplate()]
        }, () => this.progress.track({
          id: "delete-" + id,
          message: "Sletning..."
        }, () => this._actions.deleteBreak(id)));
      });
    },
    _edit(break_) {
      return this.edition.show({
        isEdit: true,
        break: break_
      });
    },
    _selectArea(areaId, isUpdate) {
      if (!isUpdate) {
        this._settings.breaksAreaId = areaId;
      }
      this.noBreaksPrompt.selectArea(areaId);
      this.table.selectArea(areaId);
      if (areaId) {
        this.errors.track({
          message: "Kunne ikke indlæse pauser",
          id: "breaks",
          actions: [retryActionTemplate()]
        }, () => this.progress.track({
          id: "breaks",
          message: "Indlæser pauser..."
        }, () => this._actions.loadBreaks([areaId])));
      }
    }
  };

  /** @constructor */
  function NoBreaksPrompt(store) {
    this._areaId = undefined;
    this._schoolYearId = undefined;
    this._store = store;
  }
  NoBreaksPrompt.prototype = {
    get value() {
      var _this$_store$areas$th, _this$_store$breaks$t, _this$_store$breaks$t2;
      if (!((_this$_store$areas$th = this._store.areas[this._schoolYearId]) !== null && _this$_store$areas$th !== void 0 && _this$_store$areas$th.some(x => x.isEditable))) {
        return "Der er ingen områder, hvor du kan redigere pauser.";
      }
      if (((_this$_store$breaks$t = (_this$_store$breaks$t2 = this._store.breaks[this._areaId]) === null || _this$_store$breaks$t2 === void 0 ? void 0 : _this$_store$breaks$t2.length) !== null && _this$_store$breaks$t !== void 0 ? _this$_store$breaks$t : 0) === 0) {
        return "Der er ingen pauser i området.";
      }
      return null;
    },
    selectArea(areaId) {
      this._areaId = areaId;
    },
    selectSchoolYear(schoolYearId) {
      this._schoolYearId = schoolYearId;
    }
  };
  _module_exports = {
    Breaks
  };
  return _module_exports;
})(_module_shared_errorList,
  _module_shared_functions,
  _module_shared_progressList,
  _module_shared_singleSelection,
  _module_widget_breakEdition,
  _module_widget_breakTable);

/**
 * MODULE shared\future.js
 **/
const _module_shared_future = (function shared_future() {
  let _module_exports = {};

  /** @constructor */
  function Future() {
    this.promise = new Promise((resolve, reject) => {
      this._resolve = resolve;
      this._reject = reject;
    });
  }
  Future.prototype.reject = function (value) {
    this._reject(value);
  };
  Future.prototype.resolve = function (value) {
    this._resolve(value);
  };
  _module_exports = {
    Future
  };
  return _module_exports;
})();

/**
 * MODULE shared\aula.js
 **/
const _module_shared_aula = (function shared_aula(
  _module_shared_future
) {
  let _module_exports = {};

  const {
    Future
  } = _module_shared_future;
  const AulaTokenMixin = {
    data() {
      return {
        /** @private */
        aulaToken: undefined,
        /** @private */
        aulaTokenFuture: new Future()
      };
    },
    computed: {
      /** @public */
      token() {
        return this.aulaTokenFuture.promise;
      }
    },
    watch: {
      aulaToken(newValue) {
        if (newValue == undefined) {
          return;
        }
        this.aulaTokenFuture.resolve(newValue);
        this.aulaTokenFuture = new Future();
        this.aulaTokenFuture.resolve(newValue);
      }
    },
    mounted() {
      this.aulaToken = this.getAulaToken();
    }
  };
  _module_exports = {
    AulaTokenMixin
  };
  return _module_exports;
})(_module_shared_future);

/**
 * MODULE shared\dateMinuteTime.js
 **/
const _module_shared_dateMinuteTime = (function shared_dateMinuteTime() {
  let _module_exports = {};

  /** @typedef {import(./date).DayOfWeek} DayOfWeek */

  /**
   * A date with time up to minute precision.
   * @typedef {Object} DateMinuteTime
   * @property {number} year Year of the date part.
   * @property {number} month Month of the date part (1-12).
   * @property {DayOfWeek} day Day of week of the date part.
   * @property {Number} hour The hour part of the time part.
   * @property {Number} minute The minute part of the time part.
   */

  /**
   * @returns {DateMinuteTime}
   */
  function makePoint(year, month, day, hour, minute, moment) {
    return wrapMoment(moment()().year(year).month(month - 1).date(day).hour(hour).minute(minute).second(0).millisecond(0));
  }

  /**
   * @returns {DateMinuteTime}
   */
  function wrapMoment(value) {
    if (!value.isValid()) {
      const message = invalidDateMinuteTimeMessage(value);
      throw new Error("Invalid date and minute time".concat(message ? ". " + message : "", "."));
    }
    return {
      _value: value,
      get year() {
        return this._value.year();
      },
      get month() {
        return this._value.month() + 1;
      },
      get day() {
        return this._value.date();
      },
      get hour() {
        return this._value.hour();
      },
      get minute() {
        return this._value.minute();
      },
      get dayOfWeek() {
        const day = this._value.day();
        return day == 0 ? 6 : day - 1;
      },
      compare(other) {
        return this._value.diff(other._value, "minutes");
      },
      isAfter(other) {
        return this._value.isAfter(other._value);
      },
      isBefore(other) {
        return this._value.isBefore(other._value);
      },
      isSame(other) {
        return this._value.isSame(other._value);
      },
      toJSON() {
        // Format date and time parts separately,
        // as for some reason, if time tokens are used together with date tokens,
        // format() outputs 0 for time parts.
        return this._value.format("YYYY-MM-DD[T]") + this._value.format("HH[:]mm");
      },
      toString() {
        return this._value.format("L LT");
      }
    };
  }
  function invalidDateMinuteTimeMessage(value) {
    switch (value.invalidAt()) {
      case 0:
        return "Year value ".concat(value.year(), " is invalid");
      case 1:
        return "Month value ".concat(value.month(), " is invalid");
      case 2:
        return "Day value ".concat(value.date(), " is invalid");
      case 3:
        return "Hours value ".concat(value.hour(), " is invalid");
      case 4:
        return "Minute value ".concat(value.minute(), " is invalid");
      default:
        return null;
    }
  }

  /**
   * @returns {?DateMinuteTime}
   */
  function parsePoint(value, moment) {
    const result = value.match(/(\d{1,5})-(\d{1,2})-(\d{1,2})T(\d{1,2}):(\d{1,2})/u);
    if (result && result[0] === value) {
      const [, year, month, day, hour, minute] = result;
      return makePoint(Number.parseInt(year, 10), Number.parseInt(month, 10), Number.parseInt(day, 10), Number.parseInt(hour, 10), Number.parseInt(minute, 10), moment);
    } else {
      return null;
    }
  }
  _module_exports = {
    /**
     * @param {import('./date').Date} date The date part.
     * @param {import('./time').MinuteTime} minuteTime The time part.
     * @returns {DateMinuteTime}
     */
    fromDateAndTime(date, minuteTime) {
      return wrapMoment(date._value.clone().hour(minuteTime.hour).minute(minuteTime.minute));
    },
    parsePoint,
    point: makePoint,
    wrapMoment(value) {
      return wrapMoment(value.clone().second(0).millisecond(0));
    }
  };
  return _module_exports;
})();

/**
 * MODULE shared\date.js
 **/
const _module_shared_date = (function shared_date() {
  let _module_exports = {};

  /**
   * A number identifying a day of week.
   * Days are numbered from 0, starting with monday, up to sunday.
   * @typedef {number} DayOfWeek
   */

  /**
   * A date.
   * @typedef {Object} Date
   * @property {number} year Year of the date.
   * @property {number} month Month of the date (1-12).
   * @property {number} day Day of month of the date (1-31).
   * @property {DayOfWeek} dayOfWeek Day of week of the date (0-6).
   */

  /**
   * @returns {Date}
   */
  function makePoint(year, month, day, moment) {
    return wrapMoment(moment()().year(year).month(month - 1).date(day).hour(0).minute(0).second(0).millisecond(0));
  }

  /**
   * @returns {Date}
   */
  function wrapMoment(value) {
    if (!value.isValid()) {
      const message = invalidDateMessage(value);
      throw new Error("Invalid date".concat(message ? ". " + message : "", "."));
    }
    return {
      _value: value,
      get year() {
        return this._value.year();
      },
      get month() {
        return this._value.month() + 1;
      },
      get day() {
        return this._value.date();
      },
      get dayOfWeek() {
        const day = this._value.day();
        return day == 0 ? 6 : day - 1;
      },
      addDays(count) {
        return wrapMoment(this._value.clone().add(count, "days"));
      },
      compare(other) {
        return this._value.diff(other._value, "days");
      },
      /**
       * Formats the date to a string.
       * Supported formats are "dayofweek" and "input".
       * The "dayofweek" format specifies that long name of the date's day of week should be used.
       * The "input" format results in a string understood by input elements of type "date" ('yyyy-mm-dd').
       * @param {string} format A format specification to use. The only supported value is "dayofweek".
       * @returns A string representation of the date, according to specified format.
       * @throws {Error} Throws when format argument is none of the supported values.
       */
      format(format) {
        switch (format) {
          case "dayofweek":
            return this._value.format("dddd");
          case "input":
            return this._value.format("YYYY-MM-DD");
          default:
            throw new Error("Unknown date point format \"".concat(format, "\""));
        }
      },
      isAfter(other) {
        return this._value.isAfter(other._value);
      },
      isBefore(other) {
        return this._value.isBefore(other._value);
      },
      isSame(other) {
        return this._value.isSame(other._value);
      },
      toJSON() {
        return this._value.format("YYYY-MM-DD");
      },
      toString() {
        return this._value.format("L");
      }
    };
  }
  function invalidDateMessage(value) {
    switch (value.invalidAt()) {
      case 0:
        return "Year value ".concat(value.year(), " is invalid");
      case 1:
        return "Month value ".concat(value.month(), " is invalid");
      case 2:
        return "Day value ".concat(value.date(), " is invalid");
      default:
        return null;
    }
  }

  /**
   * @returns {?Date}
   */
  function parsePoint(value, moment) {
    const result = value.match(/(\d{1,5})-(\d{1,2})-(\d{1,2})/u);
    if (result && result[0] === value) {
      const [, year, month, day] = result;
      return makePoint(Number.parseInt(year, 10), Number.parseInt(month, 10), Number.parseInt(day, 10), moment);
    } else {
      return null;
    }
  }
  _module_exports = {
    parsePoint,
    point: makePoint,
    wrapMoment(value) {
      return wrapMoment(value.clone().hour(0).minute(0).second(0).millisecond(0));
    }
  };
  return _module_exports;
})();

/**
 * MODULE shared\week.js
 **/
const _module_shared_week = (function shared_week(
  _module_shared_date
) {
  let _module_exports = {};

  const date = _module_shared_date;

  /**
   * A week of year.
   * @typedef {Object} Week
   * @property {Number} number The number of the week in a year, using ISO numbering system.
   * @property {Number} year The year of the week, using ISO numbering system.
   */

  /**
   * @returns {Week}
   */
  function makePoint(year, week, moment) {
    return wrapMoment(moment()().isoWeekYear(year).isoWeek(week).day(1).hour(0).minute(0).second(0).millisecond(0));
  }
  function wrapMoment(value) {
    if (!value.isValid()) {
      const message = invalidWeekMessage(value);
      throw new Error("Invalid week".concat(message ? ". " + message : "", "."));
    }
    return {
      _value: value,
      get year() {
        return this._value.isoWeekYear();
      },
      get number() {
        return this._value.isoWeek();
      },
      addWeeks(count) {
        return wrapMoment(this._value.clone().add(count, "weeks"));
      },
      compare(other) {
        return this._value.diff(other._value, "days");
      },
      endOfWeek() {
        const sunday = this._value.clone().day(7);
        return date.wrapMoment(sunday);
      },
      isAfter(other) {
        return this._value.isAfter(other._value);
      },
      isBefore(other) {
        return this._value.isBefore(other._value);
      },
      isSame(other) {
        return this._value.isSame(other._value);
      },
      startOfWeek() {
        return date.wrapMoment(this._value.clone());
      },
      /**
       * @param {number} dayOfWeek The day of week, where 0 is monday and 6 is sunday.
       */
      toDate(dayOfWeek) {
        const day = this._value.clone().day(dayOfWeek + 1);
        return date.wrapMoment(day);
      },
      toJSON() {
        return this.toString();
      },
      toString() {
        return this._value.format("GGGG[-W]WW");
      }
    };
  }
  function invalidWeekMessage(value) {
    switch (value.invalidAt()) {
      case 0:
        return "Year value ".concat(value.year(), " is invalid");
      case 1:
        return "Month value ".concat(value.month(), " is invalid");
      case 2:
        return "Day value ".concat(value.date(), " is invalid");
      default:
        return null;
    }
  }

  /**
   * @returns {?Week}
   */
  function parsePoint(value, moment) {
    const result = value.match(/(\d{1,5})-W(\d{1,2})/u);
    if (result && result[0] === value) {
      const [, year, week] = result;
      return makePoint(Number.parseInt(year, 10), Number.parseInt(week, 10), moment);
    } else {
      return null;
    }
  }
  _module_exports = {
    /**
     * @returns {Week}
     */
    fromDate(date) {
      return wrapMoment(date._value.clone().day(date._value.day() === 0 ? -6 : 1));
    },
    parsePoint,
    point: makePoint
  };
  return _module_exports;
})(_module_shared_date);

/**
 * MODULE playground_duty\weeks.js
 **/
const _module_widget_weeks = (function widget_weeks(
  _module_shared_week
) {
  let _module_exports = {};

  const Week = _module_shared_week;
  function* weeksOfSchoolYear(schoolYearPeriod) {
    const schoolYearWeeks = schoolYearPeriod.map(x => Week.fromDate(x), x => Week.fromDate(x.addDays(-1)).addWeeks(1));
    const weeks = schoolYearWeeks.iterate(x => x.addWeeks(1));
    for (const week of weeks) {
      const start = week.startOfWeek();
      const end = week.endOfWeek();
      yield {
        text: "Uge ".concat(week.number, " (").concat(start, " - ").concat(end, ")"),
        value: week.toString()
      };
    }
  }
  _module_exports = {
    weeksOfSchoolYear
  };
  return _module_exports;
})(_module_shared_week);

/**
 * MODULE playground_duty\copyWeek.js
 **/
const _module_widget_copyWeek = (function widget_copyWeek(
  _module_shared_errorList,
  _module_shared_future,
  _module_shared_intervals,
  _module_shared_multipleSelection,
  _module_shared_prelude,
  _module_shared_progressList,
  _module_shared_singleSelection,
  _module_shared_week,
  _module_widget_weeks
) {
  let _module_exports = {};

  const {
    ErrorList
  } = _module_shared_errorList;
  const {
    Future
  } = _module_shared_future;
  const intervals = _module_shared_intervals;
  const {
    ProgressList
  } = _module_shared_progressList;
  const {
    canceled
  } = _module_shared_prelude;
  const {
    SingleSelection
  } = _module_shared_singleSelection;
  const {
    MultipleSelection
  } = _module_shared_multipleSelection;
  const Week = _module_shared_week;
  const {
    weeksOfSchoolYear
  } = _module_widget_weeks;

  /** @constructor */
  function CopyWeek(modalRef, actions, confirmationModal, moment, store) {
    this.progress = new ProgressList();
    this.errors = new ErrorList();
    this.sourceWeek = new SingleSelection({
      fallback: {
        text: "Vælg uge",
        value: null
      }
    });
    this.targetWeeks = new MultipleSelection({});
    this._actions = actions;
    this._confirmationModal = confirmationModal;
    this._modalRef = modalRef;
    this._moment = moment;
    this._store = store;
  }
  CopyWeek.prototype = {
    show(areaId, schoolYearId, schoolCode, sourceWeek) {
      var _this$_store$schools$, _this$_store$schools$2, _this$_store$schools$3;
      const schoolYearPeriod = (_this$_store$schools$ = (_this$_store$schools$2 = this._store.schools.find(school => school.code === schoolCode)) === null || _this$_store$schools$2 === void 0 ? void 0 : (_this$_store$schools$3 = _this$_store$schools$2.schoolYears.find(schoolYear => schoolYear.id === schoolYearId)) === null || _this$_store$schools$3 === void 0 ? void 0 : _this$_store$schools$3.period) !== null && _this$_store$schools$ !== void 0 ? _this$_store$schools$ : intervals.empty();
      this.sourceWeek.select(sourceWeek.toString());
      const weeks = [...weeksOfSchoolYear(schoolYearPeriod)];
      this.sourceWeek.update(weeks);
      this.targetWeeks.update(weeks);
      const allWeeksSuccessful = new Future();
      const initialSourceWeekReplaced = {
        value: false,
        update(successfulWeeks) {
          this.value = this.value || successfulWeeks.some(x => sourceWeek.isSame(x));
        }
      };
      return new Promise((resolve, reject) => {
        allWeeksSuccessful.promise.then(() => {
          this._modalRef().hide("copied");
          resolve(initialSourceWeekReplaced.value);
        });
        const onHide = e => {
          if (e.trigger === "ok") {
            e.preventDefault();
            this._confirmationModal().show({
              message: "Er du sikker på, at du vil fortsætte? Alle vagter i valgte uger overskrives.",
              title: "Kopier vagter",
              okVariant: "danger"
            }).then(x => {
              if (x) {
                this._copy(areaId, allWeeksSuccessful, initialSourceWeekReplaced);
              }
            });
          } else {
            if (e.trigger !== "copied") {
              allWeeksSuccessful === null || allWeeksSuccessful === void 0 ? void 0 : allWeeksSuccessful.reject(canceled());
              reject(canceled());
            }
            this._modalRef().$off("hide", onHide);
            this.sourceWeek.select(null);
            this.sourceWeek.update([]);
            this.targetWeeks.select([]);
            this.targetWeeks.update([]);
          }
        };
        this._modalRef().$on("hide", onHide);
        this._modalRef().show();
      });
    },
    _copy(areaId, allWeeksSuccessful, initialSourceWeekReplaced) {
      const sourceWeek = Week.parsePoint(this.sourceWeek.value, this._moment);
      const targetWeeks = this.targetWeeks.values.map(weekId => Week.parsePoint(weekId, this._moment));
      return copy.call(this, areaId, sourceWeek, targetWeeks);
      function copy(areaId, sourceWeek, targetWeeks) {
        return this.errors.track("Kunne ikke kopiere uger", () => this.progress.track("Kopierer uger...", () => this._actions.copyAssignments(areaId, sourceWeek, targetWeeks).then(result => {
          initialSourceWeekReplaced.update(result.successfulWeeks);
          if (result.failedWeeks.length > 0) {
            this.errors.add({
              message: "Kunne ikke kopiere f\xF8lgende uger (".concat(result.failedWeeks.sort((a, b) => a.compare(b)).map(x => "Uge ".concat(x.number)).join(", "), ")"),
              retryCallback: () => copy.call(this, areaId, sourceWeek, result.failedWeeks)
            });
          } else {
            allWeeksSuccessful.resolve();
          }
        })));
      }
    }
  };
  _module_exports = {
    CopyWeek
  };
  return _module_exports;
})(_module_shared_errorList,
  _module_shared_future,
  _module_shared_intervals,
  _module_shared_multipleSelection,
  _module_shared_prelude,
  _module_shared_progressList,
  _module_shared_singleSelection,
  _module_shared_week,
  _module_widget_weeks);

/**
 * MODULE playground_duty\store.js
 **/
const _module_widget_store = (function widget_store(
  _module_shared_week
) {
  let _module_exports = {};

  const Week = _module_shared_week;

  /** @typedef {import('../shared/date').Date} Date */
  /** @typedef {import('../shared/date').DayOfWeek} DayOfWeek */
  /** @typedef {import('../shared/dateMinuteTime').DateMinuteTime} DateMinuteTime */
  /** @typedef {import('../shared/time').MinuteTime} MinuteTime */
  /** @typedef {import('../shared/week').Week} Week */
  /** @typedef {import('../shared/intervals').Interval} Interval */
  /** @typedef {import('../shared/intervals').IntervalSum} IntervalSum */

  /**
   * An area.
   * @typedef {Object} Area
   * @property {AreaId} id Id of the area.
   * @property {boolean} isEditable Identifies whether the area can be edited by the user.
   * @property {String} name The name of the area.
   */

  /**
   * Id of an area.
   * @typedef {*} AreaId
   */

  /**
   * An assignment
   * @typedef {Object} Assignment
   * @property {PersonId} personId Id of the assigned person.
   * @property {PersonnelType} personnelType Personnel type for the assigned person.
   */

  /**
   * Assignments of a break on a date.
   * @typedef {Object} AssignmentsOfBreakAndDate
   * @property {BreakId} breakId Id of the assignments' break.
   * @property {DayOfWeek} dayIndex Day of week of the assignments' date.
   * @property {PersonWithType[]} primary Primary assignments.
   * @property {PersonWithType[]} backup Backup assignments.
   * Assignments in the array are ordered in ascending priority.
   * @property {?Timestamp} timestamp Timestamp of the assignments for the break and the date.
   */

  /**
   * A break.
   * @typedef {Object} Break
   * @property {BreakId} id Id of the break.
   * @property {String} name Name of the break.
   * @property {MinuteTime} start Start time of the break.
   * @property {MinuteTime} end End time of the break.
   * @property {Timestamp} timestamp Timestamp of the break.
   */

  /**
   * Id of a break.
   * @typedef {*} BreakId
   */

  /**
   * Id of a department
   * @typedef {*} DepartmentId
   */

  /**
   * A department
   * @typedef {Object} Department
   * @property {DepartmentId} id Id of the department.
   * @property {String} name Name of the department.
   * @property {PersonId[]} members The ids of members of the department.
   */

  /**
   * A group of employments of same personnel type.
   * @typedef {Object} EmploymentGroup
   * @property {IntervalSum<Date>} period Period of the employments.
   * @property {PersonnelType} personnelType Personnel type of the employments.
   */

  /**
   * A person.
   * @typedef {Object} Person
   * @property {EmploymentGroup[]} employments Employment groups of the person,
   * during current school year of their school.
   * @property {String} firstName First name of the person.
   * @property {PersonId} id Id of the person.
   * @property {String} initials Initials of the person.
   * @property {String} lastName Last name of the person.
   */

  /**
   * Periods during which a person, or person with personnel type, is absent.
   * @typedef {Object} PersonAbsences
   * @property {PersonId} id Id of the person.
   * @property {PersonnelType?} personnelType Personnel type.
   * @property {IntervalSum<DateMinuteTime>} periods The periods during which the person is absent.
   * @property {PlannedPersonAbsence[]} planned The planned person absences during which the person is planned to be absent.
   */

  /**
   * Substitution of a person in an assignment.
   * @typedef {Object} PersonSubstitution
   * @property {PersonId} personId Id of the substituted person.
   * @property {BreakId} breakId Id of the assignment's break.
   * @property {Date} date Date of the assignment.
   */

  /**
   * Substitutions of personnel in an area.
   * @typedef {Object} PersonSubstitutionsOfArea
   * @property {AreaId} areaId Id of the area.
   * @property {PersonSubstitution[]} substitutions The substitutions.
   */

  /**
   * Id of a person
   * @typedef {*} PersonId
   */

  /**
   * A person id with a personnel type.
   * @typedef {Object} PersonWithType
   * @property {PersonId} id The person Id.
   * @property {PersonnelType} personnelType The personnel type.
   */

  /**
   * Id of a personnel type.
   * @typedef {*} PersonnelType
   */

  /**
   * A description of personnel type.
   * @typedef {Object} PersonnelTypeDescription
   * @property {String} abbreviation Abbreviation of the personnel type.
   * @property {String} description Description of the personnel type.
   */

  /**
   * A planned person absence.
   * @typedef {Object} PlannedPersonAbsence
   * @property {String} description Description of the absence.
   * @property {Interval<DateMinuteTime>} period Period of the absence.
   */

  /**
   * A school.
   * @typedef {Object} School
   * @property {SchoolCode} code Code of the school.
   * @property {String} name Name of the school.
   * @property {PersonId} personId Id of the current user in the school.
   * @property {SchoolYear[]} schoolYears Accessible school years of the school.
   */

  /**
   * Code of a school.
   * @typedef {String} SchoolCode
   */

  /**
   * A school year.
   * @typedef {Object} SchoolYear
   * @property {SchoolYearId} id Id of the school year.
   * @property {String} name Name of the school year.
   * @property {Interval<Date>} period Period of the school year.
   */

  /**
   * Id of a school year.
   * @typedef {*} SchoolYearId
   */

  /**
   * A team.
   * @typedef {Object} Team
   * @property {TeamId} id Id of the team.
   * @property {String} name Name of the team.
   * @property {PersonId[]} members The ids of members of the team.
   */

  /**
   * Id of a team.
   * @typedef {*} TeamId
   */

  /**
   * Identifies a week of year.
   * Uses "yyyy-Www" format, where "yyyy" is the year and "ww" is the week number
   * (e.g. "2020-W05" for 5th week of 2020).
   * @typedef {String} WeekId
   */

  /**
   * A timestamp value.
   * @typedef {*} Timestamp
   */

  /**
   * @class
   * @classdesc Stores all data used in playground duty widget.
   */
  function Store(vueSet) {
    /** @member {Object.<SchoolYearId, Area[]>} */
    this.areas = {};
    /** @member {Object.<AreaId, Object.<WeekId, AssignmentsOfBreakAndDate[]>>} */
    this.assignments = {};
    /** @member {Object.<AreaId, Break[]>} */
    this.breaks = {};
    /** @member {Object.<SchoolYearId, Department[]>} */
    this.departments = {};
    /** @member {Object.<SchoolYearId, Person[]>} */
    this.personnel = {};
    /** @member {Object.<SchoolYearId, Object.<WeekId, Object.<PersonId, Object.<PersonelType?, IntervalSum<DateMinuteTime>>>>>} */
    this.personnelAbsences = {};
    /** @member {Object.<AreaId, Object.<WeekId, PersonSubstitution[]>>} */
    this.personnelSubstitutions = {};
    /** @member {Object.<PersonnelType, PersonnelTypeDescription>} */
    this.personnelTypes = {};
    /** @member {Object.<SchoolYearId, Object.<WeekId, Object.<PersonId, Object.<PersonelType?, PlannedPersonAbsence[]>>>>} */
    this.plannedPersonnelAbsences = {};
    /** @member {School[]} */
    this.schools = [];
    /** @member {Object.<SchoolYearId, Team[]>} */
    this.teams = {};
    this._vueSet = vueSet;
  }
  Store.prototype = {
    /**
     * Removes all data from the store.
     */
    clear() {
      this.areas = {};
      this.assignments = {};
      this.breaks = {};
      this.departments = {};
      this.personnel = {};
      this.personnelAbsences = {};
      this.personnelSubstitutions = {};
      this.personnelTypes = {};
      this.plannedPersonnelAbsences = {};
      this.schools = [];
      this.teams = {};
    },
    /**
     * Removes assignments of an area, during specified weeks.
     * @param {AreaId} areaId If of assignments' area.
     * @param {Week[]} weeks Weeks of assignments to remove.
     */
    clearAssignments(areaId, weeks) {
      const assignmentsOfArea = this.assignments[areaId];
      if (!assignmentsOfArea) {
        return;
      }
      for (const week of weeks) {
        delete assignmentsOfArea[week];
      }
    },
    /**
     * Adds breaks for a school.
     * @param {AreaId} areaId Id of new break's area
     * @param {Break} break_ New break.
     */
    addBreak(areaId, break_) {
      var _this$breaks$areaId;
      const breaks = (_this$breaks$areaId = this.breaks[areaId]) !== null && _this$breaks$areaId !== void 0 ? _this$breaks$areaId : this._vueSet(this.breaks, areaId, []);
      breaks.splice(breaks.length, 0, break_);
    },
    /**
     * Adds schools.
     * @param {School[]} schools New schools.
     */
    addSchools(schools) {
      for (const school of schools) {
        const index = this.schools.findIndex(x => x.code === school.code);
        if (index !== -1) {
          this.schools.splice(index, 1, school);
        } else {
          this.schools.splice(this.schools.length, 0, school);
        }
      }
      this.schools.sort((a, b) => a.name.localeCompare(b.name));
    },
    /**
     * Deletes a break.
     * @param {BreakId} breakId Id of break to delete
     */
    deleteBreak(breakId) {
      for (const areaId of Object.keys(this.breaks)) {
        var _this$breaks$areaId$f, _this$breaks$areaId2;
        const index = (_this$breaks$areaId$f = (_this$breaks$areaId2 = this.breaks[areaId]) === null || _this$breaks$areaId2 === void 0 ? void 0 : _this$breaks$areaId2.findIndex(break_ => break_.id === breakId)) !== null && _this$breaks$areaId$f !== void 0 ? _this$breaks$areaId$f : -1;
        if (index !== -1) {
          this.breaks[areaId].splice(index, 1);
          break;
        }
      }
    },
    /**
     * Sets areas for a school year.
     * @param {SchoolYearId} schoolYearId Id of new areas' school year.
     * @param {Area[]} areas New areas.
     */
    setAreas(schoolYearId, areas) {
      const sortedAreas = [...areas];
      sortedAreas.sort((a, b) => a.name.localeCompare(b.name));
      this._vueSet(this.areas, schoolYearId, sortedAreas);
    },
    /**
     * Sets assignments for an area and week.
     * @param {AreaId} areaId The id of assignments' area.
     * @param {Week} week The assignments' week.
     * @param {Assignments[]} assignments New assignments.
     */
    setAssignments(areaId, week, assignments) {
      var _this$assignments$are;
      this._vueSet((_this$assignments$are = this.assignments[areaId]) !== null && _this$assignments$are !== void 0 ? _this$assignments$are : this._vueSet(this.assignments, areaId, {}), week, assignments);
    },
    /**
     * Sets breaks for an area.
     * @param {AreaId} areaId Id of new breaks' area.
     * @param {Break[]} breaks New breaks.
     */
    setBreaks(areaId, breaks) {
      this._vueSet(this.breaks, areaId, breaks);
    },
    /**
     * Sets departments of a school year.
     * @param {SchoolYearId} schoolYearId The id of departments' school year.
     * @param {Department[]} departments New departments.
     */
    setDepartments(schoolYearId, departments) {
      this._vueSet(this.departments, schoolYearId, departments);
    },
    /**
     * Sets personnel for a school year.
     * @param {SchoolYearId} schoolYearId Id of new personnel's school year.
     * @param {Person[]} personnel New personnel
     */
    setPersonnel(schoolYearId, personnel) {
      this._vueSet(this.personnel, schoolYearId, personnel);
    },
    /**
     * Sets personnel' absences for a school year.
     * @param {SchoolYearId} schoolYearId Id of the personnel's school year.
     * @param {Week} week Week which the absences should intersect.
     * @param {PersonAbsences[]} personnelAbsences Absent periods of the personnel.
     */
    setPersonnelAbsences(schoolYearId, week, personnelAbsences) {
      var _this$personnelAbsenc, _this$plannedPersonne;
      this._vueSet((_this$personnelAbsenc = this.personnelAbsences[schoolYearId]) !== null && _this$personnelAbsenc !== void 0 ? _this$personnelAbsenc : this._vueSet(this.personnelAbsences, schoolYearId, {}), week, personnelAbsences.reduce((result, newPersonAbsences) => {
        const existingPersonAbsences = result[newPersonAbsences.id];
        if (existingPersonAbsences) {
          var _newPersonAbsences$pe;
          existingPersonAbsences[(_newPersonAbsences$pe = newPersonAbsences.personnelType) !== null && _newPersonAbsences$pe !== void 0 ? _newPersonAbsences$pe : null] = newPersonAbsences.periods;
        } else {
          var _newPersonAbsences$pe2;
          const personAbsences = {};
          personAbsences[(_newPersonAbsences$pe2 = newPersonAbsences.personnelType) !== null && _newPersonAbsences$pe2 !== void 0 ? _newPersonAbsences$pe2 : null] = newPersonAbsences.periods;
          result[newPersonAbsences.id] = personAbsences;
        }
        return result;
      }, {}));
      this._vueSet((_this$plannedPersonne = this.plannedPersonnelAbsences[schoolYearId]) !== null && _this$plannedPersonne !== void 0 ? _this$plannedPersonne : this._vueSet(this.plannedPersonnelAbsences, schoolYearId, {}), week, personnelAbsences.reduce((result, newPersonAbsences) => {
        const existingPlannedPersonAbsences = result[newPersonAbsences.id];
        if (existingPlannedPersonAbsences) {
          var _newPersonAbsences$pe3;
          existingPlannedPersonAbsences[(_newPersonAbsences$pe3 = newPersonAbsences.personnelType) !== null && _newPersonAbsences$pe3 !== void 0 ? _newPersonAbsences$pe3 : null] = newPersonAbsences.planned;
        } else {
          var _newPersonAbsences$pe4;
          const plannedPersonAbsences = {};
          plannedPersonAbsences[(_newPersonAbsences$pe4 = newPersonAbsences.personnelType) !== null && _newPersonAbsences$pe4 !== void 0 ? _newPersonAbsences$pe4 : null] = newPersonAbsences.planned;
          result[newPersonAbsences.id] = plannedPersonAbsences;
        }
        return result;
      }, {}));
    },
    /**
     * Sets substitutions of personnel assigned in areas during a week.
     * @param {AreaId[]} areaIds Ids of the areas.
     * @param {Week} week The week the substitutions should intersect.
     * @param {PersonSubstitutionsOfArea[]} personnelSubstitutions Substitutions of the personnel.
     */
    setPersonnelSubstitutions(areaIds, week, personnelSubstitutions) {
      areaIds.forEach(areaId => {
        var _this$personnelSubsti, _personnelSubstitutio, _personnelSubstitutio2;
        return this._vueSet((_this$personnelSubsti = this.personnelSubstitutions[areaId]) !== null && _this$personnelSubsti !== void 0 ? _this$personnelSubsti : this._vueSet(this.personnelSubstitutions, areaId, {}), week, (_personnelSubstitutio = (_personnelSubstitutio2 = personnelSubstitutions.find(x => x.areaId === areaId)) === null || _personnelSubstitutio2 === void 0 ? void 0 : _personnelSubstitutio2.substitutions) !== null && _personnelSubstitutio !== void 0 ? _personnelSubstitutio : []);
      });
    },
    /**
     * Sets personnel types.
     * @param {PersonnelTypeDescription[]} personnelTypes New personnel types.
     */
    setPersonnelTypes(personnelTypes) {
      this.personnelTypes = personnelTypes.reduce((result, description) => {
        result[description.id] = {
          description: description.description,
          abbreviation: description.abbreviation
        };
        return result;
      }, {});
    },
    /**
     * Sets teams of a school year.
     * @param {SchoolYearId} schoolYearId The Id of teams' school year.
     * @param {Team[]} teams New teams.
     */
    setTeams(schoolYearId, teams) {
      this._vueSet(this.teams, schoolYearId, teams);
    },
    /**
     * Updates assignments of a break on a date.
     * @param {BreakId} breakId Id of the assignments' break.
     * @param {Date} date Date of the assignments.
     * @param {Assignment[]} primary Updated primary assignments.
     * @param {Assignment[]} backup Updated backup assignments.
     * @param {Timestamp} timestamp Updated timestamp of the assignments for the break and the date.
     */
    updateAssignments(breakId, date, primary, backup, timestamp) {
      const weekOfDate = Week.fromDate(date);
      const weekIdOfDate = weekOfDate.toString();
      const existingAssignments = Object.keys(this.assignments).map(areaId => {
        const assignmentsOfArea = this.assignments[areaId];
        return Object.keys(assignmentsOfArea).filter(weekId => weekId === weekIdOfDate).map(weekId => assignmentsOfArea[weekId].find(assignments => assignments.breakId === breakId && assignments.dayIndex === date.dayOfWeek)).find(assignments => assignments);
      }).find(assignments => assignments);
      if (existingAssignments) {
        existingAssignments.primary = primary.map(x => ({
          personId: x.personId,
          personnelType: x.personnelType
        }));
        existingAssignments.backup = backup.map(x => ({
          personId: x.personId,
          personnelType: x.personnelType
        }));
        existingAssignments.timestamp = timestamp;
      } else {
        var _this$assignments$are2, _assignmentsOfArea$we;
        const areaId = Object.keys(this.breaks).find(areaId => this.breaks[areaId].some(break_ => break_.id === breakId));
        const assignmentsOfArea = (_this$assignments$are2 = this.assignments[areaId]) !== null && _this$assignments$are2 !== void 0 ? _this$assignments$are2 : this._vueSet(this.assignments, areaId, {});
        const assignmentsOfWeek = (_assignmentsOfArea$we = assignmentsOfArea[weekIdOfDate]) !== null && _assignmentsOfArea$we !== void 0 ? _assignmentsOfArea$we : this._vueSet(assignmentsOfArea, weekIdOfDate, []);
        assignmentsOfWeek.push({
          breakId,
          dayIndex: date.dayOfWeek,
          primary: primary.map(x => ({
            personId: x.personId,
            personnelType: x.personnelType
          })),
          backup: backup.map(x => ({
            personId: x.personId,
            personnelType: x.personnelType
          })),
          timestamp
        });
      }
    },
    /**
     * Updates a break.
     * @param {BreakId} newBreak Id of updated break.
     */
    updateBreak(newBreak) {
      for (const areaId of Object.keys(this.breaks)) {
        var _this$breaks$areaId$f2, _this$breaks$areaId3;
        const index = (_this$breaks$areaId$f2 = (_this$breaks$areaId3 = this.breaks[areaId]) === null || _this$breaks$areaId3 === void 0 ? void 0 : _this$breaks$areaId3.findIndex(break_ => break_.id === newBreak.id)) !== null && _this$breaks$areaId$f2 !== void 0 ? _this$breaks$areaId$f2 : -1;
        if (index !== -1) {
          this.breaks[areaId].splice(index, 1, newBreak);
          break;
        }
      }
    }
  };
  _module_exports = {
    Store
  };
  return _module_exports;
})(_module_shared_week);

/**
 * MODULE playground_duty\settings.js
 **/
const _module_widget_settings = (function widget_settings(
  _module_shared_date,
  _module_shared_intervals,
  _module_shared_settings,
  _module_shared_week
) {
  let _module_exports = {};

  const Date = _module_shared_date;
  const intervals = _module_shared_intervals;
  const settings = _module_shared_settings;
  const Week = _module_shared_week;

  /**
   * @typedef {import('./store').AreaId} AreaId
   */

  /**
   * @constructor
   */
  function Settings(moment, settings) {
    this._moment = moment;
    this._settings = settings;
  }
  Settings.prototype = {
    get activeTab() {
      var _this$_settings;
      return (_this$_settings = this._settings) === null || _this$_settings === void 0 ? void 0 : _this$_settings.getLocal("activeTab");
    },
    set activeTab(value) {
      var _this$_settings2;
      (_this$_settings2 = this._settings) === null || _this$_settings2 === void 0 ? void 0 : _this$_settings2.setLocal("activeTab", value);
    },
    get breaksAreaId() {
      var _this$_settings3;
      return (_this$_settings3 = this._settings) === null || _this$_settings3 === void 0 ? void 0 : _this$_settings3.getLocal("breaksAreaId");
    },
    set breaksAreaId(value) {
      var _this$_settings4;
      (_this$_settings4 = this._settings) === null || _this$_settings4 === void 0 ? void 0 : _this$_settings4.setLocal("breaksAreaId", value);
    },
    get countsAreaIds() {
      var _this$_settings5;
      return (_this$_settings5 = this._settings) === null || _this$_settings5 === void 0 ? void 0 : _this$_settings5.getLocal("countsAreaIds");
    },
    set countsAreaIds(value) {
      var _this$_settings6;
      (_this$_settings6 = this._settings) === null || _this$_settings6 === void 0 ? void 0 : _this$_settings6.setLocal("countsAreaIds", value);
    },
    get countsPeriod() {
      var _this$_settings7, _this$_settings8;
      const start = (_this$_settings7 = this._settings) === null || _this$_settings7 === void 0 ? void 0 : _this$_settings7.getLocal("countsPeriodStart");
      const end = (_this$_settings8 = this._settings) === null || _this$_settings8 === void 0 ? void 0 : _this$_settings8.getLocal("countsPeriodEnd");
      if (!start || !end) {
        return null;
      }
      const startDate = Date.parsePoint(start, this._moment);
      const endDate = Date.parsePoint(end, this._moment);
      if (!startDate || !endDate || endDate.isBefore(startDate) || startDate.isSame(endDate)) {
        return null;
      }
      return intervals.nonEmpty(startDate, endDate);
    },
    set countsPeriod(value) {
      var _this$_settings9, _value$start, _this$_settings10, _value$end;
      (_this$_settings9 = this._settings) === null || _this$_settings9 === void 0 ? void 0 : _this$_settings9.setLocal("countsPeriodStart", value === null || value === void 0 ? void 0 : (_value$start = value.start) === null || _value$start === void 0 ? void 0 : _value$start.toJSON());
      (_this$_settings10 = this._settings) === null || _this$_settings10 === void 0 ? void 0 : _this$_settings10.setLocal("countsPeriodEnd", value === null || value === void 0 ? void 0 : (_value$end = value.end) === null || _value$end === void 0 ? void 0 : _value$end.toJSON());
    },
    get planAreaIds() {
      var _this$_settings11;
      return (_this$_settings11 = this._settings) === null || _this$_settings11 === void 0 ? void 0 : _this$_settings11.getPersistent("planAreaIds");
    },
    set planAreaIds(value) {
      var _this$_settings12;
      (_this$_settings12 = this._settings) === null || _this$_settings12 === void 0 ? void 0 : _this$_settings12.setPersistent("planAreaIds", value);
    },
    get planDayOfWeek() {
      var _this$_settings13;
      return (_this$_settings13 = this._settings) === null || _this$_settings13 === void 0 ? void 0 : _this$_settings13.getLocal("planDayOfWeek");
    },
    set planDayOfWeek(value) {
      var _this$_settings14;
      (_this$_settings14 = this._settings) === null || _this$_settings14 === void 0 ? void 0 : _this$_settings14.setLocal("planDayOfWeek", value);
    },
    get planAssignmentEnabled() {
      var _this$_settings15;
      return (_this$_settings15 = this._settings) === null || _this$_settings15 === void 0 ? void 0 : _this$_settings15.getLocal("planAssignmentEnabled");
    },
    set planAssignmentEnabled(value) {
      var _this$_settings16;
      (_this$_settings16 = this._settings) === null || _this$_settings16 === void 0 ? void 0 : _this$_settings16.setLocal("planAssignmentEnabled", value);
    },
    get planWeek() {
      var _this$_settings17;
      const week = (_this$_settings17 = this._settings) === null || _this$_settings17 === void 0 ? void 0 : _this$_settings17.getLocal("planWeek");
      return week ? Week.parsePoint(week, this._moment) : undefined;
    },
    set planWeek(value) {
      var _this$_settings18;
      (_this$_settings18 = this._settings) === null || _this$_settings18 === void 0 ? void 0 : _this$_settings18.setLocal("planWeek", value === null || value === void 0 ? void 0 : value.toString());
    },
    get schoolCode() {
      var _this$_settings19;
      return (_this$_settings19 = this._settings) === null || _this$_settings19 === void 0 ? void 0 : _this$_settings19.getLocal("schoolCode");
    },
    set schoolCode(value) {
      var _this$_settings20;
      (_this$_settings20 = this._settings) === null || _this$_settings20 === void 0 ? void 0 : _this$_settings20.setLocal("schoolCode", value);
    },
    get schoolYearId() {
      var _this$_settings21;
      return (_this$_settings21 = this._settings) === null || _this$_settings21 === void 0 ? void 0 : _this$_settings21.getLocal("schoolYearId");
    },
    set schoolYearId(value) {
      var _this$_settings22;
      (_this$_settings22 = this._settings) === null || _this$_settings22 === void 0 ? void 0 : _this$_settings22.setLocal("schoolYearId", value);
    },
    /**
     * @param {AreaId} areaId Id of the area.
     */
    ofArea(areaId) {
      return new AreaSettings(areaId, this._settings);
    }
  };
  Settings.load = function (axios, token, moment, sessionUUID, isNoticeBoard, institutionCode) {
    return token().then(aulaToken => settings(axios, aulaToken, sessionUUID, isNoticeBoard, institutionCode, "playground-duty")).catch(() => null).then(settings => new Settings(moment, settings));
  };

  /** @constructor */
  function AreaSettings(areaId, settings) {
    this._areaId = areaId;
    this._nameSuffix = "_".concat(areaId);
    this._settings = settings;
  }
  AreaSettings.prototype = {
    get planAssignmentSelectedDepartments() {
      var _this$_settings23;
      return this._isAvailable ? (_this$_settings23 = this._settings) === null || _this$_settings23 === void 0 ? void 0 : _this$_settings23.getLocal(this._settingName("planAssignmentSelectedDepartments")) : undefined;
    },
    set planAssignmentSelectedDepartments(departmentIds) {
      if (this._isAvailable) {
        var _this$_settings24;
        (_this$_settings24 = this._settings) === null || _this$_settings24 === void 0 ? void 0 : _this$_settings24.setLocal(this._settingName("planAssignmentSelectedDepartments"), departmentIds);
      }
    },
    get planAssignmentSelectedTeams() {
      var _this$_settings25;
      return this._isAvailable ? (_this$_settings25 = this._settings) === null || _this$_settings25 === void 0 ? void 0 : _this$_settings25.getLocal(this._settingName("planAssignmentSelectedTeams")) : undefined;
    },
    set planAssignmentSelectedTeams(teamIds) {
      if (this._isAvailable) {
        var _this$_settings26;
        (_this$_settings26 = this._settings) === null || _this$_settings26 === void 0 ? void 0 : _this$_settings26.setLocal(this._settingName("planAssignmentSelectedTeams"), teamIds);
      }
    },
    get _isAvailable() {
      return !!this._areaId;
    },
    _settingName(prefix) {
      return prefix + this._nameSuffix;
    }
  };
  _module_exports = {
    Settings
  };
  return _module_exports;
})(_module_shared_date,
  _module_shared_intervals,
  _module_shared_settings,
  _module_shared_week);

/**
 * MODULE playground_duty\plannedAssignments.js
 **/
const _module_widget_plannedAssignments = (function widget_plannedAssignments(
  _module_shared_week,
  _module_widget_personnel
) {
  let _module_exports = {};

  const {
    fullNameAndEmployment
  } = _module_widget_personnel;
  const Week = _module_shared_week;

  /** @constructor */
  function PlannedAssignments(assignments, store) {
    this._assignments = assignments;
    this._areaId = null;
    this._breakId = null;
    this._date = null;
    this._schoolYearId = null;
    this._store = store;
  }
  PlannedAssignments.prototype = {
    get items() {
      const personnel = this._personnel;
      const personnelTypes = this._personnelTypes;
      const substitutedPersonnel = this._substitutedPersonnel;
      const primaryPersonnel = this._assignments.primary.map(assignment => plannedPerson(assignment, this._assignments, personnel, substitutedPersonnel, personnelTypes, null));
      primaryPersonnel.sort((a, b) => a.fullNameAndEmployment.localeCompare(b.fullNameAndEmployment));
      const backupPersonnel = this._assignments.backup.map((assignment, index) => plannedPerson(assignment, this._assignments, personnel, substitutedPersonnel, personnelTypes, index + 1));
      return primaryPersonnel.concat(backupPersonnel);
      function plannedPerson(assignment, assignments, personnel, substitutedPersonnel, personnelTypes, backupPriority) {
        const person = personnel.find(person => person.id === assignment.personId);
        const isPersonSubstituted = substitutedPersonnel.includes(assignment.personId);
        const personnelType = personnelTypes[assignment.personnelType];
        return {
          assignment: backupPriority !== null ? "Bagvagt prio ".concat(backupPriority) : "Forvagt",
          fullNameAndEmployment: fullNameAndEmployment(person, personnelType),
          id: assignment.personId,
          isSubstituted: isPersonSubstituted,
          key: assignment.personId,
          actions() {
            return [{
              get enabled() {
                return assignments.canToggleBackup(assignment.personId, isPersonSubstituted);
              },
              get icon() {
                return backupPriority !== null ? "fa-play-circle" : "fa-pause-circle";
              },
              get label() {
                return backupPriority !== null ? "Flyt til forvagt" : "Flyt til bagvagt";
              },
              call() {
                assignments.toggleBackup(assignment.personId);
              }
            }, {
              get enabled() {
                return assignments.canMoveDown(assignment.personId);
              },
              icon: "fa-chevron-circle-down",
              label: "Flyt ned",
              call() {
                assignments.moveDown(assignment.personId);
              }
            }, {
              get enabled() {
                return assignments.canMoveUp(assignment.personId);
              },
              icon: "fa-chevron-circle-up",
              label: "Flyt op",
              call() {
                assignments.moveUp(assignment.personId);
              }
            }, {
              get enabled() {
                return assignments.canDelete(isPersonSubstituted);
              },
              icon: "fa-trash",
              label: "Slet",
              call() {
                assignments.delete(assignment.personId);
              }
            }];
          }
        };
      }
    },
    get fields() {
      return [{
        key: "fullNameAndEmployment",
        class: "no-text-overflow",
        label: "Fulde navn og beskæftigelse"
      }, {
        key: "assignment",
        label: "Opgave"
      }, {
        key: "actions",
        label: ""
      }];
    },
    update(schoolYearId, areaId, breakId, date) {
      this._areaId = areaId;
      this._breakId = breakId;
      this._date = date;
      this._schoolYearId = schoolYearId;
    },
    get _personnel() {
      var _this$_store$personne;
      return (_this$_store$personne = this._store.personnel[this._schoolYearId]) !== null && _this$_store$personne !== void 0 ? _this$_store$personne : [];
    },
    get _substitutedPersonnel() {
      var _this$_store$personne2, _this$_store$personne3;
      const substitutions = (_this$_store$personne2 = (_this$_store$personne3 = this._store.personnelSubstitutions[this._areaId]) === null || _this$_store$personne3 === void 0 ? void 0 : _this$_store$personne3[Week.fromDate(this._date)]) !== null && _this$_store$personne2 !== void 0 ? _this$_store$personne2 : [];
      return substitutions.filter(substitution => substitution.breakId === this._breakId && substitution.date.isSame(this._date)).map(substitution => substitution.personId);
    },
    get _personnelTypes() {
      return this._store.personnelTypes;
    }
  };
  _module_exports = {
    PlannedAssignments
  };
  return _module_exports;
})(_module_shared_week,
  _module_widget_personnel);

/**
 * MODULE playground_duty\actualAssignments.js
 **/
const _module_widget_actualAssignments = (function widget_actualAssignments(
  _module_shared_dateMinuteTime,
  _module_shared_intervals,
  _module_shared_week,
  _module_widget_assignments,
  _module_widget_personnel
) {
  let _module_exports = {};

  const dateMinuteTime = _module_shared_dateMinuteTime;
  const intervals = _module_shared_intervals;
  const Week = _module_shared_week;
  const {
    assignmentStatuses
  } = _module_widget_assignments;
  const {
    fullNameAndEmployment
  } = _module_widget_personnel;

  /** @constructor */
  function ActualAssignments(actions, assignments, store) {
    this._actions = actions;
    this._assignments = assignments;
    this._areaId = null;
    this._breakId = null;
    this._date = null;
    this._schoolYearId = null;
    this._store = store;
  }
  ActualAssignments.prototype = {
    get fields() {
      return [{
        key: "fullNameAndEmployment",
        class: "no-text-overflow",
        label: "Fulde navn og beskæftigelse"
      }, {
        key: "actualAssignment",
        label: "Faktisk opgave"
      }];
    },
    get items() {
      if (this._areaId === null || this._breakId === null || this._breakPeriod === null || this._date === null || this._schoolYearId === null) {
        return [];
      }
      const breakPeriod = intervals.sum([this._breakPeriod.map(x => dateMinuteTime.fromDateAndTime(this._date, x))]);
      const {
        primary: primaryStatuses,
        backup: backupStatuses
      } = assignmentStatuses(this._assignments, breakPeriod, this._personnel, this._personnelAbsences, this._substitutedPersonnel);
      const primaryPersonnel = this._assignments.primary.map(assignment => primaryStatuses.get(assignment.personId).match({
        replaced: backupAssignment => this._backupAsPrimaryPerson(backupAssignment.personId, backupAssignment.personnelType, backupAssignment.priority),
        substituted: () => this._primaryPerson(assignment.personId, assignment.personnelType, true),
        unavailable: () => ({
          skip: true
        }),
        valid: () => this._primaryPerson(assignment.personId, assignment.personnelType, false)
      })).filter(person => !person.skip).sort((a, b) => a.fullNameAndEmployment.localeCompare(b.fullNameAndEmployment));
      const absentPrimaryPersonnel = this._assignments.primary.map(assignment => {
        var _primaryStatuses$get$, _primaryStatuses$get;
        return (_primaryStatuses$get$ = (_primaryStatuses$get = primaryStatuses.get(assignment.personId)) === null || _primaryStatuses$get === void 0 ? void 0 : _primaryStatuses$get.match({
          replaced: () => this._absentPerson(assignment.personId, assignment.personnelType, null),
          substituted: () => ({
            skip: true
          }),
          unavailable: () => this._absentPerson(assignment.personId, assignment.personnelType, null),
          valid: () => ({
            skip: true
          })
        })) !== null && _primaryStatuses$get$ !== void 0 ? _primaryStatuses$get$ : this._unknownPerson(assignment.personId, assignment.personnelType);
      }).filter(person => !person.skip).sort((a, b) => a.fullNameAndEmployment.localeCompare(b.fullNameAndEmployment));
      const backupPersonnel = this._assignments.backup.map((assignment, index) => {
        var _backupStatuses$get$m, _backupStatuses$get;
        return (_backupStatuses$get$m = (_backupStatuses$get = backupStatuses.get(assignment.personId)) === null || _backupStatuses$get === void 0 ? void 0 : _backupStatuses$get.match({
          substituted: () => this._backupPerson(assignment.personId, assignment.personnelType, index + 1, true),
          used: () => ({
            skip: true
          }),
          unavailable: () => this._absentPerson(assignment.personId, assignment.personnelType, index + 1),
          unused: () => this._backupPerson(assignment.personId, assignment.personnelType, index + 1, false)
        })) !== null && _backupStatuses$get$m !== void 0 ? _backupStatuses$get$m : this._unknownPerson(assignment.personId, assignment.personnelType);
      }).filter(person => !person.skip);
      return [...primaryPersonnel, ...absentPrimaryPersonnel, ...backupPersonnel];
    },
    get _breakPeriod() {
      var _this$_store$breaks$t;
      const break_ = (_this$_store$breaks$t = this._store.breaks[this._areaId]) === null || _this$_store$breaks$t === void 0 ? void 0 : _this$_store$breaks$t.find(x => x.id === this._breakId);
      return break_ != null ? intervals.nonEmpty(break_.start, break_.end) : null;
    },
    get _personnel() {
      var _this$_store$personne;
      return (_this$_store$personne = this._store.personnel[this._schoolYearId]) !== null && _this$_store$personne !== void 0 ? _this$_store$personne : [];
    },
    get _personnelAbsences() {
      var _this$_store$personne2;
      return (_this$_store$personne2 = this._store.personnelAbsences[this._schoolYearId]) === null || _this$_store$personne2 === void 0 ? void 0 : _this$_store$personne2[Week.fromDate(this._date)];
    },
    get _substitutedPersonnel() {
      var _this$_store$personne3, _this$_store$personne4;
      const substitutions = (_this$_store$personne3 = (_this$_store$personne4 = this._store.personnelSubstitutions[this._areaId]) === null || _this$_store$personne4 === void 0 ? void 0 : _this$_store$personne4[Week.fromDate(this._date)]) !== null && _this$_store$personne3 !== void 0 ? _this$_store$personne3 : [];
      return substitutions.filter(substitution => substitution.breakId === this._breakId && substitution.date.isSame(this._date)).map(substitution => substitution.personId);
    },
    update(areaId, breakId, date, schoolYearId) {
      this._areaId = areaId;
      this._breakId = breakId;
      this._date = date;
      this._schoolYearId = schoolYearId;
    },
    _absentPerson(personId, personnelType, assignmentPriority) {
      return {
        fullNameAndEmployment: this._personFullNameAndEmployment(personId, personnelType),
        key: personId,
        actualAssignment: "Frav\xE6rende (var ".concat(assignmentPriority ? "bagvagt prio ".concat(assignmentPriority) : "forvagt", ")"),
        isSubstituted: false
      };
    },
    _backupAsPrimaryPerson(personId, personnelType, assignmentPriority) {
      return {
        fullNameAndEmployment: this._personFullNameAndEmployment(personId, personnelType),
        key: personId,
        actualAssignment: "Forvagt (var bagvagt prio ".concat(assignmentPriority, ")"),
        isSubstituted: false
      };
    },
    _backupPerson(personId, personnelType, assignmentPriority, isSubstituted) {
      return {
        fullNameAndEmployment: this._personFullNameAndEmployment(personId, personnelType),
        key: personId,
        actualAssignment: "Bagvagt prio ".concat(assignmentPriority),
        isSubstituted
      };
    },
    _personFullNameAndEmployment(personId, personnelTypeId) {
      var _this$_store$personne5;
      const person = (_this$_store$personne5 = this._store.personnel[this._schoolYearId]) === null || _this$_store$personne5 === void 0 ? void 0 : _this$_store$personne5.find(person => person.id === personId);
      const personnelType = this._store.personnelTypes[personnelTypeId];
      return fullNameAndEmployment(person, personnelType);
    },
    _primaryPerson(personId, personnelType, isSubstituted) {
      return {
        fullNameAndEmployment: this._personFullNameAndEmployment(personId, personnelType),
        key: personId,
        actualAssignment: "Forvagt",
        isSubstituted
      };
    },
    _unknownPerson(personId, personnelType) {
      return {
        fullNameAndEmployment: this._personFullNameAndEmployment(personId, personnelType),
        key: personId,
        actualAssignment: "???",
        isSubstituted: false
      };
    }
  };
  _module_exports = {
    ActualAssignments
  };
  return _module_exports;
})(_module_shared_dateMinuteTime,
  _module_shared_intervals,
  _module_shared_week,
  _module_widget_assignments,
  _module_widget_personnel);

/**
 * MODULE playground_duty\assignmentsDetails.js
 **/
const _module_widget_assignmentsDetails = (function widget_assignmentsDetails(
  _module_shared_dateMinuteTime,
  _module_shared_errorList,
  _module_shared_intervals,
  _module_shared_prelude,
  _module_shared_progressList,
  _module_widget_actualAssignments,
  _module_widget_editableAssignments,
  _module_widget_incrementalSearch,
  _module_widget_personnel,
  _module_widget_plannedAssignments
) {
  let _module_exports = {};

  const dateMinuteTime = _module_shared_dateMinuteTime;
  const {
    ErrorList,
    actionTemplate,
    retryActionTemplate
  } = _module_shared_errorList;
  const intervals = _module_shared_intervals;
  const {
    canceled
  } = _module_shared_prelude;
  const {
    ProgressList
  } = _module_shared_progressList;
  const {
    ActualAssignments
  } = _module_widget_actualAssignments;
  const {
    EditableAssignments
  } = _module_widget_editableAssignments;
  const {
    IncrementalSearch
  } = _module_widget_incrementalSearch;
  const {
    fullNameAndEmployment
  } = _module_widget_personnel;
  const {
    PlannedAssignments
  } = _module_widget_plannedAssignments;
  function AssignmentsDetails(actions, modalRef, store) {
    const assignments = new EditableAssignments();
    this.actualAssignments = new ActualAssignments(actions, assignments, store);
    this.areaIdEditable = false;
    this.errors = new ErrorList();
    this.plannedAssignments = new PlannedAssignments(assignments, store);
    this.progress = new ProgressList();
    this._actions = actions;
    this._assignments = assignments;
    this._breakId = undefined;
    this._dayIndex = undefined;
    this._modalRef = modalRef;
    this._personnelSearch = {
      primary: new IncrementalSearch(),
      backup: new IncrementalSearch()
    };
    this._schoolYearId = undefined;
    this._store = store;
    this._week = undefined;
  }
  AssignmentsDetails.prototype = {
    get assignablePersonnel() {
      return [dutyGroup(false, this._assignablePersonnel({
        assignAsBackup: false
      }), this._personnelSearch.primary), dutyGroup(true, this._assignablePersonnel({
        assignAsBackup: true
      }), this._personnelSearch.backup)];
      function dutyGroup(assignAsBackup, assignablePersonnel, search) {
        return {
          key: assignAsBackup ? "backup" : "primary",
          label: "P\xE5s\xE6t person som ".concat(assignAsBackup ? "bagvagt" : "forvagt"),
          id: "kmd-educa-personale-playground-duty-plans-edition-assign-as-".concat(assignAsBackup ? "backup" : "primary", "-dropdown"),
          groups: [personGroup("unassigned", assignablePersonnel.unassigned, assignAsBackup), personGroup("already-assigned-personnel-type", assignablePersonnel.alreadyAssignedWithDifferentPersonnelType, assignAsBackup), personGroup("already-assigned-assignment-type", assignablePersonnel.alreadyAssignedWithOppositeAssignmentType, assignAsBackup), personGroup("planned-absent", assignablePersonnel.plannedAbsent, assignAsBackup), personGroup("not-reassignable-due-to-substitution", assignablePersonnel.notReassignableDueToSubstitution, assignAsBackup)],
          search,
          searchInputId: "kmd-educa-personale-playground-duty-plans-edition-".concat(assignAsBackup ? "backup" : "primary", "-personnel-search-input")
        };
      }
      function personGroup(key, items, assignAsBackup) {
        return {
          id: "kmd-educa-personale-playground-duty-plans-edition-assign-as-primary-".concat(key, "-group"),
          items,
          key,
          label: label()
        };
        function label() {
          switch (key) {
            case "unassigned":
              return "Ledig personale";
            case "already-assigned-personnel-type":
              return "Personale allerede p\xE5sat som ".concat(assignAsBackup ? "bagvagt" : "forvagt", " men med anden personale type");
            case "already-assigned-assignment-type":
              return "Personale allerede p\xE5sat som ".concat(assignAsBackup ? "forvagt" : "bagvagt");
            case "planned-absent":
              return "Fraværende personale";
            case "not-reassignable-due-to-substitution":
              return "Personer der ikke kan påsættes, da de er vikardækket";
          }
        }
      }
    },
    get breakAndDate() {
      const date = this._date;
      const breakName = this._breakName;
      return date && breakName ? "".concat(breakName, " \u2014 ").concat(date.format("dayofweek"), " ").concat(date) : "";
    },
    get _date() {
      var _this$_week;
      return (_this$_week = this._week) === null || _this$_week === void 0 ? void 0 : _this$_week.toDate(this._dayIndex);
    },
    get _break() {
      var _this$_store$breaks$t;
      if (this._breakId === undefined) {
        return null;
      }
      return (_this$_store$breaks$t = this._store.breaks[this._areaId]) === null || _this$_store$breaks$t === void 0 ? void 0 : _this$_store$breaks$t.find(x => x.id === this._breakId);
    },
    get _breakName() {
      var _this$_break;
      return (_this$_break = this._break) === null || _this$_break === void 0 ? void 0 : _this$_break.name;
    },
    get _breakPeriod() {
      const break_ = this._break;
      const date = this._date;
      if (!date || !(break_ !== null && break_ !== void 0 && break_.start) || !(break_ !== null && break_ !== void 0 && break_.end)) {
        return intervals.empty();
      }
      return intervals.nonEmpty(dateMinuteTime.fromDateAndTime(date, break_.start), dateMinuteTime.fromDateAndTime(date, break_.end));
    },
    get _originalAssignments() {
      if (this._breakId === undefined || this._dayIndex === undefined || !this._week) {
        return null;
      }
      for (const areaId of Object.keys(this._store.assignments)) {
        var _this$_store$assignme;
        const assignments = (_this$_store$assignme = this._store.assignments[areaId][this._week]) === null || _this$_store$assignme === void 0 ? void 0 : _this$_store$assignme.find(x => x.breakId === this._breakId && x.dayIndex === this._dayIndex);
        if (assignments) {
          return assignments;
        }
      }
      return null;
    },
    get _personnel() {
      var _this$_store$personne;
      return (_this$_store$personne = this._store.personnel[this._schoolYearId]) !== null && _this$_store$personne !== void 0 ? _this$_store$personne : [];
    },
    get _personnelTypes() {
      return this._store.personnelTypes;
    },
    get _plannedPersonAbsences() {
      var _this$_store$plannedP, _this$_store$plannedP2;
      return (_this$_store$plannedP = (_this$_store$plannedP2 = this._store.plannedPersonnelAbsences[this._schoolYearId]) === null || _this$_store$plannedP2 === void 0 ? void 0 : _this$_store$plannedP2[this._week]) !== null && _this$_store$plannedP !== void 0 ? _this$_store$plannedP : {};
    },
    get _substitutedPersonnel() {
      var _this$_store$personne2, _this$_store$personne3;
      const substitutions = (_this$_store$personne2 = (_this$_store$personne3 = this._store.personnelSubstitutions[this._areaId]) === null || _this$_store$personne3 === void 0 ? void 0 : _this$_store$personne3[this._week]) !== null && _this$_store$personne2 !== void 0 ? _this$_store$personne2 : [];
      return substitutions.filter(substitution => substitution.breakId === this._breakId && substitution.date.isSame(this._date)).map(substitution => substitution.personId);
    },
    show(areaId, areaIsEditable, breakId, dayIndex, schoolYearId, week) {
      var _assignments$primary, _assignments$backup;
      this._areaId = areaId;
      this.areaIsEditable = areaIsEditable;
      this._breakId = breakId;
      this._dayIndex = dayIndex;
      this._schoolYearId = schoolYearId;
      this._week = week;
      const assignments = this._originalAssignments;
      this._assignments.update((_assignments$primary = assignments === null || assignments === void 0 ? void 0 : assignments.primary) !== null && _assignments$primary !== void 0 ? _assignments$primary : [], (_assignments$backup = assignments === null || assignments === void 0 ? void 0 : assignments.backup) !== null && _assignments$backup !== void 0 ? _assignments$backup : []);
      this.actualAssignments.update(areaId, breakId, this._date, schoolYearId);
      this.errors.clear();
      this.plannedAssignments.update(schoolYearId, areaId, breakId, this._date);
      return new Promise((resolve, reject) => {
        const onHide = e => {
          if (e.trigger === "ok" && areaIsEditable) {
            e.preventDefault();
            this._save().then(() => {
              return this._modalRef().hide("saved");
            });
          } else {
            if (e.trigger === "saved") {
              resolve();
            } else {
              reject(canceled());
            }
            this._modalRef().$off("hide", onHide);
            this.areaIdEditable = false;
            this._breakId = null;
            this._dayIndex = null;
            this._schoolYearId = null;
            this._week = null;
            this._assignments.update([], []);
          }
        };
        this._modalRef().$on("hide", onHide);
        this._modalRef().show();
      });
    },
    _assignablePersonnel({
                           assignAsBackup
                         }) {
      const date = this._date;
      const substitutedPersonnel = this._substitutedPersonnel;
      const personnelTypes = this._personnelTypes;
      const assignmentsOfOppositeType = assignAsBackup ? this._assignments.primary : this._assignments.backup;
      const assignmentsOfAssignableType = assignAsBackup ? this._assignments.backup : this._assignments.primary;
      const groupIds = {
        unassigned: 0,
        alreadyAssignedWithDifferentPersonnelType: 1,
        alreadyAssignedWithOppositeAssignmentType: 2,
        plannedAbsent: 3,
        notReassignableDueToSubstitution: 4
      };
      const plannedPersonAbsences = this._plannedPersonAbsences;
      const breakPeriod = this._breakPeriod;
      const assignablePersonnel = this._personnel.map(person => {
        var _plannedPersonAbsence, _plannedPersonAbsence2, _plannedPersonAbsence3;
        const absencesOfPerson = (_plannedPersonAbsence = (_plannedPersonAbsence2 = plannedPersonAbsences[person.id]) === null || _plannedPersonAbsence2 === void 0 ? void 0 : (_plannedPersonAbsence3 = _plannedPersonAbsence2[null]) === null || _plannedPersonAbsence3 === void 0 ? void 0 : _plannedPersonAbsence3.filter(x => x.period.intersectsWith(breakPeriod))) !== null && _plannedPersonAbsence !== void 0 ? _plannedPersonAbsence : [];
        return person.employments.filter(employment => !assignmentsOfAssignableType.some(assignment => person.id === assignment.personId && employment.personnelType === assignment.personnelType) && employment.period.contains(date)).map(employment => {
          var _plannedPersonAbsence4, _plannedPersonAbsence5, _plannedPersonAbsence6;
          const absencesOfPersonWithType = (_plannedPersonAbsence4 = (_plannedPersonAbsence5 = plannedPersonAbsences[person.id]) === null || _plannedPersonAbsence5 === void 0 ? void 0 : (_plannedPersonAbsence6 = _plannedPersonAbsence5[employment.personnelType]) === null || _plannedPersonAbsence6 === void 0 ? void 0 : _plannedPersonAbsence6.filter(x => x.period.intersectsWith(breakPeriod))) !== null && _plannedPersonAbsence4 !== void 0 ? _plannedPersonAbsence4 : [];
          const plannedAbsences = absencesOfPerson.concat(absencesOfPersonWithType).map(x => x.description);
          plannedAbsences.sort((a, b) => a.localeCompare(b));
          const isAbsent = plannedAbsences.length > 0;
          return {
            fullNameAndEmployment: fullNameAndEmployment(person, personnelTypes[employment.personnelType]),
            groupId: assignmentsOfAssignableType.some(assignment => assignment.personId === person.id && assignment.personnelType !== employment.personnelType) ? isAbsent ? groupIds.plannedAbsent : groupIds.alreadyAssignedWithDifferentPersonnelType : assignmentsOfOppositeType.some(assignment => assignment.personId === person.id) ? this._assignments.canToggleBackup(person.id, substitutedPersonnel.includes(person.id)) ? isAbsent ? groupIds.plannedAbsent : groupIds.alreadyAssignedWithOppositeAssignmentType : groupIds.notReassignableDueToSubstitution : isAbsent ? groupIds.plannedAbsent : groupIds.unassigned,
            personWithType: {
              id: person.id,
              personnelType: employment.personnelType
            },
            plannedAbsences
          };
        });
      }).reduce((result, items) => {
        items.map(item => result[item.groupId].push(item));
        return result;
      }, [[], [], [], [], []]).map(items => items.sort((a, b) => a.fullNameAndEmployment.localeCompare(b.fullNameAndEmployment)).map(availablePerson => ({
        key: "".concat(availablePerson.personWithType.id, "-").concat(availablePerson.personWithType.personnelType),
        disabled: availablePerson.groupId === groupIds.notReassignableDueToSubstitution,
        label: availablePerson.plannedAbsences.length > 0 ? "".concat(availablePerson.fullNameAndEmployment, " \u2014 ").concat(availablePerson.plannedAbsences.join(", ")) : availablePerson.fullNameAndEmployment,
        call: () => {
          if (assignAsBackup) {
            this._assignments.assignPersonAsBackup(availablePerson.personWithType, null);
          } else {
            this._assignments.assignPersonAsPrimary(availablePerson.personWithType);
          }
        }
      })));
      return {
        unassigned: assignablePersonnel[groupIds.unassigned],
        alreadyAssignedWithDifferentPersonnelType: assignablePersonnel[groupIds.alreadyAssignedWithDifferentPersonnelType],
        alreadyAssignedWithOppositeAssignmentType: assignablePersonnel[groupIds.alreadyAssignedWithOppositeAssignmentType],
        plannedAbsent: assignablePersonnel[groupIds.plannedAbsent],
        notReassignableDueToSubstitution: assignablePersonnel[groupIds.notReassignableDueToSubstitution]
      };
    },
    _save() {
      return this.errors.track({
        message: "Kunne ikke gemme vagter",
        actions: [actionTemplate("Genindlæs", () => {
          this.errors.track({
            message: "Kunne ikke indlæse vagter",
            actions: [retryActionTemplate()]
          }, () => this.progress.track("Indlæser vagter...", () => this._actions.reloadAssignments(this._areaId, this._week).then(() => {
            var _assignments$primary2, _assignments$backup2;
            const assignments = this._originalAssignments;
            this._assignments.update((_assignments$primary2 = assignments === null || assignments === void 0 ? void 0 : assignments.primary) !== null && _assignments$primary2 !== void 0 ? _assignments$primary2 : [], (_assignments$backup2 = assignments === null || assignments === void 0 ? void 0 : assignments.backup) !== null && _assignments$backup2 !== void 0 ? _assignments$backup2 : []);
          })));
        }, error => error.status == 409), retryActionTemplate(null, error => error.status != 409)]
      }, () => this.progress.track("Gemmer...", () => {
        var _this$_originalAssign;
        return this._actions.saveAssignments({
          breakId: this._breakId,
          date: this._date,
          primary: this._assignments.primary.map(assignment => ({
            personId: assignment.personId,
            personnelType: assignment.personnelType
          })),
          backup: this._assignments.backup.map(assignment => ({
            personId: assignment.personId,
            personnelType: assignment.personnelType
          })),
          timestamp: (_this$_originalAssign = this._originalAssignments) === null || _this$_originalAssign === void 0 ? void 0 : _this$_originalAssign.timestamp
        });
      }));
    }
  };
  _module_exports = {
    AssignmentsDetails
  };
  return _module_exports;
})(_module_shared_dateMinuteTime,
  _module_shared_errorList,
  _module_shared_intervals,
  _module_shared_prelude,
  _module_shared_progressList,
  _module_widget_actualAssignments,
  _module_widget_editableAssignments,
  _module_widget_incrementalSearch,
  _module_widget_personnel,
  _module_widget_plannedAssignments);

/**
 * MODULE playground_duty\actions.js
 **/
const _module_widget_actions = (function widget_actions(
  _module_shared_functions,
  _module_shared_prelude,
  _module_shared_week
) {
  let _module_exports = {};

  const functions = _module_shared_functions;
  const {
    areArraysEqual,
    areSetsEqual
  } = _module_shared_prelude;
  const Week = _module_shared_week;

  /** @typedef {import('../shared/time').MinuteTime} MinuteTime */
  /** @typedef {import('./api').AssignmentsToSave AssignmentsToSave */
  /** @typedef {import('./store').AreaId AreaId */
  /** @typedef {import('./store').Assignment Assignment */
  /** @typedef {import('./store').BreakId BreakId */
  /** @typedef {import('./store').SchoolCode SchoolCode */
  /** @typedef {import('./store').SchoolYearId SchoolYearId */
  /** @typedef {import('./store').Timestamp Timestamp */

  /**
   * The result of copy of assignments.
   * @typedef {Object} CopyAssignmentsResult
   * @property {Week[]} failedWeeks An array of weeks to which copying failed.
   * Assignments during these week are left unchanged.
   * Does never contain the source week.
   * @property {Week[]} successfulWeeks An array of weeks to which copying succeeded.
   * Does never contain the source week.
   */

  function Actions(api, store) {
    this._api = api;
    this._store = store;
  }
  Actions.prototype = {
    /**
     * Adds new break.
     * @param {AreaId} areaId Id of new break's area.
     * @param {string} name Name of new break.
     * @param {MinuteTime} start Start of new break.
     * @param {MinuteTime} end End of new break.
     * @returns {Promise}
     */
    addBreak(areaId, name, start, end) {
      return this._api.addBreak({
        areaId,
        name,
        start,
        end
      }).then(({
                 id,
                 timestamp
               }) => this._store.addBreak(areaId, {
        id,
        name,
        start,
        end,
        timestamp
      }));
    },
    /**
     * Copies assignments of an area in a source week to target weeks,
     * overwriting assignments already existing during the target weeks.
     * If target weeks contain source week, assignments of the source week are not overwritten.
     * @param {AreaId} areaId Id of the assignments' area.
     * @param {Week} sourceWeek The week of the assignments.
     * @param {Week[]} targetWeeks The weeks to which assignments are to be copied.
     * @returns {Promise<CopyAssignmentsResult>}
     */
    copyAssignments(areaId, sourceWeek, targetWeeks) {
      if (targetWeeks.length === 0 || targetWeeks.length === 1 && targetWeeks[0].isSame(sourceWeek)) {
        return Promise.resolve({
          failedWeeks: [],
          successfulWeeks: []
        });
      }
      return this._api.copyAssignments(areaId, sourceWeek, targetWeeks).then(failedWeeks => {
        const successfulWeeks = targetWeeks.filter(x => !sourceWeek.isSame(x) && !failedWeeks.some(y => x.isSame(y)));
        this._store.clearAssignments(areaId, successfulWeeks);
        return {
          failedWeeks,
          successfulWeeks
        };
      });
    },
    /**
     * Deletes a break.
     * @param {BreakId} breakId Id of deleted break
     * @returns {Promise}
     */
    deleteBreak(breakId) {
      return this._api.deleteBreak(breakId).then(() => this._store.deleteBreak(breakId));
    },
    /**
     * Loads area of a school year.
     * @param {?SchoolYearId} schoolYearId Id of areas' school year.
     * @returns {Promise}
     */
    loadAreas(schoolYearId) {
      if (this._store.areas[schoolYearId] !== undefined) {
        return Promise.resolve();
      }
      return this._api.areas(schoolYearId).then(areas => this._store.setAreas(schoolYearId, areas));
    },
    /**
     * Loads assignments of areas and week.
     * @param {AreaId[]} areaIds Ids of assignments' area.
     * @param {?Week} week Assignments' week.
     * @returns {Promise}
     */
    loadAssignments(areaIds, week) {
      if (areaIds.length === 0 || !week) {
        return Promise.resolve();
      }
      const missingAreaIds = areaIds.filter(areaId => !(areaId in this._store.assignments && week in this._store.assignments[areaId]));
      if (missingAreaIds.length === 0) {
        return Promise.resolve();
      }
      return this._api.assignments(missingAreaIds, week).then(result => {
        result.forEach(({
                          areaId,
                          assignments
                        }) => this._store.setAssignments(areaId, week, assignments));
        missingAreaIds.filter(areaId => !result.some(x => x.areaId === areaId)).forEach(areaId => this._store.setAssignments(areaId, week, []));
      });
    },
    /**
     * Loads breaks of areas.
     * @param {AreaId[]} areaIds Id of breaks' areas.
     * @returns {Promise}
     */
    loadBreaks(areaIds) {
      const missingAreaIds = areaIds.filter(x => !(x in this._store.breaks));
      if (missingAreaIds.length === 0) {
        return Promise.resolve();
      }
      return this._api.breaks(missingAreaIds).then(x => {
        x.forEach(({
                     areaId,
                     breaks
                   }) => this._store.setBreaks(areaId, breaks));
        missingAreaIds.filter(areaId => !x.some(y => y.areaId === areaId)).forEach(areaId => this._store.setBreaks(areaId, []));
      });
    },
    /**
     * Loads counts of assignments in areas and inside a period.
     * The results are not stored in the store.
     * @param {AreaId[]} areaIds Ids of assignments' areas.
     * @param {Interval<Date>} period Period of assignments.
     * @returns {Promise<AssignmentCount[]>} The counts of assignments.
     */
    loadCounts(areaIds, period) {
      return this._api.counts(areaIds, period);
    },
    /**
     * Loads personnel of a school year.
     * @param {SchoolYearId} schoolYearId Id of personnel's school year.
     * @returns {Promise}
     */
    loadPersonnel(schoolYearId) {
      if (this._store.personnel[schoolYearId] !== undefined && this._store.teams[schoolYearId] !== undefined && this._store.departments[schoolYearId] !== undefined) {
        return Promise.resolve();
      }
      return this._api.personnel(schoolYearId).then(personnelOfSchool => {
        this._store.setPersonnel(schoolYearId, personnelOfSchool.personnel);
        this._store.setTeams(schoolYearId, personnelOfSchool.teams);
        this._store.setDepartments(schoolYearId, personnelOfSchool.departments);
      });
    },
    /**
     * Loads absent periods of personnel of a school year during a week.
     * @param {SchoolYearId} schoolYearId Id of personnel's school year.
     * @param {Week} week The week the absent periods should intersect.
     * @returns {Promise}
     */
    loadPersonnelAbsences(schoolYearId, week) {
      var _this$_store$personne, _this$_store$plannedP;
      if (((_this$_store$personne = this._store.personnelAbsences[schoolYearId]) === null || _this$_store$personne === void 0 ? void 0 : _this$_store$personne[week]) !== undefined || ((_this$_store$plannedP = this._store.plannedPersonnelAbsences[schoolYearId]) === null || _this$_store$plannedP === void 0 ? void 0 : _this$_store$plannedP[week]) !== undefined) {
        return Promise.resolve();
      }
      return this._api.personnelAbsences(schoolYearId, week).then(personnelAbsences => this._store.setPersonnelAbsences(schoolYearId, week, personnelAbsences));
    },
    /**
     * Loads substitutions of personnel assigned in areas during a week.
     * @param {AreaId[]} areaIds Ids of areas.
     * @param {Week} week The week the substitutions should intersect.
     * @returns {Promise}
     */
    loadPersonnelSubstitutions(areaIds, week) {
      const missingAreaIds = areaIds.filter(areaId => !(areaId in this._store.personnelSubstitutions && week in this._store.personnelSubstitutions[areaId]));
      if (missingAreaIds.length === 0) {
        return Promise.resolve();
      }
      return this._api.personnelSubstitutions(missingAreaIds, week).then(personnelSubstitutions => this._store.setPersonnelSubstitutions(missingAreaIds, week, personnelSubstitutions));
    },
    /**
     * Loads personnel types.
     * @method
     * @returns {Promise}
     */
    loadPersonnelTypes: functions.last(function () {
      if (Object.keys(this._store.personnelTypes).length > 0) {
        return Promise.resolve();
      }
      return this._api.personnelTypes().then(personnelTypes => this._store.setPersonnelTypes(personnelTypes));
    }),
    /**
     * Loads school with given codes.
     * @method
     * @param {SchoolCode[]} schoolCodes Codes of the schools.
     * @returns {Promise}
     */
    loadSchools: functions.last(function (schoolCodes) {
      const existingSchoolCodes = Object.keys(this._store.schools);
      const missingSchoolCodes = schoolCodes.filter(schoolCode => !existingSchoolCodes.includes(schoolCode));
      if (missingSchoolCodes.length === 0) {
        return Promise.resolve();
      }
      return this._api.schools(missingSchoolCodes).then(schools => this._store.addSchools(schools));
    }),
    /**
     * Clears, then loads assignments of an area and a week.
     * @param {AreaId} areaId Id of assignments' area.
     * @param {?Week} week Assignments' week.
     * @returns {Promise}
     */
    reloadAssignments(areaId, week) {
      if (!week) {
        return Promise.resolve();
      }
      this._store.clearAssignments(areaId, [week]);
      return this._api.assignments([areaId], week).then(result => {
        result.forEach(({
                          areaId,
                          assignments
                        }) => this._store.setAssignments(areaId, week, assignments));
      });
    },
    /**
     * Saves assignments.
     * @param {AssignmentsToSave} assignments The assignments.
     * @returns {Promise}
     */
    saveAssignments(assignments) {
      var _this$_store$assignme, _this$_store$assignme2, _this$_store$assignme3;
      const areaIdOfAssignments = Object.keys(this._store.breaks).find(areaId => this._store.breaks[areaId].some(break_ => break_.id === assignments.breakId));
      const existingAssignments = (_this$_store$assignme = (_this$_store$assignme2 = this._store.assignments[areaIdOfAssignments]) === null || _this$_store$assignme2 === void 0 ? void 0 : (_this$_store$assignme3 = _this$_store$assignme2[Week.fromDate(assignments.date)]) === null || _this$_store$assignme3 === void 0 ? void 0 : _this$_store$assignme3.find(x => x.breakId === assignments.breakId && x.dayIndex === assignments.date.dayOfWeek)) !== null && _this$_store$assignme !== void 0 ? _this$_store$assignme : {
        primary: [],
        backup: []
      };
      if (areSetsEqual(assignments.primary, existingAssignments.primary, assignmentEqual) && areArraysEqual(assignments.backup, existingAssignments.backup, assignmentEqual)) {
        return Promise.resolve();
      }
      return this._api.saveAssignments(assignments).then(timestamp => this._store.updateAssignments(assignments.breakId, assignments.date, assignments.primary, assignments.backup, timestamp));
      function assignmentEqual(first, second) {
        return first.personId === second.personId && first.personnelType === second.personnelType;
      }
    },
    /**
     * Updates existing break.
     * @param {BreakId} id Id of updated break.
     * @param {string} name New name of updated break.
     * @param {MinuteTime} start New start of updated break.
     * @param {MinuteTime} end New end of updated break.
     * @param {Timestamp} timestamp Timestamp of updated break.
     * @returns {Promise}
     */
    updateBreak(id, name, start, end, timestamp) {
      const existingBreak = Object.keys(this._store.breaks).map(areaId => this._store.breaks[areaId].find(break_ => break_.id === id)).find(break_ => break_);
      if (existingBreak && existingBreak.name === name && existingBreak.start.isSame(start) && existingBreak.end.isSame(end)) {
        return Promise.resolve();
      }
      return this._api.updateBreak({
        id,
        name,
        start,
        end,
        timestamp
      }).then(timestamp => {
        this._store.updateBreak({
          id,
          name,
          start,
          end,
          timestamp
        });
      });
    }
  };
  _module_exports = {
    Actions
  };
  return _module_exports;
})(_module_shared_functions,
  _module_shared_prelude,
  _module_shared_week);

/**
 * MODULE shared\validatedDate.js
 **/
const _module_shared_validatedDate = (function shared_validatedDate(
  _module_shared_date,
  _module_shared_result,
  _module_shared_validatedValue
) {
  let _module_exports = {};

  const Date = _module_shared_date;
  const {
    Result
  } = _module_shared_result;
  const {
    ValidatedValue
  } = _module_shared_validatedValue;

  /** @typedef {import('./date').Date} Date */
  /** @typedef {import('./intervals').Interval} Interval */

  /**
   * A callback called when date changes.
   * @callback ChangeCallback
   * @param {Result} value A Result object, which in successful state contains the date,
   * and in error state contains a validation/parse error.
   * @param {boolean} isReset Indicates whether the change is a result of a reset call (as opposed to set call).
   */

  /**
   * A callback used to validate date.
   * @callback ValidateCallback
   * @param {Date} value The date to be validated.
   * @returns {Result} A Result object, which in successful state contains the date,
   * and in error state contains a validation error.
   */

  /**
   * Represents options used when creating instance of ValidatedDate.
   * @typedef {Object} Options
   * @property {?Date} [initialValue=""] Initial value of the date.
   * @property {?ChangeCallback} [change=null] A function that is called with the date, when it changes.
   * @property {?Interval<Date>} [bounds=null] Period that specifies bounds of the date, if set.
   * @property {?boolean} [isAvailable=false] Indicates whether the date is initially available.
   * @property {?ValidateCallback} [validate=null] A function that returns a valid date, given parsed date.
   */

  /**
   * @classdesc Represents a date value parsed from a string (text value).
   * @constructor
   * @param {Options} options The options.
   * @param {Function} moment Function returning a Moment instance.
   */
  function ValidatedDate(options, moment) {
    var _options$initialValue, _options$change;
    this.input = new ValidatedValue({
      change: (value, isReset) => this._change(value, isReset),
      initialTextValue: (_options$initialValue = options.initialValue) === null || _options$initialValue === void 0 ? void 0 : _options$initialValue.format("input"),
      isAvailable: options.isAvailable,
      validate: value => validDate(value, this._bounds, moment).then(validDate => {
        var _options$validate, _options$validate2;
        return (_options$validate = (_options$validate2 = options.validate) === null || _options$validate2 === void 0 ? void 0 : _options$validate2.call(options, validDate)) !== null && _options$validate !== void 0 ? _options$validate : validDate;
      })
    });
    this._bounds = options.bounds;
    this._change = (_options$change = options.change) !== null && _options$change !== void 0 ? _options$change : () => {};
  }
  ValidatedDate.prototype = {
    /**
     * Returns the date, if it is available.
     * @returns {?Result} A Result object, which in successful state contains the date,
     * and in error state contains a validation/parse error.
     * Null if the date is not yet available.
     */
    get availableValue() {
      return this.input.availableValue;
    },
    get textValue() {
      return this.input.textValue;
    },
    set textValue(value) {
      this.input.textValue = value;
    },
    /**
     * Returns the date.
     * @returns {?Result} A Result object, which in successful state contains the date,
     * and in error state contains a validation/parse error.
     */
    get value() {
      return this.input.value;
    },
    /**
     * Sets new date and bounds period and makes the date available.
     * @param {?Date} value The date to set.
     * @param {?Interval<Date>} [bounds=undefined] The bounds period to set.
     * If undefined, the bounds period is left unchanged.
     */
    set(value, bounds) {
      if (bounds !== undefined) {
        this._bounds = bounds;
      }
      this.input.set(value === null || value === void 0 ? void 0 : value.format("input"));
    },
    /**
     * Sets new date and bounds period and makes the date no longer available.
     * @param {?Date} [value=undefined] The date to set.
     * If undefined, the date is set to the initial date.
     * @param {?Interval<Date>} [bounds=undefined] The bounds period to set.
     * If undefined, the bounds period is left unchanged.
     */
    reset(value, bounds) {
      var _value$format;
      if (bounds !== undefined) {
        this._bounds = bounds;
      }
      this.input.reset(value !== undefined ? (_value$format = value === null || value === void 0 ? void 0 : value.format("input")) !== null && _value$format !== void 0 ? _value$format : null : undefined);
    },
    /**
     * Makes the date available.
     * @returns {Result} A Result object, which in successful state contains the date,
     * and in error state contains a validation/parse error.
     */
    validate() {
      return this.input.validate();
    }
  };
  function validDate(value, bounds, moment) {
    if ((value !== null && value !== void 0 ? value : "") === "") {
      return Result.error("Indtast dato");
    }
    const validValue = Date.parsePoint(value, moment);
    if (!validValue) {
      return Result.error("Ugyldig dato");
    }
    if ((bounds === null || bounds === void 0 ? void 0 : bounds.contains(validValue)) === false) {
      return Result.error("Datoen skal v\xE6re inden for perioden (".concat(bounds.start.toString(), " - ").concat(bounds.end.addDays(-1).toString(), ")"));
    }
    return Result.success(validValue);
  }
  _module_exports = {
    ValidatedDate,
    validDate
  };
  return _module_exports;
})(_module_shared_date,
  _module_shared_result,
  _module_shared_validatedValue);

/**
 * MODULE playground_duty\plans.js
 **/
const _module_widget_plans = (function widget_plans(
  _module_shared_dateMinuteTime,
  _module_shared_errorList,
  _module_shared_functions,
  _module_shared_intervals,
  _module_shared_multipleSelection,
  _module_shared_progressList,
  _module_shared_singleSelection,
  _module_shared_validatedDate,
  _module_shared_week,
  _module_widget_assignments,
  _module_widget_assignmentsDetails,
  _module_widget_copyWeek,
  _module_widget_plansDragDrop,
  _module_widget_weeks
) {
  let _module_exports = {};

  const dateMinuteTime = _module_shared_dateMinuteTime;
  const {
    ErrorList,
    retryActionTemplate
  } = _module_shared_errorList;
  const functions = _module_shared_functions;
  const intervals = _module_shared_intervals;
  const {
    MultipleSelection
  } = _module_shared_multipleSelection;
  const {
    ProgressList
  } = _module_shared_progressList;
  const {
    SingleSelection
  } = _module_shared_singleSelection;
  const {
    ValidatedDate
  } = _module_shared_validatedDate;
  const Week = _module_shared_week;
  const {
    assignmentStatuses
  } = _module_widget_assignments;
  const {
    AssignmentsDetails
  } = _module_widget_assignmentsDetails;
  const {
    PlansDragDrop
  } = _module_widget_plansDragDrop;
  const {
    CopyWeek
  } = _module_widget_copyWeek;
  const {
    weeksOfSchoolYear
  } = _module_widget_weeks;

  /**
   * @constructor
   */
  function Plans(actions, assignmentsDetailsModalRef, confirmationModal, copyWeekModalRef, isMobileApp, moment, settings, store, viewportWidth, currentWeek, placement) {
    var _settings$planWeek, _settings$planDayOfWe, _settings$planAssignm;
    this.areas = new MultipleSelection({
      change: (_, isUpdate) => this._selectAreas(isUpdate),
      initial: settings.planAreaIds,
      preselectAll: true
    });
    const initialWeek = (_settings$planWeek = settings.planWeek) !== null && _settings$planWeek !== void 0 ? _settings$planWeek : currentWeek;
    const initialDate = initialWeek.toDate((_settings$planDayOfWe = settings.planDayOfWeek) !== null && _settings$planDayOfWe !== void 0 ? _settings$planDayOfWe : 0);
    const date = new ValidatedDate({
      initialValue: initialDate,
      change: value => value.then(x => this._selectDate(x)),
      isAvailable: true
    }, moment);
    const state = new PlansState();
    this.copyWeek = new CopyWeek(copyWeekModalRef, actions, confirmationModal, moment, store);
    this.details = new AssignmentsDetails(actions, assignmentsDetailsModalRef, store);
    this.date = date;
    this.dragDrop = new PlansDragDrop(actions, (_settings$planAssignm = settings.planAssignmentEnabled) !== null && _settings$planAssignm !== void 0 ? _settings$planAssignm : false, isMobileApp, state, store);
    this.errors = new ErrorList();
    this.progress = new ProgressList();
    this.week = new SingleSelection({
      change: (_, isUpdate) => this._selectWeek(isUpdate),
      initial: initialWeek === null || initialWeek === void 0 ? void 0 : initialWeek.toString(),
      fallback: {
        value: null,
        text: "Vælg uge"
      },
      preselectFirst: true
    });
    this._actions = actions;
    this._placement = placement;
    this._moment = moment;
    this._schoolCode = null;
    this._schoolYearId = null;
    this._settings = settings;
    this._store = store;
    this._state = state;
    this._viewportWidth = viewportWidth;
  }
  Plans.prototype = {
    get days() {
      const schoolYear = this._schoolYear;
      const week = this._week;
      const date = this._date;
      const gridLayout = this.gridLayout;
      if (!schoolYear || !week || !date) {
        return [];
      }
      return daysOfWeek(week, date, gridLayout, schoolYear.period);
    },
    get items() {
      var _this$_store$areas$th, _this$_store$personne, _this$_store$personne2, _this$_store$plannedP, _this$_school;
      const assignments = this._store.assignments;
      const areas = ((_this$_store$areas$th = this._store.areas[this._schoolYearId]) !== null && _this$_store$areas$th !== void 0 ? _this$_store$areas$th : []).filter(area => this._areaIds.includes(area.id));
      const breaks = this._store.breaks;
      const days = this.days;
      const personnel = (_this$_store$personne = this._store.personnel[this._schoolYearId]) !== null && _this$_store$personne !== void 0 ? _this$_store$personne : [];
      const personnelAbsences = (_this$_store$personne2 = this._store.personnelAbsences[this._schoolYearId]) !== null && _this$_store$personne2 !== void 0 ? _this$_store$personne2 : {};
      const personnelPlannedAbsences = (_this$_store$plannedP = this._store.plannedPersonnelAbsences[this._schoolYearId]) !== null && _this$_store$plannedP !== void 0 ? _this$_store$plannedP : {};
      const personnelSubstitutions = this._store.personnelSubstitutions;
      const personnelTypes = this._store.personnelTypes;
      const week = this._week;
      return plansOfAreas(assignments, breaks, areas, days, personnel, personnelAbsences, personnelPlannedAbsences, personnelSubstitutions, personnelTypes, this._settings, this._state, week, (_this$_school = this._school) === null || _this$_school === void 0 ? void 0 : _this$_school.personId, this.dragDrop, this._showDetails.bind(this));
    },
    get gridLayout() {
      switch (this._placement()) {
        case "narrow":
          return false;
        case "medium":
          return this._viewportWidth.isAfterBeforeNext("md") || this._viewportWidth.isAfter("xxl");
        default:
          return this._viewportWidth.isAfter("lg");
      }
    },
    get _areas() {
      var _this$_store$areas$th2;
      const areas = (_this$_store$areas$th2 = this._store.areas[this._schoolYearId]) !== null && _this$_store$areas$th2 !== void 0 ? _this$_store$areas$th2 : [];
      return areas.map(x => ({
        value: x.id,
        text: x.name
      }));
    },
    get _areaIds() {
      return this.areas.values;
    },
    get _date() {
      return this.date.value.value;
    },
    get _school() {
      return this._store.schools.find(x => x.code === this._schoolCode);
    },
    get _schoolYear() {
      const school = this._school;
      return school === null || school === void 0 ? void 0 : school.schoolYears.find(x => x.id === this._schoolYearId);
    },
    get _week() {
      return this.week.value ? Week.parsePoint(this.week.value, this._moment) : null;
    },
    get _weeks() {
      var _schoolYear$period;
      const schoolYear = this._schoolYear;
      const schoolYearPeriod = (_schoolYear$period = schoolYear === null || schoolYear === void 0 ? void 0 : schoolYear.period) !== null && _schoolYear$period !== void 0 ? _schoolYear$period : intervals.empty();
      return [...weeksOfSchoolYear(schoolYearPeriod)];
    },
    selectSchoolYear(schoolYearId, schoolCode) {
      var _this$_schoolYear$per, _this$_schoolYear;
      this._schoolCode = schoolCode;
      this._schoolYearId = schoolYearId;
      this.dragDrop.selectSchoolYear(schoolYearId, schoolCode);
      this.week.update(this._weeks);
      this._state.selectSchoolYear();
      this.date.reset(this._date, (_this$_schoolYear$per = (_this$_schoolYear = this._schoolYear) === null || _this$_schoolYear === void 0 ? void 0 : _this$_schoolYear.period) !== null && _this$_schoolYear$per !== void 0 ? _this$_schoolYear$per : null);
      this.errors.track({
        message: "Kunne ikke indlæse områder",
        id: "areas",
        actions: [retryActionTemplate()]
      }, () => this.progress.track({
        id: "areas",
        message: "Indlæser områder..."
      }, () => this._actions.loadAreas(this._schoolYearId).then(() => this.areas.update(this._areas), x => {
        this.areas.update(this._areas);
        return Promise.reject(x);
      })));
      this.errors.track({
        message: "Kunne ikke indlæse personale",
        id: "personnel",
        actions: [retryActionTemplate()]
      }, () => this.progress.track({
        id: "personnel",
        message: "Indlæser personale..."
      }, () => this._actions.loadPersonnel(this._schoolYearId)));
    },
    showCopyWeek: functions.withFocusRestore(function (areaId) {
      return this.copyWeek.show(areaId, this._schoolYearId, this._schoolCode, this._week).then(weekReplaced => {
        if (weekReplaced) {
          return this._loadAssignments([areaId]);
        }
      });
    }, "activeElement"),
    toggleDragDropEnabled(value) {
      this._settings.planAssignmentEnabled = value;
      this.dragDrop.isEnabled = value;
    },
    _loadAssignments(areaIds) {
      this.errors.track({
        message: "Kunne ikke indlæse vagter",
        id: "assignments",
        actions: [retryActionTemplate()]
      }, () => this.progress.track({
        id: "assignments",
        message: "Indlæser vagter..."
      }, () => this._actions.loadAssignments(areaIds !== null && areaIds !== void 0 ? areaIds : this._areaIds, this._week)));
    },
    _loadPersonnelSubstitutions() {
      this.errors.track({
        message: "Kunne ikke indlæse vikarer",
        id: "personnelSubstitutions",
        actions: [retryActionTemplate()]
      }, () => this.progress.track({
        id: "personnelSubstitutions",
        message: "Indlæser vikarer..."
      }, () => this._actions.loadPersonnelSubstitutions(this._areaIds, this._week)));
    },
    _selectAreas(isUpdate) {
      if (!isUpdate) {
        this._settings.planAreaIds = this._areaIds;
      }
      this._state.selectAreas(this._areaIds);
      this.errors.track({
        message: "Kunne ikke indlæse pauser",
        id: "breaks",
        actions: [retryActionTemplate()]
      }, () => this.progress.track({
        id: "breaks",
        message: "Indlæser pauser..."
      }, () => this._actions.loadBreaks(this._areaIds)));
      this._loadAssignments();
      this._loadPersonnelSubstitutions();
    },
    _selectDate(date) {
      var _date$dayOfWeek;
      this._settings.planDayOfWeek = (_date$dayOfWeek = date.dayOfWeek) !== null && _date$dayOfWeek !== void 0 ? _date$dayOfWeek : null;
      const newWeek = Week.fromDate(date);
      if (!this._week || !newWeek.isSame(this._week)) {
        this.week.select(newWeek.toString());
      }
    },
    _selectWeek(isUpdate) {
      var _this$_date$dayOfWeek, _this$_date;
      if (!isUpdate) {
        this._settings.planWeek = this._week;
      }
      this.dragDrop.selectWeek(this._week);
      this._state.selectWeek(this._week);
      const dayOfWeek = (_this$_date$dayOfWeek = (_this$_date = this._date) === null || _this$_date === void 0 ? void 0 : _this$_date.dayOfWeek) !== null && _this$_date$dayOfWeek !== void 0 ? _this$_date$dayOfWeek : 0;
      const date = dateInSchoolYear(dayOfWeek, this._schoolYear, this._week);
      this.date.set(date);
      this._loadAssignments();
      this._loadPersonnelSubstitutions();
      this.errors.track({
        message: "Kunne ikke indlæse fravær",
        id: "personnelAbsences",
        actions: [retryActionTemplate()]
      }, () => this.progress.track({
        id: "personnelAbsences",
        message: "Indlæser fravær..."
      }, () => this._actions.loadPersonnelAbsences(this._schoolYearId, this._week)));
      function dateInSchoolYear(dayOfWeek, schoolYear, week) {
        if (!week || !schoolYear) {
          var _week$startOfWeek;
          return (_week$startOfWeek = week === null || week === void 0 ? void 0 : week.startOfWeek()) !== null && _week$startOfWeek !== void 0 ? _week$startOfWeek : schoolYear === null || schoolYear === void 0 ? void 0 : schoolYear.start;
        }
        const dateInWeek = week.toDate(dayOfWeek);
        if (schoolYear.period.contains(dateInWeek)) {
          return dateInWeek;
        }
        const weekInsideSchoolYear = schoolYear.period.intersect(intervals.nonEmpty(week.startOfWeek(), week.endOfWeek().addDays(1)));
        return dateInWeek.isBefore(weekInsideSchoolYear.start) ? weekInsideSchoolYear.start : weekInsideSchoolYear.end.addDays(-1);
      }
    },
    _showDetails: functions.withFocusRestore(function (breakId, dayIndex, areaId, areaIsEditable) {
      return this.details.show(areaId, areaIsEditable, breakId, dayIndex, this._schoolYearId, this._week);
    }, "activeElement")
  };

  /**
   * @typedef {Object} DayBreakProgressList
   * @property {ProgressList} progress
   * @property {AreaId} areaId
   * @property {BreakId} breakId
   * @property {DayIndex} dayIndex
   */

  /**
   * @typedef {Object} AreaErrorList
   * @property {ErrorsList} errors
   * @property {AreaId} areaId
   */

  /** @constructor */
  function PlansState() {
    /** @member {AreaErrorList[]} */
    this._areaErrors = [];
    /** @member {DayBreakProgressList[]} */
    this._dayBreakProgresses = [];
  }
  PlansState.prototype = {
    areaErrorList(areaId) {
      const errors = this._areaErrors.find(x => x.areaId === areaId);
      if (errors) {
        return errors.errors;
      }
      const newErrors = new ErrorList();
      this._areaErrors.push({
        areaId,
        errors: newErrors
      });
      return newErrors;
    },
    dayBreakProgressList(areaId, breakId, week, dayIndex) {
      const progress = this._dayBreakProgresses.find(x => x.areaId === areaId && x.breakId === breakId && x.dayIndex === dayIndex && x.week.isSame(week));
      if (progress) {
        return progress.progress;
      }
      const newProgress = new ProgressList();
      this._dayBreakProgresses.push({
        progress: newProgress,
        areaId,
        breakId,
        dayIndex,
        week
      });
      return newProgress;
    },
    selectAreas(areaIds) {
      this._areaErrors = this._areaErrors.filter(x => areaIds.includes(x.areaId));
      this._dayBreakProgresses = this._dayBreakProgresses.filter(x => areaIds.includes(x.areaId));
    },
    selectSchoolYear() {
      this._areaErrors = [];
      this._dayBreakProgresses = [];
    },
    selectWeek(week) {
      this._dayBreakProgresses = this._dayBreakProgresses.filter(x => x.week.isSame(week));
    }
  };
  function plansOfAreas(allAssignments, allBreaks, areas, days, personnel, personnelAbsences, personnelPlannedAbsences, personnelSubstitutions, personnelTypes, settings, state, week, currentUserPersonId, dragDrop, showDetails) {
    return areas.map(area => {
      var _allAssignments$area$, _assignmentsOfArea$we, _personnelAbsences$we, _personnelPlannedAbse, _personnelSubstitutio, _personnelSubstitutio2, _allBreaks$area$id, _allBreaks$area$id2;
      const assignmentsOfArea = (_allAssignments$area$ = allAssignments[area.id]) !== null && _allAssignments$area$ !== void 0 ? _allAssignments$area$ : {};
      const assignmentsOfWeek = (_assignmentsOfArea$we = assignmentsOfArea[week]) !== null && _assignmentsOfArea$we !== void 0 ? _assignmentsOfArea$we : [];
      const personnelAbsencesOfWeek = (_personnelAbsences$we = personnelAbsences[week]) !== null && _personnelAbsences$we !== void 0 ? _personnelAbsences$we : {};
      const personnelPlannedAbsencesOfWeek = (_personnelPlannedAbse = personnelPlannedAbsences[week]) !== null && _personnelPlannedAbse !== void 0 ? _personnelPlannedAbse : {};
      const personnelSubstitutionsOfWeek = (_personnelSubstitutio = (_personnelSubstitutio2 = personnelSubstitutions[area.id]) === null || _personnelSubstitutio2 === void 0 ? void 0 : _personnelSubstitutio2[week]) !== null && _personnelSubstitutio !== void 0 ? _personnelSubstitutio : [];
      const breaks = breaksOfArea((_allBreaks$area$id = allBreaks[area.id]) !== null && _allBreaks$area$id !== void 0 ? _allBreaks$area$id : []);
      const breakPeriods = ((_allBreaks$area$id2 = allBreaks[area.id]) !== null && _allBreaks$area$id2 !== void 0 ? _allBreaks$area$id2 : []).reduce((result, break_) => result.set(break_.id, intervals.nonEmpty(break_.start, break_.end)), new Map());
      return planOfArea(area, assignmentsOfWeek, breaks, breakPeriods, days, personnel, personnelAbsencesOfWeek, personnelPlannedAbsencesOfWeek, personnelSubstitutionsOfWeek, personnelTypes, settings, state, week, currentUserPersonId, dragDrop.ofArea(area.id, area.isEditable), showDetails);
    });
  }
  function planOfArea(area, assignments, breaks, breakPeriods, days, personnel, personnelAbsences, personnelPlannedAbsences, personnelSubstitutions, personnelTypes, settings, state, week, currentUserPersonId, dragDrop, showDetails) {
    const errors = state.areaErrorList(area.id);
    return {
      assignments: days.map(day => ({
        day,
        assignmentsByBreaks: breaks.map(break_ => {
          var _breakPeriods$get;
          const progress = state.dayBreakProgressList(area.id, break_.id, week, day.dayIndex);
          const date = week.toDate(day.dayIndex);
          const substitutedPersonnel = personnelSubstitutions.filter(substitution => substitution.breakId === break_.id && substitution.date.isSame(date)).map(substitution => substitution.personId);
          return {
            assignments: assignmentsOfBreakAndDay(area, assignments, break_, (_breakPeriods$get = breakPeriods.get(break_.id)) !== null && _breakPeriods$get !== void 0 ? _breakPeriods$get : intervals.empty(), day, personnel, personnelAbsences, personnelPlannedAbsences, personnelTypes, substitutedPersonnel, week, currentUserPersonId, dragDrop, progress, showDetails),
            break: break_
          };
        })
      })),
      errors,
      id: area.id,
      isEditable: area.isEditable,
      dragDrop,
      key: "area-".concat(area.id),
      name: area.name,
      settings: settings.ofArea(area.id)
    };
  }
  function assignmentsOfBreakAndDay(area, assignments, break_, breakPeriod, day, personnel, personnelAbsences, personnelPlannedAbsences, personnelTypes, substitutedPersonnel, week, currentUserPersonId, dragDropOfArea, progress, showDetails) {
    var _assignments$find;
    const assignmentsOfDay = (_assignments$find = assignments.find(x => x.dayIndex === day.dayIndex && x.breakId === break_.id)) !== null && _assignments$find !== void 0 ? _assignments$find : {
      primary: [],
      backup: []
    };
    const breakDateTimePeriod = breakPeriod.map(minuteTime => dateMinuteTime.fromDateAndTime(week.toDate(day.dayIndex), minuteTime));
    const dragDrop = dragDropOfArea.ofBreakAndDay(assignmentsOfDay, break_.id, breakDateTimePeriod, day.dayIndex, personnelPlannedAbsences, substitutedPersonnel);
    const {
      primary: primaryStatuses,
      backup: backupStatuses
    } = assignmentStatuses(assignmentsOfDay, intervals.sum([breakDateTimePeriod]), personnel, personnelAbsences, substitutedPersonnel);
    const primaryAssignments = assignmentsOfDay.primary.map(x => assignment(x, null, primaryStatuses, personnel, personnelTypes, currentUserPersonId));
    primaryAssignments.sort((a, b) => {
      var _a$personInitials, _a$personFirstName, _a$personLastName;
      return ((_a$personInitials = a.personInitials) === null || _a$personInitials === void 0 ? void 0 : _a$personInitials.localeCompare(b.personInitials)) || ((_a$personFirstName = a.personFirstName) === null || _a$personFirstName === void 0 ? void 0 : _a$personFirstName.localeCompare(b.personFirstName)) || ((_a$personLastName = a.personLastName) === null || _a$personLastName === void 0 ? void 0 : _a$personLastName.localeCompare(b.personLastName));
    });
    const backupAssignments = assignmentsOfDay.backup.map((x, index) => assignment(x, index, backupStatuses, personnel, personnelTypes, currentUserPersonId));
    return {
      areEditable: area.isEditable,
      breakId: break_.id,
      dayIndex: day.dayIndex,
      dragDrop,
      key: "assignments-".concat(day.index, "-").concat(break_.index),
      progress,
      style: "--day-index: ".concat(day.index, "; --break-index: ").concat(break_.index, ";"),
      timestamp: assignmentsOfDay.timestamp,
      primary: group(primaryAssignments, dragDrop.ofGroup(true), true),
      backup: group(backupAssignments, dragDrop.ofGroup(false), false),
      showDetails() {
        showDetails(break_.id, day.dayIndex, area.id, area.isEditable);
      }
    };
    function group(assignments, dragDrop, isPrimary) {
      return {
        get available() {
          return assignments.length > 0 || dragDrop.isAvailable;
        },
        detailsButtonPlacement: isPrimary ? "assignments-primary" : "assignments-backup",
        empty: assignments.length === 0,
        dragDrop,
        icon: isPrimary ? "fal fa-user-circle" : "fal fa-pause-circle",
        items: assignments,
        label: isPrimary ? "Forvagt" : "Bagvagt",
        key: isPrimary ? "primary" : "backup"
      };
    }
  }
  function assignment(assignment, order, assignmentStatuses, personnel, personnelTypes, currentUserPersonId) {
    const person = personnel.find(x => x.id === assignment.personId);
    const personnelType = personnelTypes[assignment.personnelType];
    const status = assignmentStatuses.get(assignment.personId);
    return {
      personId: person === null || person === void 0 ? void 0 : person.id,
      personInitials: person === null || person === void 0 ? void 0 : person.initials,
      personFirstName: person === null || person === void 0 ? void 0 : person.firstName,
      personHasMultipleEmployments: (person === null || person === void 0 ? void 0 : person.employments.length) > 1,
      personLastName: person === null || person === void 0 ? void 0 : person.lastName,
      personIsCurrentUser: (person === null || person === void 0 ? void 0 : person.id) === currentUserPersonId,
      personnelTypeAbbreviation: personnelType === null || personnelType === void 0 ? void 0 : personnelType.abbreviation,
      personnelTypeDescription: personnelType === null || personnelType === void 0 ? void 0 : personnelType.description,
      status,
      style: order !== null ? "--order: ".concat(order, ";") : null
    };
  }
  function breaksOfArea(breaks) {
    breaks = [...breaks];
    breaks.sort((a, b) => a.start.compare(b.start) || a.end.compare(b.end) || a.name.localeCompare(b.name));
    return breaks.map((x, index) => ({
      id: x.id,
      index,
      name: x.name,
      period: "".concat(x.start, " - ").concat(x.end),
      style: "--break-index: ".concat(index, ";"),
      key(dayIndex) {
        return "break-".concat(dayIndex, "-").concat(this.index);
      }
    }));
  }
  function daysOfWeek(week, date, gridLayout, schoolYearPeriod) {
    if (!gridLayout) {
      return schoolYearPeriod.contains(date) ? [dayOfWeek(date, date.dayOfWeek)] : [];
    }
    const weekDays = intervals.nonEmpty(week.startOfWeek(), week.startOfWeek().addDays(5));
    const weekDaysInSchoolYear = weekDays.intersect(schoolYearPeriod);
    const days = [...weekDaysInSchoolYear.iterate(x => x.addDays(1))];
    return days.map((date, index) => dayOfWeek(date, index));
    function dayOfWeek(date, index) {
      return {
        date: date.toString(),
        dayIndex: date.dayOfWeek,
        index,
        key: "day-".concat(index),
        name: date.format("dayofweek"),
        style: "--day-index: ".concat(index, ";")
      };
    }
  }
  _module_exports = {
    Plans
  };
  return _module_exports;
})(_module_shared_dateMinuteTime,
  _module_shared_errorList,
  _module_shared_functions,
  _module_shared_intervals,
  _module_shared_multipleSelection,
  _module_shared_progressList,
  _module_shared_singleSelection,
  _module_shared_validatedDate,
  _module_shared_week,
  _module_widget_assignments,
  _module_widget_assignmentsDetails,
  _module_widget_copyWeek,
  _module_widget_plansDragDrop,
  _module_widget_weeks);

/**
 * MODULE shared\validatedDatePeriod.js
 **/
const _module_shared_validatedDatePeriod = (function shared_validatedDatePeriod(
  _module_shared_intervals,
  _module_shared_result,
  _module_shared_validatedDate
) {
  let _module_exports = {};

  const intervals = _module_shared_intervals;
  const {
    Result
  } = _module_shared_result;
  const {
    ValidatedDate,
    validDate
  } = _module_shared_validatedDate;

  /** @typedef {import('./date').Date} Date */
  /** @typedef {import('./intervals').Interval} Interval */

  /**
   * A callback called when date period changes.
   * @callback ChangeCallback
   * @param {Result} value A Result object, which in successful state contains the date period,
   * and in error state contains a validation/parse error.
   * @param {boolean} isReset Indicates whether the change is a result of a reset call (as opposed to set call).
   */

  /**
   * Represents options used when creating instance of ValidatedDatePeriod.
   * @typedef {Object} Options
   * @property {?Interval<Date>} [initialValue=""] Initial value of the date period.
   * @property {?ChangeCallback} [change=null] A function that is called with the date period, when it changes.
   * @property {?Interval<Date>} [bounds=null] Period that specifies bounds of the date period, if set.
   * @property {?boolean} [isAvailable=false] Indicates whether the date period is initially available.
   */

  /**
   * @classdesc Represents a date period value parsed from a pair of strings (text values),
   * that correspond to the period's start and end date.
   * @constructor
   * @summary Represents a date period value parsed from a pair of strings (text values).
   * @param {Options} options The options.
   * @param {Function} moment Function returning a Moment instance.
   */
  function ValidatedDatePeriod(options, moment) {
    var _options$initialValue, _options$initialValue2, _options$change;
    this.start = new ValidatedDate({
      initialValue: (_options$initialValue = options.initialValue) === null || _options$initialValue === void 0 ? void 0 : _options$initialValue.start,
      isAvailable: !!options.isAvailable,
      change: (start, isReset) => this._changeOfStart(start, isReset),
      validate: start => this._validStart(start)
    }, moment);
    this.end = new ValidatedDate({
      initialValue: (_options$initialValue2 = options.initialValue) === null || _options$initialValue2 === void 0 ? void 0 : _options$initialValue2.end.addDays(-1).format("input"),
      isAvailable: !!options.isAvailable,
      change: (end, isReset) => this._changeOfEnd(end, isReset),
      validate: end => this._validEnd(end)
    }, moment);
    this._bounds = options.bounds;
    this._change = (_options$change = options.change) !== null && _options$change !== void 0 ? _options$change : () => {};
    this._moment = moment;
    this._isAvailable = !!options.isAvailable;
    this._isSynchronizingStartAndEnd = false;
  }
  ValidatedDatePeriod.prototype = {
    /**
     * Returns the date period, if it is available.
     * @returns {?Result} A Result object, which in successful state contains the date period,
     * and in error state contains a validation/parse error.
     * Null if the date period is not yet available.
     */
    get availableValue() {
      return this._isAvailable ? this.value : null;
    },
    /**
     * Returns the date period.
     * @returns {?Result} A Result object, which in successful state contains the date period,
     * and in error state contains a validation/parse error.
     */
    get value() {
      return this._validPeriod(this.start.value, this.end.value);
    },
    /**
     * Sets new date and bounds periods and makes the date period available.
     * @param {Interval<Date>} value The date period to set.
     * @param {?Interval<Date>} [bounds=undefined] The bounds period to set.
     * If undefined, the bounds period is left unchanged.
     */
    set(value, bounds) {
      this._isAvailable = true;
      if (bounds !== undefined) {
        this._bounds = bounds;
      }
      this._isSynchronizingStartAndEnd = true;
      try {
        var _value$end;
        this.start.set(value.start, this._bounds);
        this.end.set((_value$end = value.end) === null || _value$end === void 0 ? void 0 : _value$end.addDays(-1), this._bounds);
      } finally {
        this._isSynchronizingStartAndEnd = false;
      }
      this._change(this.value, false);
    },
    /**
     * Sets new date and bounds periods and makes the date period no longer available.
     * @param {?Interval<Date>} [value=undefined] The date period to set.
     * If undefined, the date period is set to the initial date period.
     * @param {?Interval<Date>} [bounds=undefined] The bounds period to set.
     * If undefined, the bounds period is left unchanged.
     */
    reset(value, bounds) {
      this._isAvailable = false;
      if (bounds !== undefined) {
        this._bounds = bounds;
      }
      this._isSynchronizingStartAndEnd = true;
      try {
        var _value$start, _value$end$addDays, _value$end2;
        this.start.reset(value === undefined ? undefined : (_value$start = value === null || value === void 0 ? void 0 : value.start) !== null && _value$start !== void 0 ? _value$start : null, this._bounds);
        this.end.reset(value === undefined ? undefined : (_value$end$addDays = value === null || value === void 0 ? void 0 : (_value$end2 = value.end) === null || _value$end2 === void 0 ? void 0 : _value$end2.addDays(-1)) !== null && _value$end$addDays !== void 0 ? _value$end$addDays : null, this._bounds);
      } finally {
        this._isSynchronizingStartAndEnd = false;
      }
      this._change(this.value, true);
    },
    /**
     * Makes the date period available.
     * @returns {Result} A Result object, which in successful state contains the date period,
     * and in error state contains a validation/parse error.
     */
    validate() {
      this._isAvailable = true;
      const start = this.start.validate();
      const end = this.end.validate();
      return this._validPeriod(start, end);
    },
    _changeOfStart(start, isReset) {
      if (this._isSynchronizingStartAndEnd) {
        return;
      }
      this._change(this._validPeriod(start, this.end.value), isReset);
    },
    _changeOfEnd(end, isReset) {
      if (this._isSynchronizingStartAndEnd) {
        return;
      }
      this._change(this._validPeriod(this.start.value, end), isReset);
    },
    _validPeriod(start, end) {
      return start.then(validStart => end.then(validEnd => intervals.nonEmpty(validStart, validEnd.addDays(1))));
    },
    _validStart(validStart) {
      return validDate(this.end.textValue, this._bounds, this._moment).then(validEnd => validStart.compare(validEnd) > 0 ? Result.error("Startdato skal være inden slutdato") : Result.success(validStart), () => validStart);
    },
    _validEnd(validEnd) {
      return validDate(this.start.textValue, this._bounds, this._moment).then(validStart => validStart.compare(validEnd) > 0 ? Result.error("Slutdato skal være efter startdato") : Result.success(validEnd), () => validEnd);
    }
  };
  _module_exports = {
    ValidatedDatePeriod
  };
  return _module_exports;
})(_module_shared_intervals,
  _module_shared_result,
  _module_shared_validatedDate);

/**
 * MODULE playground_duty\counts.js
 **/
const _module_widget_counts = (function widget_counts(
  _module_shared_errorIndicator,
  _module_shared_errorList,
  _module_shared_functions,
  _module_shared_multipleSelection,
  _module_shared_multipleSelectionInput,
  _module_shared_progressList,
  _module_shared_singleSelectionInput,
  _module_shared_validatedDateInput,
  _module_shared_validatedDatePeriod,
  _module_shared_widgetToolbar,
  _module_widget_countsTable
) {
  let _module_exports = {};

  const {
    CountsTable
  } = _module_widget_countsTable;
  const {
    ErrorIndicator
  } = _module_shared_errorIndicator;
  const {
    ErrorList,
    retryActionTemplate
  } = _module_shared_errorList;
  const functions = _module_shared_functions;
  const {
    MultipleSelection
  } = _module_shared_multipleSelection;
  const {
    MultipleSelectionInput
  } = _module_shared_multipleSelectionInput;
  const {
    ProgressList
  } = _module_shared_progressList;
  const {
    SingleSelectionInput
  } = _module_shared_singleSelectionInput;
  const {
    ValidatedDatePeriod
  } = _module_shared_validatedDatePeriod;
  const {
    ValidatedDateInput
  } = _module_shared_validatedDateInput;
  const {
    WidgetToolbar
  } = _module_shared_widgetToolbar;

  // @vue/component
  const Counts = {
    name: "Counts",
    components: {
      "counts-table": CountsTable,
      "error-indicator": ErrorIndicator,
      "multiple-selection-input": MultipleSelectionInput,
      "single-selection-input": SingleSelectionInput,
      "validated-date-input": ValidatedDateInput,
      "widget-toolbar": WidgetToolbar
    },
    props: {
      actions: {
        type: Object,
        required: true
      },
      moment: {
        type: Function,
        required: true
      },
      placement: {
        type: String,
        required: true
      },
      schools: {
        type: Object,
        required: true
      },
      schoolCode: {
        default: null,
        type: [String, Number]
      },
      schoolYears: {
        type: Object,
        required: true
      },
      schoolYearId: {
        default: null,
        type: [String, Number]
      },
      settings: {
        type: Object,
        required: true
      },
      store: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        areas: new MultipleSelection({
          change: (x, isUpdate) => {
            if (!isUpdate) {
              // eslint-disable-next-line vue/no-mutating-props
              this.settings.countsAreaIds = x;
            }
          },
          initial: this.settings.countsAreaIds,
          preselectAll: true
        }),
        errors: new ErrorList(),
        period: new ValidatedDatePeriod({
          change: (period, isReset) => {
            if (!isReset) {
              period === null || period === void 0 ? void 0 : period.then(x => {
                // eslint-disable-next-line vue/no-mutating-props
                this.settings.countsPeriod = x;
              });
            }
          }
        }, () => this.moment),
        progress: new ProgressList()
      };
    },
    watch: {
      schoolCode: {
        immediate: true,
        handler: function () {
          this.refresh();
        }
      },
      schoolYearId: {
        immediate: true,
        handler: function () {
          this.refresh();
        }
      }
    },
    methods: {
      refresh() {
        var _this$store$schools$f, _this$store$schools$f2;
        const schoolYearPeriod = (_this$store$schools$f = this.store.schools.find(x => x.code === this.schoolCode)) === null || _this$store$schools$f === void 0 ? void 0 : (_this$store$schools$f2 = _this$store$schools$f.schoolYears.find(x => x.id === this.schoolYearId)) === null || _this$store$schools$f2 === void 0 ? void 0 : _this$store$schools$f2.period;
        const settingsPeriod = this.settings.countsPeriod;
        const period = settingsPeriod ? schoolYearPeriod !== null && schoolYearPeriod !== void 0 && schoolYearPeriod.intersectsWith(settingsPeriod) ? schoolYearPeriod.intersect(settingsPeriod) : schoolYearPeriod : schoolYearPeriod;
        this.period.reset(period, schoolYearPeriod);
        this.errors.track({
          message: "Kunne ikke indlæse områder",
          id: "areas",
          actions: [retryActionTemplate()]
        }, () => this.progress.track({
          id: "areas",
          message: "Indlæser områder..."
        }, () => this.actions.loadAreas(this.schoolYearId).then(() => this.areas.update(this._areas()), x => {
          this.areas.update(this._areas());
          return Promise.reject(x);
        })));
      },
      show() {
        var _this$period$validate;
        (_this$period$validate = this.period.validate()) === null || _this$period$validate === void 0 ? void 0 : _this$period$validate.then(period => {
          const areaIds = this.areas.values;
          const schoolCode = this.schoolCode;
          const schoolYearId = this.schoolYearId;
          const personnel = this.errors.track({
            message: "Kunne ikke indlæse personale",
            id: "personnel"
          }, () => this.progress.track({
            id: "personnel",
            message: "Indlæser personale..."
          }, () => this.actions.loadPersonnel(schoolYearId)));
          this.errors.track({
            message: "Kunne ikke indlæse optælling",
            id: "counts"
          }, () => this.progress.track({
            id: "counts",
            message: "Indlæser optællingen..."
          }, () => this.actions.loadCounts(areaIds, period).then(counts => personnel.then(() => {
            this.$refs.table.update(areaIds, counts, period, schoolCode, schoolYearId);
          }))));
        });
      },
      _areas() {
        var _this$store$areas$thi;
        const areas = (_this$store$areas$thi = this.store.areas[this.schoolYearId]) !== null && _this$store$areas$thi !== void 0 ? _this$store$areas$thi : [];
        return areas.map(x => ({
          value: x.id,
          text: x.name
        }));
      }
    },
    render(h) {
      return h("b-container", {}, [/* Toolbar */
        h("b-row", {}, [h("b-col", {}, [h("widget-toolbar", {
          props: {
            placement: this.placement
          }
        }, [/* School selection */
          this.schools.hasMultiple ? h("single-selection-input", {
            props: {
              id: "kmd-educa-personale-playground-duty-counts-school-select",
              label: "Skoler",
              viewModel: this.schools.school
            }
          }, []) : "", /* School year selection */
          this.schoolYears.hasMultiple ? h("single-selection-input", {
            class: ["width-scale-35"],
            props: {
              id: "kmd-educa-personale-playground-duty-counts-school-year-select",
              label: "Skoleår",
              viewModel: this.schoolYears.schoolYear
            }
          }, []) : "", /* Area selection */
          h("multiple-selection-input", {
            props: {
              id: "kmd-educa-personale-playground-duty-counts-areas-select",
              dropdown: true,
              label: "Områder",
              viewModel: this.areas
            }
          }, []), /* Start date selection */
          h("validated-date-input", {
            props: {
              id: "kmd-educa-personale-playground-duty-counts-start-date-select",
              label: "Start dato",
              showSuccessStateOnInput: false,
              viewModel: this.period.start
            }
          }, []), /* End date selection */
          h("validated-date-input", {
            props: {
              id: "kmd-educa-personale-playground-duty-counts-end-date-select",
              label: "Slut dato",
              showSuccessStateOnInput: false,
              viewModel: this.period.end
            }
          }, []), /* Show */
          h("b-button", {
            on: {
              click: functions.throttle(this.show, 100)
            }
          }, "Vis")])])]), /* Errors */
        h("b-row", {}, [h("b-col", {}, [h("error-indicator", {
          props: {
            viewModel: this.errors
          }
        }, [])])]), /* Table */
        h("b-row", {}, [h("b-col", {}, [h("counts-table", {
          ref: "table",
          props: {
            placement: this.placement,
            progress: this.progress,
            store: this.store
          }
        }, [])])])]);
    }
  };
  _module_exports = {
    Counts
  };
  return _module_exports;
})(_module_shared_errorIndicator,
  _module_shared_errorList,
  _module_shared_functions,
  _module_shared_multipleSelection,
  _module_shared_multipleSelectionInput,
  _module_shared_progressList,
  _module_shared_singleSelectionInput,
  _module_shared_validatedDateInput,
  _module_shared_validatedDatePeriod,
  _module_shared_widgetToolbar,
  _module_widget_countsTable);

/**
 * MODULE shared\api.js
 **/
const _module_shared_api = (function shared_api() {
  let _module_exports = {};

  function arrayToQueryString(name, value) {
    return value.map(x => {
      var _x$toJSON, _x$toJSON2;
      return "".concat(name, "=").concat((_x$toJSON = x === null || x === void 0 ? void 0 : (_x$toJSON2 = x.toJSON) === null || _x$toJSON2 === void 0 ? void 0 : _x$toJSON2.call(x)) !== null && _x$toJSON !== void 0 ? _x$toJSON : x);
    }).join("&");
  }
  function responseError(result) {
    var _ref, _result$response$data, _result$response, _result$response$data2, _result$response2, _result$response2$dat, _result$response2$dat2;
    return Promise.reject((_ref = (_result$response$data = result === null || result === void 0 ? void 0 : (_result$response = result.response) === null || _result$response === void 0 ? void 0 : (_result$response$data2 = _result$response.data) === null || _result$response$data2 === void 0 ? void 0 : _result$response$data2.Message) !== null && _result$response$data !== void 0 ? _result$response$data : result === null || result === void 0 ? void 0 : (_result$response2 = result.response) === null || _result$response2 === void 0 ? void 0 : (_result$response2$dat = _result$response2.data) === null || _result$response2$dat === void 0 ? void 0 : (_result$response2$dat2 = _result$response2$dat.Error) === null || _result$response2$dat2 === void 0 ? void 0 : _result$response2$dat2.Message) !== null && _ref !== void 0 ? _ref : result.message);
  }
  _module_exports = {
    arrayToQueryString,
    responseError
  };
  return _module_exports;
})();

/**
 * MODULE playground_duty\api.js
 **/
const _module_widget_api = (function widget_api(
  _module_shared_api,
  _module_shared_date,
  _module_shared_dateMinuteTime,
  _module_shared_intervals,
  _module_shared_time,
  _module_shared_week
) {
  let _module_exports = {};

  const api = _module_shared_api;
  const date = _module_shared_date;
  const dateMinuteTime = _module_shared_dateMinuteTime;
  const intervals = _module_shared_intervals;
  const time = _module_shared_time;
  const Week = _module_shared_week;

  /** @typedef {import('../shared/date').Date} Date */
  /** @typedef {import('../shared/dateMinuteTime').DateMinuteTime} DateMinuteTime */
  /** @typedef {import('../shared/intervals').Interval} Interval */
  /** @typedef {import('../shared/time').MinuteDuration} MinuteDuration */
  /** @typedef {import('../shared/time').MinuteTime} MinuteTime */
  /** @typedef {import('../shared/week').Week Week */
  /** @typedef {import('./store').Area Area */
  /** @typedef {import('./store').AreaId AreaId */
  /** @typedef {import('./store').Assignment Assignment */
  /** @typedef {import('./store').AssignmentsOfBreakAndDate AssignmentsOfBreakAndDate */
  /** @typedef {import('./store').Break Break */
  /** @typedef {import('./store').BreakId BreakId */
  /** @typedef {import('./store').Department Department */
  /** @typedef {import('./store').DepartmentId DepartmentId */
  /** @typedef {import('./store').Person Person */
  /** @typedef {import('./store').PersonAbsences PersonAbsences */
  /** @typedef {import('./store').PersonSubstitution PersonSubstitution */
  /** @typedef {import('./store').PersonSubstitutionsOfArea PersonSubstitutionsOfArea */
  /** @typedef {import('./store').PersonId PersonId */
  /** @typedef {import('./store').PersonWithType PersonWithType */
  /** @typedef {import('./store').School School */
  /** @typedef {import('./store').SchoolCode SchoolCode */
  /** @typedef {import('./store').SchoolYearId SchoolYearId */
  /** @typedef {import('./store').Team Team */
  /** @typedef {import('./store').TeamId TeamId */
  /** @typedef {import('./store').Timestamp Timestamp */

  /**
   * Represents assignments of an area.
   * @typedef {Object} AssignmentsOfArea
   * @property {AreaId} areaId Id af the area.
   * @property {AssignmentsOfBreakAndDate[]} assignments The assignments.
   */

  /**
   * Represents assignments to save.
   * @typedef {Object} AssignmentsToSave
   * @property {BreakId} breakId Id of the assignments' break.
   * @property {Date} date Date of the assignments.
   * @property {Assignment[]} primary Primary assignments.
   * @property {Assignment[]} backup Backup assignments.
   * @property {Timestamp} timestamp Timestamp of the assignments.
   */

  /**
   * Represents count of assignments of a person in an area.
   * @typedef {Object} AssignmentCount
   * @property {AreaId} areaId Id af the area.
   * @property {PersonId} personId Id af the person.
   * @property {Number} primaryAssignmentCount The number of primary assignments.
   * @property {MinuteDuration} primaryAssignmentDuration The duration of primary assignments.
   * @property {Number} backupAssignmentCount The number of backup assignments.
   * @property {MinuteDuration} backupAssignmentDuration The duration of backup assignments.
   */

  /**
   * Represents breaks of an area.
   * @typedef {Object} BreaksOfArea
   * @property {AreaId} areaId Id af the area.
   * @property {Break[]} breaks The breaks.
   */

  /**
   * Represents a new playground break.
   * @typedef {Object} NewBreak
   * @property {AreaId} areaId Id of the area to which the break belongs.
   * @property {String} name Name of the break.
   * @property {MinuteTime} start Start of the break.
   * @property {MinuteTime} end End of the break.
   */

  /**
   * A description of personnel type.
   * @typedef {Object} PersonnelTypeDescription
   * @property {string} abbreviation Abbreviation of the personnel type.
   * @property {string} description Description of the personnel type.
   * @property {PersonnelType} id Id of the personnel type.
   */

  /**
   * Personnel of school, with their teams and departments.
   * @typedef {Object} PersonnelOfSchool
   * @property {Department[]} departments The departments.
   * @property {Person[]} personnel The personnel.
   * @property {Team[]} teams The teams.
   */

  /**
   * Represents a playground break that is saved.
   * @typedef {Object} SavedBreak
   * @property {BreakId} id Id of the break.
   * @property {Timestamp} timestamp Timestamp of the break.
   */

  /**
   * @classdesc Represents playground duty API.
   * @constructor
   * @param {Function} axios Function returning the Axios instance.
   * @param {Function} token Function returning a promise resolving to an Aula token.
   * @param {Function} moment Function returning the Moment instance.
   */
  function Api(axios, token, moment) {
    this._moment = moment;
    this._axios = () => {
      return token().then(x => axios().create({
        headers: {
          Authorization: x
        }
      }));
    };
  }
  function responseError(result) {
    return api.responseError(result).catch(x => {
      var _result$response;
      return Promise.reject({
        status: result === null || result === void 0 ? void 0 : (_result$response = result.response) === null || _result$response === void 0 ? void 0 : _result$response.status,
        message: x
      });
    });
  }
  Api.prototype = {
    /**
     * Gets schools with specified school codes.
     * @param {SchoolCode[]} schoolCodes Codes of the schools.
     * @returns {Promise<School[]>} Schools accessible to the user.
     */
    schools(schoolCodes) {
      return this._axios().then(axios => axios.get("https://personale.api.kmd.dk/aula/api/v2/playgroundDuty/schools?".concat(api.arrayToQueryString("schoolCodes", schoolCodes)), {
        responseType: "json"
      })).then(response => response.data.map(x => ({
        name: x.name,
        code: x.code,
        personId: x.schoolPersonId,
        schoolYears: x.schoolYears.map(y => ({
          id: y.id,
          name: y.name,
          period: intervals.nonEmpty(date.parsePoint(y.period.start, this._moment), date.parsePoint(y.period.end, this._moment).addDays(1))
        }))
      })), responseError);
    },
    /**
     * Gets areas of school year with specified id.
     * @param {?SchoolYearId} schoolYearId School year id.
     * @returns {Promise<Area[]>} The areas.
     */
    areas(schoolYearId) {
      if (!schoolYearId) {
        return Promise.resolve([]);
      }
      return this._axios().then(axios => axios.get("https://personale.api.kmd.dk/aula/api/v2/playgroundDuty/areas/" + schoolYearId, {
        responseType: "json"
      })).then(response => response.data.map(x => ({
        name: x.name,
        isEditable: x.isEditable,
        id: x.id
      })), responseError);
    },
    /**
     * Gets breaks of areas with specified ids.
     * @param {AreaId[]} areaIds Ids of breaks' areas.
     * @returns {Promise<BreaksOfArea[]>} The breaks.
     */
    breaks(areaIds) {
      if (areaIds.length === 0) {
        return Promise.resolve(new Map());
      }
      return this._axios().then(axios => axios.get("https://personale.api.kmd.dk/aula/api/v2/playgroundDuty/breaks?".concat(api.arrayToQueryString("areaIds", areaIds)), {
        responseType: "json"
      })).then(response => response.data.map(x => ({
        areaId: x.areaId,
        breaks: x.breaks.map(y => ({
          id: y.id,
          name: y.name,
          start: time.parsePoint(y.start),
          end: time.parsePoint(y.end),
          timestamp: y.timestamp
        }))
      })), responseError);
    },
    /**
     * Gets counts of assignments in areas with specified ids and inside specified period.
     * @param {AreaId[]} areaIds Ids of assignments' areas.
     * @param {Interval<Date>} period Period of assignments.
     * @returns {Promise<AssignmentCount[]>} The counts of assignments.
     */
    counts(areaIds, period) {
      if (areaIds.length === 0 || !period || period.empty) {
        return Promise.resolve([]);
      }
      return this._axios().then(axios => axios.get("https://personale.api.kmd.dk/aula/api/v2/playgroundDuty/counts?".concat(api.arrayToQueryString("areaIds", areaIds)), {
        params: {
          startDate: period.start.toJSON(),
          endDate: period.end.addDays(-1).toJSON()
        },
        responseType: "json"
      }).then(response => response.data.map(x => ({
        areaId: x.areaId,
        personId: x.personId,
        primaryAssignmentCount: Number.parseInt(x.primaryAssignmentCount, 10),
        primaryAssignmentDuration: time.parseDuration(x.primaryAssignmentDuration),
        backupAssignmentCount: Number.parseInt(x.backupAssignmentCount, 10),
        backupAssignmentDuration: time.parseDuration(x.backupAssignmentDuration)
      })), responseError));
    },
    /**
     * Gets personnel of a school year with specified id.
     * @param {?SchoolYearId} schoolYearId Id of personnel's school year.
     * @returns {Promise<PersonnelOfSchool>} The personnel, with departments and teams.
     */
    personnel(schoolYearId) {
      if (!schoolYearId) {
        return Promise.resolve([]);
      }
      return this._axios().then(axios => axios.get("https://personale.api.kmd.dk/aula/api/v2/playgroundDuty/personnel/", {
        params: {
          schoolYearId
        },
        responseType: "json"
      })).then(response => ({
        teams: response.data.teams,
        departments: response.data.departments,
        personnel: response.data.personnel.map(person => ({
          id: person.id,
          initials: person.initials,
          firstName: person.firstName,
          lastName: person.lastName,
          employments: person.employments.map(employments => ({
            personnelType: employments.personnelType,
            period: intervals.sum(employments.periods.map(period => intervals.nonEmpty(date.parsePoint(period.start, this._moment), date.parsePoint(period.end, this._moment).addDays(1))))
          }))
        }))
      }), responseError);
    },
    /**
     * Gets absences of personnel in a school year during a week.
     * @param {SchoolYearId} schoolYearId The id of persons' school year.
     * @param {Week} week The week the absences should intersect.
     * @returns {Promise<PersonAbsences[]>} The personnel's absences.
     */
    personnelAbsences(schoolYearId, week) {
      if (!schoolYearId || !week) {
        return Promise.resolve([]);
      }
      return this._axios().then(axios => axios.get("https://personale.api.kmd.dk/aula/api/v1/playgroundDuty/personnelAbsences/", {
        params: {
          schoolYearId,
          weekNumber: week.number,
          year: week.year
        },
        responseType: "json"
      })).then(response => response.data.map(personAbsences => {
        var _personAbsences$plann, _personAbsences$plann2;
        return {
          id: personAbsences.schoolPersonId,
          personnelType: personAbsences.personnelType,
          periods: intervals.sum(personAbsences.absentPeriods.map(period => intervals.nonEmpty(dateMinuteTime.parsePoint(period.start, this._moment), dateMinuteTime.parsePoint(period.end, this._moment)))),
          planned: (_personAbsences$plann = (_personAbsences$plann2 = personAbsences.planned) === null || _personAbsences$plann2 === void 0 ? void 0 : _personAbsences$plann2.map(absence => ({
            description: absence.description,
            period: intervals.nonEmpty(dateMinuteTime.parsePoint(absence.period.start, this._moment), dateMinuteTime.parsePoint(absence.period.end, this._moment))
          }))) !== null && _personAbsences$plann !== void 0 ? _personAbsences$plann : []
        };
      }), responseError);
    },
    /**
     * Gets substitutions of personnel assigned in areas during a week.
     * @param {AreaId[]} areaIds Ids of areas.
     * @param {Week} week The week the substitutions should intersect.
     * @returns {Promise<PersonSubstitutionsOfArea[]>} The personnel's substitutions.
     */
    personnelSubstitutions(areaIds, week) {
      if (areaIds.length === 0 || !week) {
        return Promise.resolve([]);
      }
      return this._axios().then(axios => axios.get("https://personale.api.kmd.dk/aula/api/v2/playgroundDuty/personnelSubstitutions?".concat(api.arrayToQueryString("areaIds", areaIds)), {
        params: {
          weekNumber: week.number,
          year: week.year
        },
        responseType: "json"
      })).then(response => response.data.map(x => ({
        areaId: x.areaId,
        substitutions: x.substitutions.map(substitution => ({
          breakId: substitution.playgroundBreakId,
          date: week.toDate(substitution.day == 0 ? 6 : substitution.day - 1),
          personId: substitution.schoolPersonId
        }))
      }), responseError));
    },
    /**
     * Gets descriptions of personnel types.
     * @returns {Promise<PersonnelTypeDescription[]>} The descriptions of personnel types.
     */
    personnelTypes() {
      return this._axios().then(axios => axios.get("https://personale.api.kmd.dk/aula/api/v2/playgroundDuty/personnelTypes/", {
        responseType: "json"
      })).then(response => response.data, responseError);
    },
    /**
     * Gets assignments of specified areas during specified week.
     * @param {AreaId[]} areaIds Ids of assignments' areas.
     * @param {?Week} week Week of assignments.
     * @returns {Promise<AssignmentsOfArea[]>} The assignments grouped by areas.
     */
    assignments(areaIds, week) {
      if (areaIds.length === 0 || !week) {
        return Promise.resolve([]);
      }
      return this._axios().then(axios => axios.get("https://personale.api.kmd.dk/aula/api/v2/playgroundDuty/assignments?".concat(api.arrayToQueryString("areaIds", areaIds)), {
        params: {
          year: week.year,
          weekNumber: week.number
        },
        responseType: "json"
      })).then(response => response.data.map(assignmentsOfArea => ({
        areaId: assignmentsOfArea.areaId,
        assignments: assignmentsOfArea.assignments.map(assignments => ({
          breakId: assignments.breakId,
          dayIndex: assignments.dayIndex,
          primary: assignments.primaryPersonnel.map(person => ({
            personId: person.schoolPersonId,
            personnelType: person.personnelType
          })),
          backup: assignments.backupPersonnel.map(person => ({
            personId: person.schoolPersonId,
            personnelType: person.personnelType
          })),
          timestamp: assignments.timestamp
        }))
      })), responseError);
    },
    /**
     * Deletes a break.
     * @param {BreakId} id Id of the break to delete.
     * @returns {Promise}
     */
    deleteBreak(id) {
      return this._axios().then(axios => axios.delete("https://personale.api.kmd.dk/aula/api/v2/playgroundDuty/breaks/" + id, {
        responseType: "json"
      })).then(() => {}, responseError);
    },
    /**
     * Adds a new break.
     * @param {NewBreak} break_ The break to save.
     * @returns {Promise<SavedBreak>} The saved break.
     */
    addBreak(break_) {
      return this._axios().then(axios => axios.put("https://personale.api.kmd.dk/aula/api/v2/playgroundDuty/breaks/", {
        areaId: break_.areaId,
        name: break_.name,
        start: break_.start,
        end: break_.end
      }, {
        responseType: "json"
      })).then(response => response.data, responseError);
    },
    /**
     * Copies assignments of an area in a source week to target weeks,
     * overwriting assignments already existing during the target weeks.
     * If target weeks contain source week, assignments of the source week are not overwritten.
     * @param {AreaId} areaId Id of the assignments' area.
     * @param {Week} sourceWeek The week of the assignments.
     * @param {Week[]} targetWeeks The weeks to which assignments are to be copied.
     * @returns {Promise<Week[]>} An array of weeks to which copying failed.
     * Assignments during these week are left unchanged.
     * Does never contain the source week.
     */
    copyAssignments(areaId, sourceWeek, targetWeeks) {
      return this._axios().then(axios => axios.post("https://personale.api.kmd.dk/aula/api/v2/playgroundDuty/copyAssignments", {
        areaId,
        sourceWeek,
        targetWeeks
      })).then(response => response.data.failedWeeks.map(weekId => Week.parsePoint(weekId, this._moment)), responseError);
    },
    /**
     * Saves assignments.
     * @param {AssignmentsToSave} assignments The assignments.
     * @returns {Promise<Timestamp>} The timestamp of saved assignments.
     */
    saveAssignments(assignments) {
      return this._axios().then(axios => axios.post("https://personale.api.kmd.dk/aula/api/v2/playgroundDuty/assignments/", {
        breakId: assignments.breakId,
        date: assignments.date,
        primaryPersonnel: assignments.primary.map(assignment => ({
          schoolPersonId: assignment.personId,
          personnelType: assignment.personnelType
        })),
        backupPersonnel: assignments.backup.map(assignment => ({
          schoolPersonId: assignment.personId,
          personnelType: assignment.personnelType
        })),
        timestamp: assignments.timestamp
      }, {
        responseType: "json"
      })).then(response => response.data, responseError);
    },
    /**
     * Updates an existing break.
     * @param {Break} break_ The break to save.
     * @returns {Promise<Timestamp>} The timestamp of saved break.
     */
    updateBreak(break_) {
      return this._axios().then(axios => axios.post("https://personale.api.kmd.dk/aula/api/v2/playgroundDuty/breaks/", {
        id: break_.id,
        name: break_.name,
        start: break_.start,
        end: break_.end,
        timestamp: break_.timestamp
      }, {
        responseType: "json"
      })).then(response => response.data, responseError);
    }
  };
  _module_exports = {
    Api
  };
  return _module_exports;
})(_module_shared_api,
  _module_shared_date,
  _module_shared_dateMinuteTime,
  _module_shared_intervals,
  _module_shared_time,
  _module_shared_week);

/**
 * WIDGET CODE
 **/

const {
  AulaTokenMixin
} = _module_shared_aula;
const {
  ConfirmationModal,
  ConfirmationModalMixin
} = _module_shared_confirmationModal;
const {
  Counts
} = _module_widget_counts;
const {
  ErrorIndicator
} = _module_shared_errorIndicator;
const {
  ErrorList,
  retryActionTemplate
} = _module_shared_errorList;
const Intervals = _module_shared_intervals;
const {
  IgnoreCanceledMixin
} = _module_shared_prelude;
const {
  SetFocus
} = _module_shared_setFocus;
const {
  MultipleSelectionInput
} = _module_shared_multipleSelectionInput;
const {
  ProgressIndicator
} = _module_shared_progressIndicator;
const {
  ProgressList
} = _module_shared_progressList;
const {
  SingleSelection
} = _module_shared_singleSelection;
const {
  SingleSelectionInput
} = _module_shared_singleSelectionInput;
const {
  TableRowToolbar
} = _module_shared_tableRowToolbar;
const {
  ValidatedDateInput
} = _module_shared_validatedDateInput;
const {
  ValidatedTimeInput
} = _module_shared_validatedTimeInput;
const {
  ValidatedValueInput
} = _module_shared_validatedValueInput;
const Week = _module_shared_week;
const {
  ViewportWidth
} = _module_shared_viewportWidth;
const {
  WidgetHeader
} = _module_shared_widgetHeader;
const {
  WidgetToolbar
} = _module_shared_widgetToolbar;
const {
  Api
} = _module_widget_api;
const {
  AssignmentPill
} = _module_widget_assignmentPill;
const {
  AssignmentPlaceholder
} = _module_widget_assignmentPlaceholder;
const {
  AssignmentsDetailsButton
} = _module_widget_assignmentsDetailsButton;
const {
  Actions
} = _module_widget_actions;
const {
  Breaks
} = _module_widget_breaks;
const {
  PersonPlannedAbsentOverlay
} = _module_widget_personPlannedAbsentOverlay;
const {
  PersonnelWithTypesBar
} = _module_widget_personnelWithTypesBar;
const {
  Plans
} = _module_widget_plans;
const {
  Settings
} = _module_widget_settings;
const {
  Store
} = _module_widget_store;
const {
  SubstitutedInStmIcon
} = _module_widget_substitutedInStmIcon;
function schools(settings, store, selectSchool) {
  return {
    school: new SingleSelection({
      change: (schoolCode, isUpdate) => {
        if (!isUpdate) {
          settings.schoolCode = schoolCode;
        }
        selectSchool(schoolCode);
      },
      fallback: {
        value: null,
        text: "Vælg skole"
      },
      initial: settings.schoolCode,
      preselectFirst: true
    }),
    get hasNone() {
      return store.schools.length === 0;
    },
    get hasMultiple() {
      return store.schools.length > 1;
    },
    updateSchools() {
      this.school.update(this._schools());
    },
    _schools() {
      return store.schools.map(x => ({
        value: x.code,
        text: x.name
      }));
    }
  };
}
function schoolYears(settings, store, currentWeek, selectSchoolYear) {
  const self = {
    schoolYear: new SingleSelection({
      change: (schoolYearId, isUpdate) => {
        if (!isUpdate) {
          settings.schoolYearId = schoolYearId;
        }
        selectSchoolYear(schoolYearId, self._schoolCode);
      },
      fallback: {
        value: null,
        text: "Vælg skoleår"
      },
      initial: settings.schoolYearId,
      preselectFirst: true
    }),
    get hasNone() {
      var _store$schools$find;
      return ((_store$schools$find = store.schools.find(x => x.code === this._schoolCode)) === null || _store$schools$find === void 0 ? void 0 : _store$schools$find.schoolYears.length) == 0;
    },
    get hasMultiple() {
      var _store$schools$find2;
      return ((_store$schools$find2 = store.schools.find(x => x.code === this._schoolCode)) === null || _store$schools$find2 === void 0 ? void 0 : _store$schools$find2.schoolYears.length) > 1;
    },
    selectSchool(schoolCode) {
      this._schoolCode = schoolCode;
      if (currentWeek) {
        const currentWeekPeriod = Intervals.nonEmpty(currentWeek.startOfWeek(), currentWeek.endOfWeek().addDays(1));
        const school = store.schools.find(x => x.code === schoolCode);
        if ((school === null || school === void 0 ? void 0 : school.schoolYears.some(x => x.id === this.schoolYear.value)) === false) {
          var _school$schoolYears$f;
          const currentSchoolYearId = school === null || school === void 0 ? void 0 : (_school$schoolYears$f = school.schoolYears.find(x => x.period.intersectsWith(currentWeekPeriod))) === null || _school$schoolYears$f === void 0 ? void 0 : _school$schoolYears$f.id;
          if (currentSchoolYearId) {
            this.schoolYear.select(currentSchoolYearId);
          }
        }
      }
      this.schoolYear.update(this._schoolYears());
    },
    _schoolCode: null,
    _schoolYears() {
      var _store$schools$find$s, _store$schools$find3;
      return (_store$schools$find$s = (_store$schools$find3 = store.schools.find(x => x.code === this._schoolCode)) === null || _store$schools$find3 === void 0 ? void 0 : _store$schools$find3.schoolYears.map(x => ({
        value: x.id,
        text: x.name
      }))) !== null && _store$schools$find$s !== void 0 ? _store$schools$find$s : [];
    }
  };
  return self;
}
function tabs(breaks, counts, plans, settings, activate) {
  var _settings$activeTab;
  return {
    activeTab: (_settings$activeTab = settings.activeTab) !== null && _settings$activeTab !== void 0 ? _settings$activeTab : 0,
    get activeViewModel() {
      switch (this.activeTab) {
        case 1:
          return counts;
        case 2:
          return breaks;
        default:
          return plans;
      }
    },
    activateTab(index) {
      if (index < 0 || index > 2) {
        return;
      }
      this.activeTab = index;
      settings.activeTab = index;
      activate(this.activeViewModel);
    }
  };
}

// @vue/component
export default {
  name: "PlaygroundDuty",
  components: {
    "assignments-details-button": AssignmentsDetailsButton,
    "assignment-pill": AssignmentPill,
    "assignment-placeholder": AssignmentPlaceholder,
    "confirmation-modal": ConfirmationModal,
    counts: Counts,
    "error-indicator": ErrorIndicator,
    "multiple-selection-input": MultipleSelectionInput,
    "person-planned-absent-overlay": PersonPlannedAbsentOverlay,
    "personnel-with-types-bar": PersonnelWithTypesBar,
    "progress-indicator": ProgressIndicator,
    "single-selection-input": SingleSelectionInput,
    "substituted-in-stm-icon": SubstitutedInStmIcon,
    "table-row-toolbar": TableRowToolbar,
    "validated-date-input": ValidatedDateInput,
    "validated-time-input": ValidatedTimeInput,
    "validated-value-input": ValidatedValueInput,
    "widget-header": WidgetHeader,
    "widget-toolbar": WidgetToolbar
  },
  directives: {
    setFocus: SetFocus
  },
  mixins: [AulaTokenMixin, ConfirmationModalMixin, IgnoreCanceledMixin],
  props: {
    axios: {
      type: Function,
      required: true
    },
    currentWeekNumber: {
      type: String,
      default: null
    },
    getAulaToken: {
      type: Function,
      required: true
    },
    institutionCode: {
      type: String,
      default: null
    },
    institutionFilter: {
      type: Array,
      default: null
    },
    isMobileApp: {
      type: Boolean,
      required: true
    },
    moment: {
      type: Function,
      required: true
    },
    placement: {
      type: String,
      required: true
    },
    sessionUUID: {
      type: String,
      required: true
    }
  },
  data: function () {
    const api = new Api(() => this.axios, () => this.token, () => this.moment);
    const store = new Store(this.$set.bind(this));
    const actions = new Actions(api, store);
    return {
      actions,
      api,
      breaks: null,
      schools: null,
      schoolYears: null,
      errors: new ErrorList(),
      plans: null,
      progress: new ProgressList(),
      settings: null,
      store,
      tabs: null,
      viewportWidth: new ViewportWidth()
    };
  },
  computed: {
    isReadOnly: function () {
      return this.placement === "NoticeBoard";
    }
  },
  watch: {
    institutionCode() {
      this.initialize();
    },
    institutionFilter() {
      this.refresh();
    }
  },
  mounted() {
    this.moment.locale("da");
    this.initialize();
  },
  destroyed() {
    this.viewportWidth.destroy();
  },
  methods: {
    initialize() {
      this.progress.track({
        id: "settings",
        message: "Indlæser indstillinger..."
      }, () => Settings.load(this.axios, () => this.token, () => this.moment, this.sessionUUID, this.placement === "NoticeBoard", this.institutionCode).then(settings => {
        const currentWeek = Week.parsePoint(this.currentWeekNumber, () => this.moment);
        this.breaks = new Breaks(this.actions, () => this.confirmationModal, () => this.$refs.breakEditionModal, settings, this.store);
        this.plans = new Plans(this.actions, () => this.$refs.assignmentsDetailsModal, () => this.confirmationModal, () => this.$refs.copyWeekModal, () => this.isMobileApp, () => this.moment, settings, this.store, this.viewportWidth, currentWeek, () => this.placement);
        this.schoolYears = schoolYears(settings, this.store, currentWeek, (schoolYearId, schoolCode) => {
          var _this$tabs;
          (_this$tabs = this.tabs) === null || _this$tabs === void 0 ? void 0 : _this$tabs.activeViewModel.selectSchoolYear(schoolYearId, schoolCode);
        });
        this.schools = schools(settings, this.store, schoolCode => {
          var _this$schoolYears;
          (_this$schoolYears = this.schoolYears) === null || _this$schoolYears === void 0 ? void 0 : _this$schoolYears.selectSchool(schoolCode);
        });
        this.tabs = tabs(this.breaks, {
          selectSchoolYear: () => {
            /* school year id and school code both updated through props and watchers */
          }
        }, this.plans, settings, activeViewModel => {
          activeViewModel.selectSchoolYear(this.schoolYears.schoolYear.value, this.schools.school.value);
        });
        this.settings = settings;
        this.refresh();
      }));
    },
    refresh() {
      this.store.clear();
      this.errors.track({
        id: "schools",
        message: "Kunne ikke indlæse skoler",
        dismissible: false,
        actions: [retryActionTemplate()]
      }, () => this.progress.track({
        id: "schools",
        message: "Indlæser skoler..."
      }, () => {
        var _this$institutionFilt;
        const schoolCodes = this.placement === "NoticeBoard" && this.institutionCode ? [this.institutionCode] : (_this$institutionFilt = this.institutionFilter) !== null && _this$institutionFilt !== void 0 ? _this$institutionFilt : [];
        return this.actions.loadSchools(schoolCodes).then(() => this.schools.updateSchools(), x => {
          this.schools.updateSchools();
          return Promise.reject(x);
        });
      }));
      this.errors.track({
        id: "personnelTypes",
        message: "Kunne ikke indlæse personaletyper",
        dismissible: false,
        actions: [retryActionTemplate()]
      }, () => this.progress.track({
        id: "personnelTypes",
        message: "Indlæser personaletyper..."
      }, () => this.actions.loadPersonnelTypes()));
    }
  }
};

</script>
<style scoped>
/* Overrides for Aula styles that conflict with ours */

.root /deep/ .custom-control > input.custom-control-input[type="checkbox"] {
  /* Reset position from 'relative' to 'absolute', set by 'input[type="checkbox"]' in 'form.scss'.
     This removes the element from document flow when inside of a 'b-checkbox' component,
     and so removes an unnecessary top "margin" within the component. */
  position: absolute;
}

/* Styles for utility CSS classes */

/* no-text-overflow prevents text from overflowing by wrapping it */

.root /deep/ .no-text-overflow,
.root /deep/ .no-text-overflow * {
  text-overflow: ellipsis;
  overflow: hidden;
  /* There is a problem with text that contains words longer than the width of the viewport.
     In such situations, the text might still overflow, making the body to be wider than the viewport.
     Because of that, we let the text break between words, and too long words to break anywhere inside it.
     However, this can only be achieved with 'overflow-wrap' set to 'anywhere',
     which is not supported yet on all browsers.
     When it is not supported, we set the property to 'break-word',
     but then too long words are not broken in some situations, namely when:
      - when any of element's parent element is a child of a flexbox container,
        and its 'overflow' is not set to 'hidden` or its `min-width` is not set to '0';
        this cannot be worked around reliably as we do not control anything above widget's root element;
      - the element is a table cell;
        to work around this we could set the element's 'max-width' to something different than 'auto';
        however any value set would result in suboptimal use of available space,
        when the element could receive more available white space, but does not.
     These same issues apply to setting of 'white-space' property to 'nowrap`.
     Hopefully this should not be an issue very often,
     as very long words are used rarely in any kind of names. */
  overflow-wrap: break-word;
  overflow-wrap: anywhere;
}

.root /deep/ .no-text-overflow.dropdown-item,
.root /deep/ .no-text-overflow.dropdown-header {
  /* Re-enable text wrapping in dropdown items as otherwise the text will overflow,
     if wider than the width of the viewport.
     The drawback is that long enough text will break,
     even if there is enough horizontal space to fit it in one line. */
  white-space: normal;
}

.root /deep/ .width-scale-35 {
  --width-scale: 0.35;
}

@media (min-width: 1200px) {
  /* Apply to a b-modal using xl size,
     through `dialog-class="modal-xl-wider"`,
     to make the modal a little wider than the default 1140px. */

  .root /deep/ .modal-xl-wider {
    max-width: 1200px;
  }
}

/* Styles for AssignmentPill component, code in './assignmentsPill.js' */

/* CSS variables are inherited from .plans-tab, defined in './plans.css' */

.root /deep/ .assignment-pill {
  align-items: baseline;
  background-color: var(--assignment-background);
  border-radius: var(--assignment-border-radius);
  display: flex;
  justify-content: center;
  margin: var(--assignment-margin);
  min-width: 2rem;
  padding: var(--assignment-padding);
  text-align: center;
}

.root /deep/ .assignment-pill.current-user {
  border-style: solid;
  border-width: 1px;
  font-weight: bold;
}

.root /deep/ .assignment-pill[data-status="unavailable"] {
  background-color: var(--assignment-status-unavailable-color);
}

.root /deep/ .assignment-pill[data-status="used"] {
  background-color: var(--assignment-status-used-color);
}

.root /deep/ .assignment-pill[data-status="replaced"] {
  background-color: var(--assignment-status-replaced-color);
}

/* Styles for AssignmentPlaceholder component, code in './assignmentPlaceholder.js' */

/* CSS variables are inherited from .plans-tab, defined in './plans.css' */

.root /deep/ .assignment-placeholder {
  border: 2px dashed black;
  padding: 0.5em;
  visibility: hidden;
}

.root /deep/ .assignment-placeholder[data-visible] {
  visibility: visible;
}

.root /deep/ .assignment-placeholder[data-visible][data-hover] {
  background-color: hsla(120, 100%, 75%, 0.5);
}

.root /deep/ .assignment-placeholder[data-placement="assignments-empty-primary"],
.root /deep/ .assignment-placeholder[data-placement="assignments-empty-backup"] {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
}

.root /deep/ .assignment-placeholder[data-placement="assignments-empty-primary"] {
  border-radius: var(--assignment-border-radius) 0 0
  var(--assignment-border-radius);
  left: 0;
  right: calc(50% + (var(--assignment-margin) / 2));
}

.root /deep/ .assignment-placeholder[data-placement="assignments-empty-backup"] {
  border-radius: 0 var(--assignment-border-radius)
  var(--assignment-border-radius) 0;
  left: calc(50% + (var(--assignment-margin) / 2));
  right: 0;
}

.root /deep/
.assignment-placeholder-container[data-placement="assignments-inline"] {
  /* Create block formatting context for absolutely positioned inline placeholder,
     by setting position to relative */
  position: relative;
  align-self: center;
  /* Set height as otherwise the item does not get any */
  height: 2em;
}

.root /deep/ .assignment-placeholder[data-placement="assignments-inline"] {
  position: absolute;
  border-radius: var(--assignment-border-radius);
  left: calc(var(--assignment-margin) * -3);
  right: calc(var(--assignment-margin) * -3);
  top: 0;
  bottom: 0;
}

.root /deep/ .assignment-placeholder[data-placement="assignments-group"] {
  position: absolute;
  border-radius: var(--assignment-border-radius);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

/* Styles for AssignmentsDetailsButton component, code in './assignmentsDetailsButton.js' */

/* CSS variables are inherited from .plans-tab, defined in './plans.css' */

.root /deep/ .assignments-details-button[data-placement|="assignments"] {
  /* Change colors so the button is not distracting from other content */
  background-color: var(--assignment-background);
  color: black;
  /* Set left margin to "auto", so the button is always on the right side of flex container */
  margin: var(--assignment-margin) var(--assignment-margin)
  var(--assignment-margin) auto;
}

.root /deep/ .assignments-details-button[data-placement|="assignments"]:hover {
  background-color: #222350;
  color: white;
}

.root /deep/ .assignments-details-button[data-placement="break"] {
  /* Set left margin to "auto", so the button is always on the right side of grid/flex container */
  margin-left: auto;
}

/* Styles for PersonPlannedAbsentOverlay component, code in './personPlannedAbsentOverlay.js' */

/* CSS variables are inherited from .plans-tab, defined in './plans.css' */

.root /deep/ .person-planned-absent-overlay {
  /* Ensure size of this element matches that of the overlay's BFC element (.plan-day-break).
     Otherwise, the overlay's contents will not get hidden,
     when dragging inside of the BFC element, but outside of its contents (.play-day-break-contents). */
  height: 100%;
  width: 100%;
  --person-planned-absent-overlay-border-color: #b50050;
  --person-planned-absent-overlay-border-width: 3px;
}

.root /deep/ .person-planned-absent-overlay .overlay {
  border-color: var(--person-planned-absent-overlay-border-color);
  border-style: solid;
  border-width: var(--person-planned-absent-overlay-border-width);
  visibility: hidden;
  /* Cover whole area of plan's day and break cell */
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  /* Allow drag&drop interaction with placeholder components */
  pointer-events: none;
  /* Show above primary/backup placeholders */
  z-index: 100;
  /* Use flex layout to center planned absences */
  display: flex;
  flex-direction: column;
}

.root /deep/ .person-planned-absent-overlay .overlay[data-visible] {
  visibility: visible;
}

.root /deep/ .person-planned-absent-overlay .overlay[data-contents-hidden] * {
  visibility: hidden;
}

.root /deep/ .person-planned-absent-overlay .planned-absences {
  align-self: center;
  background-color: var(--person-planned-absent-overlay-border-color);
  border-collapse: collapse;
  border-color: var(--person-planned-absent-overlay-border-color);
  border-radius: 0 0 10px 10px;
  border-style: solid;
  border-width: var(--person-planned-absent-overlay-border-width);
  color: #f6f7f8;
  padding: 5px;
}

/* Styles for PersonWithTypePill component, code in './personWithTypePill.js' */

/* CSS variables are inherited from .plans-tab, defined in './plans.css' */

.root /deep/ .person-with-type-pill {
  align-items: baseline;
  background-color: var(--assignment-background);
  border-radius: var(--assignment-border-radius);
  display: flex;
  justify-content: center;
  margin: var(--assignment-margin);
  min-width: 2rem;
  padding: var(--assignment-padding);
  text-align: center;
}

.root /deep/ .person-with-type-pill.current-user {
  border-width: 1px;
  border-style: solid;
  font-weight: bold;
}

/* Styles for PersonnelWithTypesBar component, code in './personnelWithTypesBar.js' */

/* CSS variables are inherited from .plans-tab, defined in './plans.css' */

.root /deep/ .personnel-with-types-bar {
  display: flex;
  /* Limit height to around two lines of pills,
     so that more drop targets for personnel with types are visible at once. */
  flex-wrap: wrap;
  max-height: max(6em, 15vh);
  overflow: auto;
  white-space: nowrap;
  align-items: baseline;
}

.root /deep/ .personnel-with-types-bar > * {
  margin: var(--assignment-margin);
}

.root /deep/ .personnel-with-types-bar > .filter-dropdown .dropdown-toggle {
  /* Change colors so the button is not distracting from other content */
  color: black;
  background-color: var(--assignment-background);
}

.root /deep/ .personnel-with-types-bar > .filter-dropdown .dropdown-toggle:hover {
  background-color: #222350;
  color: white;
}

.root /deep/ .personnel-with-types-bar > .search-input {
  flex-basis: 15ch;
  /* Reset Bootstrap styles leading to oversized input */
  margin: var(--assignment-margin);
  min-height: initial;
  padding: var(--assignment-padding);
}

/* Styles for plans */

.assignments-details-modal .flip-list-move {
  transition: transform 0.3s;
}

.plans-tab {
  --day-break-background: hsl(202, 22%, 93%);
  --day-background: hsl(202, 22%, 75%);
  --break-background: hsl(202, 22%, 85%);
  --assignment-background: hsl(202, 22%, 85%);
  --assignment-border-radius: 0.5em;
  --assignment-margin: 0.3em;
  --assignment-padding: 0.3em;
  --assignment-status-unavailable-color: #f39b9b;
  --assignment-status-used-color: palegreen;
  --assignment-status-replaced-color: gold;
}

.plan + .plan {
  /* Add vertical spacing between plans with top margin */
  margin-top: 1em;
}

.plan h3 {
  /* Make font-size smaller than that of h2 in widget header */
  font-size: 1.2em;
}

.plan h4,
.plan h5 {
  /* Make font-size smaller than that of h3 */
  font-size: 1em;
  margin: 0;
}

.plan .personnel-with-types-bar-container {
  /* Make the bar always visible above plans' contents,
     to allow drag&drop without scrolling */
  position: sticky;
  top: 0;
  z-index: 100;
}

.plan .personnel-with-types-bar {
  border: 1px solid var(--day-background);
  background-color: var(--day-break-background);
  border-radius: var(--assignment-border-radius);
}

.plan-header {
  align-items: baseline;
  display: flex;
  flex-wrap: wrap;
}

.plan-header > * + * {
  margin-left: 1em;
}

.plan-contents.grid {
  display: grid;
  grid-auto-rows: auto;
  grid-auto-columns: 1fr;
  grid-template-columns: auto;
  grid-template-rows: auto;
}

.plan-contents > * {
  margin: 0.25em;
  padding: 0.5em;
}

.plan-contents.grid > * {
  /* Place in column set by 0-based --day-index; or in first column if not set */
  grid-column: calc(var(--day-index, -1) + 2);
  /* Place in row set by 0-based --break-index; or in first row if not set */
  grid-row: calc(var(--break-index, -1) + 2);
}

.plan-day {
  align-items: baseline;
  background-color: var(--day-background);
  display: flex;
}

.plan-day:not(.grid) > * + * {
  margin-left: 0.5em;
}

.plan-day.grid {
  flex-direction: column;
  align-items: center;
}

.plan-break {
  align-items: baseline;
  background-color: var(--break-background);
  display: flex;
}

.plan-break:not(.grid) {
  flex-wrap: wrap;
  /* Remove margin between plan break and plan day-break */
  margin-bottom: 0;
}

.plan-break.grid {
  max-width: 20ch;
  text-align: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.plan-break:not(.grid) > * + *:not(.assignments-details-button) {
  margin-left: 0.5em;
}

.plan-break.grid > * {
  /* Set max width to 100%, to allow truncation of too long text */
  max-width: 100%;
}

.plan-day-break {
  background-color: var(--day-break-background);
  /* Create block formatting context
     for absolutely positioned empty primary/backup placeholders and progress indicator,
     as well as person planned absences overlay,
     by setting position to relative */
  position: relative;
}

.plan-day-break-contents {
  display: flex;
}

.plan-day-break-contents.empty {
  align-items: flex-start;
  flex-direction: row;
}

.plan-day-break-contents:not(.empty) {
  flex-direction: column;
}

.plan-day-break:not(.grid) {
  border: 2px solid var(--break-background);
  /* Remove margin between plan break and plan day-break */
  margin-top: 0;
}

.plan-day-break .compact-header {
  /* Add top margin and padding to align header
     horizontally with first line of assignment items */
  margin-top: var(--assignment-margin);
  padding-top: var(--assignment-padding);
  margin-right: var(--assignment-margin);
  /* Center items vertically using flex layout */
  display: flex;
  align-content: center;
}

.plan-day-break .compact-header-icon {
  /* Make icon size match that of assignment items' text, with font-size */
  font-size: 1.6em;
}

.plan-day-break .assignment-group {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  /* Stretch the container in case it contains details button,
     so that the button is on the right side of the parent container */
  justify-self: stretch;
  /* Create block formatting context for absolutely positioned group placeholder,
     by setting position to relative */
  position: relative;
}

.plan-day-break .assignment-group > * {
  order: var(--order);
}

.plan-day-break .assignment {
  flex-grow: 1;
}

.plan-day-break + .plan-day:not(.grid) {
  margin-top: 1em;
}

/* Styles for MultipleSelectionInput component, code in 'shared/multipleSelectionInput.js' */

.root /deep/ .btn-input {
  background-color: white;
  color: rgb(34, 35, 80);
}

.root /deep/ .multiple-selection-input-dropdown .dropdown-menu {
  padding-bottom: 0;
  padding-top: 0;
}

.root /deep/ .multiple-selection-input-dropdown .dropdown-toggle {
  /* Use flex to force chevron to stay in the same line as text */
  display: flex;
  align-items: center;
  justify-content: stretch;
  /* Use for left and right padding the value of top/bottom padding */
  padding-left: 14px;
  padding-right: 14px;
}

.root /deep/ .multiple-selection-input-dropdown .select {
  margin: 0;
  padding: 0;
}

.root /deep/ .multiple-selection-input-dropdown .text {
  flex-grow: 1;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* Styles for ProgressIndicator component, code in 'shared/progressIndicator.js' */

.root /deep/ .progress-indicator-root {
  /* Create block formatting context for absolutely positioned status,
     by setting position to relative */
  position: relative;
}

.root /deep/ .progress-indicator-status {
  /* Position spinner and message at the top center of content, above it */
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  z-index: 2000;
}

.root /deep/ .progress-indicator-content[data-obscured] {
  /* Make the content obscured by the status */
  opacity: 0.1;
  /* Expand content vertically to encompass absolutely positioned status */
  min-height: 2em;
}

/* Styles for TableRowToolbar component, code in 'shared/tableRowToolbar.js' */

.root /deep/ .table-row-toolbar {
  /* Separate links with enough space to accommodate touch input,
     but not so much that they would unnecessarily wrap. */
  --disabled-color: lightgray;
  --spacing: 0.75rem;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: calc(var(--spacing) * -1);
  margin-left: calc(var(--spacing) * -1);
}

.root /deep/ .table-row-toolbar > * {
  margin-left: var(--spacing);
  margin-bottom: var(--spacing);
}

.root /deep/ .table-row-toolbar > [aria-disabled] {
  color: var(--disabled-color);
  cursor: default;
}

/* Styles for WidgetHeader component, code in 'shared/widgetHeader.js' */

.root /deep/ .widget-header {
  --margin: 0.5rem 0 0.4rem 0;
}

.root /deep/ .widget-header h2 {
  padding: 0;
  margin: var(--margin);
}

.root /deep/ .widget-header p {
  font-weight: 800;
  color: #aaa;
  margin: var(--margin);
  padding: 0;
}

.root /deep/ .widget-header-separator {
  border-bottom: 2px solid #549ec7;
  margin-bottom: 0.5em;
}

/* Styles for WidgetToolbar component, code in 'shared/widgetToolbar.js' */

.root /deep/ .widget-toolbar {
  display: flex;
  flex-wrap: wrap;
  --item-width: initial;
  /* Non-last items get the following margins:
      - right, to add space between it and the item to its right,
      - bottom, to add space between it and the item below it */
  --item-margin: 0 1em 1em 0;
  /* Last item does not get right margin */
  --last-item-margin: 0 0 1em 0;
  /* Fix width of select inputs so that
     they don't change width when their selection changes */
  --select-width: 20rem;
  /* Enable scaling of --select-width and --item-width by --width-scale */
  --enable-width-scale: 1;
}

/* In narrow placement, put every item in its own row */

.root /deep/ .widget-toolbar.narrow {
  /* Remove right margin on all items */
  --item-margin: var(--last-item-margin);
  /* Every item gets its own row */
  --item-width: 100%;
  /* Select inputs fit their parents, to not overflow */
  --select-width: 100%;
  /* Disable scaling of 100% widths in --select-width and --item-width by --width-scale */
  --enable-width-scale: 0;
}

.root /deep/ .widget-toolbar > * {
  margin: var(--item-margin);
  /* Allow per-element variability of width by scaling --item-width by --width-scale,
     but only when --enable-width-scale is 1, and not if it is 0. */
  width: calc(
    var(--item-width) +
    (
    (var(--width-scale, 1) - 1) * var(--enable-width-scale) *
    var(--item-width)
    )
  );
}

.root /deep/ .widget-toolbar > button {
  /* Align buttons center, as top/bottom looks weird
     next to form groups with an input, label and validation errors */
  align-self: center;
}

.root /deep/ .widget-toolbar > :last-item {
  margin: var(--last-item-margin);
}

.root /deep/ .widget-toolbar input,
.root /deep/ .widget-toolbar select {
  /* Remove bottom margin set by Bootstrap */
  margin-bottom: 0;
}

.root /deep/ .widget-toolbar select,
.root /deep/ .widget-toolbar .dropdown,
.root /deep/ .widget-toolbar .dropdown-toggle {
  /* Fix width of selection components used in toolbar,
     so it doesn't change with selection change.
     Also prevents overflow in narrow view. */
  /* Allow per-element variability of width by scaling --select-width by --width-scale,
     but only when --enable-width-scale is 1, and not if it is 0. */
  width: calc(
    var(--select-width) +
    (
    (var(--width-scale, 1) - 1) * var(--enable-width-scale) *
    var(--select-width)
    )
  );
}
</style>
