<template>
  <div class="aula-widget-placeholder">
    <template v-for="(item, i) in listOfWidgets">
      <template v-if="item.type == widgetTypesEnum.SSO">
        <widget-link
          :key="i"
          :placement="placement"
          :supplier="item.widgetSupplier"
          :icon="item.icon"
          :s-s-oaction="item.url"
          :widget-title="item.name"
          widget-type="SSO"
          :widget-i-d="item.widgetId"
          :widget-version="item.widgetVersion"
          :badge-count="getWidgetNotificationCount(item)"
        />
      </template>
      <template v-else>
        <widget-content
          :key="i"
          :name="item.name"
          :placement="placement"
          :supplier="item.widgetSupplier"
          :restricted-groups="item.restrictedGroups"
          :widget-type="item.type"
          :widget-i-d="item.widgetId"
          :widget-version="item.widgetVersion"
          :widget-url="item.url"
          :badge-count="getWidgetNotificationCount(item)"
          :notifications="getWidgetNotifications(item)"
        />
      </template>
    </template>
  </div>
</template>

<script>
import WidgetContent from './WidgetContent';
import WidgetLink from './WidgetLink';
import {widgetTypes} from '../../../shared/enums/widgetTypes';
import {mapGetters} from "vuex";
import {types} from "../../store/types/types";
import {widgetUtil} from "../../../shared/utils/widgetUtil";

export default {
  components: {
    WidgetContent,
    WidgetLink,
  },
  props: {
    listOfWidgets: {type: Array, default: () => []},
    placement: {type: String, default: ''},
  },
  data: function () {
    return {
      widgetTypesEnum: widgetTypes,
    };
  },
  computed: {
    ...mapGetters({
      notifications: types.GET_NOTIFICATIONS,
    }),
  },
  methods: {
    getWidgetNotificationCount(widget) {
      return this.getWidgetNotifications(widget).length;
    },
    getWidgetNotifications(widget) {
      return widgetUtil.getNotifications(this.notifications, {widgetId: widget.id});
    },
  }
};
</script>
