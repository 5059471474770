<template>
  <div class="important-date-container" :class="{ mobile: !isAside }">
    <div class="important-date-header">
      <div class="important-date-icon">
        <i
          class="icon-Aula_calendar"
          tabindex="0"
          role="button"
          :aria-label="'ARIA_LABEL_IMPORTANT_DATA' | fromTextKey"
          @click="triggerShowCalendarOverview"
          @keydown.enter="triggerShowCalendarOverview"
        />
      </div>
      <div class="important-date-title">
        <span>{{ 'CALENDAR_IMPORTANT_DATE' | fromTextKey }}</span>
      </div>
    </div>
    <div class="important-date-body">
      <div v-if="importantDates.length === 0" class="no-important-dates">
        {{ 'CALENDAR_NO_IMPORTANT_DATES' | fromTextKey }}
      </div>
      <template v-for="(item, index) in importantDatesFiltered">
        <div :key="index" class="important-date-row">
          <div
            class="important-date-col-month"
            tabindex="0"
            role="button"
            @click="triggerShowCalendarOverview"
            @keydown.enter="triggerShowCalendarOverview"
          >
            <div class="date">
              {{ moment(item.start).format('DD') }}
              <template v-if="!item.allDay && moment(item.start).date() != moment(item.end).date()">
                - {{ moment(item.end).format('DD') }}
              </template>
              <template v-else-if="item.allDay && moment(item.start).utc().date() != moment(item.end).utc().date()">
                - {{ moment(item.end).utc().format('DD') }}
              </template>
            </div>
            <div class="month">
              {{ moment(item.start).format('MMM') }}
            </div>
          </div>
          <div class="important-date-col-detail">
            <div class="description">
              {{ item.title }}
              <template v-if="item.type == eventTypes.HOLIDAY">
                ({{ moment(item.start).format('DD MMMM') }} - {{ moment(item.end).format('DD MMMM') }})
              </template>
            </div>
            <div v-if="item.allDay" class="from-to">
              {{ 'CALENDAR_LABEL_EVENT_ALL_DAY' | fromTextKey }}
            </div>
            <div v-else class="from-to">
              {{ moment(item.start).format('HH:mm') }} -
              {{ moment(item.end).format('HH:mm') }}
            </div>
          </div>
          <div v-if="concerningChilds(item).length > 0" class="important-date-col-child">
            <AvatarGroup class="mr-2" :profiles="concerningChilds(item)" />
          </div>
        </div>
        <div :key="'clear' + index" class="clearfix" />
      </template>
    </div>
    <div v-if="isShowLoadMore" class="important-date-footer pull-right">
      <b-btn variant="link" size="sm" @click.stop="loadMoreDates">
        <template v-if="isLoadMore">
          {{ 'CALENDAR_IMPORTANT_DATE_MORE' | fromTextKey }}
          <i class="icon-Aula_forward-arrow" />
        </template>
        <template v-else>
          {{ 'CALENDAR_IMPORTANT_DATE_SEE_MORE' | fromTextKey }}
          <i class="icon-Aula_forward-arrow" />
        </template>
      </b-btn>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { mapGetters } from 'vuex';
import moment from 'moment-timezone';
import { types } from '../../store/types/types';
import { eventTypeEnum } from '../../../shared/enums/eventTypeEnum';
import AvatarGroup from '../../../shared/components/AvatarGroup.vue';

export default {
  components: { AvatarGroup },
  props: {
    isAside: {
      type: Boolean,
      default: true,
    },
  },
  data: function () {
    return {
      moment: moment,
      eventTypes: eventTypeEnum,
      isLoadMore: false,
      importantDates: [],
    };
  },
  computed: {
    ...mapGetters({
      profile: types.GET_CURRENT_PROFILE,
      children: types.GET_CHILDREN,
      isProfileLoaded: types.IS_PROFILE_LOADED,
      importantDatesResult: types.GET_IMPORTANT_DATES,
    }),
    importantDatesFiltered() {
      if (!this.isLoadMore) {
        return this.importantDates.slice(0, 5);
      } else {
        return this.importantDates.slice(0, 10);
      }
    },
    isShowLoadMore() {
      return this.importantDates.length > 5;
    },
  },
  methods: {
    ...mapActions({
      loadImportantDates: types.LOAD_IMPORTANT_DATES,
    }),
    concerningChilds(event) {
      const concerningChild = [];
      for (const child of this.children) {
        // if child is invitee
        if (event.belongsToProfiles.includes(child.id)) {
          concerningChild.push(child);
        }
      }
      return concerningChild;
    },
    loadMoreDates() {
      if (this.isLoadMore && this.importantDates.length > 5) {
        this.triggerHideCalendarOverview();
        this.$router.push({ name: 'calendar' });
      } else {
        this.isLoadMore = true;
      }
    },
    triggerShowCalendarOverview() {
      this.$emit('showCalendarOverview');
    },
    triggerHideCalendarOverview() {
      this.$emit('hideCalendarOverview');
    },
    initImportantDates() {
      this.loadImportantDates({ limit: 11 }).then(() => {
        if (this.importantDatesResult != null && this.importantDatesResult.length > 0) {
          this.importantDates = this.importantDatesResult.sort(function (a, b) {
            const aDate = new Date(a.start);
            const bDate = new Date(b.start);
            if (aDate > bDate) {
              return 1;
            } else if (aDate < bDate) {
              return -1;
            } else {
              return 0;
            }
          });
        }
      });
    },
  },
  watch: {
    isProfileLoaded() {
      if (this.isProfileLoaded) {
        this.initImportantDates();
      }
    },
  },
  mounted() {
    if (this.isProfileLoaded) {
      this.initImportantDates();
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';

.related-profile-avatar {
  --width: 40px;
  --height: 40px;
  --font-size: 12px;
  --background-color: var(--color-white);
  --color: var(--color-primary-dark);
  --border: 2px solid var(--color-primary-dark);
}

.important-date-container {
  background: white;
  width: 100%;
  margin-top: 75px;
  @include breakpoint-lg-down() {
    margin-top: 12px;
  }
  .important-date-header {
    height: 63px;
    display: flex;
    .important-date-icon {
      height: 63px;
      width: 51px;
      text-align: center;
      margin-right: 5px;
      padding-top: 18px;
      .icon-Aula_calendar {
        font-size: 24px;
        color: white;
      }
    }
    .important-date-title {
      flex: 1;
      height: 63px;
      background-color: $color-grey-base;
      text-indent: 20px;
      text-transform: uppercase;
      font-size: 14px;
      padding-top: 20px;
      .theme-employee & {
        color: $color-primary-darker-employee;
      }
      .theme-guardian & {
        color: $color-primary-darker-guardian;
      }
      .theme-child & {
        color: $color-primary-darker-child;
      }
      .theme-admin & {
        color: $color-primary-darker-admin;
      }
    }
  }
  .no-important-dates {
    margin-top: 5px;
  }
  .important-date-footer {
    clear: both;
  }
  .important-date-body {
    .important-date-row {
      padding: 10px 0;
      margin: 0 auto;
      .important-date-col-detail {
        padding: 0;
        float: left;
        max-width: calc(100% - 115px);
        width: 100%;
        margin-bottom: 10px;
        .from-to {
          font-size: 12px;
        }
      }
      .important-date-col-month {
        text-align: center;
        font-size: 13px;
        padding: 2px;
        max-width: 52px;
        min-width: 52px;
        float: left;
        margin-right: 15px;
        .date {
          font-weight: bold;
        }
        .month {
          font-size: 11px;
          text-transform: uppercase;
        }
      }
      .important-date-col-child {
        float: right;
        max-width: 45px;
        width: 45px;
        .user-img {
          width: 30px;
          border-radius: 30px;
          margin-top: 5px;
        }
        /deep/ {
          .user-icon {
            border: #ffffff 0.5px solid;
            height: 36px;
            width: 36px;
            .user-img {
              height: 100%;
              width: 100%;
            }
          }
        }
      }
    }
  }
  &.mobile {
    width: 100%;
  }
}

//Theme styling
.theme-guardian {
  .important-date-icon {
    background: $color-primary-darker-guardian;
  }
}

.theme-employee {
  .important-date-icon {
    background: $color-primary-darker-employee;
  }
}

.theme-child {
  .important-date-icon {
    background: $color-primary-darker-child;
  }
}

.theme-admin {
  .important-date-icon {
    background: $color-primary-darker-admin;
  }
}
</style>
