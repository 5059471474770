<template>
  <div class="aula-widget">
    <div v-if="isPreviewMode && restrictedGroupNames" class="restricted-groups">
      {{ restrictedGroupNames }}
    </div>
    <div class="widgetBox">
      <div class="id">
        {{ id }}
      </div>
      <div class="header">
        <AulaBadge v-if="badgeCount" class="mr-1">{{ badgeCount }}</AulaBadge>
        <div class="widget-name text-truncate">{{ name }}</div>
        <div v-if="widgetTitle" class="widget-supplier">{{ widgetTitle }}</div>
      </div>
      <iframe
        v-if="isIframeWidget"
        ref="iframe"
        :title="widgetTitle"
        class="iframe-content"
        :src="widgetUrl"
        :style="{height: iframeHeight}"
      />
      <component :is="widgetInstance" v-else v-bind="{...widgetProps}" class="content"/>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import axios from 'axios';
import moment from 'moment-timezone';
import { widgets } from '../../../widgets/widgets';
import { types } from '../../store/types/types';
import {mapActions, mapGetters, mapMutations} from 'vuex';
import { widgetTypes } from '../../../shared/enums/widgetTypes';
import $ from 'jquery';
import {postMessageController} from "../../../shared/controllers/PostMessageController";
import {widgetRequestTypes} from "../../../shared/enums/widgetRequestTypes";
import {widgetUtils} from "../../../shared/utils/widgetUtils";
import {WidgetPropsDto} from "../../dtos/widgetProps.dto";
import {WidgetTokenDto} from "../../dtos/widgetToken.dto";
import AulaBadge from "../../../shared/components/AulaBadge";

export default {
  components: {AulaBadge},
  props: {
    name: {type: String, default: ''},
    placement: {type: String, default: ''},
    widgetID: {type: String, default: ''},
    widgetVersion: {type: String, default: ''},
    widgetType: {type: String, default: ''},
    restrictedGroups: {type: Array, default: () => []},
    supplier: {type: String, default: ''},
    source: {type: String, default: 'portal'},
    institutionCode: {type: String, default: ''},
    isMobile: {type: Boolean, default: false},
    widgetUrl: {type: String, default: ''},
    badgeCount: {type: Number, default: 0},
    notifications: { type: Array, default: () => [] },
  },
  data: function () {
    return {
      moment,
      userProfile: null,
      instance: null,
      restrictedGroupNames: null,
      aulaToken: null,
      iframeHeight: '',
    };
  },
  computed: {
    ...mapGetters({
      profile: types.GET_CURRENT_PROFILE,
      currentUniLogin: types.GET_CURRENT_UNILOGIN,
      activeInstitutions: types.GET_ACTIVE_INSTITUTIONS,
      activeChildrenUniLogin: types.GET_ACTIVE_CHILDREN_UNILOGIN,
      activeWeek: types.GET_CURRENT_WEEK,
      isSteppedUp: types.GET_GLOBAL_STEPPED_UP,
      isPreviewMode: types.GET_IS_PREVIEW_MODE,
      group: types.GET_ACTIVE_GROUP,
    }),
    id() {
      return 'W' + this.widgetID + 'V' + this.widgetVersion;
    },
    widgetTitle() {
      if (this.supplier === '') {
        return '';
      }
      return this.$options.filters.fromTextKey('WIDGET_SUPPLIED_BY') + ' ' + this.supplier;
    },
    isIframeWidget() {
      return this.widgetType.toLowerCase() === widgetTypes.IFRAME;
    },
    getGroupId() {
      return this.$route.params.groupId;
    },
    axios() {
      const copy = axios.create();
      copy.create = axios.create;
      return copy;
    },
    assuranceLevel() {
      return this.isSteppedUp ? 3 : 2;
    },
    widgetProps() {
      const widgetType = this.widgetType.toLowerCase();
      const placement = this.placement;
      const props = {
        widgetID: this.widgetID,
        sessionUUID: this.currentUniLogin,
        placement,
        currentWeekNumber: this.activeWeek,
        isMobileApp: this.isMobile,
        userProfile: this.profile.role,
        group: this.group.uniGroupId,
        source: this.source,
        institutionCode: this.institutionCode,
      };

      if ([widgetTypes.SECURE, widgetTypes.NORMAL, widgetTypes.NOTICEBOARD].includes(widgetType)) {
        props.axios = this.axios;
        props.moment = this.moment;
      }

      if (widgetTypes.SECURE === widgetType) {
        props.triggerStepUp = this.triggerStepUp;
        props.deleteNotifications = this.removeNotifications;
      }

      if ([widgetTypes.SECURE, widgetTypes.IFRAME].includes(widgetType)) {
        props.notifications = this.notifications.map(notification => ({
            widgetNotificationId: notification.widgetNotificationId,
            notificationMessage: notification.notificationMessage,
            triggered: notification.triggered,
            institutionCode: notification.institutionCode,
          }));
        props.assuranceLevel = this.assuranceLevel;
        props.childFilter = this.activeChildrenUniLogin.filter((v, i, a) => a.indexOf(v) === i);
        props.institutionFilter = this.activeInstitutions.filter((v, i, a) => a.indexOf(v) === i);
      }

      if (
        [widgetTypes.NOTICEBOARD, widgetTypes.SECURE].some(type => type === widgetType)
      ) {
        // this method must be in an anonymous function in order to set this.aulaToken for the requesting widget.
        props.getAulaToken = function () {
          const widgetItemID = props.widgetID;

          if (widgetItemID == null) {
            // eslint-disable-next-line no-console
            console.error('could not determine widgetId. Will not issue aula token');
            return;
          }

          widgetUtils.getAulaToken(widgetItemID, placement).then((aulaToken) => {
            Vue.nextTick(() => {
              this.aulaToken = aulaToken;
            });
          });
        };
      }

      return props;
    },
    widgetInstance() {
      return widgets[this.id];
    }
  },
  methods: {
    ...mapMutations({
      setStepUpNotification: types.MUTATE_SET_NOTIFICATION_STEP_UP,
    }),
    ...mapActions({
      deleteNotifications: types.DELETE_NOTIFICATIONS,
    }),
    async removeNotifications(widgetNotificationIds) {
      const notifications = [];
      for (const widgetNotificationId of widgetNotificationIds) {
        const notification = this.notifications.find(notification => notification.widgetNotificationId === widgetNotificationId);
        if (notification != null) {
          notifications.push({
            notificationId: notification.notificationId,
            institutionProfileId: notification.institutionProfileId,
          });
        }
      }
      await this.deleteNotifications({ notifications });
    },
    async removeNotificationsFromIframe(requestData) {
      const metadata = requestData.metadata;
      const notificationIds = metadata.notificationIds;
      await this.removeNotifications(notificationIds);
    },
    updateIframeWidgetProps() {
      const transferable = new WidgetPropsDto(this.widgetProps);
      this.postMessageToIframe(transferable);
    },
    updateIframeWidgetToken() {
      const transferable = new WidgetTokenDto({
        token: this.aulaToken,
      });
      this.postMessageToIframe(transferable);
    },
    postMessageToIframe(payload) {
      if (this.$refs.iframe == null) {
        return;
      }
      this.$refs.iframe.contentWindow.postMessage(payload, this.widgetUrl);
    },
    triggerStepUp() {
      if (!this.isSteppedUp) {
        this.setStepUpNotification({
          showStepUpNotification: true,
          redirectedUrl: window.location.href,
        });
      }
    },
    async setAulaToken() {
      const widgetId = this.widgetID;
      const placement = this.placement;
      this.aulaToken = await widgetUtils.getAulaToken(widgetId, placement);
    },
    updateWidgetIframeHeight(requestData) {
      const metadata = requestData.metadata;
      this.iframeHeight = metadata.height + 30 + 'px';
    },
    async updateIframeWidget(event) {
      const data = event.data;
      const request = data.request;
      switch (request) {
        case widgetRequestTypes.getProps:
          this.updateIframeWidgetProps();
          break;
        case widgetRequestTypes.triggerStepUp:
          this.triggerStepUp();
          break;
        case widgetRequestTypes.getAulaToken:
          await this.setAulaToken();
          this.updateIframeWidgetToken();
          break;
        case widgetRequestTypes.setIframeHeight:
          this.updateWidgetIframeHeight(data);
          break;
        case widgetRequestTypes.deleteNotifications:
          await this.removeNotificationsFromIframe(data);
          this.updateIframeWidgetProps();
          break;
      }
    },
    registerWidgetIframe() {
      if (this.isIframeWidget) {
        postMessageController.register(this.$refs.iframe.contentWindow, this.updateIframeWidget);
      }
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        this.$nextTick(() => {
          this.registerWidgetIframe();
        });
      }
    },
    widgetProps() {
      if (this.widgetType === widgetTypes.IFRAME) {
        this.updateIframeWidgetProps();
      }
    }
  },
  mounted() {
    if (this.isPreviewMode && this.restrictedGroups.length > 0) {
      this.restrictedGroupNames =
        '( ' +
        this.$options.filters.fromTextKey('ONLY') +
        ' ' +
        this.restrictedGroups.map(g => g.name).join(' ' + this.$options.filters.fromTextKey('AND') + ' ') +
        ')';
    }
    Vue.nextTick(() => {
      if (this.isMobile) {
        $('body').on('DOMSubtreeModified', '.aula-widget', function () {
          $('.aula-widget form').removeAttr('target');
          $('.aula-widget form').attr('method', 'GET');
        });
      }
    });
  },
  beforeDestroy() {
    postMessageController.unregister(this.widgetUrl);
  }
};
</script>

<style scoped lang="scss">
@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';

.aula-widget {
  /deep/ .modal-dialog {
    @include breakpoint-lg() {
      top: 65px;
    }
    @include breakpoint-xl() {
      top: 100px;
    }
  }

  .notice-boards & {
    margin: 0;
  }

  /deep/ {
    h1,
    h2 {
      margin-top: 20px;
      font-size: 20px;
    }
  }

  .restricted-groups {
    font-style: italic;
  }

  .widgetBox {
    position: relative;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      white-space: nowrap;
      padding-bottom: 0.25rem;
      color: var(--color-primary-darker);

      .widget-name {
        font-size: 1rem;
        font-weight: bold;
        flex-grow: 1;
      }

      .widget-supplier {
        font-size: 0.7rem;
      }
    }

    .id {
      display: none;
    }

    .content {
      padding: 5px;
      font-size: 15px;
      border: solid 1px var(--color-primary-light);

      /deep/ .container-fluid {
        padding: 0 15px;
      }

      /deep/ input::-webkit-outer-spin-button,
      /deep/ input::-webkit-inner-spin-button,
      /deep/ input::-webkit-calendar-picker-indicator {
        display: block;
      }
    }

    .iframe-content {
      width: 100%;
      padding: 5px;
      border: solid 1px var(--color-primary-light);
    }
  }
}
</style>
