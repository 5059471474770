import { portalRoles } from '../../shared/enums/portalRoles';
import { CalendarFeedProfileModel } from '../../shared/models/calendarFeedProfile.model';
import { CalendarFeedConfigurationModel } from '../../shared/models/calendarFeedConfiguration.model';
import { ProfilePictureModel } from '../../shared/models/profilePicture.model';

export class CalendarFeedProfile implements CalendarFeedProfileModel {
  institutionProfileId: number;
  name: string;
  shortName: string;
  displayName: string;
  role: portalRoles;
  calendarFeedEnabled: boolean;
  profilePicture?: ProfilePictureModel;
  calendarFeedConfiguration?: CalendarFeedConfigurationModel;

  constructor(model: CalendarFeedProfileModel) {
    this.institutionProfileId = model.institutionProfileId;
    this.name = model.name;
    this.shortName = model.shortName;
    this.displayName = model.displayName;
    this.role = model.role;
    this.calendarFeedEnabled = model.calendarFeedEnabled;
    this.profilePicture = model.profilePicture;
    this.calendarFeedConfiguration = model.calendarFeedConfiguration;
  }
  getOneWeekFeed(): string {
    return this.calendarFeedConfiguration?.oneWeekFeed || '';
  }

  getOneYearFeed(): string {
    return this.calendarFeedConfiguration?.oneYearFeed || '';
  }
}
