import moment from 'moment-timezone';
import { portal } from '../assets/plugins/axios/axios';
import { widgetPlacementTypes } from '../enums/widgetPlacementTypes';

class WidgetUtils {
  private widgetSessionTokenKey = 'token';
  private widgetSessionTokenTimeIssuedIdentifierKey = 'tokenTime';

  getSessionToken(widgetId: string): string | null {
    return sessionStorage.getItem(this.widgetSessionTokenKey + widgetId);
  }

  getSessionTokenTimeIssuedIdentifier(widgetId: string): string | null {
    return sessionStorage.getItem(this.widgetSessionTokenTimeIssuedIdentifierKey + widgetId);
  }

  setSessionToken(widgetId: string, token: string): void {
    sessionStorage.setItem(this.widgetSessionTokenKey + widgetId, token);
  }

  setSessionTokenTimeIssuedIdentifier(widgetId: string, timestamp: string): void {
    sessionStorage.setItem(this.widgetSessionTokenTimeIssuedIdentifierKey + widgetId, timestamp);
  }

  async getAulaToken(widgetId: string, placement: widgetPlacementTypes) {
    const sessionTokenTimeIssuedIdentifier = this.getSessionTokenTimeIssuedIdentifier(widgetId);
    let aulaToken = null;
    if (
      sessionTokenTimeIssuedIdentifier &&
      moment().isBefore(moment(sessionTokenTimeIssuedIdentifier, 'DD-MM-YYYY HH:mm:ss'))
    ) {
      aulaToken = this.getSessionToken(widgetId);
    } else {
      const endpoint = this.getAulaTokenEndpoint(widgetId, placement);
      await portal.get(endpoint).then(response => {
        aulaToken = response.data.data;
        this.setSessionToken(widgetId, aulaToken);
        this.setSessionTokenTimeIssuedIdentifier(widgetId, moment().add(4, 'm').format('DD-MM-YYYY HH:mm:ss'));
      });
    }

    return aulaToken;
  }

  getAulaTokenEndpoint(widgetId: string, placement: widgetPlacementTypes) {
    let endpoint = `/?method=aulaToken.getAulaToken&widgetId=${widgetId}`;
    if (placement === widgetPlacementTypes.NOTICE_BOARD) {
      endpoint = `/?method=AulaTokenNoticeBoard.getAulaToken&widgetId=${widgetId}`;
    }
    return endpoint;
  }
}

export const widgetUtils = new WidgetUtils();
