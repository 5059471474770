/* schools_absence_overview 2024.10.10.2 PulsProd2 */
<template>
  <div
    ref="absenceOverviewRef"
    class="absence-overview"
  >
    <!-- Header -->
    <div class="col">
      <h1>Fraværsoverblik</h1>
    </div>

    <!-- Loading -->
    <div
      v-if="isLoading"
      class="absence-overview-loading"
    >
      <i class="fa fa-spinner fa-spin"></i>
      <span>Danner et overblik over skolens fravær...</span>
    </div>

    <!-- Message/error shown to user -->
    <div
      v-else-if="error"
      class="message-container"
    >
      <span class="error-message"
      >Noget gik galt med at hente dit overblik.</span
      >
      <span class="error-message">{{ error }}</span>
    </div>

    <b-container
      v-else
      fluid
      :class="{ 'px-0': isNarrow }"
    >
      <!-- Abscense overview -->
      <b-row class="absence-controls position-relative align-items-center mb-2">
        <b-col cols="12">
          <!--
            The left padding on the following b-row and its b-cols
            is setup to prevent double-spacing between the b-cols when they are next to each other.
           -->
          <b-row class="align-items-center justify-content-between pl-3">
            <!-- Date picker -->
            <b-col
              :class="['my-1 pl-0', { 'col-lg-4': !isNarrow }]"
              cols="12"
            >
              <div class="font-weight-bold">Dato</div>
              <b-form-input
                id="calendarInput"
                ref="calendarInput"
                v-model="inputSelectedDate"
                type="date"
                class="my-0 date-picker-input"
                @change="handleDateChange"
              />
            </b-col>

            <!-- Institution picker -->
            <b-col
              v-if="schoolsAndDepartmentsSelectList.length > 1"
              :class="['my-1 pl-0', { 'col-lg-4': !isNarrow }]"
              cols="12"
            >
              <div class="font-weight-bold">Afdeling</div>
              <b-form-select
                v-model="selectedInstitution"
                :options="schoolsAndDepartmentsSelectList"
                @change="handleInstitutionChanged"
              />
            </b-col>

            <!-- Teacher dropdown for narrow placement -->
            <b-col
              v-if="(isNarrow || isMobileView) && personnel.length"
              cols="12"
              class="my-1 pl-0"
            >
              <div class="font-weight-bold">Lærer</div>
              <b-form-select
                v-model="selectedPerson"
                :options="
                  personnel.map((x) => ({
                    text: x.fullName,
                    value: x,
                  }))
                "
              />
            </b-col>

            <!-- Refresh data -->
            <b-col
              :class="['my-1 pl-0', { 'col-lg-4': !isNarrow }]"
              cols="12"
            >
              <b-button
                variant="outline-primary"
                class="updateButton"
                @click="(busy = true), refresh()"
              >
                Opdater overblik
              </b-button>
            </b-col>
          </b-row>
        </b-col>
        <b-col
          :md="isNarrow ? 12 : 6"
          cols="12"
          class="d-flex align-items-center my-1"
        >
          <!-- Activity filters -->
          <span class="m-0 mr-2">Vis:</span>
          <b-form-group class="overview-filters flex-fill">
            <b-form-checkbox-group
              v-model="overViewFiltersSelected"
              :options="overViewFilters"
              switches
              @change="handleOverviewFilter"
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="!isNarrow"
          md="3"
          cols="12"
          class="d-flex align-items-center justify-content-end my-1"
        >
          <!-- Activity width -->
          <span class="m-0 mr-2">Bredde:</span>
          <b-input
            id="kmd-absence-overview-width-input"
            v-model="activityWidthInput.value"
            :min="activityWidthInput.min"
            :max="activityWidthInput.max"
            class="absence-overview-width-input"
            type="range"
          ></b-input>
          <b-popover
            target="kmd-absence-overview-width-input"
            triggers="hover"
            placement="top"
          >
            {{ activityWidthInput.item() }}
          </b-popover>
        </b-col>
        <b-col
          :md="isNarrow ? 12 : 3"
          cols="12"
          class="d-flex align-items-center justify-content-end my-1"
        >
          <!-- Time axis -->
          <span class="m-0 mr-2">Tidsakse:</span>
          <b-input
            id="kmd-absence-overview-timeaxis-input"
            v-model="breakDurationInput.value"
            :min="breakDurationInput.min"
            :max="breakDurationInput.max"
            class="absence-overview-timeaxis-input"
            type="range"
          ></b-input>
          <b-popover
            target="kmd-absence-overview-timeaxis-input"
            triggers="hover"
            placement="top"
          >
            {{ breakDurationInput.item().toString("human") }}
          </b-popover>
        </b-col>
      </b-row>
      <div
        v-if="personnel.length"
        :class="[
          'table-wrapper',
          'overflow-auto',
          isNarrow || isMobileView ? 'narrow-table-overview' : '',
        ]"
        :style="tableWrapperStyleObject"
      >
        <div
          v-if="busy"
          class="text-center loadingOverlay my-2"
        >
          <span
            aria-hidden="true"
            class="align-middle spinner-border"
          ></span>
          <strong>Opdaterer dit overblik...</strong>
        </div>
        <div class="table-header">
          <table
            :class="[
              'header-table table b-table table-striped sticky-col',
              { tableBusy: busy, narrowColumn: isNarrow || isMobileView },
            ]"
          >
            <thead>
            <tr>
              <!-- Header for periods -->
              <th
                :class="[
                    'periodColumn',
                    { narrowColumn: isNarrow || isMobileView },
                  ]"
              ></th>
              <!-- Headers with personnel, for activities -->
              <th
                v-for="person in shownPersonnel"
                :key="person.id"
                :class="[
                    'activityColumn',
                    { narrowColumn: isNarrow || isMobileView },
                  ]"
                :style="activityHeaderCellStyle"
              >
                <!-- Initials and full name -->
                <div>{{ person.initialsAndFullName }}</div>
                <!-- Absence -->
                <div>
                  <small>{{ person.absenceDescription }}</small>
                </div>
                <!-- Absent periods -->
                <div v-if="!person.absentPeriods.empty">
                  <small
                  >({{ person.absentPeriods.description }}
                    <i
                      v-if="person.absentPeriods.tooltip"
                      v-b-tooltip.hover
                      :title="person.absentPeriods.tooltip"
                      class="fas fa-info-circle"
                    ></i>
                    )
                  </small>
                </div>
              </th>
            </tr>
            </thead>
          </table>
        </div>

        <div class="position-relative">
          <table
            :class="[
              'overview-table table b-table table-striped sticky-col',
              { tableBusy: busy, narrowColumn: isNarrow || isMobileView },
            ]"
          >
            <tbody>
            <tr
              v-for="(period, periodIndex) in periods"
              :key="periodIndex"
            >
              <th
                :class="[
                    'periodColumn',
                    { narrowColumn: isNarrow || isMobileView },
                  ]"
                :style="
                    'height: ' + periodVerticalLayout[periodIndex].height + ';'
                  "
              >
                {{ period.description }}
              </th>

              <td
                v-for="(person, personIndex) in shownPersonnel"
                :key="person.id"
                :class="[
                    'activityColumn',
                    { narrowColumn: isNarrow || isMobileView },
                  ]"
                :style="activityCellStyle[periodIndex]"
              >
                <template v-if="periodIndex === 0">
                  <div
                    v-for="(activity, activityIndex) in person.activities"
                    :key="activityIndex"
                  >
                    <!-- Activity -->
                    <div
                      :class="[
                          'absence-activity',
                          {
                            'absent-and-substitute':
                              activity.isSubstitutedAbsence,
                            substitute: activity.isManualSubstitution,
                            clickable: activity.showNote(filterActivityNotes),
                          },
                        ]"
                      :style="{
                          height: activity.height(activityVerticalLayout),
                          top: activity.top(activityVerticalLayout),
                          left: activity.left(
                            activityHorizontalLayout[personIndex]
                          ),
                          width: activity.width(
                            activityHorizontalLayout[personIndex]
                          ),
                        }"
                      :title="activity.tooltip"
                      @click="
                          checkForNotes(activity)
                            ? (showModal = true)
                            : (showModal = false)
                        "
                    >
                      <div class="activity-container">
                        <!-- Description -->
                        <div
                          :class="[
                              'activity-description',
                              {
                                'with-period':
                                  activity.showPeriod(activityTextLines),
                                'overlaps-note':
                                  activity.noteOverlapsDescription(
                                    filterActivityNotes,
                                    activityTextLines
                                  ),
                              },
                            ]"
                        >
                            <span>{{
                                activity.description(activityTextLines)
                              }}</span>
                        </div>
                        <!-- Period -->
                        <div
                          v-show="activity.showPeriod(activityTextLines)"
                          class="activity-period"
                        >
                          <span>{{ activity.periodDescription }}</span>
                        </div>
                        <!-- Substitution -->
                        <div
                          v-show="
                              activity.showSubstitution(activityTextLines)
                            "
                          :class="[
                              'activity-substitution',
                              {
                                'overlaps-note':
                                  activity.noteOverlapsSubstitution(
                                    filterActivityNotes,
                                    activityTextLines
                                  ),
                              },
                            ]"
                        >
                          <span>{{ activity.substitutionDescription }}</span>
                        </div>
                        <!-- Note -->
                        <div
                          v-show="activity.showNote(filterActivityNotes)"
                          class="activity-note"
                          title=""
                        >
                          <transition name="fade">
                            <i
                              :id="'activity-note-' + activity.id"
                              class="fas fa-sticky-note"
                            ></i>
                          </transition>
                          <b-tooltip
                            v-if="placement.toLowerCase() !== 'narrow'"
                            :target="'activity-note-' + activity.id"
                            :title="activity.note"
                          />
                        </div>
                        <!-- Classes -->
                        <div
                          v-show="activity.showClasses(activityTextLines)"
                          :class="[
                              'activity-classes',
                              {
                                'without-rooms':
                                  !activity.showRooms(activityTextLines),
                              },
                            ]"
                        >
                          <span>{{ activity.classes }}</span>
                        </div>
                        <!-- Rooms -->
                        <div
                          v-show="activity.showRooms(activityTextLines)"
                          :class="[
                              'activity-rooms',
                              {
                                'without-classes':
                                  !activity.showClasses(activityTextLines),
                              },
                            ]"
                        >
                          <span>{{ activity.rooms }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <span
        v-else-if="!isLoading && !shownPersonnel.length"
        class="text-muted"
      >Der er ikke registret noget fravær i den valgte periode.</span
      >

      <!-- Absence list -->
      <div>
        <div class="absence-list-header">
          <h2>Fravær for skolen</h2>
        </div>
        <div v-if="absences.length">
          <b-row
            :class="['absence-list', { 'px-3': isNarrow || isMobileView }]"
          >
            <b-col
              :cols="isNarrow || isMobileView ? 12 : 'auto'"
              class="d-flex align-items-center"
            >
              <span>Vis:</span>
            </b-col>
            <b-col cols="11">
              <b-row
                :class="[
                  'd-flex',
                  {
                    'flex-column, align-items-center': isNarrow || isMobileView,
                  },
                ]"
              >
                <b-col
                  :xl="isNarrow ? 9 : 'auto'"
                  :cols="isMobileView ? 11 : 4"
                >
                  <b-row>
                    <b-form-group class="absence-filters">
                      <b-form-checkbox-group
                        v-model="listFiltersSelectedLeftColumn"
                        :options="listFiltersLeftColumn"
                        switches
                        @change="handleListFilterLeftColumn"
                      />
                    </b-form-group>
                  </b-row>
                </b-col>
                <b-col
                  :xl="isNarrow ? 9 : 'auto'"
                  :cols="isMobileView ? 12 : 4"
                >
                  <b-row>
                    <b-form-group class="absence-filters">
                      <b-form-checkbox-group
                        v-model="listFiltersSelectedRightColumn"
                        :options="listFiltersRightColumn"
                        switches
                        @change="handleListFilterRightColumn"
                      />
                    </b-form-group>
                  </b-row>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <div
            :class="[
              'table-wrapper overflow-auto list-table-wrapper',
              { 'narrow-table-list': isNarrow },
            ]"
            :style="tableWrapperStyleObject"
          >
            <table
              :class="[
                'list-table table b-table table-striped responsive',
                { tableBusy: busy },
              ]"
            >
              <thead>
              <tr>
                <th
                  v-for="column in listColumns"
                  :key="column.value"
                  :class="
                      column.value === currentSort
                        ? 'font-weight-bold'
                        : 'font-weight-normal'
                    "
                  @click="sortBy(column.value)"
                >
                  {{ column.label }}
                  <i
                    v-if="column.value === currentSort"
                    :class="[
                        'fas fa-sort-down',
                        { rotated: currentSortDir === 'desc' },
                      ]"
                  ></i>
                </th>
              </tr>
              </thead>
              <tbody
                is="transition-group"
                name="list"
              >
              <tr
                v-for="(absence, index) in filteredAbsences"
                :key="index"
              >
                <td
                  v-for="column in listColumns"
                  :key="column.value"
                >
                  {{ absence[column.value] }}
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <span
          v-else
          class="text-muted"
        >
          Der er ikke registret noget fravær.
        </span>
      </div>
    </b-container>

    <!-- Modal for mobile -->
    <b-modal
      id="noteModal"
      v-model="showModal"
      size="sm"
      ok-only
      ok-title="Luk"
      centered
    >
      <template #modal-header>
        <div class="w-100">Vikarnoter</div>
      </template>
      {{ note }}
    </b-modal>
  </div>
</template>

<script>
/**
 * MODULE schools_absence_overview\store.js
 **/
const _module_widget_store = (function widget_store() {
  let _module_exports = {};

  /** @typedef {import('../shared/date').Date} Date */
  /** @typedef {import('../shared/date').DayOfWeek} DayOfWeek */
  /** @typedef {import('../shared/dateMinuteTime').DateMinuteTime} DateMinuteTime */
  /** @typedef {import('../shared/time').MinuteTime} MinuteTime */
  /** @typedef {import('../shared/week').Week} Week */
  /** @typedef {import('../shared/intervals').Interval} Interval */
  /** @typedef {import('../shared/intervals').IntervalSum} IntervalSum */

  /**
   * An id of reason of absence.
   * @typedef {*} AbsenceReasonId
   */

  /**
   * A type of absence.
   * @typedef {*} AbsenceType
   */

  /**
   * An id of a person.
   * @typedef {*} PersonId
   */

  /**
   * An absence.
   * @typedef {Object} Absence
   * @property {String} classes The classes of the absence.
   * @property {String} description The description of the absence.
   * @property {String} endDate The end date of the absence, in YYYY-MM-DD format.
   * @property {String} endTime The end time of the absence, in HH:MM format.
   * @property {PersonId[]} personnel The personnel of the absence.
   * @property {String} startDate The start date of the absence, in YYYY-MM-DD format.
   * @property {String} startTime The start time of the absence, in HH:MM format.
   * @property {AbsenceType} type The type of the absence.
   * @property {?AbsenceReasonId} reasonId The id of reason of the absence, if applicable.
   */

  /**
   * A reason of absence.
   * @typedef {Object} AbsenceReason
   * @property {AbsenceReason} id Id af the reason of absence.
   * @property {String} description The description of the reason of absence.
   */

  /** An alternate action id
   * @typedef {*} AlternateActionId
   */

  /**
   * A type of substitution.
   * @typedef {*} SubstitutionType
   */

  /**
   * A substitution of activity
   * @typedef {Object} Substitution
   * @property {AlternateActionId|PersonId} id
   * The id of the substitution.
   * Id of substitute person if the substitution is personal,
   * otherwise the id of substituting alternate action.
   * @property {SubstitutionType} type The type of the substitution.
   */

  /**
   * A status of activity.
   * @typedef {*} ActivityStatus
   */

  /**
   * A client-side id of activity
   * @typedef {*} ClientActivityId
   */

  /**
   * An activity
   * @typedef {Object} Activity
   * @property {String} [abbreviationOfSubject] The abbreviation of subject of the activity, if applicable.
   * @property {?PersonId} absentee The id of absentee of the activity.
   * @property {String[]} classes The classes of the activity.
   * @property {String} description The description of the activity.
   * @property {String} [endLesson] The end lesson of the activity, if applicable.
   * @property {ClientActivityId} id The client-side id of the activity.
   * @property {String} note The note of the activity.
   * @property {Interval<MinuteTime>} period The period of the activity.
   * @property {String[]} rooms The rooms of the activity.
   * @property {String} [startLesson] The start lesson of the activity, if applicable.
   * @property {ActivityStatus} status The status of the activity.
   * @property {Substitution} substitute The substitution of the activity.
   */

  /**
   * Identifies a date. Uses JSON format.
   * @typedef {String} DateId
   */

  /**
   * An id id of department.
   * @typedef {*} DepartmentId
   */

  /**
   * A department.
   * @typedef {Object} Department
   * @property {DepartmentId} id The id of the department.
   * @property {String} name The name of the department.
   * @property {Department[]} children Child departments of the department.
   */

  /**
   * A period
   * @typedef {Object} Period
   * @property {String} end The end of the period.
   * @property {String} start The start of the period.
   */

  /** A person
   * @typedef {Object} Person
   * @property {String} fullName The full name of the person.
   * @property {PersonId} id The id of the person.
   * @property {String} initials The initials of the person.
   */

  /**
   * A code of school.
   * @typedef {*} SchoolCode
   */

  /**
   * A school.
   * @typedef {Object} School
   * @property {SchoolCode} code The code of the school.
   * @property {String} name The name of the school.
   * @property {Department[]} topDepartments Top-level departments of the school.
   */

  /**
   * @class
   * @classdesc Stores all data used in schools absence overview widget.
   */
  function Store(vueSet) {
    /**
     * @member {Object.<AbsenceReasonId, String>}
     */
    this.absenceReasons = {};
    /**
     * @member {?Interval<Date>}
     */
    this.periodOfDepartments = null;
    /**
     * @member {Object.<SchoolCode, Person[]>}
     */
    this.personnel = {};
    /**
     * @member {School[]}
     */
    this.schools = [];
    /**
     * @member {Object.<SchoolCode|DepartmentId, Object.<DateId, Object.<Boolean, Timeline>>>}
     */
    this.timelines = {};
    this._vueSet = vueSet;
  }
  Store.prototype = {
    /**
     * Removes all data from the store.
     */
    clear() {
      this.absenceReasons = {};
      this.periodOfDepartments = null;
      this.personnel = {};
      this.schools = [];
      this.timelines = {};
    },
    /**
     * Sets absence reasons.
     * @param {AbsenceReason[]} absenceReasons
     */
    setAbsenceReasons(absenceReasons) {
      absenceReasons.forEach(absenceReason => {
        this._vueSet(this.absenceReasons, absenceReason.id, absenceReason.description);
      });
    },
    /**
     * Sets schools and departments.
     * Replaces loaded schools if given period of departments is different.
     * @param {School[]} schools The schools.
     * @param {Interval<Date>} periodOfDepartments The period of departments.
     */
    setSchools(schools, periodOfDepartments) {
      var _this$periodOfDepartm;
      if (!((_this$periodOfDepartm = this.periodOfDepartments) !== null && _this$periodOfDepartm !== void 0 && _this$periodOfDepartm.isSame(periodOfDepartments))) {
        this.periodOfDepartments = periodOfDepartments;
        this.schools = [];
      }
      schools.forEach(school => {
        const index = this.schools.findIndex(x => x.code === school.code);
        if (index === -1) {
          this.schools.push(school);
        } else {
          this.schools.splice(index, 1, school);
        }
      });
      this.schools.sort((a, b) => a.name.localeCompare(b.name));
    },
    /**
     * Sets personnel of a school.
     * @param {SchoolCode} schoolCode The code of the school.
     * @param {Person[]} personnel The personnel.
     */
    setPersonnel(schoolCode, personnel) {
      var _this$personnel$schoo;
      const personnelOfSchool = (_this$personnel$schoo = this.personnel[schoolCode]) !== null && _this$personnel$schoo !== void 0 ? _this$personnel$schoo : this._vueSet(this.personnel, schoolCode, []);
      personnel.forEach(person => {
        const index = personnelOfSchool.findIndex(x => x.id === person.id);
        if (index === -1) {
          personnelOfSchool.push(person);
        } else {
          personnelOfSchool.splice(index, 1, person);
        }
      });
      personnelOfSchool.sort((a, b) => a.fullName.localeCompare(b.fullName));
    },
    /**
     * Sets timeline for a school/department and a date.
     * @param {SchoolCode} schoolCode The code of the school.
     * @param {?DepartmentId} departmentId The id of the department. Sets for school if null.
     * @param {Date} date The date.
     * @param {Absence[]} absences The absences of the school on the date.
     * @param {Activity[]} activities The activities of the school on the date.
     * @param {Object.<PersonId, Period[]>} personAbsentPeriods The absent periods on the date of personnel of the school.
     * @param {Boolean} includesSfo Indicates whether activities include SFO duty hours periods.
     */
    setTimeline(schoolCode, departmentId, date, absences, activities, personAbsentPeriods, includesSfo) {
      var _this$timelines$schoo, _timelinesOfSchoolOrD;
      const schoolCodeOrDepartmentId = departmentId !== null && departmentId !== void 0 ? departmentId : schoolCode;
      const timelinesOfSchoolOrDepartment = (_this$timelines$schoo = this.timelines[schoolCodeOrDepartmentId]) !== null && _this$timelines$schoo !== void 0 ? _this$timelines$schoo : this._vueSet(this.timelines, schoolCodeOrDepartmentId, {});
      const timelinesOfDate = (_timelinesOfSchoolOrD = timelinesOfSchoolOrDepartment[date.toJSON()]) !== null && _timelinesOfSchoolOrD !== void 0 ? _timelinesOfSchoolOrD : this._vueSet(timelinesOfSchoolOrDepartment, date.toJSON(), {});
      this._vueSet(timelinesOfDate, includesSfo, {
        absences,
        activities,
        personAbsentPeriods
      });
    }
  };
  _module_exports = {
    Store
  };
  return _module_exports;
})();

/**
 * MODULE schools_absence_overview\alternateActions.js
 **/
const _module_widget_alternateActions = (function widget_alternateActions() {
  let _module_exports = {};

  const alternateActions = {
    A: "Arbjeder alene",
    F: "Fri",
    I: "Ingen vikar",
    K: "Tilbage i klassen",
    V: "Vikardækket i team"
  };
  _module_exports = {
    alternateActions
  };
  return _module_exports;
})();

/**
 * MODULE schools_absence_overview\activities.js
 **/
const _module_widget_activities = (function widget_activities(
  _module_widget_alternateActions
) {
  let _module_exports = {};

  const {
    alternateActions
  } = _module_widget_alternateActions;
  function makeActivities(activities, personnel, personId) {
    return activities.filter(activity => showInColumnOfPerson(activity.absentee, personId, activity.substitute)).map(activity => makeActivity(activity, personnel, personId));
  }
  function makeActivity(dto, personnel, personId) {
    const classes = sortedAndJoined(dto.classes);
    const period = periodDescription(dto.period, dto.startLesson, dto.endLesson);
    const rooms = sortedAndJoined(dto.rooms);
    const note = (dto.note || "").trim();
    const substitution = substitutionDescription(personnel, personId, dto.substitute);
    return {
      absentee: dto.absentee,
      classes: classes,
      description(textLines) {
        return description(classes, dto.description, substitution, this.showClasses(textLines), this.showSubstitution(textLines));
      },
      height(verticalLayout) {
        return verticalLayout[dto.id].height;
      },
      id: dto.id,
      isManualSubstitution: isManualSubstitution(dto.absentee, dto.substitute),
      isSubstitutedAbsence: isSubstitutedAbsence(dto.absentee, dto.substitute),
      left(horizontalLayout) {
        return horizontalLayout[dto.id].left;
      },
      note: note,
      noteOverlapsDescription(showNotes, textLines) {
        return noteOverlapsDescription(this.description(textLines), !!note, textLines[dto.id], showNotes);
      },
      noteOverlapsSubstitution(showNotes, textLines) {
        return noteOverlapsSubstitution(!!note, textLines[dto.id], showNotes);
      },
      period: dto.period,
      periodDescription: period,
      rooms: rooms,
      showClasses(textLines) {
        return showClasses(!!classes, textLines[dto.id]);
      },
      showNote(showNotes) {
        return showNote(!!note, showNotes);
      },
      showPeriod(textLines) {
        return showPeriod(textLines[dto.id], this.showSubstitution(textLines));
      },
      showRooms(textLines) {
        return showRooms(!!rooms, textLines[dto.id]);
      },
      showSubstitution(textLines) {
        return showSubstitution(!!substitution, textLines[dto.id], personId);
      },
      substitutionDescription: dto.substitute && dto.substitute.type == 0 ? "Vikar: " + substitution : substitution,
      tooltip: tooltip(dto.abbreviationOfSubject, classes, dto.description, period, rooms, substitution),
      top(verticalLayout) {
        return verticalLayout[dto.id].top;
      },
      width(horizontalLayout) {
        return horizontalLayout[dto.id].width;
      }
    };
  }
  function description(classes, description, substitution, showClasses, showSubstitution) {
    return description + (!showClasses && classes ? " " + classes : "") + (!showSubstitution && substitution ? " (" + substitution + ")" : "");
  }
  function isManualSubstitution(absentee, substitute) {
    return substitute && absentee === null;
  }
  function isSubstitutedAbsence(absentee, substitute) {
    return substitute && absentee !== null;
  }
  function noteOverlapsDescription(description, hasNote, lineCount, showNotes) {
    return showNotes && hasNote && lineCount <= 2 && description.length > 15;
  }
  function noteOverlapsSubstitution(hasNote, lineCount, showNotes) {
    return showNotes && hasNote && lineCount > 2;
  }
  function periodDescription(period, startLesson, endLesson) {
    return period.toString() + lessonsDescription(startLesson, endLesson);
    function lessonsDescription(start, end) {
      if (!start || !end) {
        return "";
      }
      if (start == end) {
        return " (lek. " + start + ")";
      }
      return " (lek. " + start + " - " + end + ")";
    }
  }
  function showInColumnOfPerson(absentee, personId, substitute) {
    return personId === absentee || absentee === null && substitute && substitute.type == 0 && substitute.id === personId;
  }
  function showClasses(hasClasses, lineCount) {
    return hasClasses && lineCount >= 3;
  }
  function showNote(hasNote, showNotes) {
    return showNotes && hasNote;
  }
  function showPeriod(lineCount, showSubstitution) {
    return lineCount >= (showSubstitution ? 3 : 2);
  }
  function showRooms(hasRooms, lineCount) {
    return hasRooms && lineCount >= 3;
  }
  function showSubstitution(hasSubstitution, lineCount) {
    return hasSubstitution && lineCount >= 3;
  }
  function sortedAndJoined(array) {
    if (!array) {
      return "";
    }
    const copy = array.slice();
    copy.sort();
    return copy.join(", ");
  }
  function substitutionDescription(personnel, personId, substitute) {
    if (!substitute) {
      return null;
    }
    switch (substitute.type) {
      case 0:
      {
        if (substitute.id === personId) {
          return null;
        }
        const [person] = personnel.filter(x => x.id === substitute.id);
        if (!person) {
          return null;
        }
        return person.initials + " " + person.fullName;
      }
      case 1:
        return alternateActions[substitute.id] || substitute.id;
      default:
        return null;
    }
  }
  function tooltip(abbreviationOfSubject, classesDescription, description, periodDescription, roomsDescription, substitutionDescription) {
    const contents = [line("Beskrivelse", description), line("Fag", abbreviationOfSubject), line("Klasser", classesDescription), line("Lokaler", roomsDescription), line("Periode", periodDescription), line("Vikar", substitutionDescription)];
    return contents.filter(x => !x.empty).map(x => x.toString()).join("\n");
    function line(title, value) {
      return {
        empty: !value,
        toString() {
          return title + ": " + value;
        }
      };
    }
  }
  _module_exports = {
    makeActivities
  };
  return _module_exports;
})(_module_widget_alternateActions);

/**
 * MODULE schools_absence_overview\personnel.js
 **/
const _module_widget_personnel = (function widget_personnel(
  _module_widget_activities
) {
  let _module_exports = {};

  const {
    makeActivities
  } = _module_widget_activities;
  function makePersonnel(absences, activities, personnel, personAbsentPeriods, absenceReasons) {
    const result = personnel.map(x => {
      var _personAbsentPeriods$;
      const personActivities = makeActivities(activities, personnel, x.id);
      if (!personActivities.length) {
        return null;
      }
      return makePerson(x, absences, personActivities, (_personAbsentPeriods$ = personAbsentPeriods[x.id]) !== null && _personAbsentPeriods$ !== void 0 ? _personAbsentPeriods$ : [], absenceReasons);
    }).filter(x => x);
    result.sort((a, b) => a.compare(b));
    return result;
  }
  function makePerson(dto, absences, activities, absentPeriods, absenceReasons) {
    const initialsAndFullName = dto.initials + " - " + dto.fullName;
    return {
      absentPeriods: {
        empty: !absentPeriods.length,
        description: absentPeriodsDescription(absentPeriods),
        tooltip: absentPeriodsTooltip(absentPeriods)
      },
      absenceDescription: absenceDescription(absences, dto.id, absenceReasons),
      activities,
      initials: dto.initials,
      initialsAndFullName,
      fullName: dto.fullName,
      id: dto.id,
      compare(other) {
        return initialsAndFullName.localeCompare(other.initialsAndFullName);
      }
    };
  }
  function absenceDescription(absences, personId, absenceReasons) {
    var _absenceReasons$absen;
    const absence = absences.find(x => x.personnel.includes(personId));
    const visibleType = 6; // Fravær
    if (!absence) {
      return "Vikaropgave";
    }
    if (visibleType !== absence.type) {
      return "Fraværende";
    }
    const filteredType = (_absenceReasons$absen = absenceReasons[absence.reasonId]) !== null && _absenceReasons$absen !== void 0 ? _absenceReasons$absen : "???";
    return filteredType;
  }
  function absentPeriodsTooltip(absentPeriods) {
    if (!absentPeriods.length || absentPeriods.length == 1 && absentPeriods[0].start !== "00:00" && absentPeriods[0].end !== "23:59") {
      return "";
    }
    return absentPeriods.map(x => x.start + " - " + x.end).join("\n");
  }
  function absentPeriodsDescription(absentPeriods) {
    if (!absentPeriods.length) {
      return "";
    }
    const start = absentPeriods[0].start;
    const end = absentPeriods[absentPeriods.length - 1].end;
    return start === "00:00" && end === "23:59" ? "hele dagen" : start + " - " + end;
  }
  _module_exports = {
    makePersonnel
  };
  return _module_exports;
})(_module_widget_activities);

/**
 * MODULE schools_absence_overview\actions.js
 **/
const _module_widget_actions = (function widget_actions() {
  let _module_exports = {};

  /** @typedef {import('./api').Api} Api */
  /** @typedef {import('./store').Store} Store */

  /**
   * Actions for the schools absence overview widget.
   * @param {Api} api The api.
   * @param {Store} store The store.
   */
  function Actions(api, store) {
    /** @member {Api} */
    this._api = api;
    /** @member {Store} */
    this._store = store;
  }
  Actions.prototype = {
    /**
     * Loads absence reasons, if not already loaded.
     * @returns {Promise}
     */
    loadAbsenceReasons() {
      if (Object.keys(this._store.absenceReasons).length > 0) {
        return Promise.resolve();
      }
      return this._api.absenceReasons().then(absenceReasons => this._store.setAbsenceReasons(absenceReasons));
    },
    /**
     * Loads schools with given codes.
     * Skips already loaded schools if period of departments contains date.
     * @param {SchoolCode[]} schoolCodes Codes of schools to load.
     * @param {Date} date Reference date.
     * @returns {Promise}
     */
    loadSchools(schoolCodes, date) {
      var _this$_store$periodOf;
      const periodOfDepartmentsChanges = ((_this$_store$periodOf = this._store.periodOfDepartments) === null || _this$_store$periodOf === void 0 ? void 0 : _this$_store$periodOf.contains(date)) !== true;
      const existingSchoolCodes = this._store.schools.map(x => x.code);
      const missingSchoolCodes = periodOfDepartmentsChanges ? schoolCodes : schoolCodes.filter(schoolCode => !existingSchoolCodes.includes(schoolCode));
      if (!periodOfDepartmentsChanges && missingSchoolCodes.length === 0) {
        return Promise.resolve();
      }
      return this._api.schools(missingSchoolCodes, date).then(schoolsAndDepartments => this._store.setSchools(schoolsAndDepartments.schools, schoolsAndDepartments.periodOfDepartments));
    },
    /**
     * Loads timeline of school/department on a date.
     * Skips loading of already loaded timeline.
     * @param {SchoolCode} schoolCode The code of school whose timeline to load.
     * @param {DepartmentId} departmentId The department id of the school, whose timeline to load. If null, school's timeline is loaded.
     * @param {Date} date The date of timeline.
     * @param {Boolean} includeSfo Indicates whether to include SFO duty hours periods in activities.
     * @returns {Promise}
     */
    loadTimeline(schoolCode, departmentId, date, includeSfo) {
      var _this$_store$timeline, _this$_store$timeline2;
      const schoolCodeOrDepartmentId = departmentId !== null && departmentId !== void 0 ? departmentId : schoolCode;
      const existingTimeline = (_this$_store$timeline = this._store.timelines[schoolCodeOrDepartmentId]) === null || _this$_store$timeline === void 0 ? void 0 : (_this$_store$timeline2 = _this$_store$timeline[date.toJSON()]) === null || _this$_store$timeline2 === void 0 ? void 0 : _this$_store$timeline2[includeSfo];
      if (existingTimeline) {
        return Promise.resolve();
      }
      return this._api.timeline(schoolCode, departmentId, date, includeSfo).then(timeline => {
        this._store.setPersonnel(schoolCode, timeline.personnel.map(person => ({
          fullName: person.fullName,
          id: person.id,
          initials: person.initials
        })));
        this._store.setTimeline(schoolCode, departmentId, date, timeline.absences, timeline.activities, timeline.personnel.reduce((personAbsentPeriods, person) => {
          personAbsentPeriods[person.id] = person.absentPeriods;
          return personAbsentPeriods;
        }, {}), includeSfo);
      });
    }
  };
  _module_exports = {
    Actions
  };
  return _module_exports;
})();

/**
 * MODULE schools_absence_overview\absenceTypes.js
 **/
const _module_widget_absenceTypes = (function widget_absenceTypes() {
  let _module_exports = {};

  const absenceTypes = [{
    id: 0,
    description: "Lejrskole"
  }, {
    id: 1,
    description: "Klassefravær"
  }, {
    id: 2,
    description: "Kursus" // category "opgavetype"
  }, {
    id: 3,
    description: "Fraværende"
  }, {
    id: 4,
    description: "Andre opgaver"
  }, {
    id: 5,
    description: "Anden undervisning"
  }, {
    id: 6,
    description: "Fravær" // will be overridden by the actual absence reason
  }];

  _module_exports = {
    absenceTypes
  };
  return _module_exports;
})();

/**
 * MODULE schools_absence_overview\absences.js
 **/
const _module_widget_absences = (function widget_absences(
  _module_widget_absenceTypes
) {
  let _module_exports = {};

  const {
    absenceTypes
  } = _module_widget_absenceTypes;
  function makeAbsence(dto, personnel, absenceReasons) {
    return {
      classes: dto.classes,
      description: dto.description,
      endDate: dto.endDate,
      endTime: dto.endTime,
      initials: initials(dto.personnel, personnel),
      startDate: dto.startDate,
      startTime: dto.startTime,
      type: type(dto.type, dto.reasonId, absenceReasons),
      typeId: dto.type
    };
  }
  function initials(personnelIds, personnel) {
    const initials = [];
    personnelIds.forEach(absenteeId => {
      personnel.forEach(person => {
        if (person.id === absenteeId) {
          initials.push(person.initials);
        }
      });
    });
    initials.sort();
    return initials.join(", ");
  }
  function type(type, reasonId, absenceReasons) {
    var _absenceReasons$reaso, _absenceTypes$find;
    const filteredType = type === 6 ? (_absenceReasons$reaso = absenceReasons[reasonId]) !== null && _absenceReasons$reaso !== void 0 ? _absenceReasons$reaso : "???" : (_absenceTypes$find = absenceTypes.find(absenceType => absenceType.id === type)) === null || _absenceTypes$find === void 0 ? void 0 : _absenceTypes$find.description;
    if (!filteredType) {
      return "";
    }
    let kursusLabel = "";
    if (type === 2) {
      kursusLabel = "Opgave: ";
    } else if (type === 6 && reasonId === 19) {
      // reasonId 19 = Course
      kursusLabel = "Fravær: ";
    }
    return kursusLabel + filteredType;
  }
  _module_exports = {
    makeAbsence
  };
  return _module_exports;
})(_module_widget_absenceTypes);

/**
 * MODULE shared\time.js
 **/
const _module_shared_time = (function shared_time() {
  let _module_exports = {};

  /**
   * Represent a time point during a day with minute accuracy.
   * @typedef {Object} MinuteTime
   * @property {Number} hour The hour part of the time point.
   * @property {Number} minute The minute part of the time point.
   */

  /**
   * Represent a time duration with minute accuracy.
   * @typedef {Object} MinuteDuration
   * @property {Number} totalMinutes The total amount of minutes within the duration.
   */

  function makePoint(hour, minute) {
    if (hour > 23 || hour < 0) {
      throw "Hour must be between 0 and 23";
    }
    if (minute > 59 || minute < 0) {
      throw "Minute must be between 0 and 59";
    }
    hour = Math.floor(hour);
    minute = Math.floor(minute);
    return {
      hour: hour,
      minute: minute,
      addHours(hours) {
        const newHour = hour + hours;
        if (newHour > 23) {
          return makePoint(23, 59);
        }
        if (newHour < 0) {
          return makePoint(0, 0);
        }
        return makePoint(newHour, minute);
      },
      addDuration(duration) {
        const newMinutes = hour * 60 + minute + duration.totalMinutes;
        if (newMinutes < 0) {
          return makePoint(0, 0);
        }
        if (newMinutes >= 24 * 60) {
          return makePoint(23, 59);
        }
        return makePoint(Math.floor(newMinutes / 60), newMinutes % 60);
      },
      compare(other) {
        if (this.isBefore(other)) {
          return -1;
        }
        if (this.isSame(other)) {
          return 0;
        }
        return 1;
      },
      durationTo(other) {
        if (this.isAfter(other)) {
          throw "The other time point must be after or same as this time point";
        }
        if (other.hour === hour) {
          return makeDuration(other.minute - minute);
        }
        return makeDuration((other.hour - hour - 1) * 60 + (60 - minute) + other.minute);
      },
      /**
       * Formats the time point to a string.
       * Supported formats are "input".
       * The "input" format results in a string understood by input elements of type "time" ('hh:mm').
       * @param {string} format A format specification to use. The only supported value is "input".
       * @returns A string representation of the time point, according to specified format.
       * @throws {Error} Throws when format argument is none of the supported values.
       */
      format(format) {
        switch (format) {
          case "input":
            // Format used with time input elements and in json, which both expect a "00:00" format
            return padded(hour) + ":" + padded(minute);
          default:
            throw new Error('Unknown time point format "' + format + '"');
        }
      },
      isAfter(other) {
        return other.hour < hour || other.hour === hour && other.minute < minute;
      },
      isBefore(other) {
        return other.hour > hour || other.hour === hour && other.minute > minute;
      },
      isSame(other) {
        return other.hour === hour && other.minute === minute;
      },
      startOfHour() {
        return makePoint(hour, 0);
      },
      toJSON() {
        return padded(hour) + ":" + padded(minute);
      },
      toString() {
        return hour.toString() + ":" + padded(minute);
      }
    };
  }

  /**
   * Returns a minute duration from number of minutes.
   * @param {Number} minutes The number of minutes.
   * @returns {MinuteDuration} A duration that consists of the number of minutes.
   */
  function makeDuration(minutes) {
    return {
      totalMinutes: minutes,
      isSame(other) {
        return minutes === other.totalMinutes;
      },
      toString(format) {
        const hour = Math.floor(minutes / 60);
        const minute = minutes % 60;
        switch (format) {
          case "human":
          {
            const hourLabel = hour == 1 ? "time" : "timer";
            const minuteLabel = minute == 1 ? "minut" : "minutter";
            const hourPart = hour + " " + hourLabel;
            const minutePart = minute + " " + minuteLabel;
            return (hour > 0 || minute == 0 ? hourPart : "") + (minute > 0 ? " " + minutePart : "");
          }
          default:
            return hour + ":" + padded(minute);
        }
      }
    };
  }
  function padded(value) {
    return value < 10 ? "0" + value.toString() : value.toString();
  }

  /**
   * Parses a string and return a minute duration.
   * Supported format of strings is "h:mm" (more than one h digit is allowed).
   * Leading or trailing whitespace is not ignored, and needs to be trimmed.
   * @param {string} value The string to be parsed.
   * @returns {?MinuteDuration} A minute time duration if parsing was successful, null otherwise.
   * @throws {string} Throws when parsed hour or minute values are out of bounds.
   */
  function parseDuration(value) {
    const result = value.match(/(\d+):(\d\d)/u);
    if (result && result[0] === value) {
      const [, hoursPart, minutePart] = result;
      const minute = Number.parseInt(minutePart, 10);
      if (minute > 59 || minute < 0) {
        throw "Minute must be between 0 and 59";
      }
      const hours = Number.parseInt(hoursPart, 10);
      if (hours < 0) {
        throw "Hours must be greater than or equal to 0";
      }
      return makeDuration(hours * 60 + minute);
    } else {
      return null;
    }
  }

  /**
   * Parses a string and return a time point.
   * Supported formats of strings are "hh:mm" and "h:mm".
   * Leading or trailing whitespace is not ignored, and needs to be trimmed.
   * @param {string} value The string to be parsed.
   * @returns {?MinuteTime} A time point if parsing was successful, null otherwise.
   * @throws {string} Throws when parsed hour or minute values are out of bounds.
   */
  function parsePoint(value) {
    const result = value.match(/(\d?\d):(\d\d)/u);
    if (result && result[0] === value) {
      const [, hour, minute] = result;
      return makePoint(Number.parseInt(hour, 10), Number.parseInt(minute, 10));
    } else {
      return null;
    }
  }
  _module_exports = {
    parsePoint,
    point: makePoint,
    duration: makeDuration,
    parseDuration
  };
  return _module_exports;
})();

/**
 * MODULE shared\settingsStores.js
 **/
const _module_shared_settingsStores = (function shared_settingsStores() {
  let _module_exports = {};

  _module_exports = {
    browserSession(widgetName, scope, sessionUUID, expireAfterInSecs) {
      const key = "kmd-" + widgetName + "-widget-" + scope + "-settings";
      return {
        read() {
          try {
            const serializedSettings = window.sessionStorage.getItem(key);
            if (serializedSettings === null) return null;
            const {
              value,
              writtenAt,
              sessionUUID: storedSessionUUID
            } = JSON.parse(serializedSettings);
            if (storedSessionUUID !== sessionUUID) {
              return null;
            }
            if (expireAfterInSecs === 0 || expireAfterInSecs && Date.now() - new Date(writtenAt) > expireAfterInSecs) {
              return null;
            }
            return value || {};
          } catch (_) {
            return null;
          }
        },
        write(value) {
          try {
            window.sessionStorage.setItem(key, JSON.stringify({
              value,
              writtenAt: new Date().toJSON(),
              sessionUUID
            }));
          } catch (_) {
            return;
          }
        }
      };
    },
    database(aulaToken, axios, widgetName, isNoticeBoard, institutionCode) {
      return {
        read() {
          const params = {
            widgetName
          };
          if (isNoticeBoard && institutionCode) {
            params.institutionCode = institutionCode;
          }
          return axios.get("https://personale.api.kmd.dk/aula/api/v2/settings", {
            headers: {
              Authorization: aulaToken
            },
            params: params
          }).then(x => x.data, () => null);
        },
        write(value) {
          const params = {
            widgetName
          };
          if (isNoticeBoard && institutionCode) {
            params.institutionCode = institutionCode;
          }
          return axios.post("https://personale.api.kmd.dk/aula/api/v2/settings", value, {
            headers: {
              Authorization: aulaToken
            },
            params: params
          });
        }
      };
    }
  };
  return _module_exports;
})();

/**
 * MODULE shared\rangeInput.js
 **/
const _module_shared_rangeInput = (function shared_rangeInput() {
  let _module_exports = {};

  _module_exports = {
    selectingItem({
                    items,
                    initialItem,
                    itemCompare
                  }) {
      if (!items.length) {
        throw "Items array cannot be empty";
      }
      return {
        min: 0,
        max: items.length - 1,
        value: initialValue(),
        item() {
          return items[Math.max(0, Math.min(this.value, items.length - 1))];
        }
      };
      function initialValue() {
        const compare = itemCompare || ((a, b) => a === b);
        const value = items.findIndex(x => compare(x, initialItem));
        return Math.max(0, value);
      }
    }
  };
  return _module_exports;
})();

/**
 * MODULE shared\prelude.js
 **/
const _module_shared_prelude = (function shared_prelude() {
  let _module_exports = {};

  /** @constructor */
  function Canceled() {}
  Canceled.prototype.toString = function () {
    return "Canceled";
  };
  const IgnoreCanceledMixin = {
    errorCaptured(error) {
      if (isCanceled(error)) {
        return false;
      }
      return true;
    }
  };
  function areArraysEqual(first, second, elementsEqual) {
    var _elementsEqual;
    elementsEqual = (_elementsEqual = elementsEqual) !== null && _elementsEqual !== void 0 ? _elementsEqual : strictEqual;
    if (first.length !== second.length) {
      return false;
    }
    for (let i = 0; i < first.length; ++i) {
      if (!elementsEqual(first[i], second[i])) {
        return false;
      }
    }
    return true;
  }
  function areSetsEqual(first, second, elementsEqual) {
    var _elementsEqual2, _first$length, _second$length;
    elementsEqual = (_elementsEqual2 = elementsEqual) !== null && _elementsEqual2 !== void 0 ? _elementsEqual2 : strictEqual;
    if (((_first$length = first.length) !== null && _first$length !== void 0 ? _first$length : first.size) !== ((_second$length = second.length) !== null && _second$length !== void 0 ? _second$length : second.size)) {
      return false;
    }
    const notVisited = [...second];
    for (const element of first) {
      const index = notVisited.findIndex(x => elementsEqual(x, element));
      if (index === -1) {
        return false;
      }
      notVisited.splice(index, 1);
    }
    return notVisited.length === 0;
  }
  function canceled() {
    return new Canceled();
  }
  const escapedHtmlCharacters = /[&"'<>]/gu;
  function escapeHtml(string) {
    var _string;
    string = (_string = string) === null || _string === void 0 ? void 0 : _string.toString();
    return string && string.replace(escapedHtmlCharacters, x => {
      switch (x) {
        case "&":
          return "&amp;";
        case '"':
          return "&quot;";
        case "'":
          return "&#39;";
        case "<":
          return "&lt;";
        case ">":
          return "&gt;";
      }
    });
  }
  const regExpCharacters = /[\\^$.*+?()[\]{}|]/gu;
  const regExpCharactersTest = new RegExp(regExpCharacters.source, "u");
  function escapeRegExp(string) {
    var _string2;
    string = (_string2 = string) === null || _string2 === void 0 ? void 0 : _string2.toString();
    return string && regExpCharactersTest.test(string) ? string.replace(regExpCharacters, "\\$&") : string;
  }
  function isCanceled(value) {
    return value instanceof Canceled;
  }
  function isPromise(value) {
    return value && isFunction(value.then);
  }
  function isFunction(value) {
    return value && typeof value == "function";
  }
  function isString(value) {
    return Object.prototype.toString.call(value) === "[object String]";
  }
  function strictEqual(a, b) {
    return a === b;
  }
  function toPromise(value, thisArg, ...args) {
    return isFunction(value) ? new Promise(resolve => resolve(value.apply(thisArg, args))) : Promise.resolve(value);
  }
  _module_exports = {
    areArraysEqual,
    areSetsEqual,
    canceled,
    escapeHtml,
    escapeRegExp,
    IgnoreCanceledMixin,
    isCanceled,
    isPromise,
    isFunction,
    isString,
    strictEqual,
    toPromise
  };
  return _module_exports;
})();

/**
 * MODULE shared\functions.js
 **/
const _module_shared_functions = (function shared_functions(
  _module_shared_prelude
) {
  let _module_exports = {};

  const {
    canceled,
    isString,
    toPromise
  } = _module_shared_prelude;
  _module_exports = {
    combine(inner, outer) {
      return x => {
        const resultOfInner = inner(x);
        const resultOfOuter = outer(resultOfInner);
        return resultOfOuter;
      };
    },
    debounce(callback, delay) {
      let latestReject;
      let timeoutId;
      return function () {
        const result = new Promise((resolve, reject) => {
          var _latestReject;
          (_latestReject = latestReject) === null || _latestReject === void 0 ? void 0 : _latestReject(canceled());
          latestReject = reject;
          clearTimeout(timeoutId);
          timeoutId = setTimeout(() => {
            toPromise(callback, this, ...arguments).then(x => {
              latestReject = undefined;
              timeoutId = undefined;
              resolve(x);
            }, x => {
              latestReject = undefined;
              timeoutId = undefined;
              reject(x);
            });
          }, delay);
        });
        return result;
      };
    },
    /**
     * Transforms a function so that only its latest invocation yields a usable value.
     * When the transformed function is called, it in turns calls the function to be transformed,
     * with the arguments passed to itself, then returns a promise.
     * That promise is resolved to the result of the call,
     * if the transformed function is not called again before that happens.
     * Otherwise, the promise is resolved to an object representing cancellation.
     * @param {Function} callback The function to transform.
     * @returns {Function} The transformed function.
     */
    last(callback) {
      const version = function () {
        let current = 0;
        return {
          is(expected) {
            return expected === current;
          },
          increment() {
            ++current;
            return current;
          }
        };
      }();
      return function () {
        return new Promise((resolve, reject) => {
          const thisVersion = version.increment();
          toPromise(callback, this, ...arguments).then(x => {
            if (version.is(thisVersion)) {
              resolve(x);
            } else {
              reject(canceled());
            }
          }, x => {
            if (version.is(thisVersion)) {
              reject(x);
            } else {
              reject(canceled());
            }
          });
        });
      };
    },
    /**
     * Transform a function so that it can only be called once.
     * First call to the transformed function invokes the function to be transformed,
     * and returns its result.
     * Subsequent calls to the transformed function do not invoke the function to be transformed,
     * and return undefined.
     * @param {Function} callback The function to transform.
     * @returns {Function} The transformed function.
     */
    once(callback) {
      let wasCalled = false;
      return function () {
        if (wasCalled) {
          return;
        } else {
          wasCalled = true;
        }
        return callback.apply(this, arguments);
      };
    },
    /**
     * Transforms a function so that focus is moved to one of target elements after the function returns.
     * In case the function returns a promise, the focus is moved when it is settled.
     * Resolving of target elements and actual moving of focus is performed in next "tick",
     * to allow for queued DOM changes to happen, as they might affect resolve of the target elements.
     * The focus is moved to each target element, in ascending order within the array,
     * until an element is successfully found and becomes the active element when focused.
     * @param {Function} callback The function to transform.
     * @param {Array<String|Object>|String|Object} targetElements The element(s) to move focus to after the function to transform returns.
     * An element can be a DOM element, a CSS selector, or the value "activeElement",
     * which resolves to the active element before the transformed function is called.
     * @returns {Function} The transformed function.
     */
    withFocusRestore(callback, targetElements) {
      return function () {
        const activeElement = document.activeElement;
        return toPromise(callback.apply(this, arguments)).then(x => {
          setTimeout(() => setFocus(targetElements, activeElement), 0);
          return x;
        }, x => {
          setTimeout(() => setFocus(targetElements, activeElement), 0);
          return Promise.reject(x);
        });
      };
      function setFocus(targetElements, activeElement) {
        targetElements = Array.isArray(targetElements) ? targetElements : [targetElements];
        for (const targetElement of targetElements) {
          const element = targetElement === "activeElement" ? activeElement : isString(targetElement) ? document.querySelector(targetElement) : targetElement;
          if (element) {
            element.focus();
            if (element === document.activeElement) {
              break;
            }
          }
        }
      }
    },
    retry(callback, count, delay) {
      return function () {
        let retriesCount = 0;
        const errors = [];
        const result = new Promise((resolve, reject) => {
          invoke.apply(this, arguments);
          function invoke() {
            toPromise(callback, this, ...arguments).then(resolve, x => {
              errors.push(x);
              if (retriesCount < count) {
                ++retriesCount;
                setTimeout(() => invoke.apply(this, arguments), delay || 0);
              } else {
                reject(errors);
              }
            });
          }
        });
        return result;
      };
    },
    throttle(callback, delay) {
      let isScheduledOrRunning = false;
      const context = {};
      return function () {
        context.arguments = arguments;
        context.this = this;
        if (isScheduledOrRunning) return Promise.resolve(null);
        isScheduledOrRunning = true;
        const result = new Promise((resolve, reject) => {
          setTimeout(() => {
            const {
              this: thisArg,
              arguments: args
            } = context;
            context.this = undefined;
            context.arguments = undefined;
            toPromise(callback, thisArg, ...args).then(x => {
              resolve(x);
              isScheduledOrRunning = false;
            }, x => {
              reject(x);
              isScheduledOrRunning = false;
            });
          }, delay);
        });
        return result;
      };
    }
  };
  return _module_exports;
})(_module_shared_prelude);

/**
 * MODULE shared\settings.js
 **/
const _module_shared_settings = (function shared_settings(
  _module_shared_functions,
  _module_shared_prelude,
  _module_shared_settingsStores
) {
  let _module_exports = {};

  const functions = _module_shared_functions;
  const {
    isFunction
  } = _module_shared_prelude;
  const stores = _module_shared_settingsStores;
  _module_exports = function (axios, aulaToken, sessionUUID, isNoticeBoard, institutionCode, widgetName) {
    const browserSessionStore = stores.browserSession(widgetName, "session", sessionUUID);
    const databaseStore = stores.database(aulaToken, axios, widgetName, isNoticeBoard, institutionCode);
    const databaseCacheStore = stores.browserSession(widgetName, "database", sessionUUID, 8 * 60 * 60 * 1000);
    return new Promise(resolve => {
      const browserSessionSettings = browserSessionStore.read() || {};
      const databaseSettings = databaseCacheStore.read();
      if (databaseSettings !== null) {
        resolve({
          browserSessionSettings,
          databaseSettings
        });
        return;
      }
      databaseStore.read().then(x => {
        return x || {};
      }, () => {
        return {};
      }).then(x => {
        databaseCacheStore.write(x);
        resolve({
          browserSessionSettings,
          databaseSettings: x
        });
      });
    }).then(function ({
                        browserSessionSettings,
                        databaseSettings
                      }) {
      const browserSessionStoreWrite = functions.throttle(() => browserSessionStore.write(browserSessionSettings), 1000);
      const databaseStoreWrite = functions.combine(x => functions.retry(x, 5, 5000), x => functions.throttle(x, 10000))(() => databaseStore.write(databaseSettings));
      const databaseCacheStoreWrite = functions.throttle(() => databaseCacheStore.write(databaseSettings), 1000);
      function get(key, fallbackValue, validateValue, settings) {
        const value = settings[key];
        if (value === undefined || (validateValue || (() => true))(value) === false) {
          return isFunction(fallbackValue) ? fallbackValue() : fallbackValue;
        }
        return value;
      }
      function set(args, settings, storeWrites) {
        if (args.length === 2) {
          const [key, value] = args;
          settings[key] = value;
        } else if (args.length === 1) {
          const [keysAndValues] = args;
          keysAndValues.forEach(({
                                   key,
                                   value
                                 }) => settings[key] = value);
        } else {
          return;
        }
        storeWrites.forEach(x => x());
      }
      return {
        getLocal(key, fallbackValue, validateValue) {
          return get(key, fallbackValue, validateValue, browserSessionSettings);
        },
        getPersistent(key, fallbackValue, validateValue) {
          return get(key, fallbackValue, validateValue, databaseSettings);
        },
        setLocal(...args) {
          set(args, browserSessionSettings, [browserSessionStoreWrite]);
        },
        setPersistent(...args) {
          set(args, databaseSettings, [databaseCacheStoreWrite, databaseStoreWrite]);
        }
      };
    });
  };
  return _module_exports;
})(_module_shared_functions,
  _module_shared_prelude,
  _module_shared_settingsStores);

/**
 * MODULE shared\intervals.js
 **/
const _module_shared_intervals = (function shared_intervals() {
  let _module_exports = {};

  /**
   * Represents an interval between two points,
   * including the start point, and excluding the end point.
   * @typedef {Object} Interval
   * @property {*} start The start point of the interval.
   * @property {*} end The end point of the interval.
   * @property {Boolean} empty Indicates whether the interval is empty, always false.
   */

  /**
   * Represents an empty interval.
   * @typedef {Object} EmptyInterval
   * @property {Boolean} empty Indicates whether the interval is empty, always true.
   */

  /**
   * @returns {Interval|EmptyInterval}
   */
  function makeNonEmptyInterval(start, end) {
    if (end.isBefore(start)) {
      throw "End must be after start";
    }
    if (start.isSame(end)) {
      return makeEmptyInterval();
    }
    return {
      start: start,
      end: end,
      empty: false,
      contains(point) {
        return end.isAfter(point) && !start.isAfter(point);
      },
      duration() {
        return start.durationTo(end);
      },
      intersect(other) {
        if (!this.intersectsWith(other)) {
          return makeEmptyInterval();
        }
        var startToOtherStart = start.compare(other.start);
        var endToOtherEnd = end.compare(other.end);
        return makeNonEmptyInterval(startToOtherStart > 0 ? start : other.start, endToOtherEnd < 0 ? end : other.end);
      },
      intersectsWith(other) {
        if (other.empty) {
          return false;
        }
        return start.isBefore(other.end) && end.isAfter(other.start);
      },
      isSame(other) {
        if (other.empty) {
          return false;
        }
        return start.isSame(other.start) && end.isSame(other.end);
      },
      *iterate(nextFn) {
        let current = this.start;
        do {
          yield current;
          current = nextFn(current);
        } while (current.isBefore(this.end));
      },
      map(fnOrStartFn, endFn) {
        const newStart = fnOrStartFn(start);
        const newEnd = (endFn !== null && endFn !== void 0 ? endFn : fnOrStartFn)(end);
        if (newEnd.isBefore(newStart)) {
          return makeEmptyInterval();
        }
        return makeNonEmptyInterval(newStart, newEnd);
      },
      span(other) {
        if (other.empty) {
          return this;
        }
        return makeNonEmptyInterval(other.start.isBefore(start) ? other.start : start, other.end.isBefore(end) ? end : other.end);
      },
      split(endFromStart) {
        let rest = this;
        let result = [];
        do {
          const newEnd = endFromStart(rest.start);
          if (!newEnd.isBefore(rest.end) || !newEnd.isAfter(rest.start)) {
            result.push(rest);
            return makeIntervalSum(result);
          }
          result.push(makeNonEmptyInterval(rest.start, newEnd));
          rest = makeNonEmptyInterval(newEnd, rest.end);
        } while (!rest.empty);
        return makeIntervalSum(result);
      },
      subtract(other) {
        if (other.empty) {
          return makeIntervalSum([this]);
        }
        const startToOtherEnd = start.compare(other.end);
        if (startToOtherEnd >= 0) {
          return makeIntervalSum([this]);
        }
        const endToOtherStart = end.compare(other.start);
        if (endToOtherStart <= 0) {
          return makeIntervalSum([this]);
        }
        const startToOtherStart = start.compare(other.start);
        const endToOtherEnd = end.compare(other.end);
        if (startToOtherStart > 0) {
          if (endToOtherEnd <= 0) {
            return makeEmptyIntervalSum();
          } else {
            return makeIntervalSum([makeNonEmptyInterval(other.end, end)]);
          }
        } else if (startToOtherStart == 0) {
          if (endToOtherEnd > 0) {
            return makeIntervalSum([makeNonEmptyInterval(other.end, end)]);
          } else {
            return makeEmptyIntervalSum();
          }
        } else {
          if (endToOtherEnd > 0) {
            return makeIntervalSum([makeNonEmptyInterval(start, other.start), makeNonEmptyInterval(other.end, end)]);
          } else {
            return makeIntervalSum([makeNonEmptyInterval(start, other.start)]);
          }
        }
      },
      subtractSum(other) {
        if (other.empty) {
          return makeIntervalSum([this]);
        }
        return other.periods.map(x => this.subtract(x)).reduce((a, b) => a.intersect(b));
      },
      toString() {
        return start.toString() + " - " + end.toString();
      }
    };
  }

  /**
   * @returns {EmptyInterval}
   */
  function makeEmptyInterval() {
    return {
      empty: true,
      contains() {
        return false;
      },
      intersect() {
        return this;
      },
      intersectsWith() {
        return false;
      },
      isSame(other) {
        return other.empty;
      },
      *iterate() {},
      map() {
        return this;
      },
      span(other) {
        return other;
      },
      split() {
        return this;
      },
      subtract() {
        return this;
      },
      subtractSum() {
        return makeEmptyIntervalSum();
      },
      toString() {
        return "";
      }
    };
  }
  function makeIntervalSum(periods) {
    periods = periods.filter(x => !x.empty);
    if (periods.length === 0) {
      return makeEmptyIntervalSum();
    }
    periods = unionIntersecting(periods);
    periods.sort((a, b) => a.start.compare(b.start));
    return {
      empty: false,
      periods: periods,
      contains(point) {
        return periods.some(period => period.contains(point));
      },
      intersect(other) {
        return makeIntervalSum(periods.map(x => other.periods.map(y => x.intersect(y))).reduce((a, b) => a.concat(b), []));
      },
      intersectsWith(other) {
        return periods.some(x => other.periods.some(y => x.intersectsWith(y)));
      },
      joinAdjacent(predicate) {
        if (periods.length === 0) {
          return makeEmptyIntervalSum();
        }
        const {
          result
        } = periods.reduce((state, current) => {
          if (state.result.length === 0) {
            return {
              result: [current],
              lastJoinable: predicate(current)
            };
          } else {
            const last = state.result[state.result.length - 1];
            const currentJoinable = predicate(current);
            if (last.end.isSame(current.start) && state.lastJoinable && currentJoinable) {
              const joined = makeNonEmptyInterval(last.start, current.end);
              state.result[state.result.length - 1] = joined;
              return {
                result: state.result,
                lastJoinable: true
              };
            } else {
              state.result.push(current);
              return {
                result: state.result,
                lastJoinable: currentJoinable
              };
            }
          }
        }, {
          result: []
        });
        return makeIntervalSum(result);
      },
      split(endFromStart) {
        return makeIntervalSum(periods.map(x => x.split(endFromStart).periods).reduce((a, b) => a.concat(b), []));
      },
      toString() {
        return periods.map(x => x.toString()).join(", ");
      },
      union(other) {
        return other.empty ? this : makeIntervalSum([...periods, ...other.periods]);
      }
    };
    function unionIntersecting(periods) {
      return periods.reduce((a, b) => union(a, b), []);
      function union(periods, period) {
        const [intersecting, nonIntersecting] = partition(periods, x => x.intersectsWith(period));
        const merged = intersecting.reduce((a, b) => a.span(b), period);
        return [merged, ...nonIntersecting];
      }
      function partition(array, predicate) {
        return array.reduce(([trueElements, falseElements], element) => {
          const target = predicate(element) ? trueElements : falseElements;
          target.push(element);
          return [trueElements, falseElements];
        }, [[], []]);
      }
    }
  }
  function makeEmptyIntervalSum() {
    return {
      empty: true,
      periods: [],
      contains() {
        return false;
      },
      intersect() {
        return this;
      },
      intersectsWith() {
        return false;
      },
      joinAdjacent() {
        return this;
      },
      split() {
        return this;
      },
      toString() {
        return "";
      },
      union(other) {
        return other;
      }
    };
  }
  _module_exports = {
    empty: makeEmptyInterval,
    nonEmpty: makeNonEmptyInterval,
    sum: makeIntervalSum
  };
  return _module_exports;
})();

/**
 * MODULE shared\timelineLayout.js
 **/
const _module_shared_timelineLayout = (function shared_timelineLayout(
  _module_shared_intervals
) {
  let _module_exports = {};

  const intervals = _module_shared_intervals;
  function makeVerticalTimelineLayout(periods) {
    periods = periods.filter(x => !x.empty).map((x, i) => ({
      period: x,
      index: i
    }));
    if (periods.length === 0) {
      throw "The periods contain no non-empty intervals";
    }
    periods.sort(({
                    period: a
                  }, {
                    period: b
                  }) => a.start.compare(b.start));
    function toCoordinate(point) {
      const {
        period,
        index
      } = periods.find(x => x.period.contains(point)) || periods[periods.length - 1];
      if (!period.contains(point) && !period.end.isSame(point)) {
        throw "The point + " + point.toString() + " is outside of all periods";
      }
      const pointPositionWithinPeriod = period.start.durationTo(point).totalMinutes / period.start.durationTo(period.end).totalMinutes;
      return (index + pointPositionWithinPeriod) / periods.length;
    }
    function toCoordinates(interval) {
      if (interval.empty) {
        throw "Empty intervals are not supported";
      }
      const start = toCoordinate(interval.start);
      const end = toCoordinate(interval.end);
      return {
        start,
        duration: end - start
      };
    }
    return {
      cssHeight(interval, totalHeight) {
        const {
          duration
        } = toCoordinates(interval);
        return duration * totalHeight + "px";
      },
      toCssPercents(interval) {
        function toCssPercent(value) {
          return (value * 100).toFixed(5) + "%";
        }
        const {
          start,
          duration
        } = toCoordinates(interval);
        return {
          top: toCssPercent(start),
          height: toCssPercent(duration)
        };
      }
    };
  }
  function makeHorizontalTimelineLayout(items) {
    const sortedItems = [...items];
    sortedItems.sort((a, b) => comparePeriods(a.period, b.period));
    const container = makeContainer(sortedItems);
    return container.layout();
    function makeColumn(initialItem) {
      let coverage = intervals.sum([initialItem.period]);
      let items = [initialItem];
      return {
        dump() {
          return {
            coverage: coverage.toString(),
            items: items.map(x => ({
              period: x.period.toString(),
              id: x.id
            }))
          };
        },
        layout(layout, columnIndex, sizes) {
          return items.reduce((layout, item) => {
            layout[item.id] = sizes(columnIndex, 1);
            return layout;
          }, layout);
        },
        tryAdd(item) {
          const itemPeriod = intervals.sum([item.period]);
          if (coverage.intersectsWith(itemPeriod)) {
            return false;
          }
          coverage = coverage.union(itemPeriod);
          items.push(item);
          return true;
        }
      };
    }
    function makeContainer(items) {
      const columns = items.reduce((columns, item) => {
        if (!columns.some(x => x.tryAdd(item))) {
          columns.push(makeColumn(item));
        }
        return columns;
      }, []);
      return {
        dump() {
          return columns.map(x => x.dump());
        },
        layout() {
          const columnCount = columns.length;
          return {
            cssPercents(offsetWithinContainer, containerWidth) {
              return layout(cssPercents(offsetWithinContainer, containerWidth));
            },
            cssPixels(offsetOutsideContainer, containerWidth) {
              return layout(cssPixels(offsetOutsideContainer, containerWidth));
            }
          };
          function cssPercents(offsetWithinContainer, containerWidth) {
            const offsetPercentage = offsetWithinContainer / containerWidth * 100;
            const scale = (containerWidth - offsetWithinContainer) / containerWidth / columnCount * 100;
            return function (left, width) {
              return {
                left: offsetPercentage + left * scale + "%",
                width: width * scale + "%"
              };
            };
          }
          function cssPixels(offset, containerWidth) {
            const scale = containerWidth / columnCount;
            return function (left, width) {
              return {
                left: offset + left * scale + "px",
                width: width * scale + "px"
              };
            };
          }
          function layout(sizes) {
            return columns.reduce((layout, column, index) => column.layout(layout, index, sizes), {});
          }
        }
      };
    }
    function comparePeriods(a, b) {
      const start = a.start.compare(b.start);
      return start != 0 ? start : -a.end.compare(b.end);
    }
  }
  function makePeriods(activities, intervals, {
    breakDuration
  }) {
    if (!activities.length) {
      return [];
    }
    const span = activities.reduce((a, b) => a.span(b));
    const breaks = makeBreaks(span);
    const periods = breaks.periods.map(x => ({
      description: periodDescription(x),
      isBreak: true,
      number: "",
      period: x
    }));
    periods.sort(({
                    period: a
                  }, {
                    period: b
                  }) => a.start.compare(b.start));
    return periods;
    function makeBreaks(span) {
      const periods = [];
      const adjustedSpan = intervals.nonEmpty(span.start.startOfHour(), span.end);
      let period = intervals.nonEmpty(adjustedSpan.start, adjustedSpan.start.addDuration(breakDuration));
      while (period.intersectsWith(adjustedSpan)) {
        periods.push(period);
        period = period.map(x => x.addDuration(breakDuration));
      }
      return intervals.sum(periods);
    }
    function periodDescription(x) {
      if (x.start.minute === 0 && x.end.minute === 0 && x.end.hour - x.start.hour === 1) {
        return x.start.toString();
      }
      return x.toString();
    }
  }
  function defaultBreakDurations(time) {
    return {
      durations: [time.duration(180), time.duration(120), time.duration(60), time.duration(30)],
      initial: time.duration(60)
    };
  }
  function makeTextLines({
                           breakDuration,
                           minutesPerLineAtOneHourBreak
                         }) {
    const linesPerMinute = 60 / breakDuration.totalMinutes / minutesPerLineAtOneHourBreak;
    return textLines;
    function textLines(interval) {
      const duration = interval.duration();
      const lines = duration.totalMinutes * linesPerMinute;
      return Math.round(lines);
    }
  }
  _module_exports = {
    defaultBreakDurations,
    makeTextLines,
    makeHorizontal: makeHorizontalTimelineLayout,
    makeVertical: makeVerticalTimelineLayout,
    makePeriods
  };
  return _module_exports;
})(_module_shared_intervals);

/**
 * MODULE shared\future.js
 **/
const _module_shared_future = (function shared_future() {
  let _module_exports = {};

  /** @constructor */
  function Future() {
    this.promise = new Promise((resolve, reject) => {
      this._resolve = resolve;
      this._reject = reject;
    });
  }
  Future.prototype.reject = function (value) {
    this._reject(value);
  };
  Future.prototype.resolve = function (value) {
    this._resolve(value);
  };
  _module_exports = {
    Future
  };
  return _module_exports;
})();

/**
 * MODULE shared\aula.js
 **/
const _module_shared_aula = (function shared_aula(
  _module_shared_future
) {
  let _module_exports = {};

  const {
    Future
  } = _module_shared_future;
  const AulaTokenMixin = {
    data() {
      return {
        /** @private */
        aulaToken: undefined,
        /** @private */
        aulaTokenFuture: new Future()
      };
    },
    computed: {
      /** @public */
      token() {
        return this.aulaTokenFuture.promise;
      }
    },
    watch: {
      aulaToken(newValue) {
        if (newValue == undefined) {
          return;
        }
        this.aulaTokenFuture.resolve(newValue);
        this.aulaTokenFuture = new Future();
        this.aulaTokenFuture.resolve(newValue);
      }
    },
    mounted() {
      this.aulaToken = this.getAulaToken();
    }
  };
  _module_exports = {
    AulaTokenMixin
  };
  return _module_exports;
})(_module_shared_future);

/**
 * MODULE shared\date.js
 **/
const _module_shared_date = (function shared_date() {
  let _module_exports = {};

  /**
   * A number identifying a day of week.
   * Days are numbered from 0, starting with monday, up to sunday.
   * @typedef {number} DayOfWeek
   */

  /**
   * A date.
   * @typedef {Object} Date
   * @property {number} year Year of the date.
   * @property {number} month Month of the date (1-12).
   * @property {number} day Day of month of the date (1-31).
   * @property {DayOfWeek} dayOfWeek Day of week of the date (0-6).
   */

  /**
   * @returns {Date}
   */
  function makePoint(year, month, day, moment) {
    return wrapMoment(moment()().year(year).month(month - 1).date(day).hour(0).minute(0).second(0).millisecond(0));
  }

  /**
   * @returns {Date}
   */
  function wrapMoment(value) {
    if (!value.isValid()) {
      const message = invalidDateMessage(value);
      throw new Error("Invalid date".concat(message ? ". " + message : "", "."));
    }
    return {
      _value: value,
      get year() {
        return this._value.year();
      },
      get month() {
        return this._value.month() + 1;
      },
      get day() {
        return this._value.date();
      },
      get dayOfWeek() {
        const day = this._value.day();
        return day == 0 ? 6 : day - 1;
      },
      addDays(count) {
        return wrapMoment(this._value.clone().add(count, "days"));
      },
      compare(other) {
        return this._value.diff(other._value, "days");
      },
      /**
       * Formats the date to a string.
       * Supported formats are "dayofweek" and "input".
       * The "dayofweek" format specifies that long name of the date's day of week should be used.
       * The "input" format results in a string understood by input elements of type "date" ('yyyy-mm-dd').
       * @param {string} format A format specification to use. The only supported value is "dayofweek".
       * @returns A string representation of the date, according to specified format.
       * @throws {Error} Throws when format argument is none of the supported values.
       */
      format(format) {
        switch (format) {
          case "dayofweek":
            return this._value.format("dddd");
          case "input":
            return this._value.format("YYYY-MM-DD");
          default:
            throw new Error("Unknown date point format \"".concat(format, "\""));
        }
      },
      isAfter(other) {
        return this._value.isAfter(other._value);
      },
      isBefore(other) {
        return this._value.isBefore(other._value);
      },
      isSame(other) {
        return this._value.isSame(other._value);
      },
      toJSON() {
        return this._value.format("YYYY-MM-DD");
      },
      toString() {
        return this._value.format("L");
      }
    };
  }
  function invalidDateMessage(value) {
    switch (value.invalidAt()) {
      case 0:
        return "Year value ".concat(value.year(), " is invalid");
      case 1:
        return "Month value ".concat(value.month(), " is invalid");
      case 2:
        return "Day value ".concat(value.date(), " is invalid");
      default:
        return null;
    }
  }

  /**
   * @returns {?Date}
   */
  function parsePoint(value, moment) {
    const result = value.match(/(\d{1,5})-(\d{1,2})-(\d{1,2})/u);
    if (result && result[0] === value) {
      const [, year, month, day] = result;
      return makePoint(Number.parseInt(year, 10), Number.parseInt(month, 10), Number.parseInt(day, 10), moment);
    } else {
      return null;
    }
  }
  _module_exports = {
    parsePoint,
    point: makePoint,
    wrapMoment(value) {
      return wrapMoment(value.clone().hour(0).minute(0).second(0).millisecond(0));
    }
  };
  return _module_exports;
})();

/**
 * MODULE shared\api.js
 **/
const _module_shared_api = (function shared_api() {
  let _module_exports = {};

  function arrayToQueryString(name, value) {
    return value.map(x => {
      var _x$toJSON, _x$toJSON2;
      return "".concat(name, "=").concat((_x$toJSON = x === null || x === void 0 ? void 0 : (_x$toJSON2 = x.toJSON) === null || _x$toJSON2 === void 0 ? void 0 : _x$toJSON2.call(x)) !== null && _x$toJSON !== void 0 ? _x$toJSON : x);
    }).join("&");
  }
  function responseError(result) {
    var _ref, _result$response$data, _result$response, _result$response$data2, _result$response2, _result$response2$dat, _result$response2$dat2;
    return Promise.reject((_ref = (_result$response$data = result === null || result === void 0 ? void 0 : (_result$response = result.response) === null || _result$response === void 0 ? void 0 : (_result$response$data2 = _result$response.data) === null || _result$response$data2 === void 0 ? void 0 : _result$response$data2.Message) !== null && _result$response$data !== void 0 ? _result$response$data : result === null || result === void 0 ? void 0 : (_result$response2 = result.response) === null || _result$response2 === void 0 ? void 0 : (_result$response2$dat = _result$response2.data) === null || _result$response2$dat === void 0 ? void 0 : (_result$response2$dat2 = _result$response2$dat.Error) === null || _result$response2$dat2 === void 0 ? void 0 : _result$response2$dat2.Message) !== null && _ref !== void 0 ? _ref : result.message);
  }
  _module_exports = {
    arrayToQueryString,
    responseError
  };
  return _module_exports;
})();

/**
 * MODULE schools_absence_overview\api.js
 **/
const _module_widget_api = (function widget_api(
  _module_shared_api,
  _module_shared_date,
  _module_shared_intervals,
  _module_shared_time
) {
  let _module_exports = {};

  const api = _module_shared_api;
  const dates = _module_shared_date;
  const intervals = _module_shared_intervals;
  const time = _module_shared_time;

  /** @typedef {import('../shared/date').Date} Date */
  /** @typedef {import('../shared/intervals').Interval} Interval */
  /** @typedef {import('./store').Absence} Absence */
  /** @typedef {import('./store').AbsenceReason} AbsenceReason */
  /** @typedef {import('./store').AbsenceReasonId} AbsenceReasonId */
  /** @typedef {import('./store').Activity} Activity */
  /** @typedef {import('./store').Department} Department */
  /** @typedef {import('./store').DepartmentId} DepartmentId */
  /** @typedef {import('./store').PersonId} PersonId */
  /** @typedef {import('./store').School} School*/
  /** @typedef {import('./store').SchoolCode} SchoolCode */

  /**
   * A person
   * @typedef {Object} Person
   * @property {Period[]} absentPeriods The date periods, during which the person is absent. Dates are in YYYY-MM-DD format.
   * @property {String} fullName The full name of the person.
   * @property {PersonId} id The id of the person.
   * @property {String} initials The initials of the person.
   */

  /**
   * Schools and their departments.
   * @typedef {Object} SchoolsAndDepartments
   * @property {Interval<Date>} periodOfDepartments The date period, which all departments intersect.
   * @property {School[]} schools The schools (with departments).
   */

  /**
   * A timeline, which consists of activities, absences, and personnel.
   * @typedef {Object} Timeline
   * @property {Absence[]} absences The absences.
   * @property {Activity[]} activities The activities.
   * @property {Person[]} personnel The personnel.
   */

  /**
   * @classdesc A school's absence overview API.
   * @constructor
   * @param {Function} axios Function returning the Axios instance.
   * @param {Function} token Function returning a promise resolving to an Aula token.
   * @param {Function} moment Function returning the Moment instance.
   */
  function Api(axios, token, moment) {
    this._moment = moment;
    this._axios = () => {
      return token().then(x => axios().create({
        headers: {
          Authorization: x
        }
      }));
    };
  }
  Api.prototype = {
    /**
     * Gets reasons of absences.
     * @returns {Promise<AbsenceReason[]>} The reasons of absences.
     */
    absenceReasons() {
      return this._axios().then(axios => axios.get("https://personale.api.kmd.dk/aula/api/v1/absenceOverview/absenceReasons", {
        headers: {
          Accept: "application/json"
        }
      })).then(response => response.data, api.responseError);
    },
    /**
     * Gets schools with with specific codes.
     * @param {SchoolCode[]} schoolCodes The codes of the schools.
     * @param {Date} date The reference date.
     * @returns {Promise<SchoolsAndDepartments>} The reasons of absences.
     */
    schools(schoolCodes, date) {
      return this._axios().then(axios => axios.get("https://personale.api.kmd.dk/aula/api/v1/absenceOverview/departments", {
        headers: {
          Accept: "application/json"
        },
        params: {
          schoolCodes: schoolCodes.map(x => x.trim()).join(","),
          referenceDate: date.toJSON()
        }
      })).then(response => ({
        periodOfDepartments: intervals.nonEmpty(dates.parsePoint(response.data.periodOfDepartments.start, this._moment), dates.parsePoint(response.data.periodOfDepartments.end, this._moment).addDays(1)),
        schools: response.data.schools
      }), api.responseError);
    },
    /**
     * Gets timeline of school/department of a school (or department withing the school), on a date.
     * @param {SchoolCode} schoolCode The code of school whose timeline to load.
     * @param {DepartmentId} departmentId The department id of the school, whose timeline to load. If null, school's timeline is loaded.
     * @param {Date} date The date of timeline.
     * @param {Boolean} includeSfo Indicates whether to include SFO duty hours periods in activities.
     * @returns {Promise<Timeline>} The timeline.
     */
    timeline(schoolCode, departmentId, date, includeSfo) {
      return this._axios().then(axios => axios.get("https://personale.api.kmd.dk/aula/api/v1/absenceOverview/timeline", {
        headers: {
          Accept: "application/json"
        },
        params: {
          date: date.toJSON(),
          departmentId,
          includeSfo,
          schoolCode
        }
      })).then(response => ({
        absences: response.data.absences,
        activities: response.data.activities.map((x, i) => ({
          abbreviationOfSubject: x.abbreviationOfSubject,
          absentee: x.absentee,
          classes: x.classes,
          description: x.description,
          endLesson: x.endLesson,
          id: i,
          period: intervals.nonEmpty(time.parsePoint(x.start), time.parsePoint(x.end)),
          note: x.note,
          rooms: x.rooms,
          startLesson: x.startLesson,
          status: x.status,
          substitute: x.substitute
        })),
        personnel: response.data.personnel
      }), api.responseError);
    }
  };
  _module_exports = {
    Api
  };
  return _module_exports;
})(_module_shared_api,
  _module_shared_date,
  _module_shared_intervals,
  _module_shared_time);

/**
 * WIDGET CODE
 **/

const {
  AulaTokenMixin
} = _module_shared_aula;
const dates = _module_shared_date;
const intervals = _module_shared_intervals;
const rangeInput = _module_shared_rangeInput;
const {
  escapeHtml
} = _module_shared_prelude;
const settings = _module_shared_settings;
const time = _module_shared_time;
const timelineLayout = _module_shared_timelineLayout;
const {
  makeAbsence
} = _module_widget_absences;
const {
  absenceTypes
} = _module_widget_absenceTypes;
const {
  Actions
} = _module_widget_actions;
const {
  Api
} = _module_widget_api;
const {
  makePersonnel
} = _module_widget_personnel;
const {
  Store
} = _module_widget_store;
const absenceTypesCount = absenceTypes.length;
function activityWidthInput() {
  return rangeInput.selectingItem({
    items: items(),
    initialItem: 180
  });
  function items() {
    const items = [];
    for (var x = 50; x <= 200; x += 10) {
      items.push(x);
    }
    return items;
  }
}
function breakDurationInput() {
  const {
    durations,
    initial
  } = timelineLayout.defaultBreakDurations(time);
  return rangeInput.selectingItem({
    items: durations,
    initialItem: initial,
    itemCompare(a, b) {
      return a.isSame(b);
    }
  });
}
// @vue/component
export default {
  name: "SchoolsAbsenceOverview",
  mixins: [AulaTokenMixin],
  props: {
    axios: {
      type: Function,
      required: true
    },
    currentWeekNumber: {
      type: String,
      default: null
    },
    getAulaToken: {
      type: Function,
      required: true
    },
    institutionCode: {
      type: String,
      default: null
    },
    institutionFilter: {
      type: Array,
      default: null
    },
    isMobileApp: {
      type: Boolean,
      required: true
    },
    moment: {
      type: Function,
      required: true
    },
    placement: {
      type: String,
      required: true
    },
    sessionUUID: {
      type: String,
      required: true
    }
  },
  data: function () {
    const api = new Api(() => this.axios, () => this.token, () => this.moment);
    const store = new Store(this.$set.bind(this));
    const actions = new Actions(api, store);
    return {
      actions,
      absenceOverviewWidth: 0,
      activityWidthInput: activityWidthInput(),
      busy: false,
      breakDurationInput: breakDurationInput(),
      error: null,
      overViewFiltersSelected: ["vikarnoter", ""],
      overViewFilters: [{
        text: "Vikarnoter",
        value: "vikarnoter"
      }, {
        text: "SFO-opgaver",
        value: "sfo"
      }],
      listFiltersSelectedLeftColumn: ["lejrskole", "klassefravaer", "kursus"],
      listFiltersLeftColumn: [{
        text: "Lejrskole",
        value: "lejrskole"
      }, {
        text: "Klassefravær",
        value: "klassefravaer"
      }, {
        text: "Kursus",
        value: "kursus"
      }],
      listFiltersSelectedRightColumn: ["fravaerende", "andreopgaver", "andenundervisning"],
      listFiltersRightColumn: [{
        text: "Fraværende",
        value: "fravaerende"
      }, {
        text: "Andre opgaver",
        value: "andreopgaver"
      }, {
        text: "Anden undervisning",
        value: "andenundervisning"
      }],
      currentSort: "startDate",
      currentSortDir: "asc",
      filterActivityNotes: true,
      filterSfoActivities: false,
      inputSelectedDate: null,
      isLoading: false,
      note: null,
      tableWrapperStyleObject: {
        maxWidth: ""
      },
      selectedInstitution: null,
      selectedSchool: null,
      selectedPerson: null,
      selectedDepartment: null,
      settings: null,
      showFravaerende: true,
      showKlassefravaer: true,
      showKursus: true,
      showLejrSkole: true,
      showModal: false,
      showOtherActivities: true,
      showOtherLessons: true,
      store,
      week: null,
      year: null,
      mobileThreshold: 991,
      isMobileView: false,
      listColumns: [{
        label: "Start dato",
        value: "startDate"
      }, {
        label: "Slut dato",
        value: "endDate"
      }, {
        label: "Type",
        value: "type"
      }, {
        label: "Beskrivelse",
        value: "description"
      }, {
        label: "Initialer",
        value: "initials"
      }, {
        label: "Klasser",
        value: "classes"
      }, {
        label: "Fra kl.",
        value: "startTime"
      }, {
        label: "Til kl.",
        value: "endTime"
      }]
    };
  },
  computed: {
    absences() {
      const timeline = this.timeline;
      const absences = timeline.absences.map(absence => {
        var _this$store$personnel;
        return makeAbsence(absence, (_this$store$personnel = this.store.personnel[this.selectedSchool]) !== null && _this$store$personnel !== void 0 ? _this$store$personnel : [], this.store.absenceReasons);
      });
      return absences;
    },
    activityCellStyle() {
      return this.periods.map((_, periodIndex) => {
        const height = "height: " + this.periodVerticalLayout[periodIndex].height + ";";
        if (this.isNarrow) {
          return height;
        }
        const minWidth = "min-width: " + this.activityWidthInput.item() + "px;";
        const maxWidth = "max-width: " + this.activityWidthInput.item() + "px;";
        return minWidth + " " + maxWidth + " " + height;
      });
    },
    activityHeaderCellStyle() {
      if (this.isNarrow) {
        return "";
      }
      const minWidth = "min-width: " + this.activityWidthInput.item() + "px;";
      const maxWidth = "max-width: " + this.activityWidthInput.item() + "px;";
      return minWidth + " " + maxWidth;
    },
    activityTextLines() {
      if (!this.personnel.length) {
        return {};
      }
      const textLines = timelineLayout.makeTextLines({
        breakDuration: this.breakDurationInput.item(),
        minutesPerLineAtOneHourBreak: 15
      });
      return this.personnel.reduce((result, person) => person.activities.reduce((result, activity) => {
        result[activity.id] = textLines(activity.period);
        return result;
      }, result), {});
    },
    activityHorizontalLayout() {
      if (!this.shownPersonnel.length) {
        return {};
      }
      const isNarrow = this.isNarrow || this.isMobileView;
      const activityColumnWidth = isNarrow ? 230 : this.activityWidthInput.item();
      const periodColumnWidth = 60;
      const result = this.shownPersonnel.reduce((layouts, person, personIndex) => {
        const offset = periodColumnWidth + activityColumnWidth * personIndex;
        const layout = timelineLayout.makeHorizontal(person.activities).cssPixels(offset, activityColumnWidth);
        layouts[personIndex] = layout;
        return layouts;
      }, {});
      return result;
    },
    activityLayoutSpan() {
      const span = this.shownPersonnel.reduce((result, person) => person.activities.reduce((result, activity) => {
        return result.span(activity.period);
      }, result), intervals.empty());
      if (span.empty) {
        return span;
      }
      const start = span.start.startOfHour();
      const end = endFromStart(span.start, span.end);
      return intervals.nonEmpty(start, end);
      function endFromStart(start, end) {
        const step = time.duration(60);
        var newEnd = start.startOfHour();
        while (newEnd.isBefore(end)) {
          newEnd = newEnd.addDuration(step);
        }
        return newEnd;
      }
    },
    activityLayoutHeight() {
      const spanOfActivities = this.activityLayoutSpan;
      if (spanOfActivities.empty) {
        return 0;
      }
      const breakDuration = this.breakDurationInput.item();
      return spanOfActivities.duration().totalMinutes / breakDuration.totalMinutes * 110;
    },
    activityVerticalLayout() {
      if (!this.shownPersonnel.length || this.activityLayoutSpan.empty) {
        return {};
      }
      const layout = timelineLayout.makeVertical([this.activityLayoutSpan]);
      return this.shownPersonnel.reduce((result, person) => person.activities.reduce((result, activity) => {
        result[activity.id] = layout.toCssPercents(activity.period);
        return result;
      }, result), {});
    },
    isNarrow() {
      return this.placement.toLowerCase() === "narrow";
    },
    periods() {
      if (!this.personnel.length || this.activityLayoutSpan.empty) {
        return [];
      }
      return timelineLayout.makePeriods([this.activityLayoutSpan], intervals, {
        breakDuration: time.duration(60)
      });
    },
    periodVerticalLayout() {
      const periods = this.periods;
      const span = this.activityLayoutSpan;
      const totalHeight = this.activityLayoutHeight;
      if (!periods.length || span.empty || totalHeight == 0) {
        return {};
      }
      const layout = timelineLayout.makeVertical([span]);
      return periods.reduce((result, period, index) => {
        result[index] = {
          height: layout.cssHeight(period.period, totalHeight)
        };
        return result;
      }, {});
    },
    personAbsentPeriods() {
      const personAbsentPeriods = this.timeline.personAbsentPeriods;
      return personAbsentPeriods;
    },
    personnel() {
      var _this$store$personnel2;
      const personnel = (_this$store$personnel2 = this.store.personnel[this.selectedSchool]) !== null && _this$store$personnel2 !== void 0 ? _this$store$personnel2 : [];
      const timeline = this.timeline;
      return makePersonnel(timeline.absences, timeline.activities, personnel, timeline.personAbsentPeriods, this.store.absenceReasons);
    },
    schoolCodes() {
      var _this$institutionFilt;
      return this.placement === "NoticeBoard" && this.institutionCode ? [this.institutionCode] : (_this$institutionFilt = this.institutionFilter) !== null && _this$institutionFilt !== void 0 ? _this$institutionFilt : [];
    },
    shownPersonnel() {
      if (this.isNarrow || this.isMobileView) {
        return this.selectedPerson ? [this.selectedPerson] : [];
      }
      return this.personnel;
    },
    schoolsAndDepartmentsSelectList() {
      const indentStep = "&nbsp;&nbsp;";
      return this.store.schools.map(x => {
        return [{
          value: value(null, x.code),
          html: x.name
        }, ...items(x.topDepartments, x.code, indentStep)];
      }).reduce((a, b) => a.concat(b), []);
      function items(departments, schoolCode, indent) {
        return departments.map(x => [{
          value: value(x.id, schoolCode),
          html: indent + escapeHtml(x.name)
        }, ...items(x.children, schoolCode, indent + indentStep)]).reduce((a, b) => a.concat(b), []);
      }
      function value(departmentId, schoolCode) {
        return (departmentId || "") + ";" + schoolCode;
      }
    },
    selectedDate() {
      const date = dates.parsePoint(this.inputSelectedDate, () => this.moment);
      return date;
    },
    selectedSchoolCodeOrDepartmentId() {
      return this.selectedDepartment || this.selectedSchool || null;
    },
    filteredAbsences() {
      const visibleTypes = [];
      if (this.showLejrSkole) {
        visibleTypes.push(0);
      }
      if (this.showKlassefravaer) {
        visibleTypes.push(1);
      }
      if (this.showKursus) {
        visibleTypes.push(2);
      }
      if (this.showFravaerende) {
        visibleTypes.push(3,
          // Anonymiseret fravær
          6 // Andet
        );
      }

      if (this.showOtherActivities) {
        visibleTypes.push(4);
      }
      if (this.showOtherLessons) {
        visibleTypes.push(5);
      }
      if (!visibleTypes.length) {
        return [];
      }
      if (visibleTypes.length === absenceTypesCount) {
        return this.sortedAbsences;
      }
      return this.sortedAbsences.filter(x => visibleTypes.includes(x.typeId));
    },
    sortedAbsences() {
      const sortedAbsences = this.absences.slice(0);
      const modifier = this.currentSortDir === "desc" ? -1 : 1;
      return sortedAbsences.sort((a, b) => {
        if (a[this.currentSort] < b[this.currentSort]) {
          return -1 * modifier;
        }
        if (a[this.currentSort] > b[this.currentSort]) {
          return 1 * modifier;
        }
        return 0;
      });
    },
    timeline() {
      var _this$store$timelines, _this$store$timelines2, _this$selectedDate;
      const timeline = (_this$store$timelines = this.store.timelines[this.selectedSchoolCodeOrDepartmentId]) === null || _this$store$timelines === void 0 ? void 0 : (_this$store$timelines2 = _this$store$timelines[(_this$selectedDate = this.selectedDate) === null || _this$selectedDate === void 0 ? void 0 : _this$selectedDate.toJSON()]) === null || _this$store$timelines2 === void 0 ? void 0 : _this$store$timelines2[this.filterSfoActivities];
      return timeline !== null && timeline !== void 0 ? timeline : {
        absences: [],
        activities: [],
        personAbsentPeriods: {}
      };
    }
  },
  watch: {
    // If the user changes week (in the calendar e.g.)
    // The prop currentWeekNumber is changed
    // We want to get the notes for that chosen week
    currentWeekNumber: function (newWeek, oldWeek) {
      if (newWeek && newWeek.toLowerCase() !== oldWeek.toLowerCase()) {
        this.isLoading = true;
        this.week = this.extractWeekNumber(newWeek);
        this.inputSelectedDate = this.extractSelectedDate();
        this.loadTimeline();
      }
    },
    institutionCode: function () {
      this.isLoading = true;
      this.loadSettings();
    },
    institutionFilter: function () {
      this.isLoading = true;
      this.loadSchoolsAndDepartments();
    }
  },
  created: function () {
    this.isLoading = true;
    this.moment.locale("da");
    this.week = this.extractWeekNumber(this.currentWeekNumber);
    this.inputSelectedDate = this.extractSelectedDate();
  },
  mounted: function () {
    window.addEventListener("resize", this.resizeEventHandler);
    this.resizeEventHandler();
    this.isLoading = true;
    this.loadSettings();
  },
  beforeDestroy: function () {
    window.removeEventListener("resize", this.resizeEventHandler);
  },
  methods: {
    handleInstitutionChanged(value) {
      const oldSelectedSchool = this.selectedSchool;
      const oldSelectedDepartment = this.selectedDepartment;
      this.selectedInstitution = value;
      this.settings.setPersistent("selectedInstitution", value);
      [this.selectedDepartment, this.selectedSchool] = value.split(";");
      this.selectedDepartment = this.selectedDepartment || null;
      if (oldSelectedSchool !== this.selectedSchool || oldSelectedDepartment !== this.selectedDepartment) {
        this.isLoading = true;
        this.loadTimeline();
      }
    },
    handleOverviewFilter(value) {
      this.settings.setPersistent("showActivityNotes", value.includes("vikarnoter"));
      this.settings.setPersistent("showSfoActivities", value.includes("sfo"));
      if (value.includes("vikarnoter") && !this.filterActivityNotes) {
        this.filterActivityNotes = true;
      } else if (!value.includes("vikarnoter") && this.filterActivityNotes) {
        this.filterActivityNotes = false;
      }
      if (value.includes("sfo") && !this.filterSfoActivities) {
        this.busy = true;
        this.filterSfoActivities = true;
        this.loadTimeline();
      } else if (!value.includes("sfo") && this.filterSfoActivities) {
        this.busy = true;
        this.filterSfoActivities = false;
        this.loadTimeline();
      }
    },
    handleListFilterLeftColumn(value) {
      if (value.includes("lejrskole") && !this.showLejrSkole) {
        this.showLejrSkole = true;
      } else if (!value.includes("lejrskole") && this.showLejrSkole) {
        this.showLejrSkole = false;
      }
      if (value.includes("klassefravaer") && !this.showKlassefravaer) {
        this.showKlassefravaer = true;
      } else if (!value.includes("klassefravaer") && this.showKlassefravaer) {
        this.showKlassefravaer = false;
      }
      if (value.includes("kursus") && !this.showKursus) {
        this.showKursus = true;
      } else if (!value.includes("kursus") && this.showKursus) {
        this.showKursus = false;
      }
      this.updateListFilterSettings();
    },
    handleListFilterRightColumn(value) {
      if (value.includes("fravaerende") && !this.showFravaerende) {
        this.showFravaerende = true;
      } else if (!value.includes("fravaerende") && this.showFravaerende) {
        this.showFravaerende = false;
      }
      if (value.includes("andreopgaver") && !this.showOtherActivities) {
        this.showOtherActivities = true;
      } else if (!value.includes("andreopgaver") && this.showOtherActivities) {
        this.showOtherActivities = false;
      }
      if (value.includes("andenundervisning") && !this.showOtherLessons) {
        this.showOtherLessons = true;
      } else if (!value.includes("andenundervisning") && this.showOtherLessons) {
        this.showOtherLessons = false;
      }
      this.updateListFilterSettings();
    },
    updateListFilterSettings() {
      const settings = [];
      if (this.showLejrSkole) {
        settings.push(0);
      }
      if (this.showKlassefravaer) {
        settings.push(1);
      }
      if (this.showKursus) {
        settings.push(2);
      }
      if (this.showFravaerende) {
        settings.push(3);
      }
      if (this.showOtherActivities) {
        settings.push(4);
      }
      if (this.showOtherLessons) {
        settings.push(5);
      }
      this.settings.setPersistent("shownAbsenceTypes", settings);
    },
    checkForNotes(activity) {
      if (this.filterActivityNotes && activity.note) {
        this.note = activity.note;
        return true;
      }
      return false;
    },
    handleDateChange() {
      const date = dates.parsePoint(this.inputSelectedDate, () => this.moment);
      if (!date) {
        return;
      }
      this.busy = true;
      if (!this.store.periodOfDepartments || !this.store.periodOfDepartments.contains(date)) {
        this.loadSchoolsAndDepartments();
      } else {
        this.loadTimeline();
      }
    },
    extractWeekNumber(week) {
      const regex = /^(\d){4}-W(\d){1,2}$/u;
      // If there's something wrong with the data
      if (!regex.test(week)) {
        return this.moment().week();
      }
      const [yearExtracted, weekExtracted] = week.split("-W");
      const weekNumber = parseInt(weekExtracted, 10);
      this.year = parseInt(yearExtracted, 10);
      return weekNumber;
    },
    extractSelectedDate() {
      const selectedWeekOfYear = this.moment().year(this.year).week(this.week);
      const today = this.moment();
      const selectedDate = today.isSame(selectedWeekOfYear, "week") ? today : selectedWeekOfYear.weekday(0);
      return selectedDate.format("YYYY-MM-DD");
    },
    handleErrorResponse(error) {
      this.isLoading = false;
      this.busy = false;
      this.error = error.response ? error.response.data.Message : error.message || "Ukendt fejl";
    },
    loadAbsenceReasons() {
      this.actions.loadAbsenceReasons().then(() => {
        this.loadSchoolsAndDepartments();
      }).catch(err => {
        this.handleErrorResponse(err);
      });
    },
    getWeekFromLocale() {
      return this.moment().week();
    },
    loadSchoolsAndDepartments() {
      if (!this.schoolCodes.length || this.schoolCodes[0] === "") {
        this.selectedInstitution = null;
        this.selectedSchool = null;
        this.selectedDepartment = null;
        return;
      }
      const date = this.selectedDate;
      if (!date) {
        return;
      }
      this.actions.loadSchools(this.schoolCodes, date).then(() => {
        const [firstSchool] = this.store.schools;
        this.selectedInstitution = this.settings.getPersistent("selectedInstitution", ";" + firstSchool.code,
          // ";xxx" means "school with code xxx"
          x => this.schoolsAndDepartmentsSelectList.some(y => y.value === x));
        [this.selectedDepartment, this.selectedSchool] = this.selectedInstitution.split(";");
        this.selectedDepartment = this.selectedDepartment || null;
        this.loadTimeline();
      }).catch(err => {
        this.selectedInstitution = null;
        this.selectedSchool = null;
        this.selectedDepartment = null;
        this.handleErrorResponse(err);
      });
    },
    loadSettings() {
      this.token.then(aulaToken => settings(this.axios, aulaToken, this.sessionUUID, this.placement === "NoticeBoard", this.institutionCode, "schools-absence-overview").then(settings => {
        this.settings = settings;
        this.filterActivityNotes = settings.getPersistent("showActivityNotes", true);
        this.filterSfoActivities = settings.getPersistent("showSfoActivities", true);
        this.breakDurationInput.value = settings.getPersistent("breakDurationInputValue", this.breakDurationInput.value);
        this.$watch("breakDurationInput.value", x => {
          settings.setPersistent("breakDurationInputValue", x);
        });
        this.activityWidthInput.value = settings.getPersistent("activityWidthInputValue", this.activityWidthInput.value);
        this.$watch("activityWidthInput.value", x => {
          settings.setPersistent("activityWidthInputValue", x);
        });
        const shownAbsenceTypes = settings.getPersistent("shownAbsenceTypes", [0, 1, 2, 3, 4, 5]);
        this.showLejrSkole = shownAbsenceTypes.includes(0);
        this.showKlassefravaer = shownAbsenceTypes.includes(1);
        this.showKursus = shownAbsenceTypes.includes(2);
        this.showFravaerende = shownAbsenceTypes.includes(3);
        this.showOtherActivities = shownAbsenceTypes.includes(4);
        this.showOtherLessons = shownAbsenceTypes.includes(5);
        const overViewFiltersSelected = [];
        if (this.filterActivityNotes) {
          overViewFiltersSelected.push("vikarnoter");
        }
        if (this.filterSfoActivities) {
          overViewFiltersSelected.push("sfo");
        }
        this.overViewFiltersSelected = overViewFiltersSelected;
        const listFiltersSelectedLeftColumn = [];
        if (this.showLejrSkole) {
          listFiltersSelectedLeftColumn.push("lejrskole");
        }
        if (this.showKlassefravaer) {
          listFiltersSelectedLeftColumn.push("klassefravaer");
        }
        if (this.showKursus) {
          listFiltersSelectedLeftColumn.push("kursus");
        }
        this.listFiltersSelectedLeftColumn = listFiltersSelectedLeftColumn;
        const listFiltersSelectedRightColumn = [];
        if (this.showFravaerende) {
          listFiltersSelectedRightColumn.push("fravaerende");
        }
        if (this.showOtherActivities) {
          listFiltersSelectedRightColumn.push("andreopgaver");
        }
        if (this.showOtherLessons) {
          listFiltersSelectedRightColumn.push("andenundervisning");
        }
        this.listFiltersSelectedRightColumn = listFiltersSelectedRightColumn;
        this.loadAbsenceReasons();
      }));
    },
    loadTimeline() {
      if (!this.selectedSchool) {
        this.selectedPerson = null;
        this.error = null;
        this.isLoading = false;
        this.busy = false;
        return;
      }
      const date = dates.parsePoint(this.inputSelectedDate, () => this.moment);
      if (!date) {
        return;
      }
      this.actions.loadTimeline(this.selectedSchool, this.selectedDepartment, date, this.filterSfoActivities).then(() => {
        const personnel = this.personnel;
        this.selectedPerson = this.personnel.length ? this.selectedPerson && personnel.some(x => x.id == this.selectedPerson.id) ? this.selectedPerson : personnel[0] : null;
        this.error = null;
        this.isLoading = false;
        this.busy = false;
      }).catch(err => {
        this.handleErrorResponse(err);
      });
    },
    refresh() {
      this.store.clear();
      this.loadAbsenceReasons();
    },
    resizeEventHandler() {
      this.absenceOverviewWidth = this.$refs.absenceOverviewRef.clientWidth;
      this.tableWrapperStyleObject.maxWidth = this.isNarrow ? "" : this.absenceOverviewWidth - 50 + "px";
      this.isMobileView = window.innerWidth <= this.mobileThreshold || false;
    },
    sortBy(key) {
      if (key !== this.currentSort) {
        this.currentSortDir === "asc";
      } else {
        this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
      }
      this.currentSort = key;
    }
  }
};

</script>
<style scoped>
#calendarInput {
  border-color: rgb(217, 227, 233);
}

.absence-overview {
  margin-bottom: 50px;
  --period-column-width: 60px;
  --period-column-narrow-width: 60px;
}

.absence-overview .absence-overview-loading,
.absence-overview .message-container {
  padding-left: 15px;
}

.absence-overview .header-table,
.absence-overview .overview-table {
  table-layout: fixed;
  width: auto;
}

.absence-overview .absence-controls .date-picker-input {
  cursor: pointer;
}

.absence-overview /deep/ .absence-controls .fa-calendar-alt {
  float: right;
  margin-right: 36px;
  margin-top: -34px;
  position: relative;
  z-index: 2;
  cursor: pointer;
}

.absence-overview /deep/ .absence-controls select {
  margin: 0;
  border: 1px solid #d9e3e9;
  min-height: 50px;
}

.absence-overview .updateButton {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: transparent;
  color: #549ec7;
  border: 1px solid #549ec7;
  padding-top: 13px;
  padding-bottom: 13px;
  margin-top: 22px;
}

.absence-overview .updateButton:active,
.absence-overview .updateButton:focus,
.absence-overview .updateButton:hover {
  background-color: #549ec7;
  color: #ffffff;
}

.absence-overview .absence-filters {
  margin-bottom: 0;
}

.absence-overview .absence-filters label {
  text-transform: capitalize;
  font-weight: normal;
}

.absence-overview /deep/ .overview-filters div[role="group"],
.absence-overview /deep/ .absence-filters div[role="group"] {
  box-shadow: none !important;
}

.absence-overview-width-input,
.absence-overview-timeaxis-input {
  max-width: 150px;
}

.absence-overview
/deep/
.custom-control-input:checked
~ .custom-control-label::before {
  border-color: #549ec7;
  background-color: #549ec7;
}

/* Large devices (desktops, 992px and up) */

@media (max-width: 991.98px) {
  .absence-overview /deep/ .switch {
    height: 30px;
    width: 60px;
  }

  .absence-overview /deep/ .slider:before {
    height: 26px;
    width: 26px;
    bottom: 2.5px;
  }

  .absence-overview /deep/ input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
}

/* Large devices (desktops, 992px and up) */

@media (min-width: 992px) {
  .absence-overview /deep/ .switch {
    height: 20px;
    width: 30px;
  }

  .absence-overview /deep/ .slider:before {
    height: 14px;
    width: 14px;
    bottom: 3px;
  }

  .absence-overview /deep/ input:checked + .slider:before {
    -webkit-transform: translateX(8px);
    -ms-transform: translateX(8px);
    transform: translateX(8px);
  }
}

.absence-overview .table-striped tbody tr:nth-of-type(even),
.absence-overview .table-striped tbody tr:nth-of-type(even):hover {
  background-color: #ffffff;
}

.absence-overview .header-table {
  margin-bottom: 0;
}

.absence-overview .header-table.tableBusy,
.absence-overview .overview-table.tableBusy,
.absence-overview .list-table.tableBusy {
  opacity: 0.1;
}

.absence-overview .header-table th,
.absence-overview .overview-table td {
  text-align: center;
  vertical-align: middle;
}

.absence-overview .loadingOverlay {
  color: rgb(84, 158, 199);
  position: absolute;
  justify-content: center;
  text-align: center;
  width: 100%;
  z-index: 99999999;
}

.absence-overview .header-table th div {
  white-space: initial;
}

.overview-table.narrowColumn,
.header-table.narrowColumn {
  width: 100%;
}

.absence-overview .overview-table td {
  padding: 10px;
  line-height: 8px;
}

.absence-overview .overview-table td p {
  line-height: 16px;
}

.absence-overview .header-table th.activityColumn,
.absence-overview .overview-table td.activityColumn {
  padding: 2px;
  overflow-wrap: break-word;
}

.absence-overview .overview-table td.activityColumn {
  position: initial;
}

.absence-overview .header-table th.activityColumn.narrowColumn,
.absence-overview .overview-table td.activityColumn.narrowColumn {
  min-width: auto;
  width: auto;
}

.absence-overview .header-table th.periodColumn,
.absence-overview .overview-table th.periodColumn {
  min-width: var(--period-column-width);
  padding: 5px;
  vertical-align: middle;
  white-space: normal;
  width: var(--period-column-width);
}

.absence-overview .header-table th.periodColumn.narrowColumn,
.absence-overview .overview-table th.periodColumn.narrowColumn {
  min-width: var(--period-column-narrow-width);
  width: var(--period-column-narrow-width);
}

.absence-overview .list-table {
  width: 99%;
}

.absence-overview .list-table thead,
.absence-overview .list-table thead th:hover,
.absence-overview .list-table thead th .fa-sort-down:hover {
  cursor: pointer;
}

.absence-overview .list-table td:first-child {
  font-weight: normal;
}

.absence-overview .overview-table tbody tr td,
.absence-overview .list-table tbody tr td {
  cursor: default;
}

.absence-overview .overview-table tbody tr:hover,
.absence-overview .list-table tbody tr:hover {
  color: initial;
  background-color: initial;
}

.absence-overview .overview-table tbody tr:nth-of-type(odd):hover,
.absence-overview .list-table tbody tr:nth-of-type(odd):hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.absence-overview .overview-table tbody tr:hover td,
.absence-overview .list-table tbody tr:hover td {
  border-right-color: #f6f7f8 !important;
}

.absence-overview .list-table .fa-sort-down {
  position: absolute;
  margin-left: 3px;
  transition: all 0.5s ease;
}

.absence-overview .list-table .fa-sort-down.rotated {
  margin-top: 3px;
  transform: rotate(-180deg);
}

.absence-activity {
  background-color: #af0044;
  position: absolute;
  color: #ffffff;
  width: 164px;
  border-radius: 2px;
  border: 1px solid #ffffff;
}

.absence-activity.clickable {
  cursor: pointer;
}

.absence-activity.absent-and-substitute {
  background-color: #43b8c2;
}

.absence-activity.substitute {
  background-color: #ff7f25;
}

.absence-overview /deep/ .absence-activity .fade-enter-active,
.absence-overview /deep/ .absence-activity .fade-leave-active {
  transition: opacity 0.5s;
}

.absence-overview /deep/ .absence-activity .fade-enter,
.absence-overview /deep/ .absence-activity .fade-leave-to {
  opacity: 0;
}

.absence-activity > .activity-container {
  display: grid;
  grid: [top] 50% [middle] 50% [bottom]
    / [left] 50% [middle] 50% [right];
  height: 100%;
  line-height: initial;
  min-width: 0;
  min-height: 0;
  padding: 0px;
  place-content: stretch;
  text-align: initial;
}

.absence-activity > .activity-container > * {
  min-width: 0;
  min-height: 0;
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 5px;
}

.absence-activity > .activity-container > .activity-classes {
  align-self: end;
  grid-area: top / left / bottom / middle;
  justify-self: left;
}

.absence-activity > .activity-container > .activity-classes.without-rooms {
  grid-column-end: right;
}

.absence-activity > .activity-container > .activity-description {
  align-self: center;
  grid-area: top / left / bottom / right;
  justify-self: center;
}

.absence-activity > .activity-container > .activity-description.overlaps-note {
  padding-right: 24px;
}

.absence-activity > .activity-container > .activity-description.with-period {
  align-self: end;
  grid-row-end: middle;
  padding-bottom: 2px;
}

.absence-activity > .activity-container > .activity-note {
  align-self: start;
  grid-area: top / left / bottom/ right;
  justify-self: right;
}

.absence-activity > .activity-container > .activity-period {
  align-self: start;
  grid-area: middle / left / bottom / right;
  justify-self: center;
  padding-top: 2px;
}

.absence-activity > .activity-container > .activity-rooms {
  align-self: end;
  grid-area: top / middle / bottom / right;
  justify-self: right;
}

.absence-activity > .activity-container > .activity-rooms.without-classes {
  grid-column-start: left;
}

.absence-activity > .activity-container > .activity-substitution {
  align-self: start;
  grid-area: top / left / bottom / right;
  justify-self: left;
}

.absence-activity > .activity-container > .activity-substitution.overlaps-note {
  padding-right: 24px;
}

.absence-overview .absence-list-header {
  margin-top: 40px;
}

.absence-overview .absence-list {
  margin-top: 10px;
  margin-bottom: 8px;
}

.absence-overview /deep/ .list-enter-active,
.absence-overview /deep/ .list-leave-active {
  transition: all 0.3s;
}

.absence-overview /deep/ .list-enter,
.absence-overview /deep/ .list-leave-to {
  opacity: 0;
}

.absence-overview .absense-list-filter {
  display: flex;
  align-items: center;
  margin-top: 4px;
  margin-bottom: 4px;
}

.absence-overview /deep/ .modal-footer {
  border: none;
}

.absence-overview /deep/ .modal-header,
.absence-overview /deep/ .modal-footer button {
  background: #45b7c1;
  border: none;
  color: #ffffff;
}

.absence-overview .list-table-wrapper {
  height: auto;
}

.absence-overview /deep/ .overview-filters.form-group {
  margin-bottom: 0 !important;
}

.absence-overview .table-wrapper {
  max-height: 500px;
}

.absence-overview .table-wrapper.narrow-table-overview,
.absence-overview .table-wrapper.narrow-table-list {
  width: 297px !important;
  padding: 0px;
}

.error-message {
  color: #b50050;
  display: block;
}

.b-table tbody tr td:after {
  width: 0;
  height: 0;
  right: 0;
  bottom: 0;
}

.overview-table {
  position: relative;
  border-collapse: collapse;
}

.sticky-col thead th:first-child {
  position: -webkit-sticky !important;
  position: sticky !important;
  left: 0 !important;
  z-index: 1 !important;
}

.sticky-col tbody th {
  position: -webkit-sticky;
  /* for Safari */
  position: sticky;
  left: 0;
  text-align: center;
  z-index: 1;
}

.table-header {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 10;
}

.overview-table td,
.overview-table th {
  border-top: none;
}

.overview-table tr {
  border-top: 1px solid #dee2e6;
}

.list-table thead th {
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 0;
  z-index: 5;
  border-top: none;
  border-bottom: none;
}

.list-table thead th::before {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  height: 1px;
  background-color: #dee2e6;
  z-index: 5;
}

.list-table thead th::after {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 1px;
  background-color: #dee2e6;
  z-index: 5;
}
</style>
