<template>
  <div class="calendar-event-invitees">
    <label>{{ 'CALENDAR_LABEL_EVENT_INVITEES' | fromTextKey }} ({{ invitees.length }})</label>
    <CalendarEventResponseTypeInvitee
      :invitees="acceptedInvitees"
      :show-participant-information="showParticipantInformation"
    >
      {{ 'CALENDAR_LABEL_EVENT_INVITED_ACCEPTED' | fromTextKey }}
      <template v-if="showParticipantInformation">
        {{ acceptedParticipantInformation }}
      </template>
      <template v-else>({{ acceptedInvitees.length }})</template>
    </CalendarEventResponseTypeInvitee>
    <CalendarEventResponseTypeInvitee :invitees="declinedInvitees">
      {{ 'CALENDAR_LABEL_EVENT_INVITED_DECLINED' | fromTextKey }} ({{ declinedInvitees.length }})
    </CalendarEventResponseTypeInvitee>
    <CalendarEventResponseTypeInvitee :invitees="tentativeInvitees">
      {{ 'CALENDAR_LABEL_EVENT_INVITED_TENTATIVE' | fromTextKey }} ({{ tentativeInvitees.length }})
    </CalendarEventResponseTypeInvitee>
    <CalendarEventResponseTypeInvitee :invitees="waitingInvitees" :show-send-reminder="showSendReminder">
      {{ 'CALENDAR_LABEL_EVENT_INVITED_WAITING' | fromTextKey }} ({{ waitingInvitees.length }})
    </CalendarEventResponseTypeInvitee>
  </div>
</template>
<script>
import { eventResponseStatus } from '../../enums/eventResponseStatus';
import CalendarEventResponseTypeInvitee from '../../../src/components/calendar/CalendarEventResponseTypeInvitee';

export default {
  components: { CalendarEventResponseTypeInvitee },
  props: {
    invitees: { type: Array, default: () => [] },
    showParticipantInformation: { type: Boolean, default: false },
    showSendReminder: { type: Boolean, default: false },
  },
  computed: {
    acceptedInvitees() {
      return this.invitees.filter(invitee => invitee.response === eventResponseStatus.ACCEPTED);
    },
    declinedInvitees() {
      return this.invitees.filter(invitee => invitee.response === eventResponseStatus.DECLINED);
    },
    tentativeInvitees() {
      return this.invitees.filter(invitee => invitee.response === eventResponseStatus.TENTATIVE);
    },
    waitingInvitees() {
      return this.invitees.filter(invitee => invitee.response === eventResponseStatus.WAITING);
    },
    numberOfAdultParticipants() {
      return this.acceptedInvitees.reduce((sum, invitee) => sum + invitee.numberOfAdultParticipants, 0);
    },
    numberOfChildParticipants() {
      return this.acceptedInvitees.reduce((sum, invitee) => sum + invitee.numberOfChildParticipants, 0);
    },
    acceptedParticipantInformation() {
      const textAnswered = this.$options.filters.fromTextKey('CALENDAR_LABEL_EVENT_ANSWERS');
      const textAnd = this.$options.filters.fromTextKey('AND');
      const textAdult =
        this.numberOfAdultParticipants === 1
          ? this.$options.filters.fromTextKey('ADULT')
          : this.$options.filters.fromTextKey('ADULTS');
      const textChild =
        this.numberOfChildParticipants === 1
          ? this.$options.filters.fromTextKey('SMALL_CHILD')
          : this.$options.filters.fromTextKey('SMALL_CHILDREN');
      return `(${this.acceptedInvitees.length} ${textAnswered}: ${this.numberOfAdultParticipants} ${textAdult} ${textAnd} ${this.numberOfChildParticipants} ${textChild})`;
    },
  },
};
</script>
<style scoped lang="scss"></style>
