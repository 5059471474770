import Vue from 'vue';
import { parentTypes } from '../../../shared/enums/parentTypes';
import { permissionEnum } from '../../../shared/enums/permissionEnum';
import { Validator } from './validator';
import { addToDate, isBefore } from '../../../shared/utils/dateUtil';
import { NewPostValidatorModel } from '../../../shared/models/validators/newPostValidator.model';

export class NewPostValidator extends Validator {
  public static readonly MAX_MESSAGE_WITHOUT_HTML = 6600;

  private _showMandatoryTitleError: boolean = false;
  private _showMandatoryReceiverError: boolean = false;
  private _showPermissionsError: boolean = false;
  private _showMandatoryContentError: boolean = false;
  private _showContentTooLongError: boolean = false;
  private _isExpiredBeforePublish: boolean = false;

  public get showMandatoryTitleError(): boolean {
    return this._showMandatoryTitleError;
  }

  public get showMandatoryReceiverError(): boolean {
    return this._showMandatoryReceiverError;
  }

  public get showPermissionsError(): boolean {
    return this._showPermissionsError;
  }

  public get showContentTooLongError(): boolean {
    return this._showContentTooLongError;
  }

  public get showMandatoryContentError(): boolean {
    return this._showMandatoryContentError;
  }

  public get showDateNotValidError(): boolean {
    return this._isExpiredBeforePublish;
  }

  public set showDateNotValidError(value: boolean) {
    this._isExpiredBeforePublish = value;
  }

  /**
   * validates model for NewPost
   * @param  {NewPostValidatorModel} model
   * @returns {boolean} true - if model is valid
   */
  public validate(model: NewPostValidatorModel): boolean {
    return this.validateRules(
      [
        this.validateMandatoryTitle,
        this.validateMandatoryReceiver,
        this.validatePermissions,
        this.validateContent,
        this.expiredBeforePublish,
      ],
      model
    );
  }

  /**
   * validates newPost content
   * @param {string} content
   * @returns {boolean} - TRUE - if content isValid
   */
  public validatePostContent(content: string): boolean {
    const textWithoutHtml = Vue.filter('textWithoutHtml')(content);
    return this.validateRules([this.validateContent], { content: textWithoutHtml });
  }

  private validateMandatoryTitle = (model: NewPostValidatorModel): boolean => {
    this._showMandatoryTitleError = Validator.isEmpty(model.title);
    return !this._showMandatoryTitleError;
  };

  private validateMandatoryReceiver = (model: NewPostValidatorModel): boolean => {
    if (model.parent === parentTypes.PROFILE) {
      this._showMandatoryReceiverError = Validator.isEmpty(model.sharedWithGroups);
      return !this._showMandatoryReceiverError;
    }
    return true;
  };

  private validatePermissions = (): boolean => {
    this._showPermissionsError = !Validator.hasAnyPermission([
      permissionEnum.WRITE_POST,
      permissionEnum.USE_GROUPS_AS_DISTRIBUTION_LISTS,
    ]);
    return !this._showPermissionsError;
  };

  private validateMandatoryContent = (model: NewPostValidatorModel): boolean => {
    this._showMandatoryContentError = Validator.isEmpty(model.content);
    return !this._showMandatoryContentError;
  };

  private validateContentTooLong = (model: NewPostValidatorModel): boolean => {
    this._showContentTooLongError = Validator.exceedsMaxLength(
      model.content,
      NewPostValidator.MAX_MESSAGE_WITHOUT_HTML
    );
    return !this._showContentTooLongError;
  };

  private validateContent = (model: NewPostValidatorModel): boolean =>
    this.validateMandatoryContent(model) && this.validateContentTooLong(model);

  private expiredBeforePublish = (model: NewPostValidatorModel): boolean => {
    const publishAtPlus15Minutes = addToDate(model.publishAt, 15, 'minutes');

    this._isExpiredBeforePublish = isBefore(model.expireAt, publishAtPlus15Minutes);
    return !this._isExpiredBeforePublish;
  };
}
