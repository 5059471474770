import { VacationIntervalModel } from '../../shared/models/vacationInterval.model';
import { NewVacationIntervalValidator } from './validators/newVacationIntervalValidator';
import { GeneralOpeningHoursModel } from '../../shared/models/generalOpeningHours.model';
import { SpecificOpeningHoursModel } from '../../shared/models/specificOpeningHours.model';
import { VacationRegistrationModel } from '../../shared/models/vacationRegistration.model';
import { presenceOpenHours } from '../../shared/enums/presenceOpenHours';
import moment from 'moment-timezone';
import { DayTemplateModel } from '../../shared/models/dayTemplate.model';
import { loginProfile } from './loginProfile';

export class NewVacationInterval implements VacationIntervalModel {
  date: string;
  isComing: boolean;
  entryTime: string;
  exitTime: string;
  useTimes: boolean;
  year: number;
  week: number;

  constructor(
    model: VacationIntervalModel,
    vacationRegistration: VacationRegistrationModel,
    specificOpeningHours?: SpecificOpeningHoursModel,
    generalOpeningHours?: GeneralOpeningHoursModel,
    dayTemplate?: DayTemplateModel
  ) {
    this.date = model.date;
    this.isComing = model.isComing;
    this.entryTime = model.entryTime;
    this.exitTime = model.exitTime;
    this.useTimes = model.useTimes;
    this.year = model.year;
    this.week = model.week;
    this._vacationRegistration = vacationRegistration;
    this._specificOpeningHours = specificOpeningHours;
    this._generalOpeningHours = generalOpeningHours;
    this._dayTemplate = dayTemplate;
    this._validator = new NewVacationIntervalValidator(this, specificOpeningHours, generalOpeningHours);
    this.updateDisabled();
    this.setupEntryTime();
    this.setupExitTime();
  }

  private _disabled: boolean;
  private _submitClicked = false;
  private _validator: NewVacationIntervalValidator;
  private _vacationRegistration: VacationRegistrationModel;
  private _specificOpeningHours: SpecificOpeningHoursModel;
  private _generalOpeningHours: GeneralOpeningHoursModel;
  private _dayTemplate: DayTemplateModel;

  get validator() {
    return this._validator;
  }

  get isDisabled() {
    return this._disabled;
  }

  get specificOpeningHour() {
    return this._specificOpeningHours;
  }

  setSubmitClicked(submitClicked: boolean) {
    this._submitClicked = submitClicked;
  }

  isValid() {
    return this._validator.validate();
  }

  setEntryTime(entryTime: string) {
    this.entryTime = entryTime;
    this._validator.validate();
  }

  setExitTime(exitTime: string) {
    this.exitTime = exitTime;
    this._validator.validate();
  }

  setIsComing(isComing: boolean | null) {
    this.isComing = isComing;
  }

  getStartTime() {
    let openTime = '05:00';
    if (this._specificOpeningHours?.openTime) {
      openTime = this._specificOpeningHours.openTime;
    } else if (this._generalOpeningHours?.openTime) {
      openTime = this._generalOpeningHours.openTime;
    }
    return openTime;
  }

  getEndTime() {
    let closeTime = '23:59';
    if (this._specificOpeningHours?.closeTime) {
      closeTime = this._specificOpeningHours.closeTime;
    } else if (this._generalOpeningHours?.closeTime) {
      closeTime = this._generalOpeningHours.closeTime;
    }
    return closeTime;
  }

  getIsSpecificOpeningHours() {
    return this._specificOpeningHours?.type === presenceOpenHours.SPECIFIC_OPEN_HOURS;
  }

  getIsClosingDay() {
    return this._specificOpeningHours?.type === presenceOpenHours.CLOSE_OPEN_HOURS;
  }

  getShowHasTimesError(): boolean {
    return this._submitClicked && !this._validator.hasTimes;
  }

  getShowIsWithinOpeningHourError(): boolean {
    return this._validator.hasTimes && !this._validator.isWithinOpeningHour;
  }

  getShowIsValidTimesError(): boolean {
    return this._validator.hasTimes && !this._validator.isValidTimes;
  }

  private updateDisabled() {
    const isClosingDay = this.getIsClosingDay();
    const nowDate = moment();
    const isBeforeNow = nowDate.isAfter(this.date);

    this._disabled = isClosingDay || isBeforeNow || this.cannotRespondDueToDeadline;
  }

  private get cannotRespondDueToDeadline() {
    const nowDate = moment();
    let isPastDeadline = false;
    if (this._vacationRegistration?.responseDeadline) {
      isPastDeadline = nowDate.isAfter(this._vacationRegistration.responseDeadline, 'day');
    }
    return !loginProfile.isEmployee && isPastDeadline;
  }

  private setupEntryTime() {
    let entryTime = this.entryTime;
    if (this._dayTemplate) {
      entryTime = this._dayTemplate.entryTime;
    }
    this.entryTime = entryTime || '';
  }

  private setupExitTime() {
    let exitTime = this.exitTime;
    if (this._dayTemplate) {
      exitTime = this._dayTemplate.exitTime;
    }
    this.exitTime = exitTime || '';
  }
}
