<template>
  <AulaButtons class="my-1 justify-content-around">
    <template v-if="showRepeatingEventOptions">
      <CalendarEventFooterDropdown class="text-center" :dropup="dropUp">
        <template #button-content>
          <i class="icon-Aula_edit_pen m-0" />
          {{ 'CALENDAR_EDIT_EVENT_BUTTON' | fromTextKey }}
        </template>
        <b-dropdown-item-btn v-if="showRepeatingOccurrenceOption" @click="onEditCurrentEvent">
          {{ 'CALENDAR_EDIT_REPEATING_OCCURRENCE' | fromTextKey }}
        </b-dropdown-item-btn>
        <b-dropdown-item-btn @click="onEditRepeatingSeries">
          {{ 'CALENDAR_EDIT_REPEATING_SERIES' | fromTextKey }}
        </b-dropdown-item-btn>
      </CalendarEventFooterDropdown>
      <CalendarEventFooterDropdown class="text-center" :right="isMobile" :dropup="dropUp">
        <template #button-content>
          <i class="icon-Aula_bin" />
          {{ 'DELETE' | fromTextKey }}
        </template>
        <b-dropdown-item-btn v-if="showRepeatingOccurrenceOption" @click="onDeleteCurrentEvent">
          {{ 'CALENDAR_EDIT_REPEATING_OCCURRENCE' | fromTextKey }}
        </b-dropdown-item-btn>
        <b-dropdown-item-btn @click="onDeleteRepeatingSeries">
          {{ 'CALENDAR_EDIT_REPEATING_SERIES' | fromTextKey }}
        </b-dropdown-item-btn>
      </CalendarEventFooterDropdown>
    </template>
    <template v-else>
      <AulaButton v-if="showEdit" variant="link" class="text-center" @click="onEditCurrentEvent">
        <span class="button-text">
          <i class="icon-Aula_edit_pen m-0" />
          {{ 'CALENDAR_EDIT_EVENT_BUTTON' | fromTextKey }}
        </span>
      </AulaButton>
      <AulaButton v-if="showDelete" variant="link" class="text-center" @click="onDeleteCurrentEvent">
        <span class="button-text">
          <i class="icon-Aula_bin" />
          {{ 'DELETE' | fromTextKey }}
        </span>
      </AulaButton>
    </template>
  </AulaButtons>
</template>
<script>
import { eventResponseStatus } from '../../enums/eventResponseStatus';
import CalendarEventFooterDropdown from './CalendarEventFooterDropdown';
import AulaButtons from '../../components/AulaButtons';
import AulaButton from '../../components/AulaButton';
import { mapGetters } from 'vuex';
import { types } from '../../../src/store/types/types';

export default {
  components: {
    AulaButton,
    AulaButtons,
    CalendarEventFooterDropdown,
  },
  props: {
    showEdit: { type: Boolean, default: false },
    showDelete: { type: Boolean, default: false },
    showRepeatingEventOptions: { type: Boolean, default: false },
    showRepeatingOccurrenceOption: { type: Boolean, default: true },
    dropUp: { type: Boolean, default: false },
  },
  computed: {
    ...mapGetters({
      isMobile: types.GET_IS_MOBILE,
    }),
  },
  data() {
    return {
      eventResponseStatus,
    };
  },
  methods: {
    onEditCurrentEvent() {
      this.$emit('onEditCurrentEvent');
    },
    onEditRepeatingSeries() {
      this.$emit('onEditRepeatingSeries');
    },
    onDeleteCurrentEvent() {
      this.$emit('onDeleteCurrentEvent');
    },
    onDeleteRepeatingSeries() {
      this.$emit('onDeleteRepeatingSeries');
    },
  },
};
</script>
