<script>

import { interpolate } from '../util'

const GroupMessage = {
    data() {
        return {
            isHoveringMessagePendingTooltip: false,
            isHoveringMessageSuccessfullySentTooltip: false,
            isHoveringMessageUnsuccessfullySentTooltip: false,
        }
    },
    props: {
        message: Object,
        translations: Object,
    },
    methods: {
        interpolate: interpolate,
        openMessagePendingTooltip() {
            this.isHoveringMessagePendingTooltip = true
        },
        closeMessagePendingTooltip() {
            this.isHoveringMessagePendingTooltip = false
        },
        openMessageSuccessfullySentTooltip() {
            this.isHoveringMessageSuccessfullySentTooltip = true
        },
        closeMessageSuccessfullySentTooltip() {
            this.isHoveringMessageSuccessfullySentTooltip = false
        },
        openMessageUnsuccessfullySentTooltip() {
            this.isHoveringMessageUnsuccessfullySentTooltip = true
        },
        closeMessageUnsuccessfullySentTooltip() {
            this.isHoveringMessageUnsuccessfullySentTooltip = false
        },
    },
}

export default GroupMessage

</script>

<template>
    <div class="message-container">
        <div class='message-container-header' @click="$emit('handle-open-single-message', message)">
            <p class="message-time text-center">
                {{ message.created_at.format('Do MMMM YYYY, HH:mm') }}
            </p>
            <div class="message-delivery-status-container">
                <div class="message-delivery-status-item" @mouseleave="closeMessagePendingTooltip">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" class="message-delivery-status-icon fill-grey"
                         @mouseenter="openMessagePendingTooltip">
                        <path
                            d="M24 7.273v-5.94h2.667v-1.333h-21.333v1.333h2.667v5.94c0 0.001 0 0.002 0 0.003 0 2.429 1.181 4.582 3 5.916l0.020 0.014 3.847 2.793-3.847 2.8c-1.838 1.348-3.019 3.499-3.020 5.926v5.94h-2.667v1.333h21.333v-1.333h-2.667v-5.94c0-0.001 0-0.002 0-0.003 0-2.429-1.181-4.582-3-5.916l-0.020-0.014-3.847-2.793 3.847-2.8c1.838-1.348 3.019-3.499 3.020-5.926v-0zM9.333 30.667v-3.447l6.667-1.22 6.667 1.213v3.453h-13.333zM20.193 19.873c1.506 1.103 2.473 2.865 2.473 4.853v1.14l-6.667-1.2-6.667 1.2v-1.14c0-1.988 0.967-3.75 2.457-4.842l0.017-0.012 4.193-3.047zM20.193 12.12l-4.193 3.053-4.193-3.053c-1.504-1.102-2.471-2.861-2.473-4.846v-5.94h13.333v5.94c-0 1.988-0.967 3.75-2.457 4.842l-0.017 0.012z" />
                    </svg>
                    <p class="message-delivery-status-number number-text">{{ message.delivery_status_counts.pending }}</p>
                    <div v-if="isHoveringMessagePendingTooltip" class="tooltip-container tooltip-bottom message-delivery-status-tooltip">
                        {{ interpolate(translations.groupMessagesScreen.pendingTooltip, { count: message.delivery_status_counts.pending }) }}
                    </div>
                </div>
                <div class="message-delivery-status-item" @mouseleave="closeMessageSuccessfullySentTooltip">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" class="message-delivery-status-icon fill-green"
                         @mouseenter="openMessageSuccessfullySentTooltip">
                        <path
                            d="M16 0c-8.837 0-16 7.163-16 16s7.163 16 16 16c8.837 0 16-7.163 16-16v0c0-8.837-7.163-16-16-16v0zM16 30.667c-8.1 0-14.667-6.567-14.667-14.667s6.566-14.667 14.667-14.667c8.1 0 14.667 6.566 14.667 14.667v0c0 8.1-6.566 14.667-14.667 14.667v0zM15.453 20.72l-5.567-5.567-0.94 0.94 6.793 6.793 7.587-13.133-1.153-0.667-6.72 11.633z" />
                    </svg>
                    <p class="message-delivery-status-number number-text">{{ message.delivery_status_counts.received }}</p>
                    <div v-if="isHoveringMessageSuccessfullySentTooltip" class="tooltip-container tooltip-bottom message-delivery-status-tooltip">
                        {{ interpolate(translations.groupMessagesScreen.successTooltip, { count: message.delivery_status_counts.received }) }}
                    </div>
                </div>
                <div class="message-delivery-status-item" @mouseleave="closeMessageUnsuccessfullySentTooltip" v-if='message.delivery_status_counts.failed > 0'>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" class="message-delivery-status-icon fill-red"
                         @mouseenter="openMessageUnsuccessfullySentTooltip">
                        <path
                            d="M16 0c-8.837 0-16 7.163-16 16s7.163 16 16 16c8.837 0 16-7.163 16-16v0c0-8.837-7.163-16-16-16v0zM16 30.667c-8.1 0-14.667-6.567-14.667-14.667s6.566-14.667 14.667-14.667c8.1 0 14.667 6.566 14.667 14.667v0c0 8.1-6.566 14.667-14.667 14.667v0zM21.527 9.527l-5.527 5.533-5.527-5.533-0.947 0.947 5.533 5.527-5.533 5.527 0.947 0.947 5.527-5.533 5.527 5.533 0.947-0.947-5.533-5.527 5.533-5.527-0.947-0.947z" />
                    </svg>
                    <p class="message-delivery-status-number number-text">{{ message.delivery_status_counts.failed }}</p>
                    <div v-if="isHoveringMessageUnsuccessfullySentTooltip" class="tooltip-container tooltip-bottom message-delivery-status-tooltip">
                        {{ interpolate(translations.groupMessagesScreen.failedTooltip, { count: message.delivery_status_counts.failed }) }}
                    </div>
                </div>
            </div>
        </div>
        <div class="message-bubble">
            <pre class="message-text">{{ message.text }}</pre>
        </div>
    </div>
</template>