<script>

import Recipient from './recipient'
import { interpolate } from '../util'

const RecipientType = {
    props: {
        role: String,
        header: String,
        recipients: Array,
        keyedClasses: Object,
        isSelectable: { type: Boolean, default: true },
        giveConsent: Function,
        selectedUsers: Object,
        isConsentIconVisible: { type: Boolean, default: true },
        isMessageStatusIconVisible: { type: Boolean, default: false },
        isMissingPhoneIconVisible: { type: Boolean, default: true },
        recipientStatuses: Object,
        translations: Object,
    },
    data() {
        return {
            openToggle: null,
            pages: 1,
            pageSize: 25,
        }
    },
    computed: {
        isOpen() {
            if (this.openToggle === null && this.recipients.length <= 5) {
                return true
            }

            return !!this.openToggle
        },
        paginated() {
            return this.recipients.slice(
                0,
                this.pages * this.pageSize,
            )
        },
        numberOfItemsOnNextPage() {
            const itemsShown = this.pages * this.pageSize
            return itemsShown > this.recipients.length
                ? 0
                : Math.min(this.pageSize, this.recipients.length - itemsShown)
        },
    },
    methods: {
        interpolate,
        toggleOpen() {
            this.openToggle = !this.openToggle
        },
        showMore() {
            this.pages = this.pages + 1
        },
    },
    components: {
        'recipient': Recipient,
    },
}

export default RecipientType

</script>

<template>
    <div class="recipient-type" v-if='recipients.length > 0'>
        <div class="recipient-type-header" @click="toggleOpen()">
            <svg v-if="!isOpen" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 32 32" class="recipient-type-icon">
                <path d="M5.333 12.273l0.94-0.94 9.727 8.78 9.727-8.78 0.94 0.94-10.667 9.727-10.667-9.727z" />
            </svg>
            <svg v-if='isOpen' xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 32 32" class="recipient-type-icon">
                <path d="M16 10.667l-10.667 9.727 0.94 0.94 9.727-8.78 9.727 8.78 0.94-0.94-10.667-9.727z" />
            </svg>
            <p :class="[isOpen && 'bold','recipient-type-heading']">
                {{ header }}
            </p>
            <div class="recipient-type-count number-text">
                {{ recipients.length }}
            </div>
        </div>
        <div class="recipient-type-body" v-if="isOpen">
            <div class="contact-list">
                <recipient
                    v-for="recipient in paginated"
                    :key='recipient.id'
                    :role="role"
                    :recipient="recipient"
                    :is-selected='selectedUsers && !!selectedUsers[recipient.id]'
                    :is-selectable='isSelectable'
                    :give-consent='giveConsent'
                    :keyed-classes='keyedClasses'
                    :is-consent-icon-visible='isConsentIconVisible'
                    :is-message-status-icon-visible='isMessageStatusIconVisible'
                    :is-missing-phone-icon-visible='isMissingPhoneIconVisible'
                    :recipient-status='recipientStatuses ? recipientStatuses[recipient.id] : null'
                    :translations='translations'
                    @add-recipient="$emit('add-recipient', recipient)"
                    @remove-recipient="$emit('remove-recipient', recipient)"
                >
                </recipient>
            </div>
            <div class="next-page" v-if='numberOfItemsOnNextPage > 0' @click='showMore()'>
                {{ interpolate(translations.recipientType.showMore, { count: numberOfItemsOnNextPage }) }}
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>