<template>
  <div class="lektieBogen">
    <h1>Lektier uge {{ currentWeekNumber.split('W').slice(-1)[0] }}</h1>
    <div v-if="lektier && lektier.length">
      <p v-for="item of lektier">
        <strong>{{ item.dag }}</strong>
        <br /> Fag: {{ item.fag }}
        <br /> Bog: {{ item.bog }}
        <br /> Side: {{ item.side }}
        <br /> Opgave: {{ item.opgave }}
      </p>
    </div>
    <div v-else>
      <strong>Du har ingen lektier for i denne uge</strong>
    </div>
    <div>
      <p class="d-flex justify-content-between align-items-center">
        Notifikationer
        <b-btn @click="clearAllNotifications">Ryd alle</b-btn>
      </p>
      <template v-if="notifications && notifications.length > 0">
        <div v-for="notification in notifications" class="d-flex justify-content-between align-items-center" :key="notification.widgetNotificationId">
          <p class="mb-0">
            {{notification.notificationMessage}}
          </p>
          <b-btn @click="clearNotification(notification)" :disabled="removeNotificationSet.has(notification)" variant="link">
            X
          </b-btn>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    axios: Function,
    sessionUUID: String,
    placement: String,
    currentWeekNumber: String,
    isMobileApp: Boolean,
    userProfile: String,
    getAulaToken: Function,
    assuranceLevel: Number,
    childFilter: Array,
    institutionFilter: Array,
    group: Number,
    notifications: Array,
    deleteNotifications: Function,
  },
  data: function () {
    return {
      lektier: null,
      removeNotificationSet: new Set(),
    }
  },
  methods: {
    async clearNotification(notification) {
      this.removeNotificationSet.add(notification);
      await this.deleteNotifications([notification.widgetNotificationId]);
      this.removeNotificationSet.delete(notification);
    },
    async clearAllNotifications() {
      this.removeNotificationSet = new Set(this.notifications);
      const notificationIds = this.notifications.map(notification => notification.widgetNotificationId);
      await this.deleteNotifications(notificationIds);
      this.removeNotificationSet.clear();
    },
  },
  mounted() {
    switch(Math.floor(Math.random() * 5)) {
      case 1:
        this.lektier = [
          {
            dag: 'Tirsdag',
            fag: 'Engelsk',
            bog: 'Hello Oxford',
            side: '1',
            opgave: 'read monologue two'
          },
          {
            dag: 'Torsdag',
            fag: 'Matematik',
            bog: 'Matematrix',
            side: '12-13',
            opgave: '23, 24, 25, 26, 30, 31'
          },
        ]
        break;
      case 2:
        this.lektier = [
          {
            dag: 'Mandag',
            fag: 'Dansk',
            bog: 'Skriv Nu!',
            side: '5',
            opgave: 'O, P, Q'
          },
          {
            dag: 'Tirsdag',
            fag: 'Engelsk',
            bog: 'Hello Oxford',
            side: '1',
            opgave: 'read monologue two'
          },
          {
            dag: 'Torsdag',
            fag: 'Matematik',
            bog: 'Matematrix',
            side: '12-13',
            opgave: '23, 24, 25, 26, 30, 31'
          },
        ]
        break;
      case 3:
        this.lektier = [

          {
            dag: 'Fredag',
            fag: 'Fysik',
            bog: 'Fysik trin 2',
            side: '45',
            opgave: 'Syre og Base'
          },
        ]
        break;
      case 4:
        this.lektier = [
          {
            dag: 'Mandag',
            fag: 'Matematik',
            bog: 'Matematrix',
            side: '12-13',
            opgave: '23, 24, 25, 26, 30, 31'
          },
          {
            dag: 'Onsdag',
            fag: 'Dansk',
            bog: 'Skriv Nu!',
            side: '5',
            opgave: 'O, P, Q'
          }
        ]
    }
  }
};
</script>

<style scoped>
</style>
