<template>
<div class='widget-W0037V0001'>
  <div :key="section">
    <section v-if="loading">
      <div class="infoba-header">
        <div style="display:flex">
          <div style="width:24px">
            <i class="fal fa-home"></i>
          </div>
          <span @click="goto(headerLink)" class="header-link">INFOBA</span>
        </div>
        <img :src="domain + '/Images/logowhite.svg'" class="infoba-logo" />
      </div>
      <div class="infoba-quick-access-item " style="text-align:center; padding:20px !important">
        <div class="material-icons icon-rotator infoba-rotate"></div>
      </div>
    </section>
    <section v-else-if="section!='default' ">
      <div class="infoba-header">
        <div style="display:flex">
          <div style="width:24px">
            <i class="fal fa-home"></i>
          </div>
          <div @click="goto(headerLink)" class="header-link">INFOBA</div>
        </div>
        <img :src="domain + '/Images/logowhite.svg'" class="infoba-logo" />
      </div>
      <div class="infoba-quick-access-item">
        <widget-html :html="message"></widget-html>
      </div>
    </section>
    <section v-else>
      <div class="component" v-show="this.item.Binder.Person.ChildInfoSuitcaseList.length != 0">
        <div class="infoba-header" style="background-color: #eb7404;">
          <div style="display:flex">
            <div class="icon-briefcase"></div>
            <div @click="goto(domain + '/FO')" class="header-link">Digital skolekuffert</div>
          </div>
          <img :src="domain + '/Images/logowhite.svg'" class="infoba-logo" />
        </div>
        <div class="infoba-quick-access-item" style="height:auto">

          <div class="infoba-content">
            <div v-for="suitcase in this.item.Binder.Person.ChildInfoSuitcaseList" class="infoba-row" style="align-content:center; margin-top:5px">
              <div style="padding-top:5px;">{{suitcase.ChildName}} </div>
              <div style="display:flex" @click="goto(getSuitcaseLink(suitcase.DocumentId))">
                <div class="icon-download" style="cursor:pointer"></div>

              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="component" v-show="this.item.Binder.Person.ChildInfoPTCMeetingList.length != 0">
        <div class="infoba-header" style="background-color: #96be2c;">
          <div style="display:flex">
            <div class="icon-chat"></div>
            <div @click="goto(domain + '/FO/PTCMeeting/MeetingList')" class="header-link">{{item.Binder.Tile.Header}}</div>
          </div>
          <img :src="domain + '/Images/logowhite.svg'" class="infoba-logo" />
        </div>
        <div class="infoba-quick-access-item" style="height:auto">
          <div class="infoba-content">
            <div v-for="ptcMeeting in this.item.Binder.Person.ChildInfoPTCMeetingList" class="infoba-row">
              <div>
                <div class="infoba-link" @click="goto(getPTCMeetingLink(ptcMeeting.ChildId))">{{ptcMeeting.ChildName}}</div>
                <div> {{ptcMeeting.Type}}</div>
              </div>
              <div class="infoba-gray">
                <div>{{moment(ptcMeeting.Date).format('DD-MM-YYYY')}}</div>
                <div style="float:right"> kl. {{moment(ptcMeeting.Date).format('HH:mm')}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="component" v-show="this.item.Binder.Person.ChildInfoFocusChildList.length != 0">
        <div class="infoba-header" style="background-color: #873c79;">
          <div style="display:flex">
            <div class="icon-eye"></div>
            <div class="header-link" @click="goto(domain + '/FO/Report/IndexFocusChild')">Fokusbarnskema</div>
          </div>
          <img :src="domain + '/Images/logowhite.svg'" class="infoba-logo" />
        </div>
        <div class="infoba-quick-access-item" style="height:auto">

          <div class="infoba-content">
            <div v-for="focusChild in this.item.Binder.Person.ChildInfoFocusChildList" class="infoba-row">
              <div>
                <div class="infoba-link" @click="goto(domain +'/FO/Report/IndexFocusChild?Type=focuschild')">{{focusChild.ChildName}}</div>
                <div v-if="focusChild.IsFirst">L&aelig;s mere om fokusbarnsskemaer <a :href="focusChild.ParentUrl">her</a></div>
                <div v-else>Har v&aelig;ret fokusbarn </div>
              </div>
              <div class="infoba-gray">{{getFocusChildDate(focusChild.Date)}}</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>

</div>
</template>

<script>
var domain = 'https://www2.infoba.dk';
var domainOld = 'https://www.infoba.dk';

export default {
  props: {
    /* normal and secure */
    axios: Function,
    sessionUUID: String,
    placement: String,
    currentWeekNumber: String,
    isMobileApp: Boolean,
    userProfile: String,
    /* only secure */
    moment: Function,
    assuranceLevel: Number,
    getAulaToken: Function,
    childFilter: Array,
    institutionFilter: Array,
    group: String,
  },
  data: function () {
    return {
      loading: true,
      section: "default",
      message: "",
      timer: "",
      headerLink: domain,
      who: "Aula widget developers",
      date: this.moment().format("DD-MM-YYYY"),
      aulaToken: null,
      domain: domain,
      domainOld: domainOld,
      fields: {},
      item: { Binder: {} },
    };
  },
  methods: {
    getData: function (match) {
      var uuid = this.sessionUUID;
      this.axios.defaults.headers.common["Authorization"] = this.aulaToken;
      this.axios.defaults.headers.common["AuthorizationContext"] = "ParentChildrenSummary";

      if (this.childFilter != null)
        this.axios.defaults.headers.common["childFilter"] = this.childFilter.join();
      if (this.institutionFilter != null)
        this.axios.defaults.headers.common["institutionFilter"] = this.institutionFilter.join();
      if (this.group != null)
        this.axios.defaults.headers.common["groupFilter"] = this.group;
      if (this.userProfile != null)
        this.axios.defaults.headers.common["userProfile"] = this.userProfile;
      if (this.currentWeekNumber != null)
        this.axios.defaults.headers.common["currentWeekNumber"] = this.currentWeekNumber;
      if (this.isMobileApp != null)
        this.axios.defaults.headers.common["isMobileApp"] = this.isMobileApp;

      this.axios.get(domain + "/api/Aula/ParentChildrenSummaryGet", {
        withCredentials: true,
        credentials: "same-origin",
      })
        .then((response) => {
          this.moment.locale('da')
          this.item = response.data;

          handleStatus(this);
          if (
            this.item.Binder.Person.ChildInfoSuitcaseList.length == 0 &&
            this.item.Binder.Person.ChildInfoPTCMeetingList.length == 0 &&
            this.item.Binder.Person.ChildInfoFocusChildList.length == 0
          ) {
            this.section = "nodata";
            this.message = "Vi har ikke noget relevant data at vise dig.";
          }
        })
        .catch((error) => {
          handleStatus(this);
        })
        .finally(() => (this.loading = false));
    },
    goto: function (redirectUrl) {
      let serial = this.item.INFOBAserial;
      let token = this.item.INFOBAtoken;
      let url = "";
      //if logged in
      if (serial === null || token === null) {
        let aulaReferrer = '?referrer=Aula';
        if (redirectUrl.indexOf('?') !== -1) {
          aulaReferrer = '&referrer=Aula';
        }
        url = redirectUrl + aulaReferrer;
      }
      //if not logged in
      else {
        url = domain + "/Login/Index?serial=" + serial + "&token=" + token + "&referrer=Aula&returnUrl=" + encodeURIComponent(redirectUrl);
      }
      var a = document.createElement('a');
      a.href = url;
      a.target = "INFOBA";
      a.click();
    },
    getSuitcaseLink(documentId) {
      return domain + "/FO/INFOBACloud/DownloadFile/" + documentId;
    },
    getPTCMeetingLink(childId) {
      return domain + "/FO/PTCMeeting/Index/" + childId + "?Type=PtcMeeting";
    },
    getFocusChildDate(date) {
      if (this.moment(date).isSame(this.moment().startOf('day'), 'd') == true)
        return 'I dag ';
      else
        return this.moment(date).format('DD-MM-YYYY');
    },
  },
  created: function () {
    this.aulaToken = this.getAulaToken();
  },
  watch: {
    aulaToken: function () {
      if (this.aulaToken !== undefined) {
        var t = this;
        t.getData();
      }
    },
  },
};
function handleStatus(t) {
  clearTimeout(t.timer)
  if (t.item && t.item.Status == 200) {
    if (t.item.Binder.Person == undefined) {
      t.section = "error"
      t.message = 'Din bruger har ikke adgang til denne widget i INFOBA. Kontakt <a href="https://web.infoba.dk/support/" class="infoba-link">support</a>.'
    }
    else {
      t.section = "default"
    }
  }
  else if (t.item && t.item.Status == 401) {
    if (t.item.Message == "Invalid role" || t.item.Message == "Module payment missing") {
      t.section = "error"
      t.message = 'Din bruger har ikke adgang til denne widget i INFOBA. Kontakt <a href="https://web.infoba.dk/support/" class="infoba-link">support</a>.'
    }
    else {
      t.section = "login";
      t.message = 'For at se data fra INFOBA, skal dine konti f&oslash;rst sammenk&aeligdes. <div><a href="' + domain + "/IN/Personel/MatchAula/" + t.item.Username + '" class="infoba-link">Start sammenk&aeligdning <i class="material-icons icon-arrowright-circle" style="vertical-align: middle; margin-top: 2px; color:#96be2c"></i></a></div>';
      t.timer = setTimeout(function () { t.getData() }.bind(t), 5000);
      t.headerLink = domain + "/IN/Personel/MatchAula/" + t.item.Username;
    }
  }
  else {
    t.section = "error"
    t.message = 'Undskyld. Det er i &oslash;jeblikket ikke muligt at vise dig den &oslash;nskede information. Vi h&aring;ber, du vil pr&oslash;ve igen senere.'
  }
}


</script>
<style lang="scss">
.widget-W0037V0001 {
.component {
  margin-top: 20px;
}

.component:first-child {
  margin-top: 0px;
}

div.icon-rotator {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QAAAAAAAD5Q7t/AAAACXBIWXMAAA7EAAAOxAGVKw4bAAAD5UlEQVRo3u2ZT4gbdRTHv++XJUzwT6HuoUrB0nhQKR6kerG9eCgL1l3FdT1ZpLB4kL1Edmf29rtlJpsuSPEgQvdiD6ZQdS1ioTf/gFUQ24KSS3qQIloKVXGSyWa+Hjq7JNNkMzOZcT3M9zZv5v3m98n7vfd+8wuQK1euXLlyJZekPaDW+mHDMI6TPCIijwPYB2ALwB2SvwH4rtPp/KC1/vt/B6K1njIMYx7AaQAvAiiMcekC+ALARrvd/lxr7e85iOM48yQdAIeT+JO8JiIrlmVdnmQeU0kd19fX93ued47k3CQTEJEnRaQ0yRhAwohUq9UnROQyRkdhC8BVki0R+V1EPJLTAJ4C8CwAI3jOE5E3TNP89D8HCSC+AnBgyO2fAKwXi8VLlUrlzjB/rfWDhmHMAlgSEScNiNggwXL6HvdH4raIVFzXPZ9G4iZRrBzxPO9cGEJEbiilZpeXl1t7AbAtFfVBx3HmAQwktojccF33hawgHMc5mCqI1noqKLH9uq2UmtVa/5kFRLVanSHZtG37ndRAgmYXXlKVrCJRrVZnROQTACUAZ6PARF1ap/svSF5zXfd8RhAnAojtEi0BzNJEILZt78O9bceORORMFtUpiMRnfRA7rwTw3m6RiRKRYxjcO20Vi8VLGUH0RyKs7cgMhRkLQvJIyHR1VLPLEGIszFgQpdShkOnmHkHsCjMWxPf9h0KmWylCnIgJEYZZ6jcAAGzbXgTwAZJtJD2l1GsrKyup5I5t22cAVPpMH1mW9eZuPjsRsSzrQwBLABjzvW2Sc2lBBHqs/4Lk2KY7sLQsy3o/Jkyb5Kurq6tfpggBAIdC1zdjgcSEyQSiXq9PA3guZL4eGyQiTFaRQK/XexmDfaurlPomEUgfzNtDYDyl1OtZQDQajQIGkxwArpim+VdikAAmXACySOwdtVqtU+EGTHIjiu/YPtK3zNyslhMA1Gq1Msl6yNwsl8sXo/hH7hmO4xw0TfPXLCCCjem3AJ4emJzInGmam6mCZKVarVb2fX9zCMQF0zQXoo6T+FxrUjUajUKr1Trl+34dwP7Q7SbJxTjjpRYRx3FeIWmJyFkAm6MqTb1en+52uyeVUu8O2VkDwK1CoXAs7tdnKiABxMcAioGpLSI/kvxZRP4gWRSRAyQPAziK0WfDzUKhMJPkEzqNs98wRCKJyAWSi5Zl3U3iP3GO+L7/D4BfROSZhEM0RWQ5anUa+UNMCgIAWmtVKpVOknwLwEsYH50ugCskN8rl8sWFhYXepHNIvfyura09QPJor9d7XkQeBfAIyQKAu7i3i73e6XS+TvuPnly5cuXKlWsS/QvhKq1sLYByvgAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAyMC0xMC0wNlQxMTozNjo1NSswMDowMPTXOyYAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMjAtMTAtMDZUMTE6MzY6NTUrMDA6MDCFioOaAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAABJRU5ErkJggg==) no-repeat center center;
  padding: 25px 25px 25px 25px;
  display: inline-block;
}

div.icon-chat {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QAAAAAAAD5Q7t/AAAACXBIWXMAAA7EAAAOxAGVKw4bAAABIUlEQVRIx+2UsUoDQRRFz+QDDKKFgYBgaVrBMgbzDYq16KdY+g8WEsTO3kI7wVIs7DSlmpRpFo5FsjCu62422TKn232z986dfW9gRQmhqKhuAydAF9gFNmelL+AVeAAGIYT3Sq5qSx2oieUks7WtecX76mgO4SwjtV8m3lMnC4inTNTef+JNdbiEeMpQbaa6jcjjDGjX0Dht4DTP4KgG8ZTjvCMaZ7NGtbmeI8Z5CQpnYlFig7ecVJWS5GnFBrc1bvzmzxt1raY2/YjbNGty4PKD1i3Mpp4vKP6tHhb95JSdiuedANdAJ4Rwny3+ak11i+k1vB59fMV0OjvABiDwCbwAj0yv62HpNtSGehdFflL3q7ZPkcGF+qxeqnu1Ca8o4wfRm2cTUMbUdwAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAyMC0xMC0wN1QxMDowNDo0OSswMDowMBE0r+QAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMjAtMTAtMDdUMTA6MDQ6NDkrMDA6MDBgaRdYAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAABJRU5ErkJggg==) no-repeat center center;
  padding: 35px 0 0 35px;
  display: inline-block;
}

div.icon-download {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QAAAAAAAD5Q7t/AAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAhUlEQVRIx+2UwQmAMAxFf3WSrhLcyavenCrgwTm6hrd6FUmaFCmC9B9D8h6BNkCPkeBpIqIs1ZnZnB9ab9AF3wvEV0BEM4CtkrUw8/osjlJnSumIMZ4ApjdwVVApUeFFgVNShJsCQ2LCXQJF4oK7BTdJALB74f+I9tFyLQiQz3fzU9HzfS6NNz2QAU57QQAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAyMC0xMC0yNlQxNDoyNzozNyswMDowMEJ4tKIAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMjAtMTAtMjZUMTQ6Mjc6MzcrMDA6MDAzJQweAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAABJRU5ErkJggg==) no-repeat center center;
  padding: 35px 0 0 35px;
  display: inline-block;
}

div.icon-briefcase {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QAAAAAAAD5Q7t/AAAACXBIWXMAAA7EAAAOxAGVKw4bAAAArElEQVRIx+2TMQ6DMAxF/RHHQD1MpQ5wEa7ByCGrLuk9fpcEVa4hKcQdKt7oON/2jyNyUgOSEz+ZPcXrFUlKpXFN42GpWYDkQDJoD3THuXjU6NM53hKDiHSVGg8ALrs8zaF13N+gLe3KigNA7u7vtqgERPYWeCQ71lZSs5F7t5JvrMd1mVp3lKyw4pZluftFW/St72tv4IKe4Cki3cEfHbYmGGORI+Kjtyt/xgvhYilSEQrPJAAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAyMC0xMC0wNlQxMjo1Njo1NyswMDowMDY3jIIAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMjAtMTAtMDZUMTI6NTY6NTcrMDA6MDBHajQ+AAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAABJRU5ErkJggg==) no-repeat center center;
  padding: 35px 0 0 35px;
  display: inline-block;
}

div.icon-eye {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QAAAAAAAD5Q7t/AAAACXBIWXMAAA7EAAAOxAGVKw4bAAABWUlEQVRIx+2UvUpDQRCFz6pJkUQw8RH8KUXBPhrwmYRgoWDkWoraSLDJC0hiLUb8KSX4DGoVtTRg+CzuCuEym9zYpMmB5cKeM2fn7syONMWk4UYJgDlJG5LWJJX8dlfSi6Rn59zPv04GVoFz4IswPoEzYGUc4wxwBPRIjx5QAzKjzEtA2zC4AspA3q8toGnoboFiyDwPPBlBu0MSqhr6ByBniS+tzD2XBY6BN+AViICs51pGXD1pvhO427LnI4OLPLcdiK0MHnAXEBU8/2Zw756bD8S2JWkmbXMZe33/HfqW/g44CPCb/tswuEZCk0xoP1mHuvGbzYEiR77AySJfG3EXVhflgEdDXA3eG+wZ+nuzTX1AkfixJNHy3VLwqxLI/AZYGF7NeFTUGG9UfAOHxIMxHYBl4JR4oIXwAZwASyGfNON6VtK64nG96Le7kjqSOs65/iiPKSaLX/pA8qZ4SFURAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIwLTEwLTA2VDExOjAxOjEzKzAwOjAwaHN9ngAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMC0xMC0wNlQxMTowMToxMyswMDowMBkuxSIAAAAZdEVYdFNvZnR3YXJlAHd3dy5pbmtzY2FwZS5vcmeb7jwaAAAAAElFTkSuQmCC ) no-repeat center center;
  padding: 35px 0 0 35px;
  display: inline-block;
}


.header-link {
  cursor: pointer;
  margin-left: 5px;
  line-height: 35px;
}



.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  -ms-text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

.infoba-header {
  color: #fff;
  padding: 4px;
  padding-left: 8px;
  padding-right: 10px;
  display: flex;
  justify-content: space-between;
  align-content: center;
  background: #888;
  position: relative;
  margin-top: 20px
}

.infoba-header:first-child {
  margin-top: 0
}

.infoba-header i {
  position: absolute;
  top: 2px;
  line-height: 35px;
  font-size: 24px !important
}

.infoba-header span {
  position: absolute;
  left: 34px;
  line-height: 35px;
  color: #fff;
  cursor: pointer;
  top: 5px
}

.quick-access-item {
  position: relative
}

.infoba-quick-access-item {
  background-color: #fff;
  padding: 20px 10px 20px 35px;
  cursor: auto;
  height: auto
}

.infoba-gray {
  color: #888
}

.infoba-link {
  color: #888 !important;
  cursor: pointer
}

.slide-enter-active {
  -moz-transition-duration: .3s;
  -webkit-transition-duration: .3s;
  -o-transition-duration: .3s;
  transition-duration: .3s;
  -moz-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in
}

.slide-leave-active {
  -moz-transition-duration: .3s;
  -webkit-transition-duration: .3s;
  -o-transition-duration: .3s;
  transition-duration: .3s;
  -moz-transition-timing-function: cubic-bezier(0,1,.5,1);
  -webkit-transition-timing-function: cubic-bezier(0,1,.5,1);
  -o-transition-timing-function: cubic-bezier(0,1,.5,1);
  transition-timing-function: cubic-bezier(0,1,.5,1)
}

.slide-enter-to, .slide-leave {
  max-height: 100px;
  overflow: hidden
}

.slide-enter, .slide-leave-to {
  overflow: hidden;
  max-height: 0
}

.rotate {
  -moz-transition: all .3s linear;
  -webkit-transition: all .3s linear;
  transition: all .3s linear
}

.rotate.up {
  -moz-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg)
}

.infoba-rotate {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite
}

@-webkit-keyframes rotating {
  from {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg)
  }

  to {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg)
  }
}

@keyframes rotating {
  from {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg)
  }

  to {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg)
  }
}

.infoba-accordeon {
  padding-left: 5px
}

.infoba-accordeon-header {
  line-height: 20px;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  border-bottom: solid 1px
}

.infoba-accordeon-row {
  padding-left: 5px;
  font-size: 12px;
  line-height: 30px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ccc
}

.infoba-row {
  padding-left: 5px;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ccc
}

.infoba-logo {
  height: 35px
}
}
</style>
