import { textUtil } from './textUtil';

class HtmlUtil {
  /**
   * @description created html div element with given class and containing given html children and with given title
   * @param  {string} title - title if needed
   * @param  {string} className - div class name
   * @param  {string[]} htmlChildren - html elements to be inside the div - alternatively - text
   * @return  {string} - formatted html div
   */
  createDiv(title: string, className: string, htmlChildren: string[]): string {
    let div = '<div ';
    if (title) {
      div += `title="${title}" `;
    }
    if (className) {
      div += `class='${className}'`;
    }
    div += '>';
    if (htmlChildren) {
      div += htmlChildren.join(' ');
    }
    div += '</div>';
    return div;
  }

  /**
   * @description created html div element with given class and containing given text
   * @param  {string} title - title if needed
   * @param {string | string[]} classNames
   * @param {string} text - text inside div - alternatively children
   * @return  {string} - formatted html div
   */
  createSimpleDiv(title: string, classNames: string | string[], text: string): string {
    const element = document.createElement('div');
    const classNamesList: string[] = textUtil.splitAndAppend(classNames);

    element.innerHTML = text;
    if (title != null) {
      element.title = title;
    }
    if (classNamesList.length > 0) {
      element.classList.add(...classNamesList);
    }
    return element.outerHTML;
  }

  /**
   * @description created html div element with given class and containing given html children and with given title and additionaly list of
   *                        custom properties
   * @param  {string} title - title if needed
   * @param  {string} className - div class name
   * @param  {string[]} htmlChildren - html elements to be inside the div - alternatively - text
   * @param {Map<string, string>} customProps
   * @return  {string} - formatted html div
   */
  createCustomDiv(title: string, className: string, htmlChildren: string[], customProps: Map<string, string>): string {
    const element = document.createElement('div');
    const classNameList = textUtil.splitAndAppend(className);
    element.title = title;
    if (classNameList.length > 0) {
      element.classList.add(...classNameList);
    }
    for (const [key, value] of customProps) {
      element.setAttribute(key, value);
    }
    element.innerHTML = htmlChildren.join(' ');

    return element.outerHTML;
  }

  /**
   * @description creates html span element with given class and text
   * @param  {string} className - span class name
   * @param  {string} text - span text
   * @return  {string} - formatted html span
   */
  createSpan(className: string, text: string): string {
    const element = document.createElement('span');
    const classNameList = textUtil.splitAndAppend(className);
    if (classNameList.length > 0) {
      element.classList.add(...classNameList);
    }
    if (text) {
      element.innerHTML = text;
    }
    return element.outerHTML;
  }

  /**
   * @description creates html img element with given url and alt
   * @param  {string} url - url
   * @param  {string} alt - alt text
   * @return  {string} - formatted html img
   */
  createImg(url: string, alt: string): string {
    return `<img src="${url}"  class="user-img" alt="${alt}" >`;
  }

  /**
   * @description creates bolded text
   * @param  {string} text -  text
   * @return  {string} - formatted bolded text
   */
  boldedText(text: string): string {
    return '<strong>' + text + '</strong>';
  }

  /**
   * @description creates icon with given class
   * @param  {string} iconClass -  text
   * @return  {string} - formatted icon
   */
  createIcon(iconClass: string): string {
    return `<i class="${iconClass}"></i>`;
  }
}

export const htmlUtil = new HtmlUtil();
