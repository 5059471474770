<template>
  <Timepicker
    class="timepicker"
    format="HH:mm"
    hour-label="Time"
    minute-label="Minut"
    hide-clear-button
    advanced-keyboard
    :minute-interval="timepickerSettings.DEFAULT_INTERVAL"
    :start="start"
    :end="end"
    :placeholder="'PRESENCE_PLACEHOLDER' | fromTextKey"
    :value="value"
    :disabled="disabled"
    @change="$emit('change', $event)"
    @input="$emit('input', $event)"
  />
</template>
<script lang="js">
import Timepicker from '../../components/Timepicker';
import { timepickerSettings } from '../../enums/timepickerSettings';
export default {
  name: 'VacationIntervalsFragmentTimepicker',
  components: {
    Timepicker
  },
  props: {
    value: {
      type: String,
      required: true,
    },
    start: {
      type: String,
      required: false,
    },
    end: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  data() {
    return {
      timepickerSettings
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../../../shared/assets/scss/core/breakpoints.scss';

@include breakpoint-lg() {
  .timepicker {
    max-width: 100px;
  }
}
</style>
