<template>
  <div id="wrapper">
    <div id="title-and-notification">
      <h1>Forældreportalen</h1>
      <div v-if="hasNotifications()" id="notification-badge">
        {{ getNotificationCount() }}
      </div>
    </div>
    <p v-if="hasNotifications()">
      Der er nyt om dit barn i Forældreportalen.
      Tilgå nyheder ved at klikke her og anvende dit MitID for login:
    </p>
    <p v-else>
      Tilgå information omkring dit barn i Forældreportalen ved at klikke her og anvende dit MitID for login:
    </p>
    <a v-on:click="deleteAllNotifications" v-bind:href="FPLink" target="_blank">
      {{ FPLink.replace("https://", "") }}
      <i class="fa fa-chevron-right"></i>
    </a>
  </div>
</template>

<script>
module.exports = {
  data: () => ({
    FPLink: "https://hh-forældreportalen.dk",
  }),

  props: {
    notifications      : Array,
    deleteNotifications: Function,
  },

  methods: {
    getNotificationCount () {
      return this.notifications ? this.notifications.length : 0;
    },

    hasNotifications () {
      return this.getNotificationCount() > 0
    },

    deleteAllNotifications () {
      const notificationIds = this.notifications.map(n => n.widgetNotificationId);
      this.deleteNotifications(notificationIds);
    }
  },
}
</script>

<style scoped>
a {
  color: black;
}

i {
  margin-left: 0.5rem;
}

p {
  margin-bottom: 1rem;
}

#wrapper {
  padding: 0 20px 20px;
  background-color: white;
}

#title-and-notification {
  display: flex;
  align-items: center;
  justify-items: center;
  justify-content: space-between;
}

#notification-badge {
  color: white;
  background-color: #b31551;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 100px;
  display: flex;
  align-content: center;
  justify-content: center;
  font-size: 1rem;
}
</style>
