type windowType = WindowProxy;
// eslint-disable-next-line no-unused-vars
type domainCallbackType = (event: MessageEvent) => void;
class PostMessageController {
  private _registry: Map<windowType, domainCallbackType> = new Map();

  constructor() {
    window.addEventListener('message', event => {
      const source = event.source as windowType;
      const callback = this._registry.get(source);
      if (callback != null) {
        callback(event);
      }
    });
  }

  register(proxy: windowType, domainCallback: domainCallbackType) {
    this._registry.set(proxy, domainCallback);
  }

  unregister(domain: windowType) {
    this._registry.delete(domain);
  }

  clear() {
    this._registry.clear();
  }
}

export const postMessageController = new PostMessageController();
