<template>
  <div class="myWidget">
    <b-tabs pills>
      <b-tab title="Form data" active>

        <h1>Form data</h1>

        <b-row>
          <b-col>
            <br>
            <div>
              <b-dropdown id="ddown1" text="Dropdown Button" class="m-md-2">
                <b-dropdown-item>First Action</b-dropdown-item>
                <b-dropdown-item>Second Action</b-dropdown-item>
                <b-dropdown-item>Third Action</b-dropdown-item>
                <b-dropdown-divider/>
                <b-dropdown-item active>Active action</b-dropdown-item>
                <b-dropdown-item disabled>Disabled action</b-dropdown-item>
              </b-dropdown>
            </div>
            <br>
            <b-form-group
              id="exampleInputGroup1"
              label="Email address:"
              label-for="exampleInput1"
              description="We'll never share your email with anyone else."
            >
              <b-form-input
                id="exampleInput1"
                type="email"
                required
                placeholder="Enter email"/>
            </b-form-group>
            <b-form-group
              id="exampleInputGroup2"
              label="Address:"
              label-for="exampleInput2"
              description="Where do you live."
            >
              <b-form-input
                id="exampleInput2"
                type="text"
                required
                placeholder="Enter adress"/>
            </b-form-group>

          </b-col>
          <b-col>
            <label>Select</label>
            <b-form-select class="mb-3">
              <option :value="null">Please select an option</option>
              <option value="a">Option A</option>
              <option value="b" disabled>Option B (disabled)</option>
              <optgroup label="Grouped Options">
                <option :value="{ C: '3PO' }">Option with object value</option>
                <option :value="{ R: '2D2' }">Another option with object value</option>
              </optgroup>
            </b-form-select>

            <label>Checkboxes</label>
            <b-form-group id="exampleGroup4">
              <b-form-checkbox-group id="exampleChecks">
                <b-form-checkbox value="me">Check me out</b-form-checkbox>
                <b-form-checkbox value="that">Check that out</b-form-checkbox>
              </b-form-checkbox-group>
            </b-form-group>

            <label>Radios</label>
            <b-form-group>
              <b-form-radio value="A" name="some-radios">Option A</b-form-radio>
              <b-form-radio value="B" name="some-radios">Option B</b-form-radio>
            </b-form-group>
          </b-col>
        </b-row>

        <b-button type="submit" variant="primary">Submit</b-button>
        <b-button type="reset" variant="normal">Reset</b-button>

      </b-tab>
      <b-tab title="Axios">
        <i class="fa fa-spinner fa-spin" v-if="loading"></i>
        <div v-if="posts && posts.length">
          <p v-for="post of posts">
            <strong>{{ post.id }} - {{ post.title }}</strong>
            <br/> {{ post.body }}
          </p>
        </div>
      </b-tab>
      <b-tab title="Properties">
        <h1>Properties</h1>
        <template v-if="sessionUUID"><strong>sessionUUID:</strong> {{ sessionUUID }}<br/></template>
        <template v-if="placement"><strong>placement:</strong> {{ placement }}<br/></template>
        <template v-if="currentWeekNumber"><strong>currentWeekNumber:</strong> {{ currentWeekNumber }}<br/></template>
        <template v-if="isMobileApp"><strong>isMobileApp:</strong> {{ isMobileApp }}<br/></template>
        <template v-if="userProfile"><strong>userProfile:</strong> {{ userProfile }}<br/></template>
        <template v-if="aulaToken"><strong>aulaToken:</strong> <span class="breakWord">{{ aulaToken }}</span><br/>
        </template>
        <template v-if="assuranceLevel"><strong>assuranceLevel:</strong> {{ assuranceLevel }}<br/></template>
        <template v-if="childFilter"><strong>childFilter:</strong> {{ childFilter }}<br/></template>
        <template v-if="institutionFilter"><strong>institutionFilter:</strong> {{ institutionFilter }}<br/></template>
        <template v-if="group"><strong>group:</strong> {{ group }}<br/></template>
        <br/>
      </b-tab>
      <b-tab title="Links">
        <h1>Links</h1>
        <p>
          A external link opening in a new window/tab<br/>
          <a href="https://www.dr.dk/ligetil/indland/otte-skoler-har-testet-ny-udgave-af-skoleintra" target="_blank">Otte
            skoler har testet ny udgave af Skoleintra</a>
        </p>
        <p>
          A external link opening in a same window<br/>
          <a href="https://www.dr.dk/ligetil/indland/otte-skoler-har-testet-ny-udgave-af-skoleintra">Otte skoler har
            testet ny udgave af Skoleintra</a>
        </p>
      </b-tab>
    </b-tabs>
    <div>
      <p class="d-flex justify-content-between align-items-center">
        Notifikationer
        <b-btn @click="clearAllNotifications">Ryd alle</b-btn>
      </p>
      <template v-if="notifications && notifications.length > 0">
        <div v-for="notification in notifications" class="d-flex justify-content-between align-items-center"
             :key="notification.widgetNotificationId">
          <p class="mb-0">
            {{ notification.notificationMessage }}
          </p>
          <b-btn @click="clearNotification(notification)" :disabled="removeNotificationSet.has(notification)"
                 variant="link">
            X
          </b-btn>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    axios: Function,
    sessionUUID: String,
    placement: String,
    currentWeekNumber: String,
    isMobileApp: Boolean,
    userProfile: String,
    getAulaToken: Function,
    assuranceLevel: Number,
    childFilter: Array,
    institutionFilter: Array,
    group: Number,
    notifications: Array,
    deleteNotifications: Function,
  },
  data: function () {
    return {
      loading: true,
      posts: [],
      aulaToken: null,
      removeNotificationSet: new Set(),
    }
  },
  created: function () {
    this.axios
      .get("/static/posts.json")
      .then(response => {
        this.posts = response.data;
        this.loading = false;
      })
      .catch(e => {
        console.log(e);
      });
  },
  mounted() {
    this.getAulaToken();
  },
  methods: {
    async clearNotification(notification) {
      this.removeNotificationSet.add(notification);
      await this.deleteNotifications([notification.widgetNotificationId]);
      this.removeNotificationSet.delete(notification);
    },
    async clearAllNotifications() {
      this.removeNotificationSet = new Set(this.notifications);
      const notificationIds = this.notifications.map(notification => notification.widgetNotificationId);
      await this.deleteNotifications(notificationIds);
      this.removeNotificationSet.clear();
    },
  },
}
</script>

<style scoped lang="scss">

.myWidget /deep/ {
  .nav-pills {
    border-bottom: solid 1px #999;
  }

  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    background: none !important;
    border: solid 1px #999;
    border-bottom: solid 1px #fff !important;
    position: relative;
    top: 1px;
    color: #333;
  }

  .tab-pane {
    padding: 20px;
  }
}

input[type=text], input[type=email], select {
  border: solid 1px #ccc;
}

b:before {
  content: "";
  display: block;
}

.breakWord {
  word-break: break-all;
}

a {
  color: #36c;
}

</style>
