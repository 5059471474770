import { render, staticRenderFns } from "./W6V1.vue?vue&type=template&id=4a380c98&scoped=true&"
import script from "./W6V1.vue?vue&type=script&lang=js&"
export * from "./W6V1.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a380c98",
  null
  
)

export default component.exports