<template>
  <span v-html="strippedHtml" />
</template>

<script>
export default {
  props: {
    html: { type: String, default: '' },
  },
  data() {
    return {};
  },
  computed: {
    strippedHtml() {
      if (this.html) {
        return this.stripTags(this.html);
      }
      return '';
    },
  },
  methods: {
    stripTags: function (input) {
      const tags = /<\/?([a-z][a-z0-9]*)\b[^>]*>/gi;
      const commentsAndPhpTags = /<!--[\s\S]*?-->|<\?(?:php)?[\s\S]*?\?>/gi;
      const cleanUrl = /^(?:(?:[a-z]+:)?\/\/|mailto:|https?:)/i;
      let allowed = '<a><em><p><div><span><strong><u><ul><ol><li><table><thead><tbody><tr><th><td><br>';
      allowed = (((allowed || '') + '').toLowerCase().match(/<[a-z][a-z0-9]*>/g) || []).join(''); // making sure the allowed arg is a string containing only tags in lowercase (<a><b><c>)
      return input
        .replace(commentsAndPhpTags, '')
        .replace(
          tags,
          ($0, $1) => (allowed.indexOf('<' + $1.toLowerCase() + '>') > -1 ? $0 : '') // only allow white-listed tags
        )
        .replace(/href="(.*?)"/gi, (m, $2) => {
          const url = cleanUrl.test($2.toLowerCase()) ? $2 : 'javascript:void(0);'; // only allow http(s) and mailto links (no javascript) in href
          return 'href="' + url + '"';
        })
        .replace(/src="(.*?)"/gi, (m, $2) => {
          const url = cleanUrl.test($2.toLowerCase()) ? $2 : 'javascript:void(0);'; // only allow http(s) and mailto links (no javascript) in src
          return 'src="' + url + '"';
        })
        .replace(
          /<[^>]+/g,
          m => m.replace(/ on\w+="[^"]*"/gi, '') // no onclick, onfocus, etc.
        );
    },
  },
};
</script>

<style scoped lang="scss">
@import '../assets/scss/core/variables.scss';
@import '../assets/scss/core/breakpoints.scss';
</style>
