import { EventRenderer } from './eventRenderer';
import { CalendarEventModel } from '../../../shared/models/calendarEvent.model';
import { ResourceModel } from '../../../shared/models/resource.model';
import { NotificationModel } from '../../../shared/models/notification.model';
import { InstitutionProfileModel } from '../../../shared/models/institutionProfile.model';
import moment from 'moment-timezone';
import Vue from 'vue';
import { calendarUtil } from '../../../shared/utils/calendarUtil';
import { format } from '../../../shared/utils/dateUtil.js';
import { dateFormatEnum } from '../../../shared/enums/dateFormatEnum';

export class NoticeBoardEventRenderer extends EventRenderer {
  private readonly isStart: boolean;
  private readonly creatorName: string;
  constructor(
    { event, el }: { event: CalendarEventModel; el: HTMLElement; isStart: boolean },
    resources: ResourceModel[],
    notifications: NotificationModel[],
    profile: InstitutionProfileModel,
    delegatedContext: InstitutionProfileModel,
    noOfResources: number,
    isStart: boolean
  ) {
    super(
      {
        event,
        el,
      },
      resources,
      notifications,
      profile,
      delegatedContext,
      noOfResources
    );

    this.isStart = isStart;
    this.creatorName = event.extendedProps.creatorName;
  }

  override render(): string {
    if (this.shouldBeInvisible) {
      this.element.style.display = 'none';
      return '';
    }

    const dateTimeElement = this.getDateTimeElement();
    const eventDetailElement = this.getEventDetailElement();

    return dateTimeElement.outerHTML + eventDetailElement.outerHTML;
  }

  private getEventDetailElement() {
    const eventDetailElement = document.createElement('td');
    const titleElement = document.createElement('div');
    const metadataElement = document.createElement('div');
    const locationElement = document.createElement('div');
    const creatorElement = document.createElement('div');

    eventDetailElement.className = 'event-detail-container';

    titleElement.innerText = this.eventTitle;
    titleElement.className = 'event-title';

    metadataElement.className = 'event-meta-data-container';

    const locationText = calendarUtil.getPrimaryLocation(this.extendedProps);

    if (locationText !== '') {
      locationElement.innerText = locationText;
      locationElement.className = 'event-location';
      metadataElement.append(locationElement);
    }

    creatorElement.innerText = `(${this.creatorName})`;
    creatorElement.className = 'event-creator';

    metadataElement.append(creatorElement);

    eventDetailElement.append(titleElement);
    eventDetailElement.append(metadataElement);

    return eventDetailElement;
  }

  private getDateTimeElement() {
    const dateTimeElement = document.createElement('td');
    let dateTime1Text = '';
    let dateTime2Text = '';

    if (this.allDay) {
      dateTime1Text = format(this.eventStart, dateFormatEnum.COMPACT_DATE_MONTH);
      dateTime2Text = Vue.filter('fromTextKey')('CALENDAR_LABEL_EVENT_ALL_DAY');
    } else if (this.endsOnDifferentDate) {
      dateTime1Text = `${format(this.eventStart, dateFormatEnum.COMPACT_DATE_MONTH)} ${this.startTime} -`;
      dateTime2Text = `${format(this.eventEnd, dateFormatEnum.COMPACT_DATE_MONTH)} ${this.endTime}`;
    } else {
      dateTime1Text = format(this.eventStart, dateFormatEnum.COMPACT_DATE_MONTH);
      dateTime2Text = `${moment(this.eventStart).format('HH:mm')}-${this.endTime}`;
    }

    dateTimeElement.innerText = `${dateTime1Text} \n ${dateTime2Text}`;
    dateTimeElement.className = 'date-time-container';

    return dateTimeElement;
  }

  private get startTime() {
    return this.eventStart != null ? moment(this.eventStart).format('HH:mm') : '';
  }

  private get endTime() {
    return this.eventEnd != null ? moment(this.eventEnd).format('HH:mm') : '';
  }

  private get shouldBeInvisible() {
    return this.endsOnDifferentDate && !this.allDay && !this.extendedProps.repeating && !this.isStart;
  }

  private get endsOnDifferentDate() {
    return (
      moment(this.extendedProps.endDateTime).diff(moment(this.extendedProps.startDateTime), 'days') >= 1 ||
      moment(this.extendedProps.endDateTime).date() !== moment(this.extendedProps.startDateTime).date()
    );
  }
}
