<template>
  <div class="widget-container default-theme" :class="{'admin-theme' : userProfile == 'employee'}">
    <div class="widget-header">
      <form :action="aioUrl" method="POST" target="_blank">
        <input type="hidden" id="aulatoken" name="aulatoken" :value="aulaToken" />
        <input type="hidden" id="userProfile" name="userProfile" :value="userProfile" />
        <button class="widget-login-button" type="submit" aria-label="Gå til FKPay"><span class="mr-8">Gå til FKPay</span><i aria-hidden="true" class="fa-solid fa-arrow-right"></i></button>
      </form>
    </div>
  </div>
</template>
<style scoped>
button {
  color: #fff;
  background-color: rgb(69, 147, 194);
  border: 1px solid rgb(69, 147, 194);
  border-radius: 0;
  font-weight: 600;
  transition: none;
}

button:hover {
  color: rgb(255, 255, 255);
  background: rgb(40, 113, 159);
  border-color: rgb(40, 113, 159);
}

button:active {
  background: #18638F;
  border-color: #18638F;
}

button.active {
  background: #153854;
  border-color: #153854;
  pointer-events: none;
}

button[disabled="disabled"] {
  background-color: transparent !important;
  color: rgba(16, 16, 16, 0.3) !important;
  border-color: rgba(118, 118, 118, 0.3) !important;
  pointer-events: none !important;
}

.admin-theme button {
  background-color: #45B7C1;
  border: 1px solid #45B7C1;
}

.admin-theme button:hover {
  color: rgb(255, 255, 255);
  background: #2091A2;
  border-color: #2091A2;
}

.admin-theme button:active {
  background: #007A8D;
  border-color: #007A8D;
}

.admin-theme button.active {
  background: #222350;
  border-color: #222350;
  pointer-events: none;
}

.widget-container {
  background: #ffffff;
  padding: 10px;
  color: #222350;
}

.widget-login-button {
  padding: 10px 15px;
  width: 100%;
}

.widget-container .fa-solid {
  font-family: 'Font Awesome 5 Pro';
  font-style: initial;
}

.mr-8 {
  margin-right: 8px;
}

@keyframes loading-spinner {
  0% {
    transform: rotateZ(0deg);
  }

  50% {
    transform: rotateZ(180deg);
  }

  100% {
    transform: rotateZ(360deg);
  }
}

@-moz-keyframes loading-spinner {
  0% {
    transform: rotateZ(0deg);
  }

  50% {
    transform: rotateZ(180deg);
  }

  100% {
    transform: rotateZ(360deg);
  }
}

@-webkit-keyframes loading-spinner {
  0% {
    transform: rotateZ(0deg);
  }

  50% {
    transform: rotateZ(180deg);
  }

  100% {
    transform: rotateZ(360deg);
  }
}

@media screen and (min-width:992px) and (max-width:1200px) {
  .widget-login-button {
    font-size: 14px;
  }

  .widget-container {
    font-size: 14px;
  }
}
</style>
<script>
module.exports = {
  props: {
    axios: Function,
    getAulaToken: Function,
    userProfile: String
  },
  data: () => {
    return {
      aulaToken: null,
      aioUrl: "https://fkpay.dk/aula/login?return"
    }
  },
  methods: {
  },
  mounted() {
    this.aulaToken = this.getAulaToken();
  },
  watch: {
  }
};
</script>
