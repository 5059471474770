<template>
  <div class='widget-W0070V0001'>
    <div class="subit-widget-container">
      <div class="details-header">
        <div class="header-top">
          <div v-if="selectedTeacher">
            <div :style="{background: '#464d66', color: 'white', borderRadius: '50%'}">
              <div class="initial">
                <span>{{ showSelectedTeacher.schools.initials }}</span>
              </div>
            </div>
          </div>

          <div class="full-name" v-if="selectedTeacher && selectedTeacher.name" @click="showDropdown = !showDropdown">
            {{ selectedTeacher.name.full.length > 0 ? selectedTeacher.name.full : '' }}
            <i class="fas fa-chevron-down"></i>
          </div>

          <div class="dropdown-wrapper">

            <div v-if="showDropdown" class="dropdownMenu">
              <ul class="dropdown-item">
                <li class="list-item currentSelected" v-if="user" @click="teacherClicked(user.teacher_id)">
                                <span v-bind:style="{ background: '#2DCC70', color: 'white', padding: '5px',
                                borderRadius: '50px', fontSize: '12px', marginRight: '10px' }">
                                    {{ user.name.initials }}
                                </span>
                  <span>{{ user.name.full }}</span>
                </li>

                <li class="list-item" v-for="teacher in showTeacherList"
                    v-bind:key="teacher.id" @click="teacherClicked(teacher.id)">
                                <span v-bind:style="{ background: '#464d66', color: 'white', padding: '5px',
                                    borderRadius: '50px', fontSize: '12px', marginRight: '10px' }">
                                    {{ teacher.initials && teacher.initials.length > 0 ? teacher.initials : '' }}
                                </span>
                  <span>{{ teacher.full }}</span>
                </li>
              </ul>
            </div>
          </div>

          <div class="date">
            <div class="calendar-left" v-on:click="changeDate(-1)">
              <i class="calendar-arrow-left"></i>
            </div>
            <span>
                        {{ formatDate(this.selectedDate, 'D MMM') }}
                    </span>
            <div class="calendar-right" v-on:click="changeDate(1)">
              <i class="calendar-arrow-right"></i>
            </div>
          </div>
        </div>
      </div>
      <div class="details-content">
        <div class="loading" v-if="loading">
          <i class="fa fa-spinner"></i>
        </div>
        <div v-if="!loading">
          <div class="no-lesson-container" v-if="this.showActivitiesList && this.showActivitiesList.length === 0">
            <p>ingen skemaændringer i dag</p>
          </div>

          <div class="header-bottom" v-else>
            <div class="lessob-box-container">
              <div class="lesson-box">
                <div v-for="activity in showActivitiesList"
                     :key="activity.id" :class="{ active: activity.id === currentSelectedActivityId }">
                  <div :class="{ grey: activity.is_cancelled }">
                                    <span class="content-wrap" @click="handleOnClick(activity.id)">
                                        <span class="relative-content">
                                            <span>
                                                <span :class="{ grey: activity.is_cancelled }">
                                                    {{ getSubject(activity.subject_id) }}
                                                </span>
                                                <span :class="{ grey: activity.is_cancelled }"
                                                      v-show="activity.id === currentSelectedActivityId">
                                                    {{ getClass(activity.class_id) }}
                                                </span>
                                            </span>
                                        </span>

                                        <span v-show="activity.id === currentSelectedActivityId"
                                              :class="{ grey: activity.is_cancelled }">
                                            {{ formatDate(activity.starts_at, 'HH:mm') }}
                                            - {{ formatDate(activity.ends_at, 'HH:mm') }}
                                        </span>
                                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="teacher-assin-row">
            <!-- show teacher detail when clicked on subject item -->
            <div v-show="showCurrentSelectedActivity && !showCurrentSelectedActivity.is_cancelled">
              <div class="teacher-assign">
                <div v-if="hasProperty(showCurrentSelectedActivity,'substitute') ">
                  <div class="initial"
                       v-if="activities.tutors.length > 0 && showCurrentSelectedActivity.substitute.type === 'tutor'"
                       :style="{background: selectedColor(showCurrentSelectedActivity.teacher_id), color: 'white', borderRadius: '50%'}">
                    {{ getTutor(showCurrentSelectedActivity.substitute.id).name.initials }}
                  </div>
                </div>
                <div v-if="hasProperty(showCurrentSelectedActivity, 'substitute')">
                  <div class="initial" v-if="showCurrentSelectedActivity.substitute.type === 'teacher'"
                       :style="{background: selectedColor(showCurrentSelectedActivity.teacher_id), color: 'white', borderRadius: '50%'}">
                    {{ getTeacherInitial(showCurrentSelectedActivity.substitute.id) }}
                  </div>
                </div>

                <div class="space">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20">
                    <path fill="none" stroke="#000" d="M10 7H2v6h8v5l8-8-8-8v5z"/>
                  </svg>
                </div>

                <div class="initial" v-if="selectedTeacher"
                     :style="{background: selectedColor(showCurrentSelectedActivity.teacher_id), color: 'white', borderRadius: '50%'}">
                  {{ showSelectedTeacher.schools.initials }}
                </div>

                <span class="title-padding" v-if="hasProperty(showCurrentSelectedActivity, 'substitute')
                            && showCurrentSelectedActivity.substitute.id === user.teacher_id">Lektionen blev tildelt dig</span>
                <span class="title-padding" v-if="hasProperty(showCurrentSelectedActivity, 'substitute')
                            && showCurrentSelectedActivity.substitute.id !== user.teacher_id">Du blev frameldt lektionen</span>
              </div>
              <div class="line"></div>
              <div class="teacher-notes">
                <div class="note-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32">
                    <path fill="#4d88ff"
                          d="M0 0v20.667a6 6 0 006 6h4.393L16 32l5.607-5.333H26a6 6 0 006-6V0H0zm30.667 20.667A4.667 4.667 0 0126 25.334h-4.94L16 30.114l-5.06-4.78H6a4.667 4.667 0 01-4.667-4.667V1.334h29.333v19.333zm-23.334-14h17.333V8H7.333V6.667zm0 5.333h17.333v1.333H7.333V12zm0 5.333h13.333v1.333H7.333v-1.333z"/>
                  </svg>
                </div>
                <div class="note-message">
                                <span v-if="showCurrentSelectedActivity && showCurrentSelectedActivity.notes > 1">
                                    {{ showCurrentSelectedActivity && showCurrentSelectedActivity.notes }} noter tilføjet
                                </span>
                  <span v-else-if="showCurrentSelectedActivity && showCurrentSelectedActivity.notes === 1">
                                    {{ showCurrentSelectedActivity && showCurrentSelectedActivity.notes }} note tilføjet
                                </span>
                  <span v-else>
                                    Ingen noter tilføjet
                                </span>
                </div>
              </div>
            </div>

            <!-- Show current selected cancelled subject -->
            <div class="cancel-section" v-show="showCurrentSelectedActivity && showCurrentSelectedActivity.is_cancelled"
                 v-on:click="toggleLesson">
              <div class="lesson-cancel-message">
                <div class="cancel-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
                    <g data-name="Group 1119">
                      <g data-name="Group 955">
                        <g data-name="Ellipse 4">
                          <path data-name="Path 134"
                                d="M12 0a12 12 0 016.675 21.974A11.711 11.711 0 0112 24a12 12 0 010-24z"
                                fill="#c0c5d0"/>
                        </g>
                        <path data-name="Path 133"
                              d="M12 4.875a6.812 6.812 0 00-2.721.547 7 7 0 00-2.235 1.5 7 7 0 00-1.5 2.235A6.812 6.812 0 005 11.875a6.812 6.812 0 00.547 2.725 7 7 0 001.5 2.235 7 7 0 002.232 1.493 6.812 6.812 0 002.721.547 6.812 6.812 0 002.721-.547 7 7 0 002.235-1.5 7 7 0 001.5-2.235A6.812 6.812 0 0019 11.875a6.812 6.812 0 00-.547-2.721 7 7 0 00-1.5-2.235 7 7 0 00-2.235-1.5A6.812 6.812 0 0012 4.875zm0 .588a6.489 6.489 0 011.189.109 6.429 6.429 0 011.107.314 6.491 6.491 0 011.012.492 5.784 5.784 0 01.9.67v-.014l-9.103 8.984a5.58 5.58 0 01-.629-.889 6.188 6.188 0 01-.485-1 5.888 5.888 0 01-.291-1.092 6.2 6.2 0 01-.112-1.162 6.263 6.263 0 01.506-2.5 6.523 6.523 0 011.374-2.03A6.523 6.523 0 019.5 5.969a6.263 6.263 0 012.5-.506zm0 12.824a6.678 6.678 0 01-1.285-.123 6.294 6.294 0 01-1.19-.364 6.145 6.145 0 01-1.08-.581 6.4 6.4 0 01-.945-.778v.014l9.133-9.01a6.128 6.128 0 01.738.93 6.6 6.6 0 01.561 1.066 6.274 6.274 0 01.355 1.176 6.4 6.4 0 01.123 1.258 6.263 6.263 0 01-.506 2.5 6.523 6.523 0 01-1.374 2.03 6.523 6.523 0 01-2.03 1.376 6.263 6.263 0 01-2.5.506z"
                              fill="#fff"/>
                      </g>
                    </g>
                  </svg>
                  <span class="grey">Lektionen blev annulleret</span>
                </div>
                <div class="cancel-reason" v-if="lessonCancelled">
                                <span class="circle-with-content">
                                    <span class="circle-line"></span>
                                    <span class="para grey">{{ getCancellationReasons() }}</span>
                                </span>
                </div>
              </div>
              <div class="arrow-down">
                <i class="fas fa-chevron-down" v-if="!lessonCancelled"></i>
                <i class="fas fa-chevron-up" v-if="lessonCancelled"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="footer-item">
          <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 32 32" class="footer-item-icon">
            <title>mail</title>
            <path
              d="M0 4v18c0 3.314 2.686 6 6 6v0h20c3.314 0 6-2.686 6-6v0-18h-32zM30.667 22c0 2.577-2.089 4.667-4.667 4.667v0h-20c-2.577 0-4.667-2.089-4.667-4.667v0-16.667h29.333v16.667zM28.32 9.113l-0.893-0.987-11.427 10.307-11.36-10.293-0.893 0.987 12.253 11.107 12.32-11.12z"/>
          </svg>
          <p class="footer-item-text">support@subit.dk</p>
        </div>
        <div class="footer-item">
          <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 32 32" class="footer-item-icon">
            <title>smart-phone</title>
            <path
              d="M22 0h-12c-1.841 0-3.333 1.492-3.333 3.333v0 25.333c0 1.841 1.492 3.333 3.333 3.333v0h12c1.841 0 3.333-1.492 3.333-3.333v0-25.333c0-1.841-1.492-3.333-3.333-3.333v0zM10 1.333h12c1.105 0 2 0.895 2 2v0 22h-16v-22c0-1.105 0.895-2 2-2v0zM22 30.667h-12c-1.105 0-2-0.895-2-2v0-2h16v2c0 1.105-0.895 2-2 2v0zM17.333 28.667c0 0.736-0.597 1.333-1.333 1.333s-1.333-0.597-1.333-1.333c0-0.736 0.597-1.333 1.333-1.333s1.333 0.597 1.333 1.333z"/>
          </svg>
          <p class="footer-item-text">70 60 51 02</p>
        </div>
      </div>
    </div>

  </div>
</template>
<script>

export default {
  props: {
    getAulaToken: Function,
    axios: Function,
    moment: Function,
  },
  data() {
    return {
      loading: true,
      user: null,
      selectedDate: this.moment(),
      activities: {},
      aulaToken: null,
      lessonCancelled: false,
      currentSelectedActivity: '',
      cancellationReasons: [],
      currentSelectedActivityId: null,
      showDropdown: false,
      teachers: [],
      selectedTeacher: null,
    }
  },
  computed: {
    showCurrentSelectedActivity: function () {
      if (this.currentSelectedActivityId !== null) {
        if (this.activities && this.activities.activities) {
          const selectedActivities = this.activities.activities.filter(periodActivity => periodActivity.id === this.currentSelectedActivityId)
          if (selectedActivities.length === 1) {
            return selectedActivities[0]
          }
        }
      }
      return ''
    },
    showActivitiesList: function () {
      return this.getActivities()
    },
    showTeacherList: function () {
      if (this.teachers && this.teachers.length > 0) {
        const teacherWithSchools = this.teachers.map(school => school.schools).flat()
        const full = this.teachers.map(teacher => {
          return {
            id: teacher.id,
            full: teacher.name.full.length > 0 ? teacher.name.full : '',
          }
        })
        const teacherDetails = teacherWithSchools.map((item, i) => Object.assign({}, item, full[i]))
        return teacherDetails
      }
    },
    showSelectedTeacher: function () {
      if (this.selectedTeacher && this.selectedTeacher.schools.length > 0) {
        const schools = this.selectedTeacher.schools.map(school => {
          return {
            color: school.hex_color,
            is_hidden: school.is_hidden,
            initials: school.initials === undefined ? '' : school.initials,
          }
        })[0]
        return {
          id: this.selectedTeacher.id,
          schools: schools,
          full: this.selectedTeacher.name.full === '' ? this.selectedTeacher.name.full : '',
        }
      }
    },
  },
  mounted() {
    this.aulaToken = this.getAulaToken()
  },
  watch: {
    aulaToken: function () {
      if (this.aulaToken != undefined && Object.keys(this.aulaToken).length > 0) {
        this.initRender()
      }
    },
  },
  methods: {
    async initRender() {
      this.axios.defaults.baseURL = 'https://api.subit.dk'
      this.axios.defaults.headers.common['x-client'] = 'Aula teacher-details'

      let reloadToken = false
      try {
        const token = localStorage.getItem('subit_token')
        const expire = localStorage.getItem('subit_token_expires')
        if (!token || new Date(expire).getTime() < new Date().getTime()) {
          reloadToken = true
        }
      } catch (e) {
        reloadToken = true
      }

      if (reloadToken) {
        let authResponse = await this.axios.post(`/v2/auth/aula`, {
          'jwt_token': this.aulaToken,
        })

        localStorage.setItem('subit_token', authResponse.data.data.access_token)
        localStorage.setItem('subit_token_expires', authResponse.data.data.expires_at)
      }

      this.axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('subit_token')
      try {
        this.user = await this.fetchUser()
        this.teachers = await this.fetchTeachers()
        this.selectedTeacher = this.teachers.find(teacher => teacher.id === this.user.teacher_id)

        if (this.user) {
          this.activities = await this.fetchActivities(this.formatDate(this.selectedDate), this.selectedTeacher.user_id)
          this.cancellationReasons = await this.fetchCancellationReasons()
        }
      } catch (e) {

      } finally {
        this.loading = false
      }
    },

    handleOnClick: function (id) {
      this.currentSelectedActivityId = id
    },
    hasProperty: function (obj, prop) {
      return Object.prototype.hasOwnProperty.call(obj, prop)
    },
    toggleLesson: function () {
      this.lessonCancelled = !this.lessonCancelled
    },
    formatDate: function (d, format = 'YYYY-MM-DD') {
      return this.moment(d).format(format)
    },
    selectedColor: function (teacherId) {
      if (this.activities && this.activities.teachers) {
        const colors = this.activities.teachers.map(teacher => {
          return {
            teacherId: teacher.id,
            color: teacher.schools[0].hex_color,
          }
        })
        const teacherColor = colors.find(item => item.teacherId === teacherId)
        if (!teacherColor) {
          return "#464D66"
        }

        return teacherColor.color ? teacherColor.color : "#464D66"
      }
    },
    getCancellationReasons: function () {
      const cancellationReasonsIds = this.cancellationReasons
      if (this.activities && this.activities.activities) {
        const reasonIds = this.activities.activities.reduce((arr, item) => {
          if (item.cancellation_reason_id) {
            return arr.concat(item.cancellation_reason_id)
          }
          return arr
        }, [])

        const cancellationReason = cancellationReasonsIds.filter(cancellationReasonId => {
          return reasonIds.some(reasonId => cancellationReasonId.id === reasonId)
        }).find(cancellation => cancellation.value)
        if (cancellationReason) {
          return cancellationReason.value
        }
      }
    },
    getSubject: function (id) {
      const subjects = this.activities.subjects.find(subject => subject.id === id)
      return subjects ? subjects.abbreviation : ''
    },
    getTutor: function (id) {
      const tutor = this.activities.tutors.find(item => item.id === id)
      return tutor ? tutor : ''
    },
    getTeacherInitial: function (id) {
      if (this.activities && this.activities.teachers) {
        const teacher = this.activities.teachers.find(v => v.id === id)
        if (teacher && teacher.schools.length > 0) {
          return teacher.schools[0].initials
        }

        return ''
      }
    },
    getClass: function (id) {
      const classesNames = this.activities.classes.find(className => className.id === id)
      return classesNames ? classesNames.name : ''
    },
    getActivities: function () {
      if (!this.activities || !this.activities.activities) {
        return []
      }
      return this.activities.activities
    },
    fetchUser: async function () {
      const {data} = await this.axios.get('/v2/me')
      return data ? data.data : {}
    },

    fetchTeachers: async function () {
      const {data} = await this.axios.get('/v2/teachers')
      this.loading = false
      if (data) {
        const sortedTeachers = data.data.sort((a, b) => {
          let initial_a = a.name.initials.toUpperCase()
          let initial_b = b.name.initials.toUpperCase()
          if (initial_a === '') {
            return 1
          } else if (initial_b === '') {
            return -1
          } else {
            return ((initial_a > initial_b) ? 1 : -1)
          }
          return 0
        })
        this.teachers = sortedTeachers.filter(teacher => teacher.schools.some(item => item.is_hidden === false))
        return this.teachers
      }
      return []
    },

    fetchActivities: async function (dd, userId) {
      const {data} = await
        this.axios.get(`/v2/activities?period=${this.formatDate(this.selectedDate)}&users=${userId}&changed=1&only=teachers,tutors,subjects,classes`)

      this.loading = false
      if (data) {
        if (data.data) {
          if (data.data.periods && data.data.periods.length > 0 && data.data.periods[0].activities.length > 0) {
            const activities = data.data.periods[0].activities.filter(item => item.substitute || item.is_cancelled).sort((a, b) => {
              if (a.starts_at < b.starts_at || (a.starts_at == b.starts_at && a.ends_at > b.ends_at)) {
                return -1
              }

              if (a.starts_at > b.starts_at || (a.starts_at == b.starts_at && a.ends_at < b.ends_at)) {
                return 1
              }
              return 0
            })
            this.currentSelectedActivityId = activities[0].id
            this.activities = {...data.data, activities: activities}

          } else {
            return this.activities = {}
          }

          return this.activities
        }
      }

      return {}
    },
    fetchCancellationReasons: async function () {
      const {data} = await this.axios.get(`/v2/cancellation-reasons`)
      return data ? data.data : []
    },
    changeDate: async function (diff) {
      this.selectedDate = this.moment(this.selectedDate).add(diff, 'days')
      this.activities = await this.fetchActivities(this.formatDate(this.selectedDate), this.selectedTeacher.user_id)
      this.cancellationReasons = await this.fetchCancellationReasons()
    },
    teacherClicked: async function (id) {
      this.selectedTeacher = this.teachers.find(teacher => teacher.id === id)
      this.activities = await this.fetchActivities(this.formatDate(this.selectedDate), this.selectedTeacher.user_id)
      this.showDropdown = false
      this.cancellationReasons = await this.fetchCancellationReasons()
    },
  },
}
</script>
<style lang="scss">
.widget-W0070V0001 {
  ul, li {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .subit-widget-container {
    background-color: #f5f6fa;
    height: auto;
    font-size: 100%;
  }

  .details-header {
    display: flex;
    flex-direction: column;
    height: auto;
    background-color: #4D88FF;
    color: white;
    text-align: left;
    padding: 20px;
    margin-top: 0;
    padding-top: 13px;
    border-radius: 10px 10px 0 0;

  }

  .header-top {
    display: flex;
    align-items: center;
  }

  .date {
    margin-left: auto;
    display: flex;
  }

  .date span {
    padding: 0 20px;
  }

  .initial {
    height: 35px;
    width: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .initial span {
    font-size: 12px;
  }

  .full-name {
    padding: 0 10px;
    position: relative;
    font-size: 14px;
  }

  .header-bottom {
    padding: 0 20px 20px 20px;
    background: #4D88FF;
    overflow: hidden;
    overflow-x: auto;
  }

  .lesson-box {
    display: flex;
  }

  .lesson-box:last-child {
    margin-right: 0;
  }

  .calendar-left, .calendar-right {
    cursor: pointer;
  }

  .calendar-arrow-left {
    border: solid #ffffff;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
  }

  .calendar-arrow-right {
    border: solid #ffffff;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }

  .loading {
    margin: 0 auto;
    margin-top: 10px;
    text-align: center;
  }

  .details-content {
    margin: 0;
    background: white;
    min-height: 100px;
    border-radius: 0 0 10px 10px;
  }

  .teacher-assign-row {
    display: flex;
    flex-direction: column
  }

  .teacher-assign {
    display: flex;
    align-items: center;
    padding: 10px 1px 10px 10px;
  }

  .line {
    border-bottom: 1px solid #E6E7ED;
  }

  .arrow-down {
    margin-left: auto;
    cursor: pointer;
  }

  .unassign-reason, .note-message {
    padding-left: 10px;
    padding-bottom: 0;
  }

  .unassign-reason p::first-letter, .cancel-reason p::first-letter, span.para::first-letter {
    text-transform: uppercase;
    padding-left: 35px;
  }

  .no-lesson-container {
    background: #4D88FF;
    border-radius: 0 0 10px 10px;
    color: white;
    text-align: center;
    padding-bottom: 20px;
    font-size: 17px;
    height: auto;
    min-height: 100px;
  }

  .space {
    padding: 0 0.1em;
  }

  .teacher-notes {
    display: flex;
    padding: 15px;
  }

  .cancel-section {
    display: flex;
    padding: 15px;
    cursor: pointer;
  }

  .lessob-box-container {
    display: flex;
    width: max-content;
  }

  p.absolute-content {
    padding: 0 10px;
    background: white;
  }

  p.absolute-content.grey {
    color: #C0C5D0;
  }

  .active {
    background: white;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 0;
  }

  .content-wrap {
    background: white;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    color: #4D88FF;
    display: inline-flex;
    text-align: center;
  }

  .circle-line {
    position: relative;
    display: block;
    z-index: 1;
    border: 1px solid #E2E4EC;
    border-width: 0 0 0 1px;
    width: 3px;
    height: 23px;
    bottom: 0;
    top: auto;
    left: 4%;
  }

  .circle-line::after {
    top: 95%;
    right: 0;
    left: -8px;
    content: "";
    height: 15px;
    width: 15px;
    border-radius: 50%;
    background: #E2E4EC;
    position: absolute;
  }

  .circle-with-content {
    display: flex;
  }

  .cancel-icon span {
    padding-left: 12px;
  }

  span.para {
    padding-top: 12px;
    color: #8C94A6;
  }

  .lesson-box > {
    display: inline-block;
    vertical-align: middle;
  }

  .title-padding {
    padding-left: 10px;
  }

  .cancel-reason {
    padding-left: 4px;
  }

  .relative-content {
    display: flex;
  }

  span.grey {
    color: #C0C5D0;
  }

  span.left {
    padding-right: 5px;
  }

  span.content-wrap {
    display: flex;
    flex-direction: column;
    padding: 6px;
  }

  .lesson-box > div {
    margin: 0 10px 0 0;
    width: auto;
  }

  .lesson-box > div:first-child {
    margin-left: 0;
  }

  span.relative-content {
    justify-content: center;
  }

  .dropdownMenu {
    position: absolute;
    top: 69px;
    bottom: 20px;
    left: 71px;
    right: 0;
    background: white;
    width: 215px;
    overflow-x: hidden;
    overflow-y: scroll;
    height: 100px;
    padding: 0;
    border-radius: 3px;
    box-shadow: 0px 3px 10px #888;
  }

  .dropdown-item li {
    cursor: pointer;
  }

  .currentSelected {
    background: rgb(228, 243, 255);
  }

  li.list-item {
    cursor: pointer;
    padding: 0 11px;
    margin-bottom: 10px;
  }

  li.list-item:active {
    color: black;
  }

  .fa-chevron-down {
    cursor: pointer;
    padding: 10px;
  }

  ul.dropdown-item {
    background: white;
    color: black;
    /*padding: 6px 12px;*/
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  li.list-item.currentSelected {
    padding: 10px;
  }

  .footer {
    max-height: 25px;
    background: #F5F6FA;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 7px 0;
  }

  .footer .footer-item {
    margin-left: 14px;
    height: 100%;

    display: flex;
    align-items: center;
  }

  .footer .footer-item .footer-item-icon {
    fill: #6F7785;
    height: 14px;
    margin-right: 8px;
  }

  .footer .footer-item .footer-item-text {
    color: #6F7785;
    font-size: 12px;
  }
}
</style>
