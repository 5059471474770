<template>
  <div>
    <div data-widgetversion="2.0.2">
      <dialog id="imgDialog">
        <img id="imgView" src="" @click="imgClose()" />
        <button @click="imgClose();" class="closebutton">Luk</button>
      </dialog>
      <dialog id="videoDialog">
        <video id="videoView" src="" controls disablepictureinpicture @click="videoClose()"></video>
        <button @click="videoClose();" class="closebutton">Luk</button>
      </dialog>
      <dialog id="audioDialog">
        <audio id="audioView" src="" controls @click="audioClose()"></audio>
        <button @click="audioClose();" class="closebutton">Luk</button>
      </dialog>
      <div class="p-2 d-flex align-items-center easyiq-wp-widgetheader">
        <div>UGEPLAN <span class="pl-1" v-if="weekPlanInfo != null && weekPlanInfo.group != null">{{weekPlanInfo.group.Name}}</span></div>
        <div class="pl-2">
          <div class="easyiq-wp-notificationbrand" v-if="hasNotifications">
            <b-button variant="outlined" ref="button" id="btnNotification">
              <b-img id="imgNotification" :src="baseSchoolPortalUrl + '/Images/24/notification.png'" @click="readNotifications()">
              </b-img>
            </b-button>
            <b-badge variant="danger" class="easyiq-wp-notificationbadge" v-if="notViewedNotifications > 0">
              {{notViewedNotifications}}
            </b-badge>

            <b-popover target="btnNotification" placement="bottomright" triggers="focus" v-if="hasNotifications">
              <div class="container" :style="getNotificationContentStyle()">
                <div class="row">
                  <div class="col no-gutter">
                    <div :style="getNotificationTopHeaderStyle()">
                      <strong>Notifikationer</strong>
                    </div>
                  </div>
                </div>
                <div class="row" v-if="hasNewNotifications" :style="getNotificationHeaderStyle()">
                  <div class="col">
                    Nye
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <div v-for="note in getNewNotifications()">
                      <div v-if="note.notificationtypeid == 1" :style="getNotificationStyle(note)">
                        <div class="container text-left">
                          <div class="row">
                            <div class="col">
                              <b-link target="_blank" :href="baseSchoolPortalUrl +'aula/authuser/' + ssoLinkUrlParams(baseSchoolPortalUrl + note.url)">
                                <p><strong>{{note.sender.fullname}}</strong> har oprettet et <strong>overblik</strong> til <strong>{{note.target.fullname}}</strong>.</p>
                              </b-link><br />
                            </div>
                          </div>
                          <div class="row">
                            <div class="col">
                              <img :src="baseSchoolPortalUrl + '/Images/16/clock.png'" id="notificationhistory" style="display:inline-block" />
                              <i class="far fa-history"></i>
                              <p style="margin:0;display:inline;"> {{getNotificationAgeText(note)}}</p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div v-if="note.notificationtypeid == 6" :style="getNotificationStyle(note)">
                        <div class="container text-left">
                          <div class="row">
                            <div class="col">
                              <b-link target="_blank" :href="baseSchoolPortalUrl +'aula/authuser/' + ssoLinkUrlParams(baseSchoolPortalUrl + note.url)">
                                <p style="margin:0;"><strong>{{note.sender.fullname}}</strong> har kommenteret <strong>{{note.target.fullname}}</strong>'s <strong>elevplan</strong>.</p>
                              </b-link>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col">
                              <img :src="baseSchoolPortalUrl + '/Images/16/clock.png'" id="notificationhistory" style="display:inline-block" />
                              <p style="margin:0;display:inline;"> {{getNotificationAgeText(note)}}</p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div v-else-if="note.notificationtypeid == 2" :style="getNotificationStyle(note)">
                        <div class="container text-left">
                          <div class="row">
                            <div class="col">
                              <strong>{{note.sender.fullname}}</strong> har oprettet en <strong>ugeplan</strong> for uge {{note.data[1]}} til <strong>{{note.target.fullname}}</strong>.
                            </div>
                          </div>
                          <div class="row">
                            <div class="col text-left">
                              <!--<img :src="baseSchoolPortalUrl + '/Images/16/clock.png'" id="notificationhistory" style="display:inline-block" />-->
                              <p style="margin:0;display:inline;"><i class="far fa-history"></i> {{getNotificationAgeText(note)}}</p>
                            </div>
                            <div class="col text-right">
                              <div role="link" @click="selectedWeekNr = note.data[0] + '-W' + note.data[1]">Gå til uge {{note.data[1]}}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row" v-if="hasNewNotifications && hasOldNotifications" :style="getNotificationHeaderStyle()">
                  <div class="col">
                    Tidligere
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <div v-for="note in getOldNotifications()">
                      <div v-if="note.notificationtypeid == 1" :style="getNotificationStyle(note)">
                        <div class="container text-left">
                          <div class="row">
                            <div class="col">
                              <b-link target="_blank" :href="baseSchoolPortalUrl +'aula/authuser/' + ssoLinkUrlParams(baseSchoolPortalUrl + note.url)">
                                <strong>{{note.sender.fullname}}</strong> har oprettet et <strong>overblik</strong> til {{note.target.fullname}}.
                              </b-link>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div v-if="note.notificationtypeid == 2" :style="getNotificationStyle(note)">
                        <div class="container text-left">
                          <div class="row">
                            <div class="col">
                              <strong>{{note.sender.fullname}}</strong> har oprettet en <strong>ugeplan</strong> for uge {{note.data[1]}} til <strong>{{note.target.fullname}}</strong>.

                            </div>
                          </div>
                          <div class="row">
                            <div class="col text-left">
                              <!--<img :src="baseSchoolPortalUrl + '/Images/16/clock.png'" id="notificationhistory" style="display:inline-block" />-->

                              <p style="margin:0;display:inline;"><i class="far fa-history"></i> {{getNotificationAgeText(note)}}</p>
                            </div>
                            <div class="col text-right">
                              <div role="link" @click="selectedWeekNr = note.data[0] + '-W' + note.data[1]">Gå til uge {{note.data[1]}}</div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div v-if="note.notificationtypeid == 3" :style="getNotificationStyle(note)">
                        <div class="container text-left">
                          <div class="row">
                            <div class="col">
                              <b-link target="_blank" :href="baseSchoolPortalUrl +'aula/authuser/' + ssoLinkUrlParams(baseSchoolPortalUrl + note.url)">
                                <p style="margin:0;"><strong>{{note.sender.fullname}}</strong> har kommenteret en <strong>elevplan</strong>.</p>
                              </b-link>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col">
                              <img :src="baseSchoolPortalUrl + '/Images/16/clock.png'" id="notificationhistory" style="display:inline-block" />
                              <p style="margin:0;display:inline;"> {{getNotificationAgeText(note)}}</p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div v-if="note.notificationtypeid == 6" :style="getNotificationStyle(note)">
                        <div class="container text-left">
                          <div class="row">
                            <div class="col">
                              <b-link target="_blank" :href="baseSchoolPortalUrl +'aula/authuser/' + ssoLinkUrlParams(baseSchoolPortalUrl + note.url)">
                                <p style="margin:0;"><strong>{{note.sender.fullname}}</strong> har kommenteret <strong>{{note.target.fullname}}</strong>'s <strong>elevplan</strong>.</p>
                              </b-link>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col">
                              <img :src="baseSchoolPortalUrl + '/Images/16/clock.png'" id="notificationhistory" style="display:inline-block" />
                              <p style="margin:0;display:inline;"> {{getNotificationAgeText(note)}}</p>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </b-popover>
          </div>
        </div>
        <div class="ml-auto easyiq-wp-classesDropdown">
          <b-dropdown text="Klasser" right v-if="weekPlanInfo.groups != null && userProfile.toLowerCase() == 'employee'"
                      toggle-class="p-1 m-0 "
                      size="sm" variant="outline">
            <b-dropdown-item v-for="group in weekPlanInfo.groups"
                             :key="group.Name"
                             :value="group.GroupId"
                             :active="group.GroupId == group"
                             @click="selectedGroup = group.GroupId">{{group.Name}}</b-dropdown-item>
          </b-dropdown>
          <b-dropdown text="Barn"
                      toggle-class="p-1 m-0"
                      size="sm" variant="outline"
                      v-if="userProfile.toLowerCase() == 'parent' || userProfile.toLowerCase() == 'guardian'" right>
            <b-dropdown-item v-for="child in childFilter"
                             :key="child"
                             :value="child"
                             @click="selectedChild = child">{{child}}</b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
      <div class="easyiq-wp-widgetsubheader easyiq-wp-classesDropdown">
        <div v-if="placement != 'narrow'">
          <template v-for="l in weekPlanInfo.links" v-if="(weekPlanInfo.links != null)">
            <b-link class="p-2 widget-toplink" v-if="l.ssoenable" :href="baseSchoolPortalUrl + 'aula/authuser/' + ssoLinkUrlParams(l.address)" target="_blank">{{l.name}}</b-link>
            <b-link class="p-2 widget-toplink" v-else :href="l.address" target="_blank">{{l.name}}</b-link>
          </template>
        </div>
        <div v-else>
          <b-dropdown text="Links" v-if="(weekPlanInfo.links != null)"
                      toggle-class="p-1 m-0 "
                      size="sm" variant="outline">
            <template v-for="l in weekPlanInfo.links" v-if="(weekPlanInfo.links != null)">
              <b-dropdown-item class="p-2 widget-toplink" v-if="l.ssoenable" :href="baseSchoolPortalUrl + 'aula/authuser/' + ssoLinkUrlParams(l.address)" target="_blank">{{l.name}}</b-dropdown-item>
              <b-dropdown-item class="p-2 widget-toplink" v-else :href="l.address" target="_blank">{{l.name}}</b-dropdown-item>
            </template>
          </b-dropdown>
        </div>
      </div>
    </div>

    <div v-if="weekPlanInfo.ErrorCode != null">
      <b-alert show variant="warning">{{weekPlanInfo.ErrorDescription}}</b-alert>
    </div>
    <div v-else>
      <div class="lds-css" v-if="fetchingData" style="align-content:center;height:5em;width:5em;">
        <div class="lds-ring"><div></div><div></div><div></div><div></div><div></div></div>
      </div>

      <div v-if="!fetchingData">
        <div class="easyiq-wp-weekinfocontainer">
          <div class="easyiq-wp-weekinfocontaineruser">
            <span v-if="userProfile.toLowerCase() == 'guardian'">{{displaySelectedChild}}</span>
          </div>
          <div class="easyiq-wp-weekinfocontainerlinks">
            <div class="easyiq-wp-todayweekcontainer">
              <div @click="selectedWeekNr = weekPlanInfo.weekinfo.todaysweeknr" role="link">I dag</div>
            </div>
            <div>
              <div @click="selectedWeekNr = weekPlanInfo.weekinfo.prevweeknr" role="link"><i class="fal fa-chevron-circle-left easyiq-wp-arrowcirle"></i></div>
            </div>
            <div class="easyiq-wp-currentweekcontainer"><span v-if="weekPlanInfo.weekinfo != null">{{weekPlanInfo.weekinfo.currentweeknr}}</span></div>
            <div>
              <div @click="selectedWeekNr = weekPlanInfo.weekinfo.nextweeknr" role="link"><i class="fal fa-chevron-circle-right easyiq-wp-arrowcirle"></i></div>
            </div>
          </div>
        </div>



        <div v-if="this.full">

          <!-- Beskrivelse -->

          <div v-if="(weekPlanInfo.WeekPlan != null && weekPlanInfo.WeekPlan.Text != null)" class="EasyIQCard">
            <div class="EasyIQCardHeader">
              Beskrivelse
            </div>
            <div class="EasyIQCardBody">
              <widget-html :html="weekPlanInfo.WeekPlan.Text"></widget-html>
            </div>
          </div>

          <div v-if="(weekPlanInfo.otherweekplans != null && weekPlanInfo.otherweekplans.length)" v-for="otherWeekplan in weekPlanInfo.otherweekplans" class="EasyIQCard">
            <div class="EasyIQCardHeader">
              <widget-html :html="otherWeekplan.ActivityName"></widget-html>
            </div>
            <div class="EasyIQCardBody">
              <widget-html class="p-1" :html="otherWeekplan.Text"></widget-html>
            </div>
          </div>

          <!-- Ferie -->

          <div v-if="eventsByType(6).length" data-wptype="6" class="EasyIQCard wp-weekcontainer mt-3">
            <div class="EasyIQCardHeader">
              <div class="EasyIQCardHeaderExtra mb-4">
                <div class="wp-weekdays">
                  <div :data-dayofweek="i-1" v-for="i in 5">
                    {{dateToFormat(addDay(weekPlanInfo.fromDate,(i-1)))}}
                  </div>
                </div>
              </div>
              Ferier og begivenheder
            </div>
            <div class="EasyIQCardBody">
              <div data-wpplans>
                <div v-for="(dayEvent, index) in eventsByType(6).sort(this.planSort)" :class="getPlanCssClasses2(dayEvent)" :style="getPlanStyles2(dayEvent)">
                  <div class="wp-day-planbackground"></div>

                  <div class="wp-day-plancontainer" data-plan="">
                    <div class="PlanIcon2">
                      <div class="Icon48"></div>
                      <div class="Icon48" :style="getBackgroundImage(dayEvent)"></div>
                    </div>
                    <div class="CutText" style="padding:0 1rem;">
                      {{dayEvent.title}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Vigtig information -->

          <div data-flatbottom data-wptype="8" class="EasyIQCard wp-weekcontainer mt-3">
            <div class="EasyIQCardHeader">
              <div class="EasyIQCardHeaderExtra mb-4">
                <div class="wp-weekdays">
                  <div :data-dayofweek="i-1" v-for="i in 5">
                    {{dateToFormat(addDay(weekPlanInfo.fromDate,(i-1)))}}
                  </div>
                </div>
              </div>
              Vigtig information
            </div>
            <div class="EasyIQCardBody FlexOnly wp-eventContainer">
              <div :data-dayofweek="i-1" v-for="i in 5">
                <div data-dayheader></div>
                <div data-events>
                  <div v-for="dayEvent in eventsByDateAndType(i, 8)" data-itemeventype="8" class="wp-dayassignmentevent" :style="getEventStyles(dayEvent)">
                    <div data-text>
                      <div class="wp-dayassignmentevent-header">
                        <div class="Flex">
                          <div class="Flex11" title="">{{dayEvent.courses}}</div>
                          <div class="Flex11 FlexChildRight" :title="dayEvent.activities" style="text-align:right;">{{dayEvent.activities}}</div>
                        </div>
                      </div>
                      <div class="wp-day-plan-title">
                        <input :id="showMoreId(dayEvent.itemid)" type="checkbox" class="showmore" />
                        <label :for="showMoreId(dayEvent.itemid)" class="showmorecontent">
                          <widget-html :html="dayEvent.description"></widget-html>
                        </label>
                        <span class="showmorecontrols"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Ugeplan -->

          <div data-flattop data-flatbottom data-wptype="9" class="EasyIQCard wp-weekcontainer">
            <div class="EasyIQCardHeader">
              Ugeplan
            </div>
            <div class="EasyIQCardBody FlexOnly wp-eventContainer">
              <div :data-dayofweek="i-1" v-for="i in 5">
                <div data-dayheader></div>
                <div data-events>
                  <div v-for="dayEvent in eventsByDateAndType(i, 9)" class="wp-dayassignmentevent" data-itemeventype="9" :style="getEventStyles(dayEvent)">
                    <div data-text>
                      <div class="wp-dayassignmentevent-header">
                        <div class="Flex">
                          <div class="Flex11" title="">{{dayEvent.courses}}</div>
                          <div class="Flex11 FlexChildRight" :title="dayEvent.activities" style="text-align:right;">{{dayEvent.activities}}</div>
                        </div>
                      </div>
                      <div class="timeinfo">
                        <img :src="baseSchoolPortalUrl + 'Images/svg/clock.svg'" class="Icon24 mr-1" />
                        <div>{{dateToTimeFormat(dayEvent.start)}}</div>
                        &nbsp;-&nbsp;
                        <div>{{dateToTimeFormat(dayEvent.end)}}</div>
                      </div>
                      <div class="wp-day-plan-title">
                        <input :id="showMoreId(dayEvent.itemid)" type="checkbox" class="showmore" />
                        <label :for="showMoreId(dayEvent.itemid)" class="showmorecontent">
                          <widget-html :html="dayEvent.description"></widget-html>
                        </label>
                        <span class="showmorecontrols"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Forberedelse -->
          <!-- Forberedelse/lektie = 4, Opgave = 1 -->

          <div data-flattop data-wptype="8" class="EasyIQCard wp-weekcontainer">
            <div class="EasyIQCardHeader">
              Forberedelse
            </div>
            <div class="EasyIQCardBody FlexOnly wp-eventContainer">
              <div :data-dayofweek="i-1" v-for="i in 5">
                <div data-dayheader></div>
                <div data-events>
                  <div v-for="dayEvent in eventsByDateAndType(i, 4)" class="wp-dayassignmentevent" data-itemeventype="4" :style="getEventStyles(dayEvent)">
                    <div data-text>
                      <div class="wp-dayassignmentevent-header">
                        <div class="Flex">
                          <div class="Flex11" title="">{{dayEvent.courses}}</div>
                          <div class="Flex11 FlexChildRight" :title="dayEvent.activities" style="text-align:right;">{{dayEvent.activities}}</div>
                        </div>
                      </div>
                      <p class="wp-day-plan-title">
                        <input :id="showMoreId(dayEvent.itemid)" type="checkbox" class="showmore" />
                        <label :for="showMoreId(dayEvent.itemid)" class="showmorecontent">
                          <widget-html :html="dayEvent.description"></widget-html>
                        </label>
                        <span class="showmorecontrols"></span>
                      </p>
                    </div>
                  </div>

                  <div v-for="dayEvent in eventsByDateAndType(i, 1)" class="wp-dayassignmentevent" data-itemeventype="1" :style="getEventStyles(dayEvent)">
                    <div data-text>
                      <div class="wp-dayassignmentevent-header">
                        <div class="Flex">
                          <div class="Flex11" title="">{{dayEvent.courses}}</div>
                          <div class="Flex11 FlexChildRight" :title="dayEvent.activities" style="text-align:right;">{{dayEvent.activities}}</div>
                        </div>
                      </div>
                      <p class="wp-day-plan-title" style="margin-top:1rem;">
                        <a v-if="dayEvent != null && dayEvent.itemurl != ''" :href="baseSchoolPortalUrl +'aula/authuser/' + ssoLinkUrlParams(dayEvent.itemurl)" target="_blank">
                          <widget-html :html="dayEvent.title"></widget-html>
                        </a>
                        <span v-else>
                                                    <widget-html :html="dayEvent.title"></widget-html>
                                                </span>
                      </p>
                      <div class="wp-dayassignmentevent-plantitles Flex">
                        <img :src="baseSchoolPortalUrl + dayEvent.icon.replace('~','')" class="wp-plan-icon" />
                        {{dayEvent.plans[0].title}}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Forløb -->

          <div v-if="plansIsVisible" class="EasyIQCard wp-weekcontainer mt-3">
            <div class="EasyIQCardHeader">
              <div class="EasyIQCardHeaderExtra mb-4">
                <div class="wp-weekdays">
                  <div :data-dayofweek="i-1" v-for="i in 5">
                    {{dateToFormat(addDay(weekPlanInfo.fromDate,(i-1)))}}
                  </div>
                </div>
              </div>
              Forløb
            </div>
            <div class="EasyIQCardBody">
              <div data-wpplans>
                <a v-for="(dayEvent, index) in eventsByType(5).sort(this.planSort)" :href="baseSchoolPortalUrl +'aula/authuser/' + ssoLinkUrlParams(dayEvent.itemurl)" target="_blank" :class="getPlanCssClasses2(dayEvent)" :style="getPlanStyles2(dayEvent)">
                  <div class="wp-day-planbackground"></div>

                  <div class="wp-day-plancontainer" data-plan="">
                    <div class="PlanIcon2">
                      <div class="Icon48"></div>
                      <div class="Icon48" :style="getBackgroundImage(dayEvent)"></div>
                    </div>
                    <div class="CutText" style="padding:0 1rem;">
                      {{dayEvent.title}} ({{dayEvent.courses}})
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>



        <!-- Narrow -->

        <div v-if="!this.full">

          <div v-if="weekPlanInfo.WeekPlan != null && weekPlanInfo.WeekPlan.Text != null" class="EasyIQCard">
            <div class="EasyIQCardHeader">
              Beskrivelse
            </div>
            <div class="EasyIQCardBody">
              <widget-html class="p-1" :html="weekPlanInfo.WeekPlan.Text"></widget-html>
            </div>
          </div>

          <div v-if="(weekPlanInfo.otherweekplans != null && weekPlanInfo.otherweekplans.length)" v-for="otherWeekplan in weekPlanInfo.otherweekplans" class="EasyIQCard">
            <div class="EasyIQCardHeader">
              <widget-html :html="otherWeekplan.ActivityName"></widget-html>
            </div>
            <div class="EasyIQCardBody">
              <widget-html class="p-1" :html="otherWeekplan.Text"></widget-html>
            </div>
          </div>



          <div v-for="i in 5">
            <div class="EasyIQCard mt-3">
              <div class="EasyIQCardHeader">
                {{dateToFormat(addDay(weekPlanInfo.fromDate,(i-1)))}}
              </div>

              <div class="EasyIQCardBody">

                <!-- Vigtig information -->

                <div v-if="eventsByDateAndType(i, 8).length" class="narrowHeader">
                  Vigtig information
                </div>
                <div v-if="eventsByDateAndType(i, 8).length" class="eiqContent">
                  <div v-for="dayEvent in eventsByDateAndType(i, 8)" class="wp-dayassignmentevent" data-itemeventype="8" :style="getEventStyles(dayEvent)">
                    <div data-text>
                      <div class="wp-dayassignmentevent-header">
                        <div class="Flex">
                          <div class="Flex11" title="">{{dayEvent.courses}}</div>
                          <div class="Flex11 FlexChildRight" :title="dayEvent.activities" style="text-align:right;">{{dayEvent.activities}}</div>
                        </div>
                      </div>
                      <div class="wp-day-plan-title">
                        <input :id="showMoreId(dayEvent.itemid)" type="checkbox" class="showmore" />
                        <label :for="showMoreId(dayEvent.itemid)" class="showmorecontent">
                          <widget-html :html="dayEvent.description"></widget-html>
                        </label>
                        <span class="showmorecontrols"></span>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Ugeplan -->

                <div v-if="eventsByDateAndType(i, 9).length" class="narrowHeader">
                  Ugeplan
                </div>
                <div v-if="eventsByDateAndType(i, 9).length" class="eiqContent">
                  <div v-for="dayEvent in eventsByDateAndType(i, 9)" class="wp-dayassignmentevent" data-itemeventype="9" :style="getEventStyles(dayEvent)">
                    <div data-text>
                      <div class="wp-dayassignmentevent-header">
                        <div class="Flex">
                          <div class="Flex11" title="">{{dayEvent.courses}}</div>
                          <div class="Flex11 FlexChildRight" :title="dayEvent.activities" style="text-align:right;">{{dayEvent.activities}}</div>
                        </div>
                      </div>
                      <div class="timeinfo">
                        <img :src="baseSchoolPortalUrl + 'Images/svg/clock.svg'" class="Icon24 mr-1" />
                        <div>{{dateToTimeFormat(dayEvent.start)}}</div>
                        &nbsp;-&nbsp;
                        <div>{{dateToTimeFormat(dayEvent.end)}}</div>
                      </div>
                      <div class="wp-day-plan-title">
                        <input :id="showMoreId(dayEvent.itemid)" type="checkbox" class="showmore" />
                        <label :for="showMoreId(dayEvent.itemid)" class="showmorecontent">
                          <widget-html :html="dayEvent.description"></widget-html>
                        </label>
                        <span class="showmorecontrols"></span>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Forberedelse -->
                <!-- Forberedelse/lektie = 4, Opgave = 1 -->

                <div v-if="eventsByDateAndType(i, 4).length || eventsByDateAndType(i, 1).length" class="narrowHeader">
                  Forberedelse
                </div>
                <div v-for="dayEvent in eventsByDateAndType(i, 4)" class="wp-dayassignmentevent" data-itemeventype="4" :style="getEventStyles(dayEvent)">
                  <div data-text>
                    <div class="wp-dayassignmentevent-header">
                      <div class="Flex">
                        <div class="Flex11" title="">{{dayEvent.courses}}</div>
                        <div class="Flex11 FlexChildRight" :title="dayEvent.activities" style="text-align:right;">{{dayEvent.activities}}</div>
                      </div>
                    </div>
                    <p class="wp-day-plan-title">
                      <input :id="showMoreId(dayEvent.itemid)" type="checkbox" class="showmore" />
                      <label :for="showMoreId(dayEvent.itemid)" class="showmorecontent">
                        <widget-html :html="dayEvent.description"></widget-html>
                      </label>
                      <span class="showmorecontrols"></span>
                    </p>
                  </div>
                </div>

                <div v-for="dayEvent in eventsByDateAndType(i, 1)" class="wp-dayassignmentevent" data-itemeventype="1" :style="getEventStyles(dayEvent)">
                  <div data-text>
                    <div class="wp-dayassignmentevent-header">
                      <div class="Flex">
                        <div class="Flex11" title="">{{dayEvent.courses}}</div>
                        <div class="Flex11 FlexChildRight" :title="dayEvent.activities" style="text-align:right;">{{dayEvent.activities}}</div>
                      </div>
                    </div>
                    <p class="wp-day-plan-title" style="margin-top:0.5rem;">
                      <a v-if="dayEvent != null && dayEvent.itemurl != ''" :href="baseSchoolPortalUrl +'aula/authuser/' + ssoLinkUrlParams(dayEvent.itemurl)" target="_blank">
                        <widget-html :html="dayEvent.title"></widget-html>
                      </a>
                      <span v-else>
                                                <widget-html :html="dayEvent.title"></widget-html>
                                            </span>
                    </p>
                    <div class="wp-dayassignmentevent-plantitles Flex">
                      <img :src="baseSchoolPortalUrl + dayEvent.icon.replace('~','')" class="wp-plan-icon" />
                      {{dayEvent.plans[0].title}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Forløb -->

          <div v-if="plansIsVisible" class="EasyIQCard wp-weekcontainer mt-3">
            <div class="EasyIQCardHeader">
              <div class="EasyIQCardHeaderExtra mb-4">
                <div class="wp-weekdays">
                  <div :data-dayofweek="i-1" v-for="i in 5">
                    {{dateToFormat(addDay(weekPlanInfo.fromDate,(i-1)))}}
                  </div>
                </div>
              </div>
              Forløb
            </div>
            <div class="EasyIQCardBody">
              <div data-wpplans>
                <div v-for="dayEvent in eventsByType(6)" :title="dayEvent.title" :class="getPlanCssClasses2(dayEvent)" :style="getPlanStyles2(dayEvent)">
                  <div class="wp-day-planbackground"></div>

                  <div class="wp-day-plancontainer" data-plan="">
                    <div class="PlanIcon2">
                      <div class="Icon48"></div>
                      <div class="Icon48" :style="getBackgroundImage(dayEvent)"></div>
                    </div>
                    <div class="CutText" style="padding:0 1rem;">
                      {{dayEvent.title}}
                    </div>
                  </div>
                </div>
                <a v-for="(dayEvent, index) in eventsByType(5)" :href="baseSchoolPortalUrl +'aula/authuser/' + ssoLinkUrlParams(dayEvent.itemurl)" target="_blank" :title="dayEvent.title" :class="getPlanCssClasses2(dayEvent)" :style="getPlanStyles2(dayEvent)">
                  <div class="wp-day-planbackground"></div>

                  <div class="wp-day-plancontainer" data-plan="">
                    <div class="PlanIcon2">
                      <div class="Icon48"></div>
                      <div class="Icon48" :style="getBackgroundImage(dayEvent)"></div>
                    </div>
                    <div class="CutText" style="padding:0 1rem;">
                      {{dayEvent.title}} <span v-if="dayEvent.courses != null && dayEvent.courses != ''">({{dayEvent.courses}})</span>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-info" style="min-height:20px;">

    </div>
  </div>
</template>
<script>
//export default  {
export default {
  props: {
    /* normal and secure */
    axios: Function,
    sessionUUID: String,
    placement: String,
    currentWeekNumber: String,
    isMobileApp: Boolean,
    /* only secure */
    getAulaToken: Function,
    assuranceLevel: Number,
    childFilter: Array,
    group: String,
    //userProfile: String,
    userProfile: {
      type: String,
      default: "employee"
    },
    institutionFilter: Array,
    full: Boolean
  },
  data() {
    return {
      fullSize: this.full,
      aulaToken: null,
      user: {},
      errors: [],
      weekPlanInfo: {},
      monthNames: ["Januar", "Februar", "Marts", "April", "Maj", "Juni", "Juli", "August", "September", "Oktober", "November", "December"],
      shortMonthNames: ["jan", "feb", "mar", "apr", "maj", "jun", "jul", "aug", "sep", "okt", "nov", "dec"],
      dayNames: ["Søndag", "Mandag", "Tirsdag", "Onsdag", "Torsdag", "Fredag", "Lørdag"],
      selectedGroup: "",
      fetchingData: false,
      selectedWeekNr: "",
      selectedChild: "",
      notificationListIsVisible: false,
      baseApiAddress: 'https://api.easyiqcloud.dk/api/aula/',
      planSort: function ( a, b ) {
        return ( a.title > b.title ) ? 1 : -1;
      },
      countEvents: function ( typeId ) {
        var countResult = this.weekPlanInfo.Events.filter( ci => ci.itemType == typeId );
        return countResult.length;
      },
      ssoLinkUrlParams: function (link) {
        try {
          var baseParam = "?token=" + this.aulaToken;
          baseParam += "&userProfile=" + this.userProfile;
          baseParam += "&institutionFilter=" + this.institutionFilter.join(",");
          baseParam += "&ssoLink=" + decodeURIComponent(link);
          baseParam += `&selectedChild=${this.displaySelectedChild}`;
          return baseParam;
        }
        catch {
          return "";
        }
      },
      eventsByType: function ( typeId ) {
        switch ( typeId ) {
          case 2: {
            var eventTypeEvents = new Array();
            var assignments = this.weekPlanInfo.Events.filter( ci => ci.itemType == 4 );
            var assignments2 = this.weekPlanInfo.Events.filter( ci => ci.itemType == 1 );
            var homework = this.weekPlanInfo.Events.filter( ci => ci.itemType == 2 );

            if ( homework.length > 0 ) {
              for ( var i in homework ) {
                eventTypeEvents.push( homework[i] );
              }
            }

            if ( assignments.length > 0 ) {
              for ( var i in assignments ) {
                eventTypeEvents.push( assignments[i] );
              }
            }

            if ( assignments2.length > 0 ) {
              for ( var i in assignments2 ) {
                eventTypeEvents.push( assignments2[i] );
              }
            }
            return eventTypeEvents;
            break;
          }
          default: {
            var eventTypeEvents = new Array();
            return this.weekPlanInfo.Events.filter( ci => ci.itemType == typeId );
            break;
          }
        }
      },
      eventsByDateAndType: function ( dayOfWeek, typeId ) {
        switch ( typeId ) {
          case 2: {
            var eventTypeEvents = new Array();
            var assignments = this.weekPlanInfo.Events.filter( ci => ci.itemType == 4 && new Date( ci.start ).getDay() == dayOfWeek );
            var assignments2 = this.weekPlanInfo.Events.filter(ci => ci.itemType == 1 && new Date(ci.start).getDay() == dayOfWeek);
            var assignments3 = this.weekPlanInfo.Events.filter( ci => ci.itemType == 3 && new Date( ci.start ).getDay() == dayOfWeek );
            var homework = this.weekPlanInfo.Events.filter( ci => ci.itemType == 2 && new Date( ci.start ).getDay() == dayOfWeek );

            if ( homework.length > 0 ) {
              for ( var i in homework ) {
                eventTypeEvents.push( homework[i] );
              }
            }

            if ( assignments.length > 0 ) {
              for ( var i in assignments ) {
                eventTypeEvents.push( assignments[i] );
              }
            }

            if ( assignments2.length > 0 ) {
              for ( var i in assignments2 ) {
                eventTypeEvents.push( assignments2[i] );
              }
            }
            if ( assignments3.length > 0 ) {
              for ( var i in assignments3 ) {
                eventTypeEvents.push( assignments3[i] );
              }
            }
            return eventTypeEvents;
            break;
          }
          default: {
            var eventTypeEvents = new Array();
            return this.weekPlanInfo.Events.filter( ci => ci.itemType == typeId && new Date( ci.start ).getDay() == dayOfWeek );
            break;
          }
        }
      },
      dateToFormat: function (date) {
        var day = date.getDate();

        return this.dayNames[date.getDay()] + " " + day + ". " + this.shortMonthNames[date.getMonth()];
      },
      dateToTimeFormat: function (date) {
        var dDate = new Date(date);
        var hours = dDate.getHours();
        var minutes = dDate.getMinutes();

        return (hours < 10 ? "0" + hours : hours) + ":" + (minutes < 10 ? "0" + minutes : minutes);
      },
      addDay: function ( date, days ) {
        var dat = new Date( date );
        dat.setDate( dat.getDate() + days );
        return dat;
      },
      dateOnlyCompare: function ( a,b ) {
        var today = new Date( a.getFullYear(), a.getMonth(), a.getDate() );
        var bDateOnly = new Date( b.getFullYear(), b.getMonth(), b.getDate() );

        if ( today > bDateOnly ) { return 1; }
        else if ( today < bDateOnly ) { return -1; }
        else return 0;
      },
      getWeekDaysClasses: function () {
        var cssClasses = new Array();
        return cssClasses;
      },
      getResponsiveEventClasses: function () {
        var cssClasses = new Array();
        if (this.placement != "narrow") {
          cssClasses.push("d-lg-block d-xl-none");
        }
        return cssClasses;
      },
      getPlanCssClasses2: function (planEvent, index) {
        var cssClasses = new Array();
        cssClasses.push("wp-day-plancontainer2");

        var firstDay = new Date(this.weekPlanInfo.fromDate);
        var lastDay = new Date(this.weekPlanInfo.toDate);
        var dayStart = new Date(planEvent.start);
        var dayEnd = new Date(planEvent.end);

        if (this.dateOnlyCompare(dayStart, firstDay) >= 0) {
          cssClasses.push("wp-day-plancontainer-showstart");
        }
        if (this.dateOnlyCompare(dayEnd, lastDay) < 0) {
          cssClasses.push("wp-day-plancontainer-showend");
        }

        if (this.userProfile.toLowerCase() == 'employee' && planEvent.itemType != 6 && !planEvent.canEdit) {
          cssClasses.push("wp-day-plan-notowner");
        }

        return cssClasses;
      },
      getPlanStyles2: function (planEvent, index) {
        var firstDay = new Date(this.weekPlanInfo.fromDate);
        var lastDay = new Date(this.weekPlanInfo.toDate);
        var dayStart = new Date(planEvent.start);
        var dayEnd = new Date(planEvent.end);
        var dayStartIndex = 0;
        var dayEndIndex = 5;

        if (this.dateOnlyCompare(dayStart, firstDay) >= 0) {
          dayStartIndex = dayStart.getDay() - 1;
        }
        if (this.dateOnlyCompare(dayEnd, lastDay) < 0) {
          dayEndIndex = dayEnd.getDay();
        }

        return {
          "margin-left": dayStartIndex * 20 + "%",
          "width": (dayEndIndex - dayStartIndex) * 20 + "%",
          "--plancolor": this.setOpacity(planEvent.color, 0.5),
          "--plancolor2": this.setOpacity(planEvent.color, 0.25),
          "--plancolor3": this.setOpacity(planEvent.color, 0.3)
        };
      },
      getEventStyles: function (dayEvent) {
        if (dayEvent.color != null && dayEvent.color != "") {
          return {
            "--eventcolor": this.setOpacity(dayEvent.color, 0.5)
          }
        };
      },
      getBackgroundImage: function (planEvent) {
        let path;
        if (planEvent.icon.startsWith("~")) {
          path = this.baseSchoolPortalUrl + planEvent.icon.replace("~", "").replaceAll("\\", "/");
        }
        else {
          path = planEvent.icon;
        }
        return {
          "background-image": `url(${path})`
        };
      },
      getNotificationStyle: function ( n ) {
        return {
          "background-color": ( n.viewed ? "#FFF" : "aliceblue"),
          "font-size": "0.8em",
          "margin": "1px 0",
          "padding": "2px"
        };
      },
      getNotificationAgeText: function ( n ) {
        var hour = 60;
        var day = hour * 24;
        var week = day * 7;
        var ageInMinutes = Math.round( ( ( new Date() - new Date( n.timestamp ) ).valueOf() / 60000 ) );
        if ( ageInMinutes < 3 ) {
          return "Lige nu";
        }
        else if ( ageInMinutes >= hour && ageInMinutes < day )
        {
          var hours = Math.round(ageInMinutes / hour);
          return hours + ( ( hours > 1 ) ? ' timer' : ' time');
        }
        else if ( ageInMinutes >= day && ageInMinutes < week )
        {
          var days = Math.round(ageInMinutes / day);
          return days + ( ( days > 1 ) ? ' dage' : ' dag' );
        }
        else if ( ageInMinutes >= week) {
          var weeks = Math.round( ageInMinutes / week );
          return weeks + ( ( weeks > 1 ) ? ' uger' : ' uge' );
        }
        else {
          return ageInMinutes + " minutter";
        }
      },
      getNotificationHeaderStyle: function () {
        return {
          "background-color": "transparent",
          "font-size": "1em",
          "padding": "5px 10px",
          "color": "#12798c"
        };
      },
      getNotificationTopHeaderStyle: function () {
        return {
          "background-color": "#FFF",
          "font-size": "1em",
          "padding": "5px 10px",
          "color": "#12798c"
        };
      },
      getNotificationContentStyle: function () {
        return {
          "background-color": "#EEE",
          "padding":"2px"
        };
      },
      getNewNotifications: function () {
        if ( this.hasNotifications ) {
          var now = new Date();
          var nList = this.weekPlanInfo.notifications.notificationlist.filter( n => ( now - new Date( n.timestamp ) ).valueOf() < ( 3600 * 1000 ) );
          return nList;
        }
        return null;
      },
      getOldNotifications: function () {
        if ( this.hasNotifications ) {
          var now = new Date();
          var nList = this.weekPlanInfo.notifications.notificationlist.filter( n => ( now - new Date( n.timestamp ) ).valueOf() > ( 3600 * 1000 ) );
          return nList;
        }
        return null;
      },
      rgbToHex: function (rgb) {
        // Choose correct separator
        let sep = rgb.indexOf(",") > -1 ? "," : " ";
        // Turn "rgb(r,g,b)" into [r,g,b]
        rgb = rgb.substr(4).split(")")[0].split(sep);

        let r = (+rgb[0]).toString(16),
          g = (+rgb[1]).toString(16),
          b = (+rgb[2]).toString(16);

        if (r.length == 1)
          r = "0" + r;
        if (g.length == 1)
          g = "0" + g;
        if (b.length == 1)
          b = "0" + b;

        return "#" + r + g + b;
      },
      setOpacity: function (color, opacity) {
        if (color.indexOf("rgb") != -1) {
          color = this.rgbToHex(color);
        }
        return color + Math.floor(opacity * 255).toString(16);
      },
      shadeBlendConvert: function ( p, from, to ) {
        if ( typeof ( p ) != "number" || p < -1 || p > 1 || typeof ( from ) != "string" || ( from[0] != 'r' && from[0] != '#' ) || ( to && typeof ( to ) != "string" ) ) return null; //ErrorCheck
        if ( !this.sbcRip ) {
          this.sbcRip = function ( d ) {
            var l = d.length;
            var RGB = {};
            if ( l > 9 ) {
              d = d.split( "," );
              if ( d.length < 3 || d.length > 4 ) return null;//ErrorCheck
              RGB[0] = i( d[0].split( "(" )[1] ), RGB[1] = i( d[1] ), RGB[2] = i( d[2] ), RGB[3] = d[3] ? parseFloat( d[3] ) : -1;
            } else {
              if ( l == 8 || l == 6 || l < 4 ) return null; //ErrorCheck
              if ( l < 6 ) d = "#" + d[1] + d[1] + d[2] + d[2] + d[3] + d[3] + ( l > 4 ? d[4] + "" + d[4] : "" ); //3 or 4 digit
              d = i( d.slice( 1 ), 16 ), RGB[0] = d >> 16 & 255, RGB[1] = d >> 8 & 255, RGB[2] = d & 255, RGB[3] = -1;
              if ( l == 9 || l == 5 ) RGB[3] = r( ( RGB[2] / 255 ) * 10000 ) / 10000, RGB[2] = RGB[1], RGB[1] = RGB[0], RGB[0] = d >> 24 & 255;
            }
            return RGB;
          }
        }
        var i = parseInt, r = Math.round, h = from.length > 9, h = typeof ( to ) == "string" ? to.length > 9 ? true : to == "c" ? !h : false : h, b = p < 0, p = b ? p * -1 : p, to = to && to != "c" ? to : b ? "#000000" : "#FFFFFF", f = this.sbcRip( from ), t = this.sbcRip( to );
        if ( !f || !t ) return null; //ErrorCheck
        if ( h ) return "rgb" + ( f[3] > -1 || t[3] > -1 ? "a(" : "(" ) + r( ( t[0] - f[0] ) * p + f[0] ) + "," + r( ( t[1] - f[1] ) * p + f[1] ) + "," + r( ( t[2] - f[2] ) * p + f[2] ) + ( f[3] < 0 && t[3] < 0 ? ")" : "," + ( f[3] > -1 && t[3] > -1 ? r( ( ( t[3] - f[3] ) * p + f[3] ) * 10000 ) / 10000 : t[3] < 0 ? f[3] : t[3] ) + ")" );
        else return "#" + ( 0x100000000 + r( ( t[0] - f[0] ) * p + f[0] ) * 0x1000000 + r( ( t[1] - f[1] ) * p + f[1] ) * 0x10000 + r( ( t[2] - f[2] ) * p + f[2] ) * 0x100 + ( f[3] > -1 && t[3] > -1 ? r( ( ( t[3] - f[3] ) * p + f[3] ) * 255 ) : t[3] > -1 ? r( t[3] * 255 ) : f[3] > -1 ? r( f[3] * 255 ) : 255 ) ).toString( 16 ).slice( 1, f[3] > -1 || t[3] > -1 ? undefined : -2 );
      },
      initWidget: function () {
        this.fetchingData = true;
        if (this.isLocalhost) {
          //this.placement = "narrow";
          this.weekPlanInfo = window.input;
          this.fetchingData = false;
        }
        else {
          this.axios.post(
            this.baseApiAddress + 'weekplaninfo',
            this.aulaFilter,
            {
              headers:
                {
                  'Authorization': 'bearer ' + this.aulaToken,
                  'x-aula-userprofile': this.userProfile,
                  'x-aula-institutionfilter': this.institutionFilter,
                  'Content-Type': 'application/json'
                }
            }
          )
            .then( response => {
              this.fetchingData = false;
              this.weekPlanInfo = response.data;
            } );
          this.errors.push( { message: "henter data2" } );
        }
      },
      readNotifications: function () {
        this.axios.post(
          this.baseApiAddress + 'notifications/updateviewed',
          this.aulaFilter,
          {
            headers:
              {
                'Authorization': 'bearer ' + this.aulaToken,
                'x-aula-userprofile': this.userProfile,
                'x-aula-institutionfilter': this.institutionFilter,
                'Content-Type': 'application/json'
              }
          })
          .then( response => {
              if ( this.weekPlanInfo != null && this.weekPlanInfo.notifications != null ) {
                if ( this.weekPlanInfo.notifications.notificationlist != null && this.weekPlanInfo.notifications.notificationlist.length > 0 ) {
                  for ( var i = 0; i < this.weekPlanInfo.notifications.notificationlist.length; i++ ) {
                    if ( this.weekPlanInfo.notifications.notificationlist[i] ) {
                      this.weekPlanInfo.notifications.notificationlist[i].read = true;
                    }
                  }
                  this.$emit( 'updateUnread', this.notViewedNotifications );
                }
              }
            }
          );
      },
      listNotifications: function () {
        if ( this.weekPlanInfo != null ) {
          this.axios.post(
            this.baseApiAddress + 'notifications/list',
            this.aulaFilter,
            {
              headers:
                {
                  'Authorization': 'bearer ' + this.aulaToken,
                  'x-aula-userprofile': this.userProfile,
                  'x-aula-institutionfilter': this.institutionFilter,
                  'Content-Type': 'application/json'
                }
            } )
            .then( response => {
              this.weekPlanInfo.notifications = response.data;
              this.$emit( 'updateUnread', this.notViewedNotifications );
            } );
        }
      },
      showMoreId: function (id) {
        return `input-${id}`;
      },
      imgClose: function () {
        this.$el.getElementById("imgDialog").close();
        this.$el.getElementById("imgView").src = "";
      },
      videoClose: function () {
        this.$el.getElementById("videoDialog").close();
        this.$el.getElementById("videoView").src = "";
      },
      audioClose: function () {
        this.$el.getElementById("audioDialog").close();
        this.$el.getElementById("audioView").src = "";
      }
    }
  },
  computed: {
    aulaFilter: function () {
      return {
        sessionId: this.sessionUUID,
        group: this.selectedGroup != "" ? this.selectedGroup : this.group,
        currentWeekNr: this.selectedWeekNr != "" ? this.selectedWeekNr : this.currentWeekNumber,
        userProfile: this.userProfile,
        institutionFilter: this.institutionFilter,
        childFilter: this.selectedChild != "" ? [this.selectedChild.trim()] : this.childFilter
      }
    },
    selectedGroupName: function ()
    {
      if ( this.group) {
        if ( this.weekPlanInfo.groups != null ) {
          return this.weekPlanInfo.groups.filter( g => g.GroupId == this.group ).Name;
        }
      }
      return "";
    },
    displaySelectedChild: function () {
      return this.selectedChild != "" ? this.selectedChild.trim() : this.childFilter[0];
    },
    isLocalhost: function () {
      return window.location.href.indexOf("localhost") != -1;
    },
    isWidgetTest: function () {
      if ( window.origin ) {
        if ( ( window.origin ).includes( 'widget-test' ) ) {
          return true;
        }
      }
      else if (window.location.origin) {
        if ( ( window.location.origin ).includes( 'widget-test' ) ) {
          return true;
        }
      }
      else {
        return false;
      }
    },
    baseSchoolPortalUrl: function () {
      try {
        return this.isWidgetTest ? "Https://skoleportal-udv.easyiqcloud.dk/" : "Https://skoleportal.easyiqcloud.dk/";
      }
      catch(e){
        return "Https://skoleportal.easyiqcloud.dk/";
      }

    },
    notViewedNotifications: function () {
      if ( !this.fetchingData ) {
        if ( this.weekPlanInfo != null && this.weekPlanInfo.notifications != null ) {
          if ( this.weekPlanInfo.notifications.notificationlist != null && this.weekPlanInfo.notifications.notificationlist.length > 0 ) {
            return this.weekPlanInfo.notifications.notificationlist.filter( n => n.viewed == false ).length;
          }

        }
      }
      return 0;
    },
    hasNotifications: function () {
      if ( !this.fetchingData ) {
        if ( this.weekPlanInfo != null && this.weekPlanInfo.notifications != null ) {
          if ( this.weekPlanInfo.notifications.notificationlist != null && this.weekPlanInfo.notifications.notificationlist.length > 0 )
            return true;
        }
      }
      return false;
    },
    hasNewNotifications: function () {
      if ( this.hasNotifications ) {
        var newNotifications = this.getNewNotifications();
        return ( newNotifications != null && newNotifications.length > 0 );
      }
    },
    hasOldNotifications: function () {
      if ( this.hasNotifications ) {
        var oldNotifications = this.getOldNotifications();
        return ( oldNotifications != null && oldNotifications.length > 0 );
      }
    },
    plansIsVisible: function () {
      if (this.placement == 'narrow') {
        return this.weekPlanInfo.weekplanconfiguration.narrowview_showplans;
      }
      else {
        return this.weekPlanInfo.weekplanconfiguration.showplans;
      }
    },
    weekPlanItemsVisible: function () {
      return this.weekPlanInfo.weekplanconfiguration.showweekplanitemplanner;
    },
    useHtmlWidgets: function () {
      return !true;
    },
  },
  watch: {
    aulaToken: function () {
      if (this.isLocalhost) {
        this.initWidget();
      }
      else {
        if (this.aulaToken !== undefined) {
          this.initWidget();
        }
      }
    },
    selectedGroup: function () {
      this.initWidget();
    },
    aulaFilter() {
      this.aulaToken = !this.isLocalhost ? this.getAulaToken() : [];
    },
    weekPlanInfo: function () {
      if ( this.notViewedNotifications != 0)
        this.$emit( 'updateUnread', this.notViewedNotifications );
    },
  },
  created() {
    try {
      if (this.isLocalhost || this.isWidgetTest) {
        this.baseApiAddress = "https://test-api.easyiqcloud.dk/api/aula/";
      }
    }
    catch(e){

    }

    this.aulaToken = !this.isLocalhost ? this.getAulaToken() : [];

    window.addEventListener("resize", this.resize);
    this.resize();
  },
  destroyed() {
    window.removeEventListener("resize", this.resize);
  },
  methods: {
    resize() {
      this.full = this.placement != 'narrow' && window.innerWidth > 1024;
      this.$emit("update:full", this.placement != 'narrow' && window.innerWidth > 1024);
    }
  }
}
</script>
<style scoped>
.easyiq-wp-widgetheader
{
  background-color:#17a2b8;
  color:#000;
  font-size:1.2em;
}
.easyiq-wp-widgetsubheader{
  background-color:#45b7c1;
  color:#FFF;
  font-size:1em;
}
.easyiq-wp-classesDropdown .dropdown-toggle{
  background-color:transparent;
}

.easyiq-wp-classesDropdown .dropdown-menu
{
  max-height: 300px;
  overflow-x: hidden;
  overflow-y: auto;
  font-size:0.9em;
}

.easyiq-wp-notificationitem {
  font-size: 0.9em;
  margin: 5px 0;
}

.easyiq-wp-small-eventheader[data-wptype="8"] {
  background-color: #92b1c6;
  border: 1px solid #81a5bd;
}
.easyiq-wp-small-eventheader[data-wptype="2"] {
  background-color: #a5dbff;
  border: 1px solid #8bd1ff;
}

.easyiq-wp-small-eventDate {
  background-color:#45B7C1;
  margin:0.2em;
  padding:0.2em;
}

.easyiq-wp-small-eventheader{
  padding:0.1em;
  font-size:1em;
}
.easyiq-wp-small-eventheader p{
  margin:0;
  padding:0;
}
.easyiq-wp-notificationitem [data-notification-read='false'] {
  background-color: aliceblue !important;
}

.easyiq-wp-notificationitem [data-notification-read='true'] {
}

.easyiq-spMain {
  font-size:0.8em;
}

.easyiq-wp-info {
  font-weight: normal;
  text-align: left;
  border-radius: 4px;
  background-color: rgba(212, 239, 255,1);
  border: 1px solid rgba(212, 239, 255,0.7);
  padding: 5px 35px 0 5px;
  min-height: 80px;
  margin:10px 0;
}
.easyiq-wp-eventContainer-title
{
  z-index:10;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 1.2em;
  font-weight: bold;
  padding: 0.2em;
}

.easyiq-wp-eventContainer {
  position:relative;
  width: 100%;
}

.easyiq-wp-eventContainer > h2 {
  text-align: center;
  margin: 0;
}

.easyiq-wp-weekday video, .easyiq-wp-info video {
  width: 100%;
}
.easyiq-wp-dayevent-header
{
  border:1px solid rgba(128, 128, 128,0.6);
  text-align:center;
  font-weight:bold;
  font-size:1.2em;
}
.easyiq-wp-dayevent {
}

.easyiq-plandetail-skillmethod {
  font-size: 0.8em;
}

.easyiq-plandetail-skillmethod:before {
  content: "\2022";
}
.widget-toplink a {
  color: #FFF;
  text-decoration: underline;
  text-align:right;
}

.easyiq-wp-day-plan-title a{
  color:#FFF;
  text-decoration:underline;
}

.easyiq-wp-weekinfocontainerlinks {
  justify-content: flex-end;
  -webkit-justify-content: flex-end;
  display: flex;
  margin-left: auto;
}

.easyiq-wp-weekinfocontainer {
  display: flex;
  background-color: #d7dfe5;
  color: #000;
  padding: 0.4em;

}

.easyiq-wp-weekinfocontainerlinks div {
  margin: 0 2px;
}



.easyiq-wp-currentweekcontainer {
  display: flex;
  align-items: center;
  -webkit-align-items: center;
}

.easyiq-wp-todayweekcontainer {
  display: flex;
  align-items: center;
  -webkit-align-items: center;
  margin-right: 10px;
}

.easyiq-wp-weekinfocontaineruser {
  font-size: 0.8em;
  justify-content: center;
  align-self: center;
  color: #12798c;
}
.easyiq-wp-notificationbadge {
  position: absolute;
  top: 0.2em;
  right: -0.4em;
  height: 1.4em;
  font-size: 0.6em;
  width: 1.4em;
}
.easyiq-wp-notificationbrand {
  position: relative;
}
.easyiq-wp-notificationbrand button{
  background:transparent;
  padding:0;
  margin:0;
}
.easyiq-wp-notificationbrand popover-body {
  padding: 0 !important;
}

.easyiq-wp-aulaspacer{
  background-color:rgb(217, 227, 233);

}
* div[role='link']
{
  cursor:pointer;
  color: #000;
}
* div[role='link']:hover
{
  text-decoration:underline;
}

.easyiq-wp-arrowcirle{
  transform:scale(1.3);
  margin:2px;
}

@media only screen and (min-width:991px) {
  .easyiq-wp-aulaspacer
  {
    min-width:86px;
  }
}

@media only screen and (max-width:991px) {
  .easyiq-wp-aulaspacer
  {
    min-width:44px !important;
  }
}

@keyframes lds-ring {
  0% {
    transform: rotate(0)
  }

  100% {
    transform: rotate(360deg)
  }
}

.lds-css {
  width: 240px;
  height: 240px;
  margin: auto;
  transform: scale(0.7);
}

.lds-ring {
  height: 100%;
  width: 100%;
}

.lds-ring > div {
  position: absolute;
  top: 10%;
  left: 10%;
  width: 80%;
  height: 80%;
  border-radius: 50%;
  border: 10px solid #fff;
  border-color: #fff transparent transparent transparent;
  animation: lds-ring 1.5s cubic-bezier(0.5,0,0.5,1) infinite;
}

.lds-ring > div:nth-child(1) {
  border-color: rgba(0, 122, 141, 0.25);
  animation: none;
}

.lds-ring > div:nth-child(3) {
  animation-delay: .19s;
}

.lds-ring > div:nth-child(4) {
  animation-delay: .38s;
}

.lds-ring > div:nth-child(5) {
  animation-delay: .57s;
}


/*    NYT*/

.Flex {
  display: flex;
  flex-direction: row;
  align-items: center
}

.FlexOnly {
  display: flex;
}

.Flex11 {
  flex: 1 1 auto;
}

.EasyIQCard {
  background-color: #fff;
  font-weight: normal !important;
}

.EasyIQCard:not([data-flattop]) {
  border-top-left-radius: 7px;
  border-top-right-radius: 7px
}

.EasyIQCard:not([data-flatbottom]) {
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px
}

.EasyIQCard[data-flattop] {
  border-top-right-radius: 0;
  border-top-left-radius: 0
}

.EasyIQCard[data-flatbottom] {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0
}

.EasyIQCard .EasyIQCardHeader {
  display: flex;
  align-items: center;
  font-size: 1.25em;
  font-weight: bold;
  margin-top: 0;
  padding: .7rem .5rem .5rem 1rem;
  border-bottom: 10px solid #c9d7e4;
  flex-wrap: wrap
}

.EasyIQCard .EasyIQCardHeader .eiqImageButton {
  height: 2rem;
  width: 2rem
}

.EasyIQCard .EasyIQCardHeader .EasyIQCardHeaderExtra {
  display: flex;
  flex-basis: 100%;
  font-size: .9em;
  font-weight: normal
}

.EasyIQCard .EasyIQCardActions {
  margin-top: -2px;
  margin-left: auto;
  height: 0;
  font-weight: normal;
  font-size: 1rem
}

.EasyIQCard .EasyIQCardActions .eiqButton {
  height: 2rem
}

.EasyIQCard .EasyIQCardActions .eiqButton[data-nobackground] {
  background-color: unset !important
}

.EasyIQCard .EasyIQCardActions .eiqImageButton {
  height: 2rem;
  width: 2rem
}

.EasyIQCard .EasyIQCardActions input[type=checkbox] {
  height: 2.1rem;
  width: 2.1rem
}

.EasyIQCard .EasyIQCardBody {
  padding: 1rem
}

.wp-weekcontainer {
  width: 100%;
  margin: 0;
  position: relative
}

.wp-weekcontainer .wp-weekdays {
  margin-top: .5rem;
  display: flex;
  justify-content: space-between;
  width: 100%
}

.wp-weekcontainer .wp-weekdays > div {
  flex: 0 0 19%;
  text-align: center
}

.wp-weekcontainer video {
  width: 100%
}

.wp-weekcontainer thead td {
  border-left: 1px solid rgba(255,255,255,.75)
}

.wp-weekcontainer tr td {
  vertical-align: top;
  width: 20%
}

.wp-weekcontainer tr > .wp-weekday[data-showborders="1"] {
  min-height: 20px;
  padding-right: 0
}

.wp-weekcontainer tr > .wp-weekday[data-showborders="1"] .wp-dayeventadd {
  display: none
}

.wp-weekcontainer tr > TD:first-child {
  border-left: 0
}

.wp-weekcontainer tr .wp-addEventContainer {
  border-left: 1px solid rgba(255,255,255,.75);
  text-align: center;
  font-size: .95em;
  text-align: right;
  padding-top: .5em
}

.wp-weekcontainer tr .wp-addEventContainer img {
  cursor: pointer;
  width: 20px
}

.wp-weekcontainer tr > .wp-weekday {
  border-left: 1px solid rgba(255,255,255,.75);
  position: relative;
  min-height: 2em
}

.wp-weekcontainer tr > .wp-weekday .wp-dayEvents {
  width: 100%;
  height: 100%
}

.wp-weekcontainer tr > .wp-weekday .wp-dayeventadd {
  cursor: pointer;
  right: 3px;
  top: 5px;
  width: 20px
}

.wp-weekcontainer[data-showdays="1"] {
  height: 20px
}

.wp-eventContainer {
  position: relative;
  justify-content: space-between
}

.wp-eventContainer > div[data-dayofweek] {
  flex: 0 0 19%;
  max-width: 19%
}

.wp-eventContainer > div[data-dayofweek] div[data-dayheader] {
  display: none;
  align-items: center;
  margin-bottom: 1rem
}

.wp-eventContainer > div[data-dayofweek] div[data-dayheader] button {
  margin-left: 0
}

.wp-eventContainer > div[data-separator] {
  display: none;
  flex: 0 0 1px;
  border-left: 1px solid #f2f2f2;
  margin: .25rem 0 1.25rem 0
}

.wp-dayassignmentevent {
  border-radius: 7px;
  padding: .5rem;
  margin: 0 0 1rem 0;
  font-size: 1em;
  position: relative;
  overflow-wrap: break-word;
  background-color: #f2f2f2;
  /*        max-height: 250px;*/
  overflow-y:hidden; /*hack*/
}

.wp-dayassignmentevent[data-logintypeid="1"] {
  transition: box-shadow ease-out .2s,transform ease-out .2s
}

.wp-dayassignmentevent[data-logintypeid="1"]:hover {
  box-shadow: 0 10px 15px -5px rgba(0,0,0,.1),0 10px 10px -5px rgba(0,0,0,.04);
  cursor: pointer;
  transform: scale(1.02)
}

.wp-dayassignmentevent[data-logintypeid="2"] {
  transition: box-shadow ease-out .2s,transform ease-out .2s
}

.wp-dayassignmentevent[data-logintypeid="2"]:hover {
  box-shadow: 0 10px 15px -5px rgba(0,0,0,.1),0 10px 10px -5px rgba(0,0,0,.04);
  cursor: pointer;
  transform: scale(1.02)
}

.wp-dayassignmentevent[data-hidden=true] {
  background-color: #cacaca
}

.wp-dayassignmentevent[data-hidden=true]:hover {
  background-color: #eef3f7
}

.wp-dayassignmentevent .wp-dayassignmentevent-course {
  padding: 4px
}

.wp-dayassignmentevent .wp-dayassignmentevent-header {
  padding: 4px;
  font-weight: bold;
  font-size: 1.05em;
  border-bottom: .4em solid var(--eventcolor)
}

.wp-dayassignmentevent .wp-dayassignmentevent-header div {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap
}

.wp-dayassignmentevent .timeinfo {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: .5rem
}

.wp-dayassignmentevent .wp-dayassignmentevent-description {
  font-weight: normal;
  text-align: left
}

.wp-dayassignmentevent .wp-dayassignmentevent-planicon {
  position: absolute;
  top: 0;
  right: 5px;
  padding: 4px
}

.wp-dayassignmentevent .wp-dayassignmentevent-title {
  text-align: center;
  font-weight: bold;
  margin: 5px 0 5px 0
}

.wp-dayassignmentevent .wp-dayassignmentevent-plantitles {
  padding: 5px
}

.wp-dayassignmentevent .wp-dayassignmentevent-plantitles .wp-plan-icon {
  cursor: pointer;
  width: 16px;
  margin-right: 5px
}

.wp-dayassignmentevent .wp-dayassignmentevent-plantitles .wp-plan-hidden {
  width: 20px;
  margin: -2px 0 -2px auto
}

.wp-dayassignmentevent .wp-dayassignmentevent-footer {
  display: none;
  height: 10px;
  background-color: var(--eventcolor)
}

.wp-dayassignmentevent div[data-icontitle] {
  width: 100%
}

.wp-dayassignmentevent div[data-icontitle] a p {
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap
}


.wp-day-plancontainer2 {
  height: 3rem;
  margin-bottom: 0.25rem;
  position: relative;
  display:block;
}

.wp-day-plancontainer2 .wp-day-planbackground {
  cursor: pointer;
  width: 100%;
  position: absolute;
  background-color: var(--plancolor2);
  height: 60%;
  top: 20%;
}

.wp-day-plancontainer2[data-notowner] .wp-day-planbackground {
  background: repeating-linear-gradient( -55deg, var(--plancolor2), var(--plancolor2) 10px, var(--plancolor3) 10px, var(--plancolor3) 20px );
}

.wp-day-plancontainer2.wp-day-plancontainer-showstart .wp-day-planbackground,
.wp-day-holidayevent.wp-day-plancontainer-showstart .wp-day-planbackground {
  border-top-left-radius: 0.5em;
  border-bottom-left-radius: 0.5em;
}

.wp-day-plancontainer2.wp-day-plancontainer-showend .wp-day-planbackground,
.wp-day-holidayevent.wp-day-plancontainer-showend .wp-day-planbackground {
  border-top-right-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
}

.wp-day-plancontainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.wp-day-plancontainer img[data-planinfo] {
  cursor: pointer;
  width: 1.7em;
  z-index: 1;
}

.wp-day-plancontainer .wp-day-plan-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 5px;
}

.wp-day-plancontainer .wp-day-plan-courses {
  padding-left: 5px;
}


.Icon24 {
  cursor: pointer;
  width: 24px;
  height: 24px
}

.Icon48 {
  cursor: pointer;
  width: 48px;
  height: 48px
}
.PlanIcon2 {
  display: flex;
  position: relative;
}

.PlanIcon2 .Icon48 {
  min-width: 48px;
  border-radius: 50%;
}

.PlanIcon2 div:nth-child(1) {
  background-color: #fff;
}

.PlanIcon2 div:nth-child(2) {
  background-color: var(--plancolor);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 28px;
  margin-left: -48px;
}

.PlanIcon2 div:nth-child(2)[data-iscustomimage] {
  background-size: 48px;
  box-sizing: border-box;
}

.eiqCard .eiqHeader {
  font-size: 1.2em;
  border-radius: 7px;
  display: flex;
  align-items: center;
  background-color: #f2f2f2;
  padding-left: 0.75rem;
  height: 50px;
  font-weight: bold;
}

.eiqCard .eiqSubHeader {
  border-radius: 7px;
  display: flex;
  align-items: center;
  background-color: #f2f2f2;
  padding: 0.25rem 0.25rem 0.25rem 0.75rem;
  font-weight: bold;
}

.eiqCard .eiqContent {
  margin-top: 1rem;
  padding-left: 1rem;
}

.CutText {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.mt-3 {
  margin-top: 0.75rem;
}

/*Widget*/

.wp-day-plancontainer2.wp-day-plan-notowner .wp-day-planbackground {
  background: repeating-linear-gradient( -55deg, var(--plancolor2), var(--plancolor2) 10px, var(--plancolor3) 10px, var(--plancolor3) 20px );
}

.showmore {
  display:none;
}

.showmore:checked ~ label {
  height: auto;
  max-height: unset;
}

.showmorecontent {
  position: relative;
  display: block;
  width: 100%;
  max-height: 100px;
}

.showmorecontrols {
  position:absolute;
  bottom:0;
  left:0;
  right:0;
  height:30px;
  border-radius:7px;
  background-image: linear-gradient(to top, rgb(242, 242, 242), rgba(242, 242, 242, 0));
}

label {
  font-weight:unset;
  text-transform:unset;
}

.narrowHeader {
  font-size: 1.15em;
  margin-bottom: 0.75rem;
}

dialog#imgDialog, dialog#videoDialog, dialog#audioDialog {
  border: none;
  border-radius: 7px;
  padding: 1.5rem;
}

dialog::backdrop {
  opacity: 0.5;
}

img#imgView, video#videoView {
  max-height: 80vh;
  max-width: 80vw;
}

.closebutton {
  display:block;
  margin:1rem auto 0 auto;
  padding: 0.5rem 1rem;
}
</style>
