<template>
    <div class="course-list">
		<!-- Header -->
		<b-container fluid>
			<b-row class="course-list-header align-items-baseline">
				<b-col class="d-flex">
					<div class="d-flex align-items-center">
						<span class="course-list-header-small" v-if="placement === 'narrow'">Forløb</span>
						<h1 class="course-list-header-large" v-else>Forløb</h1>
					</div>
					<div v-if="week && year" :class="['d-flex', placement === 'narrow' ? 'align-items-center' : 'align-items-baseline']">
						<b-button
							class="course-list-header-week-picker d-flex justify-content-center"
							title="Gå til forrige uge" 
							@click="handlePreviousWeek"
						>
							<i :class="['fa fa-caret-left', placement === 'narrow' ? 'fa-lg' : 'fa-2x']"></i>
						</b-button>
						<span class="course-list-header-week-label" v-if="placement === 'narrow'">
							uge {{ week }} <span v-if="year !== yearLocale">({{year}})</span>
						</span>
						<h1 v-else>
							uge {{ week }} <span v-if="year !== yearLocale">({{year}})</span>
						</h1>
						<b-button
							class="course-list-header-week-picker d-flex justify-content-center"
							title="Gå til næste uge"
							@click="handleNextWeek"
						>
							<i :class="['fa fa-caret-right', placement === 'narrow' ? 'fa-lg' : 'fa-2x']"></i>
						</b-button>
					</div>
				</b-col>
			</b-row>
		</b-container>
		<!-- Loading -->
		<div v-if="isLoading" class="spinner-wrapper">
			<i class="fa fa-spinner fa-spin"></i>
			henter forløb...
		</div>

		<!-- Message/error shown to user -->
		<div v-else-if="error || !persons.length" class="message-container">
			<span v-if="error === 'licenseError'">
				<p>Du har ikke adgang til widgets fra MinUddannelse.</p>
				Kontakt <a :href="'mailto:support@minuddannelse.dk?subject=Ingen adgang til Aula-widgets fra MinUddannelse (reference-id: ' + sessionUUID + ')'">support@minuddannelse.dk</a> for adgang.
				<p class="text-muted info-text">
					Ved henvendelse kan følgende reference-id hjælpe med at spore fejlen: {{sessionUUID}}
				</p>
			</span>

			<span v-else-if="error === 'error'" class="error-message">
				Noget gik galt med at hente forløb. Prøv evt. at logge ud og ind igen.
			</span>
			
			<span v-else class="text-muted">
				Der er ingen forløb i denne uge.
			</span>
		</div>

		<div v-else>
			<!-- Course list -->
			<b-container v-if="persons && persons.length" class="course-list-container" :class="{'narrow': placement === 'narrow'}" fluid>
				<b-row v-for="person in persons" :key="person.navn + '-' + person.id">
					<b-col>
						<b-row v-if="persons.length > 1 && (userProfile.toLowerCase() === 'guardian' || userProfile.toLowerCase() === 'parent' || userProfile.toLowerCase() === 'otp')">
							<b-col class="course-person">
								<strong v-if="placement === 'narrow'">{{person.navn}}</strong>
								<h2 class="course-person-header" v-else>{{person.navn}}</h2>
							</b-col>
						</b-row>
						<b-row v-for="institution in person.institutioner" :key="institution.kode">
							<b-col class="course-institution-container">
								<b-row v-if="person.institutioner.length > 1">
									<b-col class="course-institution">
										{{institution.navn}}
									</b-col>
								</b-row>
								<ul v-if="institution.forloeb && institution.forloeb.length" class="list-inline" :class="{'narrow': placement === 'narrow'}">
									<li v-for="course in institution.forloeb" :key="course.id" class="list-inline-item course-item col" :class="placement === 'narrow' ? 'narrow' : 'col-xl-4'">
										<form :ref="aulaTokenInputIdCourseForm + course.id" @submit="addAulaTokenOnSubmit($event, aulaTokenInputIdCourseForm + course.id)" method="POST" target="_blank" :action="course.url">
											<input type="hidden" name="assuranceLevel" :value="assuranceLevel" />
											<input type="hidden" name="aulaToken" :value="getTempAulaToken" />
											<input type="hidden" name="childFilter" :value="childFilter" />
											<input type="hidden" name="currentWeekNumber" :value="currentWeekNumber" />
											<input type="hidden" name="group" :value="group" />
											<input type="hidden" name="institutionFilter" :value="institutionFilter" />
											<input type="hidden" name="isMobileApp" :value="isMobileApp" />
											<input type="hidden" name="placement" :value="placement" />
											<input type="hidden" name="sessionUUID" :value="sessionUUID" />
											<input type="hidden" name="userProfile" :value="userProfile" />
											<b-row class="align-items-center">
												<div class="course-source">
													<b-button variant="link" role="link" class="course-icon-url" :title="'Gå til forløb: ' + course.navn" type="submit">
														<div class="course-icon-circle" :style="{'background-color': course.farve}">
															<img class="course-icon" :src="'https://res.uvdata.net/icons/icons/' + course.ikon + '-32' + (contrastIsBlack(course.farve) ? '' : '-w')  + '.png'" alt="Gå til forløb">
														</div>	
													</b-button>
												</div>
												<b-col class="course-material">
													<b-button variant="link" role="link" class="course-material-url" :title="'Gå til forløb: ' + course.navn" type="submit">
														<strong>{{ course.navn }}</strong>
													</b-button>
													<div class="course-group-list" :class="{'narrow': placement === 'narrow'}">
														<span v-for="(group, index) in course.hold" :title="'Hold: ' + group.navn" :key="group.id" class="course-group">
															{{ group.navn }}<span v-if="course.hold.length -1 > index">,</span>
														</span>
													</div>
												</b-col>
											</b-row>
										</form>
									</li>
								</ul>
							</b-col>
						</b-row>
					</b-col>
				</b-row>
			</b-container>
		</div>

		<!-- Logo -->
		<div v-if="!error" class="mu-provider" :class="{'narrow': placement === 'narrow'}">
			<form :ref="aulaTokenInputIdLogoForm" @submit="addAulaTokenOnSubmit($event, aulaTokenInputIdLogoForm)" method="POST" target="_blank" :action="link">
				<span class="mu-provider-text">Fra alle os på</span>
				<input type="hidden" name="assuranceLevel" :value="assuranceLevel" />
				<input type="hidden" name="aulaToken" :value="getTempAulaToken" />
				<input type="hidden" name="childFilter" :value="childFilter" />
				<input type="hidden" name="currentWeekNumber" :value="currentWeekNumber" />
				<input type="hidden" name="group" :value="group" />
				<input type="hidden" name="institutionFilter" :value="institutionFilter" />
				<input type="hidden" name="isMobileApp" :value="isMobileApp" />
				<input type="hidden" name="placement" :value="placement" />
				<input type="hidden" name="sessionUUID" :value="sessionUUID" />
				<input type="hidden" name="userProfile" :value="userProfile" />

				<b-button role="link" class="mu-provider-btn" :title="'https://www.minuddannelse.net'" type="submit">
					<b-img
						src="https://www.minuddannelse.net/Content/Graphics/Frontpage/frontpage_logo.svg"
						alt="MinUddannelse"
						fluid
						class="mu-provider-img"
					/>
				</b-button>
			</form>
		</div>
    </div>
</template>

<script>

export default {
	name: 'CourseList',
	props: {
		assuranceLevel: Number,
		axios: Function,
		childFilter: [String, Array],
		currentWeekNumber: String,
		getAulaToken: Function,
		group: String,
		institutionFilter: [String, Array],
		isMobileApp: Boolean,
		moment: Function,
		placement: String,
		sessionUUID: String,
		userProfile: String,
	},
	data: function() {
		return {
			aulaToken: null,
			error: '',
			isLoading: false,
			link: new URL('https://api.minuddannelse.net/aula/redirect?redirectUrl=https://www.minuddannelse.net/'),
			persons: [],
			week: null,
			year: null,
			yearLocale: parseInt(this.moment().format('YYYY')),
			aulaTokenInputIdLogoForm: 'logo-form',
			aulaTokenInputIdCourseForm: 'course-form',
			tempAulaToken: '',
		};
	},
	watch: {
		aulaToken: function () {
			if (this.aulaToken !== undefined) {
				const { week, year } = this.extractWeekAndYear(this.currentWeekNumber);
				this.getCourses(week, year);
			}
		},
	},
	computed: {
		getTempAulaToken() {
			return this.tempAulaToken;
		}
    },
	filters: {
		convertDate: function(date) {
			return this.moment(date).locale('da').format('d/M');
		}
	},
	methods: {
		extractWeekAndYear: function(week) {
			const regex = (/^(\d){4}-W(\d){1,2}$/);
			
			// If there's something wrong with the data
			if (!regex.test(week)) {
				return {
					week: this.moment().week(),
					year: this.moment().year(),
				}
			}

			const [yearExtracted, weekExtracted] = week.split('-W');

			return {
				week: parseInt(weekExtracted, 10),
				year: parseInt(yearExtracted, 10),
			};
		},
		contrastIsBlack: function(hexColor) {
			hexColor = hexColor.replace('#', '');

			return (((parseInt(hexColor.substr(0,2),16) * 299) + (parseInt(hexColor.substr(2,2),16) * 587) + (parseInt(hexColor.substr(4,2),16) * 114)) / 1000 >= 128) ? true : false;
		},
		getCourses: function(week ,year) {
			this.week = week;
			this.year = year;

			const propsToPass = {};
			const propToDismiss = [
				'axios',
				'getaulatoken',
				'moment',
				'currentWeekNumber',
			];

			Object.keys(this.$props).forEach(prop => {
				if (!propToDismiss.includes(prop.toLowerCase())) {
					propsToPass[prop] = this.$props[prop] ? this.$props[prop].toString() : this.$props[prop];
				}
			});

			// Set `currentWeekNumber` to selected week
			propsToPass.currentWeekNumber = `${year}-W${week}`;

			const config = {
				method: 'get',
				headers: {
					Authorization: `Bearer ${this.aulaToken}`,
					Accept: 'application/json',
				},
				params: propsToPass,
				url: 'https://api.minuddannelse.net/aula/forloeb',
			};
			// Get courses
			this.axios(config)
				.then((result) => {
					this.persons = result.data.personer;

					this.error = '';
					
					this.isLoading = false;
				})
				.catch((err) => {
					this.isLoading = false;

					const licenseError = err.response.data.responseStatus.errorCode.toLowerCase() === 'licenserequired';

					this.error = licenseError ? 'licenseError' : 'error'

					return;
				});
		},
		handlePreviousWeek: function() {
			this.isLoading = true;
			const newWeek = this.week - 1;
			const weeksInPreviousYear = this.moment({year: this.year - 1}).isoWeeksInYear();
			const week = newWeek < 1 ? weeksInPreviousYear : newWeek;
			const year = newWeek < 1 ? this.year -1 : this.year;
			this.getCourses(week, year);
		},
		handleNextWeek: function() {
			this.isLoading = true;
			const newWeek = this.week + 1;
			const weeksInYear = this.moment({year: this.year}).isoWeeksInYear();
			const week = newWeek <= weeksInYear ? newWeek : 1;
			const year = newWeek <= weeksInYear ? this.year : this.year + 1;
			this.getCourses(week, year);
		},
		addAulaTokenOnSubmit: function (e, ref) {
			e.preventDefault();

			// Adding the aulaToken before submitting
			this.tempAulaToken = this.aulaToken;

			// Timeout ensures form is submittet after DOM is repaintet
			setTimeout(() => {
				// Ref can be an array because its located in a v-for
				if (Array.isArray(this.$refs[ref])) {
					this.$refs[ref][0].submit();
				} else {
					this.$refs[ref].submit();
				}

				// Clear the aulaToken after submitting, so it's not visible in DOM
				// Timeout ensures the form has been submittet before clearing
				setTimeout(() => {
					this.tempAulaToken = '';
				}, 500);
			}, 500);
		},
	},
	created: function() {
		this.isLoading = true;
		this.aulaToken = this.getAulaToken();
		this.moment().locale('da');
		this.link.searchParams.append('userProfile', this.userProfile.toLowerCase());
	},
};
</script>
<style scoped>

	/** NC class */
	.course-list h1 {
		font-size: 36px;
		font-weight: 900;
		margin: 0;
	}

	/** NC class */
	.course-list .container-fluid {
		padding: 0 !important;
	}

	.course-list .spinner-wrapper {
		min-height: 42px;
		padding: 10px 0;
	}

	.course-list .message-container {
		margin: 0;
		padding: 10px 0;
	}

	.course-list .error-message {
		color: #b50050;
	}

	.course-list .info-text {
		font-size: 12px;
	}

	.course-list .course-list-header .course-list-header-small,
	.course-list .course-list-header .course-list-header-week-label {
		font-size: 18px !important;
		text-transform: uppercase;
	}

	.course-list .course-list-header .course-list-header-large {
		font-size: 36px !important;
	}

	.course-list .course-list-header .course-list-header-week-picker {
		background-color: transparent;
		color: #222350;
		width: 30px;
		padding: 0;
		height: 30px;
		border-radius: 50%;
		position: relative;
		align-items: center;
	}

	.course-list .course-list-header .course-list-header-week-picker .fa-caret-left {
		margin-right: 3px;
	}

	.course-list .course-list-header .course-list-header-week-picker .fa-caret-right {
		margin-left: 3px;
	}

	.course-list .course-list-header .course-list-header-week-picker:active {
		background-color: #828a9180 !important;
	}

	.course-list .course-list-header .course-list-header-week-picker:focus {
		box-shadow: none !important;
	}

	.course-list .course-list-container {
		margin-top: 8px;
	}

	.course-list .course-list-container.narrow {
		padding: 8px 8px 3px 8px !important; 
    	background: #ffffff;
	}

	.course-list .course-list-container > .row,
	.course-list .course-list-container.narrow > .row,
	.course-list .course-list-container.narrow > .row .course-person {
		margin-bottom: 8px;
	}

	.course-list .course-person .course-person-header {
		font-size: 22px;
		font-weight: 900;
	}

	.course-list .course-institution {
		font-weight: 600;
		font-size: 15px;
	}

	.course-list .list-inline {
		margin-bottom: 0;
	}

	.course-list .course-item {
		margin-bottom: 6px;
		margin-right: 0;
	}

	.course-list .course-item > .row {
		align-items: center;
	}

	.course-list .course-item.narrow:last-child {
		margin-bottom: 0;
	}

	.course-list .course-source {
		padding-right: 0;
		width: 50px;
		height: 50px;
		padding-left: 0;
	}

	.course-list .course-material {
		padding-left: 12px;
		overflow: hidden;
	}

	.course-list .course-material-url {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		width: 100%;
		text-align: left;
		
		/* Color for the ellipsis */
		color:#12798c;
	}

	.course-list .course-icon-url,
	.course-list .course-material-url {
		padding: 0;
		font-size: 15px;
		color: #007a8d;
		background: transparent;
		
		-webkit-appearance: none;
   		-moz-appearance: none;
   		appearance: none;
	}

	.course-list .course-material-url:focus,
	.course-list .course-icon-url:focus {
		box-shadow: none !important;
	}

	.course-list .course-icon-circle {
		position: relative;
		width: 50px;
		height: 50px;
		border-radius: 50%;
	}

	.course-list .course-icon {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	.course-list .course-group-list {
		white-space: normal;
		margin-top: -3px;
		line-height: 1;
	}

	.course-list .course-group-list.narrow {
		margin-top: -5px;
	}

	.course-list .course-group {
		font-size: 12px;
		font-weight: 400;
		color: #868686;
	}

	.course-list .course-group {
		display: inline-block;
		margin-right: 8px;
		text-transform: uppercase;
	}

	.course-list .mu-provider.narrow {
		display: flex;
    	align-items: center;
    	justify-content: center;
		padding-top: 6px;
	}

	.course-list .mu-provider-text {
		font-size: 12px;
		color: #868686;
	}

	.course-list .mu-provider-btn {
		background-color: transparent;
		padding: 0 0 0 8px;
		border: none;
		border-radius: 0;
	}

	.course-list .mu-provider-btn:active {
		background-color: transparent;
		border-color: transparent;
	}

	.course-list .mu-provider-btn:focus {
		box-shadow: none;
	}

	.course-list .mu-provider-img {
		height: 30px;
	}
</style>
