<template>
  <div class="vacation-request-details">
    <HeadlineAlert v-if="lastReminderDateTime" class="mb-3">
      {{ 'CALENDAR_EVENT_REMINDER_ALERT' | fromTextKey({ dateTime: lastReminderDateTime }) }}
    </HeadlineAlert>
    <div v-if="!isLoadingDetails && activeEvent.vacationRegistration" :class="isMobile ? 'p-2' : ''">
      <b-row class="mt-2">
        <b-col cols="4" sm="3" lg="2">
          <strong>{{ 'CALENDAR_VACATION_REQUEST_PERIOD_1' | fromTextKey }}</strong>
        </b-col>
        <b-col>
          {{ 'CALENDAR_VACATION_REQUEST_PERIOD_2' | fromTextKey }}
          {{ moment(activeEvent.startDateTime).utc().format('DD-MM-YYYY') }}
          {{ 'CALENDAR_VACATION_REQUEST_PERIOD_3' | fromTextKey }}
          {{ moment(activeEvent.endDateTime).utc().format('DD-MM-YYYY') }}
        </b-col>
      </b-row>
      <b-row v-if="activeEvent.responseDeadline" class="mt-2">
        <b-col cols="4" sm="3" lg="2">
          <strong>{{ 'CALENDAR_LABEL_EVENT_REQUEST_DEADLINE' | fromTextKey }}:</strong>
        </b-col>
        <b-col>
          {{ moment(activeEvent.responseDeadline).utc().format('DD-MM-YYYY') }}
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col cols="4" sm="3" lg="2">
          <strong>{{ 'CALENDAR_VACATION_REQUEST_CREATOR' | fromTextKey }}</strong>
        </b-col>
        <b-col>
          {{ activeEvent.creator | displayProfileNameWithMetadata }}
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col cols="4" sm="3" lg="2">
          <strong>{{ 'CALENDAR_VACATION_REQUEST_INSTITUTION' | fromTextKey }}</strong>
        </b-col>
        <b-col>
          {{ activeEvent.institutionName }}
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col cols="4" sm="3" lg="2">
          <strong>{{ 'CALENDAR_VACATION_REQUEST_DEPARTMENTS' | fromTextKey }}</strong>
        </b-col>
        <b-col>
          {{
            activeEvent.vacationRegistration.departments
              .map(d => d.mainGroup.name + ' (' + d.mainGroup.institutionName + ')')
              .join(', ')
          }}
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col cols="4" sm="3" lg="2">
          <strong>{{ 'CALENDAR_VACATION_REQUEST_GROUPS' | fromTextKey }}</strong>
        </b-col>
        <b-col>
          {{ getVacationRequestGroups() }}
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col cols="4" sm="3" lg="2">
          <strong>{{ 'CALENDAR_VACATION_REQUEST_MISSING_ANSWER' | fromTextKey }}</strong>
        </b-col>
        <b-col>
          <template v-for="(pending, i) in activeEvent.vacationRegistration.childrenPendingAnswers">
            <template v-if="i < 2 || showAllPendingAnswers">
              <a
                :key="i"
                role="button"
                :class="{ disabled: !canEditVacationRequest }"
                @click="onVacationResponse(pending)"
              >
                {{ pending | displayProfileNameWithMetadata }}
              </a>
              <template
                v-if="
                  i < 1 ||
                  (showAllPendingAnswers && i < activeEvent.vacationRegistration.childrenPendingAnswers.length - 1)
                "
              >
                ,
              </template>
            </template>
          </template>
          <b-btn
            v-if="activeEvent.vacationRegistration.childrenPendingAnswers.length - 2 > 0 && !showAllPendingAnswers"
            variant="link"
            class="show-more-pending-answers"
            :aria-label="'ARIA_LABEL_VACATION_REQUEST_SEE_ALL_PENDING' | fromTextKey"
            @click="showAllPendingAnswers = true"
          >
            +
            {{ activeEvent.vacationRegistration.childrenPendingAnswers.length - 2 }}
            {{ 'CALENDAR_VACATION_REQUEST_OTHERS' | fromTextKey }}
          </b-btn>
          <b-btn
            v-if="showAllPendingAnswers"
            variant="link"
            class="show-more-pending-answers"
            :aria-label="'ARIA_LABEL_VACATION_REQUEST_SEE_LESS_PENDING' | fromTextKey"
            @click="showAllPendingAnswers = false"
          >
            <i class="icon icon-Aula_up-arrow" />
          </b-btn>
          <div class="send-reminder-container">
            <CalendarEventSendReminder />
          </div>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col cols="4" sm="3" lg="2">
          <strong>{{ 'CALENDAR_VACATION_REQUEST_NOTE' | fromTextKey }}</strong>
        </b-col>
        <b-col>
          <template v-if="activeEvent.description != null && activeEvent.description.html.length > 0">
            <template v-if="activeEvent.description.html.length < 100">
              {{ activeEvent.description.html }}
            </template>
            <template v-else>
              <template v-if="showAllNoteToGuardian">
                {{ activeEvent.description.html }}
              </template>
              <template v-else> {{ activeEvent.description.html.substr(0, 100) }}... </template>

              <b-btn
                variant="link"
                class="show-more-pending-answers"
                :aria-label="
                  showAllNoteToGuardian
                    ? 'ARIA_LABEL_VACATION_REQUEST_SEE_LESS_NOTE'
                    : 'ARIA_LABEL_VACATION_REQUEST_SEE_FULL_NOTE' | fromTextKey
                "
                @click="showAllNoteToGuardian = !showAllNoteToGuardian"
              >
                <i v-if="showAllNoteToGuardian" class="icon icon-Aula_up-arrow" />
                <i v-else class="icon icon-Aula_down-arrow" />
              </b-btn>
            </template>
          </template>
        </b-col>
      </b-row>
      <hr v-if="!isMobile" />
    </div>
    <b-row>
      <b-col v-if="!isLoadingDetails" class="filter-responses">
        <div v-if="isMobile" class="filtering-department-group">
          {{ 'CALENDAR_VACATION_REQUEST_RESPONSE_FILTER' | fromTextKey }}
          <b-dropdown ref="sorter" class="dropdown-select filter-sorter mr-1">
            <template slot="button-content">
              <template v-if="filteringResponse.departmentId === 'all'">
                {{ 'ALL' | fromTextKey }}
              </template>
              <template v-else>
                {{ filteringResponse.departmentName }}
                <template v-if="filteringResponse.groupId !== 'all'"> - {{ filteringResponse.groupName }} </template>
              </template>
              <i class="icon icon-Aula_down-arrow" aria-hidden="true" />
            </template>
            <b-dropdown-item
              key="all"
              :active="filteringResponse.departmentId === 'all'"
              @click="changeFilteringDepartment('all')"
            >
              {{ 'ALL' | fromTextKey }}
            </b-dropdown-item>
            <template v-for="(department, i) in filteringDepartments">
              <b-dropdown-item
                :key="i"
                class="department"
                :active="filteringResponse.departmentId === department.id"
                @click="changeFilteringDepartment(department.id)"
              >
                {{ department.name }}
              </b-dropdown-item>
              <template v-for="(group, j) in getFilteringGroupsByDepartmentId(department.id)">
                <b-dropdown-item
                  :key="'group' + i + '-' + j"
                  :active="filteringResponse.departmentId === department.id && filteringResponse.groupId === group.id"
                  class="group"
                  @click="changeFilteringGroupOnMobile(group.id, department.id)"
                >
                  {{ group.name }}
                </b-dropdown-item>
              </template>
            </template>
          </b-dropdown>
        </div>
        <div v-else class="filtering-department-group">
          <template v-if="filteringDepartments.length > 1">
            {{ 'CALENDAR_VACATION_REQUEST_RESPONSE_FILTERING_DEPARTMENT' | fromTextKey }}
            <b-dropdown v-if="filteringDepartments.length > 1" ref="sorter" class="dropdown-select filter-sorter mr-1">
              <template slot="button-content">
                <template v-if="filteringResponse.departmentId === 'all'">
                  {{ 'ALL' | fromTextKey }}
                </template>
                <template v-else>
                  {{ filteringResponse.departmentName }}
                </template>
                <i class="icon icon-Aula_down-arrow" aria-hidden="true" />
              </template>
              <b-dropdown-item
                key="all"
                :active="filteringResponse.departmentId === 'all'"
                @click="changeFilteringDepartment('all')"
              >
                {{ 'ALL' | fromTextKey }}
              </b-dropdown-item>
              <template v-for="(department, i) in filteringDepartments">
                <b-dropdown-item
                  :key="i"
                  :active="filteringResponse.departmentId === department.id"
                  @click="changeFilteringDepartment(department.id)"
                >
                  {{ department.name }}
                </b-dropdown-item>
              </template>
            </b-dropdown>
          </template>
          {{ 'CALENDAR_VACATION_REQUEST_RESPONSE_FILTERING_GROUP' | fromTextKey }}
          <b-dropdown ref="sorter" class="dropdown-select filter-sorter mr-1">
            <template slot="button-content">
              <template v-if="filteringResponse.groupId === 'all'">
                {{ 'ALL' | fromTextKey }}
              </template>
              <template v-else>
                {{ filteringResponse.groupName }}
              </template>
              <i class="icon icon-Aula_down-arrow" aria-hidden="true" />
            </template>
            <b-dropdown-item
              key="all"
              :active="filteringResponse.groupId === 'all'"
              @click="changeFilteringGroup('all')"
            >
              {{ 'ALL' | fromTextKey }}
            </b-dropdown-item>
            <template v-for="(group, i) in filteringGroups">
              <b-dropdown-item
                :key="'group' + i"
                :active="filteringResponse.groupId === group.id"
                @click="changeFilteringGroup(group.id)"
              >
                {{ group.name }}
              </b-dropdown-item>
            </template>
          </b-dropdown>
        </div>
      </b-col>
    </b-row>
    <aula-spinner v-if="isResponseStatusLoading" />
    <b-row v-else>
      <b-col
        v-for="(week, i) in vacationRequestResponses"
        :key="i"
        cols="12"
        :md="vacationRequestResponses.length === 1 ? '12' : '6'"
        class="week-overview"
      >
        <div class="week-header">
          {{ 'CALENDAR_VACATION_REQUEST_WEEK' | fromTextKey }}
          {{ week.weekNumber }}
        </div>
        <div class="week-days p-2">
          <vacation-response-status-by-day
            v-for="(day, index) of week.vacationDays"
            :key="index"
            :vacation-day="day"
            :opening-hour="getOpenHour(day.date)"
            @openVacationResponseForChild="onVacationResponse"
          />
        </div>
      </b-col>
    </b-row>
    <template v-if="!isMobile">
      <aula-form-footer
        submit-text="BUTTON_CLOSE"
        delete-text="CALENDAR_VACATION_REQUEST_DELETE"
        :is-loading="false"
        :show-cancel="false"
        :show-delete="canHandleVacationRequests && !isLoadingDetails"
        :show-delete-mobile="canHandleVacationRequests"
        :show-delete-icon="canHandleVacationRequests"
        @submitClicked="closeVacationRequestDetail"
        @deleteClicked="handleDeleteClick"
      />
    </template>
    <aula-modal ref="deleteModal" ok-text="YES" @cancelClicked="$refs.deleteModal.hide()" @okClicked="confirmDelete()">
      {{ 'CALENDAR_DELETE_VACATION_REGISTRATION_TEXT' | fromTextKey }}
    </aula-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { mapActions } from 'vuex';
import { types } from '../../store/types/types';
import { eventTypeEnum } from '../../../shared/enums/eventTypeEnum';
import moment from 'moment-timezone';
import VacationResponseStatusByDay from './VacationResponseStatusByDay';
import { permissionEnum } from '../../../shared/enums/permissionEnum.ts';
import { vacationResponseStatus } from '../../../shared/enums/vacationResponseStatus';
import CalendarEventSendReminder from '../../../shared/PageFragments/CalendarEvent/CalendarEventSendReminder.vue';
import HeadlineAlert from '../../../shared/components/HeadlineAlert.vue';
import { format, isSame } from '../../../shared/utils/dateUtil';
import { dateFormatEnum } from '../../../shared/enums/dateFormatEnum';

export default {
  components: {
    HeadlineAlert,
    CalendarEventSendReminder,
    VacationResponseStatusByDay,
  },
  props: {
    vacationRequest: {
      type: Object,
      required: false,
      default: () => null,
    },
  },
  data: function () {
    return {
      moment: moment,
      isLoading: false,
      isLoadingDetails: true,
      showAllPendingAnswers: false,
      showAllNoteToGuardian: false,
      isResponseStatusLoading: true,
      filteringGroups: [],
      filteringDepartments: [],
      filteringResponse: {
        departmentId: 'all',
        departmentName: null,
        groupId: 'all',
        groupName: null,
      },
      openingHours: [],
    };
  },
  computed: {
    ...mapGetters({
      vacationRequestResponses: types.GET_VACATION_REQUEST_RESPONSES,
      activeEvent: types.GET_CURRENT_EVENT,
      profile: types.GET_CURRENT_PROFILE,
      isMobile: types.GET_IS_MOBILE,
      institutions: types.GET_INSTITUTIONS,
      activeInstitutions: types.GET_ACTIVE_INSTITUTIONS,
      hasPermissionOnInstitution: types.HAS_PERMISSION_ON_INSTITUTION,
      generalOpenHours: types.GET_GENERAL_OPENING_HOURS_FOR_GUARDIAN,
      specificOpenHours: types.GET_PRESENCE_OPEN_HOURS_GUARDIAN_DASHBOARD,
    }),
    lastReminderDateTime() {
      if (!this.activeEvent?.lastReminderDateTime) {
        return null;
      }
      return format(this.activeEvent.lastReminderDateTime, dateFormatEnum.LONG_DATE_TIME_WITH_DATE_PREFIX);
    },
    canHandleVacationRequests() {
      return (
        this.activeEvent &&
        this.hasPermissionOnInstitution(permissionEnum.HANDLE_VACATION_REQUESTS, this.activeEvent.institutionCode)
      );
    },
    canEditVacationRequest() {
      return (
        this.activeEvent &&
        this.hasPermissionOnInstitution(permissionEnum.EDIT_PRESENCE_TEMPLATES, this.activeEvent.institutionCode)
      );
    },
  },
  mounted() {
    this.getVacationRequestDetail().then(() => {
      this.initial();
      this.reloadResponseStatus();
      this.loadPresenceConfiguration({
        institutionCode: this.activeEvent.institutionCode,
      });
    });
  },
  methods: {
    ...mapActions({
      deleteNotifications: types.DELETE_NOTIFICATIONS,
      deleteVacationRequest: types.DELETE_EVENT,
      loadVacationRequestResponses: types.LOAD_VACATION_REQUEST_RESPONSES,
      getEventById: types.GET_EVENT_BY_ID,
      loadVacationResponseForChild: types.LOAD_VACATION_REQUEST_RESPONSE,
      loadPresenceConfiguration: types.LOAD_PRESENCE_CONFIGURATION,
      loadOpenHours: types.LOAD_PRESENCE_OPEN_HOURS_GUARDIAN_DASHBOARD,
    }),
    getOpenHour(day) {
      return this.openingHours.find(hour => isSame(hour.date, day));
    },
    onVacationResponse(childData) {
      const answeredList = [vacationResponseStatus.IS_COMING, vacationResponseStatus.IS_NOT_COMING];
      const registrationResponseId = childData.vacationRegistrationResponseId;
      const hasAnswered = answeredList.includes(childData.status);
      let child = childData;

      if (childData.child) {
        child = childData.child;
      }

      this.$emit('showChildVacationRequest', { child, hasAnswered, registrationResponseId });
    },
    handleDeleteClick() {
      this.$refs.deleteModal.show();
    },
    setSelectedDepartment(departmentId) {
      this.filteringResponse.departmentId = departmentId;
      if (departmentId !== 'all') {
        const selectedDepartment = this.filteringDepartments.find(d => d.id === departmentId);
        if (selectedDepartment) {
          this.filteringResponse.departmentName = selectedDepartment.name;
        }
      }
    },
    changeFilteringGroupOnMobile(groupId, departmentId) {
      this.setSelectedDepartment(departmentId);
      this.filteringResponse.groupId = groupId;
      const filteringGroups = this.getFilteringGroupsByDepartmentId(departmentId);
      const selectedGroup = filteringGroups.find(g => g.id === groupId);
      if (selectedGroup) {
        this.filteringResponse.groupName = selectedGroup.name;
      }
      this.reloadResponseStatus();
    },
    changeFilteringGroup(groupId) {
      this.filteringResponse.groupId = groupId;
      if (groupId !== 'all') {
        const selectedGroup = this.filteringGroups.find(g => g.id === groupId);
        if (selectedGroup) {
          this.filteringResponse.groupName = selectedGroup.name;
        }
      }
      this.reloadResponseStatus();
    },
    changeFilteringDepartment(departmentId) {
      this.setSelectedDepartment(departmentId);
      this.filteringResponse.groupId = 'all';
      this.setFilteringGroups(departmentId);
      this.reloadResponseStatus();
    },
    getVacationRequestDetail() {
      const vacationRequestId = this.$route.params.id || this.vacationRequest?.vacationRegistrationId;

      this.isLoadingDetails = true;
      return this.getEventById({ eventId: vacationRequestId }).then(() => {
        this.loadOpenHours({
          startDate: this.activeEvent.startDateTime,
          endDate: this.activeEvent.endDateTime,
          institutionCodes: [this.activeEvent.institutionCode],
        }).then(() => {
          this.openingHours = this.specificOpenHours.openingHoursOverviewDto.flatMap(hours => hours.openingHoursDto);
          this.isLoadingDetails = false;
        });
      });
    },
    reloadResponseStatus() {
      const vacationRequestId = this.$route.params.id || this.vacationRequest?.vacationRegistrationId;
      const params = {
        vacationRequestId,
        filterDepartmentGroupIds:
          this.filteringResponse.departmentId !== 'all'
            ? [this.filteringResponse.departmentId]
            : this.activeEvent.vacationRegistration.departments.map(d => d.mainGroup.id),
        filterDepartmentFilteringGroupIds:
          this.filteringResponse.groupId !== 'all' ? [this.filteringResponse.groupId] : null,
      };
      this.isResponseStatusLoading = true;

      this.loadVacationRequestResponses(params).then(() => {
        this.isResponseStatusLoading = false;
      });
    },
    getFilteringGroupsByDepartmentId(departmentId) {
      let filteringGroups = [];
      const department = this.activeEvent.vacationRegistration.departments.find(d => d.mainGroup.id === departmentId);
      if (department) {
        filteringGroups = department.filteringGroups;
      }
      return filteringGroups;
    },
    setFilteringDepartments() {
      this.filteringDepartments =
        this.activeEvent.vacationRegistration != null && this.activeEvent.vacationRegistration.departments != null
          ? this.activeEvent.vacationRegistration.departments.map(d => d.mainGroup)
          : [];
    },
    setFilteringGroups(departmentId = null) {
      let filteringGroups = [];
      let department = null;
      if (departmentId !== 'all') {
        department = this.activeEvent.vacationRegistration.departments.find(d => d.mainGroup.id === departmentId);
      } else if (this.activeEvent.vacationRegistration.departments.length === 1) {
        department = this.activeEvent.vacationRegistration.departments[0];
      }
      if (department != null) {
        filteringGroups = department.filteringGroups;
      }
      filteringGroups = filteringGroups?.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
      this.filteringGroups = filteringGroups;
    },
    closeVacationRequestDetail() {
      if (this.$route.path.indexOf('kalender') !== -1) {
        this.$router.push({ name: 'calendar' });
      } else {
        this.$emit('onClosed');
      }
    },
    getVacationRequestGroups() {
      let groups = [];
      if (this.activeEvent.vacationRegistration != null && this.activeEvent.vacationRegistration.departments) {
        for (const department of this.activeEvent.vacationRegistration.departments) {
          if (department.filteringGroups != null) {
            const uniqueGroups = department.filteringGroups.filter(
              g => groups.find(group => group.id === g.id) == null
            );
            groups = groups.concat(uniqueGroups.map(g => ({ id: g.id, name: g.name })));
          }
        }
      }
      return groups.map(g => g.name).join(', ');
    },
    confirmDelete() {
      this.isLoading = true;
      this.deleteVacationRequest({
        eventForm: { id: this.activeEvent.id },
        type: eventTypeEnum.VACATION_REGISTRATION,
      }).then(() => {
        this.isLoading = false;
        this.$emit('reloadCalendar');
        this.closeVacationRequestDetail();
      });
    },
    initial() {
      this.filteringResponse.departmentId = 'all';
      this.filteringResponse.departmentName = '';
      this.filteringResponse.groupId = 'all';
      this.filteringResponse.groupName = '';
      this.setFilteringDepartments();
      this.setFilteringGroups('all');
    },
  },
};
</script>

<style scoped lang="scss">
@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';

.show-more-pending-answers {
  margin-right: 20px;
}

.send-reminder-container {
  display: inline-flex;
  height: 1rem;
  vertical-align: sub;
}

.filter-responses {
  padding-top: 10px;
  padding-bottom: 20px;
  display: inline-flex;
  @include breakpoint-lg-down() {
    background-color: $color-grey-dark;
    font-size: 16px;
    font-weight: 700;
    padding: 10px 20px;
    margin-bottom: 5px;
  }
  .filtering-department-group {
    display: flex;
    align-items: center;
    @include breakpoint-lg-down() {
      margin-left: 20px;
    }
    /deep/ .dropdown-menu {
      width: 250px;
      max-width: unset !important;
      @include breakpoint-lg-down() {
        width: 300px;
      }
      .dropdown-item a {
        word-break: break-all;
      }
      .department {
        background-color: $color-grey-base !important;
      }
      .group {
        padding-left: 45px;
      }
    }

    .icon-Aula_down-arrow {
      font-size: 5px;
      margin-top: 3px;
      margin-left: 4px;
    }
  }
}
/deep/.day-response-status {
  .modal-aula {
    .modal-body {
      padding: 0 !important;
    }
  }
}
a.disabled {
  pointer-events: none;
  color: var(--color-grey);
}
</style>
