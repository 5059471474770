var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.hideCalendarOverview),expression:"hideCalendarOverview"}],staticClass:"aula-calendar daily-overview",class:{ mobile: !_vm.isAside, 'daily-close': _vm.isAside },attrs:{"tabindex":"0"},on:{"click":_vm.showCalendarOverview,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.showCalendarOverview.apply(null, arguments)},"focus":_vm.showCalendarOverview}},[_c('div',{staticClass:"week-number"},[_vm._v("\n    "+_vm._s('Uge ' + _vm.weekNumber)+"\n  ")]),_vm._v(" "),(_vm.isProfileLoaded)?_c('FullCalendar',{ref:"fullCalendar",attrs:{"id":"daily-calendar","default-view":"resourceTimeGridDay","plugins":_vm.calendarPlugins,"locale":"da","lang":"da","weekends":true,"all-day-slot":true,"all-day-text":" ","title-format":{ month: 'long', day: '2-digit', year: 'numeric' },"week-numbers":false,"week-number-title":"Uge","slot-label-format":{ hour: '2-digit', minute: '2-digit' },"slot-event-overlap":false,"column-header-format":{ month: 'long', day: '2-digit', year: 'numeric' },"min-time":"08:00:00","max-time":"18:00:00","content-height":"auto","time-grid-event-min-height":25,"group-by-date-and-resource":true,"resources":_vm.resources,"resource-render":_vm.resourceRender,"events":_vm.fetchEvents,"event-render":_vm.eventRender,"dates-render":_vm.datesRender,"scheduler-license-key":"0851031242-fcs-1572871596","button-icons":{
      prev: ' icon-Aula_back-triangle',
      next: ' icon-Aula_forward-triangle',
    },"header":{ left: 'today title prev,next', right: '' },"button-text":{
      today: 'I dag',
      month: 'Måned',
      agendaWeek: 'Uge',
      agendaDay: 'Dag',
    },"event-limit":_vm.allDayEventLimit,"event-limit-text":_vm._f("fromTextKey")('LOAD_MORE_LOWERCASE'),"event-limit-click":_vm.onEventLimitClick}}):_vm._e(),_vm._v(" "),(!_vm.isMobile)?_c('b-btn',{staticClass:"daily-overview-close",attrs:{"variant":"link","aria-label":_vm._f("fromTextKey")('ARIA_LABEL_CLOSE_CALENDAR')},on:{"click":function($event){$event.stopPropagation();return _vm.hideCalendarOverview()}}},[_c('i',{staticClass:"icon-Aula_close",attrs:{"aria-hidden":"true"}})]):_vm._e(),_vm._v(" "),_c('b-button',{class:!_vm.isMobile ? 'pull-right' : '',attrs:{"variant":"link","size":"sm"},on:{"click":_vm.goToCalendar}},[_vm._v("\n    "+_vm._s(_vm._f("fromTextKey")('DAILY_OVERVIEW_SEE_CALENDAR'))),_c('i',{staticClass:"icon-Aula_forward-arrow"})]),_vm._v(" "),(_vm.isAside)?_c('important-date-overview',{on:{"showCalendarOverview":_vm.showCalendarOverview,"hideCalendarOverview":_vm.hideCalendarOverview}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }