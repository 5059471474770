<template>
<div class='widget-W0018V0001'>
  <div class="bg-white">
    <b-navbar toggleable="md" type="dark" variant="info">
      <b-navbar-brand href="#" data-widgetversion="1.2.3" style="padding:0.2em;">
        Links <span v-if="userProfile.toLowerCase() == 'parent'">({{displaySelectedChild}})</span>
      </b-navbar-brand>
      <b-navbar-toggle target="nav_collapse"></b-navbar-toggle>

      <b-collapse is-nav id="nav_collapse">
        <b-navbar-nav class="ml-auto">
          <b-nav-item v-if="userProfile.toLowerCase() == 'employee' && widgetData != null" class="widget-toplink hidden-sm-down" v-bind:href="widgetData.administration" target="_blank">Administration</b-nav-item>
          <b-nav-item-dropdown v-if="userProfile.toLowerCase() == 'parent' || userProfile.toLowerCase() == 'guardian'" text="Barn" right>
            <b-dropdown-item v-for="child in childFilter"
                             :key="child"
                             :value="child"
                             @click="selectedChild = child">{{child}}</b-dropdown-item>
          </b-nav-item-dropdown>

        </b-navbar-nav>
      </b-collapse>

    </b-navbar>
    <div v-if="widgetData.ErrorCode != null">
      <b-alert show variant="warning">{{widgetData.ErrorDescription}}</b-alert>
    </div>
    <div v-else>
      <div class="lds-css" v-if="fetchingData" style="align-content:center;height:5em;width:5em;">

      </div>

      <div v-if="!fetchingData">
        <div class="easyiq-link-searchcontainer">
          <b-form-input type="text" variant="outline" v-model="searchFilter" placeholder="Søg i links"></b-form-input>
        </div>


        <div class="easyiq-link-links">
          <div class="easyiq-link-panels" v-bind:data-placement="placement">
            <div role="tablist">
              <template v-for="panel in filteredPanels">
                <b-card no-body class="m-1" v-if="panel.links.length > 0">
                  <b-card-header header-tag="header" role="tab" class="p-1 m-0 bg-white">
                    <div @click="expandPanel(panel, placement)"
                         class="easyiq-link-panelheader text-info"
                         v-bind:data-placement="placement">
                      <div class="d-flex align-items-center">
                        <div>
                          <b-img v-bind:src="panel.iconpath" v-bind:data-placement="placement"></b-img>
                        </div>
                        <div>
                                                    <span>
                                                        {{panel.name}}
                                                    </span>
                        </div>
                        <div style="margin-left:auto;margin-right:10px">
                          <span class="easyiq-panel-openicon" v-if="!isPanelExpanded(panel, placement)"><i class="fas fa-angle-down"></i></span>
                          <span class="easyiq-panel-openicon" v-if="isPanelExpanded(panel, placement)"><i class="fas fa-angle-up"></i></span>
                        </div>
                      </div>
                    </div>

                  </b-card-header>
                  <div v-if="placement == 'narrow'">
                    <b-collapse v-if="(panel != null && panel.links != null && panel.links.length > 0)"
                                :id="'accordion-panel'+ panel.panelid"
                                v-model="panel.expandednarrow"
                                :accordion="'accordion-panel'+panel.panelid"
                                role="tabpanel">
                      <b-card-body class="p-1 m-0">
                        <div class="easyiq-link-panelcontainer" v-bind:data-placement="placement">
                          <a v-for="link in panel.links" class="easyiq-link-panel" v-bind:href="getLinkSmartUrl(link)" v-bind:data-placement="placement" target="_blank">
                            <div data-img class="d-flex justify-content-center">
                              <b-img v-bind:src="link.iconpath"></b-img>
                            </div>
                            <div data-title>
                              <p>{{link.name}}</p>
                            </div>
                          </a>
                        </div>
                      </b-card-body>
                    </b-collapse>
                  </div>
                  <div v-else>
                    <b-collapse v-if="(panel != null && panel.links != null && panel.links.length > 0)"
                                :id="'accordion-panel'+ panel.panelid"
                                v-model="panel.expanded"
                                :accordion="'accordion-panel'+panel.panelid"
                                role="tabpanel">
                      <b-card-body class="p-1 m-0">
                        <div class="easyiq-link-panelcontainer" v-bind:data-placement="placement">
                          <a v-for="link in panel.links" class="easyiq-link-panel" v-bind:href="getLinkSmartUrl(link)" v-bind:data-placement="placement" target="_blank">
                            <div data-img class="d-flex justify-content-center">
                              <b-img v-bind:src="link.iconpath"></b-img>
                            </div>
                            <div data-title>
                              <p>{{link.name}}</p>
                            </div>
                          </a>
                        </div>
                      </b-card-body>
                    </b-collapse>
                  </div>

                  <div class="easyiq-link-ListItemColoredBar" v-bind:data-placement="placement"></div>
                </b-card>

              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>
<script>
export default  {
  props: {
    /* normal and secure */
    axios: Function,
    sessionUUID: String,
    placement: String,
    currentWeekNumber: String,
    isMobileApp: Boolean,
    /* only secure */
    getAulaToken: Function,
    assuranceLevel: Number,
    childFilter: Array,
    group: String,
    userProfile: String,
    institutionFilter: Array
  },
  data() {
    return {
      aulaToken: null,
      user: {},
      errors: [],
      widgetData: {},
      baseApiAddress: 'https://api.easyiqcloud.dk/api/aula/',
      fetchingData: false,
      selectedWeekNr: "",
      selectedChild: "",
      searchFilter: "",
      countEvents: function ( typeId ) {
        var countResult = this.widgetData.Events.filter( ci => ci.itemType == typeId );
        return countResult.length;
      },
      ssoLinkUrlParams: function (link) {


        var baseParam = "?token=" + this.aulaToken;
        baseParam += "&userProfile=" + this.userProfile;
        baseParam += "&institutionFilter=" + this.institutionFilter.join( "," );
        baseParam += "&ssoLink=" + link;
        baseParam += `&selectedChild=${this.selectedChild != null ? this.selectedChild : ""}`;
        return baseParam;
      },
      shadeBlendConvert: function ( p, from, to ) {
        if ( typeof ( p ) != "number" || p < -1 || p > 1 || typeof ( from ) != "string" || ( from[0] != 'r' && from[0] != '#' ) || ( to && typeof ( to ) != "string" ) ) return null; //ErrorCheck
        if ( !this.sbcRip ) {
          this.sbcRip = function ( d ) {
            var l = d.length;
            var RGB = {};
            if ( l > 9 ) {
              d = d.split( "," );
              if ( d.length < 3 || d.length > 4 ) return null;//ErrorCheck
              RGB[0] = i( d[0].split( "(" )[1] ), RGB[1] = i( d[1] ), RGB[2] = i( d[2] ), RGB[3] = d[3] ? parseFloat( d[3] ) : -1;
            } else {
              if ( l == 8 || l == 6 || l < 4 ) return null; //ErrorCheck
              if ( l < 6 ) d = "#" + d[1] + d[1] + d[2] + d[2] + d[3] + d[3] + ( l > 4 ? d[4] + "" + d[4] : "" ); //3 or 4 digit
              d = i( d.slice( 1 ), 16 ), RGB[0] = d >> 16 & 255, RGB[1] = d >> 8 & 255, RGB[2] = d & 255, RGB[3] = -1;
              if ( l == 9 || l == 5 ) RGB[3] = r( ( RGB[2] / 255 ) * 10000 ) / 10000, RGB[2] = RGB[1], RGB[1] = RGB[0], RGB[0] = d >> 24 & 255;
            }
            return RGB;
          }
        }
        var i = parseInt, r = Math.round, h = from.length > 9, h = typeof ( to ) == "string" ? to.length > 9 ? true : to == "c" ? !h : false : h, b = p < 0, p = b ? p * -1 : p, to = to && to != "c" ? to : b ? "#000000" : "#FFFFFF", f = this.sbcRip( from ), t = this.sbcRip( to );
        if ( !f || !t ) return null; //ErrorCheck
        if ( h ) return "rgb" + ( f[3] > -1 || t[3] > -1 ? "a(" : "(" ) + r( ( t[0] - f[0] ) * p + f[0] ) + "," + r( ( t[1] - f[1] ) * p + f[1] ) + "," + r( ( t[2] - f[2] ) * p + f[2] ) + ( f[3] < 0 && t[3] < 0 ? ")" : "," + ( f[3] > -1 && t[3] > -1 ? r( ( ( t[3] - f[3] ) * p + f[3] ) * 10000 ) / 10000 : t[3] < 0 ? f[3] : t[3] ) + ")" );
        else return "#" + ( 0x100000000 + r( ( t[0] - f[0] ) * p + f[0] ) * 0x1000000 + r( ( t[1] - f[1] ) * p + f[1] ) * 0x10000 + r( ( t[2] - f[2] ) * p + f[2] ) * 0x100 + ( f[3] > -1 && t[3] > -1 ? r( ( ( t[3] - f[3] ) * p + f[3] ) * 255 ) : t[3] > -1 ? r( t[3] * 255 ) : f[3] > -1 ? r( f[3] * 255 ) : 255 ) ).toString( 16 ).slice( 1, f[3] > -1 || t[3] > -1 ? undefined : -2 );
      },
      initWidget: function () {
        this.errors.push( { message: "henter data" } );
        this.fetchingData = true;
        this.axios.post(
          this.baseApiAddress + 'links',
          this.aulaFilter,
          {
            headers:
              {
                'Authorization': 'bearer ' + this.aulaToken,
                'x-aula-userprofile': this.userProfile,
                'x-aula-institutionfilter': this.institutionFilter,
                'Content-Type': 'application/json'
              }
          } )
          .then( response => {
            this.fetchingData = false;
            this.widgetData = response.data;
            if (this.childFilter.length > 0) {
              this.selectedChild = this.childFilter[0];
            }
          } );
      },
      isPanelExpanded: function (panel, placement) {
        switch (placement) {
          case "narrow": {
            return panel.expandednarrow;
          }
          default: {
            return panel.expanded;
          }
        }
        return true;
      },
      expandPanel: function (panel, placement) {
        switch (placement) {
          case "narrow": {
            panel.expandednarrow = !panel.expandednarrow;
          }
          default: {
            panel.expanded = !panel.expanded;
          }
        }
      },
      getLinkSmartUrl: function (link) {
        if (link.ssoaulatoschoolportal == true) {
          return this.baseSchoolPortalUrl + 'aula/authuser/' + this.ssoLinkUrlParams(this.baseSchoolPortalUrl + link.url)
        } else {
          return link.url;
        }
      }
    }
  },
  computed: {
    aulaFilter: function () {
      return {
        sessionId: this.sessionUUID,
        group: this.selectedGroup != "" ? this.selectedGroup : this.group,
        currentWeekNr: this.selectedWeekNr != "" ? this.selectedWeekNr : this.currentWeekNumber,
        userProfile: this.userProfile,
        institutionFilter: this.institutionFilter,
        childFilter: this.selectedChild != "" ? [this.selectedChild.trim()] : this.childFilter
      }
    },
    displaySelectedChild: function () {

      return this.selectedChild != "" ? this.selectedChild.trim() : this.childFilter[0];
    },
    filteredPanels: function () {
      if ( this.widgetData != null && this.widgetData.panels != null ) {
        if (this.searchFilter != "") {
          var filteredArray = JSON.parse( JSON.stringify( this.widgetData.panels ) );
          for ( var i = 0; i < filteredArray.length; i++ ) {
            var panel = filteredArray[i];
            panel.links = panel.links.filter(l => l.name.toLowerCase().includes(this.searchFilter.toLowerCase()));
            if (panel.links.length > 0) {
              panel.expanded = true;
              panel.expandednarrow = true;
            }
          }

          return filteredArray;
        }

        return this.widgetData.panels;
      }
    },
    isWidgetTest: function () {
      if ( window.origin ) {
        if ( ( window.origin ).includes( 'widget-test' ) ) {
          return true;
        }
      }
      else if ( window.location.origin ) {
        if ( ( window.location.origin ).includes( 'widget-test' ) ) {
          return true;
        }
      }
      else {
        return false;
      }
    },
    baseSchoolPortalUrl: function () {
      try {
        return this.isWidgetTest ? "Https://skoleportal-udv.easyiqcloud.dk/" : "Https://skoleportal.easyiqcloud.dk/";
      }
      catch(e){
        return "Https://skoleportal.easyiqcloud.dk/";
      }

    }
  },
  watch: {
    aulaToken: function () {
      if (this.aulaToken !== undefined) {
        this.initWidget();
      }
    },
    aulaFilter() {
      this.aulaToken = this.getAulaToken();
    }
  }
  , created() {
    try {
      if ( this.isWidgetTest ) {
        this.baseApiAddress = "https://test-api.easyiqcloud.dk/api/aula/";
      }
    }
    catch(e){

    }

    this.aulaToken = this.getAulaToken();
  }
}
</script>
<style lang="scss">
.widget-W0018V0001 {
/*bootstrap overrides*/
.easyiq-wp-classesDropdown /deep/ .dropdown-menu
{
  height: auto;
  max-height: 200px;
  overflow-x: hidden;
  font-size:0.9em;
}
/*bootstrap overrides end*/
.easyiq-link-links {
  background-color:#FFF;
}
.easyiq-link-panelheader {
  font-size: 1.3em;
  text-transform: uppercase;
  color: #12798c;
  margin:10px;
  cursor:pointer;
}

.easyiq-link-panelheader /deep/ button{
  background:transparent;
  padding:5px;
  margin:3px;

}
.easyiq-panel-header{

}
.easyiq-panel-openicon{
  font-size:1em;
}

.easyiq-link-panel div[data-image] {
  margin-top: 5px;
  height: 48px;

}

.easyiq-link-panel img {
  vertical-align: middle;
  max-height: 48px;
  max-width: 100%;
  width: unset !important;

}
.easyiq-link-panel div[data-title] p{
  max-height: 45px;
  overflow-y: hidden;
  font-size: 0.8em;
}

.easyiq-link-panel {
  height: 100px;
  width: 100px;
  margin: 5px 5px 5px 0;
  padding:5px;
  text-align: center;
  border: 1px dotted #c1c1c1;
  border-radius:5px;
  -webkit-border-radius:5px;
}

.easyiq-link-panelcontainer {
  background-color: #FFF;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}

.easyiq-link-panelcontainer[data-placement='narrow'] {
  background-color: #FFF;
  display: unset;
}

.easyiq-link-panel[data-placement='narrow'] {
  height: unset;
  width: unset;
  margin: unset;
  text-align: unset;

  display: flex;
  align-items: center;
  padding: 0 5px !important;
  border:unset;
}
.easyiq-link-panelheader img
{
  margin-right:10px;
  height:32px;
}

.easyiq-link-panelheader img[data-placement='narrow']
{
  height:16px;
}

.easyiq-link-ListItemColoredBar {
  height: 7px;
  margin-top: -7px;
  margin-bottom: 2px;
  position: relative;
  z-index: 1;
  background-color: #17a2b8 !important;
}

.easyiq-link-ListItemColoredBar[data-placement='narrow'] {
  margin-top:0;
}

.easyiq-link-panel[data-placement='narrow'] img {
  max-height: 16px;
  max-width: 100%;
  width: unset !important;
}
.easyiq-link-panel[data-placement='narrow'] div[data-title] p {
  overflow-x: hidden;
  font-size: 0.8em;
  margin:0;
  justify-content:center;
  padding:0 5px;
}
.easyiq-link-panelheader[data-placement='narrow'] {
  font-size: 1em;
  text-transform: uppercase;
  padding: 0 10px;
  color: #12798c;
  margin:5px;
}
.easyiq-link-panel[data-placement='narrow'] div[data-img]
{
  align-items:center;
  width:40px;
  min-width:40px;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0)
  }

  100% {
    transform: rotate(360deg)
  }
}

.lds-css {
  width: 240px;
  height: 240px;
  margin: auto;
  transform: scale(0.7);
}

.lds-ring {
  height: 100%;
  width: 100%;
}

.lds-ring > div {
  position: absolute;
  top: 10%;
  left: 10%;
  width: 80%;
  height: 80%;
  border-radius: 50%;
  border: 10px solid #fff;
  border-color: #fff transparent transparent transparent;
  animation: lds-ring 1.5s cubic-bezier(0.5,0,0.5,1) infinite;
}

.lds-ring > div:nth-child(1) {
  border-color: rgba(0, 122, 141, 0.25);
  animation: none;
}

.lds-ring > div:nth-child(3) {
  animation-delay: .19s;
}

.lds-ring > div:nth-child(4) {
  animation-delay: .38s;
}

.lds-ring > div:nth-child(5) {
  animation-delay: .57s;
}
}
</style>
