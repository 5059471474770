<script>

const Footer = {}

export default Footer

</script>

<template>
    <div class="widget-footer">
        <div class="footer-item">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" class="footer-item-icon">
                <path
                    d="M0 4v18c0 3.314 2.686 6 6 6v0h20c3.314 0 6-2.686 6-6v0-18h-32zM30.667 22c0 2.577-2.089 4.667-4.667 4.667v0h-20c-2.577 0-4.667-2.089-4.667-4.667v0-16.667h29.333v16.667zM28.32 9.113l-0.893-0.987-11.427 10.307-11.36-10.293-0.893 0.987 12.253 11.107 12.32-11.12z" />
            </svg>
            <p class="footer-item-text">support@subit.dk</p>
        </div>
        <div class="footer-item">
            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 32 32" class="footer-item-icon">
                <path
                    d="M22 0h-12c-1.841 0-3.333 1.492-3.333 3.333v0 25.333c0 1.841 1.492 3.333 3.333 3.333v0h12c1.841 0 3.333-1.492 3.333-3.333v0-25.333c0-1.841-1.492-3.333-3.333-3.333v0zM10 1.333h12c1.105 0 2 0.895 2 2v0 22h-16v-22c0-1.105 0.895-2 2-2v0zM22 30.667h-12c-1.105 0-2-0.895-2-2v0-2h16v2c0 1.105-0.895 2-2 2v0zM17.333 28.667c0 0.736-0.597 1.333-1.333 1.333s-1.333-0.597-1.333-1.333c0-0.736 0.597-1.333 1.333-1.333s1.333 0.597 1.333 1.333z" />
            </svg>
            <p class="footer-item-text">70 60 51 02</p>
        </div>
    </div>
</template>